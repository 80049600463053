import React, { memo } from "react";
import withDataGrid from "../../../../../DataGrid/withDataGrid.js";
import DataGrid from "../../../../../DataGrid";

import { deleteResource } from "../../../../../../../store/resources/actions";
import { programsAttributesGridColumnKeys } from "../../../../../../views/configureGrids";
import { modalOpen } from "../../../../../../../store/modal/actions";
import AttributesForm from "./AttributesForm";
import { onAttributeChanged } from "./utils.js";

import { sideNavSize } from "../../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";

const AttributesGrid = memo(({ dispatch, gridId, dataGridConfig, programNumber }) => {
    const isLocked = false;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "program-config-attributes-sidenav-panel",
    });
    const customRowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            hide: isLocked,
            title: "Delete Attribute",
        },
    ];

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        const attributeNumber = dataItem[programsAttributesGridColumnKeys.attributeNumber];
        const attributeTypeName = dataItem[programsAttributesGridColumnKeys.attributeType];

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <AttributesForm
                        onClose={handleCloseSidePanel}
                        dataItem={dataItem}
                        gridId={gridId}
                        dispatch={dispatch}
                        programNumber={programNumber}
                        attributeNumber={attributeNumber}
                    />
                );
                break;
            case "delete":
                const dialogMessage = (
                    <p>
                        Are you sure you want to delete the attribute <b> {attributeTypeName} </b> ?
                    </p>
                );

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Attribute",
                            overlayClassName: "modal-styled",
                            className: "delete-attribute-confirmation-modal modal-sm",
                            modalIcon: "delete-trash-empty",
                            content: dialogMessage,
                            footerContentCenter: true,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "generalAttributes",
                                        resourceId: attributeNumber,
                                        optimisticUpdate: {
                                            clearItem: true,
                                        },
                                        onSuccess: () => {
                                            onAttributeChanged({
                                                programNumber,
                                                attributeTypeName,
                                                dataGridId: gridId,
                                            });
                                        },
                                    })
                                );
                            },
                        },
                    })
                );
                break;
            default:
                break;
        }
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsAttributes", AttributesGrid);
