import React from "react";
import DataGrid from "../../../../../DataGrid";
import withDataGrid from "../../../../../DataGrid/withDataGrid.js";
import { utilitiesHostnamesGridColumnKeys } from "../../../../../../views/configureGrids";
import { openProgramTab } from "store/window/openTabActions";
import { useDispatch } from "react-redux";

const HostnamesGrid = (props) => {
    const { gridId, dataGridConfig } = props;
    const dispatch = useDispatch();

    const customRowActions = [
        {
            name: "Manage Program",
            icon: "open-new-window",
            title: "Manage Program",
        },
    ];

    const onRowAction = (action) => {
        const { dataItem } = action;
        const programNumber = dataItem[utilitiesHostnamesGridColumnKeys.programId];

        dispatch(openProgramTab({ programNumber }));
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
};

export default withDataGrid("utilitiesHostnames", HostnamesGrid);
