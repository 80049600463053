import React, { useCallback, memo, useContext } from "react";
import { useDispatch } from "react-redux";

import { formatMoney } from "../../utils/money";
import { useInvoiceDocumentAttributes, useInvoiceDocument, useInvoiceDocumentFlags } from "../../../store/resources/useResource";
import { onCloneInvoiceDocument } from "../../ui/Dashboard/Panel/Invoice/utils";
import { openContractTab, openUtilityTab } from "../../utils/window";
import { isDateAttributeType } from "../../utils/attributes";
import { formatJsonDate } from "../../utils/date";
import { modalOpen } from "../../../store/modal/actions";
import { updateResource } from "../../../store/resources/actions";
import { entityType } from "../../utils/constants";
import { refreshInvoiceDocument } from "../../../store/resources/refreshResource";
import { refreshInvoiceDocumentBookmarksGrid } from "../../../store/dataGrid/refreshGrid";
import { invoiceIsLockedText, useInvoiceDocumentLocked } from "./utils";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { WindowContext } from "../../ui/Windows/Window";

import WaitIcon from "../../ui/WaitIcon";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import IconWrap from "../../ui/Icons";
import HeaderIconGroup from "../../ui/Icons/IconGroup";
import ActionLabel from "../../ui/Label/ActionLabel";
import SubHeaderInvoice from "./SubHeaderInvoice";
import UtilityName from "../../ui/UtilityName";
import Form from "../../ui/Dashboard/Panel/Contract/InvoicesPanel/Form";

import { useIsMobile } from "components/utils/useIsMobile";
import { useIsDesktop } from "components/utils/useIsDesktop";

import "./Header.scss";

const Header = memo(({ utilityNumber, contractNumber, documentNumber }) => {
    const dispatch = useDispatch();

    const [invoiceDocument = {}, isLoadingInvoiceDocument] = useInvoiceDocument({ documentNumber });
    const [attributes = [], isLoadingAttributes] = useInvoiceDocumentAttributes({ utilityNumber, contractNumber, documentNumber });
    const [flags, isLoadingFlags] = useInvoiceDocumentFlags({
        utilityNumber,
        contractNumber,
        documentNumber,
    });
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "utility-invoice-header-sidenav-panel",
    });

    const isMobile = useIsMobile();
    const { isSplitView } = useContext(WindowContext);
    const isDesktop = useIsDesktop();

    const items = [
        isMobile && {
            name: "Invoice Number",
            value: `#${invoiceDocument.invoiceNumber}`,
        },
        {
            name: "Utility",
            value: <UtilityName utilityNumber={utilityNumber} />,
            onClick: () => openUtilityTab({ utilityNumber }),
        },
        {
            name: "Contract",
            value: (
                <ActionLabel
                    onClick={() =>
                        openContractTab({
                            utilityNumber,
                            contractNumber,
                            contractDescription: invoiceDocument.contractDescription,
                        })
                    }
                >
                    {invoiceDocument.contractDescription}
                </ActionLabel>
            ),
        },
        {
            name: "Invoice Doc #",
            value: invoiceDocument.invoiceDocumentID,
        },
        {
            name: "Invoice Document",
            value: invoiceDocument.invoiceDocumentNumber,
        },
        {
            name: "Total Authorized",
            value: formatMoney(invoiceDocument.total),
        },
        {
            name: "Vendor ID",
            value: attributes.filter((a) => a.attributeName === "vendor_contactid").map((a) => a.attributeValue)[0],
        },
        {
            name: "Vendor",
            value: invoiceDocument.vendor,
        },
        {
            name: "Description",
            value: invoiceDocument.invoiceDescription,
        },
        {
            name: "Payment Text",
            value: invoiceDocument.paymentText,
        },
    ];

    const expandableAttributes = attributes
        .filter((a) => a.attributeName.indexOf("attr") === 0)
        .map((i) => ({
            name: i.attributeDescription,
            value: isDateAttributeType(i.validation) ? formatJsonDate(i.attributeValue) : i.attributeValue,
        }));

    const handleEditInvoice = useCallback(() => {
        handleOpenSidePanel(
            <Form utilityNumber={utilityNumber} contractNumber={contractNumber} dataItem={invoiceDocument} onClose={handleCloseSidePanel} />
        );
    }, [utilityNumber, contractNumber, invoiceDocument, handleOpenSidePanel, handleCloseSidePanel]);

    const onBookmark = useCallback(() => {
        const text =
            "Would you like to " + (invoiceDocument.isFavorite ? "remove this invoice from" : "add this invoice to") + " your bookmarks?";

        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: invoiceDocument.isFavorite ? "Remove from Bookmarks" : "Add to Bookmarks",
                    modalIcon: invoiceDocument.isFavorite
                        ? "bookmark-filled icon-wrap-combined remove"
                        : "bookmark-empty icon-wrap-combined add",
                    overlayClassName: "modal-styled",
                    className: "remove-utility-invoice-from-bookmarks-confirmation-modal modal-sm",
                    content: text,
                    footerContentCenter: true,
                    onConfirm: () => {
                        dispatch(
                            updateResource({
                                resourceName: "userFavorites",
                                query: {
                                    entityType: entityType.InvoiceDocument,
                                    entityId: invoiceDocument.invoiceDocumentNumber,
                                },
                                onSuccess: () => {
                                    refreshInvoiceDocumentBookmarksGrid();
                                    refreshInvoiceDocument({
                                        utilityNumber,
                                        contractNumber,
                                        documentNumber,
                                    });
                                },
                            })
                        );
                    },
                },
            })
        );
    }, [utilityNumber, contractNumber, documentNumber, invoiceDocument, dispatch]);

    return (
        <WindowBodyHeader flagsAndTagsAndAttributesSpecificLayout={!isSplitView && isDesktop} windowBodyHeaderName="Invoice">
            {isLoadingAttributes || isLoadingFlags || isLoadingInvoiceDocument ? (
                <WaitIcon />
            ) : (
                <>
                    <HeaderIconGroup header>
                        <IconWrap
                            iconWrapBig
                            icon="edit-empty"
                            disabled={isLocked}
                            title={isLocked ? invoiceIsLockedText : "Edit Invoice Document"}
                            onClick={handleEditInvoice}
                        />
                        <IconWrap
                            iconWrapBig
                            icon="copy-empty"
                            title="Copy Invoice"
                            onClick={() =>
                                onCloneInvoiceDocument({
                                    utilityNumber,
                                    contractNumber,
                                    documentNumber,
                                    vendor: invoiceDocument.vendor,
                                })
                            }
                        />
                        <IconWrap
                            iconWrapBig
                            icon={invoiceDocument.isFavorite ? "bookmark-filled" : "bookmark-empty"}
                            title={invoiceDocument.isFavorite ? "Un-Bookmark Invoice" : "Bookmark Invoice"}
                            onClick={onBookmark}
                        ></IconWrap>
                    </HeaderIconGroup>
                    <SubHeaderInvoice
                        items={items}
                        isLoading={isLoadingAttributes || isLoadingFlags}
                        attributes={expandableAttributes}
                        flags={flags}
                        flagsAndTagsAndAttributesSpecificLayout={!isSplitView && isDesktop}
                    />
                </>
            )}
        </WindowBodyHeader>
    );
});

export default Header;
