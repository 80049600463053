import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = memo(({ contractNumber, dataGridId, onAdd }) => {
    return (
        <div className="grid-controls flex-row justify-end">
            <IconWithLabel icon="plus" onClick={onAdd}>
                Add Parent Budget Line
            </IconWithLabel>
            <IconWithLabel
                withHandMadeIcon
                onClick={() =>
                    exportDatagridToCSV({
                        dataGridId: dataGridId,
                        fileName: "contract_budget_lines",
                        fileNamePostfix: contractNumber,
                    })
                }
            >
                Export CSV
            </IconWithLabel>
        </div>
    );
});

export default Controls;
