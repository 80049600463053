import React, { memo, useCallback, useState } from "react";
import SchemaField from "./SchemaField";
import ClassNames from "classnames";
import { get, isNil } from "lodash";

import { useSelector, useDispatch } from "react-redux";
import { selectElement, deselectElement } from "../../../../../store/formBuilder/actions";
import { getElementType, isElementFixedWidth, getFormBuilder, getElementNumber } from "../../../../../store/formBuilder/selectors";
import IconWrap from "../../../Icons";

import { useDrag } from "react-dnd";
import { ElementTypes, formIdToSchemaId } from "../../../../utils/form";
import { canDeleteElement } from "components/ui/FormBuilder/utils";
import { addDragScrollListener, removeDragScrollListener } from "./dragScrollListener";

const FormBuilderSchemaField = memo((props) => {
    const { instanceId, schema, uiSchema, idSchema, onRemove } = props;

    const [isHovered, setIsHovered] = useState();
    const dispatch = useDispatch();
    const formBuilder = useSelector((state) => getFormBuilder({ instanceId: instanceId, state: state.formBuilder }));
    const { selectedElementId, existingElementNumbers, validationErrors } = formBuilder;

    const elementType = getElementType({ uiSchema });
    const elementId = idSchema.$id;
    const elementNumber = getElementNumber({ uiSchema });
    const isDisabled = get(uiSchema, "ui:disabled", null);
    const isSelected = formIdToSchemaId(selectedElementId) === formIdToSchemaId(elementId);
    const canDelete = canDeleteElement({
        isSelected,
        existingElementNumbers,
        elementNumber,
        elementType,
        schema,
    });
    const isError = !isNil(validationErrors[elementNumber]);
    const isAdministrative = Number(get(uiSchema, "af:fieldGroup", 0)) === 42;
    const isRemoved = get(uiSchema, "ui:remove", false);

    // Set field error
    const errorSchema = isError
        ? {
              __errors: [validationErrors[elementNumber].message],
          }
        : props.errorSchema;

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ElementTypes.FIELD,
            item: () => {
                addDragScrollListener(instanceId);

                return {
                    ...props,
                    instanceId,
                    elementId,
                    type: getElementType(props),
                    elementType: getElementType(props),
                };
            },
            end: () => {
                removeDragScrollListener(instanceId);
            },
            collect: (monitor) => {
                return { isDragging: monitor.isDragging() };
            },
        }),
        [instanceId, elementId]
    );

    const className = ClassNames(`schema-container ${elementType}-container`, {
        "fill-width": !isElementFixedWidth(uiSchema),
        "is-dragging": isDragging,
        "field-hidden": isDisabled,
        "field-selected": isSelected,
        "field-error": isError,
        "field-hovered": isHovered,
        "field-administrative": isAdministrative,
        "field-removed": isRemoved,
    });

    if ([ElementTypes.SECTION, ElementTypes.ROW, ElementTypes.COLUMN].includes(elementType)) {
        updateContainerClassNames(elementType, uiSchema);
    }

    const onSelect = useCallback(
        (e) => {
            const allowedClasses = [
                "text",
                "password",
                "textarea",
                "checkbox",
                "radio",
                "dropdown-field",
                "dropdown-list-item",
                "k-input",
                "k-i-clock",
                "ql-editor",
                "with-icon",
                "datepicker",
                "react-datepicker__day",
                "react-datepicker__navigation",
                "react-datepicker__current-month",
                "react-datepicker__today-button",
                "react-datepicker__week",
                "react-datepicker-popper",
            ];

            // If clicked element has any of classes that allow to input value
            if (allowedClasses.some((item) => e.target.classList.contains(item))) {
                // If it is field and not set as active
                if (elementType === ElementTypes.FIELD && !isSelected) {
                    dispatch(
                        selectElement({
                            instanceId,
                            elementId,
                            validateProperties: true,
                        })
                    );

                    // Preserve element class to find it after rerender. After rerender element ref will be different.
                    const elementClassName = allowedClasses.filter((c) => e.target.classList.contains(c)).join(".");

                    setTimeout(() => {
                        // Search for previously selected field to focus it.
                        const element = document.querySelector(`.field-selected .${elementClassName}`);

                        if (element) {
                            element.focus();
                        }
                    }, 100);
                }

                return;
            }

            e.stopPropagation();
            if (isSelected) {
                dispatch(deselectElement({ instanceId, validateProperties: true }));
            } else {
                dispatch(
                    selectElement({
                        instanceId,
                        elementId,
                        scrollToElement: false,
                        validateProperties: true,
                    })
                );
            }
        },
        [instanceId, elementType, elementId, isSelected, dispatch]
    );

    const onRemoveElement = useCallback(
        (e) => {
            e.stopPropagation();
            onRemove({
                elementId,
                elementType,
                fieldNumber: uiSchema["af:fieldNumber"],
            });
        },
        [elementType, elementId, uiSchema, onRemove]
    );

    const onMouseOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsHovered(true);
    };

    const onMouseOut = () => {
        setIsHovered(false);
    };

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "move",
            }}
            className={className}
            onClick={onSelect}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <IconWrap icon="clear-close" title="Remove" hidden={!canDelete} onClick={onRemoveElement} />
            {isAdministrative && (
                <IconWrap icon="administrative-field-icon eye-visibility-empty" iconWrapCombined title="Administrative Field"></IconWrap>
            )}
            {isDisabled && <IconWrap icon="block-not-interested-empty" title="Disabled Field"></IconWrap>}
            {isRemoved && (
                <IconWrap className="removed-field-icon" icon="cut-scissors-filled" title="Conditionally Removed Field"></IconWrap>
            )}
            <SchemaField {...props} errorSchema={errorSchema} isFormBuilder />
        </div>
    );
});

const updateContainerClassNames = (elementType, uiSchema) => {
    const classNames = (uiSchema && uiSchema.classNames) || "";

    if (!(classNames && classNames.includes(elementType))) {
        uiSchema.classNames = ClassNames(uiSchema.classNames, elementType);
    }
};

export default FormBuilderSchemaField;
