import { isNil } from "lodash";
import { store } from "../configureStore";
import { getViewsByComponentName, windowContainerTypes } from "../../components/utils/window";
import { getResource } from "./actions";
import { entityType } from "../../components/utils/entityType";
import {
    getEventCategoriesResourceParams,
    getEventsInCategoryResourceParams,
    getUtilitiesInvoiceWorkflowResourceParams,
    getCalculationTestInputsResourceParams,
    getContractResourceParams,
    getUtilitiesWorkflowAssignmentsResourceParams,
    getProgramWorkflowAssignmentsResourceParams,
    getInvoiceDocumentResourceParams,
    getProgramAvailableDocumentsResourceParams,
    getUtiltyResourceParams,
    getProgramResourceParams,
    getApplicationResourceParams,
    getApplicationTagsResourceParams,
    getApplicationFlagsResourceParams,
    getApplicationFormsResourceParams,
    getApplicationFormDetailsResourceParams,
    getApplicationWorkflowResourceParams,
    getApplicationStatusesResourceParams,
    getApplicationWorkflowStatusTransferResourceParams,
    getProgramWorkflowStatusApplicationTransferResourceParams,
    getFolderListResourceParams,
    getProgramFormFriendlyNamesResourceParams,
    getUtilitiesContractsInvoiceDocumentsFlagsResourceParams,
    getUtilitiesContractsInvoiceDocumentsAttributesResourceParams,
    getUtilitiesContractsWorkflowStatusesResourceParams,
    getApplicationWorkflowHistoryResourceParams,
    getApplicationAvailableDocumentsResourceParams,
    getCustomerContactResourceParams,
    getCustomerContactsResourceParams,
    getUtilitiesInvoiceWorkflowHistoryResourceParams,
    getPortalConfigurationResourceParams,
    getPortalTemplateResourceParams,
    getPortalTemplatesResourceParams,
} from "../configureResources";
import { refreshAllGrids } from "store/dataGrid/refreshGrid";
import { clearFormData } from "store/forms/actions";

export const refreshUtility = ({ utilityNumber }) => {
    if (utilityNumber) {
        store.dispatch(getResource(getUtiltyResourceParams({ utilityNumber })));
    }
};

export const refreshProgram = ({ programNumber }) => {
    if (programNumber) {
        store.dispatch(getResource(getProgramResourceParams({ programNumber })));
    }
};

export const refreshProgramInOpenTab = ({ programNumber }) => {
    if (programNumber) {
        getViewsByComponentName({ componentName: "ProgramView" })
            .map((view) => view.props)
            .forEach((props) => {
                if (props.programNumber === programNumber) {
                    refreshProgram({ programNumber });
                }
            });
    }
};

export const refreshApplication = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(getResource(getApplicationResourceParams({ applicationNumber })));
        store.dispatch(getResource(getApplicationWorkflowResourceParams({ applicationNumber })));
        store.dispatch(
            getResource(
                getApplicationWorkflowHistoryResourceParams({
                    applicationNumber,
                })
            )
        );
        store.dispatch(getResource(getApplicationStatusesResourceParams({ applicationNumber })));
        refreshApplicationFormDetails({ applicationNumber });
        refreshApplicationFlags({ applicationNumber });
    }
};

export const refreshApplicationTags = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(getResource(getApplicationTagsResourceParams({ applicationNumber })));
    }
};

export const refreshApplicationFlags = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(getResource(getApplicationFlagsResourceParams({ applicationNumber })));
    }
};

export const refreshApplicationForms = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(getResource(getApplicationFormsResourceParams({ applicationNumber })));
    }
};

export const refreshApplicationFormDetails = ({ applicationNumber }) => {
    const applicationFormId = store.getState().projects.activeApplicationFormId[applicationNumber];

    if (applicationFormId) {
        store.dispatch(clearFormData());
        store.dispatch(
            getResource(
                getApplicationFormDetailsResourceParams({
                    applicationNumber,
                    applicationFormId,
                })
            )
        );
    }
};

export const refreshApplicationDetailsInOpenTabs = ({ programNumber }) => {
    const state = store.getState();

    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            const application = state.resources.applicationDetails.itemsById[applicationNumber] || {};

            if (application.programNumber === programNumber) {
                refreshApplication({ applicationNumber });
            }
        });
};

/**
 * Refresh application forms in open tabs.
 *
 * @param {*} { programNumber } - If specified, refresh only this program apps
 */
export const refreshApplicationFormsInOpenTabs = ({ programNumber } = {}) => {
    const state = store.getState();

    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            const application = state.resources.applicationDetails.itemsById[applicationNumber] ?? {};

            if (isNil(programNumber) || application.programNumber === programNumber) {
                refreshApplicationFormDetails({ applicationNumber });
                refreshApplicationForms({ applicationNumber });
            }
        });
};

/**
 * Refresh application available documents in open tabs.
 *
 * @param {*} { programNumber } - If specified, refresh only this program apps
 */
export const refreshApplicationAvailabelDocumentsInOpenTabs = ({ programNumber, isEmail } = {}) => {
    const state = store.getState();

    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            const application = state.resources.applicationDetails.itemsById[applicationNumber] ?? {};

            if (isNil(programNumber) || application.programNumber === programNumber) {
                refreshApplicationAvailabelDocuments({
                    applicationNumber,
                    isEmail,
                });
            }
        });
};

export const refreshApplicationWorkflowInOpenTabs = () => {
    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            store.dispatch(getResource(getApplicationWorkflowResourceParams({ applicationNumber })));
            store.dispatch(
                getResource(
                    getApplicationWorkflowHistoryResourceParams({
                        applicationNumber,
                    })
                )
            );
        });
};

export const refreshApplicationStatusesInOpenTabs = () => {
    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            store.dispatch(getResource(getApplicationStatusesResourceParams({ applicationNumber })));
        });
};

export const refreshApplicationFolderListsInOpenTabs = ({ programNumber }) => {
    const state = store.getState();

    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            const application = state.resources.applicationDetails.itemsById[applicationNumber] || {};

            if (application.programNumber === programNumber) {
                store.dispatch(
                    getResource(
                        getFolderListResourceParams({
                            entityTypeId: entityType.projectApplication,
                            entityId: applicationNumber,
                        })
                    )
                );
            }
        });
};

export const refreshApplicationEventCategories = () => {
    getViewsByComponentName({ componentName: "ProjectView" })
        .map((view) => view.props.applicationNumber)
        .forEach((applicationNumber) => {
            const eventCategoriesResourceParams = getEventCategoriesResourceParams({ applicationNumber });

            store.dispatch(
                getResource({
                    ...eventCategoriesResourceParams,
                    onSuccess: (action) => {
                        ((action && action.data) || [])
                            .map((category) => category.categorynumber)
                            .forEach((categoryNumber) => {
                                const eventsInCategoryResourceParams = getEventsInCategoryResourceParams({
                                    applicationNumber,
                                    categoryNumber,
                                });
                                store.dispatch(getResource(eventsInCategoryResourceParams));
                            });
                    },
                })
            );
        });
};

export const refreshInvoiceWorkflows = () => {
    getViewsByComponentName({ componentName: "UtilityInvoiceView" })
        .map((view) => view.props)
        .forEach((props) => {
            const { documentNumber } = props;
            const utilitiesInvoiceWorkflowResourceParams = getUtilitiesInvoiceWorkflowResourceParams({ documentNumber });

            store.dispatch(getResource(utilitiesInvoiceWorkflowResourceParams));
        });
};

export const refreshContractWorkflowAssignments = ({ utilityNumber }) => {
    if (utilityNumber) {
        getViewsByComponentName({ componentName: "UtilityContractView" })
            .map((view) => view.props)
            .forEach((props) => {
                if (props.utilityNumber === utilityNumber) {
                    const { contractNumber } = props;
                    const utilitiesWorkflowAssignmentsResourceParams = getUtilitiesWorkflowAssignmentsResourceParams({
                        utilityNumber,
                        contractNumber,
                    });

                    store.dispatch(getResource(utilitiesWorkflowAssignmentsResourceParams));
                }
            });
    }
};

export const refreshProgramWorkflowAssignments = ({ utilityNumber }) => {
    if (utilityNumber) {
        getViewsByComponentName({ componentName: "ProgramView" })
            .map((view) => view.props)
            .forEach((props) => {
                const { programNumber } = props;
                const programUtilityNumber = store.getState().resources.programs.itemsById[programNumber]?.utilityNumber;

                if (programUtilityNumber === utilityNumber) {
                    store.dispatch(
                        getResource(
                            getProgramWorkflowAssignmentsResourceParams({
                                programNumber,
                            })
                        )
                    );
                }
            });
    }
};

export const refreshCalculationTestInputs = ({ calculationNumber }) => {
    if (calculationNumber) {
        store.dispatch(getResource(getCalculationTestInputsResourceParams({ calculationNumber })));
    }
};

export const refreshPortalBuilderFiles = ({ utilityNumber, onSuccess }) => {
    if (utilityNumber) {
        store.dispatch(
            getResource({
                resourceName: "fileList",
                key: utilityNumber,
                query: { entityTypeId: entityType.portalTemplate, entityId: utilityNumber },
                onSuccess,
            })
        );
    }
};

export const refreshContractTab = ({ contractNumber }) => {
    if (contractNumber) {
        getViewsByComponentName({ componentName: "UtilityContractView" })
            .map((view) => view.props)
            .forEach((props) => {
                const { utilityNumber } = props;
                refreshContract({ utilityNumber, contractNumber });
            });
    }
};

export const refreshContract = ({ utilityNumber, contractNumber }) => {
    if (contractNumber) {
        store.dispatch(
            getResource({
                ...getContractResourceParams({ utilityNumber }),
                resourceId: contractNumber,
            })
        );
    }
};

export const refreshContracts = ({ utilityNumber }) => {
    if (utilityNumber) {
        store.dispatch(
            getResource({
                ...getContractResourceParams({ utilityNumber }),
                key: utilityNumber,
            })
        );
    }
};

export const refreshInvoiceDocumentTab = ({ documentNumber }) => {
    const invoice = store.getState().resources.invoiceDocument.itemsById[documentNumber];

    if (invoice) {
        const { utilityNumber, contractNumber } = invoice;

        refreshInvoiceDocument({
            utilityNumber,
            contractNumber,
            documentNumber,
        });
        refreshInvoiceDocumentFlags({
            utilityNumber,
            contractNumber,
            documentNumber,
        });
        refreshInvoiceDocumentAttributes({
            utilityNumber,
            contractNumber,
            documentNumber,
        });

        // Refresh workflow related resources
        store.dispatch(getResource(getUtilitiesInvoiceWorkflowResourceParams({ documentNumber })));
        store.dispatch(
            getResource(
                getUtilitiesInvoiceWorkflowHistoryResourceParams({
                    documentNumber,
                })
            )
        );
        store.dispatch(
            getResource(
                getUtilitiesContractsWorkflowStatusesResourceParams({
                    utilityNumber,
                    contractNumber,
                })
            )
        );
    }
};

export const refreshInvoiceDocument = ({ utilityNumber, contractNumber, documentNumber }) => {
    if (utilityNumber && contractNumber && documentNumber) {
        store.dispatch(
            getResource({
                ...getInvoiceDocumentResourceParams(),
                resourceId: documentNumber,
            })
        );
    }
};

export const refreshInvoiceDocumentFlags = ({ utilityNumber, contractNumber, documentNumber }) => {
    if (utilityNumber && contractNumber && documentNumber) {
        store.dispatch(
            getResource({
                ...getUtilitiesContractsInvoiceDocumentsFlagsResourceParams({
                    utilityNumber,
                    contractNumber,
                    documentNumber,
                }),
                key: documentNumber,
            })
        );
    }
};

export const refreshInvoiceDocumentAttributes = ({ utilityNumber, contractNumber, documentNumber }) => {
    if (utilityNumber && contractNumber && documentNumber) {
        store.dispatch(
            getResource(
                getUtilitiesContractsInvoiceDocumentsAttributesResourceParams({
                    utilityNumber,
                    contractNumber,
                    documentNumber,
                })
            )
        );
    }
};

export const refreshApplicationPaymentsAvailable = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(
            getResource({
                resourceName: "applicationRebatesAvailable",
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            })
        );
    }
};

export const refreshApplicationPayments = ({ applicationNumber }) => {
    if (applicationNumber) {
        refreshApplicationPaymentsAvailable({ applicationNumber });

        store.dispatch(
            getResource({
                resourceName: "applicationRebatesWidget",
                key: applicationNumber,
                path: {
                    appId: applicationNumber,
                },
            })
        );
    }
};

export const refreshApplicationPayment = ({ applicationNumber, paymentNumber }) => {
    if (applicationNumber && paymentNumber) {
        store.dispatch(
            getResource({
                resourceName: "applicationRebates",
                resourceId: paymentNumber,
                path: {
                    appId: applicationNumber,
                },
            })
        );
    }
};

export const refreshProgramPortalsOfUtility = ({ utilityNumber }) => {
    getViewsByComponentName({ componentName: "PortalBuilderView" })
        .filter((v) => !isNil(v.props.programNumber) && v.props.utilityNumber === utilityNumber)
        .forEach((v) => {
            const { programNumber } = v.props;
            store.dispatch(getResource(getPortalConfigurationResourceParams({ programNumber })));
        });
};

export const refreshPortalBuilderUtilityTemplate = ({ utilityNumber, portalTemplateNumber }) => {
    store.dispatch(getResource(getPortalTemplateResourceParams({ utilityNumber, portalTemplateNumber })));
};

export const refreshApplicationWorkflowStatusTransfer = ({ applicationNumber }) => {
    if (applicationNumber) {
        store.dispatch(
            getResource({
                ...getApplicationWorkflowStatusTransferResourceParams({
                    applicationNumber,
                }),
            })
        );
    }
};

export const refreshProgramWorkflowStatusApplicationTransfer = ({ programNumber }) => {
    if (programNumber) {
        store.dispatch(
            getResource({
                ...getProgramWorkflowStatusApplicationTransferResourceParams({
                    programNumber,
                }),
            })
        );
    }
};

export const refreshCalculationProgramFormItems = ({ programNumber }) => {
    if (programNumber) {
        store.dispatch(
            getResource({
                resourceName: "calculationProgramFormItems",
                key: programNumber,
                query: {
                    programNumber,
                },
            })
        );
    }
};

export const refreshCalculationProgramFormFields = ({ programNumber }) => {
    if (programNumber) {
        store.dispatch(
            getResource({
                resourceName: "calculationProgramFormFields",
                key: programNumber,
                query: {
                    programNumber,
                },
            })
        );
    }
};

export const refreshProgramCalculationsForm = ({ programNumber }) => {
    if (programNumber) {
        getViewsByComponentName({ componentName: "ProgramView" })
            .map((view) => view.props)
            .forEach((props) => {
                if (props.programNumber === programNumber) {
                    refreshCalculationProgramFormItems({ programNumber });
                    refreshCalculationProgramFormFields({ programNumber });
                }
            });
    }
};

export const refreshApplicationAvailabelDocuments = ({ applicationNumber, isEmail }) => {
    if (applicationNumber) {
        store.dispatch(
            getResource(
                getApplicationAvailableDocumentsResourceParams({
                    applicationNumber,
                    isEmail,
                })
            )
        );
    }
};

export const refreshProgramAvailableDocuments = ({ programNumber, itemType }) => {
    if (programNumber) {
        const correspondenceOptions = getProgramAvailableDocumentsResourceParams({ programNumber });

        store.dispatch(
            getResource({
                ...correspondenceOptions,
                key: `${correspondenceOptions.key}-${itemType}`,
                query: {
                    itemType,
                },
            })
        );
    }
};

export const refreshProgramHostname = ({ programNumber }) => {
    if (programNumber) {
        store.dispatch(
            getResource({
                resourceName: "programHostname",
                key: programNumber,
                path: {
                    programNumber,
                },
            })
        );
    }
};

export const refreshDataGridData = ({ dataGridId, key, filter, page = 1, pageSize = 1000, onSuccess }) => {
    if (!store.getState().resources.grid.itemsById[key]) {
        onSuccess && onSuccess();
        return;
    }

    store.dispatch(
        getResource({
            resourceName: "grid",
            resourceId: dataGridId,
            key,
            query: {
                searchAttr: filter,
                pageNum: page,
                recsPerPage: pageSize,
            },
            onSuccess,
        })
    );
};

export const refreshApplicationAssignedContacts = ({ applicationNumber }) => {
    if (!store.getState().resources.applicationContacts.itemsById[applicationNumber]) {
        return;
    }

    store.dispatch(
        getResource({
            resourceName: "applicationContacts",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
        })
    );
};

export const refreshProgramForms = ({ programNumber }) => {
    store.dispatch(
        getResource({
            resourceName: "programForms",
            key: programNumber,
            path: {
                programNumber,
            },
        })
    );
};

export const refreshProgramFormFriendlyNames = ({ programNumber }) => {
    if (!store.getState().resources.programFormFriendlyNames.itemsById[programNumber]) {
        return;
    }

    store.dispatch(getResource(getProgramFormFriendlyNamesResourceParams({ programNumber })));
};

export const refreshCustomerContact = ({ utilityNumber, customerNumber, contactNumber }) => {
    if (!contactNumber || !store.getState().resources.customerContacts.itemsById[contactNumber]) {
        return;
    }

    store.dispatch(
        getResource(
            getCustomerContactResourceParams({
                utilityNumber,
                customerNumber,
                contactNumber,
            })
        )
    );
};

export const refreshCustomerContacts = ({ utilityNumber, customerNumber }) => {
    if (!customerNumber || !store.getState().resources.customerContacts.itemsById[customerNumber]) {
        return;
    }

    store.dispatch(getResource(getCustomerContactsResourceParams({ utilityNumber, customerNumber })));
};

export const refreshPortalTemplates = ({ utilityNumber }) => {
    store.dispatch(getResource(getPortalTemplatesResourceParams({ utilityNumber })));
};

export const refreshPortalConfiguration = ({ programNumber, onComplete }) => {
    if (!programNumber) {
        onComplete?.();
        return;
    }

    store.dispatch(
        getResource({
            ...getPortalConfigurationResourceParams({ programNumber }),
            onComplete,
        })
    );
};

export const refreshAllTabsAndGrids = () => {
    const state = store.getState();
    refreshAllGrids();

    state.window[windowContainerTypes.Root]?.views.forEach((view) => {
        let applicationNumber = "";
        let utilityNumber = "";
        let programNumber = "";
        let contractNumber = "";
        let documentNumber = "";
        let portalTemplateNumber = "";

        switch (view.component) {
            case "ProjectView":
                applicationNumber = view.props.applicationNumber;
                refreshApplication({ applicationNumber });
                break;

            case "UtilityView":
                utilityNumber = view.props.utilityNumber;
                refreshUtility({ utilityNumber });
                break;

            case "ProgramView":
                programNumber = view.props.programNumber;
                refreshProgram({ programNumber });
                break;

            case "UtilityContractView":
                contractNumber = view.props.contractNumber;
                refreshContractTab({ contractNumber });
                break;

            case "UtilityInvoiceView":
                documentNumber = view.props.documentNumber;
                refreshInvoiceDocumentTab({ documentNumber });
                break;
            case "PortalBuilderView":
                utilityNumber = view.props.utilityNumber;
                portalTemplateNumber = view.props.portalTemplateNumber;
                refreshPortalBuilderUtilityTemplate({ utilityNumber, portalTemplateNumber });
                break;

            default:
                break;
        }
    });
};
