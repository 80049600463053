export enum BorderRadiusCustomPropertyName {
    BorderRadiusType = "borderRadiusType",
    BorderRadiusAllSides = "borderRadiusAllSides",
    BorderRadiusSideBySide = "borderRadiusSideBySide",
}

export enum BorderSides {
    SideBySide = "side-by-side",
    AllSides = "all-sides",
}

export enum BorderCornerName {
    TopLeft = "top-left",
    TopRight = "top-right",
    BottomRight = "bottom-right",
    BottomLeft = "bottom-left",
    AllSides = "all-sides",
}

export enum BorderCornerSize {
    Size1 = "0.25rem",
    Size2 = "0.5rem",
    Size3 = "0.75rem",
    Size4 = "1rem",
    Size5 = "2rem",
}

export interface BorderSideProps {
    corner: BorderCornerName;
    selectedValue: string;
    onSelect: (value: string) => void;
}

export interface BorderRadiusSideBySideValue {
    [BorderCornerName.TopLeft]: string;
    [BorderCornerName.TopRight]: string;
    [BorderCornerName.BottomRight]: string;
    [BorderCornerName.BottomLeft]: string;
}

export interface BorderCustomPropertiesValue {
    [BorderRadiusCustomPropertyName.BorderRadiusType]: BorderSides;
    [BorderRadiusCustomPropertyName.BorderRadiusAllSides]: string;
    [BorderRadiusCustomPropertyName.BorderRadiusSideBySide]: BorderRadiusSideBySideValue;
}
