import React, { useCallback, useContext, useMemo, memo } from "react";
import { useResource } from "../../../../../../../store/resources/useResource";
import { WorkflowContext } from "../../../../context/WorkflowContext";
import { STEP_TYPES_KEYS, STEP_TYPES } from "../../../../constants/step-types";

import { StepPropertiesSettingsContext } from "../../../../context/StepPropertiesSettingsContext";

import WaitIcon from "../../../../../WaitIcon";
import IconWithLabel from "../../../../../Icons/IconWithLabel";
import DropDownInput from "../../../../../Input/DropDownInput";
import { DefaultForm } from "./Forms/DefaultForm";
import { CorrespondenceForm } from "./Forms/CorrespondenceForm";
import { AttributeFields } from "./Fields";
import { QcForm } from "./Forms/QcForm";
import GeneralProcedureForm from "./Forms/GeneralProcedureForm";
import { FormFieldEntryForm } from "./Forms/FormFieldEntryForm";
import { FormEntryForm } from "./Forms/FormEntryForm";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavContent from "components/ui/SideNav/SideNavContent";

import "./style.scss";
import { FormSectionEntryForm } from "./Forms/FormSectionEntryForm";

const StepTypesWidget = memo(({ value, label, onChange }) => {
    const {
        entityId,
        entityIdType,
        stepTypesGetResourceOptions,
        stepAttributeTypesGetResourceOptions,
        onChangeWorkflowStepProperties,
        existingCorrespondenceGetResourceOptions,
        existingCorrespondenceDataGetResourceOptions,
        fastTagsGetResourceOptions,
        isLocked,
    } = useContext(WorkflowContext);

    const { attributes, content } = useContext(StepPropertiesSettingsContext);

    const [workflowStepTypes = [], isLoadingWorkflowStepTypes] = useResource(stepTypesGetResourceOptions);
    // Preload attribute types
    const [, isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
    });

    const dropDownValue = useMemo(() => {
        if (value && value.hasOwnProperty("typeNumber")) {
            return value.typeNumber;
        }

        return null;
    }, [value]);

    const stepContent = useMemo(() => {
        if (dropDownValue && !isLoadingWorkflowStepTypes) {
            const stepTypeCode = workflowStepTypes.find((i) => i.number === dropDownValue)?.code;

            if (stepTypeCode && STEP_TYPES.hasOwnProperty(stepTypeCode)) {
                return STEP_TYPES[stepTypeCode];
            }
        }

        return null;
    }, [dropDownValue, workflowStepTypes, isLoadingWorkflowStepTypes]);

    const handleDropDownChange = useCallback(
        (e) =>
            onChange({
                typeNumber: e.target.value,
                typeName: e.target.label,
            }),
        [onChange]
    );

    const handleClickEdit = useCallback(() => {
        const { key, modalTitle, attributes: stepContentAttributes } = stepContent;
        let Form = null;

        switch (key) {
            case STEP_TYPES_KEYS.SEND_EMAIL:
            case STEP_TYPES_KEYS.SEND_LETTER:
                Form = CorrespondenceForm;
                break;

            case STEP_TYPES_KEYS.QC_TASK:
            case STEP_TYPES_KEYS.TEST_FOR_QC_WITH_STATUS_CHANGE:
                Form = QcForm;
                break;

            case STEP_TYPES_KEYS.GENERAL_PROCEDURE:
                Form = GeneralProcedureForm;
                break;

            case STEP_TYPES_KEYS.APPLICATION_FORM_ENTRY_TASK:
                Form = FormEntryForm;
                break;

            case STEP_TYPES_KEYS.APPLICATION_FORM_FIELD_ENTRY_TASK:
                Form = FormFieldEntryForm;
                break;

            case STEP_TYPES_KEYS.APPLICATION_FORM_SECTION_ENTRY_TASK:
                Form = FormSectionEntryForm;
                break;

            default:
                Form = DefaultForm;
        }

        if (Form) {
            handleOpenSidePanel(
                <SideNavContent>
                    <SideNavHeader title={modalTitle} leadBlockIcon="configuration" smallHeader onClose={handleCloseSidePanel} />
                    <SideNavBody>
                        <Form
                            entityId={entityId}
                            entityIdType={entityIdType}
                            modal={key}
                            attributes={attributes}
                            content={content}
                            fields={stepContentAttributes}
                            stepAttributeTypesGetResourceOptions={stepAttributeTypesGetResourceOptions}
                            existingCorrespondenceGetResourceOptions={existingCorrespondenceGetResourceOptions}
                            existingCorrespondenceDataGetResourceOptions={existingCorrespondenceDataGetResourceOptions}
                            fastTagsGetResourceOptions={fastTagsGetResourceOptions}
                            onChange={onChangeWorkflowStepProperties}
                            onCloseModal={handleCloseSidePanel}
                        />
                    </SideNavBody>
                </SideNavContent>
            );
        }
    }, [
        stepContent,
        handleOpenSidePanel,
        handleCloseSidePanel,
        entityId,
        entityIdType,
        attributes,
        content,
        stepAttributeTypesGetResourceOptions,
        existingCorrespondenceGetResourceOptions,
        existingCorrespondenceDataGetResourceOptions,
        fastTagsGetResourceOptions,
        onChangeWorkflowStepProperties,
    ]);

    if (isLoadingWorkflowStepTypes || isLoadingWorkflowStepAttributeTypes) {
        return <WaitIcon />;
    }

    return (
        <div className="workflow-step-types-widget">
            <div className="workflow-step-types-widget__wrapper">
                <DropDownInput
                    value={dropDownValue}
                    mobileHeader={label}
                    onChange={handleDropDownChange}
                    disabled={isLocked}
                    data={workflowStepTypes.map((i) => ({
                        value: i.number,
                        label: i.name,
                    }))}
                />
                {stepContent && stepContent.withModal && !isLocked && (
                    <IconWithLabel icon="configuration" onClick={handleClickEdit}>
                        Manage
                    </IconWithLabel>
                )}
            </div>
            {stepContent && stepContent.withFields && <AttributeFields stepContent={stepContent} />}
        </div>
    );
});

export default StepTypesWidget;
