import React, { useCallback, useState, useRef, memo, useEffect } from "react";

import { pickInitialValues, submitResource } from "../../../../../utils/form";
import { submitByRef } from "../../../../../utils/form";

import JsonSchemaFormWithConditionals from "../../../../Form/JsonSchema/JsonSchemaFormWithConditionals";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";

const FormsForm = memo(({ form, onRefresh, onClose, sidePanel, programNumber }) => {
    const formRef = useRef();
    const isLocked = isChildProgram({ programNumber });

    const [isSubmitting, setSubmitting] = useState(false);

    const schema = {
        type: "object",
        required: [
            "formName",
            "requireContact",
            "requirePremise",
            "requireContractor",
            "beRequireContact",
            "beRequirePremise",
            "beRequireContractor",
            "formType",
        ],
        properties: {
            formName: {
                type: "string",
                title: "Form Name",
            },
            requireContact: {
                type: "string",
                title: "Require Primary Contact",
                anyOf: contactAnyOfSchema,
            },
            requirePremise: {
                type: "string",
                title: "Require Premise Contact",
                anyOf: contactAnyOfSchema,
            },
            requireContractor: {
                type: "string",
                title: "Require Contractor Contact",
                anyOf: contractorAnyOfSchema,
            },
            beRequireContact: {
                type: "string",
                title: "B/E: Req Primary Contact",
                anyOf: contactAnyOfSchema,
            },
            beRequirePremise: {
                type: "string",
                title: "B/E: Req Premise Contact",
                anyOf: contactAnyOfSchema,
            },
            beRequireContractor: {
                type: "string",
                title: "B/E: Req Contractor Contact",
                anyOf: beContractorAnyOfSchema,
            },
            formType: {
                type: "integer",
                title: "Portal Form Type",
                anyOf: formTypeAnyOfSchema,
            },
            primaryDescription: {
                type: "string",
                title: "Primary Contact Desc",
            },
            premiseDescription: {
                type: "string",
                title: "Premise Contact Desc",
            },
            contractorDescription: {
                type: "string",
                title: "Contractor Contact Desc",
            },
        },
    };

    const uiSchema = {
        primaryDescription: {
            "ui:widget": "textarea",
        },
        premiseDescription: {
            "ui:widget": "textarea",
        },
        contractorDescription: {
            "ui:widget": "textarea",
        },
    };

    const initialValues = pickInitialValues(form);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "programForm",
                path: {
                    formNumber: form?.formNumber,
                },
            };

            const body = {
                ...formData,
            };

            submitResource({
                resourceParams,
                resourceId: form?.formNumber,
                body,
                onRefresh,
                onSuccess: sidePanel.close,
                setSubmitting,
            });
        },
        [form, onRefresh, sidePanel]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent>
            <SideNavHeader
                title={isLocked ? "View Application Form Pages" : "Edit Application Form Pages"}
                leadBlockIcon={isLocked ? "eye-visibility-empty" : "edit-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaFormWithConditionals
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    disabled={isLocked}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    noSubmit
                    noCancel
                    noReset
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton={!isLocked} justifyEnd={isLocked}>
                {!isLocked && (
                    <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                        {submitText}
                    </Button>
                )}
                <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

const contactAnyOfSchema = [
    {
        title: "Required",
        enum: ["y"],
    },
    {
        title: "Not Required",
        enum: ["n"],
    },
    {
        title: "Not Required, Hidden",
        enum: ["h"],
    },
];

const contractorAnyOfSchema = [
    {
        title: "Required",
        enum: ["y"],
    },
    {
        title: "Not Required",
        enum: ["n"],
    },
    {
        title: "Required, From Contractor List",
        enum: ["l"],
    },
    {
        title: "Not Required, From Contractor List",
        enum: ["ln"],
    },
    {
        title: "Not Required, Hidden",
        enum: ["h"],
    },
];

const beContractorAnyOfSchema = [
    {
        title: "Required",
        enum: ["y"],
    },
    {
        title: "Not Required",
        enum: ["n"],
    },
    {
        title: "Required, From Contractor List",
        enum: ["l"],
    },
    {
        title: "Not Required, From Contractor List",
        enum: ["ln"],
    },
    {
        title: "Not Required, Hidden",
        enum: ["h"],
    },
    {
        title: "Read Only",
        enum: ["r"],
    },
];

const formTypeAnyOfSchema = [
    {
        title: "Singlepoint",
        enum: [363],
    },
    {
        title: "Standard",
        enum: [362],
    },
    {
        title: "Wizard",
        enum: [592],
    },
];

export default FormsForm;
