import { uniqBy, isEqual } from "lodash";
import * as actionTypes from "../actionTypes";
import { setWindowState, emptySplitViewName, activityType, windowContainerTypes } from "components/utils/window";
import { createId } from "../../components/utils/string";
import { onActivity } from "./activity";

const SPLIT_VIEW_TITLE = " ";

export const windowSetDevice =
    ({ width }) =>
    (dispatch, getState) => {
        if (getState().window) {
            const DEVICE_MOBILE = 767.0;
            const DEVICE_TABLET = 1024.0;
            const DEVICE_TABLET_LARGE = 1280.0;
            const DEVICE_DESKTOP_SMALL = 1599.0;
            const DEVICE_CAN_SPLIT_SCREEN_VIEW = 1439.0;

            const window = getState().window;

            const device = {
                isMobile: false,
                isTablet: false,
                isTabletLarge: false,
                isDesktop: false,
                isDesktopSmall: false,
                canSplitScreenView: false,
            };

            if (width <= DEVICE_MOBILE) {
                device.isMobile = true;
            } else if (width <= DEVICE_TABLET) {
                device.isTablet = true;
            } else if (width <= DEVICE_TABLET_LARGE) {
                device.isTabletLarge = true;
            } else {
                device.isDesktop = true;

                if (width >= DEVICE_CAN_SPLIT_SCREEN_VIEW) {
                    device.canSplitScreenView = true;
                }
                if (width <= DEVICE_DESKTOP_SMALL) {
                    device.isDesktopSmall = true;
                }
            }

            if (
                window.isMobile !== device.isMobile ||
                window.isTablet !== device.isTablet ||
                window.isTabletLarge !== device.isTabletLarge ||
                window.isDesktop !== device.isDesktop ||
                window.isDesktopSmall !== device.isDesktopSmall ||
                window.canSplitScreenView !== device.canSplitScreenView
            ) {
                dispatch({
                    type: actionTypes.WINDOW_SET_DEVICE,
                    device,
                });
            }
        }
    };

export const windowContainerAdd = (name) => (dispatch, getState) => {
    if (!getState().window[name]) {
        dispatch({
            type: actionTypes.WINDOW_CONTAINER_ADD,
            window: name,
        });
    }
};

export const windowContainerRemove = (name) => (dispatch, getState) => {
    if (getState().window[name]) {
        dispatch({
            type: actionTypes.WINDOW_CONTAINER_REMOVE,
            window: name,
        });
    }
};

export const windowContainerReset = (name) => (dispatch, getState) => {
    if (getState().window[name]) {
        dispatch({
            type: actionTypes.WINDOW_CONTAINER_REMOVE,
            window: name,
        });

        dispatch({
            type: actionTypes.WINDOW_CONTAINER_ADD,
            window: name,
        });
    }
};

export const windowAdd =
    ({
        name,
        containerName,
        component,
        showTab = true,
        showHeader = true,
        close,
        title,
        header,
        activate,
        props,
        forceReplaceProps,
        isSplitView = false,
        persist = true,
        layoutType,
        hideSearch = false,
        hideSplitView = false,
        hideFullscreen = false,
    }) =>
    (dispatch, getState) => {
        let container = getState().window[containerName];

        if (!container) {
            dispatch({
                type: actionTypes.WINDOW_CONTAINER_ADD,
                window: containerName,
            });

            container = getState().window[containerName];
        }

        const existingView = container?.views?.filter((w) => w.name === name)[0];

        if (!existingView) {
            const view = {
                name,
                containerName,
                component,
                showTab,
                showHeader,
                isSplitView,
                title,
                header,
                close,
                props,
                active: false,
                layoutType,
                hideSearch,
                hideSplitView,
                hideFullscreen,
            };

            const views = [].concat(container.views, [view]);

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });

            dispatch(
                onActivity({
                    view,
                    activity: activityType.OPEN,
                })
            );
        } else {
            if (!isEqual(existingView.props, props) || forceReplaceProps) {
                const views = container.views.map((w) => {
                    if (w.name === existingView.name) {
                        return {
                            ...w,
                            props,
                        };
                    }

                    if (w.leftViewName === existingView.name) {
                        return {
                            ...w,
                            leftViewProps: props,
                        };
                    }

                    if (w.rightViewName === existingView.name) {
                        return {
                            ...w,
                            rightViewProps: props,
                        };
                    }

                    return w;
                });

                dispatch({
                    type: actionTypes.WINDOW_ADD,
                    container: containerName,
                    views,
                });
            }
        }

        if (activate) {
            dispatch(windowActivate({ name, containerName, isSplitView }));
        }

        if (persist) {
            dispatch(windowPersist());
        }
    };

// onWindowRemove - is a callback function for splitview individual close / remove
export const windowRemove =
    ({ name, containerName, force = false, onWindowRemove, shouldWindowRemain = false, persist = true }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const view = container.views.filter((w) => w.name === name)[0];
            const parentSplitView = container.views.filter((view) => view.leftViewName === name || view.rightViewName === name)[0];

            if (!view && parentSplitView) {
                const views = [];

                container.views.forEach((w) => {
                    if (w.name === parentSplitView.name) {
                        w.isSplitViewSync = false;

                        if (parentSplitView.leftViewName === name) {
                            w.leftViewTitle = SPLIT_VIEW_TITLE;
                            w.leftViewName = emptySplitViewName;
                            w.leftViewComponent = emptySplitViewName;
                            w.leftViewProps = null;
                            w.leftViewHeader = "";
                            w.leftViewOnClose = null;
                        }
                        if (parentSplitView.rightViewName === name) {
                            w.rightViewTitle = SPLIT_VIEW_TITLE;
                            w.rightViewName = emptySplitViewName;
                            w.rightViewComponent = emptySplitViewName;
                            w.rightViewProps = null;
                            w.rightViewHeader = "";
                            w.rightViewOnClose = null;
                        }

                        if (onWindowRemove) {
                            onWindowRemove();
                        }

                        if (shouldWindowRemain || w.leftViewName !== "SplitView" || w.rightViewName !== "SplitView") {
                            views.push(w);
                        }
                    } else {
                        views.push(w);
                    }
                });

                if (views.length !== container.views.length) {
                    setActiveView({ views, container });
                }

                dispatch({
                    type: actionTypes.WINDOW_REMOVE,
                    container: parentSplitView.containerName,
                    views,
                });

                dispatch(
                    onActivity({
                        parentSplitView,
                        activity: activityType.CLOSE,
                    })
                );
            } else {
                if (view.onClose && !force && !view.isSplitView) {
                    view.onClose();
                } else {
                    const views = container.views.filter((w) => w.name !== name);
                    setActiveView({ views, container });

                    dispatch({
                        type: actionTypes.WINDOW_REMOVE,
                        container: containerName,
                        views,
                    });

                    dispatch(
                        onActivity({
                            view,
                            activity: activityType.CLOSE,
                        })
                    );
                }
            }

            if (persist) {
                dispatch(windowPersist());
            }
        }
    };

export const windowActivate =
    ({ name, containerName, persist = true }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container && container.views && container.views.length !== 0) {
            const splitView = container.views.find((w) => w.isSplitView && w.active);
            const selectedView = container.views.find((w) => w.name === name);

            // Open in active splitView
            if (
                splitView &&
                selectedView &&
                !selectedView.isSplitView &&
                selectedView.close && // Window is not locked.
                (splitView.leftViewName === emptySplitViewName || splitView.rightViewName === emptySplitViewName)
            ) {
                let updatedView = {};

                if (splitView.rightViewName === emptySplitViewName) {
                    updatedView = {
                        ...splitView,
                        rightViewTitle: selectedView.title,
                        rightViewName: selectedView.name,
                        rightViewClassName: selectedView.className,
                        rightViewComponent: selectedView.component,
                        rightViewProps: {
                            ...selectedView.props,
                            sidebarExpanded: false,
                        },
                        rightViewHeader: selectedView.header,
                        rightViewOnClose: selectedView.onClose,
                    };
                }

                if (splitView.leftViewName === emptySplitViewName) {
                    updatedView = {
                        ...splitView,
                        leftViewTitle: selectedView.title,
                        leftViewName: selectedView.name,
                        leftViewClassName: selectedView.className,
                        leftViewComponent: selectedView.component,
                        leftViewProps: {
                            ...selectedView.props,
                            sidebarExpanded: false,
                        },
                        leftViewHeader: selectedView.header,
                        leftViewOnClose: selectedView.onClose,
                    };
                }

                const views = container.views.filter((w) => w.name !== splitView.name).concat([updatedView]);

                dispatch({
                    type: actionTypes.WINDOW_ADD,
                    container: containerName,
                    views,
                });
            } else {
                const views = container.views.map((i) => ({
                    ...i,
                    active: i.name === name,
                }));

                const previousActive = container.views.find((i) => i.active && i.name !== name);

                dispatch({
                    type: actionTypes.WINDOW_ACTIVATE,
                    container: containerName,
                    views,
                    previousActive,
                });
            }

            if (persist) {
                dispatch(windowPersist());
            }
        }
    };

export const windowSplit =
    ({ name, containerName }) =>
    (dispatch, getState) => {
        if (getState().window[containerName]) {
            const views = getState().window[containerName].views.map((w) => {
                if (w.name === name) {
                    w.isSplitView = true;
                    w.isSplitViewSync = false;

                    w.leftViewTitle = w.title;
                    w.leftViewName = w.name;
                    w.leftViewComponent = w.component;
                    w.leftViewProps = {
                        ...w.props,
                        sidebarExpanded: false,
                    };
                    w.leftViewHeader = w.header;
                    w.leftViewOnClose = w.onClose;
                    w.leftViewClassName = w.className;

                    w.rightViewTitle = SPLIT_VIEW_TITLE;
                    w.rightViewName = emptySplitViewName;
                    w.rightViewComponent = emptySplitViewName;
                    w.rightViewProps = null;
                    w.rightViewHeader = "";
                    w.rightViewClassName = null;

                    w.name = createId();
                }

                return w;
            });

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowRemoveSplit =
    ({ name, containerName }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            let newName = null;
            let views = container.views
                .map((w) => {
                    if (w.name === name) {
                        newName = w.leftViewName === emptySplitViewName ? w.rightViewName : w.leftViewName;
                        w.name = newName;
                        w.active = true;

                        w.isSplitView = false;
                        w.isSplitViewSync = false;

                        w.leftViewTitle = SPLIT_VIEW_TITLE;
                        w.leftViewName = null;
                        w.leftViewComponent = null;
                        w.leftViewProps = null;
                        w.leftViewHeader = null;
                        w.leftViewClassName = null;

                        w.rightViewTitle = SPLIT_VIEW_TITLE;
                        w.rightViewName = null;
                        w.rightViewComponent = null;
                        w.rightViewProps = null;
                        w.rightViewHeader = null;
                        w.rightViewClassName = null;
                    }

                    return w;
                })
                .map((w) => ({
                    ...w,
                    active: w.name === newName,
                }))
                .filter((w) => w.name !== emptySplitViewName);

            views = uniqBy(views, "name");

            if (!views.some((w) => w.active)) {
                setActiveView({ views, container });
            }

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowClearSplit =
    ({ name, containerName, side }) =>
    (dispatch, getState) => {
        if (getState().window[containerName]) {
            const views = getState().window[containerName].views.map((w) => {
                if (w.name === name) {
                    w.isSplitViewSync = false;

                    if (side === "left") {
                        w.leftViewTitle = SPLIT_VIEW_TITLE;
                        w.leftViewName = emptySplitViewName;
                        w.leftViewComponent = emptySplitViewName;
                        w.leftViewProps = null;
                        w.leftViewHeader = "";
                        w.leftViewClassName = null;
                    } else {
                        w.rightViewTitle = SPLIT_VIEW_TITLE;
                        w.rightViewName = emptySplitViewName;
                        w.rightViewComponent = emptySplitViewName;
                        w.rightViewProps = null;
                        w.rightViewHeader = "";
                        w.rightViewClassName = null;
                    }
                }

                return w;
            });

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowSync =
    ({ name, containerName }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const views = container.views.map((w) => {
                if (w.name === name && w.isSplitView) {
                    w.isSplitViewSync = !w.isSplitViewSync;
                }

                return w;
            });

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowPersist = () => (dispatch, getState) => {
    setWindowState(getState().window);
};

export const windowSetLayoutType =
    ({ containerName, name, layoutType }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const views = container.views.slice();
            const viewIndex = views.findIndex((w) => w.name === name);

            if (viewIndex > -1) {
                views[viewIndex].layoutType = layoutType;
            }

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowChange =
    ({ containerName, oldViewName, newViewName, viewProps }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const views = container.views.slice();
            const viewIndex = views.findIndex((w) => w.name === oldViewName);

            if (viewIndex > -1) {
                views[viewIndex].name = newViewName;
                views[viewIndex].props = viewProps;

                dispatch({
                    type: actionTypes.WINDOW_ADD,
                    container: containerName,
                    views,
                });
            }
        }
    };

export const windowSetHeaderDetailsExpanded =
    ({ containerName, name, isHeaderDetailsExpanded }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const views = container.views.slice();
            const viewIndex = views.findIndex((w) => w.name === name);

            if (viewIndex > -1) {
                views[viewIndex].isHeaderDetailsExpanded = isHeaderDetailsExpanded;
            }

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowSetClassName =
    ({ containerName, name, className }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            let views = container.views.slice();

            const main = views.filter((i) => i.name === name);
            const left = views.filter((i) => i.isSplitView && i.leftViewName === name);
            const right = views.filter((i) => i.isSplitView && i.rightViewName === name);

            if (main && main.length !== 0) {
                main.forEach((i) => {
                    const index = views.findIndex((w) => w.name === i.name);

                    if (index > -1) {
                        views[index].className = className;
                    }
                });
            }

            if (left && left.length !== 0) {
                left.forEach((i) => {
                    const index = views.findIndex((w) => w.name === i.name);

                    if (index > -1) {
                        views[index].leftViewClassName = className;
                    }
                });
            }

            if (right && right.length !== 0) {
                right.forEach((i) => {
                    const index = views.findIndex((w) => w.name === i.name);

                    if (index > -1) {
                        views[index].rightViewClassName = className;
                    }
                });
            }

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

export const windowSetOnClose =
    ({ containerName, name, onClose }) =>
    (dispatch, getState) => {
        const container = getState().window[containerName];

        if (container) {
            const views = container.views.slice();
            const viewIndex = views.findIndex((w) => w.name === name);

            if (viewIndex > -1) {
                views[viewIndex].onClose = onClose;
            }

            dispatch({
                type: actionTypes.WINDOW_ADD,
                container: containerName,
                views,
            });
        }
    };

const setActiveView = ({ views, container }) => {
    const previousActive = container.previousActive;

    if (previousActive && views.filter((w) => w.name === previousActive.name).length > 0) {
        views.forEach((w) => {
            w.active = w.name === previousActive.name;
            return w;
        });
    } else {
        views.forEach((w, index) => {
            w.active = index === 0;
            return w;
        });
    }
};

export const windowSetNotificationVisible =
    ({ containerName, viewName, isNotificationVisible }) =>
    (dispatch, getState) => {
        const container = containerName ?? windowContainerTypes.Root;
        const currentState = getState().window[container]?.isNotificationVisible?.[viewName] ?? false;

        if (currentState !== isNotificationVisible) {
            dispatch({
                type: actionTypes.WINDOW_SET_NOTIFICATION_VISIBLE,
                container,
                viewName,
                isNotificationVisible,
            });
        }
    };
