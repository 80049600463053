import React, { memo, useCallback } from "react";
import { get } from "lodash";

import { programsDocumentsGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import DocumentsGrid from "./DocumentsGrid";
import CorrespondenceForm from "./CorrespondenceForm";
import Controls from "../../Controls";
import { isChildProgram } from "components/views/ProgramView/utils";

const DocumentsPanel = memo(({ panel, onToggleTempPanel }) => {
    const programNumber = get(panel, "data.programNumber");
    const gridId = `${programNumber}-documents`;
    const isLocked = isChildProgram({ programNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
        className: "correspondence-add-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<CorrespondenceForm onClose={handleCloseSidePanel} programNumber={programNumber} gridId={gridId} />);
    }, [programNumber, gridId, handleOpenSidePanel, handleCloseSidePanel]);

    const controlItems = [
        {
            position: "right",
            title: "Add Correspondence",
            icon: "plus",
            disabled: isLocked,
            onClick: handleCreate,
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: gridId,
                    fileName: "program_documetns",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="panel program-documents-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            <DocumentsGrid
                programNumber={programNumber}
                gridId={gridId}
                filter={`${programsDocumentsGridColumnKeys.programNumber}=${programNumber}`}
                sort={[
                    {
                        field: programsDocumentsGridColumnKeys.name,
                        dir: "asc",
                    },
                ]}
                onToggleTempPanel={onToggleTempPanel}
            />
        </div>
    );
});

export default DocumentsPanel;
