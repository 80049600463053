import React, { useState, useCallback, memo, useContext, useRef } from "react";

import NumberInput from "../../../../../ui/Input/NumericInput";
import Button from "../../../../../ui/Button";
import ContentWrapPopup from "../../../../../ui/ContentWrapPopup";
import Checkbox from "../../../../../ui/Input/Checkbox";
import { DocumentQueueGridContext } from "../../../context/DocumentQueueGridContext";

import "./BatchCheckbox.scss";
import useOnClickOutside from "components/utils/useOnClickOutside";

export const BatchCheckbox = memo(({ isSelectionDisabled, isBatchFilled, batchSize, onChangeBatchSize, onChangeCommonBatch }) => {
    const { selectedProgramDocumentNumbers, isBatchProcessing } = useContext(DocumentQueueGridContext);
    const [size, setSize] = useState(batchSize);
    const [isBatchSizeTooltipVisible, setBatchSizeTooltipVisibility] = useState(false);
    const popupRef = useRef();

    useOnClickOutside(popupRef, () => {
        if (isBatchSizeTooltipVisible) {
            handleCloseBatchSizeTooltip();
        }
    });

    const handleCloseBatchSizeTooltip = useCallback(() => setBatchSizeTooltipVisibility(false), []);

    const handleToggleBatchSizeTooltipVisibility = useCallback(() => {
        if (!isSelectionDisabled && !isBatchSizeTooltipVisible) {
            setBatchSizeTooltipVisibility(true);
        } else {
            setBatchSizeTooltipVisibility(false);
        }
    }, [isSelectionDisabled, isBatchSizeTooltipVisible]);

    const handleChangeSize = useCallback((e) => setSize(e.target.value), []);

    const handleClickSaveBatchSize = useCallback(() => {
        if (size >= 0 && size <= 2000) {
            setBatchSizeTooltipVisibility(false);
            onChangeBatchSize(size);
        }
    }, [size, onChangeBatchSize]);

    const renderButtons = () => {
        return (
            <>
                <Button primary disabled={size < 0 || size > 2000} onClick={handleClickSaveBatchSize}>
                    Save
                </Button>
                <Button onClick={handleCloseBatchSizeTooltip}>Cancel</Button>
            </>
        );
    };

    return (
        <div className="application-document-queue__dq-grid-filters-item-batch">
            <Checkbox
                labelIconBig
                iconLabelEmpty
                checked={selectedProgramDocumentNumbers.length}
                title={selectedProgramDocumentNumbers.length ? "Unselect all" : "Select all (max " + batchSize + ")"}
                checkedPartly={selectedProgramDocumentNumbers.length < batchSize}
                labelIcon={selectedProgramDocumentNumbers.length < batchSize ? "reader-mode-empty" : ""}
                checkedDisabled={isSelectionDisabled || isBatchProcessing}
                disabled={isSelectionDisabled || isBatchProcessing}
                onChange={onChangeCommonBatch}
            ></Checkbox>
            <div className="application-document-queue__dq-grid-filters-item-batch-size" onClick={handleToggleBatchSizeTooltipVisibility}>
                Batch size: {batchSize}
            </div>
            {isBatchSizeTooltipVisible && (
                <>
                    <ContentWrapPopup
                        elementRef={popupRef}
                        mobileHeaderText="Set Max Batch Size"
                        mobileHeaderOnClick={handleCloseBatchSizeTooltip}
                        contentWrapPopupButtons={renderButtons()}
                    >
                        <div className="flex-row">
                            <NumberInput value={size} min={0} max={2000} onChange={handleChangeSize} />
                            <div className="batch-tooltip-label">Number of documents per batch (maximum)</div>
                        </div>
                    </ContentWrapPopup>
                    <div className="content-wrap-backdrop"></div>
                </>
            )}
        </div>
    );
});
