import NumericInput from "components/ui/Input/NumericInput";
import { isNil } from "lodash";
import React, { memo, useCallback, useEffect, useRef } from "react";
import { useShowError } from "../../utils";
import PropertyErrors from "./PropertyErrors";
import { PropertyTitle } from "./PropertyTitle";
import { PropertyListItem } from "../Property/PropertyListItem";

export const NumberProperty = memo((props) => {
    const {
        id,
        title,
        minValue,
        maxValue,
        value,
        onChange,
        defaultValue,
        errors,
        required,
        listContainsActiveError,
        borderTop,
        borderBottom,
    } = props;

    const onPropertyChange = useCallback(
        (event) => {
            onChange(id, event.target.value);
        },
        [id, onChange]
    );

    const elementRef = useRef(null);

    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    useEffect(() => {
        if (isNil(value) && !isNil(defaultValue)) {
            onChange(id, defaultValue);
        }
    }, [id, defaultValue, onChange, value]);

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem ref={elementRef} className="property-list-item--number" borderTop={borderTop} borderBottom={borderBottom}>
            <div className="property-list-item-inner align-center flex-row justify-space-between">
                <PropertyTitle title={title} required={required}></PropertyTitle>
                <NumericInput
                    error={errors.length > 0}
                    lightUpError={lightUpError}
                    value={value ?? ""}
                    min={minValue}
                    max={maxValue}
                    onChange={onPropertyChange}
                />
            </div>
            <PropertyErrors errors={errors} />
        </PropertyListItem>
    );
});
