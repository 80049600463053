import React, { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { modalOpen, modalClose } from "../../../../../../../store/modal/actions";
import { utilitiesFastTagsGridColumnKeys } from "../../../../../../views/configureGrids";
import { uploadFile } from "../../../../../../../store/files/actions";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";
import { isLockedText } from "../../../../../../views/ProgramView/utils";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../SideNav/SideNavRoot";
import { hasPermission } from "components/utils/user";

import Controls from "../../../Controls";
import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import FastTagForm from "./FastTagForm";
import FastTagsGrid from "./FastTagsGrid";
import FastTagUploadImage from "./FastTagUploadImage";

import "../../../ConfigFastTagsPanel.scss";

const UtilityFastTagsPanel = memo(({ panel, onToggleTempPanel }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const utilityNumber = panel?.data?.utilityNumber;

    const gridId = `${utilityNumber}-fasttags-grid`;
    const isLocked = false;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<FastTagForm utilityNumber={utilityNumber} gridId={gridId} onClose={handleCloseSidePanel} />);
    }, [utilityNumber, gridId, handleOpenSidePanel, handleCloseSidePanel]);

    const closeModal = useCallback(() => {
        dispatch(modalClose());
    }, [dispatch]);

    const onUploadSuccess = useCallback(
        (action) => {
            const fileUrl = action && action.data && action.data.fileUrl;
            const initialValue = {
                tagValue: `<img src='${fileUrl}' border='0'>`,
            };
            closeModal();
            handleOpenSidePanel(
                <FastTagForm
                    tagForImage={true}
                    imageTagValue={initialValue}
                    utilityNumber={utilityNumber}
                    gridId={gridId}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [utilityNumber, gridId, handleOpenSidePanel, handleCloseSidePanel, closeModal]
    );

    const submitUploadImage = (formData) => {
        dispatch(
            uploadFile({
                fileData: {
                    ...formData,
                    entityTypeId: 926,
                    entityId: utilityNumber,
                    fileTypeId: 27,
                    itemFilterId: 149,
                },
                onUploadSuccess,
            })
        );
    };

    const toggleUploadImage = () => {
        if (hasPermission(user, "VMS")) {
            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: "Upload New FastTag Image",
                        className: "fast-tag-upload-modal modal-sm",
                        overlayClassName: "modal-styled",
                        modalIcon: "upload",
                        children: <FastTagUploadImage onSubmit={submitUploadImage} onClose={closeModal} />,
                        noFooter: true,
                    },
                })
            );
        } else {
            const text = (
                <>
                    <strong>Security Alert</strong>
                    <p>
                        Unfortunately, your user account security allowances do not allow you to perform this action. If you feel you have
                        received this message in error, contact your Security Administrator for further information.
                    </p>
                </>
            );

            dispatch(
                modalOpen({
                    modalType: "SIMPLE_DIALOG",
                    modalProps: {
                        text: text,
                        textAlignLeft: true,
                        errorModal: true,
                    },
                })
            );
        }
    };

    const controlItems = [
        {
            position: "right",
            title: "Add FastTag",
            icon: "plus",
            disabled: isLocked,
            tooltip: isLocked ? isLockedText : undefined,
            onClick: handleCreate,
        },
        {
            position: "right",
            title: "Upload Image",
            icon: "upload",
            disabled: isLocked,
            tooltip: isLocked ? isLockedText : undefined,
            onClick: toggleUploadImage,
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: gridId,
                    fileName: "utility_fasttags",
                    fileNamePostfix: utilityNumber,
                }),
        },
    ];

    return (
        <div className="panel fast-tags-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="FastTags" onClick={() => onToggleTempPanel()} />
            <Controls items={controlItems} />
            <FastTagsGrid
                utilityNumber={utilityNumber}
                gridId={gridId}
                filter={`${utilitiesFastTagsGridColumnKeys.utilityNumber}=${utilityNumber}`}
            />
        </div>
    );
});

export default UtilityFastTagsPanel;
