import React, { useState, useRef, useCallback, memo, useEffect } from "react";
import { isNil } from "lodash";

import { formatJsonDateTime } from "../../../../utils/date";
import { getTargetTableId, transformTestInputs, calculationTypes, isProgramCalculation } from "./utils";
import { submitByRef, submitByRefPromise, submitResource } from "../../../../utils/form";
import { refreshCalculationTestInputs } from "../../../../../store/resources/refreshResource";
import SideNavHeader from "../../../SideNav/SideNavHeader";

import CalculationDetails from "./CalculationDetails";
import CalculationHeaderForm from "./CalculationHeaderForm";
import FooterActions from "./FooterActions";
import { createResourcePromise, getResourcePromise } from "../../../../../store/resources/useResource";
import { getCalculationTestInputsResourceParams } from "../../../../../store/configureResources";
import {
    refreshCatalogCalculationsGrid,
    refreshEventCalculationsGrid,
    refreshProgramCalculationsGrid,
} from "../../../../../store/dataGrid/refreshGrid";
import useUnmounted from "components/utils/useUnmounted";

import "./CalculationEditForm.scss";

const CalculationEditForm = memo(
    ({
        utilityNumber,
        programNumber,
        calculationType,
        user,
        revisionDate,
        targetAttrId,
        calculation,
        onShowRevisions,
        onShowAssociations,
        onClose,
        sidePanel,
        disabled,
    }) => {
        const headerFormRef = useRef();
        const unmounted = useUnmounted();

        const isNew = !Boolean(calculation);

        const initialValues = {
            header: {
                calculationType,
                user,
                revisionDate: formatJsonDateTime(revisionDate),
            },
            values: {
                calculationName: calculation ? calculation.calculationName : undefined,
                processQueue: calculation ? calculation.processQueue : undefined,
                targetAttrId: !isNil(targetAttrId) ? `${targetAttrId}` : undefined,
            },
        };

        const [headerForm, setHeaderForm] = useState(initialValues);

        const [calculationStr, setCalculationStr] = useState(calculation ? calculation.calculationStr : "");
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [isCalculationStrEmpty, setIsCalculationStrEmpty] = useState(false);
        const [testInputs, setTestInputs] = useState([]);
        const [isLoadingTestInputs, setIsLoadingTestInputs] = useState(false);

        const lastCalculationStr = useRef(calculationStr);
        const [isCalculationChanged, setIsCalculationChanged] = useState(false);

        const onCalculationStrChange = useCallback((value) => {
            setIsCalculationStrEmpty(false);
            setCalculationStr(value);
        }, []);

        const onSubmit = () => {
            submitByRef(headerFormRef, (errors, formData) => {
                if ((calculationStr || "").length === 0) {
                    setIsCalculationStrEmpty(true);
                    return;
                }
                let errorFlag = false;
                if (headerFormRef.current && headerFormRef.current.props.formData.values.calculationName === undefined) {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_calculationName", "is a required property");
                    errorFlag = true;
                } else {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_calculationName", undefined);
                }
                if (headerFormRef.current && headerFormRef.current.props.formData.values.processQueue === undefined) {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_processQueue", "is a required property");
                    errorFlag = true;
                } else {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_processQueue", undefined);
                }

                if (headerFormRef.current && headerFormRef.current.props.formData.values.targetAttrId === undefined) {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_targetAttrId", "is a required property");
                    errorFlag = true;
                } else {
                    headerFormRef.current.props.formContext.setExtraError("calculation_values_targetAttrId", undefined);
                }

                if (errors) {
                    return;
                }
                if (errorFlag) {
                    return;
                }
                const { calculationName, processQueue, targetAttrId } = headerForm.values;
                const targetTableId = getTargetTableId(calculationType, targetAttrId);

                const resourceParams = {
                    resourceName: "calculations",
                };

                const resourceId = isNew ? null : calculation.calculationNumber;

                const body = {
                    utilityNumber,
                    programNumber,
                    calculationName,
                    calculationStr,
                    targetTableId,
                    targetAttrId: Number(targetAttrId),
                    processQueue,
                };

                submitResource({
                    resourceParams,
                    resourceId,
                    body,
                    onSuccess: sidePanel.close,
                    onRefresh: () => {
                        refreshCalculationTestInputs({
                            calculationNumber: resourceId,
                        });
                        calculationType === calculationTypes.event && refreshEventCalculationsGrid({ utilityNumber });
                        calculationType === calculationTypes.catalog && refreshCatalogCalculationsGrid({ utilityNumber });
                        calculationType === calculationTypes.program &&
                            refreshProgramCalculationsGrid({
                                utilityNumber,
                                programNumber,
                            });
                    },
                    setSubmitting: setIsSubmitting,
                });
            });
        };

        const isCalculationStrValid = useCallback(() => {
            if ((calculationStr || "").length === 0) {
                setIsCalculationStrEmpty(true);
                return false;
            }

            return true;
        }, [calculationStr]);

        const onGetTestInputs = useCallback(() => {
            submitByRef(headerFormRef, async (errors, formData) => {
                if (!isCalculationStrValid()) {
                    return;
                }

                if (errors) {
                    return;
                }

                try {
                    setIsLoadingTestInputs(true);
                    setIsCalculationChanged(false);
                    const { targetAttrId } = headerForm.values;
                    const targetTableId = getTargetTableId(calculationType, targetAttrId);

                    const body = {
                        utilityNumber,
                        tableId: Number(targetTableId),
                        columnId: Number(targetAttrId),
                        calculationStr,
                    };

                    const inputs = await createResourcePromise({
                        resourceName: "newCalculationTestInputs",
                        body,
                    });

                    setTestInputs(transformTestInputs(inputs));
                    setIsLoadingTestInputs(false);
                } catch {
                    setIsLoadingTestInputs(false);
                    setIsCalculationChanged(true);
                }
            });
        }, [utilityNumber, calculationStr, calculationType, headerForm.values, isCalculationStrValid]);

        const onCalculate = useCallback(
            ({ inputs }) => {
                return submitByRefPromise(headerFormRef)
                    .then(() => {
                        if (!isCalculationStrValid()) {
                            return;
                        }

                        const { targetAttrId } = headerForm.values;
                        const targetTableId = getTargetTableId(calculationType, targetAttrId);

                        const body = {
                            inputs,
                            utilityNumber,
                            tableId: Number(targetTableId),
                            columnId: Number(targetAttrId),
                            calculationStr,
                        };

                        return createResourcePromise({
                            resourceName: "newCalculationTest",
                            body,
                            showSuccessNotification: false,
                        });
                    })
                    .catch(isCalculationStrValid);
            },
            [utilityNumber, calculationStr, calculationType, headerForm.values, isCalculationStrValid]
        );

        useEffect(() => {
            if (lastCalculationStr.current !== calculationStr) {
                setIsCalculationChanged(() => {
                    if (lastCalculationStr.current !== calculationStr) {
                        lastCalculationStr.current = calculationStr;
                        return true;
                    }

                    return false;
                });
            }
        }, [calculationStr]);

        useEffect(() => {
            const calculationNumber = calculation?.calculationNumber;

            if (calculationNumber) {
                setIsLoadingTestInputs(true);

                getResourcePromise(
                    getCalculationTestInputsResourceParams({
                        calculationNumber,
                    })
                ).then((inputs) => {
                    if (!unmounted.current) {
                        setTestInputs(transformTestInputs(inputs));
                        setIsLoadingTestInputs(false);
                    }
                });
            }
        }, [calculation, unmounted]);

        return (
            <div className="calculation-edit-form flex-column fill-height no-scroll fill-width">
                <div className="flex-row flex-one-in-column no-scroll">
                    <SideNavHeader
                        title={isNew ? "Create New Calculation" : disabled ? "View Calculation" : "Edit Calculation"}
                        leadBlockIcon={isNew ? "plus" : disabled ? "eye-visibility-empty" : "edit-empty"}
                        sidenavHeaderLeftAligned
                        onClose={onClose}
                    >
                        <CalculationHeaderForm
                            disabled={disabled}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            formRef={headerFormRef}
                            initialValues={initialValues}
                            values={headerForm}
                            onChange={setHeaderForm}
                            isSubmitting={isSubmitting}
                            isNew={isNew}
                            onShowRevisions={onShowRevisions}
                            isProgramCalculation={isProgramCalculation({
                                calculationType,
                            })}
                            onShowAssociations={onShowAssociations}
                            sidePanel={sidePanel}
                        />
                    </SideNavHeader>
                    <CalculationDetails
                        disabled={disabled}
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        calculationType={calculationType}
                        targetAttrId={headerForm.values.targetAttrId}
                        calculationStr={calculationStr}
                        calculation={calculation}
                        onChange={onCalculationStrChange}
                        viewOnly={isSubmitting}
                        error={isCalculationStrEmpty ? "Calculation Code is Required" : undefined}
                        onGetTestInputs={onGetTestInputs}
                        onCalculate={onCalculate}
                        testInputs={testInputs}
                        isLoadingTestInputs={isLoadingTestInputs}
                        isCalculationChanged={isCalculationChanged}
                        headerFormRef={headerFormRef}
                    />
                </div>
                <FooterActions
                    disabled={disabled}
                    programNumber={programNumber}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    isNew={isNew}
                    isSubmitting={isSubmitting}
                />
            </div>
        );
    }
);

export default CalculationEditForm;
