import React, { useEffect, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { construct, setFilter, getData } from "../../../../../../store/dataGrid/actions";
import { useResource } from "../../../../../../store/resources/useResource";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import { WarningMessage } from "../../../../Message";

const gridInstancePrefix = "approved-equipment-";

const ApprovedEquipmentSearchResults = memo(
    ({
        industryMeasureNumber,
        refId,
        manufacturer,
        model,
        applicationNumber,
        actionTitle,
        onSelectResult,
        gridConfig,
        activeFilter,
        gridInstanceId,
        onShowApprovedEquipmentDetails,
    }) => {
        const dispatch = useDispatch();

        const dataGridInstanceId = gridInstanceId ?? gridInstancePrefix + industryMeasureNumber;

        const customRowActions = [
            {
                name: "view",
                icon: "eye-visibility-empty",
                title: "View Equipment",
            },
            {
                name: "add",
                icon: "plus",
                title: actionTitle ?? "Add Approved Equipment",
            },
        ];

        const [dataGridId, isLoading] = useResource({
            resourceName: "approvedEquipmentGrid",
            key: industryMeasureNumber,
            path: {
                appId: applicationNumber,
            },
            query: {
                industryMeasureNumber,
            },
            transform: (data) => {
                return data?.[0]?.gridId;
            },
        });

        const dataGridConfig = useSelector((state) => state.dataGrid[dataGridInstanceId]);

        const getFilter = useCallback(() => {
            let approvedEquipmentFilters = [];

            if (refId) {
                approvedEquipmentFilters.push({ field: "refId", value: refId });
            }

            if (manufacturer) {
                approvedEquipmentFilters.push({ field: "manf", value: manufacturer });
            }

            if (model) {
                approvedEquipmentFilters.push({ field: "modelnum", value: model });
            }

            return { filters: approvedEquipmentFilters, logic: "and" };
        }, [manufacturer, model, refId]);

        useEffect(() => {
            if (dataGridId && !dataGridConfig) {
                dispatch(
                    construct({
                        dataGridId,
                        dataGridInstanceId,
                    })
                );
            }
        }, [dataGridId, dataGridConfig, dataGridInstanceId, getFilter, dispatch]);

        useEffect(() => {
            if (dataGridConfig && !dataGridConfig.isConstructing && !isEqual(dataGridConfig.filter, getFilter())) {
                dispatch(
                    setFilter({
                        dataGridId: dataGridInstanceId,
                        filter: getFilter(),
                    })
                );

                dispatch(
                    getData({
                        dataGridId: dataGridInstanceId,
                    })
                );
            }
        }, [dataGridConfig, dataGridInstanceId, getFilter, dispatch]);

        const getRowId = useCallback(
            ({ dataItem }) => {
                if (dataGridConfig && dataGridConfig.columns) {
                    return dataItem[(dataGridConfig.columns.filter((c) => c.name === "rowid")[0] || {}).key];
                }
            },
            [dataGridConfig]
        );

        const onRowAction = useCallback(
            (action) => {
                const itemId = getRowId(action);
                const dataItem = action.dataItem;

                switch (action.name) {
                    case "add":
                        onSelectResult({
                            itemId,
                            industryMeasureNumber,
                            dataItem,
                        });
                        break;
                    case "view":
                        onShowApprovedEquipmentDetails({
                            ...action,
                            itemId,
                            industryMeasureNumber,
                            onAdd: () =>
                                onSelectResult({
                                    itemId,
                                    industryMeasureNumber,
                                    dataItem,
                                }),
                        });
                        break;
                    default:
                        break;
                }
            },
            [onSelectResult, getRowId, industryMeasureNumber, onShowApprovedEquipmentDetails]
        );

        if (isLoading) {
            return <WaitIcon />;
        }

        if (!dataGridConfig) {
            return (
                <div>
                    <WarningMessage>There is no approved equipment found.</WarningMessage>
                </div>
            );
        }

        return (
            <div className="approved-equipment-search-results flex-column flex-one-in-column no-scroll">
                <div className="flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: dataGridInstanceId,
                                fileName: "approved_equipment",
                                fileNamePostfix: applicationNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid
                    name={dataGridInstanceId}
                    config={gridConfig ?? dataGridConfig}
                    customRowActions={customRowActions}
                    onRowAction={onRowAction}
                    noFilter
                    limit={20}
                />
            </div>
        );
    }
);

export default ApprovedEquipmentSearchResults;
