import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

import { optimisticUpdateItem } from "../../../../../../store/resources/actions";
import { programsDocumentsGridColumnKeys, programsDocumentsRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { getData } from "../../../../../../store/dataGrid/actions";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useFastTags, useResource } from "../../../../../../store/resources/useResource";
import {
    refreshProgramAvailableDocuments,
    refreshApplicationAvailabelDocumentsInOpenTabs,
} from "../../../../../../store/resources/refreshResource";
import { submitResource, submitByRef } from "../../../../../utils/form";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";
import WaitIcon from "components/ui/WaitIcon";

const PreloadCorrespondenceForm = memo((props) => {
    const { dataItem, programNumber, revisionNumber } = props;

    const isNew = dataItem == null;

    const resourceNumber = isNew
        ? undefined
        : revisionNumber
        ? dataItem[programsDocumentsRevisionsGridColumnKeys.correspondenceVersionNumber]
        : dataItem[programsDocumentsGridColumnKeys.documentNumber];

    const [correspondenceTypes = [], isLoadingCorrespondenceTypes] = useReference(referenceTypes.correspondenceType);
    const [fastTags, isLoadingFastTags] = useFastTags({ entityNumber: programNumber, entityType: "program" });

    const [resource, isLoadingResource] = useResource({
        resourceName: "programDocuments",
        resourceId: resourceNumber,
        forced: true,
        path: {
            programNumber,
        },
    });

    const isLoading = isLoadingCorrespondenceTypes || isLoadingFastTags || isLoadingResource;

    if (isLoading) {
        return <WaitIcon />;
    }

    return <CorrespondenceForm {...props} resource={resource} correspondenceTypes={correspondenceTypes} fastTags={fastTags} />;
});

const CorrespondenceForm = memo(
    ({ dataItem, programNumber, resource, correspondenceTypes, fastTags, revisionNumber, gridId, onClose, sidePanel }) => {
        const dispatch = useDispatch();
        const isLocked = isChildProgram({ programNumber });

        const isNew = dataItem == null;

        const documentNumber = isNew
            ? undefined
            : revisionNumber
            ? dataItem[programsDocumentsRevisionsGridColumnKeys.documentNumber]
            : dataItem[programsDocumentsGridColumnKeys.documentNumber];

        const correspondenceTypeList =
            (correspondenceTypes &&
                correspondenceTypes.map((i) => ({
                    title: i.display,
                    enum: [+i.val],
                }))) ||
            [];

        const [isSubmitting, setSubmitting] = useState(false);
        const initialValues = resource ?? {};

        const formRef = useRef();

        const handleSave = useCallback(() => {
            submitByRef(formRef);
        }, []);

        const schema = {
            type: "object",
            required: ["item_Type", "name", "content"],
            properties: {
                item_Type: {
                    type: "integer",
                    title: "Type",
                    anyOf: correspondenceTypeList,
                },
                name: {
                    type: "string",
                    title: "Name",
                },
                content: {
                    type: "string",
                    title: "Content",
                },
            },
        };

        const uiSchema = {
            classNames: "inline-form columns-2",
            item_Type: {
                classNames: "program-correspondence-dropdown",
                "ui:options": {
                    placeholder: "-- SELECT --",
                },
            },
            content: {
                classNames: "fill-width",
                "ui:widget": "HtmlEditorWidget",
                "ui:options": {
                    fastTags,
                },
            },
        };

        const onSubmit = (formData) => {
            const resourceParams = {
                resourceName: "programDocuments",
                path: {
                    programNumber,
                },
            };

            const body = {
                ...formData,
            };

            submitResource({
                resourceParams,
                resourceId: documentNumber,
                body,
                onRefresh: () => {
                    // If not new a document then refresh the document in store
                    if (documentNumber) {
                        dispatch(
                            optimisticUpdateItem({
                                resourceName: resourceParams.resourceName,
                                resourceId: documentNumber,
                                value: body,
                            })
                        );
                    }

                    // Refresh available documents list
                    refreshProgramAvailableDocuments({
                        programNumber,
                        itemType: formData.item_Type,
                    });

                    // Refresh document lists in open applications
                    refreshApplicationAvailabelDocumentsInOpenTabs({
                        programNumber,
                        isEmail: formData.item_Type === 39,
                    });

                    // refresh grid
                    dispatch(getData({ dataGridId: gridId }));
                },
                onSuccess: sidePanel ? sidePanel.close : onClose,
                setSubmitting,
            });
        };

        const getSubmitText = isSubmitting ? "Saving..." : "Save";

        useEffect(() => {
            if (sidePanel) {
                sidePanel.setForm(formRef);
            }
        }, [sidePanel]);

        return (
            <SideNavContent>
                <SideNavHeader
                    title={isNew ? "Add Correspondence" : isLocked ? "View Correspondence" : "Edit Correspondence"}
                    leadBlockIcon={isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty"}
                    smallHeader
                    onClose={onClose}
                />
                <SideNavBody className="flex-one-in-column">
                    <div className="category-form">
                        <JsonSchemaForm
                            formRef={formRef}
                            schema={schema}
                            uiSchema={uiSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            onCancel={onClose}
                            submitText={getSubmitText}
                            disabled={isSubmitting || isLocked}
                            noActions
                        />
                    </div>
                </SideNavBody>
                <SideNavFooter justifyEnd={isLocked} justifyCenter={!isLocked}>
                    {!isLocked && (
                        <Button primary onClick={handleSave} disabled={isSubmitting}>
                            {getSubmitText}
                        </Button>
                    )}
                    <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
                </SideNavFooter>
            </SideNavContent>
        );
    }
);

export default PreloadCorrespondenceForm;
