import React, { useState } from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { PropertyListItem } from "./PropertyListItem";

export const PropertiesGroup = (props) => {
    const {
        title,
        propertiesGroup = [],
        value = [],
        collapsible = true,
        nestingLevel,
        borderTop = false,
        borderBottom = true,
        onChange,
    } = props;

    const [isExpanded, setIsExpanded] = useState(!collapsible);
    const onExpand = collapsible ? () => setIsExpanded((prev) => !prev) : undefined;

    return (
        <PropertyListItem className="property-list-item--prop-group" borderTop={borderTop} borderBottom={borderBottom}>
            <PropertyListItemTitle title={title} isExpanded={isExpanded} onExpand={onExpand} />
            <PropertyList
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={isExpanded}
            />
        </PropertyListItem>
    );
};
