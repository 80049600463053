import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import ReactModal from "react-modal";
import { modalClose } from "../../../store/modal/actions";
import IconWrap from "../Icons";
import WaitIcon from "../WaitIcon";

import "./style.scss";

export const WaitingModal = ({ title, modalIcon, className }) => {
    const dispatch = useDispatch();

    return (
        <ReactModal
            isOpen
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => dispatch(modalClose())}
            overlayClassName="modal-styled"
            className={cn("modal simple-modal modal-sm modal-no-footer", className)}
        >
            <div className="modal-content">
                {title && (
                    <div className={cn("modal-header no-shrink")}>
                        {modalIcon && <IconWrap icon={modalIcon} />}
                        {title}
                    </div>
                )}
                <div className="modal-body fill-height">
                    <div className="flex-column">
                        <div className="align-center">
                            <WaitIcon />
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default WaitingModal;
