import React, { memo } from "react";
import GridListPaging from "./GridListPaging";

const GridListFooter = memo(({ propsRenderFooter, pageable, pagingCompact, skip, take, total, onPageChange }) => {
    const renderPagination = (paginationProps) => (
        <GridListPaging
            skip={skip}
            take={take}
            total={total}
            pageable={pageable}
            compact={pagingCompact}
            onPageChange={onPageChange}
            {...paginationProps}
        />
    );

    if (propsRenderFooter) {
        return propsRenderFooter(renderPagination, skip, take, total, onPageChange);
    }

    return renderPagination();
});

export default GridListFooter;
