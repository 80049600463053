import React, { memo } from "react";

import withDataGrid from "../../../../DataGrid/withDataGrid.js";
import DataGrid from "../../../../DataGrid";
import { deleteResource } from "../../../../../../store/resources/actions";
import { programsGoalsGridColumnKeys } from "../../../../../views/configureGrids";
import { modalOpen } from "../../../../../../store/modal/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import GoalsForm from "./GoalsForm";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils.js";

const GoalsGrid = memo((props) => {
    const { dispatch, gridId, dataGridConfig, programNumber } = props;

    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
            footerExclude: true,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Delete Goal",
            hide: isLocked,
            footerExclude: true,
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "goals-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        const goalName = dataItem[programsGoalsGridColumnKeys.goalname];
        const goal = dataItem[programsGoalsGridColumnKeys.goal];
        const goalNumber = dataItem[programsGoalsGridColumnKeys.goalnumber];

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <GoalsForm
                        dataItem={dataItem}
                        gridId={gridId}
                        dispatch={dispatch}
                        programNumber={programNumber}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            case "delete":
                const dialogMessage = (
                    <p>
                        Are you sure you want to delete the goal{" "}
                        <b>
                            {goalName}: {goal.replace(/0+$/, "").replace(/\.$/, "")}
                        </b>{" "}
                        ?
                    </p>
                );

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Goal",
                            overlayClassName: "modal-styled",
                            className: "delete-goal-confirmation-modal modal-sm",
                            modalIcon: "delete-trash-empty",
                            content: dialogMessage,
                            footerContentCenter: true,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "programGoalsDelete",
                                        path: {
                                            programNumber,
                                            goalNumber,
                                        },
                                        optimisticUpdate: {
                                            clearItem: true,
                                        },
                                        onSuccess,
                                    })
                                );
                            },
                        },
                    })
                );
                break;

            default:
                break;
        }
    };

    const onSuccess = () => {
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            columnCellContent={{
                [programsGoalsGridColumnKeys.goal]: (column, row) => {
                    return Number(row[column.key]).toLocaleString();
                },
            }}
        />
    );
});

export default withDataGrid("programsGoals", GoalsGrid);
