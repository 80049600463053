import React, { useCallback, memo, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import WaitIcon from "../WaitIcon";
import { openGlobalSearchTab } from "../../utils/window";
import { toArray } from "../../utils/array";
import IconWithLabel from "../Icons/IconWithLabel";
import SearchResultsCounter from "./SearchResultsCounter";
import SearchResultsEmpty from "./SearchResultsEmpty";
import { createId } from "../../utils/string";
import { setGlobalSearchCriteriaList } from "../../../store/globalSearch/actions";
import { GlobalSearchContext } from ".";
import {
    GlobalSearchKey,
    isCisSearch,
    getCisSearchHighlightWords,
    useOpenSearchResult,
    getSecondarySearchInstanceId,
    getHighlightWords,
} from "../../../store/globalSearch/utils";
import { ExpandableSearchResult } from "./ExpandableSearchResult";
import { SearchResultList } from "./SearchResultList";

import "./SearchQuickResults.scss";

const SearchQuickResults = memo(() => {
    const dispatch = useDispatch();
    const criteriaListFromStore = useSelector((state) => state.globalSearch.criteriaList[GlobalSearchKey]);
    const criteriaList = useMemo(() => criteriaListFromStore ?? [], [criteriaListFromStore]);

    const { onCloseSearch } = useContext(GlobalSearchContext);

    const onSeeAllResults = useCallback(() => {
        const instanceId = createId();

        dispatch(setGlobalSearchCriteriaList({ instanceId, criteriaList }));
        openGlobalSearchTab({ instanceId });
        onCloseSearch();
    }, [criteriaList, dispatch, onCloseSearch]);

    if (isCisSearch({ criteriaList })) {
        return <QuickCisResults criteriaList={criteriaList} onSeeAllResults={onSeeAllResults} onCloseSearch={onCloseSearch} />;
    }

    return <QuickResults criteriaList={criteriaList} onSeeAllResults={onSeeAllResults} onCloseSearch={onCloseSearch} />;
});

const QuickResults = memo(({ criteriaList, onSeeAllResults, onCloseSearch }) => {
    const isSearching = useSelector((state) => state.globalSearch.isSearching[GlobalSearchKey]);
    const quickResults = useSelector((state) => state.resources.search.itemsById[GlobalSearchKey]);

    const searchResults = toArray(get(quickResults, "RETURN.TABLE", []));
    const totalResults = Number(get(quickResults, "RETURN.TOTAL_RESULT_COUNT", 0));

    const words = getHighlightWords({ criteriaList });
    const hasMoreResults = totalResults > toArray(searchResults[0]?.ROW).length;

    useOpenSearchResult({
        isSearching,
        totalResults,
        criteriaList,
        searchResults,
        onCloseSearch,
    });

    if (isSearching) {
        return <WaitIcon />;
    }

    return (
        <div className="global-search__results flex-one-in-column flex-column">
            {totalResults > 0 ? (
                <SearchResultsCounter totalResults={totalResults} />
            ) : (
                <SearchResultsEmpty instanceId={GlobalSearchKey} criteriaList={criteriaList} />
            )}
            {totalResults > 0 && <SearchResultList searchResults={searchResults} words={words} criteriaList={criteriaList} />}
            {hasMoreResults && (
                <IconWithLabel className="search-results__see-all" icon="shevron-small-right" iconWithLabelRight onClick={onSeeAllResults}>
                    See all results
                </IconWithLabel>
            )}
        </div>
    );
});

const QuickCisResults = memo(({ criteriaList, onSeeAllResults, onCloseSearch }) => {
    const isCisSearchInProgress = useSelector((state) => state.globalSearch.isSearching[GlobalSearchKey]);
    const cisSearch = useSelector((state) => state.resources.search.itemsById[GlobalSearchKey]);

    const cisSearchHighlightWords = getCisSearchHighlightWords({
        criteriaList,
    });
    const cisSearchResults = toArray(get(cisSearch, "RETURN.TABLE", []));
    const totalCisSearchResults = Number(get(cisSearch, "RETURN.TOTAL_RESULT_COUNT", 0));

    const secondarySearchInstanceId = getSecondarySearchInstanceId(GlobalSearchKey);
    const isSecondarySearchInProgress = useSelector((state) => state.globalSearch.isSearching[secondarySearchInstanceId]);
    const secondarySearch = useSelector((state) => state.resources.search.itemsById[secondarySearchInstanceId]);

    const secondarySearchHighlightWords = getHighlightWords({
        criteriaList,
    });
    const secondarySearchResults = toArray(get(secondarySearch, "RETURN.TABLE", []));
    const totalSecondarySearchResults = Number(get(secondarySearch, "RETURN.TOTAL_RESULT_COUNT", 0));

    const hasMoreResults =
        totalCisSearchResults > toArray(cisSearchResults[0]?.ROW).length ||
        totalSecondarySearchResults > toArray(secondarySearchResults[0]?.ROW).length;

    if (isCisSearchInProgress || isSecondarySearchInProgress) {
        return <WaitIcon />;
    }

    return (
        <div className="global-search__results flex-one-in-column flex-column">
            <ExpandableSearchResult
                expanded
                className="flex-row align-center"
                title="CIS search results"
                totalResults={totalCisSearchResults}
            >
                {totalCisSearchResults > 0 ? (
                    <SearchResultList searchResults={cisSearchResults} words={cisSearchHighlightWords} criteriaList={criteriaList} />
                ) : (
                    <SearchResultsEmpty instanceId={GlobalSearchKey} criteriaList={criteriaList} />
                )}
            </ExpandableSearchResult>
            <ExpandableSearchResult
                expanded
                className="flex-row align-center"
                title="Other search results"
                totalResults={totalSecondarySearchResults}
            >
                {totalSecondarySearchResults > 0 ? (
                    <SearchResultList
                        searchResults={secondarySearchResults}
                        words={secondarySearchHighlightWords}
                        criteriaList={criteriaList}
                        isCisSecondarySearch
                    />
                ) : (
                    <SearchResultsEmpty instanceId={secondarySearchInstanceId} criteriaList={criteriaList} />
                )}
            </ExpandableSearchResult>
            {hasMoreResults && (
                <IconWithLabel className="search-results__see-all" icon="shevron-small-right" iconWithLabelRight onClick={onSeeAllResults}>
                    See all results
                </IconWithLabel>
            )}
        </div>
    );
});

export default SearchQuickResults;
