import React, { useCallback, useState, memo, useRef } from "react";
import { useDispatch } from "react-redux";

import { onCustomerLookup } from "components/ui/Customer/actions";
import { renderToString } from "react-dom/server";
import StatusMsg from "components/ui/StatusMsg";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaForm";
import { modalClose, modalOpen } from "store/modal/actions";
import { createResource, updateResource } from "store/resources/actions";
import { refreshCustomerApplicationsGrid } from "store/dataGrid/refreshGrid";
import { applicationsGridColumnKeys } from "components/views/configureGrids";
import { useCustomer } from "store/resources/useResource";

const ApplicationCustomerTransferForm = memo(({ utilityNumber, applications, customerNumber, onSuccessfulTransfer }) => {
    const [customerEmail, setCustomerEmail] = useState();
    const customerFormRef = useRef();
    const dispatch = useDispatch();

    const [customer = {}] = useCustomer({
        utilityNumber,
        customerNumber,
        forced: true,
    });

    const handleCustomerListSelect = useCallback(
        (data) => {
            const applicationsToTransfer = applications.map((app) => {
                return app.appid ?? app[applicationsGridColumnKeys.applicationNumber];
            });

            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Transfer Application",
                        modalIcon: "import-export-compare",
                        overlayClassName: "modal-styled",
                        className: "transfer-application-to-program-confirmation-modal modal-sm",
                        footerContentCenter: true,
                        content: (
                            <StatusMsg>
                                Do you really want to transfer {applications.length} application{applications.length > 1 ? "s" : ""}?{" "}
                            </StatusMsg>
                        ),
                        onConfirm: () => {
                            dispatch(
                                updateResource({
                                    resourceName: "applicationToolsTransferCustomer",
                                    path: {
                                        customerNumber: data?.customerNumber,
                                    },
                                    body: applicationsToTransfer,
                                    onSuccess: () => {
                                        refreshCustomerApplicationsGrid({ customerNumber });
                                        onSuccessfulTransfer();
                                    },
                                })
                            );
                        },
                    },
                })
            );
        },
        [applications, dispatch, customerNumber, onSuccessfulTransfer]
    );

    const handleCustomerSearch = useCallback(() => {
        dispatch(
            createResource({
                resourceName: "utilityCustomerSearch",
                key: utilityNumber,
                path: {
                    utilityNumber,
                },
                body: {
                    email: customerEmail?.trim() || null,
                },
                onSuccess: (action) => {
                    const customers = action.data.customerList.filter((customer) => customer.status === "active");
                    if (customers) {
                        onCustomerLookup({
                            utilityNumber,
                            onSelect: (data) => handleCustomerListSelect(data),
                            customers: customers,
                            disableClose: true,
                        });
                    }
                },
                onError: () => {
                    dispatch(modalClose());
                },
            })
        );
    }, [handleCustomerListSelect, utilityNumber, dispatch, customerEmail]);

    const handleChange = (form) => {
        const { formData } = form;

        if (formData.email) {
            setCustomerEmail(formData.email);
        }
    };

    const handleCancel = useCallback(() => {
        dispatch(modalClose());
    }, [dispatch]);

    const schema = {
        type: "object",
        required: ["email"],
        description: renderToString(<StatusMsg msgInfo msgText="Please enter a customer email to search." />),
        properties: {
            email: {
                type: ["string", "null"],
                title: "Email",
            },
        },
    };

    const uiSchema = {
        email: {
            classNames: "string-input",
        },
    };

    const onValidate = (formData, errors) => {
        if (formData?.email === customer?.email) {
            errors.email.addError("Target customer is already the owner of the application(s), try a different customer.");
        }
        return errors;
    };

    return (
        <div className="customer-search">
            <JsonSchemaForm
                formRef={customerFormRef}
                schema={schema}
                uiSchema={uiSchema}
                onChange={handleChange}
                onCancel={handleCancel}
                withCancel
                noReset
                centeredFooter
                onSubmit={handleCustomerSearch}
                validate={onValidate}
            />
        </div>
    );
});

export default ApplicationCustomerTransferForm;
