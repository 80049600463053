import React, { memo } from "react";
import { isNil } from "lodash";

import { formatJsonDate, formatJsonTime } from "../../../../utils/date";

const TaskDateAndTerm = memo(({ task }) => {
    if (isNil(task.dateCreated)) {
        return null;
    }

    const termValue = `${task.term} day` + (task.term === 1 ? "" : "s");

    return (
        <div className="step-date-and-term flex-row align-center flex-one">
            <div className="step-created flex-column">
                <span className="col-label">Created:</span>
                <span className="col-content">
                    {formatJsonDate(task.dateCreated)}
                    <div className="col-content-new-line" />
                    {formatJsonTime(task.dateCreated)}
                </span>
            </div>
            {/* {task.term > -1 && */}
            <div className="step-term flex-column align-center justify-center">
                <span className="col-label">Term:</span>
                <span className="col-content">{termValue}</span>
            </div>
            {/* } */}
        </div>
    );
});

export default TaskDateAndTerm;
