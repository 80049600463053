import React, { useCallback, memo } from "react";

import { programsFastTagsGridColumnKeys, auditFastTagsGridColumnKeys } from "../../../../../../views/configureGrids";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../SideNav/SideNavRoot";

import DataGrid from "../../../../../DataGrid";
import withDataGrid from "../../../../../DataGrid/withDataGrid.js";
import RawHtml from "../../../../../RawHtml";
import FastTagForm from "./FastTagForm";
import FastTagAudit from "./FastTagAudit";
import { isChildProgram } from "components/views/ProgramView/utils";

const FastTagsGrid = memo(({ programNumber, gridId, dataGridConfig }) => {
    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
        },
        {
            name: "audit",
            icon: "audit",
            title: "FastTag History",
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.staticLarge,
    });

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <FastTagForm programNumber={programNumber} dataItem={dataItem} gridId={gridId} onClose={handleCloseSidePanel} />,
                        { size: sideNavSize.small }
                    );
                    break;

                case "audit":
                    handleOpenSidePanel(
                        <FastTagAudit
                            dataItem={dataItem}
                            programNumber={programNumber}
                            gridId={`${dataItem[programsFastTagsGridColumnKeys.number]}-audit-grid`}
                            filter={`${auditFastTagsGridColumnKeys.fastTagNumber}=${dataItem[programsFastTagsGridColumnKeys.number]}`}
                            sort={[
                                {
                                    field: auditFastTagsGridColumnKeys.date,
                                    dir: "desc",
                                },
                            ]}
                            fastTagName={dataItem[auditFastTagsGridColumnKeys.fastTagName]}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;

                default:
                    break;
            }
        },
        [programNumber, gridId, handleOpenSidePanel, handleCloseSidePanel]
    );

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            columnCellContent={{
                [programsFastTagsGridColumnKeys.tagValue]: (column, row) => <RawHtml>{row[column.key]}</RawHtml>,
            }}
        />
    );
});

export default withDataGrid("programsFastTags", FastTagsGrid);
