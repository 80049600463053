import React, { memo } from "react";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import UserGroups from "../../../../../views/UserGroups";

import "../UtilityCommonStyles.scss";
import "./style.scss";

const UserGroupsPanel = memo(({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");

    return (
        <div className="panel user-groups flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <UserGroups utilityNumber={utilityNumber} queryType="utility" />
        </div>
    );
});

export default UserGroupsPanel;
