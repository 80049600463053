import React, { memo } from "react";
import { get } from "lodash";
import { useUtility, useUtilityRights } from "../../../../store/resources/useResource";
import { systemUserRights } from "components/utils/user";

const TabContentForUtility = memo(({ view }) => {
    const utilityNumber = get(view, `props.utilityNumber`);

    const [utilityRights = []] = useUtilityRights({ utilityNumber });

    const canViewUtility = utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY);
    const [utility] = useUtility({ utilityNumber, forced: false, canViewUtility });

    let contentItems = [
        {
            label: "Utility",
            value: (utility && utility.utility) || utilityNumber,
        },
    ];

    if (utility) {
        contentItems = [
            {
                label: "Code",
                value: utility.utilityCode,
            },
        ].concat(contentItems);
    }

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForUtility;
