import React, { useState, useCallback, memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { getData } from "../../../../../../store/dataGrid/actions";
import { useApprovedEquipment } from "./utils";
import { createResource } from "../../../../../../store/resources/actions";
import { modalOpen, modalClose } from "../../../../../../store/modal/actions";

import DropDownInput from "../../../../Input/DropDownInput";
import TextInput from "../../../../Input/TextInput";
import Button from "../../../../Button";
import ButtonGroup from "../../../../Button/ButtonGroup";
import ApprovedEquipmentSearchResults from "./ApprovedEquipmentSearchResults";
import AddApprovedEquipmentQuantity from "./AddApprovedEquipmentQuantity";
import { useIsMobile } from "components/utils/useIsMobile";
import ApprovedEquipmentDetails from "./ApprovedEquipmentDetails";

import "./AddApprovedEquipment.scss";

const AddApprovedEquipmentForm = memo(
    ({
        title,
        applicationNumber,
        catalogNumber,
        onSelectResult,
        onClose,
        gridConfig,
        dataGridInstanceId,
        filter,
        approvedEquipmentDetails,
        onShowApprovedEquipmentDetails,
        isApplyApprovedEquipment,
        onRowSelectClear,
    }) => {
        const dispatch = useDispatch();

        const [industryMeasureNumber, setIndustryMeasureNumber] = useState(null);
        const [refId, setRefId] = useState("");
        const [manufacturer, setManufacturer] = useState("");
        const [model, setModel] = useState("");

        const [activeFilter, setActiveFilter] = useState(filter && filter);
        const [showResultsCount, setShowResultsCount] = useState(0);
        const [filterError, setFilterError] = useState(false);
        const [filterErrorText, setFilterErrorText] = useState("");
        const [showTable, setShowTable] = useState(false);

        const isMobile = useIsMobile();
        const [approvedEquipment = []] = useApprovedEquipment({
            applicationNumber,
            catalogNumber,
        });

        const measureTypesList = approvedEquipment.map((item) => {
            return {
                label: item.category ?? item.industryMeasureName,
                value: item.industryMeasureNumber,
            };
        });

        // Set filter after returning from viewing equipment
        useEffect(() => {
            if (!isEmpty(filter)) {
                setIndustryMeasureNumber(filter.industryMeasureNumber ?? null);
                setRefId(filter.refId ?? "");
                setManufacturer(filter.manufacturer ?? "");
                setModel(filter.model ?? "");
            }
        }, [filter, measureTypesList]);

        const onSubmit = () => {
            if (industryMeasureNumber) {
                setFilterError(false);
                setShowTable(true);
                setFilterErrorText("");
                setShowResultsCount(showResultsCount + 1);

                setActiveFilter({
                    industryMeasureNumber,
                    refId,
                    manufacturer,
                    model,
                });
            } else {
                setFilterError(true);
                setFilterErrorText("Measure Type is required");
            }
        };

        const onReset = () => {
            setIndustryMeasureNumber(null);
            setRefId("");
            setManufacturer("");
            setModel("");
            setShowResultsCount(0);
            setActiveFilter({});
            setFilterError(false);
            setFilterErrorText("");
            setShowTable(false);
        };

        const onFilterChange = (setFilter) => (event) => {
            setFilter(event.target.value);
        };

        const handleApprovedEquipmentAdd = useCallback(
            (data) => {
                if (onSelectResult) {
                    onSelectResult(data, onClose);
                } else {
                    const close = () => {
                        dispatch(modalClose());
                    };

                    const submit = (formData) => {
                        const { itemId, industryMeasureNumber } = data;
                        const { quantity } = formData;

                        dispatch(
                            createResource({
                                resourceName: "approvedEquipment",
                                path: {
                                    appId: applicationNumber,
                                },
                                query: {
                                    industryMeasureNumber,
                                    itemId,
                                    quantity,
                                },
                                onSuccess: () => {
                                    dispatch(
                                        getData({
                                            dataGridId: `${applicationNumber}-equipment-grid`,
                                        })
                                    );
                                    onRowSelectClear();
                                    onClose && onClose();
                                },
                            })
                        );

                        close();
                    };

                    dispatch(
                        modalOpen({
                            modalType: "MODAL",
                            modalProps: {
                                title: "Add approved equipment",
                                modalIcon: "plus",
                                overlayClassName: "modal-styled",
                                className: "add-approved-equipment-modal modal-sm",
                                children: <AddApprovedEquipmentQuantity onSubmit={submit} onCancel={close} />,
                                noFooter: true,
                            },
                        })
                    );
                }
            },
            [applicationNumber, onClose, onSelectResult, dispatch, onRowSelectClear]
        );

        if (approvedEquipmentDetails) {
            return (
                <ApprovedEquipmentDetails
                    title={title}
                    isApplyApprovedEquipment={isApplyApprovedEquipment}
                    itemId={approvedEquipmentDetails.itemId}
                    industryMeasureNumber={approvedEquipmentDetails.industryMeasureNumber}
                    onClose={() => onShowApprovedEquipmentDetails(false)}
                />
            );
        }

        return (
            <div className="add-approved-equipment-form fill-height">
                <div
                    className={
                        "approved-equipment-search-content flex-column fill-height no-scroll" + (isMobile ? " justify-space-between" : "")
                    }
                >
                    <div
                        className={
                            "approved-equipment-search-form" +
                            (isMobile && showTable ? " hidden" : "") +
                            (isMobile ? " flex-one with-scroll" : "")
                        }
                    >
                        <div className="flex-row form-fields">
                            <DropDownInput
                                label="Measure Type"
                                mobileHeader="Select Measure Type"
                                data={measureTypesList}
                                onChange={onFilterChange(setIndustryMeasureNumber)}
                                value={industryMeasureNumber}
                                required
                                msgError={filterError}
                                msgText={filterErrorText}
                            />
                            <TextInput label="Ref.ID" value={refId} onChange={onFilterChange(setRefId)} onEnterKey={onSubmit} />
                            <TextInput
                                label="Manufacturer"
                                value={manufacturer}
                                onChange={onFilterChange(setManufacturer)}
                                onEnterKey={onSubmit}
                            />
                            <TextInput label="Model" value={model} onChange={onFilterChange(setModel)} onEnterKey={onSubmit} />
                        </div>
                    </div>
                    {!(isMobile && showTable) && (
                        <ButtonGroup transparent classame="flex-row">
                            <Button icon="search" primary onClick={onSubmit}>
                                Search
                            </Button>
                            {!isMobile && <Button onClick={onReset}>Reset</Button>}
                        </ButtonGroup>
                    )}
                    {(showResultsCount > 0 || dataGridInstanceId) && (
                        <>
                            <ApprovedEquipmentSearchResults
                                key={showResultsCount}
                                applicationNumber={applicationNumber}
                                industryMeasureNumber={activeFilter.industryMeasureNumber}
                                refId={activeFilter.refId}
                                manufacturer={activeFilter.manufacturer}
                                model={activeFilter.model}
                                actionTitle={title}
                                onSelectResult={handleApprovedEquipmentAdd}
                                gridConfig={gridConfig}
                                gridInstanceId={dataGridInstanceId}
                                activeFilter={activeFilter}
                                onShowApprovedEquipmentDetails={onShowApprovedEquipmentDetails}
                            />
                            {isMobile && (
                                <ButtonGroup transparent className="flex-row justify-space-between">
                                    <Button icon="shevron-small-left" onClick={onReset}>
                                        Back to Search
                                    </Button>
                                    <Button onClick={onClose}>Cancel</Button>
                                </ButtonGroup>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
);

export default AddApprovedEquipmentForm;
