import React, { useEffect, useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { mapGridRowToObject, searchAttrToFilter } from "../../utils/datagrid";
import { setFilter } from "../../../store/dataGrid/actions";
import { availableGrids } from "../../views/configureGrids";
import { cisGridGetDataAction } from "../../../store/dataGrid/getDataActions";
import { createResource } from "../../../store/resources/actions";
import { getCisCustomerDetailsResourceParams } from "../../../store/configureResources";
import { getCisGridId } from "./utils";

import DataGrid from "../DataGrid";
import WaitIcon from "../WaitIcon";
import CisSearchDetails from "./CisSearchDetails";
import { useDataGrid } from "components/utils/useDataGrid";

const cisLookupGridColumnKeys = {
    ID: "c_e8e06452-0c07-4370-90e6-808ae353f6d6",
    "ACCOUNT #": "c_cfeb3559-34c1-416f-8a8f-c51652a11bc9",
    NAME: "c_7f85becc-02cd-4c86-865b-882ffdb611ff",
    ADDRESS: "c_dffa0de9-70ad-47da-8ae8-50f4711fc73f",
    PHONE: "c_94b990cd-5964-425b-8868-0082f2a59c5d",
    "RATE CODE": "c_71220221-b458-4e34-a08a-5e11a42528bb",
    PREMISEID: "c_14b78a4c-e901-467b-9b5a-82f597739cbd",
    METERID: "c_3d0d89a9-8d71-48e0-9ed2-e26352d83d81",
    FORMATTEDACCT: "c_56413f3c-d220-4a37-8a6b-a3f381d99bb9",
    FULLADDRESS: "c_96c7c2ed-63d0-4ef7-be86-c180939385e6",
    FORMATTEDCONTACT: "c_251294e4-6f77-469d-8c4a-e9527c257467",
    SIC_CODE: "c_e6abe5d7-ff90-47b3-a3f7-9d3c13e2eb4a",
};

const rowActions = [
    {
        name: "view",
        icon: "eye-visibility-empty",
        title: "View CIS Information",
    },
    {
        name: "select",
        icon: "plus",
        title: "Select This Customer",
    },
];

const searchContactFilter = (contact, newAppCreation) => {
    if (contact.acct_number) {
        if (contact.lastname && newAppCreation) {
            return `${cisLookupGridColumnKeys["ACCOUNT #"]}=${contact.acct_number}|${cisLookupGridColumnKeys["NAME"]}=${contact.lastname}`;
        } else {
            return `${cisLookupGridColumnKeys["ACCOUNT #"]}=${contact.acct_number}`;
        }
    } else if (contact.lastname) {
        return `${cisLookupGridColumnKeys["NAME"]}=${contact.lastname}`;
    } else {
        return ``;
    }
};

const CisSearchGrid = memo(({ utilityNumber, contact, onSelect, onClose, newAppCreation }) => {
    const dispatch = useDispatch();

    const dataGridId = getCisGridId({ utilityNumber });

    const loadData = useCallback(() => {
        cisGridGetDataAction({ columnKeysMap: cisLookupGridColumnKeys, utilityNumber });
    }, [utilityNumber]);

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.cisLookup,
        dataGridInstanceId: dataGridId,
        serverSidePaging: true,
        isServerSideFiltering: true,
        getDataAction: {
            type: "cisGridGetDataAction",
            props: {
                columnKeysMap: cisLookupGridColumnKeys,
                utilityNumber,
            },
        },
    });

    useEffect(() => {
        if (!isLoadingConfig) {
            dispatch(
                setFilter({
                    dataGridId,
                    filter: searchAttrToFilter(searchContactFilter(contact, newAppCreation)),
                })
            );
            loadData();
        }
    }, [dataGridId, isLoadingConfig, loadData, dispatch, contact, newAppCreation]);

    const handleSelect = useCallback(
        (dataItem) => {
            // Return as is if data comes from details view
            if (dataItem?.custRawData) {
                onSelect(dataItem);
                onClose();
            } else {
                dispatch(
                    createResource({
                        ...getCisCustomerDetailsResourceParams({
                            utilityNumber,
                            accountNumber: dataItem["ACCOUNT #"],
                            customerID: dataItem["ID"],
                            meterID: dataItem["METERID"],
                        }),
                        onSuccess: (action) => {
                            onSelect(action.data);
                        },
                        onComplete: () => {
                            onClose();
                        },
                    })
                );
            }
        },
        [utilityNumber, onSelect, onClose, dispatch]
    );

    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const data = mapGridRowToObject(cisLookupGridColumnKeys, dataItem);

            switch (name) {
                case "view":
                    onExpand((props) => (
                        <CisSearchDetails
                            {...props}
                            dataItem={data}
                            utilityNumber={utilityNumber}
                            actions={rowActions.slice(1)}
                            onRowAction={onRowAction}
                        />
                    ));
                    break;

                case "select":
                    handleSelect(dataItem?.custRawData ? dataItem : data);
                    break;

                default:
                    break;
            }
        },
        [utilityNumber, handleSelect]
    );

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default CisSearchGrid;
