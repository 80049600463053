import React, { useState } from "react";
import { connect } from "react-redux";

import TabList from "../../ui/List/TabList";
import LogsSystemErrors from "./LogsSystemErrors";
import LogsSystemEntry from "./LogsSystemEntry";
import LogsSystemProcess from "./LogsSystemProcess";
import LogsSystemFTP from "./LogsSystemFTP";
import LogsSystemJob from "./LogsSystemJob";
import LogsSystemXmlProcess from "./LogsSystemXmlProcess";

import "./style.scss";

const tabs = [
    {
        id: "errors",
        title: "Page Errors",
    },
    {
        id: "entry",
        title: "Entry Log",
    },
    {
        id: "process",
        title: "Process Queue",
    },
    {
        id: "ftp",
        title: "FTP",
    },
    {
        id: "job",
        title: "Job",
    },
    {
        id: "xml",
        title: "XML Process",
    },
];

const LogsSystem = () => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="logs-system flex-column fill-height">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <TabList items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                {activeTab.id === "errors" && <LogsSystemErrors />}
                {activeTab.id === "entry" && <LogsSystemEntry />}
                {activeTab.id === "process" && <LogsSystemProcess />}
                {activeTab.id === "ftp" && <LogsSystemFTP />}
                {activeTab.id === "job" && <LogsSystemJob />}
                {activeTab.id === "xml" && <LogsSystemXmlProcess />}
            </div>
        </div>
    );
};

export default connect()(LogsSystem);
