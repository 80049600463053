import React, { useContext, useMemo, memo } from "react";

import { WORKFLOW, NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS } from "../../constants/workflow";

import { WorkflowContext } from "../../context/WorkflowContext";
import { Status } from "./Status";

export const Statuses = memo(({ dropProvided, showSteps, filteredWorkflowStatuses, onSetActiveItem, onCreateWorkflowStep }) => {
    const { workflowStatuses } = useContext(WorkflowContext);

    const wfStatuses = useMemo(() => {
        return filteredWorkflowStatuses ?? workflowStatuses;
    }, [workflowStatuses, filteredWorkflowStatuses]);

    const orderableStatuses = useMemo(() => {
        return wfStatuses.filter((i) => !NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS.includes(i.workflowStateNumber));
    }, [wfStatuses]);

    const notOrderableStatusesTop = useMemo(() => {
        return wfStatuses.filter((i) => WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_TOP.includes(i.workflowStateNumber));
    }, [wfStatuses]);

    const notOrderableStatusesBottom = useMemo(() => {
        return wfStatuses.filter((i) => WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_BOTTOM.includes(i.workflowStateNumber));
    }, [wfStatuses]);

    const renderStatus = (wfStatus, index) => {
        let indexNumber = index + 1;

        if (!notOrderableStatusesTop.find((i) => i.workflowStateNumber === wfStatus.workflowStateNumber)) {
            indexNumber = indexNumber + notOrderableStatusesTop.length;
        }

        if (notOrderableStatusesBottom.find((i) => i.workflowStateNumber === wfStatus.workflowStateNumber)) {
            indexNumber = indexNumber + orderableStatuses.length;
        }

        return (
            <Status
                key={wfStatus.number}
                index={index}
                indexNumber={indexNumber}
                showSteps={showSteps}
                {...wfStatus}
                onSetActiveItem={onSetActiveItem}
                onCreateWorkflowStep={onCreateWorkflowStep}
            />
        );
    };

    return (
        <>
            <div className="program-wf__management-statuses top">
                {WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_TOP.map((workflowStateNumber) =>
                    wfStatuses.find((i) => i.workflowStateNumber === workflowStateNumber)
                )
                    .filter((i) => i)
                    .map(renderStatus)}
            </div>
            <div className="program-wf__management-statuses" ref={dropProvided.innerRef}>
                {orderableStatuses.map(renderStatus)}
                {dropProvided.placeholder}
            </div>
            <div className="program-wf__management-statuses bottom">
                {WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_BOTTOM.map((workflowStateNumber) =>
                    wfStatuses.find((i) => i.workflowStateNumber === workflowStateNumber)
                )
                    .filter((i) => i)
                    .map(renderStatus)}
            </div>
        </>
    );
});
