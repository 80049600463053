import { disableDownloadText, useAllowAccessCheck } from "components/utils/files";
import React, { memo } from "react";
import IconWrap from "../Icons";
import WaitIcon from "../WaitIcon";

const FilesGridFolderRow = memo(({ groupName, rows, expanded, defaultGroupRender, onFolderDownload, downloadingFolderName }) => {
    const isDownloadFolderDisabled = useAllowAccessCheck(rows);

    return (
        <div className="flex-row align-center fill-width">
            <IconWrap iconWrapBig icon={expanded ? "folder-open-filled" : "folder"} />
            {defaultGroupRender(groupName)}
            <span className="flex-one" />
            <div className="actions">
                {downloadingFolderName === groupName ? (
                    <WaitIcon />
                ) : (
                    <IconWrap
                        disabled={isDownloadFolderDisabled}
                        icon="download"
                        title={isDownloadFolderDisabled ? disableDownloadText : "Download Folder"}
                        onClick={(e) => {
                            e.stopPropagation();
                            onFolderDownload(groupName);
                        }}
                    />
                )}
            </div>
        </div>
    );
});

export default FilesGridFolderRow;
