import React, { useCallback, memo } from "react";

import { useCustomerContacts } from "../../../../../../store/resources/useResource";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import WaitIcon from "../../../../WaitIcon";
import Controls from "../../Controls";
import ContactList from "./ContactList";
import ContactEditForm from "./ContactEditForm";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

const ContactsPanel = memo(({ panel }) => {
    const utilityNumber = panel?.data?.utilityNumber;
    const customerNumber = panel?.data?.customerNumber;

    const [contacts = [], isLoading] = useCustomerContacts({
        utilityNumber,
        customerNumber,
        forced: true,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const handleCreateContact = useCallback(() => {
        handleOpenSidePanel(
            <ContactEditForm utilityNumber={utilityNumber} customerNumber={customerNumber} onClose={handleCloseSidePanel} />
        );
    }, [utilityNumber, customerNumber, handleOpenSidePanel, handleCloseSidePanel]);

    const addNewContactDisabled = !hasAnyOfPermissions([systemUserRights.VISIONDSM_ADD_APPLICATION]);

    const controlItems = [
        {
            position: "right",
            title: "Add New Contact",
            icon: "plus",
            hide: contacts.length === 0,
            onClick: () => handleCreateContact(),
            disabled: addNewContactDisabled,
        },
    ];

    return (
        <div className={"panel customer-contacts" + (contacts.length !== 0 ? "" : " fill-height flex-column")}>
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            {isLoading ? (
                <WaitIcon />
            ) : (
                <ContactList
                    utilityNumber={utilityNumber}
                    customerNumber={customerNumber}
                    items={contacts}
                    onContactAdd={handleCreateContact}
                />
            )}
        </div>
    );
});

export default ContactsPanel;
