import React, { useContext, memo } from "react";

import { ArchivedBatchGridContext } from "../../../context/ArchivedBatchGridContext";
import { EntryRow } from "../../../shared/Grid/EntryRow";
import { Document } from "./Document";
import { formatJsonDateTime } from "../../../../../utils/date";

import "./ArchivedBatch.scss";

export const ArchivedBatch = memo(
    ({ documentBatchNumber, batchDate, user, expandedArchivedBatches, loadingArchivedBatchNumbers, onClickArchivedBatchRow }) => {
        const { batchDocuments } = useContext(ArchivedBatchGridContext);

        return (
            <EntryRow
                withoutSelection
                documentComponent={Document}
                entryNumber={documentBatchNumber}
                expandedEntries={expandedArchivedBatches}
                loadingEntryNumbers={loadingArchivedBatchNumbers}
                documents={batchDocuments}
                onClick={onClickArchivedBatchRow}
            >
                <div className="application-document-queue__archived-batch-grid-item align-center">
                    <div className="application-document-queue__archived-batch-grid-item-field application-document-queue__archived-batch-grid-item-field">
                        Batch
                    </div>
                    <div className="application-document-queue__archived-batch-grid-item-field application-document-queue__archived-batch-grid-item-field--user flex-one">
                        <span>Printed by:</span>
                        {user}, {formatJsonDateTime(batchDate)}
                    </div>
                </div>
            </EntryRow>
        );
    }
);
