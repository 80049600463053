import React, { useMemo, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { getApplicationWorkflowResourceParams } from "../../../../../../store/configureResources";
import { isApplicationLocked, useCalculationStatusCheck } from "../../../../../views/ProjectView/utils";

import AdHocTaskForm from "./AdHocTaskForm";
import TaskWorkflow from "../../../../TaskWorkflow";
import { getApplicationWorkflowHistoryResourceParams } from "../../../../../../store/configureResources";
import { refreshApplication } from "store/resources/refreshResource";
import { refreshContactLists } from "../ContactsPanel/ContactsUtils";
import { entityType } from "components/utils/entityType";
import { useProgramRights } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";

const WorkflowPanel = memo(({ panel, onExpand }) => {
    const utilityNumber = panel.data?.utilityNumber;
    const programNumber = panel.data?.programNumber;
    const applicationNumber = panel.data?.applicationNumber;

    const status = useSelector((state) => get(state, `resources.applicationDetails.itemsById[${applicationNumber}].status`));
    const wfNumber = useSelector((state) => get(state, `resources.applicationDetails.itemsById[${applicationNumber}].wfNumber`));
    const isAppLocked = isApplicationLocked({ applicationNumber });

    const [programRights = []] = useProgramRights({ programNumber });
    const hasAddTaskRights = programRights.includes(systemUserRights.VISIONDSM_ADD_CUSTOM_TASK);
    const isExpandTaskDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    const checkCalculationStatus = useCalculationStatusCheck({
        applicationNumber,
    });

    const workflowGetResourceOptions = useMemo(
        () =>
            getApplicationWorkflowResourceParams({
                applicationNumber,
            }),
        [applicationNumber]
    );

    const workflowHistoryGetResourceOptions = useMemo(
        () => getApplicationWorkflowHistoryResourceParams({ applicationNumber }),
        [applicationNumber]
    );

    const workflowTaskOwnershipUpdateResourceOptions = useMemo(
        () => ({
            resourceName: "applicationWorkflowTaskOwnership",
            path: {
                appId: applicationNumber,
            },
        }),
        [applicationNumber]
    );

    const workflowTaskUpdateResourceOptions = useMemo(
        () => ({
            resourceName: "applicationWorkflowTasks",
            path: {
                appId: applicationNumber,
            },
            query: {
                taskType: "APPLICATION",
            },
        }),
        [applicationNumber]
    );

    const workflowTaskDetailsResourceOptions = useMemo(
        () => ({
            resourceName: "workflowSteps",
            path: {
                wfNumber,
            },
        }),
        [wfNumber]
    );

    const handleChangeTask = useCallback(() => {
        checkCalculationStatus().then(() => {
            refreshApplication({ applicationNumber });
            refreshContactLists({ applicationNumber });
        });
    }, [applicationNumber, checkCalculationStatus]);

    const getAdHocTaskForm = useCallback(
        (props) => {
            return (
                <AdHocTaskForm
                    {...props}
                    applicationNumber={applicationNumber}
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                />
            );
        },
        [applicationNumber, utilityNumber, programNumber]
    );

    return (
        <div className="panel panel-workflow">
            <TaskWorkflow
                noDocumentSecurity={false}
                entityId={applicationNumber}
                entityTypeId={entityType.projectApplication}
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                workflowGetResourceOptions={workflowGetResourceOptions}
                workflowHistoryGetResourceOptions={workflowHistoryGetResourceOptions}
                workflowTaskOwnershipUpdateResourceOptions={workflowTaskOwnershipUpdateResourceOptions}
                workflowTaskUpdateResourceOptions={workflowTaskUpdateResourceOptions}
                workflowTaskDetailsResourceOptions={workflowTaskDetailsResourceOptions}
                name={panel.name}
                isLocked={isAppLocked}
                hasAddTaskRights={hasAddTaskRights}
                isExpandTaskDisabled={isExpandTaskDisabled}
                activeStatusName={status}
                AdHocTaskForm={getAdHocTaskForm}
                onExpandPanel={onExpand}
                onChangeTask={handleChangeTask}
            />
        </div>
    );
});

export default WorkflowPanel;
