import React from "react";
import { useUtilityPortalConfiguration } from "components/ui/PortalBuilder/utils/useUtilityPortalConfiguration";
import { useProgram } from "store/resources/useResource";
import WaitIcon from "components/ui/WaitIcon";

const TabContentForPortalBuilderView = ({ view }) => {
    const { utilityNumber, programNumber, portalTemplateNumber } = view?.props ?? {};

    const [template = {}] = useUtilityPortalConfiguration({ utilityNumber, portalTemplateNumber });
    const [program, isLoadingProgramDetails] = useProgram({ programNumber, forced: false });

    let contentItems = [];

    if (programNumber) {
        contentItems.push({
            label: "Utility",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.utility,
        });

        contentItems.push({
            label: "Program",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.program,
        });
    }

    contentItems.push({
        label: "Portal Builder Template",
        value: template?.name ?? <>&nbsp;</>,
    });

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
};

export default TabContentForPortalBuilderView;
