import React, { memo, useMemo } from "react";

import GridListActions from "./GridListActions";
import GridListSelectAllCell from "./GridListSelectAllCell";
import GridListHeaderCell from "./GridListHeaderCell";
import ScrollableColumns from "./ScrollableColumns";

import "./GridListHeaderRow.scss";

const GridListHeaderRow = memo(({ columns, show, showActions, noData }) => {
    const columnList = useMemo(() => columns.map((column) => <GridListHeaderCell key={column.key} column={column} />), [columns]);

    if (!show && !showActions) {
        return null;
    }

    return (
        <div className="grid-list-row grid-list-row-header">
            {!noData && <GridListSelectAllCell empty={!showActions} />}
            <ScrollableColumns scrollControls={true}>{columnList}</ScrollableColumns>
            <GridListActions empty={!showActions} />
        </div>
    );
});

export default GridListHeaderRow;
