import React, { memo, useCallback } from "react";
import { get } from "lodash";

import { invoiceDocumentAuditGridColumnKeys, invoiceItemAuditGridColumnKeys } from "../../../../../views/configureGrids";
import { getInvoiceDocumentHistoryGridId, getInvoiceItemHistoryGridId } from "../utils";
import { auditType } from "../../../../../utils/auditType";
import { useSelector } from "../../../../AuditTypesSelector";
import { exportDatagridToCSV } from "../../../../../../components/utils/CSV";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "./Controls";
import DocumentGrid from "./DocumentGrid";
import ItemGrid from "./ItemGrid";

const types = [
    {
        label: "Invoice Document History",
        type: auditType.invoiceDocument,
    },
    {
        label: "Invoice Item History",
        type: auditType.invoiceItem,
    },
];

const HistoryPanel = memo(({ panel }) => {
    const documentNumber = get(panel, "data.documentNumber");

    const [type, setType] = useSelector(types[0].type);

    const getGridWithControls = useCallback(() => {
        if (type === auditType.invoiceDocument) {
            const gridId = getInvoiceDocumentHistoryGridId({ documentNumber });
            return (
                <>
                    <Controls
                        type={type}
                        types={types}
                        onChange={setType}
                        onClickExportCSV={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "invoice_document_history",
                                fileNamePostfix: documentNumber,
                            })
                        }
                    />
                    <DocumentGrid
                        gridId={gridId}
                        filter={`${invoiceDocumentAuditGridColumnKeys.id}=${documentNumber}`}
                        sort={[
                            {
                                field: invoiceDocumentAuditGridColumnKeys.date,
                                dir: "desc",
                            },
                        ]}
                    />
                </>
            );
        } else {
            const gridId = getInvoiceItemHistoryGridId({ documentNumber });
            return (
                <>
                    <Controls
                        type={type}
                        types={types}
                        onChange={setType}
                        onClickExportCSV={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "invoice_item_history",
                                fileNamePostfix: documentNumber,
                            })
                        }
                    />
                    <ItemGrid gridId={gridId} filter={`${invoiceItemAuditGridColumnKeys.id}=${documentNumber}`} />
                </>
            );
        }
    }, [type, documentNumber, setType]);

    return (
        <div className="panel invoice-history flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            {getGridWithControls()}
        </div>
    );
});

export default HistoryPanel;
