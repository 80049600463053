import { cloneDeep, isNil } from "lodash";

import { setFilter, getData } from "../dataGrid/actions";
import { usersGridColumnKeys } from "../../components/views/configureGrids";

const getFilter = (state) => {
    let filter = state.dataGrid.users.filter;
    if (!filter || Array.isArray(filter)) {
        filter = {
            filters: [],
            logic: "and",
        };
    }

    return filter;
};

const updateUsersGrid = (newFilter, dispatch) => {
    dispatch(
        setFilter({
            dataGridId: "users",
            filter: newFilter,
        })
    );
    dispatch(
        getData({
            dataGridId: "users",
        })
    );
};

/**
 * Toggle the user status seacrh filter in user managment panel.
 * @param {"active" | "expired" | "deleted"} status
 */
export const toggleUserManagmentStatusFilter = (status) => (dispatch, getState) => {
    const newFilter = cloneDeep(getFilter(getState()));

    if (newFilter && newFilter.filters) {
        const statusColumnFilter = newFilter.filters.find((f) => f.field === usersGridColumnKeys.status);
        if (statusColumnFilter) {
            if (statusColumnFilter.value === status) {
                // remove
                newFilter.filters.splice(newFilter.filters.indexOf(statusColumnFilter), 1);
            } else {
                statusColumnFilter.value = status;
            }
        } else {
            newFilter.filters.push({
                field: usersGridColumnKeys.status,
                value: status,
            });
        }
    }

    updateUsersGrid(newFilter, dispatch);
};

export const setUserManagmentClientNumberFilter = (clientNumber) => (dispatch, getState) => {
    const newFilter = cloneDeep(getFilter(getState()));

    if (newFilter && newFilter.filters) {
        const clientFilter = newFilter.filters.find((f) => f.field === usersGridColumnKeys.clientNumber);
        if (clientFilter) {
            if (isNil(clientNumber)) {
                newFilter.filters.splice(newFilter.filters.indexOf(clientFilter), 1);
            } else {
                clientFilter.value = clientNumber;
            }
        } else {
            if (!isNil(clientNumber)) {
                newFilter.filters.push({
                    field: usersGridColumnKeys.clientNumber,
                    value: clientNumber,
                });
            }
        }
    }

    updateUsersGrid(newFilter, dispatch);
};
