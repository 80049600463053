import React, { useState, useEffect, useCallback, useRef, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, uniq } from "lodash";

import WaitIcon from "../../ui/WaitIcon";
import CounterBox from "../../ui/CounterBox";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DropdownInput from "../../ui/Input/DropDownInput";
import ViewPlaceholder from "../../ui/ViewPlaceholder";
import NothingFoundBlock from "../../ui/NothingFoundBlock";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import { useDocumentNumbers } from "./utils/hooks/useDocumentNumbers";
import { useItems } from "../../utils/useItems";
import { createResource, getResource, updateResource } from "../../../store/resources/actions";
import { useResource } from "../../../store/resources/useResource";
import { previewBatchFile } from "../../../store/files/actions";
import { DocumentQueueGrid } from "./components/DocumentQueueGrid";
import { BatchGrid } from "./components/BatchGrid";
import { ArchivedBatchGrid } from "./components/ArchivedBatchGrid";
import { BatchGridContext } from "./context/BatchGridContext";
import { DocumentQueueGridContext } from "./context/DocumentQueueGridContext";
import { ArchivedBatchGridContext } from "./context/ArchivedBatchGridContext";

import "./style.scss";

const BATCH_LIST = "batchList";
const ARCHIVED_BATCH_LIST = "archivedBatchList";

const ApplicationProcessingDocumentQueue = memo(() => {
    const [utilityNumber, setUtilityNumber] = useState(null);
    const [selectedProgramNumbers, selectedProgramNumbersActions] = useItems();
    const [selectedProgramDocumentNumbers, selectedProgramDocumentNumbersActions] = useDocumentNumbers();
    const [selectedBatchNumbers, selectedBatchNumbersActions] = useItems();
    const [selectedBatchDocumentNumbers, selectedBatchDocumentNumbersActions] = useDocumentNumbers();
    const [isProgramListLoading, setProgramListLoading] = useState(false);
    const [isBatchListLoading, setBatchListLoading] = useState(false);
    const [isArchivedBatchListLoading, setArchivedBatchListLoading] = useState(false);
    const [isBatchProcessing, setBatchProcessing] = useState(false);
    const [isArchivedBatchesTableVisibile, setArchivedBatchesTableVisibility] = useState(false);
    const didCancel = useRef(null);

    const programListKey = `${utilityNumber}-programList`;
    const bachListKey = `${utilityNumber}-${BATCH_LIST}`;
    const archivedBatchListKey = `${utilityNumber}-${ARCHIVED_BATCH_LIST}`;

    const dispatch = useDispatch();
    const programListFromStore = useSelector((state) => get(state, `resources.documentQueuePrograms.itemsById[${programListKey}]`));
    const programList = useMemo(() => programListFromStore || [], [programListFromStore]);

    const batchListFromStore = useSelector((state) => get(state, `resources.documentQueueBatches.itemsById[${bachListKey}]`));
    const batchList = useMemo(() => batchListFromStore || [], [batchListFromStore]);

    const archivedBatchListFromStore = useSelector((state) =>
        get(state, `resources.documentQueueBatches.itemsById[${archivedBatchListKey}]`)
    );
    const archivedBatchList = useMemo(() => archivedBatchListFromStore || [], [archivedBatchListFromStore]);

    const queueDocumentsFromStore = useSelector((state) => get(state, "resources.documentQueueProgramEntries.itemsById"));
    const queueDocuments = useMemo(() => queueDocumentsFromStore || {}, [queueDocumentsFromStore]);

    const batchDocumentsFromStore = useSelector((state) => get(state, "resources.documentQueueBatchEntries.itemsById"));
    const batchDocuments = useMemo(() => batchDocumentsFromStore || {}, [batchDocumentsFromStore]);

    const [isVisibleBatchesToPrint, setIsVisibleBatchesToPrint] = useState(false);

    const documentsTotal = useMemo(() => programList.reduce((total, value) => total + value.total, 0), [programList]);

    const [utilitiesList = [], isUtilitiesListLoading] = useResource({
        resourceName: "utilities",
        key: "utilitiesList",
    });

    const utilities = useMemo(
        () =>
            utilitiesList.map(({ utility, utilityNumber }) => ({
                value: utilityNumber,
                label: utility,
            })),
        [utilitiesList]
    );

    const handleChangeUtility = useCallback((e) => setUtilityNumber(e.target.value), []);

    const getProgramList = useCallback(
        (withLoading = true, cb) => {
            if (utilityNumber) {
                if (withLoading) {
                    setProgramListLoading(true);
                }

                dispatch(
                    getResource({
                        resourceName: "documentQueuePrograms",
                        key: programListKey,
                        query: {
                            utilityNumber,
                        },
                        onComplete: () => {
                            if (!didCancel.current) {
                                if (withLoading) {
                                    setProgramListLoading(false);
                                }

                                if (cb) {
                                    cb();
                                }
                            }
                        },
                    })
                );
            }
        },
        [utilityNumber, programListKey, dispatch]
    );

    const getQueueDocuments = useCallback(
        (programNumber, cb) =>
            dispatch(
                getResource({
                    resourceName: "documentQueueProgramEntries",
                    key: programNumber,
                    path: {
                        programNumber,
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            if (cb) {
                                cb();
                            }
                        }
                    },
                })
            ),
        [dispatch]
    );

    const getQueueDocumentsAsync = useCallback(
        async (programNumber, cb) => {
            await new Promise((resolve) =>
                getQueueDocuments(programNumber, () => {
                    resolve();

                    if (cb) {
                        cb();
                    }
                })
            );
        },
        [getQueueDocuments]
    );

    const updateDocumentQueueAfterBatchCreating = useCallback(async () => {
        await Promise.all(selectedProgramNumbers.map((programNumber) => getQueueDocumentsAsync(programNumber)));

        selectedProgramNumbersActions.clear();
        selectedProgramDocumentNumbersActions.clear();
        setBatchProcessing(false);
    }, [selectedProgramNumbers, getQueueDocumentsAsync, selectedProgramNumbersActions, selectedProgramDocumentNumbersActions]);

    const getBatchList = useCallback(
        (withLoading = true, cb) => {
            if (utilityNumber) {
                if (withLoading) {
                    setBatchListLoading(true);
                }

                dispatch(
                    getResource({
                        resourceName: "documentQueueBatches",
                        key: bachListKey,
                        path: {
                            utilityNumber,
                            archived: false,
                        },
                        onComplete: () => {
                            if (cb) {
                                cb();
                            }

                            if (!didCancel.current) {
                                if (withLoading) {
                                    setBatchListLoading(false);
                                }
                            }
                        },
                    })
                );
            }
        },
        [utilityNumber, bachListKey, dispatch]
    );

    const displayBatchFile = useCallback(
        (batchNumber) => {
            dispatch(previewBatchFile({ batchNumber }));
        },
        [dispatch]
    );

    const getArchivedBatchList = useCallback(
        (withLoading = true) => {
            if (utilityNumber) {
                if (withLoading) {
                    setArchivedBatchListLoading(true);
                }

                dispatch(
                    getResource({
                        resourceName: "documentQueueBatches",
                        key: archivedBatchListKey,
                        path: {
                            utilityNumber,
                            archived: true,
                        },
                        onComplete: () => {
                            if (!didCancel.current) {
                                if (withLoading) {
                                    setArchivedBatchListLoading(false);
                                }
                            }
                        },
                    })
                );
            }
        },
        [utilityNumber, archivedBatchListKey, dispatch]
    );

    const getBatchDocuments = useCallback(
        (batchNumber, cb) =>
            dispatch(
                getResource({
                    resourceName: "documentQueueBatchEntries",
                    key: batchNumber,
                    path: {
                        batchNumber,
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            if (cb) {
                                cb();
                            }
                        }
                    },
                })
            ),
        [dispatch]
    );

    const getBatchDocumentsAsync = useCallback(
        async (batchNumber, cb) => {
            await new Promise((resolve) =>
                getBatchDocuments(batchNumber, () => {
                    resolve();

                    if (cb) {
                        cb();
                    }
                })
            );
        },
        [getBatchDocuments]
    );

    const updateTablesAfterBatchReverting = useCallback(async () => {
        const programNumbersToUpdate = uniq(selectedBatchDocumentNumbers.map(({ programNumber }) => programNumber));

        await Promise.all(selectedBatchNumbers.map((batchNumber) => getBatchDocumentsAsync(batchNumber)));
        await Promise.all(programNumbersToUpdate.map((programNumber) => getQueueDocumentsAsync(programNumber)));

        selectedBatchNumbersActions.clear();
        selectedBatchDocumentNumbersActions.clear();
        getProgramList(false);
        setBatchProcessing(false);
    }, [
        selectedBatchNumbers,
        selectedBatchDocumentNumbers,
        getBatchDocumentsAsync,
        getQueueDocumentsAsync,
        selectedBatchNumbersActions,
        selectedBatchDocumentNumbersActions,
        getProgramList,
    ]);

    const createBatch = useCallback(() => {
        if (!isBatchProcessing) {
            setBatchProcessing(true);
            dispatch(
                createResource({
                    resourceName: "documentQueueBatch",
                    body: selectedProgramDocumentNumbers.map(({ documentNumber }) => documentNumber),
                    onSuccess: (action) => {
                        if (action && action.data && action.data.number) {
                            displayBatchFile(action.data.number);
                        }
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            getProgramList(false, updateDocumentQueueAfterBatchCreating);
                            getBatchList(false);
                        }
                    },
                })
            );
        }
    }, [
        isBatchProcessing,
        selectedProgramDocumentNumbers,
        dispatch,
        displayBatchFile,
        getProgramList,
        getBatchList,
        updateDocumentQueueAfterBatchCreating,
    ]);

    const revertBatch = useCallback(() => {
        if (!isBatchProcessing) {
            setBatchProcessing(true);
            dispatch(
                updateResource({
                    resourceName: "documentQueueBatchRevert",
                    body: selectedBatchDocumentNumbers.map(({ documentNumber }) => documentNumber),
                    onComplete: () => {
                        if (!didCancel.current) {
                            getBatchList(false, updateTablesAfterBatchReverting);
                        }
                    },
                })
            );
        }
    }, [isBatchProcessing, selectedBatchDocumentNumbers, dispatch, getBatchList, updateTablesAfterBatchReverting]);

    const showArchivesText = () => {
        return isArchivedBatchesTableVisibile
            ? "Show Batches sent to Printer (" + batchList.length + ")"
            : "Show Archived Batches (" + archivedBatchList.length + ")";
    };

    const reprintBatch = useCallback(() => {
        if (!isBatchProcessing) {
            selectedBatchNumbers.forEach((batch) => displayBatchFile(batch));
        }
    }, [isBatchProcessing, selectedBatchNumbers, displayBatchFile]);

    const archiveBatch = useCallback(
        (documentBatchNumber) => {
            setBatchProcessing(true);
            dispatch(
                updateResource({
                    resourceName: "documentQueueBatchArchive",
                    documentBatchNumber,
                    path: {
                        documentBatchNumber,
                    },
                    onComplete: () => {
                        if (!didCancel.current) {
                            getBatchList(false, () => setBatchProcessing(false));
                            getArchivedBatchList(false);
                        }
                    },
                })
            );
        },
        [getBatchList, dispatch, getArchivedBatchList]
    );

    const onShowArchivedBatches = () => {
        setArchivedBatchesTableVisibility(!isArchivedBatchesTableVisibile);
    };

    useEffect(() => {
        getProgramList();
        getBatchList();
        getArchivedBatchList();
        [
            selectedProgramNumbersActions,
            selectedProgramDocumentNumbersActions,
            selectedBatchNumbersActions,
            selectedBatchDocumentNumbersActions,
        ].forEach((actions) => actions.clear());
        setArchivedBatchesTableVisibility(false);
    }, [
        getProgramList,
        getBatchList,
        getArchivedBatchList,
        selectedProgramDocumentNumbersActions,
        selectedProgramNumbersActions,
        selectedBatchNumbersActions,
        selectedBatchDocumentNumbersActions,
    ]);

    useEffect(() => {
        return () => {
            didCancel.current = true;
        };
    }, []);

    return (
        <div className="application-document-queue flex-column fill-height no-scroll">
            <WindowBodyHeader withShadow noPopupDetailsOnMobile>
                <div className="flex-row align-center justify-center">
                    <div className="dropdown-label">Utility</div>
                    <DropdownInput
                        value={utilityNumber}
                        data={utilities}
                        onChange={handleChangeUtility}
                        disabled={isUtilitiesListLoading}
                        placeholder={isUtilitiesListLoading ? "Loading..." : "Select Utility"}
                        mobileHeader="Select Utility"
                    />
                </div>
            </WindowBodyHeader>
            <div className="main-grid-wrap responsive flex-one-in-column with-scroll">
                {!isProgramListLoading && !utilityNumber ? (
                    <ViewPlaceholder>Please select utility</ViewPlaceholder>
                ) : (
                    <div className="application-document-queue__container flex-row fill-height">
                        <div className="view-switcher-wrap">
                            <div className="view-switcher">
                                {!isVisibleBatchesToPrint ? (
                                    <div className="flex-row">
                                        <span className="active">Documents in the Queue ({documentsTotal})</span>
                                        <span onClick={() => setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}>
                                            {!isArchivedBatchesTableVisibile
                                                ? "Show Batches sent to Printer (" + batchList.length + ")"
                                                : "Show Archived Batches (" + archivedBatchList.length + ")"}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex-row">
                                        <span onClick={() => !setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}>
                                            Show Documents in the Queue ({documentsTotal})
                                        </span>
                                        <span className="active">
                                            {!isArchivedBatchesTableVisibile
                                                ? "Batches sent to Printer (" + batchList.length + ")"
                                                : "Archived Batches (" + archivedBatchList.length + ")"}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className={
                                "application-document-queue__entry flex-column" +
                                (isVisibleBatchesToPrint ? " hidden" : "") +
                                (selectedProgramDocumentNumbers.length > 0 ? " items-selected" : "")
                            }
                        >
                            {(programList.length > 0 || batchList.length > 0 || archivedBatchList.length > 0) && (
                                <div className="application-document-queue__entry--lead-title flex-row align-center">
                                    <CounterBox>{documentsTotal}</CounterBox>
                                    <h3>
                                        Document
                                        {documentsTotal === 1 ? "" : "s"} in the Queue
                                    </h3>
                                </div>
                            )}
                            {isProgramListLoading && <WaitIcon />}
                            {!isProgramListLoading && utilityNumber && programList.length > 0 && (
                                <DocumentQueueGridContext.Provider
                                    value={{
                                        getProgramList,
                                        programList,
                                        isProgramListLoading,
                                        queueDocuments,
                                        getQueueDocuments,
                                        getQueueDocumentsAsync,
                                        isBatchProcessing,
                                        selectedProgramNumbers,
                                        selectedProgramNumbersActions,
                                        selectedProgramDocumentNumbers,
                                        selectedProgramDocumentNumbersActions,
                                        createBatch,
                                    }}
                                >
                                    <DocumentQueueGrid />
                                </DocumentQueueGridContext.Provider>
                            )}
                            {!isProgramListLoading && utilityNumber && !programList.length && (
                                <div className={"flex-column" + (batchList.length > 0 ? "" : " fill-height")}>
                                    <NothingFoundBlock
                                        icon="document-text"
                                        title={batchList.length > 0 ? "No Documents in the Queue" : "No Documents found"}
                                    >
                                        {batchList.length > 0 ? (
                                            <span>
                                                Please select another utility or revert <b>Batches sent to Printer</b>{" "}
                                                <span
                                                    className="hidden-on-desktop link"
                                                    onClick={() => setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}
                                                >
                                                    Batches sent to Printer
                                                </span>
                                            </span>
                                        ) : (
                                            <span>
                                                Please select another utility{" "}
                                                <span className="hidden-on-desktop">
                                                    or check{" "}
                                                    <span
                                                        className="link"
                                                        onClick={() => setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}
                                                    >
                                                        Batches sent to Printer
                                                    </span>
                                                </span>
                                            </span>
                                        )}
                                    </NothingFoundBlock>
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                "application-document-queue__entry print-batches flex-column" +
                                (!isVisibleBatchesToPrint ? " hidden" : "") +
                                (selectedBatchNumbers.length > 0 ? " items-selected" : "")
                            }
                        >
                            {(batchList.length > 0 || archivedBatchList.length > 0 || programList.length > 0) && (
                                <div className="application-document-queue__entry--lead-title flex-row align-center">
                                    {!isArchivedBatchesTableVisibile ? (
                                        <div className="flex-row align-start">
                                            <CounterBox>{batchList.length}</CounterBox>
                                            <h3>
                                                Batch
                                                {batchList.length === 1 ? "" : "es"} sent to Printer
                                            </h3>
                                        </div>
                                    ) : (
                                        <div className="flex-row align-start">
                                            <CounterBox>{archivedBatchList.length}</CounterBox>
                                            <h3>
                                                Archived Batch
                                                {archivedBatchList.length === 1 ? "" : "es"}
                                            </h3>
                                        </div>
                                    )}
                                    <div className="flex-one"></div>
                                    <IconWithLabel
                                        iconWithLabel={isArchivedBatchesTableVisibile ? "printer-empty" : "archives-empty--before"}
                                        active={isArchivedBatchesTableVisibile}
                                        onClick={onShowArchivedBatches}
                                    >
                                        {showArchivesText()}
                                    </IconWithLabel>
                                </div>
                            )}
                            {!isArchivedBatchesTableVisibile &&
                                !isBatchListLoading &&
                                !isArchivedBatchesTableVisibile &&
                                utilityNumber &&
                                batchList.length > 0 && (
                                    <div className="application-document-queue__entry--sent-to-printer flex-one-in-column no-scroll">
                                        {isBatchListLoading && <WaitIcon />}
                                        <BatchGridContext.Provider
                                            value={{
                                                batchList,
                                                batchDocuments,
                                                isBatchProcessing,
                                                selectedBatchNumbers,
                                                selectedBatchNumbersActions,
                                                selectedBatchDocumentNumbers,
                                                selectedBatchDocumentNumbersActions,
                                                getBatchDocuments,
                                                revertBatch,
                                                reprintBatch,
                                                archiveBatch,
                                            }}
                                        >
                                            <BatchGrid />
                                        </BatchGridContext.Provider>
                                    </div>
                                )}
                            {isArchivedBatchesTableVisibile &&
                                !isBatchListLoading &&
                                !isArchivedBatchListLoading &&
                                utilityNumber &&
                                archivedBatchList.length > 0 && (
                                    <div className="application-document-queue__entry--archived flex-one-in-column with-scroll">
                                        <ArchivedBatchGridContext.Provider
                                            value={{
                                                archivedBatchList,
                                                batchDocuments,
                                                getBatchDocuments,
                                            }}
                                        >
                                            <ArchivedBatchGrid />
                                        </ArchivedBatchGridContext.Provider>
                                    </div>
                                )}
                            {!isBatchListLoading && !isArchivedBatchesTableVisibile && utilityNumber && !batchList.length && (
                                <div className={"flex-column" + (programList.length > 0 ? "" : " fill-height")}>
                                    <NothingFoundBlock icon="copy-empty" title="No Batches found">
                                        {programList.length > 0 ? (
                                            <span>
                                                Select Documents from the <b>Documents in the Queue</b>{" "}
                                                <span
                                                    className="hidden-on-desktop link"
                                                    onClick={() => !setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}
                                                >
                                                    Documents in the Queue
                                                </span>{" "}
                                                to send to Printer <br /> or <br /> check{" "}
                                                <span className="link" onClick={onShowArchivedBatches}>
                                                    Archived Batches
                                                </span>
                                            </span>
                                        ) : (
                                            <span>
                                                Please select another utility{" "}
                                                <span className="hidden-on-desktop">
                                                    or check{" "}
                                                    <span
                                                        className="link"
                                                        onClick={() => setIsVisibleBatchesToPrint(!isVisibleBatchesToPrint)}
                                                    >
                                                        Documents in the Queue
                                                    </span>
                                                </span>
                                            </span>
                                        )}
                                    </NothingFoundBlock>
                                </div>
                            )}
                            {!archivedBatchList.length && isArchivedBatchesTableVisibile && (
                                <div className="flex-column">
                                    <NothingFoundBlock icon="archives-empty--before" title="No Archived Batches found">
                                        <span>
                                            Go to{" "}
                                            <span className="link" onClick={onShowArchivedBatches}>
                                                Batches sent to Printer
                                            </span>{" "}
                                            and archive
                                        </span>
                                    </NothingFoundBlock>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default ApplicationProcessingDocumentQueue;
