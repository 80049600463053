import "core-js";
import "react-app-polyfill/ie11";
import smoothscroll from "smoothscroll-polyfill";
import elementClosest from "element-closest";
import "css.escape";
import "intersection-observer";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
// import registerServiceWorker from './registerServiceWorker';
import App from "./components/App";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import { isInIframe } from "./components/utils/iframe";
import { isLoadedInCRM, preventLoadingInCRM } from "components/utils/crm";
import { syncSessionStorage } from "components/utils/store";
import { listenToIframeCallbacks } from "components/utils/window";
import disableBrowserNavigation from "components/utils/disableBrowserNavigation";
import { hideRecaptchaBadge } from "components/utils/recaptcha";

import "./style.scss";

// IE11 polyfill for 'scrollBy'
smoothscroll.polyfill();

// IE11 polyfill for 'Element.closest()'
elementClosest(window);

preventLoadingInCRM();

// Show nothing if could not prevent app from loading inside CRM
if (!isLoadedInCRM()) {
    disableBrowserNavigation();

    // Get session storage from other opened tabs.
    syncSessionStorage();

    // Listen callbacks from iframes
    listenToIframeCallbacks();

    // Hide recaptcha badge
    hideRecaptchaBadge();

    // Do not load app in iframe.
    if (!isInIframe()) {
        const wrappedApp = (
            <Provider store={store}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Provider>
        );
        ReactDOM.render(wrappedApp, document.getElementById("root"));
        // registerServiceWorker();
    }
}
