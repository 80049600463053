import React, { useState, memo, useContext, useCallback } from "react";

import { getPanel } from "../../../../../utils/dashboard";
import { onAssign, getContactType } from "./ContactsUtils";

import IconWrap from "../../../../Icons";
import ContentPanel from "../../ContentPanel";
import ContactHeaderSection from "./ContactHeaderSection";
import { ContactActionsSection } from "./ContactActionsSection";
import { ContactBodySection } from "./ContactBodySection";
import { ContractorPreview } from "./ContractorPreview";
import { ContactsPanelContext } from ".";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { ContactEditSidePanel } from "./ContactEditSidePanel";

import "./ContactsPanelItem.scss";

const ContactsPanelItem = memo(
    ({
        contact,
        isAssigned,
        isPremise,
        isPrimary,
        isContractor,
        isOtherContact,
        isLocked,
        sameAsPrimary,
        onOpenDashboardPanel,
        onDelete,
        onCisVerify,
        onCisStored,
        assignedContacts,
    }) => {
        const { canSearchCIS, applicationNumber, programNumber, utilityNumber, premiseContact, primaryContact, contractorContact } =
            useContext(ContactsPanelContext);

        const [selectedContractor, setSelectedContractor] = useState();

        // Use sidebar handlers
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
            size: sideNavSize.small,
            className: "contractor-preview-sidenav-panel",
        });

        const onEdit = useCallback(() => {
            handleOpenSidePanel(
                <ContactEditSidePanel
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    premiseContact={premiseContact}
                    primaryContact={primaryContact}
                    contractorContact={contractorContact}
                    contact={contact}
                    title={"Edit Contact"}
                    icon="edit-empty"
                    onClose={handleCloseSidePanel}
                />
            );
        }, [
            applicationNumber,
            programNumber,
            utilityNumber,
            premiseContact,
            primaryContact,
            contractorContact,
            contact,
            handleOpenSidePanel,
            handleCloseSidePanel,
        ]);

        const onCreateAssigned = useCallback(() => {
            if (isAssigned) {
                handleOpenSidePanel(
                    <ContactEditSidePanel
                        isNew
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        utilityNumber={utilityNumber}
                        premiseContact={premiseContact}
                        primaryContact={primaryContact}
                        contractorContact={contractorContact}
                        contact={contact}
                        icon="plus"
                        title={`Add New ${getContactType({
                            contact,
                            isPremise,
                            isPrimary,
                            isContractor,
                        })} Contact`}
                        onSuccess={(action) => {
                            if (action.data?.contactNumber) {
                                onAssign({
                                    applicationNumber,
                                    contactNumber: action.data.contactNumber,
                                    assignType: getContactType({
                                        contact,
                                        isPremise,
                                        isPrimary,
                                        isContractor,
                                    }),
                                    assignedContacts,
                                });
                            }

                            handleCloseSidePanel();
                        }}
                        onClose={handleCloseSidePanel}
                    />
                );
            }
        }, [
            applicationNumber,
            programNumber,
            utilityNumber,
            premiseContact,
            primaryContact,
            contractorContact,
            isAssigned,
            isPremise,
            isPrimary,
            isContractor,
            contact,
            assignedContacts,
            handleOpenSidePanel,
            handleCloseSidePanel,
        ]);

        const onSelectContractor = useCallback(
            (contractor) => {
                isAssigned && isContractor && setSelectedContractor(contractor);
            },
            [isAssigned, isContractor]
        );

        const handleGoToAddress = useCallback(() => {
            if (onOpenDashboardPanel) {
                onOpenDashboardPanel(getPanel("project-location", 2));
            }
        }, [onOpenDashboardPanel]);

        if (selectedContractor) {
            handleOpenSidePanel(
                <ContractorPreview
                    applicationNumber={applicationNumber}
                    contractor={selectedContractor}
                    onClose={() => {
                        setSelectedContractor();
                        handleCloseSidePanel();
                    }}
                />
            );
        }

        return (
            <ContentPanel className={"contact-panel-item" + (!contact ? " not-assigned" : "")}>
                <IconWrap iconWrapBig iconWrap={!contact ? "help-empty" : "user-identity-person-empty"} />
                {contact && (
                    <ContactActionsSection
                        applicationNumber={applicationNumber}
                        contact={contact}
                        isLocked={isLocked}
                        isAssigned={isAssigned}
                        isPremise={isPremise}
                        isPrimary={isPrimary}
                        isContractor={isContractor}
                        onSelectContractor={onSelectContractor}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        assignedContacts={assignedContacts}
                        programNumber={programNumber}
                    />
                )}
                <ContactHeaderSection
                    contact={contact}
                    isPremise={isPremise}
                    isPrimary={isPrimary}
                    isContractor={isContractor}
                    sameAsPrimary={sameAsPrimary}
                    onCisVerify={onCisVerify}
                    onCisStored={onCisStored}
                    canSearchCIS={canSearchCIS}
                    applicationNumber={applicationNumber}
                />
                <ContactBodySection
                    applicationNumber={applicationNumber}
                    contact={contact}
                    isPremise={isPremise}
                    isPrimary={isPrimary}
                    isContractor={isContractor}
                    onAddressClick={handleGoToAddress}
                    onSelectContractor={onSelectContractor}
                    onOpenCreateForm={onCreateAssigned}
                    onCisVerify={onCisVerify}
                    onCisStored={onCisStored}
                    canSearchCIS={canSearchCIS}
                    programNumber={programNumber}
                    isOtherContact={isOtherContact}
                />
            </ContentPanel>
        );
    }
);

export default ContactsPanelItem;
