import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import DataGrid from "../../../../../DataGrid";
import withDataGrid from "../../../../../DataGrid/withDataGrid.js";
import IconWithLabel from "../../../../../Icons/IconWithLabel";
import Button from "../../../../../Button";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../../SideNav/SideNavContent";

const FastTagAudit = memo(({ utilityNumber, fastTagName, gridId, dataGridConfig, onClose }) => {
    return (
        <SideNavContent>
            <SideNavHeader title="History for FastTag: " nameInBold={fastTagName} leadBlockIcon={"audit"} smallHeader onClose={onClose} />
            <SideNavBody>
                <div className="panel fast-tag-audit sticky-grid-list-panel">
                    <div className="data-grid-controls flex-row align-center">
                        <span className="flex-one" />
                        <IconWithLabel
                            withHandMadeIcon
                            onClick={() =>
                                exportDatagridToCSV({
                                    dataGridId: gridId,
                                    fileName: "utility_fasttags_history",
                                    fileNamePostfix: utilityNumber,
                                })
                            }
                        >
                            Export CSV
                        </IconWithLabel>
                    </div>
                    <DataGrid onCancel={onClose} name={gridId} config={dataGridConfig} customRowActions />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default withDataGrid("auditFastTags", FastTagAudit);
