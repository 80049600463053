// decided (V50-1067) not to permit reprint/revert functionality on individual document. alternatively enable selection and uncomment code below

import React, { useContext, /*useCallback,*/ useMemo, memo } from "react";

import { DocumentRow } from "../../../shared/Grid/DocumentRow";
import { BatchGridContext } from "../../../context/BatchGridContext";

export const Document = memo(({ index, style, data }) => {
    const {
        entityId,
        //selectedDocumentsCount,
        //isSelectionDisabled,
    } = data;
    const {
        batchDocuments,
        isBatchProcessing,
        //selectedBatchNumbers,
        //selectedBatchDocumentNumbers,
        //selectedBatchNumbersActions,
        //selectedBatchDocumentNumbersActions,
    } = useContext(BatchGridContext);
    const document = useMemo(() => batchDocuments[entityId][index], [entityId, index, batchDocuments]);
    //const { documentNumber, programNumber } = document;
    //const checked = useMemo(
    //    () => selectedBatchDocumentNumbers.some(
    //        selectedDocument => selectedDocument.documentNumber === documentNumber),
    //    [selectedBatchDocumentNumbers, documentNumber]
    //);

    //const handleSelectDocument = useCallback(e => {
    //    e.stopPropagation();

    //    if (!checked) {

    //        if (isSelectionDisabled) return;

    //        if (!selectedBatchNumbers.includes(entityId)) {
    //            selectedBatchNumbersActions.add([entityId]);
    //        }

    //        selectedBatchDocumentNumbersActions.add([{ documentNumber, programNumber }]);

    //    } else {
    //        selectedBatchDocumentNumbersActions.remove([documentNumber]);

    //        if (selectedDocumentsCount === 1) {
    //            selectedBatchNumbersActions.remove([entityId]);
    //        }
    //    }
    //}, [
    //    entityId,
    //    checked,
    //    documentNumber,
    //    programNumber,
    //    isSelectionDisabled,
    //    selectedDocumentsCount,
    //    selectedBatchNumbers,
    //    selectedBatchNumbersActions,
    //    selectedBatchDocumentNumbersActions
    //]);

    return (
        <DocumentRow
            {...document}
            index={index}
            style={style}
            withoutSelection={true}
            //checked={checked}
            //onSelectDocument={handleSelectDocument}
            //isSelectionDisabled={isSelectionDisabled}
            isBatchProcessing={isBatchProcessing}
        />
    );
});
