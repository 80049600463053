import React, { useCallback, memo } from "react";

import { onCisVerify, onApplicationContactStoredCIS } from "../../../../CIS/actions";
import { getApplicationCisSearchType, isApplicationLocked, useRequireContractor } from "../../../../../views/ProjectView/utils";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { onAssign } from "../ContactsPanel/ContactsUtils";
import { CONTACT_TYPE } from "components/utils/constants";
import { formatFullName, systemUserRights } from "../../../../../utils/user";

import IconWrap from "../../../../Icons";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import EmailLink from "../../../../EmailLink";
import { ContactEditSidePanel } from "../ContactsPanel/ContactEditSidePanel";
import { EditAction, SearchContractorAction } from "components/ui/Dashboard/Panel/Project/ContactsPanel/ContactActionsSection";
import { useProgramRights } from "store/resources/useResource";
import { openProjectTab } from "components/utils/window";

const ContactItem = memo(
    ({
        canSearchCIS,
        utilityNumber,
        programNumber,
        applicationNumber,
        contactType,
        contact,
        premiseContact,
        primaryContact,
        contractorContact,
    }) => {
        const isLocked = isApplicationLocked({ applicationNumber });
        const requireContractorOption = useRequireContractor({
            applicationNumber,
        });

        const isContractor = contactType === CONTACT_TYPE.CONTRACTOR;
        const isContractorEditable = isContractor && requireContractorOption !== "r";

        const contactTitle = formatFullName(contact.firstname, contact.lastname, contact.company);

        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.small });

        const handleCisStored = useCallback(() => {
            onApplicationContactStoredCIS({
                applicationNumber,
                contactNumber: contact.contactnumber,
            });
        }, [applicationNumber, contact.contactnumber]);

        const handleCisVerify = useCallback(() => {
            onCisVerify({
                utilityNumber,
                contact,
                searchType: getApplicationCisSearchType({ applicationNumber }),
            });
        }, [utilityNumber, applicationNumber, contact]);

        const handleEdit = useCallback(() => {
            handleOpenSidePanel(
                <ContactEditSidePanel
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    premiseContact={premiseContact}
                    primaryContact={primaryContact}
                    contractorContact={contractorContact}
                    contact={contact}
                    title={"Edit Contact"}
                    icon="edit-empty"
                    onClose={handleCloseSidePanel}
                />
            );
        }, [
            handleOpenSidePanel,
            applicationNumber,
            programNumber,
            utilityNumber,
            premiseContact,
            primaryContact,
            contractorContact,
            contact,
            handleCloseSidePanel,
        ]);

        const onSelectContractor = useCallback(
            (contractor) => {
                onAssign({
                    applicationNumber,
                    contactNumber: contractor.contactNumber,
                    assignType: "Contractor",
                });
            },
            [applicationNumber]
        );

        return (
            <div className="sidebar-contacts-list-container">
                <div className="contact flex-row">
                    <div className="flex-column flex-one left-block">
                        <div className="contact-column-item type-and-name flex-row justify-space-between">
                            <div className="flex-column">
                                <IconWrap className="contact-name-icon" iconWrapBig icon="user-identity-person-empty" />
                                <div className="type">{contactType}</div>
                                <div className="contact-name">{contactTitle}</div>
                            </div>
                            <div className="flex-column">
                                {contactType === CONTACT_TYPE.PREMISE && contact.hascis && (
                                    <IconWithLabel iconWithLabelRight icon="library-empty" onClick={handleCisStored}>
                                        Stored CIS
                                    </IconWithLabel>
                                )}
                                {contactType === CONTACT_TYPE.PREMISE && (
                                    <IconWithLabel
                                        iconWithLabelRight
                                        icon="library-empty"
                                        disabled={!canSearchCIS}
                                        onClick={handleCisVerify}
                                    >
                                        Verify CIS
                                    </IconWithLabel>
                                )}
                                {contactType === CONTACT_TYPE.CONTRACTOR && contact?.cmpAppId && contact?.cmpAppId !== applicationNumber && (
                                    <IconWithLabel
                                        title="View CMP Application"
                                        iconWithLabelRight
                                        icon="eye-visibility-empty"
                                        onClick={() => openProjectTab({ applicationNumber: contact.cmpAppId })}
                                    >
                                        View
                                    </IconWithLabel>
                                )}
                                <Actions
                                    applicationNumber={applicationNumber}
                                    isLocked={isLocked}
                                    isContractor={isContractor}
                                    isContractorEditable={isContractorEditable}
                                    contact={contact}
                                    onEdit={handleEdit}
                                    onSelectContractor={onSelectContractor}
                                    programNumber={programNumber}
                                />
                            </div>
                        </div>
                        <div className="contact-column-item flex-row align-center company">
                            <IconWrap iconWrapSmall icon="city-empty" title="company" />
                            {contact.company ? contact.company : "-"}
                        </div>
                        <div className="contact-column-item flex-row align-center address">
                            <IconWrap iconWrapSmall icon="location-user-empty" title="address" />
                            {contact.address}, {contact.address_cont ? `${contact.address_cont}, ` : ""}
                            {contact.city}, {contact.state}
                            &nbsp;{contact.zip}
                        </div>
                        <div className="contact-column-item flex-row align-center phone">
                            <IconWrap iconWrapSmall icon="phone-empty" title="phone" />
                            {contact.phone ? contact.phone : "-"}
                        </div>
                        <div className="contact-column-item flex-row align-center email">
                            <IconWrap iconWrapSmall icon="email-empty" title="email" />
                            {contact.email ? <EmailLink email={contact.email} /> : "-"}
                        </div>
                    </div>
                    <div className="flex-column align-end justify-space-between right-block">
                        <div className="contact-column-item title">
                            <span className="label">Contact Title:</span>
                            <span className="title">{contact.contacttitle ? contact.contacttitle : "-"}</span>
                        </div>
                        <div className="contact-column-item account">
                            <Identifier label="Account #:" value={contact.acct_number ? contact.acct_number : "-"} />
                        </div>
                        <div className="contact-column-item premise">
                            <Identifier label="Premise #:" value={contact.premiseid ? contact.premiseid : "-"} />
                        </div>
                        <div className="contact-column-item workflowgroup">
                            <Identifier label="Workflow Group:" value={contact.workflowgroup ? contact.workflowgroup : "-"} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default ContactItem;

export const Identifier = memo(({ label, value }) => {
    return (
        <div className="identifier">
            <div className="label">{label}</div>
            <div className="value">{value ? value : "-"}</div>
        </div>
    );
});

const Actions = memo(
    ({ applicationNumber, isLocked, isContractor, isContractorEditable, contact, onEdit, onSelectContractor, programNumber }) => {
        const [programRights = []] = useProgramRights({ programNumber });
        const isEditContactsDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

        return (
            <>
                {isContractor && (
                    <SearchContractorAction
                        applicationNumber={applicationNumber}
                        isLocked={isLocked}
                        contact={contact}
                        onSelect={onSelectContractor}
                        programNumber={programNumber}
                    />
                )}
                {(!isContractor || isContractorEditable) && (
                    <EditAction
                        applicationNumber={applicationNumber}
                        isLocked={isLocked}
                        contact={contact}
                        isContractor={isContractor}
                        isContractorEditable={isContractorEditable}
                        onEdit={onEdit}
                        disabled={isEditContactsDisabled}
                    />
                )}
            </>
        );
    }
);
