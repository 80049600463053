import React, { useMemo } from "react";
import { SelectProperty } from "./SelectProperty";
import { useResource } from "store/resources/useResource";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const PowerBiReportSelectProperty = (props) => {
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);

    const [reports = []] = useResource({
        resourceName: "publicDashboardsAvailable",
        resourceId: utilityNumber,
        forced: true,
    });

    const items = useMemo(() => {
        return reports.map((report) => ({
            value: report.id,
            label: report.name,
        }));
    }, [reports]);

    return <SelectProperty {...props} items={items} withPopper={false} />;
};
