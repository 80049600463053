import React, { useMemo } from "react";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { getPageConfigByLink } from "../../utils/page";
import { usePageTitle } from "./usePageTitle";

export const PageEditSubtitle = () => {
    const [config] = usePortalBuilderState((state) => state.updatedConfig);
    const [activePageUrl] = usePortalBuilderState((state) => state.activePageUrl);

    const activePage = useMemo(() => getPageConfigByLink(activePageUrl, config), [activePageUrl, config]);
    const pageTitle = usePageTitle(activePage);

    return (
        <span>
            EDIT <b className="page-title">{pageTitle}</b> PAGE
        </span>
    );
};
