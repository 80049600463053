import React from "react";
import cn from "classnames";
import IconWrap from "../Icons";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./TileItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TileItem = (props) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const alignPosition = props.align ?? "center";
    const justifyPosition = props.justify ?? "center";

    return (
        <div
            className={cn(`tile-item flex-column align-${alignPosition} justify-${justifyPosition}`, props.className)}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            onClick={props.onClick}
        >
            {props.content ? (
                props.content
            ) : (
                <>
                    {!props.similarTiles && <IconWrap icon={props.icon} />}
                    <div className={"title" + (props.tileActionIcon ? " with-action-icon" : "")}>
                        <span>{props.title}</span>
                        {!props.faIcon ? (
                            <IconWrap
                                title={props.tileActionIconTooltip}
                                icon={props.tileActionIcon ? props.tileActionIcon : "shevron-small-right"}
                            />
                        ) : (
                            <FontAwesomeIcon icon={props.faIcon} size={props.iconSize}></FontAwesomeIcon>
                        )}
                    </div>
                    {props.similarTiles && props.children}
                </>
            )}
        </div>
    );
};

export default TileItem;
