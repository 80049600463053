import React, { useContext, useRef } from "react";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { BACKGROUND_PROPERTY, SHADOW_PROPERTY } from "../../Property/propertyConfig";
import { BORDER_PROPERTY } from "../../Property/BorderProperty";
import { BORDER_RADIUS_PROPERTY } from "../../Property/BorderRadiusProperty";
import { set, cloneDeep, isNil, isArray, omitBy } from "lodash";
import { MARGIN_PROPERTY } from "../../Property/MarginProperty";
import { PADDING_PROPERTY } from "../../Property/PaddingProperty";
import sassVariables from "assets/sass/_export.module.scss";
import { BorderCornerSize } from "../../Property/BorderRadiusProperty/types";
import { PropertyName } from "components/ui/PortalBuilder/types";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

export const GlobalContentWrapperProperties = (props) => {
    const { errors } = props;
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const sectionConfig = updatedConfig?.widgets?.contentWrapper;

    const configRef = useRef();
    configRef.current = updatedConfig;

    const onPropertyChange = (id, value, extra) => {
        let updatedConfigClone = cloneDeep(configRef.current);
        set(updatedConfigClone, `widgets.contentWrapper[${id}]`, value);

        if (isArray(extra)) {
            updatedConfigClone.widgets.contentWrapper = omitBy(
                extra.reduce((result, property) => {
                    return {
                        ...result,
                        [property.id]: property.value,
                    };
                }, updatedConfigClone.widgets.contentWrapper ?? {}),
                isNil
            );
        }
        onConfigChange(updatedConfigClone);
    };

    return (
        <div className="flex-column fill-width with-scroll">
            <PropertyList
                items={CONTENT_WRAPPER_PROPERTIES}
                errors={errors}
                config={sectionConfig}
                onChange={onPropertyChange}
                isExpanded
            />
        </div>
    );
};

export const CONTENT_WRAPPER_PROPERTIES = [
    BACKGROUND_PROPERTY,
    { ...BORDER_PROPERTY, defaultValue: { [PropertyName.BorderColor]: sassVariables.widgetContentWrapperBorderColor } },
    { ...BORDER_RADIUS_PROPERTY, defaultValue: { [PropertyName.BorderRadius]: BorderCornerSize.Size1 } },
    SHADOW_PROPERTY,
    MARGIN_PROPERTY,
    PADDING_PROPERTY,
];
