import React, { useState, useCallback, memo, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { get, pickBy } from "lodash";
import { useBudgetLineAttributeResource, transformFormErrors, getBudgetLineAttributesGridId } from "../utils";
import WaitIcon from "../../../../WaitIcon";
import { useResource } from "../../../../../../store/resources/useResource";
import { regexPatterns, submitResource, submitByRef } from "../../../../../utils/form";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaFormV2";
import SideNavContent from "../../../../../ui/SideNav/SideNavContent";
import SideNavHeader from "../../../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../../../ui/SideNav/SideNavFooter";
import Button from "../../../../../ui/Button";

const Form = memo(({ utilityNumber, contractNumber, budgetLineNumber, dataItem, gridRefresh, sidePanel }) => {
    const isNew = dataItem === undefined;
    const resourceId = isNew ? null : dataItem.attributeNumber;

    const [isSubmitting, setSubmitting] = useState(false);
    const [resource, isLoading] = useBudgetLineAttributeResource({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
        resourceId,
    });

    const dataGridId = getBudgetLineAttributesGridId({
        budgetLineNumber: budgetLineNumber,
    });
    const attributeRows = useSelector((state) => get(state, `dataGrid[${dataGridId}].sourceRows`, []));

    const formRef = useRef();

    const [attributeTypes = [], isLoadingAttributeTypes] = useResource({
        resourceName: "utilitiesContractsBudgetLinesAttributeTypes",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return (data && data.budgetLineAttributeTypeList) || [];
        },
    });

    const schema = {
        type: "object",
        required: ["budgetAttrTypeId", "budgetAttrValueStr"],
        properties: {
            budgetAttrTypeId: {
                type: "integer",
                title: "Attribute",
                anyOf: attributeTypes.map((i) => ({
                    title: i.budgetLineAttrDesc,
                    enum: [i.budgetLineAttrTypeId],
                })),
            },
            budgetAttrValueStr: {
                type: "string",
                title: "Value",
                pattern: regexPatterns.nonEmptyString,
            },
        },
    };

    const disabledItems = attributeTypes
        // Disable attributes already in use excluding current attribute
        .filter(
            (i) =>
                attributeRows.some((row) => row.budgetAttrTypeId === i.budgetLineAttrTypeId) &&
                i.budgetLineAttrTypeId !== resource?.budgetAttrTypeId
        )
        .map((i) => i.budgetLineAttrTypeId);

    const uiSchema = {
        budgetAttrTypeId: {
            "ui:enumDisabled": disabledItems,
        },
    };

    const initialValues = isNew ? {} : pickBy(resource);

    const title = isNew ? "Add Budget Attribute" : "Edit Budget Attribute";

    const titleIcon = isNew ? "plus" : "edit-empty";

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        const numericValue = parseFloat(formData.budgetAttrValueStr);

        const resourceParams = {
            resourceName: "utilitiesContractsBudgetLinesAttributes",
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        };

        const body = {
            ...formData,
            budgetAttrValue: isNaN(numericValue) ? null : numericValue,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    const validateTypeUnique = useCallback(
        (formData, errors) => {
            if (disabledItems.some((i) => i === formData.budgetAttrTypeId)) {
                errors.budgetAttrTypeId.addError("This attribute is already in use.");
            }

            return errors;
        },
        [disabledItems]
    );

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm([formRef]);
    }, [sidePanel]);

    if (isLoading || isLoadingAttributeTypes) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={sidePanel.close} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    submitText={submitText}
                    transformErrors={transformFormErrors}
                    validate={validateTypeUnique}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={sidePanel.close}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
