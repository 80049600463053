import React, { memo, useCallback, useEffect, useRef } from "react";
import { isNil } from "lodash";
import { isPropertyRequired } from "../../utils/validation";
import "./TextareaProperty.scss";
import PropertyErrors from "./PropertyErrors";
import { PropertyListItem } from "../Property/PropertyListItem";
import { useShowError } from "../../utils";
import PropertyDescription from "./PropertyDescription";
import { PropertyTitle } from "./PropertyTitle";
import TextField from "components/ui/TextField";

export const TextareaProperty = memo((props) => {
    const {
        id,
        title,
        value,
        onChange,
        defaultValue,
        validationRules,
        maxLength,
        infoIconText,
        description,
        errors = [],
        borderTop,
        borderBottom,
        listContainsActiveError,
    } = props;
    const required = isPropertyRequired(validationRules);
    const elementRef = useRef(null);

    const onPropertyChange = useCallback(
        (event) => {
            onChange(id, event.target.value);
        },
        [id, onChange]
    );

    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    useEffect(() => {
        if (isNil(value) && !isNil(defaultValue)) {
            setTimeout(() => {
                onChange(id, defaultValue);
            }, 0);
        }
    }, [id, defaultValue, onChange, value]);

    return (
        <PropertyListItem className="property-list-item--textarea" borderTop={borderTop} borderBottom={borderBottom}>
            <div ref={elementRef} className="property-list-item-inner flex-column full-width-property">
                {title && <PropertyTitle title={title} required={required} infoIconText={infoIconText} />}
                <TextField
                    onChange={onPropertyChange}
                    value={value ?? ""}
                    error={props.errors.length > 0}
                    maxLength={maxLength}
                    lightUpError={lightUpError}
                    data-test-id={`${id}-textarea`}
                />
            </div>
            <PropertyDescription id={id} value={value} description={description} />
            <PropertyErrors errors={errors} />
        </PropertyListItem>
    );
});
