import React, { memo, useCallback, useMemo } from "react";
import { dbStringToDictionaryItemValues, itemListToDbString, splitDictionaryItemValues } from "../../../../../utils/form";
import JsonSchemaForm from "../../JsonSchemaForm";

export const ManageItemsForm = memo(({ value, onSubmit, onClose }) => {
    const schema = useMemo(
        () => ({
            type: "object",
            properties: {
                itemValues: {
                    type: "string",
                },
            },
        }),
        []
    );

    const uiSchema = useMemo(
        () => ({
            itemValues: {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 5,
                },
            },
        }),
        []
    );

    const initialValues = useMemo(() => {
        const itemList = splitDictionaryItemValues(value);

        return {
            itemValues: itemListToDbString(itemList),
        };
    }, [value]);

    const onFormSubmit = useCallback(
        (formData) => {
            const result = dbStringToDictionaryItemValues(formData.itemValues);
            onSubmit(result);
            onClose();
        },
        [onSubmit, onClose]
    );

    const onValidate = useCallback((formData, errors) => {
        // Values provided need to be even number of items "backend|display|... "
        if ((formData.itemValues ?? "").split("|").length % 2 !== 0) {
            errors.itemValues.addError("Invalid value");
        }

        return errors;
    }, []);

    return (
        <JsonSchemaForm
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            onCancel={onClose}
            validate={onValidate}
            submitText="Apply"
            noReset
            centeredFooter
        />
    );
});
