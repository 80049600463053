import React, { useCallback, useMemo, useContext, memo } from "react";

import { useResource, useFolders } from "../../../../../../../../store/resources/useResource";
import { STEP_TYPES_ATTRIBUTES } from "../../../../../constants/step-types";

import WaitIcon from "../../../../../../WaitIcon";
import DropDownInput from "../../../../../../Input/DropDownInput";

import { WorkflowContext } from "../../../../../context/WorkflowContext";
import { StepPropertiesSettingsContext } from "../../../../../context/StepPropertiesSettingsContext";
import { entityType } from "components/utils/entityType";
import { DEFAULT_FOLDER } from "components/utils/files";

export const DocumentUploadField = memo(({ onChangeAttribute }) => {
    const { entityId, stepAttributeTypesGetResourceOptions, isLocked } = useContext(WorkflowContext);
    const { attributes } = useContext(StepPropertiesSettingsContext);

    const [folders = [], isLoadingFolders] = useFolders({
        entityTypeId: entityType.program,
        entityId,
    });

    const folderList = useMemo(() => {
        if (folders.length === 0) {
            return [
                {
                    label: DEFAULT_FOLDER,
                    value: DEFAULT_FOLDER,
                },
            ];
        }

        return folders.map((i) => ({
            label: i.display,
            value: i.value,
        }));
    }, [folders]);

    const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

    const getAttribute = useCallback(
        ({ number = null, code = null }) => {
            if (number) {
                return workflowStepAttributeTypes.find((i) => i.number === number);
            } else if (code) {
                return workflowStepAttributeTypes.find((i) => i.code === code);
            }
        },
        [workflowStepAttributeTypes]
    );

    const folderName = useMemo(() => {
        return attributes.reduce((acc, { typeNumber, value }) => {
            const attribute = getAttribute({ number: typeNumber });

            if (attribute.code === STEP_TYPES_ATTRIBUTES.FILE_FOLDER_NAME) {
                if (!isLoadingFolders && value) {
                    acc = value;
                }
            }

            return acc;
        }, undefined);
    }, [attributes, isLoadingFolders, getAttribute]);

    const onChange = useCallback(
        (e) => {
            const attribute = getAttribute({
                code: STEP_TYPES_ATTRIBUTES.FILE_FOLDER_NAME,
            });
            onChangeAttribute(e, attribute.number, attribute.name);
        },
        [onChangeAttribute, getAttribute]
    );

    if (isLoadingFolders || isLoadingWorkflowStepAttributeTypes) {
        return <WaitIcon />;
    }

    const attributeName = getAttribute({
        code: STEP_TYPES_ATTRIBUTES.FILE_FOLDER_NAME,
    }).name;

    return (
        <DropDownInput
            className="workflow-step-types-widget__attribute-field"
            value={folderName}
            label={attributeName}
            readOnly={isLocked}
            onChange={onChange}
            data={folderList}
            emptyItem
            emptyItemLabel="-- SELECT --"
            placeholder={isLoadingFolders ? "Loading..." : "-- SELECT --"}
            disabled={isLoadingFolders}
            mobileHeader={attributeName}
        />
    );
});
