import { memo, useMemo } from "react";

import { programsApplicationsGridColumnKeys } from "../../../../../views/configureGrids";
import { openNewApplication } from "../../../../../utils/window";
import { isLockedText, isChildProgram, useIsProgramLocked } from "../../../../../views/ProgramView/utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

import Controls from "../../Controls";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ApplicationsGrid from "../../../../DataGrid/ApplicationsGrid";

const ApplicationsPanel = memo(({ panel }) => {
    const utilityNumber = panel?.data?.utilityNumber;
    const programNumber = panel?.data?.programNumber;

    const isLocked = useIsProgramLocked({ programNumber });
    const isChild = isChildProgram({ programNumber });

    const [programRights = []] = useProgramRights({ programNumber });
    const canAddApplication = programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    const dataGridId = `${programNumber}-applications`;
    const dataGridFilter = `${programsApplicationsGridColumnKeys.programNumber}=${programNumber}`;

    const dataGridSort = useMemo(
        () => [
            {
                field: programsApplicationsGridColumnKeys.dateCreated,
                dir: "desc",
            },
        ],
        []
    );

    const controlItems = [
        {
            position: "right",
            title: "Create New Application",
            icon: "plus",
            disabled: isLocked || !canAddApplication,
            tooltip: isLocked ? isLockedText : undefined,
            onClick: () => openNewApplication({ utilityNumber, programNumber }),
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId,
                    fileName: "program_applications",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="panel applications-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            <ApplicationsGrid
                isLocked={isLocked || isChild}
                isLockedText={isLockedText}
                gridId={dataGridId}
                filter={dataGridFilter}
                sort={dataGridSort}
                programNumber={programNumber}
            />
        </div>
    );
});

export default ApplicationsPanel;
