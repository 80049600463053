import { useState, useEffect } from "react";

const usePagination = (listItems = [], { limit, defaultPage = 0 }) => {
    const pageCount = Math.ceil(listItems.length / limit);

    const sliceItemsByPage = (page) => listItems.slice(page * limit, page * limit + limit);

    const [selectedPage, setSelectedPage] = useState(defaultPage);

    useEffect(() => {
        if (listItems) {
            setSelectedPage(0);
        }
    }, [listItems]);

    const onSelectPage = (page) => {
        if (page >= 0 && page < pageCount) {
            setSelectedPage(page);
        }
    };

    return [sliceItemsByPage(selectedPage), { selectedPage, pageCount, onSelectPage }];
};

export default usePagination;
