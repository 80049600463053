import { useState, memo, useRef, useCallback, useEffect, lazy, Suspense } from "react";
import { isNil } from "lodash";
import { useProgramCatalogCategory, useProgramCategories } from "../../../../../../../store/resources/useResource";
import { pickInitialValues, submitResource, listToAnyOf, submitByRef } from "../../../../../../utils/form";
import { getListRootWithChildren } from "../../../../../../utils/tree";
import GridDetailsFooterActions from "../../../../../DataGrid/GridDetailsFooterActions";
import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";
import { ErrorMessage } from "../../../../../Message";
import WaitIcon from "../../../../../WaitIcon";
import Button from "../../../../../Button";

import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";
import ModuleLoadError from "components/ui/ModuleLoadError";

const IconSelectWidget = lazy(() =>
    import("./IconSelect")
        .then((module) => ({ default: module.IconSelectWidget }))
        .catch((error) => ({
            default: () => <ModuleLoadError error={error} />,
        }))
);

const Form = memo((props) => {
    const { programNumber, dataItem, addChild, onClose, gridRefresh, sidePanel } = props;
    const formRef = useRef();
    const isNew = dataItem === undefined;
    const isCreateForm = isNew || addChild;
    const isLocked = isChildProgram({ programNumber });

    const resourceId = isCreateForm ? null : dataItem.categoryNumber;

    const parentCategoryNumber = isNew ? null : addChild ? dataItem.categoryNumber : dataItem.parentCategoryNumber;

    const [isSubmitting, setSubmitting] = useState(false);
    const [resource, isLoadingCategory] = useProgramCatalogCategory({
        programNumber,
        categoryNumber: resourceId,
    });

    const [categories = [], isLoadingCategories] = useProgramCategories({
        programNumber,
        resourceName: "programCatalogCategories",
        forced: false,
    });

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const isLoading = isLoadingCategory || isLoadingCategories;

    const title = isNew
        ? "Add Catalog Category"
        : addChild
        ? "Add Child Category"
        : isLocked
        ? "View Catalog Category"
        : "Edit Catalog Category";

    const icon = isNew ? "plus" : addChild ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty";

    const submitText = isSubmitting ? "Saving..." : "Save";

    const notFound = !isLoading && !isCreateForm && isNil(resource);
    const errorText = "Catalog Category not found";

    const initialValues = isNew
        ? {}
        : pickInitialValues({
              ...resource,
              parentCategoryNumber,
          });

    const tree = isNil(resourceId)
        ? []
        : getListRootWithChildren({
              tree: categories,
              rootId: resourceId,
              idKey: "categoryNumber",
              parentIdKey: "parentCategoryNumber",
          });

    const schema = {
        type: "object",
        required: ["category"],
        properties: {
            category: {
                type: "string",
                title: "Category",
            },
            imgFileLocation: {
                type: "string",
                title: "Category Icon",
            },
            parentCategoryNumber: {
                type: "string",
                title: "Parent Category",
                anyOf: listToAnyOf({
                    list: categories.filter((c) => !tree.includes(c.categoryNumber)),
                    map: (c) => ({
                        title: getCategoryFullName({
                            categories,
                            categoryNumber: c.categoryNumber,
                        }),
                        enum: [c.categoryNumber],
                    }),
                    emptyItem: "No Parent Category",
                }),
            },
            categoryDescription: {
                type: "string",
                title: "Category Title",
            },
        },
    };

    const uiSchema = {
        parentCategoryNumber: {
            "ui:disabled": categories.length === 0,
            "ui:placeholder": "No Parent Category",
        },
        categoryDescription: {
            "ui:widget": "textarea",
        },
        imgFileLocation: {
            "ui:widget": (props) => (
                <Suspense fallback={<WaitIcon />}>
                    <IconSelectWidget {...props} />
                </Suspense>
            ),
        },
    };

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "programCatalogCategoriesv2",
            path: {
                programNumber,
            },
        };

        const body = {
            ...formData,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    const otherActions = isNew ? null : (
        <>
            <span className="flex-one" />
            <GridDetailsFooterActions {...props} />
        </>
    );

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={icon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                {notFound ? (
                    <div className="with-padding">
                        <ErrorMessage>{errorText}</ErrorMessage>
                    </div>
                ) : (
                    <div className="category-form">
                        <JsonSchemaForm
                            formRef={formRef}
                            schema={schema}
                            uiSchema={uiSchema}
                            initialValues={initialValues}
                            disabled={isSubmitting}
                            readOnly={isLocked}
                            onSubmit={onSubmit}
                            otherActions={otherActions}
                            noActions
                        />
                    </div>
                )}
            </SideNavBody>
            {!notFound && (
                <SideNavFooter justifyEnd={isLocked} setPrimaryButton={!isLocked}>
                    {!isLocked && (
                        <Button primary onClick={handleSave} disabled={isSubmitting}>
                            {submitText}
                        </Button>
                    )}
                    <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

const getCategoryFullName = ({ categories, categoryNumber }) => {
    const getCategory = (categoryNumber) => categories.filter((c) => c.categoryNumber === categoryNumber)[0];

    let nameParts = [];
    let processed = [];

    let category = getCategory(categoryNumber);

    while (category) {
        if (processed.includes(category.parentCategoryNumber)) {
            // circular regression
            break;
        }

        nameParts.push(category.category);
        processed.push(category.categoryNumber);

        category = getCategory(category.parentCategoryNumber);
    }

    return nameParts.reverse().join(" > ");
};

export default Form;
