import React, { memo } from "react";
import getPath from "lodash/get";
import { formatJsonDateTime } from "components/utils/date";

import withDataGrid from "../../../../DataGrid/withDataGrid";

import IconWrap from "../../../../Icons";
import DataGrid from "../../../../DataGrid";
import NotePreview from "../NotesPanel/NotePreview";

import { auditGridColumnKeys } from "../../../../../views/configureGrids";

import "./AuditGrid.scss";

export const getAuditTypeIcon = (row) =>
    getPath(row, `[${auditGridColumnKeys.audittype}]`, "").toLowerCase().trim() === "project" ? "folder-open" : "workflow";

export const getAuditUserIcon = (row) =>
    getPath(row, `[${auditGridColumnKeys.user}]`, "").toLowerCase().trim() === "system" ? "code" : "user-identity-person-filled";

const customRowActions = [];

const AuditGrid = memo(({ gridId, dataGridConfig }) => {
    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            className="audit-main-panel-grid"
            customRowActions={customRowActions}
            columnCellContent={{
                [auditGridColumnKeys.date]: (column, row) => <div>{formatJsonDateTime(row[column.key], "", true)}</div>,
                [auditGridColumnKeys.user]: (column, row) => (
                    <div className="row-icon-value">
                        <IconWrap icon={getAuditUserIcon(row)} />
                        {row[column.key]}
                    </div>
                ),
                [auditGridColumnKeys.action]: (column, row) => (
                    <div className="row-icon-value">
                        <IconWrap icon={getAuditTypeIcon(row)} title={row[auditGridColumnKeys.audittype] + " audit"} />
                        {row[column.key]}
                    </div>
                ),
                [auditGridColumnKeys.newValue]: (column, row) => (
                    <NotePreview>{(row[column.key] ?? "").replace(/&lt;/gi, "<")}</NotePreview>
                ),
                [auditGridColumnKeys.oldValue]: (column, row) => (
                    <NotePreview>{(row[column.key] ?? "").replace(/&lt;/gi, "<")}</NotePreview>
                ),
            }}
        />
    );
});

export default withDataGrid("audit", AuditGrid);
