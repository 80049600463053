import ArrayField from "./ArrayField";
import DescriptionField from "./DescriptionField";
import FormSchemaField from "./FormSchemaField";
import TitleField from "./TitleField";

export const fields = {
    TitleField: TitleField,
    DescriptionField: DescriptionField,
    ArrayField: ArrayField,
    SchemaField: FormSchemaField,
};
