import LoginFormWrap from "components/ui/LoginFormWrap";
import WaitIcon from "components/ui/WaitIcon";
import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { useResource } from "store/resources/useResource";

import { resetPassword, resetLogin } from "../../../store/login/actions";
import { clearBrowserUrl, openLoginPage } from "../../utils/window";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = memo(() => {
    const dispatch = useDispatch();

    const { userNumber, temporaryPassword } = getUserInfoFromUrl();

    const [twoFactorInfo, isLoading] = useResource({
        resourceName: "2fa",
        key: userNumber,
        path: {
            userName: userNumber,
        },
        forced: true,
    });

    const [passwordInfo] = useResource({
        resourceName: "password",
        resourceId: temporaryPassword,
        forced: true,
    });

    const onSubmit = useCallback(
        (values) => {
            const { authCode, newPassword, recaptcha } = values;

            if (userNumber && temporaryPassword) {
                dispatch(
                    resetPassword({
                        userNumber,
                        temporaryPassword,
                        newPassword,
                        recaptcha,
                        authCode,
                    })
                );
            } else {
                openLoginPage();
            }
        },
        [userNumber, temporaryPassword, dispatch]
    );

    const redirectToLogin = useCallback(() => {
        clearBrowserUrl();
        dispatch(resetLogin());
    }, [dispatch]);

    if (isLoading) {
        return (
            <LoginFormWrap>
                <WaitIcon />
            </LoginFormWrap>
        );
    }

    return (
        <ResetPasswordForm
            title="Reset password"
            passwordHelpText={passwordInfo?.description}
            twoFactorRequired={twoFactorInfo?.twoFactorRequired}
            twoFactorQrCodeExists={twoFactorInfo?.qrCodeExists}
            onSubmit={onSubmit}
            redirectToLogin={redirectToLogin}
        />
    );
});

export default ResetPassword;

const getUserInfoFromUrl = () => {
    const pathParts = window.location.pathname.split("/");
    let userNumber = null;
    let temporaryPassword = null;

    if (pathParts.length > 3) {
        userNumber = pathParts[2];
        temporaryPassword = pathParts[3];
    }

    return { userNumber, temporaryPassword };
};
