import React from "react";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { utilitiesCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import ActionLabel from "../../../../Label/ActionLabel";
import UpdateCalculationPanel from "../UpdateCalculationPanel";
import CalculationDetailsForm from "../CalculationDetailsForm";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import CalculationRevisionsPanel from "../CalculationRevisionsPanel";
import CalculationAssociations from "../CalculationAssociations";
import { calculationsGridCustomRowActions } from "../utils";
import { isChildProgram } from "components/views/ProgramView/utils";

const Grid = ({ gridId, dataGridConfig, utilityNumber, onRowSelectChange, onPageChanged, onOpenPanel, onClosePanel, programNumber }) => {
    const disabled = isChildProgram({ programNumber });

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        const rowData = mapGridRowToObject(utilitiesCalculationsGridColumnKeys, dataItem);

        switch (name) {
            case "details":
                onOpenPanel(
                    <CalculationDetailsForm disabled={disabled} utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />
                );
                break;

            case "update":
                onOpenPanel(
                    <UpdateCalculationPanel disabled={disabled} utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />
                );
                break;
            case "revisions":
                onOpenPanel(
                    <CalculationRevisionsPanel disabled={disabled} utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />
                );
                break;
            case "associations":
                onOpenPanel(
                    <CalculationAssociations disabled={disabled} utilityNumber={utilityNumber} rowData={[rowData]} onClose={onClosePanel} />
                );
                break;
            default:
                break;
        }
    };

    if (disabled) {
        calculationsGridCustomRowActions[0].title = "View Calculation";
        calculationsGridCustomRowActions[0].icon = "eye-visibility-empty";
    }

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={calculationsGridCustomRowActions}
            canSelectRow={!disabled}
            onRowSelectChange={onRowSelectChange}
            onPageChanged={onPageChanged}
            columnCellContent={{
                [utilitiesCalculationsGridColumnKeys.calculationName]: (column, row, onExpandRow, onRowAction) => {
                    return (
                        <>
                            {disabled ? (
                                row[column.key]
                            ) : (
                                <ActionLabel onClick={() => onRowAction({ name: "details", dataItem: row })}>{row[column.key]}</ActionLabel>
                            )}
                        </>
                    );
                },
            }}
        />
    );
};

export default withDataGrid("utilitiesCalculations", Grid);
