import React from "react";
import cn from "classnames";

import "./TextLabel.scss";

const TextLabel = ({ className, children }: TextLabelProps) => <div className={cn("text-label", className)}>{children}</div>;

type TextLabelProps = {
    className?: string;
    children: React.ReactNode;
};

export default TextLabel;
