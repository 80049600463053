import {
    USER_PROFILE_READ_REQUEST,
    USER_PROFILE_READ_SUCCESS,
    USER_PROFILE_READ_ERROR,
    USER_PROFILE_UPDATE_REQUEST,
    USER_PROFILE_UPDATE_SUCCESS,
    USER_PROFILE_UPDATE_ERROR,
    USER_EMAIL_UPDATE_REQUEST,
    USER_EMAIL_UPDATE_SUCCESS,
    USER_EMAIL_UPDATE_ERROR,
    API_GET_AUTHORIZED,
    API_PUT_AUTHORIZED,
    USER_SSO_REQUEST,
    USER_SSO_SUCCESS,
    USER_SSO_ERROR,
} from "../actionTypes";

export const API_BASE_URL = process.env.REACT_APP_USERS_API_BASE_URL;
const ENDPOINT_BASE_URL = `${API_BASE_URL}profile`;

export const readProfile = () => async (dispatch, getState) => {
    if (getState().userProfile.isLoading) {
        return;
    }

    dispatch({
        type: API_GET_AUTHORIZED,
        url: `${ENDPOINT_BASE_URL}`,
        actionTypes: {
            pending: USER_PROFILE_READ_REQUEST,
            response: USER_PROFILE_READ_SUCCESS,
            error: USER_PROFILE_READ_ERROR,
        },
    });
};

export const sendSSOEmail = () => async (dispatch, getState) => {
    if (getState().userProfile.isLoading) {
        return;
    }

    dispatch({
        type: API_GET_AUTHORIZED,
        url: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL + "users/authorizationcode",
        actionTypes: {
            pending: USER_SSO_REQUEST,
            response: USER_SSO_SUCCESS,
            error: USER_SSO_ERROR,
        },
    });
};

export const updateProfile =
    ({ data }) =>
    async (dispatch, getState) => {
        if (getState().userProfile.isLoading) {
            return;
        }

        dispatch({
            type: API_PUT_AUTHORIZED,
            url: `${ENDPOINT_BASE_URL}`,
            body: JSON.stringify(data),
            actionTypes: {
                pending: USER_PROFILE_UPDATE_REQUEST,
                response: USER_PROFILE_UPDATE_SUCCESS,
                error: USER_PROFILE_UPDATE_ERROR,
            },
            passThroughData: { data, showSuccessNotification: true },
        });
    };

export const updateEmail =
    ({ data }) =>
    async (dispatch, getState) => {
        if (getState().userProfile.isLoading) {
            return;
        }

        dispatch({
            type: API_PUT_AUTHORIZED,
            url: `${API_BASE_URL}email`,
            body: JSON.stringify(data),
            actionTypes: {
                pending: USER_EMAIL_UPDATE_REQUEST,
                response: USER_EMAIL_UPDATE_SUCCESS,
                error: USER_EMAIL_UPDATE_ERROR,
            },
            passThroughData: { data, showSuccessNotification: true },
        });
    };
