import React, { useState, useCallback } from "react";
import uuidv1 from "uuid/v1";
import { isNil } from "lodash";

import { useResource } from "store/resources/useResource";
import { pickInitialValues } from "../../../utils/form";
import { AVAILABLE_DASHBOARDS_KEY, getPanelSchema, getPanelUiSchema, getReportType } from "./utils";

import JsonSchemaForm from "../../Form/JsonSchema/JsonSchemaForm";
import AddNewItemPanel from "../../AddNewItemPanel";
import ContentHeader from "../../Title/ContentHeader";
import IconWrap from "../../Icons";
import WaitIcon from "components/ui/WaitIcon";
import ViewPlaceholder from "components/ui/ViewPlaceholder";

const PanelsForm = ({ dashboard, isSubmitting, panelsRef }) => {
    const isNew = isNil(dashboard);
    const [panels, setPanels] = useState(isNew ? [] : dashboard.panels);

    // Preload available panels
    const [, isLoading] = useResource({
        resourceName: "dashboardsAvailable",
        key: AVAILABLE_DASHBOARDS_KEY,
        forced: true,
    });

    const onPanelChange = useCallback(
        (formData) => {
            const updatedPanels = panels.map((p) => ({
                ...p,
                ...(formData.id === p.id ? formData : {}),
            }));

            setPanels(updatedPanels);
        },
        [panels]
    );

    const onPanelAdd = () => {
        const updatedPanels = panels.concat([
            {
                id: uuidv1().toUpperCase(),
                w: 2,
                h: 2,
                x: 0,
                y: 0,
            },
        ]);

        setPanels(updatedPanels);
    };

    const onPanelRemove = useCallback(
        (panel) => {
            const ind = panels.findIndex((p) => p.id === panel.id);

            panelsRef.current.splice(ind, 1);

            const updatedPanels = [...panels];
            updatedPanels.splice(ind, 1);
            setPanels(updatedPanels);
        },
        [panels, panelsRef]
    );

    const getPanels = useCallback(() => {
        return panels.map((panel, index) => {
            if (!panelsRef.current[index]) {
                panelsRef.current[index] = React.createRef();
            }
            const formRef = panelsRef.current[index];

            return (
                <PanelForm
                    key={panel.id}
                    panel={panel}
                    isSubmitting={isSubmitting}
                    formRef={formRef}
                    onChange={onPanelChange}
                    onRemove={onPanelRemove}
                />
            );
        });
    }, [panels, panelsRef, isSubmitting, onPanelChange, onPanelRemove]);

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return (
        <div className="dashboard-settings-panels flex-one">
            <ContentHeader>Widgets</ContentHeader>
            {getPanels()}
            <AddNewItemPanel text="Add Widget" onClick={onPanelAdd}></AddNewItemPanel>
        </div>
    );
};

const PanelForm = ({ panel, isSubmitting, onChange, onRemove, formRef }) => {
    const [schema, setSchema] = useState(getPanelSchema(panel.type));
    const [uiSchema, setUiSchema] = useState(getPanelUiSchema(panel.type));
    const initialValues = pickInitialValues(panel);

    const onPanelChange = (form) => {
        const { formData } = form;

        setSchema(getPanelSchema(formData.type));
        setUiSchema(getPanelUiSchema(formData.type));

        onChange({
            ...formData,
            reportType: getReportType(formData.reportId),
        });
    };

    const onPanelRemove = () => {
        onRemove(panel);
    };

    return (
        <div className="dashboard-settings-panel-form">
            <div className="dashboard-settings-panel-controls">
                <IconWrap icon="clear-close" title="Remove Panel" onClick={onPanelRemove} />
            </div>
            <JsonSchemaForm
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                disabled={isSubmitting}
                onChange={onPanelChange}
                noActions
            />
        </div>
    );
};

export default PanelsForm;
