import { RowLayoutConfiguration } from "components/ui/PortalBuilder/types";

// Row layouts types for 12 column grid in portal.
export const RowLayoutTypes: RowLayoutTypesModel = {
    layout1: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 12, // full width starting from medium devices
                },
            },
        ],
    },
    layout2: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 6, // half width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 6, // half width starting from medium devices
                },
            },
        ],
    },
    layout3: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 4, // one third of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 4, // one third of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 4, // one third of width starting from medium devices
                },
            },
        ],
    },
    layout4: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 4, // one third of width starting from medium devices
                },
            },
            {
                span: 2,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 8, // two thirds of width starting from medium devices
                },
            },
        ],
    },
    layout5: {
        columns: [
            {
                span: 2,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 8, // two thirds of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 4, // one third of width starting from medium devices
                },
            },
        ],
    },
    layout6: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
        ],
    },
    layout7: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 2,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 6, // half width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
        ],
    },
    layout8: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 2,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 6, // half width starting from medium devices
                },
            },
        ],
    },
    layout9: {
        columns: [
            {
                span: 2,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 6, // half width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
        ],
    },
    layout10: {
        columns: [
            {
                span: 3,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 9, // three quarters of width starting from medium devices
                },
            },
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
        ],
    },
    layout11: {
        columns: [
            {
                span: 1,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 3, // one quarter of width starting from medium devices
                },
            },
            {
                span: 3,
                portalProps: {
                    xs: 12, // full width starting from xs devices
                    md: 9, // three quarters of width starting from medium devices
                },
            },
        ],
    },
};

export interface RowLayoutTypesModel {
    layout1: RowLayoutConfiguration;
    layout2: RowLayoutConfiguration;
    layout3: RowLayoutConfiguration;
    layout4: RowLayoutConfiguration;
    layout5: RowLayoutConfiguration;
    layout6: RowLayoutConfiguration;
    layout7: RowLayoutConfiguration;
    layout8: RowLayoutConfiguration;
    layout9: RowLayoutConfiguration;
    layout10: RowLayoutConfiguration;
    layout11: RowLayoutConfiguration;
}
