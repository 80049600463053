import React, { useCallback, memo } from "react";
import { useSelector } from "react-redux";

import { utilitiesCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import { calculationTypes } from "../utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import AddCalculationPanel from "../AddCalculationPanel";
import Controls from "./Controls";
import Grid from "./Grid";

const EventsCalculationsTab = memo(({ utilityNumber, onOpenPanel, onClosePanel, programNumber }) => {
    const user = useSelector((state) => state.user?.name);

    const dataGridId = `${utilityNumber}-events-calculations-grid`;

    const onAddCalculation = useCallback(() => {
        onOpenPanel(
            <AddCalculationPanel
                calculationType={calculationTypes.event}
                utilityNumber={utilityNumber}
                user={user}
                onClose={onClosePanel}
            />
        );
    }, [utilityNumber, user, onOpenPanel, onClosePanel]);

    const onExport = useCallback(() => {
        exportDatagridToCSV({
            dataGridId,
            fileName: "events_calculations",
            fileNamePostfix: utilityNumber,
        });
    }, [dataGridId, utilityNumber]);

    return (
        <div className="events-calculations-tab tab-list-tab-content flex-column fill-height">
            <Controls programNumber={programNumber} onAddCalculation={onAddCalculation} onExport={onExport} />
            <Grid
                programNumber={programNumber}
                gridId={dataGridId}
                filter={`${utilitiesCalculationsGridColumnKeys.utilityNumber}=${utilityNumber}|${utilitiesCalculationsGridColumnKeys.calculationType}=Event_Attr`}
                utilityNumber={utilityNumber}
                onOpenPanel={onOpenPanel}
                onClosePanel={onClosePanel}
            />
        </div>
    );
});

export default EventsCalculationsTab;
