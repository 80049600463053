import React from "react";
import { deleteResource, createResource, getResource } from "../../../../../store/resources/actions";
import { modalOpen } from "../../../../../store/modal/actions";
import { useResource } from "../../../../../store/resources/useResource";

export const getBudgetLineInvoicesGridId = ({ budgetLineNumber }) => {
    return `${budgetLineNumber}-invoices-grid`;
};

export const getBudgetLineAmountsGridId = ({ budgetLineNumber }) => {
    return `${budgetLineNumber}-amounts-grid`;
};

export const getBudgetLineAttributesGridId = ({ budgetLineNumber }) => {
    return `${budgetLineNumber}-attributes-grid`;
};

export const transformFormErrors = (errors) => {
    return errors.map((error) => {
        if (error.name === "pattern") {
            error.message = "Invalid field value";
        }

        return error;
    });
};

export const useBudgetLineAttributeResource = ({ utilityNumber, contractNumber, budgetLineNumber, resourceId }) => {
    return useResource({
        resourceName: "utilitiesContractsBudgetLinesAttributes",
        resourceId,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
    });
};

export const onDeleteBudgetLineAttribute = ({ utilityNumber, contractNumber, budgetLineNumber, dataItem, onRefresh, dispatch }) => {
    const message = (
        <>
            <p>This action will delete this budget attribute and cannot be undone.</p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Budget Attribute",
                overlayClassName: "modal-styled",
                className: "delete-budget-attribute-modal modal-sm",
                modalIcon: "delete-trash-empty",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    dispatch(
                        deleteResource({
                            resourceName: "utilitiesContractsBudgetLinesAttributes",
                            resourceId: dataItem.attributeNumber,
                            path: {
                                utilityNumber,
                                contractNumber,
                                budgetLineNumber,
                            },
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};

export const onDeleteAssignement = ({
    utilityNumber,
    contractNumber,
    budgetLineNumber,
    dataItem,
    assignedPrograms,
    onRefresh,
    dispatch,
}) => {
    const message = (
        <>
            <p>This action will remove program assignment and cannot be undone.</p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Remove Program Assignment",
                overlayClassName: "modal-styled",
                className: "remove-budget-program-assignment-modal modal-sm",
                modalIcon: "hub-filled icon-wrap-combined remove",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    const programList = assignedPrograms
                        .filter((r) => r.assigned.toLowerCase() === "yes")
                        .filter((p) => p.programNumber !== dataItem.programNumber)
                        .map((p) => p.programNumber);

                    dispatch(
                        createResource({
                            resourceName: "utilitiesContractsBudgetLinesPrograms",
                            path: {
                                utilityNumber,
                                contractNumber,
                                budgetLineNumber,
                            },
                            body: {
                                programList,
                            },
                            onComplete: onRefresh,
                        })
                    );
                },
            },
        })
    );
};
export const onRefreshAssignmentsList = ({ utilityNumber, contractNumber, budgetLineNumber, dispatch }) => {
    dispatch(
        getResource({
            resourceName: "utilitiesContractsBudgetLinesPrograms",
            key: budgetLineNumber,
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        })
    );
};
export const onDeleteBudgetLineAmount = ({ utilityNumber, contractNumber, budgetLineNumber, dataItem, onRefresh, dispatch }) => {
    const message = (
        <>
            <p>This action will permanently delete this budget amount.</p>
            <p>Once completed, this action cannot be undone.</p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Budget Amount",
                overlayClassName: "modal-styled",
                className: "delete-budget-amount-modal modal-sm",
                modalIcon: "delete-trash-empty",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    dispatch(
                        deleteResource({
                            resourceName: "utilitiesContractsBudgetLinesAmounts",
                            resourceId: dataItem.amountNumber,
                            path: {
                                utilityNumber,
                                contractNumber,
                                budgetLineNumber,
                            },
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};
