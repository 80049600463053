import React, { memo } from "react";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";

import "./ButtonWidgetProperty.scss";

export const ButtonTypeProperty = memo((props) => {
    const { value, onChange } = props;

    const buttonTypes = [
        {
            text: "Primary",
            value: "primary",
        },
        {
            text: "Secondary",
            value: "secondary",
        },
    ];

    const secondaryButtonTypes = [
        {
            text: "Filled",
            value: "filled",
        },
        {
            text: "Ghost",
            value: "ghost",
        },
    ];

    return (
        <PropertyListItem className="property-list-item--button-type" borderTop={true}>
            <SelectBoxProperty
                id="variant"
                value={value.variant}
                defaultValue={"primary"}
                title="Button type"
                items={buttonTypes}
                borderBottom={false}
                onChange={onChange}
            />
            {value.variant === "secondary" && (
                <div className="secondary-type">
                    <SelectBoxProperty
                        id="secondaryButtonType"
                        value={value.secondaryButtonType}
                        title="Button fill"
                        defaultValue={"filled"}
                        items={secondaryButtonTypes}
                        borderBottom={false}
                        onChange={onChange}
                        borderTop={true}
                    />
                </div>
            )}
        </PropertyListItem>
    );
});
