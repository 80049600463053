import React, { memo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { ScrollSyncPane } from "react-scroll-sync";
import { availableViews } from "../../views/configureViews";
import WindowHeader from "./WindowHeader/WindowHeader";
import WindowContent from "./WindowContent";
import SideNavContainer from "../SideNav/SideNavContainer";
import SideNavRoot, { sideNavPosition, getViewSideNavKey } from "../SideNav/SideNavRoot";
import { WindowContext } from "./Window";
import ErrorBoundary from "components/utils/ErrorBoundary";
import { openConfirmModal } from "../Modal/utils";
import WindowHeaderNotification from "./WindowHeaderNotification";
import { splitWindowPositionType } from "components/utils/window";

const SplitWindow = memo(({ containerName, viewName, className, onOpen, onClearSplit, onRemoveSplit }) => {
    const views = useSelector((state) => state.window[containerName])?.views || [];
    const view = views.find((i) => i.name === viewName) || {};

    const {
        name,
        showHeader,
        isSplitViewSync,
        leftViewName,
        leftViewComponent,
        leftViewProps,
        leftViewHeader,
        leftViewClassName,
        rightViewName,
        rightViewComponent,
        rightViewProps,
        rightViewHeader,
        rightViewClassName,
        active,
    } = view;

    const LeftViewComponent = availableViews[leftViewComponent];
    const RightViewComponent = availableViews[rightViewComponent];

    const isLeftViewNotificationVisible =
        useSelector((state) => state.window[containerName]?.isNotificationVisible?.[leftViewName]) ?? false;
    const isRightViewNotificationVisible =
        useSelector((state) => state.window[containerName]?.isNotificationVisible?.[rightViewName]) ?? false;

    const leftView = {
        name: leftViewName,
        header: leftViewHeader,
        component: leftViewComponent,
        props: leftViewProps,
        className: leftViewClassName,
    };

    const rightView = {
        name: rightViewName,
        header: rightViewHeader,
        component: rightViewComponent,
        props: rightViewProps,
        className: rightViewClassName,
    };

    const leftViewSideNavLeftId = getViewSideNavKey({
        viewName: leftViewName,
        position: sideNavPosition.left,
        splitWindowPosition: splitWindowPositionType.left,
    });
    const leftViewSideNavRightId = getViewSideNavKey({
        viewName: leftViewName,
        position: sideNavPosition.right,
        splitWindowPosition: splitWindowPositionType.left,
    });
    const rightViewSideNavLeftId = getViewSideNavKey({
        viewName: rightViewName,
        position: sideNavPosition.left,
        splitWindowPosition: splitWindowPositionType.right,
    });
    const rightViewSideNavRightId = getViewSideNavKey({
        viewName: rightViewName,
        position: sideNavPosition.right,
        splitWindowPosition: splitWindowPositionType.right,
    });

    const handleSplitViewClose = (side) => {
        if (side === "left") {
            if (view.leftViewOnClose) {
                view.leftViewOnClose(null, true);
            } else {
                onClearSplit(name, "left");
            }
        } else if (side === "right") {
            if (view.rightViewOnClose) {
                view.rightViewOnClose(null, true);
            } else {
                onClearSplit(name, "right");
            }
        }
    };

    return (
        <div className={className} hidden={!active}>
            <WindowContent active={active}>
                <WindowContext.Provider
                    value={{
                        isActiveView: active,
                        isSplitView: true,
                        splitWindowPosition: splitWindowPositionType.left,
                        viewName: leftViewName,
                    }}
                >
                    <div
                        className={cn("window-view left flex-column flex-one-in-row no-shrink", leftView.className, {
                            "window--notification-visible": isLeftViewNotificationVisible,
                        })}
                    >
                        <SideNavContainer sideNavIds={[leftViewSideNavLeftId, leftViewSideNavRightId]}>
                            <SideNavRoot id={leftViewSideNavLeftId} onConfirmClose={openConfirmModal} />
                            <WindowHeaderNotification view={leftView} />
                            <WindowHeader
                                view={leftView}
                                onClose={leftViewComponent !== "SplitView" ? () => handleSplitViewClose("left") : null}
                            />
                            <div className="window-body-container flex-one-in-column popup-boundary">
                                <div className="window-body flex-column flex-one">
                                    <ScrollSyncPane>
                                        <ErrorBoundary>
                                            {LeftViewComponent && (
                                                <LeftViewComponent
                                                    {...leftViewProps}
                                                    onWindowOpen={onOpen}
                                                    isSplitViewSync={isSplitViewSync}
                                                />
                                            )}
                                        </ErrorBoundary>
                                    </ScrollSyncPane>
                                </div>
                            </div>
                            <SideNavRoot id={leftViewSideNavRightId} onConfirmClose={openConfirmModal} />
                        </SideNavContainer>
                    </div>
                </WindowContext.Provider>
                <WindowContext.Provider
                    value={{
                        isActiveView: active,
                        isSplitView: true,
                        splitWindowPosition: splitWindowPositionType.right,
                        viewName: rightViewName,
                    }}
                >
                    <div
                        className={cn("window-view right flex-column flex-one-in-row no-shrink", rightView.className, {
                            "window--notification-visible": isRightViewNotificationVisible,
                        })}
                    >
                        <SideNavContainer sideNavIds={[rightViewSideNavLeftId, rightViewSideNavRightId]}>
                            <SideNavRoot id={rightViewSideNavLeftId} onConfirmClose={openConfirmModal} />
                            <WindowHeaderNotification view={rightView} />
                            {showHeader && (
                                <WindowHeader
                                    view={rightView}
                                    onClose={rightViewComponent !== "SplitView" ? () => handleSplitViewClose("right") : null}
                                    onRemoveSplit={() => onRemoveSplit(name)}
                                />
                            )}
                            <div className="window-body-container flex-one-in-column popup-boundary">
                                <div className="window-body flex-column flex-one">
                                    <ScrollSyncPane>
                                        <ErrorBoundary>
                                            {RightViewComponent && (
                                                <RightViewComponent
                                                    {...rightViewProps}
                                                    onWindowOpen={onOpen}
                                                    isSplitViewSync={isSplitViewSync}
                                                />
                                            )}
                                        </ErrorBoundary>
                                    </ScrollSyncPane>
                                </div>
                            </div>
                            <SideNavRoot id={rightViewSideNavRightId} onConfirmClose={openConfirmModal} />
                        </SideNavContainer>
                    </div>
                </WindowContext.Provider>
            </WindowContent>
        </div>
    );
});

export default SplitWindow;
