import React, { useCallback, memo, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { eventsGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { appIsLockedText } from "../utils";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { systemUserRights } from "components/utils/user";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import AddEvent from "./AddEvent";
import EventsGrid from "./EventsGrid";

import { modalOpen } from "store/modal/actions";
import { getData } from "store/dataGrid/actions";
import { deleteResource } from "store/resources/actions";

import "../ProjectCommonStyles.scss";
import Button from "components/ui/Button";
import GridSelectedItemsLabel from "components/ui/DataGrid/GridSelectedItemsLabel";
import useMultiPageRowSelect from "store/dataGrid/useMultiPageRowSelect";
import { useProgramRights } from "store/resources/useResource";
import { refreshSidebarEvents } from "./utils";

const EventsPanel = memo(
    ({
        panel,
        programNumber = panel && panel.data && panel.data.programNumber,
        applicationNumber = panel && panel.data && panel.data.applicationNumber,
    }) => {
        const dispatch = useDispatch();

        const [selectedRows, setSelectedRows] = useState([]);

        const gridId = `${applicationNumber}-events-grid`;

        const isAppLocked = isApplicationLocked({ applicationNumber });
        const [programRights = []] = useProgramRights({ programNumber });
        const isAddEventDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_EVENT);
        const isEditEventDisabled =
            isAppLocked ||
            (!programRights.includes(systemUserRights.VISIONDSM_MANAGE_EVENTS) &&
                !programRights.includes(systemUserRights.VISIONDSM_ADD_EVENT));

        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ className: "add-event-sidenav-panel" });

        const onRowSelect = (selectedRows) => setSelectedRows(selectedRows || []);

        const handleAdd = useCallback(() => {
            handleOpenSidePanel(
                <AddEvent applicationNumber={applicationNumber} onClose={handleCloseSidePanel} programNumber={programNumber} />
            );
        }, [applicationNumber, programNumber, handleOpenSidePanel, handleCloseSidePanel]);

        const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
            dataGridId: gridId,
            onRowSelect,
        });

        const onDeleteEvents = useCallback(() => {
            const eventNumbers = selectedRows.map((item) => item[eventsGridColumnKeys.number]);
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Delete Events",
                        overlayClassName: "modal-styled",
                        className: "delete-event-confirmation-modal modal-sm",
                        modalIcon: "delete-trash-empty",
                        content: (
                            <p>
                                Are you sure you want to permanently delete <b>{eventNumbers.length}</b> selected events? This cannot be
                                undone.
                            </p>
                        ),
                        footerContentCenter: true,
                        onConfirm: async () => {
                            await eventNumbers.forEach((eventId) => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "event",
                                        resourceId: eventId,
                                        path: {
                                            appId: applicationNumber,
                                        },
                                        onSuccess: () => {
                                            setSelectedRows([]);
                                            refreshSidebarEvents({ applicationNumber });
                                        },
                                    })
                                );
                            });
                            dispatch(
                                getData({
                                    dataGridId: `${applicationNumber}-events-grid`,
                                })
                            );
                        },
                    },
                })
            );
        }, [selectedRows, applicationNumber, dispatch]);

        const gridSort = useMemo(
            () => [
                {
                    field: eventsGridColumnKeys.dateEntered,
                    dir: "desc",
                },
            ],
            []
        );

        return (
            <div className="panel events-panel-large">
                <PanelHeaderLarge title="Events" />
                <div className="data-grid-controls flex-row justify-space-between">
                    <div className="data-grid-controls__left-side-actions flex-row">
                        {selectedRows.length ? (
                            <GridSelectedItemsLabel
                                text={
                                    selectedRows.length ? (
                                        <>
                                            Event
                                            {selectedRows.length > 1 ? "s" : ""} <br /> selected
                                        </>
                                    ) : (
                                        ""
                                    )
                                }
                                count={selectedRows.length}
                                onClear={onRowSelectClear}
                            />
                        ) : (
                            <div className="select-label">
                                Select <br /> Events
                            </div>
                        )}
                        <Button
                            primary
                            icon="delete-trash-empty"
                            disabled={isEditEventDisabled || selectedRows.length === 0}
                            onClick={onDeleteEvents}
                        >
                            Delete Events
                        </Button>
                    </div>
                    <div className="data-grid-controls__right-side-actions flex-row  align-end">
                        <IconWithLabel
                            icon={"plus"}
                            disabled={isAddEventDisabled}
                            title={isAppLocked ? appIsLockedText : undefined}
                            onClick={handleAdd}
                        >
                            Add Event
                        </IconWithLabel>
                        <IconWithLabel
                            withHandMadeIcon
                            onClick={() =>
                                exportDatagridToCSV({
                                    dataGridId: gridId,
                                    fileName: "application_events",
                                    fileNamePostfix: applicationNumber,
                                })
                            }
                        >
                            Export CSV
                        </IconWithLabel>
                    </div>
                </div>
                <EventsGrid
                    onRowSelect={onRowSelect}
                    gridId={gridId}
                    filter={`${eventsGridColumnKeys.appid}=${applicationNumber}`}
                    sort={gridSort}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    onRowSelectChange={onRowSelectChange}
                    onPageChanged={onPageChanged}
                    isEditEventDisabled={isEditEventDisabled}
                />
            </div>
        );
    }
);

export default EventsPanel;
