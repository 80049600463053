import React, { useCallback, useContext, useState, useEffect, useMemo, memo } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { useId } from "../../utils/hooks/useId";
import { isValidStrings } from "../../utils/validation";
import { WORKFLOW, NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS, HEADER_PREFIX_DND_ID } from "../../constants/workflow";

import IconWrap from "../../../Icons";
import Steps from "./Steps";
import IconWithLabel from "../../../Icons/IconWithLabel";
import AddNewItemPanel from "../../../AddNewItemPanel";
import { ErrorMessage } from "../../../Message";
import { WorkflowContext } from "../../context/WorkflowContext";
import DragHandle from "../../../DragHandle";
import CounterBox from "../../../CounterBox";
import SelectBox from "../../../SelectBox";

import "./Status.scss";

export const Status = memo(
    ({
        id,
        number,
        showSteps,
        isConfirmed = true,
        index,
        indexNumber,
        steps,
        processModeID,
        state,
        statusID,
        editStatus,
        workflowStateNumber,
        onSetActiveItem,
        onCreateWorkflowStep,
    }) => {
        const { activeItem, onDeleteWorkflowStatus, processModes, isLocked } = useContext(WorkflowContext);

        const isSelected = useMemo(() => {
            return activeItem && activeItem.statusNumber === number && activeItem.type === WORKFLOW.STATUS;
        }, [activeItem, number]);

        useEffect(() => {
            const element = document.querySelector(".wf__status-info-wrap--new-status-placeholder");
            if (element) {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }, [number]);

        const isActiveStatus = useMemo(() => {
            return activeItem && activeItem.statusNumber === number;
        }, [activeItem, number]);

        const isActive = useMemo(() => {
            return statusID === WORKFLOW.ACTIVE_STATUS_ID;
        }, [statusID]);

        const [isStepsVisible, setStepsVisibility] = useState(showSteps || isSelected);

        useEffect(() => {
            if (isStepsVisible && !showSteps && !isActiveStatus) {
                setStepsVisibility(false);
            } else if (!isStepsVisible && (showSteps || isActiveStatus)) {
                setStepsVisibility(true);
            }
        }, [showSteps, isActiveStatus, isStepsVisible]);

        const processModeLabel = useMemo(() => {
            if (processModeID) {
                const result = processModes.find(({ val }) => Number(val) === processModeID);

                if (result) {
                    return result.display;
                }
            }
        }, [processModes, processModeID]);

        const handleClick = useCallback(
            (e) => {
                onSetActiveItem(e, number, WORKFLOW.STATUS);

                if (isStepsVisible && !showSteps && isSelected) {
                    setStepsVisibility(false);
                }
            },
            [number, showSteps, isStepsVisible, isSelected, onSetActiveItem]
        );

        const handleClickDelete = useCallback((e) => onDeleteWorkflowStatus(e, number), [number, onDeleteWorkflowStatus]);

        const draggableId = useId(number);

        const statusClassName = cn("wf__status flex-column", {
            "wf__status--selected": isSelected,
            "wf__status--disabled wf-status-disabled": !isActive && isConfirmed,
            "wf__status--steps-visible": isStepsVisible,
        });

        if (NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS.includes(workflowStateNumber)) {
            return (
                <Droppable
                    droppableId={`${HEADER_PREFIX_DND_ID}${draggableId}`}
                    type={WORKFLOW.STEPS_CONTAINER}
                    isDropDisabled={!!isStepsVisible}
                >
                    {(dropProvided, snapshot) => (
                        <div className={statusClassName} ref={dropProvided.innerRef}>
                            <StatusHead
                                statusNumber={indexNumber}
                                isConfirmed={isConfirmed}
                                isActive={isActive}
                                isLocked={isLocked}
                                isSelected={isSelected || snapshot.isDraggingOver}
                            />

                            <StatusBody
                                id={id}
                                number={number}
                                state={state}
                                isConfirmed={isConfirmed}
                                isLocked={isLocked}
                                steps={steps}
                                isSelected={isSelected || snapshot.isDraggingOver}
                                isStepsVisible={isStepsVisible}
                                onClick={handleClick}
                                onSetActiveItem={onSetActiveItem}
                                onCreateWorkflowStep={onCreateWorkflowStep}
                                processModeLabel={processModeLabel}
                                editStatus={editStatus}
                                handleClickDelete={handleClickDelete}
                            />
                            <IconWrap className="not-orderable" iconWrap="view-headline-list-text" title="Status is not orderable" />
                        </div>
                    )}
                </Droppable>
            );
        }

        return (
            <Draggable draggableId={draggableId} index={index}>
                {(dragProvided) => (
                    <Droppable
                        droppableId={`${HEADER_PREFIX_DND_ID}${draggableId}`}
                        type={WORKFLOW.STEPS_CONTAINER}
                        isDropDisabled={!!isStepsVisible}
                    >
                        {(dropProvided, snapshot) => {
                            return (
                                <div
                                    className={statusClassName}
                                    ref={(ref) => {
                                        dragProvided.innerRef(ref);
                                        dropProvided.innerRef(ref);
                                    }}
                                    {...dragProvided.draggableProps}
                                >
                                    <StatusHead
                                        statusNumber={indexNumber}
                                        isConfirmed={isConfirmed}
                                        isActive={isActive}
                                        isLocked={isLocked}
                                        isSelected={isSelected || snapshot.isDraggingOver}
                                    />
                                    <StatusBody
                                        id={id}
                                        number={number}
                                        state={state}
                                        isConfirmed={isConfirmed}
                                        isLocked={isLocked}
                                        steps={steps}
                                        isSelected={isSelected || snapshot.isDraggingOver}
                                        isStepsVisible={isStepsVisible}
                                        onClick={handleClick}
                                        onSetActiveItem={onSetActiveItem}
                                        onCreateWorkflowStep={onCreateWorkflowStep}
                                        processModeLabel={processModeLabel}
                                        editStatus={editStatus}
                                        handleClickDelete={handleClickDelete}
                                    />
                                    <DragHandle dragVertical {...dragProvided.dragHandleProps} />
                                </div>
                            );
                        }}
                    </Droppable>
                )}
            </Draggable>
        );
    }
);

const StatusHead = memo(({ statusNumber, isConfirmed, isActive, isLocked, isSelected }) => {
    return (
        <div className="wf__status-head flex-column justify-end">
            <div className={"wf__status-head-counter" + (!isSelected && !isConfirmed ? " error" : "")}>Status {statusNumber}</div>
            {isConfirmed && <div className="wf__status-head-state">{isActive ? "Active" : "Disabled"}</div>}
        </div>
    );
});

const StatusBody = memo(
    ({
        id,
        number,
        state,
        isConfirmed,
        isLocked,
        steps,
        isSelected,
        isStepsVisible,
        onClick,
        onCreateWorkflowStep,
        onSetActiveItem,
        processModeLabel,
        editStatus,
        handleClickDelete,
    }) => {
        if (isConfirmed) {
            return (
                <div
                    className={cn("wf__status-body", {
                        "wf__status-body--selected": isSelected,
                    })}
                >
                    <div
                        className={cn("wf__status-info", {
                            "wf__status-info--selected": isSelected,
                            "wf__status-info--with-steps": !isEmpty(steps),
                            "wf__status-info--no-steps": isEmpty(steps),
                        })}
                        onClick={onClick}
                    >
                        <div
                            className={
                                "wf__status-info-wrap flex-row align-center justify-space-between" +
                                (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && !isLocked ? " status-not-saved" : "")
                            }
                        >
                            <div className="wf__status-lead-text flex-row flex-one align-center justify-space-between">
                                {id && (
                                    <div className="wf__status-head-id">
                                        <div className="wf__status-head-id-text">
                                            <span>Status ID:</span>
                                            {id}
                                        </div>
                                    </div>
                                )}
                                <div className="wf__status-title flex-one">
                                    <div
                                        className={cn("wf__status-title-text", {
                                            "wf__status-title-text--selected": isSelected,
                                            "wf__status-title-text--steps-visible": isStepsVisible,
                                        })}
                                    >
                                        {isValidStrings(state) ? state : <ErrorMessage>Please enter valid status title</ErrorMessage>}
                                    </div>
                                </div>
                                {processModeLabel && (
                                    <div className="wf__status-head-process-mode">
                                        <div className="wf__status-head-process-mode-text">
                                            <span>Process Mode:</span>
                                            {processModeLabel}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="wf__status-steps-wrap flex-row justify-end">
                                {!isEmpty(steps) ? (
                                    <SelectBox
                                        selectBoxExpandable
                                        iconArrow={isStepsVisible ? "shevron-small-up-expand-less" : "shevron-small-down-expand-more"}
                                        selectBoxText={"Step" + (steps.length > 1 ? "s" : "")}
                                        selectBoxSelected={isStepsVisible}
                                    >
                                        <CounterBox>{steps.length}</CounterBox>
                                    </SelectBox>
                                ) : (
                                    <div className="wf__status-add-step">
                                        <IconWithLabel
                                            icon="plus"
                                            title={isLocked ? "Worklfow is Disabled" : "Add Step"}
                                            iconWithLabelRight
                                            disabled={isLocked}
                                            onClick={(e) => onCreateWorkflowStep(e, number)}
                                        >
                                            Add Step
                                        </IconWithLabel>
                                    </div>
                                )}
                            </div>
                            <div className="wf__status-actions">
                                <IconWrap
                                    iconWrapTheme={isSelected}
                                    icon={isSelected ? "shevron-in-circle-left-filled" : "shevron-in-circle-right-empty"}
                                    title={isSelected ? "Hide Status Properties" : "Show Status Properties"}
                                    onClick={(e) => onSetActiveItem(e, number, WORKFLOW.STATUS)}
                                />
                                {editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && !isLocked && (
                                    <IconWrap icon="delete-trash-empty" title="Delete Workflow Status" onClick={handleClickDelete} />
                                )}
                            </div>
                        </div>
                    </div>
                    {isStepsVisible && (
                        <div
                            className={cn("wf__status-steps-list-wrap", {
                                "wf__status-steps-list-wrap--selected": isSelected,
                                "wf__status-steps-list-wrap--visible": !isEmpty(steps) || isStepsVisible,
                            })}
                        >
                            <Steps steps={steps} statusNumber={number} onSetActiveItem={onSetActiveItem} />
                            {(!isEmpty(steps) || isStepsVisible) && (
                                <AddNewItemPanel
                                    text="Add Step"
                                    disabled={isLocked}
                                    onClick={(e) => onCreateWorkflowStep(e, number)}
                                ></AddNewItemPanel>
                            )}
                        </div>
                    )}
                </div>
            );
        }

        if (!isConfirmed) {
            return (
                <div
                    className={cn("wf__status-body", {
                        "wf__status-body--selected": isSelected,
                    })}
                >
                    <div
                        className={cn("wf__status-info", {
                            "wf__status-info--selected new": isSelected,
                            "wf__status-info--error": !isSelected,
                        })}
                    >
                        <div className="wf__status-info-wrap flex-row align-center justify-space-between">
                            {!isSelected ? (
                                <div className="flex-one">
                                    <ErrorMessage>Status creation is not completed yet</ErrorMessage>
                                </div>
                            ) : (
                                <div className="wf__status-info-wrap--new-status-placeholder flex-one">new status placeholder</div>
                            )}
                            <div className="wf__status-actions">
                                <IconWrap
                                    iconWrapTheme={isSelected}
                                    icon={isSelected ? "shevron-in-circle-left-filled" : "shevron-in-circle-right-empty"}
                                    title={isSelected ? "Hide Status Properties" : "Show Status Properties"}
                                    onClick={(e) => onSetActiveItem(e, number, WORKFLOW.STATUS)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }
);
