import React, { useCallback, useState, memo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { pickBy } from "lodash";

import { modalOpen } from "../../../../../../store/modal/actions";
import { submitResource, submitByRef } from "../../../../../utils/form";
import { useResource } from "../../../../../../store/resources/useResource";

import RawHtml from "../../../../RawHtml";
import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = memo(({ utilityNumber, contractNumber, documentNumber, dataItem, onClose, gridRefresh, sidePanel }) => {
    const formRef = useRef();

    const dispatch = useDispatch();

    const isNew = dataItem === undefined;

    const resourceId = isNew ? null : dataItem.noteNumber;

    const title = isNew ? "Add Note" : "Edit Note";

    const titleIcon = isNew ? "plus" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useResource({
        resourceName: "utilitiesContractsInvoiceDocumentsNotes",
        resourceId,
        forced: true,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
    });

    const schema = {
        type: "object",
        required: ["note", "noteType"],
        properties: {
            note: {
                type: "string",
                title: "Note",
            },
            noteType: {
                type: "string",
                title: "Note Type",
                enum: ["private", "public"],
            },
        },
    };

    const uiSchema = {
        "ui:rootFieldId": "note",
        note: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
        noteType: {
            "ui:widget": "radio",
            "ui:options": {
                inline: true,
            },
        },
    };

    const initialValues = isNew ? {} : pickBy(resource);
    // Sometimes a SQL Proc will capitalize noteType to "Private" or "Public"
    if (initialValues.noteType) {
        initialValues.noteType = initialValues.noteType.toLowerCase();
    }

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = {
                resourceName: "utilitiesContractsInvoiceDocumentsNotes",
                path: {
                    utilityNumber,
                    contractNumber,
                    documentNumber,
                },
            };

            const body = {
                ...formData,
            };

            if (body.noteType === "public") {
                const text =
                    "<p>The note is designated as <strong>Public</strong> and client will be notified about it via email.</p><p>Are you sure you want to do it?</p>";

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: isNew ? "Create Note" : "Edit Note",
                            modalIcon: isNew ? "plus" : "edit-empty",
                            overlayClassName: "modal-styled",
                            className: "modal-sm",
                            footerContentCenter: true,
                            content: <RawHtml>{text}</RawHtml>,
                            onConfirm: () => {
                                submitResource({
                                    resourceParams,
                                    resourceId,
                                    body,
                                    onRefresh: gridRefresh,
                                    onSuccess: sidePanel.close,
                                    setSubmitting,
                                });
                            },
                        },
                    })
                );
            } else {
                submitResource({
                    resourceParams,
                    resourceId,
                    body,
                    onRefresh: gridRefresh,
                    onSuccess: sidePanel.close,
                    setSubmitting,
                });
            }
        },
        [utilityNumber, contractNumber, documentNumber, isNew, resourceId, gridRefresh, sidePanel, dispatch]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
