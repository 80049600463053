import React from "react";
import { closeModal, openFormModal } from "components/ui/Modal/utils";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import Label from "components/ui/Label";
import { DictionaryItem } from "./DictionaryItem";
import { ManageItemsForm } from "./ManageItemsForm";

import "./DictionaryWidget.scss";

const DictionaryWidget = (props) => {
    const { value, onChange, disabled, readonly, required } = props;

    const currentValue = value || "";
    const items = currentValue === "" ? [] : currentValue.split("\n");

    const onItemChange = (itemIndex) => (value) => {
        const result = items.map((item, index) => (index === itemIndex ? value : item)).join("\n");

        onChange(result);
    };

    const onItemRemove = (itemIndex) => () => {
        const result = items.filter((item, index) => index !== itemIndex).join("\n");

        onChange(result);
    };

    const onAddItem = () => {
        const result = items.concat(["="]).join("\n");

        onChange(result);
    };

    const onItemsManage = () => {
        openFormModal({
            title: "Manage Item Values",
            form: <ManageItemsForm value={value} onSubmit={onChange} onClose={closeModal} />,
        });
    };

    return (
        <div className="dictionary-widget">
            <div className="flex-row justify-space-between">
                <Label className="control-label">
                    <span>Item Values</span>
                    {required && <span className="required" />}
                </Label>
                <IconWithLabel disabled={readonly || disabled} icon="configuration" onClick={onItemsManage}>
                    Manage
                </IconWithLabel>
            </div>
            {items.map((item, index) => (
                <DictionaryItem
                    key={index}
                    value={item}
                    disabled={disabled || readonly}
                    onChange={onItemChange(index)}
                    onRemove={onItemRemove(index)}
                />
            ))}
            <IconWithLabel className="dictionary-widget__add-item" icon="plus" disabled={disabled || readonly} onClick={onAddItem}>
                Add
            </IconWithLabel>
        </div>
    );
};

export default DictionaryWidget;
