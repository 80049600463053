import { Cluster, ClusterStats } from "@googlemaps/markerclusterer";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { isString } from "lodash";

const libraries: Libraries = ["maps", "marker", "visualization", "geometry", "drawing", "places"];

export const useMapLoader = () => {
    return useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY!,
        libraries,
    });
};

export const clusterRenderer = (cluster: Cluster, stats: ClusterStats, map: google.maps.Map) => {
    // Get full count from markers
    const count = cluster.markers?.reduce((prev, current) => {
        const label = (current as google.maps.Marker)?.getLabel();
        return prev + Number(isString(label) ? label : label?.text);
    }, 0);

    // change color if this cluster has more markers than the mean cluster
    const color = cluster.count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";

    // create svg literal with fill color
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;

    // create cluster SVG element
    const parser = new DOMParser();
    const svgEl = parser.parseFromString(svg, "image/svg+xml").documentElement;
    svgEl.setAttribute("transform", "translate(0 25)");

    const clusterOptions = {
        position: cluster.position,
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
        title: `Cluster of ${cluster.count} markers`,
        icon: {
            url: `data:image/svg+xml;base64,${btoa(svg)}`,
            anchor: new google.maps.Point(25, 25),
        },
    };

    return new google.maps.Marker(clusterOptions);
};
