import { format as formatTimeAgo } from "timeago.js";
import { isString } from "lodash";
import { isIEBrowser } from "./user";
import { pad, removeNonASCIICharacters } from "./string";
import { isAdjustTimeForTimezoneEnabled } from "./settings";
import { format, isValid, parse, parseISO } from "date-fns";

const storage = sessionStorage;

const DEFAULT_LOCALE = process.env.REACT_APP_DATE_FORMAT_LOCALE;

const DEFAULT_DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

const DEFAULT_TIME_OPTIONS = {
    hour: "numeric",
    minute: "numeric",
};

export const SERVER_TIMEZONE_OFFSET_STORAGE_KEY = "server-timezone";
export const DEFAULT_SERVER_TIMEZONE_OFFSET = -300;

export const DATEPICKER_DATE_FORMAT = process.env.REACT_APP_DATEPICKER_DATE_FORMAT;

let serverTimezoneOffset = null;

export const dateIsSoon = (date, days = 2) => {
    if (!date) {
        return false;
    }

    if (dateIsOverdue(date)) {
        return false;
    } else {
        var date1 = new Date();
        var date2 = new Date(date);

        return date2 <= date1 && date2.setDate(date2.getDate() + days) >= date1;
    }
};

export const dateIsOverdue = (date) => {
    if (!date) {
        return false;
    }

    var date1 = new Date();
    var date2 = new Date(date);

    return date2 < date1;
};

export const dateIsNA = (termValue) => {
    return termValue <= 0;
};

/**
 * Compares an input date against a reference date
 * Return 1 if input date > reference date
 * Return -1 if input date < reference date
 * Return 0 if input date = reference date
 * @param inputDate Date
 * @param referenceDate Date
 * @returns int
 */
export const compareDateWithoutTime = (inputDate, referenceDate) => {
    const inputDatePart = getDateWithoutTime(inputDate);
    const referenceDatePart = getDateWithoutTime(referenceDate);
    if (inputDatePart > referenceDatePart) {
        return 1;
    } else if (inputDatePart < referenceDatePart) {
        return -1;
    } else {
        return 0;
    }
};

/**
 * Returns beginning of day for a given datetime
 * @param date Date
 * @returns Date
 */
export const getDateWithoutTime = (date) => {
    let datePart = new Date(date);
    datePart.setHours(0);
    datePart.setMinutes(0);
    datePart.setSeconds(0);
    datePart.setMilliseconds(0);
    return datePart;
};

export const dateToJson = (date) => {
    if (date instanceof Date) {
        const yyyy = pad(date.getFullYear(), 4);
        const MM = pad(date.getMonth() + 1, 2);
        const dd = pad(date.getDate(), 2);
        const hh = pad(date.getHours(), 2);
        const mm = pad(date.getMinutes(), 2);
        const ss = pad(date.getSeconds(), 2);
        const SSS = pad(date.getMilliseconds(), 3);

        // Using json date without trailing "Z" to prevent adding timezone offset when converting json date to javascript Date instance with `parseISO`.
        return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${SSS}`;
    }

    return null;
};

export const jsonDateToDate = (dateStr) => {
    if (!isString(dateStr)) {
        return dateStr;
    }

    // remove trailing "Z" to prevent adding timezone offset when converting json date to javascript Date instance with `parseISO`.
    const dateStrWithoutZ = dateStr.endsWith("Z") ? dateStr.slice(0, -1) : dateStr;
    const date = parseISO(dateStrWithoutZ);

    return isNaN(date) ? NaN : date;
};

export const formatJsonDate = (dateStr, defaultValue = "") => {
    if (!dateStr) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (isNaN(date)) {
        return dateStr;
    }

    const convertedDateTimeStr = date.toLocaleString(DEFAULT_LOCALE, DEFAULT_DATE_OPTIONS);

    return isIEBrowser() ? removeNonASCIICharacters(convertedDateTimeStr) : convertedDateTimeStr;
};

/**
 * Format JSON date string to localized date string
 * @param {string | null | undefined} dateStr - JSON date string
 * @param {string | undefined} defaultValue - fallback value if dateStr is empty
 * @param {boolean} showSeconds - show seconds in time
 * @param {{ isEnabled: boolean, local?: number, server?: number} | undefined} timezoneOffset - timezone offset params
 * @returns formatted date string
 */
export const formatJsonDateTime = (dateStr, defaultValue = "", showSeconds = false, timezoneOffset = undefined) => {
    if (!dateStr) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (isNaN(date)) {
        return dateStr;
    }

    const adjustedDate = serverDateToLocal(date, timezoneOffset);

    const timeOptions = showSeconds
        ? {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
          }
        : DEFAULT_TIME_OPTIONS;

    const convertedDateTimeStr = adjustedDate.toLocaleString(DEFAULT_LOCALE, {
        ...DEFAULT_DATE_OPTIONS,
        ...timeOptions,
    });

    return isIEBrowser() ? removeNonASCIICharacters(convertedDateTimeStr) : convertedDateTimeStr;
};

export const formatJsonTime = (dateStr, defaultValue = "") => {
    const dateTime = formatJsonDateTime(dateStr, defaultValue);

    if (dateTime) {
        const parts = dateTime.split(" ");

        if (parts.length === 5) {
            return parts[3] + " " + parts[4];
        }
    }

    return defaultValue;
};

export const formatJsonDateToShortMonth = (dateStr, defaultValue = "") => {
    if (!dateStr) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (isNaN(date)) {
        return dateStr;
    }

    const adjustedDate = serverDateToLocal(date);

    return adjustedDate.toLocaleString(DEFAULT_LOCALE, {
        month: "short",
        day: "numeric",
    });
};

export const datePartFromJsonDate = (dateStr) => {
    if (isString(dateStr)) {
        return dateStr.slice(0, 10);
    }

    return undefined;
};

export const toTimeAgo = (jsonDate, defaultValue) => {
    if (!jsonDate) {
        return defaultValue;
    }

    const date = jsonDateToDate(jsonDate);
    // Always adjust timeAgo dates because not adjusting can end up with "time ago" that is in the future.
    const adjustedDate = serverDateToLocal(date, { isEnabled: true });

    return formatTimeAgo(adjustedDate);
};

export const sortObjectsByDate = (list, dateKey) => {
    if (list) {
        let orderedList = list.slice();

        orderedList.sort((a, b) => {
            return jsonDateToDate(b[dateKey]) - jsonDateToDate(a[dateKey]);
        });

        return orderedList;
    }
};

export const serverDateToLocal = (date, timezoneOffset = {}) => {
    const needToConvert = timezoneOffset.isEnabled ?? isAdjustTimeForTimezoneEnabled();
    if (!needToConvert) {
        return date;
    } else {
        const localOffset = timezoneOffset.local ?? getUserTimezoneOffset();
        const serverOffset = timezoneOffset.server ?? getServerTimezoneOffset();

        let adjustedDate = addMinutes(date, -serverOffset);
        adjustedDate = addMinutes(adjustedDate, -localOffset);

        return adjustedDate;
    }
};

export const getServerTimezoneOffset = () => {
    if (serverTimezoneOffset === null) {
        serverTimezoneOffset = getInitialServerTimezoneOffset();
    }

    return serverTimezoneOffset;
};

export const getUserTimezoneOffset = () => {
    return getBrowserTimezoneOffset();
};

export const getBrowserTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
};

export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};

/**
 * Saves the server timezone offset and time adjust enabled flag to the storage.
 *
 * @param {number} value - The server timezone offset value.
 */
export const saveServerTimezone = (value) => {
    serverTimezoneOffset = value;
    storage.setItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY, value);
};

export const getInitialServerTimezoneOffset = () => {
    if (serverTimezoneOffset === null) {
        const value = storage.getItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY);
        const offset = value ? Number(value) : DEFAULT_SERVER_TIMEZONE_OFFSET;
        serverTimezoneOffset = offset;
    }

    return serverTimezoneOffset;
};

/**
 * Convert input value to date used in datepicker
 * If isFormatDate == true use date part only
 *
 * @param {*} { value, isFormatDate }
 * @returns Date
 */
export const getDateValueForInput = ({ value, isFormatDate }) => {
    let stringValue = String(value);

    if (stringValue.indexOf("/") > -1) {
        if (isFormatDate) {
            // If a space exists between a date and time, converts a string like `3/31/2022 12:00:00 AM` to `3/31/2022`
            // Otherwise the original stringValue would be converted to an invalid value of `2022 12:00:00 AM-3-31`
            stringValue = stringValue.indexOf(" ") > -1 ? stringValue.split(" ")[0] : stringValue;

            stringValue = dateStringToJsonDate(stringValue);
        }
        // Convert date time string value like `3/31/2022 12:00:00 AM` to date
        else {
            const date = parse(stringValue, `${DATEPICKER_DATE_FORMAT} h:mm a`, new Date());
            const dateValue = typeof value === "undefined" ? null : isNaN(date) ? null : date;
            return dateValue;
        }
    }
    // If string is a time like 8:16 AM
    else if (!isFormatDate && stringValue.includes("M")) {
        const date = parse(stringValue, "h:mm a", new Date());
        const dateValue = typeof value === "undefined" ? null : isNaN(date) ? null : date;
        return dateValue;
    }

    const date = jsonDateToDate(isFormatDate ? datePartFromJsonDate(stringValue) : stringValue);

    const dateValue = typeof value === "undefined" ? null : isNaN(date) ? null : date;

    return dateValue;
};

export const isValidDateFormatFromInput = (fieldVal) => {
    const formats = ["yyyy-MM-dd", "yyyy-MM-dd'T'HH:mm:ss", "MM/dd/yyyy"];
    for (let format of formats) {
        const date = parse(fieldVal, format, new Date());
        if (isValid(date)) {
            return true;
        }
    }
    return false;
};

/**
 * Format the date to time string like "8:16 AM"
 * @param {Date} date
 * @returns string
 */
export const dateToTimeString = (date) => {
    return format(date, "h:mm a");
};

export const dateStringToJsonDate = (value) => {
    const stringValue = String(value);

    if (stringValue.indexOf("/") > -1) {
        const formatParts = DATEPICKER_DATE_FORMAT.split("/");
        const valueParts = stringValue.split("/");

        if (formatParts.length === valueParts.length) {
            let yyyy = "";
            let MM = "";
            let dd = "";

            // If first part is a year then consider the format provided is "YYYY/MM/DD"
            if (valueParts[0].length === 4) {
                yyyy = valueParts[0];
                MM = valueParts[1];
                dd = valueParts[2];
            } else {
                yyyy = valueParts[formatParts.indexOf("yyyy")];
                MM = valueParts[formatParts.indexOf("MM")];
                dd = valueParts[formatParts.indexOf("dd")];
            }

            return `${yyyy}-${MM}-${dd}`;
        }
    }

    return dateToJson(new Date(stringValue));
};

/**
 * Change date string format: 2020-05-10 => 05/10/2020
 * Time part is removed.
 *
 * @param {string} value Json date value like 2020-05-10
 * @returns
 */
export const localizeJsonDate = (value) => {
    if (!isString(value)) {
        return value;
    }

    let result = value;
    const datePart = (result || "").split("T")[0];

    if (datePart) {
        // Parts of json date
        const valueParts = datePart.split("-");

        if (valueParts.length === 3) {
            result = DATEPICKER_DATE_FORMAT.replace("dd", pad(valueParts[2], 2))
                .replace("MM", pad(valueParts[1], 2))
                .replace("yyyy", valueParts[0]);
        }
    }

    return result;
};

/**
 * Change date time string format: 2020-05-10T08:16:00 => 05/10/2020 8:16 AM
 *
 * @param {string} value Json date value like 2020-05-10T08:16:00
 * @returns
 */
export const localizeJsonDateTime = (value) => {
    if (!isString(value)) {
        return value;
    }

    const datePart = localizeJsonDate(value);
    const timePart = dateToTimeString(jsonDateToDate(value));

    return `${datePart} ${timePart}`;
};
