import React, { useRef, useState, useCallback, memo, useEffect } from "react";
import { pick, orderBy, isNil } from "lodash";

import { useInvoiceDocumentAttributes, useInvoiceDocument, useContractAttributes } from "../../../../../../store/resources/useResource";
import { pickInitialValues, submitResource } from "../../../../../utils/form";
import { invoiceDocumentRefresh } from "./utils";
import { isNumericAttributeType, isDateAttributeType } from "../../../../../utils/attributes";
import { submitByRef } from "../../../../../utils/form";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import WaitIcon from "../../../../WaitIcon";
import GridDetailsFooterActions from "../../../../DataGrid/GridDetailsFooterActions";
import ContractVendorWidget from "../../../../Form/JsonSchema/widgets/ContractVendorWidget";
import { ErrorMessage } from "../../../../Message";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = memo((props) => {
    const { utilityNumber, contractNumber, dataItem, onClose, sidePanel } = props;
    const formRef = useRef();

    const isNew = dataItem === undefined;
    const isLocked = dataItem && dataItem.islocked;
    const resourceId = isNew ? null : dataItem.invoiceDocumentNumber;

    const titleText = isNew ? "Add Invoice Document" : isLocked ? "View Invoice Document" : "Edit Invoice Document";
    const titleIcon = isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useInvoiceDocument({
        documentNumber: resourceId,
        forced: true,
    });
    const [invoiceDocumentAttributes = [], isLoadingInvoiceDocumentAttributes] = useInvoiceDocumentAttributes({
        utilityNumber,
        contractNumber,
        documentNumber: resourceId,
    });
    const [contractAttributes = [], isLoadingContractAttributes] = useContractAttributes({ utilityNumber, contractNumber });

    const submitText = isSubmitting ? "Saving..." : "Save";

    const attributesSchema = contractAttributes.reduce(
        (result, attr) => ({
            ...result,
            [attr.attributeNumber]: {
                type: isNumericAttributeType(attr.validationTypeName) ? "number" : "string",
                title: attr.attributeName,
            },
        }),
        {}
    );

    const attributesUiSchema = contractAttributes
        .filter((attr) => isDateAttributeType(attr.validationTypeName))
        .reduce(
            (result, attr) => ({
                ...result,
                [attr.attributeNumber]: {
                    "ui:widget": "date",
                    "ui:options": {
                        overlap: true,
                    },
                },
            }),
            {}
        );

    const attributesOrder = orderBy(contractAttributes, ["itemOrder"]).map((attr) => attr.attributeNumber);

    const attributesRequired = contractAttributes
        .filter((attr) => (attr.validationRequiredName || "").toLowerCase() === "true")
        .map((attr) => attr.attributeNumber);

    const attributeValues = Object.keys(attributesSchema).reduce(
        (result, attrKey) => ({
            ...result,
            [attrKey]: invoiceDocumentAttributes
                .filter((attr) => attr.attributeNumber === attrKey)
                .map((attr) => (isNumericAttributeType(attr.validation) ? Number(attr.attributeValue) : attr.attributeValue))[0],
        }),
        {}
    );

    const initialValues = isNew
        ? {}
        : pickInitialValues({
              ...resource,
              ...attributeValues,
          });

    const schema = {
        type: "object",
        required: ["refId", "vendorContactId", "invoiceNumber", "invoiceDescription", "paymentText", ...attributesRequired],
        properties: {
            refId: {
                type: "string",
                title: "refID",
            },
            vendorContactId: {
                type: "integer",
                title: "Vendor Contact ID",
            },
            invoiceNumber: {
                type: "string",
                title: "Invoice #",
            },
            invoiceDate: {
                type: "string",
                title: "Invoice Date",
            },
            invoiceDescription: {
                type: "string",
                title: "Invoice Description",
            },
            paymentText: {
                type: "string",
                title: "Payment Text",
            },
            ...attributesSchema,
        },
    };

    const uiSchema = {
        vendorContactId: {
            "ui:widget": (props) => <ContractVendorWidget {...props} utilityNumber={utilityNumber} contractNumber={contractNumber} />,
        },
        invoiceDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        ...attributesUiSchema,
        order: ["*", ...attributesOrder],
    };

    const onSubmit = useCallback(
        (formData) => {
            const attributeKeys = contractAttributes.map((attr) => attr.attributeNumber);

            const attributes = Object.keys(formData)
                .filter((key) => attributeKeys.some((k) => k === key))
                .map((key) => ({
                    attributeNumber: key,
                    attributeValue: !isNil(formData[key]) ? String(formData[key]) : "",
                }));
            const submitProperties = ["refId", "vendorContactId", "invoiceNumber", "invoiceDate", "invoiceDescription", "paymentText"];

            const resourceParams = {
                resourceName: "utilitiesContractsInvoiceDocuments",
                path: {
                    utilityNumber,
                    contractNumber,
                },
            };

            const body = {
                ...pick(formData, submitProperties),
                attributes,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onRefresh: () =>
                    invoiceDocumentRefresh({
                        utilityNumber,
                        contractNumber,
                        documentNumber: resourceId,
                    }),
                noResourceRefresh: true,
                onSuccess: sidePanel.close,
                setSubmitting,
            });
        },
        [utilityNumber, contractNumber, resourceId, contractAttributes, sidePanel]
    );

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading || isLoadingInvoiceDocumentAttributes || isLoadingContractAttributes) {
        return <WaitIcon />;
    }

    const showError = !isNew && !resource;

    const otherActions =
        isNew || isLocked ? null : (
            <>
                <span className="flex-one" />
                <GridDetailsFooterActions {...props} />
            </>
        );

    return (
        <SideNavContent className="invoice-edit-form">
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose}></SideNavHeader>
            <SideNavBody className="flex-one-in-column">
                {showError ? (
                    <ErrorMessage spaceAround>Invoice Document not Found</ErrorMessage>
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        readOnly={isLocked}
                        onSubmit={onSubmit}
                        submitText={submitText}
                        noReset
                        noSubmit
                        noCancel
                        noActions
                        otherActions={otherActions}
                    />
                )}
            </SideNavBody>
            <SideNavFooter setPrimaryButton={!isLocked} justifyEnd={isLocked}>
                {!isLocked && (
                    <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                        {submitText}
                    </Button>
                )}
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
