import React, { useState, memo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { refreshSidebarFilesGrid } from "./utils";
import { getGridId } from "./utils";
import { entityType } from "../../../../../utils/entityType";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { downloadAllFiles } from "../../../../../../store/files/actions";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import WaitIcon from "../../../../../ui/WaitIcon";
import { useResource } from "store/resources/useResource";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import FileUpload from "../../../../FileUpload";
import TabList from "../../../../List/TabList";
import DocumentsGrid from "./DocumentsGrid";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { refreshGrid } from "../../../../../../store/dataGrid/refreshGrid";
import { useCanSplitScreenView } from "components/utils/useCanSplitScreenView";
import SideNavFormContainer from "components/ui/SideNavFormContainer";
import { SortType } from "components/utils/datagrid";
import { applicationFilesGridColumnKeys, availableGrids } from "components/views/configureGrids";
import FilesGrid from "components/ui/DataGrid/FilesGrid";
import { disableDownloadText, useAllowAccessCheck } from "components/utils/files";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";
import { useDataGrid } from "components/utils/useDataGrid";

import "./DocumentsPanel.scss";

const tabs = [
    {
        id: "applicationFiles",
        title: "Files",
    },
    {
        id: "correspondence",
        title: "Correspondence",
    },
];

const DocumentsPanel = memo(({ panel }) => {
    const { applicationNumber, programNumber, utilityNumber } = panel?.data;
    const [isLoading, setIsLoading] = useState(false);
    const [programRights = []] = useProgramRights({ programNumber });

    const dispatch = useDispatch();
    const fileGridId = getGridId({ applicationNumber, type: "applicationFiles" });
    const entityTypeId = entityType.projectApplication;
    const entityId = applicationNumber;
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const isAppLocked = isApplicationLocked({ applicationNumber });
    const isUploadDocumentsDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_FILES);
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "file-upload-sidenav-panel",
    });
    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: entityTypeId,
        },
    });

    const handleUploadSuccess = useCallback(() => {
        handleCloseSidePanel();
        refreshGrid({ dataGridId: fileGridId });
    }, [fileGridId, handleCloseSidePanel]);

    const fileUploadComponentRef = useRef();

    const canUseSplitScreenView = useCanSplitScreenView();

    const [gridConfig, gridConfigLoading] = useDataGrid({
        dataGridId: availableGrids.applicationFiles,
        dataGridInstanceId: fileGridId,
        sorting: {
            type: SortType.CLIENT,
            sort: [
                {
                    field: applicationFilesGridColumnKeys.date,
                    dir: "desc",
                },
            ],
        },
        getDataAction: {
            type: "filesGridGetDataAction",
            props: {
                gridId: fileGridId,
                columnKeysMap: applicationFilesGridColumnKeys,
                applicationNumber,
            },
        },
    });

    const dataGridRows = useSelector((state) => state.dataGrid[fileGridId]?.sourceRows);
    const isDownloadAllDisabled = useAllowAccessCheck(dataGridRows);

    const openUploadForm = () => {
        handleOpenSidePanel(
            <SideNavFormContainer
                title="File Upload"
                onClose={handleCloseSidePanel}
                forwardedFormComponentRef={fileUploadComponentRef}
                submitText="Upload"
                titleIcon="upload"
                fileUpload
            >
                <FileUpload
                    ref={fileUploadComponentRef}
                    entityTypeId={entityType.projectApplication}
                    entityId={applicationNumber}
                    programNumber={programNumber}
                    utilityNumber={utilityNumber}
                    onCancel={handleCloseSidePanel}
                    onUploadSuccess={handleUploadSuccess}
                    withinSideNav
                    withoutHeaderPanel
                    applicationNumber={applicationNumber}
                    useApplicationFileTags={true}
                    files={files}
                />
            </SideNavFormContainer>
        );
    };

    return (
        <div className="panel documents">
            <PanelHeaderLarge title="Documents" />
            <div className="document-toolbar flex-row">
                <TabList items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                <div className="file-controls flex-one flex-row justify-end">
                    {activeTab.id === "applicationFiles" && (
                        <>
                            <IconWithLabel
                                iconWithLabelRight
                                iconWithLabel="upload"
                                disabled={isUploadDocumentsDisabled}
                                onClick={() => openUploadForm()}
                            >
                                Upload Files
                            </IconWithLabel>
                            {isLoading ? (
                                <WaitIcon />
                            ) : (
                                <IconWithLabel
                                    iconWithLabelRight
                                    iconWithLabel="download"
                                    disabled={isDownloadAllDisabled}
                                    title={isDownloadAllDisabled ? disableDownloadText : ""}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsLoading(true);
                                        dispatch(
                                            downloadAllFiles({
                                                entityId,
                                                entityTypeId,
                                                onComplete: () => {
                                                    setIsLoading(false);
                                                },
                                            })
                                        );
                                    }}
                                >
                                    Download All
                                </IconWithLabel>
                            )}
                        </>
                    )}
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: getGridId({
                                    applicationNumber,
                                    type: activeTab.id,
                                }),
                                fileName: `application_${activeTab.id}`,
                                fileNamePostfix: applicationNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
            </div>
            {activeTab.id === "applicationFiles" && !gridConfigLoading && (
                <div className="flex-column flex-one-in-column">
                    <FilesGrid
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        gridColumnKeys={applicationFilesGridColumnKeys}
                        gridId={fileGridId}
                        dataGridConfig={gridConfig}
                        isLocked={isAppLocked}
                        onRefresh={() => refreshSidebarFilesGrid({ applicationNumber })}
                        entityId={entityId}
                        entityTypeId={entityTypeId}
                        canUseSplitScreenView={canUseSplitScreenView}
                        utilityNumber={utilityNumber}
                        isApplicationGrid
                        canEdit={!isUploadDocumentsDisabled}
                    />
                </div>
            )}
            {activeTab.id === "correspondence" && (
                <DocumentsGrid applicationNumber={applicationNumber} isLocked={isAppLocked} programNumber={programNumber} />
            )}
        </div>
    );
});

export default DocumentsPanel;
