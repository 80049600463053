import React from "react";
import PropTypes from "prop-types";
import { isDate, isEmpty } from "lodash";
import cn from "classnames";

import {
    dateToJson,
    datePartFromJsonDate,
    getDateValueForInput,
    localizeJsonDate,
    jsonDateToDate,
    compareDateWithoutTime,
    formatJsonDate,
    dateToTimeString,
    localizeJsonDateTime,
} from "../../../../utils/date";
import DatePicker, { DatePickerPortal } from "../../../Input/DatePicker";

import { clearExtraErrors } from "components/utils/form";

const DatePickerWidget = (props) => {
    const {
        formContext,
        id,
        value,
        schema,
        disabled = false,
        readonly = false,
        onChange,
        onFocus,
        onBlur,
        time = false,
        placeholder,
        options = { yearsRange: [1900, new Date().getFullYear() + 2] },
        rawErrors,
        popperContainer,
    } = props;

    const showTimeOnly = schema.format === "time" || options.timeOnly;
    const showTime = showTimeOnly || schema.format === "date-time" || (options.time ?? time);

    const minDate = schema.formatMinimum === "today" ? dateToJson(new Date()) : schema.formatMinimum;
    const maxDate = schema.formatMaximum === "today" ? dateToJson(new Date()) : schema.formatMaximum;

    const isError = !isEmpty(rawErrors);

    // Field options have higher priority than form context
    const datePickerOverlap = options.overlap ?? formContext?.datePickerOverlap ?? false;
    const localizeDateValues = options.localize ?? formContext?.localizeDateValues ?? false;

    // Take only date part if format is not "date-time" and time picker is not enabled
    const isFormatDate = !["date-time", "time"].includes(schema.format) && !showTime;

    // Provide the value as is if it can not be parsed.
    const dateValue = getDateValueForInput({ value, isFormatDate }) ?? value;

    const handleChange = (e) => {
        const value = e.value;

        // Date value is invalid if it is not of type Date
        if (isDate(value)) {
            let result = dateToJson(value) || undefined;

            if (result) {
                if (isFormatDate) {
                    // Remove time part from date value
                    result = datePartFromJsonDate(result);

                    // Localize the date only if format is 'date'. Leave date-time value as is.
                    if (localizeDateValues) {
                        result = localizeJsonDate(result);
                    }
                }
                // Localize the date-time value
                else if (showTime && !showTimeOnly && localizeDateValues) {
                    result = localizeJsonDateTime(result);
                }
                // Localize the time value
                else if (showTimeOnly && localizeDateValues) {
                    result = dateToTimeString(value);
                }
            }

            onChange(result);

            // Clear extra errors if there are any
            clearExtraErrors(id, formContext);
        } else {
            if (isEmpty(value)) {
                onChange(undefined);
                clearExtraErrors(id, formContext);
            } else {
                onChange(String(value));

                const date = getDateValueForInput({ value: String(value), isFormatDate });
                const parsedMinDate = minDate ? jsonDateToDate(minDate) : undefined;
                const parsedMaxDate = maxDate ? jsonDateToDate(maxDate) : undefined;

                let message = "Invalid date";

                // Check if this is min/max limit error.
                if (isDate(date) && isDate(parsedMinDate) && compareDateWithoutTime(date, parsedMinDate) === -1) {
                    message = "Minimum allowed date is " + formatJsonDate(minDate);
                } else if (isDate(date) && isDate(parsedMaxDate) && compareDateWithoutTime(date, parsedMaxDate) === 1) {
                    message = "Maximum allowed date is " + formatJsonDate(maxDate);
                }

                // Set extra error.
                setTimeout(() => {
                    if (formContext?.setExtraError) {
                        formContext.setExtraError(id, message);
                    }
                }, 0);
            }
        }
    };

    return (
        <DatePicker
            id={id}
            showTime={showTime}
            showTimeOnly={showTimeOnly}
            value={dateValue}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled || readonly}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholder}
            error={isError}
            popperContainer={datePickerOverlap ? DatePickerPortal : popperContainer}
            popperClassName={cn({
                "with-timepicker": showTime,
            })}
            title={schema.title}
        />
    );
};

if (process.env.NODE_ENV !== "production") {
    DatePickerWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        options: PropTypes.object,
    };
}

export default DatePickerWidget;
