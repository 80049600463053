import { MODAL_OPEN, MODAL_CLOSE } from "../actionTypes";

export const modalOpen =
    ({ modalType, modalProps }) =>
    (dispatch) => {
        dispatch({
            type: MODAL_OPEN,
            modalType,
            modalProps,
        });
    };

export const modalClose = () => (dispatch) => {
    dispatch({
        type: MODAL_CLOSE,
    });
};
