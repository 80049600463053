/**
 * Try to reload CRM if app is loaded inside CRM markup.
 * This can happen when CRM session expires.
 *
 */
export const preventLoadingInCRM = () => {
    const flagReloaded = "vdsm.reloaded";

    if (isLoadedInCRM()) {
        // Check if already tried to reload the page to not go into infinite loop
        if (!sessionStorage.getItem(flagReloaded)) {
            sessionStorage.setItem(flagReloaded, "true");
            window.top.location.reload();
        } else {
            sessionStorage.removeItem(flagReloaded);
        }
    } else {
        sessionStorage.removeItem(flagReloaded);
    }
};

/**
 * Check if app is loaded inside CRM.
 */
export const isLoadedInCRM = () => {
    let result = false;
    const appRoot = document.getElementById("root");

    if (appRoot) {
        result = appRoot.closest("#UpdateSection") !== null;
    }

    return result;
};

export const getOpportunityTokenRequestParameters = ({ opportunityId, isPartial, isVision }) => {
    return {
        parameters: `opportunityid=${opportunityId?.toString()}|ispartial=${isPartial ? "true" : "false"}|isvision=${
            isVision ? "true" : "false"
        }`,
        sourceSystem: "Vision Client",
    };
};
