import React, { memo, useContext } from "react";
import { useSelector } from "react-redux";

import Subtitle from "../../ui/Title/Subtitle";
import { WorkCenterContext } from ".";

const WorkqueueGridTitle = memo(() => {
    const { instanceId } = useContext(WorkCenterContext);

    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);

    return (
        <div className="workqueue-title">
            <Subtitle>{activeDataSource.datasourceName}</Subtitle>
        </div>
    );
});

export default WorkqueueGridTitle;
