import { get, isObject } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDataActionProps } from "store/dataGrid/actions";
import { getColumns } from "./datagrid";
import { DataGridRowResponse, useDataGridConfig } from "./useDataGridConfig";

export const useDataGrid = ({
    dataGridId,
    dataGridInstanceId,
    getDataAction,
    tree,
    serverSidePaging = false,
    isServerSideFiltering = false,
    sorting,
    serverTimezoneOffset,
    noInitialFetch = false,
}: UseDataGridProps) => {
    const [gridConfig, isLoading] = useDataGridConfig(dataGridId);
    const dispatch = useDispatch();

    // Update getDataAction props in grid config if changed
    useEffect(() => {
        dispatch(
            setDataActionProps({
                dataGridId: dataGridInstanceId,
                getDataActionProps: getDataAction?.props,
            })
        );
    }, [dataGridInstanceId, getDataAction, dispatch]);

    const columns = get(gridConfig, "grid[0].columns", []);
    const gridActions = get(gridConfig, "grid[0].gridactions", []);

    return [
        {
            serverSidePaging,
            isServerSideFiltering,
            dataGridId,
            name: dataGridInstanceId,
            getDataAction,
            gridactions: gridActions,
            columns: getColumns({ columns, serverTimezoneOffset }),
            sort: isObject(sorting) ? sorting.sort : [],
            tree,
            noInitialFetch,
        },
        isLoading,
    ];
};

type UseDataGridProps = {
    dataGridId: string;
    dataGridInstanceId: string;
    columnKeysMap?: ColumnKeysMap;
    rows?: DataGridRowResponse[];
    getDataAction?: GetDataActionProps;
    tree?: boolean;
    serverSidePaging?: boolean;
    isServerSideFiltering?: boolean;
    sorting?:
        | boolean
        | {
              type: string;
              sort: {
                  field: string;
                  dir: string;
              }[];
          };
    serverTimezoneOffset?: number;
    noInitialFetch?: boolean;
};

type ColumnKeysMap = {
    [key: string]: string;
};

type GetDataActionProps = {
    type: string;
    props?: {
        dataGridId: string;
        columnKeysMap?: ColumnKeysMap;
        [key: string]: any;
    };
};
