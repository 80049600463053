import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";

import { dashboardTypes, customerDashboard } from "../../utils/dashboard";
import { windowSetClassName } from "../../../store/window/actions";
import { windowContainerTypes } from "../../utils/window";
import { useCustomerDisabled } from "./utils";

import Dashboard from "../../ui/Dashboard";
import Header from "./Header";

import "./style.scss";

const CustomerView = memo((props) => {
    const { customerNumber } = props;

    const dispatch = useDispatch();
    const isLocked = useCustomerDisabled({ customerNumber });

    const dashboardData = {
        ...props,
        dashboardKey: customerNumber,
    };

    useEffect(() => {
        dispatch(
            windowSetClassName({
                containerName: windowContainerTypes.Root,
                name: customerNumber,
                className: isLocked ? "entity-locked" : undefined,
            })
        );
    }, [customerNumber, isLocked, dispatch]);

    return (
        <div className="customer-view flex-column fill-height no-scroll">
            <Dashboard
                key={customerNumber}
                type={dashboardTypes.CUSTOMER}
                activeDashboardId={customerDashboard.dashboardNumber}
                dashboardData={dashboardData}
                header={<Header {...props} />}
            />
        </div>
    );
});

export default CustomerView;
