import React from "react";
import { BorderBox } from "../../Property/BorderProperty/BorderBox";
import { BorderSideName } from "../../Property/BorderProperty/types";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import cn from "classnames";

import "./GlobalButtonProperties.scss";

export const ButtonBorderWidthProperty = (props) => {
    const { value, onChange, title, id, className, borderBottom, borderTop } = props;

    const onSelect = (id, width) => {
        const extraProperties = {
            id: id,
            value: undefined,
        };

        if (width === value) {
            onChange(id, `${0}px`, extraProperties);
        } else {
            onChange(id, `${width}px`, extraProperties);
        }
    };

    const selectedWidth = Number(value);

    return (
        <PropertyListItem
            className={cn("property-list-item--border-width flex-column", className)}
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            <PropertyListItemTitle title={title}></PropertyListItemTitle>
            <div className="property-list-item-inner button-border-width-boxes flex-row">
                {Array.from(Array(5).keys()).map((index) => (
                    <BorderBox
                        key={index}
                        width={index + 1}
                        side={BorderSideName.AllSides}
                        isSelected={selectedWidth === index + 1}
                        onClick={() => onSelect(id, index + 1)}
                    />
                ))}
            </div>
        </PropertyListItem>
    );
};
