import React from "react";
import ClassNames from "classnames";

import SidebarHeader from "./SidebarParts/SidebarHeader";
import SidebarFooter from "./SidebarParts/SidebarFooter";

import "./style.scss";

const SidebarPanel = (props) => {
    const className = ClassNames("sidebar-content", props.className, {
        "no-footer": props.noFooter,
        "no-padding": props.noPadding,
        "no-data": props.noData,
    });

    return (
        <div className="sidebar-panel">
            <SidebarHeader
                iconClickable={props.iconClickable}
                PasswordsidebarHeaderContent={props.sidebarHeaderContent}
                sidebarHeaderTitle={props.sidebarHeaderTitle}
                sidebarHeaderActions={props.sidebarHeaderActions}
            ></SidebarHeader>
            <div className={className}>{props.children}</div>
            {props.sidebarFooterContent && <SidebarFooter sidebarFooterContent={props.sidebarFooterContent}></SidebarFooter>}
        </div>
    );
};

export default SidebarPanel;
