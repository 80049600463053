import React, { useCallback, useEffect, useRef } from "react";
import {
    FontFamily,
    LayoutWidth,
    PortalTemplateConfigurationSectionKey,
    PropertyName,
    PropertyType,
    TypographyFontSize,
    TypographyLineHeight,
    ValidationRule,
} from "../../types";
import { Cookies, COOKIES_STYLE_PROPERTIES, COOKIES_TEXT_PROPERTIES } from "../Cookies";
import { BACKGROUND_PROPERTY, TEXT_COLOR_PROPERTY } from "../Property/propertyConfig";
import { ToolbarTypes } from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import { PropertyList } from "../PropertyList";
import { setConfigSectionValue } from "../../utils";
import { cloneDeep, isEqual } from "lodash";
import { SiteTitleProperty } from "../Property/SiteTitleProperty";

import "./UtilitySettings.scss";
import { SectionInfo } from "../SectionInfo";
import StatusMsg from "components/ui/StatusMsg";
import { useResizeObserver } from "components/utils/useResizeObserver";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const UtilitySettings = (props) => {
    const { onChange } = props;
    const [config, setPortalBuilderState] = usePortalBuilderState((state) => state.updatedConfig);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const [activeSectionErrors] = usePortalBuilderState((state) => state.activeSectionErrors);
    const containsActiveError = (activeSectionErrors ?? []).some((e) => isEqual(e, activeError));
    const hasCookiesError = activeError && ["cookiesBannerText", "cookiesBannerLinkUrl"].some((key) => activeError.id.endsWith(key));
    const enableCookiesBanner = config.settings.enableCookiesBanner;

    const configRef = useRef();
    configRef.current = config;

    const onPropertyChange = useCallback(
        (id, value, additionalValues = []) => {
            let updatedConfig = cloneDeep(configRef.current);
            updatedConfig = setConfigSectionValue(updatedConfig, PortalTemplateConfigurationSectionKey.SETTINGS, id, value);

            if (id === "enableGoogleAnalytics" && value !== true) {
                updatedConfig.settings.googleAnalyticsTrackingId = undefined;
            }

            if (id === "enableGoogleTags" && value !== true) {
                updatedConfig.settings.googleTagManagerKey = undefined;
            }

            if (id === "enableCookiesBanner") {
                if (value === true) {
                    const defaultCookiesProperties = [...COOKIES_STYLE_PROPERTIES, ...COOKIES_TEXT_PROPERTIES].reduce((result, next) => {
                        if (next.propertiesGroup) {
                            return next.propertiesGroup.reduce((result, next) => {
                                return { ...result, [next.id]: next.defaultValue };
                            }, result);
                        }
                        return { ...result, [next.id]: next.defaultValue };
                    }, {});

                    updatedConfig.settings = {
                        ...updatedConfig.settings,
                        ...defaultCookiesProperties,
                    };
                } else {
                    const emptyCookiesProperties = [...COOKIES_STYLE_PROPERTIES, ...COOKIES_TEXT_PROPERTIES].reduce((result, next) => {
                        return { ...result, [next.id]: undefined };
                    }, {});

                    updatedConfig.settings = {
                        ...updatedConfig.settings,
                        ...emptyCookiesProperties,
                    };
                }
            }

            if (additionalValues.length > 0) {
                var values = additionalValues.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

                updatedConfig.settings = {
                    ...updatedConfig.settings,
                    ...values,
                };
            }

            onChange && onChange(updatedConfig);
        },
        [onChange]
    );
    const previewFrame = document.querySelector("#iframe");
    const frameWidth = useResizeObserver(previewFrame)[0].contentRect?.width;

    const hideInfoComponent = frameWidth <= 1320 ? true : false;

    // activeError is added to dependencies to prevent navigation to cookies error only once.
    useEffect(() => {
        if (hasCookiesError) {
            setPortalBuilderState({ activeConfigPanel: <Cookies onClose={() => setPortalBuilderState({ activeConfigPanel: null })} /> });
        }
    }, [hasCookiesError, activeError, setPortalBuilderState]);

    return (
        <div className="portal-builder-properties-section-body flex-one-in-column fill-width with-scroll utility-portal-settings">
            <PropertyList
                items={UTILITY_SETTINGS_PROPERTIES(hideInfoComponent, enableCookiesBanner)}
                errors={activeSectionErrors}
                containsActiveError={containsActiveError}
                config={config?.settings}
                onChange={onPropertyChange}
                isExpanded
            />
        </div>
    );
};

export const LayoutTitleInfo = () => {
    return (
        <SectionInfo>
            <StatusMsg
                msgIcon="info-empty"
                msgText={
                    <>
                        <div className="info-text">
                            Don't see any change when switching between options? This might happen if the preview area is smaller than
                            1320px.
                        </div>
                        Press CTRL together with - (the minus sign) to zoom out in your web browser - to simulate bigger screen - and then
                        try to switch between layout options one more time.
                    </>
                }
            />
        </SectionInfo>
    );
};

export const SearchIndexingInfo = () => {
    return (
        <SectionInfo>
            <StatusMsg
                msgIcon="info-empty"
                msgText={
                    <>
                        <div className="info-text">
                            Allow search engines to organize and store portal information to help with web searches. On by default.
                        </div>
                    </>
                }
            />
        </SectionInfo>
    );
};

export const FaviconInfo = () => {
    return (
        <SectionInfo>
            <StatusMsg
                msgIcon="info-empty"
                msgText={
                    <>
                        <div className="info-text">
                            Use a square image with a size of at least 180x180 pixels with an extension GIF, PNG, or ICO.
                        </div>
                    </>
                }
            />
        </SectionInfo>
    );
};

export const UTILITY_SETTINGS_PROPERTIES = (hideInfoComponent, enableCookiesBanner) => [
    { ...BACKGROUND_PROPERTY, withGradient: true, selectableWidth: false },
    {
        id: "layout-selection",
        title: "Layout",
        type: PropertyType.Group,
        collapsible: false,
        propertiesGroup: [
            {
                id: PropertyName.MaxWidth,
                type: PropertyType.SelectBox,
                borderBottom: false,
                fullWidth: true,
                defaultValue: LayoutWidth.XXL,
                className: "property-list-item--layout-selection",
                description: (id, value, context) => (hideInfoComponent === true ? <LayoutTitleInfo /> : undefined),
                items: [
                    {
                        text: "Full Screen",
                        value: LayoutWidth.FullScreen,
                    },
                    {
                        text: "Centered",
                        value: LayoutWidth.XXL,
                    },
                ],
            },
        ],
    },
    {
        id: "favicon",
        title: "Favicon",
        type: PropertyType.Group,
        collapsible: false,
        propertiesGroup: [
            {
                id: "faviconSrc",
                type: PropertyType.FileSelect,
                buttonTitle: "Select image or upload new",
                fileType: "Favicon",
                selectTooltip: "Select Image",
                description: () => <FaviconInfo />,
            },
        ],
    },
    {
        id: "group-typography",
        title: "Typography",
        type: PropertyType.Group,
        collapsible: false,
        borderBottom: true,
        propertiesGroup: [
            {
                ...TEXT_COLOR_PROPERTY,
                selectPanelSubTitle: (
                    <>
                        <strong>Typography.</strong> <strong>Text</strong> color selection
                    </>
                ),
            },
            {
                id: PropertyName.FontFamily,
                title: "Font",
                type: PropertyType.Select,
                defaultValue: FontFamily.Roboto,
                items: Object.keys(FontFamily)
                    .map((key) => ({ label: FontFamily[key], value: FontFamily[key] }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
                emptyItem: false,
            },
            {
                id: PropertyName.FontSize,
                title: "Text size",
                type: PropertyType.SelectBox,
                items: [
                    {
                        text: "Small",
                        value: TypographyFontSize.Small,
                    },
                    {
                        text: "Normal",
                        value: TypographyFontSize.Normal,
                    },
                    {
                        text: "Large",
                        value: TypographyFontSize.Large,
                    },
                ],
            },
            {
                id: PropertyName.LineHeight,
                title: "Text line height",
                type: PropertyType.SelectBox,
                borderBottom: false,
                items: [
                    {
                        text: "Small",
                        value: TypographyLineHeight.Small,
                    },
                    {
                        text: "Normal",
                        value: TypographyLineHeight.Normal,
                    },
                    {
                        text: "Large",
                        value: TypographyLineHeight.Large,
                    },
                ],
            },
        ],
    },
    {
        id: "siteTitleTemplate",
        title: "Site Title",
        type: PropertyType.CustomComponent,
        component: SiteTitleProperty,
    },
    {
        id: "GoogleAnalytics",
        title: "Google Analytics",
        type: "analytics",
        titleSwitch: {
            id: "enableGoogleAnalytics",
        },
        propertiesGroup: [
            {
                id: "googleAnalyticsTrackingId",
                title: "Tracking ID",
                type: "text",
            },
            {
                type: "analytics-link",
            },
        ],
    },
    {
        id: "GoogleTag",
        title: "Google Tag",
        type: "analytics",
        titleSwitch: {
            id: "enableGoogleTags",
        },
        propertiesGroup: [
            {
                id: "googleTagManagerKey",
                title: "Tracking Key",
                type: "text",
            },
            {
                type: "google-tag-link",
            },
        ],
    },
    {
        id: "enableIndexing",
        title: "Allow indexing",
        type: PropertyType.Switch,
        defaultValue: true,
        description: () => <SearchIndexingInfo />,
    },
    {
        id: "enableCookiesBanner",
        title: "Show Cookies Panel",
        type: "switch",
        className: "show-cookies-panel",
    },
    {
        id: "cookies-settings",
        title: "Configure panel",
        type: "config-panel",
        openIcon: "shevron-right-keyboard-arrow-right",
        panelComponent: Cookies,
        hidden: !enableCookiesBanner,
    },
    {
        id: "notification",
        title: "Notification",
        type: "notification",
        titleSwitch: {
            id: "enableNotification",
            defaultValue: false,
        },
        propertiesGroup: [
            {
                id: "notificationType",
                title: "Notification Type:",
                type: "notification-type",
                defaultValue: "info",
                items: [
                    {
                        icon: "info-type-icon",
                        value: "info",
                        tooltip: "Info Type",
                    },
                    {
                        icon: "attention-type-icon",
                        value: "attention",
                        tooltip: "Attention Type",
                    },
                    {
                        icon: "regular-type-icon",
                        value: "regular",
                        tooltip: "Regular Type",
                    },
                ],
            },
            {
                id: "notificationIcon",
                title: "Notification with type icon",
                type: "switch",
            },
            {
                id: "notificationCloseIcon",
                title: "Notification with close icon",
                type: "switch",
                labelIcon: "info-empty",
                labelTitle: "The user will be able to close a notification panel",
            },
            {
                id: "notificationText",
                title: "Notification Text",
                type: "html",
                borderBottom: false,
                validationRules: [ValidationRule.Required],
                toolbar: ToolbarTypes.Minimum,
            },
        ],
    },
];
