import React, { useContext, memo } from "react";

import { ArchivedBatchGridContext } from "../../../context/ArchivedBatchGridContext";
import { ArchivedBatch } from "./ArchivedBatch";

export const ArchivedBatchList = memo((props) => {
    const { archivedBatchList } = useContext(ArchivedBatchGridContext);

    return (
        <div className="application-document-queue__dq-grid-programs">
            {archivedBatchList.map((batch) => (
                <ArchivedBatch key={batch.documentBatchNumber} {...props} {...batch} />
            ))}
        </div>
    );
});
