import React, { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { resetLogin, forgotPassword } from "../../../store/login/actions";
import { openLoginPage } from "../../utils/window";

import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = memo(({ userName }) => {
    const dispatch = useDispatch();
    const login = useSelector((state) => state.login);

    const { isLoading, infoMessage, errorMessage } = login;

    const handleRedirectToLogin = useCallback(() => {
        if (infoMessage) {
            // reset password email sent
            dispatch(resetLogin());
        } else {
            openLoginPage();
        }
    }, [infoMessage, dispatch]);

    const handleSubmit = useCallback(
        (data) => {
            dispatch(forgotPassword(data));
        },
        [dispatch]
    );

    return (
        <ForgotPasswordForm
            userName={userName}
            title={"Forgot your password?"}
            isLoading={isLoading}
            errorMessage={errorMessage}
            infoMessage={infoMessage}
            onSubmit={handleSubmit}
            onRedirectToLogin={handleRedirectToLogin}
        />
    );
});

export default ForgotPassword;
