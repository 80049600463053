import React, { useState, useCallback, useRef, useEffect, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual, isNil, get } from "lodash";

import { programsEventsGridColumnKeys } from "../../../../../../views/configureGrids";
import { useProgramEvent, useProgramRights } from "../../../../../../../store/resources/useResource";
import { getEventKey, onEventsGridRefresh, getSubmitData } from "../utils";
import { initializeAttributes, setAttributes } from "../../../../../../../store/attributes/actions";
import { submitResource, submitByRef, submitByRefPromise } from "../../../../../../utils/form";
import { setAssociations, initializeAssociations } from "../../../../../../../store/associations/actions";
import { refreshApplicationEventCategories } from "../../../../../../../store/resources/refreshResource";
import { systemUserRights } from "components/utils/user";

import WaitIcon from "../../../../../WaitIcon";
import CounterBox from "../../../../../CounterBox";
import ItemForm from "./ItemForm";
import Associations from "./Associations";
import AttributesForm from "../../../../../AttributesForm";
import { ErrorMessage } from "../../../../../Message";
import Button from "../../../../../Button";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../../SideNav/SideNavContent";
import Calculation from "../../CatalogPanel/Form/Calculation";
import { isChildProgram } from "components/views/ProgramView/utils";

// Moved out of component to not recreate on every render.
const availableAttributesResourceKeys = {
    name: "productName",
    number: "eventAttrTypeNumber",
    description: "eventAttrDescription",
};

const Form = memo(({ utilityNumber, programNumber, dataItem, onClose, sidePanel }) => {
    const isLocked = isChildProgram({ programNumber });
    const isNew = isNil(dataItem);
    const title = isNew ? "Add Event" : isLocked ? "View Event" : "Edit Event";
    const leadBlockIcon = isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty";
    const availableAttributesResourceName = "programEventAttributes";
    const notFoundText = "Event not found";

    const [programRights = []] = useProgramRights({ programNumber });
    const canEditCalculation = programRights.includes(systemUserRights.VISIONDSM_ADD_CALCULATION);

    const [isSubmitting, setSubmitting] = useState(false);
    const [selectedCalculationNumber, setSelectedCalculationNumber] = useState(null);

    const itemFormRef = useRef();
    const attributeFormRef = useRef();
    const resourceInitialized = useRef(false);

    const resourceId = isNew ? null : dataItem[programsEventsGridColumnKeys.eventNumber];
    const [resource, isLoading] = useProgramEvent({
        programNumber,
        resourceId,
    });

    const entityKey = getEventKey({
        programNumber,
        eventNumber: resourceId,
    });
    const notFound = !isLoading && !isNew && isNil(resource);

    const dispatch = useDispatch();
    const attributesFromStore = useSelector((state) => state.attributes[entityKey], isEqual);
    const attributes = useMemo(() => attributesFromStore ?? [], [attributesFromStore]);
    const isAttributeError = useSelector((state) => state.attributes[entityKey]?.some((a) => a._error));

    const associations = useSelector((state) => state.associations[entityKey]) || [];
    const approvedMeasures = undefined;
    const calculationAssociations = get(associations, `calculationAssociations`, []);

    useEffect(() => {
        if (resource && !isLoading && !resourceInitialized.current) {
            resourceInitialized.current = true;

            dispatch(
                initializeAttributes({
                    key: entityKey,
                    isNew,
                    resource,
                })
            );

            dispatch(
                initializeAssociations({
                    key: entityKey,
                    isNew,
                    resource,
                })
            );
        }
    }, [isNew, entityKey, resource, isLoading, dispatch]);

    const onFormClose = useCallback(
        ({ success = false } = {}) => {
            resourceInitialized.current = false;

            dispatch(
                setAttributes({
                    attributes: null,
                    key: entityKey,
                })
            );

            dispatch(
                setAssociations({
                    associations: null,
                    key: entityKey,
                })
            );

            onClose({ success });
        },
        [entityKey, onClose, dispatch]
    );

    const onSubmit = useCallback(
        async (formData) => {
            try {
                if (attributeFormRef.current) {
                    await submitByRefPromise(attributeFormRef);
                }

                const resourceParams = {
                    resourceName: "programEvents",
                    path: {
                        programNumber,
                    },
                };

                const body = getSubmitData({
                    resource,
                    formData,
                    attributesList: attributes,
                    calculationAssociations,
                });

                submitResource({
                    resourceParams,
                    resourceId,
                    body,
                    setSubmitting,
                    onSuccess: () => {
                        if (isNew) {
                            onFormClose({ success: true });
                        } else {
                            onClose();
                        }
                    },
                    onRefresh: () => {
                        onEventsGridRefresh({ programNumber });
                        refreshApplicationEventCategories();
                        resourceInitialized.current = false;
                    },
                });
            } catch {
                return;
            }
        },
        [programNumber, resourceId, resource, attributes, calculationAssociations, isNew, onFormClose, onClose]
    );

    const handleSelectCalculation = useCallback((event) => {
        setSelectedCalculationNumber(event.calculationNumber);
    }, []);

    useEffect(() => {
        sidePanel.setForm(itemFormRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    if (notFound) {
        return (
            <div className="panel program-events">
                <ErrorMessage>{notFoundText}</ErrorMessage>
            </div>
        );
    }

    if (selectedCalculationNumber) {
        return (
            <Calculation
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                calculationNumber={selectedCalculationNumber}
                onClose={() => setSelectedCalculationNumber(null)}
            />
        );
    }

    return (
        <SideNavContent>
            <SideNavBody rowLayout noPadding>
                <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} sidenavHeaderLeftAligned onClose={onClose}>
                    <ItemForm
                        formRef={itemFormRef}
                        programNumber={programNumber}
                        dataItem={resource}
                        isSubmitting={isSubmitting}
                        onSubmit={onSubmit}
                    />
                    <Associations
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        eventNumber={resourceId}
                        approvedMeasures={approvedMeasures}
                        calculationAssociations={calculationAssociations}
                        onSelectCalculation={canEditCalculation ? handleSelectCalculation : undefined}
                    />
                </SideNavHeader>
                <div className="panel program-events flex-column flex-one-in-row no-scroll fill-height">
                    {attributes.length > 0 && (
                        <div className="atrributes-lead-text flex-row align-center">
                            <CounterBox>{attributes.length}</CounterBox>
                            <span>
                                Item Attribute
                                {attributes.length === 1 ? "" : "s"}
                            </span>
                        </div>
                    )}
                    <div className="atrributes-main-block flex-column flex-one no-scroll">
                        <AttributesForm
                            isLocked={isLocked}
                            programNumber={programNumber}
                            isSubmitting={isSubmitting}
                            attributeFormRef={attributeFormRef}
                            attributesKey={entityKey}
                            availableAttributesResourceName={availableAttributesResourceName}
                            availableAttributesResourceKeys={availableAttributesResourceKeys}
                            onSelectCalculation={canEditCalculation ? handleSelectCalculation : undefined}
                        />
                    </div>
                </div>
            </SideNavBody>
            <SideNavFooter justifyCenter={!isLocked} justifyEnd={isLocked}>
                {!isLocked && (
                    <Button primary onClick={() => submitByRef(itemFormRef)} disabled={isSubmitting || isAttributeError}>
                        Save
                    </Button>
                )}
                <Button onClick={onFormClose}>{isLocked ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
