import React from "react";
import { WarningMessage } from "components/ui/Message";

export const LinkUnderlineWarning = () => {
    return (
        <WarningMessage>
            If not underlined, the link text must have at least 3:1 contrast ratio from the surrounding non-link text and must present a
            "non-color designator" (for example, a different font style) on mouse hover and keyboard focus.
        </WarningMessage>
    );
};
