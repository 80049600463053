import React from "react";
import IconWrap from "components/ui/Icons";
import StatusMsg from "components/ui/StatusMsg";
import { SectionInfo } from "../SectionInfo";
import Separator from "components/ui/Separator";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const ContentPagesInfo = () => {
    const [isProgramPortalBuilder] = usePortalBuilderState((state) => state.isProgramPortalBuilder);

    const msgText = isProgramPortalBuilder ? <ProgramPagesInfo /> : <UtilityPagesInfo />;

    return (
        <SectionInfo>
            <StatusMsg msgIcon="info-empty" msgText={msgText} />
            <Separator line marginBottom={0} marginTop={15} />
        </SectionInfo>
    );
};

const UtilityPagesInfo = () => {
    return (
        <div className="flex-column">
            <span className="info-text-block">
                If you know what kind of pages with a standard non-changeable content will be in use for Portals, create such pages here.
            </span>
            <span>Also, here is a list of already existing standard pages, which can be configured.</span>
        </div>
    );
};

const ProgramPagesInfo = () => {
    return (
        <div className="flex-column">
            <span className="info-text-block">Here is a list of pages based on the selected Template.</span>
            <span className="info-text-block">
                Pages from the Template are marked with <IconWrap iconWrapSmall icon="lock-filled" disabled />, which indicates locked
                customization on Program Level.
            </span>
            <span>You can add new pages and have full control over them in comparison with pages from the selected Template.</span>
        </div>
    );
};
