import { useProgramFormPageFields } from "store/resources/useResource";
import useProgramFormNumber from "./useProgramFormNumber";

const useFormPageFields = ({ entityId, pageNumber }) => {
    const [formNumber, isLoadingForm] = useProgramFormNumber({
        entityId: pageNumber ? entityId : undefined,
    });
    const [fields = [], isLoadingFields] = useProgramFormPageFields({
        programNumber: entityId,
        formNumber,
        pageNumber,
        forced: true,
    });

    return [fields, isLoadingForm || isLoadingFields];
};

export default useFormPageFields;
