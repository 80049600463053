import React, { memo } from "react";

import Button from "../Button";
import ButtonGroup from "../Button/ButtonGroup";
import GridDetailsFooterActions from "./GridDetailsFooterActions";

import "./GridDetailsFooter.scss";

const GridDetailsFooter = memo((props) => {
    return (
        <div className="grid-details-panel-footer flex-row align-center">
            <GridDetailsFooterActions {...props} />
            <span className="flex-one" />
            <ButtonGroup transparent>
                <Button onClick={props.onClose}>Close</Button>
            </ButtonGroup>
        </div>
    );
});

export default GridDetailsFooter;
