import React, { useCallback, memo } from "react";

import { formatFullName, formatFullAddress, hasAnyOfPermissions, systemUserRights } from "../../../../../utils/user";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useCustomerContact } from "../../../../../../store/resources/useResource";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import WaitIcon from "../../../../WaitIcon";
import IconWrap from "../../../../Icons";
import EmailLink from "../../../../EmailLink";
import Reference from "../../../../Reference";
import ContactEditForm from "./ContactEditForm";

const ContactViewForm = memo(({ utilityNumber, customerNumber, contactNumber }) => {
    const [contact = {}, isLoading] = useCustomerContact({
        utilityNumber,
        customerNumber,
        contactNumber,
    });

    const { contacttype, contacttitle, phone, email } = contact;
    const { firstname, lastname, company } = contact;
    const { address, address_cont, city, state, zip } = contact;

    const fullName = formatFullName(firstname, lastname, company);
    const fullAddress = formatFullAddress(address, address_cont, city, state, zip);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.small });

    const editContactDisabled = !hasAnyOfPermissions([systemUserRights.VISIONDSM_ADD_APPLICATION]);

    const handleEditContact = useCallback(() => {
        handleOpenSidePanel(
            <ContactEditForm
                utilityNumber={utilityNumber}
                customerNumber={customerNumber}
                contactNumber={contactNumber}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, customerNumber, contactNumber, handleOpenSidePanel, handleCloseSidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className="contact-panel-item flex-column">
            <IconWrap iconWrapBig iconWrap="user-identity-person-empty" />
            <div className="actions">
                <IconWrap icon="edit-empty" title="Edit Contact" onClick={handleEditContact} disabled={editContactDisabled} />
            </div>
            <div className="flex-column content-lead">
                <div className="contact-row-item type">
                    <Reference type={referenceTypes.userContact} id={contacttype} />
                </div>
                <div className="contact-row-item full-name">{fullName}</div>
            </div>
            <div className="flex-one flex-column content-body">
                <div>
                    <div className="contact-row-item name">
                        <span className="label">
                            <span>Contact Title:</span>
                        </span>
                        <span className="title">
                            <span>{contacttitle ? contacttitle : "-"}</span>
                        </span>
                    </div>
                    <div className="contact-row-item address">
                        <span className="label">
                            <span>Address:</span>
                        </span>
                        <span className="title">
                            <span>{fullAddress ? fullAddress : "-"}</span>
                        </span>
                    </div>
                    <div className="contact-row-item flex-row premise-and-account">
                        <div className="flex-row">
                            {<Identifier label="Account #:" value={contact.acct_number ? contact.acct_number : "-"} />}
                        </div>
                        <div className="flex-row">
                            {<Identifier label="Premise #:" value={contact.premiseid ? contact.premiseid : "-"} />}
                        </div>
                    </div>
                </div>
                <div className="contact-row-item email-container">
                    <div className="phone">
                        <IconWrap iconWrapSmall icon="phone-empty" />
                        {phone ? phone : "-"}
                    </div>
                    <div className="flex-row align-center email">
                        <IconWrap iconWrapSmall icon="email-empty" />
                        {email ? <EmailLink email={email} /> : "-"}
                    </div>
                </div>
            </div>
        </div>
    );
});

const Identifier = ({ label, value }) => {
    if (!value) {
        return null;
    }

    return (
        <>
            <div className="identifier">{label}</div>
            <div>{value}</div>
        </>
    );
};

export default ContactViewForm;
