import React, { memo } from "react";
import cn from "classnames";

import TextLabel from "../../Label/TextLabel";

import "./style.scss";

const InfoList = memo(({ listRef, className, items }) => {
    return (
        <div ref={listRef} className={cn("info-list flex-column", className)}>
            {(items || []).map((item, index) => (
                <div key={index} className={cn("flex-row info-list-row", item.className)}>
                    <TextLabel className="info-list-field-label">{item.label}</TextLabel>
                    <div className="info-list-field-value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default InfoList;
