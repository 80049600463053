import { getTextWidth } from "../../../utils/string";

// Absolute minimum column width
export const MinColumnWidth = 40;

export const getGridListWidth = ({ gridListRef }) => {
    let width = 0;

    if (gridListRef.current) {
        width = gridListRef.current.parentNode.offsetWidth;

        const firstRow = gridListRef.current.querySelector(".grid-list-row");

        if (firstRow) {
            let rowStyles = window.getComputedStyle(firstRow);

            const paddingLeft = parseInt(rowStyles.getPropertyValue("padding-left"), 10);
            const paddingRight = parseInt(rowStyles.getPropertyValue("padding-right"), 10);
            const borderLeftWidth = parseInt(rowStyles.getPropertyValue("border-left-width"), 10);
            const borderRightWidth = parseInt(rowStyles.getPropertyValue("border-right-width"), 10);

            width = width - paddingLeft - paddingRight - borderLeftWidth - borderRightWidth;
        }
    }

    return width;
};

export const getScrollableAreaWidth = ({ gridListRef, isWidget }) => {
    let width = 0;

    if (isWidget) {
        width = gridListRef.current.offsetWidth;
    } else {
        const scrollableArea = gridListRef.current.querySelector(".grid-list-scrollable-columns");

        if (scrollableArea) {
            width = scrollableArea.offsetWidth;
        }
    }

    return width;
};

export const getColumnMinWidths = ({ columns }) => {
    let font = null;

    const minWidths = columns
        .map((column) => {
            let minWidth = 0;

            const columnContainer = document.getElementById(column.key);

            if (columnContainer) {
                const element = columnContainer.querySelector(".column-name");

                if (element) {
                    if (!font) {
                        const computedStyles = window.getComputedStyle(element);
                        font = computedStyles.getPropertyValue("font");
                    }

                    minWidth = Math.floor(getTextWidth(element.textContent, font)) + 18;
                }
            }

            return { [column.key]: minWidth };
        })
        .reduce((result, next) => (result = { ...result, ...next }), {});

    return minWidths;
};

export const getGroupedRows = ({ rowGroups, rows }) => {
    let groupedRows = [];

    if (rowGroups.length) {
        groupedRows = rowGroups.map((item) => {
            let defaultGroups = (item.defaultGroups || []).reduce((result, next) => {
                return {
                    ...result,
                    [next]: [],
                };
            }, []);

            return rows.reduce((result, next) => {
                const groupName = next[item.key] || "";

                // Create group
                if (!result[groupName]) {
                    result[groupName] = [];
                }

                result[groupName].push(next);

                return result;
            }, defaultGroups);
        })[0];
    }

    return groupedRows;
};

export const getSelectAllRowState = ({ rows }) => {
    // Select All checkbox state
    // false: none selected
    // true: all selected
    // null: some selected

    let selectAllRowsState = false;
    if (rows.length) {
        const selectedRowsCount = rows.filter((r) => r._selected).length;
        if (selectedRowsCount > 0) {
            selectAllRowsState = true;

            if (selectedRowsCount !== rows.length) {
                selectAllRowsState = null;
            }
        }
    }

    return selectAllRowsState;
};

export const canResize = (prevWidth, nextWidth, minWidth) => {
    let result = false;

    if (nextWidth >= minWidth || (nextWidth < minWidth && nextWidth > prevWidth)) {
        result = true;
    }

    return result;
};

export const getStoredColumnWidths = (dataGridId) => {
    const storedColumnWidths = localStorage.getItem(`grid-list-column-widths-${dataGridId}`);
    let result = null;
    try {
        result = storedColumnWidths ? JSON.parse(storedColumnWidths) : null;
    } catch (e) {
        console.error(e);
    }

    return result;
};

/**
 * Delayed storing of data grid column widths.
 * Store timeout handles to be able to clear timeouts that are not triggered yet.
 */
const timeoutHandles = {};
export const setStoredColumnWidths = (dataGridId, columnWidths, timeout = 1000) => {
    if (timeoutHandles[dataGridId]) {
        clearTimeout(timeoutHandles[dataGridId]);
    }

    timeoutHandles[dataGridId] = setTimeout(() => {
        localStorage.setItem(`grid-list-column-widths-${dataGridId}`, JSON.stringify(columnWidths));
    }, timeout);
};

export const EVENT_GRID_COLUMN_WIDTHS_RESET = "grid-column-widths-reset";
export const clearStoredColumnWidths = (dataGridId) => {
    localStorage.removeItem(`grid-list-column-widths-${dataGridId}`);

    var event = new CustomEvent(EVENT_GRID_COLUMN_WIDTHS_RESET, {
        detail: {
            dataGridId,
        },
    });
    window.dispatchEvent(event);
};

export const calculateColumnWidths = (dataGridId, prevColumnWidths, columns, columnMinWidths, resizableHeaders, gridListRef, isWidget) => {
    // Do not recalculate if columns not resizable and has width specified.
    if (!resizableHeaders && Object.keys(prevColumnWidths).length === columns.length) {
        return prevColumnWidths;
    }

    let currentColumnWidths = prevColumnWidths;

    columnMinWidths.current = getColumnMinWidths({ columns });

    const storedColumnWidths = getStoredColumnWidths(dataGridId);
    if (storedColumnWidths) {
        if (Object.keys(storedColumnWidths).length === columns.length) {
            return storedColumnWidths;
        }

        currentColumnWidths = storedColumnWidths;
    }

    const scrollableAreaWidth = getScrollableAreaWidth({
        gridListRef,
        isWidget,
    });

    const columnWidthSum = columns
        .map((c) => currentColumnWidths[c.key] || columnMinWidths.current[c.key])
        .reduce((result, next) => (result = result + next), 0);

    let columnExtraWidth = 0;
    if (scrollableAreaWidth > columnWidthSum) {
        columnExtraWidth = (scrollableAreaWidth - columnWidthSum) / columns.length;
    }

    const newColumnWidths = columns
        .map((c) => ({
            [c.key]: currentColumnWidths[c.key]
                ? currentColumnWidths[c.key] + columnExtraWidth
                : columnMinWidths.current[c.key] + columnExtraWidth,
        }))
        .reduce((result, next) => (result = { ...result, ...next }), {});

    return newColumnWidths;
};
