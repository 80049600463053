import React from "react";

import DashboardPanel from "../DashboardPanel";
import { PinnedUtilitiesDataGrid } from "../../../../views/UtilitiesAndProgramManagementBookmarkedUtilities";

const PinnedUtilitiesPanel = ({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="sticky-grid-list-panel">
            <PinnedUtilitiesDataGrid isWidget={true} />
        </DashboardPanel>
    );
};

export default React.memo(PinnedUtilitiesPanel);
