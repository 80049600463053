import { orderBy } from "lodash";
import { useMemo } from "react";
import { useResource } from "store/resources/useResource";
import { ClientProgramsUtility } from "./types";

export const useClientPrograms = (clientNumber: string) => {
    const [response, isLoading] = useResource({
        resourceName: "clientPrograms",
        resourceId: undefined,
        key: clientNumber,
        path: {
            clientNumber,
        },
        forced: true,
        onError: undefined,
        showSuccessNotification: false,
        showErrorNotification: true,
        transform: undefined,
    }) as [response: ClientProgramsResponse, isLoading: boolean];

    return useMemo(() => {
        return [orderResponse(response), isLoading];
    }, [response, isLoading]) as [ClientProgramsUtility[], boolean];
};

// Order lists in the response data
const orderResponse = (data: ClientProgramsResponse): ClientProgramsUtility[] => {
    const orderedUtilities = orderBy(data?.utilityList ?? [], ["utilityName"], ["asc"]);

    return orderedUtilities.map((utility: ClientProgramsUtility) => {
        return {
            ...utility,
            programList: orderBy(utility.programList, ["programName"], ["asc"]),
        };
    });
};

interface ClientProgramsResponse {
    utilityList: ClientProgramsUtility[];
}
