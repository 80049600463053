import React, { memo, useCallback, useContext, useRef } from "react";
import cn from "classnames";
import { PortalBuilderHtmlPropertyProps, PropertyValue } from "components/ui/PortalBuilder/types";
import HtmlEditorWidget from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget";
import TextLabel from "components/ui/Label/TextLabel";
import { useDefaultValue, useShowError } from "components/ui/PortalBuilder/utils";
import { ToolbarTypes } from "components/ui/Form/JsonSchema/widgets/HtmlEditorWidget/toolbars";
import { isPropertyRequired } from "components/ui/PortalBuilder/utils/validation";
import PropertyErrors from "../PropertyErrors";
import { isEmpty } from "lodash";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { useErrorContext } from "components/ui/PortalBuilder/PortalBuilderErrorContextProvider";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";

export const HtmlProperty = memo((props: PortalBuilderHtmlPropertyProps) => {
    const {
        id,
        title,
        value,
        onChange,
        defaultValue,
        validationRules,
        toolbar,
        useTemplateColors,
        borderBottom,
        listContainsActiveError = false,
    } = props;

    const editorOptions = {
        toolbar: toolbar ?? ToolbarTypes.PortalBuilder,
        fastTags: ["YEAR"],
        forcePasteAsPlainText: true,
        hideLinkAdvancedTab: true,
        restrictedTablePlugin: true,
    };

    const required = isPropertyRequired(validationRules);
    const context = useErrorContext();
    const { colors } = useContext(PortalBuilderContext);

    const onPropertyChange = useCallback(
        (value: PropertyValue) => {
            onChange(id, value);
        },
        [id, onChange]
    );

    const elementRef = useRef<HTMLDivElement>(null);

    useDefaultValue(id, value, defaultValue, onChange);

    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    let errors = !isEmpty(props.errors)
        ? props.errors
        : [context?.errors.find((e) => e.id.substring(e.id.indexOf(".") + 1) === id)?.message];

    function getTemplateColors() {
        if (useTemplateColors) {
            const configColors = (colors as Object) ?? {};
            return Object.entries(configColors)
                .map(([key, value]) => `${key}/${value?.substring(1)}`)
                .join(",");
        }
    }

    return (
        <PropertyListItem
            ref={elementRef}
            className={cn("property-list-item--html", {
                "light-up-error": lightUpError,
                error: errors?.length,
            })}
            borderBottom={borderBottom}
        >
            <div className="property-list-item-inner flex-column full-width-property">
                {title && (
                    <TextLabel
                        className={cn({
                            required,
                        })}
                    >
                        {title}:
                    </TextLabel>
                )}
                <HtmlEditorWidget options={editorOptions} value={value} onChange={onPropertyChange} colors={getTemplateColors()} />
                {/* @ts-ignore */}
                {!isEmpty(errors) && <PropertyErrors errors={errors}></PropertyErrors>}
            </div>
        </PropertyListItem>
    );
});
