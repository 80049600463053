import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { SectionHeader } from "../SectionHeader";
import { PageContext, PortalBuilderPropertiesContext, RowContext } from "components/ui/PortalBuilder/contexts";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaForm";
import { entityType } from "components/utils/entityType";
import { useResource } from "store/resources/useResource";
import { useDispatch } from "react-redux";
import { uploadFile } from "store/files/actions";
import { refreshPortalBuilderFiles } from "store/resources/refreshResource";
import WaitIcon from "components/ui/WaitIcon";
import { getFileExtension } from "components/utils/files";
import FileView from "components/views/FileView";
import IconHandMade from "components/ui/Icons/IconHandMade";
import cn from "classnames";
import CloseCircle from "components/ui/Icons/CloseCircle";
import { isNil } from "lodash";
import Label from "components/ui/Label";
import IconWrap from "components/ui/Icons";
import { PropertyListItem } from "./PropertyListItem";
import PropertyErrors from "./PropertyErrors";
import { isPropertyRequired } from "../../utils/validation";
import Separator from "components/ui/Separator";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import PropertyDescription from "../Property/PropertyDescription";

import "./FileSelectProperty.scss";

const FileSelectProperty = (props) => {
    const {
        id,
        onChange,
        value,
        buttonTitle = {},
        selectTooltip,
        fileType,
        errors,
        validationRules,
        borderTop,
        borderBottom,
        customSubtitle,
        description,
    } = props;
    const dispatch = useDispatch();
    const formRef = useRef();
    const { sectionIcon, sectionTitle, selectedColumn } = useContext(PortalBuilderPropertiesContext);
    const { row } = useContext(RowContext);
    const { page } = useContext(PageContext);

    const [isConfigView, setIsConfigView] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isFilesPresent, setIsFilesPresent] = useState(false);
    const required = isPropertyRequired(validationRules);
    const [isDarkFileListBackground, setPortalBuilderState] = usePortalBuilderState((state) => state.isDarkFileListBackground);
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);

    let allowedFileTypes = [];
    let allowedFileTypeError = "";

    if (fileType === "Image") {
        allowedFileTypes = ["jpeg", "png", "jpg", "gif", "svg"];
        allowedFileTypeError = "This is not an image. Can't be selected.";
    }
    if (fileType === "Favicon") {
        allowedFileTypes = ["png", "ico", "gif"];
        allowedFileTypeError = "This is not an image. Can't be selected.";
    }

    const [files, loading] = useResource({
        resourceName: "fileList",
        key: utilityNumber,
        query: { entityTypeId: entityType.portalTemplate, entityId: utilityNumber },
    });

    const onSelectFile = useCallback(
        (file) => {
            onChange(id, file.publicPath);
            setIsConfigView(false);
        },
        [id, onChange]
    );

    const onRemove = useCallback(() => {
        onChange(id, undefined);
    }, [onChange, id]);

    function isSelectable(file) {
        const fileExtension = getFileExtension(file.fileName).substring(0, 4).toLowerCase();
        if (allowedFileTypes.includes(fileExtension)) return true;
        return false;
    }

    const fileName = value?.split("/").pop();

    const file = {
        publicPath: value ?? "",
        fileName: fileName,
    };

    const subtitle = useMemo(
        () => (
            <div className="flex-column align-center">
                {page && (
                    <span>
                        EDIT <b className="page-title">{page?.title}</b> PAGE
                    </span>
                )}
                <span className="black-text">
                    {row && (
                        <>
                            {row.title}{" "}
                            <span className="non-transformed-text">
                                section-{selectedColumn + 1} "{fileType}" widget
                            </span>
                        </>
                    )}
                    {customSubtitle && <span className="non-transformed-text">{customSubtitle}</span>}
                </span>
            </div>
        ),
        [customSubtitle, fileType, page, row, selectedColumn]
    );

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "DropZoneWidget",
            "ui:multiple": true,
        },
    };

    const onUpload = useCallback((form) => {
        setIsFilesPresent((form.formData.file ?? []).length > 0);
    }, []);

    const onSuccess = useCallback(() => {
        refreshPortalBuilderFiles({
            utilityNumber: utilityNumber,
            onSuccess: () => {
                formRef.current.setFormData({ file: [] });
                setIsUploading(false);
            },
        });
    }, [utilityNumber]);

    const onSubmit = useCallback(
        (file) => {
            if (file) {
                setIsUploading(true);
                dispatch(
                    uploadFile({
                        fileData: {
                            file,
                            entityTypeId: entityType.portalTemplate,
                            entityId: utilityNumber,
                            fileTypeId: 27,
                            itemFilterId: 149,
                        },
                        onUploadSuccess: onSuccess,
                        onUploadError: () => {
                            setIsUploading(false);
                        },
                    })
                );
            }
        },
        [dispatch, onSuccess, utilityNumber]
    );

    const tilesContainerClasses = cn("justify-start file-tiles-container flex-row flex-wrap align-content-start", {
        "dark-background": isDarkFileListBackground,
    });

    if (isConfigView) {
        return (
            <div className="file-select-property portal-builder-properties-overlay-view flex-column fill-width">
                <SectionHeader
                    Icon={sectionIcon}
                    title={sectionTitle ?? "Select file"}
                    subtitle={subtitle}
                    underline
                    onGoBack={() => setIsConfigView(false)}
                />
                {files?.fileList.length > 0 && fileType === "Image" && (
                    <div className="flex-row justify-space-between align-center background-switcher">
                        <Label contentLabel>Preview on dark background</Label>
                        <IconWrap
                            onClick={() => setPortalBuilderState({ isDarkFileListBackground: !isDarkFileListBackground })}
                            iconWrapBig
                            title={isDarkFileListBackground ? "Switch OFF" : "Switch ON"}
                            icon={isDarkFileListBackground ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                        ></IconWrap>
                    </div>
                )}
                {loading ? (
                    <div className="flex-row justify-center">
                        <WaitIcon withSpaceAround />
                    </div>
                ) : (
                    <>
                        <div className={tilesContainerClasses}>
                            {files?.fileList.map((f) => {
                                return (
                                    <div
                                        title={!isSelectable(f) && allowedFileTypeError}
                                        onClick={isSelectable(f) ? () => onSelectFile(f) : undefined}
                                        key={f.publicPath}
                                        className={cn("file-tile flex-column", { disabled: !isSelectable(f) })}
                                    >
                                        <FilePreview file={f} />
                                        <span className="file-name">{f.fileName}</span>
                                        <div
                                            className="file-select-overlay"
                                            onClick={isSelectable(f) ? () => onSelectFile(f) : undefined}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="file-upload-form">
                            <Separator line marginTop={0} marginBottom={0} />
                            <JsonSchemaForm
                                noSubmit={!isFilesPresent}
                                formRef={formRef}
                                schema={schema}
                                uiSchema={uiSchema}
                                onSubmit={(f) => onSubmit(f.file)}
                                submitText={isUploading ? "Uploading..." : "Upload"}
                                noReset
                                onChange={onUpload}
                                disabled={isUploading}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <PropertyListItem borderTop={borderTop} borderBottom={borderBottom}>
            {description ? <PropertyDescription description={description} /> : ""}
            {!isNil(value) ? (
                <div>
                    <div className="selected-file-label">Selected {fileType}:</div>
                    <div key={value} className="selected-file-tile flex-row align-center">
                        <FilePreview file={file} />
                        <span className="file-name">{fileName}</span>
                        <CloseCircle title={"Remove selected " + fileType} onClick={() => onRemove()} />
                    </div>
                </div>
            ) : (
                <div className="file-select-property-button flex-row align-center justify-space-between">
                    <Label onClick={() => setIsConfigView(true)} labelRequired={required}>
                        {buttonTitle}
                    </Label>
                    <IconWrap icon="touch-empty" title={selectTooltip} onClick={() => setIsConfigView(true)}></IconWrap>
                </div>
            )}
            <PropertyErrors errors={errors} />
        </PropertyListItem>
    );
};

export const FilePreview = ({ file }) => {
    const extension = getFileExtension(file.fileName).substring(0, 4).toLowerCase();
    const isPreviewAvailable = ["jpeg", "png", "jpg", "gif", "svg"].includes(extension);

    return (
        <div className="flex-row justify-center align-center file-preview-container">
            {isPreviewAvailable ? (
                <FileView hideControls publicPath={file.publicPath} />
            ) : (
                <IconHandMade className="margin-auto" iconDocumentHandMade big title={extension} />
            )}
        </div>
    );
};

export default FileSelectProperty;
