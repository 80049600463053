import { Tab } from "components/ui/List/TabList";
import React, { useContext, useEffect, useState } from "react";
import { WidgetListItem } from "./types";
import { WidgetsList } from "./WidgetsList";
import { Widget } from "./Widget";

import "./Widgets.scss";
import { GlobalLinkProperties } from "./GlobalLinkProperties";
import { GlobalButtonProperties } from "./GlobalButtonProperties";
import { GlobalContentWrapperProperties } from "./GlobalContentWrapperProperties";
import { GlobalHeadingProperties } from "./GlobalHeadingProperties";
import { GlobalInputFieldProperties } from "./GlobalInputFieldProperties";
import { GlobalListProperties } from "./GlobalListProperties";
import { PortalBuilderPropertiesContext } from "../../contexts";
import { SectionHeader } from "../SectionHeader";
import { isNil } from "lodash";
import { WidgetType } from "../../types";

export const Widgets = (props: any) => {
    const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
    const [activeWidget, setActiveWidget] = useState<WidgetListItem | any>();
    const { setSubTitle } = useContext(PortalBuilderPropertiesContext);
    const { selectedWidget } = useContext(PortalBuilderPropertiesContext);

    useEffect(() => {
        if (activeWidget) {
            setSubTitle && setSubTitle(activeWidget?.title);
        }
        if (selectedWidget) {
            setActiveWidget(selectedWidget);
        }
    }, [activeWidget, setSubTitle, selectedWidget]);

    const listItems = activeTab === tabs[0] ? SINGLE_WIDGETS : COMBINED_WIDGETS;

    const onEdit = (widget: WidgetListItem) => {
        setActiveWidget(widget);
    };

    return (
        <div className="portal-builder-widgets-section flex-one-in-column flex-column fill-height no-scroll">
            {activeWidget ? (
                <Widget widget={activeWidget} onClose={() => setActiveWidget(undefined)} />
            ) : (
                <WidgetsList items={listItems} tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} onEdit={onEdit} />
            )}
        </div>
    );
};

export const WidgetsSelect = (props: any) => {
    const { id, onChange, onClose, row } = props;
    const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
    const { sectionTitle, sectionIcon, subTitle, selectedColumn } = useContext(PortalBuilderPropertiesContext);

    const fullSubTitle = (
        <span>
            <span className="black-text">
                {isNil(row?.title) ? subTitle ?? "Main" : row.title}
                {isNil(selectedColumn) ? "" : <span className="non-transformed-text"> section-{selectedColumn + 1}</span>}
            </span>{" "}
            widget selection
        </span>
    );

    const listItems = activeTab === tabs[0] ? SINGLE_WIDGETS : COMBINED_WIDGETS;

    const selectableListItems = listItems.map((i) => {
        if (["InputField", "ContentWrapper"].includes(i.type ?? "")) {
            return { ...i, isEditable: false };
        } else {
            return { ...i, isEditable: true };
        }
    });

    const onSelect = (widget: WidgetListItem) => {
        onChange(id, widget.type);
        onClose();
    };
    return (
        <div className="portal-builder-widgets-section flex-one-in-column flex-column fill-height no-scroll">
            <SectionHeader Icon={sectionIcon} title={sectionTitle} subtitle={fullSubTitle} onGoBack={onClose} />
            <WidgetsList
                items={selectableListItems}
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                onClick={onSelect}
                noWidgetInfo
                noSectionHeader
            />
        </div>
    );
};

const tabs: Tab[] = [
    {
        id: "single",
        title: "Single",
    },
    {
        id: "combined",
        title: "Combined",
    },
];

export const SINGLE_WIDGETS: WidgetListItem[] = [
    {
        title: "Button",
        isEditable: true,
        Component: GlobalButtonProperties,
        type: WidgetType.BUTTON,
    },
    {
        title: "Content Wrapper",
        isEditable: true,
        Component: GlobalContentWrapperProperties,
        type: WidgetType.CONTENT_WRAPPER,
    },
    {
        title: "Heading",
        isEditable: true,
        Component: GlobalHeadingProperties,
        type: WidgetType.HEADING,
    },
    {
        title: "Image",
        isEditable: true,
        type: WidgetType.IMAGE,
    },
    {
        title: "Input Field",
        isEditable: true,
        Component: GlobalInputFieldProperties,
        type: WidgetType.INPUT_FIELD,
    },
    {
        title: "Link",
        isEditable: true,
        Component: GlobalLinkProperties,
        type: WidgetType.LINK,
    },
    {
        title: "List",
        isEditable: true,
        Component: GlobalListProperties,
        type: WidgetType.LIST,
    },
    {
        title: "Text",
        isEditable: true,
        type: WidgetType.TEXT_WIDGET,
    },
    {
        title: "Header navigation",
        isEditable: false,
        type: WidgetType.HEADER_NAVIGATION_WIDGET,
    },
    {
        title: "Accordion",
        isEditable: false,
        type: WidgetType.ACCORDION,
    },
    {
        title: "Carousel",
        isEditable: false,
        type: WidgetType.CAROUSEL,
    },
    {
        title: "PowerBI report",
        isEditable: false,
        type: WidgetType.POWER_BI_PUBLIC_REPORT,
    },
];

export const COMBINED_WIDGETS: WidgetListItem[] = [
    // V50-7184: Hidden until development starts
    // {
    //     title: "Field Group",
    //     isEditable: false,
    //     type: WidgetType.FIELD_GROUP,
    // },
    // {
    //     title: "Icon & Text",
    //     isEditable: false,
    //     type: WidgetType.ICON_AND_TEXT,
    // },
    // {
    //     title: "Image & Link",
    //     isEditable: false,
    //     type: WidgetType.IMAGE_AND_LINK,
    // },
    // {
    //     title: "Image & Text",
    //     isEditable: false,
    //     type: WidgetType.IMAGE_AND_TEXT,
    // },
    {
        title: "Navigation List",
        isEditable: false,
        type: WidgetType.NAVIGATION_WIDGET,
    },
    {
        title: "Social Links",
        isEditable: false,
        type: WidgetType.SOCIAL_LINKS,
    },
    {
        title: "Title & Button",
        isEditable: false,
        type: WidgetType.TITLE_AND_BUTTON,
    },
    {
        title: "Card Content",
        isEditable: false,
        type: WidgetType.CARD_CONTENT,
    },
];
