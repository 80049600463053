import { isEmpty } from "lodash";

export const deepOmitBy = (object, fn) => {
    if (typeof object !== "object" || object === null) {
        return object;
    }

    return Object.keys(object).reduce((prevValue, key) => {
        const value = object[key];
        if (typeof value === "object" && value !== null) {
            const recursValue = deepOmitBy(value, fn);
            if (!isEmpty(recursValue)) {
                prevValue[key] = recursValue;
            }
        } else if (!fn(value)) {
            prevValue[key] = value;
        }
        return prevValue;
    }, {});
};
