import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { modalOpen } from "../../../../../../../store/modal/actions";
import { deleteResource } from "../../../../../../../store/resources/actions";
import { mapGridRowToObject } from "../../../../../../utils/datagrid";
import { availableGrids } from "../../../../../../views/configureGrids";

import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../SideNav/SideNavRoot";

import WaitIcon from "../../../../../WaitIcon";
import Form from "./Form";
import DataGrid from "../../../../../DataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";
import { useDataGrid } from "components/utils/useDataGrid";

const programEventCategoriesGridColumnKeys = {
    categoryNumber: "c_2946b3dd-b07c-456d-923f-b755e02e7c70",
    category: "c_e98fb41e-54af-4b84-b88a-5d9a9a60dbcc",
    parentCategoryNumber: "c_6b8fc330-cb40-439c-8350-6881b1b3b8f7",
    parentCategoryName: "c_3af408a6-02a0-4665-a85b-c7f22b46f167",
};

const Grid = memo(({ dataGridId, programNumber, onRefresh }) => {
    const dispatch = useDispatch();

    const isLocked = isChildProgram({ programNumber });

    const rowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
            footerExclude: true,
        },
        {
            name: "add",
            icon: "plus",
            title: "Add New Child Category",
            hide: isLocked,
            footerExclude: true,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Remove Category",
            hide: (row) => isLocked || row._treeHasChildren,
            footerExclude: true,
        },
    ];

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.programEventCategories,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "eventCategoriesGridGetDataAction",
            props: {
                columnKeysMap: programEventCategoriesGridColumnKeys,
                programNumber,
            },
        },
        tree: {
            treeColumn: programEventCategoriesGridColumnKeys.category,
            idColumn: programEventCategoriesGridColumnKeys.categoryNumber,
            parentIdColumn: programEventCategoriesGridColumnKeys.parentCategoryNumber,
            parentNameColumn: programEventCategoriesGridColumnKeys.parentCategoryName,
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "event-category-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(programEventCategoriesGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form dataItem={data} onClose={handleCloseSidePanel} programNumber={programNumber} gridRefresh={onRefresh} />
                );
                break;

            case "add":
                handleOpenSidePanel(
                    <Form dataItem={data} onClose={handleCloseSidePanel} programNumber={programNumber} gridRefresh={onRefresh} addChild />
                );
                break;

            case "delete":
                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: "Delete Category",
                            modalIcon: "delete-trash-empty",
                            className: "modal-sm",
                            overlayClassName: "modal-styled",
                            content: (
                                <p>
                                    Would you like to delete category <strong>{data.category}</strong>?
                                </p>
                            ),
                            footerContentCenter: true,
                            onConfirm: () => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "programEventCategories",
                                        resourceId: data.categoryNumber,
                                        path: {
                                            programNumber,
                                        },
                                        onSuccess: () => {
                                            onRefresh();
                                        },
                                    })
                                );
                            },
                        },
                    })
                );
                break;

            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            notExpandableRowIcon="catalog"
        />
    );
});

export default Grid;
