import React from "react";
import PropTypes from "prop-types";
import Radio from "../../../Input/Radio";

function RadioWidget(props) {
    const { options, id, value, disabled, readonly, autofocus, onChange } = props;
    const name = Math.random().toString();
    const { enumOptions, enumDisabled, inline } = options;

    const optionsArray = Array.isArray(enumOptions) ? enumOptions : [];

    return (
        <div className="field-radio-group">
            {optionsArray.map((option, i) => {
                const checked = option.value === value;
                const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
                const disabledCls = disabled || itemDisabled || readonly ? "disabled" : "";

                // For debugging. Log error if there are multiple elements with the same id
                const radioId = `${id}_${i}`;
                const selector = "#" + CSS.escape(radioId);
                if (document.querySelectorAll(selector).length > 1) {
                    console.error(`Duplicate radio option id: ${radioId}`);
                }

                const radio = (
                    <Radio
                        id={radioId}
                        name={name}
                        label={option.label}
                        checked={checked}
                        value={option.value}
                        disabled={disabled || itemDisabled || readonly}
                        onChange={(_) => onChange(option.value)}
                        autofocus={autofocus}
                    />
                );

                return inline ? (
                    <div key={i} className={`radio-inline ${disabledCls}`}>
                        {radio}
                    </div>
                ) : (
                    <div key={i} className={`radio ${disabledCls}`}>
                        {radio}
                    </div>
                );
            })}
        </div>
    );
}

RadioWidget.defaultProps = {
    autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
    RadioWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.shape({
            enumOptions: PropTypes.array,
            inline: PropTypes.bool,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
    };
}
export default RadioWidget;
