import React, { memo, useState, useCallback, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import IconWrap from "../../ui/Icons";
import WorkqueueFilters from "./WorkqueueFilters";
import WorkqueueFilterResults from "./WorkqueueFilterResults";
import { windowContainerTypes } from "../../utils/window";
import { initWorkcenter } from "store/workcenter/actions";
import { WindowContext } from "components/ui/Windows/Window";
import { createId } from "components/utils/string";

import "./style.scss";

export const WorkCenterContext = React.createContext();

const DEFAULT_INSTANCE_ID = "workcenter";

const ApplicationProcessingWorkCenter = memo(() => {
    const { viewName } = useContext(WindowContext);
    const instanceIdRef = useRef(createId());
    const workcenterInstanceId = viewName === "ApplicationProcessing" ? DEFAULT_INSTANCE_ID : instanceIdRef.current;

    const dispatch = useDispatch();
    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);
    const isTabletLarge = useSelector((state) => state.window?.isTabletLarge);
    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);

    const [showFilter, setShowFilter] = useState(false);

    const workcenterInitialized = useSelector((state) => state.workcenter[workcenterInstanceId]) !== undefined;

    useEffect(() => {
        if (!workcenterInitialized) {
            dispatch(initWorkcenter({ instanceId: workcenterInstanceId }));
        }
    }, [workcenterInstanceId, workcenterInitialized, dispatch]);

    const toggleShowHideFilter = useCallback(() => {
        setShowFilter((prevValue) => !prevValue);
    }, []);

    if (!workcenterInitialized) {
        // Return empty node to prevent scrollSyncPane exception
        return <div></div>;
    }

    return (
        <WorkCenterContext.Provider
            value={{
                instanceId: workcenterInstanceId,
            }}
        >
            <div className="app-processing-workcenter fill-height no-scroll">
                <div className="main-grid-wrap responsive fill-height">
                    <div className="flex-row fill-height">
                        {(isMobile || isTablet || isTabletLarge || isSplitView) && (
                            <div
                                className={
                                    "show-hide-filter-panel flex-column align-center justify-space-between" +
                                    (!showFilter ? " filter-hidden" : "")
                                }
                                onClick={toggleShowHideFilter}
                            >
                                <IconWrap icon={!showFilter ? "shevron-in-circle-right-empty" : "shevron-in-circle-left-filled"}></IconWrap>
                                <span>{showFilter ? "hide filter" : "show filter"}</span>
                                <IconWrap icon={!showFilter ? "shevron-in-circle-right-empty" : "shevron-in-circle-left-filled"}></IconWrap>
                            </div>
                        )}
                        <div className={"filter-block-wrap" + (!showFilter ? " hidden" : "")}>
                            <WorkqueueFilters closeFilterPanel={toggleShowHideFilter} />
                        </div>
                        <WorkqueueFilterResults isFilterPanelOpen={showFilter} expandFilterPanel={toggleShowHideFilter} />
                    </div>
                </div>
            </div>
        </WorkCenterContext.Provider>
    );
});

export default ApplicationProcessingWorkCenter;
