import React, { memo, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { downloadFile, deleteFile, downloadAllFiles } from "../../../store/files/actions";
import { refreshGrid } from "../../../store/dataGrid/refreshGrid";
import { filesGridColumnCellContent, DEFAULT_FOLDER, FileSecurity } from "../../utils/files";
import { openFileTab } from "components/utils/window";
import { WindowContext } from "../Windows/Window";
import { getResourcePromise, useFolders, useResource } from "store/resources/useResource";
import { updateResource } from "store/resources/actions";
import { entityType } from "components/utils/entityType";

import DataGrid from ".";
import FilesGridFolderRow from "./FilesGridFolderRow";
import Button from "../Button";
import WaitIcon from "../WaitIcon";
import DropDownInput from "../Input/DropDownInput";
import Separator from "../Separator";

import FileView from "components/views/FileView";
import SideNavBody from "../SideNav/SideNavBody";
import SideNavFooter from "../SideNav/SideNavFooter";
import SideNavHeader from "../SideNav/SideNavHeader";
import SideNavContent from "../SideNav/SideNavContent";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import { isNil } from "lodash";
import TextInput from "../Input/TextInput";

import "./FilesGrid.scss";

const FilesGrid = memo(
    ({
        applicationNumber,
        isApplicationGrid,
        programNumber,
        gridId,
        dataGridConfig,
        gridColumnKeys,
        isLocked,
        onRefresh,
        entityId,
        entityTypeId,
        canUseSplitScreenView,
        utilityNumber,
        canEdit,
    }) => {
        const dispatch = useDispatch();
        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
            className: "files-grid-file-preview-sidenav-panel",
        });

        const { splitWindowPosition } = useContext(WindowContext) ?? {};
        const [downloadingRowId, setDownloadingRowId] = useState(-1);
        const [downloadingFolderName, setDownloadingFolderName] = useState();

        const onDownloadFile = useCallback(
            (file, id) => {
                setDownloadingRowId(id);

                dispatch(
                    downloadFile({
                        fileId: file.fileId,
                        onComplete: () => {
                            setDownloadingRowId(-1);
                        },
                    })
                );
            },
            [dispatch]
        );

        const rowActions = [
            {
                name: "open-new-window",
                icon: canUseSplitScreenView ? "reader-mode-empty" : "open-new-window",
                title: canUseSplitScreenView ? "Open in Split View" : "View in Tab",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            ...(entityTypeId === entityType.projectApplication
                ? [
                      {
                          name: "edit",
                          icon: "edit-empty",
                          title: "Edit",
                          disabled: (row) => (isApplicationGrid && !row.allowAccess) || !canEdit,
                      },
                  ]
                : []),
            {
                name: "download",
                icon: (row) => (row?.id === downloadingRowId ? "waiting" : "download"),
                title: "Download File",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            {
                name: "sidebar",
                icon: "eye-visibility-empty",
                title: "View in Sidebar",
                disabled: (row) => isApplicationGrid && !row.allowAccess,
            },
            {
                name: "delete",
                icon: "delete-trash-empty",
                title: "Delete File",
                hide: isLocked,
                disabled: (row) => (isApplicationGrid && !row.allowDelete) || !canEdit,
            },
        ];

        const handleOpen = useCallback(
            ({ dataItem }) => {
                const title = dataItem[gridColumnKeys.fileName];
                const leadBlockIcon = "eye-visibility-empty";

                handleOpenSidePanel(
                    <SideNavContent>
                        <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={handleCloseSidePanel} />
                        <SideNavBody className="flex-one-in-column">
                            <FileView fileNumber={dataItem[gridColumnKeys.fileId]} />
                        </SideNavBody>
                        <SideNavFooter justifyEnd>
                            <Button onClick={handleCloseSidePanel}>Close</Button>
                        </SideNavFooter>
                    </SideNavContent>
                );
            },
            [gridColumnKeys.fileId, gridColumnKeys.fileName, handleOpenSidePanel, handleCloseSidePanel]
        );

        const onRowAction = (action) => {
            const { dataItem } = action;

            const file = {
                fileId: dataItem[gridColumnKeys.fileId],
                fileName: dataItem[gridColumnKeys.fileName],
            };

            switch (action.name) {
                case "edit":
                    document.activeElement.blur();
                    handleOpenSidePanel(
                        <FileEditForm
                            dataItem={dataItem}
                            gridId={gridId}
                            utilityNumber={utilityNumber}
                            onClose={handleCloseSidePanel}
                            fileId={dataItem[gridColumnKeys.fileId]}
                            fileSecurity={dataItem[gridColumnKeys.security]}
                            fileAuthorizationGroup={dataItem.fileAuthorizationGroupNumber || ""}
                            fileAuthorizationGroupName={dataItem[gridColumnKeys.fileAuthorizationGroup]}
                            tag={dataItem[gridColumnKeys.tag]}
                            folder={dataItem[gridColumnKeys.folder]}
                            entityTypeId={entityTypeId}
                            entityId={programNumber}
                            onRefresh={() => {
                                refreshGrid({ dataGridId: gridId });
                                onRefresh && onRefresh();
                            }}
                            applicationNumber={applicationNumber}
                            isApplicationGrid={isApplicationGrid}
                        />,
                        { size: sideNavSize.small }
                    );
                    break;
                case "download":
                    document.activeElement.blur();
                    onDownloadFile(file, dataItem.id);
                    break;
                case "open-new-window":
                    openFileTab({
                        fileNumber: dataItem[gridColumnKeys.fileId],
                        fileName: dataItem[gridColumnKeys.fileName],
                        openInSplitView: canUseSplitScreenView ? true : false,
                        splitWindowPosition,
                    });
                    break;
                case "sidebar":
                    handleOpen({ dataItem });
                    break;
                case "delete":
                    dispatch(
                        deleteFile({
                            ...file,
                            onDeleteSuccess: () => {
                                refreshGrid({ dataGridId: gridId });
                                onRefresh && onRefresh();
                            },
                        })
                    );
                    break;
                default:
                    break;
            }
        };

        const onFolderDownload = (groupName) => {
            let folder = groupName;
            setDownloadingFolderName(groupName);
            dispatch(
                downloadAllFiles({
                    entityId,
                    entityTypeId,
                    folder,
                    onComplete: () => {
                        setDownloadingFolderName();
                    },
                })
            );
        };

        return (
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                rowGroups={[
                    {
                        key: gridColumnKeys.folder,
                    },
                ]}
                groupRender={(props) => (
                    <FilesGridFolderRow {...props} onFolderDownload={onFolderDownload} downloadingFolderName={downloadingFolderName} />
                )}
                onRowAction={onRowAction}
                customRowActions={rowActions}
                columnCellContent={filesGridColumnCellContent({ gridColumnKeys })}
                noFilter
            />
        );
    }
);

const FileEditForm = memo((props) => {
    const { fileId, entityTypeId, entityId, onClose, onRefresh, utilityNumber, isApplicationGrid, applicationNumber } = props;

    const dispatch = useDispatch();

    const [tags, setTags] = useState("");
    const [folderName, setFolderName] = useState();
    const [fileSecurity, setFileSecurity] = useState();
    const [fileAuthorizationGroup, setFileAuthorizationGroup] = useState();
    const [fileAuthorizationGroupName, setFileAuthorizationGroupName] = useState();
    const [fileTags, setFileTags] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [folders = [], isLoadingFolders] = useFolders({
        entityTypeId,
        entityId,
    });

    const folderList = useMemo(() => {
        if (folders.length === 0) {
            return [
                {
                    label: DEFAULT_FOLDER,
                    value: DEFAULT_FOLDER,
                },
            ];
        }

        return folders.map((i) => ({
            label: i.display,
            value: i.value,
        }));
    }, [folders]);

    const [fileAuthorizationList = []] = useResource({
        resourceName: "fileAuthorizationList",
        resourceId: utilityNumber,
        transform: (data) =>
            data?.fileAuthorizationList
                ? data.fileAuthorizationList.map((f) => {
                      return { label: f.fileAuthorizationGroupName, value: f.fileAuthorizationGroupNumber || "" };
                  })
                : [],
    });

    const fileSecurityValues = [
        {
            label: "Public",
            value: FileSecurity.PUBLIC,
        },
        {
            label: "Private",
            value: FileSecurity.PRIVATE,
        },
    ];

    const buttonText = isSubmitting ? "Saving..." : "Save";

    useEffect(() => {
        if (isApplicationGrid && applicationNumber) {
            const getAppFileTags = async () => {
                const fileTagData = await getResourcePromise({
                    resourceName: "fileUploadTags",
                    key: applicationNumber,
                    path: {
                        appId: applicationNumber,
                    },
                });

                if (!isNil(fileTagData)) {
                    setFileTags(fileTagData);
                } else {
                    setFileTags([]);
                }
            };
            getAppFileTags();
        }
    }, [applicationNumber, isApplicationGrid]);

    const tagList = useMemo(() => {
        return fileTags?.fileTagList
            ? fileTags.fileTagList.map((ft) => ({
                  label: ft.fileTag,
                  value: ft.fileTag,
              }))
            : [];
    }, [fileTags]);

    useEffect(() => {
        setTags(props.tag);
        setFolderName(props.folder);
        setFileSecurity(props.fileSecurity);
        setFileAuthorizationGroup(props.fileAuthorizationGroup);
        setFileAuthorizationGroupName(props.fileAuthorizationGroupName);
    }, [props]);

    const onSubmit = useCallback(() => {
        setIsSubmitting(true);

        const tagUpdatePromise = new Promise((resolve, reject) => {
            if (tags !== props.tag) {
                dispatch(
                    updateResource({
                        resourceName: "fileTags",
                        path: {
                            fileId,
                        },
                        query: {
                            tags,
                        },
                        showSuccessNotification: false,
                        onSuccess: resolve,
                        onError: reject,
                    })
                );
            } else {
                resolve();
            }
        });

        const folderUpdatePromise = new Promise((resolve, reject) => {
            if (folderName !== props.folder) {
                dispatch(
                    updateResource({
                        resourceName: "fileFolder",
                        path: {
                            fileId,
                        },
                        query: {
                            folderName,
                        },
                        showSuccessNotification: false,
                        onSuccess: resolve,
                        onError: reject,
                    })
                );
            } else {
                resolve();
            }
        });

        const fileSecurityUpdatePromise = new Promise((resolve, reject) => {
            if (fileSecurity !== props.fileSecurity) {
                dispatch(
                    updateResource({
                        resourceName: "fileSecurity",
                        path: {
                            fileId,
                        },
                        query: {
                            securityId: fileSecurity,
                        },
                        showSuccessNotification: false,
                        onSuccess: resolve,
                        onError: reject,
                    })
                );
            } else {
                resolve();
            }
        });

        const fileAuthorizationGroupUpdatePromise = new Promise((resolve, reject) => {
            if (fileAuthorizationGroup !== props.fileAuthorizationGroup) {
                dispatch(
                    updateResource({
                        resourceName: "fileAuthorizationGroup",
                        path: {
                            fileId,
                        },
                        query: {
                            groupNumber: fileAuthorizationGroup,
                        },
                        showSuccessNotification: false,
                        onSuccess: resolve,
                        onError: reject,
                    })
                );
            } else {
                resolve();
            }
        });

        Promise.all([tagUpdatePromise, folderUpdatePromise, fileSecurityUpdatePromise, fileAuthorizationGroupUpdatePromise])
            .then((results) => {
                toast.success("File updated sucessfully");
                onRefresh();
                onClose();
            })
            .catch((reason) => {
                setIsSubmitting(false);
            });
    }, [
        tags,
        props.tag,
        props.folder,
        props.fileSecurity,
        props.fileAuthorizationGroup,
        dispatch,
        fileId,
        folderName,
        fileSecurity,
        fileAuthorizationGroup,
        onRefresh,
        onClose,
    ]);

    const getFileAuthorizationGroup = () => {
        if (fileAuthorizationList.find((f) => f.value === fileAuthorizationGroup)) {
            return fileAuthorizationGroup;
        } else {
            return fileAuthorizationGroupName;
        }
    };

    if (isLoadingFolders) {
        return <WaitIcon />;
    }

    if (isApplicationGrid && !fileTags) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={"Edit File"} leadBlockIcon={"edit-empty"} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <div className="form-item flex-column ">
                    <DropDownInput
                        label="Folder"
                        disabled={isSubmitting}
                        placeholder="Select folder"
                        mobileHeader="Select folder"
                        data={folderList}
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                    />
                    <Separator />
                    {isApplicationGrid ? (
                        <DropDownInput
                            label="Tags"
                            value={tags}
                            extraInput={!fileTags?.forceFileTags}
                            data={tagList}
                            onChange={(e) => setTags(e.target.value)}
                            disabled={isSubmitting}
                        />
                    ) : (
                        <TextInput value={tags} label="Tags" onChange={(e) => setTags(e.target.value)} disabled={isSubmitting} />
                    )}
                    <Separator />
                    <DropDownInput
                        label="File Access"
                        disabled={isSubmitting}
                        placeholder="Select file access"
                        mobileHeader="Select file access"
                        data={fileSecurityValues}
                        value={fileSecurity}
                        onChange={(e) => setFileSecurity(e.target.value)}
                    />
                    <Separator />
                    <DropDownInput
                        label="File Security"
                        disabled={isSubmitting}
                        placeholder="Select file security"
                        mobileHeader="Select file security"
                        data={fileAuthorizationList}
                        value={getFileAuthorizationGroup()}
                        onChange={(e) => setFileAuthorizationGroup(e.target.value)}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={onSubmit} disabled={isSubmitting}>
                    {buttonText}
                </Button>
                <Button title="Cancel" onClick={onClose}>
                    Cancel
                </Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default FilesGrid;
