import React, { useState, useCallback, useMemo, memo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { windowContainerTypes, openUserProfile } from "../../utils/window";
import useFocusClasses from "../../utils/useFocusClasses";
import IconWrap from "../Icons";
import WaitIcon from "../WaitIcon";
import IconWithDropdown from "../Icons/IconWithDropdown";
import GlobalSearch from "../GlobalSearch";
import Settings from "./Settings";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
import ScrollControls from "../ScrollControls";

import "./style.scss";

const NavBarView = memo(({ items = [], logOutPending, logout, onClick }) => {
    const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
    const activeViewName = useSelector((state) => (state.window[windowContainerTypes.Home]?.views || []).find((i) => i.active)?.name);
    const isProfileActive = activeViewName === "UserProfile";

    const canSearch = hasAnyOfPermissions([systemUserRights.VISIONDSM_GENERAL_SEARCH]);
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses();

    const onUserProfileClick = () => {
        setIsActiveMobileMenu(false);
        openUserProfile();
    };

    const onMenuClick = useCallback(
        (item) => {
            setIsActiveMobileMenu(false);
            onClick(item);
        },
        [onClick]
    );

    const onHomeClick = (event) => {
        event.preventDefault();
        onMenuClick(items[0]);
    };

    const onKeyDown = useCallback(
        (item) => (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                onMenuClick(item);
            }
        },
        [onMenuClick]
    );

    const pageTitle = useMemo(() => {
        if (activeViewName === "UserProfile") {
            return "User Profile";
        } else {
            return items.find((i) => i.view.name === activeViewName)?.text;
        }
    }, [activeViewName, items]);

    const headerLogo = (
        <a className="header-logo" href="/" onClick={onHomeClick}>
            &nbsp;
        </a>
    );

    return (
        <div>
            <div className={"nav-bar-placeholder" + (isActiveMobileMenu ? " mobile-menu-opened" : "")}>{headerLogo}</div>
            <div className={"nav-bar" + (isActiveMobileMenu ? " mobile-menu-opened" : "")}>
                <div className="main-grid-wrap responsive">
                    <div className="mobile-header-wrap">
                        {headerLogo}
                        <div className="current-page-name visible">{pageTitle}</div>
                        <IconWrap
                            className="mobile-menu-icon"
                            iconWrapClickable
                            iconWrapBig
                            iconWrapTransparent
                            iconWrapActive={isActiveMobileMenu}
                            iconWrap={isActiveMobileMenu ? "clear-close" : "menu-queue-list-playlist"}
                            onClick={() => setIsActiveMobileMenu(!isActiveMobileMenu)}
                        ></IconWrap>
                    </div>
                    <div className="header-menu-wrap">
                        <ul id="main-navigation-list" className="main-nav">
                            {items.map((item, index) => (
                                <li
                                    key={index}
                                    className={cn("main-nav-item", {
                                        active: item.view.name === activeViewName,
                                        "text-uppercase": !["CRM", "TRMulator"].includes(item.view.name),
                                    })}
                                    onClick={() => onMenuClick(item)}
                                    tabIndex="0"
                                    onFocus={onFocusClassesFocus}
                                    onBlur={onFocusClassesBlur}
                                    onKeyDown={onKeyDown(item)}
                                    role="button"
                                >
                                    <span>{item.text}</span>
                                </li>
                            ))}
                        </ul>
                        {canSearch && (
                            <div className="header-search-block">
                                <GlobalSearch iconBackground="white" iconSize="medium" />
                            </div>
                        )}
                        {!logOutPending && <Settings />}
                        <div className="header-profile-block">
                            {logOutPending ? (
                                <WaitIcon />
                            ) : (
                                <IconWithDropdown
                                    dropdownRight
                                    iconWrapWhite
                                    title="Go to Profile or Logout"
                                    icon="user-identity-person-empty"
                                    iconActive="user-identity-person-filled"
                                    items={[
                                        {
                                            icon: "user",
                                            selected: isProfileActive,
                                            text: "Profile",
                                            onClick: onUserProfileClick,
                                        },
                                        {
                                            icon: "exit",
                                            text: "Log out",
                                            onClick: logout,
                                        },
                                    ]}
                                />
                            )}
                        </div>
                        <ScrollControls targetId={"main-navigation-list"} horizontalScroll />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default NavBarView;
