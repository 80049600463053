import React from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";
import cn from "classnames";

import "./style.scss";

const SelectBox = (props) => {
    const className = ClassNames("select-box flex-row align-center justify-center", props.className, props.selectBoxExpandable, {
        disabled: props.disabled,
        selected: props.selectBoxSelected,
        expandable: props.selectBoxExpandable,
        "without-icon": !props.icon,
        blocked: props.blocked,
    });

    return (
        <div className={className} onClick={props.onClick} title={props.selectBoxTitle}>
            {props.children}
            {!props.selectBoxExpandable && props.icon && <IconWrap iconWrap={props.icon}></IconWrap>}
            {props.selectBoxText && <span className={cn("select-box__text", { "without-icon": !props.icon })}>{props.selectBoxText}</span>}
            {props.selectBoxExpandable && <IconWrap iconWrap={props.iconArrow} />}
        </div>
    );
};

export default SelectBox;
