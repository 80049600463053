import React, { useRef, useMemo, memo } from "react";
import cn from "classnames";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import IconWrap from "../Icons";
import DropdownList from "../DropdownList";
import DropdownListItem from "../DropdownList/DropdownListItem";
import useOnClickOutside from "../../utils/useOnClickOutside";
import IconWithLabel from "../Icons/IconWithLabel";
import Portal from "../Portal";
import usePopper from "../../utils/usePopper";
import { getDropdownZIndex, getDropdownContainer } from "../../utils/dom";

import "./style.scss";

const FieldGroupDropdownIcon = memo((props) => {
    let triggerRef = useRef();
    const popupRef = useRef();
    const popperRef = useRef();

    if (props.elementRef) {
        triggerRef = props.elementRef;
    }

    const { visible, isLocked = false, popupPosition = "left-start", withPopper, popupBoundary, popupModifiers = [], zIndexOffset } = props;

    useOnClickOutside(popupRef, (event) => {
        if (visible && !isLocked && event.target !== triggerRef.current) {
            props.onClick(event);
            popperRef.current && popperRef.current.forceUpdate();
        }
    });

    const modifiers = useMemo(
        () => [
            {
                name: "preventOverflow",
                options: {
                    boundary: popupBoundary ?? triggerRef.current?.closest(".popup-boundary") ?? undefined,
                },
            },
            {
                name: "eventListeners",
                options: {
                    scroll: visible,
                    resize: visible,
                },
            },
            ...popupModifiers,
        ],
        [popupBoundary, popupModifiers, visible]
    );

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: popupPosition,
        enabled: withPopper,
        modifiers,
    });

    const onPopupClick = (event) => {
        props.onClick && props.onClick(event);
        popperRef.current && popperRef.current.forceUpdate();
    };

    return (
        <FieldGroup className={props.className} inline>
            <FieldWrap iconDropdown>
                {props.label ? (
                    <IconWithLabel
                        elementRef={triggerRef}
                        title={props.title}
                        active={props.iconWrapActive}
                        disabled={props.disabled}
                        icon={props.iconWrap}
                        iconWithLabelRight={props.iconWithLabelRight}
                        onClick={onPopupClick}
                    >
                        {props.label}
                    </IconWithLabel>
                ) : (
                    <IconWrap
                        elementRef={triggerRef}
                        title={props.title}
                        iconWrapClickable
                        iconWrapBig={props.iconWrapBig}
                        iconWrapWhite={props.iconWrapWhite}
                        iconWrapWhiteInverse={props.iconWrapWhiteInverse}
                        iconWrapRoundedSquare={props.iconWrapRoundedSquare}
                        iconWrapDropdown={props.iconWrapDropdown}
                        iconWrapActive={props.iconWrapActive}
                        iconWrapTransparent={props.iconWrapTransparent}
                        iconWrap={props.iconWrap}
                        onClick={onPopupClick}
                        disabled={props.disabled}
                    />
                )}
                <ListWrapper
                    className={props.className}
                    triggerRef={triggerRef}
                    popupRef={popupRef}
                    zIndexOffset={zIndexOffset}
                    enabled={props.withPopper}
                >
                    <DropdownList
                        elementRef={props.withPopper ? undefined : popupRef}
                        mobileHeader={props.mobileHeader}
                        footer={props.footer}
                        draggable={props.draggable}
                        dropdownFixedWidth
                        withTitle={props.withTitle}
                        alone
                        dropdownOptions={props.dropdownOptions}
                        dropdownRight={props.dropdownRight}
                        visible={props.visible}
                        onKeyDown={props.onKeyDown}
                        onClose={onPopupClick}
                    >
                        {props.items.length > 0 &&
                            props.items.map((item, i) => (
                                <DropdownListItem
                                    key={item.draggableId || i}
                                    draggable={props.draggable}
                                    draggableId={item.draggableId}
                                    draggableIndex={item.draggableIndex}
                                    dragDisabled={item.dragDisabled}
                                    checked={item.checked}
                                    selected={item.selected}
                                    disabled={item.disabled}
                                    icon={item.icon}
                                    title={item.title}
                                    onClick={item.disabled ? undefined : () => props.onSelect && props.onSelect(item)}
                                    renderItem={props.renderItem ? (dragProps) => props.renderItem(item, dragProps) : undefined}
                                >
                                    <span>{item.label}</span>
                                </DropdownListItem>
                            ))}
                        {props.children}
                    </DropdownList>
                </ListWrapper>
            </FieldWrap>
        </FieldGroup>
    );
});

const ListWrapper = ({ className, triggerRef, popupRef, zIndexOffset, enabled, children }) => {
    if (!enabled) {
        return children;
    }

    return (
        <Portal container={getDropdownContainer({ element: triggerRef.current })}>
            <div
                className={cn("field-group-dropdown-icon-popup__container", className)}
                ref={popupRef}
                style={{
                    zIndex: getDropdownZIndex({
                        element: triggerRef.current,
                        zIndexOffset,
                    }),
                }}
            >
                {children}
            </div>
        </Portal>
    );
};

export default FieldGroupDropdownIcon;
