import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isEmpty, isString } from "lodash";

import { asNumber } from "react-jsonschema-form/lib/utils";
import DropDownInput from "../../../Input/DropDownInput";
import DropDownMultiSelect from "../../../Input/DropDownMultiSelect";
import { toArray } from "../../../../utils/array";

const nums = new Set(["number", "integer"]);

function processValue({ type, items }, value) {
    if (value === "") {
        return undefined;
    } else if (type === "array" && items && nums.has(items.type)) {
        return value.map(asNumber);
    } else if (type === "boolean") {
        return value === "true";
    } else if (type === "number") {
        return asNumber(value);
    }
    return value;
}

function getValue(event, multiple) {
    if (multiple) {
        return event.target.value || [];
    } else {
        return event.target.value === undefined ? undefined : event.target.value;
    }
}

function SelectWidget(props) {
    const {
        formContext,
        schema,
        id,
        options,
        value,
        required,
        disabled,
        readonly,
        multiple,
        autofocus,
        onChange,
        onBlur,
        onFocus,
        placeholder,
        label,
    } = props;
    const { enumOptions, enumDisabled, enumItems, separator } = options;
    const emptyValue = multiple ? [] : null;
    const defaultValInitialized = useRef(false);

    const handleChange = (event) => {
        const newValue = getValue(event, multiple);
        onChange(processValue(schema, newValue));
    };

    const handleFocus = (event) => {
        const newValue = getValue(event, multiple);
        onFocus(id, processValue(schema, newValue));
    };

    const handleBlur = (event) => {
        const newValue = getValue(event, multiple);
        onBlur(id, processValue(schema, newValue));
    };

    // Enable default empty item only for not required fields
    const defaultEmptyItemEnabled = (formContext?.emptyItemInSelectLists ?? false) && !required;

    // Whether to select the first drop down item as default
    const defaultValueOnInitialize = formContext?.defaultValueOnInitialize ?? false;

    const withPopper = options.withPopper ?? formContext?.selectListsWithPopper ?? false;

    // Take empty item prop by priority
    const emptyItemProp = options?.separator ?? options?.emptyItem;

    // Check if need to use empty item
    const emptyItem = isString(emptyItemProp) || emptyItemProp === true || defaultEmptyItemEnabled;

    // Set empty item label if empty item is used
    const emptyItemLabel = emptyItem ? (isString(emptyItemProp) ? emptyItemProp : "-- SELECT --") : undefined;

    // Show placeholder or configured empty item label when nothing selected
    const placeholderValue = placeholder || (options && options.placeholder) || emptyItemLabel;

    const allItems = Array.isArray(enumOptions) ? enumOptions : Array.isArray(enumItems) ? enumItems : [];
    const disabledItems = Array.isArray(enumDisabled) ? enumDisabled : [];

    const dropdownItems = allItems.map((item) => ({
        ...item,
        disabled: disabledItems.includes(item.value),
    }));

    useEffect(() => {
        if (defaultValueOnInitialize && !defaultValInitialized.current && !multiple) {
            const firstItem = enumOptions[0];
            if (!value && firstItem && !separator && !readonly) {
                defaultValInitialized.current = true;
                setTimeout(() => onChange(processValue(schema, firstItem.value)));
            }
        }
    }, [
        defaultValInitialized,
        defaultValueOnInitialize,
        emptyItem,
        enumOptions,
        multiple,
        onChange,
        readonly,
        required,
        schema,
        separator,
        value,
    ]);

    const isError = !isEmpty(props.rawErrors);

    const component = multiple ? (
        <DropDownMultiSelect
            data={dropdownItems}
            placeholder={placeholderValue}
            mobileHeader={label}
            value={typeof value === "undefined" ? emptyValue : toArray(value)}
            required={required}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autofocus}
            onBlur={onBlur && handleBlur}
            onFocus={onFocus && handleFocus}
            onChange={handleChange}
            msgError={isError}
            withPopper={withPopper}
            zIndexOffset={options.zIndexOffset}
            popperPadding={options.popperPadding}
        />
    ) : (
        <DropDownInput
            data={dropdownItems}
            emptyItem={emptyItem}
            emptyItemLabel={emptyItemLabel}
            placeholder={placeholderValue}
            mobileHeader={label}
            value={typeof value === "undefined" ? emptyValue : value}
            required={required}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autofocus}
            onBlur={onBlur && handleBlur}
            onFocus={onFocus && handleFocus}
            onChange={handleChange}
            msgError={isError}
            extraInput={props.options.extraInput}
            withPopper={withPopper}
            zIndexOffset={options.zIndexOffset}
            popperPadding={options.popperPadding}
        />
    );

    return component;
}

SelectWidget.defaultProps = {
    autofocus: false,
};

if (process.env.NODE_ENV !== "production") {
    SelectWidget.propTypes = {
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        options: PropTypes.shape({
            enumOptions: PropTypes.array,
        }).isRequired,
        value: PropTypes.any,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        multiple: PropTypes.bool,
        autofocus: PropTypes.bool,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
    };
}

export default SelectWidget;
