import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const StickyBottomPanel = (props) => {
    const className = ClassNames("sticky-bottom-panel", props.className, {
        visible: props.visible,
        "app-view": props.appView,
    });

    return (
        <div className={className}>
            <div className="sticky-bottom-panel__buttons-container flex-row align-center justify-center fill-height">{props.children}</div>
        </div>
    );
};

export default StickyBottomPanel;
