import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useResource } from "../../../../../../store/resources/useResource";
import WaitIcon from "../../../../WaitIcon";
import { anyOfTrueFalse, anyOfNoYes, referenceToAnyOf, pickInitialValues, submitResource, submitByRef } from "../../../../../utils/form";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const Form = (props) => {
    const formRef = useRef();

    const { utilityNumber, contractNumber, dataItem, onClose, gridRefresh, sidePanel } = props;

    const isNew = dataItem === undefined;
    const title = isNew ? "Add Attribute" : "Edit Attribute";
    const titleIcon = isNew ? "plus" : "edit-empty";

    const resourceId = isNew ? null : dataItem.attributeNumber;

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useResource({
        resourceName: "utilitiesContractsAttributes",
        resourceId,
        forced: true,
        path: {
            utilityNumber,
            contractNumber,
        },
    });

    const [attributeTypes, attributeTypesIsLoading] = useResource({
        resourceName: "utilitiesContractsAttributeTypes",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return (data && data.contractAttributeTypeList) || [];
        },
    });

    const [validationTypes = []] = useReference(referenceTypes.equipmentValidation);
    const anyOfValidationTypes = referenceToAnyOf({ list: validationTypes });
    const anyOfAttributeTypes = attributeTypes.map((item) => ({
        title: item.attributeDescription,
        enum: [item.attributeTypeId],
    }));

    const schema = {
        type: "object",
        required: ["attributeTypeId", "attributeName", "itemOrder", "validationTypeId", "validationRequiredId", "showAll", "editAll"],
        properties: {
            attributeTypeId: {
                type: "integer",
                title: "Attribute Type",
                anyOf: anyOfAttributeTypes,
            },
            attributeName: {
                type: "string",
                title: "Attribute Name",
                maxLength: 20,
            },
            attributeValue: {
                type: "string",
                title: "Attribute Value",
            },
            itemOrder: {
                type: "integer",
                title: "Sort Order",
            },
            validationTypeId: {
                type: "integer",
                title: "Validation Type",
                anyOf: anyOfValidationTypes,
            },
            validationOther: {
                type: "string",
                title: "Validation Other",
            },
            validationRequiredId: {
                type: "integer",
                title: "Validation Required",
                anyOf: anyOfTrueFalse,
            },
            showAll: {
                type: "string",
                title: "Show All",
                anyOf: anyOfNoYes,
            },
            editAll: {
                type: "string",
                title: "Edit All",
                anyOf: anyOfNoYes,
            },
            lowLimit: {
                type: "number",
                title: "Low Limit",
            },
            highLimit: {
                type: "string",
                title: "High Limit",
            },
        },
    };

    const uiSchema = {};

    const initialValues = isNew ? {} : pickInitialValues(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "utilitiesContractsAttributes",
            path: {
                utilityNumber,
                contractNumber,
            },
        };

        const body = {
            ...formData,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading || attributeTypesIsLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default connect()(Form);
