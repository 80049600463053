import React, { memo } from "react";
import cn from "classnames";

import ButtonGroup from "../Button/ButtonGroup";
import TagButton from "../Button/TagButton";
import IconWrap from "../Icons";
import ActionLabel from "../Label/ActionLabel";

import "./style.scss";

const AssociationsList = memo(
    ({ className, headerText, placeholder, onRemove, onItemClick, title, onEdit, list, displayProperty, readOnly, disabled }) => {
        const isEmpty = list.length === 0;

        return (
            <div className={cn("associations-list fill-width", className)}>
                <div className="associations-list__header flex-row align-center">
                    <div className="associations-list__header__text">{headerText}</div>
                    {!readOnly && (
                        <IconWrap
                            disabled={disabled}
                            className="associations-list__header__action"
                            title={!isEmpty ? "Edit" : "Add"}
                            icon={!isEmpty ? "edit-empty" : "plus"}
                            onClick={onEdit}
                        />
                    )}
                </div>
                {isEmpty && placeholder && (
                    <ActionLabel className="associations-list__placeholder" onClick={onEdit}>
                        {placeholder}
                    </ActionLabel>
                )}
                {!isEmpty && (
                    <div
                        className={cn("associations-list__list", {
                            "associations-list__list--empty": isEmpty,
                        })}
                    >
                        <ButtonGroup transparent>
                            {list.map((item, index) => (
                                <TagButton
                                    disabled={disabled}
                                    key={index}
                                    title={title ?? "Remove Assignment"}
                                    multiline
                                    edit={!readOnly}
                                    readonly={readOnly}
                                    onClick={() => onRemove(item, index)}
                                    onFullItemClick={onItemClick ? () => onItemClick(item, index) : undefined}
                                >
                                    {item[displayProperty]}
                                </TagButton>
                            ))}
                        </ButtonGroup>
                    </div>
                )}
            </div>
        );
    }
);

export default AssociationsList;
