import { useState, useEffect } from "react";

/**
 * Container for components to mount with delay
 * @param key - component id to mount, if it changes will try to remount component
 * @param delay - mount delay, used to calculate order of mounting
 * @param manager - instance of DelayedMountManager
 * @param children - components to mount, passed trough JSX
 */
const DelayedMountItem = ({ delay, manager, children }) => {
    const [loaded, setLoaded] = useState(false);

    // Mount children after delay provided by manager
    useEffect(() => {
        // Request callback
        const mountCallback = () => {
            // Mount
            setLoaded(true);
        };

        // Request callback if manager is provided
        if (manager) manager.requestCallback(mountCallback, delay);
        else mountCallback();

        // Clear timeouts on unmount
        return () => {
            setLoaded(false);
            manager && manager.removeCallback(mountCallback);
        };
    }, [delay, manager]);

    return loaded ? children : null;
};

export default DelayedMountItem;
