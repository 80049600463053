import ViewPlaceholder from "components/ui/ViewPlaceholder";
import React from "react";

export const GlobalInputFieldProperties = () => {
    return (
        <div className="global-input-field-properties flex-one-in-column flex-column fill-height no-scroll">
            <ViewPlaceholder>Input field properties</ViewPlaceholder>
        </div>
    );
};
