import React, { useState, memo } from "react";
import getPath from "lodash/get";

import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";

import IconWrap from "../../../../Icons";
import Breadcrumb from "../../../../Breadcrumb";

import EventsList from "./EventsList";
import EventDetails from "./EventDetails";

import "./EventsPanelSmall.scss";

const EventsPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand, onToggleSidebar }) => {
    const [selectedEvent, setSelectedEvent] = useState(null);

    const applicationNumber = getPath(panel, "data.applicationNumber");

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const getBreadcrumb = () => {
        if (!selectedEvent) {
            return [];
        }

        let items = [
            {
                name: <IconWrap icon="home-empty" title="Sidebar Home" onClick={() => setSelectedEvent(null)} />,
                onClick: null,
            },
        ];

        if (selectedEvent) {
            items = items.concat([
                {
                    name: selectedEvent.category,
                    onClick: () => setSelectedEvent(null),
                },
            ]);
        }

        return items;
    };

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            className="sidebar-events"
        >
            <div className="sidebar-events-wrap">
                {selectedEvent && <Breadcrumb items={getBreadcrumb()} />}
                {selectedEvent ? (
                    <EventDetails resourceName="event" resourceId={selectedEvent.eventnumber} renderFooter />
                ) : (
                    <EventsList
                        entity="events"
                        resourceName="applicationEventsWidget"
                        applicationNumber={applicationNumber}
                        onSelectEvent={(eventItem) => setSelectedEvent(eventItem)}
                        renderFooter
                    />
                )}
            </div>
        </SidebarPanel>
    );
});

export default EventsPanelSmall;
