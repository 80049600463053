import React from "react";
import SelectWidget from "./SelectWidget";
import { useResource } from "store/resources/useResource";

const StatesWidget = (props) => {
    const [statesList = [], isLoadingStates] = useResource({
        resourceName: "states",
        key: "states",
    });

    const options = {
        ...(props.options || {}),
        enumOptions: statesList.map((item) => ({
            label: item.state,
            value: item.abbreviation,
        })),
    };

    const placeholderValue = isLoadingStates ? "Loading..." : props.placeholder || options.placeholder || "-- SELECT --";

    return <SelectWidget {...props} options={options} placeholder={placeholderValue} />;
};

export default StatesWidget;
