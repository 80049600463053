import React, { memo, useCallback } from "react";

import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { availableGrids } from "../../../../../views/configureGrids";
import { getContractAttributesResourceParams } from "store/configureResources";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import WaitIcon from "../../../../WaitIcon";
import { useDataGrid } from "components/utils/useDataGrid";

const contractAttributesGridColumnKeys = {
    attributeNumber: "c_8b1aa465-793b-45f2-aeb3-bf6d6f3067c2",
    attributeName: "c_b74bb842-2ecd-4628-a8ba-32102f092f62",
    attributeType: "c_fb7df06f-e3f7-4c46-beac-e19713740110",
    attributeValue: "c_49c55b62-fc31-4014-9d08-6405975c163b",
    itemOrder: "c_db9b8e7b-8627-4610-9f0d-177f853ae3cf",
    validationTypeName: "c_076184ac-9672-4baa-9380-7d6625834380",
    validationOther: "c_23a2369f-906f-4304-a945-d09a70c75dd7",
    validationRequiredName: "c_2b5da50e-ef07-4ed3-b322-a95758bf2dcc",
    showAll: "c_dedfc9cb-5306-497f-a038-fedc76ccaad6",
    editAll: "c_0557de14-94d7-449d-bd68-a96f2656e855",
    lowLimit: "c_f3bb0b6d-a4d3-4d9f-934e-a45ef767e273",
    highLimit: "c_5c14faaf-bf31-4d8c-96bd-eede7ae57b33",
};

const rowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit Contract Attribute",
        footerExclude: true,
    },
];

const Grid = memo(({ dataGridId, utilityNumber, contractNumber, onRefresh }) => {
    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.contractAttributes,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: contractAttributesGridColumnKeys,
                resourceParams: getContractAttributesResourceParams({
                    utilityNumber,
                    contractNumber,
                }),
            },
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-attributes-sidenav-panel",
    });

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            const data = mapGridRowToObject(contractAttributesGridColumnKeys, dataItem);

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <Form
                            dataItem={data}
                            utilityNumber={utilityNumber}
                            contractNumber={contractNumber}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;
                default:
                    break;
            }
        },
        [utilityNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default Grid;
