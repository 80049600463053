import { useResource } from "store/resources/useResource";
import { ClientRole } from "./types";

export const useClientRoles = (clientNumber: string) => {
    return useResource({
        resourceName: "clientRoles",
        resourceId: undefined,
        key: clientNumber,
        path: {
            clientNumber,
        },
        forced: true,
        onError: undefined,
        showSuccessNotification: false,
        showErrorNotification: true,
        transform: (data: ClientRolesResponse) => {
            return (data && data.clientRoles) || [];
        },
    }) as [response: ClientRole[], isLoading: boolean];
};

interface ClientRolesResponse {
    clientRoles: ClientRole[];
}
