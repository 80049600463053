import Label from "components/ui/Label";
import { isChildProgram } from "components/views/ProgramView/utils";
import React, { useCallback, memo } from "react";

import { programsFormsPagesGridColumnKeys } from "../../../../../views/configureGrids";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ActionLabel from "../../../../Label/ActionLabel";

const Grid = memo(({ gridId, dataGridConfig, onPageOpen, programNumber }) => {
    const isLocked = isChildProgram({ programNumber });

    const rowActions = [
        {
            name: "open-page",
            icon: "open-new-window",
            title: isLocked ? undefined : "Manage Form Page",
            disabled: isLocked,
        },
    ];

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            const pageNumber = dataItem[programsFormsPagesGridColumnKeys.pageNumber];

            switch (name) {
                case "open-page":
                    onPageOpen(pageNumber);
                    break;

                default:
                    break;
            }
        },
        [onPageOpen]
    );

    return (
        <div className="forms-pages-tab flex-one-in-column no-scroll">
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [programsFormsPagesGridColumnKeys.pageName]: (column, row, onExpandRow, onRowAction) => (
                        <>
                            {isLocked ? (
                                <Label>{row[column.key]}</Label>
                            ) : (
                                <ActionLabel
                                    onClick={() =>
                                        onRowAction({
                                            name: "open-page",
                                            dataItem: row,
                                        })
                                    }
                                    title="Manage Form Page"
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            )}
                        </>
                    ),
                }}
            />
        </div>
    );
});

export default withDataGrid("programsFormsPages", Grid);
