import React, { memo, useRef, useState, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { createId, replaceHttpLinksToHttps } from "components/utils/string";
import { resizeHeightToFitContent, getFrameDocument, addFontStyle } from "components/utils/iframe";
import RawHtml from "../RawHtml";
import FileView from "../../views/FileView";
import { createResourcePromise } from "store/resources/useResource";
import NothingFoundBlock from "../NothingFoundBlock";

const HtmlContentPreview = memo(({ className, children, iframeRef, applyDefaultFont = false }) => {
    const titleRef = useRef(createId());

    const [ref, setRef] = useState(null);
    const frameBody = getFrameDocument(ref)?.body;

    const content = <RawHtml>{children}</RawHtml>;

    const onRefSet = useCallback(
        (r) => {
            if (iframeRef) {
                iframeRef.current = r;
            }

            setRef(r);

            if (applyDefaultFont) {
                addFontStyle(r);
            }

            resizeHeightToFitContent(r);
        },
        [iframeRef, applyDefaultFont]
    );

    return (
        <iframe ref={onRefSet} className={className} src="about:blank" width="100%" height="100%" frameBorder="0" title={titleRef.current}>
            {frameBody && createPortal(React.Children.only(content), frameBody)}
        </iframe>
    );
});

export const HtmlContentAsPdf = memo(({ fileContent, fileName }) => {
    const pdfGenerated = useRef(false);

    const [state, setState] = useState({
        fileData: null,
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        const generatePdf = async () => {
            try {
                const fileData = await createResourcePromise({
                    resourceName: "generatePdf",
                    key: createId(),
                    body: {
                        fileName,
                        content: replaceHttpLinksToHttps(fileContent),
                    },
                });

                setState({
                    fileData,
                    isLoading: false,
                });
            } catch (error) {
                setState({
                    isLoading: false,
                    error: error.message,
                });
            }
        };

        if (!pdfGenerated.current) {
            pdfGenerated.current = true;
            generatePdf();
        }
    }, [state.pdfBlob, fileContent, fileName]);

    if (state.error) {
        return (
            <NothingFoundBlock icon="files" title="Failed to load data">
                {state.error}
            </NothingFoundBlock>
        );
    }

    return <FileView fileData={state.fileData} isLoading={state.isLoading} />;
});

export default HtmlContentPreview;
