import React, { memo } from "react";

import { programsContentRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import ContentRevisionsGrid from "./ContentRevisionsGrid";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const ContentRevisionsPanel = memo((props) => {
    const { programNumber, formNumber, contentTitle, contentNumber, onClose, contentGridId } = props;
    const gridId = `${contentNumber}-revisions`;

    return (
        <SideNavContent>
            <SideNavHeader title="Revisions of " leadBlockIcon="assignment" nameInBold={contentTitle} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "program_content_revisions",
                                fileNamePostfix: programNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <div className="panel sticky-grid-list-panel no-scroll fill-height">
                    <ContentRevisionsGrid
                        contentTitle={contentTitle}
                        programNumber={programNumber}
                        formNumber={formNumber}
                        contentNumber={contentNumber}
                        gridId={gridId}
                        contentGridId={contentGridId}
                        filter={`${programsContentRevisionsGridColumnKeys.contentNumber}=${contentNumber}`}
                        sort={[
                            {
                                field: programsContentRevisionsGridColumnKeys.date,
                                dir: "desc",
                            },
                        ]}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default ContentRevisionsPanel;
