import React, { useCallback, memo, useRef } from "react";
import { useState } from "react";
import Button from "components/ui/Button";
import IconWrap from "components/ui/Icons";
import StatusMsg from "components/ui/StatusMsg";
import Separator from "components/ui/Separator";
import Label from "components/ui/Label";
import "./FilesPage.scss";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import FileView from "components/views/FileView";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import IconHandMade from "components/ui/Icons/IconHandMade";
import { getFileExtension } from "components/utils/files";
import ClassNames from "classnames";
import { useDispatch } from "react-redux";
import { uploadFile } from "store/files/actions";
import { useResource } from "store/resources/useResource";
import { refreshPortalBuilderFiles } from "store/resources/refreshResource";
import WaitIcon from "components/ui/WaitIcon";
import { entityType } from "components/utils/entityType";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import { SectionInfo } from "../SectionInfo";
import { copyToClipboard } from "components/utils/string";
import { toast } from "react-toastify";
import CloseCircle from "components/ui/Icons/CloseCircle";
import { openConfirmModal } from "components/ui/Modal/utils";
import { deleteResource } from "store/resources/actions";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

const FilesPage = memo(() => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const [isUploading, setIsUploading] = useState(false);
    const [isFilesPresent, setIsFilesPresent] = useState(false);
    const [isDarkFileListBackground, setPortalBuilderState] = usePortalBuilderState((state) => state.isDarkFileListBackground);
    const [utilityNumber] = usePortalBuilderState((state) => state.utilityNumber);

    const [files, loading] = useResource({
        resourceName: "fileList",
        key: utilityNumber,
        query: { entityTypeId: entityType.portalTemplate, entityId: utilityNumber },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "files-grid-file-preview-sidenav-panel",
    });

    const handleOpen = useCallback(
        (file) => {
            const title = file.fileName;
            const leadBlockIcon = "eye-visibility-empty";

            handleOpenSidePanel(
                <SideNavContent>
                    <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={handleCloseSidePanel} />
                    <SideNavBody className="flex-one-in-column">
                        <FileView publicPath={file.publicPath} />
                    </SideNavBody>
                    <SideNavFooter justifyEnd>
                        <Button onClick={handleCloseSidePanel}>Close</Button>
                    </SideNavFooter>
                </SideNavContent>
            );
        },
        [handleCloseSidePanel, handleOpenSidePanel]
    );

    const tilesContainerClasses = ClassNames("justify-start file-tiles-container flex-row flex-wrap align-content-start", {
        "dark-background": isDarkFileListBackground,
    });

    const onSuccess = useCallback(() => {
        refreshPortalBuilderFiles({
            utilityNumber: utilityNumber,
            onSuccess: () => {
                formRef.current.setFormData({ file: [] });
                setIsUploading(false);
            },
        });
    }, [utilityNumber]);

    const onChange = useCallback((form) => {
        setIsFilesPresent((form.formData.file ?? []).length > 0);
    }, []);

    const onSubmit = useCallback(
        (file) => {
            if (file) {
                setIsUploading(true);
                dispatch(
                    uploadFile({
                        fileData: {
                            file,
                            entityTypeId: entityType.portalTemplate,
                            entityId: utilityNumber,
                            fileTypeId: 27,
                            itemFilterId: 149,
                        },
                        onUploadSuccess: onSuccess,
                        onUploadError: () => {
                            setIsUploading(false);
                        },
                    })
                );
            }
        },
        [dispatch, onSuccess, utilityNumber]
    );

    const onDelete = (file) => {
        openConfirmModal({
            title: "Remove File",
            modalIcon: "cancel-clear-circle-highlight-off-filled",
            message: (
                <>
                    Are you sure you want to remove the file <strong>{file.fileName}</strong>?
                </>
            ),
            onConfirm: () => {
                dispatch(
                    deleteResource({
                        resourceName: "utilityPortalTemplateFiles",
                        resourceId: file.fileName,
                        path: {
                            utilityNumber,
                        },
                        onComplete: () => {
                            refreshPortalBuilderFiles({
                                utilityNumber,
                            });
                        },
                    })
                );
            },
        });
    };

    return (
        <div className="portal-builder-file-panel flex-column">
            <SectionInfo>
                <StatusMsg
                    msgIcon="info-empty"
                    msgText="If you already know which files will be needed for the portal, upload here to use them later. If not sure, you will have the option to do it in specific places later."
                ></StatusMsg>
                <StatusMsg
                    msgText={
                        <>
                            <strong> Important: </strong> For images consider using svg format to not lose image quality when scaling.
                        </>
                    }
                ></StatusMsg>
            </SectionInfo>
            {files?.fileList.length === 0 && (
                <FileUploadForm
                    isFilesPresent={isFilesPresent}
                    isUploading={isUploading}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    formRef={formRef}
                />
            )}
            {files?.fileList.length > 0 && (
                <div className="flex-row justify-space-between align-center background-switcher">
                    <Label contentLabel>Preview on dark background</Label>
                    <IconWrap
                        onClick={() => setPortalBuilderState({ isDarkFileListBackground: !isDarkFileListBackground })}
                        iconWrapBig
                        title={isDarkFileListBackground ? "Switch OFF" : "Switch ON"}
                        icon={isDarkFileListBackground ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                    ></IconWrap>
                </div>
            )}
            {loading ? (
                <div className="flex-row justify-center">
                    <WaitIcon withSpaceAround />
                </div>
            ) : (
                <>
                    <div className={tilesContainerClasses}>
                        {files?.fileList.map((f) => {
                            return (
                                <div key={f.publicPath} className="file-tile flex-column">
                                    <CloseCircle
                                        title="Remove"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(f);
                                        }}
                                    />
                                    <FilePreview file={f} />
                                    <IconWrap
                                        className="view-button"
                                        iconWrapRoundedSquare
                                        iconWrapWhite
                                        icon="eye-visibility-empty"
                                        title="View File"
                                        onClick={() => handleOpen(f)}
                                    />
                                    <IconWrap
                                        className="copy-button"
                                        iconWrapRoundedSquare
                                        iconWrapWhite
                                        icon="layers-empty"
                                        title="Copy path to clipboard"
                                        onClick={() =>
                                            copyToClipboard(f.publicPath, () => {
                                                toast.success("Copied to clipboard");
                                            })
                                        }
                                    />
                                    <span className="file-name">{f.fileName}</span>
                                </div>
                            );
                        })}
                    </div>
                    {files?.fileList.length > 0 && (
                        <FileUploadForm
                            isFilesPresent={isFilesPresent}
                            isUploading={isUploading}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            formRef={formRef}
                        />
                    )}
                </>
            )}
        </div>
    );
});

const FilePreview = ({ file }) => {
    const extension = getFileExtension(file.fileName).substring(0, 4).toLowerCase();
    const isPreviewAvailable = ["jpeg", "png", "jpg", "gif", "svg"].includes(extension);

    return (
        <div className="flex-row justify-center align-center file-preview-container">
            {isPreviewAvailable ? (
                <FileView hideControls publicPath={file.publicPath} />
            ) : (
                <IconHandMade className="margin-auto" iconDocumentHandMade big title={extension} />
            )}
        </div>
    );
};

const FileUploadForm = (props) => {
    const { isFilesPresent, isUploading, onChange, onSubmit, formRef } = props;

    const schema = {
        type: "object",
        required: ["file"],
        properties: {
            file: {
                type: "array",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:widget": "DropZoneWidget",
            "ui:multiple": true,
        },
    };

    return (
        <div className="file-upload-form">
            <Separator line marginTop={0} marginBottom={20} />
            {isUploading && (
                <>
                    <WaitIcon /> <Separator />
                </>
            )}
            <JsonSchemaForm
                noSubmit={!isFilesPresent}
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={(f) => onSubmit(f.file)}
                submitText={isUploading ? "Uploading..." : "Upload"}
                noReset
                onChange={onChange}
                disabled={isUploading}
            />
        </div>
    );
};

export default FilesPage;
