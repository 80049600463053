import React, { useCallback, useRef, memo, useEffect } from "react";
import { cloneDeep } from "lodash";
import { addWidget, setWidgetProperties } from "components/ui/PortalBuilder/utils";
import { PropertyListItemAdd } from "../PropertyList/PropertyListItemAdd";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { AccordionItemProperty } from "./AccordionItemProperty";

import "./AccordionProperty.scss";

export const AccordionProperty = memo((props) => {
    const { id, value = [], onChange } = props;
    const accordionItems = value;

    const accordionItemsRef = useRef();
    accordionItemsRef.current = accordionItems;

    const onPropertyChange = useCallback(
        (index) =>
            (propertyId, propertyValue, additionalValues = []) => {
                const updatedValue = cloneDeep(accordionItemsRef.current);
                updatedValue[index] = setWidgetProperties(updatedValue[index], [
                    { id: propertyId, value: propertyValue },
                    ...additionalValues,
                ]);

                onChange(id, updatedValue);
            },
        [id, onChange]
    );

    const { errors } = useErrorContext();

    const addedItemRef = useRef({});

    const onAdd = useCallback(() => {
        const index = accordionItems?.length ?? 0;
        const updatedValue = addWidget(accordionItems, WidgetType.ACCORDION_ITEM, index, `Accordion Item #${index + 1}`);
        addedItemRef.current[index] = updatedValue[index];
        onChange(id, updatedValue);
    }, [id, onChange, accordionItems]);

    useEffect(() => {
        if (accordionItemsRef.current.length < 1) {
            onAdd();
        }
    }, [accordionItems, onAdd]);

    const onRemove = useCallback(
        (index) => () => {
            const updatedValue = cloneDeep(accordionItems);
            updatedValue.splice(index, 1);
            onChange(id, updatedValue);
        },
        [id, onChange, accordionItems]
    );

    const onChangeOrderUp = useCallback(
        (index) => {
            const list = cloneDeep(accordionItems);
            const temp = list[index];
            list[index] = list[index - 1];
            list[index - 1] = temp;
            onChange(id, list);
        },
        [accordionItems, id, onChange]
    );

    const onChangeOrderDown = useCallback(
        (index) => {
            const list = cloneDeep(accordionItems);
            const temp = list[index];
            list[index] = list[index + 1];
            list[index + 1] = temp;
            onChange(id, list);
        },
        [accordionItems, id, onChange]
    );

    if (props.hidden) {
        return null;
    }

    const addItemLabel = <PropertyListItemAdd title="Add Item" onAdd={onAdd} />;

    if (accordionItems.length === 0) {
        return addItemLabel;
    }

    return (
        <div className="accordion-property">
            {accordionItems.map((item, index) => {
                const hideItem = item.type !== "AccordionItem";
                if (hideItem) {
                    return null;
                } else {
                    return (
                        <AccordionItemProperty
                            errors={errors}
                            index={index}
                            key={index}
                            value={item.props}
                            onRemove={onRemove(index)}
                            onChange={onPropertyChange(index)}
                            onChangeOrderUp={onChangeOrderUp}
                            onChangeOrderDown={onChangeOrderDown}
                            listLength={accordionItems.length}
                        />
                    );
                }
            })}
            {addItemLabel}
        </div>
    );
});
