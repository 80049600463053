import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { utilitiesGridColumnKeys, widgetUtilitiesGridColumnKeys, availableGrids } from "../configureGrids";
import { openUtilityTab } from "../../utils/window";
import { entityType } from "../../utils/constants";
import { updateResource } from "../../../store/resources/actions";
import { modalOpen } from "../../../store/modal/actions";
import { refreshUtilityBookmarksGrid, refreshUtilityBookmarksWidgetGrid } from "../../../store/dataGrid/refreshGrid";
import { refreshUtility } from "../../../store/resources/refreshResource";
import { construct } from "../../../store/dataGrid/actions";
import { searchAttrToFilter } from "../../utils/datagrid";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import RawHtml from "../../ui/RawHtml";
import ActionLabel from "../../ui/Label/ActionLabel";
import WaitIcon from "../../ui/WaitIcon";

import "./style.scss";

const rowActions = [
    {
        name: "open",
        icon: "open-new-window",
        title: "Manage Utility",
    },
    {
        name: "delete",
        icon: "bookmark-filled icon-wrap-combined remove",
        title: "Remove from Bookmarks",
    },
];

const UtilitiesAndProgramManagementBookmarkedUtilities = memo(() => {
    return (
        <div className="bookmarked-utilities panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <PinnedUtilitiesDataGrid isWidget={false} />
            </div>
        </div>
    );
});

const Grid = memo(({ isWidget }) => {
    const dispatch = useDispatch();

    const dataGridID = isWidget ? availableGrids.widgetUtilities : availableGrids.utilities;

    const columnIsFavorite = isWidget ? widgetUtilitiesGridColumnKeys.isFavorite : utilitiesGridColumnKeys.isFavorite;
    const columnUtilityName = isWidget ? widgetUtilitiesGridColumnKeys.utilityName : utilitiesGridColumnKeys.utilityName;
    const columnUtilityNumber = isWidget ? widgetUtilitiesGridColumnKeys.utilityNumber : utilitiesGridColumnKeys.utilityNumber;

    const user = useSelector((store) => store.user);
    const gridId = `${user.userNumber}${isWidget ? "-widget" : ""}-utilities-bookmarks-grid`;
    const gridFilter = `${columnIsFavorite}=${true}`;
    const gridConfig = useSelector((store) => store.dataGrid[gridId]);

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: dataGridID,
                dataGridInstanceId: gridId,
                filter: searchAttrToFilter(gridFilter),
                data: true,
                sort: [
                    {
                        field: widgetUtilitiesGridColumnKeys.utilityName,
                        dir: "asc",
                    },
                ],
            })
        );
    }, [dataGridID, gridId, gridFilter, dispatch]);

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const utilityName = dataItem[columnUtilityName];
        const utilityNumber = dataItem[columnUtilityNumber];

        switch (name) {
            case "open":
                onBookmarkOpen(utilityNumber);
                break;

            case "delete":
                onBookmarkDelete({ utilityNumber, utilityName });
                break;

            default:
                break;
        }
    };

    const onBookmarkOpen = (utilityNumber) => {
        openUtilityTab({ utilityNumber });
    };

    const onBookmarkDelete = ({ utilityNumber, utilityName }) => {
        const text = "<p>Would you like to remove utility <strong>" + utilityName + "</strong> from your Bookmarks?</p>";

        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Remove from Bookmarks",
                    modalIcon: "bookmark-filled icon-wrap-combined remove",
                    overlayClassName: "modal-styled",
                    className: "remove-from-bookmarks-confirmation-modal modal-sm",
                    footerContentCenter: true,
                    content: <RawHtml>{text}</RawHtml>,
                    onConfirm: () => {
                        dispatch(
                            updateResource({
                                resourceName: "userFavorites",
                                query: {
                                    entityType: entityType.Utility,
                                    entityId: utilityNumber,
                                },
                                optimisticUpdate: {
                                    clearItem: true,
                                },
                                onSuccess: () => {
                                    refreshUtilityBookmarksGrid();
                                    refreshUtilityBookmarksWidgetGrid();
                                    refreshUtility({ utilityNumber });
                                },
                            })
                        );
                    },
                },
            })
        );
    };

    if (!gridConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "utilities",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid
                name={gridId}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [columnUtilityNumber]: (column, row) => (
                        <ActionLabel onClick={() => onBookmarkOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                    ),
                    [columnUtilityName]: (column, row) => {
                        if (row[columnUtilityNumber]) {
                            return <ActionLabel onClick={() => onBookmarkOpen(row[columnUtilityNumber])}>{row[column.key]}</ActionLabel>;
                        }

                        return row[column.key];
                    },
                }}
            />
        </>
    );
});

export default UtilitiesAndProgramManagementBookmarkedUtilities;

export const PinnedUtilitiesDataGrid = Grid;
