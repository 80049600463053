import React, { memo } from "react";
import ClassNames from "classnames";

import IconWithLabel from "../Icons/IconWithLabel";

import "./style.scss";

export const ViewPlaceholder = memo((props) => {
    const className = ClassNames("view-placeholder flex-one flex-column align-center", props.className, {
        "split-screen-placeholder": props.placeholderSplitView,
        small: props.viewPlaceholderSmall,
    });

    return (
        <div className={className}>
            {props.placeholderSplitView ? (
                <div className="split-view-inner-block">
                    {props.children}
                    {props.clickableText && (
                        <div className="view-placeholder__clickable-text">
                            <IconWithLabel icon={props.clickableTextIcon} onClick={props.onClick}>
                                {props.clickableText}
                            </IconWithLabel>
                        </div>
                    )}
                </div>
            ) : (
                <div className="view-placeholder--inner-block">
                    {props.children}
                    {props.clickableText && (
                        <div className="view-placeholder__clickable-text">
                            <IconWithLabel icon={props.clickableTextIcon} onClick={props.onClick}>
                                {props.clickableText}
                            </IconWithLabel>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default ViewPlaceholder;
