import { createContext, useContext, useEffect } from "react";

/**
 * Context to consume within AutoSizeList items
 * Provides:
 * @param setAutoSize - handle to request minimum size for list item
 * @param windowWidth - window width, required to update component when window resizes
 **/
export const AutoSizeContext = createContext({});

/**
 * Decorator to consume context by AutoSizeList item
 * @param contentREF - REF to a container which boundary defines minimum required size
 * @param index - list item index
 * @param autoSizePadding - additional height from top and bottom to add to measured height
 * @param measureChildren - if true measure REF container children and take largest value as height
 */
export const useAutoSize = (contentREF, index, autoSizePadding, measureChildren) => {
    const { setAutoSize, windowWidth } = useContext(AutoSizeContext);

    useEffect(() => {
        // After delay to ensure layout finishes update
        setTimeout(() => {
            if (!contentREF.current || !setAutoSize) {
                return;
            }

            const paddingSize = autoSizePadding * 2;

            if (measureChildren) {
                // Measure all children
                const childrenSizes = Array.prototype.map.call(contentREF.current.childNodes, (child) => {
                    return child.getBoundingClientRect().height;
                });

                // Save max child height as content height
                setAutoSize(index, Math.max(...childrenSizes) + paddingSize);
            } else {
                // Save content height
                setAutoSize(index, contentREF.current.getBoundingClientRect().height + paddingSize);
            }
        }, 30);
    }, [contentREF, index, setAutoSize, windowWidth, autoSizePadding, measureChildren]);
};
