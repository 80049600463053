import { useState, useCallback } from "react";
import { store } from "../configureStore";
import { selectRows } from "./actions";
import { isSameRow, getActivePageNumber } from "components/utils/datagrid";

const useMultiPageRowSelect = ({ dataGridId, onRowSelect }) => {
    const [selectedRows, setSelectedRows] = useState({});

    //update when deleting. This needed becasue selectedRows defined separately in other file.
    // keep this just incase
    // const updateSelectedRows = useCallback(() => {
    //     setSelectedRows([]);
    // }, [setSelectedRows]);

    const onPageChanged = useCallback(
        (event) => {
            const activePage = getActivePageNumber(null, event.page);
            const selectedRowsInPage = selectedRows[activePage];

            store.dispatch(
                selectRows({
                    dataGridId: dataGridId,
                    selectedRows: selectedRowsInPage,
                })
            );
        },
        [dataGridId, selectedRows]
    );

    const onRowSelectChange = useCallback(
        (rows) => {
            const activePage = getActivePageNumber(dataGridId);
            const items = {
                ...selectedRows,
                [activePage]: rows,
            };

            setSelectedRows(items);
            onRowSelect(getRowsList(items));
        },
        [dataGridId, selectedRows, onRowSelect]
    );

    const onClear = useCallback(
        (row) => {
            let items = {};
            let rowsList = [];

            if (row) {
                Object.keys(selectedRows).forEach((key) => {
                    items = {
                        ...items,
                        [key]: selectedRows[key].filter((r) => !isSameRow(r, row)),
                    };
                });

                rowsList = getRowsList(items);
            }

            const activePage = getActivePageNumber(dataGridId);
            const selectedRowsInPage = items[activePage];

            setSelectedRows(items);
            onRowSelect(rowsList);

            store.dispatch(
                selectRows({
                    dataGridId: dataGridId,
                    selectedRows: selectedRowsInPage,
                })
            );
        },
        [dataGridId, selectedRows, onRowSelect]
    );

    return [onRowSelectChange, onPageChanged, onClear];
};

const getRowsList = (pagedRows) => {
    return Object.entries(pagedRows)
        .map((entry) => entry[1])
        .reduce((acc, next) => (acc = acc.concat(next)), []);
};

export default useMultiPageRowSelect;
