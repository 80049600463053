import React from "react";
import Dashboard from "../../ui/Dashboard";
import { dashboardTypes, utilityContractDashboard } from "../../utils/dashboard";
import UtilityContractHeader from "./UtilityContractHeader";

const UtilityContractView = (props) => {
    const { utilityNumber, contractNumber } = props;

    const dashboardData = {
        ...props,
        dashboardKey: contractNumber,
    };

    return (
        <div className="utility-contract-view flex-column fill-height no-scroll">
            <Dashboard
                key={contractNumber}
                type={dashboardTypes.UTILITY_CONTRACT}
                activeDashboardId={utilityContractDashboard.dashboardNumber}
                dashboardData={dashboardData}
                header={<UtilityContractHeader utilityNumber={utilityNumber} contractNumber={contractNumber} />}
            />
        </div>
    );
};

export default UtilityContractView;
