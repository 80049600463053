import React, { Suspense, memo } from "react";
import ErrorBoundary from "../../../utils/ErrorBoundary";

import WorkflowPanel from "./Project/WorkflowPanel";
import WorkflowPanelSmall from "./Project/WorkflowPanelSmall";
import ApplicationsPanel from "./Project/ApplicationsPanel";
import ApplicationsPanelSmall from "./Project/ApplicationsPanelSmall";
import NotesPanel from "./Project/NotesPanel";
import NotesPanelSmall from "./Project/NotesPanelSmall";
import EquipmentPanelSmall from "./Project/EquipmentPanelSmall";
import DocumentsPanel from "./Project/DocumentsPanel";
import DocumentsPanelSmall from "./Project/DocumentsPanelSmall";
import RebatesPanelSmall from "./Project/RebatesPanelSmall";
import ContactsPanel from "./Project/ContactsPanel";
import ContactsPanelSmall from "./Project/ContactsPanelSmall";
import LocationPanel from "./Project/LocationPanel";
import LocationPanelSmall from "./Project/LocationPanelSmall";
import AuditPanel from "./Project/AuditPanel";
import AuditPanelSmall from "./Project/AuditPanelSmall";
import EventsPanel from "./Project/EventsPanel";
import EventsPanelSmall from "./Project/EventsPanelSmall";
import QualityControlPanel from "./Project/QualityControlPanel";
import QualityControlPanelSmall from "./Project/QualityControlPanelSmall";
import PortalDashboardPanel from "./Project/PortalDashboardPanel";
import OpportunityPanel from "./Project/OpportunityPanel";
import ApplicationEditPanel from "./Project/ApplicationsPanel/ApplicationEditPanel";

import CalculationsPanel from "./Utility/CalculationsPanel";
import ConfigPanel from "./Utility/ConfigPanel";
import FinancialsPanel from "./Utility/FinancialsPanel";
import FilesPanel from "./Utility/FilesPanel";
import ForecastPanel from "./Utility/ForecastPanel";
import ProgramsPanel from "./Utility/ProgramsPanel";
import RebateApprovalsPanel from "./Utility/RebateApprovalsPanel";
import SnapshotPanel from "./Utility/SnapshotPanel";
import UserGroupsPanel from "./Utility/UserGroupsPanel";
import UtilityFastTagsPanel from "./Utility/ConfigPanel/FastTagsPanel";
import UtilityAttributesPanel from "./Utility/ConfigPanel/AttributesPanel";
import UtilityHostnamesPanel from "./Utility/ConfigPanel/HostnamesPanel";
import UtilityPortalsManagementPanel from "./Utility/ConfigPanel/PortalsManagementPanel";
import UtilityUserManagementPanel from "./Utility/ConfigPanel/UserManagementPanel";

import ProgramSnapshotPanel from "./Program/SnapshotPanel";
import ProgramConfigPanel from "./Program/ConfigPanel";
import ProgramBudgetsPanel from "./Program/BudgetsPanel";
import ProgramGoalsPanel from "./Program/GoalsPanel";
import ProgramWorkflowPanel from "./Program/WorkflowPanel";
import ProgramCatalogPanel from "./Program/CatalogPanel";
import ProgramEventsPanel from "./Program/EventsPanel";
import ProgramFormsPanel from "./Program/FormsPanel";
import ProgramContentPanel from "./Program/ContentPanel";
import ProgramDocumentsPanel from "./Program/DocumentsPanel";
import ProgramFilesPanel from "./Program/FilesPanel";
import ProgramApplicationsPanel from "./Program/ApplicationsPanel";
import ProgramRebatesPanel from "./Program/RebatesPanel";
import ProgramCalculationsPanel from "./Program/CalculationsPanel";
import ProgramFastTagsPanel from "./Program/ConfigPanel/FastTagsPanel";
import ProgramAttributesPanel from "./Program/ConfigPanel/AttributesPanel";
import ProgramAuditsPanel from "./Program/ConfigPanel/AuditsPanel";
import ProgramDashboardCriteriaPanel from "./Program/ConfigPanel/DashboardCriteriaPanel";
import ProgramApprovedMeasuresPanel from "./Program/ConfigPanel/ApprovedMeasuresPanel";
import ProgramCatalogCategoriesPanel from "./Program/ConfigPanel/CatalogCategoriesPanel";
import ProgramEventCategoriesPanel from "./Program/ConfigPanel/EventCategoriesPanel";

import ContractBudgetLinesPanel from "./Contract/BudgetLinesPanel";
import ContractInvoicesPanel from "./Contract/InvoicesPanel";
import ContractWorkflowPanel from "./Contract/WorkflowPanel";
import ContractAttributesPanel from "./Contract/AttributesPanel";
import ContractBudgetLookupsPanel from "./Contract/BudgetLookups";

import BudgetAmountsPanel from "./Budget/AmountsPanel";
import BudgetInvoicesPanel from "./Budget/InvoicesPanel";
import BudgetWorkflowPanel from "./Budget/WorkflowPanel";
import BudgetAttributesPanel from "./Budget/AttributesPanel";
import BudgetAssignmentsPanel from "./Budget/ProgramAssignmentsPanel";

import InvoiceWorkflowPanel from "./Invoice/WorkflowPanel";
import InvoiceItemsPanel from "./Invoice/InvoiceItemsPanel";
import InvoiceDocumentsPanel from "./Invoice/DocumentsPanel";
import InvoiceNotesPanel from "./Invoice/NotesPanel";
import InvoiceHistoryPanel from "./Invoice/HistoryPanel";

import TextPanel from "./Widgets/TextPanel";
import ReportPanel from "./Widgets/ReportPanel";
import BookmarksPanel from "./Widgets/BookmarksPanel";
import RecentAppsPanel from "./Widgets/RecentAppsPanel";
import PinnedProgramsPanel from "./Widgets/PinnedProgramsPanel";
import PinnedUtilitiesPanel from "./Widgets/PinnedUtilitiesPanel";
import AssignedAppsPanel from "./Widgets/AssignedAppsPanel";
import ScannedAppsPanel from "./Widgets/ScannedAppsPanel";
import AppActivityGraphPanel from "./Widgets/AppActivityGraphPanel";
import ApplicationsSummaryStatusPanel from "./Widgets/ApplicationsSummaryStatusPanel";
import PinnedContractsPanel from "./Widgets/PinnedContractsPanel";
import PinnedInvoiceDocumentsPanel from "./Widgets/PinnedInvoiceDocumentsPanel";

import CustmerApplicationsPanel from "./Customer/ApplicationsPanel";
import CustmerContactsPanel from "./Customer/ContactsPanel";

import EligibilityVerification from "./Widgets/EligibilityVerification";
import { NoPermissionPanel } from "./NoPermissionPanel";
import BulkUpload from "./Widgets/BulkUpload";
import ActivityPanel from "./Project/ActivityPanel";
import ModuleLoadError from "components/ui/ModuleLoadError";
import WaitIcon from "components/ui/WaitIcon";

const EquipmentPanel = React.lazy(() =>
    import("./Project/EquipmentPanel").catch((error) => ({ default: () => <ModuleLoadError error={error} /> }))
);

const RebatesPanel = React.lazy(() =>
    import("./Project/RebatesPanel").catch((error) => ({ default: () => <ModuleLoadError error={error} /> }))
);

export const availablePanels = {
    // Generic panels
    text: TextPanel,
    report: ReportPanel,
    "paginated-report": ReportPanel,

    // Custom panels
    bookmarks: BookmarksPanel,
    "recent-apps": RecentAppsPanel,
    "pinned-programs": PinnedProgramsPanel,
    "pinned-utilities": PinnedUtilitiesPanel,
    "assigned-apps": AssignedAppsPanel,
    "scanned-apps": ScannedAppsPanel,
    "app-activity-graph": AppActivityGraphPanel,
    "eligibility-verification": EligibilityVerification,
    "application-bulk-upload": BulkUpload,
    "bulk-status-change-grouper": BulkUpload,
    "applications-summary-status": ApplicationsSummaryStatusPanel,
    "pinned-contracts": PinnedContractsPanel,
    "pinned-invoice-documents": PinnedInvoiceDocumentsPanel,

    // Project main panels
    "project-workflow": WorkflowPanel,
    "project-applications": ApplicationsPanel,
    "project-notes": NotesPanel,
    "project-equipment": (props) => (
        <Suspense fallback={<WaitIcon />}>
            <EquipmentPanel {...props} />
        </Suspense>
    ),
    "project-docs": DocumentsPanel,
    "project-activity": ActivityPanel,
    "project-rebates": (props) => (
        <Suspense fallback={<WaitIcon />}>
            <RebatesPanel {...props} />
        </Suspense>
    ),
    "project-contacts": ContactsPanel,
    "project-location": LocationPanel,
    "project-audit": AuditPanel,
    "project-events": EventsPanel,
    "project-control": QualityControlPanel,
    "project-portaldashboard": PortalDashboardPanel,
    "project-opportunity": OpportunityPanel,

    // Project sidepanels
    "project-workflow-small": WorkflowPanelSmall,
    "project-applications-small": ApplicationsPanelSmall,
    "project-notes-small": NotesPanelSmall,
    "project-equipment-small": EquipmentPanelSmall,
    "project-docs-small": DocumentsPanelSmall,
    "project-rebates-small": RebatesPanelSmall,
    "project-contacts-small": ContactsPanelSmall,
    "project-location-small": LocationPanelSmall,
    "project-audit-small": AuditPanelSmall,
    "project-events-small": EventsPanelSmall,
    "project-control-small": QualityControlPanelSmall,
    "application-edit-panel": ApplicationEditPanel,

    // Program main panels
    "program-snapshot": ProgramSnapshotPanel,
    "program-config": ProgramConfigPanel,
    "program-budgets": ProgramBudgetsPanel,
    "program-goals": ProgramGoalsPanel,
    "program-workflow": ProgramWorkflowPanel,
    "program-catalog": ProgramCatalogPanel,
    "program-events": ProgramEventsPanel,
    "program-forms": ProgramFormsPanel,
    "program-content": ProgramContentPanel,
    "program-documents": ProgramDocumentsPanel,
    "program-files": ProgramFilesPanel,
    "program-applications": ProgramApplicationsPanel,
    "program-rebates": ProgramRebatesPanel,
    "program-calculations": ProgramCalculationsPanel,
    "program-attributes-panel": ProgramAttributesPanel,
    "program-audits-panel": ProgramAuditsPanel,
    "program-fast-tags": ProgramFastTagsPanel,
    "program-dashboard-criteria": ProgramDashboardCriteriaPanel,
    "program-approved-measures": ProgramApprovedMeasuresPanel,
    "program-catalog-categories": ProgramCatalogCategoriesPanel,
    "program-event-categories": ProgramEventCategoriesPanel,

    // Utility main panels
    "utility-snapshot": SnapshotPanel,
    "utility-config": ConfigPanel,
    "utility-programs": ProgramsPanel,
    "utility-financials": FinancialsPanel,
    "utility-files": FilesPanel,
    "utility-usergroups": UserGroupsPanel,
    "utility-calculations": CalculationsPanel,
    "utility-rebateapprovals": RebateApprovalsPanel,
    "utility-forecast": ForecastPanel,
    "utility-attributes-panel": UtilityAttributesPanel,
    "utility-fast-tags": UtilityFastTagsPanel,
    "utility-host-names": UtilityHostnamesPanel,
    "utility-role-management": UtilityUserManagementPanel,
    "utility-portals-management": UtilityPortalsManagementPanel,

    // Contract panels
    "utility-contract-budget-lines": ContractBudgetLinesPanel,
    "utility-contract-invoices": ContractInvoicesPanel,
    "utility-contract-workflow": ContractWorkflowPanel,
    "utility-contract-attributes": ContractAttributesPanel,
    "utility-contract-budget-lookups": ContractBudgetLookupsPanel,

    // Budget panels
    "utility-budget-amounts": BudgetAmountsPanel,
    "utility-budget-invoices": BudgetInvoicesPanel,
    "utility-budget-workflow": BudgetWorkflowPanel,
    "utility-budget-attributes": BudgetAttributesPanel,
    "utility-budget-program-assignment": BudgetAssignmentsPanel,

    // Invoice panels
    "utility-invoice-workflow": InvoiceWorkflowPanel,
    "utility-invoice-items": InvoiceItemsPanel,
    "utility-invoice-docs": InvoiceDocumentsPanel,
    "utility-invoice-notes": InvoiceNotesPanel,
    "utility-invoice-history": InvoiceHistoryPanel,

    // Customer panels
    "customer-applications": CustmerApplicationsPanel,
    "customer-contacts": CustmerContactsPanel,

    // Custom panels
    "no-permission-panel": NoPermissionPanel,
};

const PanelRoot = memo((props) => {
    const { type } = props.panel;
    const DefinedPanel = availablePanels[type];

    if (DefinedPanel) {
        return (
            <ErrorBoundary>
                <DefinedPanel {...props} />
            </ErrorBoundary>
        );
    }

    return null;
});

export default PanelRoot;
