import React from "react";
import { store } from "../../store/configureStore";
import { deleteResource, updateResource, createResource } from "../../store/resources/actions";
import { modalOpen } from "../../store/modal/actions";
import * as actionTypes from "../../store/actionTypes";
import { triggerSessionStorageSync } from "./store";

const nameKey = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const expiresInKey = "ExpiresIn";

const storage = sessionStorage;

export const USER_STORAGE_KEY = "user";

export const systemUserRights = {
    VISIONDSM_ADD_EVENT: "AE",
    VISIONDSM_ADD_CONTRACT: "AC",
    VISIONDSM_ADD_CUSTOM_TASK: "ACT",
    VISIONDSM_MANAGE_SCANGROUPS: "MS", // Manage system
    VISIONDSM_MANAGE_USERGROUPS: "MU",
    VISIONDSM_ADD_APPLICATION: "VAA",
    VISIONDSM_ASSIGN_APPLICATION: "VAAP",
    VISIONDSM_ADD_BUDGET: "VAB",
    VISIONDSM_VIEW_BUDGET: "VVB",
    VISIONDSM_MANAGE_BUDGET: "VMB",
    VISIONDSM_ADD_INVOICE: "VAI",
    VISIONDSM_VIEW_INVOICE: "VVI",
    VISIONDSM_ADD_CORRESPONDENCE: "VAC", // Correspondence
    VISIONDSM_ADD_CONTENT: "VACO",
    VISIONDSM_APPROVE_CONTENT: "VACT",
    VISIONDSM_ADMINISTRATOR_ACTIONS: "VADA", // Reset payment dates
    VISIONDSM_ADD_FORM: "VAF",
    VISIONDSM_ADD_GOAL: "VAG",
    VISIONDSM_ADD_NOTES: "VAN", // Can add notes
    VISIONDSM_ADD_PROGRAM: "VAP", // Create Program
    VISIONDSM_COPY_PROGRAM: "VCP", // Copy Program
    VISIONDSM_ADD_PROGRAM_CATALOG: "VAPC",
    VISIONDSM_VIEW_APPLICATION: "VVA", // view Application
    VISIONDSM_ADD_REBATE: "VAR", // add Rebate
    VISIONDSM_ADMIN_REBATE: "VARB", // admin Rebate
    VISIONDSM_OVERRIDE_REBATE: "VOR", // override Rebate
    VISIONDSM_COMMIT_REBATE: "VCR", // commit Rebate
    VISIONDSM_MANAGE_REBATE: "VMR", // manage Rebate
    VISIONDSM_ADD_WORKFLOW: "VAW", // Add workflow
    VISIONDSM_ADD_UTILITY: "VAU", // Create Utility
    VISIONDSM_CRM_ACCESS: "VCA", // Can see CRM
    VISIONDSM_IMPERSONATE_PORTAL: "VIP", // VISIONDSM_IMPERSONATE_PORTAL
    VISIONDSM_MANAGE_APPLICATIONS: "VMA", // Can complete workflow tasks
    VISIONDSM_MANAGE_DOCUMENT_QUEUE: "VMDQ",
    VISIONDSM_MANAGE_EVENTS: "VME",
    VISIONDSM_MANAGE_FORECASTS: "VMF",
    VISIONDSM_MANAGE_FORECASTS_PRO: "VMFP",
    VISIONDSM_MANAGE_PROGRAM: "VMP",
    VISIONDSM_MANAGE_PROGRAM_FILES: "VMPF",
    VISIONDSM_MANAGE_SYSTEM: "VMS", // Can change hostname
    VISIONDSM_MANAGE_UTILITY: "VMU",
    VISIONDSM_MANAGE_UTILITY_FILES: "VMUF",
    VISIONDSM_MANAGE_USER_RIGHTS: "VMUR", // VISIONDSM_MANAGE_USER_RIGHTS
    VISIONDSM_ONLY_ASSIGNED_APPS: "VOAA",
    VISIONDSM_TRANSFER_APPLICATION: "VTA",
    VISIONDSM_WORKFLOW_OVERRIDE: "VWO",
    VISIONDSM_WORKFLOW_RULES: "VWR",
    VISIONDSM_VIEW_UTILITY: "VVU",
    VISIONDSM_VIEW_PROGRAM: "VVP",
    VISIONDSM_GENERAL_SEARCH: "VGS",
    VISIONDSM_CIS_SEARCH: "VCS",
    VISIONDSM_MANAGE_HOSTNAME: "VMH", // VISIONDSM_MANAGE_HOSTNAME
    VISIONDSM_ADD_CALCULATION: "VCAL", // view / edit Calculations
    VISIONDSM_ADD_QC: "VAQ", // Add QC Request
    VISIONDSM_ADD_FILES: "VAFI", // Upload Files
    VISIONDSM_ADMINISTRATION_MENU: "VAM",
    VISIONDSM_VIEW_ALL_CUSTOMERS: "VVAC",
    VISIONDSM_ADD_MASTER_CATALOG: "VAMC",
    VISIONDSM_ADD_USER_ASSIGNMENTS: "VAUA",
    VISIONDSM_VIEW_REPORTS: "VVR",
    VISIONDSM_MANAGE_DATA: "VMD",
    VISIONDSM_UPLOAD_TEMPLATES: "VUT",
    VISIONDSM_RECEIVE_CONTACT_NOTIFICATION: "VRCN",
    VISIONDSM_ADD_PROGRAM_ASSIGNMENTS: "VAPA",
    VISIONDSM_SAVE_REPORTS: "VSR",
    VISIONDSM_ENTER_CALL_TRACKING: "VECT",
    VISIONDSM_CREATE_CALL_INQUIRY: "VCCI",
    VISIONDSM_MANAGE_QC: "VMC",
    VISIONDSM_ADD_MV: "VAMV",
    VISIONDSM_MANAGE_MV: "VMM",
    VISIONDSM_VIEW_FILES: "VVF",
    VISIONDSM_ADD_TICKET: "VATT",
    VISIONDSM_VIEW_TICKET: "VVT",
    VISIONDSM_ASSIGN_TICKET: "VAT",
    VISIONDSM_CHANGE_TICKET_STATUS: "VCTS",
    VISIONDSM_ADD_RECURRING_TICKET: "VART",
    VISIONDSM_ASSIGN_TO_TICKET: "ATT",
    VISIONDSM_DELETE_APPLICATION: "VDA",
    VISIONDSM_SINGLEPOINT_APPLICATION: "VSA",
    VISIONDSM_EDIT_ALL_EVENTS: "EAE",
    VISIONDSM_REPORT_VIEWER: "VRV",
    VISIONDSM_OVERRIDE_APPROVED_MEASURE: "VOAM",
    VISIONDSM_ADD_CAMPAIGN: "ACA",
    VISIONDSM_VIEW_CAMPAIGN: "VVC",
    VISIONDSM_SHOW_DEBUG: "VSB",
    VISIONDSM_SHOW_DASHBOARDS: "VSD",
    VISIONDSM_BULK_APPROVAL: "VBA",
    BETA_TESTER: "BT",
    VISIONDSM_LIMIT_APPLICATION_ITEMS: "VLAI",
    VISIONDSM_ADD_CONTRACTOR_LIST: "VACL",
    VISIONDSM_RESTRICT_CIS_SEARCH: "VRCS",
    VISIONDSM_ADD_USER: "AU",
    VISIONDSM_NEW_DOCUMENT_GENERATOR: "VNDG",
    VISIONDSM_LIMIT_INVOICE_ACCESS: "VLIA",
    VISIONDSM_SUPER_USER: "VSU",
    VISIONDSM_EDIT_CONSTRAINT_RULE: "ECR",
    VISIONDSM_PROJECTS_API: "VPA",
    VISIONDSM_SECURITY_OVERRIDE: "VSO",
    VISIONDSM_ALLOW_CLIENT_ACCESS: "VACA",
    VISIONDSM_API_APP_CREATE: "VAAC",
    VISIONDSM_SYSTEM_ADMIN: "VSYA",
    VISIONDSM_VIEW_PORTAL_ANALYTICS: "VPOA",
};

export const ProgramRights = {
    VISIONDSM_ADD_EQUIPMENT: "VAE", //VISIONDSM_ADD_EQUIPMENT
    VISIONDSM_EDIT_ALL_EQUIPMENT: "VEAE", //Edit all equipment
};

export const UserApplications = {
    Insights: "Insights",
    TRMulator: "TRMulator",
    VisionDSM: "VisionDSM",
};

export const isAuthenticated = (user) => {
    return Boolean(user && user.status === "active" && !user.qrCode);
};

export const isStatusExpired = (user) => {
    return Boolean(user && user.status === "expired");
};

export const isSuspended = (user) => {
    return Boolean(user && user.status === "suspended");
};

export const isQrCode = (user) => {
    return Boolean(user && user.status === "active" && user.qrCode);
};

export const hasPermission = (user, permission) => {
    return isAuthenticated(user) && user.rights.includes(permission);
};

/**
 * Checks if the current user has any of the specified permissions.
 *
 * @param {Array<string>} permissions - The permissions to check.
 * @returns {boolean} - True if the user has any of the permissions, false otherwise.
 */
export const hasAnyOfPermissions = (permissions) => {
    const user = getUser();
    return permissions.some((permission) => hasPermission(user, permission));
};

/**
 * Tests if the current user has access to the specified application.
 *
 * @param {string} application - The application to check.
 * @returns true if the user has access to the application, false otherwise.
 */
export const hasApplicationAccess = (application) => {
    const user = getUser();
    return user?.applicationList?.includes(application);
};

export const tokenExpired = (user) => {
    var currentTime = Date.now();

    if (isQrCode(user)) {
        return user.tokenExpires < currentTime;
    }

    return isAuthenticated(user) ? user.tokenExpires < currentTime : true;
};

export const createUser = (params) => {
    const { qrCode, userName, accessToken, refreshToken, firstName, lastName, rights, userStatus, settings, applicationList } = params;

    let name = userName;

    if (!name && accessToken) {
        name = getUserNameFromToken(accessToken);
    }

    if (userStatus !== "active") {
        return {
            name,
            status: userStatus,
            accessToken,
            qrCode,
            settings,
        };
    }

    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return {
        accessToken,
        refreshToken,
        userNumber: userData.sub,
        firstName,
        lastName,
        rights,
        applicationList,
        name,
        status: userStatus,
        isActive: userStatus === "active",
        tokenExpires: Date.now() + userData[expiresInKey] * 60000,
        tokenExpiresIn: userData[expiresInKey],
        qrCode,
        settings,
    };
};

export const refreshUser = (params) => {
    const user = createUser(params);

    saveUser(user);

    return user;
};

export const getUser = () => {
    return JSON.parse(storage.getItem(USER_STORAGE_KEY));
};

export const saveUser = (user) => {
    storage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    triggerSessionStorageSync(USER_STORAGE_KEY);
};

export const deleteUser = () => {
    storage.removeItem(USER_STORAGE_KEY);
    triggerSessionStorageSync(USER_STORAGE_KEY);
};

export const getUserNameFromToken = (accessToken) => {
    const jwtData = accessToken.split(".")[1];
    const decodedData = window.atob(jwtData);
    const userData = JSON.parse(decodedData);

    return userData[nameKey] || "";
};

export const formatFullName = (firstName, lastName, companyName = "") => {
    if (!firstName && !lastName) {
        return companyName;
    }

    if (!firstName || !lastName) {
        return `${firstName || ""}${lastName || ""}`.trim();
    }

    return `${firstName} ${lastName}`.trim();
};

export const formatFullAddress = (address, address_cont, city, state, zip) => {
    return [address, address_cont, city, state, zip].filter((part) => part).join(", ");
};

export const setUserSettings = (settings) => {
    var user = getUser();

    if (user && settings) {
        saveUser({
            ...user,
            settings: {
                ...settings,
            },
        });
    }

    return user;
};

export const getUserRights = () => {
    const user = getUser();

    return user?.rights;
};

export const resetPassword = ({ userNumber, userName }) => {
    const text = (
        <p>
            Are you sure you want to reset password for <strong>{userName}</strong>?
        </p>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Reset Password",
                overlayClassName: "modal-styled",
                className: "reset-password-confirmation-modal modal-sm",
                modalIcon: "backup-restore-empty",
                content: text,
                footerContentCenter: true,
                onConfirm: () => {
                    store.dispatch(
                        deleteResource({
                            resourceName: "userPasswordReset",
                            path: {
                                userNumber: userNumber,
                            },
                        })
                    );
                },
            },
        })
    );
};

export const changePassword = ({ oldPassword, newPassword }, setSubmitting, onSuccess) => {
    store.dispatch(
        updateResource({
            resourceName: "userPassword",
            body: { oldPassword, newPassword },
            showErrorNotification: false,
            onError: (data) => {
                store.dispatch({
                    type: actionTypes.CHANGE_PASSWORD_ERROR,
                    message: data.message,
                });
                setSubmitting(false);
            },
            onSuccess: () => {
                setSubmitting(false);
                onSuccess();
            },
        })
    );
};

export const changePasswordSsrs = ({ authorizationCode, newPassword }, setSubmitting, onSuccess) => {
    store.dispatch(
        createResource({
            resourceName: "passwordSsrs",
            body: { newPassword, authorizationCode },
            showErrorNotification: false,
            onError: (data) => {
                store.dispatch({
                    type: actionTypes.CHANGE_SSRS_PASSWORD_ERROR,
                    message: data.message,
                });
                setSubmitting(false);
            },
            onSuccess: () => {
                setSubmitting(false);
                onSuccess();
            },
        })
    );
};

export const resetQrCode = ({ userNumber, userName, onComplete }) => {
    const text = (
        <p>
            Are you sure you want to reset the QR code for <strong>{userName}</strong>?
        </p>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Reset QR Code",
                overlayClassName: "modal-styled",
                className: "reset-qr-code-confirmation-modal modal-sm",
                modalIcon: "backup-restore-empty",
                content: text,
                footerContentCenter: true,
                onConfirm: () => {
                    store.dispatch(
                        deleteResource({
                            resourceName: "usersQrCode",
                            path: {
                                userNumber: userNumber,
                            },
                            onComplete: onComplete(),
                        })
                    );
                },
            },
        })
    );
};

export const isIEBrowser = () => !!window.document.documentMode;
