import React, { useMemo, memo, useCallback, useRef, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useWindowResize } from "components/utils/useWindowResize";
import { AutoSizeContext } from "components/utils/autosize";

import "./DocumentList.scss";

const DOCUMENT_ROW_HEIGHT = 40;
const DOCUMENT_LIST_MAX_HEIGHT = 225;

export const DocumentList = memo(
    ({ documentComponent, entityId, documents, isBatchFilled, isSelectionDisabled, isExpanded, selectedDocumentsCount }) => {
        const documentList = useMemo(() => documents[entityId], [entityId, documents]);

        // List REF
        const listRef = React.useRef();

        // Map of heights
        const listItemSizeMap = useRef({});

        // Listen for window resize
        const [windowWidth] = useWindowResize();

        const getSize = useCallback((index) => {
            const savedSize = listItemSizeMap.current[index];
            return savedSize ? Math.max(savedSize, DOCUMENT_ROW_HEIGHT) : DOCUMENT_ROW_HEIGHT;
        }, []);

        const getListHeight = useCallback(() => {
            const listItemCount = documentList?.length ?? 0;
            let listHeight = 0;

            for (let i = 0; i < listItemCount; i++) {
                listHeight += getSize(i);
            }

            return listHeight < DOCUMENT_LIST_MAX_HEIGHT ? listHeight : DOCUMENT_LIST_MAX_HEIGHT;
        }, [documentList, getSize]);

        // Listen for size updates from children
        const setAutoSize = useCallback(
            (index, size) => {
                listItemSizeMap.current = {
                    ...listItemSizeMap.current,
                    [index]: size,
                };

                // Update list
                if (listRef.current) listRef.current.resetAfterIndex(0);

                setListHeight(getListHeight());
            },
            [getListHeight]
        );

        const [listHeight, setListHeight] = useState(getListHeight());

        if (isNil(documentList) || isEmpty(documentList) || !isExpanded) return null;

        return (
            <div className="application-document-queue__grid-document-list" onClick={(e) => e.stopPropagation()}>
                <div className="application-document-queue__grid-document-list-columns flex-row align-center">
                    <div className="application-document-queue__grid-document-list-columns--application">application</div>
                    <div className="application-document-queue__grid-document-list-columns--document">document</div>
                    <div className="application-document-queue__grid-document-list-columns--user">user</div>
                    <div className="application-document-queue__grid-document-list-columns--date">received</div>
                </div>
                <AutoSizeContext.Provider value={{ setAutoSize, windowWidth }}>
                    <AutoSizer disableHeight>
                        {({ width }) => {
                            return (
                                <VariableSizeList
                                    height={listHeight}
                                    width={width}
                                    ref={listRef}
                                    itemCount={documentList.length}
                                    itemData={{
                                        entityId,
                                        isBatchFilled,
                                        isSelectionDisabled,
                                        selectedDocumentsCount,
                                    }}
                                    estimatedItemSize={DOCUMENT_ROW_HEIGHT}
                                    itemSize={getSize}
                                >
                                    {documentComponent}
                                </VariableSizeList>
                            );
                        }}
                    </AutoSizer>
                </AutoSizeContext.Provider>
            </div>
        );
    }
);
