import React, { memo } from "react";

import { systemTabs } from "../../utils/window";

import ViewWithSubmenu from "../../ui/ViewWithSubmenu";
import { systemUserRights } from "components/utils/user";

const submenuItems = [
    {
        id: "all-utilities",
        text: "All Utilities",
        icon: "city-empty",
        component: "UtilitiesAndProgramManagementAllUtilities",
        userRights: [systemUserRights.VISIONDSM_VIEW_UTILITY],
    },
    {
        id: "all-programs",
        text: "All Programs",
        icon: "chart-multiple-empty",
        component: "UtilitiesAndProgramManagementAllPrograms",
        userRights: [systemUserRights.VISIONDSM_VIEW_PROGRAM],
    },
    {
        id: systemTabs.Catalog,
        text: "Global Catalog",
        icon: "catalog",
        component: "UtilitiesAndProgramManagementCatalog",
        userRights: [systemUserRights.VISIONDSM_MANAGE_SYSTEM],
    },
    {
        id: systemTabs.UtilityBookmarks,
        text: "Bookmarked Utilities",
        icon: "bookmark-empty",
        component: "UtilitiesAndProgramManagementBookmarkedUtilities",
        userRights: [systemUserRights.VISIONDSM_VIEW_UTILITY],
    },
    {
        id: systemTabs.ProgramBookmarks,
        text: "Bookmarked Programs",
        icon: "bookmark-empty",
        component: "UtilitiesAndProgramManagementBookmarkedPrograms",
        userRights: [systemUserRights.VISIONDSM_VIEW_PROGRAM],
    },
    {
        id: systemTabs.ContractBookmarks,
        text: "Bookmarked Contracts",
        icon: "bookmark-empty",
        component: "UtilitiesAndProgramManagementBookmarkedContracts",
        userRights: [systemUserRights.VISIONDSM_ADD_BUDGET],
    },
    {
        id: systemTabs.InvoiceBookmarks,
        text: "Bookmarked Invoices",
        icon: "bookmark-empty",
        component: "UtilitiesAndProgramManagementBookmarkedInvoices",
        userRights: [systemUserRights.VISIONDSM_VIEW_INVOICE],
    },
];

const UtilitiesAndProgramManagement = memo(() => {
    return (
        <ViewWithSubmenu
            className="utilities-programs-management-view"
            viewName="UtilitiesAndProgramManagement"
            menuItems={submenuItems}
            viewPlaceholder="Utility and Program Management"
        />
    );
});

export default UtilitiesAndProgramManagement;
