import React, { useCallback, useRef, memo } from "react";

import { onEventsGridRefresh, getEventsGridId } from "./utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { programsEventsGridColumnKeys } from "../../../../../views/configureGrids";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "../../Controls";
import Grid from "./Grid";
import Form from "./Form";
import { isChildProgram } from "components/views/ProgramView/utils";

const EventsPanel = memo(({ panel }) => {
    const utilityNumber = panel.data.utilityNumber;
    const programNumber = panel.data.programNumber;

    const dataItemRef = useRef(null);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "events-sidenav-panel",
    });

    const handleGridRefresh = useCallback(() => {
        onEventsGridRefresh({ programNumber });
    }, [programNumber]);

    const handleShowEditForm = useCallback(
        ({ dataItem }) => {
            dataItemRef.current = dataItem;

            handleOpenSidePanel(
                <Form
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                    gridRefresh={handleGridRefresh}
                    dataItem={dataItemRef.current}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [utilityNumber, programNumber, handleGridRefresh, handleOpenSidePanel, handleCloseSidePanel]
    );

    const controlItems = [
        {
            position: "right",
            title: "Add Event",
            icon: "plus",
            disabled: isChildProgram({ programNumber }),
            onClick: () => handleShowEditForm({ dataItem: null }),
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: getEventsGridId({ programNumber }),
                    fileName: "program_events",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="panel program-events flex-column fill-height sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            <Grid
                gridId={getEventsGridId({ programNumber })}
                filter={`${programsEventsGridColumnKeys.programNumber}=${programNumber}`}
                utilityNumber={utilityNumber}
                programNumber={programNumber}
                onShowEditForm={handleShowEditForm}
            />
        </div>
    );
});

export default EventsPanel;
