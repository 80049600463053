import React, { useCallback, useContext, useMemo, memo } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { WorkflowContext } from "../../../context/WorkflowContext";
import { useResource } from "../../../../../../store/resources/useResource";
import { STEP_TYPES_KEYS, STEP_TYPES } from "../../../constants/step-types";
import { TargetStatusField } from "./StepTypesWidget/Fields/TargetStatusField";

const USER_RIGHTS = {
    ECR: "ECR",
    VWR: "VWR",
};

const getRulesFormUserRights = (userRights) => {
    const { ECR, VWR } = USER_RIGHTS;
    const [isECR, isVWR] = [ECR, VWR].map((right) => userRights.includes(right));

    if (isECR) {
        return ECR;
    }

    if (isVWR) {
        return VWR;
    }

    return null;
};

const getRulesFormSchemes = ({
    userRights,
    constraintRules,
    autocompleteRules,
    areConstraintRulesLoading,
    areAutoCompleteRulesLoading,
    newStatus,
    stepTypeCode,
}) => {
    let schema = null;
    let uiSchema = null;
    const rulesFormUserRights = getRulesFormUserRights(userRights);
    const { ECR, VWR } = USER_RIGHTS;

    const showAutoCompleteRule = newStatus && newStatus !== "" && stepTypeCode !== "STCH" && stepTypeCode !== "QCT";

    if (rulesFormUserRights === ECR) {
        schema = {
            type: "object",
            properties: {
                constraintRule: {
                    type: "string",
                    title: "Constraint Rules",
                },
            },
        };

        uiSchema = {
            constraintRule: {
                classNames: "wf__settings-step-form-field",
                "ui:widget": "textarea",
            },
        };

        if (showAutoCompleteRule) {
            schema = {
                ...schema,
                properties: {
                    ...schema.properties,
                    autoCompleteRule: {
                        type: "string",
                        title: "Status Change Rule",
                    },
                },
            };

            uiSchema = {
                ...uiSchema,
                autoCompleteRule: {
                    classNames: "wf__settings-step-form-field",
                    "ui:widget": "textarea",
                },
            };
        }
    }

    if (rulesFormUserRights === VWR) {
        schema = {
            type: "object",
            properties: {
                constraintRule: {
                    type: "string",
                    title: "Constraint Rule",
                    anyOf: constraintRules.map((rule) => ({
                        title: rule.display,
                        enum: [rule.display],
                    })),
                },
            },
        };

        uiSchema = {
            constraintRule: {
                classNames: "wf__settings-step-form-field",
                "ui:placeholder": areConstraintRulesLoading ? "Loading..." : "-- SELECT --",
                "ui:disabled": areConstraintRulesLoading,
                "ui:emptyItem": true,
            },
        };

        if (showAutoCompleteRule) {
            schema = {
                ...schema,
                properties: {
                    ...schema.properties,
                    autoCompleteRule: {
                        type: "string",
                        title: "Status Change Rule",
                        anyOf: autocompleteRules.map((rule) => ({
                            title: rule.display,
                            enum: [rule.display],
                        })),
                    },
                },
            };

            uiSchema = {
                ...uiSchema,
                autoCompleteRule: {
                    classNames: "wf__settings-step-form-field",
                    "ui:placeholder": areAutoCompleteRulesLoading ? "Loading..." : "-- SELECT --",
                    "ui:disabled": areAutoCompleteRulesLoading,
                    "ui:emptyItem": true,
                },
            };
        }
    }

    return { schema, uiSchema };
};

const RulesFormContainer = memo(({ constraintRule, autoCompleteRule, userRights }) => {
    const {
        workflowStatusGetResourceOptions,
        stepTypesGetResourceOptions,
        onChangeWorkflowStepProperties,
        activeItem,
        workflowStatuses,
        isLocked,
    } = useContext(WorkflowContext);

    const [constraintRules = [], areConstraintRulesLoading] = useReference(referenceTypes.constraintRule);
    const [autocompleteRules = [], areAutoCompleteRulesLoading] = useReference(referenceTypes.autocompleteRule);
    const [targetStatuses = [], areTargetStatusesLoading] = useResource(workflowStatusGetResourceOptions);
    const [workflowStepTypes = [], isLoadingWorkflowStepTypes] = useResource(stepTypesGetResourceOptions);

    const newStatus = useMemo(() => {
        const { statusNumber, stepNumber } = activeItem;

        return workflowStatuses
            .filter((status) => status.number === statusNumber)
            .map((status) => status.steps)
            .reduce((acc, val) => acc.concat(val), [])
            .filter((step) => step.number === stepNumber)
            .map((step) => step.newStatus)[0];
    }, [activeItem, workflowStatuses]);

    const stepTypeCode = useMemo(() => {
        if (!isLoadingWorkflowStepTypes) {
            const { statusNumber, stepNumber } = activeItem;

            const stepTypeNumber = workflowStatuses
                .filter((status) => status.number === statusNumber)
                .map((status) => status.steps)
                .reduce((acc, val) => acc.concat(val), [])
                .filter((step) => step.number === stepNumber)
                .map((step) => step.typeNumber)[0];

            return workflowStepTypes.find((i) => i.number === stepTypeNumber)?.code;
        }

        return null;
    }, [activeItem, workflowStatuses, workflowStepTypes, isLoadingWorkflowStepTypes]);

    const showNewStatus = useMemo(() => {
        if (stepTypeCode && STEP_TYPES.hasOwnProperty(stepTypeCode)) {
            return STEP_TYPES[stepTypeCode].key !== STEP_TYPES_KEYS.AUTOMATED_STATUS_CHANGE;
        }

        return false;
    }, [stepTypeCode]);

    const { schema, uiSchema } = getRulesFormSchemes({
        userRights,
        constraintRules,
        autocompleteRules,
        targetStatuses,
        areConstraintRulesLoading,
        areAutoCompleteRulesLoading,
        areTargetStatusesLoading,
        newStatus,
        showNewStatus,
        stepTypeCode,
        activeItem,
    });

    const initialValues = useMemo(
        () => ({
            constraintRule: areConstraintRulesLoading ? null : constraintRule,
            autoCompleteRule: areAutoCompleteRulesLoading ? null : autoCompleteRule,
            newStatus: areTargetStatusesLoading ? null : newStatus,
        }),
        [constraintRule, autoCompleteRule, newStatus, areAutoCompleteRulesLoading, areConstraintRulesLoading, areTargetStatusesLoading]
    );

    const handleChange = useCallback(({ formData }) => onChangeWorkflowStepProperties(formData), [onChangeWorkflowStepProperties]);

    if (schema) {
        return (
            <div className="wf__settings-step-form-container">
                <div className="wf__settings-step-form-title">Rules</div>
                <JsonSchemaForm
                    className="wf__settings-step-form"
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    readOnly={isLocked}
                    onChange={handleChange}
                    noSubmit
                    noReset
                />
                <TargetStatusField className="rules-target-status-field" />
            </div>
        );
    }

    return null;
});

const mapStateToProps = (state) => ({
    userRights: get(state, "user.rights", []),
});

export const RulesForm = connect(mapStateToProps)(RulesFormContainer);
