import React, { useCallback, memo } from "react";

import { openUserAssignmentModal, openProgramAssignmentModal } from "./utils";

import WaitIcon from "../../ui/WaitIcon";
import AssociationsList from "../../ui/AssociationsList";

const Associations = memo(({ isLoading, dataItem, users, programs, onChangeUsers, onChangePrograms, readOnly }) => {
    const onEditUsers = useCallback(() => {
        openUserAssignmentModal({
            group: dataItem,
            onSelect: (items) => {
                onChangeUsers(items);
            },
        });
    }, [dataItem, onChangeUsers]);

    const onRemoveUser = useCallback(
        (user) => {
            onChangeUsers(users.filter((i) => i.userNumber !== user.userNumber));
        },
        [users, onChangeUsers]
    );

    const onEditPrograms = useCallback(() => {
        openProgramAssignmentModal({
            group: dataItem,
            onSelect: (items) => {
                onChangePrograms(items);
            },
        });
    }, [dataItem, onChangePrograms]);

    const onRemoveProgram = useCallback(
        (program) => {
            onChangePrograms(programs.filter((i) => i.programNumber !== program.programNumber));
        },
        [programs, onChangePrograms]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div>
            <AssociationsList
                readOnly={readOnly}
                headerText="Programs"
                onEdit={onEditPrograms}
                title="Remove Program"
                onRemove={onRemoveProgram}
                list={programs}
                displayProperty="programName"
            />
            <AssociationsList
                readOnly={readOnly}
                headerText="Users"
                onEdit={onEditUsers}
                title="Remove User"
                onRemove={onRemoveUser}
                list={users}
                displayProperty="userName"
            />
        </div>
    );
});

export default Associations;
