import React from "react";
import { ColorSelectIcon } from "../../Property/ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../../Property/ColorSelect/ColorSelectPanel";
import { SelectPanelProperty } from "../../Property/SelectPanelProperty";
import { ButtonBorderCustomPropertyName } from "./types";

export const ButtonBorderColorProperty = (props) => {
    return (
        <SelectPanelProperty
            {...props}
            id={ButtonBorderCustomPropertyName.PrimaryButtonBorderColor}
            title={"Color"}
            selectComponent={ColorSelectIcon}
            panelComponent={ColorSelectPanel}
        />
    );
};
