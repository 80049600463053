import { formatJsonDateTime } from "components/utils/date";
import React, { memo, useContext } from "react";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { PortalBuilderProperties, PropertyType, PropertyValue } from "components/ui/PortalBuilder/types";
import { getTemplateInfoErrors } from "components/ui/PortalBuilder/utils/validation";
import { PropertyList } from "../PropertyList";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { isNil } from "lodash";

export const ProgramTemplateInfo = memo(() => {
    const { template = {}, templateInfo = {}, onTemplateInfoChange } = useContext(PortalBuilderContext);
    const { errors = [] } = useErrorContext()!;
    const { dateEntered, dateEdited, userLastEdited, userCreated } = template;

    const isNewTemplate = isNil(dateEntered);
    const activeSectionErrors = getTemplateInfoErrors(errors);

    const onPropertyChange = (id: string, value: PropertyValue) => {
        onTemplateInfoChange({
            ...templateInfo,
            [id]: value,
        });
    };

    return (
        <div className="flex-one-in-column with-scroll">
            {!isNewTemplate && (
                <div className="template-info-items flex-column">
                    <div className="template-info-date fill-width flex-row">
                        <div className="template-info-date-created flex-column">
                            <div className="template-info-date-title">Created:</div>
                            <div className="template-info-date-info">{formatJsonDateTime(dateEntered)}</div>
                            <div className="template-info-date-sub-info">
                                <span className="template-info-date-label">by:</span>
                                <span className="template-info-date-user">{userCreated}</span>
                            </div>
                        </div>
                        <div className="template-info-date-edited flex-column">
                            <div className="template-info-date-title">Last Edited:</div>
                            <div className="template-info-date-info">{formatJsonDateTime(dateEdited)}</div>
                            <div className="template-info-date-sub-info">
                                <span className="template-info-date-label">by:</span>
                                <span className="template-info-date-user">{userLastEdited}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <PropertyList
                className="template-info-properties"
                items={PROGRAM_TEMPLATE_INFO_PROPERTIES}
                config={templateInfo}
                errors={activeSectionErrors}
                onChange={onPropertyChange}
                isExpanded
            />
        </div>
    );
});

export const PROGRAM_TEMPLATE_INFO_PROPERTIES: PortalBuilderProperties = [
    {
        id: "notes",
        title: "Notes",
        type: PropertyType.Textarea,
    },
];
