import React, { useMemo, useRef, useEffect, useState } from "react";
import { get } from "lodash";
import { store } from "../../../store/configureStore";
import { updateResource, getResource, createResource, deleteResource } from "../../../store/resources/actions";
import { openAssignmentModal } from "../../ui/AssignmentSelector/utils";
import { modalClose, modalOpen } from "../../../store/modal/actions";
import FilterCreateForm from "./FilterCreateForm";
import FilterUpdateForm from "./FilterUpdateForm";
import { setWorkqueueActiveDataSource, dataSourceChanged } from "../../../store/workcenter/actions";
import { submitByRefPromise } from "../../utils/form";
import { useDispatch, useSelector } from "react-redux";
import WaitIcon from "components/ui/WaitIcon";

export const defaultDataSource = {
    datasourceNumber: "0",
    datasourceName: "Add New Filter",
    isShared: false,
    isDefault: false,
};

export const defaultProgramFilter = {
    utility: undefined,
    program: undefined,
    projectStatuses: undefined,
};

export const filterTabs = [
    {
        id: "applications",
        title: "APPLICATIONS",
    },
    {
        id: "tasks",
        title: "TASKS",
    },
];

export const getDefaultApplicationsFilter = () => {
    return {
        assignment: 0,
    };
};

export const getDefaultTasksFilter = () => {
    return {
        ownership: 0,
    };
};

export const getDataSourceProgramFilter = (dataSources) => {
    if (dataSources) {
        const { workCenter } = dataSources;

        let workCenterItem = workCenter.workCenterItem;

        if (!Array.isArray(workCenterItem)) {
            workCenterItem = [workCenterItem];
        }

        return workCenterItem.map((item) => {
            const statusItem = item.statuses.statusItem;

            return {
                utility: item.utilitynumber,
                program: item.progid,
                projectStatuses: Array.isArray(statusItem) ? statusItem.map((s) => s.wfnumber) : [statusItem.wfnumber],
            };
        });
    }

    return [
        {
            ...defaultProgramFilter,
        },
    ];
};

export const getStatusesFromProgramFilters = (programFilters) => {
    return programFilters.reduce((result, filter) => {
        return result.concat(filter.projectStatuses);
    }, []);
};

export const onFilterCreatePrompt = ({ instanceId, programFilters, filterFormRefs }) => {
    const close = () => {
        store.dispatch(modalClose());
    };

    const submit = (formData) => {
        onFilterCreate({
            instanceId,
            filterName: formData.filterName,
            programFilters,
        });

        close();
    };

    const promises = (filterFormRefs.current || []).map((ref) => submitByRefPromise(ref));

    // Continue only if all forms valid
    Promise.all(promises).then(
        (results) => {
            store.dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: "Create Filter",
                        modalIcon: "plus",
                        overlayClassName: "modal-styled",
                        className: "create-filter-modal modal-sm",
                        children: <FilterCreateForm onSubmit={submit} onCancel={close} programFilters={programFilters} />,
                        noFooter: true,
                    },
                })
            );
        },
        () => {}
    );
};

export const onFilterCreate = ({ instanceId, filterName, programFilters }) => {
    const statusItems = getStatusesFromProgramFilters(programFilters);

    store.dispatch(
        createResource({
            resourceName: "workcenter",
            body: {
                datasourceName: filterName,
                isDefault: false,
                allowshare: false,
                statusItems,
            },
            onComplete: (action) => {
                getFilters({
                    onComplete: () => {
                        const number = action.data && action.data.number;

                        if (number) {
                            store.dispatch(
                                dataSourceChanged({
                                    instanceId,
                                    dataSource: {
                                        datasourceName: filterName,
                                        datasourceNumber: number,
                                        isDefault: false,
                                        isShared: false,
                                    },
                                })
                            );
                        }
                    },
                });
            },
        })
    );
};

export const onFilterUpdatePrompt = ({ instanceId, activeDataSource, programFilters, filterFormRefs, onComplete }) => {
    const close = () => {
        store.dispatch(modalClose());
    };

    const submit = (formData) => {
        if (formData.saveAsNew) {
            onFilterCreate({
                instanceId,
                filterName: formData.filterName,
                programFilters,
            });
        } else {
            onFilterUpdate({
                instanceId,
                filterName: formData.filterName,
                programFilters,
                activeDataSource,
                onComplete,
            });
        }

        close();
    };

    const promises = (filterFormRefs.current || []).map((ref) => submitByRefPromise(ref));

    // Continue only if all forms valid
    Promise.all(promises).then(
        (results) => {
            store.dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: "Save Filter",
                        overlayClassName: "modal-styled",
                        className: "save-filter-modal modal-sm",
                        children: <FilterUpdateForm filterName={activeDataSource.datasourceName} onSubmit={submit} onCancel={close} />,
                        noFooter: true,
                    },
                })
            );
        },
        () => {}
    );
};

export const onFilterUpdate = ({ instanceId, filterName, activeDataSource, programFilters, onComplete }) => {
    const statusItems = getStatusesFromProgramFilters(programFilters);

    store.dispatch(
        updateResource({
            resourceName: "workcenter",
            resourceId: activeDataSource.datasourceNumber,
            body: {
                datasourceName: filterName,
                isDefault: activeDataSource.isDefault,
                allowshare: activeDataSource.isShared,
                statusItems,
            },
            onComplete: () => {
                getFilters({
                    onComplete: () => {
                        store.dispatch(
                            getResource({
                                resourceName: "workcenter",
                                resourceId: activeDataSource.datasourceNumber,
                                onComplete: () => {
                                    const userNumber = get(store.getState(), `user.userNumber`);
                                    const filters = (
                                        get(store.getState(), `resources.workcenter.itemsById[${userNumber}-workcenter]`) || []
                                    ).filter((i) => i.datasourceNumber === activeDataSource.datasourceNumber);

                                    if (filters && filters.length === 1) {
                                        store.dispatch(
                                            setWorkqueueActiveDataSource({
                                                instanceId,
                                                activeDataSource: filters[0],
                                            })
                                        );
                                        onComplete && onComplete();
                                    }
                                },
                            })
                        );
                    },
                });
            },
        })
    );
};

export const onFilterRemovePrompt = ({ instanceId, activeDataSource }) => {
    const onConfirm = () => {
        store.dispatch(
            deleteResource({
                resourceName: "workcenter",
                resourceId: activeDataSource.datasourceNumber,
                optimisticUpdate: {
                    key: "datasourceNumber",
                },
                onComplete: () => {
                    getFilters({
                        onComplete: () => {
                            store.dispatch(
                                dataSourceChanged({
                                    instanceId,
                                    dataSource: undefined,
                                })
                            );
                        },
                    });
                },
            })
        );
    };

    const message = (
        <p>
            Are you sure you would like to delete Filter <b>{activeDataSource.datasourceName}</b>?
        </p>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Filter",
                modalIcon: "delete-trash-empty",
                overlayClassName: "modal-styled",
                className: "delete-filter-confirmation-modal modal-sm",
                footerContentCenter: true,
                content: message,
                onConfirm: onConfirm,
            },
        })
    );
};

export const onFilterShare = ({ activeDataSource, programFilters }) => {
    const resourceDataPath = "data";
    const title = "Share Filter with Users";
    const submitButtonText = "Share";
    const addAllText = "Add All Users";
    const removeAllText = "Remove All Users";
    const modalIcon = "share-empty";
    const idKey = "userNumber";
    const nameKey = "userName";

    const resourceGetParams = {
        resourceName: "workcenterUserAssignments",
        key: activeDataSource.datasourceNumber,
        path: {
            datasourceNumber: activeDataSource.datasourceNumber,
        },
    };

    const onSelect = (userAssignments) => {
        const state = store.getState();
        const userNumber = get(state, `user.userNumber`);
        const isShared = userAssignments.length > 0;

        store.dispatch(
            updateResource({
                resourceName: "workcenter",
                resourceId: activeDataSource.datasourceNumber,
                body: {
                    datasourceName: activeDataSource.datasourceName,
                    isDefault: activeDataSource.isDefault,
                    allowshare: isShared,
                    statusItems: getStatusesFromProgramFilters(programFilters),
                },
                showSuccessNotification: false,
                onSuccess: () => {
                    store.dispatch(
                        updateResource({
                            ...resourceGetParams,
                            body: userAssignments.map((u) => u[idKey]),
                            onSuccess: () => {
                                store.dispatch(
                                    getResource({
                                        resourceName: "workcenter",
                                        key: `${userNumber}-workcenter`,
                                    })
                                );
                            },
                            successMessage: "Filter successfully shared with selected users",
                        })
                    );
                },
                onComplete: () => {
                    store.dispatch(
                        getResource({
                            resourceName: "workcenter",
                            resourceId: activeDataSource.datasourceNumber,
                        })
                    );
                },
            })
        );
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        submitButtonText,
        addAllText,
        removeAllText,
        modalIcon,
        idKey,
        nameKey,
        onSelect,
    });
};

export const getFilters = ({ onComplete } = {}) => {
    const state = store.getState();
    const userNumber = get(state, `user.userNumber`);

    store.dispatch(
        getResource({
            resourceName: "workcenter",
            key: `${userNumber}-workcenter`,
            onComplete,
        })
    );
};

export const setActiveDataSource = ({ instanceId, activeDataSource, dataSources }) => {
    if (!activeDataSource) {
        const dataSource = (dataSources || []).filter((s) => s.isDefault)[0];
        if (dataSource) {
            store.dispatch(
                setWorkqueueActiveDataSource({
                    instanceId,
                    activeDataSource: dataSource,
                })
            );
        } else {
            store.dispatch(
                setWorkqueueActiveDataSource({
                    instanceId,
                    activeDataSource: (dataSources || [])[0] || defaultDataSource,
                })
            );
        }
    }
};

export const setAsDefaultDataSource = ({ instanceId, dataSource }) => {
    store.dispatch(
        createResource({
            resourceName: "workcenterDefaultDataSource",
            path: {
                datasourceNumber: dataSource.datasourceNumber,
            },
            onError: () => {
                store.dispatch(
                    setWorkqueueActiveDataSource({
                        instanceId,
                        activeDataSource: {
                            ...dataSource,
                            isDefault: dataSource.isDefault,
                        },
                    })
                );
            },
            onSuccess: () => {
                getFilters();
            },
        })
    );

    store.dispatch(
        setWorkqueueActiveDataSource({
            instanceId,
            activeDataSource: {
                ...dataSource,
                isDefault: !dataSource.isDefault,
            },
        })
    );
};

export const transformFilterFormErrors = (errors) => {
    return errors.map((error) => {
        if (error.name === "pattern") {
            error.message = "Invalid Filter Name";
        }

        return error;
    });
};

export const useFilterList = ({ programFilters }) => {
    const dispatch = useDispatch();

    // Create unique utilities list
    const utilitiesList = useMemo(() => {
        return programFilters.reduce((result, filter) => {
            if (filter.utility && !result.includes(filter.utility)) {
                result.push(filter.utility);
            }

            return result;
        }, []);
    }, [programFilters]);

    // Take available utilities from store
    const utilities = useSelector((state) => {
        const list = state.resources.utilities.itemsById["utilities"] ?? [];

        return utilitiesList.map((utilityNumber) => list.find((i) => i.utilityNumber === utilityNumber)).filter((i) => i);
    });

    // Create flag to ensure request is done only once
    const isUtilitiesRequested = useRef(false);

    // Create flags to ensure request is completed
    const [isUtilitiesRequestCompleted, setIsUtilitiesRequestCompleted] = useState(false);

    // Load utilities if not available in store
    useEffect(() => {
        if (!isUtilitiesRequested.current && utilitiesList.length > utilities.length) {
            isUtilitiesRequested.current = true;

            dispatch(
                getResource({
                    resourceName: "utilities",
                    key: "utilities",
                    onComplete: () => {
                        setIsUtilitiesRequestCompleted(true);
                    },
                })
            );
        }
    }, [utilitiesList, utilities, dispatch]);

    // Create unique programs list
    const programList = useMemo(() => {
        return programFilters.reduce((result, filter) => {
            if (filter.program && !result.some((f) => f.programNumber === filter.program)) {
                result.push({
                    utilityNumber: filter.utility,
                    programNumber: filter.program,
                });
            }

            return result;
        }, []);
    }, [programFilters]);

    // Take available programs from store
    const programs = useSelector((state) => {
        return programList
            .map((item) =>
                (state.resources.programs.itemsById[item.utilityNumber]?.programList ?? []).find(
                    (i) => i.programNumber === item.programNumber
                )
            )
            .filter((i) => i);
    });

    // Create flags to ensure requests are done only once
    const isUtilityRequested = useRef(
        utilitiesList.reduce(
            (result, utilityNumber) =>
                (result = {
                    ...result,
                    [utilityNumber]: false,
                }),
            {}
        )
    );

    // Create flags to ensure requests are completed
    const [isUtilityRequestCompleted, setIsUtilityRequestCompleted] = useState(
        utilitiesList.reduce(
            (result, utilityNumber) =>
                (result = {
                    ...result,
                    [utilityNumber]: false,
                }),
            {}
        )
    );

    // Load programs if not available in store
    useEffect(() => {
        programList.forEach((item) => {
            const { utilityNumber } = item;
            const isAvailable = programs.some((i) => i.programNumber === item.programNumber);
            const isRequested = isUtilityRequested.current[utilityNumber];

            if (!isAvailable && !isRequested) {
                isUtilityRequested.current[utilityNumber] = true;

                dispatch(
                    getResource({
                        resourceName: "programs",
                        key: utilityNumber,
                        query: {
                            utilityNumber,
                        },
                        onComplete: () => {
                            setIsUtilityRequestCompleted((prevValue) => ({
                                ...prevValue,
                                [utilityNumber]: true,
                            }));
                        },
                    })
                );
            }
        });
    }, [programList, programs, dispatch]);

    // Create unique statuses list
    const statusList = useMemo(() => {
        return programFilters.reduce((result, filter) => {
            if (filter.program && !result.some((f) => f.programNumber === filter.program)) {
                result.push({
                    projectStatuses: filter.projectStatuses,
                    programNumber: filter.program,
                });
            }

            return result;
        }, []);
    }, [programFilters]);

    // Take available statuses from store
    const statuses = useSelector((state) => {
        return statusList
            .map((item) => {
                const list = state.resources.programWorkflowStatus.itemsById[item.programNumber];

                if (!list) {
                    return null;
                }

                return {
                    programNumber: item.programNumber,
                    text: item.projectStatuses
                        .map((workflowNumber) => list.find((i) => i.workflowNumber === workflowNumber))
                        .filter((i) => i)
                        .map((item) => item.status)
                        .join(", "),
                };
            })
            .filter((i) => i);
    });

    // Create flags to ensure requests are done only once
    const isStatusListRequested = useRef(
        statusList.reduce(
            (result, programNumber) =>
                (result = {
                    ...result,
                    [programNumber]: false,
                }),
            {}
        )
    );

    // Create flags to ensure requests are completed
    const [isStatusListRequestCompleted, setIsStatusListRequestCompleted] = useState(
        statusList.reduce(
            (result, programNumber) =>
                (result = {
                    ...result,
                    [programNumber]: false,
                }),
            {}
        )
    );

    // Load statuses if not available in store
    useEffect(() => {
        statusList.forEach((item) => {
            const { programNumber } = item;
            const isAvailable = statuses.some((i) => i.programNumber === programNumber);
            const isRequested = isStatusListRequested.current[programNumber];

            if (!isAvailable && !isRequested) {
                isStatusListRequested.current[programNumber] = true;

                dispatch(
                    getResource({
                        resourceName: "programWorkflowStatus",
                        key: programNumber,
                        path: {
                            programNumber,
                        },
                        onComplete: () => {
                            setIsStatusListRequestCompleted((prevValue) => ({
                                ...prevValue,
                                [programNumber]: true,
                            }));
                        },
                    })
                );
            }
        });
    }, [statusList, statuses, dispatch]);

    const getFilterData = (item) => {
        let utility = utilities.find((i) => i.utilityNumber === item.utility)?.utility;

        if (!utility) {
            utility = isUtilitiesRequestCompleted ? null : <WaitIcon />;
        }

        let program = programs.find((i) => i.programNumber === item.program)?.program;
        if (!program) {
            program = isUtilityRequestCompleted[item.utility] ? null : <WaitIcon />;
        }

        let projectStatuses = statuses.find((i) => i.programNumber === item.program)?.text;
        if (!projectStatuses) {
            projectStatuses = isStatusListRequestCompleted[item.program] ? null : <WaitIcon />;
        }

        return {
            utility,
            program,
            projectStatuses,
        };
    };

    // Populate filter list values
    return programFilters.map(getFilterData);
};
