import React from "react";
import { getPagingItems } from "store/globalSearch/utils";
import ExpandablePanel from "../ExpandablePanel";

export const ExpandableSearchResult = ({ title, pageNumber, pageSize, totalResults, expanded, children }) => {
    const { firstRecord, lastRecord } = getPagingItems({
        pageNumber,
        pageSize,
        totalResults,
    });

    const resultsText = totalResults + " result" + (totalResults === 1 ? "" : "s");
    const countInfo = firstRecord && lastRecord ? `${firstRecord} - ${lastRecord} from ${resultsText}` : resultsText;

    const panelTitle = (
        <>
            <div className="expandable-panel-title flex-one-in-row">{title}</div>
            <div className="expandable-panel-info">{countInfo}</div>
        </>
    );

    return (
        <ExpandablePanel expanded={expanded} className="expandable-search-result flex-row align-center" title={panelTitle}>
            {children}
        </ExpandablePanel>
    );
};
