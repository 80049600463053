import React from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { useProgramForms } from "../../../../store/resources/useResource";
import cn from "classnames";

import CopyToClipboard from "../../Label/CopyToClipboard";

const WindowHeaderForApplicationFormPages = (view) => {
    const formNumber = get(view, `view.props.formNumber`);
    const [programForm] = useProgramForms({ formNumber });

    if (!programForm) {
        return null;
    }

    return (
        <div className="window-header-content">
            <div
                className={cn("project-mark", {
                    "application-form-pages-view": view?.view?.component === "ApplicationFormPages",
                })}
            >
                <span>Application Form Pages</span>
            </div>
            <div className="project-number">
                <CopyToClipboard title="Copy Form Name to clipboard" successMessage="Form Name copied to clipboard">
                    {programForm.formName}
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default connect()(WindowHeaderForApplicationFormPages);
