import React, { useCallback, useRef } from "react";
import { cloneDeep, isEqual, isNumber } from "lodash";
import { addWidget, getRowName, setWidgetProperties } from "components/ui/PortalBuilder/utils";
import { closeModal, openConfirmModal } from "components/ui/Modal/utils";
import { RowProperty } from "./RowProperty";
import { PropertyListItemAdd } from "../PropertyList/PropertyListItemAdd";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

export const RowsProperty = (props) => {
    const { id, value = [], onChange, isTop } = props;
    const rows = value;

    // onPropertyChange sometimes has old value if not using this ref.
    // TODO: investigate why onChange somehow preserves old value.
    const rowsRef = useRef();
    rowsRef.current = rows;

    const onPropertyChange = useCallback(
        (index) =>
            (propertyId, propertyValue, additionalValues = []) => {
                const updatedValue = cloneDeep(rowsRef.current);
                updatedValue[index] = setWidgetProperties(updatedValue[index], [
                    { id: propertyId, value: propertyValue },
                    ...additionalValues,
                ]);

                onChange(id, updatedValue);
            },
        [id, onChange]
    );

    const customComponentIndex = value.findIndex((c) => c.type !== "Row");
    const { errors } = useErrorContext();

    const addedRowRef = useRef({});

    const onAdd = useCallback(() => {
        const index = rows?.length ?? 0;
        const updatedValue = addWidget(
            rows,
            WidgetType.ROW,
            isTop ? customComponentIndex : index,
            getRowName(
                1,
                rows?.map((v) => v.props)
            )
        );

        addedRowRef.current[isTop ? customComponentIndex : index] = updatedValue[isTop ? customComponentIndex : index];
        onChange(id, updatedValue);
    }, [customComponentIndex, id, isTop, onChange, rows]);

    const onRemove = useCallback(
        (index) => () => {
            const onConfirm = () => {
                const updatedValue = cloneDeep(rows);
                updatedValue.splice(index, 1);
                onChange(id, updatedValue);
            };

            function handleAfterOpen() {
                onkeydown = (e) => {
                    if (e.key === "Enter") {
                        onConfirm();
                        closeModal();
                    }
                };
            }

            const newRow = addedRowRef.current[index];
            const currentRow = rows[index];

            if (!newRow || !isEqual(newRow, currentRow)) {
                openConfirmModal({
                    title: "Delete Row",
                    modalIcon: "delete-trash-empty",
                    message: "Are you sure you want to delete the row?",
                    onConfirm: () => onConfirm(),
                    onAfterOpen: handleAfterOpen(),
                });
            } else {
                onConfirm();
            }
        },
        [id, onChange, rows]
    );

    const onClone = useCallback(
        (index) => () => {
            const list = cloneDeep(rows);
            list.push(rows[index]);
            onChange(id, list);
        },
        [rows, id, onChange]
    );

    const onChangeOrderUp = useCallback(
        (index) => {
            const list = cloneDeep(rows);
            const temp = list[index];
            list[index] = list[index - 1];
            list[index - 1] = temp;
            onChange(id, list);
        },
        [rows, id, onChange]
    );

    const onChangeOrderDown = useCallback(
        (index) => {
            const list = cloneDeep(rows);
            const temp = list[index];
            list[index] = list[index + 1];
            list[index + 1] = temp;
            onChange(id, list);
        },
        [rows, id, onChange]
    );

    if (props.hidden) {
        return null;
    }

    const addRowLabel = <PropertyListItemAdd title="Add Row" onAdd={onAdd} />;

    if (rows.length === 0) {
        return addRowLabel;
    }

    return (
        <>
            {rows.map((item, index) => {
                const hideItem =
                    item.type !== "Row" ||
                    (isNumber(customComponentIndex) && (isTop ? index > customComponentIndex : index < customComponentIndex));

                if (hideItem) {
                    return null;
                } else {
                    return (
                        <RowProperty
                            errors={errors}
                            index={index}
                            key={index}
                            value={item.props}
                            onRemove={onRemove(index)}
                            onChange={onPropertyChange(index)}
                            onClone={onClone(index)}
                            onChangeOrderUp={onChangeOrderUp}
                            onChangeOrderDown={onChangeOrderDown}
                            listLength={rows.length}
                        />
                    );
                }
            })}
            {addRowLabel}
        </>
    );
};
