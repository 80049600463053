import React, { useState, memo } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { downloadFile } from "../../../../../../store/files/actions";

import TagButton from "../../../../Button/TagButton";
import IconHandMade from "../../../../Icons/IconHandMade";
import WaitIcon from "../../../../WaitIcon";
import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";
import { useResource } from "store/resources/useResource";
import { FileSecurity, getFileExtension } from "components/utils/files";

const FilesTab = memo(({ applicationNumber }) => {
    const dispatch = useDispatch();

    const [openFolderIndices, setOpenFolderIndices] = useState([]);
    const toggleOpenFolder = (idx) => {
        const arrIdx = openFolderIndices.indexOf(idx);
        const newIndices = [...openFolderIndices];
        if (arrIdx > -1) {
            newIndices.splice(arrIdx, 1);
        } else {
            newIndices.push(idx);
        }
        setOpenFolderIndices(newIndices);
    };

    const [rows = [], isReading] = useResource({
        resourceName: "applicationFiles",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });

    const onDownload = (item) => {
        dispatch(
            downloadFile({
                fileId: item.fileId,
            })
        );
    };

    if (isReading) {
        return <WaitIcon />;
    }

    // group rows into folders
    const folders = [];
    rows.forEach((item) => {
        let folder = folders.find((f) => f.name === item.folder);
        if (!folder) {
            folder = {
                name: item.folder,
                items: [],
                open: false,
            };
            folders.push(folder);
        }
        folder.items.push(item);
    });

    // open folders
    openFolderIndices.forEach((idx) => (folders[idx].open = true));
    const renderItem = (item) => {
        return (
            <div key={item.fileId} className="item">
                <IconWrap
                    title={item.fileSecurity === FileSecurity.PUBLIC ? "Public" : "Private"}
                    icon={item.fileSecurity === FileSecurity.PUBLIC ? "add-group-filled" : "user-identity-person-filled"}
                    className="security-icon"
                />
                <IconHandMade iconDocumentHandMade title={getFileExtension(item.fileName).substring(0, 4)}></IconHandMade>
                <div className="item-header">{item.fileType}</div>
                {item.allowAccess ? (
                    <ActionLabel className="item-name" onClick={() => onDownload(item)}>
                        {item.fileName}
                    </ActionLabel>
                ) : (
                    <span title="You do not have access to this file">{item.fileName}</span>
                )}
                <div className="security-type">
                    <span>File Security:</span>
                    {item.fileAuthorizationGroup || "All Access"}
                </div>
                {item.tag && (
                    <div className="item-tags">
                        <TagButton readonly>{item.tag}</TagButton>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div className="sidebar-doc-tab files">
            {folders.map((folder, i) => (
                <div
                    key={i}
                    className={cn("file-folder", {
                        "file-folder--open": folder.open,
                    })}
                >
                    <div className="folder-name" onClick={() => toggleOpenFolder(i)}>
                        <IconWrap iconWrapBig icon={folder.open ? "folder-open-filled" : "folder"} /> {folder.name}
                    </div>

                    {folder.open && (
                        <div className="items">
                            <CustomList
                                limit={6}
                                items={folder.items}
                                renderItem={renderItem}
                                renderFooter={renderCustomFooter}
                            ></CustomList>
                        </div>
                    )}
                </div>
            ))}
            {rows.length === 0 && <NothingFoundBlock nothingFoundBlockSmall icon="files" title="No files" />}
        </div>
    );
});

export default FilesTab;
