import React, { useEffect, useCallback, useState, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import ClassNames from "classnames";

import IconWrap from "../../Icons";
import TabMain from "./TabMain";
import TabContent from "./TabContent";
import TabContentForProgram from "./TabContentForProgram";
import TabContentForUtility from "./TabContentForUtility";
import TabContentForProject from "./TabContentForProject";
import TabContentForApplicationFormPages from "./TabContentForApplicationFormPages";
import TabContentForGlobalSearch from "./TabContentForGlobalSearch";
import TabContentForInvoice from "./TabContentForInvoice";
import TabContentForContract from "./TabContentForContract";
import TabContentForBudget from "./TabContentForBudget";
import TabContentForCustomer from "./TabContentForCustomer";
import TabContentForPortalBuilderView from "./TabContentForPortalBuilderView";

const TabDetails = memo(({ containerName, viewName, style, side, onClick, onClose }) => {
    const viewsContainer = useSelector((state) => state.window[containerName]);
    const views = useMemo(() => viewsContainer?.views || [], [viewsContainer]);

    const view = useMemo(() => views.find((i) => i.name === viewName) || {}, [views, viewName]);

    const {
        leftViewTitle,
        rightViewTitle,
        leftViewName,
        rightViewName,
        leftViewComponent,
        rightViewComponent,
        leftViewProps,
        rightViewProps,
        className,
        leftViewClassName,
        rightViewClassName,
    } = view;

    const [viewData, setViewData] = useState({});

    const getViewData = useCallback(() => {
        let result = null;

        switch (side) {
            case "left":
                result = {
                    ...view,
                    title: leftViewTitle,
                    name: leftViewName,
                    component: leftViewComponent,
                    props: leftViewProps,
                    isLockedEntity: leftViewClassName && leftViewClassName.includes("entity-locked"),
                };
                break;

            case "right":
                result = {
                    ...view,
                    title: rightViewTitle,
                    name: rightViewName,
                    component: rightViewComponent,
                    props: rightViewProps,
                    isLockedEntity: rightViewClassName && rightViewClassName.includes("entity-locked"),
                };
                break;

            default:
                result = {
                    ...view,
                    isLockedEntity: className && className.includes("entity-locked"),
                };
                break;
        }

        return result;
    }, [
        leftViewTitle,
        rightViewTitle,
        leftViewName,
        rightViewName,
        leftViewComponent,
        rightViewComponent,
        leftViewProps,
        rightViewProps,
        className,
        leftViewClassName,
        rightViewClassName,
        view,
        side,
    ]);

    useEffect(() => {
        setViewData(getViewData());
    }, [view, side, getViewData]);

    const closeTab = useCallback(
        (event) => {
            event.stopPropagation();

            onClose(view.name);
        },
        [view, onClose]
    );

    let tabContent = null;

    switch (viewData.component) {
        case "ProjectView":
            tabContent = <TabContentForProject view={viewData} />;
            break;

        case "ProgramView":
            tabContent = <TabContentForProgram view={viewData} />;
            break;

        case "UtilityView":
            tabContent = <TabContentForUtility view={viewData} />;
            break;

        case "UtilityInvoiceView":
            tabContent = <TabContentForInvoice view={viewData} />;
            break;

        case "UtilityContractView":
            tabContent = <TabContentForContract view={viewData} />;
            break;

        case "UtilityBudgetView":
            tabContent = <TabContentForBudget view={viewData} />;
            break;

        case "HomeView":
            tabContent = <TabMain view={viewData} />;
            break;

        case "ApplicationFormPages":
            tabContent = <TabContentForApplicationFormPages view={viewData} />;
            break;

        case "PortalBuilderView":
            tabContent = <TabContentForPortalBuilderView view={viewData} />;
            break;

        case "GlobalSearchView":
            tabContent = <TabContentForGlobalSearch view={viewData} />;
            break;

        case "CustomerView":
            tabContent = <TabContentForCustomer view={viewData} />;
            break;

        default:
            tabContent = <TabContent view={viewData} />;
            break;
    }

    const tabClass = ClassNames("tab-details", {
        "tab-left": side === "left",
        "tab-right": side === "right",
        "entity-locked": viewData.isLockedEntity,
    });

    return (
        <div className={tabClass} onClick={() => onClick(viewData.name)} style={style}>
            {viewData.isLockedEntity && <IconWrap iconWrap="lock-encryption-empty" title="Locked" />}
            {tabContent}
            {onClose && <IconWrap iconWrapTransparent iconWrapClickable iconWrap="clear-close" title="Close" onClick={closeTab} />}
        </div>
    );
});

export default TabDetails;
