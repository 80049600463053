import React from "react";

import Button from "../../../../Button";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import GridSelectedItemsLabel from "../../../../DataGrid/GridSelectedItemsLabel";
import { isChildProgram } from "components/views/ProgramView/utils";

const Controls = ({ assignmentDisabled, selectedRowCount, onRowSelectClear, onBulkAssign, onAddCalculation, onExport, programNumber }) => (
    <div className="grid-controls calculations flex-row align-center">
        {selectedRowCount ? (
            <GridSelectedItemsLabel
                text={
                    selectedRowCount ? (
                        <>
                            {" "}
                            Calculation{selectedRowCount > 1 ? "s" : ""} <br /> selected{" "}
                        </>
                    ) : (
                        ""
                    )
                }
                count={selectedRowCount}
                onClear={onRowSelectClear}
            />
        ) : (
            <div className="bulk-assignment__label">
                Select <br /> Calculations
            </div>
        )}
        <Button primary onClick={onBulkAssign} disabled={assignmentDisabled || isChildProgram({ programNumber })}>
            Bulk Assignment
        </Button>
        <span className="flex-one" />
        <IconWithLabel
            disabled={isChildProgram({ programNumber })}
            title={isChildProgram({ programNumber }) ? undefined : "Add Catalog Calculation"}
            icon="plus"
            onClick={onAddCalculation}
        >
            Add Catalog Calculation
        </IconWithLabel>
        <IconWithLabel withHandMadeIcon onClick={onExport}>
            Export CSV
        </IconWithLabel>
    </div>
);

export default Controls;
