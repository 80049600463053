import * as actionTypes from "../actionTypes";
import { getInitialServerTimezoneOffset } from "components/utils/date";

const initialState = {
    systemStatus: false,
    systemStatusDateRestored: null,
    fileSystemStatus: false,
    fileSystemStatusDateRestored: null,
    heartbeatInterval: Number(process.env.REACT_APP_HEARTBEAT_INTERVAL),
    checked: false,
    pending: false,
    currentVersion: document.querySelector('meta[name="author"]')?.getAttribute("content").split("|")[0],
    currentVersionDate: document.querySelector('meta[name="author"]')?.getAttribute("content").split("|")[1],
    isLatestVersion: true,
    serverTimezoneOffset: null,
    apiErrorLogging: false,
};

export const reducer = (state, action) => {
    state = state ?? {
        ...initialState,
        serverTimezoneOffset: getInitialServerTimezoneOffset(),
    };

    switch (action.type) {
        case actionTypes.SYSTEM_STATUS_REQUEST:
            state = {
                ...state,
                pending: true,
            };
            break;
        case actionTypes.SYSTEM_STATUS_RECEIVE:
            state = {
                ...state,
                fileSystemStatus: action.data.fileSystemStatus,
                fileSystemStatusDateRestored: action.data.fileSystemStatusDateRestored,
                heartbeatInterval: state.heartbeatInterval > 0 ? action.data.heartbeatInterval : 0, // Do not update heartbeat interval from response if it is set to 0. If heartbeat interval is 0 then endpoint will be called only on page load.
                systemStatus: action.data.systemStatus,
                systemStatusDateRestored: action.data.systemStatusDateRestored,
                apiErrorLogging: action.data.apiErrorLogging,
                checked: true,
                pending: false,
            };
            break;
        case actionTypes.SYSTEM_STATUS_ERROR:
            state = {
                ...initialState,
                checked: true,
            };
            break;
        case actionTypes.HEARTBEAT_STOP:
            state = {
                ...state,
                pending: false,
            };
            break;
        case actionTypes.APP_VERSION_CHANGED:
            state = {
                ...state,
                isLatestVersion: false,
            };
            break;
        case actionTypes.SET_SERVER_TIMEZONE_OFFSET:
            state = {
                ...state,
                serverTimezoneOffset: action.serverTimezoneOffset,
            };
            break;
        default:
            break;
    }

    return state;
};
