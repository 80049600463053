import React, { useState, useCallback, memo } from "react";

import LoginFormWrap from "../../ui/LoginFormWrap";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import Captcha from "../../ui/Captcha";
import TextInput from "../../ui/Input/TextInput";

const ForgotPasswordForm = memo(({ userName, title, isLoading, infoMessage, onRedirectToLogin, onSubmit }) => {
    const [user, setUser] = useState(userName);
    const [recaptcha, setRecaptcha] = useState("");

    const isValid = useCallback(() => {
        return (user || "").trim().length > 0 && (recaptcha || "").trim().length > 0;
    }, [user, recaptcha]);

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();

            if (isValid()) {
                onSubmit({
                    userName: user,
                    recaptcha,
                });
            }
        },
        [user, recaptcha, isValid, onSubmit]
    );

    if (infoMessage) {
        return (
            <LoginFormWrap>
                <h3 className="text-center">Check your email</h3>
                <p className="text-center">We've just sent instructions for resetting your password.</p>
                <div className="login-form-link text-center" onClick={onRedirectToLogin}>
                    Return to login page
                </div>
            </LoginFormWrap>
        );
    }

    return (
        <LoginFormWrap>
            <Form onSubmit={handleSubmit}>
                <h3>{title}</h3>
                <div className="forgotPassword-block">
                    <p>No problem. Just enter your username below and we'll send you an email with instructions.</p>
                    <TextInput
                        label="Username"
                        name="userName"
                        placeholder="Enter your username"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                    />
                    <Captcha label="recaptcha" name="recaptcha" onChange={setRecaptcha} />
                    <div className="form-btn-wrap password">
                        <span className="login-form-link" onClick={onRedirectToLogin}>
                            Return to login page
                        </span>
                        <span className="flex-one"></span>
                        <Button type="submit" primary disabled={!isValid() || isLoading}>
                            {isLoading ? "Loading..." : "Request password"}
                        </Button>
                    </div>
                </div>
            </Form>
        </LoginFormWrap>
    );
});

export default ForgotPasswordForm;
