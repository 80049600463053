import React from "react";
import withDataGrid from "../../../ui/DataGrid/withDataGrid.js";
import { openProgramTab } from "store/window/openTabActions";
import DataGrid from "../../../ui/DataGrid";
import { allCatalogAttributesColumnKeys } from "../../../views/configureGrids";
import { useDispatch } from "react-redux";

const AllCatalogAttributesGrid = (props) => {
    const { gridId, dataGridConfig, filterRequired } = props;
    const procIdColumnKey = allCatalogAttributesColumnKeys.procIdColumnKey;
    const catNumberColumnKey = allCatalogAttributesColumnKeys.catNumberColumnKey;
    const utilityColumnKey = allCatalogAttributesColumnKeys.utilityColumnKey;
    const dispatch = useDispatch();

    const rowActions = [
        {
            name: "open",
            icon: "open-new-window",
            title: "Manage Program",
        },
    ];
    const onRowAction = (action) => {
        const { dataItem } = action;
        const programNumber = dataItem[procIdColumnKey];
        const catalogNumber = dataItem[catNumberColumnKey];
        const utilityNumber = dataItem[utilityColumnKey];
        const activePanel = "program-catalog";
        const autoOpen = true;
        dispatch(openProgramTab({ programNumber, activePanel, catalogNumber, utilityNumber, autoOpen }));
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            filterRequired={filterRequired}
        />
    );
};

export default withDataGrid("allCatalogAttributes", AllCatalogAttributesGrid);
