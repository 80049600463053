import cn from "classnames";
import SelectBox from "components/ui/SelectBox";
import { isEmpty } from "lodash";
import React, { memo } from "react";
import { useDefaultValue } from "../../utils";
import { isPropertyRequired } from "../../utils/validation";
import PropertyDescription from "./PropertyDescription";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyTitle } from "./PropertyTitle";

import "./SelectBoxProperty.scss";

export const SelectBoxProperty = memo((props) => {
    const {
        className,
        id,
        value,
        defaultValue,
        onChange,
        title,
        items = [],
        fullWidth,
        borderTop,
        borderBottom,
        validationRules,
        noPostfix,
        description,
        disabled,
    } = props;
    const required = isPropertyRequired(validationRules);

    useDefaultValue(id, value, defaultValue, onChange);

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className={cn("property-list-item--select-box", className)} borderTop={borderTop} borderBottom={borderBottom}>
            <div
                className={cn("property-list-item-inner", {
                    "flex-column full-width-property": fullWidth,
                    "flex-row align-center justify-space-between": !fullWidth,
                    "empty-items": isEmpty(items),
                })}
            >
                {title && <PropertyTitle title={title} required={required} noPostfix={noPostfix} />}
                {!isEmpty(items) && (
                    <div className={cn("flex-row", { "flex-wrap heading-widget": id === "level" })}>
                        {items.map((i, index) => {
                            return (
                                <SelectBox
                                    key={index}
                                    selectBoxTitle={i.tooltip}
                                    selectBoxText={i.text}
                                    icon={i.icon}
                                    selectBoxSelected={i.value === value}
                                    onClick={i.blocked || i.value === value ? undefined : () => onChange(id, i.value)}
                                    blocked={i.blocked}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </div>
                )}
                <PropertyDescription id={id} value={value} description={description} />
            </div>
        </PropertyListItem>
    );
});
