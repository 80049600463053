// Inspiration from: https://gomakethings.com/detecting-when-a-visitor-has-stopped-scrolling-with-vanilla-javascript/
export const scrollEnd = (element, callback) => {
    let isScrolling;

    const scrollHandler = (event) => {
        clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
            element.removeEventListener("scroll", scrollHandler, false);
            callback();
        }, 50);
    };

    element.addEventListener("scroll", scrollHandler, false);
};

// https://stackoverflow.com/a/42543908
export const getScrollParent = (element, includeHidden) => {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === "absolute";
    var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    for (var parent = element; (parent = parent.parentElement); ) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
};
