import React, { useEffect, useState, useMemo, memo } from "react";
import { useDispatch } from "react-redux";

import { cisSearchType } from "../../utils/constants";
import { createResource } from "../../../store/resources/actions";
import { getCisCustomerDetailsResourceParams } from "../../../store/configureResources";
import { getInfoListItemKey, getInfoListItemValue } from "./utils";

import WaitIcon from "../WaitIcon";
import NothingFoundBlock from "../NothingFoundBlock";
import RawHtml from "../RawHtml";
import InfoList from "../List/InfoList";

const CIS = memo(({ utilityNumber, contact, searchType }) => {
    const dispatch = useDispatch();

    const [customerDetails, setCustomerDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isInvalid = useMemo(() => {
        return (
            (searchType === cisSearchType.AccountNumber && !contact?.acct_number) ||
            (searchType === cisSearchType.PremiseID && !contact?.premiseid) ||
            (searchType === cisSearchType.AccountAndPremise && (!contact?.acct_number || !contact?.premiseid)) ||
            (searchType === cisSearchType.AccountAndMeter && (!contact?.acct_number || !contact?.meterid))
        );
    }, [searchType, contact]);

    const getRequiredText = useMemo(() => {
        if (searchType === cisSearchType.AccountNumber) {
            return "Account # is required";
        } else if (searchType === cisSearchType.PremiseID) {
            return "Premise # is required";
        } else if (searchType === cisSearchType.AccountAndPremise) {
            return "Account # and Premise # are required";
        } else if (searchType === cisSearchType.AccountAndMeter) {
            return "Account # and Meter # are required";
        }
    }, [searchType]);

    useEffect(() => {
        if (!isInvalid) {
            setIsLoading(true);

            dispatch(
                createResource({
                    ...getCisCustomerDetailsResourceParams({
                        utilityNumber,
                        accountNumber: contact?.acct_number,
                        premiseNumber: contact?.premiseid,
                        meterID: contact?.meterid,
                        searchType,
                    }),
                    onSuccess: (action) => {
                        setCustomerDetails(action.data);
                    },
                    onComplete: () => {
                        setIsLoading(false);
                    },
                })
            );
        }
    }, [utilityNumber, contact, searchType, isInvalid, dispatch]);

    if (isInvalid) {
        return (
            <>
                <p>There is not enough information for this contact to verify CIS:</p>
                <p>{getRequiredText}</p>
            </>
        );
    }

    if (isLoading) {
        return <WaitIcon />;
    }

    if (!isLoading && (!customerDetails || !customerDetails.custInfoList || customerDetails.custInfoList.length === 0)) {
        return <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockNoBorder icon="library-empty" title="Information not found." />;
    }

    return (
        <div className="cis_details">
            <InfoList
                items={customerDetails?.custInfoList?.map((i, index) => ({
                    label: getInfoListItemKey(i),
                    // this HTML shouldn't contain anything more than text and <br/> tags
                    value: <RawHtml>{getInfoListItemValue(i) ?? "-"}</RawHtml>,
                }))}
            />
        </div>
    );
});

export default CIS;
