import React, { useMemo, memo } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Workflow from "../../../../Workflow";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { getFastTagsResourceParams, getUtilitiesWorkflowAssignmentsResourceParams } from "../../../../../../store/configureResources";

import "./style.scss";

const WorkflowPanelContainer = ({ panel, workflow }) => {
    const {
        data: { utilityNumber, contractNumber },
    } = panel;

    const workflowGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesContractsWorkflowDetails",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
            },
        }),
        [utilityNumber, contractNumber]
    );

    const statesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStates",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const stepTypesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStepTypes",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const stepAttributeTypesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStepAttributeTypes",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const assignmentsGetResourceOptions = useMemo(
        () => ({
            ...getUtilitiesWorkflowAssignmentsResourceParams({
                utilityNumber,
                contractNumber,
            }),
        }),
        [utilityNumber, contractNumber]
    );

    const workflowStatusGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesContractsWorkflowStatuses",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
            },
        }),
        [utilityNumber, contractNumber]
    );
    const fastTagsGetResourceOptions = useMemo(
        () => getFastTagsResourceParams({ entityNumber: contractNumber, entityType: "invoicedocument" }),
        [contractNumber]
    );
    return (
        <div className="panel contract-workflow flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Workflow
                workflow={workflow}
                flagWorkflowReference={referenceTypes.invoiceFlagWorkflow}
                workflowGetResourceOptions={workflowGetResourceOptions}
                statesGetResourceOptions={statesGetResourceOptions}
                stepTypesGetResourceOptions={stepTypesGetResourceOptions}
                stepAttributeTypesGetResourceOptions={stepAttributeTypesGetResourceOptions}
                assignmentsGetResourceOptions={assignmentsGetResourceOptions}
                workflowStatusGetResourceOptions={workflowStatusGetResourceOptions}
                entityId={utilityNumber}
                entityIdType="utility"
                fastTagsGetResourceOptions={fastTagsGetResourceOptions}
            />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { panel } = ownProps;
    const {
        data: { contractNumber },
    } = panel;

    return {
        workflow: get(state, `resources.utilitiesContractsWorkflowDetails.itemsById[${contractNumber}]`),
    };
};

export default memo(connect(mapStateToProps)(WorkflowPanelContainer));
