import React from "react";
import { get } from "lodash";
import { useBudgetLine } from "../../../../store/resources/useResource";

import CopyToClipboard from "../../Label/CopyToClipboard";

const WindowHeaderForUtilityBudget = ({ view }) => {
    const utilityNumber = get(view, `props.utilityNumber`);
    const contractNumber = get(view, `props.contractNumber`);
    const budgetLineNumber = get(view, `props.budgetLineNumber`);

    const [budgetLine] = useBudgetLine({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
    });

    if (!budgetLine) {
        return null;
    }

    return (
        <div className="window-header-content">
            <div className="project-mark">
                <span>Budget</span>
            </div>
            <div className="project-number">
                <CopyToClipboard title="Copy Budget Name to clipboard" successMessage="Budget Name copied to clipboard">
                    {budgetLine.budgetName}
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default WindowHeaderForUtilityBudget;
