import React from "react";
import { get } from "lodash";

import { deleteResource, createResource } from "../../../../../store/resources/actions";
import { modalOpen } from "../../../../../store/modal/actions";
import { openInvoiceTab } from "../../../../utils/window";
import { store } from "../../../../../store/configureStore";

import "./InvoiceCommonStyles.scss";

import CostCodeValueWidget from "./InvoiceItemsPanel/CostCodeValueWidget";
import { refreshInvoiceDocumentsGrid, refreshContractDetailsInvoiceDocumentsGrid } from "../../../../../store/dataGrid/refreshGrid";

export const getInvoiceItemsGridId = ({ documentNumber }) => {
    return `${documentNumber}-invoice-items-grid`;
};

export const getInvoiceNotesGridId = ({ documentNumber }) => {
    return `${documentNumber}-notes-grid`;
};

export const getInvoiceDocumentHistoryGridId = ({ documentNumber }) => {
    return `${documentNumber}-invoice-document-history-grid`;
};

export const getInvoiceItemHistoryGridId = ({ documentNumber }) => {
    return `${documentNumber}-invoice-item-history-grid`;
};

export const getInvoiceFilesGridId = ({ documentNumber }) => {
    return `${documentNumber}-invoice-files-grid`;
};

export const onDeleteInvoiceItem = ({ utilityNumber, contractNumber, documentNumber, dataItem, onRefresh }) => {
    const message = (
        <>
            <p>This action will permanently delete this invoice item.</p>
            <p>Once completed, this action cannot be undone.</p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Invoice Item",
                overlayClassName: "modal-styled",
                className: "delete-invoice-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: message,
                footerContentCenter: true,
                onConfirm: () => {
                    store.dispatch(
                        deleteResource({
                            resourceName: "utilitiesContractsInvoiceDocumentsInvoices",
                            resourceId: dataItem.invoiceNumber,
                            path: {
                                utilityNumber,
                                contractNumber,
                                documentNumber,
                            },
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};

export const onCloneInvoiceDocument = ({ utilityNumber, contractNumber, documentNumber, vendor }) => {
    const message = (
        <>
            <p>This action will create a new invoice document and copy all invoice items that exist under the current invoice document.</p>
            <p>All monetary values will be changed to zero and all dates will be nulled.</p>
            <p>
                <b>Note:</b> Files WILL NOT be copied. Any new files required for the new copy of the invoice must be uploaded again.
            </p>
        </>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Copy Invoice",
                overlayClassName: "modal-styled",
                className: "copy-invoice-confirmation-modal modal-sm",
                modalIcon: "copy-empty",
                content: message,
                okButtonText: "Copy Invoice",
                cancelButtonText: "Cancel",
                footerContentCenter: true,
                onConfirm: () => {
                    store.dispatch(
                        createResource({
                            resourceName: "utilitiesContractsInvoiceDocumentsClone",
                            path: {
                                utilityNumber,
                                contractNumber,
                                documentNumber,
                            },
                            onSuccess: (action) => {
                                openInvoiceTab({
                                    documentNumber: get(action, "data.invoiceNumber"),
                                });
                                refreshInvoiceDocumentsGrid({ contractNumber });
                                refreshContractDetailsInvoiceDocumentsGrid({
                                    contractNumber,
                                });
                            },
                        })
                    );
                },
            },
        })
    );
};

export const getInvoiceItemFormSchema = ({ withCostCode, costCodeRequired }) => {
    const costCodeValueSchema = withCostCode
        ? {
              blItemList: {
                  type: "string",
                  title: "Cost Code Value",
              },
          }
        : {};

    const required = (costCodeRequired ? ["blItemList"] : []).concat(["refId", "itemName", "billingDate", "amountAuthorized"]);

    return {
        type: "object",
        required,
        properties: {
            ...costCodeValueSchema,
            refId: {
                type: "string",
                title: "Ref ID",
            },
            itemCode: {
                type: "string",
                title: "Item Code",
            },
            itemName: {
                type: "string",
                title: "Item Name",
            },
            itemDescription: {
                type: "string",
                title: "Item Description",
            },
            itemNumber: {
                type: "string",
                title: "Item Number",
            },
            billingDate: {
                type: "string",
                title: "Billing Date",
            },
            dueDate: {
                type: "string",
                title: "Due Date",
            },
            invoiceStartDate: {
                type: "string",
                title: "Invoice Start Date",
            },
            invoiceEndDate: {
                type: "string",
                title: "Invoice End Date",
            },
            amountDue: {
                type: "number",
                title: "Amount Due",
            },
            amountRequested: {
                type: "number",
                title: "Amount Requested",
            },
            amountAuthorized: {
                type: "number",
                title: "Amount Authorized",
            },
        },
    };
};

export const getInvoiceItemFormUiSchema = ({ withCostCode, budgetLookups }) => {
    const costCodeValueUiSchema = withCostCode
        ? {
              blItemList: {
                  "ui:widget": (props) => <CostCodeValueWidget {...props} budgetLookups={budgetLookups} />,
                  "ui:help": budgetLookups.length > 0 ? "Please select all cost code values before submitting" : undefined,
              },
          }
        : {};

    return {
        ...costCodeValueUiSchema,
        billingDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        dueDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        invoiceStartDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        invoiceEndDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
    };
};
