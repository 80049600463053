import { useResource } from "store/resources/useResource";
import { availableGrids, programsAttributesGridColumnKeys } from "components/views/configureGrids";

const usePortalWizardSteps = ({ programNumber }) => {
    return useResource({
        resourceName: "grid",
        resourceId: availableGrids.programsAttributes,
        key: `${programNumber}-attributes`,
        query: {
            searchAttr: `${programsAttributesGridColumnKeys.programNumber}=${programNumber}|${programsAttributesGridColumnKeys.attributeType}=Portal Wizard Step Indicator`,
            pageNum: 1,
            recsPerPage: 7000,
            returnType: "JSON",
        },
        forced: false,
        transform: (data) => {
            return data?.grid?.rows?.[programsAttributesGridColumnKeys.attributeValue]?.split("|").map((step) => ({
                title: step,
                enum: [step],
            }));
        },
    });
};

export default usePortalWizardSteps;
