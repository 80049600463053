import React, { useId, memo } from "react";
import cn from "classnames";

import IconWithLabel from "../Icons/IconWithLabel";
import IconWrap from "../Icons";
import WaitIcon from "../WaitIcon";
import ScrollControls from "../ScrollControls";

import "./SubMenu.scss";

export const SubMenu = memo(({ items, active = {}, isLoading, menuSettings, onClick, onOpenInTab }) => {
    const targetId = useId();

    const onKeyDown = (item) => (event) => {
        if (event.key === "Enter") {
            onClick(item);
        }
    };

    return (
        <div
            className={cn("submenu", {
                "submenu-with-settings": menuSettings,
            })}
        >
            <div className="submenu-placeholder"></div>
            <div className="main-grid-wrap responsive">
                <div
                    className={cn("submenu-row flex-row align-center", {
                        "submenu--loading": isLoading,
                    })}
                >
                    {isLoading ? (
                        <WaitIcon />
                    ) : (
                        <>
                            <div className="submenu-item-list" id={targetId}>
                                {items.map((item) => (
                                    <div
                                        key={item.id}
                                        className={"submenu-item" + ((active || {}).id === item.id ? " active" : "")}
                                        tabIndex="0"
                                        onKeyDown={onKeyDown(item)}
                                        onClick={() => onClick(item)}
                                    >
                                        <IconWithLabel iconWrapTransparent iconWithLabel={item.icon}>
                                            {item.text}
                                        </IconWithLabel>
                                        <IconWrap
                                            iconWrapClickable
                                            iconWrapTransparent
                                            title="Open page in the new tab"
                                            iconWrap="open-new-window"
                                            onClick={() => onOpenInTab(item)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <ScrollControls targetId={targetId} />
                            {menuSettings}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
