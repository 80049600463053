import React, { useCallback, useMemo, memo, useRef } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { windowAdd as windowAddAction } from "../../../../../store/window/actions";
import { windowContainerTypes } from "../../../../utils/window";
import Checkbox from "../../../../ui/Input/Checkbox";
import { formatJsonDateTime } from "../../../../utils/date";
import { useAutoSize } from "components/utils/autosize";

import "./DocumentRow.scss";

const DOCUMENT_ROW_PADDING = 10;

const DocumentRowContainer = memo(
    ({
        index,
        applicationNumber,
        checked,
        projectNumber,
        document,
        contactFirstName,
        contactLastName,
        received,
        style,
        withoutSelection,
        isSelectionDisabled,
        isBatchProcessing,
        isBatchFilled,
        onSelectDocument,
        windowAdd,
    }) => {
        // Content container for minimum size calculation
        const contentRef = useRef();

        // Content size updater
        useAutoSize(contentRef, index, DOCUMENT_ROW_PADDING, true);

        const contact = useMemo(() => {
            if (contactFirstName && contactLastName) {
                return `${contactFirstName} ${contactLastName}`;
            }

            return null;
        }, [contactFirstName, contactLastName]);

        const handleClickOpenProject = useCallback(
            (e) => {
                e.stopPropagation();
                windowAdd({
                    containerName: windowContainerTypes.Root,
                    name: "A: #" + applicationNumber,
                    component: "ProjectView",
                    header: "A: #" + applicationNumber,
                    close: true,
                    activate: true,
                    props: {
                        applicationNumber,
                    },
                });
            },
            [applicationNumber, windowAdd]
        );

        return (
            <div
                ref={contentRef}
                className={cn("application-document-queue__grid-document-row flex-row align-center", {
                    "application-document-queue__grid-document-row--without-selection": withoutSelection,
                    "application-document-queue__grid-document-row--selected": checked,
                })}
                style={style}
            >
                {!withoutSelection && (
                    <Checkbox
                        labelIconBig
                        iconLabelEmpty
                        checked={checked}
                        disabled={(isBatchFilled && !checked) || isSelectionDisabled || isBatchProcessing}
                        onChange={onSelectDocument}
                    ></Checkbox>
                )}
                <div
                    className="application-document-queue__grid-document-row-field application-document-queue__grid-document-row-field--link"
                    onClick={handleClickOpenProject}
                >
                    {projectNumber}
                </div>
                <div className="application-document-queue__grid-document-row-field application-document-queue__grid-document-row-field--document">
                    {document}
                </div>
                <div className="application-document-queue__grid-document-row-field application-document-queue__grid-document-row-field--contact">
                    {contact}
                </div>
                <div className="application-document-queue__grid-document-row-field application-document-queue__grid-document-row-field--date">
                    {formatJsonDateTime(received)}
                </div>
            </div>
        );
    }
);

export const DocumentRow = connect(null, { windowAdd: windowAddAction })(DocumentRowContainer);
