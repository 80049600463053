import React, { useState } from "react";
import SuccessfulLogins from "./SuccessfulLoginsPanel";
import PasswordChanges from "./PasswordChangesPanel";
import PasswordResetRequests from "./PasswordResetPanel";
import FailedLoginAttempts from "./FailedLoginPanel";
import AccountAudit from "./AccountAuditPanel";
import TabList from "components/ui/List/TabList";
import Separator from "components/ui/Separator";
import Button from "components/ui/Button";
import Breadcrumbs from "components/ui/Headers/Breadcrumbs";
import { useResource } from "store/resources/useResource";
import { SupplementalDataBlock } from "./SupplementalDataBlock";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import WaitIcon from "components/ui/WaitIcon";
import { isNil } from "lodash";

import "./style.scss";

const tabs = [
    {
        id: "successful-logins",
        title: "Successful Logins",
    },
    {
        id: "password-changes",
        title: "Password Changes",
    },
    {
        id: "password-reset-requests",
        title: "Password Reset Requests",
    },
    {
        id: "failed-login-attempts",
        title: "Failed Login Attempts",
    },
    {
        id: "account-audit",
        title: "Account Audit",
    },
];

const UserAudit = (props) => {
    const { hidden, user, onClose } = props;
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const { name, userNumber } = user;
    const [userSupplementalData, isLoading] = useResource({
        resourceName: "userSupplementData",
        key: userNumber,
        path: {
            userNumber: userNumber,
        },
        forced: true,
    });

    if (hidden) {
        return null;
    }

    if (isLoading && isNil(userSupplementalData)) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return (
        <>
            <div className="user-audit-panel main-grid-wrap responsive no-scroll flex-column flex-grow">
                <div className="flex-row">
                    <Breadcrumbs title="Manage Users" childTitle={`User Account Audit - ${name} (${userNumber})`} onClick={onClose} />
                </div>
                {userSupplementalData && <SupplementalDataBlock props={userSupplementalData} />}
                <TabList items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                {activeTab.id === "successful-logins" && <SuccessfulLogins userNumber={userNumber} />}
                {activeTab.id === "password-changes" && <PasswordChanges userNumber={userNumber} />}
                {activeTab.id === "password-reset-requests" && <PasswordResetRequests userNumber={userNumber} />}
                {activeTab.id === "failed-login-attempts" && <FailedLoginAttempts userNumber={userNumber} />}
                {activeTab.id === "account-audit" && <AccountAudit userNumber={userNumber} />}
                <Separator />
            </div>
            <div className="user-audit-footer flex-row">
                <Button icon="shevron-small-left" onClick={onClose}>
                    Back to user list
                </Button>
            </div>
        </>
    );
};

export default UserAudit;
