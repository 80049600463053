import React, { memo } from "react";
import { getContactType } from "./ContactsUtils";
import { openProjectTab } from "components/utils/window";

import IconWrap from "../../../../Icons";
import IconWithLabel from "../../../../Icons/IconWithLabel";

const ContactHeaderSection = memo(
    ({ canSearchCIS, contact, isPremise, isPrimary, isContractor, sameAsPrimary, onCisVerify, onCisStored, applicationNumber }) => {
        const { hascis, cmpAppId } = contact ?? {};
        const contactType = getContactType({
            contact,
            isPremise,
            isPrimary,
            isContractor,
        });

        return (
            <div className="flex-row row header-row">
                <div className="contact-row-item type">{contactType}</div>
                {contact && isPremise && (
                    <div className="contact-row-item cis-verification flex-column">
                        {hascis && (
                            <IconWithLabel icon="library-empty" disabled={!canSearchCIS} onClick={onCisStored}>
                                Stored CIS
                            </IconWithLabel>
                        )}
                        <IconWithLabel icon="library-empty" disabled={!canSearchCIS} onClick={onCisVerify}>
                            Verify CIS
                        </IconWithLabel>
                    </div>
                )}
                {contact && cmpAppId && cmpAppId !== applicationNumber && (
                    <div className="contact-row-item view-contractor flex-column">
                        <IconWithLabel
                            title="View CMP Application"
                            icon="eye-visibility-empty"
                            onClick={() => openProjectTab({ applicationNumber: cmpAppId })}
                        >
                            View
                        </IconWithLabel>
                    </div>
                )}
                {sameAsPrimary && (
                    <div className="contact-row-item equal">
                        <IconWrap icon="pause-filled" title="Same as Primary" />
                    </div>
                )}
            </div>
        );
    }
);

export default ContactHeaderSection;
