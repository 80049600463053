import React from "react";

import TabList from "../../../ui/List/TabList";
import ExpandablePanel from "../../../ui/ExpandablePanel";
import IconWrap from "../../../ui/Icons";
import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import FieldGroupDropdownIcon from "../../../ui/FieldGroupDropdownIcon";
import UploadFile from "../../../ui/UploadFile";
import TilesWrap from "../../../ui/TilesWrap";
import TileItem from "../../../ui/TilesWrap/TileItem";
import SelectBox from "../../../ui/SelectBox";
import AddNewItemPanel from "../../../ui/AddNewItemPanel";
import NothingFoundBlock from "../../../ui/NothingFoundBlock";

class Additional extends React.Component {
    constructor(props) {
        super(props);

        this.tabs = [
            {
                id: "item",
                title: "Item",
            },
            {
                id: "name",
                title: "Name",
            },
        ];

        this.state = {
            activeTab: this.tabs[0],
            activeTab2: this.tabs[0],
            activeTab3: this.tabs[0],
            isActive: false,
            isActive2: true,
            isActive3: false,
            isActive4: false,
            isActive5: true,
            isActive6: false,
            isActive7: false,
            isActive8: true,
            isActive9: true,
            isActive10: true,
            isActive11: true,
            isActive12: true,
            isActive13: true,
            isActive14: true,
            isActive15: false,
            isActive16: false,
            isActive17: false,
            isActive18: false,
            isActive19: false,
            isActive20: false,
            isActive21: false,
            DropdownListItems: [
                {
                    label: "Workflow",
                    icon: "fiber-smart-record-filled",
                    checked: true,
                },
                {
                    label: "Docs",
                    icon: "fiber-smart-record-filled",
                    checked: true,
                },
                {
                    label: "Audit",
                    icon: "fiber-smart-record-empty",
                    checked: false,
                },
                {
                    label: "Location",
                    icon: "fiber-smart-record-filled",
                    checked: true,
                },
            ],
            DropdownListItems2: [
                {
                    label: "Profile",
                    icon: "user-identity-person-empty",
                    selected: false,
                },
                {
                    label: "Log out",
                    icon: "move",
                    selected: false,
                },
            ],
            showOptionalText: false,
        };
    }
    showOptionalText = () => {
        return this.state.isActive17 ? "Hide more details" : "Show more details";
    };
    render() {
        return (
            <div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon Actions</div>
                        <div className="row">
                            <IconWrap
                                iconWrapClickable
                                iconWrapBig
                                iconWrapActive={this.state.isActive3}
                                iconWrap={this.state.isActive3 ? "pin-filled" : "pin-empty"}
                                title="Pin"
                                onClick={() =>
                                    this.setState({
                                        isActive3: !this.state.isActive3,
                                    })
                                }
                            ></IconWrap>
                            <IconWrap
                                iconWrapClickable
                                iconWrapBig
                                disabled
                                iconWrapActive={this.state.isActive4}
                                iconWrap={this.state.isActive4 ? "pin-filled" : "pin-empty"}
                                title="Pin"
                                onClick={() =>
                                    this.setState({
                                        isActive4: !this.state.isActive4,
                                    })
                                }
                            ></IconWrap>
                            <IconWrap
                                iconWrapClickable
                                iconWrapBig
                                iconWrapActive={this.state.isActive5}
                                iconWrap={this.state.isActive5 ? "pin-filled" : "pin-empty"}
                                title="Pin"
                                onClick={() =>
                                    this.setState({
                                        isActive5: !this.state.isActive5,
                                    })
                                }
                            ></IconWrap>
                        </div>
                        <div className="row">
                            <IconWrap
                                iconWrapClickable
                                iconWrapActive={this.state.isActive6}
                                iconWrap={this.state.isActive6 ? "delete-trash-filled" : "delete-trash-empty"}
                                title="Delete"
                                onClick={() =>
                                    this.setState({
                                        isActive6: !this.state.isActive6,
                                    })
                                }
                            ></IconWrap>
                            <IconWrap
                                disabled
                                iconWrapActive={this.state.isActive7}
                                iconWrap={this.state.isActive7 ? "delete-trash-filled" : "delete-trash-empty"}
                                title="Delete"
                                onClick={() =>
                                    this.setState({
                                        isActive7: !this.state.isActive7,
                                    })
                                }
                            ></IconWrap>
                            <IconWrap
                                iconWrapClickable
                                iconWrapActive={this.state.isActive8}
                                iconWrap={this.state.isActive8 ? "delete-trash-filled" : "delete-trash-empty"}
                                title="Delete"
                                onClick={() =>
                                    this.setState({
                                        isActive8: !this.state.isActive8,
                                    })
                                }
                            ></IconWrap>
                        </div>
                        <div className="row">
                            <IconWrap
                                iconWrapClickable
                                iconWrapCombined
                                iconWrap={this.state.isActive19 ? "bookmark-filled remove" : "bookmark-empty add"}
                                title={this.state.isActive19 ? "Remove from Bookmarks" : "Add to Bookmarks"}
                                onClick={() =>
                                    this.setState({
                                        isActive19: !this.state.isActive19,
                                    })
                                }
                            ></IconWrap>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon</div>
                        <div className="row">
                            <IconWrap iconWrapBig iconWrap="pin-empty"></IconWrap>
                            <IconWrap iconWrap="pin-empty"></IconWrap>
                            <IconWrap iconWrapSmall iconWrap="pin-empty"></IconWrap>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon Dropdown</div>
                        <div className="row">
                            <FieldGroupDropdownIcon
                                dropdownRight
                                dropdownOptions
                                iconWrapDropdown
                                iconWrapBig
                                withTitle={
                                    <>
                                        <span>{"Widgets Control Center"}</span>
                                        <IconWrap
                                            icon="settings-filled"
                                            onClick={() =>
                                                this.setState({
                                                    isActive15: !this.state.isActive15,
                                                })
                                            }
                                        />
                                    </>
                                }
                                mobileHeader="Widgets Control Center"
                                visible={this.state.isActive15}
                                iconWrapActive={this.state.isActive15}
                                iconWrap={this.state.isActive15 ? "settings-filled" : "settings-empty"}
                                onClick={() =>
                                    this.setState({
                                        isActive15: !this.state.isActive15,
                                    })
                                }
                                items={this.state.DropdownListItems}
                            ></FieldGroupDropdownIcon>
                        </div>
                        <div className="row">
                            <FieldGroupDropdownIcon
                                mobileHeader="Choose where to go"
                                dropdownOptions
                                visible={this.state.isActive16}
                                iconWrapActive={this.state.isActive16}
                                iconWrapWhite
                                iconWrap={this.state.isActive16 ? "user-identity-person-filled" : "user-identity-person-empty"}
                                onClick={() =>
                                    this.setState({
                                        isActive16: !this.state.isActive16,
                                    })
                                }
                                items={this.state.DropdownListItems2}
                            ></FieldGroupDropdownIcon>
                        </div>
                        <div className="row">
                            <FieldGroupDropdownIcon
                                mobileHeader="Choose where to go"
                                dropdownOptions
                                visible={this.state.isActive21}
                                iconWrapActive={this.state.isActive21}
                                iconWrapWhite
                                iconWrapRoundedSquare
                                iconWrap={this.state.isActive21 ? "user-identity-person-filled" : "user-identity-person-empty"}
                                onClick={() =>
                                    this.setState({
                                        isActive21: !this.state.isActive21,
                                    })
                                }
                                items={this.state.DropdownListItems2}
                            ></FieldGroupDropdownIcon>
                        </div>
                        <div className="row">
                            <FieldGroupDropdownIcon
                                mobileHeader="Choose where to go"
                                dropdownOptions
                                visible={this.state.isActive20}
                                iconWrapActive={this.state.isActive20}
                                iconWrapWhiteInverse
                                iconWrap={this.state.isActive20 ? "user-identity-person-filled" : "user-identity-person-empty"}
                                onClick={() =>
                                    this.setState({
                                        isActive20: !this.state.isActive20,
                                    })
                                }
                                items={this.state.DropdownListItems2}
                            ></FieldGroupDropdownIcon>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon Text Actions</div>
                        <div className="flexbox-row">
                            <div className="flexbox-col">
                                <div className="row">
                                    <IconWithLabel
                                        iconWithLabel={this.state.isActive9 ? "alert-add-empty" : "notification-off-filled"}
                                        active={this.state.isActive9}
                                        onClick={() =>
                                            this.setState({
                                                isActive9: !this.state.isActive9,
                                            })
                                        }
                                    >
                                        Action
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel
                                        disabled
                                        iconWithLabel={this.state.isActive10 ? "alert-add-empty" : "notification-off-filled"}
                                        active={this.state.isActive10}
                                        onClick={() =>
                                            this.setState({
                                                isActive10: !this.state.isActive10,
                                            })
                                        }
                                    >
                                        Disabled
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel
                                        iconWithLabel={this.state.isActive11 ? "notification-off-filled" : "alert-add-empty"}
                                        active={this.state.isActive11}
                                        onClick={() =>
                                            this.setState({
                                                isActive11: !this.state.isActive11,
                                            })
                                        }
                                    >
                                        Toggled
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel withHandMadeIcon>Export CSV</IconWithLabel>
                                </div>
                            </div>
                            <div className="flexbox-col">
                                <div className="row">
                                    <IconWithLabel
                                        iconWithLabelRight
                                        iconWithLabel={this.state.isActive12 ? "settings-empty" : "settings-filled"}
                                        active={this.state.isActive12}
                                        onClick={() =>
                                            this.setState({
                                                isActive12: !this.state.isActive12,
                                            })
                                        }
                                    >
                                        Settings
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel
                                        disabled
                                        iconWithLabelRight
                                        iconWithLabel={this.state.isActive13 ? "settings-empty" : "settings-filled"}
                                        active={this.state.isActive13}
                                        onClick={() =>
                                            this.setState({
                                                isActive13: !this.state.isActive13,
                                            })
                                        }
                                    >
                                        Settings
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel
                                        iconWithLabelRight
                                        iconWithLabel={this.state.isActive14 ? "settings-filled" : "settings-empty"}
                                        active={this.state.isActive14}
                                        onClick={() =>
                                            this.setState({
                                                isActive14: !this.state.isActive14,
                                            })
                                        }
                                    >
                                        Settings
                                    </IconWithLabel>
                                </div>
                                <div className="row">
                                    <IconWithLabel
                                        iconWithLabelRight
                                        iconWithLabelTextUppercase
                                        iconWithLabel="shevron-right-keyboard-arrow-right"
                                    >
                                        Settings
                                    </IconWithLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tabs</div>
                        <div className="row with-background">
                            <TabList
                                items={this.tabs}
                                activeItem={this.state.activeTab}
                                onClick={(tab) => this.setState({ activeTab: tab })}
                            />
                        </div>
                        <div className="row">
                            <TabList
                                tabListGhost
                                items={this.tabs}
                                activeItem={this.state.activeTab2}
                                onClick={(tab) => this.setState({ activeTab2: tab })}
                            />
                        </div>
                        <div className="row with-background">
                            <TabList
                                tabListWrapped
                                items={this.tabs}
                                activeItem={this.state.activeTab3}
                                onClick={(tab) => this.setState({ activeTab3: tab })}
                            />
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Collapse / Expand</div>
                        <div className="row">
                            <ExpandablePanel panelIcon="shevron-right-keyboard-arrow-right" title="Header Collapsed">
                                <div className="panel-content">Content</div>
                            </ExpandablePanel>
                        </div>
                        <div className="row">
                            <IconWithLabel
                                iconWithLabelRight
                                iconWithLabel={
                                    this.state.isActive17 ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"
                                }
                                active={this.state.isActive17}
                                onClick={() =>
                                    this.setState({
                                        isActive17: !this.state.isActive17,
                                    })
                                }
                            >
                                {this.showOptionalText()}
                            </IconWithLabel>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Selectable Box</div>
                        <div className="row">
                            <SelectBox icon="view-array-empty" selectBoxText="with borders" selectBoxSelected></SelectBox>
                        </div>
                        <div className="row">
                            <SelectBox icon="crop-7x5-empty" selectBoxText="full screen"></SelectBox>
                        </div>
                        <div className="row">
                            <SelectBox selectBoxText="without icon" selectBoxSelected></SelectBox>
                        </div>
                        <div className="row">
                            <SelectBox selectBoxText="without icon"></SelectBox>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Add New / Add More Item</div>
                        <div className="row">
                            <AddNewItemPanel text="Add New Filter"></AddNewItemPanel>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Drop File Zone (Single)</div>
                        <div className="row">
                            <UploadFile uplodFileSingle></UploadFile>
                        </div>
                        <div className="row">
                            <UploadFile uplodFileSingle fileUploaded></UploadFile>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Drop File Zone Disabled (Single)</div>
                        <div className="row">
                            <UploadFile uplodFileSingle fileUploadedDisabled></UploadFile>
                        </div>
                        <div className="row">
                            <UploadFile uplodFileSingle fileUploaded fileUploadedDisabled></UploadFile>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Drop Files Zone (Multiple)</div>
                        <div className="row">
                            <UploadFile uplodaFileMultiple></UploadFile>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tiles</div>
                        <div className="row">
                            <TilesWrap>
                                <TileItem icon="criteria" title="Criteria"></TileItem>
                                <TileItem icon="view-list-list-bulleted" title="Attributes"></TileItem>
                                <TileItem icon="dashboard-view-dashboard-empty" title="ashboard Criteria"></TileItem>
                                <TileItem icon="audit" title="Audits"></TileItem>
                            </TilesWrap>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Nothing found block</div>
                        <NothingFoundBlock icon="archives-empty--before" title="No Archived Batches found" />
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Nothing found block error</div>
                        <NothingFoundBlock nothingFoundBlockError icon="circle-exclamation-empty" title="Something went wrong." />
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Nothing found block with an explanation</div>
                        <NothingFoundBlock icon="copy-empty" title="No Batches found">
                            <span>
                                Select Documents from the <b>Documents in the Queue</b> to send to Printer <br /> or <br /> check Archived
                                Batches
                            </span>
                        </NothingFoundBlock>
                    </div>
                </div>
            </div>
        );
    }
}

export default Additional;
