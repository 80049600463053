import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import { contractorApplicationsGridColumnKeys } from "../../../../../views/configureGrids";
import { openProjectTab, splitProjectTab } from "../../../../../utils/window";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import IconWrap from "../../../../Icons";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./ActivityPanel.scss";
import { useCanSplitScreenView } from "components/utils/useCanSplitScreenView";
import ActionLabel from "components/ui/Label/ActionLabel";

const Applications = memo(({ contactNumber, applicationNumber }) => {
    const gridId = `${contactNumber}-contractor-applications-grid`;
    const filter = `${contractorApplicationsGridColumnKeys.contactnumber}=${contactNumber}`;

    return (
        <div className="fill-width">
            <div className="flex-row">
                <IconWrap icon="user-identity-person-empty" />
                <h3>Applications this Trade Ally is Assigned To</h3>
            </div>
            {contactNumber ? (
                <Grid contactNumber={contactNumber} applicationNumber={applicationNumber} gridId={gridId} filter={filter} />
            ) : (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="info-empty" title="No Data Available" />
            )}
        </div>
    );
});

const showedColumnsKeys = [
    contractorApplicationsGridColumnKeys.program,
    contractorApplicationsGridColumnKeys.rebate,
    contractorApplicationsGridColumnKeys["date submitted"],
    contractorApplicationsGridColumnKeys.status,
    contractorApplicationsGridColumnKeys["status date"],
    contractorApplicationsGridColumnKeys["project name"],
    contractorApplicationsGridColumnKeys["project number"],
];

const Grid = withDataGrid(
    "contractorApplications",
    memo(({ contactNumber, applicationNumber, gridId, dataGridConfig }) => {
        const isMobile = useSelector((state) => state.window?.isMobile);
        const canUseSplitScreenView = useCanSplitScreenView();

        const customRowActions = [
            {
                name: "open-in-split",
                icon: "reader-mode-empty",
                title: "Open in Split View",
                hide: !canUseSplitScreenView,
            },
            {
                name: "edit",
                icon: "open-new-window",
                title: "Manage Application",
            },
        ];

        const onRowAction = (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    onApplicationOpen(dataItem[contractorApplicationsGridColumnKeys.appid]);
                    break;

                case "open-in-split":
                    splitProjectTab({ applicationNumber });
                    onApplicationOpen(dataItem[contractorApplicationsGridColumnKeys.appid]);
                    break;

                default:
                    break;
            }
        };

        const onApplicationOpen = (applicationNumber) => {
            openProjectTab({ applicationNumber });
        };

        const handleCustomRowClassName = useCallback(
            (row) => {
                return row[contractorApplicationsGridColumnKeys.appid] === applicationNumber ? "current-app" : "";
            },
            [applicationNumber]
        );

        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "application_activity_applications",
                                fileNamePostfix: contactNumber,
                                columnsKeys: showedColumnsKeys,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid
                    key={isMobile}
                    name={gridId}
                    config={dataGridConfig}
                    showedColumnsKeys={showedColumnsKeys}
                    onRowAction={onRowAction}
                    customRowActions={customRowActions}
                    customRowClassName={handleCustomRowClassName}
                    columnCellContent={{
                        [contractorApplicationsGridColumnKeys["project number"]]: (column, row) => {
                            if (row[contractorApplicationsGridColumnKeys.appid]) {
                                return (
                                    <ActionLabel onClick={() => onApplicationOpen(row[contractorApplicationsGridColumnKeys.appid])}>
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                        [contractorApplicationsGridColumnKeys["project name"]]: (column, row) => {
                            if (row[contractorApplicationsGridColumnKeys.appid]) {
                                return (
                                    <ActionLabel onClick={() => onApplicationOpen(row[contractorApplicationsGridColumnKeys.appid])}>
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                    }}
                />
            </>
        );
    })
);

export default Applications;
