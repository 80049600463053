import React from "react";
import "./CloseCircle.scss";
import IconWrap from ".";
import ClassNames from "classnames";

const CloseCircle = (props) => {
    const className = ClassNames("close-circle", props.className);

    return (
        <IconWrap
            title={props.title}
            onClick={props.onClick}
            className={className}
            iconWrapSmall
            icon="cancel-clear-circle-highlight-off-filled"
        ></IconWrap>
    );
};

export default CloseCircle;
