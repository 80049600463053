import React, { memo } from "react";

import { availableGrids, locationPortalsGridColumnKeys } from "../../../../../views/configureGrids";
import { openUrl } from "../../../../../utils/window";
import { useDataGridData, useProgramRights } from "../../../../../../store/resources/useResource";
import { downloadCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";
import WaitIcon from "../../../../WaitIcon";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import { systemUserRights } from "components/utils/user";

import "./LocationPanel.scss";

const Portals = memo(({ applicationNumber, utilityNumber, programNumber }) => {
    const [dataGridData, isLoading] = useDataGridData({
        dataGridId: availableGrids.locationPortals,
        key: `${applicationNumber}-location-portals-grid`,
        filter: `${locationPortalsGridColumnKeys.utilityNumber}=${utilityNumber}`,
    });
    const [programRights = []] = useProgramRights({ programNumber });
    const canViewProgram = programRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM);
    const renderItem = (item, index) => <PortalItem key={index} item={item} canViewProgram={canViewProgram} />;
    const onExport = () => {
        const exelColumnKeys = [locationPortalsGridColumnKeys.program, locationPortalsGridColumnKeys.url];
        const exelData = dataGridData.map((obj) => {
            const newData = {};
            exelColumnKeys.forEach((key) => {
                if (obj.hasOwnProperty(key)) {
                    if (key === locationPortalsGridColumnKeys.program) {
                        newData["Program"] = obj[key];
                    } else if (key === locationPortalsGridColumnKeys.url) {
                        newData["Url"] = obj[key].replace(/<a[^>]*>([^>]*)<\/a>/g, "$1");
                    } else {
                        newData[key] = obj[key];
                    }
                }
            });
            return newData;
        });
        downloadCSV({
            data: exelData,
            fileName: "application_location_portals",
            fileNamePostfix: applicationNumber,
        });
    };

    return (
        <div className="fill-width portals-grid">
            <h3>Utility Programs With Application Portals</h3>
            {isLoading ? (
                <WaitIcon />
            ) : (
                <>
                    <div className="data-grid-controls flex-row justify-end">
                        <IconWithLabel withHandMadeIcon onClick={onExport}>
                            Export CSV
                        </IconWithLabel>
                    </div>
                    <CustomList limit={5} items={dataGridData} renderItem={renderItem} renderFooter={renderCustomFooter} />
                </>
            )}
        </div>
    );
});

const PortalItem = ({ item, canViewProgram }) => {
    const onClick = () => {
        const url = item[locationPortalsGridColumnKeys.url];
        const [href] = url.match(/href="([^"]*")/g);
        const link = href.split("=")[1];

        openUrl(link.replace(/"/gi, ""));
    };

    return (
        <div className="portal-item">
            {item[locationPortalsGridColumnKeys.url] ? (
                <>
                    {canViewProgram ? (
                        <ActionLabel onClick={onClick}>{item[locationPortalsGridColumnKeys.program]}</ActionLabel>
                    ) : (
                        <div className="title">{item[locationPortalsGridColumnKeys.program]}</div>
                    )}

                    <IconWrap icon="arrow-right-up" title="Open Portal" onClick={onClick} />
                </>
            ) : (
                <div className="title">{item[locationPortalsGridColumnKeys.program]}</div>
            )}
        </div>
    );
};

export default Portals;
