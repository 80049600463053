import React from "react";
import cn from "classnames";
import { ElementTypes } from "../../../../utils/form";
import { useDrop } from "react-dnd";
import { getElementType } from "../../../../../store/formBuilder/selectors";
import DropTargetField from "../../../FormBuilder/DropTargetField";

const FormBuilderObjectProperties = (props) => {
    let properties = props.properties || [];
    const propertiesCount = properties.length;

    const [{ isOver, canDrop, dragSourceElement }, drop] = useDrop(
        () => ({
            accept: [ElementTypes.FIELD, ElementTypes.SECTION, ElementTypes.ROW, ElementTypes.COLUMN],
            canDrop: (item, monitor) => {
                let result = false;
                const dragElementType = item.elementType;
                const dropElementType = getElementType(props.parent);

                switch (dropElementType) {
                    case ElementTypes.PAGE:
                        result = dragElementType === ElementTypes.SECTION;
                        break;
                    case ElementTypes.SECTION:
                        result = dragElementType === ElementTypes.COLUMN || dragElementType === ElementTypes.ROW;
                        break;
                    case ElementTypes.ROW:
                        result = dragElementType === ElementTypes.FIELD;
                        break;
                    case ElementTypes.COLUMN:
                        result = dragElementType === ElementTypes.FIELD;
                        break;
                    default:
                        result = false;
                        break;
                }

                return result;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                dragSourceElement: monitor.getItem(),
            }),
        }),
        [props.parent]
    );

    const { instanceId } = props;
    const showDropTargets = isOver && canDrop;

    const dragSourceId = dragSourceElement?.idSchema?.$id;
    const dropTargetId = props.parent?.idSchema?.$id;
    let dragSourceFound = false;
    properties = properties.reduce(
        (result, next, index) => {
            const indexOffset = dragSourceFound ? 0 : 1;

            const propertyId = next.content.props && next.content.props.idSchema && next.content.props.idSchema.$id;

            let properties = [next];
            if (propertyId !== dragSourceId) {
                properties.push({
                    content: (
                        <DropTargetField
                            key={`dt-${propertyId}`}
                            instanceId={instanceId}
                            order={index + indexOffset}
                            dropTargetId={dropTargetId}
                            show={showDropTargets}
                        />
                    ),
                });
            } else {
                dragSourceFound = true;
            }

            return result.concat(properties);
        },
        [
            {
                content: (
                    <DropTargetField key={`dt`} instanceId={instanceId} order={0} dropTargetId={dropTargetId} show={showDropTargets} />
                ),
            },
        ]
    );

    return (
        <div
            ref={drop}
            className={cn("object-properties", {
                empty: propertiesCount === 0,
                "is-over": isOver,
                "can-drop": canDrop,
            })}
        >
            {properties.map((prop) => prop.content)}
        </div>
    );
};

export default FormBuilderObjectProperties;
