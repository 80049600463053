import React, { useState, useRef, memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { getEventsInCategoryResourceParams } from "../../../../../../store/configureResources";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { submitByRef } from "../../../../../utils/form";

import CatalogSearch from "../../../../CatalogSearch";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import EventDetails from "./EventDetails";
import "./AddEvent.scss";

const AddEvent = memo(({ applicationNumber, programNumber, onClose, sidePanel }) => {
    const formRef = useRef();

    const isAppLocked = isApplicationLocked({ applicationNumber });

    const isMobile = useSelector((state) => state.window?.isMobile);

    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    const renderButtons = () => {
        return (
            <>
                {selectedEvent && (
                    <Button icon="shevron-small-left" onClick={() => setSelectedEvent(null)}>
                        Back
                    </Button>
                )}
                <div className="flex-row flex-one-in-row justify-end">
                    {!isAppLocked && selectedEvent && (
                        <Button primary icon="plus" onClick={handleSubmit}>
                            Add Event
                        </Button>
                    )}
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </>
        );
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className={"add-event-panel" + (!selectedEvent ? " catalog-selection" : "")}>
            {(!selectedEvent || isMobile) && <SideNavHeader title="Add Event" leadBlockIcon="plus" smallHeader onClose={onClose} />}
            <SideNavBody noPadding>
                {selectedEvent && (
                    <EventDetails
                        isNewEvent
                        formRef={formRef}
                        applicationNumber={applicationNumber}
                        programEventNumber={selectedEvent.programEventNumber}
                        headerLeftAlignedTitle={"Add Event"}
                        headerLeftAlignedIcon={"plus"}
                        hasHeaderLeftAligned
                        onClose={onClose}
                        sidePanel={sidePanel}
                        programNumber={programNumber}
                    />
                )}
                <div hidden={!!selectedEvent} className={"catalog-selector fill-height"}>
                    <CatalogSearch
                        inline={true}
                        applicationNumber={applicationNumber}
                        itemType="event"
                        title="Add Event"
                        usageSummaryResourceName="eventCatalogSummary"
                        categoriesResourceName="eventCategories"
                        categoryItemsResourceName="eventsInCategory"
                        categorySearchResourceName="eventsSearch"
                        categoryItemsResourceParams={getEventsInCategoryResourceParams}
                        categoryItemNumberKey="programEventNumber"
                        categoryItemNameKey="programEventName"
                        onSelect={setSelectedEvent}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter setPrimaryButton={isMobile} justifyCenter={!isAppLocked} justifyEnd={isAppLocked || !selectedEvent}>
                {renderButtons()}
            </SideNavFooter>
        </SideNavContent>
    );
});

export default AddEvent;
