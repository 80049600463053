import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const StatusMark = (props) => {
    const className = ClassNames("status-mark", props.className, props.statusMarkWithIcon, {
        "status-success-empty": props.statusMarkSuccessEmpty,
        "status-success-filled": props.statusMarkSuccessFilled,
        "status-error": props.statusMarkError,
        "status-neutral": props.statusMarkNeutral,
        "status-theme": props.statusMarkTheme,
        "status-theme-base": props.statusMarkThemeBase,
        "status-theme-dark": props.statusMarkThemeDark,
        "status-warning": props.statusMarkWarning,
        "status-ghost": props.statusMarkGhost,
        "status-disabled": props.statusMarkDisabled,
        "status-child": props.statusMarkChildIndicator,
        "status-parent": props.statusMarkParentIndicator,
        "status-mark-small": props.statusMarkSmall,
        "with-icon": props.statusMarkWithIcon,
        "with-line-break": props.statusMarkWithLineBreak,
    });

    return (
        <div className={className} title={props.title}>
            {props.children}
        </div>
    );
};

export default StatusMark;
