import React, { useRef } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { BorderRadiusPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PortalBuilderPropertyProps, PropertyName, PropertyType } from "components/ui/PortalBuilder/types";
import { BorderCornerName, BorderCornerSize, BorderRadiusCustomPropertyName, BorderSides } from "./types";
import { PropertyListItem } from "../PropertyListItem";
import { CornerAllSidesProperty } from "./CornerAllSidesProperty";
import { CornerSideBySideProperty } from "./CornerSideBySideProperty";

import "./BorderRadiusProperty.scss";

export const DefaultCornerSize = BorderCornerSize.Size5;

export const BorderRadiusProperty = (props: PortalBuilderPropertyProps) => {
    const { className, title, value = {}, nestingLevel = 0, borderTop, borderBottom, onChange, defaultValue } = props;
    const valueRef = useRef(value);
    const isExpanded = Object.values(value).some((v) => !isEmpty(v));
    const customPropertiesValue = {
        [BorderRadiusCustomPropertyName.BorderRadiusType]: isEmpty(value[PropertyName.BorderRadius])
            ? BorderSides.SideBySide
            : BorderSides.AllSides,
        [BorderRadiusCustomPropertyName.BorderRadiusAllSides]: value[PropertyName.BorderRadius],
        [BorderRadiusCustomPropertyName.BorderRadiusSideBySide]: {
            [BorderCornerName.TopLeft]: value[PropertyName.BorderTopLeftRadius],
            [BorderCornerName.TopRight]: value[PropertyName.BorderTopRightRadius],
            [BorderCornerName.BottomRight]: value[PropertyName.BorderBottomRightRadius],
            [BorderCornerName.BottomLeft]: value[PropertyName.BorderBottomLeftRadius],
        },
    };

    const onPropertyChange = (id: string, propertyValue: string) => {
        switch (id) {
            case BorderRadiusCustomPropertyName.BorderRadiusType:
                if (propertyValue === BorderSides.SideBySide) {
                    onChange(PropertyName.BorderRadius, undefined, [
                        {
                            id: PropertyName.BorderTopLeftRadius,
                            value: value[PropertyName.BorderRadius],
                        },
                        {
                            id: PropertyName.BorderTopRightRadius,
                            value: value[PropertyName.BorderRadius],
                        },
                        {
                            id: PropertyName.BorderBottomRightRadius,
                            value: value[PropertyName.BorderRadius],
                        },
                        {
                            id: PropertyName.BorderBottomLeftRadius,
                            value: value[PropertyName.BorderRadius],
                        },
                    ]);
                } else {
                    onChange(PropertyName.BorderRadius, value[PropertyName.BorderTopLeftRadius], [
                        {
                            id: PropertyName.BorderTopLeftRadius,
                            value: undefined,
                        },
                        {
                            id: PropertyName.BorderTopRightRadius,
                            value: undefined,
                        },
                        {
                            id: PropertyName.BorderBottomRightRadius,
                            value: undefined,
                        },
                        {
                            id: PropertyName.BorderBottomLeftRadius,
                            value: undefined,
                        },
                    ]);
                }

                break;
            default:
                onChange(id, propertyValue);
                break;
        }
    };

    // On Enable/disable
    const onToggle = () => {
        if (isExpanded) {
            valueRef.current = value;

            // Clear all properties
            onChange(PropertyName.BorderRadius, undefined, [
                {
                    id: PropertyName.BorderTopLeftRadius,
                    value: undefined,
                },
                {
                    id: PropertyName.BorderTopRightRadius,
                    value: undefined,
                },
                {
                    id: PropertyName.BorderBottomRightRadius,
                    value: undefined,
                },
                {
                    id: PropertyName.BorderBottomLeftRadius,
                    value: undefined,
                },
            ]);
        } else {
            const defaultBorderRadius = Object.values(valueRef.current).every(isEmpty)
                ? defaultValue?.[PropertyName.BorderRadius] ?? DefaultCornerSize
                : valueRef.current[PropertyName.BorderRadius];

            // Set default properties
            onChange(PropertyName.BorderRadius, defaultBorderRadius, [
                {
                    id: PropertyName.BorderTopLeftRadius,
                    value: valueRef.current[PropertyName.BorderTopLeftRadius],
                },
                {
                    id: PropertyName.BorderTopRightRadius,
                    value: valueRef.current[PropertyName.BorderTopRightRadius],
                },
                {
                    id: PropertyName.BorderBottomRightRadius,
                    value: valueRef.current[PropertyName.BorderBottomRightRadius],
                },
                {
                    id: PropertyName.BorderBottomLeftRadius,
                    value: valueRef.current[PropertyName.BorderBottomLeftRadius],
                },
            ]);
        }
    };

    const contextValue = {
        customPropertiesValue,
    };

    if (props.hidden) {
        return null;
    }

    return (
        <BorderRadiusPropertyContext.Provider value={contextValue}>
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--border-radius", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onPropertyChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </BorderRadiusPropertyContext.Provider>
    );
};

/**
 * properties-group only represents the list of properties that goes to config.
 * UI uses custom properties
 */
export const BORDER_RADIUS_PROPERTY = {
    id: "group-border-radius",
    title: "Rounded corners",
    type: PropertyType.BorderRadius,
    propertiesGroup: [
        {
            id: PropertyName.BorderRadius,
        },
        {
            id: PropertyName.BorderTopLeftRadius,
        },
        {
            id: PropertyName.BorderTopRightRadius,
        },
        {
            id: PropertyName.BorderBottomRightRadius,
        },
        {
            id: PropertyName.BorderBottomLeftRadius,
        },
    ],
};

const customProperties = [
    {
        id: BorderRadiusCustomPropertyName.BorderRadiusType,
        title: (
            <>
                Corner <br /> radius
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: false,
        borderBottom: false,
        items: [
            {
                text: "Side By Side",
                value: BorderSides.SideBySide,
            },
            {
                text: "All Sides",
                value: BorderSides.AllSides,
            },
        ],
    },
    {
        id: BorderRadiusCustomPropertyName.BorderRadiusAllSides,
        type: PropertyType.CustomComponent,
        component: CornerAllSidesProperty,
    },
    {
        id: BorderRadiusCustomPropertyName.BorderRadiusSideBySide,
        type: PropertyType.CustomComponent,
        component: CornerSideBySideProperty,
    },
];
