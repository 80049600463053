import React, { memo, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TilesWrap from "../../../../TilesWrap";
import TileItem from "../../../../TilesWrap/TileItem";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import { systemUserRights, hasPermission } from "components/utils/user";

import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import { UtilityForm } from "../../../../Form/shared/UtilityForm";
import { pickInitialValues } from "../../../../../utils/form";
import { getData } from "../../../../../../store/dataGrid/actions";
import { getResource, updateResource } from "../../../../../../store/resources/actions";
import { useResource, useUtilityRights } from "../../../../../../store/resources/useResource";

import "./style.scss";

const RESOURCE_NAME_UTILITIES = "utilities";

const ConfigPanel = memo((props) => {
    const dispatch = useDispatch();
    const {
        data: { utilityNumber },
    } = props.panel;
    const didCancel = useRef(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [utility] = useResource({
        resourceName: RESOURCE_NAME_UTILITIES,
        resourceId: utilityNumber,
    });

    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const user = useSelector((state) => state.user);

    const canAddUtility = utilityRights.includes(systemUserRights.VISIONDSM_ADD_UTILITY);
    const canManageFastTags =
        utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM_FILES) ||
        utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_UTILITY_FILES);
    const canManageHostnames = utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_HOSTNAME);
    const canManageUserRoles = hasPermission(user, systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS);
    const canManagePortals = utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_SYSTEM);

    const initialValues = pickInitialValues(utility);

    const onTileClick = (component) => {
        const { onToggleTempPanel } = props;

        onToggleTempPanel(component);
    };

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "utility-config-criteria-sidenav-panel",
    });

    const handleCloseUtilityConfigCriteriaForm = useCallback(() => handleCloseSidePanel(), [handleCloseSidePanel]);

    const handleSubmitForm = useCallback(
        (formData) => {
            setIsSubmitting(true);

            dispatch(
                updateResource({
                    resourceName: RESOURCE_NAME_UTILITIES,
                    resourceId: utilityNumber,
                    body: {
                        ...formData,
                    },
                    onSuccess: () => {
                        if (!didCancel.current) {
                            handleCloseUtilityConfigCriteriaForm();
                            dispatch(getData({ dataGridId: "utilities" }));
                            dispatch(
                                getResource({
                                    resourceName: RESOURCE_NAME_UTILITIES,
                                    resourceId: utilityNumber,
                                })
                            );
                        }
                    },
                    onComplete: () => setIsSubmitting(false),
                })
            );
        },
        [utilityNumber, handleCloseUtilityConfigCriteriaForm, dispatch]
    );

    const handleCriteriaPanel = useCallback(() => {
        handleOpenSidePanel(
            <UtilityForm
                onSubmit={handleSubmitForm}
                onCancel={handleCloseSidePanel}
                isSubmitting={isSubmitting}
                isProgramConfigCriteria
                initialValues={initialValues}
            />
        );
    }, [initialValues, handleSubmitForm, isSubmitting, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel utility-config-panel">
            <PanelHeaderLarge title="Config" />
            <TilesWrap>
                {canAddUtility && (
                    <TileItem
                        icon="criteria"
                        title="Criteria"
                        tileActionIcon="eye-visibility-empty"
                        tileActionIconTooltip="See Details"
                        onClick={handleCriteriaPanel}
                    />
                )}
                {canAddUtility && (
                    <TileItem icon="view-list-list-bulleted" title="Attributes" onClick={() => onTileClick("utility-attributes-panel")} />
                )}
                {canManageFastTags && <TileItem icon="offer-empty" title="FastTags" onClick={() => onTileClick("utility-fast-tags")} />}
                {canManageHostnames && (
                    <TileItem icon="dns-server-empty" title="Hostnames" onClick={() => onTileClick("utility-host-names")} />
                )}
                {canManageUserRoles && (
                    <TileItem icon="theaters-empty" title="Role Management" onClick={() => onTileClick("utility-role-management")} />
                )}
                {canManagePortals && (
                    <TileItem icon="domain-empty" title="Portal Management" onClick={() => onTileClick("utility-portals-management")} />
                )}
            </TilesWrap>
        </div>
    );
});

export default ConfigPanel;
