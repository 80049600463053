import React, { memo, useState, useCallback, useMemo, useEffect, useRef, createContext, useLayoutEffect } from "react";
import cn from "classnames";
import { ScrollSync } from "react-scroll-sync";
import memoize from "memoize-one";
import { VariableSizeList } from "react-window";

import { AutoSizeContext } from "components/utils/autosize";
import { getVirtualListHeight, SizeMeasureItem } from "../GridList/SizeMeasureItem";
import { RolesListBody } from "./RolesListBody";
import { RolesListHeaderRow } from "./RolesListHeaderRow";
import { RolesListItemRow } from "./RolesListItemRow";
import { downloadCSV, lineToObject, uploadCSV } from "components/utils/CSV";

import "./style.scss";

export const VariableSizeListContext = createContext({});

export const RolesList = memo(
    ({
        className,
        roles,
        listItems,
        listItemInfo,
        listItemsTitle,
        roleButtonTitle,
        roleBadgeTitle,
        roleHeaderButtonTitle,
        onItemsRendered,
        onGetListItemInfo,
        onListItemsSelect,
        onHeaderRoleClick,
        onListItemRoleClick,
        onListItemRoleBadgeClick,
        manageSelectedItemsTitle,
        manageSelectedItemsTooltip,
        onManageSelectedItems,
    }) => {
        const [itemFilter, setItemFilter] = useState("");
        const [filterByRole, setFilterByRole] = useState({});
        const [selectedItems, setSelectedItems] = useState({});

        const filterPlaceholder = "Filter " + listItemsTitle;
        const isAnyItemSelected = Object.values(selectedItems).some((i) => i);
        const showItemSelection = onManageSelectedItems || onListItemsSelect;

        const filteredListItems = useMemo(() => {
            const filterValue = itemFilter.toLowerCase();

            let filteredItems = listItems.filter((item) => {
                const itemValue = (item.itemName || "").toString().toLowerCase();
                return itemValue.includes(filterValue);
            });

            const filteredRoleIds = Object.keys(filterByRole)
                .filter((key) => filterByRole[key])
                .map((key) => key);

            // filter by selected roles
            if (filteredRoleIds.length > 0) {
                filteredItems = filteredItems.filter((item) => {
                    const itemInfo = listItemInfo[item.itemId];
                    if (itemInfo) {
                        return Object.keys(itemInfo.roleAssignments)
                            .filter((roleId) => itemInfo.roleAssignments[roleId]?.length > 0)
                            .some((roleId) => filteredRoleIds.includes(roleId));
                    }

                    return true;
                });
            }

            return filteredItems;
        }, [listItems, listItemInfo, itemFilter, filterByRole]);

        const selectAllState = useMemo(() => {
            const itemCount = filteredListItems?.length ?? 0;
            const selectedItemCount = Object.keys(selectedItems).filter((key) => selectedItems[key] === true).length;

            let state = false;
            if (selectedItemCount > 0) {
                state = true;

                if (selectedItemCount !== itemCount) {
                    state = null;
                }
            }

            return state;
        }, [filteredListItems, selectedItems]);

        const onItemSelect = useCallback((e, item) => {
            e.stopPropagation();

            setSelectedItems((prevValue) => ({
                ...prevValue,
                [item.itemId]: !prevValue[item.itemId],
            }));
        }, []);

        const onSelectAll = useCallback(() => {
            // deselect all
            if (selectAllState) {
                setSelectedItems({});
            }
            // select all
            else {
                const allSelected = filteredListItems.reduce(
                    (result, next) =>
                        (result = {
                            ...result,
                            [next.itemId]: true,
                        }),
                    {}
                );

                setSelectedItems(allSelected);
            }
        }, [filteredListItems, selectAllState]);

        const onManageItems = useCallback(() => {
            onManageSelectedItems && onManageSelectedItems(selectedItems);
        }, [onManageSelectedItems, selectedItems]);

        const onListItemsRendered = useCallback(
            ({ overscanStartIndex, overscanStopIndex }) => {
                if (onItemsRendered) {
                    const itemIds = filteredListItems
                        .filter((item, index) => index >= overscanStartIndex && index <= overscanStopIndex)
                        .map((item) => item.itemId);

                    onItemsRendered(itemIds);
                }
            },
            [filteredListItems, onItemsRendered]
        );

        const itemCount = filteredListItems.length;
        const itemHeight = 58;
        const sizeMap = useRef({});
        const [, setListHeight] = useState(0); // List height only for rerender

        const itemData = createRowData(filteredListItems, listItems, listItemInfo);

        const instanceRef = useRef();
        const [listRef, setListRef] = useState(null);
        const onSetRowContainerRef = useCallback((ref) => {
            if (ref) {
                setListRef(ref);
            }
        }, []);

        // Align widths of header cells to body role button cells
        useLayoutEffect(() => {
            if (listRef) {
                const list = listRef.closest(".roles-list");
                const scrollableCells = listRef.querySelector(".roles-list-scrollable-cells");

                if (list && scrollableCells) {
                    const bodyRoleCells = scrollableCells.querySelectorAll(".roles-list-cell");
                    const headerRoleCells = list.querySelectorAll(".roles-list-scrollable-cells .roles-list-cell--header");

                    headerRoleCells.forEach((node, index) => {
                        const rect = bodyRoleCells[index].getBoundingClientRect();
                        node.style.width = rect.width;
                    });
                }
            }
        }, [listRef, itemCount]);

        // trigger item select events
        useEffect(() => {
            onListItemsSelect && onListItemsSelect(selectedItems);
        }, [selectedItems, onListItemsSelect]);

        // Callback returns list height changes
        // Need only to trigger rerender
        const onResize = useCallback(setListHeight, [setListHeight]);

        const getSize = useCallback(
            (index) => {
                const savedSize = sizeMap.current[index];
                return savedSize ? Math.max(savedSize, itemHeight) : itemHeight;
            },
            [itemHeight]
        );

        const setSize = useCallback(
            (index, size) => {
                sizeMap.current = { ...sizeMap.current, [index]: size };

                // Update list
                if (instanceRef.current) {
                    instanceRef.current.resetAfterIndex(0);
                }
            },
            [instanceRef]
        );

        const onExportList = useCallback(() => {
            const data = filteredListItems.map((i) => ({
                ID: i.itemId,
                NAME: i.itemName,
            }));

            downloadCSV({
                data,
                fileName: "RoleManagementItemsList",
            });
        }, [filteredListItems]);

        const onImportSelection = useCallback(() => {
            uploadCSV().then((result) => {
                const uploadedItems = result.lines
                    .map((line) => lineToObject({ header: result.header, line }))
                    .map((line) => filteredListItems.find((i) => i.itemId === line.ID)) // Get list items by uploaded identifier
                    .filter((item) => item) // Take only found items
                    .reduce(
                        (result, next) =>
                            (result = {
                                ...result,
                                [next.itemId]: true,
                            }),
                        {}
                    );

                setSelectedItems(uploadedItems);
            });
        }, [filteredListItems]);

        const onHeaderRoleBtnClick = useCallback(
            (role) => {
                onHeaderRoleClick({
                    ...role,
                    selectedItems: Object.keys(selectedItems).filter((key) => selectedItems[key] === true),
                });
            },
            [onHeaderRoleClick, selectedItems]
        );

        const listHeight = getVirtualListHeight({
            listRef,
            itemHeight,
            itemCount,
        });

        return (
            <div className={cn("roles-list flex-column", className)}>
                <ScrollSync>
                    <>
                        <RolesListHeaderRow
                            manageSelectedItemsTooltip={manageSelectedItemsTooltip}
                            isAnyItemSelected={isAnyItemSelected}
                            listItemsTitle={listItemsTitle}
                            manageSelectedItemsTitle={manageSelectedItemsTitle}
                            roleHeaderButtonTitle={roleHeaderButtonTitle}
                            selectAllState={selectAllState}
                            filteredListItemsLength={filteredListItems.length}
                            filterPlaceholder={filterPlaceholder}
                            showItemSelection={showItemSelection}
                            roles={roles}
                            itemFilter={itemFilter}
                            setItemFilter={setItemFilter}
                            filterByRole={filterByRole}
                            setFilterByRole={setFilterByRole}
                            onManageSelectedItems={onManageSelectedItems}
                            onManageItems={onManageItems}
                            onSelectAll={onSelectAll}
                            onExportList={onExportList}
                            onImportSelection={onImportSelection}
                            onHeaderRoleClick={onHeaderRoleClick ? onHeaderRoleBtnClick : undefined}
                        />
                        <RolesListBody className="flex-one-in-column">
                            <AutoSizeContext.Provider value={{ setAutoSize: setSize }}>
                                <VariableSizeList
                                    ref={instanceRef}
                                    outerRef={onSetRowContainerRef}
                                    height={listHeight}
                                    width={"100%"}
                                    itemCount={itemCount}
                                    itemSize={getSize}
                                    estimatedItemSize={itemHeight}
                                    itemData={itemData}
                                    useIsScrolling
                                    onItemsRendered={onListItemsRendered}
                                >
                                    {(props) => (
                                        <RolesListItemRow
                                            {...props}
                                            isSelected={selectedItems[filteredListItems[props.index].itemId] ?? false}
                                            roles={roles}
                                            roleButtonTitle={roleButtonTitle}
                                            roleBadgeTitle={roleBadgeTitle}
                                            onSelect={showItemSelection ? onItemSelect : undefined}
                                            onRoleClick={onListItemRoleClick}
                                            onRoleBadgeClick={onListItemRoleBadgeClick}
                                            onGetListItemInfo={onGetListItemInfo}
                                        />
                                    )}
                                </VariableSizeList>
                            </AutoSizeContext.Provider>
                            <SizeMeasureItem listRef={listRef} itemHeight={itemHeight} itemCount={itemCount} onResize={onResize} />
                        </RolesListBody>
                    </>
                </ScrollSync>
            </div>
        );
    }
);

const createRowData = memoize((filteredListItems, listItems, listItemInfo) => ({
    items: filteredListItems,
    allItems: listItems,
    itemInfo: listItemInfo,
}));
