import React from "react";
import { useResource } from "../../../store/resources/useResource";
import { getTotalTimesUsed, renderCategory } from "../Dashboard/Panel/Project/utils";
import StatusMark from "../StatusMark";
import WaitIcon from "../WaitIcon";
import ListItemCategory from "../List/ListItemCategory";
import IconWrap from "../Icons";
import { WarningMessage } from "../Message";

const CommonItemsTab = ({ title, itemType, categoryItemNumberKey, applicationNumber, usageSummaryResourceName, onSelectItem }) => {
    const [usageSummary, isLoadingUsageSummary] = useResource({
        resourceName: usageSummaryResourceName,
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });

    const getCommon = () => {
        if (isLoadingUsageSummary) {
            return <WaitIcon />;
        }

        if (usageSummary?.mostCommonList?.length > 0) {
            return usageSummary.mostCommonList.map((item) => (
                <ListItemCategory key={item[categoryItemNumberKey]} onClick={() => onSelectItem(item)}>
                    <div className="flex-row align-center">
                        <div className="timing">{getTotalTimesUsed(item.total)}</div>
                        <div className="category">{renderCategory(item.category, item.categoryParent)}</div>
                        <div className={"equipment-name-wrap flex-row fill-width align-center flex-one " + itemType}>
                            <StatusMark statusMarkNeutral>{itemType}</StatusMark>
                            <div className="equipment-name flex-one">{item.name}</div>
                        </div>
                    </div>
                    <IconWrap icon="plus" title={title} onClick={() => onSelectItem(item)} />
                </ListItemCategory>
            ));
        } else {
            return (
                <div className="no-equipment-found-block flex-column align-center">
                    <WarningMessage msgCentered>No most commonly used {itemType} found.</WarningMessage>
                </div>
            );
        }
    };

    return <div className="catalog-columns common">{getCommon()}</div>;
};

export default CommonItemsTab;
