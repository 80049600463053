import React, { memo } from "react";
import { useSelector } from "react-redux";

import { usePowerBiReport } from "../../../store/resources/useResource";
import { PBI_REPORT_TYPE } from "../../ui/Dashboard/DashboardSettings/utils";

import WaitIcon from "../../ui/WaitIcon";
import PowerBiReport from "../../ui/PowerBiReport";
import ErrorBoundary from "../../utils/ErrorBoundary";

export const reportPages = {
    "Utility Standard Dashboard Utility": "ReportSectionc04559652653cd98ebac",
    "Utility Standard Dashboard Program": "ReportSection10445ab866799cc6a090",
};

const PowerBI = memo(({ reportID, reportType, pageName, entityTypeID, entityNumber, showFilter = false, showPrint = true }) => {
    const isMobile = useSelector((state) => state.window.isMobile);

    const [report] = usePowerBiReport({
        reportID,
        isPaginated: (reportType ?? PBI_REPORT_TYPE.STANDARD) === PBI_REPORT_TYPE.PAGINATED,
        entityTypeID,
        entityNumber,
        forced: false,
    });

    if (!(report && report.token && report.url)) {
        return <WaitIcon />;
    }

    return (
        <ErrorBoundary>
            <PowerBiReport
                accessToken={report.token}
                embedUrl={report.url}
                embedId={reportID}
                pageName={pageName}
                showFilter={showFilter}
                isMobile={isMobile}
                showPrint={showPrint}
            />
        </ErrorBoundary>
    );
});

export default PowerBI;
