import React, { useCallback, memo } from "react";

import { openUtilityTab, closeNewApplicationTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";

import SubHeader from "../../ui/Headers/SubHeader";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import { useProgram, useUtilityRights } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";

import Application from "./Application";
import WaitIcon from "components/ui/WaitIcon";
import { useDispatch } from "react-redux";

const NewApplicationForm = memo(({ applicationNumber, utilityNumber, programNumber, formPageNumber, customerNumber, customerDetails }) => {
    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const canViewUtility = utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY);
    const [program = {}, isLoadingProgramDetails] = useProgram({ programNumber });
    const dispatch = useDispatch();

    const headerItems = [
        {
            name: "Utility",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.utility,
            onClick: !canViewUtility
                ? undefined
                : () =>
                      openUtilityTab({
                          utilityNumber,
                      }),
        },
        {
            name: "Program",
            value: isLoadingProgramDetails ? <WaitIcon /> : program?.program,
            onClick: () =>
                dispatch(
                    openProgramTab({
                        programNumber,
                    })
                ),
        },
        {
            name: "Application",
            value: applicationNumber,
        },
    ];

    // onWindowRemove - is a callback function for splitview close
    const handleClose = useCallback(
        (onWindowRemove, shouldWindowRemain) => {
            closeNewApplicationTab({
                applicationNumber,
                onWindowRemove,
                shouldWindowRemain,
            });
        },
        [applicationNumber]
    );

    return (
        <div className="create-new-application-page flex-column fill-height no-scroll">
            <WindowBodyHeader withShadow noPopupDetailsOnMobile>
                <SubHeader items={headerItems} className="fill-width" />
            </WindowBodyHeader>
            <div className="popup-boundary flex-one-in-column with-scroll">
                <Application
                    applicationNumber={applicationNumber}
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                    formPageNumber={formPageNumber}
                    customerNumber={customerNumber}
                    customerDetails={customerDetails}
                    onClose={handleClose}
                />
            </div>
        </div>
    );
});

export default NewApplicationForm;
