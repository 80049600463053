import React, { useState, memo, useRef } from "react";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import WaitIcon from "components/ui/WaitIcon";
import { isMultipleEmailsValidate, pickInitialValues, submitByRef, submitResource } from "components/utils/form";
import { ftpConfigColumnKeys } from "components/views/configureGrids";
import { mapGridRowToObject } from "components/utils/datagrid";
import { refreshFTPConfigsGrid } from "store/dataGrid/refreshGrid";
import { useResource } from "store/resources/useResource";

const uiSchema = {
    ftpSecure: {
        "ui:widget": "select",
        "ui:enumItems": [
            { label: "None", value: 0 },
            { label: "Implicit", value: 1 },
            { label: "Explicit", value: 3 },
        ],
    },

    protocol: {
        "ui:widget": "select",
        "ui:enumItems": [
            { label: "Sftp", value: 0 },
            { label: "Scp", value: 1 },
            { label: "Ftp", value: 2 },
            { label: "Webdav", value: 3 },
            { label: "S3", value: 4 },
        ],
    },
};

const schema = {
    type: "object",
    required: ["clientName", "serverHostName", "userName", "password", "localPath", "remotePath", "emailToAddy", "emailErrorToAddy"],
    properties: {
        clientName: {
            type: "string",
            title: "Client Name",
        },
        serverHostName: {
            type: "string",
            title: "Server Host Name",
        },
        userName: {
            type: "string",
            title: "Username",
        },
        password: {
            type: "string",
            title: "Password",
        },
        localPath: {
            type: "string",
            title: "Local Path",
        },
        remotePath: {
            type: "string",
            title: "Remote Path",
        },
        sshHostKeyFingerprint: {
            type: "string",
            title: "SSH Host Key Fingerprint",
        },
        sshPrivateKeyPath: {
            type: "string",
            title: "SSH Private Key Path",
        },
        winScpPath: {
            type: "string",
            title: "WinScp path",
        },
        fileExtension: {
            type: "string",
            title: "File Extension",
        },
        emailToAddy: {
            type: "string",
            title: "Email to Address",
        },
        emailCCAddy: {
            type: "string",
            title: "Email CC Address",
        },
        emailSubject: {
            type: "string",
            title: "Email Subject",
        },
        decryptExtension: {
            type: "string",
            title: "Decrypt Extension",
        },
        emailErrorToAddy: {
            type: "string",
            title: "Email Error to Address",
        },
        emailErrorCCAddy: {
            type: "string",
            title: "Error Email CC Address",
        },
        filePrefix: {
            type: "string",
            title: "File Prefix",
        },
        customPortNumber: {
            type: "number",
            title: "Custom Port Number",
        },
        daysToKeep: {
            type: "number",
            title: "Amount of Days to Keep",
        },
        ftpSecure: {
            type: "number",
            title: "FTP Secure",
        },
        protocol: {
            type: "number",
            title: "Protocol",
        },
        skipNoFileEmail: {
            type: "boolean",
            title: "Skip No File Email",
        },
        onDemandRun: {
            type: "boolean",
            title: "On Demand Run",
        },
        extractFileToSubfolder: {
            type: "boolean",
            title: "Extract File to Subfolder",
        },
        deleteLocalFile: {
            type: "boolean",
            title: "Delete Local File",
        },
        excludeTimestampOnDone: {
            type: "boolean",
            title: "Exclude Timestamp on Done",
        },
        useSFTP: {
            type: "boolean",
            title: "Use FTP",
        },
        unzipNeeded: {
            type: "boolean",
            title: "Unzip Needed",
        },
        deleteFilePostDL: {
            type: "boolean",
            title: "Delete File Post DL",
        },
        isActive: {
            type: "boolean",
            title: "Active",
        },
        decryptionNeeded: {
            type: "boolean",
            title: "Decryption Needed",
        },
    },
};

const Form = memo(({ dataItem, onClose }) => {
    const formRef = useRef();

    const isNew = dataItem === undefined;

    const ftpConfig = isNew ? {} : mapGridRowToObject(ftpConfigColumnKeys, dataItem);

    const resourceId = isNew ? null : ftpConfig.clientDownloadConfigNumber;

    const [initialValues, isLoading] = useResource({
        resourceName: "ftpConfigs",
        resourceId,
        forced: true,
        transform: (data) => {
            return data
                ? {
                      ...pickInitialValues(data),
                      decryptionNeeded: !!data.decryptionNeeded,
                      isActive: !!data.isActive,
                      unzipNeeded: !!data.unzipNeeded,
                      skipNoFileEmail: !!data.skipNoFileEmail,
                      onDemandRun: !!data.onDemandRun,
                      extractFileToSubfolder: !!data.extractFileToSubfolder,
                      deleteLocalFile: !!data.deleteLocalFile,
                      excludeTimestampOnDone: !!data.excludeTimestampOnDone,
                      deleteFilePostDL: !!data.deleteFilePostDL,
                  }
                : {
                      ftpSecure: 0,
                      protocol: 0,
                      decryptionNeeded: false,
                      isActive: false,
                      unzipNeeded: false,
                      skipNoFileEmail: false,
                      onDemandRun: false,
                      extractFileToSubfolder: false,
                      deleteLocalFile: false,
                      excludeTimestampOnDone: false,
                      deleteFilePostDL: false,
                  };
        },
    });

    const title = isNew ? "Add FTP Configuration" : "Edit FTP Configuration";

    const titleIcon = isNew ? "plus" : "edit-empty";

    const [isSubmitting, setSubmitting] = useState(false);

    const validate = (formData, errors) => {
        const emailError = "Email address must be valid email address";
        if (formData.emailToAddy && !isMultipleEmailsValidate(formData.emailToAddy)) {
            errors.emailToAddy.addError(emailError);
        }

        if (formData.emailCCAddy && !isMultipleEmailsValidate(formData.emailCCAddy)) {
            errors.emailCCAddy.addError(emailError);
        }

        if (formData.emailErrorToAddy && !isMultipleEmailsValidate(formData.emailErrorToAddy)) {
            errors.emailErrorToAddy.addError(emailError);
        }

        if (formData.emailErrorCCAddy && !isMultipleEmailsValidate(formData.emailErrorCCAddy)) {
            errors.emailErrorCCAddy.addError(emailError);
        }

        return errors;
    };

    const submitText = isSubmitting ? "Saving..." : "Save";

    const onSubmit = (formData) => {
        setSubmitting(true);
        submitResource({
            resourceParams: { resourceName: "ftpConfigs" },
            resourceId,
            body: formData,
            onRefresh: refreshFTPConfigsGrid,
            onSuccess: onClose,
            setSubmitting,
            noResourceRefresh: true,
        });
    };

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    validate={validate}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default Form;
