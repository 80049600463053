import React from "react";
import {
    get,
    set,
    unset,
    cloneDeep,
    isEmpty,
    isInteger,
    isNil,
    isObject,
    orderBy,
    pickBy,
    isString,
    isNumber,
    isBoolean,
    isFunction,
    uniqueId,
    union,
    isEqual,
    assign,
    isFinite,
    isDate,
} from "lodash";
import memoizeOne from "memoize-one";
import { getResource, createResource, updateResource } from "store/resources/actions";
import { store } from "store/configureStore";
import predicate from "predicate";
import Engine from "json-rules-engine-simplified";
import { setFormData } from "store/forms/actions";
import { deselectElement, selectElement, clearValidationError, updatePage } from "store/formBuilder/actions";
import {
    jsonDateToDate,
    formatJsonDate,
    dateToJson,
    getDateValueForInput,
    getDateWithoutTime,
    dateStringToJsonDate,
    datePartFromJsonDate,
    isValidDateFormatFromInput,
    localizeJsonDate,
} from "./date";
import {
    getPropertyList,
    getActiveColumn,
    getActiveRow,
    getSelectedElementUiParams,
    getActiveSectionId,
    getActiveColumnId,
    getActiveRowId,
    getActiveFieldId,
    getSelectedElement,
    getFormBuilder,
    getActiveUiField,
    getActiveSection,
    getSectionList,
    getSectionCount,
} from "../../store/formBuilder/selectors";
import { dispatchEvent } from "./dom";
import { logError } from "./logger";
import { toArray } from "./array";
import { createId, escapeEqualSign, isNullOrWhitespace, removeExtraSpaces, stripHtml, unescapeEqualSign } from "./string";
import { downloadCSV, uploadCSV, lineToObject, showUploadCsvError } from "./CSV";
import { toast } from "react-toastify";
import { fieldGroups, showValidationAdd, showValidationPivot } from "components/ui/FormBuilder/utils";
import { modalOpen, modalClose } from "store/modal/actions";
import { referenceTypes } from "components/ui/Reference/referenceTypes";
import { getReference } from "components/ui/Reference/utils";
import omitDeep from "omit-deep-lodash";
import {
    DEFAULT_PAGE_PROP_DEFAULTPAGE,
    DEFAULT_PAGE_PROP_STATUS,
    FIELD_DESCRIPTOR_DB,
    FIELD_DESCRIPTOR_TEXT,
} from "store/formBuilder/utils";
import { deepOmitBy } from "components/utils/object";
import { CURRENCY_SYMBOL } from "components/utils/money";
import { TrueFalse, YesNo } from "./constants";
import rulesRunner from "components/ui/Form/JsonSchema/conditionals/rulesRunner";

// formbuilder constants
export const FIELD_ID_PREFIX = "field";
export const COLUMN_ID_PREFIX = "column";
export const SECTION_ID_PREFIX = "section";

export const TEXT_WIDGET_LIST = [
    "text",
    "password",
    "textarea",
    "date",
    "defaultdate",
    "file",
    "html",
    "state",
    "select",
    "radio",
    "readonlyvalue",
    "additionalcontacts",
    "applicationcontacts",
    "applicationname",
    "equipmentblock",
    "auditequipmentblock",
    "auditresult",
    "auditworkorder",
    "contractorlookuplist",
    "eqipmentrecomms",
    "rebatetotalgrid",
    "generalprocedure",
    "workflow",
    "signature",
];

export const BOOLEAN_WIDGET_LIST = ["checkbox", "largecheckbox", "radio", "select"];

export const NUMBER_WIDGET_LIST = ["text", "radio", "select"];

export const ARRAY_WIDGET_LIST = ["checkboxes", "largecheckboxes", "select"];

export const GENERAL_PROCEDURE_WIDGET_LIST = ["checkboxes", "radio", "select"];

export const READONLY_WIDGET_LIST = ["defaultdate", "nontextspacer", "rawhtml", "spacer", "statement"];

export const FIELD_WIDGETS = {
    text: "Text",
    //password: "Password",
    textarea: "Textarea",
    date: "Date",
    defaultdate: "Default Date",
    //file: "File",
    //html: "Html",
    state: "State",
    checkbox: "Checkbox",
    checkboxes: "Checkboxes",
    largecheckbox: "Large Checkbox",
    largecheckboxes: "Large Checkboxes",
    select: "Select",
    radio: "Radio",
    readonlyvalue: "Read Only Value",
    statement: "Statement",
    spacer: "Spacer",
    nontextspacer: "Non Text Spacer",
    additionalcontacts: "Additional Contacts",
    applicationcontacts: "Application Contacts",
    applicationname: "Application Name",
    equipmentblock: "Equipment Block",
    auditequipmentblock: "Audit Equipment Block",
    //auditresult: "Audit Result",
    //auditworkorder: "Audit Workorder",
    contractorlookuplist: "Contractor Lookup List",
    //eqipmentrecomms: "Eqipment Recomms",
    rebatetotalgrid: "Rebate Total Grid",
    generalprocedure: "General Procedure",
    workflow: "Workflow",
    image: "Image",
    signature: "Signature Block",
};

export const FIELD_FORMAT = {
    email: "email",
    url: "url",
};

export const DEFAULT_AUTOCOMPLETE = "off";

export const ElementTypes = {
    PAGE: "page",
    SECTION: "section",
    COLUMN: "column",
    ROW: "row",
    FIELD: "field",
    UNKNOWN: "unknown",
};

export const regexPatterns = {
    nonEmptyString: "^(?!\\s*$).+",
};

export const flattenFormData = (form, keyName = "ui:key") => {
    const { schema, uiSchema, formData } = form;

    let keysFound = false;
    const fieldKeys = getSchemaIds(schema).reduce((result, next) => {
        const path = `${next}.${keyName}`;
        const key = get(uiSchema, path);

        if (key !== undefined) {
            keysFound = true;
            const value = get(formData, next);

            // Add undefined values to know which fields were cleared
            if (value !== undefined || keyName === "af:fieldNumber") {
                result[key] = value;
            }
        }

        return result;
    }, {});

    return keysFound ? fieldKeys : formData;
};

/**
 * Returns a rules runner function that applies the specified rules to the form config.
 * @param {object} schema - The JSON schema object.
 * @param {object} uiSchema - The UI schema object.
 * @param {object} rules - The rules object containing validation rules.
 * @returns {function} - The rules runner function.
 */
export const getRulesRunner = (schema, uiSchema, rules) => {
    const normalizedRules = normalizeRules(rules);

    return rulesRunner(schema, uiSchema, normalizedRules, Engine);
};

/**
 * Removes disabled fields, empty columns and empty section from the form.
 *
 * @param {*} { schema, uiSchema, rules, visibleFieldIds = [] }
 * @returns
 */
export const removeDisabledFields = ({ schema, uiSchema, rules, visibleFieldIds = [] }) => {
    let updatedSchema = cloneDeep(schema);
    let updatedUiSchema = cloneDeep(uiSchema);
    let updatedRules = cloneDeep(rules);

    let formFieldCount = 0;

    const removeEmptyContainers = ({ elementId, form }) => {
        const formBuilderState = {
            schema: form.schema,
            uiSchema: form.uiSchema,
            selectedElementId: "root_" + elementId.split(".").join("_"),
        };

        const elementSchema = getSelectedElement(formBuilderState);

        // Remove if element is empty
        if (Object.keys(elementSchema?.properties ?? {}).length === 0) {
            form = removeElementById({ elementId, ...form });

            const parent = getParentSchemasById({ elementId, ...form });
            const parentElementType = parent?.uiSchema["ui:elementType"];

            // Do not remove whole page. Just sections and columns
            if ([ElementTypes.COLUMN, ElementTypes.SECTION].includes(parentElementType)) {
                form = removeEmptyContainers({
                    elementId: elementId.split(".").slice(0, -1).join("."),
                    form,
                });
            }
        }

        return form;
    };

    getSchemaIds(schema).forEach((id) => {
        const fieldId = get(uiSchema, `${id}.af:fieldId`);
        const fieldNumber = get(uiSchema, `${id}.af:fieldNumber`);
        const elementType = get(uiSchema, `${id}.ui:elementType`);
        const isDisabled = get(uiSchema, `${id}.af:status`) === "disabled";

        // Remove field if disabled or not in list of visible fields
        const removeField =
            isDisabled ||
            (visibleFieldIds.length > 0 &&
                elementType === ElementTypes.FIELD &&
                !visibleFieldIds.includes(fieldId) &&
                !visibleFieldIds.includes(fieldNumber));

        if (!removeField && elementType === ElementTypes.FIELD) {
            // Increment field Count if field stays in form
            formFieldCount = formFieldCount + 1;
        }

        // Remove field
        if (removeField) {
            let updatedForm = removeElementById({
                elementId: id,
                schema: updatedSchema,
                uiSchema: updatedUiSchema,
                rules: updatedRules,
            });

            // Remove empty parent elements
            updatedForm = removeEmptyContainers({
                elementId: id.split(".").slice(0, -1).join("."),
                form: updatedForm,
            });

            updatedSchema = updatedForm.schema;
            updatedUiSchema = updatedForm.uiSchema;
            updatedRules = updatedForm.rules;
        }

        // Remove empty container elements
        if ([ElementTypes.COLUMN, ElementTypes.SECTION].includes(elementType)) {
            let updatedForm = removeEmptyContainers({
                elementId: id,
                form: {
                    schema: updatedSchema,
                    uiSchema: updatedUiSchema,
                },
            });

            updatedSchema = updatedForm.schema;
            updatedUiSchema = updatedForm.uiSchema;
        }
    });

    return {
        schema: updatedSchema,
        uiSchema: updatedUiSchema,
        rules: updatedRules,
        formFieldCount,
    };
};

export const removeElementById = ({ elementId, schema, uiSchema, rules = [] }) => {
    const parts = (elementId || "").split(".");

    let updatedSchema = cloneDeep(schema);
    let updatedUiSchema = cloneDeep(uiSchema);
    let updatedRules = cloneDeep(rules);

    if (parts.length === 0 || elementId === "") {
        updatedSchema = {};
        updatedUiSchema = {};
        updatedRules = [];
    } else {
        // schema
        const schemaPath = `properties.${parts.join(".properties.")}`;
        unset(updatedSchema, schemaPath);

        // uiSchema
        const schemaUiPath = parts.join(".");
        unset(updatedUiSchema, schemaUiPath);

        const fieldId = parts[parts.length - 1];
        const parent = getParentSchemasById({ elementId, schema: updatedSchema, uiSchema: updatedUiSchema });

        // Required
        if ((parent.schema?.required || []).some((i) => i === fieldId)) {
            const pathToRequired = `properties.${parts.slice(0, -1).join(".properties.")}.required`;
            const updatedRequired = parent.schema.required.filter((i) => i !== fieldId);
            set(updatedSchema, pathToRequired, updatedRequired);
        }

        // Order
        if (!isEmpty(parent.uiSchema)) {
            if (parts.length < 2) {
                updatedUiSchema = {
                    ...parent.uiSchema,
                };

                if (parent.uiSchema["ui:order"]) {
                    updatedUiSchema["ui:order"] = parent.uiSchema["ui:order"].filter((e) => e !== fieldId);
                }
            } else {
                const parentSchemaUiPath = parts.slice(0, -1).join(".");
                const parentUiSchema = {
                    ...parent.uiSchema,
                };

                if (parent.uiSchema["ui:order"]) {
                    parentUiSchema["ui:order"] = parent.uiSchema["ui:order"].filter((e) => e !== fieldId);
                }

                set(updatedUiSchema, parentSchemaUiPath, parentUiSchema);
            }
        }

        // rules
        updatedRules = (updatedRules || []).filter(
            (r) => !Object.keys(r.conditions).includes(schemaUiPath) && r.event.params.field !== schemaUiPath
        );
    }

    return {
        schema: updatedSchema,
        uiSchema: updatedUiSchema,
        rules: updatedRules,
    };
};

export const getParentSchemasById = ({ elementId, schema, uiSchema }) => {
    let result = {
        schema: {},
        uiSchema: {},
    };

    const parts = (elementId || "").split(".");

    if (parts.length > 1) {
        const path = `properties.${parts.slice(0, -1).join(".properties.")}`;
        const uiPath = parts.slice(0, -1).join(".");

        result.schema = {
            ...get(schema, path),
        };

        result.uiSchema = {
            ...get(uiSchema, uiPath),
        };
    } else {
        result.schema = {
            ...schema,
        };

        result.uiSchema = {
            ...uiSchema,
        };
    }

    return result;
};

// Update order and return updated uiSchema
// Pass dot separated elementId
export const updateOrder = (state, elementId, newIndex) => {
    let schema = state.schema;
    let uiSchema = state.uiSchema;

    const parts = (elementId || "").split(".");

    if (parts.length > 0 && elementId !== "") {
        let parentElementSchemas = getParentSchemasById({
            elementId,
            schema,
            uiSchema,
        });
        let parentUiSchema = parentElementSchemas.uiSchema;

        if (isInteger(newIndex) && parentUiSchema) {
            if (parentUiSchema["ui:order"]) {
                const currentOrder = parentUiSchema["ui:order"].slice();
                const fieldId = parts[parts.length - 1];
                const newOrder = updateOrderList(currentOrder, fieldId, newIndex);

                parentUiSchema = {
                    ...parentUiSchema,
                    "ui:order": newOrder,
                };
            }

            const parentSchemaUiPath = parts.slice(0, -1).join(".");
            if (parentSchemaUiPath !== "") {
                set(uiSchema, parentSchemaUiPath, parentUiSchema);
            } else {
                uiSchema = parentUiSchema;
            }
        }
    }

    return uiSchema;
};

export const updateOrderList = (orderList = [], elementRelativeId, newIndex) => {
    let list = [...orderList];

    if (isInteger(newIndex)) {
        const oldIndex = list.indexOf(elementRelativeId);
        if (oldIndex > -1) {
            list.splice(oldIndex, 1);
        }

        list.splice(newIndex, 0, elementRelativeId);

        return list;
    }

    return list;
};

/**
 * Fix Parent element ui:order property if it is not defined.
 *
 * @param {*} { elementId, schema, uiSchema }
 * @returns Updated uiSchema
 */
export const updateParentUiOrder = ({ elementId, schema, uiSchema }) => {
    const parts = (elementId || "").split(".");

    if (parts.length > 0 && elementId !== "") {
        const parentElementSchemas = getParentSchemasById({
            elementId,
            schema,
            uiSchema,
        });
        let parentUiSchema = parentElementSchemas.uiSchema;

        if (parentUiSchema && !parentUiSchema["ui:order"]) {
            const order = getPropertyList(parentElementSchemas.schema);

            parentUiSchema = {
                ...parentUiSchema,
                "ui:order": order,
            };

            const parentSchemaUiPath = parts.slice(0, -1).join(".");

            if (parentSchemaUiPath !== "") {
                set(uiSchema, parentSchemaUiPath, parentUiSchema);
            } else {
                uiSchema = parentUiSchema;
            }
        }
    }

    return uiSchema;
};

export const updateRequired = (state, elementId, isRequired) => {
    const activeColumn = getActiveColumn(state);
    const activeRow = getActiveRow(state);
    let parentElement = activeColumn ? activeColumn : activeRow;

    let requiredList = (parentElement["required"] || []).slice();
    const elementIndex = requiredList.indexOf(elementId);

    if (isRequired) {
        if (elementIndex === -1) {
            requiredList.push(elementId);
        }
    } else {
        if (elementIndex > -1) {
            requiredList.splice(elementIndex, 1);
        }
    }

    parentElement = {
        ...parentElement,
        required: requiredList,
    };

    return parentElement;
};

// Conver form id root_field1_field2 to field1.field2
export const formIdToSchemaId = (elementId) => {
    if (!elementId) {
        return null;
    }
    if (elementId.indexOf("_") === -1) {
        return elementId.indexOf(".") === -1 ? "" : elementId;
    }

    return elementId.split("_").slice(1).join(".");
};

export const getSchemaIds = memoizeOne((schema, fieldsOnly = false) => {
    if (!isObject(schema) || !isObject(schema.properties)) {
        return [];
    }

    const properyNames = (properties) => {
        return isObject(properties)
            ? Object.keys(properties)
                  .map((key) => {
                      const childNames = properyNames(properties[key].properties || {}).map((name) => `${key}.${name}`);

                      return [].concat([key], childNames);
                  })
                  .reduce((result, next) => result.concat(next), [])
            : [];
    };

    let result = properyNames(schema.properties);

    if (fieldsOnly) {
        result = result
            .map((id) => {
                const pathParts = id.split(".");

                const path = `properties.${pathParts.join(".properties.")}`;
                const elementSchema = get(schema, path);

                if (elementSchema.type && elementSchema.type !== "object") {
                    return {
                        type: elementSchema.type,
                        title: elementSchema.title,
                        format: elementSchema.format,
                        id: id,
                    };
                }

                return null;
            })
            .filter((id) => id !== null);
    }

    return result;
});

export const setInitialValuesByFieldNumber = (schema, uiSchema, values) => {
    const schemaIds = getSchemaIds(schema);

    return schemaIds.reduce((result, path) => {
        const fieldNumber = getFieldNumber(uiSchema, path);

        if (fieldNumber) {
            const field = values.filter((v) => v.number === fieldNumber)[0];
            const fieldWidget = getFieldWidget(uiSchema, path);

            if (field) {
                const schemaPath = `properties.${path.split(".").join(".properties.")}`;
                const fieldSchema = get(schema, schemaPath);
                const fieldType = fieldSchema?.type;

                let value;

                switch (fieldWidget) {
                    case "checkbox":
                    case "largecheckbox":
                        value = `${field.value}`.toLowerCase() === "true";
                        break;
                    case "generalprocedure":
                        value = String(field.value);
                        break;
                    default:
                        if (["number", "integer"].includes(fieldType)) {
                            value = Number(field.value);

                            if (isNaN(value) && isString(field.value)) {
                                let valueStr = field.value;

                                // Remove currency symbol
                                if (valueStr[0] === CURRENCY_SYMBOL) {
                                    valueStr = valueStr.slice(1);
                                }

                                // Remove grouping separators
                                if (valueStr.indexOf(",") > -1) {
                                    valueStr = valueStr.split(",").join("");
                                }

                                value = Number(valueStr);
                            }
                        } else if (["array"].includes(fieldType)) {
                            value = getCommaSeparatedValueAsArray(field.value, fieldSchema);
                        } else {
                            value = field.value;
                        }

                        break;
                }

                set(result, path, value);
            } else {
                // set todays date if no date is set for defaultdate widget
                if (fieldWidget === "defaultdate") {
                    set(result, path, localizeJsonDate(dateToJson(new Date())));
                }
            }
        }

        return result;
    }, {});
};

/**
 * Generate form initial values object with
 * default date widget values set to current date.
 *
 * @param {object} params
 * @param {object} params.schema Form schema
 * @param {object} params.uiSchema Form uiSchema
 * @returns {object} Form initial values
 */
export const setDefaultDateValues = ({ schema, uiSchema }) => {
    const schemaIds = getSchemaIds(schema);

    return schemaIds.reduce((result, path) => {
        const fieldNumber = getFieldNumber(uiSchema, path);

        if (fieldNumber) {
            const fieldWidget = getFieldWidget(uiSchema, path);

            // set todays date for defaultdate widget
            if (fieldWidget === "defaultdate") {
                set(result, path, dateToJson(new Date()));
            }
        }

        return result;
    }, {});
};

export const addNonExistingValueOptionsToSchema = ({ schema, initialValues }) => {
    const schemaIds = getSchemaIds(schema);
    let updatedSchema = cloneDeep(schema);

    schemaIds.forEach((path) => {
        const schemaPath = `properties.${path.split(".").join(".properties.")}`;
        const fieldSchema = get(schema, schemaPath);

        let availableOptions = [];
        let optionsPath = "";

        if (fieldSchema?.type === "array") {
            availableOptions = fieldSchema?.items?.anyOf ?? [];
            optionsPath = ".items.anyOf";
        }

        // Radio buttons has list of values in anyOf property
        if (!isNil(fieldSchema?.anyOf)) {
            availableOptions = fieldSchema?.anyOf ?? [];
            optionsPath = ".anyOf";
        }

        if (availableOptions.length > 0) {
            const fieldValue = get(initialValues, path);
            const availableValues = availableOptions.map((item) => item.enum[0]);
            const notAvailableOptions = toArray(fieldValue)
                .filter((item) => !availableValues.includes(item))
                .map((item) => ({
                    title: item,
                    enum: [item],
                }));

            if (notAvailableOptions.length > 0) {
                set(updatedSchema, schemaPath + optionsPath, [].concat(notAvailableOptions, availableOptions));
            }
        }
    });

    return updatedSchema;
};

export const getCommaSeparatedValueAsArray = (value, fieldSchema) => {
    const processCommaSeparatedString = (input) => {
        let result = [];
        const availableValues = (fieldSchema?.items?.anyOf ?? []).map((item) => item.enum[0]);

        let valuesList = (input || "").split(",").map((i) => i.trim());
        let nonExistingValues = [];

        // Return index offest to add to processed values list
        const processValue = (availableValue, valueListIndex) => {
            const valueParts = (availableValue ?? "").split(",").map((i) => i.trim());
            let isCorrectValue = true;

            // check if we have all value parts as separate values
            for (let valuePartIndex = 0; valuePartIndex < valueParts.length; valuePartIndex++) {
                if (valueParts[valuePartIndex] !== valuesList[valueListIndex + valuePartIndex]) {
                    isCorrectValue = false;
                }
            }

            // Update values list if correct value found
            if (isCorrectValue) {
                result.push(availableValue);
            }

            return isCorrectValue ? valueParts.length : 0;
        };

        for (let valueIndex = 0; valueIndex < valuesList.length; valueIndex++) {
            const item = valuesList[valueIndex];

            // If value is not in the list of available values then process it
            if (!availableValues.includes(item)) {
                let isExistingValue = false;

                for (let availableValueIndex = 0; availableValueIndex < availableValues.length; availableValueIndex++) {
                    const availableValue = availableValues[availableValueIndex];
                    const offset = processValue(availableValue, valueIndex);

                    // If value was found jump to next unprocessed value in list
                    if (offset > 0) {
                        valueIndex += offset - 1;
                        isExistingValue = true;
                        break;
                    }
                }

                // Store as nonexsiting value if it is not in available values list
                if (!isExistingValue) {
                    nonExistingValues.push(item);
                }
            } else {
                // Set value as is if exists in available values list
                result.push(item);
            }
        }

        // Add nonexisting values to result
        if (nonExistingValues.length) {
            result.push(nonExistingValues.join(", "));
        }

        return result;
    };

    let result = [];

    try {
        var arrayValue = toArray(JSON.parse(value)).join(",");
        result = processCommaSeparatedString(arrayValue);
    } catch {
        result = processCommaSeparatedString(value);
    }

    return result;
};

export const getFieldNumber = (uiSchema, path) => {
    return get(uiSchema, path + ".af:fieldNumber");
};

export const getFieldWidget = (uiSchema, path) => {
    return get(uiSchema, path + ".ui:widget");
};

export const submitByRefPromise = (formRef) => {
    return new Promise((resolve, reject) => {
        submitByRef(formRef, (errors, formData) => (errors ? reject(errors) : resolve(formData)));
    });
};

export const submitByRef = (formRef, callback) => {
    // Perform some additional actions like out of form validation when trying to submit the form
    if (isFunction(formRef?.current?.preSubmit)) {
        // In case when preSubmit causes rerender the form does not show errors without using setTimeout.
        setTimeout(() => {
            if (isFunction(formRef?.current?.preSubmit)) {
                formRef.current.preSubmit();
            }
        }, 0);
    }

    if (formRef.current) {
        const submitCallback = (errors, formData) => {
            formRef.current.submitCallback = undefined;

            if (callback) {
                callback(errors, formData);
            }
        };

        formRef.current.submitCallback = submitCallback;

        // Do we have requestSubmit method? (will not reload page on Firefox, not supported on IE11)
        if (formRef.current.formElement.requestSubmit) {
            formRef.current.formElement.requestSubmit();
        }

        // Dispatch event (will reload page on Firefox)
        else {
            dispatchEvent(formRef.current.formElement, "submit");
        }
    } else {
        const error = {
            message: "Invalid Form Reference",
        };

        if (callback) {
            callback(error);
        }
    }
};

export const listToAnyOf = ({ list, map, sort = "asc", emptyItem, skipEmptyItem }) => {
    const emptyItemTitle = isString(emptyItem) && emptyItem.length > 0 ? emptyItem : " ";
    const empty = skipEmptyItem ? [] : [{ title: emptyItemTitle, enum: [undefined] }];

    const normalizeItem = (item) => {
        const title = isString(item.title) ? item.title : isNil(item.title) ? "" : String(item.title);

        return {
            ...item,
            title,
        };
    };

    let optionList = list.length > 0 ? list.map(map).map(normalizeItem) : empty;

    if (["asc", "desc"].includes(sort)) {
        optionList = orderBy(optionList, [(item) => (item.title ?? "").toLowerCase()], [sort]);
    }

    if (emptyItem && list.length > 0) {
        return empty.concat(optionList);
    }

    return optionList;
};

export const referenceToAnyOf = ({ list = [], sort = "asc", type = "number", emptyItem }) => {
    return listToAnyOf({
        list,
        map: (i) => ({
            title: i.display,
            enum: type === "number" ? [Number(i.val)] : [i.val],
        }),
        sort,
        emptyItem,
    });
};

export const referenceToDropDownList = ({ list, sort = "asc" }) => {
    const sortedList = ["asc", "desc"].includes(sort) ? orderBy(list, [(item) => item.display.toLowerCase()], [sort]) : list;

    return (
        (sortedList &&
            sortedList.map((i) => ({
                label: i.display,
                value: Number(i.val),
            }))) ||
        []
    );
};

export const pickInitialValues = (data) => {
    return pickBy(data, (o) => isString(o) || isNumber(o) || isBoolean(o) || isObject(o));
};

export const pickString = (value) => {
    return isString(value) ? value : undefined;
};

export const pickNumber = (value) => {
    return isNumber(value) && !isNaN(value) ? value : undefined;
};

export const pickNumberFromClassifier = (value, classifier) => {
    return Object.keys(classifier).some((key) => Number(value) === Number(key)) ? Number(value) : undefined;
};

export const pickNoYesValue = (value) => {
    return anyOfNoYes.some((i) => (value || "").toUpperCase() === i.enum[0]) ? value.toUpperCase() : "N"; // Default is No
};

export const pickDefaultPagePropertyValue = (value) => {
    return anyOfTrueFalse.some((i) => Number(value) === Number(i.enum[0])) ? Number(value) : DEFAULT_PAGE_PROP_DEFAULTPAGE; //Default is false
};

export const pickPageStatusPropertyValue = (value) => {
    return anyOfStatuses.some((i) => Number(value) === Number(i.enum[0])) ? Number(value) : DEFAULT_PAGE_PROP_STATUS; //Default is disabled
};

export const anyOfTrueFalse = [
    {
        title: "False",
        enum: [TrueFalse.False],
    },
    {
        title: "True",
        enum: [TrueFalse.True],
    },
];

export const anyOfNoYes = [
    {
        title: "No",
        enum: [YesNo.No],
    },
    {
        title: "Yes",
        enum: [YesNo.Yes],
    },
];

export const anyOfNoYesInteger = [
    {
        title: "No",
        enum: [TrueFalse.False],
    },
    {
        title: "Yes",
        enum: [TrueFalse.True],
    },
];

export const anyOfStatuses = [
    {
        title: "Active",
        enum: [212],
    },
    {
        title: "Disabled",
        enum: [213],
    },
];

export const submitResource = ({
    resourceParams,
    resourceId,
    body,
    noResourceRefresh,
    showSuccessNotification = true,
    onRefresh,
    onSuccess,
    onComplete,
    setSubmitting = () => {},
}) => {
    // Refresh the resource if not disabled with noResourceRefresh prop.
    const refreshResource = () => {
        if (resourceId && !noResourceRefresh) {
            store.dispatch(
                getResource({
                    ...resourceParams,
                    resourceId,
                })
            );
        }
    };

    const submitParams = {
        ...resourceParams,
        body,
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
                refreshResource();
            } else {
                refreshResource();
                setSubmitting(false);
            }

            onRefresh && onRefresh();
        },
        onError: () => setSubmitting(false),
        onComplete: (action) => onComplete && onComplete(action),
        showSuccessNotification,
    };

    setSubmitting(true);

    if (resourceId) {
        store.dispatch(
            updateResource({
                ...submitParams,
                resourceId,
            })
        );
    } else {
        store.dispatch(createResource(submitParams));
    }
};

export const initRules = () => {
    const initialEqualPredicate = predicate.equal;

    // Create rule "equal"
    predicate.equal = predicate.curry((fieldVal, ruleVal) => {
        if (predicate.arr(fieldVal)) {
            // handle numeric values
            if (fieldVal.length > 0 && isNumber(fieldVal[0])) {
                return predicate.includes(fieldVal, Number(ruleVal));
            }

            return predicate.includes(fieldVal, ruleVal);
        }

        if (isNumber(fieldVal)) {
            return initialEqualPredicate(fieldVal, Number(ruleVal));
        }

        if (isBoolean(fieldVal)) {
            return initialEqualPredicate(String(fieldVal), ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return initialEqualPredicate(
                    datePartFromJsonDate(dateStringToJsonDate(fieldVal)),
                    datePartFromJsonDate(dateToJson(ruleDate))
                );
            }
        }

        return initialEqualPredicate(fieldVal, ruleVal);
    });

    // Create rule "notEqual"
    predicate.notEqual = predicate.curry((fieldVal, ruleVal) => {
        if (predicate.arr(fieldVal)) {
            return !predicate.includes(fieldVal, ruleVal);
        }

        return !predicate.equal(fieldVal, ruleVal);
    });

    // Create rule "empty"
    predicate.empty = (fieldVal) => {
        if (predicate.not.exists(fieldVal)) {
            return true;
        }

        if (predicate.arr(fieldVal) || predicate.str(fieldVal)) {
            return !fieldVal.length;
        }

        if (predicate.number(fieldVal)) {
            return false;
        }

        if (predicate.obj(fieldVal)) {
            for (let prop in fieldVal) {
                if (predicate.has(fieldVal, prop)) {
                    return false;
                }
            }

            return true;
        }

        if (isBoolean(fieldVal)) {
            return !fieldVal;
        }

        return false;
    };

    // Create rule "less"
    predicate.less = predicate.curry(function (fieldVal, ruleVal) {
        if (isNumber(fieldVal)) {
            return fieldVal < Number(ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return datePartFromJsonDate(dateStringToJsonDate(fieldVal)) < datePartFromJsonDate(dateToJson(ruleDate));
            }
        }

        return fieldVal < ruleVal;
    });

    // Create rule "greater"
    predicate.greater = predicate.curry(function (fieldVal, ruleVal) {
        if (isNumber(fieldVal)) {
            return fieldVal > Number(ruleVal);
        }

        if (isValidDateFormatFromInput(fieldVal)) {
            const dateValue = getDateValueForInput({ value: fieldVal, isFormatDate: true });
            if (isDate(dateValue)) {
                const ruleDate = ruleVal === "today" ? new Date() : getDateValueForInput({ value: ruleVal, isFormatDate: true });
                return datePartFromJsonDate(dateStringToJsonDate(fieldVal)) > datePartFromJsonDate(dateToJson(ruleDate));
            }
        }

        return fieldVal > ruleVal;
    });

    // Create rule "lessEq"
    predicate.lessEq = predicate.curry(function (fieldVal, ruleVal) {
        return predicate.equal(fieldVal, ruleVal) || predicate.less(fieldVal, ruleVal);
    });

    // Create rule "greaterEq"
    predicate.greaterEq = predicate.curry(function (fieldVal, ruleVal) {
        return predicate.equal(fieldVal, ruleVal) || predicate.greater(fieldVal, ruleVal);
    });
};

export const formatRule = (rule) => {
    const field = Object.keys(rule.conditions)[0];
    const isRuleWithoutValue = isString(rule.conditions[field]);
    const isOrCondition = Object.keys(rule.conditions[field])?.[0] === "or" && Array.isArray(rule.conditions[field].or);
    const isAndCondition = Object.keys(rule.conditions[field])?.[0] === "and" && Array.isArray(rule.conditions[field].and);
    const isBetweenCondition =
        Object.keys(rule.conditions[field])?.[0] === "greaterEq" && Object.keys(rule.conditions[field])?.[1] === "lessEq";

    const condition = isRuleWithoutValue
        ? rule.conditions[field]
        : Object.keys(rule.conditions[field])[0] === "not"
        ? "notEmpty"
        : isOrCondition
        ? "anyOf"
        : isAndCondition
        ? "notAnyOf"
        : isBetweenCondition
        ? "between"
        : Object.keys(rule.conditions[field])[0];

    const value = isRuleWithoutValue
        ? ""
        : condition === "notEmpty"
        ? ""
        : isOrCondition
        ? rule.conditions[field].or.map((i) => Object.values(i)[0])
        : isAndCondition
        ? rule.conditions[field].and.map((i) => Object.values(i)[0])
        : isBetweenCondition
        ? [rule.conditions[field].greaterEq, rule.conditions[field].lessEq]
        : rule.conditions[field][condition];

    return JSON.stringify({
        field,
        condition,
        value,
    });
};

export const setFormDataById = ({ formId, formData }) => {
    if (formId) {
        store.dispatch(setFormData({ formId, formData }));
    }
};

export const addExtraError = ({ id, message, extraErrors }) => {
    if (id?.length > 0) {
        // Remove existing field errors.
        let errors = extraErrors.filter((e) => e.id !== id);

        // Do not add error if no message.
        if (isNil(message)) {
            return errors;
        }

        // Add error.
        return [].concat(errors, { id, message });
    } else {
        throw Error("Error id not specified");
    }
};

// Clear extra errors if there are any
export const clearExtraErrors = (id, formContext) => {
    if (formContext?.extraErrors.filter((e) => e.id === id).length > 0) {
        setTimeout(() => {
            if (formContext.setExtraError) {
                formContext.setExtraError(id, undefined);
            }
        }, 0);
    }
};

export const standardFormValidate = ({ formData, errors, validate, extraErrors, idPrefix }) => {
    const isRootKeyWithUnderscore = Object.keys(errors)
        .filter((key) => !["__errors"].includes(key))
        .some((key) => {
            if (!isEmpty(idPrefix) && key.startsWith(idPrefix)) {
                return key.substr(key.indexOf("_") + 1).includes("_");
            }

            return key.includes("_");
        });

    const getKey = (id) => {
        let key = id;

        if (!isEmpty(idPrefix) && id.startsWith(idPrefix)) {
            key = id.substr(id.indexOf("_") + 1);
        }

        // If form has keys with underscores the key is returned as is.
        if (isRootKeyWithUnderscore) {
            return key;
        }

        return key.split("_").join(".");
    };

    // Add extra errors
    extraErrors.forEach(({ id, message }) => {
        const key = getKey(id);

        const fieldErrors = get(errors, key);

        if (fieldErrors) {
            fieldErrors.addError(message);
        }
    });

    // If available, run custom validation function
    if (isFunction(validate)) {
        return validate(formData, errors);
    }

    return errors;
};

export const customFormValidate = ({ formData, errors, schema, validate, extraErrors, idPrefix, uiSchema }) => {
    errors = standardFormValidate({
        formData,
        errors,
        validate,
        extraErrors,
        idPrefix,
    });

    // TODO: Need to refactor. This is very slow.

    const schemaIds = getSchemaIds(schema, true);

    schemaIds.forEach((item) => {
        const schemaId = item.id;
        const schemaPath = `properties.${schemaId.split(".").join(".properties.")}`;
        const fieldSchema = get(schema, schemaPath);
        const fieldUiSchema = get(uiSchema, schemaId);

        if (fieldSchema && (fieldSchema.formatMinimum || fieldSchema.formatMaximum)) {
            const value = get(formData, schemaId);
            const isFormatDate = fieldSchema.format !== "date-time";

            const fieldDateValue = getDateValueForInput({
                value,
                isFormatDate,
            });

            const todayDate = isFormatDate ? getDateWithoutTime(new Date()) : new Date();

            const formatMinimum =
                fieldSchema.formatMinimum &&
                (fieldSchema.formatMinimum === "today" ? todayDate : jsonDateToDate(fieldSchema.formatMinimum));

            const formatMaximum =
                fieldSchema.formatMaximum &&
                (fieldSchema.formatMaximum === "today" ? todayDate : jsonDateToDate(fieldSchema.formatMaximum));

            if (isDate(fieldDateValue)) {
                if (formatMinimum && formatMinimum > fieldDateValue) {
                    const fieldErrorSchema = get(errors, schemaId);
                    fieldErrorSchema.addError("Minimum allowed date is " + formatJsonDate(dateToJson(formatMinimum)));
                }

                if (formatMaximum && formatMaximum < fieldDateValue) {
                    const fieldErrorSchema = get(errors, schemaId);
                    fieldErrorSchema.addError("Maximum allowed date is " + formatJsonDate(dateToJson(formatMaximum)));
                }
            }
        }

        // Add min/max validation for field type number and integer,ticket:V50-8091
        if (["text"].includes(fieldUiSchema?.["ui:widget"]) && (item.type === "number" || item.type === "integer")) {
            const value = get(formData, schemaId);
            if (!isNil(value)) {
                const fieldErrorSchema = get(errors, schemaId);
                if (fieldSchema.hasOwnProperty("minLength") && value.toString().length < fieldSchema["minLength"]) {
                    fieldErrorSchema.addError(
                        `should NOT be shorter than ${fieldSchema["minLength"]} ${
                            fieldSchema["minLength"] > 1 ? "characters" : "character."
                        }`
                    );
                } else if (fieldSchema.hasOwnProperty("maxLength") && value.toString().length > fieldSchema["maxLength"]) {
                    fieldErrorSchema.addError(
                        `should NOT be longer than ${fieldSchema["maxLength"]} ${
                            fieldSchema["maxLength"] > 1 ? "characters." : "character."
                        }`
                    );
                }
            }
        }

        // Validate pattern for array fields with string items
        if (fieldSchema.type === "array" && fieldSchema.items) {
            const value = get(formData, schemaId);
            const fieldErrorSchema = get(errors, schemaId);

            if (value && fieldSchema.pattern) {
                const pattern = new RegExp(fieldSchema.pattern);

                if (Array.isArray(value)) {
                    if (!pattern.test(value.join(","))) {
                        fieldErrorSchema.addError(`should match pattern "${fieldSchema.pattern}"`);
                    }
                } else if (!pattern.test(value)) {
                    fieldErrorSchema.addError(`should match pattern "${fieldSchema.pattern}"`);
                }
            }
        }

        // Validate signature field
        if (fieldUiSchema?.["ui:widget"] === "signature") {
            const value = get(formData, schemaId);
            const parent = getParentSchemasById({ elementId: schemaId, schema, uiSchema });
            const fieldId = schemaId.split(".").pop();
            const isRequired = (parent?.schema?.required ?? []).includes(fieldId);

            if (isRequired) {
                const fieldErrorSchema = get(errors, schemaId);
                const [signature, checked] = (value || "").split("|");

                const requiredMessage = "is a required property";
                let signatureError = "";
                let confirmationError = "";

                if (isNullOrWhitespace(signature)) {
                    signatureError = requiredMessage;
                }

                if (checked !== "true") {
                    confirmationError = requiredMessage;
                }

                if (!isEmpty(signatureError) || !isEmpty(confirmationError)) {
                    fieldErrorSchema.addError(
                        JSON.stringify({
                            signature: signatureError,
                            confirmation: confirmationError,
                        })
                    );
                }
            }
        }
    });

    if (isFunction(validate)) {
        errors = validate(formData, errors);
    }

    return errors;
};

export const transformApplicationFormErrors = (errors, schema, uiSchema, formContext) => {
    const valueLengthErrorNames = ["minLength", "maxLength"];

    // Allow value length validation only in listed widgets
    const valueLengthValidationWidgets = ["text"];

    errors = errors.filter((error) => {
        const fieldPath = (error.property || "").slice(1);
        const fieldWidget = get(uiSchema, fieldPath + ".ui:widget");

        if (valueLengthErrorNames.includes(error.name)) {
            // Keep error if present in allowed widgets list
            return valueLengthValidationWidgets.includes(fieldWidget);
        }

        // ignore this error if we are setting date value in localized format
        if (error.name === "format" && ["date", "date-time", "time"].includes(error.params?.format) && formContext?.localizeDateValues) {
            return false;
        }

        if (fieldWidget === "signature") {
            return false;
        }

        return true;
    });

    return errors;
};

export const getApplicationFormSubmitValues = (formData, initialFields) => {
    const normalizeValue = (value, key) => {
        if (isString(value)) {
            return value;
        }

        if (Array.isArray(value)) {
            return value.join(",");
        }

        return JSON.stringify(value);
    };

    const formDataKeys = Object.keys(formData);

    let submitValues = formDataKeys
        .map((key) => ({
            fieldNumber: key,
            fieldValue: normalizeValue(formData[key], key),
        }))
        .filter((f) => f.fieldValue !== undefined);

    (initialFields || []).forEach((field) => {
        // Leave disabled fields as is
        if (!formDataKeys.includes(field.number)) {
            submitValues.push({
                fieldNumber: field.number,
                fieldValue: field.value,
            });
        }
        // Add cleared values
        else if (isNil(formData[field.number]) && !isNil(field.value)) {
            submitValues.push({
                fieldNumber: field.number,
                fieldValue: undefined,
            });
        }
    });

    return submitValues;
};

/**
 * Checks whether a string matches an email pattern.
 * @param {string} value
 * @returns {boolean}
 */
export const isEmailAddress = (value) => {
    const emailEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailRegEx = new RegExp(emailEx);

    return emailRegEx.test(value);
};

/**
 * validate multiple emails for ftp config.
 * @param {string} emails
 * @returns {boolean}
 */
export const isMultipleEmailsValidate = (emails) => {
    const emailsArr = emails.replace(/\s+/g, "").split(";");
    return emailsArr.every(isEmailAddress);
};

/**
 * Checks whether a string is not empty or whitespace.
 * @param {string} value
 * @returns {boolean}
 */
export const isNonEmptyString = (value) => {
    const regEx = new RegExp(/^(?!\s*$).+/);
    return regEx.test(value);
};

/**
 * Check if First and Last name or Company is filled.
 * @param {*} { firstName, lastName, company }
 * @returns {boolean}
 */
export const isNameOrCompany = ({ firstName, lastName, company }) => {
    return company || (firstName && lastName);
};

export const isEmailAddressErrorText = "Email address must be valid email address";
export const isNameOrCompanyErrorText = "First Name and Last Name or Company Name is Required";

export const normalizeFormConfiguration = ({ configuration }) => {
    // Align page properties
    if (configuration.schema) {
        if (!configuration.schema.required) {
            configuration.schema = {
                ...configuration.schema,
                required: [],
            };
        }
        if (!configuration.schema.description) {
            configuration.schema = {
                ...configuration.schema,
                description: "",
            };
        }
    } else {
        logError(`normalizeFormConfiguration page schema not found`);
    }

    // Align page ui properties
    if (configuration.uiSchema) {
        configuration.uiSchema = {
            ...configuration.uiSchema,
            "af:defaultPage": pickDefaultPagePropertyValue(configuration.uiSchema["af:defaultPage"]),
            "af:disqualificationPage": pickNoYesValue(configuration.uiSchema["af:disqualificationPage"]),
            "af:status": pickPageStatusPropertyValue(configuration.uiSchema["af:status"]),
            "af:allowEditAppForm": pickNoYesValue(configuration.uiSchema["af:allowEditAppForm"]),
            "af:denyLimitedAccess": pickNoYesValue(configuration.uiSchema["af:denyLimitedAccess"]),
            "af:runAppEntryStatusInWizard": pickNoYesValue(configuration.uiSchema["af:runAppEntryStatusInWizard"]),
            "af:runAppReceivedStatusInWizard": pickNoYesValue(configuration.uiSchema["af:runAppReceivedStatusInWizard"]),
            "af:showFormPageOnApp": pickNoYesValue(configuration.uiSchema["af:showFormPageOnApp"]),
        };
    } else {
        logError(`normalizeFormConfiguration page ui schema not found`);
    }

    const fieldIds = getSchemaIds(configuration.schema);

    fieldIds.forEach((item) => {
        const elementId = "root_" + item.split(".").join("_");

        const formBuilderState = {
            schema: configuration.schema,
            uiSchema: configuration.uiSchema,
            selectedElementId: elementId,
        };

        const elementType = getElementTypeByPath({ path: elementId });
        const elementUiSchema = getSelectedElementUiParams(formBuilderState);
        const elementSchema = getSelectedElement(formBuilderState);

        if (elementType === ElementTypes.COLUMN) {
            configuration = {
                ...configuration,
                ...updateColumnMissingProperties({
                    formBuilder: formBuilderState,
                }),
            };
        } else if (elementType === ElementTypes.FIELD) {
            const activeSectionId = getActiveSectionId(formBuilderState);
            const activeColumnId = getActiveColumnId(formBuilderState);
            const activeRowId = getActiveRowId(formBuilderState);
            const activeFieldId = getActiveFieldId(formBuilderState);
            const activeParentId = activeColumnId ? activeColumnId : activeRowId;

            const validationRequired = elementUiSchema["af:validationRequired"];

            // Delete unneeded props from parent
            delete elementUiSchema["order"];
            delete elementUiSchema["required"];

            switch (elementUiSchema["af:validationType"]) {
                case "344": // Regex
                    if (!isEmpty(elementUiSchema["af:validationAdd"]) && !elementSchema["pattern"]) {
                        if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
                            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                                ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                                pattern: elementUiSchema["af:validationAdd"],
                            };
                        } else {
                            logError(
                                `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
                            );
                        }
                    }

                    break;
                case "12": // Date
                    setFieldAsDate({
                        configuration,
                        elementUiSchema,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    break;
                case "503": // LimitedNumeric
                    setFieldAsNumeric({
                        configuration,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    updateMinMaxValueValidation({
                        configuration,
                        elementUiSchema,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    break;
                case "577": // OtherNumeric
                    setFieldAsNumeric({
                        configuration,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    break;
                case "13": // Email
                    if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
                        configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                            ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                            format: "email",
                        };
                    } else {
                        logError(
                            `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
                        );
                    }

                    break;
                case "463": // Date with limits
                    setFieldAsDate({
                        configuration,
                        elementUiSchema,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    updateDateRangeValidation({
                        configuration,
                        elementUiSchema,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });

                    break;
                case "16": // Other
                    break;
                case "11": // Text
                    break;
                case "14": // Number
                    setFieldAsNumeric({
                        configuration,
                        activeSectionId,
                        activeParentId,
                        activeFieldId,
                    });
                    break;
                default:
                    break;
            }

            // validationPivot
            if (elementUiSchema["af:validationPivot"]) {
                const otherFieldValue = elementUiSchema["af:validationAdd"] ?? "";
                const otherFieldPath =
                    // Forms generated from legacy have fieldId in validationPivot prop
                    getFieldPathByFieldId({
                        schema: configuration.schema,
                        uiSchema: configuration.uiSchema,
                        fieldId: elementUiSchema["af:validationPivot"],
                    }) ??
                    // V5 forms have fieldNumber in validationPivot prop
                    getFieldPathByFieldNumber({
                        schema: configuration.schema,
                        uiSchema: configuration.uiSchema,
                        fieldNumber: elementUiSchema["af:validationPivot"],
                    });

                if (otherFieldPath) {
                    if (configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]) {
                        configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                            ...elementUiSchema,
                            "af:validationPivot": getFieldNumber(configuration.uiSchema, otherFieldPath),
                        };
                    }

                    // Add new rules generated from legacy fields if not already added
                    otherFieldValue.split("|").forEach((value) => {
                        let condition = "empty";

                        if (!isNullOrWhitespace(value)) {
                            condition = {
                                ...(value === "*any*" ? { not: "empty" } : { equal: value }),
                            };
                        }

                        const newRule = {
                            conditions: {
                                [otherFieldPath]: condition,
                            },
                            event: {
                                type: "require",
                                params: {
                                    field: item,
                                },
                            },
                        };

                        if (!(configuration.rules || []).some((rule) => isEqual(rule, newRule))) {
                            configuration.rules = [newRule].concat(configuration.rules || []);
                        }
                    });
                }
            } else {
                updateRequredValidation({
                    validationRequired,
                    configuration,
                    formBuilderState,
                    activeSectionId,
                    activeParentId,
                    activeFieldId,
                });
            }

            // field status
            if (configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "ui:disabled": (elementUiSchema["af:status"] || "").toLowerCase() === "disabled",
                };
            }

            // Set default fieldDescriptor "TEXT" if not present in field config
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                isNil(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:fieldDescriptor"])
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "af:fieldDescriptor": FIELD_DESCRIPTOR_TEXT,
                };
            }

            // fieldDescriptor should be "DB" for "ui:widget": "generalprocedure"
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"] === "generalprocedure" &&
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:fieldDescriptor"] !== FIELD_DESCRIPTOR_DB
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "af:fieldDescriptor": FIELD_DESCRIPTOR_DB,
                };
            }

            // Date align
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                ["date", "defaultdate"].includes(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"])
            ) {
                if (
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] &&
                    !["date", "date-time", "time"].includes(
                        configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].format
                    )
                ) {
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                        ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                        format: "date",
                    };
                }
            }

            // FieldGroup align
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                ["42", "43"].includes(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:fieldGroup"])
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "af:fieldGroup": Number(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:fieldGroup"]),
                };
            }

            // set default "ui:widget" if not specified
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                !configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"]
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "ui:widget": "text",
                };
            }

            // Change af:separator to ui:separator to be accessible in widget options
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:separator"]
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "ui:separator": configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:separator"],
                };

                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:separator"];
            }

            // Ensure checkbox widget data type is boolean
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"] === "checkbox"
            ) {
                if (
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] &&
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type !== "boolean"
                ) {
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                        ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                        type: "boolean",
                    };
                }
            }

            // Ensure readOnlyValue widget data type is string
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"] === "readonlyvalue"
            ) {
                if (
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] &&
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type !== "string"
                ) {
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                        ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                        type: "string",
                    };
                }
            }

            // If number field has incompatible widget fall back to text input
            if (
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] &&
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type === "number"
            ) {
                if (
                    configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                    ["textarea"].includes(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"])
                ) {
                    configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                        ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                        "ui:widget": "text",
                    };
                }
            }

            // Convert number field list items to numbers.
            if (
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] &&
                ["integer", "number"].includes(
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type
                ) &&
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]?.anyOf?.length > 0
            ) {
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].anyOf =
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].anyOf.map(
                        (i) => ({
                            ...i,
                            enum: [Number(i.enum[0])],
                        })
                    );
            }

            // Set minimum/maximum for numbers
            if (["number", "integer", "string"].includes(elementSchema.type)) {
                updateMinMaxValueValidation({
                    configuration,
                    elementUiSchema,
                    activeSectionId,
                    activeParentId,
                    activeFieldId,
                });
            }

            updateLengthValidation({
                configuration,
                elementSchema,
                elementUiSchema,
                activeSectionId,
                activeParentId,
                activeFieldId,
            });

            if (configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]) {
                // Remove legacy properties if exists.
                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["fieldLength"];
                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:minLength"];
                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:maxLength"];
                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:lowLimit"];
                delete configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:highLimit"];

                const validationType = configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:validationType"];

                // Create empty props if not exist
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "af:dbString": configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:dbString"] ?? undefined,
                    "af:validationAdd":
                        configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:validationAdd"] ?? undefined,
                    "af:validationPivot":
                        configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:validationPivot"] ?? undefined,
                    "af:validationType": validationType === "0" ? undefined : validationType,
                    "af:friendlyName":
                        configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:friendlyName"] ?? undefined,
                    "af:fieldDescriptor":
                        configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["af:fieldDescriptor"] ?? undefined,
                };
            }

            // Replace all unknown widget types with readonlyvalue.
            if (
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] &&
                !isValidWidget(configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]["ui:widget"])
            ) {
                configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                    ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                    "ui:widget": "readonlyvalue",
                };
            }
        }

        // Align required props for sections
        if (elementType === ElementTypes.SECTION) {
            const activeSectionId = getActiveSectionId(formBuilderState);

            if (configuration.schema.properties[activeSectionId]) {
                configuration.schema.properties[activeSectionId] = {
                    ...configuration.schema.properties[activeSectionId],
                    required: configuration.schema.properties[activeSectionId].required ?? [],
                    description: configuration.schema.properties[activeSectionId].description ?? "",
                };
            } else {
                logError(`normalizeFormConfiguration section not found: ${activeSectionId}`);
            }
        }

        // Align required props for column
        if (elementType === ElementTypes.COLUMN) {
            const activeSectionId = getActiveSectionId(formBuilderState);
            const activeColumnId = getActiveColumnId(formBuilderState);

            if (configuration.schema.properties[activeSectionId].properties[activeColumnId]) {
                configuration.schema.properties[activeSectionId].properties[activeColumnId] = {
                    ...configuration.schema.properties[activeSectionId].properties[activeColumnId],
                    required: configuration.schema.properties[activeSectionId].properties[activeColumnId].required ?? [],
                    description: configuration.schema.properties[activeSectionId].properties[activeColumnId].description ?? "",
                };
            } else {
                logError(`normalizeFormConfiguration column not found: ${activeSectionId}.${activeColumnId}`);
            }
        }

        // Remove class "required"
        if (isString(elementUiSchema?.classNames) && elementUiSchema.classNames.indexOf("required") > -1) {
            const updatedClassNames = elementUiSchema.classNames
                .split(" ")
                .filter((c) => c !== "required")
                .join(" ");

            switch (elementType) {
                case ElementTypes.PAGE:
                    configuration.uiSchema.classNames = updatedClassNames;
                    break;
                case ElementTypes.SECTION:
                    set(configuration.uiSchema, `${getActiveSectionId(formBuilderState)}.classNames`, updatedClassNames);
                    break;
                case ElementTypes.COLUMN:
                    set(
                        configuration.uiSchema,
                        `${getActiveSectionId(formBuilderState)}.${getActiveColumnId(formBuilderState)}.classNames`,
                        updatedClassNames
                    );
                    break;
                case ElementTypes.FIELD:
                    set(
                        configuration.uiSchema,
                        `${getActiveSectionId(formBuilderState)}.${getActiveColumnId(formBuilderState)}.${getActiveFieldId(
                            formBuilderState
                        )}.classNames`,
                        updatedClassNames
                    );
                    break;
                default:
                    break;
            }
        }
    });

    return configuration;
};

export const setFieldAsDate = ({ configuration, elementUiSchema, activeSectionId, activeParentId, activeFieldId }) => {
    if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
        if (
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type === "array" &&
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items
        ) {
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items = {
                ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items,
                format: "date",
            };
        } else {
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                format: "date",
            };
        }
    } else {
        logError(
            `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
        );
    }

    if (!["date", "defaultdate"].includes(elementUiSchema["ui:widget"])) {
        if (configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]) {
            configuration.uiSchema[activeSectionId][activeParentId][activeFieldId] = {
                ...configuration.uiSchema[activeSectionId][activeParentId][activeFieldId],
                "ui:widget": "date",
            };
        } else {
            logError(
                `normalizeFormConfiguration field not found: ${configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]}`
            );
        }
    }
};

export const setFieldAsNumeric = ({ configuration, activeSectionId, activeParentId, activeFieldId }) => {
    if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
        if (
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type === "array" &&
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items
        ) {
            if (
                !["integer", "number"].includes(
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items.type
                )
            ) {
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items = {
                    ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items,
                    type: "number",
                };
            }
        } else {
            if (
                !["integer", "number"].includes(
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type
                )
            ) {
                configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                    ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                    type: "number",
                };
            }
        }
    } else {
        logError(
            `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
        );
    }
};

export const updateDateRangeValidation = ({ configuration, elementUiSchema, activeSectionId, activeParentId, activeFieldId }) => {
    const minMaxParts = (elementUiSchema["af:validationAdd"] || "").split("|");

    const getMinMaxPart = (partName) =>
        minMaxParts
            .filter((p) => p.indexOf(partName) > -1)
            .map((p) => p.split("="))
            .map((parts) => parts[1])
            .filter((part) => part)
            .map((part) => (part === "today" ? "todayT" : dateToJson(new Date(Date.parse(part)))))
            .map((p) => p.split("T"))
            .map((parts) => parts[0])[0];

    // startdate=1/1/2001|enddate=today
    // startdate=03/31/2016|enddate=12/31/2020
    // enddate=today
    // startdate=1/1/2017
    let formatMinimum = getMinMaxPart("startdate");
    let formatMaximum = getMinMaxPart("enddate");

    // ----------
    // formatMinimum: "2020-02-17"
    // format: "date"
    // formatMaximum: "2020-02-29"
    if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
        configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
            ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
            ...(formatMinimum ? { formatMinimum } : {}),
            ...(formatMaximum ? { formatMaximum } : {}),
        };
    } else {
        logError(
            `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
        );
    }
};

export const updateMinMaxValueValidation = ({ configuration, elementUiSchema, activeSectionId, activeParentId, activeFieldId }) => {
    const minimum = isNil(elementUiSchema["af:lowLimit"]) ? undefined : Number(elementUiSchema["af:lowLimit"]);
    const maximum = isNil(elementUiSchema["af:highLimit"]) ? undefined : Number(elementUiSchema["af:highLimit"]);

    if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
        if (
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type === "array" &&
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items
        ) {
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items = {
                ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items,
                ...(minimum ? { minimum } : {}),
                ...(maximum ? { maximum } : {}),
            };
        } else {
            configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                ...(minimum ? { minimum } : {}),
                ...(maximum ? { maximum } : {}),
            };
        }
    } else {
        logError(
            `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
        );
    }
};

export const updateRequredValidation = ({
    validationRequired,
    configuration,
    formBuilderState,
    activeSectionId,
    activeParentId,
    activeFieldId,
}) => {
    // required
    if (validationRequired) {
        const parentSchema = updateRequired(formBuilderState, activeFieldId, true);

        if (
            configuration.schema.properties[activeSectionId].properties[activeParentId] &&
            configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]
        ) {
            configuration.schema.properties[activeSectionId].properties[activeParentId] = {
                ...parentSchema,
            };
        } else {
            logError(`normalizeFormConfiguration field not found:
                                    ${configuration.schema.properties[activeSectionId].properties[activeParentId]},
                                    ${configuration.uiSchema[activeSectionId][activeParentId][activeFieldId]}`);
        }
    }
};

export const updateLengthValidation = ({
    configuration,
    elementSchema,
    elementUiSchema,
    activeSectionId,
    activeParentId,
    activeFieldId,
}) => {
    // Convert minLength and maxLength properties to numbers
    if (isString(elementSchema.minLength) && elementSchema.minLength.length > 0) {
        configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
            ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
            minLength: Number(elementSchema.minLength),
        };
    }

    if (isString(elementSchema.maxLength) && elementSchema.maxLength.length > 0) {
        configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
            ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
            maxLength: Number(elementSchema.maxLength),
        };
    }

    if (["string"].includes(elementSchema.type)) {
        const maxLength = elementUiSchema["af:maxLength"] ? Number(elementUiSchema["af:maxLength"]) : undefined;

        if (isNumber(maxLength) && isFinite(maxLength) && maxLength > 0) {
            if (configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]) {
                if (
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].type ===
                        "array" &&
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items
                ) {
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items = {
                        ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId].items,
                        ...(maxLength ? { maxLength } : {}),
                    };
                } else {
                    configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId] = {
                        ...configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId],
                        ...(maxLength ? { maxLength } : {}),
                    };
                }
            } else {
                logError(
                    `normalizeFormConfiguration field not found: ${configuration.schema.properties[activeSectionId].properties[activeParentId].properties[activeFieldId]}`
                );
            }
        }
    }
};

export const getFieldPathByFieldId = ({ schema, uiSchema, fieldId }) => {
    return getSchemaIds(schema, true)
        .map((item) => {
            const formBuilderState = {
                schema,
                uiSchema,
                selectedElementId: "root_" + item.id.split(".").join("_"),
            };

            const elementUiParams = getSelectedElementUiParams(formBuilderState);

            if (elementUiParams["af:fieldId"] === fieldId) {
                return item.id;
            }

            return null;
        })
        .find((i) => !isNil(i));
};

export const getFieldPathByFieldNumber = ({ schema, uiSchema, fieldNumber }) => {
    return getSchemaIds(schema, true)
        .map((item) => {
            const formBuilderState = {
                schema,
                uiSchema,
                selectedElementId: "root_" + item.id.split(".").join("_"),
            };

            const elementUiParams = getSelectedElementUiParams(formBuilderState);

            if (elementUiParams["af:fieldNumber"] === fieldNumber) {
                return item.id;
            }

            return null;
        })
        .find((i) => !isNil(i));
};

/**
 * Get element type by looking at path.
 *
 * @param {object} params - params object.
 * @param {string} params.path - element schema path like [instanceId]_[sectionId]_[columnId]_[fieldId]
 */
export const getElementTypeByPath = ({ path }) => {
    let elementType = ElementTypes.UNKNOWN;

    if (isString(path)) {
        const pathParts = path.split("_");

        switch (pathParts.length) {
            case 1:
                elementType = ElementTypes.PAGE;
                break;
            case 2:
                elementType = ElementTypes.SECTION;
                break;
            case 3:
                elementType = ElementTypes.COLUMN;
                break;
            case 4:
                elementType = ElementTypes.FIELD;
                break;
            default:
                elementType = ElementTypes.UNKNOWN;
                break;
        }
    }

    return elementType;
};

/**
 * Try recover configuration for unknown elements.
 *
 * @param {*} { formBuilder }
 */
export const tryUpdateUnknownElement = ({ formBuilder }) => {
    const elementType = getElementTypeByPath({
        path: formBuilder.selectedElementId,
    });
    let result = null;

    if (elementType === ElementTypes.COLUMN) {
        result = updateColumnMissingProperties({ formBuilder });
    }

    return result;
};

export const updateColumnMissingProperties = ({ formBuilder }) => {
    const selectedElementId = formBuilder.selectedElementId;
    let schema = formBuilder.schema;
    let uiSchema = formBuilder.uiSchema;

    const pathParts = selectedElementId.split("_");

    // Column should have path from three parts
    if (pathParts.length > 2) {
        const sectionId = pathParts[1];
        const columnId = pathParts[2];

        uiSchema = cloneDeep(uiSchema);
        let sectionUiSchema = uiSchema[sectionId] ?? {};
        let columnUiSchema = uiSchema[sectionId][columnId] ?? {};

        schema = cloneDeep(schema);
        let sectionSchema = schema?.properties[sectionId] ?? {};
        let columnSchema = sectionSchema?.properties[columnId] ?? {};

        // Check column schema
        const schemaChange = !columnSchema["type"];
        if (schemaChange) {
            columnSchema = {
                ...columnSchema,
                type: "object",
            };
        }

        // Check uiSchema
        const uiSchemaChange = !columnUiSchema["ui:elementType"] || !columnUiSchema["af:columnNumber"];
        if (uiSchemaChange) {
            columnUiSchema = {
                ...columnUiSchema,
                classNames: ElementTypes.COLUMN,
                "ui:elementType": ElementTypes.COLUMN,
                "af:columnNumber": columnUiSchema["af:columnNumber"] ?? createId(),
            };
        }

        // Check column order
        const orderChange = !(sectionUiSchema["ui:order"] ?? []).includes(columnId);
        if (orderChange) {
            const existingOrder = sectionUiSchema["ui:order"] ?? [];
            const sectionColumns = Object.keys(schema?.properties[sectionId]?.properties ?? {});

            sectionUiSchema = {
                ...sectionUiSchema,
                "ui:order": union(existingOrder, sectionColumns),
            };
        }

        if (schemaChange) {
            schema = {
                ...schema,
                properties: {
                    ...schema.properties,
                    [sectionId]: {
                        ...sectionSchema,
                        properties: {
                            ...sectionSchema.properties,
                            [columnId]: {
                                ...columnSchema,
                            },
                        },
                    },
                },
            };
        }

        if (uiSchemaChange || orderChange) {
            uiSchema = {
                ...uiSchema,
                [sectionId]: {
                    ...sectionUiSchema,
                    [columnId]: {
                        ...columnUiSchema,
                    },
                },
            };
        }
    }

    return {
        uiSchema,
        schema,
    };
};

/**
 * Disable autocomplete in form.
 *
 * @param {*} { formRef } - reference to form
 */
export const disableAutocomplete = ({ formRef }) => {
    const form = formRef?.current?.formElement;
    const formAutocomplete = form?.autocomplete;

    if (form && formAutocomplete === DEFAULT_AUTOCOMPLETE) {
        const textInputs = form.querySelectorAll('input[type="text"]');

        textInputs.forEach((input) => {
            // If empty set the autocomplete to a random string
            if (!input.autocomplete) {
                input.autocomplete = createId();
            }
        });
    }
};

export const downloadColumnFields = ({ formBuilder }) => {
    const { schema, uiSchema, rules } = formBuilder;

    const activeSectionId = getActiveSectionId(formBuilder);
    const activeColumnId = getActiveColumnId(formBuilder);

    const fileNamePrefix = stripHtml(removeExtraSpaces(schema?.title ?? ""));

    const columnSchema = schema.properties[activeSectionId].properties[activeColumnId];
    const columnUiSchema = uiSchema[activeSectionId][activeColumnId];

    if (columnSchema) {
        const data = Object.keys(columnSchema.properties).map((fieldId) => {
            const fieldSchema = columnSchema.properties[fieldId];
            const fieldUiSchema = columnUiSchema[fieldId];

            const fieldPath = [activeSectionId, activeColumnId, fieldId].join(".");
            const fieldRules = (rules ?? []).filter((rule) => rule.event.params.field === fieldPath);
            const fieldOrder = (columnUiSchema["ui:order"] ?? []).indexOf(fieldId);

            return getFieldDataForCsv({
                schema: fieldSchema,
                uiSchema: fieldUiSchema,
                rules: fieldRules,
                order: fieldOrder > 0 ? fieldOrder : 0,
                formUiSchema: uiSchema,
            });
        });

        downloadCSV({
            data,
            fileName: `${fileNamePrefix}_SECTION_${activeSectionId}_COLUMN_${activeColumnId}_FIELDS`,
        });
    } else {
        toast.error("Column config not found");
    }
};

export const uploadColumnFields = async ({ instanceId, showModal = true, selectedElementId, dispatch }) => {
    let formBuilder = getFormBuilder({ instanceId });

    return uploadCSV({ header: true }).then(async (result) => {
        if (showModal) {
            store.dispatch(
                modalOpen({
                    modalType: "WAITING_MODAL",
                    modalProps: {
                        title: "Uploading Fields...",
                        modalIcon: "upload",
                    },
                })
            );
        }

        try {
            if (selectedElementId) {
                dispatch(deselectElement({ instanceId }));
            }

            // Update form
            const activeSectionId = getActiveSectionId(formBuilder);
            const activeColumnId = getActiveColumnId(formBuilder);

            const columnSchema = formBuilder.schema.properties[activeSectionId].properties[activeColumnId];
            const columnUiSchema = formBuilder.uiSchema[activeSectionId][activeColumnId];

            const fieldDescriptors = await getReference({
                type: referenceTypes.fielddescriptor,
            });

            if (columnSchema) {
                // Create list of paths for existing fields
                const fieldPaths = Object.keys(columnSchema.properties).reduce((result, fieldId) => {
                    const fieldUiSchema = columnUiSchema[fieldId];

                    return {
                        ...result,
                        [fieldUiSchema["af:fieldNumber"]]: [instanceId, activeSectionId, activeColumnId, fieldId].join("_"),
                    };
                }, {});

                const importedItems = result.lines
                    .map((line) => lineToObject({ header: result.header, line }))
                    .map((line, index) => {
                        const lineNumber = index + 2;

                        // Throw error if csv has unknown fieldnumber
                        if (line.FIELD_NUMBER && !fieldPaths[line.FIELD_NUMBER]) {
                            throw Error(`Line ${lineNumber}. Field not found ${line.FIELD_NUMBER}`);
                        }

                        validateFieldFromCsv({
                            line,
                            lineNumber,
                            fieldDescriptors,
                        });

                        return line;
                    });

                importedItems.forEach((line) => {
                    let fieldNumber = line.FIELD_NUMBER;
                    let elementId = fieldPaths[fieldNumber];

                    // Add field if no fieldnumber in csv
                    if (!line.FIELD_NUMBER) {
                        const addFieldResult = addFieldToSelectedColumn(formBuilder);
                        elementId = [instanceId, activeSectionId, activeColumnId, addFieldResult.fieldId].join("_");
                        fieldNumber = addFieldResult.fieldNumber;

                        formBuilder = {
                            ...formBuilder,
                            schema: addFieldResult.schema,
                            uiSchema: addFieldResult.uiSchema,
                        };
                    }

                    // Clear field validation error
                    store.dispatch(clearValidationError({ instanceId, elementId }));

                    const updateFieldResult = updateField({
                        // Create builder state with current field selected
                        formBuilder: {
                            ...formBuilder,
                            selectedElementId: elementId,
                        },
                        // field values to set
                        values: {
                            ...getFieldValuesFromCsv({
                                line,
                                schema: formBuilder.schema,
                                uiSchema: formBuilder.uiSchema,
                            }),
                            fieldNumber,
                        },
                    });

                    // update form builder schemas
                    formBuilder = {
                        ...formBuilder,
                        schema: updateFieldResult.schema,
                        uiSchema: updateFieldResult.uiSchema,
                        rules: updateFieldResult.rules,
                    };
                });

                // Update form in store
                store.dispatch(updatePage({ instanceId, ...formBuilder }));

                if (showModal) {
                    store.dispatch(modalClose());
                }

                if (selectedElementId) {
                    // Added setTimeout to allow deselect and select element again in next cycle.
                    setTimeout(() => {
                        dispatch(
                            selectElement({
                                instanceId,
                                elementId: selectedElementId,
                            })
                        );
                    }, 0);
                }

                toast.success("Fields Uploaded");
            } else {
                toast.error("Column config not found");
            }

            return;
        } catch (error) {
            if (showModal) {
                const text = (
                    <>
                        <strong>Upload Fields failed</strong>
                        <p>{error.message}</p>
                    </>
                );

                showUploadCsvError({ text });
            } else {
                throw error;
            }
        }
    });
};

export const validateFieldFromCsv = ({ line, lineNumber, fieldDescriptors }) => {
    // FIELD_NUMBER
    // No validation

    // FIELD_GROUP
    if (!Object.keys(fieldGroups).some((key) => (fieldGroups[key] ?? "").toLowerCase() === (line.FIELD_GROUP ?? "").toLowerCase())) {
        throw Error(`Line ${lineNumber}. Invalid FIELD_GROUP ${line.FIELD_GROUP}`);
    }

    // FIELD_NAME
    if ((line.FIELD_NAME ?? "").trim() === "") {
        throw Error(`Line ${lineNumber}. Invalid FIELD_NAME ${line.FIELD_NAME}`);
    }

    // FRIENDLYNAME
    // No validation

    // FIELD_TYPE
    if (!FIELD_WIDGETS[line.FIELD_TYPE]) {
        throw Error(`Line ${lineNumber}. Invalid FIELD_TYPE ${line.FIELD_TYPE}`);
    }

    // FIELD_DATATYPE
    if (
        getWidgetDataTypeList({
            widget: FIELD_WIDGETS[line.FIELD_TYPE],
        }).includes(line.FIELD_DATATYPE)
    ) {
        throw Error(`Line ${lineNumber}. Invalid FIELD_DATATYPE ${line.FIELD_DATATYPE}`);
    }

    // ITEM_DATATYPE
    if (!line.ITEM_DATATYPE && line.FIELD_TYPE === "array") {
        throw Error(`Line ${lineNumber}. Invalid ITEM_DATATYPE ${line.ITEM_DATATYPE}`);
    }

    // ITEM_VALUES
    // If values provided need to be even number of items (label, value) in list
    if (line.ITEM_VALUES && line.ITEM_VALUES.split("|").length % 2 !== 0) {
        throw Error(`Line ${lineNumber}. Invalid ITEM_VALUES ${line.ITEM_VALUES}`);
    }

    // ORDER
    if (line.ORDER && isNaN(Number(line.ORDER))) {
        throw Error(`Line ${lineNumber}. Invalid ORDER ${line.ORDER}`);
    }

    // FIELD_LENGTH
    if (line.FIELD_LENGTH && (isNaN(Number(line.FIELD_LENGTH)) || Number(line.FIELD_LENGTH) < 0)) {
        throw Error(`Line ${lineNumber}. Invalid FIELD_LENGTH ${line.FIELD_LENGTH}`);
    }

    // DISABLED
    if (!["true", "false"].includes((line.DISABLED ?? "").toLowerCase())) {
        throw Error(`Line ${lineNumber}. Invalid DISABLED ${line.DISABLED}`);
    }

    // PLACEHOLDER
    // No validation

    // HELP
    // No validation

    // FIELD_DESCRIPTOR
    if (line.FIELD_DESCRIPTOR && !fieldDescriptors.some((i) => i.val === line.FIELD_DESCRIPTOR)) {
        throw Error(`Line ${lineNumber}. Invalid FIELD_DESCRIPTOR ${line.FIELD_DESCRIPTOR}`);
    }

    // SEPARATOR
    // No validation

    // GENPROC
    if (isEmpty((line.GENPROC ?? "").trim()) && line.FIELD_TYPE === "generalprocedure") {
        throw Error(`Line ${lineNumber}. Invalid GENPROC ${line.GENPROC}`);
    }

    // GENPROC_DISPLAY_TYPE
    if (!GENERAL_PROCEDURE_WIDGET_LIST.includes(line.GENPROC_DISPLAY_TYPE) && line.FIELD_TYPE === "generalprocedure") {
        throw Error(`Line ${lineNumber}. Invalid GENPROC_DISPLAY_TYPE ${line.GENPROC_DISPLAY_TYPE}`);
    }

    // GENPROC_PARAMETER_NAMES
    // No validation

    // GENPROC_PARAMETER_VALUES
    // No validation

    // VALIDATION_REQUIRED
    if (!["true", "false"].includes((line.VALIDATION_REQUIRED ?? "").toLowerCase())) {
        throw Error(`Line ${lineNumber}. Invalid VALIDATION_REQUIRED ${line.VALIDATION_REQUIRED}`);
    }

    // VALIDATION_EMAIL
    if (line.VALIDATION_EMAIL && !["true", "false"].includes((line.VALIDATION_EMAIL ?? "").toLowerCase())) {
        throw Error(`Line ${lineNumber}. Invalid VALIDATION_EMAIL ${line.VALIDATION_EMAIL}`);
    }

    // VALIDATION_REGEX
    // No validation

    const minLengthNumber = !isEmpty((line.MINLENGTH ?? "").trim()) ? Number(line.MINLENGTH) : null;
    const maxLengthNumber = !isEmpty((line.MAXLENGTH ?? "").trim()) ? Number(line.MAXLENGTH) : null;

    // MINLENGTH
    if (isNaN(minLengthNumber)) {
        throw Error(`Line ${lineNumber}. Invalid MINLENGTH ${line.MINLENGTH}`);
    }

    // MAXLENGTH
    if (isNaN(Number(maxLengthNumber))) {
        throw Error(`Line ${lineNumber}. Invalid MAXLENGTH ${line.MAXLENGTH}`);
    }

    // MINLENGTH > MAXLENGTH
    if (minLengthNumber > maxLengthNumber) {
        throw Error(`Line ${lineNumber}. MINLENGTH larger than MAXLENGTH ${line.MINLENGTH} > ${line.MAXLENGTH}`);
    }

    // LOW_LIMIT
    // HIGH_LIMIT
    if (["date", "defaultdate"].includes(line.FIELD_TYPE)) {
        const lowDate = getDateValueForInput({ value: line.LOW_LIMIT });
        const highDate = getDateValueForInput({ value: line.HIGH_LIMIT });

        if ([lowDate, highDate].every((i) => !isNil(i))) {
            if (lowDate > highDate) {
                throw Error(`Line ${lineNumber}. LOW_LIMIT larger than HIGH_LIMIT ${line.LOW_LIMIT} > ${line.HIGH_LIMIT}`);
            }
        }
    }

    // VALIDATION_REMOVE_IF_FIELD
    // No validation

    // VALIDATION_REMOVE_IF_CONDITION
    // No validation

    // VALIDATION_REMOVE_IF_VALUE
    // No validation

    // VALIDATION_REQUIRE_IF_FIELD
    // No validation

    // VALIDATION_REQUIRE_IF_CONDITION
    // No validation

    // VALIDATION_REQUIRE_IF_VALUE
    // No validation
};

export const getFieldValuesFromCsv = ({ line, schema, uiSchema }) => {
    // Conditional removeIf validation
    const removeIfFields = (line.VALIDATION_REMOVE_IF_FIELD ?? "")
        .split("|")
        .map((i) => i.trim())
        .filter((i) => !isEmpty(i));
    const removeIfConditions = (line.VALIDATION_REMOVE_IF_CONDITION ?? "").split("|").map((i) => i.trim());
    const removeIfValues = (line.VALIDATION_REMOVE_IF_VALUE ?? "").split("|").map((i) => i.trim());
    const removeif =
        removeIfFields.length > 0
            ? removeIfFields.map((item, index) => ({
                  field: getFieldPathByFieldNumber({
                      schema,
                      uiSchema,
                      fieldNumber: item,
                  }),
                  condition: removeIfConditions[index],
                  value: removeIfValues[index],
              }))
            : undefined;

    // Conditional requireIf validation
    const requireIfFields = (line.VALIDATION_REQUIRE_IF_FIELD ?? "")
        .split("|")
        .map((i) => i.trim())
        .filter((i) => !isEmpty(i));
    const requireIfConditions = (line.VALIDATION_REQUIRE_IF_CONDITION ?? "").split("|").map((i) => i.trim());
    const requireIfValues = (line.VALIDATION_REQUIRE_IF_VALUE ?? "").split("|").map((i) => i.trim());
    const requireif =
        requireIfFields.length > 0
            ? requireIfFields.map((item, index) => ({
                  field: getFieldPathByFieldNumber({
                      schema,
                      uiSchema,
                      fieldNumber: item,
                  }),
                  condition: requireIfConditions[index],
                  value: requireIfValues[index],
              }))
            : undefined;

    return {
        fieldNumber: line.FIELD_NUMBER,
        fieldGroup: Object.keys(fieldGroups).find(
            (key) => (fieldGroups[key] ?? "").toLowerCase() === (line.FIELD_GROUP ?? "").toLowerCase()
        ),
        title: line.FIELD_NAME,
        friendlyName: line.FRIENDLYNAME,
        widget: line.FIELD_TYPE,
        type: line.FIELD_DATATYPE,
        itemType: line.ITEM_DATATYPE,
        itemValues: dbStringToDictionaryItemValues(line.ITEM_VALUES),
        order: Number(line.ORDER),
        disabled: (line.DISABLED ?? "").toLowerCase() === "true",
        placeholder: line.PLACEHOLDER,
        help: line.HELP,
        fieldDescriptor: line.FIELD_DESCRIPTOR,
        separator: line.SEPARATOR,

        procId: line.GENPROC,
        procDisplayType: line.GENPROC_DISPLAY_TYPE,
        parameterNamesList: line.GENPROC_PARAMETER_NAMES.split("|").map((i) => i.trim()),
        ...(line.GENPROC_PARAMETER_VALUES ?? [])
            .split("|")
            .map((i) => i.trim())
            .map((value, index) => ({
                [`parameterNamesList-${index}`]: value,
            }))
            .reduce((props, next) => (props = { ...props, ...next }), {}),

        validation: {
            required: (line.VALIDATION_REQUIRED ?? "").toLowerCase() === "true",
            email: (line.VALIDATION_EMAIL ?? "").toLowerCase() === "true",
            pattern: line.VALIDATION_REGEX,
            minLength: isEmpty(line.MINLENGTH) ? undefined : Number(line.MINLENGTH),
            maxLength: isEmpty(line.MAXLENGTH) ? undefined : Number(line.MAXLENGTH),
            minimum: ["date", "defaultdate"].includes(line.FIELD_TYPE)
                ? undefined
                : isEmpty(line.LOW_LIMIT)
                ? undefined
                : Number(line.LOW_LIMIT),
            maximum: ["date", "defaultdate"].includes(line.FIELD_TYPE)
                ? undefined
                : isEmpty(line.HIGH_LIMIT)
                ? undefined
                : Number(line.HIGH_LIMIT),
            formatMinimum: ["date", "defaultdate"].includes(line.FIELD_TYPE)
                ? isEmpty(line.LOW_LIMIT)
                    ? undefined
                    : line.LOW_LIMIT === "today"
                    ? line.LOW_LIMIT
                    : dateStringToJsonDate(line.LOW_LIMIT)
                : undefined,
            formatMaximum: ["date", "defaultdate"].includes(line.FIELD_TYPE)
                ? isEmpty(line.HIGH_LIMIT)
                    ? undefined
                    : line.HIGH_LIMIT === "today"
                    ? line.HIGH_LIMIT
                    : dateStringToJsonDate(line.HIGH_LIMIT)
                : undefined,
        },

        removeif,
        requireif,

        // legacy values
        dbString: isEmpty(line.ITEM_VALUES) ? undefined : line.ITEM_VALUES,
        validationType: isEmpty(line.VALIDATION_TYPE) ? undefined : line.VALIDATION_TYPE,
    };
};

export const getFieldDataForCsv = ({ schema, uiSchema, rules, order, formUiSchema }) => {
    const removeIfRules = rules
        .filter((rule) => rule.event.type === "remove")
        .map(formatRule)
        // Replace field path to fieldnumber in exported data
        .map((rule) => ({
            ...rule,
            field: getFieldNumber(formUiSchema, rule.field),
        }));

    const requireIfRules = rules
        .filter((rule) => rule.event.type === "require")
        .map(formatRule)
        .map((rule) => ({
            ...rule,
            field: getFieldNumber(formUiSchema, rule.field),
        }));

    const itemValuesAnyOf = schema["items"] ? (schema["items"] ?? {})["anyOf"] : schema["anyOf"];

    return {
        FIELD_NUMBER: uiSchema["af:fieldNumber"],
        FIELD_GROUP: fieldGroups[Number(uiSchema["af:fieldGroup"])],
        FIELD_NAME: schema["title"],
        FRIENDLYNAME: uiSchema["af:friendlyName"],
        FIELD_TYPE: uiSchema["ui:widget"] ?? "text",
        FIELD_DATATYPE: schema["type"] ?? "string",
        ITEM_DATATYPE: (schema["items"] ?? {})["type"] ?? "",
        ITEM_VALUES: (itemValuesAnyOf ?? []).flatMap((i) => [i.enum[0], i.title]).join("|"),
        ORDER: String(order),
        DISABLED: uiSchema["ui:disabled"] ? "TRUE" : "FALSE",
        PLACEHOLDER: uiSchema["ui:placeholder"],
        HELP: uiSchema["ui:help"],
        FIELD_DESCRIPTOR: uiSchema["af:fieldDescriptor"],
        SEPARATOR: uiSchema["ui:separator"],

        // Genproc
        GENPROC: uiSchema["ui:procId"],
        GENPROC_DISPLAY_TYPE: uiSchema["ui:procDisplayType"],
        GENPROC_PARAMETER_NAMES: toArray(uiSchema["ui:parameterNamesList"]).join("|"),
        GENPROC_PARAMETER_VALUES: toArray(uiSchema["ui:parameterValuesList"]).join("|"),

        // Validation
        VALIDATION_REQUIRED: uiSchema["af:validationRequired"] ? "TRUE" : "FALSE",
        VALIDATION_EMAIL: schema["format"] === "email" ? "TRUE" : "FALSE",
        VALIDATION_REGEX: schema["pattern"],
        MINLENGTH: isNumber(schema["minLength"]) ? String(schema["minLength"]) : schema["minLength"],
        MAXLENGTH: isNumber(schema["maxLength"]) ? String(schema["maxLength"]) : schema["maxLength"],
        LOW_LIMIT: schema["minimum"] ?? schema["formatMinimum"] ?? "",
        HIGH_LIMIT: schema["maximum"] ?? schema["formatMaximum"] ?? "",

        // Conditional validation
        VALIDATION_REMOVE_IF_FIELD: removeIfRules.length > 0 ? removeIfRules.map((rule) => rule.field).join("|") : "",
        VALIDATION_REMOVE_IF_CONDITION: removeIfRules.length > 0 ? removeIfRules.map((rule) => rule.condition).join("|") : "",
        VALIDATION_REMOVE_IF_VALUE: removeIfRules.length > 0 ? removeIfRules.map((rule) => rule.value).join("|") : "",

        VALIDATION_REQUIRE_IF_FIELD: requireIfRules.length > 0 ? requireIfRules.map((rule) => rule.field).join("|") : "",
        VALIDATION_REQUIRE_IF_CONDITION: requireIfRules.length > 0 ? requireIfRules.map((rule) => rule.condition).join("|") : "",
        VALIDATION_REQUIRE_IF_VALUE: requireIfRules.length > 0 ? requireIfRules.map((rule) => rule.value).join("|") : "",

        // Legacy validation fields
        VALIDATION_TYPE: uiSchema["af:validationType"],
        // VALIDATION_ADD: uiSchema["af:validationAdd"],
        // VALIDATION_PIVOT: uiSchema["af:validationPivot"],
    };
};

export const addFieldToSelectedColumn = (formBuilder) => {
    let schema = cloneDeep(formBuilder.schema);
    let uiSchema = cloneDeep(formBuilder.uiSchema);

    const activeSectionId = getActiveSectionId(formBuilder);
    const activeColumnId = getActiveColumnId(formBuilder);
    const activeFieldId = getActiveFieldId(formBuilder);
    const activeColumn = getActiveColumn(formBuilder);

    let fieldId = uniqueId(FIELD_ID_PREFIX);
    const fieldNumber = createId();

    // Ensure column has properties object
    if (!activeColumn.properties) {
        activeColumn.properties = {};
    }

    // Ensure id is unique for this column
    while (activeColumn.properties[fieldId]) {
        fieldId = uniqueId(FIELD_ID_PREFIX);
    }

    // Default field schema
    const fieldSchema = {
        type: "string",
        title: `Field ${fieldId}`,
    };

    // Default field uiSchema
    const fieldUiSchema = {
        "ui:widget": "text",
        "ui:elementType": ElementTypes.FIELD,
        "af:fieldNumber": fieldNumber,
        "af:fieldGroup": 43,
    };

    schema.properties[activeSectionId] = {
        ...schema.properties[activeSectionId],
        properties: {
            ...schema.properties[activeSectionId].properties,
            [activeColumnId]: {
                ...schema.properties[activeSectionId].properties[activeColumnId],
                properties: {
                    ...schema.properties[activeSectionId].properties[activeColumnId].properties,
                    [fieldId]: fieldSchema,
                },
            },
        },
    };

    let order = uiSchema[activeSectionId][activeColumnId]["ui:order"] ?? [];

    // If currently a field is selected, move the new field after selected field
    if (activeFieldId) {
        let newIndex = order.findIndex((v) => v === activeFieldId) + 1;
        if (newIndex === 0) {
            newIndex = order.length - 1;
        }

        order.splice(newIndex, 0, fieldId);
    } else {
        order.push(fieldId);
    }

    uiSchema = {
        ...uiSchema,
        [activeSectionId]: {
            ...uiSchema[activeSectionId],
            [activeColumnId]: {
                ...uiSchema[activeSectionId][activeColumnId],
                "ui:order": order,
                [fieldId]: fieldUiSchema,
            },
        },
    };

    return {
        schema,
        uiSchema,
        fieldId,
        fieldNumber,
    };
};

export const updateField = ({ formBuilder, values, genProcParameterList }) => {
    let schema = formBuilder.schema;
    let uiSchema = formBuilder.uiSchema;
    let rules = formBuilder.rules;

    const activeSectionId = getActiveSectionId(formBuilder);
    const activeSection = getActiveSection(formBuilder);
    const activeColumnId = getActiveColumnId(formBuilder);
    const activeColumn = getActiveColumn(formBuilder);
    const activeRowId = getActiveRowId(formBuilder);
    const activeRow = getActiveRow(formBuilder);
    let activeFieldId = getActiveFieldId(formBuilder);
    let schemaUiField = getActiveUiField(formBuilder);

    // Do nothing if values are not for selected field in form builder instance
    if (isNil(activeSectionId) || values["fieldNumber"] !== schemaUiField?.["af:fieldNumber"]) {
        return {
            schema,
            uiSchema,
            rules,
        };
    }

    let activeParent = activeColumn ? activeColumn : activeRow;
    let activeParentId = activeColumnId ? activeColumnId : activeRowId;

    const elementSchemaId = formIdToSchemaId(formBuilder.selectedElementId);

    // Widget changed
    if (schemaUiField["ui:widget"] !== values["widget"]) {
        // Set default maxLength
        values.validation.maxLength = getDefaultFieldMaxLength({
            widget: values["widget"],
        });

        // Set default values for signature widget
        if (values["widget"] === "signature") {
            values["title"] = "Please type your full name in the box below to sign electronically.";
            values["confirmCheckboxTitle"] = "I agree that this represents my electronic signature.";
        }
    }

    // Update schema field
    let field = {
        ...activeParent.properties[activeFieldId],
        title: values["title"],
    };

    delete field["uniqueItems"];
    delete field["items"];
    delete field["enum"];
    delete field["anyOf"];
    delete field["format"];

    updateFieldDataType(field, values);

    const isRequired = get(values, "validation.required", false);
    updateFieldValidations(field, values);

    // Date widget
    if (["date", "defaultdate"].includes(values["widget"])) {
        field["format"] = values.dateFormat ?? "date";
    }

    // Changed from General Procedure widget to something else
    if (schemaUiField["ui:widget"] === "generalprocedure" && values["widget"] !== "generalprocedure") {
        values["procId"] = undefined;
        values["parameterNamesList"] = undefined;
        values["parameterValuesList"] = undefined;
    }

    if (values["widget"] === "generalprocedure") {
        // Set field type for genproc
        field["type"] = "string";

        // Fill in parameterNamesList
        values["parameterNamesList"] =
            genProcParameterList?.map((item) => item.parameterName) ?? schemaUiField["ui:parameterNamesList"] ?? [];

        if (schemaUiField["ui:procId"] !== values["procId"]) {
            values["parameterValuesList"] = [];
        } else {
            if (values["parameterNamesList"].some((item, index) => !isNil(values[`parameterNamesList-${index}`]))) {
                values["parameterValuesList"] = values["parameterNamesList"].map((_, index) => values[`parameterNamesList-${index}`] ?? "");
            } else {
                values["parameterValuesList"] = schemaUiField["ui:parameterValuesList"] ?? [];
            }
        }
    }

    // Set proper type for text widgets
    if (TEXT_WIDGET_LIST.filter((i) => i !== "select").includes(values["widget"]) && ["boolean", "array"].includes(field["type"])) {
        field["type"] = "string";
        delete field["uniqueItems"];
        delete field["items"];
        values["itemValues"] = "";
    }

    // Checkbox widget
    if (["checkbox", "largecheckbox"].includes(values["widget"])) {
        values["itemValues"] = "";
        values["dbString"] = "true|" + values["title"];
    }

    // Set proper type for radio
    if (["radio"].includes(values["widget"]) && ["array"].includes(field["type"])) {
        field["type"] = "string";
        delete field["uniqueItems"];
        delete field["items"];
        values["itemValues"] = "";
    }

    // Set proper type for checkboxes
    if (["checkboxes", "largecheckboxes"].includes(values["widget"]) && field["type"] !== "array") {
        field = {
            ...field,
            type: "array",
            uniqueItems: true,
            items: {
                type: values["itemType"] || "string",
                anyOf: [],
            },
        };
    }

    // Values for select and radio widgets
    if (field.type !== "array" && ["select", "radio"].includes(values["widget"])) {
        if (values["fieldDescriptor"] !== FIELD_DESCRIPTOR_DB) {
            const itemList = splitDictionaryItemValues(values["itemValues"]);

            field["anyOf"] = itemListToAnyOf(itemList);

            // If field type is numeric then convert values to numbers
            if (["integer", "number"].includes(field["type"])) {
                field["anyOf"] = field["anyOf"].map((i) => ({
                    ...i,
                    enum: [Number(i.enum[0])],
                }));
            }

            values["dbString"] = itemListToDbString(itemList);
        } else {
            field["anyOf"] = [];
        }
    }

    // Set array values
    if (field.type === "array") {
        if (values["fieldDescriptor"] === FIELD_DESCRIPTOR_DB || values["widget"] === "generalprocedure") {
            const itemList = [
                {
                    title: values["dbString"],
                    enum: [undefined],
                },
            ];

            field = {
                ...field,
                uniqueItems: true,
                items: {
                    type: values["itemType"] || "string",
                    anyOf: itemList,
                },
            };
        } else {
            const itemList = splitDictionaryItemValues(values["itemValues"]);

            field = {
                ...field,
                uniqueItems: true,
                items: {
                    type: values["itemType"] || "string",
                    anyOf: itemListToAnyOf(itemList),
                },
            };

            values["dbString"] = itemListToDbString(itemList);
        }
    }

    // Update UI schema field
    schemaUiField = updateUiSchemaFields({
        keyPrefix: "ui",
        keys: [
            "widget",
            "placeholder",
            "help",
            "key",
            "separator",
            "procDisplayType",
            "procId",
            "parameterNamesList",
            "parameterValuesList",
        ],
        uiSchema: schemaUiField,
        values,
    });

    if (values["widget"] === "image") {
        const imageProperties = isString(values["image"]) ? JSON.parse(values["image"]) : values["image"];
        schemaUiField["ui:imageSize"] = imageProperties?.imageSize;
        schemaUiField["ui:imageSrc"] = imageProperties?.imageSrc;
        schemaUiField["ui:imageAltText"] = imageProperties?.imageAltText;
        schemaUiField["ui:imageLink"] = imageProperties?.imageLink;
        schemaUiField["ui:openInNewTab"] = imageProperties?.openInNewTab;
    }

    if (values["widget"] === "signature") {
        schemaUiField["ui:confirmCheckboxTitle"] = values["confirmCheckboxTitle"];
    }

    schemaUiField["ui:disabled"] = values["disabled"];
    schemaUiField["af:validationRequired"] = isRequired;
    schemaUiField["af:fieldNumber"] = values["fieldNumber"];
    schemaUiField["af:fieldGroup"] = values["fieldGroup"];
    schemaUiField["af:fieldDescriptor"] = values["fieldDescriptor"];
    schemaUiField["af:friendlyName"] = values["friendlyName"];
    schemaUiField["af:dbString"] = values["dbString"];
    schemaUiField["af:status"] = values["disabled"] ? "disabled" : "active";

    const parentElement = updateRequired(formBuilder, activeFieldId, isRequired);

    // update conditional validation
    if (isLegacyConditionalRulesChanged(schemaUiField, values)) {
        schemaUiField["af:validationType"] = isEmpty(values["validationType"]) ? undefined : values["validationType"];
        schemaUiField["af:validationPivot"] = showValidationPivot({
            elementUiParams: { "af:validationType": values["validationType"] },
        })
            ? values["validationPivot"]
            : undefined;
        schemaUiField["af:validationAdd"] = showValidationAdd({
            elementUiParams: { "af:validationType": values["validationType"] },
        })
            ? values["validationAdd"]
            : undefined;

        rules = updateFieldRulesFromLegacyFieldRules({
            validationType: schemaUiField["af:validationType"],
            fieldPath: elementSchemaId,
            otherFieldValue: schemaUiField["af:validationAdd"],
            otherFieldPath: getFieldPathByFieldNumber({
                schema,
                uiSchema,
                fieldNumber: schemaUiField["af:validationPivot"],
            }),
            rules,
        });
    } else {
        rules = updateFieldRules(rules, values, elementSchemaId);

        // Update legacy other field validation
        schemaUiField = updateLegacyFieldRulesFromFieldRules({
            formSchema: schema,
            formUiSchema: uiSchema,
            formRules: rules,
            elementSchemaId,
            elementUiSchema: schemaUiField,
        });
    }

    // update legacy date range validation
    if (["date", "defaultdate"].includes(values["widget"])) {
        // Set date range validation if any of limits are defined
        if (!isNil(values?.validation["formatMinimum"]) || !isNil(values?.validation["formatMaximum"])) {
            schemaUiField["af:validationType"] = "463";
            schemaUiField["af:validationPivot"] = undefined;
            schemaUiField["af:validationAdd"] = getLegacyDateRangeValidationString(
                values.validation["formatMinimum"],
                values.validation["formatMaximum"]
            );
        }
        // Clear date range validation if no limits are set but validation is still 'Date with limits'
        else if (values["validationType"] === "463" && schemaUiField["af:validationType"] === "463") {
            schemaUiField["af:validationPivot"] = undefined;
            schemaUiField["af:validationAdd"] = undefined;
        }
    }

    schema = {
        ...schema,
        properties: {
            ...schema.properties,
            [activeSectionId]: {
                ...activeSection,
                properties: {
                    ...activeSection.properties,
                    [activeParentId]: {
                        ...parentElement,
                        properties: {
                            ...parentElement.properties,
                            [activeFieldId]: {
                                ...field,
                            },
                        },
                    },
                },
            },
        },
    };

    uiSchema = {
        ...uiSchema,
        [activeSectionId]: {
            ...uiSchema[activeSectionId],
            [activeParentId]: {
                ...uiSchema[activeSectionId][activeParentId],
                [activeFieldId]: {
                    ...schemaUiField,
                },
            },
        },
    };

    uiSchema = updateOrder({ schema, uiSchema }, elementSchemaId, values["order"]);

    return {
        schema,
        uiSchema,
        rules,
    };
};

export const isLegacyConditionalRulesChanged = (fieldUiSchema, values) => {
    return ["validationType", "validationPivot", "validationAdd"].some((key) => !isEqual(fieldUiSchema[`af:${key}`], values[key]));
};

export const isFormChanged = (formBuilder) => {
    const { initialSchema, initialUiSchema, initialRules, schema, uiSchema, rules } = formBuilder;

    let omittedSchema = omitDeep(schema, "uniqueItems");
    let omittedInitialSchema = omitDeep(initialSchema, "uniqueItems");

    // check that rules array items are equal ignoring order
    const rulesEqual = (rules, initialRules) => {
        // equal if not defined
        if (!rules && !initialRules) {
            return true;
        }

        if (rules?.length !== initialRules?.length) {
            return false;
        }

        return rules.every((rule) => initialRules.some((initialRule) => isEqual(rule, initialRule)));
    };

    if (isEqual(omittedSchema, omittedInitialSchema) && isEqual(uiSchema, initialUiSchema) && rulesEqual(rules, initialRules)) {
        return false;
    } else {
        return true;
    }
};

export const isFormDataChanged = ({ initialValues, currentValues, defaultValues }) => {
    const initialFormValues = deepOmitBy(assign({}, defaultValues, initialValues), isNil);
    const currentSnapshot = deepOmitBy(currentValues, isNil);

    return !isEqual(initialFormValues, currentSnapshot);
};

export const addSectionToPage = (formBuilder) => {
    let schema = formBuilder.schema;
    let uiSchema = formBuilder.uiSchema;

    const sectionCount = getSectionCount(schema);
    let sectionId = uniqueId(SECTION_ID_PREFIX);

    if (sectionCount === 0) {
        schema.properties = {};
    }

    while (schema.properties[sectionId]) {
        sectionId = uniqueId(SECTION_ID_PREFIX);
    }

    schema = {
        ...schema,
        properties: {
            ...schema.properties,
            [sectionId]: {
                type: "object",
                title: "Section title",
                description: "Section description",
                properties: {},
            },
        },
    };

    let order = (uiSchema["ui:order"] || []).slice();
    const sectionList = getSectionList(schema);

    if (order.length === 0 && sectionCount > 0) {
        order = getSectionList(schema);
    } else {
        order = order.filter((i) => sectionList.includes(i));
        if (!order.includes(sectionId)) {
            order.push(sectionId);
        }
    }

    uiSchema = {
        ...uiSchema,
        "ui:order": order,
        [sectionId]: {
            classNames: ElementTypes.SECTION,
            "ui:elementType": ElementTypes.SECTION,
            "af:sectionNumber": createId(),
        },
    };

    return {
        schema,
        uiSchema,
    };
};

export const addColumnToSelectedSection = (formBuilder) => {
    const activeSectionId = getActiveSectionId(formBuilder);
    const activeSection = getActiveSection(formBuilder);

    let schema = cloneDeep(formBuilder.schema);
    let uiSchema = cloneDeep(formBuilder.uiSchema);

    // Do nothing if active section not found
    if (!activeSectionId) {
        return {
            schema,
            uiSchema,
        };
    }

    // Find unique column id
    let columnId = uniqueId(COLUMN_ID_PREFIX);
    while (activeSection?.properties?.[columnId]) {
        columnId = uniqueId(COLUMN_ID_PREFIX);
    }

    schema = {
        ...schema,
        properties: {
            ...(schema.properties ?? {}),
            [activeSectionId]: {
                ...(schema?.properties[activeSectionId] ?? {}),
                properties: {
                    ...(schema.properties[activeSectionId].properties ?? {}),
                    [columnId]: {
                        type: "object",
                        title: "Column title",
                        description: "Column description",
                        required: [],
                        properties: {},
                    },
                },
            },
        },
    };

    let order = uiSchema[activeSectionId]["ui:order"] || [];
    order.push(columnId);

    uiSchema = {
        ...uiSchema,
        [activeSectionId]: {
            ...uiSchema[activeSectionId],
            "ui:order": order,
            [columnId]: {
                classNames: ElementTypes.COLUMN,
                "ui:elementType": ElementTypes.COLUMN,
                "af:columnNumber": createId(),
            },
        },
    };

    return {
        schema,
        uiSchema,
    };
};

export const getWidgetDataTypeList = ({ widget }) => {
    let typeList = [];

    if (TEXT_WIDGET_LIST.includes(widget) || READONLY_WIDGET_LIST.includes(widget)) {
        typeList.push("string");
    }

    if (BOOLEAN_WIDGET_LIST.includes(widget)) {
        typeList.push("boolean");
    }

    if (NUMBER_WIDGET_LIST.includes(widget)) {
        typeList.push("number");
        typeList.push("integer");
    }

    if (ARRAY_WIDGET_LIST.includes(widget)) {
        typeList.push("array");
    }

    return typeList;
};

export const getDefaultFieldMaxLength = ({ widget }) => {
    let maxLength = undefined;

    switch (widget) {
        case "date":
            maxLength = 10;
            break;
        case "text":
        case "textarea":
        case "signature":
            maxLength = undefined;
            break;
        default:
            maxLength = 0;
            break;
    }

    return maxLength;
};

/**
 * Check if selected field data type is correct for field widget
 * otherwise reset to a valid data type.
 *
 * @param {*} field - field to be updated
 * @param {*} values - field properties form values
 */
const updateFieldDataType = (field, values) => {
    const WidgetDataTypeList = getWidgetDataTypeList({
        widget: values["widget"],
    });

    if (WidgetDataTypeList.includes(values["type"])) {
        field["type"] = values["type"];
    } else {
        field["type"] = WidgetDataTypeList[0] ?? "string";
    }
};

const updateFieldValidations = (field, values) => {
    // string
    const minLength = get(values, "validation.minLength");
    if (isNumber(minLength)) {
        field["minLength"] = minLength;
    } else {
        delete field["minLength"];
    }

    const maxLength = get(values, "validation.maxLength");
    if (isNumber(maxLength)) {
        field["maxLength"] = maxLength;
    } else {
        delete field["maxLength"];
    }

    const pattern = get(values, "validation.pattern");
    if (pattern) {
        field["pattern"] = pattern;
    } else {
        delete field["pattern"];
    }

    // date
    const formatMinimum = get(values, "validation.formatMinimum");
    if (formatMinimum) {
        field["formatMinimum"] = formatMinimum;
    } else {
        delete field["formatMinimum"];
    }

    const formatMaximum = get(values, "validation.formatMaximum");
    if (formatMaximum) {
        field["formatMaximum"] = formatMaximum;
    } else {
        delete field["formatMaximum"];
    }

    const fieldFormat = get(values, "validation.fieldFormat");
    if ([FIELD_FORMAT.email, FIELD_FORMAT.url].includes(fieldFormat)) {
        field["format"] = fieldFormat;
    } else if ([FIELD_FORMAT.email, FIELD_FORMAT.url].includes(field["format"])) {
        delete field["format"];
    }

    // number
    const minimum = get(values, "validation.minimum");
    if (isNumber(minimum)) {
        field["minimum"] = minimum;
    } else {
        delete field["minimum"];
    }

    const maximum = get(values, "validation.maximum");
    if (isNumber(maximum)) {
        field["maximum"] = maximum;
    } else {
        delete field["maximum"];
    }
};

export const updateFieldRules = (rules, values, elementSchemaId) => {
    // let rules = [{
    //     conditions: {
    //       age: { less : 16 }
    //     },
    //     event: {
    //       type: "require",
    //       params: {
    //         field: "zip"
    //       }
    //     }
    // }]

    const fieldRules = ["showif", "removeif", "requireif"].map((rule) => {
        if (values[rule]) {
            return values[rule]
                .map((c) => {
                    return isString(c) ? JSON.parse(c) : c;
                })
                .filter((c) => {
                    return c && !isNil(c.field) && !isNil(c.condition);
                })
                .map((c) => {
                    const event = {
                        type: rule.slice(0, -2),
                        params: {
                            field: elementSchemaId,
                        },
                    };

                    let value = c.value;

                    // Convert array value to string if the rule is not for multiple values. This can happen when changing rules in form.
                    if (["anyOf", "notAnyOf", "between"].includes(c.condition)) {
                        value = Array.isArray(value) ? value : [value];
                    } else if (Array.isArray(value)) {
                        value = value.join(",");
                    }

                    if (["empty"].includes(c.condition)) {
                        return {
                            conditions: {
                                [c.field]: c.condition,
                            },
                            event,
                        };
                    } else if (["notEmpty"].includes(c.condition)) {
                        return {
                            conditions: {
                                [c.field]: { not: "empty" },
                            },
                            event,
                        };
                    } else if (["anyOf"].includes(c.condition)) {
                        return {
                            conditions: {
                                [c.field]: {
                                    or: value.map((v) => ({
                                        equal: v,
                                    })),
                                },
                            },
                            event,
                        };
                    } else if (["notAnyOf"].includes(c.condition)) {
                        return {
                            conditions: {
                                [c.field]: {
                                    and: value.map((v) => ({
                                        notEqual: v,
                                    })),
                                },
                            },
                            event,
                        };
                    } else if (["between"].includes(c.condition)) {
                        return {
                            conditions: {
                                [c.field]: {
                                    greaterEq: value[0],
                                    lessEq: value[1],
                                },
                            },
                            event,
                        };
                    } else {
                        return {
                            conditions: {
                                [c.field]: { [c.condition]: value },
                            },
                            event,
                        };
                    }
                });
        }

        return [];
    });

    const otherRules = (rules || []).filter((rule) => rule.event.params.field !== elementSchemaId);
    rules = [...otherRules.concat(...fieldRules)];

    return rules;
};

const updateLegacyFieldRulesFromFieldRules = ({ formSchema, formUiSchema, formRules, elementSchemaId, elementUiSchema }) => {
    const fieldRules = (formRules || [])
        .filter((rule) => rule.event.type === "require" && rule.event.params.field === elementSchemaId)
        .filter((rule) => isLegacySupportedCondition(getRuleCondition(rule)));

    if (fieldRules.length > 0) {
        const firstConditionKey = Object.keys(fieldRules[0].conditions)[0];
        const otherFieldNumber = getFieldNumber(formUiSchema, firstConditionKey);

        if (otherFieldNumber) {
            const otherFieldValue = fieldRules
                .filter((rule) => {
                    // take if target field and condition is the same
                    const ruleConditionKey = Object.keys(rule.conditions)[0];

                    return (
                        isEqual(ruleConditionKey, firstConditionKey) &&
                        isEqual(Object.keys(rule.conditions[ruleConditionKey]), Object.keys(fieldRules[0].conditions[firstConditionKey]))
                    );
                })
                .map((rule) => {
                    const condition = rule.conditions[firstConditionKey];

                    if (condition.not === "empty") {
                        return "*any*";
                    } else if (!isNil(condition.equal)) {
                        return condition.equal;
                    } else if (Object.keys(condition).includes("notEqual")) {
                        const conditionElementSchemaPath = `properties.${firstConditionKey.split(".").join(".properties.")}`;
                        const conditionElementSchema = get(formSchema, conditionElementSchemaPath);
                        let availableValues = [];

                        if (!isNil(conditionElementSchema?.anyOf)) {
                            availableValues = conditionElementSchema.anyOf.map((item) => item.enum[0]);
                        } else if (!isNil(conditionElementSchema?.items?.anyOf)) {
                            availableValues = conditionElementSchema.items.anyOf.map((item) => item.enum[0]);
                        }

                        return availableValues.filter((i) => i !== condition.notEqual).join("|");
                    }

                    return "";
                })
                .join("|");

            if (shouldRemoveLegacyRule(otherFieldValue, formRules)) {
                elementUiSchema = {
                    ...elementUiSchema,
                    "af:validationType": undefined,
                    "af:validationPivot": undefined,
                    "af:validationAdd": undefined,
                };
            } else {
                elementUiSchema = {
                    ...elementUiSchema,
                    "af:validationType": "16",
                    "af:validationPivot": otherFieldNumber,
                    "af:validationAdd": otherFieldValue,
                };
            }
        }
    } else {
        // Remove legacy conditional validation if require if rule was removed
        if (elementUiSchema["af:validationType"] === "16") {
            elementUiSchema = {
                ...elementUiSchema,
                "af:validationType": undefined,
                "af:validationPivot": undefined,
                "af:validationAdd": undefined,
            };
        }
    }

    return elementUiSchema;
};

const shouldRemoveLegacyRule = (otherFieldValue, formRules) => {
    const validationAddValues = otherFieldValue.includes("|") ? otherFieldValue.split("|") : [otherFieldValue];

    const getRuleValue = (rule) => {
        const ruleConditionKey = Object.keys(rule.conditions)[0];
        const condition = getRuleCondition(rule);

        if (condition === "empty") {
            return [""];
        }

        if (condition === "not") {
            return ["*any*"];
        }

        if (condition === "or") {
            return rule.conditions[ruleConditionKey][condition].map((i) => i.equal);
        }

        return [rule.conditions[ruleConditionKey][condition]];
    };

    return !formRules.some((rule) => {
        const condition = getRuleCondition(rule);
        const conditionValue = getRuleValue(rule);

        return isLegacySupportedCondition(condition) && isEqual(conditionValue, validationAddValues);
    });
};

const getRuleCondition = (rule) => {
    const ruleConditionKey = Object.keys(rule.conditions)[0];
    const condition = isObject(rule.conditions[ruleConditionKey])
        ? Object.keys(rule.conditions[ruleConditionKey])[0]
        : rule.conditions[ruleConditionKey];

    return condition;
};

const isLegacySupportedCondition = (condition) => {
    return ["empty", "equal", "not", "notEqual", "or"].includes(condition);
};

const updateFieldRulesFromLegacyFieldRules = ({ validationType, fieldPath, otherFieldValue, otherFieldPath, rules = [] }) => {
    let removeIfRules = rules.filter((r) => r.event?.type === "remove");
    let showIfRules = rules.filter((r) => r.event?.type === "show");
    let requireIfRules = rules.filter((r) => r.event?.type === "require");

    if (validationType === "16" && !isNil(otherFieldPath) && !isNil(fieldPath)) {
        // Add new rules generated from legacy fields
        requireIfRules = (otherFieldValue ?? "").split("|").map((value) => {
            return {
                conditions: {
                    [otherFieldPath]: {
                        ...(value === "*any*" ? { not: "empty" } : { equal: value }),
                    },
                },
                event: {
                    type: "require",
                    params: {
                        field: fieldPath,
                    },
                },
            };
        });
    }

    return [].concat(removeIfRules, requireIfRules, showIfRules);
};

/**
 * Generate date range validation string used for legacy date with limits validation.
 * Examples:
 * startdate=1/1/2001|enddate=today
 * startdate=03/31/2016|enddate=12/31/2020
 * enddate=today
 * startdate=1/1/2017
 *
 * @param {string} formatMinimum - json date string or 'today'
 * @param {string} formatMaximum - json date string or 'today'
 * @returns {string} - date range validation string
 */
const getLegacyDateRangeValidationString = (formatMinimum, formatMaximum) => {
    const getDateString = (dateValue) => {
        if (isNil(dateValue)) {
            return null;
        }

        if (dateValue === "today") {
            return dateValue;
        }

        // Convert json date '2021-05-18' to US format '05/18/2021'
        // There should be three date parts from json date
        const dateParts = dateValue.split("-");
        if (dateParts.length > 2) {
            return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
        }

        return null;
    };

    const startDate = getDateString(formatMinimum);
    const endDate = getDateString(formatMaximum);

    if (startDate && endDate) {
        return `startdate=${startDate}|enddate=${endDate}`;
    }

    if (startDate) {
        return `startdate=${startDate}`;
    }

    if (endDate) {
        return `enddate=${endDate}`;
    }

    return undefined;
};

const updateUiSchemaFields = ({ keyPrefix, keys, uiSchema, values }) => {
    keys.forEach((key) => {
        if (
            (isString(values[key]) && values[key].length > 0) ||
            (isNumber(values[key]) && values[key]) ||
            (isBoolean(values[key]) && values[key])
        ) {
            uiSchema = {
                ...uiSchema,
                [`${keyPrefix}:${key}`]: values[key],
            };
        } else if (Array.isArray(values[key]) && values[key].filter(isString).length > 0) {
            uiSchema = {
                ...uiSchema,
                [`${keyPrefix}:${key}`]: values[key].filter(isString),
            };
        } else {
            const updatedUiField = Object.assign({}, uiSchema);
            delete updatedUiField[`${keyPrefix}:${key}`];

            uiSchema = {
                ...updatedUiField,
            };
        }
    });

    return uiSchema;
};

export const splitDictionaryItemValues = (itemValues) =>
    (itemValues || "")
        .split("\n")
        .filter((i) => i.length > 0)
        .map((i) => i.split("="))
        // Unescape equals sign
        .map((i) => [unescapeEqualSign(i[0] ?? ""), unescapeEqualSign(i[1] ?? "")]);

/**
 * Convert item list to dbString.
 *
 * @param {[[string, string]]} itemList - items are array of [display, backend] value arrays
 * @returns {string} string with pipe delimited values "backend|display|backend|display|..."
 */
export const itemListToDbString = (itemList) => {
    return itemList
        .map((pair) => [pair[1], pair[0]])
        .flatMap((i) => i)
        .join("|");
};

/**
 * Convert dbString value to dictionary item values.
 *
 * @param {string} dbStringValue with pipe delimited values "backend|display|backend|display|..."
 * @returns {string} dictionary with list items "display=backend\ndisplay=backend\n..."
 */
export const dbStringToDictionaryItemValues = (dbStringValue) => {
    return (
        (dbStringValue ?? "")
            .split("|")
            // create item pairs [[backend, display], ...]
            .reduce((result, next, index) => {
                const pairIndex = Math.floor(index / 2);

                if (index % 2 === 1) {
                    result[pairIndex] = [result[pairIndex], next];
                } else {
                    result[pairIndex] = next;
                }

                return result;
            }, [])
            // escape equals sign
            .map((pair) => [escapeEqualSign(pair[0] ?? ""), escapeEqualSign(pair[1] ?? "")])
            // create items ["display=backend", ...]
            .map((pair) => `${pair[1]}=${pair[0]}`)
            .join("\n")
    );
};

/**
 * Convert item list to anyOf schema.
 *
 * @param {[[string, string]]} itemList - items are array of [display, backend] value arrays
 * @returns {[{title: string, enum: []}]} - anyOf schema
 */
export const itemListToAnyOf = (itemList) => {
    return itemList.map((i) => ({
        title: i[0],
        enum: [isNil(i[1]) ? i[0] : i[1]],
    }));
};

export const anyOfToItemValues = (anyOfValues) => {
    if (Array.isArray(anyOfValues)) {
        return anyOfValues
            .map((i) => ({
                ...i,
                title: escapeEqualSign(i.title ?? ""),
                enum: [escapeEqualSign(i.enum[0] ?? "")],
            }))
            .map((i) => `${i.title}=${i.enum[0] ?? ""}`)
            .join("\n");
    }

    return "";
};

/**
 * Update field "remove" rules to rules that only set the flag that field should be removed.
 * @param {Array} rules - rules to update
 */
export const updateFieldRemoveRules = (rules) => {
    const newRules = cloneDeep(rules);
    newRules.forEach((rule) => {
        if (rule?.event?.type === "remove" && rule?.event?.params?.field) {
            rule.event.type = "uiAppend";
            rule.event.params = set({}, rule.event.params.field, {
                "ui:remove": true,
            });
        }
    });

    return newRules;
};

/**
 * Invert condition of rules "show" and change to rule "remove".
 */
export const normalizeRules = (rules) => {
    const newRules = cloneDeep(rules);
    newRules.forEach((rule) => {
        if (rule?.event?.type === "show" && rule?.event?.params?.field) {
            rule.event.type = "remove";
            rule.conditions = {
                not: {
                    ...rule.conditions,
                },
            };
        }
    });

    return newRules;
};

export const isValidWidget = (widget) => {
    /**
     * For some reason there is a compilation error if try to import widgets list object
     * from "src\components\ui\Form\JsonSchema\widgets\index.js" and look through the keys.
     *  */
    return [
        "checkbox",
        "checkboxes",
        "radio",
        "select",
        "hidden",
        "text",
        "password",
        "textarea",
        "email",
        "updown",
        "range",
        "date",
        "file",
        "html",
        "state",
        "readonlyvalue",
        "rawhtml",
        "statement",
        "nontextspacer",
        "defaultdate",
        "spacer",
        "dictionary",
        "largecheckbox",
        "largecheckboxes",
        "daterangevalidation",
        "fieldformat",
        "formwizardtarget",
        "additionalcontacts",
        "applicationcontacts",
        "applicationname",
        "equipmentblock",
        "auditequipmentblock",
        "auditresult",
        "auditresults",
        "auditworkorder",
        "contractorlookuplist",
        "eqipmentrecomms",
        "rebatetotalgrid",
        "workflow",
        "generalprocedure",
        "image",
        "signature",
    ].includes(widget);
};
