import React, { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RolesList } from "components/ui/List/RolesList";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { exportUserList, getProgramListInfo, setProgramLevelRoleForSelection } from "./utils";
import { RolesByProgramSideNav } from "./RolesByProgramSideNav";
import { ChangeProgramLevelRoleTitle, RoleChangeModalMessage } from "./RoleChangeModalMessage";
import { useResource } from "store/resources/useResource";
import { getClientUsersResourceParams } from "store/configureResources";

export const RolesByProgram = memo(({ clientNumber, clientName, utilityNumber, roles, programs }) => {
    const dispatch = useDispatch();
    const utilityName = useSelector((state) => state.resources.utilities.itemsById[utilityNumber]?.utility);

    const [users] = useResource({
        ...getClientUsersResourceParams({ clientNumber, utilityNumber }),
        transform: (data) => data?.clientUserList ?? [],
    });

    const items = useMemo(
        () =>
            (programs ?? []).map((item) => ({
                itemId: item.programNumber,
                itemName: item.program,
            })),
        [programs]
    );

    const itemInfoRef = useRef();

    const itemInfo = useMemo(() => {
        const result = getProgramListInfo({
            utilityNumber,
            roles,
            programs,
            users,
        });
        itemInfoRef.current = result;
        return result;
    }, [utilityNumber, roles, programs, users]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();

    const onManageItems = useCallback(
        (selectedItems) => {
            const programList = Object.keys(selectedItems)
                .filter((key) => selectedItems[key]) // get selected items
                .map((key) => programs.find((i) => i.programNumber === key));

            handleOpenSidePanel(
                <RolesByProgramSideNav
                    clientNumber={clientNumber}
                    clientName={clientName}
                    utilityNumber={utilityNumber}
                    roles={roles}
                    programs={programList}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [clientNumber, clientName, utilityNumber, roles, programs, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onHeaderRoleClick = useCallback(
        ({ roleId, roleName, selectedItems }) => {
            const userNumbers = users.map((i) => i.userNumber);

            const programNumbers = selectedItems?.length > 0 ? selectedItems : programs.map((p) => p.programNumber);

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers,
                programNumbers,
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs.filter((i) => programNumbers.some((item) => item === i.programNumber))}
                        userList={users}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, programs, users, dispatch]
    );

    const onListItemRoleClick = useCallback(
        (data) => {
            const { itemId, roleId } = data;
            const roleName = roles.find((i) => i.roleId === roleId)?.roleName;

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers: users.map((i) => i.userNumber),
                programNumbers: [itemId],
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs.filter((i) => i.programNumber === itemId)}
                        userList={users}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, users, programs, roles, dispatch]
    );

    const onListItemRoleBadgeClick = useCallback((data) => {
        const { roleId, itemData } = data;
        const users = itemData.roleAssignments[roleId];

        if (users) {
            exportUserList({ users });
        }
    }, []);

    const onGetListItemInfo = useCallback(async (itemId, refresh) => {
        return itemInfoRef.current[itemId];
    }, []);

    const roleHeaderButtonTitle = useCallback(({ isAnyItemSelected }) => {
        return isAnyItemSelected ? "Set role for all users in selected programs" : "Set role for all users in all programs";
    }, []);

    const roleButtonTitle = useCallback(({ isHighlighted, roleName }) => {
        return `Move all users to ${roleName} role`;
    }, []);

    const roleBadgeTitle = useCallback(({ roleAssignments, roleName }) => {
        return `${roleAssignments} user${[1].includes(roleAssignments) ? "" : "s"} in ${roleName} role`;
    }, []);

    return (
        <RolesList
            listItemsTitle="Programs"
            roles={roles}
            listItems={items}
            listItemInfo={itemInfo}
            roleHeaderButtonTitle={roleHeaderButtonTitle}
            roleButtonTitle={roleButtonTitle}
            roleBadgeTitle={roleBadgeTitle}
            onHeaderRoleClick={onHeaderRoleClick}
            onListItemRoleClick={onListItemRoleClick}
            onListItemRoleBadgeClick={onListItemRoleBadgeClick}
            onGetListItemInfo={onGetListItemInfo}
            manageSelectedItemsTitle="Manage"
            manageSelectedItemsTooltip="Select programs for user role assignment"
            onManageSelectedItems={onManageItems}
        />
    );
});
