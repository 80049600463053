import React, { memo, useRef } from "react";
import { PropertyList } from "../PropertyList";
import "./LeadImageView.scss";
import { SectionInfo } from "../SectionInfo";
import StatusMsg from "components/ui/StatusMsg";
import { PropertyValue, WidgetType } from "../../types";
import { getLeadImageContentProperties, HEADER_IMAGE_FILE_SELECT_PROPERTY, PORTAL_WIDGET_PROPERTIES } from "../../utils";
import { getLeadImageErrors } from "../../utils/validation";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { cloneDeep } from "lodash";

export const LeadImageView = memo((props: LeadImageViewProps) => {
    const { id, value = {}, onChange, hideDescription, hideContent } = props;
    const valueRef = useRef(value);
    valueRef.current = value;

    const onPropertyChange = (propId: string, val: PropertyValue, arg: any) => {
        const newValue = cloneDeep(valueRef.current);
        onChange(id, { ...newValue, [propId]: val });
    };

    const leadImageProperties = PORTAL_WIDGET_PROPERTIES[WidgetType.LEAD_IMAGE];
    const { errors } = useErrorContext()!;
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const leadImageErrors = getLeadImageErrors(errors, updatedConfig);

    return (
        <div className="flex-one property-list">
            {!hideDescription && (
                <SectionInfo>
                    <StatusMsg
                        msgIcon="info-empty"
                        msgText={
                            <>
                                <strong> Important: </strong> the lead image needs to be in high resolution - as big as possible.
                            </>
                        }
                    ></StatusMsg>
                    {value.imageSize === "fit" && (
                        <StatusMsg
                            msgIcon="info-empty"
                            msgText={
                                <>
                                    <strong> Important: </strong> when choosing <strong>FIT</strong> image size with lead image content it
                                    can overflow. It is important to make sure no overflow is occurring on mobile, tablet and desktop
                                    previews.
                                </>
                            }
                        ></StatusMsg>
                    )}
                </SectionInfo>
            )}
            <div className="lead-image-view">
                <PropertyList
                    className="image-selection"
                    items={HEADER_IMAGE_FILE_SELECT_PROPERTY}
                    config={value}
                    onChange={onPropertyChange}
                    errors={leadImageErrors}
                    containsActiveError={activeError?.isLeadImageError}
                    isExpanded
                />
                {value?.file && (
                    <PropertyList
                        className="image-properties"
                        items={leadImageProperties}
                        config={value}
                        onChange={onPropertyChange}
                        nestingLevel={2}
                        containsActiveError={activeError?.isLeadImageError}
                    />
                )}
                {!hideContent && (
                    <PropertyList
                        className="content-properties"
                        items={getLeadImageContentProperties(value.contentType, value)}
                        config={value}
                        onChange={onPropertyChange}
                        nestingLevel={1}
                        containsActiveError={activeError?.isLeadImageError}
                        isExpanded
                    />
                )}
            </div>
        </div>
    );
});

interface LeadImageViewProps {
    id: string;
    value: any;
    onChange: (val: any, s: any) => void;
    hideDescription?: boolean;
    hideContent?: boolean;
}
