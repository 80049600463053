import React, { useCallback, memo } from "react";
import { useSelector } from "react-redux";

import { programsCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import { calculationTypes } from "../utils";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { getProgramCalculationsGridId, openProgramCalculationsAssignmentsModal } from "./utils";

import Grid from "./Grid";
import Controls from "./Controls";
import UtilityProgramsDropDown from "../UtilityProgramsDropDown";
import AddCalculationPanel from "../AddCalculationPanel";
import ViewPlaceholder from "../../../../ViewPlaceholder";

const ProgramCalculationsTab = memo(
    ({ utilityNumber, programNumber, isProgramCalculations, onProgramNumberChange, onOpenPanel, onClosePanel }) => {
        const userName = useSelector((state) => state.user?.name);
        const programName = useSelector((state) => state.resources?.programs?.itemsById[programNumber]?.program);

        const dataGridId = getProgramCalculationsGridId({
            utilityNumber,
            programNumber,
        });

        const onAddCalculation = useCallback(() => {
            onOpenPanel(
                <AddCalculationPanel
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                    calculationType={calculationTypes.program}
                    user={userName}
                    onClose={onClosePanel}
                />
            );
        }, [utilityNumber, programNumber, userName, onOpenPanel, onClosePanel]);

        const onManageAssociations = useCallback(() => {
            openProgramCalculationsAssignmentsModal({
                utilityNumber,
                programNumber,
            });
        }, [utilityNumber, programNumber]);

        const onExport = useCallback(() => {
            exportDatagridToCSV({
                dataGridId,
                fileName: "program_calculations",
                fileNamePostfix: programNumber,
            });
        }, [dataGridId, programNumber]);

        return (
            <div className="program-calculations-tab tab-list-tab-content flex-column fill-height">
                <div className="grid-controls flex-row justify-space-between align-center">
                    {isProgramCalculations ? (
                        <>
                            <strong>Program:</strong>
                            {programName}
                        </>
                    ) : (
                        <UtilityProgramsDropDown utilityNumber={utilityNumber} program={programNumber} onChange={onProgramNumberChange} />
                    )}
                    <div className="flex-one"></div>
                    {programNumber && (
                        <Controls
                            programNumber={programNumber}
                            onAddCalculation={onAddCalculation}
                            onManageAssociations={onManageAssociations}
                            onExport={onExport}
                        />
                    )}
                </div>
                {programNumber ? (
                    <Grid
                        gridId={dataGridId}
                        filter={`${programsCalculationsGridColumnKeys.programNumber}=${programNumber}`}
                        utilityNumber={utilityNumber}
                        programNumber={programNumber}
                        onOpenPanel={onOpenPanel}
                        onClosePanel={onClosePanel}
                    />
                ) : (
                    <ViewPlaceholder viewPlaceholderSmall>Please select Program to get results.</ViewPlaceholder>
                )}
            </div>
        );
    }
);

export default ProgramCalculationsTab;
