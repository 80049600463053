import * as actionTypes from "../actionTypes";
import { setSubMenuState } from "../../components/utils/subMenu";

export const setActiveSubMenu =
    ({ key, subMenu }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_ACTIVE_SUBMENU,
            key,
            subMenu,
        });

        setSubMenuState(getState().subMenu);
    };
