import CustomList from "components/ui/List/CustomList";
import React from "react";

export const FastTagList = (props: FastTagListProps) => {
    const { fastTags, onClick } = props;

    const items = fastTags.map((fastTag) => ({ fastTag, onClick }));

    return (
        // @ts-ignore
        <CustomList className="no-scroll fill-height" items={items} headers={headers} renderItem={ListItem} />
    );
};

interface FastTagListProps {
    fastTags: string[];
    onClick: (fastTag: string) => void;
}

export const headers = {
    fastTag: "Fast Tag",
};

export const ListItem = ({ fastTag, onClick }: ListItemProps) => {
    return (
        <div className="list-item-row" onClick={() => onClick(fastTag)}>
            <div className="list-item-column">{fastTag}</div>
        </div>
    );
};

interface ListItemProps {
    fastTag: string;
    onClick: (fastTag: string) => void;
}
