import { memo, useRef } from "react";

const WindowContent = memo(
    ({ active, children }) => {
        const viewActivated = useRef(false);
        if (!viewActivated.current) {
            viewActivated.current = active;

            if (!active) {
                return null;
            }
        }

        return children;
    },
    (prevPrps, nextProps) => {
        return !nextProps.active;
    }
);

export default WindowContent;
