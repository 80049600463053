import React, { memo } from "react";

import { useResource } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import LocationMap from "../../../../Location/Map";
import SidebarPanel from "../../../../SidebarPanel";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";

const LocationPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand }) => {
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;

    const [location = {}, isLoadingLocation] = useResource({
        resourceName: "applicationLocation",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        showErrorNotification: false,
    });

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={<SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />}
            noPadding={location.address}
            noData={!location.address}
            noFooter
        >
            {isLoadingLocation ? (
                <WaitIcon />
            ) : (
                <div className="location-container">
                    {location.address ? (
                        <div className="fill-width map">
                            <LocationMap showMarker applicationNumber={applicationNumber} location={location} height="212" />
                        </div>
                    ) : (
                        <NothingFoundBlock nothingFoundBlockSmall icon="location" title="No Data Available" />
                    )}
                </div>
            )}
        </SidebarPanel>
    );
});

export default LocationPanelSmall;
