import getPath from "lodash/get";

import * as actionType from "../actionTypes";
import { toArray } from "../../components/utils/array";
import {
    filterRows,
    setTreeParams,
    getPageSize,
    getColumns,
    getTotalRecords,
    getClientRowsPage,
    sortRows,
    getExpandedRowsIds,
} from "components/utils/datagrid";

const initialState = {};

export function reducer(state = initialState, action) {
    const { passThroughData } = action;
    let gridName = action.name;

    if (passThroughData) {
        gridName = passThroughData.name || passThroughData.dataGridId;
    }

    let currentGrid = state[gridName];

    if (!currentGrid && ![actionType.DATA_GRID_INIT].includes(action.type)) {
        return state;
    }

    if (gridName) {
        switch (action.type) {
            case actionType.DATA_GRID_INIT:
                state = {
                    ...state,
                    [gridName]: action.dataGrid,
                };
                break;
            case actionType.DATA_GRID_DESTROY:
                const { [gridName]: _remove, ...updatedState } = state;

                state = {
                    ...updatedState,
                };
                break;
            case actionType.DATA_GRID_MOUNT:
                currentGrid = {
                    ...currentGrid,
                    isMounted: true,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_UNMOUNT:
                currentGrid = {
                    ...currentGrid,
                    isMounted: false,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CHANGE_SETTINGS_OPEN:
                currentGrid = {
                    ...currentGrid,
                    isSettingsOpen: {
                        ...currentGrid.isSettingsOpen,
                        [action.containerName]: action.isOpen,
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_UPDATE_COLUMNS:
                currentGrid = {
                    ...currentGrid,
                    config: action.config,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CONSTRUCT_REQUEST:
                currentGrid = {
                    ...currentGrid,
                    isConstructing: true,
                    isError: false,
                    message: null,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CONSTRUCT_SUCCESS:
                // limited flag for limit property in data grid
                const take = getPath(currentGrid, "paging.limited")
                    ? getPath(currentGrid, "paging.take")
                    : getPageSize(action.data.grid[0]);

                currentGrid = {
                    ...currentGrid,
                    ...action.data.grid[0],
                    name: gridName,
                    isConstructing: false,
                    columns: getColumns(action.data.grid[0]),
                    rows: currentGrid.rows,
                    // do not use datagrid configured sorting
                    //sort: getColumnSort(action.data.grid[0]),
                    paging: {
                        skip: 0,
                        take,
                        total: getTotalRecords(action.data.grid[0]),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CONSTRUCT_ERROR:
                state = {
                    ...state,
                    isConstructing: false,
                    isError: true,
                    message: action.message,
                };
                break;
            case actionType.DATA_GRID_GET_DATA_REQUEST:
                currentGrid = {
                    ...currentGrid,
                    isReading: true,
                    isError: false,
                    message: null,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_DATA_SUCCESS:
                const rows = toArray(action.data.grid.rows);

                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isFilterChanged: false,
                    isDataRequested: true,
                    rows,
                    needFilter: action.data.grid.needFilter,
                    paging: {
                        ...currentGrid.paging,
                        total: getTotalRecords({ ...currentGrid, rows }),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CLIENT_GET_DATA_SUCCESS:
                const expandedRowsSnapshot = getExpandedRowsIds(currentGrid);

                const sourceRows = setTreeParams({
                    ...(currentGrid.tree ?? {}),
                    rows: [...toArray(action.data.grid.rows)],
                    expandedRowsSnapshot,
                });

                const filteredSourceRows = currentGrid.isServerSideFiltering
                    ? sourceRows
                    : filterRows({
                          filter: currentGrid.filter,
                          rows: sourceRows,
                          columns: currentGrid.columns,
                          timezoneOffset: action.timezoneOffset,
                      });

                const sortedRows = sortRows({
                    sort: currentGrid.sort,
                    rows: filteredSourceRows,
                    columns: currentGrid.columns,
                });
                const rowsPage = getClientRowsPage(currentGrid, sortedRows);

                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isFilterChanged: false,
                    isDataRequested: true,
                    sourceRows: sourceRows,
                    filteredSourceRows,
                    rows: rowsPage,
                    paging: {
                        ...currentGrid.paging,
                        total: getTotalRecords({
                            ...currentGrid,
                            rows: rowsPage,
                        }),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CLIENT_PAGINATE: {
                const sourceRows = currentGrid.sourceRows;
                const filteredSourceRows = filterRows({
                    filter: currentGrid.filter,
                    rows: sourceRows,
                    columns: currentGrid.columns,
                    timezoneOffset: action.timezoneOffset,
                });
                const sortedRows = sortRows({
                    sort: currentGrid.sort,
                    rows: filteredSourceRows,
                    columns: currentGrid.columns,
                });
                const rowsPage = getClientRowsPage(currentGrid, sortedRows);

                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isFilterChanged: false,
                    sourceRows: [...sourceRows],
                    filteredSourceRows,
                    rows: rowsPage,
                    paging: {
                        ...currentGrid.paging,
                        total: getTotalRecords({
                            ...currentGrid,
                            rows: rowsPage,
                        }),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };

                break;
            }
            case actionType.DATA_GRID_CLIENT_FILTER: {
                const sourceRows = currentGrid.sourceRows;
                const filteredSourceRows = filterRows({
                    filter: currentGrid.filter,
                    rows: sourceRows,
                    columns: currentGrid.columns,
                    timezoneOffset: action.timezoneOffset,
                });
                const sortedRows = sortRows({
                    sort: currentGrid.sort,
                    rows: filteredSourceRows,
                    columns: currentGrid.columns,
                });
                const rowsPage = getClientRowsPage(
                    {
                        paging: {
                            ...currentGrid.paging,
                            skip: 0,
                        },
                    },
                    sortedRows
                );

                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isFilterChanged: false,
                    sourceRows: [...sourceRows],
                    filteredSourceRows,
                    rows: rowsPage,
                    paging: {
                        ...currentGrid.paging,
                        skip: 0,
                        total: getTotalRecords({
                            ...currentGrid,
                            rows: rowsPage,
                        }),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };

                break;
            }
            case actionType.DATA_GRID_CLIENT_SORT: {
                const sortedRows = sortRows({
                    sort: currentGrid.sort,
                    rows: currentGrid.filteredSourceRows,
                    columns: currentGrid.columns,
                });

                const rowsPage = getClientRowsPage(currentGrid, sortedRows);

                currentGrid = {
                    ...currentGrid,
                    filteredSourceRows: sortedRows,
                    rows: rowsPage,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };

                break;
            }
            case actionType.DATA_GRID_TREE_EXPAND_CHANGE: {
                const filteredSourceRows = filterRows({
                    filter: currentGrid.filter,
                    rows: action.sourceRows,
                    columns: currentGrid.columns,
                    timezoneOffset: action.timezoneOffset,
                });
                const sortedRows = sortRows({
                    sort: currentGrid.sort,
                    rows: filteredSourceRows,
                    columns: currentGrid.columns,
                });
                const rowsPage = getClientRowsPage(currentGrid, sortedRows);

                currentGrid = {
                    ...currentGrid,
                    filteredSourceRows,
                    rows: rowsPage,
                    sourceRows: [...action.sourceRows],
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            }
            case actionType.DATA_GRID_ROW_EXPAND_CHANGE: {
                currentGrid = {
                    ...currentGrid,
                    rows: action.rows,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            }

            case actionType.DATA_GRID_ROW_GROUP_EXPAND_CHANGE: {
                currentGrid = {
                    ...currentGrid,
                    rowGroups: action.rowGroups,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            }

            case actionType.DATA_GRID_CLEAR_DATA:
                currentGrid = {
                    ...currentGrid,
                    rows: [],
                    paging: {
                        ...currentGrid.paging,
                        total: getTotalRecords({ ...currentGrid, rows: [] }),
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_DATA_EXPORT:
            case actionType.DATA_GRID_CLIENT_GET_DATA_EXPORT:
                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isFilterChanged: false,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_DATA_ERROR:
                currentGrid = {
                    ...currentGrid,
                    isReading: false,
                    isError: true,
                    isDataRequested: true,
                    message: action.message,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_COLUMNS_REQUEST:
                currentGrid = {
                    ...currentGrid,
                    isReadingColumns: true,
                    isError: false,
                    message: null,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_COLUMNS_SUCCESS:
                currentGrid = {
                    ...currentGrid,
                    isReadingColumns: false,
                    managedColumns: action.data.dataGridColumnsResults,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_GET_COLUMNS_ERROR:
                currentGrid = {
                    ...currentGrid,
                    isReadingColumns: false,
                    isError: true,
                    message: action.message,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SET_COLUMNS_REQUEST:
                currentGrid = {
                    ...currentGrid,
                    columns: passThroughData.columns,
                    isUpdatingColumns: true,
                    isError: false,
                    message: null,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SET_COLUMNS_SUCCESS:
                currentGrid = {
                    ...currentGrid,
                    isUpdatingColumns: false,
                    managedColumns: [],
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SET_COLUMNS_ERROR:
                currentGrid = {
                    ...currentGrid,
                    isUpdatingColumns: false,
                    isError: true,
                    message: action.message,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SET_DATA_ACTION_PROPS:
                currentGrid = {
                    ...currentGrid,
                    getDataAction: {
                        ...currentGrid.getDataAction,
                        props: action.getDataActionProps,
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SORT:
                currentGrid = {
                    ...currentGrid,
                    columns: action.columns,
                    sort: action.sort,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_FILTER:
                currentGrid = {
                    ...currentGrid,
                    filter: action.filter,
                    isFilterChanged: true,
                };

                if (action.setAsDefault) {
                    currentGrid = {
                        ...currentGrid,
                        defaultFilter: action.filter,
                    };
                }

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SET_PAGE:
                currentGrid = {
                    ...currentGrid,
                    paging: {
                        ...currentGrid.paging,
                        skip: action.skip,
                        take: action.take,
                    },
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;

            case actionType.DATA_GRID_SET_SOURCE_ROWS:
                currentGrid = {
                    ...currentGrid,
                    sourceRows: [...action.rows],
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_SELECT_ROWS:
                currentGrid = {
                    ...currentGrid,
                    rows: [...action.rows],
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_UPDATE_ROWS:
                currentGrid = {
                    ...currentGrid,
                    rows: [...action.rows],
                };
                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            case actionType.DATA_GRID_CLEAR_ERROR:
                currentGrid = {
                    ...currentGrid,
                    isError: false,
                    message: null,
                };

                state = {
                    ...state,
                    [gridName]: currentGrid,
                };
                break;
            default:
                break;
        }
    }

    return state;
}
