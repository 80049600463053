import React from "react";

import Button from "../../../ui/Button";
import IconWrap from "../../../ui/Icons";
import ButtonGroup from "../../../ui/Button/ButtonGroup";

class Buttons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            splitButtonState: true,
            splitButtons: [
                {
                    text: "Button",
                    active: true,
                },
                {
                    text: "Button",
                    active: false,
                },
            ],
            splitButtons2: [
                {
                    icon: "blocks",
                    active: true,
                },
                {
                    icon: "lines",
                    active: false,
                },
            ],
        };

        this.onSplitButtonClick = this.onSplitButtonClick.bind(this);
        this.onSplitButtonClick2 = this.onSplitButtonClick2.bind(this);
    }
    onSplitButtonClick() {
        const newState = this.state.splitButtons.map((b) => {
            b.active = !b.active;
            return b;
        });

        this.setState({
            splitButtons: newState,
        });
    }
    onSplitButtonClick2() {
        const newState = this.state.splitButtons2.map((b) => {
            b.active = !b.active;
            return b;
        });

        this.setState({
            splitButtons2: newState,
        });
    }
    render() {
        return (
            <div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Standard</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button standard primary>
                                    Button
                                </Button>
                                <Button standard primary focus>
                                    Button
                                </Button>
                                <Button standard primary disabled>
                                    Button
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button standard>Button</Button>
                                <Button standard focus>
                                    Button
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Split Buttons</div>
                        <div className="row">
                            <ButtonGroup>
                                {this.state.splitButtons.map((button, i) => (
                                    <Button key={i} primary={button.active} focus={button.active} onClick={this.onSplitButtonClick}>
                                        {button.text}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                        <div className="row">
                            <ButtonGroup>
                                {this.state.splitButtons2.map((button, i) => (
                                    <Button
                                        key={i}
                                        square
                                        primary={button.active}
                                        focus={button.active}
                                        icon={button.icon}
                                        onClick={this.onSplitButtonClick2}
                                    ></Button>
                                ))}
                            </ButtonGroup>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Icon Buttons</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button standard primary icon="star">
                                    Button
                                </Button>
                                <Button standard icon="star">
                                    Button
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button standard primary iconRight icon="play-circle-filled">
                                    Run Calculations
                                </Button>
                                <Button standard iconRight icon="play-circle-filled">
                                    Run Calculations
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Upload File</div>
                        <div className="row">
                            <div className="upload-file-wrap">
                                <Button standard icon="upload-file" upload>
                                    <input type="file" id="file" multiple="" />
                                    <label htmlFor="file">Choose file</label>
                                </Button>
                                <ul className="file-list">
                                    <li className="with-icon attachment">
                                        Filename.pdf (pdf, 181Kb)
                                        <IconWrap iconWrapClickable iconWrap="remove" title="Remove"></IconWrap>
                                    </li>
                                    <li className="with-icon attachment">
                                        Filename2111122.pdf (pdf, 181Kb)
                                        <IconWrap iconWrapClickable iconWrap="remove" title="Remove"></IconWrap>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Standard Small Buttons</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button small standard primary>
                                    Button
                                </Button>
                                <Button small standard primary focus>
                                    Button
                                </Button>
                                <Button small standard primary disabled>
                                    Button
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <Button small standard>
                                    Button
                                </Button>
                                <Button small standard focus>
                                    Button
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Buttons;
