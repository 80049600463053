import React, { useCallback, useRef, useMemo, useState, memo, useEffect } from "react";
import { isString } from "lodash";
import FieldGroupDropdownMultipleChoice from "../FieldGroupDropdownMultipleChoice";
import { useOnListKeyDown } from "./DropDownInput";
import Checkbox from "./Checkbox";
import { decodeHtml } from "components/utils/string";

const SELECT_ALL_ITEMS_VALUE = "__SELECT_ALL_ITEMS__";

const DropDownMultiSelect = memo((props) => {
    const {
        label,
        name,
        mobileHeader,
        placeholder,
        data,
        value,
        disabled,
        readOnly,
        inline,
        required,
        msgInfo,
        msgError,
        msgText,
        selectAllItem,
        selectAllItemLabel,
        withPopper,
        popupBoundary,
        onChange,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    const [focusedItemIndex, setFocusedItemIndex] = useState(null);

    const ref = useRef();

    const withFilter = (data || []).length > 5;
    const isFilled = value && value.length > 0;

    const dataItems = useMemo(() => {
        const filter = (filterValue || "").toLowerCase();

        return (data || []).map((item, index) => {
            return {
                ...item,
                label: isString(item.label) ? decodeHtml(item.label) : item.label,
                selected: (value || []).includes(item.value),
                hidden: filter !== "" && !(item.label || "").toLowerCase().includes(filter),
                focused: index === focusedItemIndex,
            };
        });
    }, [data, value, filterValue, focusedItemIndex]);

    const selectAllState = useMemo(() => {
        const itemCount = dataItems?.length ?? 0;
        const selectedItemCount = value?.length ?? 0;

        let state = false;
        if (selectedItemCount > 0) {
            state = true;

            if (selectedItemCount !== itemCount) {
                state = null;
            }
        }

        return state;
    }, [dataItems, value]);

    const items = useMemo(() => {
        const firstItems = selectAllItem
            ? [
                  {
                      label: <SelectAllItemLabel state={selectAllState}>{selectAllItemLabel}</SelectAllItemLabel>,
                      value: SELECT_ALL_ITEMS_VALUE,
                      className: "dropdown-list-item--select-all",
                      isSelectAllItem: true,
                  },
              ]
            : [];

        return firstItems.concat(dataItems);
    }, [dataItems, selectAllItem, selectAllItemLabel, selectAllState]);

    useEffect(() => {
        if (isOpen && focusedItemIndex === null) {
            const selectedElement = ref.current.querySelector(".dropdown-list-item.selected");

            if (selectedElement) {
                selectedElement.scrollIntoView({
                    block: "nearest",
                    inline: "start",
                });
            }
        }
    }, [isOpen, items, focusedItemIndex]);

    const onSelectAllClick = useCallback(
        (event) => {
            event && event.stopPropagation();

            let values = null;

            // deselect all
            if (selectAllState) {
                values = [];
            }
            // select all
            else {
                values = dataItems.map((i) => i.value);
            }

            if (onChange) {
                onChange({
                    target: { type: "select", name: name, value: values },
                });
            }
        },
        [name, dataItems, selectAllState, onChange]
    );

    const onListSelect = useCallback(
        (item) => {
            let values = (value || []).concat([item.value]);

            if (item.value === SELECT_ALL_ITEMS_VALUE) {
                onSelectAllClick();
                return false;
            }

            if (onChange) {
                onChange({
                    target: { type: "select", name: name, value: values },
                });
            }
        },
        [name, value, onChange, onSelectAllClick]
    );

    const onListRemove = useCallback(
        (item) => {
            const values = (value || []).filter((v) => v !== item.value);

            if (onChange) {
                onChange({
                    target: { type: "select", name: name, value: values },
                });
            }
        },
        [name, value, onChange]
    );

    const onFilterChange = useCallback((event) => {
        setFilterValue(event.target.value);
    }, []);

    const onListKeyDown = useOnListKeyDown({
        items,
        onListSelect,
        focusedItemIndex,
        setFocusedItemIndex,
        ref,
    });

    return (
        <FieldGroupDropdownMultipleChoice
            containerRef={ref}
            inline={inline}
            label={label}
            mobileHeader={mobileHeader}
            required={required}
            error={msgError}
            placeholder={isFilled ? "" : placeholder}
            filled={isFilled}
            active={isOpen}
            onClick={() => (data || []).length && setIsOpen((state) => !state)}
            visible={isOpen}
            dropdownFieldIcon={!isOpen ? "shevron-small-down-expand-more" : "shevron-in-circle-up-filled"}
            items={items}
            tags={items}
            disabled={disabled}
            readOnly={readOnly}
            onSelect={onListSelect}
            onRemove={onListRemove}
            // messages
            msgInfo={msgInfo}
            msgError={msgError}
            msgText={msgText}
            // filter props
            withFilter={withFilter}
            filterValue={filterValue}
            onFilterChange={onFilterChange}
            onListKeyDown={onListKeyDown}
            // Render dropdown list in portal with popper
            withPopper={withPopper}
            popupBoundary={popupBoundary}
        />
    );
});

const SelectAllItemLabel = ({ children, state }) => {
    return (
        <>
            <span className="select-all-label">{children ?? (state ? "Unselect all" : "Select all")}</span>
            <Checkbox iconLabelEmpty checked={state} checkedPartly={!state} />
        </>
    );
};

export default DropDownMultiSelect;
