import React, { memo, useContext, useCallback } from "react";

import { GridListContext } from ".";
import GridListCell from "./GridListCell";
import IconWrap from "../../Icons";
import GridListResizeHandler from "./GridListResizeHandler";

const GridListHeaderCell = memo(({ column }) => {
    const { sortable, columnWidths } = useContext(GridListContext);

    return (
        <GridListCell className={sortable ? "sortable" : undefined} width={columnWidths[column.key]}>
            <ColumnContent column={column} />
            <GridListResizeHandler column={column} />
        </GridListCell>
    );
});

const ColumnContent = memo(({ column }) => {
    const { columnHeaderContent = {}, sortable, sort, onSortChange } = useContext(GridListContext);

    const hasSortParams = sortable && sort.length > 0;
    const sortBy = hasSortParams ? sort[0].field : null;
    const sortAsc = hasSortParams ? sort[0].dir === "asc" : null;

    const onSort = useCallback(
        (column) => {
            if (sortable) {
                const event = {
                    sort: [
                        {
                            field: column.key,
                            dir: sortBy === column.key ? (sortAsc ? "desc" : "asc") : "asc",
                        },
                    ],
                };

                onSortChange(event);
            }
        },
        [sortAsc, sortBy, sortable, onSortChange]
    );

    return (
        <div id={column.key} className="column-content" onClick={() => onSort(column)}>
            <div className="column-name" data-test-id={`${column.key}-header`}>
                {columnHeaderContent[column.key] ? columnHeaderContent[column.key](column) : column.name.toUpperCase()}
            </div>
            {sortBy !== column.key && (
                <IconWrap
                    data-test-id={`${column.key}-header-sort`}
                    icon="swap-vertical"
                    title="Sort ASC or DESC"
                    onClick={() => onSort(column)}
                />
            )}
            {sortBy === column.key && (
                <div
                    data-test-id={`${column.key}-header-sort`}
                    className={"sorted" + (sortAsc ? " asc" : " desc")}
                    title={"Sort " + (sortAsc ? "Descending" : "Ascending")}
                >
                    <IconWrap icon="swap-vertical" />
                    <div className={"column-sorting-hint" + (sortAsc ? " asc" : " desc")}>{sortAsc ? "asc" : "dsc"}</div>
                </div>
            )}
        </div>
    );
});

export default GridListHeaderCell;
