import React, { memo } from "react";

import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import withDataGrid from "../../ui/DataGrid/withDataGrid";
import { useSelector } from "react-redux";

const DATA_GRID_ID = "logs-system-job";

const LogsGrid = withDataGrid("logsSystemJob", ({ gridId, dataGridConfig, filterRequired }) => {
    const ROW_ACTIONS = [];

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={ROW_ACTIONS} filterRequired={filterRequired} />;
});

const LogsSystemJob = memo(() => {
    const noFilters = useSelector((state) => !state.dataGrid[DATA_GRID_ID]?.filter?.filters?.length);
    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column no-scroll">
            <div className="data-grid-controls">
                <span className="flex-one"></span>
                <IconWithLabel
                    disabled={noFilters}
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "log_jobs",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <LogsGrid filterRequired={true} gridId={DATA_GRID_ID} />
        </div>
    );
});

export default LogsSystemJob;
