import React, { useState, useRef, memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { createResource, updateResource } from "../../../../../../../store/resources/actions";
import { utilitiesAttributesGridColumnKeys } from "../../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../../utils/datagrid";
import { getData } from "../../../../../../../store/dataGrid/actions";
import { referenceTypes } from "../../../../../Reference/referenceTypes";

import JsonSchemaForm from "../../../../../Form/JsonSchema/JsonSchemaForm";

import { submitByRef } from "../../../../../../utils/form";

import SideNavBody from "../../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../../SideNav/SideNavContent";
import SideNavFooter from "../../../../../SideNav/SideNavFooter";
import Button from "../../../../../Button";
import { useResource } from "store/resources/useResource";
import WaitIcon from "components/ui/WaitIcon";

const AttributesFormPreload = (props) => {
    const [attributeTypes, isLoading] = useResource({
        resourceName: "entityReference",
        key: props.utilityNumber,
        path: {
            entityNumber: props.utilityNumber,
        },
        query: {
            type: referenceTypes.utilityAttribute,
            currentGeneralAttributeNumber: props.attributeNumber,
        },
        transform: (data) => data?.referenceResults ?? [],
        forced: true,
    });
    if (isLoading) {
        return <WaitIcon />;
    }

    return <AttributesForm {...props} attributeTypes={attributeTypes} />;
};

const AttributesForm = memo(({ utilityNumber, gridId, dataItem, onClose, sidePanel, attributeTypes }) => {
    const dispatch = useDispatch();

    const isNew = dataItem == null;
    const initialValues = isNew ? {} : mapGridRowToObject(utilitiesAttributesGridColumnKeys, dataItem);

    const availableAttributeTypes = attributeTypes.map((i) => ({
        title: i.display,
        enum: [+i.val],
    }));

    if (!isNew) {
        const defaultAttrType = availableAttributeTypes.find((attr) => attr.title === initialValues.attributeType);
        if (defaultAttrType) {
            initialValues.dataType = defaultAttrType.enum[0];
        }
    }

    const required = ["dataType", "dataValue"];

    const schema = {
        type: "object",
        required,
        properties: {
            dataType: {
                type: "integer",
                title: "Attribute Type",
                anyOf: availableAttributeTypes,
            },
            dataValue: {
                type: "string",
                title: "Attribute Value",
                default: initialValues != null ? initialValues.attributeValue : "",
            },
        },
    };

    const uiSchema = {
        dataType: {
            "ui:options": {
                placeholder: "Select Attribute Type",
            },
        },
        dataValue: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
    };

    const [isSubmitting, setSubmitting] = useState(false);

    const onSubmit = (formData) => {
        setSubmitting(true);

        if (isNew) {
            dispatch(
                createResource({
                    resourceName: "generalAttributes",
                    body: {
                        ...formData,
                        tableName: "utility attribute",
                        primaryKeys: utilityNumber,
                    },
                    onSuccess,
                    onComplete: () => setSubmitting(false),
                })
            );
        } else {
            dispatch(
                updateResource({
                    resourceName: "generalAttributes",
                    resourceId: initialValues.attributeNumber,
                    body: {
                        ...formData,
                        tableName: "utility attribute",
                        primaryKeys: utilityNumber,
                    },
                    onSuccess,
                    onComplete: () => setSubmitting(false),
                })
            );
        }
    };

    const onSuccess = () => {
        sidePanel.close();
        dispatch(
            getData({
                dataGridId: gridId,
            })
        );
    };

    const formRef = useRef();
    const leadBlockIcon = isNew ? "plus" : "edit-empty";

    const getSubmitText = (isNew, isSubmitting) => {
        if (!isNew) {
            return isSubmitting ? "Saving..." : "Save";
        }

        return isSubmitting ? "Adding..." : "Add";
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent>
            <SideNavHeader
                title={isNew ? "Add New attribute" : "Edit Attribute"}
                leadBlockIcon={leadBlockIcon}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody>
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    submitText={getSubmitText(isNew, isSubmitting)}
                    disabled={isSubmitting}
                    noCancel
                    noSubmit
                    noReset
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} icon={isNew ? "plus" : ""} onClick={() => submitByRef(formRef)}>
                    {getSubmitText(isNew, isSubmitting)}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default AttributesFormPreload;
