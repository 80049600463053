import React from "react";
import { ChildrenProps } from "./index";
import { SECTION_ACCESSIBILITY } from "../Section";
import { SectionHeader } from "../SectionHeader";

import "./AccessibilityProperties.scss";

export const AccessibilityProperties = ({ children }: ChildrenProps) => {
    return (
        <div className="portal-builder-properties-accessibility flex-column flex-one-in-column fill-width">
            <SectionHeader Icon={SECTION_ACCESSIBILITY.Icon} title={SECTION_ACCESSIBILITY.title} underline />
            {children}
        </div>
    );
};
