import React from "react";
import { ITEM_HEIGHT, OVERSCAN_COUNT } from ".";
import AvailableListItem from "./AvailableListItem";
import AutoSizeList from "./AutoSizeList";
import ListFilter from "./ListFilter";

const AvailableList = ({ items, selectedItems, nameKey, filterValue, onFilterChange, onAdd, AvailableListItemComponent }) => {
    return (
        <>
            <ListFilter autoFocus filterValue={filterValue} onFilterChange={onFilterChange} />
            <div className="assignment-selector__list-container">
                <AutoSizeList
                    className="assignment-selector__list assignment-selector__list--selectable"
                    itemCount={items.length}
                    itemSize={ITEM_HEIGHT}
                    overscanCount={OVERSCAN_COUNT}
                >
                    {(props) => (
                        <AvailableListItem
                            {...props}
                            items={items}
                            selectedItems={selectedItems}
                            nameKey={nameKey}
                            onAdd={onAdd}
                            ItemComponent={AvailableListItemComponent}
                        />
                    )}
                </AutoSizeList>
            </div>
        </>
    );
};

export default AvailableList;
