import React, { useContext } from "react";
import { BorderRadiusPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderCornerName, BorderRadiusCustomPropertyName, BorderRadiusSideBySideValue, BorderSides } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "../PropertyListItem";
import { BorderCorner } from "./BorderCorner";

export const CornerSideBySideProperty = (props: CornerSideBySidePropertyProps) => {
    const { value, onChange } = props;
    const { customPropertiesValue } = useContext(BorderRadiusPropertyContext);

    const onSelect = (id: PropertyName, corner: SideBySideCorner) => (size: string) => {
        // Deselect the value if same width is selected.
        if (size === value[corner]) {
            onChange(id, "0");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[BorderRadiusCustomPropertyName.BorderRadiusType] !== BorderSides.SideBySide) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--border-corner flex-column" borderTop={false} borderBottom={false}>
            <BorderCorner
                side={BorderCornerName.TopLeft}
                value={value[BorderCornerName.TopLeft]}
                onSelect={onSelect(PropertyName.BorderTopLeftRadius, BorderCornerName.TopLeft)}
            />
            <BorderCorner
                side={BorderCornerName.TopRight}
                value={value[BorderCornerName.TopRight]}
                onSelect={onSelect(PropertyName.BorderTopRightRadius, BorderCornerName.TopRight)}
            />
            <BorderCorner
                side={BorderCornerName.BottomRight}
                value={value[BorderCornerName.BottomRight]}
                onSelect={onSelect(PropertyName.BorderBottomRightRadius, BorderCornerName.BottomRight)}
            />
            <BorderCorner
                side={BorderCornerName.BottomLeft}
                value={value[BorderCornerName.BottomLeft]}
                onSelect={onSelect(PropertyName.BorderBottomLeftRadius, BorderCornerName.BottomLeft)}
            />
        </PropertyListItem>
    );
};

interface CornerSideBySidePropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value: BorderRadiusSideBySideValue;
}

export interface BorderCornerProps {
    side: BorderCornerName;
    value: string;
    onSelect: (size: string) => void;
}

type SideBySideCorner = Exclude<BorderCornerName, BorderCornerName.AllSides>;
