import React, { memo, useContext } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import DataSourceFilterList from "./DataSourceFilterList";
import DataSourceFilterDetails from "./DataSourceFilterDetails";
import DataSourceFilterControls from "./DataSourceFilterControls";
import { WorkCenterContext } from ".";
import { useIsMobile } from "components/utils/useIsMobile";

import IconWrap from "../../ui/Icons";

const WorkqueueFilters = memo(({ closeFilterPanel }) => {
    const { instanceId } = useContext(WorkCenterContext);

    const isMobile = useIsMobile();

    const datasourceNumber = useSelector((state) => state.workcenter[instanceId].activeDataSource?.datasourceNumber) ?? 0;

    const isFiltersListEmpty = useSelector((state) => {
        const userNumber = state.user.userNumber;
        return state.resources.workcenter.itemsById[`${userNumber}-workcenter`]?.length === 0;
    });

    return (
        <div
            className={cn("filter-block fill-height flex-row", {
                "new-filter": datasourceNumber === "0",
            })}
        >
            {isMobile && <IconWrap icon="clear-close" onClick={closeFilterPanel} />}
            <div className="filter-block__static flex-column">
                <DataSourceFilterList />
                {!isFiltersListEmpty && <DataSourceFilterControls closeFilterPanel={closeFilterPanel} />}
            </div>
            <DataSourceFilterDetails />
        </div>
    );
});

export default WorkqueueFilters;
