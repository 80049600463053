import React, { useCallback, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalOpen } from "store/modal/actions";
import { openConfirmModal } from "components/ui/Modal/utils";

import { qualityControlGridColumnKeys } from "../../../../../views/configureGrids";
import { createResource, updateResource, getResource, deleteResource } from "../../../../../../store/resources/actions";
import { getData, setPage } from "../../../../../../store/dataGrid/actions";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { appIsLockedText } from "../utils";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

import QualityControlAddRequest from "./QualityControlAddRequest";
import QualityControlGrid from "./QualityControlGrid";

import GridSelectedItemsLabel from "components/ui/DataGrid/GridSelectedItemsLabel";
import Button from "components/ui/Button";

import "../ProjectCommonStyles.scss";
import useMultiPageRowSelect from "store/dataGrid/useMultiPageRowSelect";

const QualityControlPanel = memo(({ panel }) => {
    const { applicationNumber, programNumber } = panel?.data;
    const gridId = `${applicationNumber}-quality-control-grid`;
    const [programRights = []] = useProgramRights({ programNumber });
    const isAppLocked = isApplicationLocked({ applicationNumber });
    const isAddRequestDisabled = isAppLocked || !programRights.includes(systemUserRights.VISIONDSM_ADD_QC);
    const isEditQCDisabled =
        isAppLocked ||
        (!programRights.includes(systemUserRights.VISIONDSM_MANAGE_QC) && !programRights.includes(systemUserRights.VISIONDSM_ADD_QC));
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.staticLarge,
    });
    const onRowSelect = (selectedRows) => setSelectedRows(selectedRows || []);
    const [selectedRows, setSelectedRows] = useState([]);
    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId: gridId,
        onRowSelect,
    });

    const grid = useSelector((state) => state.dataGrid[gridId]);
    const dispatch = useDispatch();

    const handleAddQualityControlRequest = useCallback(
        (qctype, description) => () => {
            dispatch(
                createResource({
                    resourceName: "qc",
                    path: {
                        appId: applicationNumber,
                    },
                    body: {
                        qctype,
                        description,
                    },
                    onSuccess: (data) => {
                        handleCloseSidePanel();
                        dispatch(
                            getData({
                                dataGridId: `${applicationNumber}-quality-control-grid`,
                            })
                        );
                        dispatch(
                            getResource({
                                resourceName: "qcWidget",
                                key: applicationNumber,
                                path: {
                                    appId: applicationNumber,
                                },
                            })
                        );
                    },
                })
            );
        },
        [applicationNumber, dispatch, handleCloseSidePanel]
    );

    const toggleAddQCRequest = useCallback(() => {
        handleOpenSidePanel(
            <QualityControlAddRequest entity="types" onClose={handleCloseSidePanel} onAdd={handleAddQualityControlRequest} />
        );
    }, [handleAddQualityControlRequest, handleOpenSidePanel, handleCloseSidePanel]);

    const onUpdateQCRequest = useCallback(
        (qc, description, onComplete) => {
            dispatch(
                updateResource({
                    resourceName: "qcDescription",
                    path: {
                        appId: applicationNumber,
                        qcNumber: qc.qcnumber,
                    },
                    body: description,
                    onSuccess: () => {
                        refreshQcData(dispatch, applicationNumber, qc.qcnumber, gridId);
                    },
                    onComplete: () => {
                        if (onComplete) {
                            onComplete();
                        }
                    },
                })
            );
        },
        [applicationNumber, gridId, dispatch]
    );

    const onDeleteQCRequest = useCallback(
        ({ qcNumber, onSuccess, onConfirm }) => {
            const message = <p>Are you sure you would like to delete this QC request?</p>;

            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Delete QC Request",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        modalIcon: "delete-trash-empty",
                        content: message,
                        footerContentCenter: true,
                        onConfirm: () => {
                            onConfirm && onConfirm();
                            dispatch(
                                deleteResource({
                                    resourceName: "qcDelete",
                                    path: {
                                        appId: applicationNumber,
                                        qcNumber: qcNumber,
                                    },
                                    onSuccess: () => {
                                        onSuccess && onSuccess();
                                        onRowSelectClear();
                                        if (grid.paging.skip > 0) {
                                            dispatch(
                                                setPage({
                                                    dataGridId: gridId,
                                                    page: {
                                                        ...grid.paging,
                                                        skip: 0,
                                                    },
                                                })
                                            );
                                        }
                                        dispatch(
                                            getData({
                                                dataGridId: gridId,
                                            })
                                        );
                                    },
                                })
                            );
                        },
                    },
                })
            );
        },
        [applicationNumber, dispatch, gridId, onRowSelectClear, grid]
    );

    const onDeleteMultipleQCRequests = useCallback(() => {
        const selectedQCRequests = selectedRows.map((item) => item[qualityControlGridColumnKeys.qcNumber]);
        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Delete QC Request",
                    overlayClassName: "modal-styled",
                    className: "modal-sm",
                    modalIcon: "delete-trash-empty",
                    content: (
                        <p>
                            Are you sure you want to permanently delete <b>{selectedQCRequests.length}</b> selected QC Request? This cannot
                            be undone.
                        </p>
                    ),
                    footerContentCenter: true,
                    onConfirm: async () => {
                        const selectedQCRequestsArr = selectedQCRequests.map((qcNumber) => {
                            return new Promise((resolve, reject) => {
                                dispatch(
                                    deleteResource({
                                        resourceName: "qcDelete",
                                        path: {
                                            appId: applicationNumber,
                                            qcNumber: qcNumber,
                                        },
                                        onSuccess: resolve,
                                        onError: reject,
                                    })
                                );
                            });
                        });
                        await Promise.all(selectedQCRequestsArr);
                        setSelectedRows([]);
                        onRowSelectClear();
                        if (grid.paging.skip > 0) {
                            dispatch(
                                setPage({
                                    dataGridId: gridId,
                                    page: {
                                        ...grid.paging,
                                        skip: 0,
                                    },
                                })
                            );
                        }

                        dispatch(
                            getData({
                                dataGridId: gridId,
                            })
                        );
                    },
                },
            })
        );
    }, [applicationNumber, dispatch, gridId, selectedRows, onRowSelectClear, grid]);

    const onAcceptQualityControlRequest = useCallback(
        (qc) => () => {
            openConfirmModal({
                title: "Confirm Acceptance",
                message: "Are you sure you want to confirm this request?",
                onClose: undefined,
                modalIcon: undefined,
                onAfterOpen: undefined,
                onConfirm: () => {
                    dispatch(
                        updateResource({
                            resourceName: "qcStatus",
                            path: {
                                applicationNumber,
                                qcNumber: qc.qcnumber,
                                status: 83,
                            },
                            onSuccess: () => {
                                refreshQcData(dispatch, applicationNumber, qc.qcnumber, gridId);
                            },
                        })
                    );
                },
            });
        },
        [applicationNumber, dispatch, gridId]
    );

    return (
        <div className="panel qc-panel">
            <PanelHeaderLarge title="Quality Control" />
            <div className="mt-14 data-grid-controls flex-row justify-space-between">
                <div className="data-grid-controls__left-side-actions flex-row">
                    {selectedRows.length ? (
                        <GridSelectedItemsLabel
                            text={
                                selectedRows.length ? (
                                    <>
                                        QC
                                        {selectedRows.length > 1 ? "s" : ""} <br /> selected
                                    </>
                                ) : (
                                    ""
                                )
                            }
                            count={selectedRows.length}
                            onClear={onRowSelectClear}
                        />
                    ) : (
                        <div className="select-label">
                            Select <br /> QC
                        </div>
                    )}
                    <Button
                        primary
                        icon="delete-trash-empty"
                        className="ml-20"
                        disabled={isEditQCDisabled || selectedRows.length === 0}
                        onClick={onDeleteMultipleQCRequests}
                    >
                        Delete Requests
                    </Button>
                </div>
                <div className="data-grid-controls__right-side-actions flex-row  align-end">
                    <IconWithLabel
                        icon="plus"
                        disabled={isAddRequestDisabled}
                        title={isAppLocked ? appIsLockedText : undefined}
                        onClick={toggleAddQCRequest}
                    >
                        Add Request
                    </IconWithLabel>
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "application_qc",
                                fileNamePostfix: applicationNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
            </div>
            <div className="panel qc flex-column">
                <QualityControlGrid
                    gridId={gridId}
                    filter={`${qualityControlGridColumnKeys.entityNumber}=${applicationNumber}`}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    onUpdateQCRequest={onUpdateQCRequest}
                    onAcceptQCRequest={onAcceptQualityControlRequest}
                    onDeleteQCRequest={onDeleteQCRequest}
                    onRowSelect={onRowSelect}
                    onRowSelectChange={onRowSelectChange}
                    onPageChanged={onPageChanged}
                />
            </div>
        </div>
    );
});

export const confirmQCStatusChange = ({ applicationNumber, qcData, qcStatus, dispatch }) => {
    const gridId = `${applicationNumber}-quality-control-grid`;
    const text = (
        <p>
            This action will change Quality Control status to <strong>{qcStatus.status}</strong>. Are you sure you want to do this?
        </p>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Change Quality Control Status",
                overlayClassName: "modal-styled",
                className: "change-status-confirmation-modal modal-sm",
                footerContentCenter: true,
                content: text,
                onConfirm: () => {
                    dispatch(
                        updateResource({
                            resourceName: "qcStatus",
                            path: {
                                appId: applicationNumber,
                                qcNumber: qcData.qcnumber,
                                status: +qcStatus.val,
                            },
                            onSuccess: () => {
                                refreshQcData(dispatch, applicationNumber, qcData.qcnumber, gridId);
                            },
                        })
                    );
                },
            },
        })
    );
};

export const refreshQcData = (dispatch, applicationNumber, qcNumber, dataGridId) => {
    dispatch(
        getResource({
            resourceName: "qc",
            resourceId: qcNumber,
            path: {
                appId: applicationNumber,
            },
        })
    );

    dispatch(getData({ dataGridId }));

    dispatch(
        getResource({
            resourceName: "qcWidget",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
        })
    );
};

export default QualityControlPanel;
