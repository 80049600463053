import React, { useContext, useMemo } from "react";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import "./ColorSelectIcon.scss";
import IconWrap from "components/ui/Icons";
import cn from "classnames";
import { isNil } from "lodash";
import ActionLabel from "components/ui/Label/ActionLabel";

export const ColorSelectIcon = (props) => {
    const { value, onClick, disabled = false, title } = props;

    const context = useContext(PortalBuilderContext);
    const colors = useMemo(() => context?.colors ?? {}, [context?.colors]);
    const colorCode = colors[value] ?? value;

    const colorStyle = useMemo(() => (colorCode ? { color: colorCode } : undefined), [colorCode]);
    const toolTip = disabled ? `${colorCode?.slice(0, -2)}, opacity: 50%` : colorCode;

    if (isNil(value)) {
        return (
            <ActionLabel className="color-select-label" onClick={onClick}>
                Select
            </ActionLabel>
        );
    }

    return (
        <IconWrap
            iconWrapBig
            iconWrapNoMouseOver
            className={cn("color-select-icon align-center justify-center", { disabled: disabled && colorCode === "transparent" })}
            title={title ?? toolTip}
            icon="checkbox-unchecked-filled"
            iconWrapStyle={colorStyle}
            onClick={onClick}
        />
    );
};
