import React, { useState, useEffect, memo, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { useResource } from "../../../store/resources/useResource";
import { getResource, createResource } from "../../../store/resources/actions";
import { store } from "../../../store/configureStore";

import WaitIcon from "../../ui/WaitIcon";
import Button from "../../ui/Button";
import DropDownInput from "../../ui/Input/DropDownInput";
import Separator from "../../ui/Separator";
import { openNewApplicationFromScanTab, openScanQueueManagementTab } from "../../utils/window";
import ButtonGroup from "../../ui/Button/ButtonGroup";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import useUnmounted from "../../utils/useUnmounted";
import ModuleLoadError from "components/ui/ModuleLoadError";

import "./style.scss";

// Lazy load the Report Component
const Report = lazy(() =>
    import("./Report").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const types = [
    {
        value: 0,
        label: "Existing Applications",
    },
    {
        value: 1,
        label: "New Applications",
    },
    {
        value: 2,
        label: "New Scanned Applications",
    },
];

const periods = [
    {
        value: "today",
        label: "Today",
    },
    {
        value: "yesterday",
        label: "Yesterday",
    },
    {
        value: "thisweek",
        label: "This Week",
    },
    {
        value: "lastweek",
        label: "Last Week",
    },
    {
        value: "thismonth",
        label: "This Month",
    },
    {
        value: "lastmonth",
        label: "Last Month",
    },
];

const ScanQueue = memo(() => {
    return (
        <div className="scan-queue main-grid-wrap responsive">
            <Separator />
            <div className="flex-row fill-width">
                <div className="scan-queue-actions">
                    <ScanQueueSidePanel />
                    <IconWithLabel iconWithLabelRight icon="open-new-window" onClick={() => openScanQueueManagementTab()}>
                        Manage Scan Queue
                    </IconWithLabel>
                </div>
                <ScanQueueMainPanel />
            </div>
            <Separator />
        </div>
    );
});

export const ScanQueueMainPanel = memo(({ forDashboard, scanControls }) => {
    const reportTitle = "My Applications - Total Applications & Avg Time";

    const [type, setType] = useState(0);
    const [period, setPeriod] = useState("thismonth");
    const [report, setReport] = useState();
    const [reportIsLoading, setReportIsLoading] = useState(false);
    const unmounted = useUnmounted();

    useEffect(() => {
        setReportIsLoading(true);

        store.dispatch(
            getResource({
                resourceName: "applicationScanQueueReport",
                key: "scanQueueReport",
                query: {
                    repType: period,
                    newApplicationFlag: type,
                },
                onSuccess: (action) => !unmounted.current && setReport(action.data),
                onComplete: () => !unmounted.current && setReportIsLoading(false),
            })
        );
    }, [type, period, unmounted]);

    const reportControls = (
        <div className="report-controls flex-row">
            <DropDownInput
                label="Select application type"
                mobileHeader="Select application type"
                data={types}
                value={type}
                onChange={(event) => setType(event.target.value)}
                withPopper={forDashboard}
            />
            <Separator vertical />
            <DropDownInput
                label="Select period"
                mobileHeader="Select period"
                data={periods}
                value={period}
                onChange={(event) => setPeriod(event.target.value)}
                withPopper={forDashboard}
            />
        </div>
    );

    return (
        <div
            className={cn("scan-queue__main-panel flex-one", {
                "scan-queue__widget": forDashboard,
            })}
        >
            {forDashboard ? (
                <div className="report-for-dashboard flex-column fill-height">
                    {scanControls && <ScanQueueSidePanel />}
                    <div className="report-title">{reportTitle}</div>
                    {reportControls}
                    {reportIsLoading ? (
                        <WaitIcon />
                    ) : (
                        <Suspense fallback={<WaitIcon />}>
                            <Report data={report} />
                        </Suspense>
                    )}
                </div>
            ) : (
                <div className="report-wrap">
                    <div className="report-lead-block">
                        <div className="report-title">{reportTitle}</div>
                        {reportControls}
                    </div>
                    {reportIsLoading ? (
                        <WaitIcon />
                    ) : (
                        <Suspense fallback={<WaitIcon />}>
                            <Report data={report} />
                        </Suspense>
                    )}
                </div>
            )}
        </div>
    );
});

export const ScanQueueSidePanel = memo(() => {
    const dispatch = useDispatch();
    const [applicationIsLoading, setApplicationIsLoading] = useState(false);

    const [totals = {}, isLoadingTotals] = useResource({
        resourceName: "applicationScanQueueAvailable",
        key: "scanQueueTotals",
    });

    const handleCreate = () => {
        setApplicationIsLoading(true);

        dispatch(
            createResource({
                resourceName: "applicationScanQueue",
                showSuccessNotification: false,
                onSuccess: (action) => {
                    dispatch(
                        getResource({
                            resourceName: "applicationScanQueueAvailable",
                            key: "scanQueueTotals",
                        })
                    );

                    openNewApplicationFromScanTab({
                        applicationNumber: action.data.applicationNumber,
                        fileNumber: action.data.fileNumber,
                        utilityNumber: action.data.utilityNumber,
                        programNumber: action.data.programNumber,
                        formPageNumber: action.data.formPageNumber,
                    });
                },
                onComplete: () => setApplicationIsLoading(false),
            })
        );
    };

    return (
        <div className="scan-queue__side-panel">
            {isLoadingTotals ? (
                <WaitIcon />
            ) : (
                <div>
                    <div className="scan-totals-item">
                        <span>Scan Applications</span>
                        <span className="value">{totals.totalAvailableScans}</span>
                        {!applicationIsLoading && !isLoadingTotals && totals.totalAvailableScans !== 0 && (
                            <ButtonGroup transparent>
                                <Button icon="new-empty" onClick={handleCreate}>
                                    Create New from Scan
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                    {applicationIsLoading && <WaitIcon />}
                    <div className="scan-totals-item flaw">
                        <span>Flaw Applications</span>
                        <span className="value">{totals.totalAvailableFlaws}</span>
                    </div>
                </div>
            )}
        </div>
    );
});

export default ScanQueue;
