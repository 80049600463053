import React, { useCallback, useMemo, memo } from "react";
import { useResource } from "../../../../../../../../store/resources/useResource";
import WaitIcon from "../../../../../../WaitIcon";
import JsonSchemaForm from "../../../../../../Form/JsonSchema/JsonSchemaForm";
import { listToAnyOf } from "components/utils/form";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";

export const FormEntryForm = memo(
    ({ entityId, attributes, content, fields, stepAttributeTypesGetResourceOptions, onChange, onCloseModal }) => {
        const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

        const [pages, isLoadingFormPages] = useFormPages({ entityId });

        const getAttribute = useCallback(
            ({ number = null, code = null }) => {
                if (number) {
                    return workflowStepAttributeTypes.find((i) => i.number === number);
                } else if (code) {
                    return workflowStepAttributeTypes.find((i) => i.code === code);
                }
            },
            [workflowStepAttributeTypes]
        );

        const getProperties = useCallback(
            (fields) => {
                return fields.reduce((properties, attrCode) => {
                    const title = getAttribute({ code: attrCode })?.name;
                    const type = "string";
                    let anyOf = [];

                    if (attrCode === "formpgnum") {
                        anyOf = listToAnyOf({
                            list: pages,
                            map: (item) => ({
                                title: item.name,
                                enum: [item.number],
                            }),
                        });
                    }

                    return {
                        ...properties,
                        [attrCode]: {
                            title,
                            type,
                            anyOf,
                        },
                    };
                }, {});
            },
            [pages, getAttribute]
        );

        const getInitialValues = useCallback(() => {
            const initialFieldsValues = fields.reduce(
                (result, field) => {
                    return {
                        ...result,
                        [field]: undefined,
                    };
                },
                { content }
            );

            if (attributes) {
                return attributes.reduce((acc, { typeNumber, value }) => {
                    const attribute = getAttribute({ number: typeNumber });

                    if (attribute.code in initialFieldsValues && value) {
                        acc[attribute.code] = String(value);
                    }

                    return acc;
                }, initialFieldsValues);
            }

            return initialFieldsValues;
        }, [attributes, content, fields, getAttribute]);

        const schema = useMemo(
            () => ({
                type: "object",
                required: ["formpgnum"],
                properties: {
                    ...getProperties(fields),
                    content: {
                        title: "Content",
                        type: "string",
                    },
                },
            }),
            [fields, getProperties]
        );

        const uiSchema = useMemo(
            () => ({
                classNames: "inline-form columns-2",
                content: {
                    classNames: "workflow-step-types-widget__form-field workflow-step-types-widget__form-field-textarea fill-width",
                    "ui:widget": "HtmlEditorWidget",
                },
            }),
            []
        );

        const initialValues = useMemo(() => {
            return getInitialValues();
        }, [getInitialValues]);

        const handleClickSave = useCallback(
            (formData) => {
                const { content } = formData;

                const data = {
                    content,
                    attributes: [],
                };

                for (const key in formData) {
                    if (formData.hasOwnProperty(key) && key !== "content") {
                        const attribute = getAttribute({ code: key });

                        data.attributes.push({
                            typeNumber: attribute.number,
                            name: attribute.name,
                            value: formData[key],
                        });
                    }
                }

                onChange(data);
                onCloseModal();
            },
            [getAttribute, onChange, onCloseModal]
        );

        if (isLoadingWorkflowStepAttributeTypes || isLoadingFormPages) {
            return <WaitIcon />;
        }

        return (
            <JsonSchemaForm
                className="workflow-step-types-widget__form"
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                submitText="Save"
                onCancel={onCloseModal}
                onSubmit={handleClickSave}
                noReset
                centeredFooter
            />
        );
    }
);
