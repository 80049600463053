import { cloneDeep } from "lodash";
import * as actionType from "../actionTypes";

export const initialState = {
    schema: {},
    uiSchema: {},
    rules: [],
    initialValues: {},
    selectedElementId: "root",
    formData: {},
    existingElementNumbers: [],
    validationErrors: {},
};

export const reducer = (state = {}, action) => {
    const { instanceId } = action;
    let currentInstance = state[instanceId] || initialState;

    switch (action.type) {
        case actionType.APP_FORM_INIT:
            if (!(action.keepInitializedPage && state[instanceId])) {
                currentInstance = {
                    ...currentInstance,
                    schema: action.schema || initialState.schema,
                    uiSchema: action.uiSchema || initialState.uiSchema,
                    rules: action.rules || initialState.rules,
                    initialValues: action.initialValues || initialState.initialValues,
                    selectedElementId: action.selectedElementId || initialState.selectedElementId,
                    existingElementNumbers: action.existingElementNumbers || initialState.existingElementNumbers,

                    initialSchema: cloneDeep(action.schema || initialState.schema),
                    initialUiSchema: cloneDeep(action.uiSchema || initialState.uiSchema),
                    initialRules: cloneDeep(action.rules || initialState.rules),
                };
            }

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_DESTROY:
            const { [instanceId]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };
            break;
        case actionType.APP_FORM_UPDATE_SCHEMA:
            currentInstance = {
                ...currentInstance,
                schema: action.schema,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_UPDATE_UI_SCHEMA:
            currentInstance = {
                ...currentInstance,
                uiSchema: action.uiSchema,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_UPDATE_RULES:
            currentInstance = {
                ...currentInstance,
                rules: action.rules,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_UPDATE_INITIAL_VALUES:
            currentInstance = {
                ...currentInstance,
                initialValues: action.initialValues,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_UPDATE_INITIAL_SCHEMA:
            currentInstance = {
                ...currentInstance,
                initialSchema: cloneDeep(action.schema),
                initialUiSchema: cloneDeep(action.uiSchema),
                initialRules: cloneDeep(action.rules),
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_ADD_FIELD:
        case actionType.APP_FORM_ADD_COLUMN:
        case actionType.APP_FORM_ADD_SECTION:
        case actionType.APP_FORM_ADD_PAGE:
        case actionType.APP_FORM_UPDATE_FIELD:
        case actionType.APP_FORM_UPDATE_COLUMN:
        case actionType.APP_FORM_UPDATE_SECTION:
        case actionType.APP_FORM_UPDATE_PAGE:
        case actionType.APP_FORM_UPDATE:
            currentInstance = {
                ...currentInstance,
                schema: action.schema,
                uiSchema: action.uiSchema,
                rules: action.rules,
                initialValues: action.initialValues,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_SELECT_ELEMENT:
            currentInstance = {
                ...currentInstance,
                selectedElementId: action.elementId,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_SET_FORM_DATA:
            currentInstance = {
                ...currentInstance,
                formData: action.formData,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        case actionType.APP_FORM_SET_VALIDATION_ERRORS:
            currentInstance = {
                ...currentInstance,
                validationErrors: action.validationErrors,
            };

            state = {
                ...state,
                [instanceId]: currentInstance,
            };
            break;
        default:
            break;
    }

    return state;
};
