/** Manages deferred mount queue */
class DelayedMountManager {
    constructor() {
        // List of mount callbacks
        this.mountCallbacks = [];

        // Waiting right now for the next frame
        this.waiting = false;

        // Handles of timers
        this.requestFrameHandle = null;
        this.startTimerHandle = null;
        this.waitTimerHandle = null;
    }

    /**
     * Request frame callback
     * Called on mount of DelayedMountItem
     * @param callback - function to call when we're ready to mount this component
     * @param mountDelay - priority of mounting, bigger number will be mounted last
     */
    requestCallback(callback, mountDelay) {
        this.mountCallbacks.push({
            delay: mountDelay,
            cb: callback,
        });

        // Sort callbacks by delay
        this.mountCallbacks.sort((a, b) => a.delay - b.delay);

        // Launch mount sequence after timeout
        if (this.startTimerHandle) clearTimeout(this.startTimerHandle);
        this.startTimerHandle = setTimeout(() => {
            this.takeNext();
        }, 1);
    }

    /**
     * Remove frame callback by id
     * @param callback - callback to remove
     */
    removeCallback(callback) {
        this.mountCallbacks = this.mountCallbacks.filter((obj) => obj.cb !== callback);
    }

    /** Reset waiting queue */
    resetQueue() {
        clearTimeout(this.startTimerHandle);
        clearTimeout(this.waitTimerHandle);
        window.cancelAnimationFrame(this.requestFrameHandle);

        // Flags and queue
        this.waiting = false;
        this.mountCallbacks = [];
    }

    /** Take next callback */
    takeNext() {
        if (this.mountCallbacks.length <= 0) return;
        if (this.waiting) return;
        this.waiting = true;

        // Take first item of array
        const obj = this.mountCallbacks.shift();
        if (obj && obj.cb) {
            // Call mount callback
            obj.cb();

            // Take next after delay
            const fn = () => {
                this.waitTimerHandle = setTimeout(() => {
                    this.waiting = false;
                    this.takeNext();
                }, 1);
            };

            // Request animation frame
            this.requestFrameHandle = window.requestAnimationFrame(() => {
                this.requestFrameHandle = window.requestAnimationFrame(fn);
            });
        } else {
            this.waiting = false;
            this.takeNext();
        }
    }
}

export default DelayedMountManager;
