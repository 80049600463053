import React, { memo } from "react";
import { useResource } from "../../../../../../store/resources/useResource";
import { toArray } from "lodash";

import WaitIcon from "../../../../WaitIcon";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

import LocationView from "./LocationView";
import Applications from "./Applications";
import Equipment from "./Equipment";
import Participation from "./Participation";

import "./ActivityPanel.scss";

const ActivityPanel = memo(({ panel }) => {
    const applicationNumber = panel && panel.data && panel.data.applicationNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;
    const contactNumber = panel && panel.data && panel.data.contractorContactNumber;

    const [appZipCodes, isLoadingZipCodes] = useResource({
        resourceName: "contractorAppZipCodeCount",
        key: contactNumber,
        path: {
            contactNumber,
            applicationNumber,
        },
        forced: true,
        transform: (data) => {
            return toArray(data).map((z) => {
                const latlng = z.geoCode?.split(",");
                if (latlng) {
                    const lat = latlng[0];
                    const lng = latlng[1].trim();
                    return { ...z, lat: Number(lat), lng: Number(lng) };
                } else {
                    return z;
                }
            });
        },
    });

    return (
        <div className="panel activity">
            <PanelHeaderLarge title="Activity" />
            <div className="content">
                <div className="content-item">
                    {isLoadingZipCodes && <WaitIcon />}
                    {!isLoadingZipCodes && <LocationView appZipCodes={appZipCodes} />}
                </div>
                <div className="content-item applications">
                    <Applications applicationNumber={applicationNumber} contactNumber={contactNumber} />
                </div>
                <div className="content-item">
                    <div className="flex-column fill-width participation">
                        <Participation contactNumber={contactNumber} programNumber={programNumber} />
                    </div>

                    <div className="flex-column fill-width equipment">
                        <div className="content-item">
                            <Equipment contactNumber={contactNumber} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ActivityPanel;
