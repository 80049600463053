import React, { useCallback } from "react";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Grid from "./Grid";
import { onRefreshBudgetLinesGrid, getBudgetLinesGridId } from "./utils";
import Controls from "./Controls";
import Form from "./Form";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";

import "./style.scss";

const BudgetLinesPanel = ({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");

    const dataGridId = getBudgetLinesGridId({ contractNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-lines-sidenav-panel",
    });

    const onRefresh = useCallback(() => {
        onRefreshBudgetLinesGrid({ contractNumber });
    }, [contractNumber]);

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(
            <Form utilityNumber={utilityNumber} contractNumber={contractNumber} gridRefresh={onRefresh} onClose={handleCloseSidePanel} />
        );
    }, [utilityNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel contract-budget-lines flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls contractNumber={contractNumber} dataGridId={dataGridId} onAdd={handleCreate} />
            <Grid
                className="flex-one"
                dataGridId={dataGridId}
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                onRefresh={onRefresh}
            />
        </div>
    );
};

export default BudgetLinesPanel;
