export const entityType = {
    program: 70,
    projectApplication: 71,
    ticket: 262,
    utility: 361,
    invoice: 531,
    scannedApplication: 758,
    template: 904,
    fastTagImage: 926,
    calculator: 943,
    portalTemplate: 1184,
};
