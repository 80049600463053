export const auditType = {
    all: "all",
    application: "application",
    budget: "budget",
    catalog: "catalog",
    contact: "Contact",
    contract: "contract",
    customer: "Customer",
    equipment: "equipment",
    form: "form",
    formItem: "formitem",
    formSection: "formsection",
    goal: "goal",
    invoice: "invoice",
    invoiceDocument: "invoiceDocument",
    invoiceItem: "invoiceItem",
    masterCatalog: "mastercatalog",
    note: "Note",
    program: "program",
    project: "project",
    rebate: "Rebate",
    report: "report",
    status: "status",
    task: "task",
    ticket: "ticket",
    user: "user",
    utility: "utility",
    workflow: "Workflow",
    workflowItem: "workflowitem",
};
