import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import ReactModal from "react-modal";

import Button from "../Button";
import { modalClose } from "../../../store/modal/actions";
import IconWrap from "../Icons";

import "./style.scss";

export const Modal = ({
    title,
    modalIcon,
    children,
    bodyOpenClassName,
    overlayClassName,
    className,
    dispatch,
    actions,
    onClose = () => {},
    noFooter = false,
    footerContentCenter = false,
    noCrossButton = false,
    withScroll = false,
}) => (
    <ReactModal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => dispatch(modalClose())}
        bodyOpenClassName={cn("", bodyOpenClassName)}
        overlayClassName={cn("", overlayClassName)}
        className={cn("modal simple-modal", className, {
            "modal-no-footer": noFooter,
            "with-scroll": withScroll,
        })}
    >
        <div className="modal-content">
            {title && (
                <div className={cn("modal-header no-shrink")}>
                    {modalIcon && <IconWrap icon={modalIcon} />}
                    {title}
                    {!noCrossButton && (
                        <IconWrap
                            icon="clear-close"
                            title="Close"
                            onClick={() => {
                                dispatch(modalClose());
                                onClose();
                            }}
                        />
                    )}
                </div>
            )}
            <div className="modal-body fill-height">
                <div className="flex-column">{children}</div>
            </div>
            {!noFooter && (
                <div className={"modal-footer no-shrink" + (footerContentCenter ? " center" : "")}>
                    {actions && actions}
                    <Button
                        className="modal-close"
                        onClick={() => {
                            dispatch(modalClose());
                            onClose();
                        }}
                    >
                        Close
                    </Button>
                </div>
            )}
        </div>
    </ReactModal>
);

export default connect()(Modal);
