import React from "react";
import TagButton from "../../Button/TagButton";
import IconWrap from "../../Icons";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { editSearchInput, removeSearchInput } from "../../../../store/globalSearch/actions";

export const SearchCriteriaItem = ({ instanceId, item }) => {
    const { searchInputId, mainCriteria, subCriteriaList } = item;

    const dispatch = useDispatch();

    const onRemove = useCallback(() => {
        dispatch(removeSearchInput({ instanceId, searchInputId }));
    }, [instanceId, searchInputId, dispatch]);

    const onEdit = useCallback(() => {
        dispatch(editSearchInput({ instanceId, searchInputId }));
    }, [instanceId, searchInputId, dispatch]);

    return (
        <div className="global-search__criteria-item flex-row align-center">
            <div className="global-search__criteria-category">{mainCriteria}:</div>
            <SubCriteriaList items={subCriteriaList} />
            <CriteriaActions onEdit={onEdit} onRemove={onRemove} />
        </div>
    );
};

const SubCriteriaList = ({ items }) => {
    return (
        <div className="global-search__criteria-sub-categories flex-row flex-wrap">
            {items.map((criteria, key) => (
                <TagButton key={key} tagWithoutIcon readonly>
                    {criteria.title && criteria.title + ": "}
                    <strong>{criteria.value}</strong>
                </TagButton>
            ))}
        </div>
    );
};

const CriteriaActions = ({ onEdit, onRemove }) => {
    return (
        <div className="global-search__criteria-actions flex-row align-center">
            <IconWrap icon="edit-empty" title="Edit" onClick={onEdit} />
            <IconWrap icon="clear-close" title="Remove" onClick={onRemove} />
        </div>
    );
};
