import React from "react";

import Button from "../../../../Button";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import ButtonGroup from "../../../../Button/ButtonGroup";
import GridSelectedItemsLabel from "../../../../DataGrid/GridSelectedItemsLabel";

const Controls = ({ programNumber, assignmentDisabled, selectedRowCount, onRowSelectClear, onAdd, onRemove, onExport, disabled }) => (
    <div className="grid-controls associations">
        {selectedRowCount ? (
            <GridSelectedItemsLabel
                text={
                    selectedRowCount > 0 ? (
                        <>
                            {" "}
                            Association{selectedRowCount > 1 ? "s" : ""} <br /> selected{" "}
                        </>
                    ) : (
                        ""
                    )
                }
                count={selectedRowCount}
                onClear={onRowSelectClear}
            />
        ) : (
            <div className="bulk-assignment__label">
                Select <br /> Associations
            </div>
        )}
        <ButtonGroup transparent>
            <Button primary icon="delete-trash-empty" onClick={onRemove} disabled={assignmentDisabled || disabled}>
                Remove Associations
            </Button>
        </ButtonGroup>
        <span className="flex-one" />
        <div className="flex-row align-center">
            <IconWithLabel icon="plus" disabled={disabled} onClick={onAdd}>
                Add Associations
            </IconWithLabel>
            <IconWithLabel withHandMadeIcon onClick={onExport}>
                Export CSV
            </IconWithLabel>
        </div>
    </div>
);

export default Controls;
