import React from "react";
import { WidgetProps } from "./types";
import { WidgetHeader } from "./WidgetHeader";

export const Widget = (props: WidgetProps) => {
    const {
        widget: { Component },
    } = props;

    return (
        <div className="widget-panel flex-one-in-column flex-column fill-height no-scroll">
            <WidgetHeader {...props} />
            {Component && <Component {...props} />}
        </div>
    );
};
