import React, { memo } from "react";

import { programsEventsGridColumnKeys } from "../../../../../views/configureGrids";
import { openCalculationAssignmentModal } from "./utils";
import { isYes } from "../../../../../utils/string";

import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";
import { systemUserRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

const Grid = memo(({ gridId, dataGridConfig, utilityNumber, programNumber, onShowEditForm }) => {
    const [programRights = []] = useProgramRights({ programNumber });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        switch (name) {
            case "edit":
                onShowEditForm({ dataItem });
                break;

            case "calc-assignment":
                openCalculationAssignmentModal({
                    utilityNumber,
                    programNumber,
                    eventNumber: dataItem[programsEventsGridColumnKeys.eventNumber],
                });
                break;

            default:
                break;
        }
    };

    const rowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit",
        },
        {
            name: "calc-assignment",
            icon: (row) => (isYes(row[programsEventsGridColumnKeys.calculations]) ? "hub-filled" : "hub-empty"),
            title: "Edit Event Calculation Assignment",
            hide: () => !programRights.includes(systemUserRights.VISIONDSM_ADD_CALCULATION),
        },
    ];

    if (isChildProgram({ programNumber })) {
        rowActions[0].icon = "eye-visibility-empty";
        rowActions[0].title = "View";
        rowActions[1].disabled = true;
        rowActions[1].title = undefined;
    }

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={rowActions} onRowAction={onRowAction} />;
});

export default withDataGrid("programsEvents", Grid);
