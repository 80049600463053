import ReactDOM from "react-dom";
import cn from "classnames";
import { toNumber } from "lodash";
import React, { useLayoutEffect, useRef } from "react";
import { PropertyListItem } from "./PropertyListItem";
import noUiSlider from "nouislider";
import { ColorSelectIcon } from "./ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "./ColorSelect/ColorSelectPanel";
import { SelectPanelProperty } from "./SelectPanelProperty";
import TextLabel from "components/ui/Label/TextLabel";
import { SelectBoxProperty } from "./SelectBoxProperty";

import "nouislider/dist/nouislider.css";
import "./InputRangeProperty.scss";

export const ColorGradientProperty = (props) => {
    const { className, onChange, value = {}, borderTop, borderBottom } = props;
    const sliderRef = useRef();

    const valueRef = useRef(value);

    const startFirstValue = valueRef?.current?.firstGradientValue ? valueRef?.current?.firstGradientValue : 0;
    const startSecondValue = valueRef?.current?.secondGradientValue ? 100 - valueRef?.current?.secondGradientValue : 100;

    useLayoutEffect(() => {
        var slider = ReactDOM.findDOMNode(sliderRef.current);

        function updateGradientValues(values) {
            if (slider?.noUiSlider) {
                var newFirstGradientValue = toNumber(values[0]);
                var newSecondGradientValue = 100 - toNumber(values[1]);

                //To compensate for slider margin
                if (newFirstGradientValue + newSecondGradientValue === 95) {
                    if (newFirstGradientValue > newSecondGradientValue) {
                        newFirstGradientValue += 5;
                    } else {
                        newSecondGradientValue += 5;
                    }
                }
                const extraProperties = [
                    {
                        id: "secondGradientValue",
                        value: newSecondGradientValue,
                    },
                ];

                onChange("firstGradientValue", newFirstGradientValue, extraProperties);
            }
        }

        if (slider && !slider.noUiSlider) {
            noUiSlider.create(slider, {
                start: [startFirstValue, startSecondValue],
                step: 1,
                margin: 5,
                range: {
                    min: 0,
                    max: 100,
                },
            });
            slider.noUiSlider.on("set", updateGradientValues);
        }
    }, [startFirstValue, startSecondValue, onChange]);

    const gradientDirectionItems = [
        {
            text: "Vertical",
            value: "to bottom",
        },
        {
            text: "Horizontal",
            value: "to right",
        },
    ];

    return (
        <PropertyListItem
            className={cn("property-list-item--gradient-slider", className)}
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            <div className="property-list-item-inner flex-column">
                <SelectBoxProperty
                    id="gradientDirection"
                    items={gradientDirectionItems}
                    onChange={onChange}
                    fullWidth={true}
                    value={value?.gradientDirection}
                ></SelectBoxProperty>
                <div className="flex-row">
                    <div className="flex-column align-start">
                        <TextLabel>{value?.gradientDirection === "to bottom" ? "TOP" : "LEFT"}</TextLabel>
                        <SelectPanelProperty
                            id="firstGradientColor"
                            value={value?.firstGradientColor}
                            selectComponent={ColorSelectIcon}
                            panelComponent={ColorSelectPanel}
                            borderBottom={false}
                            onChange={onChange}
                        />
                    </div>
                    <div ref={sliderRef} className="gradient-slider" />
                    <div className="flex-column align-end">
                        <TextLabel>{value?.gradientDirection === "to bottom" ? "BOTTOM" : "RIGHT"}</TextLabel>
                        <SelectPanelProperty
                            id="secondGradientColor"
                            value={value?.secondGradientColor}
                            selectComponent={ColorSelectIcon}
                            panelComponent={ColorSelectPanel}
                            borderBottom={false}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </PropertyListItem>
    );
};
