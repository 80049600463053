import React, { useCallback, useContext, useState, useMemo, memo } from "react";
import { isEmpty } from "lodash";

import { DocumentQueueGridContext } from "../../context/DocumentQueueGridContext";
import { useItems } from "../../../../utils/useItems";
import { getResourceByPath } from "../../../../../store/resources/selectors";
import { Controls } from "./Controls";
import { ProgramList } from "./ProgramList";

const getCommonBatchProgramsCount = (programList, batchSize) => {
    let programsCount = 0;
    let lastProgramDocumentsCount = 0;

    if (!isEmpty(programList) && batchSize) {
        let sum = 0;

        for (const program of programList) {
            const { total } = program;

            programsCount++;
            sum += total;

            if (sum >= batchSize) {
                lastProgramDocumentsCount = total + batchSize - sum;
                break;
            }
        }
    }

    return { programsCount, lastProgramDocumentsCount };
};

const getLastProgramDocumentsCount = (programNumber, lastProgramNumber, lastProgramDocumentsCount) => {
    const isLastProgramNumber = programNumber === lastProgramNumber;

    return isLastProgramNumber ? lastProgramDocumentsCount : null;
};

const getAvailableDocumentsCount = (documentsCount, batchSize, selectedProgramDocumentNumbers) => {
    const batchRemainder = batchSize - selectedProgramDocumentNumbers.length;

    if (batchRemainder) {
        if (documentsCount > batchRemainder) return batchRemainder;

        return documentsCount;
    }

    return 0;
};

export const DocumentQueueGrid = memo(() => {
    const {
        programList,
        getQueueDocuments,
        getQueueDocumentsAsync,
        queueDocuments,
        isBatchProcessing,
        selectedProgramNumbers,
        selectedProgramNumbersActions,
        selectedProgramDocumentNumbers,
        selectedProgramDocumentNumbersActions,
    } = useContext(DocumentQueueGridContext);

    const [batchSize, setBatchSize] = useState(100);
    const [expandedPrograms, expandedProgramsActions] = useItems();
    const [loadingProgramNumbers, loadingProgramNumbersActions] = useItems();
    const [isSelectionDisabled, setSelectionDisabled] = useState(false);

    const isBatchFilled = useMemo(() => selectedProgramDocumentNumbers.length >= batchSize, [selectedProgramDocumentNumbers, batchSize]);

    const selectProgramDocuments = useCallback(
        (programNumber, count) => {
            const queueDocuments = getResourceByPath("documentQueueProgramEntries.itemsById");

            if (programNumber in queueDocuments) {
                let payload = queueDocuments[programNumber].map(({ documentNumber }) => ({ documentNumber }));

                if (count) {
                    payload = payload.slice(0, count);
                }

                selectedProgramDocumentNumbersActions.add(payload);
            }
        },
        [selectedProgramDocumentNumbersActions]
    );

    const handleSelectProgram = useCallback(
        (programNumber) => {
            if (!selectedProgramNumbers.includes(programNumber)) {
                if (isBatchFilled || isSelectionDisabled || isBatchProcessing) return;

                if (!expandedPrograms.includes(programNumber)) {
                    expandedProgramsActions.add([programNumber]);
                }

                if (!selectedProgramNumbers.includes(programNumber)) {
                    selectedProgramNumbersActions.add([programNumber]);
                }

                if (programNumber in queueDocuments) {
                    const documentsCount = queueDocuments[programNumber].length;
                    const availableDocumentsCount = getAvailableDocumentsCount(documentsCount, batchSize, selectedProgramDocumentNumbers);

                    selectProgramDocuments(programNumber, availableDocumentsCount);
                } else {
                    setSelectionDisabled(true);
                    loadingProgramNumbersActions.add([programNumber]);

                    getQueueDocuments(programNumber, () => {
                        const documentsCount = (getResourceByPath("documentQueueProgramEntries.itemsById")[programNumber] || []).length;
                        const availableDocumentsCount = getAvailableDocumentsCount(
                            documentsCount,
                            batchSize,
                            selectedProgramDocumentNumbers
                        );

                        setSelectionDisabled(false);
                        loadingProgramNumbersActions.remove([programNumber]);
                        selectProgramDocuments(programNumber, availableDocumentsCount);
                    });
                }
            } else {
                selectedProgramNumbersActions.remove([programNumber]);

                if (programNumber in queueDocuments) {
                    const programDocuments = queueDocuments[programNumber].map(({ documentNumber }) => documentNumber);

                    selectedProgramDocumentNumbersActions.remove(programDocuments);
                }
            }
        },
        [
            batchSize,
            selectedProgramNumbers,
            selectedProgramNumbersActions,
            selectedProgramDocumentNumbers,
            selectedProgramDocumentNumbersActions,
            queueDocuments,
            loadingProgramNumbersActions,
            getQueueDocuments,
            selectProgramDocuments,
            isBatchFilled,
            isSelectionDisabled,
            isBatchProcessing,
            expandedPrograms,
            expandedProgramsActions,
        ]
    );

    const selectDocumentsAfterCommonBatchHasChecked = useCallback(async () => {
        const { programsCount, lastProgramDocumentsCount } = getCommonBatchProgramsCount(programList, batchSize);
        selectedProgramDocumentNumbersActions.clear();

        if (programsCount) {
            const programs = programList.slice(0, programsCount);
            const { progid: lastProgramNumber } = programs[programsCount - 1];
            const { withDocumentsProgramNumbers, withoutDocumentsProgramNumbers } = programs.reduce(
                (acc, { progid }) => {
                    if (progid in queueDocuments) {
                        acc.withDocumentsProgramNumbers.push(progid);
                    } else {
                        acc.withoutDocumentsProgramNumbers.push(progid);
                    }

                    return acc;
                },
                {
                    withDocumentsProgramNumbers: [],
                    withoutDocumentsProgramNumbers: [],
                }
            );

            [...withoutDocumentsProgramNumbers, ...withDocumentsProgramNumbers].forEach((programNumber) => {
                expandedProgramsActions.add([programNumber]);

                if (!selectedProgramNumbers.includes(programNumber)) {
                    selectedProgramNumbersActions.add([programNumber]);
                }
            });

            withDocumentsProgramNumbers.forEach((programNumber) => {
                const count = getLastProgramDocumentsCount(programNumber, lastProgramNumber, lastProgramDocumentsCount);

                selectProgramDocuments(programNumber, count);
            });

            if (!isEmpty(withoutDocumentsProgramNumbers)) {
                setSelectionDisabled(true);
                loadingProgramNumbersActions.add(withoutDocumentsProgramNumbers);

                await Promise.all(
                    withoutDocumentsProgramNumbers.map((programNumber) =>
                        getQueueDocumentsAsync(programNumber, () => {
                            const count = getLastProgramDocumentsCount(programNumber, lastProgramNumber, lastProgramDocumentsCount);

                            loadingProgramNumbersActions.remove([programNumber]);
                            selectProgramDocuments(programNumber, count);
                        })
                    )
                );
                setSelectionDisabled(false);
            }
        }
    }, [
        programList,
        batchSize,
        queueDocuments,
        getQueueDocumentsAsync,
        selectProgramDocuments,
        selectedProgramNumbers,
        selectedProgramDocumentNumbersActions,
        loadingProgramNumbersActions,
        selectedProgramNumbersActions,
        expandedProgramsActions,
    ]);

    const clearSelection = useCallback(() => {
        selectedProgramDocumentNumbersActions.clear();
        selectedProgramNumbersActions.clear();
    }, [selectedProgramNumbersActions, selectedProgramDocumentNumbersActions]);

    const handleChangeCommonBatch = useCallback(() => {
        if (isSelectionDisabled || isBatchProcessing) return;

        if (selectedProgramDocumentNumbers.length) {
            clearSelection();
        } else {
            selectDocumentsAfterCommonBatchHasChecked();
        }
    }, [selectedProgramDocumentNumbers, selectDocumentsAfterCommonBatchHasChecked, isSelectionDisabled, isBatchProcessing, clearSelection]);

    const handleChangeBatchSize = useCallback(
        (value) => {
            if (Number(value) < selectedProgramDocumentNumbers.length) {
                clearSelection();
                expandedProgramsActions.clear();
            }

            setBatchSize(Number(value));
        },
        [selectedProgramDocumentNumbers, clearSelection, expandedProgramsActions]
    );

    const handleClickProgramRow = useCallback(
        (programNumber) => {
            if (expandedPrograms.includes(programNumber)) {
                expandedProgramsActions.remove([programNumber]);
            } else {
                expandedProgramsActions.add([programNumber]);
            }

            if (!(programNumber in queueDocuments)) {
                loadingProgramNumbersActions.add([programNumber]);

                getQueueDocuments(programNumber, () => loadingProgramNumbersActions.remove([programNumber]));
            }
        },
        [queueDocuments, getQueueDocuments, expandedPrograms, loadingProgramNumbersActions, expandedProgramsActions]
    );

    return (
        <div className="application-document-queue__dq-grid flex-one-in-column no-scroll">
            <div className="application-document-queue__dq-grid-wrapper flex-column fill-height">
                <Controls
                    batchSize={batchSize}
                    isSelectionDisabled={isSelectionDisabled}
                    isBatchFilled={isBatchFilled}
                    expandedProgramsActions={expandedProgramsActions}
                    onChangeCommonBatch={handleChangeCommonBatch}
                    onChangeBatchSize={handleChangeBatchSize}
                />
                <ProgramList
                    loadingProgramNumbers={loadingProgramNumbers}
                    expandedPrograms={expandedPrograms}
                    isBatchFilled={isBatchFilled}
                    isSelectionDisabled={isSelectionDisabled}
                    onClickProgramRow={handleClickProgramRow}
                    onSelectProgram={handleSelectProgram}
                />
            </div>
        </div>
    );
});
