import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { windowContainerTypes } from "../../../utils/window";

import { WindowHeaderIconGroup } from "./WindowHeaderIconGroup";
import WindowHeaderForProject from "./WindowHeaderForProject";
import WindowHeaderForProgram from "./WindowHeaderForProgram";
import WindowHeaderForUtility from "./WindowHeaderForUtility";
import WindowHeaderForUtilityInvoice from "./WindowHeaderForUtilityInvoice";
import WindowHeaderForUtilityBudget from "./WindowHeaderForUtilityBudget";
import WindowHeaderForUtilityContract from "./WindowHeaderForUtilityContract";
import WindowHeaderForApplicationFormPages from "./WindowHeaderForApplicationFormPages";
import WindowHeaderForGlobalSearch from "./WindowHeaderForGlobalSearch";
import WindowHeaderForCustomer from "./WindowHeaderForCustomer";

import "./WindowHeader.scss";

const WindowHeader = memo(({ view, onClose, onSplit, onRemoveSplit }) => {
    const isHeaderDetailsExpanded = useSelector(
        (state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isHeaderDetailsExpanded
    );

    const headerContent = useMemo(() => {
        const { name, header, component } = view;

        let title = null;

        switch (component) {
            case "ProjectView":
                title = <WindowHeaderForProject view={view} />;
                break;

            case "ProgramView":
                title = <WindowHeaderForProgram view={view} />;
                break;

            case "UtilityView":
                title = <WindowHeaderForUtility view={view} />;
                break;

            case "UtilityContractView":
                title = <WindowHeaderForUtilityContract view={view} />;
                break;

            case "UtilityBudgetView":
                title = <WindowHeaderForUtilityBudget view={view} />;
                break;

            case "UtilityInvoiceView":
                title = <WindowHeaderForUtilityInvoice view={view} />;
                break;

            case "ApplicationFormPages":
                title = <WindowHeaderForApplicationFormPages view={view} />;
                break;

            case "GlobalSearchView":
                title = <WindowHeaderForGlobalSearch view={view} />;
                break;

            case "CustomerView":
                title = <WindowHeaderForCustomer view={view} />;
                break;

            default:
                title = header === undefined ? name : header;
                break;
        }

        return typeof title === "string" ? <span className="header-title">{title}</span> : title;
    }, [view]);

    return (
        <div
            className={cn("window-header", {
                "header-more-details-opened": isHeaderDetailsExpanded,
            })}
        >
            <div className="main-grid-wrap responsive">
                <div className="header-body flex">
                    <div className="header-info flex-one">{headerContent}</div>
                    <WindowHeaderIconGroup view={view} onSplit={onSplit} onClose={onClose} onRemoveSplit={onRemoveSplit} />
                </div>
            </div>
        </div>
    );
});

export default WindowHeader;
