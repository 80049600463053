import React from "react";
import { connect } from "react-redux";

import { openBudgetLineTab } from "../../../../../utils/window";
import { programsBudgetLinesGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../../ui/Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import { isChildProgram } from "components/views/ProgramView/utils";

const DATA_GRID_ID = "logs-program-budget-lines";

const LogsGrid = withDataGrid("programsBudgetLines", ({ gridId, dataGridConfig, programNumber }) => {
    const ROW_ACTIONS = [
        {
            name: "open",
            icon: "open-new-window",
            title: isChildProgram({ programNumber }) ? "View Budget" : "Manage Budget",
        },
    ];

    const onRowAction = (action) => {
        const { dataItem } = action;

        const utilityNumber = dataItem[programsBudgetLinesGridColumnKeys.utilityNumber];
        const contractNumber = dataItem[programsBudgetLinesGridColumnKeys.contractNumber];
        const budgetLineNumber = dataItem[programsBudgetLinesGridColumnKeys.budgetLineNumber];
        const budgetName = dataItem[programsBudgetLinesGridColumnKeys.budgetName];

        openBudgetLineTab({
            utilityNumber,
            contractNumber,
            budgetLine: {
                budgetLineNumber,
                budgetName,
            },
        });
    };

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={ROW_ACTIONS} onRowAction={onRowAction} />;
});

const BudgetsPanel = ({ panel }) => {
    const programNumber = panel && panel.data && panel.data.programNumber;

    return (
        <div className="panel budgets-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: DATA_GRID_ID,
                            fileName: "program_budgets",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <LogsGrid
                programNumber={programNumber}
                gridId={DATA_GRID_ID}
                filter={`${programsBudgetLinesGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
};

export default connect()(BudgetsPanel);
