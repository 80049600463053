import TabList from "components/ui/List/TabList";
import { PropertyType } from "components/ui/PortalBuilder/types";
import React, { useCallback, useContext, useRef, useState } from "react";
import { PropertyList } from "../../PropertyList";
import { PortalBuilderContext } from "components/ui/PortalBuilder/contexts";
import { cloneDeep, isArray, isNil, omitBy, set } from "lodash";
import { ButtonCornerProperty } from "./ButtonCornerProperty";
import ColorSelectPanel from "../../Property/ColorSelect/ColorSelectPanel";
import { SHADOW_PROPERTY } from "../../Property/propertyConfig";
import { PRIMARY_BUTTON_BORDER_PROPERTY } from "./PrimaryButtonBorderProperty";
import { GHOST_BUTTON_BORDER_PROPERTY } from "./GhostButtonBorderProperty";
import { FILLED_BUTTON_BORDER_PROPERTY } from "./FilledButtonBorderProperty";
import { ColorSelectIcon } from "../../Property/ColorSelect/ColorSelectIcon";
import {
    ButtonBackgroundCustomPropertyName,
    ButtonBorderCustomPropertyName,
    ButtonCustomPropertyName,
    ButtonSize,
    GhostButtonTextCustomPropertyName,
} from "./types";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

import "./GlobalButtonProperties.scss";

export const GlobalButtonProperties = (props) => {
    const { errors } = props;
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const configRef = useRef();

    if (updatedConfig) {
        configRef.current = updatedConfig;
    }

    const secondaryButtonType = updatedConfig?.widgets?.button?.secondaryButtonType;
    const sectionConfig = updatedConfig?.widgets?.button;

    const onPropertyChange = useCallback(
        (id, value, extra) => {
            let updatedConfigClone = cloneDeep(configRef.current);
            set(updatedConfigClone, `widgets.button[${id}]`, value);

            for (let [key, value] of Object.entries(configRef.current.colors)) {
                if (updatedConfigClone.widgets.button.primaryButtonBackgroundColorDefault === key) {
                    set(updatedConfigClone, `widgets.button.primaryButtonBackgroundColorDisabled`, `${value + "50"}`);
                }
                if (updatedConfigClone.widgets.button.filledButtonBackgroundColorDefault === key) {
                    set(updatedConfigClone, `widgets.button.filledButtonBackgroundColorDisabled`, `${value + "50"}`);
                }
                if (updatedConfigClone.widgets.button.ghostButtonBorderColorDefault === key) {
                    set(updatedConfigClone, `widgets.button.ghostButtonBorderColorDisabled`, `${value + "50"}`);
                }
                if (updatedConfigClone.widgets.button.filledButtonBorderColorDefault === key) {
                    set(updatedConfigClone, `widgets.button.filledButtonBorderColorDisabled`, `${value + "50"}`);
                }
                if (updatedConfigClone.widgets.button.ghostButtonTextColorDefault === key) {
                    set(updatedConfigClone, `widgets.button.ghostButtonTextColorDisabled`, `${value + "50"}`);
                }
            }

            if (isArray(extra)) {
                updatedConfigClone.widgets.button = omitBy(
                    extra.reduce((result, property) => {
                        return {
                            ...result,
                            [property.id]: property.value,
                        };
                    }, updatedConfigClone.widgets.button ?? {}),
                    isNil
                );
            }
            onConfigChange(updatedConfigClone);
        },
        [onConfigChange]
    );

    const COMMON_STYLES_PROPERTIES = [
        {
            id: ButtonCustomPropertyName.ButtonSize,
            title: <>Button size</>,
            type: PropertyType.SelectBox,
            className: "button-size-property",
            fullWidth: true,
            noPostfix: true,
            defaultValue: ButtonSize.Normal,
            items: [
                {
                    text: "Small",
                    value: ButtonSize.Small,
                },
                {
                    text: "Normal",
                    value: ButtonSize.Normal,
                },
                {
                    text: "Large",
                    value: ButtonSize.Large,
                },
            ],
        },
        {
            id: ButtonCustomPropertyName.ButtonCornerRadius,
            title: "Buttons Rounded Corners",
            type: PropertyType.CustomComponent,
            component: ButtonCornerProperty,
            borderBottom: true,
        },
    ];

    const PRIMARY_BUTTON_PROPERTIES = [
        {
            title: "Background Color",
            type: PropertyType.Group,
            collapsible: false,
            className: "background-color-property",
            propertiesGroup: [
                {
                    id: ButtonBackgroundCustomPropertyName.PrimaryButtonBackgroundColorDefault,
                    title: <>Default</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.PrimaryButtonBackgroundColorMouseOver,
                    title: <>Mouse over</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.PrimaryButtonBackgroundColorFocusActive,
                    title: <>Focus/Active</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.PrimaryButtonBackgroundColorDisabled,
                    title: <>Disabled</>,
                    type: "select-panel",
                    borderBottom: false,
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    disabled: true,
                },
            ],
        },
        {
            ...PRIMARY_BUTTON_BORDER_PROPERTY,
            propertiesGroup: [
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth,
                },
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColor,
                },
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColorOpacity,
                },
            ],
        },
        // TODO: Implement various state shadow property when design is ready
        SHADOW_PROPERTY,
    ];

    const GHOST_BUTTON_PROPERTIES = [
        {
            title: "Background Color",
            type: PropertyType.Group,
            collapsible: false,
            propertiesGroup: [
                {
                    title: <>Default</>,
                    type: "select-panel",
                    defaultValue: "transparent",
                    disabled: true,
                    selectTooltip: "Transparent / Not allowed to change because of selected ”Ghost” button type",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.GhostButtonBackgroundColorMouseOver,
                    title: <>Mouse over</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.GhostButtonBackgroundColorFocusActive,
                    title: <>Focus/Active</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    title: <>Disabled</>,
                    type: "select-panel",
                    defaultValue: "transparent",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    selectTooltip: "Transparent / Not allowed to change because of selected ”Ghost” button type",
                    disabled: true,
                    borderBottom: false,
                },
            ],
        },
        {
            title: "Text Color",
            type: PropertyType.Group,
            collapsible: false,
            className: "background-color-property",
            propertiesGroup: [
                {
                    id: GhostButtonTextCustomPropertyName.GhostButtonTextColorDefault,
                    title: <>Default</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: GhostButtonTextCustomPropertyName.GhostButtonTextColorMouseOver,
                    title: <>Mouse over</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    selectTooltip: "#ffffff / Not allowed to change because of selected ”Ghost” button type",
                    disabled: true,
                },
                {
                    id: GhostButtonTextCustomPropertyName.GhostButtonTextColorFocusActive,
                    title: <>Focus/Active</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    selectTooltip: "#ffffff / Not allowed to change because of selected ”Ghost” button type",
                    disabled: true,
                },
                {
                    id: GhostButtonTextCustomPropertyName.GhostButtonTextColorDisabled,
                    title: <>Disabled</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    disabled: true,
                    borderBottom: false,
                },
            ],
        },
        {
            ...GHOST_BUTTON_BORDER_PROPERTY,
            propertiesGroup: [
                {
                    id: ButtonBorderCustomPropertyName.GhostButtonBorderWidth,
                },
                {
                    id: ButtonBorderCustomPropertyName.GhostButtonBorderColorDefault,
                },
                {
                    id: ButtonBorderCustomPropertyName.GhostButtonBorderColorMouseOver,
                },
                {
                    id: ButtonBorderCustomPropertyName.GhostButtonBorderColorFocusActive,
                },
                {
                    id: ButtonBorderCustomPropertyName.GhostButtonBorderColorDisabled,
                },
            ],
        },
        // TODO: Implement various state shadow property when design is ready
        SHADOW_PROPERTY,
    ];

    const FILLED_BUTTON_PROPERTIES = [
        {
            title: "Background Color",
            type: PropertyType.Group,
            collapsible: false,
            propertiesGroup: [
                {
                    id: ButtonBackgroundCustomPropertyName.FilledButtonBackgroundColorDefault,
                    title: <>Default</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.FilledButtonBackgroundColorMouseOver,
                    title: <>Mouse over</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.FilledButtonBackgroundColorFocusActive,
                    title: <>Focus/Active</>,
                    type: "select-panel",
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                },
                {
                    id: ButtonBackgroundCustomPropertyName.FilledButtonBackgroundColorDisabled,
                    title: <>Disabled</>,
                    type: "select-panel",
                    borderBottom: false,
                    selectComponent: ColorSelectIcon,
                    panelComponent: ColorSelectPanel,
                    disabled: true,
                },
            ],
        },
        {
            ...FILLED_BUTTON_BORDER_PROPERTY,
            propertiesGroup: [
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderWidth,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDefault,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorMouseOver,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorFocusActive,
                },
                {
                    id: ButtonBorderCustomPropertyName.FilledButtonBorderColorDisabled,
                },
            ],
        },
        // TODO: Implement various state shadow property when design is ready
        SHADOW_PROPERTY,
    ];

    const SECONDARY_BUTTON_TYPE_PROPERTY = [
        {
            id: "secondaryButtonType",
            title: <>Button Type</>,
            type: PropertyType.SelectBox,
            defaultValue: "filled",
            noPostfix: true,
            items: [
                {
                    text: "Filled",
                    value: "filled",
                },
                {
                    text: "Ghost",
                    value: "ghost",
                },
            ],
        },
    ];

    const tabs = [
        {
            id: "commonStyles",
            title: "Common Styles",
            properties: COMMON_STYLES_PROPERTIES,
        },
        {
            id: "primaryButton",
            title: "Primary Button",
            properties: PRIMARY_BUTTON_PROPERTIES,
        },
        {
            id: "secondaryButton",
            title: "Secondary Button",
        },
    ];

    const [activeTab, setActiveTab] = useState(tabs[0]);
    let propertyItems = activeTab.properties;

    if (activeTab.id === "secondaryButton") {
        if (secondaryButtonType === "filled") {
            propertyItems = FILLED_BUTTON_PROPERTIES;
        } else {
            propertyItems = GHOST_BUTTON_PROPERTIES;
        }
    }

    return (
        <>
            <TabList items={tabs} activeItem={activeTab} onClick={setActiveTab} equalItemsWidth />
            {activeTab.id === "secondaryButton" && (
                <PropertyList
                    className="secondary-button-type-select"
                    items={SECONDARY_BUTTON_TYPE_PROPERTY}
                    config={sectionConfig}
                    onChange={onPropertyChange}
                ></PropertyList>
            )}
            <div className="global-button-properties with-scroll">
                <PropertyList
                    className=""
                    errors={errors}
                    items={propertyItems}
                    config={sectionConfig}
                    onChange={onPropertyChange}
                    isExpanded
                />
            </div>
        </>
    );
};
