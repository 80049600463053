import React, { useContext } from "react";
import { WidgetType } from "components/ui/PortalBuilder/types";
import { PORTAL_WIDGET_PROPERTIES } from "components/ui/PortalBuilder/utils";
import { PropertyList } from "../PropertyList";
import { PageContext, PortalBuilderPropertiesContext, RowContext } from "../../contexts";
import { getColumnErrors } from "../../utils/validation";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

import "./ColumnProperty.scss";

export const ColumnProperty = (props) => {
    const { value = [], onChange } = props;
    const type = WidgetType.COL;
    const { selectedColumn, sectionTitle } = useContext(PortalBuilderPropertiesContext);
    const { row } = useContext(RowContext);
    const { page } = useContext(PageContext);

    const { errors } = useErrorContext();
    const [activeError] = usePortalBuilderState((state) => state.activeError);

    const containsActiveError =
        activeError?.pageIndex === page?.index && activeError?.rowIndex === row.index && activeError?.columnIndex === selectedColumn;
    let columnErrors;
    if (page && row) {
        columnErrors = getColumnErrors(errors, `content.pages[${page.index}].components[${row.index}].components[${selectedColumn}].`);
    } else if (sectionTitle === "Header") {
        columnErrors = getColumnErrors(errors, `header.components[${row.index}].components[${selectedColumn}].`);
    } else if (sectionTitle === "Footer") {
        columnErrors = getColumnErrors(errors, `footer.components[${row.index}].components[${selectedColumn}].`);
    }

    return (
        <div className="column-property flex-one-in-column">
            <PropertyList
                containsActiveError={containsActiveError}
                errors={columnErrors}
                items={PORTAL_WIDGET_PROPERTIES[type]}
                nestingLevel={1}
                config={value}
                onChange={onChange}
                isExpanded
            />
        </div>
    );
};
