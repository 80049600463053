import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getResource } from "../../../../../../store/resources/actions";

import WaitIcon from "../../../../WaitIcon";
import ApprovedEquipmentAttributesForm from "./ApprovedEquipmentAttributesForm";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";

const ApprovedEquipmentDetails = memo(({ title, isApplyApprovedEquipment, itemId, industryMeasureNumber, addEquipment, onClose }) => {
    const dispatch = useDispatch();

    const approvedEquipmentDetails = useSelector((state) => state.resources.approvedEquipmentDetails.itemsById[itemId]);
    const loadingApprovedEquipmentDetails = useSelector((state) => state.resources.approvedEquipmentDetails.isReading);

    useEffect(() => {
        if (!approvedEquipmentDetails) {
            dispatch(
                getResource({
                    resourceName: "approvedEquipmentDetails",
                    resourceId: itemId,
                    path: {
                        industryMeasureNumber,
                    },
                })
            );
        }
    }, [itemId, approvedEquipmentDetails, industryMeasureNumber, dispatch]);

    const getEquipment = () => {
        if (approvedEquipmentDetails && approvedEquipmentDetails.approvedMeasure) {
            return {
                attributes: (approvedEquipmentDetails.approvedMeasure.attributeItem || []).map((attr) => ({
                    attributename: attr.attribute,
                    value: attr.val,
                })),
            };
        }

        return {};
    };

    const notFound = () => {
        if (approvedEquipmentDetails && approvedEquipmentDetails.xmloutput === null) {
            return <div className="not-found-msg">Equipment not found</div>;
        }

        return null;
    };

    if (!approvedEquipmentDetails && loadingApprovedEquipmentDetails) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            {isApplyApprovedEquipment && (
                <SideNavHeader title={title ?? "Add Approved Equipment"} leadBlockIcon="plus" smallHeader onClose={onClose} />
            )}
            <SideNavBody className="flex-one-in-column">
                <div className="approved-equipment-details fill-width fill-height">
                    {notFound()}
                    <ApprovedEquipmentAttributesForm equipment={getEquipment()} />
                </div>
            </SideNavBody>
        </SideNavContent>
    );
});

export default ApprovedEquipmentDetails;
