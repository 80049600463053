import React, { useState, useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import {
    getFriendlyNamesGridGridId,
    programsFormsHistoryGridColumnKeys,
    programsFormsPagesGridColumnKeys,
} from "../../../../../views/configureGrids";
import { refreshProgramForms } from "../../../../../../store/resources/refreshResource";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { openApplicationFormPages } from "../../../../../utils/window";
import { useProgramForm } from "../../../../../../store/resources/useResource";
import { isChildProgram } from "components/views/ProgramView/utils";
import { setActivePage } from "../../../../../../store/pages/actions";

import WaitIcon from "../../../../WaitIcon";
import TabList from "../../../../List/TabList";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import FormsForm from "./FormsForm";
import PagesGrid from "./PagesGrid";
import HistoryGrid from "./HistoryGrid";
import FriendlyNamesGrid from "./FriendlyNamesGrid";
import NothingFoundBlock from "components/ui/NothingFoundBlock";

import "./style.scss";

const tabs = [
    {
        id: "form",
        title: "Forms",
    },
    {
        id: "friendlyNames",
        title: "All Form Fields",
    },
    {
        id: "history",
        title: "History",
    },
];

const FormsPanel = memo(({ panel }) => {
    const dispatch = useDispatch();

    const panelTitle = panel && panel.name;
    const utilityNumber = panel && panel.data && panel.data.utilityNumber;
    const programNumber = panel && panel.data && panel.data.programNumber;
    const isLocked = isChildProgram({ programNumber });

    const dataGridIdPages = `${programNumber}-forms-pages`;
    const dataGridIdHistory = `${programNumber}-forms-history`;

    const [activeTab, setActiveTab] = useState(tabs[0]);

    const [form, isLoading] = useProgramForm({ programNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "application-form-pages-form-sidenav-panel",
    });

    const handleRefresh = useCallback(() => {
        refreshProgramForms({ programNumber });
    }, [programNumber]);

    const handleOpenDetails = useCallback(() => {
        handleOpenSidePanel(
            <FormsForm programNumber={programNumber} form={form} onRefresh={handleRefresh} onClose={handleCloseSidePanel} />
        );
    }, [form, handleRefresh, handleOpenSidePanel, handleCloseSidePanel, programNumber]);

    const handleOpenPages = useCallback(() => {
        openApplicationFormPages({
            programNumber,
            utilityNumber,
            formNumber: form?.formNumber,
            formName: form?.formName,
        });
    }, [utilityNumber, programNumber, form]);

    const handleOpenPage = useCallback(
        (pageNumber) => {
            // Clear active page
            dispatch(
                setActivePage({
                    id: programNumber,
                    page: undefined,
                })
            );

            openApplicationFormPages({
                programNumber,
                utilityNumber,
                formNumber: form?.formNumber,
                formName: form?.formName,
                pageNumber,
            });
        },
        [utilityNumber, programNumber, form, dispatch]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    if (isNil(form)) {
        return (
            <div className="flex-column fill-height">
                <NothingFoundBlock icon="forms" title="Forms Not Found" />
            </div>
        );
    }

    return (
        <>
            <div className="panel forms-panel sticky-grid-list-panel flex-column fill-height">
                <PanelHeaderLarge title={panelTitle} />
                <TabList items={tabs} tabListGhost activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                {activeTab.id === "form" && (
                    <>
                        <div className="forms-tab-lead-block flex-row align-center justify-space-between">
                            <div className="form-name">
                                <strong>Application Form Pages:</strong>
                                {`${form?.formName}`}
                            </div>
                            <span className="flex-one"></span>
                            <IconWithLabel disabled={isLocked} className="form-action" icon={"open-new-window"} onClick={handleOpenPages}>
                                Manage
                            </IconWithLabel>
                            <IconWithLabel
                                className="form-action"
                                icon={isLocked ? "eye-visibility-empty" : "edit-empty"}
                                onClick={handleOpenDetails}
                            >
                                {isLocked ? "View Details" : "Edit Details"}
                            </IconWithLabel>
                            <IconWithLabel
                                className="form-action"
                                icon={"custom-csv"}
                                onClick={() =>
                                    exportDatagridToCSV({
                                        dataGridId: dataGridIdPages,
                                        fileName: "program_form",
                                        fileNamePostfix: programNumber,
                                    })
                                }
                            >
                                Export CSV
                            </IconWithLabel>
                        </div>
                        <PagesGrid
                            programNumber={programNumber}
                            gridId={dataGridIdPages}
                            filter={`${programsFormsPagesGridColumnKeys.programNumber}=${programNumber}`}
                            onPageOpen={handleOpenPage}
                        />
                    </>
                )}
                {activeTab.id === "friendlyNames" && (
                    <div className="forms-friendly-names-tab flex-column flex-one-in-column no-scroll">
                        <div className="data-grid-controls flex-row justify-end">
                            <div className="data-grid-controls flex-row justify-end"></div>
                            <IconWithLabel
                                withHandMadeIcon
                                onClick={() => {
                                    const formNumber = form?.formNumber;
                                    const dataGridIdFriendlyNames = getFriendlyNamesGridGridId({ formNumber });

                                    exportDatagridToCSV({
                                        dataGridId: dataGridIdFriendlyNames,
                                        fileName: "program_form_all_fields",
                                        fileNamePostfix: programNumber,
                                    });
                                }}
                            >
                                Export CSV
                            </IconWithLabel>
                        </div>
                        <FriendlyNamesGrid
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            formNumber={form?.formNumber}
                            formName={form?.formName}
                            onClose={handleCloseSidePanel}
                        />
                    </div>
                )}
                {activeTab.id === "history" && (
                    <div className="forms-history-tab flex-column flex-one-in-column no-scroll">
                        <div className="data-grid-controls flex-row justify-end">
                            <IconWithLabel
                                withHandMadeIcon
                                onClick={() =>
                                    exportDatagridToCSV({
                                        dataGridId: dataGridIdHistory,
                                        fileName: "program_form_history",
                                        fileNamePostfix: programNumber,
                                    })
                                }
                            >
                                Export CSV
                            </IconWithLabel>
                        </div>
                        <div className="flex-one-in-column">
                            <HistoryGrid
                                gridId={dataGridIdHistory}
                                filter={`${programsFormsHistoryGridColumnKeys.programNumber}=${programNumber}`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});

export default FormsPanel;
