import React, { memo } from "react";
import cn from "classnames";

import CustomList, { renderCustomFooter } from "../../../../List/CustomList";

import { formatJsonDateTime } from "../../../../../utils/date";

import "./QualityControlList.scss";

const headers = {
    type: "Type",
    status: "Status",
    dateentered: "Status date",
};

const QualityControlList = memo(({ items }) => {
    const renderItem = (item) => {
        const datetime = formatJsonDateTime(item.dateentered);
        const initDate = datetime.split(",").slice(0, 2);
        const date = initDate.join(",");

        return (
            <div key={item.qcnumber} className="grid-list-row">
                <div className="grid-list-column type">
                    {item.type}
                    <div className="subtitle">
                        <div className={cn({ empty: item.filecount === 0 })}>{`Files (${item.filecount})`},</div>
                        <div className={cn({ empty: item.notecount === 0 })}>{`Notes (${item.notecount})`}</div>
                    </div>
                </div>
                <div className="grid-list-column">{item.status}</div>
                <div className="grid-list-column">{date}</div>
            </div>
        );
    };

    return (
        <CustomList
            limit={6}
            items={items}
            headers={headers}
            className="quality-control-panel-list"
            renderItem={renderItem}
            renderFooter={items.length > 6 && renderCustomFooter}
        />
    );
});

export default QualityControlList;
