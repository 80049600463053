import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { programBookmarksGridColumnKeys, widgetProgramsGridColumnKeys, availableGrids } from "../configureGrids";
import { openProgramTab } from "store/window/openTabActions";
import { entityType } from "../../utils/constants";
import { updateResource } from "../../../store/resources/actions";
import { modalOpen } from "../../../store/modal/actions";
import { construct } from "../../../store/dataGrid/actions";
import { refreshProgram } from "../../../store/resources/refreshResource";
import { refreshProgramBookmarksWidgetGrid, refreshProgramBookmarksGrid } from "../../../store/dataGrid/refreshGrid";
import { searchAttrToFilter } from "../../utils/datagrid";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import DataGrid from "../../ui/DataGrid";
import RawHtml from "../../ui/RawHtml";
import ActionLabel from "../../ui/Label/ActionLabel";
import WaitIcon from "../../ui/WaitIcon";

import "./style.scss";

const rowActions = [
    {
        name: "open",
        icon: "open-new-window",
        title: "Manage Program",
    },
    {
        name: "delete",
        icon: "bookmark-filled icon-wrap-combined remove",
        title: "Remove from Bookmarks",
    },
];

const UtilitiesAndProgramManagementBookmarkedPrograms = memo(() => {
    return (
        <div className="bookmarked-programs panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <PinnedProgramsDataGrid isWidget={false} />
            </div>
        </div>
    );
});

const Grid = memo(({ isWidget }) => {
    const dispatch = useDispatch();

    const dataGridID = isWidget ? availableGrids.widgetPrograms : availableGrids.programBookmarks;

    const columnIsFavorite = isWidget ? widgetProgramsGridColumnKeys.isFavorite : programBookmarksGridColumnKeys.isFavorite;
    const columnProgramName = isWidget ? widgetProgramsGridColumnKeys.programName : programBookmarksGridColumnKeys.programName;
    const columnProgramNumber = isWidget ? widgetProgramsGridColumnKeys.programNumber : programBookmarksGridColumnKeys.programNumber;

    const user = useSelector((store) => store.user);
    const gridId = `${user.userNumber}${isWidget ? "-widget" : ""}-programs-bookmarks-grid`;
    const gridFilter = `${columnIsFavorite}=${true}`;
    const gridConfig = useSelector((store) => store.dataGrid[gridId]);

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: dataGridID,
                dataGridInstanceId: gridId,
                filter: searchAttrToFilter(gridFilter),
                data: true,
            })
        );
    }, [dataGridID, gridId, gridFilter, dispatch]);

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const programName = dataItem[columnProgramName];
        const programNumber = dataItem[columnProgramNumber];

        switch (name) {
            case "open":
                onBookmarkOpen(programNumber);
                break;

            case "delete":
                onBookmarkDelete({ programNumber, programName });
                break;

            default:
                break;
        }
    };

    const onBookmarkOpen = (programNumber) => {
        dispatch(openProgramTab({ programNumber }));
    };

    const onBookmarkDelete = ({ programNumber, programName }) => {
        const text = "<p>Would you like to remove program <strong>" + programName + "</strong> from your Bookmarks?</p>";

        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Remove from Bookmarks",
                    modalIcon: "bookmark-filled icon-wrap-combined remove",
                    overlayClassName: "modal-styled",
                    className: "remove-from-bookmarks-confirmation-modal modal-sm",
                    footerContentCenter: true,
                    content: <RawHtml>{text}</RawHtml>,
                    onConfirm: () => {
                        dispatch(
                            updateResource({
                                resourceName: "userFavorites",
                                query: {
                                    entityType: entityType.Program,
                                    entityId: programNumber,
                                },
                                optimisticUpdate: {
                                    clearItem: true,
                                },
                                onSuccess: () => {
                                    refreshProgramBookmarksGrid();
                                    refreshProgramBookmarksWidgetGrid();
                                    refreshProgram({ programNumber });
                                },
                            })
                        );
                    },
                },
            })
        );
    };

    if (!gridConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "programs",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid
                name={gridId}
                isWidget={isWidget}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [columnProgramNumber]: (column, row) => (
                        <ActionLabel onClick={() => onBookmarkOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                    ),
                    [columnProgramName]: (column, row) => {
                        if (row[columnProgramNumber]) {
                            return <ActionLabel onClick={() => onBookmarkOpen(row[columnProgramNumber])}>{row[column.key]}</ActionLabel>;
                        }

                        return row[column.key];
                    },
                }}
            />
        </>
    );
});

export default UtilitiesAndProgramManagementBookmarkedPrograms;

export const PinnedProgramsDataGrid = Grid;
