import React, { useState, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { UPDATE_PASSWORD_ERROR, UPDATE_PASSWORD_ERROR_CLEAR } from "../../../store/actionTypes";
import { updatePassword } from "../../../store/login/actions";

import Form from "../../ui/Form";
import TextInput from "../../ui/Input/TextInput";
import Button from "../../ui/Button";
import LoginFormWrap from "../../ui/LoginFormWrap";
import { ErrorMessage } from "../../ui/Message";
import { useResource } from "store/resources/useResource";

const ERROR_PASSWORDS_DO_NOT_MATCH = "Passwords do not match";

const ChangePasswordForm = memo(({ onSuccess }) => {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.login.isLoading);
    const errorMessage = useSelector((state) => state.login.errorMessage);
    const twoFactorRequired = useSelector((state) => state.login.twoFactorRequired);
    const twoFactorQrCodeExists = useSelector((state) => state.login.twoFactorQrCodeExists);

    const isError = (errorMessage || "").length > 0;
    const isErrorNewPassword = (errorMessage || "") === ERROR_PASSWORDS_DO_NOT_MATCH;

    const [authCode, setAuthCode] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeated, setNewPasswordRepeated] = useState("");

    const [passwordInfo] = useResource({
        resourceName: "userPassword",
        key: "userPassword",
    });

    const isValid = useMemo(() => {
        let valid =
            oldPassword?.length > 0 && newPassword?.length > 0 && newPasswordRepeated?.length > 0 && newPassword === newPasswordRepeated;

        if (twoFactorRequired && twoFactorQrCodeExists) {
            valid = valid && (authCode || "").trim().length > 0;
        }

        if (newPassword?.length > 0 && newPasswordRepeated?.length > 0 && newPassword !== newPasswordRepeated) {
            dispatch({
                type: UPDATE_PASSWORD_ERROR,
                message: ERROR_PASSWORDS_DO_NOT_MATCH,
            });

            return false;
        }

        return valid;
    }, [authCode, newPassword, newPasswordRepeated, oldPassword, twoFactorQrCodeExists, twoFactorRequired, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid) {
            dispatch(
                updatePassword({
                    oldPassword,
                    newPassword,
                    authCode,
                })
            );
        }
    };

    const handleRedirectToLogin = (e) => {
        e.preventDefault();

        if (onSuccess) {
            onSuccess();
        }
    };

    const clearError = () => {
        if (isError) {
            dispatch({
                type: UPDATE_PASSWORD_ERROR_CLEAR,
            });
        }
    };

    return (
        <LoginFormWrap>
            <Form onSubmit={handleSubmit}>
                {isError && <ErrorMessage msgSquare>{errorMessage}</ErrorMessage>}
                <h3>Change password</h3>
                <TextInput
                    required
                    label="Old password"
                    name="oldPassword"
                    type="password"
                    placeholder="Enter your old password"
                    onChange={(e) => {
                        clearError();
                        setOldPassword(e.target.value);
                    }}
                />
                <TextInput
                    required
                    label="New password"
                    name="newPassword"
                    type="password"
                    placeholder="Enter your new password"
                    error={isErrorNewPassword}
                    msgText={passwordInfo?.description}
                    onChange={(e) => {
                        clearError();
                        setNewPassword(e.target.value);
                    }}
                />
                <TextInput
                    required
                    label="Confirm password"
                    name="newPasswordRepeated"
                    type="password"
                    placeholder="Confirm your new password"
                    error={isErrorNewPassword}
                    onChange={(e) => {
                        clearError();
                        setNewPasswordRepeated(e.target.value);
                    }}
                />
                {twoFactorRequired && twoFactorQrCodeExists && (
                    <TextInput
                        required
                        label="Authentication Code"
                        name="authCode"
                        type="password"
                        placeholder="Enter authentication code"
                        error={isError}
                        onChange={(e) => {
                            clearError();
                            setAuthCode(e.target.value);
                        }}
                    />
                )}
                <div className="form-btn-wrap change-password">
                    <span className="login-form-link" onClick={handleRedirectToLogin}>
                        Return to login page
                    </span>
                    <span className="flex-one"></span>
                    <Button type="submit" primary disabled={!isValid || isLoading}>
                        Change password
                    </Button>
                </div>
            </Form>
        </LoginFormWrap>
    );
});

export default ChangePasswordForm;
