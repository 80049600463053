import React, { useState } from "react";
import cn from "classnames";
import { isEmpty } from "lodash";
import IconWithLabel from "../../../../../Icons/IconWithLabel";
import TileItem from "components/ui/TilesWrap/TileItem";
import TextLabel from "components/ui/Label/TextLabel";
import IconWrap from "components/ui/Icons";
import { PortalTemplateThumbnail } from "components/ui/PortalBuilder/PortalThumbnail";

const PortalsTileItem = (props) => {
    const { isNew, onOpen, template } = props;
    const content = isNew ? <TileForNewTemplate {...props} /> : <TileForTemplate {...props} />;

    return (
        <TileItem
            className={cn("portal-template-tile", {
                "portal-template-tile--new": isNew,
            })}
            align={isNew ? "center" : "stretch"}
            justify={isNew ? "center" : "start"}
            content={content}
            onClick={onOpen(template)}
        />
    );
};

const TileForNewTemplate = (props) => {
    const { onOpen } = props;
    return (
        <IconWithLabel icon="plus" title="Add Template" onClick={onOpen()}>
            Add Template
        </IconWithLabel>
    );
};

const TileForTemplate = (props) => {
    const { template = {}, onOpen, onRemove, onClone } = props;
    const [showNotes, setShowNotes] = useState(false);

    const { name, notes, thumbnail, inUseOnPrograms = [] } = template;
    const programsAssigned = inUseOnPrograms.length;
    const canDelete = programsAssigned === 0;
    const deleteTooltip = canDelete ? "Delete Template" : "Template is in use on Program Level so it can not be deleted.";

    return (
        <>
            <div className="portal-template-tile-preview flex-column align-center">
                <PortalTemplateNotes isExpanded={showNotes} notes={notes} usageCount={programsAssigned} />
                <PortalTemplateThumbnail thumbnail={thumbnail} />
            </div>
            <span className="flex-one-in-column" />
            <TextLabel>{name}</TextLabel>
            <div className="tile-item-footer flex-row fill-width align-center justify-end">
                <IconWrap icon="layers-empty" title={`Clone "${name}"`} onClick={onClone(template)} />
                <IconWrap
                    onMouseOver={() => setShowNotes(true)}
                    onMouseLeave={() => setShowNotes(false)}
                    className="tile-item-hover-info"
                    iconWrapClickable
                    title="Template Info"
                    icon="info-empty"
                />
                <IconWrap icon="open-new-window" title="Manage Template" onClick={onOpen(template)} />
                <IconWrap
                    disabled={!canDelete}
                    icon="delete-trash-empty"
                    title={deleteTooltip}
                    data-test-id={`${template.portalTemplateNumber}-delete`}
                    onClick={onRemove(template)}
                />
            </div>
        </>
    );
};

const PortalTemplateNotes = ({ isExpanded, notes, usageCount }) => {
    const hasNotes = !isEmpty(notes);

    return (
        <div
            className={cn("portal-template-notes", {
                "portal-template-notes--expanded": isExpanded,
            })}
        >
            <div className="portal-template-notes-content flex-column fill-height fill-width">
                {hasNotes && (
                    <>
                        <div className="portal-template-note-title">Note:</div>
                        <div className="portal-template-note-text">{notes}</div>
                    </>
                )}
                <div className="flex-one-in-column" />
                <div className="portal-template-usage-note flex-row align-center">
                    <div className="portal-template-usage-count">{usageCount}</div>
                    <div className="portal-template-usage-text">
                        <strong>{usageCount === 1 ? "program" : "programs"}</strong> {usageCount === 1 ? "is" : "are"} using
                        <br />
                        this template
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortalsTileItem;
