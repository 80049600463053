export enum BorderCustomPropertyName {
    BorderWidthType = "borderWidthType",
    BorderWidthAllSides = "borderWidthAllSides",
    BorderWidthSideBySide = "borderWidthSideBySide",
    BorderColorType = "borderColorType",
    BorderColorAllSides = "borderColorAllSides",
    BorderColorSideBySide = "borderColorSideBySide",
}

export enum BorderSides {
    SideBySide = "side-by-side",
    AllSides = "all-sides",
}

export enum BorderSideName {
    Top = "top",
    Right = "right",
    Bottom = "bottom",
    Left = "left",
    AllSides = "all-sides",
}

export interface BorderSideProps {
    side: BorderSideName;
    selectedValue: number;
    onSelect: (width: number) => void;
}

export interface BorderWidthSideBySideValue {
    [BorderSideName.Top]: number;
    [BorderSideName.Right]: number;
    [BorderSideName.Bottom]: number;
    [BorderSideName.Left]: number;
}

export interface BorderColorSideBySideValue {
    [BorderSideName.Top]: string;
    [BorderSideName.Right]: string;
    [BorderSideName.Bottom]: string;
    [BorderSideName.Left]: string;
}
export interface BorderCustomPropertiesValue {
    [BorderCustomPropertyName.BorderWidthType]: BorderSides;
    [BorderCustomPropertyName.BorderWidthAllSides]: number;
    [BorderCustomPropertyName.BorderWidthSideBySide]: BorderWidthSideBySideValue;
    [BorderCustomPropertyName.BorderColorType]: BorderSides;
    [BorderCustomPropertyName.BorderColorAllSides]: string;
    [BorderCustomPropertyName.BorderColorSideBySide]: BorderColorSideBySideValue;
}
