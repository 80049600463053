import React, { useMemo, memo } from "react";
import { connect } from "react-redux";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Workflow from "../../../../Workflow";
import { referenceTypes } from "../../../../Reference/referenceTypes";

const WorkflowPanelContainer = ({ panel, workflow }) => {
    const {
        data: { utilityNumber, contractNumber, budgetLineNumber },
    } = panel;

    const workflowGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesContractsBudgetLinesWorkflowDetails",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        }),
        [utilityNumber, contractNumber, budgetLineNumber]
    );

    const statesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStates",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const stepTypesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStepTypes",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const stepAttributeTypesGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowStepAttributeTypes",
            key: utilityNumber,
            path: {
                utilityNumber,
            },
        }),
        [utilityNumber]
    );

    const assignmentsGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesWorkflowAssignments",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
            },
        }),
        [utilityNumber, contractNumber]
    );

    const workflowStatusGetResourceOptions = useMemo(
        () => ({
            resourceName: "utilitiesContractsBudgetLinesWorkflowStatus",
            key: contractNumber,
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        }),
        [utilityNumber, contractNumber, budgetLineNumber]
    );

    return (
        <div className="panel contract-workflow">
            <PanelHeaderLarge title={panel.name} />
            <Workflow
                workflow={workflow}
                flagWorkflowReference={referenceTypes.invoiceFlagWorkflow}
                workflowGetResourceOptions={workflowGetResourceOptions}
                statesGetResourceOptions={statesGetResourceOptions}
                stepTypesGetResourceOptions={stepTypesGetResourceOptions}
                stepAttributeTypesGetResourceOptions={stepAttributeTypesGetResourceOptions}
                assignmentsGetResourceOptions={assignmentsGetResourceOptions}
                workflowStatusGetResourceOptions={workflowStatusGetResourceOptions}
            />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { panel } = ownProps;
    const {
        data: { contractNumber },
    } = panel;

    return {
        workflow: get(state, `resources.utilitiesContractsBudgetLinesWorkflowDetails.itemsById[${contractNumber}]`),
    };
};

export default memo(connect(mapStateToProps)(WorkflowPanelContainer));
