import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";

import { locationApplicationsGridColumnKeys } from "../../../../../views/configureGrids";
import { openProjectTab, splitProjectTab } from "../../../../../utils/window";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import IconWrap from "../../../../Icons";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import ActionLabel from "../../../../Label/ActionLabel";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./LocationPanel.scss";
import { useCanSplitScreenView } from "components/utils/useCanSplitScreenView";

const Applications = memo(({ applicationNumber, address, zip }) => {
    const gridId = `${applicationNumber}-location-applications-grid`;
    const filter = `${locationApplicationsGridColumnKeys.address}=${address}|${locationApplicationsGridColumnKeys.zip}=${zip}`;

    return (
        <div className="fill-width">
            <div className="flex-row">
                <IconWrap icon="location" />
                <h3>Other applications at this location</h3>
            </div>
            {address && zip ? (
                <Grid applicationNumber={applicationNumber} gridId={gridId} filter={filter} />
            ) : (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="location" title="No Data Available" />
            )}
        </div>
    );
});

const showedColumnsKeys = [
    locationApplicationsGridColumnKeys.projectName,
    locationApplicationsGridColumnKeys.projectNumber,
    locationApplicationsGridColumnKeys.utility,
    locationApplicationsGridColumnKeys.program,
    locationApplicationsGridColumnKeys.dateSubmitted,
    locationApplicationsGridColumnKeys.status,
    locationApplicationsGridColumnKeys.statusDate,
    locationApplicationsGridColumnKeys.rebate,
];

const Grid = withDataGrid(
    "locationApplications",
    memo(({ applicationNumber, gridId, dataGridConfig }) => {
        const isMobile = useSelector((state) => state.window?.isMobile);
        const canUseSplitScreenView = useCanSplitScreenView();

        const customRowActions = [
            {
                name: "open-in-split",
                icon: "reader-mode-empty",
                title: "Open in Split View",
                hide: !canUseSplitScreenView,
            },
            {
                name: "edit",
                icon: "open-new-window",
                title: "Manage Application",
            },
        ];

        const onRowAction = (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    onApplicationOpen(dataItem[locationApplicationsGridColumnKeys.applicationNumber]);
                    break;

                case "open-in-split":
                    splitProjectTab({ applicationNumber });
                    onApplicationOpen(dataItem[locationApplicationsGridColumnKeys.applicationNumber]);
                    break;

                default:
                    break;
            }
        };

        const onApplicationOpen = (applicationNumber) => {
            openProjectTab({ applicationNumber });
        };

        const handleCustomRowClassName = useCallback(
            (row) => {
                return row[locationApplicationsGridColumnKeys.applicationNumber] === applicationNumber ? "current-app" : "";
            },
            [applicationNumber]
        );

        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "application_location_other_applications",
                                fileNamePostfix: applicationNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid
                    key={isMobile}
                    name={gridId}
                    config={dataGridConfig}
                    showedColumnsKeys={showedColumnsKeys}
                    onRowAction={onRowAction}
                    customRowActions={customRowActions}
                    customRowClassName={handleCustomRowClassName}
                    columnCellContent={{
                        [locationApplicationsGridColumnKeys.applicationNumber]: (column, row) => (
                            <ActionLabel onClick={() => onApplicationOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                        ),
                        [locationApplicationsGridColumnKeys.projectNumber]: (column, row) => {
                            if (row[locationApplicationsGridColumnKeys.applicationNumber]) {
                                return (
                                    <ActionLabel
                                        onClick={() => onApplicationOpen(row[locationApplicationsGridColumnKeys.applicationNumber])}
                                    >
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                        [locationApplicationsGridColumnKeys.projectName]: (column, row) => {
                            if (row[locationApplicationsGridColumnKeys.applicationNumber]) {
                                return (
                                    <ActionLabel
                                        onClick={() => onApplicationOpen(row[locationApplicationsGridColumnKeys.applicationNumber])}
                                    >
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            }

                            return row[column.key];
                        },
                    }}
                />
            </>
        );
    })
);

export default Applications;
