import cn from "classnames";
import React, { useMemo } from "react";
import { PropertyType } from "../../types";
import { Property } from "../Property";

import "./propertyList.scss";

export const PropertyList = (props) => {
    const {
        className,
        parentTitle,
        items = [],
        config = {},
        onChange,
        isExpanded = true,
        nestingLevel = 1,
        errors = [],
        containsActiveError,
    } = props;

    const properties = useMemo(
        () =>
            items
                .map((item) => ({
                    ...item,
                    value: config[item.id],
                    titleSwitchValue: config[item.titleSwitch?.id] ?? item.titleSwitch?.defaultValue,
                    errors: errors.filter((e) => e.id === item.id).map((e) => e.message),
                    ...(item.helperPropName ? { [item.helperPropName]: config[item.helperPropName] } : {}),
                }))
                .map((item) => {
                    if (Array.isArray(item.propertiesGroup)) {
                        let value;

                        if (item.type === PropertyType.NestedProperties) {
                            value = config[item.id];
                        } else {
                            value = item.propertiesGroup.reduce((result, child1) => {
                                if (Array.isArray(child1.propertiesGroup)) {
                                    if (child1.type === PropertyType.NestedProperties) {
                                        return config[child1.id];
                                    }

                                    return {
                                        ...result,
                                        ...child1.propertiesGroup.reduce(
                                            (result, child2) => ({ ...result, [child2.id]: config[child2.id] }),
                                            {}
                                        ),
                                    };
                                }

                                return { ...result, [child1.id]: config[child1.id] };
                            }, {});
                        }

                        let listErrors;
                        if (item.type === PropertyType.NestedProperties) {
                            listErrors = [];
                            item.propertiesGroup.forEach((nestedItem) => {
                                // different id, because the property is nested
                                listErrors[nestedItem.id] = errors
                                    .filter((e) => e.id === `${item.id}.${nestedItem.id}`)
                                    .map((e) => e.message);

                                // the properties group props of the nested property are still flat relative to it though
                                nestedItem?.propertiesGroup?.forEach((nestedPropGroupItem) => {
                                    listErrors[nestedPropGroupItem.id] = errors
                                        .filter((e) => e.id === `${item.id}.${nestedPropGroupItem.id}`)
                                        .map((e) => e.message);
                                });
                            });
                        } else {
                            listErrors = item.propertiesGroup.reduce(
                                (result, item) =>
                                    (result = { ...result, [item.id]: errors.filter((e) => e.id === item.id).map((e) => e.message) }),
                                {}
                            );
                        }

                        return {
                            ...item,
                            value,
                            errors: listErrors,
                        };
                    }

                    return item;
                }),
        [config, errors, items]
    );

    if (!isExpanded) {
        return null;
    }

    return (
        <div
            className={cn("flex-one property-list", `property-list--level-${nestingLevel}`, className, {
                "property-list--expanded": isExpanded,
            })}
        >
            {properties.map((item, index) => (
                <Property
                    key={index}
                    {...item}
                    listContainsActiveError={containsActiveError}
                    parentTitle={parentTitle}
                    nestingLevel={nestingLevel}
                    onChange={onChange}
                />
            ))}
        </div>
    );
};
