import * as actionTypes from "../actionTypes";
import { getDashboardState } from "../../components/utils/dashboard";
import { omit } from "lodash";

const initialState = {
    activeDashboardId: {},
    activeWidgetConfiguration: {},
    activeDashboardTool: {},
    sidebarExpanded: {},
};

export const reducer = (state, action) => {
    state = state || getDashboardState() || initialState;

    switch (action.type) {
        case actionTypes.SET_ACTIVE_DASHBOARD:
            state = {
                ...state,
                activeDashboardId: {
                    ...state.activeDashboardId,
                    [action.entityNumber]: action.dashboardNumber,
                },
            };
            break;

        case actionTypes.SET_ACTIVE_TEMP_PANEL:
            if (action.panel) {
                const activeTempPanel = {
                    tempPanel: action.panel,
                    activeToolType: action.activeToolType,
                };
                state = {
                    ...state,
                    activeTempPanel: {
                        ...state.activeTempPanel,
                        [action.entityNumber]: activeTempPanel,
                    },
                };
            } else {
                const activeTempPanelRemoved = omit(state.activeTempPanel, action.entityNumber);
                state = {
                    ...state,
                    activeTempPanel: activeTempPanelRemoved,
                };
            }

            break;

        case actionTypes.SET_ACTIVE_DASHBOARD_TOOL:
            state = {
                ...state,
                activeDashboardTool: {
                    ...state.activeDashboardTool,
                    [action.entityNumber]: action.tool,
                },
            };
            break;

        case actionTypes.SET_AUTO_CATALOG_EDIT_OPEN:
            state = {
                ...state,
                catalogAutoEditOpen: {
                    catalogNumber: action.catalogNumber,
                    utilityNumber: action.utilityNumber,
                    programNumber: action.programNumber,
                    autoOpen: action.autoOpen,
                },
            };
            break;

        case actionTypes.SET_AUTO_ATTRIBUTE_EDIT_OPEN:
            state = {
                ...state,
                attributeAutoEditOpen: {
                    attributeNumber: action.attributeNumber,
                    attributeType: action.attributeType,
                    attributeValue: action.attributeValue,
                    utilityNumber: action.utilityNumber,
                    programNumber: action.programNumber,
                    autoOpen: action.autoOpen,
                },
            };
            break;

        case actionTypes.TOGGLE_WIDGET_CONFIGURATION:
            state = {
                ...state,
                activeWidgetConfiguration: {
                    ...state.activeWidgetConfiguration,
                    [action.entityNumber]: !state.activeWidgetConfiguration[action.entityNumber],
                },
            };
            break;

        case actionTypes.TOGGLE_DASHBOARD_SIDEBAR:
            state = {
                ...state,
                sidebarExpanded: {
                    ...state.sidebarExpanded,
                    [action.dashboardKey]: action.state,
                },
            };
            break;

        default:
            break;
    }

    return state;
};
