import React, { useContext } from "react";
import { MarginPropertyContext } from "components/ui/PortalBuilder/contexts";
import { MarginCustomPropertyName, MarginSideName, MarginSideProps, MarginSides, MarginSideBySideValue } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import TextLabel from "components/ui/Label/TextLabel";
import { MarginBox } from "./MarginBox";
import { PropertyListItem } from "../PropertyListItem";
import { MARGIN_SIZES } from "./AllSidesProperty";

export const SideBySideProperty = (props: SizeSideBySidePropertyProps) => {
    const { value, borderBottom, onChange } = props;
    const { customPropertiesValue, onlyTopAndBottom } = useContext(MarginPropertyContext);

    const onSelect = (id: PropertyName, marginSide: SideBySideMargin) => (size: string) => {
        if (size === value[marginSide]) {
            onChange(id, "0rem");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[MarginCustomPropertyName.MarginType] !== MarginSides.SideBySide) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--margin-side-by-side flex-column" borderBottom={borderBottom}>
            <MarginSide
                side={MarginSideName.Top}
                selectedValue={value[MarginSideName.Top]}
                onSelect={onSelect(PropertyName.MarginTop, MarginSideName.Top)}
            />
            {!onlyTopAndBottom && (
                <MarginSide
                    side={MarginSideName.Right}
                    selectedValue={value[MarginSideName.Right]}
                    onSelect={onSelect(PropertyName.MarginRight, MarginSideName.Right)}
                />
            )}
            <MarginSide
                side={MarginSideName.Bottom}
                selectedValue={value[MarginSideName.Bottom]}
                onSelect={onSelect(PropertyName.MarginBottom, MarginSideName.Bottom)}
            />
            {!onlyTopAndBottom && (
                <MarginSide
                    side={MarginSideName.Left}
                    selectedValue={value[MarginSideName.Left]}
                    onSelect={onSelect(PropertyName.MarginLeft, MarginSideName.Left)}
                />
            )}
        </PropertyListItem>
    );
};

const MarginSide = (props: MarginSideProps) => {
    const { side, selectedValue: selectedSize, onSelect } = props;

    return (
        <div className="margin-side flex-row fill-size align-center">
            <TextLabel>{side}</TextLabel>
            <div className="margin-boxes flex-one-in-row flex-row">
                {MARGIN_SIZES.map((size, index) => (
                    <MarginBox
                        text={size.text}
                        tooltip={size.tooltip}
                        key={index}
                        isSelected={selectedSize === size.value}
                        onClick={() => onSelect(size.value)}
                    />
                ))}
            </div>
        </div>
    );
};

interface SizeSideBySidePropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value: MarginSideBySideValue;
}

type SideBySideMargin = Exclude<MarginSideName, MarginSideName.AllSides>;
