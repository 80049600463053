import React from "react";
import { connect } from "react-redux";

import IconWrap from "../../Icons";
import CopyToClipboard from "../../Label/CopyToClipboard";

class WindowHeaderForUtility extends React.Component {
    render() {
        const { utility } = this.props;

        if (!utility) {
            return null;
        }

        return (
            <div className="window-header-content">
                <div className="project-mark">
                    <span>Utility</span>
                </div>
                <div className="project-number">
                    <CopyToClipboard title="Copy Utility Name to clipboard" successMessage="Utility Name copied to clipboard">
                        {utility.utility}
                    </CopyToClipboard>
                </div>
                {utility.isFavorite && <IconWrap iconWrap="bookmark-filled" title="Bookmarked" />}
            </div>
        );
    }
}

export default connect((state, ownProps) => {
    const { utilities } = state.resources;
    const { itemsById } = utilities;
    const utilityNumber = ownProps.view && ownProps.view.props && ownProps.view.props.utilityNumber;

    return {
        utilityNumber,
        utility: itemsById[utilityNumber],
    };
})(WindowHeaderForUtility);
