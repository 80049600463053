import React, { useState, useEffect, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { windowContainerTypes, processAuthenticatedRoutes } from "../../utils/window";
import { getResource } from "../../../store/resources/actions";
import { setSettingsTheme, getUserTheme } from "../../utils/settings";
import { isAuthenticated, isQrCode, isStatusExpired } from "../../utils/user";
import { windowContainerReset, windowAdd } from "../../../store/window/actions";
import { clientRoute } from "../../utils/constants";

import Windows from "../../ui/Windows";
import WaitIcon from "../../ui/WaitIcon";
import ViewPlaceholder from "components/ui/ViewPlaceholder";

const WindowManager = memo(() => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userThemes = useSelector((state) => state?.resources?.userThemes?.itemsById[user?.userNumber]);

    const [isLoading, setIsLoading] = useState(false);

    const isUserAuthenticated = isAuthenticated(user);
    const isUserQrCode = isQrCode(user);
    const isExpired = isStatusExpired(user);
    const isResetPassword = window.location.pathname.split("/")[1] === clientRoute.resetPassword;
    const isSaml = window.location.pathname.split("/")[1] === clientRoute.saml;
    const containerName = isUserAuthenticated ? windowContainerTypes.Root : windowContainerTypes.Login;

    const views = useMemo(
        () =>
            isUserAuthenticated
                ? [
                      {
                          containerName,
                          name: "Home",
                          component: "HomeView",
                          close: false,
                          showHeader: false,
                      },
                  ]
                : [
                      {
                          containerName,
                          name: "Login",
                          component: "Login",
                          showTab: false,
                          showHeader: false,
                          activate: true,
                          persist: false,
                      },
                  ],
        [isUserAuthenticated, containerName]
    );

    useEffect(() => {
        if (!isUserAuthenticated) {
            setSettingsTheme(getUserTheme({ isUserAuthenticated }));
        }
    }, [isUserAuthenticated]);

    useEffect(() => {
        if (!isUserAuthenticated && !isUserQrCode && !isExpired && !isResetPassword && !isSaml) {
            dispatch(windowContainerReset(containerName));
            dispatch(windowAdd(views[0]));
        }
    }, [containerName, isUserAuthenticated, isUserQrCode, isExpired, isResetPassword, isSaml, views, dispatch]);

    useEffect(() => {
        if (isUserAuthenticated && !isLoading && (!userThemes || userThemes.length === 0)) {
            setIsLoading(true);

            dispatch(
                getResource({
                    resourceName: "userThemes",
                    key: user.userNumber,
                    transform: (data) => {
                        return data.themes.map((i) => i.theme);
                    },
                    onComplete: () => {
                        setIsLoading(false);
                        setSettingsTheme(getUserTheme({ isUserAuthenticated }));
                    },
                })
            );
        }
    }, [user, isUserAuthenticated, isLoading, userThemes, dispatch]);

    useEffect(() => {
        if (isUserAuthenticated) {
            processAuthenticatedRoutes();
        }
    }, [isUserAuthenticated]);

    // Ensure that the reset password view is opened.
    useEffect(() => {
        if (isResetPassword) {
            dispatch(windowContainerReset(windowContainerTypes.Login));
            dispatch(
                windowAdd({
                    containerName: windowContainerTypes.Login,
                    name: "Reset Password",
                    component: "ResetPassword",
                    showTab: false,
                    showHeader: false,
                    activate: true,
                    persist: false,
                })
            );
        }
    }, [isResetPassword, dispatch]);

    if (isLoading) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return <Windows containerName={containerName} initialViews={views} persist={isUserAuthenticated} />;
});

export default WindowManager;
