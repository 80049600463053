import React from "react";
import IconWrap from "components/ui/Icons";
import TextLabel from "components/ui/Label/TextLabel";

export const GlobalWidgetWarning = () => {
    return (
        <div className="global-widget-warning flex-row">
            <IconWrap icon="warning-report-problem-filled" />
            <TextLabel className="flex-one-in-row">All changes done here will affect all places where the widget is in use.</TextLabel>
        </div>
    );
};
