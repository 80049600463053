import React from "react";
import { orderBy } from "lodash";

import { formatJsonDateTime } from "components/utils/date";
import { toArray, distinct } from "components/utils/array";
import {
    getAttributeSchema,
    getValidationTypeName,
    getAttributeUiSchema,
    getNormalizedAttributeValue,
    AttributeFieldType,
} from "components/utils/attributes";
import { store } from "store/configureStore";
import { modalOpen } from "store/modal/actions";
import { deleteResource, getResource } from "store/resources/actions";
import { getData } from "store/dataGrid/actions";

export const getDefaultSchema = (event = {}, validationTypes, trueFalseTypes, canEditAllEvents) => {
    const schema = {
        type: "object",
        properties: {
            attributes: {
                type: "object",
                properties: {
                    startDate: {
                        type: "string",
                        title: "Event Start Date",
                    },
                    endDate: {
                        type: "string",
                        title: "Event End Date",
                    },
                    duration: {
                        type: "number",
                        title: "Event Duration",
                    },
                },
            },
        },
    };

    const attributes = toArray(event.attributes?.attributes ?? []).filter((a) => canEditAllEvents || a.showAll === "Y");

    const attributesSchema = attributes.reduce((result, attribute) => {
        const attributeName = attribute.event_attr_name;
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const lookupValues = toArray(attribute.lookupValues?.lookupValue ?? []);
        const lowerLimit = attribute.lowerLimit;
        const upperLimit = attribute.upperLimit;
        const fieldType = attribute.fieldtype;

        const attributeSchema = getAttributeSchema({
            attributeName,
            validationTypeName,
            lookupValues,
            lowerLimit,
            upperLimit,
            fieldType,
        });

        return {
            ...result,
            ...attributeSchema,
        };
    }, {});

    const isRequiredId = trueFalseTypes.filter((t) => t.display.toLowerCase() === "true").map((t) => Number(t.val))[0];

    const attributesRequired = attributes
        .filter((attribute) => Number(attribute.validationReq) === isRequiredId)
        .map((attribute) => attribute.event_attr_name)
        .filter(distinct);

    schema.properties.attributes = {
        ...schema.properties.attributes,
        required: ["startDate"].concat(attributesRequired),
        properties: {
            ...schema.properties.attributes.properties,
            ...attributesSchema,
        },
    };

    return schema;
};

export const getDefaultUiSchema = (event = {}, validationTypes, canEditAllEvents) => {
    const uiSchema = {
        "ui:rootFieldId": "event",
        attributes: {
            startDate: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                    time: true,
                    localize: false,
                },
            },
            endDate: {
                "ui:widget": "date",
                "ui:options": {
                    overlap: true,
                    time: true,
                    localize: false,
                },
            },
        },
    };

    const attributes = toArray(event.attributes?.attributes ?? []).filter((a) => canEditAllEvents || a.showAll === "Y");

    const attributesUiSchema = attributes.reduce((result, attribute) => {
        const attributeName = attribute.event_attr_name;
        const validationTypeName = getValidationTypeName(attribute, validationTypes);
        const lookupValues = toArray(attribute.lookupValues?.lookupValue ?? []);
        const editAll = attribute.editAll;
        const showAll = attribute.showAll;
        const fieldType = attribute.fieldtype;

        const attributeUiSchema = getAttributeUiSchema({
            attributeName,
            validationTypeName,
            lookupValues,
            editAll,
            showAll,
            fieldType,
        });

        return {
            ...result,
            ...attributeUiSchema,
        };
    }, {});

    const attributesOrder = orderBy(attributes, [(item) => Number(item.itemOrder)])
        .map((attribute) => attribute.event_attr_name)
        .filter(distinct);

    uiSchema.attributes = {
        ...uiSchema.attributes,
        ...attributesUiSchema,
        "ui:order": ["*", ...attributesOrder],
    };

    return uiSchema;
};

export const getInitialValues = (event, validationTypes, schema, isNewEvent = false, canEditAllEvents) => {
    if (!event) {
        return {};
    }

    const attributes = toArray(event.attributes?.attributes ?? []).filter((a) => canEditAllEvents || a.showAll === "Y");

    const attributeValues = attributes.reduce((result, attribute) => {
        const attributeName = attribute.event_attr_name;
        const validationTypeName = getValidationTypeName(attribute, validationTypes);

        const isNumericTypeMismatch = schema?.properties?.attributes?.properties?.[attributeName]?.isNumericTypeMismatch ?? false;
        const isTextArea = attribute.fieldtype === AttributeFieldType.TEXTAREA;

        const value = getNormalizedAttributeValue({
            value: attribute.event_Attr_Value_Str,
            validationTypeName,
            // Set isNumericTypeMismatch if textarea should be shown. This will prevent parsing value as a number and will show as is.
            isNumericTypeMismatch: isNumericTypeMismatch || isTextArea,
        });

        const defaultValue = getNormalizedAttributeValue({
            value: attribute.defaultValue,
            validationTypeName,
        });

        let attributeValue = value;

        // Use default value only when adding an event
        if (isNewEvent) {
            attributeValue = value ?? defaultValue;
        }

        return {
            ...result,
            [attributeName]: attributeValue,
        };
    }, {});

    return {
        header: {
            categoryName: event.categoryName,
            parentCategoryName: event.parentCategoryName,
            programEventName: event.programEventName,
            dateEntered: formatJsonDateTime(event.dateEntered),
        },
        attributes: {
            startDate: event.startDate,
            endDate: event.endDate,
            duration: event.duration ? +event.duration : undefined,
            ...attributeValues,
        },
    };
};

export const onDeleteEvent = ({ applicationNumber, eventNumber, eventName, onConfirm, onSuccess }) => {
    const message = (
        <p>
            Are you sure you would like to delete Event <b>{eventName}</b>?
        </p>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Event",
                overlayClassName: "modal-styled",
                className: "delete-event-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: message,
                footerContentCenter: true,
                onConfirm: () => {
                    onConfirm && onConfirm();

                    store.dispatch(
                        deleteResource({
                            resourceName: "event",
                            resourceId: eventNumber,
                            path: {
                                appId: applicationNumber,
                            },
                            onSuccess: () => {
                                onSuccess && onSuccess();
                                store.dispatch(
                                    getData({
                                        dataGridId: `${applicationNumber}-events-grid`,
                                    })
                                );
                                refreshSidebarEvents({ applicationNumber });
                            },
                        })
                    );
                },
            },
        })
    );
};

export const refreshSidebarEvents = ({ applicationNumber }) => {
    store.dispatch(
        getResource({
            resourceName: "applicationEventsWidget",
            key: applicationNumber,
            path: {
                appId: applicationNumber,
            },
        })
    );
};
