import cn from "classnames";
import FieldGroupDropdownIcon from "components/ui/FieldGroupDropdownIcon";
import Checkbox from "components/ui/Input/Checkbox";
import React, { memo, useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { ScrollSyncPane } from "react-scroll-sync";
import { modalOpen } from "store/modal/actions";
import { DEFAULT_EXPANDED_STATE, HighlightingContext } from "./RolesManagementBody";
import RoleUsers from "./RoleUsers";
import { ClientProgramsUtility, EntityRolesState, ExpandedState, RoleActionType, RolesState, RoleState, UserRole } from "./types";
import { updateEntityRolesState } from "./utils";
import { isNil } from "lodash";

export const RolesColumnBody: React.FC<{
    roles: UserRole[];
    clientPrograms: ClientProgramsUtility[];
    expandedState: ExpandedState;
    entityRolesState: EntityRolesState;
    onEntityRolesStateChange: (entityRolesState: EntityRolesState) => void;
}> = ({ roles, clientPrograms, expandedState, entityRolesState, onEntityRolesStateChange }) => {
    return (
        <ScrollSyncPane group={["roles-list", "entities-list"]}>
            <div className="roles-management-roles-column-body with-scroll">
                {clientPrograms.map((utility, index) => (
                    <UtilityRoles
                        key={index}
                        utility={utility}
                        roles={roles}
                        isExpanded={expandedState[utility.utilityNumber] ?? DEFAULT_EXPANDED_STATE}
                        entityRolesState={entityRolesState}
                        onEntityRolesStateChange={onEntityRolesStateChange}
                    />
                ))}
            </div>
        </ScrollSyncPane>
    );
};

const UtilityRoles: React.FC<{
    utility: ClientProgramsUtility;
    roles: UserRole[];
    isExpanded: boolean;
    entityRolesState: EntityRolesState;
    onEntityRolesStateChange: (entityRolesState: EntityRolesState) => void;
}> = memo(({ utility, roles, isExpanded, entityRolesState, onEntityRolesStateChange }) => {
    const highlightingContext = useContext(HighlightingContext);

    const onRoleChange = useCallback(
        (roleID: number, entityNumber: string) => {
            onEntityRolesStateChange(updateEntityRolesState(roleID, entityNumber, entityRolesState, utility));
        },
        [entityRolesState, onEntityRolesStateChange, utility]
    );

    return (
        <>
            <RolesRow
                roles={roles}
                entityNumber={utility.utilityNumber}
                entityName={utility.utilityName}
                isUtilityRow
                isExpanded={isExpanded}
                isHighlighted={highlightingContext.entityNumber === utility.utilityNumber}
                rolesState={entityRolesState[utility.utilityNumber]?.rolesState}
                onRoleChange={onRoleChange}
                onHighlight={highlightingContext.onHighlight}
            />
            {utility.filteredProgramList.map((program) => (
                <RolesRow
                    key={program.programNumber}
                    roles={roles}
                    entityNumber={program.programNumber}
                    entityName={program.programName}
                    isExpanded={isExpanded}
                    isHighlighted={highlightingContext.entityNumber === program.programNumber}
                    rolesState={entityRolesState[program.programNumber]?.rolesState}
                    onRoleChange={onRoleChange}
                    onHighlight={highlightingContext.onHighlight}
                />
            ))}
        </>
    );
});

const RolesRow: React.FC<{
    roles: UserRole[];
    entityNumber: string;
    entityName: string;
    isExpanded: boolean;
    isHighlighted?: boolean;
    rolesState?: RolesState;
    isUtilityRow?: boolean;
    onRoleChange: (roleID: number, entityNumber: string) => void;
    onHighlight?: (entityNumber: string) => void;
}> = memo(({ roles, entityNumber, entityName, isUtilityRow, isExpanded, isHighlighted, rolesState, onRoleChange, onHighlight }) => {
    const hidden = !isUtilityRow && !isExpanded;

    const isChecked = (roleID: number) => !isNil(rolesState) && [RoleState.On, RoleState.Indeterminate].includes(rolesState[roleID]);
    const isIndeterminate = (roleID: number) => rolesState?.[roleID] === RoleState.Indeterminate;
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState<{
        [roleID: number]: boolean;
    }>({});

    const getRoleCheckboxTitle = (role: UserRole) => {
        if (isIndeterminate(role.roleID)) {
            return "Multiple Roles - Expand to View";
        }

        if (!isChecked(role.roleID)) {
            if (isUtilityRow) {
                return `Select Role for All ${entityName} Programs`;
            }

            return `Select Role`;
        }

        if (isUtilityRow) {
            return `All Programs: ${role.roleName} Role`;
        }

        return `${role.roleName} Selected`;
    };

    const onClick = (roleID: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        // This is to prevent the checkbox from being unchecked when clicked.
        if (isChecked(roleID)) {
            return;
        }

        onRoleChange(roleID, entityNumber);
    };

    const onAction = ({ actionType, role }: { actionType: RoleActionType; role: UserRole }) => {
        // Close menu
        setIsActive((prev) => ({ ...prev, [role.roleID]: false }));

        if (actionType === RoleActionType.ShowUsers) {
            dispatch(
                modalOpen({
                    modalType: "MODAL",
                    modalProps: {
                        title: (
                            <>
                                {`${role.roleName} role users for ${isUtilityRow ? "utility" : "program"}`}
                                <br />
                                {entityName}
                            </>
                        ),
                        overlayClassName: "modal-styled",
                        className: "modal-list role-modal modal-sm",
                        modalIcon: "theaters-empty",
                        children: <RoleUsers role={role} entityNumber={entityNumber} entityTypeId={isUtilityRow ? 2 : 3} />,
                    },
                })
            );
        }
    };

    const actionItems = [
        {
            label: "Show users",
            actionType: RoleActionType.ShowUsers,
        },
    ];

    if (hidden) {
        return null;
    }

    return (
        <div
            className={cn("roles-management-roles-row flex-row no-shrink", {
                "roles-management-roles-row--utility": isUtilityRow,
                "roles-management-roles-row--highlighted": isHighlighted,
            })}
            hidden={hidden}
            onMouseOverCapture={() => onHighlight?.(entityNumber)}
        >
            {roles.map((role, index) => (
                <div
                    key={index}
                    className={cn("roles-management-role-cell flex-one", {
                        "roles-management-role-cell--menu-active": isActive[role.roleID],
                    })}
                >
                    <FieldGroupDropdownIcon
                        //@ts-ignore
                        title={undefined}
                        className="role-actions-dropdown"
                        dropdownOptions
                        iconWrapDropdown
                        mobileHeader="Role actions"
                        visible={isActive[role.roleID]}
                        iconWrapActive={isActive[role.roleID]}
                        iconWrap="more-vertical"
                        onClick={() => setIsActive((prev) => ({ ...prev, [role.roleID]: !prev[role.roleID] }))}
                        onSelect={({ actionType }: { actionType: RoleActionType }) => onAction({ actionType, role })}
                        items={actionItems}
                        withPopper={isActive[role.roleID]}
                        popupPosition="bottom-start"
                    />
                    <Checkbox
                        labelIconBig
                        iconLabelEmpty
                        checked={isChecked(role.roleID)}
                        checkedPartly={isIndeterminate(role.roleID)}
                        id={undefined}
                        className={undefined}
                        label={undefined}
                        onChange={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(role.roleID, event)}
                        disabled={undefined}
                        title={getRoleCheckboxTitle(role)}
                        labelStyle={undefined}
                    />
                </div>
            ))}
        </div>
    );
});
