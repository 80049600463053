import React from "react";
import cn from "classnames";
import { useDelayedShow } from "components/utils/useDelayedShow";
import StatusMsg from "../StatusMsg";
import { MessageProps } from "./index";

const WarningMessage = ({ spaceAround, inline, msgCentered, marginTop, msgSquare, delay, children }: MessageProps) => {
    const show = useDelayedShow({ delay });

    if (!show) {
        return null;
    }

    return (
        <StatusMsg
            className={cn("warning-message", {
                "warning-message--margin": spaceAround,
                "warning-message--inline": inline,
                "warning-message--margin-top": marginTop,
            })}
            msgVisible
            msgFieldStatus
            msgWarning
            msgText={children}
            msgCentered={msgCentered}
            msgSquare={msgSquare}
        />
    );
};

export default WarningMessage;
