import React from "react";

import { modalOpen, modalClose } from "../../../../../../store/modal/actions";
import { updateResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { uploadCSV, lineToObject } from "../../../../../utils/CSV";
import { programsCatalogGridColumnKeys, programsEventsGridColumnKeys } from "../../../../../views/configureGrids";

import AddAssociationsForm from "./AddAssociationsForm";
import CatalogItemGridControls from "./CatalogItemGridControls";
import UploadedItemsGrid from "./UploadedItemsGrid";
import EventItemGridControls from "./EventItemGridControls";
import { CatalogItemsGrid, EventItemsGrid } from "./ItemsGrid";

export const onAddCatalogAssignments = ({ dataGridId, utilityNumber, calculationNumbers, calculationNames, dispatch }) => {
    const close = () => {
        dispatch(modalClose());
    };

    const submit = (catalogNumbersToAdd) => {
        dispatch(
            updateResource({
                resourceName: "calculationAssignments",
                query: {
                    actionItem: "ADD",
                    scope: "CALCULATION",
                },
                body: {
                    calculationNumber: calculationNumbers,
                    catalogNumber: catalogNumbersToAdd,
                },
                onComplete: () => {
                    close();
                    dispatch(getData({ dataGridId }));
                },
            })
        );
    };

    dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Add Catalog Items to the Calculation Association",
                overlayClassName: "modal-styled",
                className: "calculations-modal add-catalog-items-to-the-calc-associations modal-with-grid modal-md",
                modalIcon: "plus",
                children: (
                    <AddAssociationsForm
                        calculationNames={calculationNames}
                        utilityNumber={utilityNumber}
                        dataGridId={`${utilityNumber}-catalog-items-grid`}
                        itemNumberKey={programsCatalogGridColumnKeys.catalogNumber}
                        utilityNumberKey={programsCatalogGridColumnKeys.utilityNumber}
                        GridControls={CatalogItemGridControls}
                        UploadedItemsGrid={UploadedItemsGrid}
                        ItemsGrid={CatalogItemsGrid}
                        onSubmit={submit}
                        onCancel={close}
                    />
                ),
                noFooter: true,
                withScroll: true,
            },
        })
    );
};

export const onRemoveCatalogAssignments = ({ dataGridId, calculationNumbers, catalogs, onRowSelectClear, dispatch }) => {
    const dialogMessage = (
        <>
            <p>
                {catalogs.length > 1 ? (
                    <>
                        Are you sure you want to remove <b>{catalogs.length}</b> catalog associations:
                    </>
                ) : (
                    <>Are you sure you want to remove catalog association:</>
                )}
            </p>
            <ul>
                {catalogs.map((item, index) => (
                    <li key={index}>{item.catalogName}</li>
                ))}
            </ul>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Remove Calculation Catalog Associations",
                overlayClassName: "modal-styled",
                className: "calculations-modal remove-calculation-associations-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: dialogMessage,
                okButtonText: catalogs.length > 1 ? "Remove Associations" : "Remove Association",
                footerContentCenter: true,
                cancelButtonText: "Cancel",
                onConfirm: () => {
                    dispatch(
                        updateResource({
                            resourceName: "calculationAssignments",
                            query: {
                                actionItem: "REMOVE",
                                scope: "CALCULATION",
                            },
                            body: {
                                calculationNumber: calculationNumbers,
                                catalogNumber: catalogs.map((i) => i.catalogNumber),
                            },
                            onComplete: () => {
                                onRowSelectClear();
                                dispatch(getData({ dataGridId }));
                            },
                        })
                    );

                    dispatch(modalClose());
                },
            },
        })
    );
};

export const onAddEventAssignments = ({ dataGridId, utilityNumber, calculationNumbers, dispatch }) => {
    const close = () => {
        dispatch(modalClose());
    };

    const submit = (eventNumbersToAdd) => {
        dispatch(
            updateResource({
                resourceName: "calculationEventAssignments",
                query: {
                    actionItem: "ADD",
                    scope: "CALCULATION",
                },
                body: {
                    calculationNumber: calculationNumbers,
                    eventNumber: eventNumbersToAdd,
                },
                onComplete: () => {
                    close();
                    dispatch(getData({ dataGridId }));
                },
            })
        );
    };

    dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Add Event Items to the Calculation Association",
                overlayClassName: "modal-styled",
                className: "calculations-modal add-event-items-to-the-calc-associations modal-with-grid modal-md",
                modalIcon: "plus",
                children: (
                    <AddAssociationsForm
                        utilityNumber={utilityNumber}
                        dataGridId={`${utilityNumber}-event-items-grid`}
                        itemNumberKey={programsEventsGridColumnKeys.eventNumber}
                        utilityNumberKey={programsEventsGridColumnKeys.utilityNumber}
                        GridControls={EventItemGridControls}
                        UploadedItemsGrid={UploadedItemsGrid}
                        ItemsGrid={EventItemsGrid}
                        onSubmit={submit}
                        onCancel={close}
                    />
                ),
                noFooter: true,
                withScroll: true,
            },
        })
    );
};

export const onRemoveEventAssignments = ({ dataGridId, calculationNumbers, eventNumbers, onRowSelectClear, dispatch }) => {
    const dialogMessage = (
        <p>
            {eventNumbers.length} association
            {eventNumbers.length > 1 ? "s" : ""} with event calculations will be removed.
        </p>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Remove Calculation Event Associations",
                overlayClassName: "modal-styled",
                className: "calculations-modal remove-calculation-associations-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: dialogMessage,
                footerContentCenter: true,
                okButtonText: eventNumbers.length > 1 ? "Remove Associations" : "Remove Association",
                cancelButtonText: "Cancel",
                onConfirm: () => {
                    dispatch(
                        updateResource({
                            resourceName: "calculationEventAssignments",
                            query: {
                                actionItem: "REMOVE",
                                scope: "CALCULATION",
                            },
                            body: {
                                calculationNumber: calculationNumbers,
                                eventNumber: eventNumbers,
                            },
                            onComplete: () => {
                                onRowSelectClear();
                                dispatch(getData({ dataGridId }));
                            },
                        })
                    );

                    dispatch(modalClose());
                },
            },
        })
    );
};

export const uploadItemsForAssociation = () => {
    return uploadCSV({ delimiter: ",", header: true }).then((result) => {
        return result.lines
            .map((line) => lineToObject({ header: result.header, line }))
            .map((item) => ({
                ...item,
                _selected: true,
            }));
    });
};
