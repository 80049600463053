import React from "react";
import { useSelector } from "react-redux";
import { splitWindowPositionType } from "components/utils/window";
import SideNav from ".";

export const sideNavKey = {
    globalLeft: "global-left",
    globalRight: "global-right",
};

export const sideNavMode = {
    over: "over", // show over the main content
    push: "push", // push the main content aside
};

export const sideNavPosition = {
    left: "left",
    right: "right",
};

export const sideNavSize = {
    small: "small",
    medium: "medium",
    staticMedium: "static-medium",
    large: "large",
    staticLarge: "static-large",
    staticXXL: "static-xxl",
};

export const getDashboardSideNavKey = ({ dashboardKey, position }) => {
    return `${dashboardKey}-${position}`;
};

export const getViewSideNavKey = ({ viewName, position, splitWindowPosition }) => {
    return splitWindowPosition ? `${viewName}-${position}-${splitWindowPosition}` : `${viewName}-${position}`;
};

export const SideNavRoot = ({ id, onConfirmClose }) => {
    const props = useSelector((state) => state.sideNav[id]);

    if (!id || !props) {
        return null;
    }

    const position =
        props.position ||
        (id.replace(splitWindowPositionType.left, "").replace(splitWindowPositionType.right, "").indexOf("left") > -1 ? "left" : "right");

    return <SideNav id={id} position={position} onConfirmClose={onConfirmClose} {...props} />;
};

export default SideNavRoot;
