import { useResource } from "store/resources/useResource";
import useProgramFormNumber from "./useProgramFormNumber";

const useFormPageSections = ({ entityId, pageNumber }) => {
    const [formNumber, isLoadingForm] = useProgramFormNumber({
        entityId: pageNumber ? entityId : undefined,
    });

    const [sections = [], isLoadingSections] = useResource({
        resourceName: "programFormPages",
        key: formNumber ? pageNumber : undefined,
        resourceId: formNumber ? pageNumber : undefined,
        path: {
            programNumber: entityId,
            formNumber,
        },
        transform: (data) => {
            const uiSchema = data?.configuration?.uiSchema;
            const properties = data?.configuration?.schema?.properties;
            return Object.keys(uiSchema ?? {})
                .filter((k) => uiSchema[k]["af:sectionNumber"])
                .map((k) => ({ name: properties?.[k]?.title, value: uiSchema?.[k]?.["af:sectionNumber"] }));
        },
    });

    return [sections, isLoadingForm || isLoadingSections];
};

export default useFormPageSections;
