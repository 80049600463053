import SelectBox from "components/ui/SelectBox";
import React from "react";
import { BorderSideName } from "./types";

export const BorderBox = ({ width, side = BorderSideName.AllSides, isSelected, onClick }: BorderBoxProps) => {
    return (
        <SelectBox className="border-box-container flex-one-in-row" selectBoxSelected={isSelected} onClick={onClick}>
            <div className={`border-box border-box--width-${width} border-box--${side}`} />
        </SelectBox>
    );
};

interface BorderBoxProps {
    width: number;
    side: BorderSideName;
    isSelected: boolean;
    onClick?: () => void;
}
