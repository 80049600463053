import { SIDENAV_OPEN, SIDENAV_CLOSE, SIDENAV_CHANGE, SIDENAV_SET_CONFIRM, SIDENAV_SET_FORM } from "../actionTypes";
import { sideNavKey } from "../../components/ui/SideNav/SideNavRoot";
import { modalOpen } from "../modal/actions";
import { customConfirmModalCheck, isSideNavFormDataChanged } from "./utils";

// Use this if need specific styles while sliding
// TODO : Listen to css transitionEnd event instead of using a constant
// const DEFAULT_DELAY = 500;

export const sideNavOpen =
    ({ id, props }) =>
    (dispatch, getState) => {
        const state = getState();
        const isMobile = state.window.isMobile;

        // Switch to global sidenav on mobile devices
        const sideNavId = isMobile ? (id.indexOf("left") > -1 ? sideNavKey.globalLeft : sideNavKey.globalRight) : id;

        const openAction = {
            type: SIDENAV_OPEN,
            id: sideNavId,
            props,
        };

        dispatch(openAction);

        // Use this if need specific styles while sliding
        // if(!sideNav[sideNavId] && props.mode !== sideNavMode.push) {

        //     dispatch({
        //         type: SIDENAV_SET_OPENING,
        //         id: sideNavId
        //     });

        //     setTimeout(() => {
        //         dispatch({
        //             type: SIDENAV_RESET_OPENING,
        //             id: sideNavId
        //         });
        //     }, DEFAULT_DELAY);
        // }
    };

export const setSideNavForm =
    ({ id, form }) =>
    (dispatch, getState) => {
        const state = getState();
        const isMobile = state.window.isMobile;

        // Switch to global sidenav on mobile devices
        const sideNavId = isMobile ? (id.indexOf("left") > -1 ? sideNavKey.globalLeft : sideNavKey.globalRight) : id;

        const setFormAction = {
            type: SIDENAV_SET_FORM,
            id: sideNavId,
            form: form,
        };

        dispatch(setFormAction);
    };

const shouldShowConfirmModal = (sideNavState) => {
    const key = sideNavState.confirmModalCheck;
    const fn = customConfirmModalCheck[key] ?? isSideNavFormDataChanged;
    return fn(sideNavState);
};

export const sideNavClose =
    ({ id, backdropClick }) =>
    (dispatch, getState) => {
        const state = getState();

        const sideNav = state.sideNav;
        const isMobile = state.window.isMobile;

        // Switch to global sidenav on mobile devices
        const sideNavId = isMobile ? (id.indexOf("left") > -1 ? sideNavKey.globalLeft : sideNavKey.globalRight) : id;

        // Do nothing if sideNav not found
        const sideNavState = sideNav[sideNavId];
        if (!sideNavState) {
            return;
        }

        const close = () => {
            sideNavState.onClose && sideNavState.onClose();
            dispatch({
                type: SIDENAV_CLOSE,
                id: sideNavId,
            });
        };

        // Checks whether form data changed or not and if so show confirm modal
        const { confirmMessage } = sideNavState;
        if (shouldShowConfirmModal(sideNavState) && backdropClick) {
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Discarding changes",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        footerContentCenter: true,
                        content: confirmMessage ?? "The side panel might have unsaved changes, do you want to discard changes?",
                        onConfirm: close,
                    },
                })
            );
        } else {
            close();
        }

        // Use this if need specific styles while sliding
        // if(sideNav[sideNavId].mode !== sideNavMode.push) {

        //     dispatch({
        //         type: SIDENAV_SET_CLOSING,
        //         id: sideNavId
        //     });

        //     setTimeout(() => {
        //         dispatch(closeAction);
        //     }, DEFAULT_DELAY);
        // }
        // else {
        //     dispatch(closeAction);
        // }
    };

/**
 * Set sideNav confirm message to show before sidenav is closed.
 * If id is not provided confirm message will be set for all sidenavs.
 *
 * @param {*} { id, confirmMessage }
 */
export const setSideNavConfirmMessage =
    ({ id, confirmMessage }) =>
    (dispatch, getState) => {
        const action = {
            type: SIDENAV_SET_CONFIRM,
            id,
            confirmMessage,
        };

        if (id) {
            dispatch(action);
        } else {
            const sideNav = getState().sideNav ?? {};

            Object.keys(sideNav).forEach((key) => {
                dispatch({
                    ...action,
                    id: key,
                });
            });
        }
    };

export const sideNavChange =
    ({ id, size, className }) =>
    (dispatch, getState) => {
        const state = getState();
        const isMobile = state.window.isMobile;

        // Switch to global sidenav on mobile devices
        const sideNavId = isMobile ? (id.indexOf("left") > -1 ? sideNavKey.globalLeft : sideNavKey.globalRight) : id;

        const action = {
            type: SIDENAV_CHANGE,
            id: sideNavId,
            size,
            className,
        };

        dispatch(action);
    };
