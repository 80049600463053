import React, { useEffect, useState, memo, useMemo, useCallback, useId } from "react";
import { orderBy, isNil, sum, uniqBy } from "lodash";
import { getResourcePromise, useProgramRights } from "../../../../../../store/resources/useResource";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import IconWrap from "../../../../Icons";
import Checkbox from "../../../../Input/Checkbox";
import ScrollControls from "../../../../ScrollControls";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { ScrollSyncPane, ScrollSync } from "react-scroll-sync";
import { toArray } from "components/utils/array";
import { formatAttributeName, getAttributeTitle, isValidationTypeNumeric } from "components/utils/attributes";
import { ProgramRights } from "components/utils/user";
import WaitIcon from "components/ui/WaitIcon";
import CounterBox from "components/ui/CounterBox";
import Separator from "components/ui/Separator";
import ErrorMessage from "components/ui/Message/ErrorMessage";
import classNames from "classnames";

import "./CompareEquipmentPanel.scss";

const CompareEquipmentPanel = memo(({ items, applicationNumber, programNumber, onClose, onRemoveEquipment }) => {
    const scrollId = useId();

    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [isLoadingAttributes, setIsLoadingAttributes] = useState(true);
    const [isLoadingError, setIsLoadingError] = useState(false);

    const [equipmentIds, setEquipmentIds] = useState(items);
    const [showAttribute, setShowAttribute] = useState(true);
    const [programRights = []] = useProgramRights({ programNumber });

    const attributeIcon = showAttribute ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty";
    const showAttributeText = showAttribute ? "Hide Attributes" : "Show Attributes";

    const title = "Compare Equipment";
    const leadBlockIcon = "widgets-empty";

    const hasRightsToEditAll = programRights.includes(ProgramRights.VISIONDSM_EDIT_ALL_EQUIPMENT);

    const attributeNames = useMemo(() => getAttributeNames(equipments, hasRightsToEditAll), [equipments, hasRightsToEditAll]);

    const selectAllState = useMemo(() => {
        const itemCount = attributeNames?.length ?? 0;
        const selectedItemCount = selectedAttributes?.length ?? 0;

        let state = false;
        if (selectedItemCount > 0) {
            state = true;

            if (selectedItemCount !== itemCount) {
                state = null;
            }
        }

        return state;
    }, [attributeNames, selectedAttributes]);

    const updateAttributes = useCallback(
        (equipmentIds) => {
            const promises = equipmentIds?.map((equipmentId) => {
                return getResourcePromise({
                    resourceName: "equipment",
                    resourceId: equipmentId,
                    path: {
                        appId: applicationNumber,
                    },
                });
            });

            Promise.all(promises)
                .then((result) => {
                    const equipmentList = result.map((r) => r.equipment);

                    const attributeNames = getAttributeNames(equipmentList, hasRightsToEditAll);
                    const totals = {
                        name: "TOTAL",
                        attributes: attributeNames.map((a) => ({
                            attributeName: a.attributeName,
                            value: undefined,
                        })),
                    };

                    const selectedAttributesList = attributeNames.filter(({ attributeName }) =>
                        equipmentList.some((equipment) => {
                            const value = getAttributeValue(equipment, attributeName);
                            return value !== null && value !== undefined && value !== "";
                        })
                    );

                    setEquipments([totals].concat(equipmentList));
                    setSelectedAttributes(selectedAttributesList);
                    setIsLoadingAttributes(false);
                })
                .catch((error) => {
                    setIsLoadingAttributes(false);
                    setIsLoadingError(true);
                });
        },
        [applicationNumber, hasRightsToEditAll]
    );

    useEffect(() => {
        updateAttributes(equipmentIds);
    }, [updateAttributes, equipmentIds]);

    const isAttributeSelected = (attribute) => selectedAttributes.filter((a) => a.name === attribute.name).length > 0;

    const onAttributesPanelToggle = () => {
        setShowAttribute(!showAttribute);
    };

    const onAttributeToggle = (attribute) => {
        const isSelected = isAttributeSelected(attribute);

        isSelected
            ? setSelectedAttributes(selectedAttributes.filter((a) => a.name !== attribute.name))
            : setSelectedAttributes(selectedAttributes.concat([attribute]));
    };

    const onSelectAllClick = useCallback(
        (event) => {
            event?.stopPropagation();

            selectAllState ? setSelectedAttributes([]) : setSelectedAttributes([...attributeNames]);
        },
        [attributeNames, selectAllState]
    );

    const removeEquipment = useCallback(
        (index, equipment) => {
            if (index > -1) {
                const updatedEquipmentIds = equipmentIds.filter((equipmentId) => equipmentId !== equipment.equipid);
                setEquipmentIds(updatedEquipmentIds);
                onRemoveEquipment(equipment);
                updateAttributes(updatedEquipmentIds);
            }
        },
        [equipmentIds, updateAttributes, onRemoveEquipment]
    );

    return (
        <SideNavContent className="compare-equipment-form">
            <SideNavHeader title={title} leadBlockIcon={leadBlockIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column" noPadding>
                <div className="compare-equipment-form flex-row no-scroll fill-height">
                    <div className="controls-expander flex-column justify-center" onClick={onAttributesPanelToggle}>
                        <IconWithLabel icon={attributeIcon}>
                            {showAttributeText}
                            <span>
                                (selected <b>{selectedAttributes.length}</b> from <b>{attributeNames.length}</b>)
                            </span>
                        </IconWithLabel>
                    </div>
                    <div className={"compare-controls-list with-scroll" + (showAttribute ? " expanded" : " collapsed")}>
                        {isLoadingAttributes && <WaitIcon />}
                        {!isLoadingAttributes && isLoadingError && <ErrorMessage>Equipment not found</ErrorMessage>}
                        {!isLoadingAttributes && !isLoadingError && (
                            <>
                                <div className="attribute-checkboxes--header">
                                    <Checkbox label="Select All" checked={selectAllState} onChange={onSelectAllClick} />
                                </div>
                                <div className="attribute-checkboxes">
                                    {attributeNames
                                        ?.toSorted((a, b) => {
                                            return formatAttributeName(a?.attributeName).localeCompare(
                                                formatAttributeName(b?.attributeName)
                                            );
                                        })
                                        .map((a) => (
                                            <Checkbox
                                                key={`chk-${a.attributeName}`}
                                                label={formatAttributeName(a.attributeName)}
                                                checked={isAttributeSelected(a)}
                                                onChange={() => onAttributeToggle(a)}
                                            />
                                        ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="equipment-attribute-list-wrap flex-column flex-one-in-row no-scroll">
                        <div className="flex-row align-center justify-center">
                            <IconWithLabel icon="plus" onClick={onClose}>
                                Add more items to compare
                            </IconWithLabel>
                        </div>
                        <Separator />
                        <ScrollSync>
                            <div className="equipment-attribute-list flex-column no-scroll">
                                <div className="compare-row compare-row-header flex-row">
                                    <div className="compare-column flex-column align-end justify-space-between">
                                        <div>
                                            <b>{equipmentIds.length}</b> Equipment item
                                            {items.length === 1 ? "" : "s"}
                                            <IconWrap iconWrapSmall icon="arrow-thin-right-empty" />
                                        </div>
                                        <div>
                                            <b>{selectedAttributes.length}</b> Attribute
                                            {selectedAttributes.length === 1 ? "" : "s"}
                                            <IconWrap iconWrapSmall icon="arrow-thin-down-empty" />
                                        </div>
                                    </div>
                                    <ScrollSyncPane group="equipment">
                                        <div id={scrollId} className="flex-row with-scroll">
                                            {equipments.map((equipment, index) => (
                                                <div
                                                    key={`col-name-${index}`}
                                                    className={classNames(
                                                        "compare-column flex-row align-center justify-center compare-column-header",
                                                        {
                                                            sticky: equipment.name === "TOTAL",
                                                        }
                                                    )}
                                                >
                                                    {equipment.name !== "TOTAL" && (
                                                        <IconWrap
                                                            className="remove-equipment-button"
                                                            icon="clear-close"
                                                            title="Remove from Compare"
                                                            onClick={() => removeEquipment(index, equipment)}
                                                        />
                                                    )}

                                                    {equipment.name}
                                                    <div className="equipment-quantity" title="Quantity">
                                                        <CounterBox>{equipment.quantity}</CounterBox>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </ScrollSyncPane>
                                </div>
                                <div className="scrollable-block with-scroll">
                                    {selectedAttributes.map(({ name, friendlyName, attributeName }) => (
                                        <div key={name} className="compare-row flex-row">
                                            <div key={`col-${name}`} className="compare-column flex-row align-center justify-end">
                                                {formatAttributeName(friendlyName, <>&nbsp;</>)}
                                                <br />({formatAttributeName(attributeName)})
                                            </div>
                                            <ScrollSyncPane group="equipment">
                                                <div className="flex-row with-scroll">
                                                    {equipments.map((equipment, index) => (
                                                        <div
                                                            key={`col-${name}-${index}`}
                                                            className={classNames("compare-column flex-row align-center justify-center", {
                                                                sticky: equipment.name === "TOTAL",
                                                            })}
                                                        >
                                                            <span className="flex-row align-center justify-center">
                                                                {equipment.name === "TOTAL"
                                                                    ? getAttributeTotalValue(equipments, attributeName)
                                                                    : getAttributeValue(equipment, attributeName)}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ScrollSyncPane>
                                        </div>
                                    ))}
                                </div>
                                <div className="compare-row compare-row-scroll-indicator flex-row">
                                    <div className="compare-column flex-row"></div>
                                    <ScrollSyncPane group="equipment">
                                        <div className="scroll-indicator flex-row with-scroll">
                                            {equipments.map((equipment, index) => (
                                                <div
                                                    key={index}
                                                    className="compare-column flex-row align-center justify-center compare-column-item"
                                                ></div>
                                            ))}
                                        </div>
                                    </ScrollSyncPane>
                                </div>
                            </div>
                        </ScrollSync>
                        <ScrollControls targetId={scrollId} horizontalScroll />
                    </div>
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default CompareEquipmentPanel;

/**
 * Retrieves the attribute names from the equipment list.
 *
 * @param {Array} equipmentList - The list of equipment.
 * @param {boolean} hasRightsToEditAll - Indicates if the user has rights to edit all attributes.
 * @returns {{ name: string, friendlyName: string | undefined, attributeName: string | undefined }[]}} - The sorted list of attribute names.
 */
const getAttributeNames = (equipmentList, hasRightsToEditAll) => {
    const list = uniqBy(
        equipmentList
            // take actual equipment with ID. Excluding TOTAL
            .filter((equipment) => !isNil(equipment.equipid))
            .flatMap((equipment) => toArray(equipment?.attributes ?? []))
            .filter((a) => hasRightsToEditAll || a.showAll === "1")
            .map((attribute) => ({
                name: getAttributeTitle(attribute.friendlyName, attribute.attributename ?? attribute.attributeName),
                attributeName: attribute.attributename ?? attribute.attributeName,
                friendlyName: attribute.friendlyName,
            })),
        "attributeName"
    );

    return orderBy(list, "attributeName");
};

const getAttributeValue = (equipment, attributeName) => {
    const attribute = toArray(equipment?.attributes ?? []).find(
        (attribute) => (attribute.attributename ?? attribute.attributeName) === attributeName
    );
    return attribute?.value ?? null;
};

const getAttributeTotalValue = (equipments, attributeName) => {
    const attributeValues = equipments
        .map((equipment) => toArray(equipment.attributes) ?? [])
        .map((attr) =>
            attr.filter((val) => (val.attributename ?? val.attributeName) === attributeName && isValidationTypeNumeric(val.validationType))
        )
        .map((attrList) => attrList.filter((attr) => !isNil(attr.value) && Number(attr.value)))
        .flatMap((attrList) => attrList.map((attr) => Number(attr.value)));

    return attributeValues.length > 0 ? sum(attributeValues) : null;
};
