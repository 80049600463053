import React, { useCallback, useEffect, useRef, useState } from "react";
import { pickBy } from "lodash";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useBudgetLineAmount } from "../../../../../../store/resources/useResource";
import WaitIcon from "../../../../WaitIcon";
import { submitByRef, submitResource } from "../../../../../utils/form";
import { onRefreshBudgetLinesGrid } from "../../Contract/BudgetLinesPanel/utils";
import SideNavContent from "../../../../../ui/SideNav/SideNavContent";
import SideNavHeader from "../../../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../../../ui/SideNav/SideNavFooter";
import Button from "../../../../../ui/Button";
import { jsonDateToDate } from "components/utils/date";

const Form = (props) => {
    const { utilityNumber, contractNumber, budgetLineNumber, dataItem, onClose, gridRefresh, sidePanel } = props;

    const formRef = useRef();

    const isNew = dataItem === undefined;
    const resourceId = isNew ? null : dataItem.amountNumber;

    const [isSubmitting, setSubmitting] = useState(false);

    const [resource, isLoading] = useBudgetLineAmount({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
        resourceId,
    });

    const title = isNew ? "Add Budget Amount" : "Edit Budget Amount";
    const titleIcon = isNew ? "plus" : "edit-empty";

    const initialValues = isNew ? {} : pickBy(resource);

    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = {
        type: "object",
        required: ["fromDate", "thruDate", "amount", "sortOrder"],
        properties: {
            fromDate: {
                type: "string",
                title: "From Date",
            },
            thruDate: {
                type: "string",
                title: "Thru Date",
            },
            amount: {
                type: "number",
                title: "Amount",
            },
            sortOrder: {
                type: "integer",
                title: "Sort Order",
                default: 1,
            },
            note: {
                type: "string",
                title: "Note",
            },
        },
    };

    const uiSchema = {
        fromDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        thruDate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        note: {
            "ui:widget": "textarea",
            "ui:options": {
                overlap: true,
            },
        },
    };

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "utilitiesContractsBudgetLinesAmounts",
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        };

        const body = {
            ...formData,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });

        onRefreshBudgetLinesGrid({ contractNumber });
    };

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    const validateDates = (formData, errors) => {
        const { fromDate, thruDate } = formData;
        if (jsonDateToDate(fromDate) > jsonDateToDate(thruDate)) {
            errors.fromDate.addError("From Date must be equal to or smaller than Thru Date");
        }

        return errors;
    };

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    submitText={submitText}
                    noReset
                    noActions
                    validate={validateDates}
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default Form;
