import { useResource } from "store/resources/useResource";

export const useDataGridConfig = (dataGridId: string, forced: boolean = false) => {
    return useResource({
        resourceName: "gridConstruct",
        key: dataGridId,
        path: {
            dataGridId,
        },
        query: {
            returnType: "JSON",
        },
        forced,
    }) as [GridConfigResponse, boolean];
};

export type GridConfigResponse = {
    grid: {
        /* Grid GUID */
        name: string;
        /* Grid column config */
        columns: DataGridColumnResponse[];
        gridactions: {
            sorting: {
                enabled: string;
                type: string;
            }[];
            paging: {
                enabled: string;
                pagesize: string;
            }[];
            columnmanagement: {
                enabled: string;
                ordering: string;
                activating: string;
            }[];
            filter: {
                enabled: string;
                search: string;
                columns: string;
            }[];
            refresh: {
                endpoint: string | null;
            }[];
        }[];
        rowActions: {
            name: string;
            enabled: string;
            endpoint: string;
            refKey: string;
            otherparams: string;
        }[];
    }[];
};

export type DataGridColumnResponse = {
    key: string;
    type: string;
    name: string;
    order: string;
    sort: string;
    filter: string;
    active: string;
    hidecolumn: string;
    datatype?: string;
    rowactions?: string;
    serverTimezoneOffset?: number;
    width?: string;
};

export type DataGridRowResponse = {
    id: string;
    [key: string]: any;
};
