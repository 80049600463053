import React from "react";

import { store } from "../../store/configureStore";
import { exportCSV } from "../../store/dataGrid/actions";
import { modalOpen, modalClose } from "../../store/modal/actions";
import { updateResource } from "../../store/resources/actions";
import { availableGrids, lookupTableColumnKeys } from "../views/configureGrids";
import { getResourcePromise } from "../../store/resources/useResource";
import { download, getValidFileName } from "./files";
import { toArray } from "./array";
import { isNonEmptyString } from "./form";
import { getTotalRecords } from "./datagrid";

/**
 * Downloads a CSV file with the given data, file name, and options.
 *
 * @param {Object} options - The options for the CSV download.
 * @param {Array} options.data - The data to include in the CSV.
 * @param {string} [options.fileName] - The name of the file to download. Defaults to "export".
 * @param {string} [options.fileNamePostfix] - The postfix to add to the file name.
 * @param {boolean} [options.showHeaderRow=true] - Whether to include a header row in the CSV.
 * @param {boolean} [options.isCustom=false] - Whether the data is custom formatted.
 */
export const downloadCSV = ({ data, fileName, fileNamePostfix = undefined, showHeaderRow = true, isCustom = false }) => {
    const csv = convertToCSV({ data, showHeaderRow, isCustom });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

    download({
        blob,
        fileName: getValidFileName({
            fileName: (fileName || "export") + (fileNamePostfix ? "_" + fileNamePostfix.trim() : ""),
            fileExtension: "csv",
            spaceDelimeter: "_",
        }),
    });
};

const convertToCSV = ({ data, showHeaderRow, isCustom, columnDelimiter, lineDelimiter }) => {
    let result = "";

    if (!data || data === null || !data.length) {
        return result;
    }

    columnDelimiter = columnDelimiter || ",";
    lineDelimiter = lineDelimiter || "\n";

    let keys = Object.keys(data[0]);

    if (showHeaderRow) {
        result += '"' + keys.join('"' + columnDelimiter + '"') + '"';
        result += lineDelimiter;
    }

    let ctr = 0;

    data.forEach((item) => {
        ctr = 0;

        if (isCustom) {
            keys = Object.keys(item);
        }

        keys.forEach((key) => {
            if (ctr > 0) {
                result += columnDelimiter;
            }

            // Escape quotes in value and wrap the value in quotes to support commas
            result += item[key] ? '"' + String(item[key]).replace(/"/g, '""') + '"' : '""';
            ctr++;
        });

        result += lineDelimiter;
    });

    return result;
};

export const uploadCSV = (
    params = {
        header: true,
    }
) => {
    return new Promise((resolve, reject) => {
        const inputId = "file-upload-hidden";
        let input = document.getElementById(inputId);

        if (input) {
            input.remove();
        }

        input = document.createElement("input");
        input.id = inputId;
        input.type = "file";
        input.accept = ".csv";
        input.hidden = true;
        input.addEventListener("change", handleUpload, false);

        document.body.appendChild(input);

        input.click();

        function handleUpload(event) {
            if (window.FileReader) {
                getAsText(event.target.files[0]);
            } else {
                reject("FileReader is not supported.");
            }

            input?.remove();
        }

        function getAsText(fileToRead) {
            var reader = new FileReader();
            reader.readAsText(fileToRead);
            reader.onload = loadHandler;
            reader.onerror = errorHandler;
        }

        function loadHandler(event) {
            var csv = event.target.result;
            processData(csv);
        }

        function processData(csv) {
            var lines = csvToArray(csv)
                // Filter out empty line
                .filter((line) => !(line[0] === "" && line.length === 1));

            if (params.header) {
                resolve({
                    lines: lines.slice(1),
                    header: lines[0],
                });
            } else {
                resolve({ lines });
            }
        }

        function errorHandler(event) {
            if (event.target.error.name === "NotReadableError") {
                reject("Cannot read file!");
            }
        }
    });
};

/**
 * Exports a data grid to a CSV file.
 *
 * @param {Object} options - The options for exporting the data grid.
 * @param {string} options.dataGridId - The ID of the data grid to export.
 * @param {string} [options.fileName] - The name of the CSV file to export.
 * @param {string} [options.fileNamePostfix] - The postfix to add to the file name.
 * @param {Object} [options.formatColumnType] - The format for the column type.
 * @param {Object} [options.formatColumnKey] - The format for the column key.
 * @param {Array} [options.columnsKeys] - The keys of the columns to export.
 */
export const exportDatagridToCSV = ({ dataGridId, fileName, fileNamePostfix, formatColumnType, formatColumnKey, columnsKeys }) => {
    store.dispatch(exportCSV({ dataGridId, fileName, fileNamePostfix, formatColumnType, formatColumnKey, columnsKeys }));
};

export const uploadLookupItems = ({ lookupTableNumber, showModal = true }) => {
    return uploadCSV({ delimiter: ",", header: true }).then(async (result) => {
        if (showModal) {
            store.dispatch(
                modalOpen({
                    modalType: "WAITING_MODAL",
                    modalProps: {
                        title: "Uploading Lookup Items...",
                        modalIcon: "upload",
                    },
                })
            );
        }

        try {
            const importedItems = result.lines
                .map((line) => lineToObject({ header: result.header, line }))
                .map((line, index) => {
                    const lineNumber = index + 2;

                    // Lower Limit should be required.
                    if (!isNonEmptyString(line.LOWER_LIMIT ?? "")) {
                        throw Error(`Line ${lineNumber}. Invalid LOWER_LIMIT ${line.LOWER_LIMIT}`);
                    }

                    // Upper Limit, not required.

                    // Value, Required.
                    if (!isNonEmptyString(line.VALUE ?? "")) {
                        throw Error(`Line ${lineNumber}. Invalid VALUE ${line.VALUE}`);
                    }

                    return {
                        lowerLimit: line.LOWER_LIMIT,
                        upperLimit: line.UPPER_LIMIT,
                        value: line.VALUE,
                    };
                });

            await new Promise(async (resolve, reject) => {
                store.dispatch(
                    updateResource({
                        resourceName: "calculationLookupTableAllItems",
                        path: {
                            lookupTableNumber,
                        },
                        body: {
                            lookupItemList: importedItems,
                        },
                        onSuccess: resolve,
                        onError: reject,
                    })
                );
            });

            if (showModal) {
                store.dispatch(modalClose());
            }

            return importedItems;
        } catch (error) {
            if (showModal) {
                const text = (
                    <>
                        <strong>Upload Lookup Items failed</strong>
                        <p>{error.message}</p>
                    </>
                );
                showUploadCsvError({ text });
            } else {
                throw error;
            }
        }
    });
};

export const downloadLookupItems = ({ utilityNumber, lookupTableNumber }) => {
    const state = store.getState();
    const dataGridId = availableGrids.lookupTable;
    const dataGridInstanceId = `${utilityNumber}-${lookupTableNumber}-lookup-items`;
    const filter = `${lookupTableColumnKeys.lookupTableNumber}=${lookupTableNumber}`;

    const getPageSize = () => {
        return new Promise((resolve, reject) => {
            if (state.dataGrid[dataGridInstanceId]) {
                resolve(getTotalRecords(state.dataGrid[dataGridInstanceId]));
                return;
            }

            getResourcePromise({
                resourceName: "grid",
                resourceId: dataGridId,
                query: {
                    searchAttr: filter,
                    pageNum: 1,
                    recsPerPage: 1,
                },
            }).then((data) => {
                resolve(getTotalRecords(data?.grid));
            });
        });
    };

    const getAllRows = ({ pageSize }) => {
        return getResourcePromise({
            resourceName: "grid",
            resourceId: dataGridId,
            query: {
                searchAttr: filter,
                pageNum: 1,
                recsPerPage: pageSize,
                sortBy: lookupTableColumnKeys.lowerLimit,
            },
        }).then((data) => toArray(data.grid?.rows));
    };

    store.dispatch(
        modalOpen({
            modalType: "WAITING_MODAL",
            modalProps: {
                title: "Preparing to Download Lookup Items...",
                modalIcon: "download",
            },
        })
    );

    getPageSize()
        .then((pageSize) => {
            const fileName = `lookup-${lookupTableNumber}`;

            if (pageSize > 0) {
                getAllRows({ pageSize }).then((rows) => {
                    const data = rows.map((row) => ({
                        LOWER_LIMIT: row[lookupTableColumnKeys.lowerLimit],
                        UPPER_LIMIT: row[lookupTableColumnKeys.upperLimit],
                        VALUE: row[lookupTableColumnKeys.value],
                    }));

                    downloadCSV({ data, fileName });
                });
            } else {
                downloadCSV({
                    data: [
                        {
                            LOWER_LIMIT: "",
                            UPPER_LIMIT: "",
                            VALUE: "",
                        },
                    ],
                    fileName,
                });
            }
        })
        .finally(() => {
            store.dispatch(modalClose());
        });
};

export const lineToObject = ({ header, line }) => {
    return header.reduce((acc, next, index) => {
        const key = (next || "").replace(/^"(.*)"$/, "$1");
        const value = (line[index] || "").replace(/^"(.*)"$/, "$1");

        acc = {
            ...acc,
            [key]: value,
        };

        return acc;
    }, {});
};

export const showUploadCsvError = ({ text }) => {
    store.dispatch(
        modalOpen({
            modalType: "SIMPLE_DIALOG",
            modalProps: {
                text,
                errorModal: true,
            },
        })
    );
};

/*
 * Parse csv string to array.
 * Allows comma in items.
 * source: https://stackoverflow.com/a/41563966
 */
export const csvToArray = (text) => {
    let p = "",
        row = [""],
        ret = [row],
        i = 0,
        r = 0,
        s = !0,
        l;

    for (l of text) {
        if ('"' === l) {
            if (s && l === p) row[i] += l;
            s = !s;
        } else if ("," === l && s) l = row[++i] = "";
        else if ("\n" === l && s) {
            if ("\r" === p) row[i] = row[i].slice(0, -1);
            row = ret[++r] = [(l = "")];
            i = 0;
        } else row[i] += l;
        p = l;
    }
    return ret;
};
