import React, { useCallback, memo, useMemo } from "react";
import { useDispatch } from "react-redux";

import ActionLabel from "../../../../Label/ActionLabel";
import WaitIcon from "../../../../WaitIcon";
import { getAttributeValue } from "../../../../../utils/workflow";
import { setActiveApplicationForm } from "../../../../../../store/projects/actions";
import { useResource } from "../../../../../../store/resources/useResource";

export const ApplicationFormActivateTask = memo(({ applicationNumber, task, onExpandPanel }) => {
    const dispatch = useDispatch();

    const [forms = [], isLoadingForms] = useResource({
        resourceName: "applicationForms",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });

    const pageNumber = useMemo(() => getAttributeValue("Form Page Number", task), [task]);

    const form = forms.filter((f) => f.pageNumber === pageNumber)[0];

    const onClick = useCallback(() => {
        dispatch(setActiveApplicationForm(applicationNumber, pageNumber));
        onExpandPanel({
            type: "project-applications",
        });
    }, [applicationNumber, pageNumber, onExpandPanel, dispatch]);

    if (isLoadingForms) {
        return <WaitIcon />;
    }

    return <ActionLabel onClick={onClick}>{form ? form.pageName : "form"}</ActionLabel>;
});
