import React, { useRef } from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { PaddingPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import {
    PaddingBoxSize,
    PortalBuilderPropertyProps,
    PropertyChangeFunc,
    PropertyName,
    PropertyType,
} from "components/ui/PortalBuilder/types";
import { AllSidesProperty } from "./AllSidesProperty";
import { SideBySideProperty } from "./SideBySideProperty";

import { PaddingCustomPropertyName, PaddingSideName, PaddingSides } from "./types";
import { PropertyListItem } from "../PropertyListItem";

import "./PaddingProperty.scss";

export const DefaultPadding = PaddingBoxSize.MD;

export const PaddingProperty = (props: PortalBuilderPropertyProps) => {
    const { className, title, value = {}, nestingLevel = 0, emptyValue, borderTop, borderBottom, onChange } = props;
    const filteredValues = Object.fromEntries(Object.entries(value).filter(([key]) => !key.includes("_")));
    const valueRef = useRef(value);
    const isExpanded = Object.values(filteredValues).some((v) => !isEmpty(v) && v !== emptyValue);

    const customPropertiesValue = {
        [PaddingCustomPropertyName.PaddingType]: isEmpty(value[PropertyName.Padding]) ? PaddingSides.SideBySide : PaddingSides.AllSides,
        [PaddingCustomPropertyName.PaddingAllSides]: value[PropertyName.Padding],
        [PaddingCustomPropertyName.PaddingSideBySide]: {
            [PaddingSideName.Top]: value[PropertyName.PaddingTop],
            [PaddingSideName.Right]: value[PropertyName.PaddingRight],
            [PaddingSideName.Bottom]: value[PropertyName.PaddingBottom],
            [PaddingSideName.Left]: value[PropertyName.PaddingLeft],
        },
    };

    const onPropertyChange = (id: string, propertyValue: string) => {
        switch (id) {
            case PaddingCustomPropertyName.PaddingType:
                onPaddingTypeChange(propertyValue, value, onChange);
                break;
            default:
                // Reset side properties if this is all sides mode
                if (id === PropertyName.Padding) {
                    onChange(id, propertyValue, emptySideBySideProperties);
                    // Reset all sides property if this is side by side mode
                } else {
                    onChange(id, propertyValue, [
                        {
                            id: PropertyName.Padding,
                            value: undefined,
                        },
                    ]);
                }
                break;
        }
    };

    const onToggle = () => {
        if (isExpanded) {
            // Store last value in ref before collapsing the padding properties.
            valueRef.current = value;
            onChange(PropertyName.Padding, emptyValue, emptySideBySideProperties);
        } else {
            const allSidesValue = Object.values(valueRef.current).every((v) => isEmpty(v) || v === emptyValue)
                ? DefaultPadding
                : valueRef.current[PropertyName.Padding];

            const isAllSidesMode = !isEmpty(allSidesValue);

            if (isAllSidesMode) {
                onChange(PropertyName.Padding, allSidesValue, emptySideBySideProperties);
            } else {
                const extraProperties = [
                    {
                        id: PropertyName.PaddingTop,
                        value: valueRef.current[PropertyName.PaddingTop] ?? DefaultPadding,
                    },
                    {
                        id: PropertyName.PaddingBottom,
                        value: valueRef.current[PropertyName.PaddingBottom] ?? DefaultPadding,
                    },
                    {
                        id: PropertyName.PaddingLeft,
                        value: valueRef.current[PropertyName.PaddingLeft] ?? DefaultPadding,
                    },
                    {
                        id: PropertyName.PaddingRight,
                        value: valueRef.current[PropertyName.PaddingRight] ?? DefaultPadding,
                    },
                ];

                onChange(PropertyName.Padding, undefined, extraProperties);
            }
        }
    };

    const PaddingPropertyContextValue = {
        customPropertiesValue,
    };

    if (props.hidden) {
        return null;
    }

    return (
        <PaddingPropertyContext.Provider value={PaddingPropertyContextValue}>
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--padding", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onPropertyChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </PaddingPropertyContext.Provider>
    );
};

const onPaddingTypeChange = (propertyValue: string, propertyGroupValue: any, onChange: PropertyChangeFunc) => {
    if (propertyValue === PaddingSides.SideBySide) {
        const extraProperties = [
            {
                id: PropertyName.PaddingTop,
                value: propertyGroupValue[PropertyName.Padding],
            },
            {
                id: PropertyName.PaddingRight,
                value: propertyGroupValue[PropertyName.Padding],
            },
            {
                id: PropertyName.PaddingBottom,
                value: propertyGroupValue[PropertyName.Padding],
            },
            {
                id: PropertyName.PaddingLeft,
                value: propertyGroupValue[PropertyName.Padding],
            },
        ];

        onChange(PropertyName.Padding, undefined, extraProperties);
    } else {
        onChange(PropertyName.Padding, propertyGroupValue[PropertyName.PaddingTop] ?? DefaultPadding, emptySideBySideProperties);
    }
};

const emptySideBySideProperties = [
    {
        id: PropertyName.PaddingTop,
        value: undefined,
    },
    {
        id: PropertyName.PaddingRight,
        value: undefined,
    },
    {
        id: PropertyName.PaddingBottom,
        value: undefined,
    },
    {
        id: PropertyName.PaddingLeft,
        value: undefined,
    },
];

export const PADDING_PROPERTY = {
    id: "padding",
    title: "Padding",
    type: PropertyType.Padding,
    propertiesGroup: [
        {
            id: PropertyName.Padding,
        },
        {
            id: PropertyName.PaddingTop,
        },
        {
            id: PropertyName.PaddingRight,
        },
        {
            id: PropertyName.PaddingBottom,
        },
        {
            id: PropertyName.PaddingLeft,
        },
    ],
};

const customProperties = [
    {
        id: PaddingCustomPropertyName.PaddingType,
        title: (
            <>
                Padding <br /> size
            </>
        ),
        type: PropertyType.SelectBox,
        borderTop: false,
        borderBottom: false,
        items: [
            {
                text: "Side By Side",
                value: PaddingSides.SideBySide,
            },
            {
                text: "All Sides",
                value: PaddingSides.AllSides,
            },
        ],
    },
    {
        id: PaddingCustomPropertyName.PaddingAllSides,
        type: PropertyType.CustomComponent,
        borderTop: true,
        borderBottom: false,
        component: AllSidesProperty,
    },
    {
        id: PaddingCustomPropertyName.PaddingSideBySide,
        type: PropertyType.CustomComponent,
        borderBottom: false,
        component: SideBySideProperty,
    },
];
