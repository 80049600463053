import React, { memo } from "react";
import { isEmpty } from "lodash";

import Checkbox from "../../../../Input/Checkbox";
import Button from "../../../../Button";
import IconWrap from "../../../../Icons";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import { ErrorMessage } from "../../../../Message";

import "./RebateApprovalsLevels.scss";

export const RebateApprovalsLevels = memo(
    ({
        approvedLevels,
        pendingLevels,
        activeRebateApprovalsLevels,
        rejectEnabled,
        onClickApprove,
        onClickReject,
        onSetActiveRebateApprovalLevel,
    }) => (
        <div className="rebate-approvals__item-levels flex-row">
            {isEmpty(approvedLevels) && isEmpty(pendingLevels) && (
                <div className="rebate-approvals__item-levels-error flex-row justify-center fill-width">
                    <ErrorMessage>There are no proper details for the approval status. Check payments under the project.</ErrorMessage>
                </div>
            )}
            {!isEmpty(approvedLevels) && (
                <>
                    {approvedLevels.map(({ name, label, date, groupRebateId }, index) => (
                        <div key={index} className="rebate-approvals__item-levels-approved-entry flex-column align-center flex-one">
                            <div className="rebate-approvals__item-levels-approved-entry-title">{label}</div>
                            <div className="rebate-approvals__item-levels-approved-entry-description flex-row flex-wrap justify-center">
                                <span className="approved">Approved:</span>
                                <span>{name}</span>
                                in
                                <span>{date}</span>
                            </div>
                            {approvedLevels.length - 1 === index && rejectEnabled && (
                                <IconWithLabel icon="thumb-down-empty" onClick={() => onClickReject(groupRebateId)}>
                                    Reject
                                </IconWithLabel>
                            )}
                            <IconWrap iconWrapSuccess icon="check-circle-filled" title="approved"></IconWrap>
                        </div>
                    ))}
                </>
            )}
            {pendingLevels.map(({ id, groupRebateId, label, allowed, enabled }, index) => {
                const activeRebateApprovalsLevel =
                    allowed && activeRebateApprovalsLevels.find((item) => item.groupRebateId === groupRebateId && item.id === id);
                const checked = Boolean(activeRebateApprovalsLevel);
                const isApproving = checked && activeRebateApprovalsLevel.loading;

                return (
                    <div
                        key={index}
                        className={
                            "rebate-approvals__item-levels-action flex-row align-center justify-center flex-one" +
                            (!allowed || !enabled || isApproving ? " disabled" : "")
                        }
                    >
                        <div className="rebate-approvals__item-levels-action-checkbox">
                            <Checkbox
                                onChange={(e) => {
                                    if (allowed) {
                                        onSetActiveRebateApprovalLevel(e, groupRebateId, id);
                                    }
                                }}
                                label={label}
                                checked={checked}
                                disabled={!allowed || !enabled || isApproving}
                            />
                        </div>
                        {allowed && enabled && !isEmpty(pendingLevels) && (
                            <Button
                                onClick={() => onClickApprove(groupRebateId)}
                                className="rebate-approvals__item-levels-approve-button"
                                disabled={!checked || isApproving}
                                primary
                                small
                            >
                                {isApproving ? "..." : "Approve"}
                            </Button>
                        )}
                        <IconWrap
                            iconWrapTheme
                            disabled={!allowed || !enabled || isApproving}
                            title={!allowed || !enabled || isApproving ? "pending for " + label : "pending for your approve"}
                            icon="clock-time-watch-later-filled"
                        ></IconWrap>
                    </div>
                );
            })}
        </div>
    )
);
