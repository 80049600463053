import React, { memo } from "react";
import cn from "classnames";
import { toArray } from "../../utils/array";
import SearchResultsItem from "./SearchResultsItem";

export const SearchResultList = memo(({ className, searchResults, words, criteriaList, isCisSecondarySearch }) => {
    return (
        <div className={cn("search-results__list", className)}>
            {searchResults.map((table, tableIndex) =>
                toArray(table.ROW).map((row, rowIndex) => (
                    <SearchResultsItem
                        key={`${tableIndex}-${rowIndex}`}
                        table={table}
                        row={row}
                        tableIndex={tableIndex}
                        rowIndex={rowIndex}
                        words={words}
                        criteriaList={criteriaList}
                        isCisSecondarySearch={isCisSecondarySearch}
                    />
                ))
            )}
        </div>
    );
});
