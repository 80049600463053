import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const WaitIcon = (props) => {
    const className = ClassNames("wait-icon", props.className, {
        centered: props.centered,
        "with-space-around": props.withSpaceAround,
    });

    return (
        <div className={className}>
            <div className="wait__item1" />
            <div className="wait__item2" />
            <div className="wait__item3" />
        </div>
    );
};

export default WaitIcon;
