import React, { useContext, memo, useMemo } from "react";
import IconWithLabel from "../../Icons/IconWithLabel";
import { TaskWorkflowContext } from "../context/TaskWorkflowContext";
import { formatJsonDateTime } from "../../../utils/date";
import { openConfirmModal } from "components/ui/Modal/utils";
import { TaskCounter } from "./TaskCounter";
import { isEmpty } from "lodash";
import { isTaskCompleted } from "components/utils/workflow";
import { isAutomated } from "components/ui/TaskWorkflow/utils";

import "./Header.scss";
import { isTodoDependentTask } from "../utils";

export const Header = memo(
    ({ status, processMode, statusDate, userName, tasks, isCompleted = false, showTakeAllTasks, onOpenAdHocTaskForm }) => {
        const { isLocked, isAddAdHocTaskDisabled, onChangeTaskOwnership } = useContext(TaskWorkflowContext);
        const statusLabel = isCompleted ? "Status" : "Current status";

        const availableTaskCount = useMemo(() => {
            if (isCompleted) {
                return 0;
            }

            return tasks.filter(
                (task) => isEmpty(task.userOwner) && !isTaskCompleted(task) && !isTodoDependentTask(task) && !isAutomated(task)
            ).length;
        }, [tasks, isCompleted]);

        const isTakeAllTasksDisabled = isLocked || availableTaskCount < 1 || isAddAdHocTaskDisabled;

        const onTakeAllTasks = () => {
            openConfirmModal({
                modalIcon: "flag-empty",
                title: "Take all tasks",
                message: (
                    <p>
                        All <b>To Do</b> tasks (<b>{availableTaskCount}</b>) will be assigned to you. Are you sure you want to do it?
                    </p>
                ),
                onConfirm: () => onChangeTaskOwnership(null),
            });
        };

        return (
            <div className="workflow-tasks-header">
                <div className="workflow-tasks-header-container flex-row flex-wrap">
                    <div className="workflow-tasks-header-title flex-column flex-one">
                        {status && <div className="panel-small-title">{statusLabel}</div>}
                        {status && <div className="app-status">{status}</div>}
                        {processMode && (
                            <div className="process-mode-label">
                                <span>Processing mode:</span> {processMode}
                            </div>
                        )}
                    </div>
                    {isCompleted ? (
                        <StatusChangeLabel statusDate={statusDate} userName={userName} />
                    ) : (
                        <div className="workflow-tasks-header-actions flex-column">
                            <IconWithLabel iconWithLabelRight icon="plus" disabled={isAddAdHocTaskDisabled} onClick={onOpenAdHocTaskForm}>
                                Add Ad-hoc Task
                            </IconWithLabel>
                            {showTakeAllTasks && (
                                <IconWithLabel
                                    iconWithLabelRight
                                    icon="flag-empty"
                                    disabled={isTakeAllTasksDisabled}
                                    onClick={onTakeAllTasks}
                                >
                                    Take All Tasks
                                </IconWithLabel>
                            )}
                        </div>
                    )}
                </div>
                <TaskCounter tasks={tasks} />
            </div>
        );
    }
);

const StatusChangeLabel = ({ statusDate, userName }) => {
    const text = (
        <>
            <div className="workflow-tasks-status-info__date flex-column align-end">
                <span className="dimm">Changed:</span>
                <span className="highlight">{formatJsonDateTime(statusDate)}</span>
            </div>
            {userName && (
                <div className="workflow-tasks-status-info__time flex-column align-end">
                    <span className="dimm">By:</span>
                    <span className="highlight">{userName}</span>
                </div>
            )}
        </>
    );

    return <div className="workflow-tasks-status-info">{text}</div>;
};
