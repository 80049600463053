import React, { useContext, useMemo, memo } from "react";

import { DocumentRow } from "../../../shared/Grid/DocumentRow";
import { ArchivedBatchGridContext } from "../../../context/ArchivedBatchGridContext";

export const Document = memo(({ index, style, data }) => {
    const { entityId } = data;
    const { batchDocuments } = useContext(ArchivedBatchGridContext);
    const document = useMemo(() => batchDocuments[entityId][index], [entityId, index, batchDocuments]);

    return <DocumentRow {...document} style={style} withoutSelection />;
});
