import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import { CriteriaButton, UtilitiesDropdown } from "./CriteriaButton";
import { useSearchOptions, useResource } from "../../../store/resources/useResource";
import {
    getSubSearchCriteria,
    hasSubCriteria,
    isCisSearchCriteria,
    CIS_UTILITY_SUB_CRITERIA,
    getCriteriaFromCisSearchConfig,
} from "../../../store/globalSearch/utils";
import { clickSubCriteria } from "../../../store/globalSearch/actions";
import WaitIcon from "../WaitIcon";
import { arrayToText } from "../../utils/string";

const SubCriteriaSection = memo(({ instanceId }) => {
    const dispatch = useDispatch();
    const mainCriteria = useSelector((state) => state.globalSearch.selectedMainCriteria[instanceId]) ?? {};
    const selectedCriteria = useSelector((state) => state.globalSearch.selectedSubCriteria[instanceId]) ?? [];
    const [searchOptions] = useSearchOptions();

    const onSelect = useCallback(
        (criteria) => {
            dispatch(clickSubCriteria({ instanceId, criteria }));
        },
        [instanceId, dispatch]
    );

    if (isCisSearchCriteria({ criteria: mainCriteria })) {
        return <CisSubCriteriaSection instanceId={instanceId} onSelect={onSelect} />;
    }

    if (!hasSubCriteria({ mainCriteria })) {
        return null;
    }

    const searchCriteriaList = getSubSearchCriteria({
        mainCriteria,
        searchOptions,
    });

    return (
        <div className="global-search__sub-criteria flex-column fill-width no-shrink">
            <div className="global-search__section-title">
                <strong>{mainCriteria.title}</strong> Search Criteria
            </div>
            <div className="global-search__main-criteria-list flex-row flex-wrap">
                {searchCriteriaList.map((criteria) => (
                    <CriteriaButton
                        instanceId={instanceId}
                        key={criteria.key}
                        criteria={criteria}
                        active={selectedCriteria.some((i) => i.key === criteria.key)}
                        onClick={() => onSelect(criteria)}
                    />
                ))}
            </div>
        </div>
    );
});

const CisSubCriteriaSection = ({ instanceId, onSelect }) => {
    const mainCriteria = useSelector((state) => state.globalSearch.selectedMainCriteria[instanceId]) ?? {};
    const selectedCriteria = useSelector((state) => state.globalSearch.selectedSubCriteria[instanceId]) ?? [];
    const { utilityNumber } = useSelector((state) => state.globalSearch.selectedUtility[instanceId]) ?? {};

    const [cisSearchConfig = {}, isLoading] = useResource({
        resourceName: "cisSearchCriteria",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
    });

    const [searchCriteriaList, mandatoryKeys, disabledFieldTooltip] = useMemo(() => {
        const criteria = getCriteriaFromCisSearchConfig(cisSearchConfig);

        const mandatoryFields = criteria.filter((item) => item.isMandatory);
        const optionalFields = criteria.filter((item) => !item.isMandatory);

        const mandatoryKeys = mandatoryFields.map((item) => item.key);
        const disabledFieldTooltip =
            arrayToText(
                mandatoryFields.map((i) => i.title),
                "or"
            ) + " is required";

        return [mandatoryFields.concat(optionalFields), mandatoryKeys, disabledFieldTooltip];
    }, [cisSearchConfig]);

    const isAnyMandatorySelected = selectedCriteria.some((i) => mandatoryKeys.includes(i.key));

    const isCriteriaDisabled = (criteria) => {
        return (
            isNil(utilityNumber) ||
            // No mandatory criteria selected and criteria is not mandatory
            (!isAnyMandatorySelected && !mandatoryKeys.includes(criteria.key)) ||
            // Criteria is required for some selected criteria
            selectedCriteria
                .filter((i) => !i.isGroup)
                .flatMap((i) => i.relatedFields || [])
                .filter((i) => i.isRequired)
                .some((i) => i.key === criteria.key)
        );
    };

    const getCriteriaTooltip = (criteria) => {
        const isRequired = selectedCriteria
            .filter((i) => !i.isGroup)
            .flatMap((i) => i.relatedFields || [])
            .filter((i) => i.isRequired)
            .some((i) => i.key === criteria.key);

        return isRequired ? "Is Required" : isCriteriaDisabled(criteria) ? disabledFieldTooltip : undefined;
    };

    return (
        <div className="global-search__sub-criteria flex-column fill-width no-shrink">
            <div className="global-search__section-title">
                <strong>{mainCriteria.title}</strong> Criteria
            </div>
            <div className="global-search__main-criteria-list flex-row flex-wrap">
                <UtilitiesDropdown
                    instanceId={instanceId}
                    onClick={() => onSelect(CIS_UTILITY_SUB_CRITERIA)}
                    isCisSearch
                    criteriaKey={mainCriteria.key}
                />
                {isLoading && <WaitIcon />}
                {searchCriteriaList.map((criteria) => (
                    <CriteriaButton
                        instanceId={instanceId}
                        key={criteria.key}
                        criteria={criteria}
                        active={selectedCriteria.some((i) => i.key === criteria.key)}
                        onClick={() => onSelect(criteria)}
                        disabled={isCriteriaDisabled(criteria)}
                        tooltip={getCriteriaTooltip(criteria)}
                    />
                ))}
            </div>
        </div>
    );
};

export default SubCriteriaSection;
