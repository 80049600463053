import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { availableGrids } from "../../views/configureGrids";
import { searchAttrToFilter } from "../../utils/datagrid";
import { construct } from "../../../store/dataGrid/actions";
import WaitIcon from "../WaitIcon";

const withDataGrid = (gridName, WrappedComponent) => {
    const InitializedGrid = (props) => {
        const { gridId, filter, sort, onSuccess, filterRequired, initialFilter } = props;
        const dataGrid = useSelector((state) => state.dataGrid);
        const dispatch = useDispatch();
        const dataGridConfig = dataGrid[gridId];

        useEffect(() => {
            const options = {
                dataGridId: availableGrids[gridName],
                dataGridInstanceId: gridId,
                data: true,
                onSuccess,
                filterRequired,
                initialFilter,
            };

            if (filter || filter === "") {
                options.filter = searchAttrToFilter(filter);
            }

            if (sort) {
                options.sort = sort;
            }

            dispatch(construct(options));
        }, [gridId, filter, sort, onSuccess, dispatch, filterRequired, initialFilter]);
        if (dataGridConfig) {
            return <WrappedComponent dataGridConfig={dataGridConfig} dataGrid={dataGrid} dispatch={dispatch} {...props} />;
        }

        return <WaitIcon />;
    };

    return InitializedGrid;
};

export default withDataGrid;
