import React, { useState } from "react";
import Button from "../../../../Button";
import ButtonGroup from "../../../../Button/ButtonGroup";
import { uploadItemsForAssociation } from "./actions";
import { SelectedItemsExpandablePanel } from "components/ui/SelectedItemsExpandablePanel";

const AddAssociationForm = ({
    calculationNames,
    utilityNumber,
    dataGridId,
    itemNumberKey,
    utilityNumberKey,
    GridControls,
    UploadedItemsGrid,
    ItemsGrid,
    onSubmit,
    onCancel,
}) => {
    const [selectedRows, setSelectedRows] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [uploadedRows, setUploadedRows] = useState([]);

    const onUpload = () => {
        uploadItemsForAssociation().then((rows) => {
            setSelectedRows({});
            setUploadedRows(rows);
        });
    };

    const onUploadedRowSelect = (event) => {
        let rows = uploadedRows;

        if (!event) {
            // Select All clicked
            const selectedRowCount = rows.filter((r) => r._selected).length;
            let nextState = selectedRowCount < rows.length;

            rows = rows.map((r) => ({
                ...r,
                _selected: nextState,
            }));
        } else {
            rows = uploadedRows.map((r) => {
                if (r.ID === event.dataItem.ID) {
                    r._selected = !event.dataItem._selected;
                }

                return r;
            });
        }

        setUploadedRows(rows);
    };

    const onRowSelect = (rows) => {
        setSelectedRows(rows);
    };

    const onRowSelectClear = () => {
        setSelectedRows({});
    };

    const onFormSubmit = () => {
        setIsSubmitting(true);

        const itemNumbers = uploadedRows.length > 0 ? uploadedRows.filter((i) => i._selected).map((i) => i.ID) : getSelectedItems();

        onSubmit(itemNumbers);
    };

    const getSelectedItems = () => {
        return Object.entries(selectedRows)
            .map((entry) => entry[1])
            .reduce((acc, next) => (acc = acc.concat(next)), [])
            .map((i) => i[itemNumberKey]);
    };

    const selectedRowCount = getSelectedItems().length;
    const submitDisabled = (uploadedRows.length === 0 && selectedRowCount === 0) || isSubmitting;

    return (
        <div className="calculations-assign-form panel sticky-grid-list-panel">
            <SelectedItemsExpandablePanel items={calculationNames} itemTitle="Selected Calculation" itemsTitle="Selected Calculations" />
            <GridControls selectedRowCount={selectedRowCount} onRowSelectClear={onRowSelectClear} onUpload={onUpload} />
            {uploadedRows.length > 0 ? (
                <UploadedItemsGrid rows={uploadedRows} onRowSelect={onUploadedRowSelect} />
            ) : (
                <ItemsGrid
                    gridId={dataGridId}
                    filter={`${utilityNumberKey}=${utilityNumber}`}
                    selectedRows={selectedRows}
                    onRowSelect={onRowSelect}
                />
            )}
            <ButtonGroup transparent>
                <Button primary onClick={onFormSubmit} disabled={submitDisabled}>
                    Save
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </ButtonGroup>
        </div>
    );
};

export default AddAssociationForm;
