import React from "react";
import DashboardPanel from "../DashboardPanel";

const TextPanel = ({ panel }) => (
    <DashboardPanel title={panel.name}>
        <div dangerouslySetInnerHTML={{ __html: panel.text }} />
    </DashboardPanel>
);

export default React.memo(TextPanel);
