import React, { memo } from "react";

import IconWrap from "../../Icons";
import IconGroup from "../../Icons/IconGroup";

import "./SidebarIconGroup.scss";

export const SidebarIconGroup = memo(({ panel, onSettings, onUnpin, onExpand }) => {
    return (
        <IconGroup sidebar>
            {onSettings && <IconWrap icon="settings-empty" onClick={() => onSettings(panel)}></IconWrap>}
            {onExpand && <IconWrap icon="exit" title="See more" onClick={() => onExpand(panel)}></IconWrap>}
            {onUnpin && <IconWrap icon="pin-filled" title="Unpin from Sidebar" onClick={() => onUnpin(panel)}></IconWrap>}
        </IconGroup>
    );
});
