import React, { useRef, useEffect, useCallback, memo } from "react";
import { isString } from "lodash";

import FieldGroupNumericInput from "../FieldGroupNumericInput";

const NumericInput = memo(
    ({
        id,
        className,
        label,
        placeholder,
        required,
        name,
        value,
        disabled,
        readOnly,
        withoutIcon,
        error,
        onChange,
        onKeyDown,
        onFocus,
        onBlur,
        min = 0,
        max,
        decimals,
        title,
        lightUpError,
    }) => {
        const inputRef = useRef();

        const getValue = useCallback(() => {
            if ((value === undefined || value === null) && inputRef.current) {
                return inputRef.current.value;
            }

            return "" + value;
        }, [value]);

        useEffect(() => {
            if (decimals) {
                let parts = getValue().split(".");

                if (parts[1] && parts[1].length > decimals) {
                    parts[1] = parts[1].slice(0, decimals);

                    const newValue = parts.join(".");

                    onChange({
                        target: {
                            value: +newValue,
                        },
                    });
                }
            }
        }, [decimals, getValue, onChange]);

        const onUp = () => {
            let parts = getValue().split(".");
            parts[0] = +parts[0] + 1;
            const newValue = parts.join(".");

            if (max !== undefined && parseFloat(newValue) > max) {
                return;
            }

            onChange({
                target: {
                    value: +newValue,
                },
            });

            if (value === undefined || value === null) {
                inputRef.current.value = newValue;
            }
        };

        const onDown = () => {
            let parts = getValue().split(".");
            parts[0] = +parts[0] - 1;
            const newValue = parts.join(".");

            if (min !== undefined && parseFloat(newValue) < min) {
                return;
            }

            onChange({
                target: {
                    value: +newValue,
                },
            });

            if (value === undefined || value === null) {
                inputRef.current.value = newValue;
            }
        };

        return (
            <FieldGroupNumericInput
                id={id}
                inputRef={inputRef}
                className={className}
                label={label}
                placeholder={placeholder}
                labelRequired={required}
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                value={value ?? ""}
                error={error}
                msgText={isString(error) ? error : undefined}
                title={title}
                min={min}
                max={max}
                withIcon
                iconRight
                withoutIcon={withoutIcon}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                onUp={onUp}
                onDown={onDown}
                lightUpError={lightUpError}
            />
        );
    }
);

export default NumericInput;
