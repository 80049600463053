import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { modalOpen } from "store/modal/actions";
import { EntitiesColumn } from "./EntitiesColumn";
import { EntitiesColumnBody } from "./EntitiesColumnBody";
import { EntitiesColumnHeader } from "./EntitiesColumnHeader";
import RoleRights from "./RoleRights";
import { RolesColumn } from "./RolesColumn";
import { RolesColumnBody } from "./RolesColumnBody";
import { RolesColumnHeader } from "./RolesColumnHeader";
import RoleUsers from "./RoleUsers";
import { ClientProgramsUtility, EntityRolesState, ExpandedState, HighlightingCtx, RoleActionType, UserRole } from "./types";
import { filterClientPrograms, setRoleForAllEntities, unselectRoleForAllEntities } from "./utils";

export const DEFAULT_EXPANDED_STATE = false;

export const HighlightingContext = React.createContext<HighlightingCtx>({});

export const RolesManagementBody = ({
    clientName,
    clientNumber,
    clientRoleId,
    entityRolesState,
    clientPrograms,
    roles,
    onEntityRolesStateChange,
}: RolesManagementBodyProps) => {
    const [expandedState, setExpandedState] = useState<ExpandedState>({});
    const [highlightedEntityNumber, setHighlightedEntityNumber] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState("");

    const dispatch = useDispatch();

    // Filter utilities and programs by search term
    const filteredClientPrograms = useMemo(() => filterClientPrograms(clientPrograms, searchTerm), [clientPrograms, searchTerm]);

    const onToggleExpand = useCallback((utilityNumber: string) => {
        setExpandedState((prev) => ({ ...prev, [utilityNumber]: !(prev[utilityNumber] ?? DEFAULT_EXPANDED_STATE) }));
    }, []);

    const onRoleAction = (roleID: number, actionType: RoleActionType) => {
        switch (actionType) {
            case RoleActionType.SelectAll:
                onEntityRolesStateChange(setRoleForAllEntities(roleID, entityRolesState, filteredClientPrograms));
                break;
            case RoleActionType.UnselectAll:
                if (clientRoleId) {
                    onEntityRolesStateChange(unselectRoleForAllEntities(roleID, clientRoleId, entityRolesState, filteredClientPrograms));
                }
                break;
            case RoleActionType.ShowRights:
                const role = roles.find((r) => r.roleID === roleID);
                if (role) {
                    dispatch(
                        modalOpen({
                            modalType: "MODAL",
                            modalProps: {
                                title: role.roleName,
                                overlayClassName: "modal-styled",
                                className: "modal-list modal-sm",
                                modalIcon: "theaters-empty",
                                children: <RoleRights role={role} />,
                            },
                        })
                    );
                }
                break;
            case RoleActionType.ShowUsers:
                {
                    const role = roles.find((r) => r.roleID === roleID);
                    if (role) {
                        dispatch(
                            modalOpen({
                                modalType: "MODAL",
                                modalProps: {
                                    title: (
                                        <>
                                            {`${role.roleName} role users for client`}
                                            <br />
                                            {clientName}
                                        </>
                                    ),
                                    overlayClassName: "modal-styled",
                                    className: "modal-list role-modal modal-sm",
                                    modalIcon: "theaters-empty",
                                    children: <RoleUsers role={role} entityNumber={clientNumber} entityTypeId={1} />,
                                },
                            })
                        );
                    }
                }
                break;
            default:
                break;
        }
    };

    return (
        <HighlightingContext.Provider value={{ entityNumber: highlightedEntityNumber, onHighlight: setHighlightedEntityNumber }}>
            <div className="roles-management-body flex-row fill-width">
                <EntitiesColumn>
                    <EntitiesColumnHeader searchTerm={searchTerm} onSearch={setSearchTerm} />
                    <EntitiesColumnBody
                        clientPrograms={filteredClientPrograms}
                        expandedState={expandedState}
                        searchTerm={searchTerm}
                        onToggleExpand={onToggleExpand}
                    />
                </EntitiesColumn>
                <RolesColumn>
                    <RolesColumnHeader clientRoleId={clientRoleId} roles={roles} onRoleAction={onRoleAction} />
                    <RolesColumnBody
                        roles={roles}
                        clientPrograms={filteredClientPrograms}
                        expandedState={expandedState}
                        entityRolesState={entityRolesState}
                        onEntityRolesStateChange={onEntityRolesStateChange}
                    />
                </RolesColumn>
            </div>
        </HighlightingContext.Provider>
    );
};

interface RolesManagementBodyProps {
    clientName: string;
    clientNumber: string;
    clientRoleId?: number;
    roles: UserRole[];
    clientPrograms: ClientProgramsUtility[];
    entityRolesState: EntityRolesState;
    onEntityRolesStateChange: (entityRolesState: EntityRolesState) => void;
}
