import React from "react";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import Label from "../Label";
import TextField from "../TextField";
import StatusMsg from "../StatusMsg";

const FieldGroupTextarea = (props) => {
    return (
        <FieldGroup>
            {props.label && <Label labelRequired={props.labelRequired}>{props.label}</Label>}
            <FieldWrap textarea>
                <TextField
                    readOnly={props.readOnly}
                    onChange={props.onChange}
                    onFocus={props.onFocus}
                    focus={props.focus}
                    placeholder={props.placeholder}
                    value={props.value}
                    error={props.error}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    lightUpError={props.lightUpError}
                    data-test-id={props["data-test-id"]}
                />
            </FieldWrap>
            {props.msgText && (
                <StatusMsg msgFieldStatus msgVisible={props.error} msgError={props.error} msgText={props.msgText}></StatusMsg>
            )}
        </FieldGroup>
    );
};

export default FieldGroupTextarea;
