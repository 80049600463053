/**
 * Types of available html editor toolbars
 */
export enum ToolbarTypes {
    Lite = "lite",
    Basic = "basic",
    FastTags = "fasttags",
    PortalBuilder = "portalbuilder",
    PortalBuilderFullScreen = "portalbuilder_fullscreen",
    Minimum = "minimum",
    Texts = "texts",
    Links = "links",
    TextsFullScreen = "texts_fullscreen",
    List = "list",
}

export const toolbarLite = [
    { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike"] },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "paragraph", items: ["NumberedList", "BulletedList"] },
];

export const toolbarBasic = [
    { name: "document", items: ["Source"] },
    {
        name: "clipboard",
        items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"],
    },
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
    },
    { name: "links", items: ["Link", "Unlink", "Anchor"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    {
        name: "paragraph",
        items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
            "CreateDiv",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
        ],
    },
    { name: "styles", items: ["Styles", "Format", "FontSize"] },
    { name: "insert", items: ["Table", "HorizontalRule"] },
    { name: "tools", items: ["ShowBlocks"] },
];

export const toolbarFastTags = [
    { name: "document", items: ["Source"] },
    {
        name: "clipboard",
        items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"],
    },
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
    },
    { name: "links", items: ["Link", "Unlink", "Anchor"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    {
        name: "paragraph",
        items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
            "CreateDiv",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
        ],
    },
    { name: "styles", items: ["Styles", "Format", "FontSize"] },
    { name: "insert", items: ["Table", "HorizontalRule", "Fasttags"] },
    { name: "tools", items: ["ShowBlocks"] },
];

export const toolbarPortalBuilder = [
    { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike"] },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "paragraph", items: ["NumberedList", "BulletedList"] },
    { name: "tools", items: ["Toolbarswitch"] },
];

export const toolbarPortalBuilderFullScreen = [
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "colors", items: ["TextColor", "BGColor"] },
    {
        name: "paragraph",
        items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "Blockquote",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
        ],
    },
    { name: "styles", items: ["Format", "FontSize"] },
    { name: "insert", items: ["Table", "Fasttags"] },
    { name: "tools", items: ["Toolbarswitch"] },
];

export const toolbarMinimum = [
    { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike"] },
    { name: "links", items: ["Link", "Unlink"] },
];

export const toolbarTexts = [
    { name: "basicstyles", items: ["Bold", "Italic", "Underline"] },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "insert", items: ["Fasttags"] },
    { name: "tools", items: ["Toolbarswitch"] },
];

export const toolbarLinks = [
    { name: "links", items: ["Link", "Unlink"] },
    { name: "tools", items: ["Toolbarswitch"] },
];

export const toolbarList = [
    {
        name: "paragraph",
        items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent"],
    },
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline"],
    },
    { name: "tools", items: ["Toolbarswitch"] },
];

export const toolbarTextsFullScreen = [
    {
        name: "basicstyles",
        items: ["Bold", "Italic", "Underline", "Subscript", "Superscript"],
    },
    { name: "links", items: ["Link", "Unlink"] },
    { name: "colors", items: ["TextColor"] },
    {
        name: "paragraph",
        items: [
            "NumberedList",
            "BulletedList",
            "-",
            "Outdent",
            "Indent",
            "-",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "JustifyBlock",
        ],
    },
    { name: "styles", items: ["Format"] },
    { name: "insert", items: ["Table", "Fasttags"] },
    { name: "tools", items: ["Toolbarswitch"] },
];
