import React from "react";

import DataGrid from "../../../../../DataGrid";
import withDataGrid from "../../../../../DataGrid/withDataGrid";

const Grid = ({ gridId, dataGridConfig, dispatch }) => {
    return <DataGrid name={gridId} config={dataGridConfig} customRowActions />;
};

export default withDataGrid("programsCatalogHistory", Grid);
