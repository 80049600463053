import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import NothingFoundBlock from "../../NothingFoundBlock";
import { changeSettingsOpen } from "../../../../store/dataGrid/actions";

import IconWithLabel from "../../Icons/IconWithLabel";

const GridListNoColumnsRow = memo(({ show, dataGridId }) => {
    const dispatch = useDispatch();

    const handleOpenSettings = useCallback(() => {
        dispatch(changeSettingsOpen({ dataGridId, isOpen: true }));
    }, [dataGridId, dispatch]);

    if (!show) {
        return null;
    }

    return (
        <div className="grid-list-row no-data">
            <NothingFoundBlock nothingFoundBlockNoBorder icon="view-week-view-columns-empty" title="No Columns Selected">
                <span>All columns are turned OFF</span>
                <IconWithLabel icon={"settings-empty"} onClick={handleOpenSettings}>
                    Configure columns
                </IconWithLabel>
            </NothingFoundBlock>
        </div>
    );
});

export default GridListNoColumnsRow;
