import React, { useRef, memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import Button from "../../../../Button";
import { referenceToAnyOf, submitByRef } from "components/utils/form";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { updateResource } from "store/resources/actions";
import { refreshQcData } from ".";
import { isEmpty } from "lodash";
import { stripHtml, isNullOrWhitespace } from "components/utils/string";

const QCControlsEdit = memo(({ onClose, resultList, statusList, initialValues, qcNumber, applicationNumber }) => {
    const formRef = useRef();
    const dispatch = useDispatch();
    const onSave = () => {
        submitByRef(formRef);
    };

    const [disableDescriptionFields, setDisableDescriptionFields] = useState(!isEmpty(initialValues?.dateCompleted));

    const handleOnChange = ({ formData }) => {
        if (!formData?.dateCompleted) {
            setDisableDescriptionFields(false);
        } else {
            setDisableDescriptionFields(true);
        }
    };

    const onSubmit = useCallback(
        (formData) => {
            const gridId = `${applicationNumber}-quality-control-grid`;
            dispatch(
                updateResource({
                    resourceName: "qc",
                    resourceId: qcNumber,
                    path: {
                        appId: applicationNumber,
                    },
                    body: formData,
                    onSuccess: () => {
                        onClose();
                        refreshQcData(dispatch, applicationNumber, qcNumber, gridId);
                    },
                })
            );
        },
        [dispatch, qcNumber, applicationNumber, onClose]
    );
    const handleValidate = useCallback((formData, errors) => {
        if (isNullOrWhitespace(stripHtml(formData.description))) {
            errors.description.addError("Description cannot be empty or filled with spaces.");
        }

        return errors;
    }, []);

    const schema = {
        type: "object",
        required: ["description"],
        properties: {
            dateAccepted: {
                type: ["string", "null"],
                title: "Date Accepted",
            },
            dateCompleted: {
                type: ["string", "null"],
                title: "Date Completed",
            },
            result: {
                type: ["string", "null"],
                title: "Result",
                anyOf: referenceToAnyOf({
                    list: resultList,
                    type: "string",
                }),
            },
            status: {
                type: ["string", "null"],
                title: "Status",
                anyOf: referenceToAnyOf({
                    list: statusList,
                    type: "string",
                }),
            },
            description: {
                type: ["string", "null"],
                title: "Description",
            },
        },
    };

    const uiSchema = {
        dateAccepted: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
                time: true,
                localize: false,
            },
        },
        dateCompleted: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
                time: true,
                localize: false,
            },
        },
        description: {
            "ui:widget": "HtmlEditorWidget",
            "ui:disabled": disableDescriptionFields,
        },
    };
    return (
        <SideNavContent>
            <SideNavHeader smallHeader title="Edit QC Request Details" onClose={onClose} leadBlockIcon="edit-empty" />
            <SideNavBody>
                {initialValues && (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onChange={(formData) => handleOnChange(formData)}
                        noSubmit
                        noReset
                        noCancel
                        validate={handleValidate}
                    />
                )}
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onSave}>Save</Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});
export default QCControlsEdit;
