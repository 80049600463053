import React, { memo, useState } from "react";
import DataGrid from "../../ui/DataGrid";
import withDataGrid from "../../ui/DataGrid/withDataGrid";
import RawHtml from "components/ui/RawHtml";
import { logsSystemXmlProcessDetailsGridColumnKeys } from "../configureGrids";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { exportDatagridToCSV } from "components/utils/CSV";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";

import "./LogsSystemXmlProcessDetails.scss";

const LogsGrid = withDataGrid("logsSystemXmlProcessDetails", ({ gridId, dataGridConfig, setShowXmlContext, setXmlContext }) => {
    const customRowActions = [
        {
            name: "open-xml-context",
            icon: "eye-visibility-empty",
            title: "Open XML Context",
        },
    ];

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        switch (name) {
            case "open-xml-context":
                setXmlContext(dataItem[logsSystemXmlProcessDetailsGridColumnKeys.context]);
                setShowXmlContext(true);
                break;
            default:
                break;
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={customRowActions}
            onRowAction={onRowAction}
            columnCellContent={{
                [logsSystemXmlProcessDetailsGridColumnKeys.context]: (column, row) => (
                    <RawHtml className="context-preview">{row[logsSystemXmlProcessDetailsGridColumnKeys.context]}</RawHtml>
                ),
            }}
        />
    );
});

const LogsSystemXmlProcessDetails = memo(({ processRunID, onClose }) => {
    const DATA_GRID_ID = `logs-system-xml-process-details-${processRunID}`;
    const [showXmlContext, setShowXmlContext] = useState(false);
    const [xmlContext, setXmlContext] = useState("");
    return (
        <SideNavContent className="xml-process-details">
            <SideNavHeader
                title={showXmlContext ? "XML Context" : "XML Process Details"}
                leadBlockIcon="eye-visibility-empty"
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                {!showXmlContext && (
                    <div className="xml-process-details">
                        <div className="data-grid-controls flex-row justify-end">
                            <IconWithLabel
                                withHandMadeIcon
                                onClick={() =>
                                    exportDatagridToCSV({
                                        dataGridId: DATA_GRID_ID,
                                        fileName: `log_xml_process_details-${processRunID}`,
                                    })
                                }
                            >
                                Export CSV
                            </IconWithLabel>
                        </div>
                        <LogsGrid
                            gridId={DATA_GRID_ID}
                            setShowXmlContext={setShowXmlContext}
                            setXmlContext={setXmlContext}
                            filter={`${logsSystemXmlProcessDetailsGridColumnKeys.processRunID}=${processRunID}`}
                        />
                    </div>
                )}

                {showXmlContext && xmlContext && <RawHtml className="context-preview">{xmlContext}</RawHtml>}
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button hidden={!showXmlContext} onClick={() => setShowXmlContext(false)}>
                    Go Back
                </Button>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default LogsSystemXmlProcessDetails;
