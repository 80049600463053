import React, { useEffect, useCallback, memo } from "react";
import { useDispatch } from "react-redux";
import { availableGrids } from "../../views/configureGrids";
import { customerGridGetDataAction } from "../../../store/dataGrid/getDataActions";
import { getCustomerGridId } from "./actions";
import DataGrid from "../DataGrid";
import WaitIcon from "../WaitIcon";
import { setFilter } from "store/dataGrid/actions";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const customersGridColumnKeys = {
    accountNumber: "c_cd000f48-f0c0-4396-8590-549627cdba3e",
    firstName: "c_37bd26d7-53f0-42d8-9ca5-86ebcfb514a0",
    lastName: "c_1ad94457-cc39-446d-acae-3f4d969f2a56",
    customerNumber: "c_82911582-0ed2-45b5-a2a5-6d6560486435",
    email: "c_b429cfd5-0249-42ce-86de-2fdc0b64ba4f",
    status: "c_8ec6358f-b036-4cae-855b-d5da8b1746a7",
};

const rowActions = [
    {
        name: "select",
        icon: "plus",
        title: "Select This Customer",
    },
];

const CustomerSearchGrid = memo(({ utilityNumber, onSelect, onClose, customers }) => {
    const dispatch = useDispatch();

    const dataGridId = getCustomerGridId({ utilityNumber });

    const loadData = useCallback(() => {
        customerGridGetDataAction({
            columnKeysMap: customersGridColumnKeys,
            utilityNumber,
            customers,
        });
    }, [customers, utilityNumber]);

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.customers,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "customerGridGetDataAction",
            props: {
                columnKeysMap: customersGridColumnKeys,
                utilityNumber,
                customers,
            },
        },
    });

    useEffect(() => {
        if (!isLoadingConfig) {
            dispatch(
                setFilter({
                    dataGridId,
                    filter: [],
                })
            );

            loadData();
        }
    }, [dataGridId, isLoadingConfig, loadData, dispatch, customers]);

    const onRowAction = useCallback(
        (action) => {
            const { dataItem } = action;

            const data = mapGridRowToObject(customersGridColumnKeys, dataItem);

            onSelect(data);
            onClose();
        },
        [onClose, onSelect]
    );

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default CustomerSearchGrid;
