import React, { useContext } from "react";
import { ColumnsProperty } from "../ColumnsProperty";
import { RowLayout } from ".";
import { RowLayoutTypes } from "./RowLayoutTypes";
import { isNil } from "lodash";
import { LayoutType, Row, RowLayoutConfiguration } from "components/ui/PortalBuilder/types";
import { PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";

export const LayoutColumnSelector = (props: LayoutColumnSelectorProps) => {
    const { layoutId, onChange, row } = props;
    const { selectedColumn, setSelectedColumn, selectedColumnRow, setSelectedColumnRow } = useContext(PortalBuilderPropertiesContext);
    const layout: RowLayoutConfiguration = RowLayoutTypes[layoutId];

    return (
        <>
            {!isNil(selectedColumn) && selectedColumnRow === row.index && (
                <ColumnsProperty id="components" {...props} onChange={onChange} selectedLayout={layout} />
            )}
            <RowLayout
                layout={layout}
                onColumnSelect={(index) => {
                    setSelectedColumn && setSelectedColumn(index);
                    setSelectedColumnRow && setSelectedColumnRow(row.index ?? null);
                }}
            />
        </>
    );
};

export interface LayoutColumnSelectorProps {
    layoutId: LayoutType;
    onChange: (id: string, value: string) => void;
    row: Row;
}
