import { MODAL_OPEN, MODAL_CLOSE } from "../actionTypes";

const initialState = {
    modalType: null,
    modalProps: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPEN:
            return {
                modalType: action.modalType,
                modalProps: action.modalProps,
            };
        case MODAL_CLOSE:
            return initialState;
        default:
            return state;
    }
};
