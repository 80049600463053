import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../../../utils/CSV";

import { programsFilesGridColumnKeys } from "../../../../../views/configureGrids";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";

import FilesGrid from "./FilesGrid";
import FileUpload from "./FileUpload";
import "../../FilesPanel.scss";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils";
import { useResource } from "store/resources/useResource";

const FilesPanel = memo(({ panel }) => {
    const programNumber = panel && panel.data && panel.data.programNumber;
    const gridId = `${programNumber}-files-grid`;
    const isLocked = isChildProgram({ programNumber });
    const panelTitle = panel && panel.name;
    const entityTypeId = 70;
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "files-upload-sidenav-panel",
    });

    const toggleUploadEvent = () => {
        handleOpenSidePanel(<FileUpload gridId={gridId} entityId={programNumber} onClose={handleCloseSidePanel} files={files} />);
    };

    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: 70,
        },
    });
    return (
        <div className="panel files-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panelTitle} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel icon={"upload"} disabled={isLocked} onClick={toggleUploadEvent}>
                    Upload New File
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "program_files",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <FilesGrid
                programNumber={programNumber}
                gridId={gridId}
                filter={`${programsFilesGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default FilesPanel;
