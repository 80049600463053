import { useRef } from "react";
import DelayedMountManager from "./DelayedMountManager";

/** Returns instance of DelayedMountManager */
export const useDelayedMount = () => {
    // Create manager only once
    const delayedMountManagerREF = useRef(null);
    if (delayedMountManagerREF.current === null) {
        delayedMountManagerREF.current = new DelayedMountManager();
    }

    return delayedMountManagerREF.current;
};

export default useDelayedMount;
export { default as DelayedMountItem } from "./DelayedMountItem";
export { default as DelayedMountManager } from "./DelayedMountManager";
