import React, { memo } from "react";

import { SortType, mapGridRowToObject } from "../../../../../utils/datagrid";
import { getInvoiceItemsGridId, onDeleteInvoiceItem } from "../utils";
import { availableGrids } from "../../../../../views/configureGrids";
import { useInvoiceDocumentLocked } from "../../../../../views/UtilityInvoiceView/utils";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { useDataGrid } from "components/utils/useDataGrid";

const invoiceItemsGridColumnKeys = {
    refId: "c_0df84cdb-9d2e-4f89-b0c1-09444cae43c8",
    itemName: "c_357b3fa9-b773-423a-a5bb-fe8e530e8174",
    itemCode: "c_fcc209cd-d12e-4b78-9e89-761cbc4f1752",
    billingDate: "c_98d79efe-1c3c-49d5-b28b-eb583fe9fa67",
    budgetLineName: "c_1149bf13-7c25-476e-8f7d-186dd8489ed9",
    amountAuthorized: "c_0b021005-6c9c-4739-a4f3-47cf63811076",
    amountRequested: "c_cff84ba7-c290-45a8-af4d-656213d180f1",
    itemNumber: "c_96d9db0c-2e98-4812-8d33-d5b9095c6683",
    invoiceNumber: "c_1234649c-638d-4d52-ab62-12dd5e93e964",
    islocked: "c_86140b83-89e5-4b87-902b-e8b16b0b6fb0",
    dueDate: "c_8fa13307-1998-47a9-be33-8849bb254a5c",
    invoiceStartDate: "c_10a525da-5ae7-4176-9872-061c8eb51557",
    invoiceEndDate: "c_4c3bf6a1-ff35-4e61-ae52-51e155c7c138",
    amountDue: "c_996e6092-e781-44d6-a2f6-a7fec5888a3c",
    itemDescription: "c_03e5d9e5-d5c8-4419-b097-cdc5b0c89a52",
    dateEntered: "c_6eba4027-b459-484e-9280-4787c2cce534",
    dateEdited: "c_237d253b-d3af-4875-9b4a-8f2020462878",
};

const Grid = memo(({ utilityNumber, contractNumber, documentNumber, onRefresh }) => {
    const dataGridId = getInvoiceItemsGridId({ documentNumber });
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const rowActions = [
        {
            name: "edit",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
            title: isLocked ? "View" : "Edit",
            footerExclude: true,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Delete",
            hide: isLocked,
            footerName: "Delete",
        },
    ];

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.invoiceItems,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "invoiceItemsGridGetDataAction",
            props: {
                columnKeysMap: invoiceItemsGridColumnKeys,
                utilityNumber,
                contractNumber,
                documentNumber,
            },
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "utility-invoice-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(invoiceItemsGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        documentNumber={documentNumber}
                        gridRefresh={onRefresh}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            case "delete":
                onDeleteInvoiceItem({
                    utilityNumber,
                    contractNumber,
                    documentNumber,
                    dataItem: data,
                    onRefresh,
                });
                break;
            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default Grid;
