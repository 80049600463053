import React, { memo, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import JsonSchemaForm from "../Form/JsonSchema/JsonSchemaForm";
import { onColumnPropertiesChange, useCurrentPosition } from "./utils";
import { getSelectedElementUiParams, getSelectedElement, getFormBuilder } from "../../../store/formBuilder/selectors";

const ColumnProperties = memo(({ formRef, instanceId, disabled, onChange }) => {
    const formBuilder = useSelector((state) => getFormBuilder({ instanceId, state: state.formBuilder }));
    const selectedElementId = formBuilder.selectedElementId;

    const element = useMemo(() => {
        return getSelectedElement(formBuilder);
    }, [formBuilder]);

    const elementUiParams = useMemo(() => {
        return getSelectedElementUiParams(formBuilder);
    }, [formBuilder]);

    const onPropertiesChange = useCallback(
        (props) => {
            onColumnPropertiesChange({
                instanceId,
                selectedElementId,
                ...props,
            });
            onChange && onChange({ instanceId, selectedElementId, ...props });
        },
        [instanceId, selectedElementId, onChange]
    );

    const currentPosition = useCurrentPosition({
        selectedElementId,
        elementUiParams,
    });

    const schema = {
        type: "object",
        required: ["order"],
        properties: {
            title: {
                type: "string",
                title: "Title",
            },
            description: {
                type: "string",
                title: "Description",
            },
            order: {
                type: "integer",
                title: "Order",
            },
        },
    };

    const uiSchema = {
        description: {
            "ui:widget": "html",
        },
    };

    const initialValues = {
        title: element["title"],
        description: element["description"],
        order: currentPosition,
    };

    return (
        <div className="element-properties fill-width with-scroll">
            <JsonSchemaForm
                formRef={formRef}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                disabled={disabled}
                onChange={onPropertiesChange}
                noActions
                liveValidate={!isNil(formBuilder.validationErrors[elementUiParams["af:columnNumber"]])}
                debounce
            />
        </div>
    );
});

export default ColumnProperties;
