import React, { memo, useRef } from "react";

import { entityType } from "../../utils/entityType";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import IconWrap from "../../ui/Icons";
import FileUpload from "../../ui/FileUpload";
import ContentTitle from "../../ui/Title/ContentTitle";
import { applicationFilesGridColumnKeys, availableGrids } from "../configureGrids";
import { useResource } from "store/resources/useResource";

import "./style.scss";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import SideNavFormContainer from "components/ui/SideNavFormContainer";
import { useCanSplitScreenView } from "components/utils/useCanSplitScreenView";
import { getGridId } from "components/ui/Dashboard/Panel/Project/DocumentsPanel/utils";
import { SortType } from "components/utils/datagrid";
import FilesGrid from "components/ui/DataGrid/FilesGrid";
import { refreshGrid } from "store/dataGrid/refreshGrid";
import { useDataGrid } from "components/utils/useDataGrid";
import WaitIcon from "components/ui/WaitIcon";

const ApplicationFiles = memo(({ programNumber, applicationNumber, utilityNumber }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "file-upload-sidenav-panel",
    });
    const fileUploadComponentRef = useRef();
    const entityTypeId = 71;
    const canUseSplitScreenView = useCanSplitScreenView();
    const fileGridId = getGridId({ applicationNumber, type: "applicationFiles" });
    const [gridConfig, gridConfigLoading] = useDataGrid({
        dataGridId: availableGrids.applicationFiles,
        dataGridInstanceId: fileGridId,
        sorting: {
            type: SortType.CLIENT,
            sort: [
                {
                    field: applicationFilesGridColumnKeys.date,
                    dir: "desc",
                },
            ],
        },
        getDataAction: {
            type: "filesGridGetDataAction",
            props: {
                columnKeysMap: applicationFilesGridColumnKeys,
                gridId: fileGridId,
                applicationNumber,
            },
        },
    });

    const [files] = useResource({
        resourceName: "fileUploadReqs",
        key: entityTypeId,
        path: {
            entityTypeId: 71,
        },
    });
    const handleFileUploadSuccess = () => {
        handleCloseSidePanel();
        refreshGrid({ dataGridId: fileGridId });
    };

    const handleFileUploadOpen = () => {
        handleOpenSidePanel(
            <SideNavFormContainer
                title="File Upload"
                onClose={handleCloseSidePanel}
                forwardedFormComponentRef={fileUploadComponentRef}
                submitText="Upload"
                titleIcon="upload"
                fileUpload
            >
                <FileUpload
                    ref={fileUploadComponentRef}
                    withinSideNav
                    utilityNumber={utilityNumber}
                    entityTypeId={entityType.projectApplication}
                    withoutHeaderPanel
                    entityId={applicationNumber}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    useApplicationFileTags={true}
                    onCancel={handleCloseSidePanel}
                    onUploadSuccess={handleFileUploadSuccess}
                    files={files}
                />
            </SideNavFormContainer>
        );
    };

    return (
        <div className="new-app-files">
            <div className="new-app-section">
                <div className="section-name">
                    <IconWrap iconWrapBig iconWrapTheme iconWrap="files"></IconWrap>
                    <ContentTitle>Files</ContentTitle>
                </div>
                <div className="new-app-section__form">
                    <div className="file-controls flex-one flex-row justify-end">
                        <IconWithLabel iconWithLabelRight iconWithLabel={"upload"} onClick={handleFileUploadOpen}>
                            Upload Files
                        </IconWithLabel>
                    </div>
                    {gridConfigLoading ? (
                        <WaitIcon />
                    ) : (
                        <FilesGrid
                            dataGridConfig={gridConfig}
                            gridColumnKeys={applicationFilesGridColumnKeys}
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            gridId={fileGridId}
                            applicationNumber={applicationNumber}
                            entityId={applicationNumber}
                            entityTypeId={entityType.projectApplication}
                            canUseSplitScreenView={canUseSplitScreenView}
                            isApplicationGrid
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default ApplicationFiles;
