import React, { memo } from "react";

import ActionLabel from "../../../../Label/ActionLabel";
//import { getAttributeValue } from "../../../../../utils/workflow";

export const FlagRecordTask = memo(({ task }) => {
    const onClick = () => {
        //const flagCode = getAttributeValue("Application Flag Code", task);
    };

    return <ActionLabel onClick={onClick}>{task.step}</ActionLabel>;
});
