import React, { memo } from "react";
import cn from "classnames";

import { RebateApprovalsLevels } from "./RebateApprovalsLevels";
import { formatMoney } from "../../../../../utils/money";
import IconWrap from "../../../../Icons";
import { formatJsonDate } from "components/utils/date";

import "./RebateApprovalsItem.scss";

const GRID_HEAD_CELLS = new Map([
    ["billAccount", "Bill Account"],
    ["receivedDate", "Received Date"],
    ["projectNameId", "Project Name & ID"],
    ["measure", "Measure"],
    ["kwImpact", "KW Impact"],
    ["kwhImpact", "KWH Impact"],
    ["thermImpact", "Therm Impact"],
    ["peakThermImpact", "Peak Therm Impact"],
    ["measureIncentive", "Measure Incentive"],
    ["projectIncentive", "Project Incentive"],
]);

export const RebateApprovalsItem = memo(
    ({
        onClickOpenProject,
        onSetActiveRebateApprovalLevel,
        onClickApprove,
        onClickReject,
        payeeName,
        programName,
        projectList,
        rebateTotal,
        approvedLevels,
        pendingLevels,
        rejectEnabled,
        activeRebateApprovalsLevels,
        approvalStatus,
    }) => {
        return (
            <div className="rebate-approvals__item flex-row">
                <div className={"status flex-column justify-center" + (approvalStatus === "Pending" ? " pending" : "")}>
                    <span>
                        <IconWrap icon={approvalStatus === "Pending" ? "clock-time-watch-later-filled" : "check-circle-filled"} />
                        {approvalStatus}
                    </span>
                </div>
                <div className="flex-column flex-one-in-row">
                    <RebateApprovalsItemHead payeeName={payeeName} programName={programName} rebateTotal={rebateTotal} />
                    <RebateApprovalsProjectList projectList={projectList} onClickOpenProject={onClickOpenProject} />
                    <RebateApprovalsLevels
                        approvedLevels={approvedLevels}
                        pendingLevels={pendingLevels}
                        activeRebateApprovalsLevels={activeRebateApprovalsLevels}
                        rejectEnabled={rejectEnabled}
                        onSetActiveRebateApprovalLevel={onSetActiveRebateApprovalLevel}
                        onClickApprove={onClickApprove}
                        onClickReject={onClickReject}
                    />
                </div>
            </div>
        );
    }
);

const RebateApprovalsItemHead = ({ payeeName, programName, rebateTotal }) => {
    return (
        <div className={cn("rebate-approvals__item-head flex-row")}>
            <div className="rebate-approvals__item-head-entry flex-column flex-one">
                <span className="cell-label">Payee:</span>
                <span className="cell-content">{payeeName}</span>
            </div>
            <div className="rebate-approvals__item-head-entry rebate-approvals__item-head-entry--program flex-row align-center">
                <span className="cell-label">Program:</span>
                <span className="cell-content">{programName}</span>
            </div>
            <div className="rebate-approvals__item-head-entry rebate-approvals__item-head-entry--sum flex-row align-center flex-one justify-end">
                <span className="cell-label">Total:</span>
                <span className="cell-content">{formatMoney(rebateTotal)}</span>
            </div>
        </div>
    );
};

const RebateApprovalsProjectList = ({ projectList, onClickOpenProject }) => {
    // Return row per project
    return projectList.map((project, index) => (
        <RebateApprovalsProjectListItem key={index} projectList={[project]} onClickOpenProject={onClickOpenProject} />
    ));
};

const RebateApprovalsProjectListItem = ({ projectList, onClickOpenProject }) => {
    return (
        <div className="rebate-approvals__project rebate-approvals__item-columns">
            <div className="rebate-approvals__item-column rebate-approvals__item-column--received-date">
                {projectList.map((project, index) => (
                    <div key={index} className="rebate-approvals__item-body-cell-sub flex-column">
                        <span className="cell-label">Received Date</span>
                        {formatJsonDate(project.receivedDate, "-")}
                    </div>
                ))}
            </div>

            <div className="rebate-approvals__item-column rebate-approvals__item-column--bill-account">
                {projectList.map((project, index) => (
                    <div key={index} className="rebate-approvals__item-body-cell-sub flex-column">
                        <span className="cell-label">Bill Account</span>
                        {project.acctNumber}
                    </div>
                ))}
            </div>

            <div className="rebate-approvals__item-column rebate-approvals__item-column--project-name">
                {projectList.map((project, index) => (
                    <div key={index} className="rebate-approvals__item-body-cell-sub flex-column">
                        <span className="cell-label">Project Name & ID</span>
                        <span className="cell-value">{project.projectName}</span>
                        <span className="link" onClick={() => onClickOpenProject(project.appId)}>
                            {project.projectId}
                        </span>
                    </div>
                ))}
            </div>

            <Measures projectList={projectList} />

            <div className="rebate-approvals__item-column rebate-approvals__item-column--project-incentive">
                {projectList.map((project, index) => (
                    <div key={index} className="rebate-approvals__item-body-cell-sub flex-column">
                        <span className="cell-label">Project Incentive</span>
                        <span className="cell-value money">{formatMoney(project.projectIncentive)}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const Measures = ({ projectList }) => {
    // Use row per measure
    const rowColumn = ({ className, measureItem, property }) => (
        <div className={cn("rebate-approvals__item-column", className)}>
            <MeasureColumn list={[measureItem]} property={property} />
        </div>
    );

    return (
        <div className={cn("rebate-approvals__item-column", "rebate-approvals__item-column--measure-rows")}>
            {projectList.map((project) =>
                project.measuresList.map((measureItem, index) => (
                    <div key={index} className="rebate-approvals__item-row">
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--measure",
                            property: "measure",
                        })}
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--kw-impact",
                            property: "kwImpact",
                        })}
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--kwh-impact",
                            property: "kwhImpact",
                        })}
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--therm-impact",
                            property: "thermImpact",
                        })}
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--peak-therm-impact",
                            property: "peakThermImpact",
                        })}
                        {rowColumn({
                            measureItem,
                            className: "rebate-approvals__item-column--measure-incentive",
                            property: "measureIncentive",
                        })}
                    </div>
                ))
            )}
        </div>
    );
};

const MeasureColumn = ({ list, property }) =>
    list.map((item, index) => {
        if (item.hasOwnProperty(property)) {
            const label = GRID_HEAD_CELLS.get(property);
            const value = property === "measureIncentive" ? formatMoney(item[property]) : (item[property] ?? 0).toLocaleString();

            return (
                <div key={index} className={cn("rebate-approvals__item-body-cell-sub")}>
                    <span className="cell-label">{label}</span>
                    <span className="cell-value" title={item[property]}>
                        {value}
                    </span>
                </div>
            );
        }

        return null;
    });
