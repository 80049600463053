import React from "react";
import TextLabel from "components/ui/Label/TextLabel";

import "./WidgetHeader.scss";

export const PreviewArea = ({ props }: any) => {
    return (
        <div className="preview-area flex-column">
            <TextLabel>Preview Area</TextLabel>
            <div className="preview-area-content flex-row justify-center align-center"></div>
        </div>
    );
};
