import React, { memo, useCallback, useRef } from "react";
import Input from "components/ui/Input";
import { isEmpty, isNil } from "lodash";
import PropertyErrors from "./PropertyErrors";
import PropertyDescription from "./PropertyDescription";
import { useShowError } from "components/ui/PortalBuilder/utils";
import { PropertyTitle } from "./PropertyTitle";
import { isPropertyRequired } from "components/ui/PortalBuilder/utils/validation";
import { PropertyListItem } from "./PropertyListItem";
import DropDownInput from "components/ui/Input/DropDownInput";
import { SwitchProperty } from "./SwitchProperty";
import "./LeadImageUrl.scss";
import { LinkType } from "../../types";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

export const LeadImageUrl = memo((props) => {
    const {
        title,
        id,
        value,
        description,
        maxLength,
        onChange,
        borderTop,
        borderBottom,
        validationRules,
        errors = [],
        listContainsActiveError,
        infoIconText,
        parentValue,
        disabled,
    } = props;
    const required = isPropertyRequired(validationRules);

    const elementRef = useRef(null);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const links = updatedConfig.content.pages.map((p) => {
        return {
            label: p.link,
            value: p.link,
        };
    });

    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    const onPropertyChange = useCallback(
        (event) => {
            onChange(id, event.target.value);
        },
        [id, onChange]
    );

    const onTargetPropertyChange = useCallback(
        (id, value) => {
            const val = id === "target" ? "_blank" : true;
            if (!value) {
                onChange(id, false);
            } else {
                onChange(id, val);
            }
        },
        [onChange]
    );

    if (props.hidden) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--lead-image-url" borderTop={borderTop} borderBottom={borderBottom}>
            <div ref={elementRef} className={"property-list-item-inner align-center flex-column full-width-property"}>
                {title && <PropertyTitle title={title} required={required} infoIconText={infoIconText} />}
                <SwitchProperty
                    title={"Select from existing pages"}
                    id={"internalPageSelect"}
                    value={parentValue?.internalPageSelect ?? false}
                    onChange={onChange}
                    borderBottom={false}
                    fullWidth={false}
                    className="fill-width"
                    hidden={parentValue.linkType !== LinkType.INTERNAL && !isNil(parentValue.linkType)}
                />
                {parentValue.internalPageSelect && (parentValue.linkType === LinkType.INTERNAL || isNil(parentValue.linkType)) ? (
                    <DropDownInput
                        className="property-list-item-value"
                        emptyItem
                        emptyItemLabel="Not selected"
                        placeholder="Select url"
                        data={links}
                        value={isEmpty(value) ? undefined : value}
                        onChange={onPropertyChange}
                    />
                ) : (
                    <Input
                        error={errors.length > 0}
                        lightUpError={lightUpError}
                        className="property-list-item-value fill-width"
                        value={value ?? ""}
                        onChange={onPropertyChange}
                        disabled={disabled}
                        maxLength={maxLength}
                    />
                )}
            </div>
            <PropertyDescription id={id} value={value} description={description} />
            <PropertyErrors errors={errors} />
            {parentValue.linkType === LinkType.EXTERNAL && (
                <SwitchProperty
                    title={"Open in the new Tab"}
                    id={"target"}
                    value={parentValue.target ?? false}
                    onChange={(id, value) => onTargetPropertyChange(id, value)}
                    borderBottom={false}
                    fullWidth={true}
                />
            )}
        </PropertyListItem>
    );
});
