import React, { useState } from "react";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../Button";
import { onAssign } from "./ContactsUtils";
import { Localize } from "components/utils/text";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";

export const ContractorPreview = ({ applicationNumber, contractor, onClose }) => {
    const [isSubmitting, setIsSubmitting] = useState();

    const schema = {
        type: "object",
        required: ["company"],
        properties: {
            company: {
                type: ["string", "null"],
                title: "Company",
            },
            firstName: {
                type: ["string", "null"],
                title: "First Name",
            },
            lastName: {
                type: ["string", "null"],
                title: "Last Name",
            },
            address: {
                type: ["string", "null"],
                title: "Address",
            },
            city: {
                type: ["string", "null"],
                title: "City",
            },
            state: {
                type: ["string", "null"],
                title: Localize.STATE,
            },
            zip: {
                type: ["string", "null"],
                title: "Postal Code",
            },
            phone: {
                type: ["string", "null"],
                title: "Phone",
            },
            email: {
                type: ["string", "null"],
                title: "Email",
            },
            technologies: {
                type: ["string", "null"],
                title: "Technologies",
            },
            services: {
                type: ["string", "null"],
                title: "Services",
            },
        },
    };

    const uiSchema = {
        technologies: {
            "ui:widget": "textarea",
        },
        services: {
            "ui:widget": "textarea",
        },
    };

    const onAssignContractor = () => {
        setIsSubmitting(true);
        onAssign({
            applicationNumber,
            contactNumber: contractor.contactNumber,
            assignType: "Contractor",
            onComplete: () => {
                setIsSubmitting(false);
                onClose();
            },
        });
    };

    const assignText = isSubmitting ? "Assigning..." : "Assign";

    return (
        <SideNavContent>
            <SideNavHeader title="Assign Contractor" leadBlockIcon="plus" smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm initialValues={contractor} schema={schema} uiSchema={uiSchema} readOnly noCancel noReset />
            </SideNavBody>

            <SideNavFooter setPrimaryButton>
                <Button onClick={onAssignContractor} primary disabled={isSubmitting}>
                    {assignText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};
