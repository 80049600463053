import React from "react";
import cn from "classnames";
import FieldGroup from "components/ui/FieldGroup";
import DropDownInput from "components/ui/Input/DropDownInput";
import TextInput from "components/ui/Input/TextInput";
import Separator from "components/ui/Separator";
import { ErrorMessage, WarningMessage } from "components/ui/Message";
import Label from "components/ui/Label";
import { toArray } from "components/utils/array";
import DateRangeValidationWidget from "../DateRangeValidationWidget";
import { isEmpty, isNil } from "lodash";
import { DatePickerPortal } from "components/ui/Input/DatePicker";

const allConditions = ["empty", "notEmpty", "equal", "notEqual", "less", "lessEq", "greater", "greaterEq", "anyOf", "notAnyOf", "between"];

const conditionsWithoutValue = ["empty", "notEmpty"];
const conditionsWithMultipleValues = ["anyOf", "notAnyOf", "between"];

const numericConditions = ["less", "lessEq", "greater", "greaterEq", "between"];
const booleanConditions = ["empty", "notEmpty"];

const ConditionalValidationRuleWidget = ({ classNames, options, value, rawErrors, onChange }: ConditionalValidationRuleWidgetProps) => {
    const { otherFields } = options;

    const errors = parseErrors(rawErrors);
    const fieldError = errors.field;
    const conditionError = errors.condition;
    const valueError = errors.value;

    const [currentField, currentCondition, currentValue] = processValue(value);
    const currentFieldData = otherFields.find((f) => f.id === currentField);
    const fieldFormat = currentFieldData?.format ?? "";
    const isDateField = ["date", "date-time"].includes(fieldFormat);

    const fieldDropdownItems = otherFields
        .map((f) => ({
            label: f.title ?? "",
            value: f.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const conditionDropdownItems = getConditions(currentField, otherFields).map((c) => ({
        label: getConditionTitle(c),
        value: c,
    }));

    const numericFields = otherFields.filter((f) => ["integer", "number"].includes(f.type)).map((f) => f.id);
    const showConditionWarning = !isDateField && !numericFields.includes(currentField) && numericConditions.includes(currentCondition);
    const showValue = currentCondition && !conditionsWithoutValue.includes(currentCondition);
    const showMultipleValues = conditionsWithMultipleValues.includes(currentCondition);

    const onFieldChange = (fieldValue: string) => {
        onChange(JSON.stringify({ field: fieldValue, condition: currentCondition, value: currentValue }));
    };

    const onConditionChange = (conditionValue: string) => {
        onChange(JSON.stringify({ field: currentField, condition: conditionValue, value: currentValue }));
    };

    const onValueChange = (newValue: string | string[]) => {
        onChange(JSON.stringify({ field: currentField, condition: currentCondition, value: newValue }));
    };

    return (
        <div className={classNames}>
            <DropDownInput
                // @ts-ignore
                data={fieldDropdownItems}
                label={"Field"}
                mobileHeader={"Field"}
                placeholder={"-- SELECT --"}
                value={currentField}
                required
                onChange={(e: ChangeEvent) => onFieldChange(e.target.value)}
                msgError={!isEmpty(fieldError)}
                msgText={fieldError}
            />
            <Separator />
            <DropDownInput
                // @ts-ignore
                data={conditionDropdownItems}
                label={"Condition"}
                mobileHeader={"Condition"}
                placeholder={"-- SELECT --"}
                value={currentCondition}
                required
                msgError={!isEmpty(conditionError)}
                msgText={conditionError}
                onChange={(e: ChangeEvent) => onConditionChange(e.target.value)}
            />
            {showConditionWarning && <WarningMessage marginTop>Selected condition is for numeric fields only</WarningMessage>}
            {showValue && (
                <>
                    <Separator />
                    {showMultipleValues ? (
                        <MultiValueInput
                            value={toArray(currentValue)}
                            condition={currentCondition}
                            format={fieldFormat}
                            onChange={onValueChange}
                            error={valueError}
                        />
                    ) : (
                        <FieldGroup>
                            <Label labelRequired={isDateField}>Value</Label>
                            <SingleValueInput value={currentValue} format={fieldFormat} onChange={onValueChange} error={valueError} />
                        </FieldGroup>
                    )}
                </>
            )}
        </div>
    );
};

const SingleValueInput = ({
    value,
    format,
    error,
    onChange,
}: {
    value: string;
    format: string;
    error?: string;
    onChange: (value: string) => void;
}) => {
    const isDateField = ["date", "date-time"].includes(format);
    const isRequired = isDateField;

    if (isDateField) {
        return (
            <>
                <DateRangeValidationWidget
                    value={value}
                    onChange={onChange}
                    options={{ showInfo: false }}
                    schema={{ format }}
                    popperContainer={DatePickerPortal}
                />
                {error && <ErrorMessage marginTop>{error}</ErrorMessage>}
            </>
        );
    }

    return (
        <TextInput
            // @ts-ignore
            value={value}
            onChange={(e: ChangeEvent) => onChange(e.target.value)}
            required={isRequired}
            error={!isEmpty(error)}
            msgText={error}
        />
    );
};

const MultiValueInput = ({
    value,
    format,
    condition,
    error,
    onChange,
}: {
    value: string[];
    format: string;
    condition: string;
    error?: string;
    onChange: (value: string[]) => void;
}) => {
    const onAdd = () => {
        onChange([...value, ""]);
    };

    const onRemove = (index: number) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
    };

    const onValueChange = (newValue: string, index: number) => {
        const newValueList = [...value];
        newValueList[index] = newValue;
        onChange(newValueList);
    };

    const isBetween = condition === "between";
    const values = isBetween ? [value[0], value[1]] : value;

    return (
        <div className="form-group field field-array">
            <fieldset className="field field-array">
                <legend>
                    <span>Values</span>
                    <span className="required"></span>
                </legend>
                <div className="row array-item-list">
                    {values.map((v, index) => (
                        <div key={index} className="array-item">
                            <div className="schema-container">
                                <div
                                    className={cn("form-group field", {
                                        "form-group--between": isBetween,
                                    })}
                                >
                                    <div className="field-group">
                                        <div className="field-wrap">
                                            <SingleValueInput
                                                value={v}
                                                format={format}
                                                onChange={(e) => onValueChange(e, index)}
                                                error={isEmpty(v) ? error : undefined}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!isBetween && (
                                <div className="col-xs-3 array-item-toolbox">
                                    <button
                                        type="button"
                                        className="btn btn-danger array-item-remove"
                                        onClick={() => onRemove(index)}
                                    ></button>
                                </div>
                            )}
                        </div>
                    ))}
                    {(values.length === 0 || (isBetween && values.every((v) => !isNil(v)))) && error && (
                        <ErrorMessage marginTop marginBottom>
                            {error}
                        </ErrorMessage>
                    )}
                </div>
                {!isBetween && (
                    <div className="row">
                        <p className="col-xs-3 col-xs-offset-9 text-right array-item-add">
                            <button type="button" className="btn btn-info btn-add col-xs-12" onClick={onAdd}></button>
                        </p>
                    </div>
                )}
            </fieldset>
        </div>
    );
};

const processValue = (rule: Rule) => {
    let field = null;
    let condition = null;
    let value = null;

    if (typeof rule === "string") {
        const result = JSON.parse(rule);
        field = result?.field;
        condition = result?.condition;
        value = result?.value;
    } else {
        field = rule?.field;
        condition = rule?.condition;
        value = rule?.value;
    }

    return [field, condition, value];
};

const getConditions = (currentField: string, otherFields: OtherField[]) => {
    const booleanFields = otherFields.filter((f) => ["boolean"].includes(f.type)).map((f) => f.id);

    if (booleanFields.includes(currentField)) {
        return booleanConditions;
    }

    return allConditions;
};

const getConditionTitle = (condition: string) => {
    switch (condition) {
        case "empty":
            return "is empty";
        case "notEmpty":
            return "is not empty";
        case "equal":
            return "is equal to";
        case "notEqual":
            return "is not equal to";
        case "less":
            return "is less than";
        case "lessEq":
            return "is less than or equal to";
        case "greater":
            return "is greater than";
        case "greaterEq":
            return "is greater than or equal to";
        case "anyOf":
            return "is equal to any of";
        case "notAnyOf":
            return "is not equal to any of";
        case "between":
            return "is between";
        default:
            return condition;
    }
};

const parseErrors = (rawErrors: string[] | undefined) => {
    if (!rawErrors) {
        return {};
    }

    let errors: { [key: string]: string } = {};

    try {
        errors = JSON.parse(rawErrors[0] ?? "{}");
    } catch (e) {
        errors = {};
    }

    return errors;
};

type Rule = string | undefined | { field?: string; condition?: string; value?: string };
type OtherField = { id: string; title: string; type: string; format: string };
type ChangeEvent = { target: { value: string } };

export interface ConditionalValidationRuleWidgetProps {
    classNames?: string;
    options: {
        otherFields: OtherField[];
    };
    value?: Rule;
    rawErrors?: string[];
    onChange: (value: Rule) => void;
}

export default ConditionalValidationRuleWidget;
