import React from "react";
import AllProgramAttributesGrid from "./AllProgramAttributesGrid";

const AllProgramAttributesPanel = () => {
    const gridId = "all-program-attributes";

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="panel budget-attributes flex-column fill-height no-scroll">
                <AllProgramAttributesGrid gridId={gridId} filterRequired={true} />
            </div>
        </div>
    );
};

export default AllProgramAttributesPanel;
