import { store } from "../../../../../../store/configureStore";
import { updateResource } from "../../../../../../store/resources/actions";
import { openAssignmentModal } from "../../../../AssignmentSelector/utils";
import { refreshProgramCalculationsGrid } from "../../../../../../store/dataGrid/refreshGrid";

export const getProgramCalculationsGridId = ({ utilityNumber, programNumber }) => {
    return `${utilityNumber}-${programNumber}-program-calculations-grid`;
};

export const openProgramCalculationsAssignmentsModal = ({ utilityNumber, programNumber }) => {
    const resourceDataPath = "data.formFieldAssignmentsList";
    const title = "Program Calculation Assignment";
    const submitButtonText = "Apply";
    const idKey = "calculationNumber";
    const nameKey = "calculationName";

    const resourceGetParams = {
        resourceName: "programCalculationsAssignments",
        key: `${programNumber}`,
        query: {
            programNumber,
        },
    };

    const onSelect = (calculationAssociations) => {
        const calculationNumbers = calculationAssociations.map((r) => r.calculationNumber);

        store.dispatch(
            updateResource({
                resourceName: "programCalculationsAssignments",
                query: {
                    programNumber,
                },
                body: {
                    calculationNumbers,
                },
                onSuccess: () =>
                    refreshProgramCalculationsGrid({
                        utilityNumber,
                        programNumber,
                    }),
            })
        );
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        submitButtonText,
        idKey,
        nameKey,
        onSelect,
    });
};
