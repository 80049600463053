import { memo, useMemo, useContext } from "react";
import WaitIcon from "../../../../WaitIcon";
import { getAttributeValue } from "../../../../../utils/workflow";
import { useApplicationFormDetails } from "../../../../../../store/resources/useResource";
import ApplicationForm from "components/ui/Dashboard/Panel/Project/ApplicationsPanel/ApplicationForm";
import { TaskWorkflowContext } from "../../../context/TaskWorkflowContext";
import { getFormSectionFields } from "components/ui/Workflow/utils/utils";

export const ApplicationFormSectionEntryTask = memo(({ applicationNumber, wfTaskNumber, task, onSubmitSuccess, onCancel, editMode }) => {
    const { programNumber } = useContext(TaskWorkflowContext);

    const applicationFormId = useMemo(() => getAttributeValue("Form Page Number", task), [task]);
    const applicationFormSectionId = useMemo(() => getAttributeValue("Form Section Number", task), [task]);
    const formId = `task-workflow-${wfTaskNumber}-${applicationNumber}`;

    const [form, isLoadingFormDetails] = useApplicationFormDetails({
        applicationNumber,
        applicationFormId,
        forced: true,
    });
    const fields = useMemo(() => getFormSectionFields(form, applicationFormSectionId), [applicationFormSectionId, form]);

    if (isLoadingFormDetails) {
        return <WaitIcon />;
    }

    return (
        <ApplicationForm
            formId={formId}
            programNumber={programNumber}
            applicationNumber={applicationNumber}
            applicationFormId={applicationFormId}
            loading={isLoadingFormDetails}
            visibleFieldIds={fields}
            editMode={editMode}
            taskNumber={wfTaskNumber}
            onSubmitSuccess={onSubmitSuccess}
            onCancel={onCancel}
        />
    );
});
