import React, { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RolesList } from "components/ui/List/RolesList";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { exportProgramList, getUserListInfo, setUtilityLevelRoleForSelection } from "./utils";
import { RolesByUserSideNav } from "./RolesByUserSideNav";
import { useResource } from "store/resources/useResource";
import { getClientUsersResourceParams } from "store/configureResources";
import { ChangeUtilityLevelRoleTitle, RoleChangeModalMessage } from "./RoleChangeModalMessage";

export const RolesByUser = memo(({ clientNumber, clientName, utilityNumber, roles, programs }) => {
    const dispatch = useDispatch();
    const utilityName = useSelector((state) => state.resources.utilities.itemsById[utilityNumber]?.utility);

    const [users] = useResource({
        ...getClientUsersResourceParams({ clientNumber, utilityNumber }),
        transform: (data) => data?.clientUserList ?? [],
    });

    const items = useMemo(
        () =>
            (users ?? []).map((item) => ({
                itemId: item.userNumber,
                itemName: item.userName,
            })),
        [users]
    );

    const itemInfoRef = useRef();

    const itemInfo = useMemo(() => {
        const result = getUserListInfo({
            utilityNumber,
            roles,
            programs,
            users,
        });
        itemInfoRef.current = result;
        return result;
    }, [utilityNumber, roles, programs, users]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();

    const onManageItems = useCallback(
        (selectedItems) => {
            const selectedUserNumbers = Object.keys(selectedItems).filter((key) => selectedItems[key]); // get values that are not null

            handleOpenSidePanel(
                <RolesByUserSideNav
                    clientNumber={clientNumber}
                    clientName={clientName}
                    utilityNumber={utilityNumber}
                    roles={roles}
                    selectedUserNumbers={selectedUserNumbers}
                    programs={programs}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [clientNumber, clientName, utilityNumber, roles, programs, handleOpenSidePanel, handleCloseSidePanel]
    );

    const onHeaderRoleClick = useCallback(
        ({ roleId, roleName, selectedItems }) => {
            const userNumbers = selectedItems?.length > 0 ? selectedItems : users.map((i) => i.userNumber);

            setUtilityLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                userNumbers,
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeUtilityLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        userList={users.filter((i) => userNumbers.some((userNumber) => userNumber === i.userNumber))}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, users, dispatch]
    );

    const onListItemRoleClick = useCallback(
        ({ itemId, roleId }) => {
            const roleName = roles.find((i) => i.roleId === roleId)?.roleName;

            setUtilityLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers: [itemId],
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeUtilityLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        userList={users.filter((i) => i.userNumber === itemId)}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, users, roles, dispatch]
    );

    const onListItemRoleBadgeClick = useCallback((data) => {
        const { roleId, itemData } = data;
        const programs = itemData.roleAssignments[roleId];

        if (programs) {
            exportProgramList({ programs });
        }
    }, []);

    const onGetListItemInfo = useCallback(async (itemId, refresh) => {
        return itemInfoRef.current[itemId];
    }, []);

    const roleButtonTitle = useCallback(({ isHighlighted }) => {
        return isHighlighted ? "Default Role" : "Set as default role";
    }, []);

    const roleBadgeTitle = useCallback(({ roleAssignments, roleName }) => {
        return `${roleAssignments} program${[1].includes(roleAssignments) ? "" : "s"} in ${roleName} role`;
    }, []);

    const roleHeaderButtonTitle = useCallback(({ isAnyItemSelected }) => {
        return isAnyItemSelected ? "Set as default role for selected users" : "Set as default role for all users";
    }, []);

    return (
        <RolesList
            listItemsTitle="Users"
            roles={roles}
            listItems={items}
            listItemInfo={itemInfo}
            roleHeaderButtonTitle={roleHeaderButtonTitle}
            roleBadgeTitle={roleBadgeTitle}
            roleButtonTitle={roleButtonTitle}
            onHeaderRoleClick={onHeaderRoleClick}
            onListItemRoleClick={onListItemRoleClick}
            onListItemRoleBadgeClick={onListItemRoleBadgeClick}
            onGetListItemInfo={onGetListItemInfo}
            manageSelectedItemsTitle="Manage"
            manageSelectedItemsTooltip="Select users to manage program roles"
            onManageSelectedItems={onManageItems}
        />
    );
});
