import React, { useCallback, useMemo, memo } from "react";

import WaitIcon from "../../../../ui/WaitIcon";
import { DocumentList } from "./DocumentList";
import Checkbox from "../../../../ui/Input/Checkbox";
import IconWrap from "../../../../ui/Icons";

import "./EntryRow.scss";

export const EntryRow = memo(
    ({
        children,
        documentComponent,
        entryNumber,
        selectedEntryNumbers,
        loadingEntryNumbers,
        expandedEntries,
        documents,
        selectedEntryDocumentsNumbers,
        isBatchFilled,
        isBatchProcessing,
        isSelectionDisabled,
        withoutSelection,
        onClick,
        onSelect,
        total,
    }) => {
        const checked = useMemo(
            () => !withoutSelection && selectedEntryNumbers.includes(entryNumber),
            [selectedEntryNumbers, entryNumber, withoutSelection]
        );

        const isLoading = useMemo(() => loadingEntryNumbers.includes(entryNumber), [entryNumber, loadingEntryNumbers]);

        const isExpanded = useMemo(() => expandedEntries.includes(entryNumber), [expandedEntries, entryNumber]);

        const selectedDocumentsCount = useMemo(() => {
            if (!withoutSelection && entryNumber in documents) {
                return documents[entryNumber].reduce((acc, { documentNumber }) => {
                    if (selectedEntryDocumentsNumbers.some((item) => item.documentNumber === documentNumber)) {
                        acc++;
                    }

                    return acc;
                }, 0);
            }

            return null;
        }, [entryNumber, documents, selectedEntryDocumentsNumbers, withoutSelection]);

        const isPartialyChecked = useMemo(
            () => documents[entryNumber] && documents[entryNumber].length !== selectedDocumentsCount,
            [documents, entryNumber, selectedDocumentsCount]
        );

        const handleClick = useCallback(
            (event) => {
                event.stopPropagation();

                if (!(event.target.classList.contains("checkbox") || event.target.classList.contains("checkbox-unchecked-empty"))) {
                    onClick(entryNumber);
                }
            },
            [entryNumber, onClick]
        );

        const handleSelect = useCallback(
            (e) => {
                e.stopPropagation();

                if (!isLoading) {
                    onSelect(entryNumber);
                }
            },
            [entryNumber, onSelect, isLoading]
        );

        return (
            <div className="application-document-queue__grid-entry-row" onClick={handleClick}>
                <div
                    className={
                        "application-document-queue__grid-entry-row-container flex-row align-center" + (isExpanded ? " expanded" : "")
                    }
                >
                    {!withoutSelection && (
                        <Checkbox
                            labelIconBig
                            iconLabelEmpty
                            checked={checked}
                            checkedPartly={isPartialyChecked}
                            disabled={isSelectionDisabled || isBatchProcessing || (isBatchFilled && !checked)}
                            onChange={handleSelect}
                        ></Checkbox>
                    )}
                    {children}
                    <IconWrap iconWrapBig iconWrap={isExpanded ? "shevron-small-up-expand-less" : "shevron-small-down-expand-more"} />
                </div>
                {isLoading && (
                    <div className="application-document-queue__grid-entry-row-loader">
                        <WaitIcon />
                    </div>
                )}
                <DocumentList
                    documentComponent={documentComponent}
                    entityId={entryNumber}
                    documents={documents}
                    isBatchFilled={isBatchFilled}
                    isSelectionDisabled={isSelectionDisabled}
                    isExpanded={isExpanded}
                    selectedDocumentsCount={selectedDocumentsCount}
                />
            </div>
        );
    }
);
