import React, { useCallback, memo } from "react";

import { formatFullName } from "../../utils/user";
import { useCustomer, useUtilityRights } from "../../../store/resources/useResource";
import { openUtilityTab } from "../../utils/window";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";

import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import SubHeader from "../../ui/Headers/SubHeader";
import UtilityName from "../../ui/UtilityName";
import WaitIcon from "../../ui/WaitIcon";
import EmailLink from "../../ui/EmailLink";
import IconWrap from "../../ui/Icons";
import HeaderIconGroup from "../../ui/Icons/IconGroup";
import Form from "../../ui/Dashboard/Panel/Customer/DetailsPanel/Form";
import { systemUserRights } from "components/utils/user";

const Header = memo(({ utilityNumber, customerNumber }) => {
    const [customer = {}, isLoading] = useCustomer({
        utilityNumber,
        customerNumber,
        forced: true,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const handleEditInvoice = useCallback(() => {
        handleOpenSidePanel(<Form utilityNumber={utilityNumber} customerNumber={customerNumber} onClose={handleCloseSidePanel} />);
    }, [utilityNumber, customerNumber, handleOpenSidePanel, handleCloseSidePanel]);
    const [utilityRights = []] = useUtilityRights({ utilityNumber });
    const canViewUtilities = utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY);
    const canEditCustomer = utilityRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION);

    const items = [
        canViewUtilities && {
            name: "Utility",
            value: <UtilityName utilityNumber={utilityNumber} />,
            onClick: () =>
                openUtilityTab({
                    utilityNumber,
                }),
        },
        {
            name: "Name",
            value: formatFullName(customer.firstName, customer.lastName),
        },
        {
            name: "Account Number",
            value: customer.accountNumber,
        },
        {
            name: "Email",
            value: customer.email ? <EmailLink email={customer.email} /> : "-",
        },
    ];

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <WindowBodyHeader windowBodyHeaderName="Customer">
            {isLoading ? (
                <WaitIcon />
            ) : (
                <>
                    <HeaderIconGroup header>
                        {canEditCustomer && (
                            <IconWrap iconWrapBig icon="edit-empty" title={"Edit Customer Details"} onClick={handleEditInvoice} />
                        )}
                    </HeaderIconGroup>
                    <SubHeader items={items} />
                </>
            )}
        </WindowBodyHeader>
    );
});

export default Header;
