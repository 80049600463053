import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { initGrobalSearchTab } from "../../../store/globalSearch/actions";
import GlobalSearchHeader from "./GlobalSearchHeader";
import GlobalSearchResults from "./GlobalSearchResults";

import "./style.scss";

const GlobalSearchView = memo(({ instanceId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (instanceId) {
            dispatch(initGrobalSearchTab({ instanceId }));
        }
    }, [instanceId, dispatch]);

    if (!instanceId) {
        return null;
    }

    return (
        <div className="global-search-view flex-column fill-height no-scroll">
            <GlobalSearchHeader instanceId={instanceId} />
            <GlobalSearchResults instanceId={instanceId} />
        </div>
    );
});

export default GlobalSearchView;
