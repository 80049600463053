import React, { memo } from "react";

import { programsDocumentsRevisionsGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import DocumentRevisionsGrid from "./DocumentRevisionsGrid";
import Controls from "../../Controls";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const DocumentRevisionsPanel = memo(({ programNumber, documentName, documentNumber, onClose }) => {
    const gridId = `${documentNumber}-revisions`;

    const controlItems = [
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: gridId,
                    fileName: "document_revisions",
                    fileNamePostfix: documentName,
                }),
        },
    ];

    return (
        <SideNavContent>
            <SideNavHeader
                title="Documents Revisions of "
                leadBlockIcon="assignment"
                nameInBold={documentName}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                <Controls items={controlItems} />
                <div className="panel program-documents-panel sticky-grid-list-panel">
                    <DocumentRevisionsGrid
                        programNumber={programNumber}
                        gridId={gridId}
                        filter={`${programsDocumentsRevisionsGridColumnKeys.documentNumber}=${documentNumber}`}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default DocumentRevisionsPanel;
