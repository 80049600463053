import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { hasCriteriaForSearch, getSearchCriteriaText } from "../../../../store/globalSearch/utils";

const WindowHeaderForGlobalSearch = ({ view }) => {
    const instanceId = get(view, `props.instanceId`);
    const criteriaList = useSelector((state) => get(state, `globalSearch.criteriaList[${instanceId}]`)) || [];
    const hasCriteria = hasCriteriaForSearch({ criteriaList });

    if (hasCriteria) {
        return <span className="header-title">Search Results for {getSearchCriteriaText({ criteriaList })}</span>;
    }

    return <span className="header-title">Global Search</span>;
};

export default WindowHeaderForGlobalSearch;
