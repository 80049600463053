import React, { useCallback, useMemo, useContext, memo } from "react";
import cn from "classnames";
import { isEmpty } from "lodash";

import DropDownInput from "../../../../../../Input/DropDownInput";
import { WorkflowContext } from "../../../../../context/WorkflowContext";
import { useResource } from "../../../../../../../../store/resources/useResource";

export const TargetStatusField = memo((props) => {
    const { workflowStatusGetResourceOptions, onChangeWorkflowStepProperties, activeItem, workflowStatuses, isLocked, workflow } =
        useContext(WorkflowContext);
    const [targetStatusList = [], isLoadingTargetStatusList] = useResource(workflowStatusGetResourceOptions);

    const targetStatus = useMemo(() => {
        const { statusNumber, stepNumber } = activeItem;
        return workflowStatuses
            .filter((status) => status.number === statusNumber)
            .map((status) => status.steps)
            .reduce((acc, val) => acc.concat(val), [])
            .filter((step) => step.number === stepNumber)
            .map((step) => step.newStatus)[0];
    }, [activeItem, workflowStatuses]);

    const handleChange = useCallback(
        (e) => {
            onChangeWorkflowStepProperties({
                newStatus: e.target.value || "",
            });
        },
        [onChangeWorkflowStepProperties]
    );

    const statusError = useMemo(() => activeItem.statusNumber === targetStatus, [activeItem.statusNumber, targetStatus]);

    const possibleTargetStatuses = useMemo(() => {
        const possibleTargets = statusError
            ? targetStatusList
            : targetStatusList.filter((x) => x.workflowNumber !== activeItem.statusNumber);
        if (!possibleTargets.find((x) => x.workflowNumber === targetStatus)) {
            const incorrectStatus = workflow.statuses.find((d) => d.number === targetStatus);
            if (incorrectStatus) {
                possibleTargets.push({
                    status: incorrectStatus.workflowStateName,
                    workflowNumber: targetStatus,
                });
            }
        }
        return possibleTargets;
    }, [activeItem.statusNumber, statusError, targetStatus, targetStatusList, workflow.statuses]);

    const errorText = useMemo(() => (statusError ? "Target status and current status may not be the same" : ""), [statusError]);
    const dropDownClasses = cn("workflow-step-types-widget__attribute-field", props.className);

    return (
        <DropDownInput
            className={dropDownClasses}
            value={isLoadingTargetStatusList || isEmpty(targetStatus) ? undefined : targetStatus}
            label="Target Status"
            onChange={handleChange}
            readOnly={isLocked}
            data={possibleTargetStatuses.map((item) => ({
                label: item.status,
                value: item.workflowNumber,
            }))}
            placeholder={isLoadingTargetStatusList ? "Loading..." : "-- SELECT --"}
            emptyItem
            emptyItemLabel="-- SELECT --"
            disabled={isLoadingTargetStatusList}
            mobileHeader="Target Status"
            msgError={activeItem.statusNumber === targetStatus}
            msgText={errorText}
        />
    );
});
