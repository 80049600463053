import React, { memo } from "react";
import { onDeleteUserGroup, getScanQueueUserGroupsGridId } from "../utils";
import { availableGrids } from "../../configureGrids";

import useSidePanelHandlers from "../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../ui/SideNav/SideNavRoot";

import WaitIcon from "../../../ui/WaitIcon";
import DataGrid from "../../../ui/DataGrid";
import ActionLabel from "../../../ui/Label/ActionLabel";
import Form from "../Form";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const scanQueueUserGroupsGridColumnKeys = {
    groupNumber: "c_6468f680-4ae1-43a2-8632-6423c6f74b9d",
    groupName: "c_47c2b990-47ec-47d5-943f-c87a513d4c0c",
    status: "c_7a7f8e0a-a525-4296-b72b-864cddd14cd4",
    totalUtilities: "c_5b57d2f3-5805-4054-a5e0-698c4b23a162",
    totalPrograms: "c_74eb113d-3be6-4549-9e1c-490b920c10f2",
    totalScans: "c_f6b5cf1e-4d79-4871-a09f-53be1b5628a0",
    totalUsers: "c_19566561-61d1-4127-ac55-06945579a062",
};

const rowActions = [
    {
        name: "edit-details",
        title: "Edit User Group",
        icon: "edit-empty",
        footerExclude: true,
    },
    {
        name: "delete",
        title: "Delete User Group",
        icon: "delete-trash-empty",
        footerName: "Delete Group",
    },
];

const Grid = memo(({ onRefresh }) => {
    const dataGridId = getScanQueueUserGroupsGridId();
    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.scanQueueUserGroups,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "scanQueueUserGroupsGridGetDataAction",
            props: {
                columnKeysMap: scanQueueUserGroupsGridColumnKeys,
            },
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(scanQueueUserGroupsGridColumnKeys, dataItem);

        switch (name) {
            case "edit-details":
                handleOpenSidePanel(<Form dataItem={data} editEnabled onClose={handleCloseSidePanel} onRefresh={onRefresh} />);
                break;
            case "edit":
                handleOpenSidePanel(<Form dataItem={data} onClose={handleCloseSidePanel} onRefresh={onRefresh} />);
                break;

            case "delete":
                onDeleteUserGroup({ dataItem: data, onRefresh });
                break;

            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            columnWidth={{
                [scanQueueUserGroupsGridColumnKeys.totalUtilities]: 100,
                [scanQueueUserGroupsGridColumnKeys.totalPrograms]: 100,
                [scanQueueUserGroupsGridColumnKeys.totalScans]: 100,
                [scanQueueUserGroupsGridColumnKeys.totalUsers]: 100,
            }}
            columnCellContent={{
                [scanQueueUserGroupsGridColumnKeys.groupName]: (column, row, onExpandRow, onRowAction, rowIndex) => (
                    <ActionLabel
                        onClick={() =>
                            onRowAction({
                                name: "edit",
                                dataItem: row,
                                dataIndex: rowIndex,
                            })
                        }
                    >
                        {row[column.key]}
                    </ActionLabel>
                ),
            }}
        />
    );
});

export default Grid;
