import React from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";

import "./style.scss";

const ContentWrapPopup = (props) => {
    const className = ClassNames("content-wrap-popup", props.className);

    return (
        <div ref={props.elementRef} className={className}>
            <div className="content-wrap-popup--mobile-header">
                {props.mobileHeaderText}
                <IconWrap icon="clear-close" onClick={props.mobileHeaderOnClick} />
            </div>
            <div className="content-wrap-popup--content-wrap">{props.children}</div>
            {props.contentWrapPopupButtons && <div className="content-wrap-popup--footer flex-row">{props.contentWrapPopupButtons}</div>}
        </div>
    );
};

export default ContentWrapPopup;
