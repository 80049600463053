import { isEqual, isObject } from "lodash";
import { useRef } from "react";
import { getPortalTemplateResourceParams } from "store/configureResources";
import { useResource } from "store/resources/useResource";
import { UtilityPortalBuilderConfigData, UtilityPortalConfigurationResponse } from "../types";
import { transformUtilityPortalConfig } from "./page";

export const useUtilityPortalConfiguration = ({
    utilityNumber,
    portalTemplateNumber,
    forced = false,
}: UseUtilityPortalConfigurationProps): [config: UtilityPortalBuilderConfigData | undefined, isLoading: boolean] => {
    const configRef = useRef<UtilityPortalBuilderConfigData>();

    const transform = (data: UtilityPortalConfigurationResponse) => {
        if (isObject(data)) {
            return transformUtilityPortalConfig(data, portalTemplateNumber!);
        }

        return data;
    };

    const [data, isLoading] = useResource({
        ...getPortalTemplateResourceParams({ utilityNumber, portalTemplateNumber }),
        forced,
        transform,
    });

    // Return previous value if configs are equal. This is to prevent unnecessary re-renders.
    const equal = isEqual(data, configRef.current);
    if (!equal) {
        configRef.current = data;
    }

    return [configRef.current, isLoading];
};

interface UseUtilityPortalConfigurationProps {
    utilityNumber: string;
    portalTemplateNumber?: string;
    forced: boolean;
}
