import React from "react";
import AssociationsList from "components/ui/AssociationsList";

export function RoleChangeModalMessage({ title, utilityList, programList, userList }) {
    return (
        <div className="role-management-modal-message flex-column">
            <p className="role-management-modal-message__title">{title}</p>
            <br />
            {userList && <AssociationsList headerText="Selected Users" list={userList} displayProperty="userName" readOnly />}
            {utilityList && <AssociationsList headerText="Selected Utilities" list={utilityList} displayProperty="utilityName" readOnly />}
            {programList && <AssociationsList headerText="Selected Programs" list={programList} displayProperty="program" readOnly />}
        </div>
    );
}

export const ChangeProgramLevelRoleTitle = ({ roleName }) => (
    <>
        Change program level role to <strong>{roleName}</strong>?
    </>
);
export const ChangeUtilityLevelRoleTitle = ({ roleName }) => (
    <>
        Change utility level role to <strong>{roleName}</strong>?
    </>
);
