import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import WaitIcon from "../../../../WaitIcon";
import ListItemCategory from "../../../../List/ListItemCategory";
import { setActiveApplicationForm } from "../../../../../../store/projects/actions";
import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";
import { useResource } from "../../../../../../store/resources/useResource";
import CustomList, { renderCustomFooter } from "components/ui/List/CustomList";

import "./style.scss";

const ApplicationsPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand }) => {
    const dispatch = useDispatch();
    const { applicationNumber } = panel?.data;
    const [forms = [], loadingForms] = useResource({
        resourceName: "applicationForms",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        forced: true,
    });

    const onShowForm = useCallback(
        (form) => {
            dispatch(setActiveApplicationForm(applicationNumber, form.pageNumber));
            onExpand(panel);
        },
        [applicationNumber, panel, onExpand, dispatch]
    );

    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const renderItem = (item) => {
        return (
            <ListItemCategory item={item} withArrow key={item.pageNumber} onClick={() => onShowForm(item)}>
                {item.pageName}
            </ListItemCategory>
        );
    };

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            className="applications-sidebar-panel"
            noPadding
            noFooter
            noData={!loadingForms && forms.length === 0}
        >
            <div className="applications-list flex-column">
                <div className="applications-sidebar-body">
                    <div className="applications-list">
                        {loadingForms && <WaitIcon />}
                        <CustomList limit={6} renderItem={renderItem} renderFooter={renderCustomFooter} items={forms} />
                    </div>
                </div>
            </div>
        </SidebarPanel>
    );
});

export default ApplicationsPanelSmall;
