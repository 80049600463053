import React, { memo } from "react";
import ClassNames from "classnames";

import "./style.scss";

const FieldWrap = memo((props) => {
    const className = ClassNames(props.icon, props.className, {
        "field-wrap": props.input,
        "textarea-wrap": props.textarea,
        "checkbox-wrap": props.checkbox,
        "dropdown-wrap": props.dropdown,
        "radio-wrap": props.radio,
        "icon-dropdown": props.iconDropdown,
        error: props.error,
        frozen: props.frozen,
        "with-icon": props.icon,
        right: props.iconRight,
        "with-two-icons": props.twoIcons,
        "focus highlighted": props.focus,
        "numeric-wrap": props.numericInput,
        "expandable-input-wrap": props.expandableInputWrap,
    });

    return (
        <div className={className} title={props.title}>
            {props.children}
        </div>
    );
});

export default FieldWrap;
