import React, { memo, useRef, useCallback, useEffect } from "react";

import JsonSchemaForm from "../JsonSchema/JsonSchemaForm";
import { useReference } from "../../Reference/useReference";
import { referenceTypes } from "../../Reference/referenceTypes";
import { submitByRef } from "../../../utils/form";

import SideNavBody from "../../SideNav/SideNavBody";
import SideNavFooter from "../../SideNav/SideNavFooter";
import SideNavHeader from "../../SideNav/SideNavHeader";
import SideNavContent from "../../SideNav/SideNavContent";
import Button from "../../Button";

export const UtilityForm = memo(({ initialValues, onSubmit, onCancel, isSubmitting, isProgramConfigCriteria, sidePanel }) => {
    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const [lookupMethods = []] = useReference(referenceTypes.cisLookupMethod);
    const required = ["utility", "utilityCode", "clientFolder"];
    const schema = {
        type: "object",
        required,
        properties: {
            utility: {
                type: "string",
                title: "Utility",
            },
            utilityCode: {
                type: "string",
                title: "Utility Code",
                maxLength: 2,
            },
            clientFolder: {
                type: "string",
                title: "Client Folder",
            },
            cis_customer_table: {
                type: "string",
                title: "CIS Customer Table",
            },
            cis_SP: {
                type: "string",
                title: "CIS SP",
            },
            cis_datagridid: {
                type: "integer",
                title: "CIS Datagrid ID",
                minimum: 0,
            },
            cis_sourcedata: {
                type: "string",
                title: "CIS Complete View",
            },
            cis_lookupmethod: {
                type: "integer",
                title: "CIS Lookup Method",
                anyOf: lookupMethods.map((lookupMethod) => ({
                    title: lookupMethod.display,
                    enum: [Number(lookupMethod.val)],
                })),
            },
            showDashboard: {
                type: "integer",
                title: "Show Utility Dashboard",
                anyOf: [
                    {
                        title: "No",
                        enum: [0],
                    },
                    {
                        title: "Yes",
                        enum: [1],
                    },
                ],
            },
        },
    };
    const uiSchema = {
        utility: {
            classNames: "add-utility-form__field add-utility-form__field-wide",
            "ui:options": {
                placeholder: "Utility",
            },
        },
        utilityCode: {
            classNames: "add-utility-form__field",
            "ui:options": {
                placeholder: "Utility Code",
            },
        },
        clientFolder: {
            classNames: "add-utility-form__field add-utility-form__field-wide",
            "ui:options": {
                placeholder: "Client Folder",
            },
        },
        cis_customer_table: {
            classNames: "add-utility-form__field add-utility-form__field-wide",
            "ui:options": {
                placeholder: "CIS Customer Table",
            },
        },
        cis_SP: {
            classNames: "add-utility-form__field add-utility-form__field-wide",
            "ui:options": {
                placeholder: "CIS SP",
            },
        },
        cis_datagridid: {
            classNames: "add-utility-form__field add-utility-form__field-cis-data-grid-id",
        },
        cis_sourcedata: {
            classNames: "add-utility-form__field add-utility-form__field-wide",
            "ui:options": {
                placeholder: "CIS Complete Customer Table View",
            },
        },
        cis_lookupmethod: {
            classNames: "add-utility-form__field add-utility-form__field-cis-lookup-method",
            "ui:options": {
                placeholder: "Select CIS Lookup Method",
            },
        },
        showDashboard: {
            classNames: "add-utility-form__field add-utility-form__field-show-dashboard",
            "ui:options": {
                placeholder: "Show Utility Dashboard",
            },
        },
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent>
            <SideNavHeader
                title={isProgramConfigCriteria ? "Criteria" : "Add New Utility"}
                leadBlockIcon={isProgramConfigCriteria ? "criteria" : "plus"}
                smallHeader
                onClose={onCancel}
            />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    className="add-utility-form"
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    disabled={isSubmitting}
                    initialValues={initialValues}
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary onClick={handleSave} disabled={isSubmitting}>
                    Save
                </Button>
                <Button onClick={onCancel}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});
