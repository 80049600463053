import React from "react";
import { get, isNumber } from "lodash";
import { store } from "../../../../../../store/configureStore";
import { modalClose, modalOpen } from "../../../../../../store/modal/actions";
import { destroy, setSourceRows, getData } from "../../../../../../store/dataGrid/actions";
import AttributeLookupAssignmentModal from "./AttributeLookupAssignmentModal";
import { uploadCSV, exportDatagridToCSV } from "../../../../../utils/CSV";
import { toast } from "react-toastify";

export const getLookupValuesGridId = ({ entityKey }) => {
    return `${entityKey}-attribute-lookups-grid`;
};

export const openAttributeLookupAssignmentModal = ({ entityKey, attributeId, isCatalog, onSelect }) => {
    const dataGridId = getLookupValuesGridId({ entityKey });

    const onClose = () => {
        store.dispatch(modalClose());
        store.dispatch(destroy({ name: dataGridId }));
    };

    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Attribute Lookup Values",
                className: "attributes-lookup-values-modal modal-with-grid modal-md",
                overlayClassName: "modal-styled",
                children: (
                    <AttributeLookupAssignmentModal
                        dataGridId={dataGridId}
                        entityKey={entityKey}
                        attributeId={attributeId}
                        isCatalog={isCatalog}
                        onClose={onClose}
                        onSelect={onSelect}
                    />
                ),
                noFooter: true,
                withScroll: true,
            },
        })
    );
};

export const attributeLookupsToArray = (value) => {
    if (Array.isArray(value)) {
        return value;
    }

    let lookupValues = (value || "").trim();

    if (lookupValues.length > 0) {
        lookupValues = lookupValues
            .split("|")
            .map((l) => l.trim())
            .filter((l) => l.length > 0)
            .map((l) => ({ lookup: l }));
    } else {
        lookupValues = [];
    }

    return lookupValues;
};

export const attributeLookupsToString = (value) => {
    if (!Array.isArray(value)) {
        return value;
    }

    return value.map((i) => i.lookup).join("|");
};

export const addNewAttributeLookup = ({ dataGridId, value = "" }) => {
    const state = store.getState();

    const rows = get(state, `dataGrid[${dataGridId}].rows`) || [];

    const newRows = rows.concat({
        value,
        timesUsed: 0,
    });

    store.dispatch(setSourceRows({ dataGridId, rows: newRows }));
    store.dispatch(getData({ dataGridId }));
};

export const removeAttributeLookup = ({ dataGridId, dataIndex }) => {
    const state = store.getState();

    const rows = get(state, `dataGrid[${dataGridId}].rows`) || [];

    rows.splice(dataIndex, 1);

    store.dispatch(setSourceRows({ dataGridId, rows }));
    store.dispatch(getData({ dataGridId }));
};

export const getAttributeLookupValues = ({ dataGridId }) => {
    const state = store.getState();
    const rows = get(state, `dataGrid[${dataGridId}].rows`) || [];

    return rows.map((r) => r.value);
};

export const uploadValues = ({ entityKey, attributeMaxLength, onUploaded }) => {
    uploadCSV({ delimiter: ",", header: true })
        .then((result) => {
            if (isNumber(attributeMaxLength)) {
                result.lines.forEach((line, index) => {
                    if (String(line[0]).length > attributeMaxLength) {
                        const lineNumber = index + 2;
                        throw Error(`Line ${lineNumber}. Invalid lookup value ${line[0]}`);
                    }
                });
            }

            const dataGridId = getLookupValuesGridId({ entityKey });
            result.lines.forEach((line) =>
                addNewAttributeLookup({
                    dataGridId,
                    value: line[0],
                })
            );

            toast.success("Attributes Lookup Values Imported");
            onUploaded && onUploaded();
        })
        .catch((error) => {
            onUploaded && onUploaded(error.message);
        });
};

export const downloadValues = ({ entityKey }) => {
    const dataGridId = getLookupValuesGridId({ entityKey });

    exportDatagridToCSV({
        dataGridId,
        fileName: "lookup_values",
        fileNamePostfix: entityKey,
    });
};
