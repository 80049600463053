import React, { useCallback, memo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { updateResource } from "../../../../store/resources/actions";
import { setSettingsLayout, LAYOUT_WITH_BORDERS, LAYOUT_FULL_SCREEN } from "../../../utils/settings";
import { downloadFile } from "store/files/actions";
import { WindowContext } from "../Window";

import IconWrap from "../../Icons";
import IconGroup from "../../Icons/IconGroup";
import GlobalSearch from "components/ui/GlobalSearch";

import { useCanSplitScreenView } from "components/utils/useCanSplitScreenView";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
import { splitWindowPositionType } from "components/utils/window";

import "./WindowHeaderIconGroup.scss";

export const WindowHeaderIconGroup = memo(({ view, onSplit, onClose, onRemoveSplit }) => {
    const dispatch = useDispatch();

    const { isSplitView, splitWindowPosition } = useContext(WindowContext);

    const canSearch = hasAnyOfPermissions([systemUserRights.VISIONDSM_GENERAL_SEARCH]);

    const user = useSelector((state) => get(state, "user"));
    const showFileDownload = view.component === "FileView";
    const showGlobalSearch =
        canSearch &&
        view.component !== "GlobalSearchView" &&
        !(isSplitView && splitWindowPosition === splitWindowPositionType.left) &&
        !view.hideSearch;
    const canSplitView = useCanSplitScreenView();

    const onLayoutChange = useCallback(() => {
        const newLayout = (user.settings.layout ?? LAYOUT_WITH_BORDERS) === LAYOUT_WITH_BORDERS ? LAYOUT_FULL_SCREEN : LAYOUT_WITH_BORDERS;

        setSettingsLayout(newLayout);

        dispatch(
            updateResource({
                resourceName: "userSettings",
                body: {
                    ...user.settings,
                    layout: newLayout,
                },
                showSuccessNotification: false,
            })
        );
    }, [user, dispatch]);

    const onDownload = useCallback(() => {
        dispatch(downloadFile({ fileId: view.props?.fileNumber }));
    }, [view, dispatch]);

    return (
        <IconGroup windowHader>
            {showFileDownload && (
                <IconWrap iconWrapBig iconWrapTransparent iconWrap="download" title="Download File" onClick={onDownload}></IconWrap>
            )}
            {showGlobalSearch && <GlobalSearch iconBackground="transparent" iconSize="big" />}
            {onSplit && (!view.layoutType || view.layoutType !== "left-right") && !view.hideFullscreen && (
                <IconWrap
                    iconWrapBig
                    iconWrapTransparent
                    iconWrap={
                        (user.settings.layout ?? LAYOUT_WITH_BORDERS) === LAYOUT_WITH_BORDERS ? "movie-movies-empty" : "movie-movies-filled"
                    }
                    title={
                        (user.settings.layout ?? LAYOUT_WITH_BORDERS) === LAYOUT_WITH_BORDERS
                            ? "Full Screen - switch ON"
                            : "Full Screen - switch OFF"
                    }
                    onClick={() => onLayoutChange()}
                ></IconWrap>
            )}
            {onSplit && !view.hideSplitView && (
                <>
                    {canSplitView ? (
                        <IconWrap
                            iconWrapClickable
                            iconWrapBig
                            iconWrapTransparent
                            iconWrap="view-stream-view-agenda-empty"
                            title="Split Screen - switch ON"
                            onClick={onSplit}
                        ></IconWrap>
                    ) : (
                        <IconWrap
                            iconWrapBig
                            disabled
                            iconWrapTransparent
                            iconWrap="view-stream-view-agenda-empty"
                            title="Screen is too small for Split Screen View"
                        ></IconWrap>
                    )}
                </>
            )}
            {onClose && (
                <IconWrap
                    iconWrapClickable
                    iconWrapBig
                    iconWrapTransparent
                    iconWrap="clear-close"
                    title="Close"
                    onClick={onClose}
                ></IconWrap>
            )}
            {onRemoveSplit && (
                <>
                    <IconWrap
                        iconWrapClickable
                        iconWrapBig
                        iconWrapTransparent
                        iconWrap="view-stream-view-agenda-filled"
                        title="Split Screen - switch OFF"
                        onClick={onRemoveSplit}
                    ></IconWrap>
                    <IconWrap
                        iconWrapBig
                        disabled
                        iconWrapTransparent
                        iconWrap="view-stream-view-agenda-filled"
                        title="Screen is too small for Split Screen View"
                    ></IconWrap>
                </>
            )}
        </IconGroup>
    );
});
