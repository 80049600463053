import React, { memo } from "react";

import Button from "../Button";
import ButtonGroup from "../Button/ButtonGroup";

import "./GridDetailsFooter.scss";

const GridDetailsFooterActions = memo(({ dataItem, dataIndex, actions = [], onRowAction }) => {
    const onAction = (action) => () => {
        onRowAction &&
            onRowAction({
                ...action,
                dataItem,
                dataIndex,
            });
    };

    return (
        <ButtonGroup transparent>
            {actions
                .filter((action) => !action.footerExclude)
                .map((action) => (
                    <Button
                        key={action.name}
                        title={action.title}
                        icon={action.icon}
                        onClick={onAction(action)}
                        primary={!action.name.startsWith("delete")}
                    >
                        {action.footerName || action.name}
                    </Button>
                ))}
        </ButtonGroup>
    );
});

export default GridDetailsFooterActions;
