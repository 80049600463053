import React, { memo } from "react";
import cn from "classnames";

export const RolesListCell = memo(({ className, children, onClick }) => {
    return (
        <div className={cn("roles-list-cell", className)} onClick={onClick}>
            {children}
        </div>
    );
});
