import React, { useCallback, useContext, useMemo, memo } from "react";
import { omit } from "lodash";

import { useReference } from "../../../../Reference/useReference";
import { useResource } from "../../../../../../store/resources/useResource";
import { WorkflowContext } from "../../../context/WorkflowContext";
import { WORKFLOW } from "../../../constants/workflow";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { isValidStrings } from "../../../utils/validation";

import Button from "../../../../Button";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { WarningMessage } from "components/ui/Message";

import "./style.scss";

export const StatusProperties = memo(
    ({
        number,
        state,
        isConfirmed = true,
        workflowStateNumber,
        processModeID,
        groupID,
        statusID,
        targetableID,
        portalStatusFriendlyName,
    }) => {
        const { workflowStatuses, onDeleteWorkflowStatus, statesGetResourceOptions, onChangeWorkflowStatusProperties } =
            useContext(WorkflowContext);

        const [programWorkflowStates = [], areProgramWorkflowStatesLoading] = useResource(statesGetResourceOptions);
        const { processModes, isLocked } = useContext(WorkflowContext);
        const [statusGroups = [], areStatusGroupsLoading] = useReference(referenceTypes.workflowStatusGroup);
        const isValid = isValidStrings(state, workflowStateNumber);

        // List of statuses already in use.
        const activeStatuses = workflowStatuses.filter((i) => i.statusID === WORKFLOW.ACTIVE_STATUS_ID).map((i) => i.workflowStateNumber);

        const isDisabledDuplicate = statusID === WORKFLOW.DISABLED_STATUS_ID && activeStatuses.includes(workflowStateNumber);
        const programWorkflowState = programWorkflowStates.find((i) => i.wfStateNumber === workflowStateNumber)?.wfState;

        const required = ["state", "workflowStateNumber", "processModeID"];

        const schema = {
            type: "object",
            required,
            properties: {
                state: {
                    type: "string",
                    title: "Title",
                    maxLength: 300,
                },
                workflowStateNumber: {
                    type: "string",
                    title: "State",
                    anyOf: programWorkflowStates.map((state) => ({
                        title: state.wfState,
                        enum: [state.wfStateNumber],
                    })),
                },
                processModeID: {
                    type: "integer",
                    title: "Process mode",
                    anyOf: processModes.map((processMode) => ({
                        title: processMode.display,
                        enum: [Number(processMode.val)],
                    })),
                },
                groupID: {
                    type: "integer",
                    title: "Workflow Status Group",
                    anyOf: statusGroups.map((statusGroup) => ({
                        title: statusGroup.display,
                        enum: [Number(statusGroup.val)],
                    })),
                },
                portalStatusFriendlyName: {
                    type: "string",
                    title: "Portal Status Friendly Name",
                },
                isTargetable: {
                    type: "boolean",
                    title: "Transfer Targetable",
                    default: targetableID === WORKFLOW.TARGETABLE_TRANSFER_ID,
                },
                statusID: {
                    type: "integer",
                    title: "Status",
                    anyOf: [
                        {
                            title: "Active",
                            enum: [WORKFLOW.ACTIVE_STATUS_ID],
                        },
                        {
                            title: "Disabled",
                            enum: [WORKFLOW.DISABLED_STATUS_ID],
                        },
                    ],
                },
            },
        };

        const uiSchema = {
            state: {
                classNames: "wf__settings-status-form-field",
                "ui:placeholder": "Title",
            },
            workflowStateNumber: {
                classNames: "wf__settings-status-form-field",
                "ui:placeholder": areProgramWorkflowStatesLoading ? "Loading..." : "State",
                "ui:disabled": areProgramWorkflowStatesLoading,
                // Disable statuses already in use to not allow duplicates
                "ui:enumDisabled": activeStatuses,
            },
            processModeID: {
                classNames: "wf__settings-status-form-field",
                "ui:placeholder": "Process Mode",
            },
            groupID: {
                classNames: "wf__settings-status-form-field",
                "ui:placeholder": areStatusGroupsLoading ? "Loading..." : "Group",
                "ui:disabled": areStatusGroupsLoading,
            },
            portalStatusFriendlyName: {
                classNames: "wf__settings-status-form-field",
            },
            isTargetable: {
                classNames: "wf__settings-status-form-field",
            },
            statusID: {
                classNames: "wf__settings-status-form-field",
                "ui:widget": "radio",
                "ui:disabled": isDisabledDuplicate,
                "ui:help": isDisabledDuplicate ? (
                    <WarningMessage>
                        Other state <strong>{programWorkflowState}</strong> is already active in configuration.
                    </WarningMessage>
                ) : undefined,
                "ui:options": {
                    inline: true,
                },
            },
        };

        const initialValues = useMemo(
            () => ({
                state,
                workflowStateNumber: areProgramWorkflowStatesLoading ? null : workflowStateNumber,
                processModeID,
                groupID: areStatusGroupsLoading ? null : groupID,
                portalStatusFriendlyName,
                statusID,
                isTargetable: targetableID === WORKFLOW.TARGETABLE_TRANSFER_ID,
            }),
            [
                state,
                workflowStateNumber,
                processModeID,
                statusID,
                targetableID,
                groupID,
                portalStatusFriendlyName,
                areStatusGroupsLoading,
                areProgramWorkflowStatesLoading,
            ]
        );

        const handleChange = useCallback(
            ({ formData }) => {
                const { isTargetable } = formData;

                onChangeWorkflowStatusProperties({
                    ...omit(formData, ["isTargetable"]),
                    targetableID: isTargetable ? WORKFLOW.TARGETABLE_TRANSFER_ID : WORKFLOW.NOT_TARGETABLE_TRANSFER_ID,
                });
            },
            [onChangeWorkflowStatusProperties]
        );

        const handleClickCreateStatus = useCallback(() => {
            if (isValid) {
                onChangeWorkflowStatusProperties({ isConfirmed: true });
            }
        }, [isValid, onChangeWorkflowStatusProperties]);

        const handleClickCancel = useCallback((e) => onDeleteWorkflowStatus(e, number), [onDeleteWorkflowStatus, number]);

        return (
            <div className="wf__settings-status flex-column fill-height">
                <div className="flex-one-in-column with-scroll">
                    <JsonSchemaForm
                        className="wf__settings-status-form"
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isLocked}
                        onChange={handleChange}
                        noSubmit
                        noReset
                    />
                </div>
                {!isConfirmed && (
                    <div className="wf__settings-status-buttons flex-row">
                        <Button icon="plus" primary onClick={handleClickCreateStatus} disabled={!isValid}>
                            Create Status
                        </Button>
                        <Button className="wf__settings-status-buttons-cancel" onClick={handleClickCancel}>
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
        );
    }
);
