import React from "react";

import { store } from "../../../store/configureStore";
import { modalClose, modalOpen } from "../../../store/modal/actions";

import Grid from "./Grid";

export const getContractorsGridId = ({ programNumber, applicationNumber }) => {
    return `${programNumber ?? applicationNumber}-contractors`;
};

export const onContractorLookup = ({ programNumber, applicationNumber, onSelect }) => {
    const close = () => {
        store.dispatch(modalClose());
    };

    store.dispatch(
        modalOpen({
            modalType: "MODAL",
            modalProps: {
                title: "Search Contractor",
                overlayClassName: "modal-styled",
                className: "contractor-modal modal-with-grid modal-lg",
                modalIcon: "search",
                children: <Grid programNumber={programNumber} applicationNumber={applicationNumber} onSelect={onSelect} onClose={close} />,
            },
        })
    );
};
