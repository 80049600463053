import React, { lazy, Suspense, useCallback, useState, memo } from "react";

import WaitIcon from "../../../../WaitIcon";
import TabList from "../../../../List/TabList";
import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import WorkflowAudit from "./WorkflowAudit";
import ModuleLoadError from "components/ui/ModuleLoadError";

const WorkflowManagement = lazy(() =>
    import("./WorkflowManagement").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

const TABS = [
    {
        id: "workflowManagement",
        title: "Manage Workflow",
    },
    {
        id: "workflowAudit",
        title: "Workflow History",
    },
];

const WorkflowPanel = memo(({ panel }) => {
    const programNumber = panel.data.programNumber;

    const [activeTab, setActiveTab] = useState(TABS[0]);

    const handleClickTab = useCallback((tab) => setActiveTab(tab), []);

    return (
        <div className="program-wf flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <TabList tabListGhost items={TABS} activeItem={activeTab} onClick={handleClickTab} />
            {activeTab.id === "workflowManagement" && (
                <Suspense fallback={<WaitIcon />}>
                    <WorkflowManagement programNumber={programNumber} />
                </Suspense>
            )}
            {activeTab.id === "workflowAudit" && <WorkflowAudit programNumber={programNumber} />}
        </div>
    );
});

export default WorkflowPanel;
