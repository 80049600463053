import React, { useCallback, memo } from "react";
import { useDispatch } from "react-redux";

import { getBudgetLineInvoicesGridId } from "../utils";
import { onRefreshBudgetLinesGrid } from "../../Contract/BudgetLinesPanel/utils";
import { getData } from "store/dataGrid/actions";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "./Controls";
import Grid from "./Grid";
import Form from "./Form";

import "../style.scss";

const InvoicesPanel = memo(({ panel }) => {
    const utilityNumber = panel.data.utilityNumber;
    const contractNumber = panel.data.contractNumber;
    const budgetLineNumber = panel.data.budgetLineNumber;

    const dataGridId = getBudgetLineInvoicesGridId({ budgetLineNumber });

    const dispatch = useDispatch();

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-invoices-sidenav-panel",
    });

    const handleRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
        onRefreshBudgetLinesGrid({ contractNumber });
    }, [contractNumber, dataGridId, dispatch]);

    const handleCreateInvoice = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                gridRefresh={handleRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, budgetLineNumber, handleRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel budget-invoices flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls
                utilityNumber={utilityNumber}
                budgetLineNumber={budgetLineNumber}
                dataGridId={dataGridId}
                onAdd={() => handleCreateInvoice()}
            />
            <Grid
                dataGridId={dataGridId}
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                budgetLineNumber={budgetLineNumber}
                onRefresh={handleRefresh}
            />
        </div>
    );
});

export default InvoicesPanel;
