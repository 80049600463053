import React, { useCallback, useState, memo, useMemo } from "react";

import { utilitiesCalculationsRevisionsColumnKeys, programsCalculationsRevisionsGridColumnKeys } from "../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../utils/datagrid";
import { exportDatagridToCSV } from "../../../../utils/CSV";

import IconWithLabel from "../../../Icons/IconWithLabel";
import withDataGrid from "../../../DataGrid/withDataGrid";
import DataGrid from "../../../DataGrid";
import UpdateCalculationPanel from "./UpdateCalculationPanel";
import SideNavHeader from "../../../SideNav/SideNavHeader";
import SideNavBody from "../../../SideNav/SideNavBody";
import SideNavFooter from "../../../SideNav/SideNavFooter";
import SideNavContent from "../../../SideNav/SideNavContent";
import Button from "../../../Button";

const customRowActions = [
    {
        name: "update",
        icon: "edit-empty",
        title: "Edit Calculation",
    },
];

const RevisionsGrid = ({ gridId, gridKeys, dataGridConfig, onShowUpdatePanel }) => {
    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const rowData = mapGridRowToObject(gridKeys, dataItem);

            switch (name) {
                case "update":
                    onShowUpdatePanel(rowData);
                    break;
                default:
                    break;
            }
        },
        [onShowUpdatePanel, gridKeys]
    );

    return <DataGrid name={gridId} config={dataGridConfig} customRowActions={customRowActions} onRowAction={onRowAction} />;
};

const UtilitiesRevisionsGrid = withDataGrid("utilitiesCalculationsRevisions", RevisionsGrid);
const ProgramsRevisionsGrid = withDataGrid("programsCalculationsRevisions", RevisionsGrid);

const CalculationRevisionsPanel = memo(({ utilityNumber, programNumber, rowData, onClose, disabled }) => {
    const calculationNumber = rowData.calculationNumber;

    if (disabled) {
        customRowActions[0].icon = "eye-visibility-empty";
        customRowActions[0].title = "View Calculation";
    }
    const [updatePanelData, setUpdatePanelData] = useState(null);

    const onShowUpdatePanel = useCallback((rowData) => {
        setUpdatePanelData(rowData);
    }, []);

    const onCloseUpdatePanel = useCallback(() => {
        setUpdatePanelData(null);
    }, []);

    const nestedSidePanel = useMemo(
        () => ({
            close: onClose,
            success: onClose,
            setForm: () => {},
        }),
        [onClose]
    );

    if (updatePanelData) {
        return (
            <UpdateCalculationPanel
                disabled={disabled}
                programNumber={programNumber}
                utilityNumber={utilityNumber}
                rowData={updatePanelData}
                onClose={onCloseUpdatePanel}
                sidePanel={nestedSidePanel}
            />
        );
    }

    const gridId = `${calculationNumber}-revisions-grid`;
    const gridKeys = programNumber ? programsCalculationsRevisionsGridColumnKeys : utilitiesCalculationsRevisionsColumnKeys;
    const Grid = programNumber ? ProgramsRevisionsGrid : UtilitiesRevisionsGrid;
    const filterEntity = programNumber ? `${gridKeys.programNumber}=${programNumber}` : `${gridKeys.utilityNumber}=${utilityNumber}`;

    return (
        <SideNavContent className="calculations-form">
            <SideNavHeader
                title="Revisions of "
                nameInBold={rowData.calculationName}
                leadBlockIcon={"assignment"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody>
                <div className="panel calculations flex-column fill-height">
                    <div className="grid-controls flex-row justify-end">
                        <IconWithLabel
                            withHandMadeIcon
                            onClick={() =>
                                exportDatagridToCSV({
                                    dataGridId: gridId,
                                    fileName: "calculation_revisions",
                                    fileNamePostfix: calculationNumber,
                                })
                            }
                        >
                            Export CSV
                        </IconWithLabel>
                    </div>
                    <Grid
                        gridKeys={gridKeys}
                        gridId={gridId}
                        filter={`${filterEntity}|${gridKeys.calculationNumber}=${calculationNumber}`}
                        sort={[
                            {
                                field: gridKeys.revisionDate,
                                dir: "desc",
                            },
                        ]}
                        onShowUpdatePanel={onShowUpdatePanel}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default CalculationRevisionsPanel;
