import React, { useState, memo } from "react";
import ClassNames from "classnames";

import "./style.scss";

const ExpandablePanel = memo((props) => {
    const [expanded, setExpanded] = useState(props.expanded);

    const toggleExpanded = () => setExpanded(!expanded);

    const panelIcon = props.panelIcon ?? "shevron-right-keyboard-arrow-right";

    const className = ClassNames("expandable-panel", panelIcon, props.className, {
        active: expanded,
        "with-icon": panelIcon,
    });

    return (
        <>
            <div className={className} onClick={toggleExpanded}>
                {props.title}
            </div>
            {expanded && props.children}
        </>
    );
});

export default ExpandablePanel;
