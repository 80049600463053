import React, { useEffect, memo } from "react";
import { connect } from "react-redux";

import { construct } from "../../../store/dataGrid/actions";
import {
    availableGrids,
    applicationsRecentApplicationsGridColumnKeys,
    widgetApplicationsRecentGridColumnKeys,
} from "../../views/configureGrids";
import { openProjectTab, openUtilityTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import WaitIcon from "../../ui/WaitIcon";
import DataGrid from "../../ui/DataGrid";
import ActionLabel from "../../ui/Label/ActionLabel";

import "./style.scss";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
import Label from "components/ui/Label";

const rowActions = [
    {
        name: "edit",
        icon: "open-new-window",
        title: "Manage Application",
    },
];

const ApplicationProcessingRecentApplications = memo(() => {
    return (
        <div className="app-processing-recent-apps panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <RecentApplicationsDataGrid isWidget={false} />
            </div>
        </div>
    );
});

const Grid = memo(({ user, dataGrid, isWidget, dispatch }) => {
    const dataGridID = isWidget ? availableGrids.widgetApplicationsRecent : availableGrids.applicationsRecentApplications;

    const columnApplicationNumber = isWidget
        ? widgetApplicationsRecentGridColumnKeys.applicationNumber
        : applicationsRecentApplicationsGridColumnKeys.applicationNumber;
    const columnProjectName = isWidget
        ? widgetApplicationsRecentGridColumnKeys.projectName
        : applicationsRecentApplicationsGridColumnKeys.projectName;
    const columnProjectNumber = isWidget
        ? widgetApplicationsRecentGridColumnKeys.projectNumber
        : applicationsRecentApplicationsGridColumnKeys.projectNumber;
    const columnProgramID = isWidget
        ? widgetApplicationsRecentGridColumnKeys.programID
        : applicationsRecentApplicationsGridColumnKeys.programID;
    const columnProgramName = isWidget
        ? widgetApplicationsRecentGridColumnKeys.programName
        : applicationsRecentApplicationsGridColumnKeys.programName;
    const columnProgramNumber = isWidget
        ? widgetApplicationsRecentGridColumnKeys.programNumber
        : applicationsRecentApplicationsGridColumnKeys.programNumber;
    const columnUtilityName = isWidget
        ? widgetApplicationsRecentGridColumnKeys.utilityName
        : applicationsRecentApplicationsGridColumnKeys.utilityName;
    const columnUtilityNumber = isWidget
        ? widgetApplicationsRecentGridColumnKeys.utilityNumber
        : applicationsRecentApplicationsGridColumnKeys.utilityNumber;

    const gridId = `${user.userNumber}${isWidget ? "-widget" : ""}-applications-recent-projects-grid`;
    const gridConfig = dataGrid[gridId];

    const canViewUtility = hasAnyOfPermissions([systemUserRights.VISIONDSM_VIEW_UTILITY]);
    const canViewProgram = hasAnyOfPermissions([systemUserRights.VISIONDSM_VIEW_PROGRAM]);

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: dataGridID,
                dataGridInstanceId: gridId,
                data: true,
            })
        );
    }, [dataGridID, gridId, dispatch]);

    const onRowAction = (action) => {
        const { dataItem } = action;

        const applicationNumber = dataItem[columnApplicationNumber];

        openProjectTab({ applicationNumber });
    };

    if (!gridConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "applications_recent",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid
                name={gridId}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [columnProgramID]: (column, row) =>
                        canViewProgram ? (
                            <ActionLabel
                                onClick={() =>
                                    dispatch(
                                        openProgramTab({
                                            programNumber: row[columnProgramNumber],
                                        })
                                    )
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ) : (
                            <Label>{row[column.key]}</Label>
                        ),
                    [columnProgramName]: (column, row) =>
                        canViewProgram ? (
                            <ActionLabel
                                onClick={() =>
                                    dispatch(
                                        openProgramTab({
                                            programNumber: row[columnProgramNumber],
                                        })
                                    )
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ) : (
                            <Label>{row[column.key]}</Label>
                        ),
                    [columnUtilityName]: (column, row) =>
                        canViewUtility ? (
                            <ActionLabel
                                onClick={() =>
                                    openUtilityTab({
                                        utilityNumber: row[columnUtilityNumber],
                                    })
                                }
                            >
                                {row[column.key]}
                            </ActionLabel>
                        ) : (
                            <Label>{row[column.key]}</Label>
                        ),
                    [columnApplicationNumber]: (column, row) => (
                        <ActionLabel
                            onClick={() =>
                                openProjectTab({
                                    applicationNumber: row[column.key],
                                })
                            }
                        >
                            {row[column.key]}
                        </ActionLabel>
                    ),
                    [columnProjectNumber]: (column, row) => {
                        if (row[columnApplicationNumber]) {
                            return (
                                <ActionLabel
                                    onClick={() =>
                                        openProjectTab({
                                            applicationNumber: row[columnApplicationNumber],
                                        })
                                    }
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            );
                        }

                        return row[column.key];
                    },
                    [columnProjectName]: (column, row) => {
                        if (row[columnApplicationNumber]) {
                            return (
                                <ActionLabel
                                    onClick={() =>
                                        openProjectTab({
                                            applicationNumber: row[columnApplicationNumber],
                                        })
                                    }
                                >
                                    {row[column.key]}
                                </ActionLabel>
                            );
                        }

                        return row[column.key];
                    },
                }}
            />
        </>
    );
});

export default ApplicationProcessingRecentApplications;

export const RecentApplicationsDataGrid = connect((state) => ({
    user: state.user,
    dataGrid: state.dataGrid,
}))(Grid);
