import React, { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportUserList, getProgramListInfo, setProgramLevelRoleForSelection } from "./utils";

import AssociationsList from "components/ui/AssociationsList";
import { RolesList } from "components/ui/List/RolesList";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavHeaderInfoList from "components/ui/SideNav/SideNavHeaderInfoList";
import { useResource } from "store/resources/useResource";
import { getClientUsersResourceParams } from "store/configureResources";
import { ChangeProgramLevelRoleTitle, RoleChangeModalMessage } from "./RoleChangeModalMessage";

export const RolesByUserSideNav = memo(({ clientNumber, clientName, utilityNumber, roles, selectedUserNumbers, programs, onClose }) => {
    const dispatch = useDispatch();
    const utilityName = useSelector((state) => state.resources.utilities.itemsById[utilityNumber]?.utility);

    const [allUsers] = useResource({
        ...getClientUsersResourceParams({ clientNumber, utilityNumber }),
        transform: (data) => data?.clientUserList ?? [],
    });

    const users = useMemo(() => {
        return selectedUserNumbers.map((key) => allUsers.find((i) => i.userNumber === key));
    }, [selectedUserNumbers, allUsers]);

    const programsList = useMemo(() => {
        return (programs ?? []).map((item, index) => ({
            itemId: item.programNumber,
            itemName: item.program,
        }));
    }, [programs]);

    const itemInfoRef = useRef();

    const itemInfo = useMemo(() => {
        const result = getProgramListInfo({
            utilityNumber,
            roles,
            programs,
            users,
        });
        itemInfoRef.current = result;
        return result;
    }, [utilityNumber, roles, programs, users]);

    const onHeaderRoleClick = useCallback(
        ({ roleId, roleName, selectedItems }) => {
            const userNumbers = users.map((i) => i.userNumber);

            const programNumbers = selectedItems?.length > 0 ? selectedItems : programs.map((i) => i.programNumber);

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers,
                programNumbers,
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs.filter((i) => programNumbers.some((item) => item === i.programNumber))}
                        userList={users}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, programs, users, dispatch]
    );

    const onListItemRoleClick = useCallback(
        (data) => {
            const { roleId, itemId } = data;
            const roleName = roles.find((r) => r.roleId === roleId)?.roleName;

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers: users.map((i) => i.userNumber),
                programNumbers: [itemId],
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs.filter((i) => i.programNumber === itemId)}
                        userList={users}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, roles, programs, users, dispatch]
    );

    const onListItemRoleBadgeClick = useCallback((data) => {
        const { roleId, itemData } = data;
        const users = itemData.roleAssignments[roleId];

        if (users) {
            exportUserList({ users });
        }
    }, []);

    const onGetListItemInfo = useCallback(async (itemId, refresh) => {
        return itemInfoRef.current[itemId];
    }, []);

    const showRolesList = roles.length > 0;

    const headerItems = [
        {
            label: "Client",
            value: clientName,
        },
        {
            label: "Utility",
            value: utilityName,
        },
    ];

    const roleHeaderButtonTitle = useCallback(({ isAnyItemSelected }) => {
        return isAnyItemSelected ? "Set role for selected users in selected programs" : "Set role for selected users in all programs";
    }, []);

    const roleButtonTitle = useCallback(({ isHighlighted, roleName }) => {
        return `Set ${roleName} role for this program`;
    }, []);

    const roleBadgeTitle = useCallback(({ roleAssignments, roleName }) => {
        return `${roleAssignments} of selected users in ${roleName} role`;
    }, []);

    return (
        <SideNavContent className="role-management-sidenav">
            <div className="flex-row flex-one-in-column">
                <SideNavHeader sidenavHeaderLeftAligned title="Manage Programs" leadBlockIcon="theaters-empty" onClose={onClose}>
                    <div className="role-management-sidenav-header-top">
                        <SideNavHeaderInfoList items={headerItems} />
                    </div>
                    <AssociationsList headerText="Selected Users" list={users} displayProperty="userName" readOnly />
                </SideNavHeader>
                <SideNavBody noPadding>
                    {showRolesList && (
                        <RolesList
                            listItemsTitle="Programs"
                            roles={roles}
                            listItems={programsList}
                            listItemInfo={itemInfo}
                            roleHeaderButtonTitle={roleHeaderButtonTitle}
                            roleButtonTitle={roleButtonTitle}
                            roleBadgeTitle={roleBadgeTitle}
                            onHeaderRoleClick={onHeaderRoleClick}
                            onListItemRoleClick={onListItemRoleClick}
                            onListItemRoleBadgeClick={onListItemRoleBadgeClick}
                            onListItemsSelect={() => {}}
                            onGetListItemInfo={onGetListItemInfo}
                        />
                    )}
                </SideNavBody>
            </div>
        </SideNavContent>
    );
});
