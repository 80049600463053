import * as actionType from "../actionTypes";
import { transformDashboardResponse } from "../../components/utils/dashboard";

export function dashboardsMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        if (action.passThroughData && action.passThroughData.resourceName === "dashboards") {
            if (action.type === actionType.API_CRUD_READ_SUCCESS) {
                action.data = transformDashboardResponse(action.data.dashboardList);
            }
        }

        return next(action);
    };
}
