import * as actionType from "../actionTypes";
import * as selectors from "./selectors";

import { uniqueId, get, set, isString, isBoolean, isNil, isNumber, cloneDeep } from "lodash";
import {
    removeElementById,
    ElementTypes,
    updateOrder,
    updateOrderList,
    formIdToSchemaId,
    getSchemaIds,
    addFieldToSelectedColumn,
    getFieldPathByFieldNumber,
    addColumnToSelectedSection,
    updateField,
    FIELD_ID_PREFIX,
    COLUMN_ID_PREFIX,
    SECTION_ID_PREFIX,
    addSectionToPage,
} from "../../components/utils/form";
import { createId } from "../../components/utils/string";
import { createPage, validatePropertiesForm } from "./utils";
import { batch } from "react-redux";
import { elementReady, isInViewport } from "components/utils/dom";
import { setActivePage } from "store/pages/actions";

const ROW_ID_PREFIX = "row";

// Timeout handlers for setTimeout.
let timeoutHandlers = {};

// List of references to active properties forms. Formbuilder instanceId is a key.
let propertiesForms = {};

export const initPage =
    ({ instanceId, schema, uiSchema, rules, initialValues, selectedElementId, isNewPage, keepInitializedPage }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        const existingElementNumbers = getSchemaIds(schema)
            .map((elementSchemaId) => ({
                uiSchema: selectors.getElementUiSchema({
                    uiSchema,
                    elementSchemaId,
                }),
            }))
            .map(selectors.getElementNumber)
            .concat(isNewPage ? [] : [uiSchema["af:pageNumber"]]);

        batch(() => {
            dispatch({
                type: actionType.APP_FORM_INIT,
                instanceId,
                schema,
                uiSchema,
                rules,
                initialValues,
                selectedElementId: selectedElementId ?? currentForm?.selectedElementId,
                existingElementNumbers,
                keepInitializedPage,
            });

            dispatch(scrollSelectedElementIntoView({ instanceId }));
        });
    };

export const updatePage =
    ({ instanceId, schema, uiSchema, rules, initialValues, selectedElementId }) =>
    async (dispatch, getState) => {
        dispatch({
            type: actionType.APP_FORM_UPDATE_PAGE,
            instanceId,
            schema,
            uiSchema,
            rules,
            initialValues,
            selectedElementId,
        });
    };

export const initRebuiltPage =
    ({ programNumber, instanceId, page }) =>
    (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        const { selectedElementId } = formBuilder;
        batch(() => {
            if (selectedElementId) {
                dispatch(deselectElement({ instanceId }));
            }
            dispatch(setActivePage({ id: programNumber, page }));
            if (page.configuration) {
                const { schema, uiSchema, rules } = page.configuration;
                dispatch({
                    type: actionType.APP_FORM_INIT,
                    instanceId,
                    schema: schema,
                    uiSchema: uiSchema,
                    rules: rules,
                });
            }
            // Clear validation errors
            dispatch(
                setValidationErrors({
                    instanceId,
                    errorList: [],
                })
            );
            dispatch(scrollSelectedElementIntoView({ instanceId }));
        });
    };

export const revertPageChanges =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        const { initialSchema, initialUiSchema, initialRules, initialValues, selectedElementId } = formBuilder;

        batch(() => {
            if (selectedElementId) {
                dispatch(deselectElement({ instanceId }));
            }

            dispatch({
                type: actionType.APP_FORM_UPDATE_PAGE,
                instanceId,
                schema: initialSchema,
                uiSchema: initialUiSchema,
                rules: initialRules,
                initialValues: initialValues,
            });

            // Clear validation errors
            dispatch(
                setValidationErrors({
                    instanceId,
                    errorList: [],
                })
            );

            if (selectedElementId) {
                // Added setTimeout to allow deselect and select element again in next cycle.
                setTimeout(() => {
                    dispatch(
                        selectElement({
                            instanceId,
                            elementId: selectedElementId,
                        })
                    );
                }, 0);
            }
        });
    };

export const destroy =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        dispatch({
            type: actionType.APP_FORM_DESTROY,
            instanceId,
        });
    };

/**
 * Select element in form builder.
 *
 * @param {object} params
 * @param {string} params.instanceId Form builder instance id
 * @param {string} params.elementId Id of the element to select
 * @param {boolean} params.scrollToElement Scroll to selected element if true. Default is true.
 * @param {boolean} params.autoFocus Focus selected element if true. Default is true.
 * @param {boolean} params.validateProperties Validate current element properties form before element selection. Default is false.
 */
export const selectElement =
    ({ instanceId, elementId, scrollToElement = true, autoFocus = false, validateProperties = false }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        try {
            if (validateProperties && propertiesForms[instanceId]?.current) {
                await validatePropertiesForm({
                    instanceId,
                    formRef: propertiesForms[instanceId],
                    elementId: currentForm.selectedElementId,
                    dispatch,
                });
            }

            batch(() => {
                // Check if not selected
                if (currentForm.selectedElementId !== elementId) {
                    dispatch({
                        type: actionType.APP_FORM_SELECT_ELEMENT,
                        instanceId,
                        elementId,
                    });
                }

                // Scroll to element
                if (scrollToElement) {
                    dispatch(scrollSelectedElementIntoView({ instanceId }));
                }

                if (autoFocus) {
                    dispatch(focusSelectedElement({ instanceId }));
                }
            });
        } catch {}
    };

export const deselectElement =
    ({ instanceId, validateProperties = false }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        try {
            dispatch({
                type: actionType.APP_FORM_SELECT_ELEMENT,
                instanceId,
                elementId: null,
            });

            if (validateProperties && propertiesForms[instanceId]?.current) {
                await validatePropertiesForm({
                    instanceId,
                    formRef: propertiesForms[instanceId],
                    elementId: currentForm.selectedElementId,
                    dispatch,
                });
            }
        } catch {}
    };

export const scrollSelectedElementIntoView =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const timerKey = `${instanceId}-scroll-to-element`;

        // Cancel previous pending scroll if there is any.
        clearTimeout(timeoutHandlers[timerKey]);

        // Wait a little and scroll selected element in to view if it is of type field
        timeoutHandlers[timerKey] = setTimeout(() => {
            const selectedElementType = selectors.getSelectedElementType(selectors.getFormBuilder({ instanceId }));

            const scrollToElement = () => {
                elementReady("#" + CSS.escape(instanceId) + " .field-selected").then((element) => {
                    const rect = element.getBoundingClientRect();
                    if ((window.innerHeight || document.documentElement.clientHeight) > rect.height) {
                        if (!isInViewport(element, 200)) {
                            element.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                            });
                        }
                    } else {
                        element.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }
                });
            };

            if ([ElementTypes.FIELD, ElementTypes.PAGE, ElementTypes.COLUMN, ElementTypes.SECTION].includes(selectedElementType)) {
                scrollToElement();
            }
        }, 50);
    };

export const focusSelectedElement =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        setTimeout(() => {
            const element = document.querySelector(`.field-selected .text`);

            if (element) {
                element.focus();
            }
        }, 100);
    };

export const removeElement =
    ({ instanceId, elementId }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;

        const id = elementId.split("_").slice(1).join(".");
        const updatedSchemas = removeElementById({
            elementId: id,
            schema,
            uiSchema,
            rules,
        });

        batch(() => {
            dispatch({
                type: actionType.APP_FORM_SELECT_ELEMENT,
                instanceId,
                elementId: null,
            });

            if (id === "") {
                dispatch(destroy({ instanceId }));
                dispatch({
                    type: actionType.FORM_PAGES_NEW_PAGE_DELETED,
                    pageNumber: instanceId,
                });
            } else {
                dispatch({
                    type: actionType.APP_FORM_UPDATE,
                    instanceId,
                    ...updatedSchemas,
                    initialValues: {
                        ...currentForm.initialValues,
                    },
                });
            }
        });
    };

export const addPage =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const newPage = createPage();
        const { schema, uiSchema, rules, initialValues } = newPage.configuration;

        dispatch({
            type: actionType.APP_FORM_ADD_PAGE,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const addSection =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        const { rules, initialValues } = formBuilder;
        const { schema, uiSchema } = addSectionToPage(formBuilder);

        dispatch({
            type: actionType.APP_FORM_ADD_SECTION,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const addRow =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });

        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;
        let initialValues = currentForm.initialValues;

        const activeSectionId = selectors.getActiveSectionId(currentForm);
        const activeSection = selectors.getActiveSection(currentForm);
        const childCount = selectors.getSectionChildCount(currentForm);
        let rowId = uniqueId(ROW_ID_PREFIX);

        if (childCount === 0) {
            activeSection.properties = {};
        }

        while (activeSection.properties[rowId]) {
            rowId = uniqueId(ROW_ID_PREFIX);
        }

        schema.properties[activeSectionId] = {
            ...schema.properties[activeSectionId],
            properties: {
                ...schema.properties[activeSectionId].properties,
                [rowId]: {
                    type: "object",
                    title: "Row title",
                    description: "Row description",
                    properties: {},
                },
            },
        };

        let order = uiSchema[activeSectionId]["ui:order"] || [];
        order.push(rowId);

        uiSchema = {
            ...uiSchema,
            [activeSectionId]: {
                ...uiSchema[activeSectionId],
                "ui:order": order,
                [rowId]: {
                    classNames: ElementTypes.ROW,
                    "ui:elementType": ElementTypes.ROW,
                    "af:rowNumber": createId(),
                },
            },
        };

        dispatch({
            type: actionType.APP_FORM_UPDATE,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const addColumn =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        if (selectors.isAddColumnDisabled(formBuilder)) {
            return;
        }

        const { rules, initialValues } = formBuilder;
        const { schema, uiSchema } = addColumnToSelectedSection(formBuilder);

        dispatch({
            type: actionType.APP_FORM_ADD_COLUMN,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const addField =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        const { rules, initialValues } = formBuilder;
        const { schema, uiSchema, fieldNumber } = addFieldToSelectedColumn(formBuilder);
        const fieldPath = getFieldPathByFieldNumber({
            schema,
            uiSchema,
            fieldNumber,
        });
        const elementId = instanceId + "_" + fieldPath.split(".").join("_");

        dispatch({
            type: actionType.APP_FORM_ADD_FIELD,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });

        dispatch(
            selectElement({
                instanceId,
                elementId,
                scrollToElement: true,
                autoFocus: true,
                validateProperties: true,
            })
        );
    };

export const pagePropertiesUpdate =
    ({ instanceId, values, programNumber, formNumber }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;
        let initialValues = currentForm.initialValues;

        schema = {
            ...schema,
            title: values["title"],
            description: values["description"],
        };

        // Update UI application form specific schema fields
        [
            "defaultPage",
            "status",
            "disqualificationPage",
            "denyLimitedAccess",
            "allowEditAppForm",
            "runAppEntryStatusInWizard",
            "runAppReceivedStatusInWizard",
            "showFormPageOnApp",
            "portalWizardStep",
        ].forEach((key) => {
            if (
                (isString(values[key]) && values[key].length > 0) ||
                (isNumber(values[key]) && values[key]) ||
                (isBoolean(values[key]) && values[key])
            ) {
                uiSchema = {
                    ...uiSchema,
                    [`af:${key}`]: values[key],
                };
            } else if (Array.isArray(values[key]) && values[key].filter(isString).length > 0) {
                uiSchema = {
                    ...uiSchema,
                    [`af:${key}`]: values[key].filter(isString),
                };
            } else {
                const updatedUiSchema = Object.assign({}, uiSchema);
                delete updatedUiSchema[`af:${key}`];

                uiSchema = {
                    ...updatedUiSchema,
                };
            }
        });

        dispatch({
            type: actionType.APP_FORM_UPDATE_PAGE,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const sectionPropertiesUpdate =
    ({ instanceId, values }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;
        let initialValues = currentForm.initialValues;

        const activeSectionId = selectors.getActiveSectionId(currentForm);
        const activeSection = selectors.getActiveSection(currentForm);

        if (isNil(activeSectionId)) {
            return;
        }

        schema = {
            ...schema,
            properties: {
                ...schema.properties,
                [activeSectionId]: {
                    ...activeSection,
                    title: values["title"],
                    description: values["description"],
                },
            },
        };

        const elementSchemaId = formIdToSchemaId(currentForm.selectedElementId);
        uiSchema = updateOrder(currentForm, elementSchemaId, values["order"]);

        uiSchema = {
            ...uiSchema,
        };

        dispatch({
            type: actionType.APP_FORM_UPDATE_SECTION,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
        dispatch(scrollSelectedElementIntoView({ instanceId }));
    };

export const columnPropertiesUpdate =
    ({ instanceId, values }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;
        let initialValues = currentForm.initialValues;

        const activeSectionId = selectors.getActiveSectionId(currentForm);
        const activeSection = selectors.getActiveSection(currentForm);
        const activeColumnId = selectors.getActiveColumnId(currentForm);
        const activeColumn = selectors.getActiveColumn(currentForm);

        // Do nothing if section or column not found
        if (isNil(activeSection) || isNil(activeColumn)) {
            return;
        }

        const elementSchemaId = formIdToSchemaId(currentForm.selectedElementId);
        uiSchema = updateOrder(currentForm, elementSchemaId, values["order"]);

        uiSchema = {
            ...uiSchema,
        };

        schema = {
            ...schema,
            properties: {
                ...schema.properties,
                [activeSectionId]: {
                    ...activeSection,
                    properties: {
                        ...activeSection.properties,
                        [activeColumnId]: {
                            ...activeColumn,
                            type: "object",
                            title: values["title"],
                            description: values["description"],
                        },
                    },
                },
            },
        };

        dispatch({
            type: actionType.APP_FORM_UPDATE_COLUMN,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const rowPropertiesUpdate =
    ({ instanceId, values }) =>
    async (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        let schema = currentForm.schema;
        let uiSchema = currentForm.uiSchema;
        let rules = currentForm.rules;
        let initialValues = currentForm.initialValues;

        const activeSectionId = selectors.getActiveSectionId(currentForm);
        const activeSection = selectors.getActiveSection(currentForm);
        const activeRowId = selectors.getActiveRowId(currentForm);
        const activeRow = selectors.getActiveRow(currentForm);

        if (isNil(activeSectionId)) {
            return;
        }

        const elementSchemaId = formIdToSchemaId(currentForm.selectedElementId);
        uiSchema = updateOrder(currentForm, elementSchemaId, values["order"]);

        uiSchema = {
            ...uiSchema,
        };

        schema = {
            ...schema,
            properties: {
                ...schema.properties,
                [activeSectionId]: {
                    ...activeSection,
                    properties: {
                        ...activeSection.properties,
                        [activeRowId]: {
                            ...activeRow,
                            title: values["title"],
                            description: values["description"],
                        },
                    },
                },
            },
        };

        dispatch({
            type: actionType.APP_FORM_UPDATE,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
    };

export const fieldPropertiesUpdate =
    ({ instanceId, values }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        const activeSectionId = selectors.getActiveSectionId(formBuilder);
        const schemaUiField = selectors.getActiveUiField(formBuilder);
        const initialValues = formBuilder.initialValues;

        if (isNil(activeSectionId) || values["fieldNumber"] !== schemaUiField?.["af:fieldNumber"]) {
            return;
        }

        // Get gen proc param names if gen proc is selected
        const genProcParameterList = values["procId"]
            ? get(getState(), `resources.programFormGenProcs.itemsById[${values["procId"]}]`, [])
            : [];

        const { schema, uiSchema, rules } = updateField({
            formBuilder,
            values,
            genProcParameterList,
        });

        dispatch({
            type: actionType.APP_FORM_UPDATE_FIELD,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues,
        });
        dispatch(scrollSelectedElementIntoView({ instanceId }));
    };

export const dropElement =
    ({ instanceId, elementId, dropTargetId, order }) =>
    (dispatch, getState) => {
        const currentForm = selectors.getFormBuilder({
            instanceId,
            state: getState().formBuilder,
        });
        let uiSchema = cloneDeep(currentForm.uiSchema);
        let schema = cloneDeep(currentForm.schema);
        let rules = cloneDeep(currentForm.rules);

        // The same object
        if (elementId.indexOf(dropTargetId) === 0) {
            const elementSchemaId = formIdToSchemaId(elementId);

            uiSchema = updateOrder({ schema, uiSchema }, elementSchemaId, order);

            uiSchema = {
                ...uiSchema,
            };
        } else {
            const elementSchemaUiPath = elementId.split("_").slice(1);
            const elementSchemaPath = `properties.${elementId.split("_").slice(1).join(".properties.")}`;

            const elementUiSchema = {
                ...get(uiSchema, elementSchemaUiPath, {}),
            };

            const elementSchema = {
                ...get(schema, elementSchemaPath, {}),
            };

            const id = elementId.split("_").slice(1).join(".");
            const removeElementResult = removeElementById({
                elementId: id,
                schema,
                uiSchema,
            });
            const addElementResult = addElementById({
                containerId: dropTargetId,
                elementId,
                elementSchema,
                elementUiSchema,
                order,
                schema: removeElementResult.schema,
                uiSchema: removeElementResult.uiSchema,
            });

            uiSchema = {
                ...addElementResult.uiSchema,
            };

            schema = {
                ...addElementResult.schema,
            };

            const sourceId = elementId.split("_").slice(1).join(".");
            const targetId = dropTargetId.split("_").slice(1).concat([addElementResult.fieldId]).join(".");

            rules = (rules || []).map((r) => {
                if (r.conditions[sourceId]) {
                    // Add new condition
                    const conditions = {
                        ...r.conditions,
                        [targetId]: r.conditions[sourceId],
                    };

                    // Remove old condition
                    const newConditions = Object.assign({}, conditions);
                    delete newConditions[sourceId];

                    // Update rule
                    const updatedRule = {
                        ...r,
                        conditions: {
                            ...newConditions,
                        },
                    };

                    return updatedRule;
                }

                if (r.event.params.field === sourceId) {
                    const updatedRule = {
                        ...r,
                        event: {
                            ...r.event,
                            params: {
                                field: targetId,
                            },
                        },
                    };

                    return updatedRule;
                }

                return r;
            });
        }

        dispatch({
            type: actionType.APP_FORM_UPDATE,
            instanceId,
            uiSchema,
            schema,
            rules,
            initialValues: {
                ...currentForm.initialValues,
            },
        });
    };

export const setFormData =
    ({ instanceId, formData }) =>
    async (dispatch, getState) => {
        dispatch({
            type: actionType.APP_FORM_SET_FORM_DATA,
            instanceId,
            formData,
        });
    };

export const setValidationErrors =
    ({ instanceId, errorList }) =>
    async (dispatch, getState) => {
        const validationErrors = (errorList ?? []).reduce(
            (result, item) => ({
                ...result,
                [item.entityIdentifier]: {
                    elementId: instanceId + "_" + item.entityName.split(".").join("_"),
                    message: item.errorMessage,
                },
            }),
            {}
        );

        dispatch({
            type: actionType.APP_FORM_SET_VALIDATION_ERRORS,
            instanceId,
            validationErrors,
        });
    };

export const setValidationError =
    ({ instanceId, elementId, message }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({ instanceId });
        const currentErrors = formBuilder.validationErrors ?? {};
        const errorKey = selectors.getSelectedElementNumber(formBuilder);

        // Do nothing if there is already and error
        if (errorKey && !currentErrors[errorKey]) {
            const validationErrors = {
                ...currentErrors,
                [errorKey]: {
                    elementId,
                    message,
                },
            };

            dispatch({
                type: actionType.APP_FORM_SET_VALIDATION_ERRORS,
                instanceId,
                validationErrors,
            });
        }
    };

export const clearValidationError =
    ({ instanceId, elementId }) =>
    async (dispatch, getState) => {
        //quick fix:
        let elementIdArr = elementId.split("_");
        elementIdArr[0] = instanceId;
        const newElementID = elementIdArr.join("_");
        //
        const formBuilder = selectors.getFormBuilder({ instanceId });
        const currentErrors = formBuilder.validationErrors ?? {};
        const currentErrorKeys = Object.keys(currentErrors);

        if (currentErrorKeys.some((key) => currentErrors[key].elementId === newElementID)) {
            const validationErrors = currentErrorKeys.reduce((result, key) => {
                if (currentErrors[key].elementId !== newElementID) {
                    return {
                        ...result,
                        [key]: currentErrors[key],
                    };
                }

                return result;
            }, {});

            dispatch({
                type: actionType.APP_FORM_SET_VALIDATION_ERRORS,
                instanceId,
                validationErrors,
            });
        }
    };

export const copyValidationErrors =
    ({ sourceInstanceId, targetInstanceId }) =>
    async (dispatch, getState) => {
        const sourceFormBuilder = selectors.getFormBuilder({
            instanceId: sourceInstanceId,
        });
        const sourceErrors = sourceFormBuilder.validationErrors;

        if (sourceErrors) {
            // Replace element id prefixes with target instance to work properly.
            const validationErrors = Object.keys(sourceErrors).reduce(
                (result, key) => ({
                    ...result,
                    [key]: {
                        ...sourceErrors[key],
                        elementId: sourceErrors[key].elementId.replace(sourceInstanceId, targetInstanceId),
                    },
                }),
                {}
            );

            dispatch({
                type: actionType.APP_FORM_SET_VALIDATION_ERRORS,
                instanceId: targetInstanceId,
                validationErrors,
            });
        }
    };

export const goToNextError =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({ instanceId });
        const selectedElementNumber = selectors.getSelectedElementNumber(formBuilder);
        const validationErrors = formBuilder.validationErrors ?? {};
        const errorKeys = Object.keys(validationErrors);

        if (errorKeys.length > 0) {
            let nextIndex = errorKeys.findIndex((i) => i === selectedElementNumber) + 1;

            // Start from beginning on overlap
            if (nextIndex === errorKeys.length) {
                nextIndex = 0;
            }

            const elementError = validationErrors[errorKeys[nextIndex]];
            if (elementError) {
                const { elementId } = elementError;
                dispatch(selectElement({ instanceId, elementId }));
            }
        }
    };

export const goToPrevError =
    ({ instanceId }) =>
    async (dispatch, getState) => {
        const formBuilder = selectors.getFormBuilder({ instanceId });
        const selectedElementNumber = selectors.getSelectedElementNumber(formBuilder);
        const validationErrors = formBuilder.validationErrors ?? {};
        const errorKeys = Object.keys(validationErrors);

        if (errorKeys.length > 0) {
            let nextIndex = errorKeys.findIndex((i) => i === selectedElementNumber) - 1;

            // Start from end on overlap
            if (nextIndex < 0) {
                nextIndex = errorKeys.length - 1;
            }

            const elementError = validationErrors[errorKeys[nextIndex]];
            if (elementError) {
                const { elementId } = elementError;
                dispatch(selectElement({ instanceId, elementId }));
            }
        }
    };

export const updateUnknownElement =
    ({ instanceId, uiSchema }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionType.APP_FORM_UPDATE_UI_SCHEMA,
            instanceId,
            uiSchema: uiSchema,
        });
    };

export const registerPropertiesForm =
    ({ instanceId, formRef }) =>
    (dispatch, getState) => {
        if (formRef) {
            propertiesForms[instanceId] = formRef;
        }
    };

export const unregisterPropertiesForm =
    ({ instanceId }) =>
    (dispatch, getState) => {
        if (propertiesForms[instanceId]) {
            delete propertiesForms[instanceId];
        }
    };

const addElementById = ({ containerId, elementId, elementSchema, elementUiSchema, order, schema, uiSchema }) => {
    const parts = containerId.split("_");
    let fieldId = elementId.split("_").pop();

    if (parts.length > 0) {
        if (parts.length === 1) {
            uiSchema = {
                ...elementUiSchema,
            };
            schema = {
                ...elementSchema,
            };
        } else {
            const idPrefix = parts.length === 3 ? FIELD_ID_PREFIX : parts.length === 2 ? COLUMN_ID_PREFIX : SECTION_ID_PREFIX;

            let schemaPath = `properties.${parts.slice(1).join(".properties.")}.properties.${fieldId}`;

            // Ensure field has unique id
            while (!isNil(get(schema, schemaPath))) {
                fieldId = uniqueId(idPrefix);
                schemaPath = `properties.${parts.slice(1).join(".properties.")}.properties.${fieldId}`;
            }

            // schema
            set(schema, schemaPath, elementSchema);

            // uiSchema
            const schemaUiPath = `${parts.slice(1).join(".")}.${fieldId}`;
            set(uiSchema, schemaUiPath, elementUiSchema);

            const element = selectors.getElementSchemasById({
                elementId: containerId,
                schema,
                uiSchema,
            });
            const currentOrder = element.uiSchema["ui:order"];

            if (parts.length < 2) {
                uiSchema = {
                    ...element.uiSchema,
                    "ui:order": updateOrderList(currentOrder, fieldId, order),
                };
            } else {
                set(uiSchema, parts.slice(1).join("."), {
                    ...element.uiSchema,
                    "ui:order": updateOrderList(currentOrder, fieldId, order),
                });
            }
        }
    }

    return {
        schema,
        uiSchema,
        fieldId,
    };
};
