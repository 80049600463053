import React from "react";
import ClassNames from "classnames";

import IconWithLabel from "../Icons/IconWithLabel";

import "./style.scss";

const AddNewItemPanel = (props) => {
    const className = ClassNames("add-new-item-panel flex-row align-center justify-center", {
        disabled: props.disabled,
    });

    return (
        <div className={className} onClick={props.onClick}>
            <IconWithLabel title={props.tooltip} disabled={props.disabled} icon="plus">
                {props.text}
            </IconWithLabel>
        </div>
    );
};

export default AddNewItemPanel;
