import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";

import { useProgramRights, useResource } from "../../../../store/resources/useResource";
import { setActiveDashboardTool } from "../../../../store/dashboards/actions";
import { systemUserRights } from "../../../utils/user";
import { windowContainerTypes } from "../../../utils/window";

import WaitIcon from "../../WaitIcon";
import IconWrap from "../../Icons";
import ActionLabelWithDropdown from "../../Label/ActionLabelWithDropdown";
import { appIsLockedText } from "../../Dashboard/Panel/Project/utils";
import { reapplySelectedStatusText } from "../../Dashboard/Panel/Project/utils";
import ProjectSubInfoLine from "../../../views/ProjectView/ProjectSubInfoLine";
import { confirmApplicationStatusChange } from "../../../views/ProjectView/utils";
import SimilarPremises from "../../Dashboard/Panel/Project/ContactsPanel/SimilarPremises";
import CopyToClipboard from "components/ui/Label/CopyToClipboard";
import { toArray } from "components/utils/array";

import "./WindowHeaderContent.scss";
import "./WindowHeaderForProject.scss";

const WindowHeaderForProject = memo(({ view }) => {
    const dispatch = useDispatch();

    const applicationNumber = view.props.applicationNumber;
    const application = useSelector((state) => state.resources.applicationDetails?.itemsById[applicationNumber]);

    const isDesktop = useSelector((state) => get(state, "window.isDesktop"));
    const isSplitView = useSelector((state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isSplitView);

    const isLocked = application?.isLocked;

    const [programRights = []] = useProgramRights({ programNumber: application?.programNumber });

    const [statuses = [], isLoadingStatuses] = useResource({
        resourceName: "applicationWorkflowStatus",
        key: applicationNumber,
        path: {
            applicationNumber,
        },
        transform: (data) => {
            return toArray(data).filter((item) => (item.itemStatus ?? "").toLowerCase() === "active");
        },
    });

    const canChangeStatus = !isLocked && statuses.length > 1 && programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS);
    const canReapplyStatus = !isLocked && programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS);

    const handleOpenSimilarPremises = useCallback(
        (panel) => {
            dispatch(
                setActiveDashboardTool({
                    entityNumber: applicationNumber,
                    tool: panel.type,
                })
            );
        },
        [applicationNumber, dispatch]
    );

    const handleChangeStatus = useCallback(
        (status) => {
            if (canChangeStatus && application && status) {
                confirmApplicationStatusChange({
                    application,
                    status,
                    applicationNumber,
                });
            }
        },
        [application, applicationNumber, canChangeStatus]
    );

    const handleReapplyChangeStatus = useCallback(() => {
        if (application && application.status && canReapplyStatus) {
            const status = {
                status: application.status,
                workflowNumber: application.wfNumber,
            };
            confirmApplicationStatusChange({
                application,
                status,
                applicationNumber,
            });
        }
    }, [application, applicationNumber, canReapplyStatus]);

    if (!application) {
        return null;
    }

    return (
        <div className={"window-header-content app-header" + (isLocked ? " app-is-locked" : "")}>
            {isLocked && (
                <div className="app-is-locked-mark">
                    <IconWrap icon="lock-encryption-empty" title={appIsLockedText}></IconWrap>
                </div>
            )}
            <div className="project-mark">
                <span>Application</span>
            </div>
            <div className="project-number">
                #
                <CopyToClipboard title="Copy Application Number to clipboard" successMessage="Application Number copied to clipboard">
                    {application.projectNumber}
                </CopyToClipboard>
            </div>
            {application.isBookmarked && <IconWrap iconWrap="bookmark-filled" title="Bookmarked" />}
            <div className="project-info">
                <div className="status">
                    <span>Status:</span>
                    {isLoadingStatuses && <WaitIcon />}
                    {!isLoadingStatuses && !canChangeStatus && application.status}
                    {!isLoadingStatuses && canChangeStatus && (
                        <ActionLabelWithDropdown
                            iconWithLabel="shevron-small-down-expand-more"
                            mobileHeader="Change Application Status to"
                            label={application.status}
                            items={statuses}
                            onClick={handleChangeStatus}
                        />
                    )}
                    {!isLoadingStatuses && canReapplyStatus && (
                        <IconWrap
                            iconWrapClickable
                            iconWrapTransparent
                            iconWrap="repeat"
                            title={reapplySelectedStatusText}
                            onClick={handleReapplyChangeStatus}
                        ></IconWrap>
                    )}
                </div>
            </div>
            {isDesktop && !isSplitView && (
                <SimilarPremises applicationNumber={applicationNumber} onOpenDashboardPanel={handleOpenSimilarPremises} />
            )}
            <ProjectSubInfoLine applicationNumber={applicationNumber} statuses={statuses} isLoadingStatuses={isLoadingStatuses} />
        </div>
    );
});

export default WindowHeaderForProject;
