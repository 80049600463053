import React, { memo, useState, useEffect, useCallback } from "react";
import ViewPlaceholder from "../ViewPlaceholder";
import WaitIcon from "../WaitIcon";
import { blobToText } from "components/utils/files";

import "./style.scss";
import IconWrap from "../Icons";
import printJS from "print-js";

const TxtViewer = memo(({ fileData, isLoading }) => {
    const [txtContent, setTxtContent] = useState("");

    // Load file
    useEffect(() => {
        if (fileData && !isLoading) {
            blobToText(fileData.blob, (data) => {
                setTxtContent(data);
            });
        }
    }, [fileData, isLoading]);

    const onPrint = useCallback(() => {
        printJS({ printable: txtContent, type: "raw-html" });
    }, [txtContent]);

    return (
        <>
            <IconWrap icon="printer-empty" onClick={onPrint} title="Print" />
            <div className="txt-viewer-container flex-column fill-height with-scroll">
                {isLoading && (
                    <ViewPlaceholder>
                        <WaitIcon />
                    </ViewPlaceholder>
                )}
                <pre className="txt-viewer">{txtContent}</pre>
            </div>
        </>
    );
});

export default TxtViewer;
