import React, { memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { hasCriteriaForSearch, getSearchCriteriaText } from "../../../../store/globalSearch/utils";

const TabContentForGlobalSearch = memo(({ view }) => {
    const instanceId = get(view, `props.instanceId`);
    const criteriaList = useSelector((state) => get(state, `globalSearch.criteriaList[${instanceId}]`)) || [];
    const hasCriteria = hasCriteriaForSearch({ criteriaList });

    const label = hasCriteria ? "Search Results for" : "Search Results";

    const contentItems = [
        {
            label,
            value: getSearchCriteriaText({ criteriaList }),
        },
    ];

    return (
        <div className="tab-content">
            {contentItems.map((item) => (
                <div key={item.label} className="tab-content-item">
                    <div className="title">{item.label}</div>
                    <div className="value">{item.value}</div>
                </div>
            ))}
        </div>
    );
});

export default TabContentForGlobalSearch;
