import React, { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";

import FieldGroup from "../FieldGroup";
import FieldWrap from "../FieldWrap";
import DropdownField from "../DropdownField";
import DropdownList from "../DropdownList";
import DropdownListItem from "../DropdownList/DropdownListItem";
import Label from "../Label";
import IconWrap from "../Icons";
import TagButton from "../Button/TagButton";
import StatusMsg from "../StatusMsg";
import useOnClickOutside from "components/utils/useOnClickOutside";
import usePopper from "components/utils/usePopper";
import DropdownListWrapper from "../DropdownList/DropdownListWrapper";

import "./style.scss";

const FieldGroupDropdownMultipleChoice = (props) => {
    const triggerRef = useRef();
    const popupRef = useRef();
    const popperRef = useRef();
    const [isListOnTop, setIsListOnTop] = useState(false);
    const [popupBoundary, setPopupBoundary] = useState();

    useOnClickOutside(popupRef, (event) => {
        if (props.active && event.target !== triggerRef.current) {
            props.onClick(event);
            popperRef.current && popperRef.current.forceUpdate();
        }
    });

    useEffect(() => {
        if (triggerRef.current) {
            setPopupBoundary((prevValue) => {
                if (!prevValue) {
                    return triggerRef.current?.closest(".popup-boundary");
                }

                return prevValue;
            });
        }
    }, []);
    const modifiers = useMemo(
        () => [
            {
                name: "preventOverflow",
                options: {
                    boundary: popupBoundary ?? undefined,
                },
            },
            {
                name: "eventListeners",
                options: {
                    scroll: props.visible,
                    resize: props.visible,
                },
            },
            {
                name: "onFlip", // Custom modifier
                phase: "afterWrite",
                enabled: true,
                fn({ state }) {
                    if (props.visible) {
                        setIsListOnTop(state.placement === "top-start");
                    }
                },
            },
            {
                name: "flip",
                options: {
                    padding: 10, // Without this prop the list sometimes not flip if overflows boundary only a little
                },
            },
            {
                name: "sameWidth",
                enabled: true,
                phase: "beforeWrite",
                requires: ["computeStyles"],
                fn: ({ state }) => {
                    state.styles.popper.width = `${state.rects.reference.width}px`;
                },
                effect: ({ state }) => {
                    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
                },
            },
        ],
        [props.visible, popupBoundary]
    );

    usePopper({
        triggerRef,
        popperRef,
        popupRef,
        placement: "bottom-start",
        enabled: props.withPopper,
        modifiers,
    });

    const onPopupClick = (event) => {
        props.onClick && props.onClick(event);
        popperRef.current && popperRef.current.forceUpdate();
    };

    useEffect(() => {
        popperRef.current && popperRef.current.forceUpdate();
    }, [props.items]);

    const onRemoveClick = (item) => (event) => {
        event.stopPropagation();
        props.onRemove && props.onRemove(item);
    };

    const onItemClick = (item) => (event) => {
        if (!item.selected) {
            event.stopPropagation();
            props.onSelect && props.onSelect(item);
        } else {
            onRemoveClick(item)(event);
        }
    };

    return (
        <FieldGroup
            className={cn(props.className, {
                "dropdown-list-up": isListOnTop,
            })}
            fieldGroupRef={props.containerRef}
            inline={props.inline}
        >
            {props.label && <Label labelRequired={props.required}>{props.label}</Label>}
            <FieldWrap dropdown>
                <DropdownField
                    dropdownMultipleChoise
                    elementRef={triggerRef}
                    active={props.active}
                    ghost={props.ghost}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                    error={props.error}
                    filled={props.filled}
                    dropdownFieldIcon={props.dropdownFieldIcon}
                    onClick={onPopupClick}
                    dropdownFieldText={props.placeholder}
                    onListKeyDown={props.onListKeyDown}
                    visible={props.visible}
                >
                    {props.items
                        .filter((i) => i.selected)
                        .map((tag, i) => (
                            <TagButton key={i} edit disabled={props.disabled} title="Remove Item" onClick={onRemoveClick(tag)}>
                                {tag.label}
                            </TagButton>
                        ))}
                </DropdownField>
                <DropdownListWrapper
                    className={props.className}
                    triggerRef={triggerRef}
                    popupRef={popupRef}
                    popperRef={popperRef}
                    enabled={props.withPopper}
                    visible={props.visible}
                    filled={props.filled}
                    multipleChoice
                >
                    <DropdownList
                        elementRef={props.withPopper ? undefined : popupRef}
                        visible={props.visible}
                        onClose={props.onClick}
                        mobileHeader={props.mobileHeader}
                        withFilter={props.withFilter}
                        filterValue={props.filterValue}
                        onFilterChange={props.onFilterChange}
                        onListKeyDown={props.onListKeyDown}
                    >
                        {props.items
                            .filter((i) => !i.hidden)
                            .map((item, i) => (
                                <DropdownListItem
                                    key={i}
                                    className={item.className}
                                    disabled={item.disabled}
                                    multiSelect
                                    focused={item.focused}
                                    onClick={onItemClick(item)}
                                >
                                    {item.label}
                                    {!item.isSelectAllItem && (
                                        <>
                                            <span className="flex-one"></span>
                                            {item.selected ? (
                                                <IconWrap icon="checkbox-checked-filled" onClick={onItemClick(item)} />
                                            ) : (
                                                <IconWrap icon="checkbox-unchecked-empty" />
                                            )}
                                        </>
                                    )}
                                </DropdownListItem>
                            ))}
                    </DropdownList>
                </DropdownListWrapper>
            </FieldWrap>
            {props.msgText && <StatusMsg msgFieldStatus msgInfo={props.msgInfo} msgError={props.msgError} msgText={props.msgText} />}
        </FieldGroup>
    );
};

export default FieldGroupDropdownMultipleChoice;
