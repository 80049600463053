import React, { useCallback, memo } from "react";
import { get } from "lodash";

import { useInvoiceDocument } from "../../../../../../store/resources/useResource";
import { useInvoiceDocumentLocked } from "../../../../../views/UtilityInvoiceView/utils";
import {
    refreshInvoiceItemsGrid,
    refreshInvoiceDocumentsGrid,
    refreshContractDetailsInvoiceDocumentsGrid,
} from "../../../../../../store/dataGrid/refreshGrid";
import { refreshInvoiceDocument } from "../../../../../../store/resources/refreshResource";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Controls from "./Controls";
import Grid from "./Grid";
import Form from "./Form";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

const InvoiceItemsPanel = memo(({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const contractNumber = get(panel, "data.contractNumber");
    const documentNumber = get(panel, "data.documentNumber");

    const [invoiceDocument] = useInvoiceDocument({ documentNumber });
    const invoiceEnabled = get(invoiceDocument, "invoiceEnabled", false);
    const isLocked = useInvoiceDocumentLocked({ documentNumber });

    const onRefresh = useCallback(() => {
        refreshInvoiceItemsGrid({ documentNumber });
        refreshInvoiceDocumentsGrid({ contractNumber });
        refreshContractDetailsInvoiceDocumentsGrid({ contractNumber });
        refreshInvoiceDocument({
            utilityNumber,
            contractNumber,
            documentNumber,
        });
    }, [documentNumber, contractNumber, utilityNumber]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "utility-invoice-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(
            <Form
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                documentNumber={documentNumber}
                gridRefresh={onRefresh}
                onClose={handleCloseSidePanel}
            />
        );
    }, [utilityNumber, contractNumber, documentNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="panel invoices flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Controls isLocked={isLocked} onAdd={handleCreate} onAddEnabled={invoiceEnabled} />
            <Grid utilityNumber={utilityNumber} contractNumber={contractNumber} documentNumber={documentNumber} onRefresh={onRefresh} />
        </div>
    );
});

export default InvoiceItemsPanel;
