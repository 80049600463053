import * as actionTypes from "../actionTypes";
import { unionWith, isEqual, omit } from "lodash";
import { getResourceKey } from "../configureResources";

export const resourceReducer = (state, action, { resourceName }) => {
    const resourceKey = getResourceKey(action.passThroughData);

    switch (action.type) {
        case actionTypes.API_CRUD_READ_LIST_REQUEST:
            state = {
                ...state,
                isReadingList: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.API_CRUD_READ_LIST_SUCCESS:
            state = {
                ...state,
                isReadingList: false,
                items: unionWith(state.items, action.data, isEqual), // Combine unique items
            };
            break;
        case actionTypes.API_CRUD_READ_LIST_ERROR:
            state = {
                ...state,
                isReadingList: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.API_CRUD_READ_REQUEST:
            state = {
                ...state,
                isReading: true,
                isError: false,
                message: null,
                itemsById: {
                    ...state.itemsById,
                    [`${resourceKey}-is-loading`]: true,
                },
            };
            break;
        case actionTypes.API_CRUD_READ_SUCCESS:
            state = {
                ...state,
                isReading: false,
                itemsById: {
                    ...state.itemsById,
                    [resourceKey]: action.data,
                    [`${resourceKey}-is-loading`]: false,
                },
            };
            break;
        case actionTypes.API_CRUD_READ_ERROR:
            const itemsById = omit(state.itemsById, [resourceKey, `${resourceKey}-is-loading`]);

            state = {
                ...state,
                isReading: false,
                itemsById,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.API_CRUD_CREATE_REQUEST:
            state = {
                ...state,
                isCreating: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.API_CRUD_CREATE_SUCCESS:
            state = {
                ...state,
                isCreating: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.API_CRUD_CREATE_ERROR:
            if (resourceKey) {
                // Clear optimistic update values
                const { [resourceKey]: _remove, ...itemsById } = state.itemsById;

                state = {
                    ...state,
                    isCreating: false,
                    itemsById,
                    isError: true,
                    message: action.message,
                };
            } else {
                state = {
                    ...state,
                    isCreating: false,
                    isError: true,
                    message: action.message,
                };
            }
            break;
        case actionTypes.API_CRUD_UPDATE_REQUEST:
            state = {
                ...state,
                isUpdating: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.API_CRUD_UPDATE_SUCCESS:
            state = {
                ...state,
                isUpdating: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.API_CRUD_UPDATE_ERROR:
            if (resourceKey) {
                // Clear optimistic update values
                const { [resourceKey]: _remove, ...itemsById } = state.itemsById;

                state = {
                    ...state,
                    isUpdating: false,
                    itemsById,
                    isError: true,
                    message: action.message,
                };
            } else {
                state = {
                    ...state,
                    isUpdating: false,
                    isError: true,
                    message: action.message,
                };
            }
            break;
        case actionTypes.API_CRUD_DELETE_REQUEST:
            state = {
                ...state,
                isDeleting: true,
                isError: false,
                message: null,
            };
            break;
        case actionTypes.API_CRUD_DELETE_SUCCESS:
            state = {
                ...state,
                isDeleting: false,
                message: action.data.responseMessage,
            };
            break;
        case actionTypes.API_CRUD_DELETE_ERROR:
            state = {
                ...state,
                isDeleting: false,
                isError: true,
                message: action.message,
            };
            break;
        case actionTypes.API_CRUD_OPTIMISTIC_UPDATE_LIST:
            state = {
                ...state,
                items: action.items,
            };
            break;
        case actionTypes.API_CRUD_OPTIMISTIC_UPDATE_ITEM:
            state = {
                ...state,
                itemsById: {
                    ...state.itemsById,
                    [action.id]: action.value,
                    [`${action.id}-is-loading`]: false,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
