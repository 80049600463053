import { CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_ERROR_CLEAR, CHANGE_SSRS_PASSWORD_ERROR } from "../actionTypes";

const initialState = {
    message: null,
    ssrsMessage: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_ERROR:
            state = {
                ...state,
                message: action.message,
            };
            break;
        case CHANGE_PASSWORD_ERROR_CLEAR:
            state = {
                ...state,
                message: null,
                ssrsMessage: null,
            };
            break;
        case CHANGE_SSRS_PASSWORD_ERROR:
            state = {
                ...state,
                message: null,
                ssrsMessage: action.message,
            };
            break;
        default:
            break;
    }

    return state;
};
