import React, { useState, useEffect, useRef } from "react";
import renderjson from "renderjson";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";

const JsonStateErrors = ({ data, onToggleVisible }) => {
    const jsonRef = useRef();

    const [isStateDetailsVisible, setIsStateDetailsVisible] = useState(false);

    useEffect(() => {
        try {
            const element = jsonRef.current;

            if (element && data) {
                const tree = renderjson(JSON.parse(data));
                element.innerHTML = "";
                element.appendChild(tree);
            }
        } catch {}
    }, [data]);

    const openDetails = () => {
        setIsStateDetailsVisible(!isStateDetailsVisible);
        onToggleVisible(!isStateDetailsVisible);
    };

    return (
        <div className="json-state-errors-wrap">
            <IconWithLabel
                iconWithLabelRight
                iconWithLabel={isStateDetailsVisible ? "shevron-in-circle-left-filled--before" : "shevron-in-circle-right-empty--after"}
                onClick={openDetails}
            >
                {isStateDetailsVisible ? "Hide State Details" : "Open State Details"}
            </IconWithLabel>
            <div className="json-state-errors" ref={jsonRef} hidden={!isStateDetailsVisible} />
        </div>
    );
};

export default JsonStateErrors;
