import React, { useRef, memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { submitByRef } from "../../../../../utils/form";

import EventDetails from "./EventDetails";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";

const EditEvent = memo(({ programNumber, applicationNumber, eventNumber, onClose, sidePanel }) => {
    const formRef = useRef();

    const isAppLocked = isApplicationLocked({ applicationNumber });

    const isMobile = useSelector((state) => state.window?.isMobile);

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    const formButtons = (
        <>
            {!isAppLocked && (
                <Button primary onClick={handleSubmit}>
                    Save
                </Button>
            )}
            <Button onClick={onClose}>Cancel</Button>
        </>
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className="edit-event-panel">
            {isMobile && <SideNavHeader title="Edit Event" leadBlockIcon="edit-empty" smallHeader onClose={onClose} />}
            <SideNavBody noPadding>
                <EventDetails
                    formRef={formRef}
                    programNumber={programNumber}
                    applicationNumber={applicationNumber}
                    eventNumber={eventNumber}
                    headerLeftAlignedTitle={"Edit Event"}
                    headerLeftAlignedIcon={"edit-empty"}
                    hasHeaderLeftAligned
                    onClose={onClose}
                    sidePanel={sidePanel}
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton={isMobile} justifyCenter={!isAppLocked} justifyEnd={isAppLocked}>
                {formButtons}
            </SideNavFooter>
        </SideNavContent>
    );
});

export default EditEvent;
