import React from "react";
import ClassNames from "classnames";

const IconGroup = (props) => {
    const className = ClassNames("icon-group", props.className, {
        "header-icon-group": props.header,
        "window-header-icon-group": props.windowHader,
        "sidebar-icon-group": props.sidebar,
    });

    return <div className={className}>{props.children}</div>;
};

export default IconGroup;
