import React, { memo } from "react";

import { programsRebatesGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import IconWithLabel from "../../../../Icons/IconWithLabel";

import RebatesGrid from "./RebatesGrid";

const RebatesPanel = memo(({ panel }) => {
    const programNumber = panel && panel.data && panel.data.programNumber;
    const gridId = `${programNumber}-rebates`;
    const panelTitle = panel && panel.name;

    return (
        <div className="panel rebates-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panelTitle} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "program_payments",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <RebatesGrid
                programNumber={programNumber}
                gridId={gridId}
                filter={`${programsRebatesGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default RebatesPanel;
