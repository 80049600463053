import React, { useCallback, useContext, memo } from "react";

import { ArchivedBatchGridContext } from "../../context/ArchivedBatchGridContext";
import { ArchivedBatchList } from "./ArchivedBatchList";
import { useItems } from "../../../../utils/useItems";

export const ArchivedBatchGrid = memo(() => {
    const { batchDocuments, getBatchDocuments } = useContext(ArchivedBatchGridContext);
    const [expandedArchivedBatches, expandedArchivedBatchesActions] = useItems();
    const [loadingArchivedBatchNumbers, loadingArchivedBatchNumbersActions] = useItems();

    const handleClickArchivedBatchRow = useCallback(
        (batchNumber) => {
            if (expandedArchivedBatches.includes(batchNumber)) {
                expandedArchivedBatchesActions.remove([batchNumber]);
            } else {
                expandedArchivedBatchesActions.add([batchNumber]);
            }

            if (!(batchNumber in batchDocuments)) {
                loadingArchivedBatchNumbersActions.add([batchNumber]);

                getBatchDocuments(batchNumber, () => loadingArchivedBatchNumbersActions.remove(batchNumber));
            }
        },
        [batchDocuments, getBatchDocuments, expandedArchivedBatches, loadingArchivedBatchNumbersActions, expandedArchivedBatchesActions]
    );

    return (
        <div className="application-document-queue__archived-batch-grid">
            <div className="application-document-queue__archived-batch-grid-wrapper">
                <ArchivedBatchList
                    expandedArchivedBatches={expandedArchivedBatches}
                    loadingArchivedBatchNumbers={loadingArchivedBatchNumbers}
                    onClickArchivedBatchRow={handleClickArchivedBatchRow}
                />
            </div>
        </div>
    );
});
