import React from "react";
import { useClientRoleUsers } from "../../../../store/resources/useResource";
import WaitIcon from "../../../ui/WaitIcon";
import CustomList from "../../../ui/List/CustomList";
import { UserRole } from "./types";
import { formatFullName } from "components/utils/user";

const headers = {
    userName: "User Name",
    fullName: "Full Name",
};

const RoleUsers = ({ entityNumber, entityTypeId, role }: RoleUsersProps) => {
    const [users, isLoading] = useClientRoleUsers({
        clientRoleId: role.roleID,
        entityTypeId,
        entityNumber,
        forced: true,
    });

    if (isLoading && users?.length === 0) {
        return <WaitIcon />;
    }

    if (users?.length === 0) {
        return <div>No users</div>;
    }

    return (
        // @ts-ignore
        <CustomList className="no-scroll fill-height" items={users} headers={headers} renderHeaders={Header} renderItem={ListItem} />
    );
};

interface RoleUsersProps {
    entityNumber: string;
    entityTypeId: number;
    role: UserRole;
}

const Header = () => {
    return (
        <>
            <div>{headers.userName}</div>
            <div>{headers.fullName}</div>
        </>
    );
};

const ListItem = ({ userName, firstName, lastName }: ListItemProps) => {
    return (
        <div className="list-item-row justify-space-between">
            <div className="list-item-column">{userName}</div>
            <div className="list-item-column">{formatFullName(firstName, lastName)}</div>
        </div>
    );
};

interface ListItemProps {
    userNumber: string;
    userName: string;
    firstName: string;
    lastName: string;
}

export default RoleUsers;
