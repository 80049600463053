import { logWarning } from "./logger";
import { encodeHtml } from "./string";

export const createHttpError = ({ name, message, body, url, state }) => {
    return formatError({
        errorName: name,
        errorMessage: message,
        errorStack: encodeHtml(body),
        errorComponentStack: "",
        errorUserAgent: navigator.userAgent,
        errorURL: url,
        errorHost: document.location.host,
        errorState: getErrorState(state),
    });
};

export const createJavascriptError = ({ error, info, state }) => {
    return formatError({
        errorName: error.name,
        errorMessage: error.message,
        errorStack: error.stack,
        errorComponentStack: info && info.componentStack,
        errorUserAgent: navigator.userAgent,
        errorURL: document.location.href,
        errorHost: document.location.host,
        errorState: getErrorState(state),
    });
};

const formatError = (error) => {
    const maxLength = {
        errorName: 150,
        errorMessage: 4000,
        errorStack: 4000,
        errorComponentStack: 4000,
        errorUserAgent: 500,
        errorURL: 4000,
    };

    Object.keys(error).forEach((key) => {
        error[key] = error[key] || "";

        if (typeof error[key] === "object") {
            error[key] = JSON.stringify(error[key]);
        }

        if (maxLength[key] && error[key]) {
            if (error[key].length > maxLength[key]) {
                logWarning(`${key} too long: ${error[key].length}|${maxLength[key]}`);
                error[key] = error[key].substring(0, maxLength[key]);
            }
        }
    });

    return error;
};

/**
 * Create serialized error state to include in error.
 *
 * @param {Object} state - Application redux state
 * @returns {string}
 */
const getErrorState = (state) => {
    let errorState = "";

    try {
        const { system, window } = state;

        errorState = JSON.stringify({ system, window });
    } catch {
        errorState = "Error: Could not serialize the state";
    }

    return errorState;
};
