import React, { useState, useCallback, useEffect, useMemo, useRef, memo } from "react";
import { useDispatch } from "react-redux";
import { isNil, cloneDeep, omit, isEqual } from "lodash";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import uuid from "uuid/v4";

import { WorkflowContext } from "./context/WorkflowContext";
import { useReference } from "../Reference/useReference";
import { referenceTypes } from "../Reference/referenceTypes";
import { hasErrorsWfStatus, hasErrorsWfStep } from "./utils/validation";
import { reorder } from "../../utils/array";
import { STEP_TYPES, STEP_TYPES_KEYS, STEP_TYPES_ATTRIBUTES } from "./constants/step-types";
import { modalOpen } from "../../../store/modal/actions";
import { getResource, updateResource } from "../../../store/resources/actions";
import { refreshApplicationStatusesInOpenTabs, refreshApplicationWorkflowInOpenTabs } from "../../../store/resources/refreshResource";
import { useResource } from "../../../store/resources/useResource";
import { WORKFLOW, NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS, HEADER_PREFIX_DND_ID } from "./constants/workflow";

import TextInput from "../Input/TextInput";
import WaitIcon from "../WaitIcon";
import NothingFoundBlock from "../NothingFoundBlock";
import IconWrap from "../Icons";
import { Controls } from "./components/Controls";
import { Buttons } from "./components/Buttons";
import { Statuses } from "./components/Statuses";
import { Settings } from "./components/Settings";

import "./style.scss";

const setItemSortOrder = (type, item, index) => {
    const sortOrder = index + 1;

    if (item.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_NOT_CHANGED) {
        if (item.sortOrder !== sortOrder) {
            item.editStatus = WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED;
        }
    }

    item.sortOrder = sortOrder;

    return item;
};

/**
 * @function fillUnsetStepAttributes
 * @param {string} typeNumber
 * @param {Array<object>} attributes
 * @returns {object}
 * @description Fills required step attributes in case when user doesn't open content modal.
 */

const fillUnsetStepAttributes = (typeNumber, attributes, workflowStepTypes, workflowStepAttributesTypes) => {
    let touched = false;

    if (workflowStepTypes) {
        const stepTypeCode = workflowStepTypes.find((i) => i.number === typeNumber)?.code;

        if (stepTypeCode && STEP_TYPES.hasOwnProperty(stepTypeCode)) {
            const stepContent = STEP_TYPES[stepTypeCode];

            const { attributes: stepContentAttributes } = stepContent;

            for (const stepContentAttribute of stepContentAttributes) {
                const attribute = workflowStepAttributesTypes.find((i) => i.code === stepContentAttribute.code);
                const attributeExisting = attributes.find(({ typeNumber }) => typeNumber === attribute?.number);

                if (attribute && !attributeExisting) {
                    const { number, name } = attribute;

                    attributes.push({
                        typeNumber: number,
                        name: name,
                        value: "",
                    });

                    if (stepContent.key === STEP_TYPES_KEYS.CLEAR_FLAG || stepContent.key === STEP_TYPES_KEYS.FLAG_RECORD) {
                        const flagAttribute = workflowStepAttributesTypes.find((i) => i.code === STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER);

                        if (flagAttribute) {
                            attributes.push({
                                typeNumber: flagAttribute.number,
                                name: flagAttribute.name,
                                value: "",
                            });
                        }
                    }

                    touched = true;
                }
            }
        }
    }

    return { attributes, touched };
};

/**
 * @function getConfiguredItemBeforeWorkflowSaving
 * @param {string} type
 * @param {object} item
 * @param {number} index
 * @returns {object}
 * @description Sets correct sortOrder. Cleans created item from number, isConfirmed properties before workflow saving.
 * Fills unset attributes.
 */

const getConfiguredItemBeforeWorkflowSaving = (type, item, index, workflowStepTypes = null, workflowStepAttributeTypes = null) => {
    delete item.isConfirmed;

    if (item.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED) {
        item.number = null;
    }

    if (type === WORKFLOW.STEP) {
        const { attributes, touched } = fillUnsetStepAttributes(
            item.typeNumber,
            item.attributes,
            workflowStepTypes,
            workflowStepAttributeTypes
        );

        if (touched) {
            item.attributes = attributes;

            if (item.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_NOT_CHANGED) {
                item.editStatus = WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED;
            }
        }

        if (isNil(item.content)) {
            item.content = "";
        }
    }

    return setItemSortOrder(type, item, index);
};

const getDestinationDroppableId = (destination) => {
    return destination.droppableId.replace(HEADER_PREFIX_DND_ID, "");
};

/**
 * @function reorderWfStepBetweenFilteredWfStatuses
 * @param {object} source DnD source object
 * @param {object} destination DnD destination object
 * @param {object} fromWfStatus
 * @param {object} toWfStatus
 * @param {Array<object>} filteredWfStatuses
 * @description Reorders step between different filtered statuses
 */

const reorderWfStepBetweenFilteredWfStatuses = (source, destination, fromWfStatus, toWfStatus, filteredWfStatuses) => {
    const sourceWfStatusFromFilteredWorkflow = filteredWfStatuses.find((status) => status.number === source.droppableId);
    const isDestinationHeader = destination.droppableId.startsWith(HEADER_PREFIX_DND_ID);
    const destDroppableId = getDestinationDroppableId(destination);
    const destWfStatusFromFilteredWorkflow = filteredWfStatuses.find((status) => status.number === destDroppableId);

    if (sourceWfStatusFromFilteredWorkflow && destWfStatusFromFilteredWorkflow) {
        const sourceStep = sourceWfStatusFromFilteredWorkflow.steps[source.index];
        const destinationStep = destWfStatusFromFilteredWorkflow.steps[destination.index];

        if (sourceStep) {
            const sourceStepIndex = fromWfStatus.steps.findIndex((step) => step.number === sourceStep.number);
            const destinationStepIndex =
                destinationStep && !isDestinationHeader
                    ? toWfStatus.steps.findIndex((step) => step.number === destinationStep.number)
                    : toWfStatus.steps.length;

            const [removed] = fromWfStatus.steps.splice(sourceStepIndex, 1);

            if (removed.editStatus !== WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED) {
                removed.editStatus = WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED;
            }

            toWfStatus.steps.splice(destinationStepIndex, 0, removed);
        }
    }
};

const Workflow = memo(
    ({
        workflow,
        flagWorkflowReference,
        workflowGetResourceOptions,
        statesGetResourceOptions,
        stepTypesGetResourceOptions,
        stepAttributeTypesGetResourceOptions,
        assignmentsGetResourceOptions,
        existingCorrespondenceGetResourceOptions,
        existingCorrespondenceDataGetResourceOptions,
        fastTagsGetResourceOptions,
        workflowStatusGetResourceOptions,
        entityId,
        entityIdType,
        isLocked,
    }) => {
        const dispatch = useDispatch();
        const [workflowStatuses, setWorkflowStatuses] = useState([]);
        const [initialWorkflowStatuses, setInitialWorkflowStatuses] = useState([]);
        const [isChanged, setIsChanged] = useState(false);
        const [hasStatusError, setHasStatusError] = useState(false);
        const [activeItem, setActiveItem] = useState(null);
        const [areDisabledItemsVisible, setDisabledStatusesVisibility] = useState(false);
        const [isWorkflowLoading, setWorkflowLoading] = useState(true);
        const [processModes = []] = useReference(referenceTypes.WFProcessMode);
        const [isWorkflowUpdating, setWorkflowUpdating] = useState(false);
        const [isVisibleInfo, setIsVisibleInfo] = useState(false);
        const [showSteps, setShowSteps] = useState(false);

        const [searchFilterType, setSearchFilterType] = useState("");
        const [searchFilterName, setSearchFilterName] = useState("");
        const [searchFilterContent, setSearchFilterContent] = useState("");

        const [workflowStepTypes = [], isLoadingWorkflowStepTypes] = useResource(stepTypesGetResourceOptions);
        const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

        const hasSameTargetStatus = (status) => {
            return status.steps.some((s) => s.newStatus === status.number);
        };

        const hasErrors = useMemo(() => {
            return workflowStatuses.some(
                (wfStatus) =>
                    hasErrorsWfStatus(wfStatus) || wfStatus.steps.some((step) => hasErrorsWfStep(step) || hasSameTargetStatus(wfStatus))
            );
        }, [workflowStatuses]);

        const filteredWorkflowStatuses = useMemo(() => {
            let wfStatuses = cloneDeep(workflowStatuses);

            if (searchFilterType) {
                wfStatuses = wfStatuses.reduce((acc, wfStatus) => {
                    const steps = wfStatus.steps.filter(
                        (step) => !isNil(step.name) && (step.typeName ?? "").toLowerCase().includes(searchFilterType.toLowerCase())
                    );

                    wfStatus.steps = steps;

                    if (steps && steps.length !== 0) {
                        acc.push(wfStatus);
                    }

                    return acc;
                }, []);
            }

            if (searchFilterName) {
                wfStatuses = wfStatuses.reduce((acc, wfStatus) => {
                    const steps = wfStatus.steps.filter(
                        (step) => !isNil(step.name) && step.name.toLowerCase().includes(searchFilterName.toLowerCase())
                    );

                    wfStatus.steps = steps;

                    if (steps && steps.length !== 0) {
                        acc.push(wfStatus);
                    }

                    return acc;
                }, []);
            }

            if (searchFilterContent) {
                wfStatuses = wfStatuses.reduce((acc, wfStatus) => {
                    const steps = wfStatus.steps.filter(
                        (step) => !isNil(step.name) && (step.content ?? "").toLowerCase().includes(searchFilterContent.toLowerCase())
                    );

                    wfStatus.steps = steps;

                    if (steps && steps.length !== 0) {
                        acc.push(wfStatus);
                    }

                    return acc;
                }, []);
            }

            if (!areDisabledItemsVisible) {
                wfStatuses = wfStatuses.reduce((acc, wfStatus) => {
                    if (wfStatus.statusID !== WORKFLOW.DISABLED_STATUS_ID) {
                        wfStatus.steps = wfStatus.steps.filter((step) => step.statusID !== WORKFLOW.DISABLED_STEP_ID);
                        acc.push(wfStatus);
                    }

                    return acc;
                }, []);
            }

            return wfStatuses;
        }, [workflowStatuses, areDisabledItemsVisible, searchFilterType, searchFilterName, searchFilterContent]);

        const orderableStatuses = useMemo(() => {
            return filteredWorkflowStatuses.filter((i) => !NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS.includes(i.workflowStateNumber));
        }, [filteredWorkflowStatuses]);

        const didCancel = useRef(false);

        const getWorkflow = useCallback(
            (withLoading = true, withUpdating) => {
                if (withLoading) {
                    setWorkflowLoading(true);
                }

                dispatch(
                    getResource({
                        ...workflowGetResourceOptions,
                        onComplete: () => {
                            if (!didCancel.current) {
                                if (withLoading) {
                                    setWorkflowLoading(false);
                                }

                                if (withUpdating) {
                                    setWorkflowUpdating(false);
                                }
                            }
                        },
                    })
                );
            },
            [workflowGetResourceOptions, dispatch]
        );

        const refreshWorkflowStatuses = useCallback(() => {
            refreshApplicationWorkflowInOpenTabs();
            refreshApplicationStatusesInOpenTabs();

            dispatch(
                getResource({
                    ...workflowStatusGetResourceOptions,
                })
            );
        }, [workflowStatusGetResourceOptions, dispatch]);

        const handleDragEnd = useCallback(
            (result) => {
                const { draggableId, destination, source, type } = result;

                if (!destination) {
                    return;
                }

                const wfStatuses = cloneDeep(workflowStatuses);

                if (type === WORKFLOW.STATUSES_CONTAINER) {
                    const sourceIndex = wfStatuses.findIndex((i) => i.number === draggableId);
                    const destinationIndex = wfStatuses.findIndex((i) => i.number === orderableStatuses[destination.index].number);

                    reorder(wfStatuses, sourceIndex, destinationIndex);
                }

                if (type === WORKFLOW.STEPS_CONTAINER) {
                    const isDestinationHeader = destination.droppableId.startsWith(HEADER_PREFIX_DND_ID);
                    const destDroppableId = getDestinationDroppableId(destination);
                    if (source.droppableId === destDroppableId) {
                        const wfStatus = wfStatuses.find((i) => i.number === source.droppableId);

                        if (wfStatus) {
                            const status = filteredWorkflowStatuses.find((i) => i.number === wfStatus.number);

                            if (status) {
                                const sourceIndex = wfStatus.steps.findIndex((i) => i.number === draggableId);
                                const destinationIndex = wfStatus.steps.findIndex(
                                    (i) => i.number === status.steps[destination.index].number
                                );

                                reorder(wfStatus.steps, sourceIndex, destinationIndex);
                            }
                        }
                    } else {
                        const fromWfStatus = wfStatuses.find((wfStatus) => wfStatus.number === source.droppableId);
                        const toWfStatus = wfStatuses.find((wfStatus) => wfStatus.number === destDroppableId);

                        if (fromWfStatus && toWfStatus) {
                            if (areDisabledItemsVisible) {
                                const [removed] = fromWfStatus.steps.splice(source.index, 1);

                                if (removed.editStatus !== WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED) {
                                    removed.editStatus = WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED;
                                }

                                toWfStatus.steps.splice(destination.index, 0, removed);
                            } else {
                                reorderWfStepBetweenFilteredWfStatuses(
                                    source,
                                    destination,
                                    fromWfStatus,
                                    toWfStatus,
                                    filteredWorkflowStatuses
                                );
                            }
                        }
                    }
                    if (isDestinationHeader) {
                        setActiveItem({
                            statusNumber: destDroppableId,
                            type: WORKFLOW.STATUS,
                        });
                    }
                }
                setWorkflowStatuses(wfStatuses);
            },
            [workflowStatuses, filteredWorkflowStatuses, areDisabledItemsVisible, orderableStatuses]
        );

        const handleSetActiveItem = useCallback(
            (e, number, type, statusNumber) => {
                e.stopPropagation();

                if (activeItem && (number === activeItem.statusNumber || number === activeItem.stepNumber) && type === activeItem.type) {
                    if (statusNumber) {
                        setActiveItem({ statusNumber, type: WORKFLOW.STATUS });
                    } else {
                        setActiveItem(null);
                    }
                } else {
                    if (statusNumber) {
                        setActiveItem({
                            stepNumber: number,
                            statusNumber,
                            type,
                        });
                    } else {
                        setActiveItem({ statusNumber: number, type });
                    }
                }
            },
            [activeItem]
        );

        const handleCreateWorkflowStatus = useCallback(() => {
            const number = uuid();

            setWorkflowStatuses([
                ...workflowStatuses,
                {
                    isConfirmed: false,
                    entityId,
                    editStatus: WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED,
                    number,
                    processModeID: WORKFLOW.STANDARD_PROCESS_MODE_ID,
                    statusID: WORKFLOW.ACTIVE_STATUS_ID,
                    targetableID: WORKFLOW.NOT_TARGETABLE_TRANSFER_ID,
                    steps: [],
                },
            ]);
            setActiveItem({ statusNumber: number, type: WORKFLOW.STATUS });
        }, [entityId, workflowStatuses]);

        const handleDeleteWorkflowStatus = useCallback(
            (e, statusNumber) => {
                e.stopPropagation();

                const wfStatuses = workflowStatuses.filter((wfStatus) => wfStatus.number !== statusNumber);

                if (activeItem && statusNumber === activeItem.statusNumber) {
                    setActiveItem(null);
                }

                setWorkflowStatuses(wfStatuses);
            },
            [workflowStatuses, activeItem]
        );

        const handleChangeWorkflowStatusProperties = useCallback(
            (data) => {
                const wfStatuses = workflowStatuses.map((wfStatus) => {
                    if (wfStatus.number === activeItem.statusNumber) {
                        const { editStatus } = wfStatus;

                        return {
                            ...omit(wfStatus, ["workflowStateName"]),
                            ...data,
                            editStatus:
                                editStatus !== WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED ? WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED : editStatus,
                        };
                    }

                    return wfStatus;
                });

                setWorkflowStatuses(wfStatuses);
            },
            [workflowStatuses, activeItem]
        );

        const handleToggleShowSteps = useCallback(() => {
            setShowSteps(!showSteps);
        }, [showSteps]);

        const handleToggleDisabledStatusesVisibility = useCallback(() => {
            setActiveItem(null);
            setDisabledStatusesVisibility(!areDisabledItemsVisible);
        }, [areDisabledItemsVisible]);

        const handleCreateWorkflowStep = useCallback(
            (e, statusNumber) => {
                e.stopPropagation();

                const wfStatuses = cloneDeep(workflowStatuses);
                const activeStatus = wfStatuses.find((wfStatus) => wfStatus.number === statusNumber);
                const number = uuid();

                activeStatus.steps = [
                    ...activeStatus.steps,
                    {
                        id: null,
                        isConfirmed: false,
                        editStatus: WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED,
                        number,
                        statusID: WORKFLOW.ACTIVE_STEP_ID,
                        term: 0,
                        isCustom: "n",
                        assignments: [],
                        constraintRule: "",
                        autoCompleteRule: "",
                        content: "",
                        attributes: [],
                    },
                ];
                setWorkflowStatuses(wfStatuses);
                setActiveItem({
                    stepNumber: number,
                    statusNumber,
                    type: WORKFLOW.STEP,
                });
            },
            [workflowStatuses]
        );

        const handleDeleteWorkflowStep = useCallback(
            (e, statusNumber, stepNumber) => {
                e.stopPropagation();

                const wfStatuses = cloneDeep(workflowStatuses);
                const status = wfStatuses.find((wfStatus) => wfStatus.number === statusNumber);

                if (status) {
                    status.steps = status.steps.filter((step) => step.number !== stepNumber);
                }

                if (activeItem && statusNumber === activeItem.statusNumber && stepNumber === activeItem.stepNumber) {
                    setActiveItem(null);
                }

                setWorkflowStatuses(wfStatuses);
            },
            [workflowStatuses, activeItem]
        );

        const handleChangeWorkflowStepProperties = useCallback(
            (data) => {
                const { statusNumber, stepNumber } = activeItem;
                const wfStatuses = workflowStatuses.map((wfStatus) => {
                    if (activeItem && wfStatus.number === statusNumber) {
                        const { steps } = wfStatus;

                        return {
                            ...wfStatus,
                            steps: steps.map((step) => {
                                if (step.number === stepNumber) {
                                    const { editStatus } = step;

                                    return {
                                        ...step,
                                        ...data,
                                        editStatus:
                                            editStatus !== WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED
                                                ? WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED
                                                : editStatus,
                                    };
                                }

                                return step;
                            }),
                        };
                    }

                    return wfStatus;
                });

                setWorkflowStatuses(wfStatuses);
            },
            [workflowStatuses, activeItem]
        );

        const handleClickSaveWorkflow = useCallback(() => {
            if (!hasErrors && !isWorkflowUpdating) {
                const wfStatuses = cloneDeep(workflowStatuses).map((wfStatus, statusIndex) => {
                    const configuredWfStatus = getConfiguredItemBeforeWorkflowSaving(WORKFLOW.STATUS, wfStatus, statusIndex);

                    configuredWfStatus.steps = configuredWfStatus.steps.map((step, stepIndex) => {
                        return getConfiguredItemBeforeWorkflowSaving(
                            WORKFLOW.STEP,
                            step,
                            stepIndex,
                            workflowStepTypes,
                            workflowStepAttributeTypes
                        );
                    });

                    return configuredWfStatus;
                });

                setActiveItem(null);
                setWorkflowUpdating(true);

                dispatch(
                    updateResource({
                        ...workflowGetResourceOptions,
                        body: {
                            statuses: wfStatuses,
                        },
                        onSuccess: () => {
                            if (!didCancel.current) {
                                getWorkflow(false, true);
                                refreshWorkflowStatuses();
                            }
                        },
                        onComplete: () => {
                            if (!didCancel.current) {
                                setWorkflowUpdating(false);
                            }
                        },
                    })
                );
            }
        }, [
            workflowGetResourceOptions,
            workflowStatuses,
            workflowStepTypes,
            workflowStepAttributeTypes,
            getWorkflow,
            refreshWorkflowStatuses,
            isWorkflowUpdating,
            hasErrors,
            dispatch,
        ]);

        const handleClickCancel = useCallback(() => {
            const dialogMessage = <p>Would you like to revert changes to this workflow?</p>;
            dispatch(
                modalOpen({
                    modalType: "CONFIRM",
                    modalProps: {
                        title: "Revert Changes",
                        modalIcon: "undo",
                        overlayClassName: "modal-styled",
                        className: "modal-sm",
                        footerContentCenter: true,
                        content: dialogMessage,
                        onConfirm: () => {
                            setActiveItem(null);
                            setWorkflowUpdating(true);
                            getWorkflow(false, true);
                        },
                    },
                })
            );
        }, [getWorkflow, dispatch]);

        const handleChangeSearchFilterType = useCallback((e) => {
            setSearchFilterType(e.target.value);
        }, []);

        const handleChangeSearchFilterName = useCallback((e) => {
            setSearchFilterName(e.target.value);
        }, []);

        const handleChangeSearchFilterContent = useCallback((e) => {
            setSearchFilterContent(e.target.value);
        }, []);

        useEffect(() => {
            getWorkflow();

            return () => {
                didCancel.current = true;
            };
        }, [getWorkflow]);

        useEffect(() => {
            if (workflow && workflow.statuses) {
                const wf = (
                    workflow.statuses.filter((i) => WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_TOP.includes(i.workflowStateNumber)) || []
                )
                    .concat(workflow.statuses.filter((i) => !NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS.includes(i.workflowStateNumber)) || [])
                    .concat(
                        workflow.statuses.filter((i) =>
                            WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_BOTTOM.includes(i.workflowStateNumber)
                        )
                    );

                setWorkflowStatuses(wf);
                setInitialWorkflowStatuses(wf);
            }
        }, [workflow]);

        useEffect(() => {
            setIsChanged(!isEqual(workflowStatuses, initialWorkflowStatuses));
        }, [workflowStatuses, initialWorkflowStatuses]);

        useEffect(() => {
            const workflowHasStatusError = workflow?.statuses.some((status) =>
                status.steps.some((step) => step.newStatus === status.number)
            );
            setHasStatusError(workflowHasStatusError);
        }, [workflow]);

        return (
            <WorkflowContext.Provider
                value={{
                    workflow,
                    entityId,
                    entityIdType,
                    isLocked,
                    isLoading: isLoadingWorkflowStepTypes || isLoadingWorkflowStepAttributeTypes,
                    workflowStatuses,
                    activeItem,
                    processModes,
                    showSteps,
                    areDisabledItemsVisible,
                    hasErrors,
                    isWorkflowUpdating,
                    isWorkflowLoading,
                    flagWorkflowReference,
                    statesGetResourceOptions,
                    stepTypesGetResourceOptions,
                    stepAttributeTypesGetResourceOptions,
                    assignmentsGetResourceOptions,
                    existingCorrespondenceGetResourceOptions,
                    existingCorrespondenceDataGetResourceOptions,
                    fastTagsGetResourceOptions,
                    workflowStatusGetResourceOptions,
                    onChangeWorkflowStatusProperties: handleChangeWorkflowStatusProperties,
                    onDeleteWorkflowStatus: handleDeleteWorkflowStatus,
                    onChangeWorkflowStepProperties: handleChangeWorkflowStepProperties,
                    onDeleteWorkflowStep: handleDeleteWorkflowStep,
                }}
            >
                <div className={"wf flex-row flex-one with-scroll" + (isChanged || hasStatusError ? " save-panel-visible" : "")}>
                    <div className="wf__container flex-column flex-one-in-row no-scroll">
                        {workflowStatuses.length > 0 && (
                            <div className="wf__sticky-panel flex-column">
                                <div className="flex-row align-center justify-space-between">
                                    <Buttons
                                        withSwitcher
                                        onToggleShowSteps={handleToggleShowSteps}
                                        onToggleDisabledStatusesVisibility={handleToggleDisabledStatusesVisibility}
                                        activeItem={activeItem}
                                    />
                                    {(filteredWorkflowStatuses.length !== 0 ||
                                        searchFilterType ||
                                        searchFilterName ||
                                        searchFilterContent) && (
                                        <>
                                            <TextInput
                                                labelInside="Step Type"
                                                icon="search"
                                                iconRight
                                                className="wf__search"
                                                value={searchFilterType}
                                                placeholder="Search Step Type"
                                                onChange={handleChangeSearchFilterType}
                                            />
                                            <TextInput
                                                labelInside="Step Name"
                                                icon="search"
                                                iconRight
                                                className="wf__search"
                                                value={searchFilterName}
                                                placeholder="Search Step Name"
                                                onChange={handleChangeSearchFilterName}
                                            />
                                            <TextInput
                                                labelInside="Content"
                                                icon="search"
                                                iconRight
                                                className="wf__search"
                                                value={searchFilterContent}
                                                placeholder="Search Step Content"
                                                onChange={handleChangeSearchFilterContent}
                                            />
                                        </>
                                    )}
                                    <Controls onCreateWorkflowStatus={handleCreateWorkflowStatus} />
                                    <IconWrap
                                        iconWrapTheme
                                        icon={isVisibleInfo ? "cancel-clear-circle-highlight-off-filled" : "info-empty"}
                                        onClick={() => !setIsVisibleInfo(!isVisibleInfo)}
                                        title={isVisibleInfo ? "Hide Info" : "Show Info"}
                                    />
                                </div>
                                <p className={isVisibleInfo ? "info-visible" : ""}>
                                    Please note, the statuses and steps can't be deleted after saving workflow. Use the disabled status
                                    instead.
                                </p>
                            </div>
                        )}
                        {isWorkflowLoading ? (
                            <WaitIcon />
                        ) : (
                            <>
                                {workflowStatuses.length === 0 ? (
                                    <NothingFoundBlock icon="search-off" title="No Statuses Exists">
                                        <Controls onCreateWorkflowStatus={handleCreateWorkflowStatus} />
                                    </NothingFoundBlock>
                                ) : (
                                    <>
                                        {filteredWorkflowStatuses.length === 0 ? (
                                            <NothingFoundBlock icon="search-off" title="No Steps Found"></NothingFoundBlock>
                                        ) : (
                                            <div className="wf__steps-list flex-one-in-column with-scroll">
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="statuses" type={WORKFLOW.STATUSES_CONTAINER}>
                                                        {(dropProvided) => (
                                                            <Statuses
                                                                dropProvided={dropProvided}
                                                                showSteps={
                                                                    searchFilterType.length > 0 ||
                                                                    searchFilterName.length > 0 ||
                                                                    searchFilterContent.length > 0 ||
                                                                    showSteps
                                                                }
                                                                filteredWorkflowStatuses={filteredWorkflowStatuses}
                                                                onSetActiveItem={handleSetActiveItem}
                                                                onCreateWorkflowStep={handleCreateWorkflowStep}
                                                            />
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {activeItem && <Settings />}
                    <Buttons
                        isChanged={isChanged}
                        onSetActiveItem={handleSetActiveItem}
                        onClickCancel={handleClickCancel}
                        onClickSaveWorkflow={handleClickSaveWorkflow}
                        activeItem={activeItem}
                    />
                </div>
            </WorkflowContext.Provider>
        );
    }
);

export default Workflow;
