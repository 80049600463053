import React, { useCallback } from "react";
import { closeModal } from "components/ui/Modal/utils";
import { createResource, getResource } from "store/resources/actions";
import { getPortalTemplatesResourceParams } from "store/configureResources";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaFormV2";
import { useDispatch } from "react-redux";
import { isEmpty, isObject } from "lodash";
import { isNullOrWhitespace } from "components/utils/string";

const CloneTemplateForm = ({ template, utilityNumber }) => {
    const dispatch = useDispatch();

    const schema = {
        type: "object",
        required: ["templateName"],
        properties: {
            templateName: {
                title: "New Template name",
                type: "string",
            },
        },
    };

    const handleValidate = useCallback((formData, errors) => {
        if (!isEmpty(formData.templateName) && isNullOrWhitespace(formData.templateName)) {
            errors.templateName.addError("Template Name cannot be empty or filled with spaces, try different name.");
        }

        return errors;
    }, []);

    return (
        <JsonSchemaForm
            schema={schema}
            submitText="Clone"
            resetText="Cancel"
            centeredFooter
            validate={handleValidate}
            onReset={() => dispatch(closeModal)}
            onSubmit={(formData) =>
                dispatch(
                    createResource({
                        resourceName: "utilityPortalTemplates",
                        path: {
                            utilityNumber,
                        },
                        body: {
                            name: formData.templateName,
                            notes: template.notes,
                            configuration: isObject(template.configuration) ? JSON.stringify(template.configuration) : null,
                            thumbnail: template.thumbnail,
                        },
                        onSuccess: () => {
                            dispatch(getResource(getPortalTemplatesResourceParams({ utilityNumber })));
                            dispatch(closeModal);
                        },
                    })
                )
            }
        />
    );
};

export default CloneTemplateForm;
