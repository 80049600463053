import { toast } from "react-toastify";
import { get } from "lodash";
import * as actionTypes from "../actionTypes";

const excludedActions = [
    actionTypes.FILES_UPLOAD_SUCCESS,
    actionTypes.FILES_UPLOAD_ERROR,
    actionTypes.FILES_DELETE_SUCCESS,
    actionTypes.FILES_DELETE_ERROR,
    actionTypes.DATA_GRID_SET_COLUMNS_SUCCESS,
    actionTypes.LOGIN_ERROR,
    actionTypes.LOGIN_REFRESH_ERROR,
];

const excludedResources = ["applicationActivity", "ssoToken"];

export function notificationsMiddleware() {
    return (next) => (action) => {
        if (canShowNotification(action)) {
            if (action.type.endsWith("_SUCCESS") && action.data) {
                if (action.data.responseStatus === "failure" && canShowErrorNotification(action)) {
                    toast.error(getErrorMessage(action));
                } else if (canShowSuccessNotification(action)) {
                    toast.success(getSuccessMessage(action));
                }
            }

            if (action.type.endsWith("_ERROR") && canShowErrorNotification(action)) {
                toast.error(getErrorMessage(action));
            }
        }

        return next(action);
    };
}

const canShowNotification = (action) => {
    const resourceName = get(action, "passThroughData.resourceName", "");

    return !excludedActions.includes(action.type) && !excludedResources.includes(resourceName);
};

const canShowSuccessNotification = (action) => {
    const showSuccessNotification =
        get(action, "passThroughData.showSuccessNotification", false) && (getSuccessMessage(action) || "").length > 0;

    return showSuccessNotification;
};

const canShowErrorNotification = (action) => {
    const showErrorNotification = get(action, "passThroughData.showErrorNotification", false) && (getErrorMessage(action) || "").length > 0;

    return showErrorNotification;
};

const getSuccessMessage = (action) => {
    const showSuccessNotification = get(action, "passThroughData.successMessage", action && action.data && action.data.responseMessage);

    return showSuccessNotification;
};

const getErrorMessage = (action) => {
    const showErrorNotification = get(action, "passThroughData.errorMessage", action && action.data && action.data.responseMessage);

    return showErrorNotification || action.message;
};
