import React, { useState, memo } from "react";

import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../Headers/PanelHeaderLarge";
import TabList from "../../../List/TabList";
import CatalogCalculationsTab from "./CatalogCalculationsTab";
import EventsCalculationsTab from "./EventsCalculationsTab";
import LookupsTab from "./LookupsTab";
import ProgramCalculationsTab from "./ProgramCalculationsTab";

import "./style.scss";
import "./calculationsModal.scss";

export const CalculationsContext = React.createContext();

const tabs = [
    {
        id: "catalogCalc",
        title: "Catalog Calculations",
    },
    {
        id: "eventsCalc",
        title: "Events Calculations",
    },
    {
        id: "lookups",
        title: "Calculation Lookups",
    },
    {
        id: "programCalc",
        title: "Program Calculations",
    },
];

const CalculationsPanel = memo(({ panel }) => {
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();

    const isProgramCalculations = panel.data.programNumber ? true : false;

    const utilityNumber = panel.data.utilityNumber;
    const [programNumber, setProgramNumber] = useState(panel.data.programNumber);

    const [activeTab, setActiveTab] = useState(programNumber ? tabs[3] : tabs[0]);

    return (
        <CalculationsContext.Provider
            value={{
                onOpenPanel: handleOpenSidePanel,
                onClosePanel: handleCloseSidePanel,
            }}
        >
            <div className="panel calculations flex-column fill-height">
                <PanelHeaderLarge title="Calculation Engine" />
                <div className="calculations-toolbar">
                    <TabList items={tabs} tabListGhost activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
                </div>
                <div className="calculations-wrap flex-one-in-column no-scroll">
                    {activeTab.id === "catalogCalc" && (
                        <CatalogCalculationsTab
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            onOpenPanel={handleOpenSidePanel}
                            onClosePanel={handleCloseSidePanel}
                        />
                    )}
                    {activeTab.id === "eventsCalc" && (
                        <EventsCalculationsTab
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            onOpenPanel={handleOpenSidePanel}
                            onClosePanel={handleCloseSidePanel}
                        />
                    )}
                    {activeTab.id === "lookups" && (
                        <LookupsTab
                            programNumber={programNumber}
                            utilityNumber={utilityNumber}
                            onOpenPanel={handleOpenSidePanel}
                            onClosePanel={handleCloseSidePanel}
                        />
                    )}
                    {activeTab.id === "programCalc" && (
                        <ProgramCalculationsTab
                            isProgramCalculations={isProgramCalculations}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            onProgramNumberChange={setProgramNumber}
                            onOpenPanel={handleOpenSidePanel}
                            onClosePanel={handleCloseSidePanel}
                        />
                    )}
                </div>
            </div>
        </CalculationsContext.Provider>
    );
});

export default CalculationsPanel;
