import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ScrollSync } from "react-scroll-sync";
import Breadcrumbs from "components/ui/Headers/Breadcrumbs";
import WaitIcon from "components/ui/WaitIcon";
import { RolesManagementBody } from "./RolesManagementBody";
import { SystemUserInfoBlock } from "../SystemUserInfoBlock";
import { EntityRolesState, User } from "./types";
import { useClientRoles } from "./useClientRoles";
import { refreshUserRoles, useUserRoles } from "./useUserRoles";
import { useClientPrograms } from "./useClientPrograms";
import { isEmpty, isEqual } from "lodash";
import ViewPlaceholder from "components/ui/ViewPlaceholder";
import { getInitialEntityRolesState, saveRolesState, updateClientRole } from "./utils";
import { RolesManagementFooter } from "./RolesManagementFooter";
import { closeModal, openConfirmModal, openWaitingModal } from "components/ui/Modal/utils";

import "./style.scss";

const RolesManagement = (props: RolesManagementProps) => {
    const { user, hidden, onClose } = props;

    const dispatch = useDispatch();

    const [entityRolesState, setEntityRolesState] = useState<EntityRolesState>({});
    const [clientRoleId, setClientRoleId] = useState<number | undefined>(undefined);

    const [userRoles, isLoadingUserRoles] = useUserRoles(user.userNumber);
    const [clientRoles, isLoadingRoles] = useClientRoles(user.clientNumber);
    const [clientPrograms, isLoadingPrograms] = useClientPrograms(user.clientNumber);

    const initialRolesState = useMemo(
        () => getInitialEntityRolesState(userRoles?.roles ?? [], clientPrograms),
        [clientPrograms, userRoles]
    );
    const initialClientRoleId = userRoles?.clientRole?.roleID;
    const disableSave = useMemo(
        () => isEqual(initialRolesState, entityRolesState) && isEqual(initialClientRoleId, clientRoleId),
        [initialRolesState, entityRolesState, initialClientRoleId, clientRoleId]
    );

    useEffect(() => {
        if (!isEmpty(initialRolesState)) {
            setEntityRolesState(initialRolesState);
            setClientRoleId(initialClientRoleId);
        }
    }, [initialRolesState, initialClientRoleId]);

    // Change role to new client role for all utilities that had previous client role
    const onChangeClientRole = (newClientRoleId: number) => {
        // If new client role is same as old client role, do nothing
        if (newClientRoleId === clientRoleId) {
            return;
        }

        setClientRoleId(newClientRoleId);
        setEntityRolesState(updateClientRole(clientRoleId, newClientRoleId, entityRolesState, clientPrograms));
    };

    const onRevert = () => {
        openConfirmModal({
            title: "Revert Changes",
            message: "Are you sure you want to revert changes?",
            onConfirm: () => {
                setEntityRolesState(initialRolesState);
                setClientRoleId(initialClientRoleId);
            },
            onClose: undefined,
            modalIcon: undefined,
            onAfterOpen: undefined,
        });
    };

    const onSave = () => {
        if (!clientRoleId) {
            return;
        }

        const onConfirm = async () => {
            openWaitingModal({
                title: "Saving Changes. Please wait...",
                modalIcon: undefined,
                className: undefined,
            });

            try {
                await saveRolesState(user.userNumber, clientRoleId, user.clientNumber, entityRolesState, dispatch);
                await refreshUserRoles(user.userNumber);
            } finally {
                closeModal();
            }
        };

        openConfirmModal({
            title: "Apply Roles",
            message: "Are you sure you want to apply roles?",
            onConfirm: onConfirm,
            onClose: undefined,
            modalIcon: undefined,
            onAfterOpen: undefined,
        });
    };

    if (
        (isLoadingUserRoles && isEmpty(userRoles)) ||
        isLoadingRoles ||
        isLoadingPrograms ||
        (!isEmpty(userRoles?.roles) && !isEmpty(clientPrograms) && isEmpty(entityRolesState))
    ) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return (
        <>
            <ScrollSync>
                <div
                    key={user.userNumber}
                    className="roles-management main-grid-wrap responsive no-scroll flex-column flex-grow"
                    hidden={hidden}
                >
                    <Breadcrumbs title="Manage Users" childTitle="User Roles" onClick={onClose} />
                    <SystemUserInfoBlock
                        user={user}
                        clientRoleId={clientRoleId}
                        clientRoles={clientRoles}
                        onChangeClientRole={onChangeClientRole}
                    />
                    {!isEmpty(userRoles) && (
                        <RolesManagementBody
                            clientName={user.client}
                            clientNumber={user.clientNumber}
                            clientRoleId={clientRoleId}
                            roles={userRoles?.roles ?? []}
                            clientPrograms={clientPrograms}
                            entityRolesState={entityRolesState}
                            onEntityRolesStateChange={setEntityRolesState}
                        />
                    )}
                </div>
            </ScrollSync>
            <RolesManagementFooter disableSave={disableSave} onClose={onClose} onRevert={onRevert} onSave={onSave} />
        </>
    );
};

interface RolesManagementProps {
    /** Show/Hide the panel */
    hidden: boolean;
    /** The user object from grid */
    user: User;
    /** Close the user roles management panel */
    onClose: () => void;
}

export default RolesManagement;
