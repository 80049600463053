import React from "react";
import IconWrap from "../Icons";
import { AUTO_SIZE_PADDING } from ".";
import { useAutoSize } from "components/utils/autosize";
import { breakLongWords } from "components/utils/string";

const SelectedListItem = ({ items, nameKey, onRemove, index, style }) => {
    const item = items[index];

    // Content container for minimum size calculation
    const contentREF = React.useRef();

    // Content size updater
    useAutoSize(contentREF, index, AUTO_SIZE_PADDING);

    return (
        <div className={"assignment-selector__list-item flex-row"} style={style} onClick={() => onRemove(item)}>
            <IconWrap iconWrap="arrow-left" title="< Remove" onClick={() => onRemove(item)} />
            <span className="flex-one" />
            <span ref={contentREF}>{breakLongWords(item[nameKey], 30)}</span>
        </div>
    );
};

export default SelectedListItem;
