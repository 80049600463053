import React, { useState, useCallback, useRef, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuidv1 from "uuid/v1";

import { createResource } from "../../../../../../store/resources/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { getEquipmentInCategoryResourceParams } from "../../../../../../store/configureResources";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { submitByRef } from "../../../../../utils/form";

import EquipmentDetails from "./EquipmentDetails";
import CatalogSearch from "../../../../CatalogSearch";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { refreshSidebarEquipment } from "./utils";

const AddEquipment = memo(({ applicationNumber, programNumber, onClose, onSuccess, sidePanel, viewOnly, onRowSelectClear }) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const [equipmentItem, setEquipmentItem] = useState(null);
    const [showApprovedEquipmentSearch, setShowApprovedEquipmentSearch] = useState(false);
    const [approvedEquipmentDetails, setApprovedEquipmentDetails] = useState();
    const isMobile = useSelector((state) => state.window?.isMobile);

    const isAppLocked = isApplicationLocked({ applicationNumber });

    const handleAddEquipment = useCallback(
        (data) => {
            dispatch(
                createResource({
                    resourceName: "equipment",
                    path: {
                        appId: applicationNumber,
                    },
                    body: {
                        ...data,
                        equipID: uuidv1().toUpperCase(),
                    },
                    onSuccess: () => {
                        dispatch(
                            getData({
                                dataGridId: `${applicationNumber}-equipment-grid`,
                            })
                        );
                        refreshSidebarEquipment({ applicationNumber });
                        onRowSelectClear?.();

                        if (onSuccess) {
                            onSuccess();
                        }
                    },
                })
            );

            setEquipmentItem(null);
        },
        [applicationNumber, onSuccess, dispatch, onRowSelectClear]
    );

    const handleClickBack = useCallback(() => {
        // Go back from approved equipment details
        if (approvedEquipmentDetails) {
            setApprovedEquipmentDetails(null);
        }
        // Go back from approved equipment search
        else if (showApprovedEquipmentSearch) {
            setShowApprovedEquipmentSearch(false);
        }
        // Go back to equipment catalog
        else {
            setEquipmentItem(null);
        }
    }, [showApprovedEquipmentSearch, approvedEquipmentDetails]);

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    useEffect(() => {
        if (sidePanel) {
            sidePanel.setForm(formRef);
        }
    }, [sidePanel]);

    const showFooter = !(isMobile && showApprovedEquipmentSearch && !approvedEquipmentDetails);
    const showHeader = !equipmentItem || (isMobile && !approvedEquipmentDetails);

    return (
        <SideNavContent className={"add-equipment-panel" + (!equipmentItem ? " catalog-selection" : "")}>
            {showHeader && <SideNavHeader title="Add Equipment" leadBlockIcon="plus" smallHeader onClose={onClose} />}
            <SideNavBody noPadding>
                {equipmentItem && (
                    <EquipmentDetails
                        hideButtons
                        formRef={formRef}
                        catalogNumber={equipmentItem.catalogNumber}
                        categoryName={equipmentItem.category}
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        headerLeftAlignedTitle={"Add Equipment"}
                        headerLeftAlignedIcon={"plus"}
                        onClose={onClose}
                        onSubmit={handleAddEquipment}
                        showApprovedEquipmentSearch={showApprovedEquipmentSearch}
                        approvedEquipmentDetails={approvedEquipmentDetails}
                        onShowApprovedEquipmentSearch={(data) => setShowApprovedEquipmentSearch(data)}
                        onShowApprovedEquipmentDetails={(data) => setApprovedEquipmentDetails(data)}
                        viewOnly={viewOnly}
                    />
                )}
                <div hidden={!!equipmentItem} className={"catalog-selector fill-height"}>
                    <CatalogSearch
                        inline={true}
                        applicationNumber={applicationNumber}
                        itemType="equipment"
                        title="Add Equipment"
                        usageSummaryResourceName="equipmentCatalogSummary"
                        categoriesResourceName="equipmentCategories"
                        categoryItemsResourceName="equipmentCategoryCatalogs"
                        categorySearchResourceName="equipmentSearch"
                        categoryItemsResourceParams={getEquipmentInCategoryResourceParams}
                        categoryItemNumberKey="catalogNumber"
                        categoryItemNameKey="name"
                        onSelect={setEquipmentItem}
                    />
                </div>
            </SideNavBody>
            {showFooter && (
                <SideNavFooter setPrimaryButton={isMobile} justifyCenter={!isAppLocked} justifyEnd={isAppLocked || !equipmentItem}>
                    {equipmentItem && (
                        <Button icon="shevron-small-left" onClick={handleClickBack}>
                            Back
                        </Button>
                    )}
                    <div className="flex-row flex-one-in-row justify-end">
                        {!isAppLocked && equipmentItem && !showApprovedEquipmentSearch && (
                            <Button primary icon="plus" onClick={handleSubmit} disabled={viewOnly}>
                                Add Equipment
                            </Button>
                        )}
                        {approvedEquipmentDetails && (
                            <Button primary icon="plus" onClick={approvedEquipmentDetails.onAdd} disabled={viewOnly}>
                                Apply Approved Equipment
                            </Button>
                        )}
                        {!isMobile && <Button onClick={onClose}>Cancel</Button>}
                    </div>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default AddEquipment;
