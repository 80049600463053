import React from "react";

import { findLastIndex, orderBy } from "lodash";
import { store } from "../../../store/configureStore";
import { modalOpen } from "../../../store/modal/actions";
import { deleteResource, getResource, updateResource } from "../../../store/resources/actions";
import * as actionTypes from "../../../store/actionTypes";
import { getData } from "../../../store/dataGrid/actions";
import { toArray, reorder } from "../../utils/array";
import { openAssignmentModal } from "../../ui/AssignmentSelector/utils";
import { getDataGridConfig, mapDataToGridColumnKeys, mapGridRowToObject } from "components/utils/datagrid";

export const scanQueueGridColumnKeys = {
    utilityName: "c_dbe9523c-848f-4131-8a56-dffd62499bc6",
    programName: "c_83efd6ae-9935-414e-a236-867efe1d63df",
    queue: "c_8790a307-ce74-463b-b4ed-6b9af0fc3b0a",
    groupName: "c_235cad72-250f-4b84-ba17-f5c6e126acc5",
    groupNumber: "c_85d5f944-919f-4510-8b5e-934ba0012eaa",
    priority: "c_79d520e8-39ff-447c-9c7d-61ffdd317dc4",
    itemOrder: "c_d39182ef-6992-446c-8196-ce51f8d2e297",
    groupList: "c_21aaf7c8-bb58-471a-a3de-e49733054d4a",
    programNumber: "c_fe4fac08-f2fb-48f4-a46a-3b306db32b67",
};

export const scanQueuePriorities = {
    high: "up",
    low: "down",
    none: "none",
};

export const getScanQueueUserGroupsGridId = () => {
    return "scan-queue-management__user-groups-grid";
};

export const getScanQueueGridId = () => {
    return "scan-queue-management__global-scans-queue-grid";
};

export const onRefreshGrids = () => {
    store.dispatch(
        getData({
            dataGridId: getScanQueueUserGroupsGridId(),
        })
    );

    store.dispatch(
        getData({
            dataGridId: getScanQueueGridId(),
        })
    );
};

export const refreshUserGroups = () => {
    store.dispatch(
        getResource({
            resourceName: "scanGroups",
            key: "scans-queue-groups",
        })
    );
};

export const formatScanQueueGridRows = ({ rows }) => {
    const orderedRows = orderBy(toArray(rows), ["itemOrder", "queue"], ["asc", "desc"]);

    const getPriority = (row, index) => {
        let priority = "none";

        const isUp = (row, index) => {
            return orderedRows.some((r, i) => i > index && r.queue > row.queue);
        };

        const isDown = (row, index) => {
            return orderedRows.some((r, i) => i < index && r.queue < row.queue);
        };

        if (isUp(row, index)) {
            priority = "up";
        } else {
            if (isDown(row, index)) {
                priority = "down";
            }
        }

        return priority;
    };

    return orderedRows.map((row, index) => ({
        ...row,
        priority: getPriority(row, index),
        groupName: (row.groupList || []).map((i) => i.groupName).join(", "),
    }));
};

export const toggleHighPriority = ({ dataItem }) => {
    const { programNumber, queue, priority } = dataItem;

    const dataGridId = getScanQueueGridId();
    const dataGridConfig = getDataGridConfig(dataGridId);
    const currentRows = dataGridConfig.sourceRows;
    const updatedRows = dataGridConfig.sourceRows.slice();
    const currentIndex = currentRows.findIndex((r) => r[scanQueueGridColumnKeys.programNumber] === programNumber);
    let newIndex = 0;

    switch (priority) {
        case scanQueuePriorities.high:
            // Move after last with higher queue
            newIndex = findLastIndex(currentRows, (r) => r[scanQueueGridColumnKeys.queue] > queue);
            if (newIndex === -1) {
                newIndex = currentRows.length - 1;
            }

            reorder(updatedRows, currentIndex, newIndex);

            break;
        default:
            // Move to top
            newIndex = 0;
            reorder(updatedRows, currentIndex, newIndex);

            break;
    }

    updateProgramOrder({
        rows: updatedRows,
    });
};

export const reorderPriority = ({ oldIndex, newIndex }) => {
    const dataGridId = getScanQueueGridId();
    const dataGridConfig = getDataGridConfig(dataGridId);
    const updatedRows = dataGridConfig.rows.slice();

    reorder(updatedRows, oldIndex, newIndex);

    updateProgramOrder({
        rows: updatedRows,
    });
};

export const getRowPriorityInfo = (priority) => {
    let icon = "";
    let text = "";
    let className = "";
    let iconWrapError = false;
    let iconWrapTheme = false;

    switch (priority) {
        case scanQueuePriorities.high:
            icon = "arrow-in-circle-up-empty";
            text = "Cancel high priority";
            iconWrapError = true;
            break;
        case scanQueuePriorities.low:
            icon = "arrow-in-circle-down-empty";
            text = "Set high priority";
            iconWrapTheme = true;
            break;
        default:
            icon = "arrow-in-circle-up-empty";
            text = "Set high priority";
            className = "priority-none";
            break;
    }

    return {
        icon,
        text,
        className,
        iconWrapError,
        iconWrapTheme,
    };
};

export const restoreDefaultScanQueueOrder = () => {
    const text = <p>Are you sure you want to restore default order for the Scan Queue?</p>;

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Restore Scan Queue Default Order",
                modalIcon: "low-priority",
                overlayClassName: "modal-styled",
                className: "restore-scan-queue-default-order-confirmation-modal modal-sm",
                content: text,
                footerContentCenter: true,
                onConfirm: () => {
                    const dataGridId = getScanQueueGridId();
                    const dataGridConfig = getDataGridConfig(dataGridId);
                    const currentRows = dataGridConfig.rows;

                    const updatedRows = orderBy(currentRows, ["queue"], ["desc"]);

                    updateProgramOrder({
                        rows: updatedRows,
                    });
                },
            },
        })
    );
};

export const updateProgramOrder = ({ rows }) => {
    var formattedRows = formatScanQueueGridRows({
        rows: rows.map((r, index) => ({
            ...mapGridRowToObject(scanQueueGridColumnKeys, r),
            itemOrder: index,
        })),
    });

    var updatedRows = mapDataToGridColumnKeys(scanQueueGridColumnKeys, formattedRows);

    store.dispatch({
        type: actionTypes.DATA_GRID_CLIENT_GET_DATA_SUCCESS,
        name: getScanQueueGridId(),
        data: {
            grid: {
                rows: updatedRows,
            },
        },
    });

    store.dispatch(
        updateResource({
            resourceName: "scanGroupProgramOrder",
            key: "global-scans-queue",
            body: rows.map((r) => r[scanQueueGridColumnKeys.programNumber]),
            showSuccessNotification: false,
            onSuccess: () => {
                store.dispatch(
                    getData({
                        dataGridId: getScanQueueGridId(),
                    })
                );
            },
        })
    );
};

export const onDeleteUserGroup = ({ dataItem, onRefresh }) => {
    const message = (
        <>
            <p>
                This action will delete the user group <b>{dataItem.groupName}</b> and cannot be undone.
            </p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete User Group",
                overlayClassName: "modal-styled",
                className: "delete-user-group-confirmation-modal modal-sm",
                modalIcon: "delete-trash-empty",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    store.dispatch(
                        deleteResource({
                            resourceName: "scanGroups",
                            resourceId: dataItem.groupNumber,
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};

export const onUpdateProgramList = ({ groupNumber, list }) => {
    const resourceGetParams = {
        resourceName: "scanGroupPrograms",
        key: groupNumber,
        path: {
            groupNumber,
        },
    };

    store.dispatch(
        updateResource({
            ...resourceGetParams,
            body: list,
            showSuccessNotification: false,
        })
    );
};

export const onUpdateUserList = ({ groupNumber, list }) => {
    const resourceGetParams = {
        resourceName: "scanGroupUsers",
        key: groupNumber,
        path: {
            groupNumber,
        },
    };

    store.dispatch(
        updateResource({
            ...resourceGetParams,
            body: list,
            showSuccessNotification: false,
        })
    );
};

export const openUserAssignmentModal = ({ group, onSelect }) => {
    const { groupNumber } = group;

    const resourceDataPath = "data.scanGroupUserList";
    const title = "User Assignment";
    const idKey = "userNumber";
    const nameKey = "userName";

    const resourceGetParams = {
        resourceName: "scanGroupUsers",
        key: groupNumber,
        path: {
            groupNumber,
        },
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        onSelect,
    });
};

export const openProgramAssignmentModal = ({ group, onSelect }) => {
    const { groupNumber } = group;

    const resourceDataPath = "data.scanGroupProgramList";
    const title = "Program Assignment";
    const idKey = "programNumber";
    const nameKey = "programName";

    const resourceGetParams = {
        resourceName: "scanGroupPrograms",
        key: groupNumber,
        path: {
            groupNumber,
        },
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        idKey,
        nameKey,
        onSelect,
    });
};
