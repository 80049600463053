import React from "react";

import "./style.scss";
import useFocusClasses from "../../../utils/useFocusClasses";
import useEnterKey from "../../../utils/useEnterKey";

const GridListGroupRow = ({ groupName, rows, expanded, groupRender, onClick }) => {
    const defaultGroupRender = (groupName) => {
        return <div className="group-name">{groupName}</div>;
    };

    const onGroupClick = (event) => {
        event.stopPropagation();
        onClick(groupName);
    };

    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: onGroupClick,
    });

    return (
        <div
            className={"grid-list-row group-header-row show-expanded-row" + (expanded ? " expanded" : "")}
            tabIndex="0"
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            onClick={onGroupClick}
        >
            {groupRender ? groupRender({ groupName, rows, expanded, defaultGroupRender }) : defaultGroupRender(groupName)}
        </div>
    );
};

export default GridListGroupRow;
