import { useRef, useCallback, memo, useMemo, useState } from "react";
import cn from "classnames";
import Form from "react-jsonschema-form";
import { debounce, isEqual, isNil } from "lodash";

import Button from "../../Button";

import ObjectFieldTemplate from "./fields/ObjectFieldTemplate";
import FormBuilderObjectFieldTemplate from "./fields/FormBuilderObjectFieldTemplate";
import FormBuilderSchemaField from "./fields/FormBuilderSchemaField";
import FormSchemaField from "./fields/FormSchemaField";

import {
    flattenFormData,
    setFormDataById,
    customFormValidate,
    disableAutocomplete,
    DEFAULT_AUTOCOMPLETE,
    addExtraError,
    updateFieldRemoveRules,
    normalizeRules,
    getRulesRunner,
} from "components/utils/form";
import { logError, log } from "../../../utils/logger";
import { isInViewport } from "components/utils/dom";
import { createId } from "components/utils/string";
import { fields } from "./fields";
import { widgets } from "./widgets";

import "./style.scss";

const JsonSchemaFormWithConditionals = memo(
    ({
        className,
        formId,
        idPrefix,
        schema,
        uiSchema,
        validate,
        transformErrors,
        rules,
        initialValues,
        disabled,
        readOnly,
        formRef,
        noValidate,
        liveValidate,
        autocomplete,
        withCancel,
        submitText,
        cancelText,
        submitIcon,
        cancelIcon,
        submitIsPrimary,
        submitDisabled,
        onChange,
        onSubmit,
        onCancel,
        onError,
        onRemove,
        otherActions,
        formBuilder,
        fieldKey,
        noSubmit,
        noActions,
        centeredFooter,
        emptyItemInSelectLists,
        defaultValueOnInitialize,
        selectListsWithPopper,
        formContext,
    }) => {
        const [data, setData] = useState(initialValues || {});
        const [localConfig, setLocalConfig] = useState({});
        const [extraErrors, setExtraErrors] = useState([]);

        const uiSchemaWithReadOnly = useMemo(() => {
            if (readOnly) {
                return {
                    ...uiSchema,
                    "ui:readonly": true,
                };
            }

            return uiSchema;
        }, [readOnly, uiSchema]);

        const formRules = useMemo(() => {
            if (!rules) {
                return [];
            }

            const normalizedRules = normalizeRules(rules);

            if (formBuilder) {
                return updateFieldRemoveRules(normalizedRules);
            }

            return normalizedRules;
        }, [formBuilder, rules]);

        const isInitialized = useRef(false);
        const lastSchema = useRef(schema);
        const lastUiSchema = useRef(uiSchemaWithReadOnly);
        const lastFormRules = useRef(formRules);

        const rulesRunnerRef = useRef(getRulesRunner(schema, uiSchemaWithReadOnly, formRules));
        const uniqueIdPrefix = useRef(idPrefix ?? createId()).current;

        let jsonFormRef = useRef();
        if (formRef) {
            jsonFormRef = formRef;
        }

        // Check if schema, uiSchema or formRules changed and reinitialize form
        useMemo(() => {
            if (
                !isEqual(lastSchema.current, schema) ||
                !isEqual(lastUiSchema.current, uiSchemaWithReadOnly) ||
                !isEqual(lastFormRules.current, formRules)
            ) {
                isInitialized.current = false;
                lastSchema.current = schema;
                lastUiSchema.current = uiSchemaWithReadOnly;
                rulesRunnerRef.current = getRulesRunner(schema, uiSchemaWithReadOnly, formRules);
            }
        }, [schema, uiSchemaWithReadOnly, formRules]);

        // Init form
        if (!isInitialized.current) {
            isInitialized.current = true;
            rulesRunnerRef.current(data).then((config) => {
                setLocalConfig({
                    schema: config.schema,
                    uiSchema: config.uiSchema,
                });

                setFormDataById({ formId, formData: config.formData });
            });

            // Try to disable autocomplete
            disableAutocomplete({ formRef: jsonFormRef });
        }

        const getInitialValues = useCallback(() => ({ ...initialValues }), [initialValues]);

        const setFormData = useCallback(
            (formData) => {
                setFormDataById({ formId, formData });
            },
            [formId]
        );

        const setRef = useCallback(
            (ref) => {
                if (!ref) {
                    return;
                }
                jsonFormRef.current = Object.assign(ref, {
                    getInitialValues,
                    setFormData,
                });
            },
            [getInitialValues, setFormData]
        );

        const formFields = useMemo(
            () => ({
                ...fields,
                SchemaField: formBuilder
                    ? (props) => <FormBuilderSchemaField {...props} instanceId={formBuilder} onRemove={onRemove} />
                    : FormSchemaField,
            }),
            [formBuilder, onRemove]
        );

        const objectFieldTemplate = useMemo(
            () => (formBuilder ? (props) => <FormBuilderObjectFieldTemplate {...props} instanceId={formBuilder} /> : ObjectFieldTemplate),
            [formBuilder]
        );

        const onFormSubmit = useCallback(
            (form) => {
                log("JsonSchemaFormWithConditionals onFormSubmit", form);

                const data = flattenFormData(form, fieldKey);

                onSubmit?.(data);
                jsonFormRef.current.submitCallback?.(null, data);
            },
            [fieldKey, jsonFormRef, onSubmit]
        );

        const onFormChange = useCallback(
            (form) => {
                setData((prev) => (isEqual(prev, form.formData) ? prev : form.formData));
                debouncedOnChange(form, formId, fieldKey, rulesRunnerRef.current, onChange, setLocalConfig);
            },
            [fieldKey, formId, onChange]
        );

        const onFormError = useCallback(
            (form) => {
                const errorEl = jsonFormRef?.current?.formElement?.querySelector(".field-error");
                if (errorEl && !isInViewport(errorEl)) {
                    errorEl.scrollIntoView();
                }

                logError("JsonSchemaFormWithConditionals onFormError", form);
                onError?.(form);

                jsonFormRef?.current?.submitCallback?.(form, null);
            },
            [jsonFormRef, onError]
        );

        const onFormCancel = useCallback(
            (form) => {
                const data = flattenFormData(form, fieldKey);

                onCancel?.({ formData: data });
            },
            [onCancel, fieldKey]
        );

        const onFormValidate = useCallback(
            (formData, errors) => {
                return customFormValidate({
                    formData,
                    errors,
                    schema: localConfig.schema,
                    uiSchema: localConfig.uiSchema,
                    validate,
                    extraErrors,
                    idPrefix: localConfig.uiSchema?.["ui:rootFieldId"] ?? uniqueIdPrefix,
                });
            },
            [extraErrors, localConfig.schema, localConfig.uiSchema, uniqueIdPrefix, validate]
        );

        const setExtraError = useCallback((id, message) => {
            setExtraErrors((extraErrors) => addExtraError({ id, message, extraErrors }));
        }, []);

        const showSubmitButton = () => {
            return !noSubmit && schema && Object.keys(schema).length > 0;
        };

        let submitButtonText = submitText || "Submit";
        let cancelButtonText = cancelText || "Cancel";

        let submitButtonIcon = submitIcon;
        let cancelButtonIcon = cancelIcon;

        const context = useMemo(
            () => ({
                // Add empty option in not required dropdown fields
                emptyItemInSelectLists,
                // Whether to select the first dropdown item by default
                defaultValueOnInitialize,
                // Render dropdown list in portal with popper
                selectListsWithPopper,
                // Form context from props
                ...(formContext ?? {}),

                // Function to set extra errors to be shown on submit
                setExtraError,

                // List of extra errors
                extraErrors,
            }),
            [emptyItemInSelectLists, defaultValueOnInitialize, selectListsWithPopper, formContext, setExtraError, extraErrors]
        );

        if (isNil(localConfig) || isNil(localConfig.schema)) {
            return null;
        }

        return (
            <div className={cn("json-form", className)}>
                <Form
                    formContext={context}
                    idPrefix={uniqueIdPrefix}
                    ref={setRef}
                    schema={localConfig.schema}
                    uiSchema={localConfig.uiSchema}
                    formData={data}
                    widgets={widgets}
                    fields={formFields}
                    ObjectFieldTemplate={objectFieldTemplate}
                    showErrorList={false}
                    noHtml5Validate
                    disabled={disabled}
                    onChange={onFormChange}
                    onSubmit={onFormSubmit}
                    onError={onFormError}
                    liveValidate={liveValidate}
                    noValidate={noValidate}
                    validate={onFormValidate}
                    autocomplete={autocomplete ?? DEFAULT_AUTOCOMPLETE}
                    transformErrors={transformErrors}
                >
                    <div className={"action-buttons flex-row" + (centeredFooter ? " justify-center" : "")} hidden={noActions}>
                        {showSubmitButton() && (
                            <Button
                                icon={submitButtonIcon}
                                type="submit"
                                primary={submitIsPrimary ?? true}
                                disabled={disabled || submitDisabled}
                            >
                                {submitButtonText}
                            </Button>
                        )}
                        {(withCancel || onCancel) && !disabled && (
                            <Button icon={cancelButtonIcon} type="button" onClick={onFormCancel}>
                                {cancelButtonText}
                            </Button>
                        )}
                        {otherActions}
                    </div>
                </Form>
            </div>
        );
    }
);

// Debounce form onChange callback
const debouncedOnChange = debounce((form, formId, fieldKey, runRules, onChange, setConfig) => {
    runRules(form.formData).then((config) => {
        // Update form schemas if changed by rules
        if (!isEqual(form.schema, config.schema) || !isEqual(form.uiSchema, config.uiSchema)) {
            setConfig({
                schema: config.schema,
                uiSchema: config.uiSchema,
            });
        }

        const data = flattenFormData(
            {
                schema: config.schema,
                uiSchema: config.uiSchema,
                formData: config.formData,
            },
            fieldKey
        );

        setFormDataById({ formId, formData: config.formData });

        onChange?.({ formData: data, errorSchema: form.errorSchema });
    });
}, 500);

export default JsonSchemaFormWithConditionals;
