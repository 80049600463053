import React, { memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import cn from "classnames";

import { WarningMessage } from "../../Message";
import IconWrap from "../../Icons";
import StatusMark from "../../StatusMark";
import { useIsProgramLocked } from "../../../views/ProgramView/utils";
import CopyToClipboard from "../../Label/CopyToClipboard";

import "./WindowHeaderForProgram.scss";

const WindowHeaderForProgram = memo(({ view }) => {
    const programNumber = get(view, "props.programNumber");
    const program = useSelector((state) => get(state, `resources.programs.itemsById[${programNumber}]`));
    const isLocked = useIsProgramLocked({ programNumber });

    if (!program) {
        return null;
    }

    return (
        <div className="window-header-content program">
            <div
                className={cn("project-mark", {
                    "child-program": program.isChild,
                    "parent-program": program.isParent,
                })}
            >
                {program.isParent && <StatusMark statusMarkParentIndicator>Parent</StatusMark>}
                {program.isChild && <StatusMark statusMarkChildIndicator>Child</StatusMark>}
                <span>Program</span>
            </div>
            <div className="project-number">
                <CopyToClipboard title="Copy Program Name to clipboard" successMessage="Program Name copied to clipboard">
                    {program.program}
                </CopyToClipboard>
            </div>
            {program.isFavorite && <IconWrap iconWrap="bookmark-filled" title="Bookmarked" />}
            {isLocked && (
                <div className="project-info">
                    <div className="status">
                        <WarningMessage inline>LOCKED</WarningMessage>
                    </div>
                </div>
            )}
        </div>
    );
});

export default WindowHeaderForProgram;
