import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { PropertyList } from "../PropertyList";
import { PORTAL_WIDGET_PROPERTIES, setWidgetProperties } from "../../utils";
import { PropertyType, WidgetType } from "../../types";
import { COMBINED_WIDGETS, SINGLE_WIDGETS, WidgetsSelect } from "../Widgets";
import IconWrap from "components/ui/Icons";
import { cloneDeep, isEmpty, isEqual, omitBy, pick } from "lodash";
import { openConfirmModal } from "components/ui/Modal/utils";
import { WidgetsListItem } from "../Widgets/WidgetsListItem";
import { HeadingTypeProperty } from "../HeadingWidgetProperty/HeadingTypeProperty";
import { PageContext, PortalBuilderPropertiesContext, RowContext } from "../../contexts";
import { getColumnErrors } from "../../utils/validation";
import { PropertyListItem } from "../Property/PropertyListItem";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

import "./WidgetProperty.scss";

export const WidgetProperty = (props) => {
    const { value = [], onChange, id } = props;
    const item = useMemo(() => value[0] ?? {}, [value]);
    const itemRef = useRef(item);

    // onPropertyChange sometimes has old value if not using this ref.
    // TODO: investigate why onChange somehow preserves old value.
    const valueRef = useRef();
    valueRef.current = value;

    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const { errors = [] } = useErrorContext();
    const { selectedColumn, sectionTitle } = useContext(PortalBuilderPropertiesContext);
    const { row } = useContext(RowContext);
    const { page } = useContext(PageContext);

    const rowSectionContainsActiveError =
        row.index === activeError?.rowIndex && page?.index === activeError?.pageIndex && selectedColumn === activeError?.columnIndex;

    let widgetErrors;
    if (page && row) {
        widgetErrors = getColumnErrors(errors, `content.pages[${page.index}].components[${row.index}].components[${selectedColumn}].`);
    } else if (sectionTitle === "Header") {
        widgetErrors = getColumnErrors(errors, `header.components[${row.index}].components[${selectedColumn}].`);
    } else if (sectionTitle === "Footer") {
        widgetErrors = getColumnErrors(errors, `footer.components[${row.index}].components[${selectedColumn}].`);
    }

    if (widgetErrors) {
        let errors = [];
        widgetErrors.map((e) =>
            errors.push({
                ...e,
                id: e.id.substring(e.id.lastIndexOf(".") + 1),
            })
        );
        widgetErrors = errors;
    }

    useEffect(() => {
        if (itemRef.current.type !== item.type) {
            setTimeout(() => {
                itemRef.current = item;
            }, 100);
        }
    }, [item]);

    const title = "WIDGET";
    const allWidgets = SINGLE_WIDGETS.concat(
        COMBINED_WIDGETS,
        // TODO: Remove old widgets after new ones are implemented and existing templates deleted.
        [
            {
                title: "Header navigation",
                type: WidgetType.HEADER_NAVIGATION_WIDGET,
            },
            {
                title: "Paragraph",
                type: WidgetType.PARAGRAPH,
            },
            {
                label: "Rich Text",
                value: WidgetType.HTML,
            },
        ]
    );

    const selectedItem = allWidgets.find((e) => e.type === item.type);
    const widgetProperties = PORTAL_WIDGET_PROPERTIES[item.type] ?? [];

    const isChanged = !isEqual(itemRef.current, {
        type: value[0]?.type,
        props: omitBy(value[0]?.props, isEmpty),
    });

    const onPropertyChange = useCallback(
        (propertyId, propertyValue, additionalValues = []) => {
            const updatedValue = cloneDeep(valueRef.current) ?? [];

            if (propertyId === "type") {
                const widgetPropKeys = (PORTAL_WIDGET_PROPERTIES[propertyValue] ?? []).map((i) => i.id);
                const props = pick(updatedValue[0]?.props ?? {}, widgetPropKeys);

                if (!updatedValue[0]) {
                    updatedValue.push({});
                }
                updatedValue[0].type = propertyValue;
                updatedValue[0].props = props;

                // Add version if it is present in the widget properties.
                if (widgetPropKeys.includes("version")) {
                    updatedValue[0].props.version = PORTAL_WIDGET_PROPERTIES[propertyValue].find((i) => i.id === "version")?.defaultValue;
                }
            } else {
                updatedValue[0] = setWidgetProperties(updatedValue[0], [{ id: propertyId, value: propertyValue }, ...additionalValues]);
            }

            onChange(id, updatedValue);
        },
        [id, onChange]
    );

    const onRemove = useCallback(() => {
        let updatedValue = cloneDeep(value);

        updatedValue[0] = {
            type: updatedValue[0]?.type,
            props: omitBy(updatedValue[0]?.props, isEmpty),
        };

        if (isChanged) {
            openConfirmModal({
                title: `Delete ${item.type} Widget`,
                modalIcon: "delete-trash-filled",
                message: (
                    <>
                        <p>
                            <strong>{item.type}</strong> widget will be deleted with all configuration you have made for it.
                        </p>
                        <p>Are you sure you want to delete the selected widget ?</p>
                    </>
                ),
                onConfirm: () => {
                    updatedValue.splice(0, 1);
                    onChange(id, updatedValue);
                },
            });
        } else {
            updatedValue.splice(0, 1);
            onChange(id, updatedValue);
        }
    }, [id, onChange, item.type, isChanged, value]);

    const values = useMemo(
        () => ({
            type: item.type === "Widget" ? undefined : item.type,
            ...item.props,
        }),
        [item.props, item.type]
    );

    const selectPanelProperties = useMemo(() => {
        return [
            {
                id: "type",
                title: "Widget",
                type: PropertyType.SelectPanel,
                selectIcon: "touch-empty",
                selectTooltip: (isSelected) => (isSelected ? "Change Widget" : "Select Widget"),
                panelComponent: WidgetsSelect,
                selectComponent: IconWrap,
                noPostfix: true,
                className: "widget-selection",
                borderBottom: false,
                showConfirmModal: isChanged,
                confirmModalMessage: (
                    <>
                        <p>
                            <strong>{item?.type}</strong> widget will be replaced and you will lose all configuration you have made for it.
                        </p>
                        <p>Are you sure you want to change the widget?</p>
                    </>
                ),
                confirmModalTitle: item.type ? `Change ${item?.type} Widget` : undefined,
                valueComponent: (props) => {
                    return selectedItem?.type === "Heading" ? (
                        <HeadingTypeProperty value={values} onChange={onPropertyChange} onRemove={onRemove} item={selectedItem} />
                    ) : (
                        <WidgetsListItem {...props} onRemove={onRemove} item={selectedItem} />
                    );
                },
            },
        ];
    }, [onRemove, selectedItem, onPropertyChange, values, item, isChanged]);

    return (
        <>
            <PropertyList
                parentTitle={title}
                items={selectPanelProperties}
                nestingLevel={1}
                config={values}
                onChange={onPropertyChange}
                isExpanded={true}
            />
            {!isEmpty(widgetProperties) && (
                <PropertyListItem className="property-list-item--prop-group">
                    <PropertyList
                        errors={widgetErrors}
                        parentTitle={title}
                        items={widgetProperties}
                        nestingLevel={2}
                        config={values}
                        onChange={onPropertyChange}
                        isExpanded={true}
                        containsActiveError={rowSectionContainsActiveError}
                    />
                </PropertyListItem>
            )}
        </>
    );
};
