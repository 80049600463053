import React from "react";
import Dashboard from "../../ui/Dashboard";
import { dashboardTypes, utilityBudgetDashboard } from "../../utils/dashboard";
import Header from "./Header";

const UtilityBudgetView = (props) => {
    const { contractNumber, budgetLineNumber } = props;

    const dashboardData = {
        ...props,
        dashboardKey: `${contractNumber}-${budgetLineNumber}`,
    };

    return (
        <div className="utility-budget-view flex-column fill-height no-scroll">
            <Dashboard
                key={`${contractNumber}-${budgetLineNumber}`}
                type={dashboardTypes.UTILITY_BUDGET}
                activeDashboardId={utilityBudgetDashboard.dashboardNumber}
                dashboardData={dashboardData}
                header={<Header {...props} />}
            />
        </div>
    );
};

export default UtilityBudgetView;
