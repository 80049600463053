import React, { useRef, useState, useCallback, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, isNil, pick, pickBy } from "lodash";

import { referenceTypes } from "../../../ui/Reference/referenceTypes";
import { useResource } from "../../../../store/resources/useResource";
import { createResource, updateResource } from "../../../../store/resources/actions";
import { getData } from "../../../../store/dataGrid/actions";
import { usersGridColumnKeys } from "../../../views/configureGrids";
import {
    getKeyByValue,
    refreshUser,
    useUser,
    defaultSchema,
    defaultUiSchema,
    PASSWORD_GROUPS,
    USER_STATUS,
    USER_TYPES,
    dashboardTypesList,
} from "./utils";
import { resetDashboards } from "../../../utils/dashboard";
import { hasPermission, resetPassword, resetQrCode } from "../../../utils/user";
import { submitByRef } from "../../../utils/form";

import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import Button from "../../../ui/Button";
import JsonSchemaFormWithConditionals from "../../../ui/Form/JsonSchema/JsonSchemaFormWithConditionals";
import { useReference } from "../../../ui/Reference/useReference";
import WaitIcon from "../../../ui/WaitIcon";
import FieldGroupDropdownIcon from "../../../ui/FieldGroupDropdownIcon";
import SideNavHeader from "../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../ui/SideNav/SideNavFooter";
import SideNavContent from "../../../ui/SideNav/SideNavContent";
import ViewPlaceholder from "components/ui/ViewPlaceholder";

import "./UserEditForm.scss";

const UserEditForm = memo(({ userNumber, isNew, panelTitle, goToManagement, onClose }) => {
    const dispatch = useDispatch();

    const formRef = useRef();

    const [user, isLoadingUser] = useUser(userNumber);
    const userName = user?.userName;

    const [manageRolesAfterCreation, setManageRolesAfterCreation] = useState(false);
    const [isResetDashboardsActive, setIsResetDashboardsActive] = useState(false);
    const currentUser = useSelector((state) => get(state, "user"));

    const isQrCodeExists = user?.isQrCodeExists;

    const [companies = [], isLoadingCompanies] = useReference(referenceTypes.userCompanyName);
    const [clients, isLoadingClients] = useResource({
        resourceName: "clients",
        key: "clients",
        transform: (data) => {
            return data?.clientList || [];
        },
    });

    const [serverGroups, isLoadingServerGroups] = useResource({
        resourceName: "userReporting",
        key: "userReporting",
        transform: (data) => data?.servers ?? [],
    });

    const [ssoGroups, isLoadingSSOGroups] = useResource({
        resourceName: "ssoGroups",
        key: "ssoGroups",
        transform: (data) => data?.ssoGroupsList ?? [],
    });

    const schema = useMemo(
        () => defaultSchema(!isNew, companies, clients, serverGroups, ssoGroups),
        [isNew, companies, clients, serverGroups, ssoGroups]
    );
    const uiSchema = useMemo(() => defaultUiSchema(!isNew), [isNew]);

    const initialValues =
        isNew || isNil(user)
            ? {}
            : pickBy({
                  ...user,
                  userType: USER_TYPES[user.userType],
                  passwordRuleId: PASSWORD_GROUPS[user.passwordRuleId],
                  status: USER_STATUS[user.status],
                  company: (companies ?? []).find((company) => company.display === user.company)?.display,
              });

    const title = isNew ? "Create New User Account" : "Edit User Details";
    const leadBlockIcon = isNew ? "add-user-add-identity-add-person-empty" : "user-identity-person-empty edit";

    const onSubmit = (formData) => {
        const submitProperties = [
            "userNumber",
            "status",
            "userName",
            "clientNumber",
            "userType",
            "firstName",
            "lastName",
            "company",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "phone",
            "cell",
            "email",
            "initialPage",
            "passwordRuleId",
            "reportServerGroup",
            "ssoGroupId",
            "ssoUserId",
        ];

        const getSubmitModel = (formData) => {
            if (!formData) {
                return {};
            }

            return {
                ...formData,
                userType: Number(getKeyByValue(USER_TYPES, formData.userType)),
                passwordRuleId: Number(getKeyByValue(PASSWORD_GROUPS, formData.passwordRuleId)),
                status: getKeyByValue(USER_STATUS, formData.status),
            };
        };

        const onSuccess = (userNumber, formData) => {
            dispatch(getData({ dataGridId: "users" }));
            dispatch(refreshUser(userNumber));

            if (manageRolesAfterCreation) {
                let user = {
                    [usersGridColumnKeys.userNumber]: userNumber,
                    [usersGridColumnKeys.clientNumber]: formData.clientNumber,
                    [usersGridColumnKeys.client]: schema.properties.clientNumber.anyOf.find(
                        (client) => client.enum[0] === formData.clientNumber
                    ).title,
                    [usersGridColumnKeys.name]: formData.userName,
                    [usersGridColumnKeys.firstName]: formData.firstName,
                    [usersGridColumnKeys.lastName]: formData.lastName,
                    [usersGridColumnKeys.company]: formData.company,
                };

                goToManagement(user);
            } else {
                onClose();
            }
        };

        if (isNew) {
            dispatch(
                createResource({
                    resourceName: "users",
                    body: getSubmitModel(formData),
                    onSuccess: (action) => onSuccess(action.data.userNumber, formData),
                })
            );
        } else {
            dispatch(
                updateResource({
                    resourceName: "users",
                    resourceId: userNumber,
                    path: {
                        userNumber,
                    },
                    body: pick(getSubmitModel(formData), submitProperties),
                    onSuccess: () => onSuccess(userNumber),
                })
            );
        }
    };

    const onCancel = () => {
        onClose();
    };

    const onResetQrCode = useCallback(() => {
        resetQrCode({
            userNumber,
            userName,
            onComplete: () => {
                dispatch(refreshUser(userNumber));
            },
        });
    }, [dispatch, userNumber, userName]);

    const onResetPassword = useCallback(() => {
        resetPassword({ userNumber, userName });
    }, [userNumber, userName]);

    const onResetDashboard = useCallback(
        (dashboardType) => {
            resetDashboards({ dashboardType, userNumber, currentUser });
        },
        [userNumber, currentUser]
    );

    const renderButtons = () => {
        const submit = () => {
            if (formRef?.current) {
                submitByRef(formRef);
            }
        };

        const submitAndManageRoles = () => {
            setManageRolesAfterCreation(true);
            submit();
        };

        if (isNew) {
            return (
                <>
                    <div className="flex-one-in-row">
                        <Button type="submit" primary onClick={submitAndManageRoles}>
                            Create With Role
                        </Button>
                        <Button onClick={submit}>Create Without Role</Button>
                    </div>
                    <Button onClick={onCancel}>Cancel</Button>
                </>
            );
        }

        return (
            <>
                <Button primary onClick={submit}>
                    Save
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </>
        );
    };

    const canDeleteDashboards = () => {
        return userNumber === currentUser?.userNumber || hasPermission(currentUser, "AU");
    };

    if (isLoadingUser || isLoadingClients || isLoadingCompanies || isLoadingServerGroups || isLoadingSSOGroups) {
        return (
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        );
    }

    return (
        <SideNavContent className="user-edit-form">
            <SideNavHeader title={title} iconWrapCombined={!isNew} leadBlockIcon={leadBlockIcon} onClose={onClose} />
            <SideNavBody>
                {!isNew && (
                    <div className={"user-custom-actions flex-row align-center" + (isQrCodeExists ? " three-action-items" : "")}>
                        {isQrCodeExists && (
                            <IconWithLabel className="user-custom-actions__item" icon="backup-restore-empty" onClick={onResetQrCode}>
                                Reset QR Code
                            </IconWithLabel>
                        )}
                        <IconWithLabel className="user-custom-actions__item" icon="backup-restore-empty" onClick={onResetPassword}>
                            Reset Password
                        </IconWithLabel>
                        <span className="flex-one" />
                        {canDeleteDashboards() && (
                            <FieldGroupDropdownIcon
                                label="Reset Dashboard"
                                title={isResetDashboardsActive ? "Close" : "Reset Dashboard"}
                                className="user-custom-actions__item"
                                dropdownRight
                                dropdownOptions
                                iconWithLabelLeft
                                iconWrapDropdown
                                mobileHeader="Reset Dashboard for"
                                visible={isResetDashboardsActive}
                                iconWrapActive={isResetDashboardsActive}
                                iconWrap={isResetDashboardsActive ? "shevron-circle-down-filled" : "backup-restore-empty"}
                                onClick={() => setIsResetDashboardsActive(!isResetDashboardsActive)}
                                onSelect={onResetDashboard}
                                items={dashboardTypesList}
                            />
                        )}
                    </div>
                )}
                <JsonSchemaFormWithConditionals
                    formRef={formRef}
                    noReset
                    noSubmit
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>{renderButtons()}</SideNavFooter>
        </SideNavContent>
    );
});

export default UserEditForm;
