import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, get } from "lodash";
import { windowAdd, windowRemove, windowSetOnClose, windowSplit, windowClearSplit, windowActivate } from "../../store/window/actions";
import { store } from "../../store/configureStore";
import { setActiveDashboardTool } from "../../store/dashboards/actions";
import { clientRoute } from "./constants";
import { useEffect, useRef, useState } from "react";
import { openConfirmModal } from "components/ui/Modal/utils";
import { createResource } from "store/resources/actions";

const storage = sessionStorage;
const WINDOW_STATE_KEY = "window-state";

export const activityType = {
    OPEN: "open",
    CLOSE: "close",
};

export const windowContainerTypes = {
    Root: "root-window",
    Login: "login-window",
    Home: "home-window",
};

export const splitWindowPositionType = {
    left: "splitWindowPositionLeft",
    right: "splitWindowPositionRight",
};

export const systemTabs = {
    NewApplication: "NewApplication",
    ScanQueue: "ScanQueue",
    UtilityBookmarks: "UtilityBookmarks",
    ProgramBookmarks: "ProgramBookmarks",
    ApplicationBookmarks: "ApplicationBookmarks",
    ContractBookmarks: "ContractBookmarks",
    InvoiceBookmarks: "InvoiceBookmarks",
    Catalog: "GlobalCatalog",
};

export const emptySplitViewName = "SplitView";

export const processAuthenticatedRoutes = () => {
    let activeView = null;
    const pathParts = window.location.pathname.split("/");

    // If route is something like: host/entity/{entityNumber}
    if (pathParts.length > 2) {
        switch (pathParts[1]) {
            case clientRoute.applications:
                activeView = getProjectView({
                    applicationNumber: pathParts[2],
                });
                break;
            case clientRoute.programs:
                activeView = getProgramView({ programNumber: pathParts[2] });
                break;
            case clientRoute.utilities:
                activeView = getUtilityView({ utilityNumber: pathParts[2] });
                break;
            case clientRoute.invoices:
                activeView = getInvoiceView({ documentNumber: pathParts[2] });
                break;
            default:
                break;
        }
    }

    if (activeView) {
        store.dispatch(
            windowAdd({
                ...activeView,
                activate: true,
            })
        );
    }

    clearBrowserUrl();
};

// Listen to parent.checkLoad() call from iframes to open tabs in vision.
export const listenToIframeCallbacks = () => {
    window.checkLoad = (target = "") => {
        if (target.startsWith("app")) {
            const applicationNumber = target.substr(3);
            openProjectTab({ applicationNumber });
        }
    };
};

export const openUrl = (url) => {
    const _url = url.startsWith("http") ? (url.startsWith("http:") ? url.replace("http:", "https:") : url) : `https://${url}`;

    window.open(_url, "_blank");
};

export const clearBrowserUrl = () => {
    window.history.pushState("", "", "/");
};

export const getWindowState = () => {
    return JSON.parse(storage.getItem(WINDOW_STATE_KEY)) || {};
};

export const setWindowState = (state) => {
    if (state !== null) {
        storage.setItem(WINDOW_STATE_KEY, JSON.stringify(state));
    }
};

export const resetWindowState = () => {
    // keep only the window width dependent values, since other properties are user specific
    const { isMobile, isTablet, isDesktopSmall, isDesktop, canSplitScreenView } = store.getState().window;
    storage.setItem(
        WINDOW_STATE_KEY,
        JSON.stringify({
            isDesktopSmall,
            isDesktop,
            isTablet,
            isMobile,
            canSplitScreenView,
        })
    );
};

export const getViewsByComponentName = ({ componentName }) => {
    const state = store.getState();

    return get(state, `window[${windowContainerTypes.Root}].views`, []).filter((view) => view.component === componentName);
};

export const getActiveView = () => {
    const state = store.getState();

    return get(state, `window[${windowContainerTypes.Root}].views`, []).filter((view) => view.active)[0];
};

export const isViewActive = ({ name }) => {
    const activeView = getActiveView();
    return activeView.name === name || activeView.leftViewName === name || activeView.rightViewName === name;
};

export const closeView = ({ name }) => {
    store.dispatch(
        windowRemove({
            containerName: windowContainerTypes.Root,
            name,
        })
    );
};

export const openProjectTab = ({ applicationNumber }) => {
    const view = getProjectView({ applicationNumber });

    store.dispatch(
        windowAdd({
            ...view,
            activate: true,
        })
    );
};

/**
 * Open split view with app in left side.
 * @param {Object} params
 * @param {string} params.applicationNumber
 */
export const splitProjectTab = ({ applicationNumber }) => {
    const activeView = getActiveView();

    if (activeView.isSplitView) {
        const isLeftView = get(activeView, "leftViewProps.applicationNumber") === applicationNumber;

        if (!isLeftView) {
            store.dispatch(
                windowClearSplit({
                    name: activeView.name,
                    containerName: windowContainerTypes.Root,
                    side: "left",
                })
            );

            openProjectTab({ applicationNumber });
        }

        store.dispatch(
            windowClearSplit({
                name: activeView.name,
                containerName: windowContainerTypes.Root,
                side: "right",
            })
        );
    } else {
        store.dispatch(
            windowSplit({
                containerName: windowContainerTypes.Root,
                name: "A: #" + applicationNumber,
            })
        );
    }
};

export const openUtilityTab = ({ utilityNumber, activePanel }) => {
    const view = getUtilityView({ utilityNumber });

    store.dispatch(
        windowAdd({
            ...view,
            activate: true,
        })
    );

    if (activePanel) {
        store.dispatch(
            setActiveDashboardTool({
                entityNumber: utilityNumber,
                tool: activePanel,
            })
        );
    }
};

export const openContractTab = ({ utilityNumber, contractNumber, contractDescription }) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "C: #" + contractNumber,
            component: "UtilityContractView",
            header: `Contract - ${contractDescription}`,
            title: `Contract - ${contractDescription}`,
            close: true,
            activate: true,
            props: {
                utilityNumber,
                contractNumber,
            },
        })
    );
};

export const openInvoiceTab = ({ documentNumber }) => {
    const view = getInvoiceView({ documentNumber });

    store.dispatch(
        windowAdd({
            ...view,
            activate: true,
        })
    );
};

export const openBudgetLineTab = ({ utilityNumber, contractNumber, budgetLine }) => {
    const { budgetLineNumber, budgetName } = budgetLine;

    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "I: #" + budgetLineNumber,
            component: "UtilityBudgetView",
            header: `Budget - ${budgetName}`,
            title: `Budget - ${budgetName}`,
            close: true,
            activate: true,
            props: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        })
    );
};

export const openApplicationFormPages = ({ programNumber, utilityNumber, formNumber, formName, pageNumber, selectedElementId }) => {
    const name = `formName ${programNumber}`;
    const isActive = isViewActive({ name });

    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name,
            component: "ApplicationFormPages",
            header: formName,
            close: true,
            activate: !isActive,
            props: {
                programNumber,
                utilityNumber,
                formNumber,
                pageNumber,
                selectedElementId,
            },
        })
    );
};

export const openNewApplication = ({ utilityNumber, programNumber, customerNumber, customerDetails, reset = false }) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "New Application",
            component: "NewApplication",
            header: "New Application",
            close: true,
            activate: true,
            props: {
                utilityNumber,
                programNumber,
                customerNumber,
                customerDetails,
                reset,
            },
        })
    );
};

export const openNewApplicationFromScanTab = ({ applicationNumber, fileNumber, utilityNumber, programNumber, formPageNumber }) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "Create Scan App: " + applicationNumber,
            component: "NewApplicationFromScan",
            header: "Create New Application From Scan",
            title: "Create Scan App",
            close: true,
            activate: true,
            props: {
                applicationNumber,
                fileNumber,
                utilityNumber,
                programNumber,
                formPageNumber,
            },
        })
    );
};

export const setOnCloseNewApplicationFromScanTab = ({ applicationNumber, onCancel }) => {
    store.dispatch(
        windowSetOnClose({
            containerName: windowContainerTypes.Root,
            name: "Create Scan App: " + applicationNumber,
            onClose: onCancel,
        })
    );
};

// onWindowRemove - is a callback function for splitview
export const closeNewApplicationFromScanTab = ({ applicationNumber, onWindowRemove, shouldWindowRemain }) => {
    store.dispatch(
        windowRemove({
            containerName: windowContainerTypes.Root,
            name: "Create Scan App: " + applicationNumber,
            force: true,
            onWindowRemove,
            shouldWindowRemain,
        })
    );
};

export const closeNewApplication = ({ applicationNumber }) => {
    store.dispatch(
        windowRemove({
            containerName: windowContainerTypes.Root,
            name: "Create App: " + applicationNumber,
        })
    );
};

export const openScanQueueTab = () => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "Scan Queue",
            component: "ScanQueue",
            header: "Scan Queue",
            close: true,
            activate: true,
        })
    );
};

export const openScanQueueManagementTab = () => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "Scan Queue Management",
            component: "ScanQueueManagement",
            header: "Scan Queue Management",
            close: true,
            activate: true,
        })
    );
};

export const openGlobalSearchTab = ({ instanceId }) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: `GlobalSearchView-${instanceId}`,
            component: "GlobalSearchView",
            header: "Search Results",
            close: true,
            activate: true,
            props: {
                instanceId,
            },
        })
    );
};

export const openNewApplicationTab = ({
    applicationNumber,
    utilityNumber,
    programNumber,
    formPageNumber,
    customerNumber,
    customerDetails,
}) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: "Create New Application: " + applicationNumber,
            component: "NewApplicationForm",
            header: "Create New Application",
            title: "Create New Application",
            close: true,
            activate: true,
            props: {
                applicationNumber,
                utilityNumber,
                programNumber,
                formPageNumber,
                customerNumber,
                customerDetails,
            },
        })
    );
};

export const setOnCloseNewApplicationTab = ({ applicationNumber, onCancel }) => {
    store.dispatch(
        windowSetOnClose({
            containerName: windowContainerTypes.Root,
            name: "Create New Application: " + applicationNumber,
            onClose: onCancel,
        })
    );
};

// onWindowRemove - is a callback function for splitview
export const closeNewApplicationTab = ({ applicationNumber, onWindowRemove, shouldWindowRemain }) => {
    store.dispatch(
        windowRemove({
            containerName: windowContainerTypes.Root,
            name: "Create New Application: " + applicationNumber,
            force: true,
            onWindowRemove,
            shouldWindowRemain,
        })
    );
};

export const openLoginPage = () => {
    const view = getLoginView();

    store.dispatch(
        windowAdd({
            ...view,
            activate: true,
        })
    );
};

export const openCustomerTab = ({ utilityNumber, customerNumber }) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: customerNumber,
            component: "CustomerView",
            header: "Customer",
            close: true,
            activate: true,
            props: {
                utilityNumber,
                customerNumber,
            },
        })
    );
};

export const openUserProfile = () => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Home,
            name: "UserProfile",
            title: "User Profile",
            component: "UserProfile",
            showTab: false,
            showHeader: false,
            activate: true,
        })
    );
};

export const openFileTab = ({ fileNumber, fileName, openInSplitView, splitWindowPosition }) => {
    if (openInSplitView) {
        const activeView = getActiveView();

        switch (splitWindowPosition) {
            case splitWindowPositionType.right:
                store.dispatch(
                    windowClearSplit({
                        name: activeView.name,
                        containerName: windowContainerTypes.Root,
                        side: "left",
                    })
                );
                break;
            case splitWindowPositionType.left:
                store.dispatch(
                    windowClearSplit({
                        name: activeView.name,
                        containerName: windowContainerTypes.Root,
                        side: "right",
                    })
                );
                break;
            default:
                store.dispatch(
                    windowSplit({
                        containerName: windowContainerTypes.Root,
                        name: activeView.name,
                    })
                );
                break;
        }
    }

    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: fileNumber,
            header: fileName ?? "",
            title: fileName ?? "",
            component: "FileView",
            activate: true,
            close: true,
            props: {
                fileNumber,
            },
        })
    );
};

export const openCrm = ({ token } = {}) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Home,
            name: "CRM",
            title: "VisionCRM",
            component: "CRM",
            showTab: false,
            showHeader: false,
            activate: true,
            props: {
                token,
            },
        })
    );

    store.dispatch(
        windowActivate({
            containerName: windowContainerTypes.Root,
            name: "Home",
        })
    );
};

export const openPortalBuilderTab = ({
    utilityNumber,
    programNumber,
    entityType,
    entityName,
    portalTemplateNumber,
    activeInitialView,
    forceReplaceProps,
}) => {
    store.dispatch(
        windowAdd({
            containerName: windowContainerTypes.Root,
            name: `portal-builder-${utilityNumber}-${programNumber}-${portalTemplateNumber}`,
            component: "PortalBuilderView",
            header: "Portal Builder",
            showHeader: false,
            activate: true,
            forceReplaceProps,
            layoutType: "left-right",
            props: {
                utilityNumber,
                programNumber,
                entityType,
                entityName,
                portalTemplateNumber,
                activeInitialView: cloneDeep(activeInitialView),
            },
        })
    );
};

export const getLoginView = () => {
    return {
        containerName: windowContainerTypes.Login,
        name: "Login",
        component: "Login",
        showTab: false,
        showHeader: false,
        persist: false,
    };
};

export const getHomeView = () => {
    return {
        containerName: windowContainerTypes.Root,
        name: "Home",
        component: "HomeView",
        showHeader: false,
        close: false,
    };
};

export const getProjectView = ({ applicationNumber }) => {
    return {
        containerName: windowContainerTypes.Root,
        name: "A: #" + applicationNumber,
        component: "ProjectView",
        header: "A: #" + applicationNumber,
        close: true,
        props: {
            applicationNumber,
        },
    };
};

export const getProgramView = ({ programNumber }) => {
    return {
        containerName: windowContainerTypes.Root,
        name: "P: #" + programNumber,
        component: "ProgramView",
        header: "P: #" + programNumber,
        close: true,
        props: {
            programNumber,
        },
    };
};

export const getUtilityView = ({ utilityNumber }) => {
    return {
        containerName: windowContainerTypes.Root,
        name: "U: #" + utilityNumber,
        component: "UtilityView",
        header: "U: #" + utilityNumber,
        close: true,
        props: {
            utilityNumber: utilityNumber,
        },
    };
};

export const getInvoiceView = ({ documentNumber }) => {
    return {
        containerName: windowContainerTypes.Root,
        name: "I: #" + documentNumber,
        component: "UtilityInvoiceView",
        close: true,
        props: {
            documentNumber,
        },
    };
};

export const useConfirmOnWindowClose = ({ confirmTitle, confirmMessage, showConfirmation }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const view = getActiveView();

        const closeView = (onWindowRemove, shouldWindowRemain) =>
            dispatch(
                windowRemove({
                    containerName: windowContainerTypes.Root,
                    name: view.name,
                    force: true,
                    onWindowRemove,
                    shouldWindowRemain,
                })
            );

        dispatch(
            windowSetOnClose({
                containerName: windowContainerTypes.Root,
                name: view.name,
                onClose: (onWindowRemove, shouldWindowRemain) => {
                    if (showConfirmation) {
                        openConfirmModal({
                            title: confirmTitle,
                            message: confirmMessage,
                            onConfirm: () => {
                                closeView(onWindowRemove, shouldWindowRemain);
                            },
                        });
                    } else {
                        closeView(onWindowRemove, shouldWindowRemain);
                    }
                },
            })
        );
    }, [confirmMessage, confirmTitle, dispatch, showConfirmation]);
};

export const openTRMulator = () => async (dispatch, getState) => {
    const user = getState().user;

    const data = {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
        sourceTokenType: "vision",
        targetTokenType: "trmulator",
    };

    const response = await new Promise((resolve, reject) => {
        dispatch(
            createResource({
                resourceName: "applicationTokens",
                body: data,
                recaptchaAction: "trmulator_open",
                onSuccess: (action) => {
                    resolve(action.data);
                },
                onError: (error) => {
                    reject(error?.responseMessage);
                },
            })
        );
    });

    const { accessToken, refreshToken } = response;
    const baseUrl = process.env.REACT_APP_TRMULATOR_URL;
    const appUrl = `${baseUrl}/sso?accesstoken=${accessToken}&refreshtoken=${refreshToken}`;

    window.open(appUrl, "_blank");
};

/**
 * Get TRMulator SSO URL
 *
 * @returns {[string | undefined, boolean]} - TRMulator SSO URL and loading state
 */
export const useTRMulatorSsoUrl = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isTokenRequested = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useState();

    const data = {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
        sourceTokenType: "vision",
        targetTokenType: "trmulator",
    };

    if (!isTokenRequested.current) {
        isTokenRequested.current = true;
        setIsLoading(true);
        dispatch(
            createResource({
                resourceName: "applicationTokens",
                body: data,
                recaptchaAction: "trmulator_open",
                onSuccess: (action) => {
                    const { accessToken, refreshToken } = action.data;
                    if (accessToken && refreshToken) {
                        setUrl(`${process.env.REACT_APP_TRMULATOR_URL}/sso?accesstoken=${accessToken}&refreshtoken=${refreshToken}`);
                    }
                },
                onError: (error) => {
                    console.log(error);
                },
                onComplete: () => {
                    setIsLoading(false);
                },
            })
        );
    }

    return [url, isLoading];
};
