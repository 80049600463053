import React from "react";
import uuidv1 from "uuid/v1";
import memoizeOne from "memoize-one";
import { get, omit, orderBy } from "lodash";

import { store } from "../../store/configureStore";
import { optimisticUpdateItem, deleteResource } from "../../store/resources/actions";
import { modalOpen } from "../../store/modal/actions";
import { setActiveDashboard } from "../../store/dashboards/actions";
import { systemUserRights } from "./user";

const storage = sessionStorage;
const DASHBOARD_STATE_KEY = "dashboard-state";

export const getDashboardState = (defaultValue) => {
    return JSON.parse(storage.getItem(DASHBOARD_STATE_KEY)) || defaultValue;
};

export const setDashboardState = (state) => {
    if (state !== null) {
        storage.setItem(DASHBOARD_STATE_KEY, JSON.stringify(state));
    }
};

export const deleteDashboardState = () => {
    storage.removeItem(DASHBOARD_STATE_KEY);
};

export const getPanel = (type, size) => {
    let panel = null;
    let panelSize = size;
    let panelType = type || "";

    if (!panelSize) {
        panelSize = 2;

        if (panelType.endsWith("-small")) {
            panelType = panelType.split("-").slice(0, -1).join("-");
            panelSize = 1;
        }
    } else {
        if (panelType.endsWith("-small")) {
            panelType = panelType.split("-").slice(0, -1).join("-");
        }
    }

    const panelDefaultProps = {
        id: uuidv1().toUpperCase(),
        type: panelType + (size === 1 ? "-small" : ""),
        w: panelSize,
    };

    panel = []
        .concat(
            defaultProjectTools,
            defaultProgramTools,
            defaultUtilityTools,
            defaultContractTools,
            defaultBudgetTools,
            defaultInvoiceTools,
            defaultCustomerTools
        )
        .reduce((result, tool) => {
            if (panelType === tool.panelType) {
                result = {
                    ...panelDefaultProps,
                    name: tool.title,
                };
            }

            return result;
        }, null);

    return panel;
};

export const getDefinedDashboard = (dashboardType) => {
    let config = null;

    switch (dashboardType) {
        case dashboardTypes.UTILITY_CONTRACT:
            config = utilityContractDashboard;
            break;
        case dashboardTypes.UTILITY_BUDGET:
            config = utilityBudgetDashboard;
            break;
        case dashboardTypes.UTILITY_INVOICE:
            config = utilityInvoiceDashboard;
            break;
        case dashboardTypes.CUSTOMER:
            config = customerDashboard;
            break;
        default:
            break;
    }

    return config;
};

export const getSubmitConfiguration = (dashboard) => {
    return omit(dashboard, ["id", "name", "type", "isDefault", "isGlobal", "entityId"]);
};

export const clearDashboards = (dashboardType) => {
    store.dispatch(
        optimisticUpdateItem({
            resourceName: "dashboards",
            resourceId: dashboardType,
            value: undefined,
        })
    );
};

export const getActiveDashboardById = memoizeOne(
    (dashboards, activeDashboardId) => dashboards.filter((d) => d.id === activeDashboardId)[0]
);

export const dashboardTypes = {
    LANDING_PAGE: "landingpage",
    PROJECT_PAGE: "projectpage",
    UTILITY_PAGE: "utilitypage",
    PROGRAM_PAGE: "programpage",
    UTILITY_CONTRACT: "utility-contract",
    UTILITY_BUDGET: "utility-budget",
    UTILITY_INVOICE: "utility-invoice",
    CUSTOMER: "customer",
};

export const defaultProjectTools = [
    {
        id: "workflow",
        title: "Workflow",
        icon: "workflow",
        panelType: "project-workflow",
        pinned: false,
        show: true,
    },
    {
        id: "applications",
        title: "Forms",
        icon: "forms",
        panelType: "project-applications",
        pinned: false,
        show: true,
    },
    {
        id: "notes",
        title: "Notes",
        icon: "notes",
        panelType: "project-notes",
        pinned: false,
        show: true,
    },
    {
        id: "equipment",
        title: "Equipment",
        icon: "equipment",
        panelType: "project-equipment",
        pinned: false,
        show: true,
    },
    {
        id: "docs",
        title: "Documents",
        icon: "documents",
        panelType: "project-docs",
        pinned: false,
        show: true,
    },
    {
        id: "rebates",
        title: "Payments",
        icon: "rebates",
        panelType: "project-rebates",
        pinned: false,
        show: true,
    },
    {
        id: "contacts",
        title: "Contacts",
        icon: "contacts",
        panelType: "project-contacts",
        pinned: false,
        show: true,
    },
    {
        id: "location",
        title: "Location",
        icon: "location",
        panelType: "project-location",
        pinned: false,
        show: true,
    },
    {
        id: "audit",
        title: "History",
        icon: "audit",
        panelType: "project-audit",
        pinned: false,
        show: true,
    },
    {
        id: "events",
        title: "Events",
        icon: "events",
        panelType: "project-events",
        pinned: false,
        show: true,
    },
    {
        id: "control",
        title: "QC",
        icon: "control",
        panelType: "project-control",
        pinned: false,
        show: true,
    },
    {
        id: "opportunity",
        title: "Opportunity",
        icon: "control",
        panelType: "project-opportunity",
        pinned: undefined,
        show: true,
    },
    {
        id: "portaldashboard",
        title: "Reports",
        icon: "dashboard",
        panelType: "project-portaldashboard",
        pinned: false,
        show: true,
    },
    {
        id: "activity",
        title: "Activity",
        icon: "documents",
        panelType: "project-activity",
        pinned: undefined,
        show: true,
    },
];

export const defaultUtilityTools = [
    {
        id: "snapshot",
        title: "Snapshot",
        icon: "snapshot",
        panelType: "utility-snapshot",
        show: true,
    },
    {
        id: "config",
        title: "Config",
        icon: "settings-empty",
        panelType: "utility-config",
        show: true,
    },
    {
        id: "programs",
        title: "Programs",
        icon: "programs",
        panelType: "utility-programs",
        show: true,
    },
    {
        id: "financials",
        title: "Financials",
        icon: "financials",
        panelType: "utility-financials",
        show: true,
    },
    {
        id: "files",
        title: "Files",
        icon: "files",
        panelType: "utility-files",
        show: true,
    },
    {
        id: "usergroups",
        title: "User Groups",
        icon: "usergroups",
        panelType: "utility-usergroups",
        show: true,
    },
    {
        id: "calculations",
        title: "Calculations",
        icon: "calculations",
        panelType: "utility-calculations",
        show: true,
    },
    {
        id: "rebateapprovals",
        title: "Payment Approvals",
        icon: "rebates",
        panelType: "utility-rebateapprovals",
        show: true,
    },
    {
        id: "forecast",
        title: "Forecast",
        icon: "forecast",
        panelType: "utility-forecast",
        show: true,
    },
];

export const defaultProgramTools = [
    {
        id: "snapshot",
        title: "Snapshot",
        icon: "snapshot",
        panelType: "program-snapshot",
        show: true,
    },
    {
        id: "config",
        title: "Config",
        icon: "settings-empty",
        panelType: "program-config",
        show: true,
    },
    {
        id: "budgets",
        title: "Budgets",
        icon: "budgets",
        panelType: "program-budgets",
        show: true,
    },
    {
        id: "goals",
        title: "Goals",
        icon: "goals",
        panelType: "program-goals",
        show: true,
    },
    {
        id: "workflow",
        title: "Workflow",
        icon: "workflow",
        panelType: "program-workflow",
        show: true,
    },
    {
        id: "catalog",
        title: "Catalog",
        icon: "catalog",
        panelType: "program-catalog",
        show: true,
    },
    {
        id: "events",
        title: "Events",
        icon: "events",
        panelType: "program-events",
        show: true,
    },
    {
        id: "forms",
        title: "Forms",
        icon: "forms",
        panelType: "program-forms",
        show: true,
    },
    {
        id: "content",
        title: "Content",
        icon: "content",
        panelType: "program-content",
        show: true,
    },
    {
        id: "documents",
        title: "Documents",
        icon: "documents",
        panelType: "program-documents",
        show: true,
    },
    {
        id: "files",
        title: "Files",
        icon: "files",
        panelType: "program-files",
        show: true,
    },
    {
        id: "applications",
        title: "Applications",
        icon: "applications",
        panelType: "program-applications",
        show: true,
    },
    {
        id: "rebates",
        title: "Payments",
        icon: "rebates",
        panelType: "program-rebates",
        show: true,
    },
    {
        id: "calculations",
        title: "Calculations",
        icon: "calculations",
        panelType: "program-calculations",
        show: true,
    },
];

export const defaultContractTools = [
    {
        id: "invoices",
        title: "Invoices",
        icon: "snapshot",
        panelType: "utility-contract-invoices",
        show: true,
    },
    {
        id: "budget-lines",
        title: "Budget Lines",
        icon: "snapshot",
        panelType: "utility-contract-budget-lines",
        show: true,
    },
    {
        id: "workflow",
        title: "Workflow",
        icon: "workflow",
        panelType: "utility-contract-workflow",
        show: true,
    },
    {
        id: "attributes",
        title: "Attributes",
        icon: "snapshot",
        panelType: "utility-contract-attributes",
        show: true,
    },
    {
        id: "budget-lookups",
        title: "Budget Lookups",
        icon: "snapshot",
        panelType: "utility-contract-budget-lookups",
        show: true,
    },
];

export const defaultBudgetTools = [
    {
        id: "amounts",
        title: "Amounts",
        icon: "snapshot",
        panelType: "utility-budget-amounts",
        show: true,
    },
    {
        id: "invoices",
        title: "Invoices",
        icon: "snapshot",
        panelType: "utility-budget-invoices",
        show: true,
    },
    {
        id: "attributes",
        title: "Attributes",
        icon: "snapshot",
        panelType: "utility-budget-attributes",
        show: true,
    },
    {
        id: "program-assignment",
        title: "Program Assignment",
        icon: "snapshot",
        panelType: "utility-budget-program-assignment",
        show: true,
    },
];

export const defaultInvoiceTools = [
    {
        id: "workflow",
        title: "Workflow",
        icon: "workflow",
        panelType: "utility-invoice-workflow",
        show: true,
    },
    {
        id: "invoice-items",
        title: "Invoice Items",
        icon: "snapshot",
        panelType: "utility-invoice-items",
        show: true,
    },
    {
        id: "docs",
        title: "Documents",
        icon: "documents",
        panelType: "utility-invoice-docs",
        show: true,
    },
    {
        id: "notes",
        title: "Notes",
        icon: "notes",
        panelType: "utility-invoice-notes",
        show: true,
    },
    {
        id: "history",
        title: "History",
        icon: "audit",
        panelType: "utility-invoice-history",
        show: true,
    },
];

export const defaultCustomerTools = [
    {
        id: "applications",
        title: "Applications",
        icon: "applications",
        panelType: "customer-applications",
        show: true,
    },
    {
        id: "contacts",
        title: "Contacts",
        icon: "contacts",
        panelType: "customer-contacts",
        show: true,
    },
];

export const utilityContractDashboard = {
    dashboardNumber: "utility-contract-dashboard",
    dashboardName: "Default dashboard",
    dashboardType: "utility-contract",
    isDefault: true,
    isGlobal: false,
    configuration: JSON.stringify({
        columns: 3,
        tools: defaultContractTools,
        panels: [
            {
                id: "budget-lines",
                type: "utility-contract-budget-lines",
                name: "Budget Lines",
                w: 2,
            },
        ],
    }),
};

export const utilityBudgetDashboard = {
    dashboardNumber: "utility-budget-dashboard",
    dashboardName: "Default dashboard",
    dashboardType: "utility-budget",
    isDefault: true,
    isGlobal: false,
    configuration: JSON.stringify({
        columns: 3,
        tools: defaultBudgetTools,
        panels: [
            {
                id: "budget-amounts",
                type: "utility-budget-amounts",
                name: "Amounts",
                w: 2,
            },
        ],
    }),
};

export const utilityInvoiceDashboard = {
    dashboardNumber: "utility-invoice-dashboard",
    dashboardName: "Default dashboard",
    dashboardType: "utility-invoice",
    isDefault: true,
    isGlobal: false,
    configuration: JSON.stringify({
        columns: 3,
        tools: defaultInvoiceTools,
        panels: [
            {
                id: "invoice-workflow",
                type: "utility-invoice-workflow",
                name: "Workflow",
                w: 2,
            },
        ],
    }),
};

export const customerDashboard = {
    dashboardNumber: "customer-dashboard",
    dashboardName: "Default dashboard",
    dashboardType: "customer",
    isDefault: true,
    isGlobal: false,
    configuration: JSON.stringify({
        columns: 3,
        tools: defaultCustomerTools,
        panels: [
            {
                id: "contacts",
                type: "customer-contacts",
                name: "Contacts",
                w: 2,
            },
        ],
    }),
};

export const getDashboardNameByType = (dashboardType) => {
    let name = "";

    switch (dashboardType) {
        case dashboardTypes.LANDING_PAGE:
            name = "Landing Page";
            break;
        case dashboardTypes.UTILITY_PAGE:
            name = "Utility";
            break;
        case dashboardTypes.PROGRAM_PAGE:
            name = "Program";
            break;
        case dashboardTypes.PROJECT_PAGE:
            name = "Project";
            break;
        default:
            break;
    }

    return name;
};

export const resetDashboards = ({ dashboardType, userNumber, currentUser, entityNumber }) => {
    const title = 'Reset "' + dashboardType.label + '" Dashboard';

    const message = (
        <>
            <p>
                This action will permanently reset <b>{dashboardType.label}</b> dashboard. Once completed, this action cannot be undone.
            </p>
            <p>Are you sure you want to do this?</p>
        </>
    );

    store.dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: title,
                overlayClassName: "modal-styled",
                className: "rest-dashboard-confirmation-modal modal-sm",
                modalIcon: "backup-restore-empty",
                footerContentCenter: true,
                content: message,
                onConfirm: () => {
                    const query =
                        userNumber === currentUser.userNumber
                            ? undefined
                            : {
                                  tgtUserNumber: userNumber,
                              };

                    store.dispatch(
                        deleteResource({
                            resourceName: "dashboardsInitialize",
                            path: {
                                dashboardType: dashboardType.id,
                            },
                            query,
                            onSuccess: () => {
                                if (userNumber === currentUser.userNumber) {
                                    const dashboards = get(store.getState(), `resources.dashboards.itemsById[${dashboardType.id}]`);

                                    if (dashboards) {
                                        const defaultDashboards = dashboards.filter((d) => d.isGlobal);

                                        store.dispatch(
                                            optimisticUpdateItem({
                                                resourceName: "dashboards",
                                                resourceId: dashboardType.id,
                                                value: dashboards.filter((d) => d.isGlobal),
                                            })
                                        );

                                        if (entityNumber && defaultDashboards.length > 0) {
                                            store.dispatch(
                                                setActiveDashboard({
                                                    entityNumber,
                                                    dashboardNumber: defaultDashboards[0].id,
                                                })
                                            );
                                        }
                                    }
                                }
                            },
                        })
                    );
                },
            },
        })
    );
};

export const transformDashboardResponse = (data) => {
    let result = data.map((item, index) => {
        const config = JSON.parse(item.configuration);

        return {
            ...config,
            id: item.dashboardNumber,
            name: item.dashboardName,
            type: item.dashboardType,
            isDefault: item.isDefault,
            isGlobal: item.isGlobal,
            entityNumber: item.entityNumber,
            tools: getToolList(item.dashboardType, config),
            order: config.order ?? index,
            showHeaderDetails: config.showHeaderDetails ?? false, // show or hide expandable details in dashboard header
            show: config.show || item.isGlobal,
        };
    });

    return orderBy(result, ["order"], ["asc"]);
};

function getToolList(dashboardType, configuration) {
    let existingTools = configuration.tools ?? [];
    let defaultTools = [];

    switch (dashboardType) {
        case dashboardTypes.PROJECT_PAGE:
            defaultTools = defaultProjectTools;
            break;
        case dashboardTypes.UTILITY_PAGE:
            defaultTools = defaultUtilityTools;
            break;
        case dashboardTypes.PROGRAM_PAGE:
            defaultTools = defaultProgramTools;
            break;
        case dashboardTypes.UTILITY_CONTRACT:
            defaultTools = defaultContractTools;
            break;
        case dashboardTypes.UTILITY_BUDGET:
            defaultTools = defaultBudgetTools;
            break;
        case dashboardTypes.UTILITY_INVOICE:
            defaultTools = defaultInvoiceTools;
            break;
        case dashboardTypes.CUSTOMER:
            defaultTools = defaultCustomerTools;
            break;
        default:
            break;
    }
    const newTools = defaultTools.filter((tool) => !existingTools.some((existing) => existing.id === tool.id));
    return existingTools.concat(newTools);
}

export const getExcludedProgramSections = (programRights) => {
    const list = [];

    !programRights.includes(systemUserRights.VISIONDSM_ADD_WORKFLOW) && list.push("workflow");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM) &&
        !programRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM) &&
        !programRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM) &&
        list.push("config");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_FORM) && list.push("forms");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM_CATALOG) && list.push("catalog");

    !programRights.includes(systemUserRights.VISIONDSM_MANAGE_EVENTS) && list.push("events");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_CONTENT) &&
        !programRights.includes(systemUserRights.VISIONDSM_APPROVE_CONTENT) &&
        list.push("content");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_CALCULATION) && list.push("calculations");

    !programRights.includes(systemUserRights.VISIONDSM_VIEW_APPLICATION) &&
        !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION) &&
        !programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS) &&
        list.push("applications");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM) &&
        !programRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM) &&
        !programRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM) &&
        list.push("files");

    !programRights.includes(systemUserRights.VISIONDSM_VIEW_APPLICATION) &&
        !programRights.includes(systemUserRights.VISIONDSM_ADD_APPLICATION) &&
        !programRights.includes(systemUserRights.VISIONDSM_MANAGE_APPLICATIONS) &&
        list.push("rebates");

    !programRights.includes(systemUserRights.VISIONDSM_VIEW_BUDGET) && list.push("budgets");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_CORRESPONDENCE) && list.push("documents");

    !programRights.includes(systemUserRights.VISIONDSM_ADD_GOAL) && list.push("goals");

    return list;
};

export const getExcludedUtilitySections = (utilityRights) => {
    const list = [];

    !utilityRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_PROGRAM) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_VIEW_PROGRAM) &&
        list.push("programs");

    !utilityRights.includes(systemUserRights.VISIONDSM_VIEW_UTILITY) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS) &&
        list.push("config");

    !utilityRights.includes(systemUserRights.VISIONDSM_ADD_CONTRACT) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_ADD_INVOICE) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_VIEW_INVOICE) &&
        list.push("financials");

    !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_UTILITY_FILES) && list.push("files");

    !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_USERGROUPS) && list.push("usergroups");

    !utilityRights.includes(systemUserRights.VISIONDSM_ADD_CALCULATION) && list.push("calculations");

    !utilityRights.includes(systemUserRights.VISIONDSM_BULK_APPROVAL) && list.push("rebateapprovals");

    !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_FORECASTS) &&
        !utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_FORECASTS_PRO) &&
        list.push("forecast");

    return list;
};
