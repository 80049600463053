import React, { memo } from "react";

import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { getInvoiceDocumentsGridId } from "./utils";
import { openInvoiceTab } from "../../../../../utils/window";
import { availableGrids } from "../../../../../views/configureGrids";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import { useDataGrid } from "components/utils/useDataGrid";

const invoiceDocumentsGridColumnKeys = {
    invId: "c_a2898f09-32c8-47c9-aee9-974958f163d6",
    vendor: "c_c04f6de0-bde2-4ac7-a568-a30de177d85b",
    invoiceDate: "c_e470795a-5ff5-4bde-a1a0-f112b7c263a3",
    dateEdited: "c_94002c2b-b3cd-42a5-ad5f-65c59915e917",
    total: "c_f2c82a0d-89d6-42cf-a82c-f44eed0e0100",
    status: "c_cc38faf6-ffb3-49b3-a65c-5f79be63c649",
    invoiceDocumentID: "c_2dabe7c5-65d9-4025-9b5a-dcb37c8a5eea",
    invoiceDocumentNumber: "c_ace62981-883b-4d9a-ab65-0d4c3b700eec",
    refId: "c_9bf39212-53b5-46bc-a443-1fb1c6a0ea19",
    invoiceNumber: "c_f134334c-d66b-40a4-bbdc-59fef97600f2",
    invoiceDescription: "c_effa37d9-42b2-4cf7-be7e-59dcff721844",
    paymentText: "c_dd03bcab-ac9b-4bcc-9411-48bfff3f8f84",
    islocked: "c_6ca3fcf1-9b1f-4809-a7f5-adb37b57b56d",
    dateEntered: "c_2c3919af-342f-44eb-b111-bcd72917a929",
};

const Grid = memo(({ utilityNumber, contractNumber, onRefresh }) => {
    const dataGridId = getInvoiceDocumentsGridId({ contractNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-invoices-sidenav-panel",
    });

    const rowActions = [
        {
            name: "edit",
            icon: (row) => (row[invoiceDocumentsGridColumnKeys.islocked] ? "eye-visibility-empty" : "edit-empty"),
            title: (row) => (row[invoiceDocumentsGridColumnKeys.islocked] ? "View" : "Edit"),
            footerExclude: true,
        },
        {
            name: "open",
            icon: "open-new-window",
            title: "Manage Invoice",
            footerName: "Manage Invoice",
        },
    ];

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.invoiceDocuments,
        dataGridInstanceId: dataGridId,
        sorting: {
            type: SortType.CLIENT,
            sort: [
                {
                    dir: "desc",
                    field: invoiceDocumentsGridColumnKeys.dateEdited,
                },
            ],
        },
        getDataAction: {
            type: "invoiceDocumentsGridGetDataAction",
            props: {
                columnKeysMap: invoiceDocumentsGridColumnKeys,
                utilityNumber,
                contractNumber,
                dataGridId,
            },
        },
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(invoiceDocumentsGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        gridRefresh={onRefresh}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;

            case "open":
                openInvoiceTab({
                    documentNumber: dataItem[invoiceDocumentsGridColumnKeys.invoiceDocumentNumber],
                });
                break;
            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
});

export default Grid;
