import React, { memo } from "react";

import withDataGrid from "../../../../DataGrid/withDataGrid";
import { programsDocumentsGridColumnKeys } from "../../../../../views/configureGrids";

import DataGrid from "../../../../DataGrid";
import CorrespondenceForm from "./CorrespondenceForm";
import DocumentRevisionsPanel from "./DocumentRevisionsPanel";
import CorrespondenceWorkflowPanel from "./CorrespondenceWorkflowPanel";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { isChildProgram } from "components/views/ProgramView/utils";

const DocumentsGrid = memo((props) => {
    const { gridId, dataGridConfig, programNumber } = props;
    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "edit",
            title: isLocked ? "View Correspondence" : "Edit Correspondence",
            icon: isLocked ? "eye-visibility-empty" : "edit-empty",
        },
        {
            name: "revisions",
            title: "Manage Correspondence Revisions",
            icon: "assignment",
        },
        {
            name: "workflow",
            icon: "workflow",
            title: "View Workflow Using This Correspondence",
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;
        const documentName = dataItem[programsDocumentsGridColumnKeys.name];
        const documentNumber = dataItem[programsDocumentsGridColumnKeys.documentNumber];

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <CorrespondenceForm dataItem={dataItem} gridId={gridId} programNumber={programNumber} onClose={handleCloseSidePanel} />,
                    { className: "correspondence-edit-sidenav-panel" }
                );
                break;

            case "revisions":
                handleOpenSidePanel(
                    <DocumentRevisionsPanel
                        gridId={gridId}
                        programNumber={programNumber}
                        documentNumber={documentNumber}
                        documentName={documentName}
                        onClose={handleCloseSidePanel}
                    />,
                    { size: sideNavSize.staticLarge }
                );
                break;

            case "workflow":
                handleOpenSidePanel(
                    <CorrespondenceWorkflowPanel
                        programNumber={programNumber}
                        documentNumber={documentNumber}
                        documentName={documentName}
                        onClose={handleCloseSidePanel}
                    />,
                    { size: sideNavSize.staticLarge }
                );
                break;

            default:
                break;
        }
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsDocuments", DocumentsGrid);
