import React, { useContext, memo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { GridListContext } from ".";
import WaitIcon from "../../WaitIcon";
import IconWrap from "../../Icons";
import GridSettingsDropdown from "../../DataGrid/GridSettingsDropdown";
import { GridContext } from "../../DataGrid";
import { hasCustomFilter } from "components/utils/datagrid";
import GridListCell from "./GridListCell";
import { WindowContext } from "components/ui/Windows/Window";

const getActionIcon = (action) => {
    return action.icon;
};

const renderAction = (action, dataGridId, columns, onGridAction, showedColumnsKeys, filterRequired) => {
    if (action.name === "columnmanagement") {
        return <GridSettingsDropdown key={action.name} dataGridId={dataGridId} columns={columns.filter((c) => c.hidecolumn !== "true")} />;
    }

    if (action.name === "clear-filter") {
        return (
            <IconWrap
                key={action.name}
                icon={getActionIcon(action)}
                iconWrapHidden={!hasCustomFilter(dataGridId, showedColumnsKeys)}
                title={action.title}
                onClick={() => onGridAction(action)}
            />
        );
    }

    if (action.name === "refresh") {
        return (
            <IconWrap
                key={action.name}
                icon={getActionIcon(action)}
                iconWrapHidden={filterRequired && !hasCustomFilter(dataGridId, showedColumnsKeys)}
                title={action.title}
                onClick={() => onGridAction(action)}
            />
        );
    }

    return <IconWrap key={action.name} icon={getActionIcon(action)} title={action.title} onClick={() => onGridAction(action)} />;
};

const GridListActions = memo(({ empty }) => {
    const context = useContext(GridContext) ?? {};
    const { dataGridId, columns, showedColumnsKeys, filterRequired } = context;
    const windowContext = useContext(WindowContext) ?? {};

    const { showActions, gridActions, isReading, onGridAction } = useContext(GridListContext);
    const isSettingsOpen = useSelector((state) => state.dataGrid[dataGridId]?.isSettingsOpen[windowContext.containerName]);

    if (!showActions || !context) {
        return null;
    }

    return (
        <GridListCell
            className={cn("actions", {
                "settings-open": isSettingsOpen,
            })}
        >
            {!empty &&
                (isReading && !isSettingsOpen ? (
                    <WaitIcon />
                ) : (
                    gridActions.map((action) => renderAction(action, dataGridId, columns, onGridAction, showedColumnsKeys, filterRequired))
                ))}
        </GridListCell>
    );
});

export default GridListActions;
