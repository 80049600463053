import AssociationsList from "components/ui/AssociationsList";
import { RolesList } from "components/ui/List/RolesList";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavHeaderInfoList from "components/ui/SideNav/SideNavHeaderInfoList";
import React, { memo, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientUsersResourceParams } from "store/configureResources";
import { useResource } from "store/resources/useResource";
import { ChangeProgramLevelRoleTitle, RoleChangeModalMessage } from "./RoleChangeModalMessage";
import { exportProgramList, getUserListInfo, setProgramLevelRoleForSelection } from "./utils";

export const RolesByProgramSideNav = memo(({ clientNumber, clientName, utilityNumber, roles, programs, onClose }) => {
    const dispatch = useDispatch();
    const utilityName = useSelector((state) => state.resources.utilities.itemsById[utilityNumber]?.utility);

    const [users] = useResource({
        ...getClientUsersResourceParams({ clientNumber, utilityNumber }),
        transform: (data) => data?.clientUserList ?? [],
    });

    const userList = useMemo(() => {
        return (users ?? []).map((item, index) => ({
            itemId: item.userNumber,
            itemName: item.userName,
        }));
    }, [users]);

    const itemInfoRef = useRef();

    const itemInfo = useMemo(() => {
        const result = getUserListInfo({
            utilityNumber,
            roles,
            programs,
            users,
        });
        itemInfoRef.current = result;
        return result;
    }, [utilityNumber, roles, programs, users]);

    const onHeaderRoleClick = useCallback(
        ({ roleId, roleName, selectedItems }) => {
            const userNumbers = selectedItems?.length > 0 ? selectedItems : users.map((i) => i.userNumber);

            const programNumbers = programs.map((p) => p.programNumber);

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers,
                programNumbers,
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs}
                        userList={users.filter((i) => userNumbers.some((userNumber) => userNumber === i.userNumber))}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, programs, users, dispatch]
    );

    const onListItemRoleClick = useCallback(
        ({ roleId, itemId }) => {
            const roleName = roles.find((r) => r.roleId === roleId)?.roleName;

            setProgramLevelRoleForSelection({
                clientNumber,
                utilityNumber,
                roleId,
                roleName,
                userNumbers: [itemId], // Clicked row userNumber
                programNumbers: programs.map((p) => p.programNumber),
                message: (
                    <RoleChangeModalMessage
                        title={<ChangeProgramLevelRoleTitle roleName={roleName} />}
                        utilityList={[{ utilityName }]}
                        programList={programs}
                        userList={users.filter((i) => i.userNumber === itemId)}
                    />
                ),
                dispatch,
            });
        },
        [clientNumber, utilityNumber, utilityName, roles, programs, users, dispatch]
    );

    const onListItemRoleBadgeClick = useCallback((data) => {
        const { roleId, itemData } = data;
        const programs = itemData.roleAssignments[roleId];

        if (programs) {
            exportProgramList({ programs });
        }
    }, []);

    const onGetListItemInfo = useCallback(async (itemId, refresh) => {
        return itemInfoRef.current[itemId];
    }, []);

    const showRolesList = roles.length > 0;

    const headerItems = [
        {
            label: "Client",
            value: clientName,
        },
        {
            label: "Utility",
            value: utilityName,
        },
    ];

    const roleHeaderButtonTitle = useCallback(({ isAnyItemSelected }) => {
        return isAnyItemSelected ? "Set role for selected users in selected programs" : "Set role for all users in selected programs";
    }, []);

    const roleButtonTitle = useCallback(({ isHighlighted, roleName }) => {
        return `Set ${roleName} role for this user`;
    }, []);

    const roleBadgeTitle = useCallback(({ roleAssignments, roleName }) => {
        return `${roleAssignments} programs in ${roleName} role`;
    }, []);

    return (
        <SideNavContent className="role-management-sidenav">
            <div className="flex-row flex-one-in-column">
                <SideNavHeader sidenavHeaderLeftAligned title="Manage Users" leadBlockIcon="theaters-empty" onClose={onClose}>
                    <div className="role-management-sidenav-header-top">
                        <SideNavHeaderInfoList items={headerItems} />
                    </div>
                    <AssociationsList headerText="Selected Programs" list={programs} displayProperty="program" readOnly />
                </SideNavHeader>
                <SideNavBody noPadding>
                    {showRolesList && (
                        <RolesList
                            listItemsTitle="Users"
                            roles={roles}
                            listItems={userList}
                            listItemInfo={itemInfo}
                            roleHeaderButtonTitle={roleHeaderButtonTitle}
                            roleButtonTitle={roleButtonTitle}
                            roleBadgeTitle={roleBadgeTitle}
                            onHeaderRoleClick={onHeaderRoleClick}
                            onListItemRoleClick={onListItemRoleClick}
                            onListItemRoleBadgeClick={onListItemRoleBadgeClick}
                            onListItemsSelect={() => {}}
                            onGetListItemInfo={onGetListItemInfo}
                        />
                    )}
                </SideNavBody>
            </div>
        </SideNavContent>
    );
});
