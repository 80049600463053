import React from "react";
import { isEmpty } from "lodash";
import cn from "classnames";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { PropertyList } from "../../PropertyList";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PropertyType } from "components/ui/PortalBuilder/types";
import sassVariables from "assets/sass/_export.module.scss";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { ButtonBorderWidthProperty } from "./ButtonBorderWidthProperty";
import { ButtonBorderColorProperty } from "./ButtonBorderColorProperty";
import { ButtonBorderCustomPropertyName } from "./types";

import "./GlobalButtonProperties.scss";

export const DefaultBorderWidth = "1px";
export const DefaultBorderColor = sassVariables.colorBaseGrey;

export const PrimaryButtonBorderProperty = (props) => {
    const { className, title, value = {}, nestingLevel, borderTop, borderBottom, onChange } = props;

    const isExpanded = Object.values(value).some((v) => !isEmpty(v));
    const customPropertiesValue = {
        [ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth]: getBorderWidth(
            value[ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth]
        ),
        [ButtonBorderCustomPropertyName.PrimaryButtonBorderColor]: value[ButtonBorderCustomPropertyName.PrimaryButtonBorderColor],
        [ButtonBorderCustomPropertyName.PrimaryButtonBorderColorOpacity]:
            value[ButtonBorderCustomPropertyName.PrimaryButtonBorderColorOpacity],
    };

    const onToggle = () => {
        if (isExpanded) {
            const extraProperties = [
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColor,
                    value: undefined,
                },
                {
                    id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColorOpacity,
                    value: undefined,
                },
            ];
            onChange(ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth, undefined, extraProperties);
        } else {
            onChange(ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth, DefaultBorderWidth);
        }
    };

    const buttonBorderPropertyContextValue = {
        customPropertiesValue,
    };

    const customProperties = [
        {
            id: ButtonBorderCustomPropertyName.PrimaryButtonBorderWidth,
            type: PropertyType.CustomComponent,
            title: <>Border width:</>,
            borderTop: false,
            borderBottom: false,
            component: ButtonBorderWidthProperty,
        },
        {
            id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColor,
            type: PropertyType.CustomComponent,
            component: ButtonBorderColorProperty,
            borderTop: true,
            borderBottom: false,
        },
        {
            id: ButtonBorderCustomPropertyName.PrimaryButtonBorderColorOpacity,
            title: (
                <>
                    Border color <br /> opacity
                </>
            ),
            type: PropertyType.InputRange,
            borderTop: true,
            borderBottom: false,
        },
    ];

    return (
        <BorderPropertyContext.Provider value={buttonBorderPropertyContextValue}>
            <PropertyListItem
                className={cn("property-list-item--prop-group property-list-item--border", className)}
                borderTop={borderTop}
                borderBottom={borderBottom}
            >
                {/* @ts-ignore */}
                <PropertyListItemTitle
                    title={title}
                    toggleTooltip={isExpanded ? "Switch OFF" : "Switch ON"}
                    onToggle={onToggle}
                    toggleValue={!isExpanded}
                />
                <PropertyList
                    items={customProperties}
                    nestingLevel={nestingLevel + 1}
                    config={customPropertiesValue}
                    onChange={onChange}
                    isExpanded={isExpanded}
                />
            </PropertyListItem>
        </BorderPropertyContext.Provider>
    );
};

const getBorderWidth = (cssValue) => {
    const result = parseInt(cssValue, 10);
    return isNaN(result) ? 1 : result;
};

export const PRIMARY_BUTTON_BORDER_PROPERTY = {
    title: "Border",
    type: PropertyType.CustomComponent,
    component: PrimaryButtonBorderProperty,
};
