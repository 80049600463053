import React, { memo } from "react";

import { invoiceIsLockedText } from "../../../../../views/UtilityInvoiceView/utils";

import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = memo(({ isLocked, onAdd, onAddEnabled }) => {
    const title = onAddEnabled
        ? undefined
        : "Invoice Item can not be created.\nBudget lookups record(s) MUST exist for the contract.\nThe last budget lookup in the lookup tree must have a budget line id.";

    return (
        <div className="grid-controls flex-row align-center">
            <span className="flex-one" />
            <span title={title}>
                <IconWithLabel
                    icon="plus"
                    disabled={isLocked || !onAddEnabled}
                    title={isLocked ? invoiceIsLockedText : undefined}
                    onClick={onAdd}
                >
                    Add Invoice Item
                </IconWithLabel>
            </span>
        </div>
    );
});

export default Controls;
