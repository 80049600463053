import React from "react";
import { useDispatch } from "react-redux";

import { onDeleteBudgetLineAttribute } from "../utils";
import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { availableGrids } from "../../../../../views/configureGrids";
import { getBudgetLinesAttributesResourceParams } from "store/configureResources";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import { useDataGrid } from "components/utils/useDataGrid";

const budgetLineAttributesGridColumnKeys = {
    budgetAttrDesc: "c_cfe0ead3-d541-44a8-a647-03c01d27445f",
    budgetAttrValueStr: "c_45a33c4d-3199-441a-ae5e-a90691e381a1",
    budgetAttrValue: "c_d5358897-0150-4335-b645-0d6eb6a74bad",
    attributeNumber: "c_126d887a-a32f-4382-8c76-fb1121d6517e",
    budgetAttrTypeId: "c_e427829f-9392-4cd7-8095-0eba5e23d6be",
};

const Grid = ({ dataGridId, utilityNumber, contractNumber, budgetLineNumber, onRefresh }) => {
    const dispatch = useDispatch();

    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canEdit = utilityRights.includes(systemUserRights.VISIONDSM_ADD_CONTRACT);
    const canDelete = utilityRights.includes(systemUserRights.VISIONDSM_ADD_CONTRACT);

    const rowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit Budget Line Attribute",
            footerExclude: true,
            disabled: !canEdit,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Delete Budget Line Attribute",
            disabled: !canDelete,
        },
    ];

    const { handleOpenSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-attributes-sidenav-panel",
    });

    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.budgetLineAttributes,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: budgetLineAttributesGridColumnKeys,
                resourceParams: getBudgetLinesAttributesResourceParams({
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                }),
            },
        },
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(budgetLineAttributesGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        budgetLineNumber={budgetLineNumber}
                        gridRefresh={onRefresh}
                    />
                );
                break;
            case "delete":
                onDeleteBudgetLineAttribute({
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                    dataItem: data,
                    onRefresh,
                    dispatch,
                });
                break;
            default:
                break;
        }
    };

    if (isLoading || isLoadingRights) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
};

export default Grid;
