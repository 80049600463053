import React from "react";
import cn from "classnames";
import StatusMsg from "../StatusMsg";
import { MessageProps } from ".";

const SuccessMessage = ({ spaceAround, inline, children, msgCentered, msgSquare }: MessageProps) => {
    return (
        <StatusMsg
            className={cn("success-message", {
                "success-message--margin": spaceAround,
                "success-message--inline": inline,
            })}
            msgVisible
            msgFieldStatus
            msgSuccess
            msgText={children}
            msgCentered={msgCentered}
            msgSquare={msgSquare}
        />
    );
};

export default SuccessMessage;
