import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const Label = (props) => {
    const classes = ClassNames(props.labelIcon, props.className, props.contentLabel, {
        "content-label": props.contentLabel,
        required: props.labelRequired,
        "with-icon": props.labelIcon,
        "label-icon-big": props.labelIconBig,
        "icon-label-empty": props.iconLabelEmpty,
        "label-full-width": props.labelFullWidth,
        "label-for-field": props.forField,
    });

    const {
        labelIcon,
        className,
        contentLabel,
        labelRequired,
        labelIconBig,
        iconLabelEmpty,
        labelFullWidth,
        labelStyle,
        forField,
        ...rest
    } = props;

    return (
        <label style={props.labelStyle} {...rest} className={classes}>
            {props.children}
        </label>
    );
};

export default Label;
