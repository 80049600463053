import React, { memo, useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { formatJsonDateTime } from "../../../../../utils/date";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";

import Button from "../../../../Button";
import FilesAndNotes from "./FilesAndNotes";
import QualityControlDescription from "./QualityControlDescription";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import ActionLabelWithDropdown from "../../../../Label/ActionLabelWithDropdown";
import WaitIcon from "../../../../WaitIcon/index";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import { useProgramRights, useResource } from "store/resources/useResource";
import { systemUserRights } from "../../../../../utils/user";
import { confirmQCStatusChange } from ".";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import QCControlsEdit from "./QCControlsEdit";

import "./QualityControlRequestDetails.scss";

const QualityControlRequestDetails = memo((props) => {
    const dispatch = useDispatch();
    const { qcNumber, programNumber, applicationNumber, onDescriptionSave, onAcceptQCRequest, setNotesActive } = props;
    const [qcData, isLoadingQcData] = useResource({
        resourceName: "qc",
        resourceId: qcNumber,
        forced: true,
    });
    const [qcResultList] = useReference("qcresult");
    const isAppLocked = isApplicationLocked({ applicationNumber });
    const [qcStatuses = [], isQcStatusLoading] = useReference(referenceTypes.qcStatus);
    const qcStatusesList = qcStatuses?.map((i) => ({ val: i.val, status: i.display }));
    const [programRights = []] = useProgramRights({ programNumber: props.programNumber });
    const canChangeStatus = !isAppLocked && qcStatusesList.length > 1 && programRights.includes(systemUserRights.VISIONDSM_ADD_QC);
    const [initialValues, setInitialValues] = useState({});
    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
        className: "rebate-details-sidenav-panel",
    });

    useEffect(() => {
        if (qcResultList && qcStatuses && qcData) {
            const initVals = {
                dateAccepted: qcData.dateaccepted,
                dateCompleted: qcData.datecompleted,
                result: qcResultList?.find((result) => result.display === qcData.qcresult)?.val,
                status: qcStatuses?.find((status) => status.display === qcData.status)?.val,
                description: qcData.description,
            };
            setInitialValues(initVals);
        }
    }, [qcData, qcResultList, qcStatuses]);

    const handleChangeQCStatus = useCallback(
        (qcStatus) => {
            if (canChangeStatus && qcStatus) {
                confirmQCStatusChange({
                    qcStatus,
                    applicationNumber,
                    qcData,
                    dispatch,
                });
            }
        },
        [applicationNumber, canChangeStatus, qcData, dispatch]
    );
    const onEditQCControls = useCallback(() => {
        handleOpenSidePanel(
            <QCControlsEdit
                onClose={handleCloseSidePanel}
                resultList={qcResultList}
                statusList={qcStatuses}
                initialValues={initialValues}
                qcNumber={qcNumber}
                applicationNumber={applicationNumber}
            />
        );
    }, [handleOpenSidePanel, handleCloseSidePanel, initialValues, qcStatuses, qcResultList, applicationNumber, qcNumber]);

    return (
        <SideNavContent>
            <SideNavHeader smallHeader title="Edit QC Request" onClose={props.onClose} leadBlockIcon="edit-empty" />
            <SideNavBody noPadding>
                {isLoadingQcData ? (
                    <WaitIcon />
                ) : (
                    <div className="edit-qc-request-form">
                        <div className="flex-row header-values">
                            <div className="attribute">
                                <div className="title">QCID</div>
                                <div className="value">{qcData?.qcid}</div>
                            </div>
                            <div className="attribute">
                                <div className="title">Category</div>
                                <div className="value">{qcData?.qctype}</div>
                            </div>
                            <div className="attribute">
                                <div className="title">Status</div>
                                {isQcStatusLoading && <WaitIcon />}
                                {!isQcStatusLoading && !canChangeStatus && <div className="value">{qcData?.status}</div>}
                                {!isQcStatusLoading && canChangeStatus && (
                                    <ActionLabelWithDropdown
                                        iconWithLabel="shevron-small-down-expand-more"
                                        iconWithLabelRight
                                        mobileHeader="Change QC Status to"
                                        label={qcData?.status}
                                        items={qcStatusesList}
                                        onClick={handleChangeQCStatus}
                                    />
                                )}
                            </div>
                            <div className="attribute">
                                <div className="title">Created</div>
                                <div className="value">{formatJsonDateTime(qcData?.dateentered)}</div>
                            </div>
                            <div className="attribute">
                                <div className="title">Created By</div>
                                <div className="value">{qcData?.username}</div>
                            </div>
                            <div className="attribute">
                                <div className="title">Accepted</div>
                                {!qcData?.dateaccepted && (
                                    <Button disabled={isAppLocked} onClick={onAcceptQCRequest(qcData)}>
                                        Confirm Acceptance
                                    </Button>
                                )}
                                {qcData?.dateaccepted && (
                                    <div className="flex-row">
                                        <div className="value">{formatJsonDateTime(qcData.dateaccepted)}</div>
                                    </div>
                                )}
                            </div>
                            {qcData?.datecompleted && (
                                <div className="attribute">
                                    <div className="title">Date Completed</div>
                                    {qcData?.datecompleted && (
                                        <div className="flex-row">
                                            <div className="value">{formatJsonDateTime(qcData.datecompleted)}</div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {qcData.qcresult && (
                                <div className="attribute">
                                    <div className="title">Result</div>
                                    <div className="value">{qcData.qcresult}</div>
                                </div>
                            )}
                        </div>
                        <div className="flex-column content flex-one">
                            <QualityControlDescription
                                description={qcData?.description}
                                isLocked={isAppLocked || qcData.datecompleted}
                                onDescriptionSave={onDescriptionSave(qcData)}
                            />
                            <FilesAndNotes
                                qc={qcData}
                                resourceId="qc"
                                applicationNumber={applicationNumber}
                                programNumber={programNumber}
                                onDescriptionSave={onDescriptionSave}
                                onAcceptQCRequest={onAcceptQCRequest}
                                setNotesActive={setNotesActive}
                            />
                        </div>
                    </div>
                )}
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onEditQCControls}>Edit</Button>
                <Button onClick={props.onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default QualityControlRequestDetails;
