import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import TextInput from "../../../Input/TextInput";
import IconWithLabel from "../../../Icons/IconWithLabel";
import { modalOpen, modalClose } from "../../../../../store/modal/actions";
import TemplateFileUpload from "../../shared/Program/TemplateFileUpload";
import TemplateFileSelect from "../../shared/Program/TemplateFileSelect";

import "./SelectTemplateFilesWidget.scss";

export const SelectTemplateFilesWidget = memo(({ formRef, utilityNumber, readonly, value, onChange, disabled }) => {
    const dispatch = useDispatch();

    const onUploadEvent = useCallback(() => {
        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Upload Template File",
                    modalIcon: "upload",
                    overlayClassName: "modal-styled",
                    className: "program-form-template-upload-modal modal-sm",
                    children: <TemplateFileUpload dispatch={dispatch} entityId={utilityNumber} onClose={() => dispatch(modalClose())} />,
                    noFooter: true,
                    withScroll: true,
                },
            })
        );
    }, [utilityNumber, dispatch]);

    const onShowTemplateFiles = useCallback(() => {
        const selectedUtilityNumber = formRef.current.state.formData.utilityNumber;

        const onSelect = (file) => {
            onChange(file);
            dispatch(modalClose());
        };

        dispatch(
            modalOpen({
                modalType: "MODAL",
                modalProps: {
                    title: "Available files",
                    overlayClassName: "modal-styled",
                    className: "program-form-template-upload-modal modal-sm",
                    children: (
                        <TemplateFileSelect
                            utilityNumber={utilityNumber || selectedUtilityNumber}
                            onCancel={() => dispatch(modalClose())}
                            onSelect={onSelect}
                        />
                    ),
                    noFooter: true,
                    withScroll: true,
                },
            })
        );
    }, [formRef, utilityNumber, onChange, dispatch]);

    return (
        <>
            <TextInput
                readOnly={readonly}
                disabled={disabled}
                value={isEmpty(value) ? "" : value}
                onChange={(event) => onChange(event.target.value)}
            />
            <div className="select-template-files-actions">
                <IconWithLabel icon="upload" disabled={readonly || disabled} onClick={onUploadEvent}>
                    Upload Template File
                </IconWithLabel>
                <IconWithLabel icon="touch-empty" disabled={readonly || disabled} iconWithLabelRight onClick={onShowTemplateFiles}>
                    Select Template
                </IconWithLabel>
            </div>
        </>
    );
});
