import React, { useCallback, useState, memo } from "react";
import { isEmpty } from "lodash";
import Label from "../../../../../Label";
import IconWrap from "../../../../../Icons";
import TextInput from "../../../../../Input/TextInput";
import Button from "../../../../../Button";
import ButtonGroup from "../../../../../Button/ButtonGroup";

import "./QuantityFriendlyNameWidget.scss";

export const QuantityFriendlyNameWidget = memo(({ value, readonly, onChange, disabled }) => {
    const [friendlyName, setFriendlyName] = useState(value ?? "Quantity");
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleUpdate = useCallback(() => {
        onChange(friendlyName);
        setIsEditing(false);
    }, [friendlyName, onChange]);

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, []);

    return (
        <div className={"quantity-widget" + (isEditing ? " edit-mode" : "")}>
            {isEditing ? (
                <>
                    <TextInput
                        value={friendlyName}
                        onChange={(event) => setFriendlyName(event.target.value)}
                        onEnterKey={handleUpdate}
                        onEscapeKey={handleCancel}
                    />
                    <ButtonGroup transparent>
                        <Button small primary disabled={isEmpty(friendlyName)} onClick={handleUpdate}>
                            Update
                        </Button>
                        <Button small onClick={handleCancel}>
                            Cancel
                        </Button>
                    </ButtonGroup>
                </>
            ) : (
                <>
                    <Label>{value ?? "Quantity"}</Label>
                    <IconWrap disabled={readonly || disabled} icon="edit-empty" title="Change Friendly Name" onClick={handleEdit} />
                </>
            )}
        </div>
    );
});
