import React, { useMemo } from "react";

import IconWrap from "../ui/Icons";
import TagButton from "../ui/Button/TagButton";
import IconHandMade from "../ui/Icons/IconHandMade";
import { createResourcePromise } from "store/resources/useResource";
import { createId } from "./string";
import { isNil } from "lodash";

export const DEFAULT_FOLDER = "FILES";
export const disableDownloadText = "There are no files you are permitted to download";

export const download = ({ blob, fileName }) => {
    if (blob && fileName) {
        // IE11 support
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement("a");
            link.setAttribute("href", window.URL.createObjectURL(blob));
            link.setAttribute("download", getValidFileName({ fileName }));
            link.click();
        }
    }
};

export const exportPdf = async ({ fileName, fileContent, onComplete }) => {
    try {
        const fileData = await createResourcePromise({
            resourceName: "generatePdf",
            key: createId(),
            body: {
                fileName,
                content: fileContent,
            },
            onComplete,
        });

        download(fileData);
    } catch {}
};

export const useAllowAccessCheck = (rows) => {
    return useMemo(() => {
        if (rows?.some((e) => isNil(e.allowAccess))) {
            return false;
        }
        return !rows?.some((e) => e.allowAccess);
    }, [rows]);
};

export const getValidFileName = ({ fileName, fileExtension, spaceDelimeter }) => {
    let name = (fileName || "file").trim();

    if (!fileName && !fileExtension) {
        name = name + ".txt";
    } else if (fileExtension) {
        name = name + "." + fileExtension.trim();
    }

    name = name.replace(/(["'])/g, "");

    return spaceDelimeter ? name.replace(/ /g, spaceDelimeter) : name;
};

export const getFileExtension = (file) => {
    if (typeof file === "string") {
        return file.split(".").pop();
    } else {
        return "";
    }
};

export const MAX_FILE_SIZE = 1024 * 1024 * 30; // 30MB
export const MAX_FILE_SIZE_TEXT = "30MB";

export const FileSecurity = {
    PUBLIC: "149",
    PRIVATE: "150",
};

export const filesGridColumnCellContent = ({ gridColumnKeys }) => {
    return {
        [gridColumnKeys.security]: (column, row) =>
            row[column.key]?.toLowerCase() === "public" || row[column.key] === FileSecurity.PUBLIC ? (
                <IconWrap title="Public" icon="add-group-filled" />
            ) : (
                <IconWrap title="Private" icon="user-identity-person-filled" />
            ),
        [gridColumnKeys.fileName]: (column, row) => (
            <>
                <IconHandMade
                    className="document-icon-grid"
                    iconDocumentHandMade
                    title={getFileExtension(row[gridColumnKeys.fileName]).substring(0, 4)}
                ></IconHandMade>
                <div className={"file-name-with-type-icon"}>{row[column.key]}</div>
            </>
        ),
        [gridColumnKeys.tag]: (column, row) =>
            row[column.key] && (
                <div className="flex-row">
                    <TagButton readonly>{row[column.key]}</TagButton>
                </div>
            ),
    };
};

/**
 * Convert blob to base64 data URL
 *
 * @param {object} blob - blob object
 * @param {function} callback - Callback with resulting data URL string
 */
export const blobToBase64 = (blob, callback) => {
    const reader = new FileReader();
    reader.onload = function () {
        callback(reader.result);
    };
    reader.readAsDataURL(blob);
};

/**
 * Convert blob to ArrayBuffer
 *
 * @param {object} blob - blob object
 * @param {function} callback - Callback with resulting data array
 */
export const blobToArrayBuffer = (blob, callback) => {
    const reader = new FileReader();
    reader.onload = function () {
        callback(reader.result);
    };
    reader.readAsArrayBuffer(blob);
};

/**
 * Get contents of blob as a text string
 *
 * @param {object} blob - blob object
 * @param {function} callback - Callback with resulting text string
 */
export const blobToText = (blob, callback) => {
    const reader = new FileReader();
    reader.onload = function () {
        callback(reader.result);
    };
    reader.readAsText(blob);
};
