import React from "react";
import { BorderCornerSize } from "./types";
import TextLabel from "components/ui/Label/TextLabel";
import { CornerBox } from "./CornerBox";
import { BorderCornerProps } from "./CornerSideBySideProperty";

export const BorderCorner = (props: BorderCornerProps) => {
    const { side, value, onSelect } = props;

    return (
        <div className="border-corner flex-row justify-space-between fill-width align-center">
            <TextLabel>{side.replace("-", " ")}</TextLabel>
            <div className="corner-boxes flex-one-in-row flex-row">
                <CornerBox
                    side={side}
                    size="size-1"
                    isSelected={value === BorderCornerSize.Size1}
                    onClick={() => onSelect(BorderCornerSize.Size1)}
                />
                <CornerBox
                    side={side}
                    size="size-2"
                    isSelected={value === BorderCornerSize.Size2}
                    onClick={() => onSelect(BorderCornerSize.Size2)}
                />
                <CornerBox
                    side={side}
                    size="size-3"
                    isSelected={value === BorderCornerSize.Size3}
                    onClick={() => onSelect(BorderCornerSize.Size3)}
                />
                <CornerBox
                    side={side}
                    size="size-4"
                    isSelected={value === BorderCornerSize.Size4}
                    onClick={() => onSelect(BorderCornerSize.Size4)}
                />
                <CornerBox
                    side={side}
                    size="size-5"
                    isSelected={value === BorderCornerSize.Size5}
                    onClick={() => onSelect(BorderCornerSize.Size5)}
                />
            </div>
        </div>
    );
};
