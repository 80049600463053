import React, { memo, useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce, isNil } from "lodash";
import { getFormData, clearFormData } from "store/forms/actions";
import WaitIcon from "../../../../WaitIcon";
import JsonSchemaFormWithConditionals from "../../../../Form/JsonSchema/JsonSchemaFormWithConditionals";
import {
    setInitialValuesByFieldNumber,
    removeDisabledFields,
    getApplicationFormSubmitValues,
    transformApplicationFormErrors,
    addNonExistingValueOptionsToSchema,
    isFormDataChanged,
} from "../../../../../utils/form";
import { getApplicationItemsResourceParams } from "store/configureResources";
import { updateResource } from "store/resources/actions";
import { ErrorMessage } from "components/ui/Message";
import { useCalculationStatusCheck } from "components/views/ProjectView/utils";

import "./ApplicationForm.scss";

const ApplicationForm = memo(
    ({
        formId,
        programNumber,
        applicationNumber,
        applicationFormId,
        visibleFieldIds,
        taskNumber,
        loading,
        editMode,
        onSubmitSuccess,
        onSubmitStatusSet,
        onSubmitDisabledSet,
        onCancel,
        hasStickyBottomPanel,
        formTitle,
        noActions,
        formRef,
    }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [submitDisabled, setSubmitDisabled] = useState(isNil(taskNumber)); // Disable save button if not opened from task workflow

        const dispatch = useDispatch();

        const formDetailsKey = `${applicationNumber}-${applicationFormId}`;
        const formDetailsFromStore = useSelector((state) => state.resources.applicationFormDetails.itemsById[formDetailsKey]);
        const formDetails = useMemo(() => formDetailsFromStore ?? {}, [formDetailsFromStore]);

        const checkCalculationStatus = useCalculationStatusCheck({
            applicationNumber,
        });

        const configuration = useMemo(() => formDetails.formConfiguration ?? {}, [formDetails.formConfiguration]);
        const formValues = useMemo(() => formDetails?.formDetails?.fields ?? [], [formDetails.formDetails]);

        const { schema, uiSchema, rules, initialValues, formFieldCount } = useMemo(() => {
            const formConfig = removeDisabledFields({
                ...configuration,
                visibleFieldIds,
            });
            const currentData = getFormData({ formId });

            const initialValues = currentData
                ? currentData
                : setInitialValuesByFieldNumber(formConfig.schema, formConfig.uiSchema, formValues);

            const updatedSchema = addNonExistingValueOptionsToSchema({
                schema: formConfig.schema,
                initialValues,
            });

            return { ...formConfig, schema: updatedSchema, initialValues };
        }, [formId, configuration, formValues, visibleFieldIds]);

        const formNotLoaded = !formDetails;
        const formFieldsNotFound = visibleFieldIds?.length > 0 && formFieldCount === 0;

        const formContext = useMemo(
            () => ({
                programNumber,
                applicationNumber,
                localizeDateValues: true,
                datePickerOverlap: !isNil(taskNumber),
            }),
            [programNumber, applicationNumber, taskNumber]
        );

        const onFormSubmit = useCallback(
            (formData) => {
                setIsSubmitting(true);

                let applicationItemList = getApplicationFormSubmitValues(formData, formDetails.formDetails?.fields);

                dispatch(
                    updateResource({
                        ...getApplicationItemsResourceParams({
                            applicationNumber,
                        }),
                        query: {
                            taskNumber,
                        },
                        body: {
                            applicationItemList,
                        },
                        showSuccessNotification: isNil(taskNumber), // Show notification if not called from workflow
                        onSuccess: () => {
                            checkCalculationStatus().then(() => {
                                dispatch(clearFormData());
                                onSubmitSuccess?.();
                            });
                        },
                        onError: () => {
                            setIsSubmitting(false);
                            onSubmitStatusSet?.(false);
                        },
                    })
                );
            },
            [applicationNumber, taskNumber, formDetails, onSubmitSuccess, onSubmitStatusSet, checkCalculationStatus, dispatch]
        );

        const onFormCancel = useCallback(() => {
            dispatch(clearFormData());
            onCancel();
        }, [onCancel, dispatch]);

        const onFormChange = useCallback(() => {
            debouncedOnChange({ formId, initialValues, setSubmitDisabled, onSubmitDisabledSet });
        }, [initialValues, formId, onSubmitDisabledSet]);

        const transformErrors = useCallback(
            (errors) => {
                return transformApplicationFormErrors(errors, schema, uiSchema, formContext);
            },
            [schema, uiSchema, formContext]
        );

        const handleError = useCallback(() => {
            onSubmitStatusSet?.(false);
        }, [onSubmitStatusSet]);

        const formLabel = useMemo(
            () =>
                isNil(taskNumber) ? (
                    <div className="form-name flex-row flex-wrap">
                        <span>Form:</span>
                        <b>{formTitle}</b>
                    </div>
                ) : null,
            [formTitle, taskNumber]
        );

        if (loading) {
            return <WaitIcon />;
        }

        if (formNotLoaded) {
            return <ErrorMessage spaceAround>Form not loaded</ErrorMessage>;
        }

        if (formFieldsNotFound) {
            return <ErrorMessage spaceAround>Form fields were not found in form</ErrorMessage>;
        }

        return (
            <div className={"application-form" + (hasStickyBottomPanel ? " has-sticky-bottom-panel" : "")}>
                {schema && uiSchema && (
                    <JsonSchemaFormWithConditionals
                        formId={formId}
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        rules={rules}
                        initialValues={initialValues}
                        fieldKey="af:fieldNumber"
                        disabled={isSubmitting}
                        readOnly={!editMode}
                        noActions={noActions || !editMode}
                        noReset
                        withCancel
                        submitText={isSubmitting ? "Saving..." : "Save"}
                        submitDisabled={submitDisabled}
                        onSubmit={onFormSubmit}
                        onCancel={onFormCancel}
                        onChange={onFormChange}
                        onError={handleError}
                        transformErrors={transformErrors}
                        selectListsWithPopper
                        emptyItemInSelectLists
                        formContext={formContext}
                        otherActions={formLabel}
                    />
                )}
            </div>
        );
    }
);

export default ApplicationForm;

// Debounce form onChange callback
const debouncedOnChange = debounce(({ formId, initialValues, setSubmitDisabled, onSubmitDisabledSet }) => {
    const currentValues = getFormData({ formId }) ?? {};
    const isChanged = isFormDataChanged({
        initialValues,
        currentValues,
        defaultValues: {},
    });

    setSubmitDisabled(!isChanged);
    onSubmitDisabledSet?.(!isChanged);
}, 500);
