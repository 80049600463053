import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";

import { lookupTableColumnKeys } from "../../../../../views/configureGrids";
import { getData } from "../../../../../../store/dataGrid/actions";
import { uploadLookupItems, downloadLookupItems } from "../../../../../utils/CSV";

import LookupTableControls from "./LookupTableControls";
import LookupTableItems from "./LookupTableItems";
import LookupTableItemDetailsForm from "./LookupTableItemDetailsForm";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import Button from "../../../../Button";

import "./style.scss";

const LookupTableView = memo(({ utilityNumber, lookupTableNumber, lookupTableName, onClose, programNumber }) => {
    const dispatch = useDispatch();

    const [showAddForm, setShowAddForm] = useState(false);

    const gridRefresh = () => {
        dispatch(
            getData({
                dataGridId: `${utilityNumber}-${lookupTableNumber}-lookup-items`,
            })
        );
    };

    const onDownloadLookupItems = () => {
        if (lookupTableNumber) {
            downloadLookupItems({ utilityNumber, lookupTableNumber });
        }
    };

    const onUploadLookupItems = () => {
        uploadLookupItems({ lookupTableNumber }).then(() => {
            gridRefresh();
        });
    };

    return (
        <SideNavContent className="calculations-form">
            <SideNavHeader
                title={"Lookup Table of "}
                nameInBold={lookupTableName}
                leadBlockIcon={"view-module-empty"}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody noPadding>
                <div className="lookup-table flex-column no-scroll">
                    <LookupTableControls
                        programNumber={programNumber}
                        onAdd={() => setShowAddForm(!showAddForm)}
                        onDownload={onDownloadLookupItems}
                        onUpload={onUploadLookupItems}
                        showAddForm={showAddForm}
                    />
                    {showAddForm && (
                        <div className="add-lookup-item-form">
                            <LookupTableItemDetailsForm
                                lookupTableNumber={lookupTableNumber}
                                gridRefresh={gridRefresh}
                                onClose={() => setShowAddForm(false)}
                            />
                        </div>
                    )}
                    <LookupTableItems
                        programNumber={programNumber}
                        gridId={`${utilityNumber}-${lookupTableNumber}-lookup-items`}
                        filter={`${lookupTableColumnKeys.lookupTableNumber}=${lookupTableNumber}`}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default LookupTableView;
