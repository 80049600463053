import { getPortalTemplatesResourceParams } from "store/configureResources";
import { useResource } from "store/resources/useResource";
import { convertToObject } from "components/utils/string";
import { set } from "lodash";
import { transformUtilityStandardPages } from "./page";
import { PortalTemplateConfiguration } from "../types";

export const usePortalTemplates = ({ utilityNumber, forced = false }: UsePortalTemplatesProps) => {
    const transform = (data: UtilityPortalTemplatesResponse) => {
        return (data?.portalTemplateList ?? []).map((item) => {
            const template = {
                ...item,
                configuration: convertToObject(item?.configuration ?? "") as PortalTemplateConfiguration,
            };

            // Ensure utility pages are correct
            const updatedUtilityPages = transformUtilityStandardPages(template.configuration?.content?.pages ?? []);
            set(template.configuration, "content.pages", updatedUtilityPages);

            return template;
        });
    };

    return useResource({
        ...getPortalTemplatesResourceParams({ utilityNumber }),
        forced,
        transform,
    });
};

interface UsePortalTemplatesProps {
    utilityNumber: string;
    forced: boolean;
}

interface UtilityPortalTemplatesResponse {
    portalTemplateList: {
        portalTemplateNumber: string;
        name: string;
        notes?: string;
        configuration?: string;
        thumbnail?: string;
        inUseOnPrograms: {
            programNumber: string;
            programName: string;
        }[];
    }[];
}
