import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import { getData } from "../../../../../../store/dataGrid/actions";
import { qcNotesGridColumnKeys } from "../../../../../views/configureGrids";
import { getTotalRecords, mapGridRowToObject } from "components/utils/datagrid";
import { exportDatagridToCSV } from "../../../../../utils/CSV";
import DataGrid from "../../../../DataGrid";
import IconWithLabel from "../../../../Icons/IconWithLabel";
import IconWrap from "../../../../Icons";
import NotesEditForm from "../NotesPanel/NotesEditForm";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import NotePreview from "../NotesPanel/NotePreview";
import { getResource } from "../../../../../../store/resources/actions";
import "./FilesAndNotes.scss";
import QualityControlRequestDetails from "./QualityControlRequestDetails";
const customRowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit",
    },
];

const notesGridSort = [
    {
        field: qcNotesGridColumnKeys.dateEntered,
        dir: "desc",
    },
];

const NotesTab = memo(
    ({ applicationNumber, resourceId, qcNumber, setNoteCount, hidden, programNumber, onAcceptQCRequest, onDescriptionSave }) => {
        const dispatch = useDispatch();

        const dataGridId = `${applicationNumber}-${resourceId}-notes-grid`;

        const onSaved = () => {
            dispatch(getData({ dataGridId }));
            handleOpenSidePanel(
                <QualityControlRequestDetails
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    qcNumber={qcNumber}
                    onClose={() => handleCloseSidePanel()}
                    onDescriptionSave={onDescriptionSave}
                    onAcceptQCRequest={onAcceptQCRequest}
                    setNotesActive={true}
                />
            );
            dispatch(
                getResource({
                    resourceName: "qcWidget",
                    key: applicationNumber,
                    path: {
                        appId: applicationNumber,
                    },
                })
            );
        };

        const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({ size: sideNavSize.staticLarge });
        const onRowAction = (action) => {
            const { name, dataItem } = action;

            switch (name) {
                case "edit":
                    document.activeElement.blur();
                    const note = mapGridRowToObject(qcNotesGridColumnKeys, dataItem);
                    handleOpenSidePanel(
                        <NotesEditForm
                            note={note}
                            applicationNumber={applicationNumber}
                            qcNumber={qcNumber}
                            refType={179}
                            resourceName="updateQCNote"
                            onSaved={onSaved}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;

                default:
                    break;
            }
        };

        const onAdd = () => {
            handleOpenSidePanel(
                <NotesEditForm
                    applicationNumber={applicationNumber}
                    qcNumber={qcNumber}
                    refType={179}
                    resourceName="qcNote"
                    onSaved={onSaved}
                    onClose={handleCloseSidePanel}
                />
            );
        };

        const onExport = () => {
            exportDatagridToCSV({
                dataGridId: dataGridId,
                fileName: "notes",
                fileNamePostfix: resourceId,
            });
        };

        return (
            <div hidden={hidden}>
                <div className="files-actions">
                    <IconWithLabel icon={"plus"} onClick={onAdd}>
                        Add Note
                    </IconWithLabel>
                    <IconWithLabel withHandMadeIcon onClick={onExport}>
                        Export CSV
                    </IconWithLabel>
                </div>
                <NotesGrid
                    applicationNumber={applicationNumber}
                    gridId={dataGridId}
                    filter={`${qcNotesGridColumnKeys.refId}=${qcNumber}`}
                    sort={notesGridSort}
                    setNoteCount={setNoteCount}
                    onRowAction={onRowAction}
                    qcNumber={qcNumber}
                    columnCellContent={{
                        [qcNotesGridColumnKeys.security]: (column, row) =>
                            row[qcNotesGridColumnKeys.security].toLowerCase() === "public" ? (
                                <IconWrap title="Public" icon="add-group-filled" />
                            ) : (
                                <IconWrap title="Private" icon="user-identity-person-filled" />
                            ),
                        [qcNotesGridColumnKeys.note]: (column, row) => <NotePreview>{row[qcNotesGridColumnKeys.note]}</NotePreview>,
                    }}
                />
            </div>
        );
    }
);

const NotesGrid = withDataGrid("qcNotes", ({ gridId, dataGridConfig, setNoteCount, onRowAction, columnCellContent }) => {
    useEffect(() => {
        setNoteCount(getTotalRecords(dataGridConfig));
    }, [dataGridConfig, setNoteCount]);

    return (
        <DataGrid
            className="notes-grid"
            name={gridId}
            config={dataGridConfig}
            customRowActions={customRowActions}
            onRowAction={onRowAction}
            columnCellContent={columnCellContent}
        />
    );
});

export { NotesTab };
