import cn from "classnames";
import TextLabel from "components/ui/Label/TextLabel";
import { isEmpty } from "lodash";
import React, { useCallback, useRef, useLayoutEffect } from "react";
import { PropertyListItem } from "./PropertyListItem";
import "./InputRangeProperty.scss";

export const InputRangeProperty = (props) => {
    const { className, id, onChange, title, value, borderTop, borderBottom, minValue } = props;

    const onSelect = useCallback(
        (event) => {
            onChange(id, event.target.value);
        },
        [id, onChange]
    );

    const onSelectPercentage = useCallback(
        (event) => {
            const re = /^[0-9\b]+$/;
            if (event.target.value === "" || re.test(event.target.value)) {
                setTimeout(() => {
                    setBackgroundSize(inputRef.current);
                }, 0);
                onChange(id, event.target.value);
            }
        },
        [id, onChange]
    );
    const inputRef = useRef();

    const onBlur = useCallback(() => {
        if (isEmpty(String(value))) {
            setTimeout(() => {
                setBackgroundSize(inputRef.current);
            }, 0);
            onChange(id, 100);
        }
    }, [id, onChange, value]);

    useLayoutEffect(() => {
        const input = inputRef.current;

        const updateBackground = () => setBackgroundSize(input);

        updateBackground();
        input?.addEventListener("input", updateBackground);

        return () => input?.removeEventListener("input", updateBackground);
    }, [value]);

    const maxValue = 100;
    const newValue = (value > maxValue ? maxValue : value) ?? maxValue;
    const maxLength = String(maxValue).length;

    return (
        <PropertyListItem className={cn("property-list-item--input-range", className)} borderTop={borderTop} borderBottom={borderBottom}>
            <div className="property-list-item-inner flex-row align-center">
                {title && <TextLabel>{title}:</TextLabel>}
                <input
                    ref={inputRef}
                    className="input-field-slider flex-one"
                    type="range"
                    min={minValue ?? 0}
                    max={maxValue}
                    value={isEmpty(String(newValue)) ? 0 : newValue}
                    onChange={onSelect}
                />
                <div className="flex-row justify-end align-center">
                    <input
                        className="percentage-input-field"
                        onChange={onSelectPercentage}
                        onBlur={onBlur}
                        value={newValue}
                        min={minValue ?? 0}
                        max={maxValue}
                        maxLength={maxLength}
                    ></input>
                    <span className="percentage-value">%</span>
                </div>
            </div>
        </PropertyListItem>
    );
};

function getBackgroundSize(input) {
    const min = +input.min || 0;
    const max = +input.max || 100;
    const value = +input.value;

    const size = ((value - min) / (max - min)) * 100;

    return size;
}

function setBackgroundSize(input) {
    input?.style.setProperty("--background-size", `${getBackgroundSize(input)}%`);
}
