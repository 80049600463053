import React, { memo, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";

import { openProjectTab } from "../../utils/window";
import { openProgramTab } from "store/window/openTabActions";
import { getColumnKeys } from "../../views/configureGrids";
import { setWorkqueueGridSelectedRows } from "../../../store/workcenter/actions";

import DataGrid from "../../ui/DataGrid";
import WaitIcon from "../../ui/WaitIcon";
import ActionLabel from "../../ui/Label/ActionLabel";
import { WorkCenterContext } from ".";

const WorkqueueGrid = memo(({ showAssignments }) => {
    const { instanceId } = useContext(WorkCenterContext);
    const dispatch = useDispatch();
    const dataGridId = useSelector((state) => state.workcenter[instanceId].workqueueGrid);
    const dataGridInstanceId = useSelector((state) => state.workcenter[instanceId].workqueueGridId);
    const dataGridConfig = useSelector((state) => state.dataGrid[dataGridInstanceId]);

    const onAppOpen = useCallback((applicationNumber) => {
        openProjectTab({ applicationNumber });
    }, []);

    const onProgramOpen = useCallback(
        (programNumber) => {
            dispatch(openProgramTab({ programNumber }));
        },
        [dispatch]
    );
    const onProgramOpenApplicationStatus = (programNumber, clickedFilter) => {
        dispatch(
            openProgramTab({
                programNumber: programNumber,
                activePanel: "program-applications",
                applicationStatus: clickedFilter,
            })
        );
    };

    const onRowSelectChange = useCallback(
        (selectedRows) => {
            dispatch(
                setWorkqueueGridSelectedRows({
                    instanceId,
                    workqueueGridSelectedRows: selectedRows,
                })
            );
        },
        [instanceId, dispatch]
    );

    const onPageChanged = useCallback(
        (event) => {
            const selectedRowsInPage = [];
            dispatch(
                setWorkqueueGridSelectedRows({
                    instanceId,
                    workqueueGridSelectedRows: selectedRowsInPage,
                })
            );
        },
        [instanceId, dispatch]
    );

    const onFilterChanged = useCallback(
        (event) => {
            const selectedRowsInPage = [];
            dispatch(
                setWorkqueueGridSelectedRows({
                    instanceId,
                    workqueueGridSelectedRows: selectedRowsInPage,
                })
            );
        },
        [instanceId, dispatch]
    );

    const columnKeys = getColumnKeys(dataGridId);

    if (isNil(dataGridConfig)) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            canSelectRow={showAssignments}
            customRowActions
            onRowSelectChange={onRowSelectChange}
            onPageChanged={onPageChanged}
            onFilterChanged={onFilterChanged}
            name={dataGridInstanceId}
            config={dataGridConfig}
            columnCellContent={{
                [columnKeys.appId]: (column, row) => (
                    <ActionLabel onClick={() => onAppOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                ),
                [columnKeys.program]: (column, row) => {
                    if (row[columnKeys.progId]) {
                        return <ActionLabel onClick={() => onProgramOpen(row[columnKeys.progId])}>{row[column.key]}</ActionLabel>;
                    }

                    return row[column.key];
                },
                [columnKeys.project]: (column, row) => {
                    if (row[columnKeys.appId]) {
                        return <ActionLabel onClick={() => onAppOpen(row[columnKeys.appId])}>{row[column.key]}</ActionLabel>;
                    }

                    return row[column.key];
                },
                [columnKeys.status]: (column, row) => {
                    if (row[columnKeys.progId]) {
                        return (
                            <ActionLabel
                                onClick={() => {
                                    onProgramOpenApplicationStatus(row[columnKeys.progId], row[column.key]);
                                }}
                            >
                                {row[column.key]}
                            </ActionLabel>
                        );
                    }

                    return row[column.key];
                },
            }}
        />
    );
});

export default WorkqueueGrid;
