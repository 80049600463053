import React, { useCallback, useContext, useEffect } from "react";
import { SectionHeader } from "../../SectionHeader";
import { RowLayoutTypes } from "./RowLayoutTypes";
import { openConfirmModal } from "components/ui/Modal/utils";
import { RowLayout } from ".";
import { PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";

import "./SelectRowLayout.scss";
import { isInViewport } from "components/utils/dom";

const SelectRowLayout = (props) => {
    const { id, value, parentTitle, onChange, onClose } = props;

    const { sectionTitle, sectionIcon } = useContext(PortalBuilderPropertiesContext);
    const currentLayoutColumnCount = RowLayoutTypes[value]?.columns?.length ?? 0;

    const subTitle = (
        <span>
            Select <strong>{parentTitle}</strong> layout
        </span>
    );

    const onSelect = useCallback(
        (layoutId) => {
            const columnCount = RowLayoutTypes[layoutId]?.columns?.length ?? 0;

            const changeLayout = () => {
                onChange(id, layoutId);
                onClose();
            };

            if (columnCount < currentLayoutColumnCount) {
                openConfirmModal({
                    title: "Change Row Layout",
                    modalIcon: "touch-empty",
                    message: "Sections that do not fit in the selected layout will be removed. Are you sure?",
                    onConfirm: () => {
                        changeLayout();
                    },
                });
            } else {
                changeLayout();
            }
        },
        [id, onChange, onClose, currentLayoutColumnCount]
    );

    return (
        <div className="portal-builder-properties-section flex-column fill-width fill-height">
            <SectionHeader Icon={sectionIcon} title={sectionTitle} subtitle={subTitle} underline onGoBack={onClose} />
            <LayoutList onSelect={onSelect} selectedLayout={value} />
        </div>
    );
};

const isColumnSelectable = () => false;

const LayoutList = ({ onSelect, selectedLayout }) => {
    // Scroll to active layout on load.
    useEffect(() => {
        const element = document.getElementById(selectedLayout);
        if (element && !isInViewport(element)) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [selectedLayout]);

    return (
        <div className="portal-row-layout-list flex-column with-scroll">
            {Array(11)
                .fill(0)
                .map((_, index) => {
                    const layoutId = `layout${index + 1}`;
                    const layout = RowLayoutTypes[layoutId];
                    return (
                        <RowLayout
                            key={index}
                            id={layoutId}
                            isSelected={layoutId === selectedLayout}
                            layout={layout}
                            isColumnSelectable={isColumnSelectable}
                            onClick={() => onSelect(layoutId)}
                            layoutTooltip={"Select Layout"}
                        />
                    );
                })}
        </div>
    );
};

export default SelectRowLayout;
