import { isNil } from "lodash";
import { store } from "../../../../../../store/configureStore";
import { getData } from "../../../../../../store/dataGrid/actions";
import { setCalculationAssociations } from "../../../../../../store/associations/actions";
import { updateResource } from "../../../../../../store/resources/actions";
import { openAssignmentModal } from "../../../../AssignmentSelector/utils";
import { getAttributesSubmitData } from "components/utils/attributes";

export const getEventsGridId = ({ programNumber }) => {
    return `${programNumber}-events-grid`;
};

export const getEventCategoriesGridId = ({ programNumber }) => {
    return `${programNumber}-event-categories-grid`;
};

export const onEventsGridRefresh = ({ programNumber }) => {
    const dataGridId = getEventsGridId({ programNumber });

    store.dispatch(getData({ dataGridId }));
};

export const getEventKey = ({ programNumber, eventNumber }) => {
    return eventNumber || `${programNumber}-event-new`;
};

export const openCalculationAssignmentModal = ({ utilityNumber, programNumber, eventNumber, selectedItems, withSelect = false }) => {
    const resourceDataPath = "data.calculationList";
    const title = "Event Calculation Assignment";
    const modalIcon = "hub-empty";
    const submitButtonText = withSelect ? "Apply" : "Submit";
    const idKey = "calculationNumber";
    const nameKey = "calculationName";

    const resourceGetParams = {
        resourceName: "programEventCalculations",
        key: `${programNumber}-${eventNumber}`,
        path: {
            programNumber,
        },
        query: {
            eventNumber,
        },
    };

    const onSelect = (calculationAssociations) => {
        if (withSelect) {
            store.dispatch(
                setCalculationAssociations({
                    key: getEventKey({ programNumber, eventNumber }),
                    calculationAssociations,
                })
            );

            onEventsGridRefresh({ programNumber });

            return;
        }

        const calculationNumbers = calculationAssociations.map((r) => r.calculationNumber);

        store.dispatch(
            updateResource({
                resourceName: "calculationEventAssignments",
                query: {
                    actionItem: "REPLACE",
                    scope: "EVENT",
                },
                body: {
                    calculationNumber: calculationNumbers,
                    eventNumber: [eventNumber],
                },
                onSuccess: () => onEventsGridRefresh({ programNumber }),
            })
        );
    };

    openAssignmentModal({
        resourceGetParams,
        resourceDataPath,
        title,
        modalIcon,
        submitButtonText,
        idKey,
        nameKey,
        selectedItems,
        onSelect,
    });
};

export const getSubmitData = ({ resource, formData, attributesList, calculationAssociations }) => {
    const attributes = getAttributesSubmitData({
        attributeNumberKey: "eventAttrTypeNumber",
        attributes: attributesList,
        resource,
    });

    return {
        ...formData,
        calculationAssociations,
        attributes,
    };
};

export const isItemChanged = ({ resource, submitData }) => {
    let isChanged = false;

    if (isNil(resource)) {
        return true;
    }

    ["name", "categoryNumber", "statusID"].forEach((key) => {
        if (submitData[key] && resource[key] !== submitData[key]) {
            isChanged = true;
        }
    });

    // attributes
    if (!isChanged) {
        isChanged = submitData.attributes.length > 0;
    }

    // calculationAssociations
    if (!isChanged) {
        isChanged = submitData.calculationAssociations.length !== resource.calculationAssociations.length;
    }

    if (!isChanged) {
        isChanged =
            submitData.calculationAssociations.filter(
                (ca) => resource.calculationAssociations.filter((rca) => rca.calculationNumber === ca.calculationNumber).length === 0
            ).length > 0;
    }

    return isChanged;
};
