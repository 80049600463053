import React, { useContext, memo } from "react";

import { DocumentQueueGridContext } from "../../../context/DocumentQueueGridContext";
import { EntryRow } from "../../../shared/Grid/EntryRow";
import { Document } from "./Document";

import "./Program.scss";

export const Program = memo(
    ({
        progid,
        program,
        total,
        loadingProgramNumbers,
        expandedPrograms,
        isBatchFilled,
        isSelectionDisabled,
        onClickProgramRow,
        onSelectProgram,
    }) => {
        const { queueDocuments, selectedProgramNumbers, selectedProgramDocumentNumbers, isBatchProcessing } =
            useContext(DocumentQueueGridContext);

        return (
            <EntryRow
                documentComponent={Document}
                entryNumber={progid}
                selectedEntryNumbers={selectedProgramNumbers}
                loadingEntryNumbers={loadingProgramNumbers}
                expandedEntries={expandedPrograms}
                documents={queueDocuments}
                selectedEntryDocumentsNumbers={selectedProgramDocumentNumbers}
                isBatchFilled={isBatchFilled}
                isBatchProcessing={isBatchProcessing}
                isSelectionDisabled={isSelectionDisabled}
                onClick={onClickProgramRow}
                onSelect={onSelectProgram}
                total={total}
            >
                <div className="application-document-queue__dq-grid-program flex-row flex-one align-center">
                    <div className="flex-one">{program}</div>
                    <span>({total})</span>
                </div>
            </EntryRow>
        );
    }
);
