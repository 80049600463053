import React, { useState, useEffect, useCallback, useContext } from "react";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import FormBuilder from "../../ui/FormBuilder";
import ErrorBoundary from "../../utils/ErrorBoundary";
import NothingFoundBlock from "../../ui/NothingFoundBlock";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import { initPage, initRebuiltPage, revertPageChanges } from "../../../store/formBuilder/actions";
import { useDispatch } from "react-redux";
import { openRevertChangesModal } from "../../ui/Modal/utils";
import { copyToClipboard } from "components/utils/string";
import Separator from "components/ui/Separator";
import { ApplicationFormPagesContext } from ".";
import { getPage, rebuildPage } from "store/pages/actions";
import { modalClose, modalOpen } from "store/modal/actions";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

const ApplicationFormPageEditor = ({
    page,
    onSave,
    isSubmitting,
    isNewPage,
    keepInitializedPage,
    onHideDisabledPages,
    hideDisabledPages,
}) => {
    const dispatch = useDispatch();
    const [isConfigurationError, setIsConfigurationError] = useState(false);

    const { programNumber, formNumber } = useContext(ApplicationFormPagesContext);
    const instanceId = page.number;
    const hasConfiguration = !isEmpty(page.configuration);
    const isError = isConfigurationError || !hasConfiguration;

    const errorMessage = isConfigurationError ? `Page "${page.name}" configuration error` : `Page "${page.name}" configuration not found`;

    const initFormBuilder = useCallback(() => {
        if (hasConfiguration) {
            dispatch(
                initPage({
                    instanceId,
                    ...page.configuration,
                    isNewPage,
                    keepInitializedPage,
                })
            );
        }
    }, [instanceId, page.configuration, hasConfiguration, isNewPage, keepInitializedPage, dispatch]);

    const onRevert = useCallback(() => {
        const message = (
            <>
                <p>
                    All changes for page <strong>{page.name}</strong> will be lost.
                </p>
                <p>Are you sure?</p>
            </>
        );

        openRevertChangesModal({
            message,
            onConfirm: () => {
                dispatch(revertPageChanges({ instanceId }));
            },
        });
    }, [page.name, dispatch, instanceId]);

    const onError = useCallback(() => {
        setIsConfigurationError(true);
    }, []);

    const onCopyToClipboard = useCallback(() => {
        copyToClipboard(JSON.stringify(page.configuration), () => {
            toast.success("Copied to clipboard");
        });
    }, [page]);

    const onRebuildConfiguartionSuccess = () => {
        dispatch(
            getPage({
                pageNumber: page.number,
                programNumber,
                formNumber,
                refresh: true,
                onSuccess: (page) => {
                    dispatch(modalClose());
                    dispatch(initRebuiltPage({ page, programNumber, instanceId }));
                },
            })
        );
    };

    useEffect(() => {
        setIsConfigurationError(false);
    }, [page]);

    useEffect(() => {
        initFormBuilder();
    }, [initFormBuilder]);

    const onRebuildConfiguration = () => {
        dispatch(
            modalOpen({
                modalType: "CONFIRM",
                modalProps: {
                    title: "Rebuild Form Page Configuration",
                    modalIcon: "repeat",
                    overlayClassName: "modal-styled",
                    className: "modal-sm",
                    footerContentCenter: true,
                    content:
                        "Warning: Rebuilding may overwrite version 5 configuration values. Are you sure you want to rebuild this form page configuration?",
                    onConfirm: () => {
                        dispatch(
                            modalOpen({
                                modalType: "WAITING_MODAL",
                                modalProps: {
                                    title: "Rebuilding form page configuration...",
                                },
                            })
                        );
                        dispatch(
                            rebuildPage({
                                pageNumber: page.number,
                                onSuccess: onRebuildConfiguartionSuccess,
                                onError: () => {
                                    dispatch(modalClose());
                                },
                            })
                        );
                    },
                },
            })
        );
    };
    return (
        <div className={"form-page-editor flex-one-in-column with-scroll" + (isError ? " form-error" : "")}>
            <div className="main-grid-wrap responsive fill-height">
                {isError ? (
                    <NothingFoundBlock nothingFoundBlockError icon="circle-exclamation-empty" title={errorMessage}>
                        {isConfigurationError && (
                            <>
                                <Separator />
                                <IconWithLabel icon="layers-empty" onClick={onCopyToClipboard}>
                                    Copy configuration to clipboard
                                </IconWithLabel>
                            </>
                        )}
                        {hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_SYSTEM]) && (
                            <>
                                <Separator />
                                <IconWithLabel icon="repeat" onClick={onRebuildConfiguration}>
                                    Rebuild configuration
                                </IconWithLabel>
                            </>
                        )}
                    </NothingFoundBlock>
                ) : (
                    <ErrorBoundary onError={onError}>
                        <FormBuilder
                            instanceId={instanceId}
                            applicationForm
                            noActions={true}
                            onSave={onSave}
                            onRevert={onRevert}
                            isSubmitting={isSubmitting}
                            onRebuildConfiguration={onRebuildConfiguration}
                            onHideDisabledPages={onHideDisabledPages}
                            hideDisabledPages={hideDisabledPages}
                        />
                    </ErrorBoundary>
                )}
            </div>
        </div>
    );
};

export default ApplicationFormPageEditor;
