import React, { useState, memo } from "react";
import { get } from "lodash";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import TabList from "../../../../List/TabList";
import CatalogHistoryTab from "./CatalogHistoryTab";
import CatalogItemsTab from "./CatalogItemsTab";

import "./style.scss";

const tabs = [
    {
        id: "catalogItems",
        title: "Catalog Items",
    },
    {
        id: "history",
        title: "History",
    },
];

const CatalogPanel = memo(({ panel }) => {
    const utilityNumber = get(panel, "data.utilityNumber");
    const programNumber = get(panel, "data.programNumber");

    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="panel program-catalog flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <TabList tabListGhost items={tabs} activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
            {activeTab.id === "catalogItems" && <CatalogItemsTab utilityNumber={utilityNumber} programNumber={programNumber} />}
            {activeTab.id === "history" && <CatalogHistoryTab programNumber={programNumber} />}
        </div>
    );
});

export default CatalogPanel;
