import { isEmpty } from "lodash";

export const getContactFromCisContact = ({ cisContact, isMailingAddress }) => {
    // some users have different address for premise and primary contacts. For primary contacts MAILING address should be used if it exists
    let addressFromCisDetails = "";
    let cityFromCisDetails = "";
    let stateFromCisDetails = "";
    let zipFromCisDetails = "";
    let phoneFromCisDetails = "";

    if (isMailingAddress && !isEmpty(cisContact.mailingAddress)) {
        addressFromCisDetails = cisContact.mailingAddress;
        cityFromCisDetails = cisContact.mailingCity;
        stateFromCisDetails = cisContact.mailingState;
        zipFromCisDetails = cisContact.mailingZip;
        phoneFromCisDetails = cisContact.mailingPhone;
    }

    if (!isMailingAddress) {
        addressFromCisDetails = cisContact.address;
        cityFromCisDetails = cisContact.city;
        stateFromCisDetails = cisContact.state;
        zipFromCisDetails = cisContact.zip;
        phoneFromCisDetails = cisContact.phone;
    }

    var contact = {
        ...cisContact,
        address: addressFromCisDetails,
        city: cityFromCisDetails,
        state: stateFromCisDetails,
        zip: zipFromCisDetails,
        phone: phoneFromCisDetails,
    };

    return contact;
};
