import React, { useState, memo } from "react";
import { useSelector } from "react-redux";
import { ScrollSync } from "react-scroll-sync";
import cn from "classnames";

import { availableViews } from "../../views/configureViews";
import SplitWindow from "./SplitWindow";
import WindowHeader from "./WindowHeader/WindowHeader";
import WindowContent from "./WindowContent";
import SideNavRoot, { sideNavPosition, getViewSideNavKey } from "../SideNav/SideNavRoot";
import SideNavContainer from "../SideNav/SideNavContainer";
import ErrorBoundary from "components/utils/ErrorBoundary";
import IconWrap from "../Icons";
import { openConfirmModal } from "../Modal/utils";
import WindowHeaderNotification from "./WindowHeaderNotification";

export const WindowContext = React.createContext({});
const VIEWS_WITH_CONFIGURABLE_SIDEBAR = ["ProjectView", "ProgramView", "UtilityView"];

const Window = memo(({ containerName, viewName, onOpen, onClose, onSplit, onRemoveSplit, onClearSplit }) => {
    const views = useSelector((state) => state.window[containerName])?.views || [];
    const view = views.find((i) => i.name === viewName) || {};
    const isDesktop = useSelector((state) => state.window?.isDesktop);

    const isNotificationVisible = useSelector((state) => state.window[containerName]?.isNotificationVisible?.[viewName]) ?? false;

    const { name, component, showHeader, isSplitView, isSplitViewSync, props, layoutType, className, active } = view;
    const ViewComponent = availableViews[component];
    const [isVisibleInfoSidebar, setIsVisibleInfoSidebar] = useState(false);

    const windowClass = cn("window flex-one-in-row no-shrink", {
        active: active,
        "split-view": isSplitView,
        "layout-top": layoutType === "top-right" && !isSplitView,
        "layout-left": layoutType === "left-right" && !isSplitView && isDesktop,
        "info-sidebar-hidden": layoutType === "left-right" && isVisibleInfoSidebar && !isSplitView && isDesktop,
    });

    const viewSideNavLeftId = getViewSideNavKey({
        viewName: name,
        position: sideNavPosition.left,
    });
    const viewSideNavRightId = getViewSideNavKey({
        viewName: name,
        position: sideNavPosition.right,
    });

    if (isSplitView) {
        return (
            <ScrollSync enabled={isSplitViewSync}>
                <SplitWindow
                    className={windowClass}
                    containerName={containerName}
                    viewName={viewName}
                    onOpen={onOpen}
                    onClearSplit={onClearSplit}
                    onRemoveSplit={onRemoveSplit}
                />
            </ScrollSync>
        );
    }

    return (
        <WindowContext.Provider
            value={{
                isActiveView: active,
                isSplitView,
                viewName: name,
                containerName,
            }}
        >
            <ScrollSync enabled={isSplitViewSync}>
                <div
                    className={cn(windowClass, className, {
                        "window--notification-visible": isNotificationVisible,
                    })}
                    hidden={!active}
                >
                    <WindowContent active={active}>
                        <SideNavContainer sideNavIds={[viewSideNavLeftId, viewSideNavRightId]}>
                            <SideNavRoot id={viewSideNavLeftId} onConfirmClose={openConfirmModal} />
                            <WindowHeaderNotification view={view} />
                            {showHeader && <WindowHeader view={view} onClose={() => onClose(name)} onSplit={() => onSplit(name)} />}
                            <div className="window-body-container flex-one-in-column popup-boundary">
                                <div className="window-body flex-column flex-one">
                                    <ErrorBoundary>{ViewComponent && <ViewComponent {...props} onWindowOpen={onOpen} />}</ErrorBoundary>
                                </div>
                            </div>
                            {layoutType === "left-right" && isDesktop && VIEWS_WITH_CONFIGURABLE_SIDEBAR.includes(component) && (
                                <div
                                    className={
                                        "hide-info-sidebar-wrap flex-row align-center justify-center" +
                                        (isVisibleInfoSidebar ? "" : " opened")
                                    }
                                    onClick={() => setIsVisibleInfoSidebar(!isVisibleInfoSidebar)}
                                    title={isVisibleInfoSidebar ? "Show Left Sidebar" : "Hide Left Sidebar"}
                                >
                                    <IconWrap
                                        iconWrapTransparent
                                        iconWrapBig
                                        icon={isVisibleInfoSidebar ? "shevron-in-circle-right-empty" : "shevron-in-circle-left-filled"}
                                    />
                                </div>
                            )}
                            <SideNavRoot id={viewSideNavRightId} onConfirmClose={openConfirmModal} />
                        </SideNavContainer>
                    </WindowContent>
                </div>
            </ScrollSync>
        </WindowContext.Provider>
    );
});

export default Window;
