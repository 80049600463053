import React, { useCallback, useState, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";

import { getData } from "../../../../../../store/dataGrid/actions";
import { getDefaultUiSchema, getDefaultSchema, getInitialValues, refreshSidebarEvents } from "./utils";
import { useProgramRights, useResource } from "../../../../../../store/resources/useResource";
import { submitResource } from "../../../../../utils/form";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { formatJsonDateTime } from "../../../../../utils/date";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { jsonDateToDate } from "../../../../../utils/date";

import Label from "../../../../Label";
import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import { validateAttributesForm } from "components/utils/attributes";
import { systemUserRights } from "components/utils/user";

const EventDetails = memo(
    ({
        isNewEvent,
        formRef,
        applicationNumber,
        eventNumber,
        programEventNumber,
        hasHeaderLeftAligned,
        headerLeftAlignedTitle,
        headerLeftAlignedIcon,
        onClose,
        sidePanel,
        programNumber,
    }) => {
        const dispatch = useDispatch();

        const isAppLocked = isApplicationLocked({ applicationNumber });

        const [isSubmitting, setSubmitting] = useState(false);

        const isMobile = useSelector((state) => state.window?.isMobile);

        const [programEvent, isLoadingProgramEvent] = useResource({
            resourceName: "applicationEventForCreate",
            resourceId: programEventNumber,
            path: {
                appId: applicationNumber,
            },
            forced: true,
            transform: (data) => data?.event,
        });

        const [applicationEvent, isLoadingApplicationEvent] = useResource({
            resourceName: "event",
            resourceId: eventNumber,
            path: {
                appId: applicationNumber,
            },
            forced: true,
            transform: (data) => data?.event,
        });

        const [validationTypes = [], isLoadingValidationTypes] = useReference(referenceTypes.equipmentValidation);
        const [trueFalseTypes = [], isLoadingTrueFalseTypes] = useReference(referenceTypes.trueFalse);

        const event = useMemo(() => programEvent ?? applicationEvent ?? {}, [programEvent, applicationEvent]);
        const [programRights = []] = useProgramRights({ programNumber });

        const canEditAllEvents = programRights.includes(systemUserRights.VISIONDSM_EDIT_ALL_EVENTS);

        const schema = getDefaultSchema(event, validationTypes, trueFalseTypes, canEditAllEvents);
        const uiSchema = getDefaultUiSchema(event, validationTypes, canEditAllEvents);
        const initialValues = getInitialValues(event, validationTypes, schema, isNewEvent, canEditAllEvents);

        const isLoading = isLoadingApplicationEvent || isLoadingProgramEvent || isLoadingValidationTypes || isLoadingTrueFalseTypes;

        const onRefresh = useCallback(() => {
            dispatch(
                getData({
                    dataGridId: `${applicationNumber}-events-grid`,
                })
            );
        }, [applicationNumber, dispatch]);

        const onSubmit = useCallback(
            (formData) => {
                const resourceParams = {
                    resourceName: "event",
                    path: {
                        appId: applicationNumber,
                    },
                };

                const { duration, endDate, startDate } = formData.attributes;
                const newAttributes = omit(formData.attributes, ["duration", "endDate", "startDate"]);

                const attributes = Object.keys(newAttributes).map((key) => ({
                    attributename: key,
                    attributevalue: String(newAttributes[key] ?? ""),
                }));

                const body = {
                    eventnumber: eventNumber,
                    programeventnumber: event.programEventNumber,
                    attributes,
                    event_Start_Datetime: startDate,
                    event_End_Datetime: endDate,
                    event_Duration: duration,
                };

                submitResource({
                    resourceParams,
                    resourceId: eventNumber,
                    body,
                    onRefresh,
                    onSuccess: sidePanel.close,
                    setSubmitting,
                    onComplete: () => refreshSidebarEvents({ applicationNumber }),
                });
            },
            [applicationNumber, eventNumber, event, onRefresh, sidePanel]
        );

        const onValidate = useCallback(
            (formData, errors) => {
                const startDate = formData.attributes?.startDate;
                const endDate = formData.attributes?.endDate;

                if (jsonDateToDate(startDate) >= jsonDateToDate(endDate)) {
                    errors.attributes.startDate.addError("Start Date must be smaller than End Date");
                }

                validateAttributesForm(formData.attributes, errors.attributes, schema?.properties?.attributes ?? {});

                return errors;
            },
            [schema]
        );

        const infoField = (label, value) => {
            return (
                <>
                    <Label contentLabel>{label}</Label>
                    <div className="event-info-value">{value}</div>
                </>
            );
        };

        const renderInfo = useMemo(() => {
            return (
                <>
                    <div className="flex-column">{infoField("Category", event.categoryName)}</div>
                    <div className="flex-column">{infoField("Name", event.programEventName)}</div>
                    {event.refId ? (
                        <div className="flex-column ref-id">{infoField("Ref.ID", event.refId)}</div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                    {event.dateEntered ? (
                        <div className="flex-column">{infoField("Entered", formatJsonDateTime(event.dateEntered))}</div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                    {event.startDate ? (
                        <div className="flex-column">{infoField("Started", formatJsonDateTime(event.startDate))}</div>
                    ) : (
                        <div className="flex-column blank-column"></div>
                    )}
                </>
            );
        }, [event]);

        const renderForm = useMemo(() => {
            return (
                <JsonSchemaForm
                    formRef={formRef}
                    formContext={{
                        localizeDateValues: true,
                    }}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    validate={onValidate}
                    disabled={isAppLocked || isSubmitting}
                    onSubmit={onSubmit}
                    noReset
                    noSubmit
                    noActions
                />
            );
        }, [formRef, initialValues, schema, uiSchema, isAppLocked, isSubmitting, onSubmit, onValidate]);

        if (isLoading) {
            return <WaitIcon />;
        }

        return (
            <div className={"event-details" + (!isMobile && hasHeaderLeftAligned ? " flex-one flex-row fill-height no-scroll" : "")}>
                {hasHeaderLeftAligned && !isMobile ? (
                    <SideNavHeader
                        title={headerLeftAlignedTitle}
                        leadBlockIcon={headerLeftAlignedIcon}
                        sidenavHeaderLeftAligned
                        onClose={onClose}
                    >
                        {renderInfo}
                    </SideNavHeader>
                ) : (
                    <div className="flex-row event-info">{renderInfo}</div>
                )}
                {hasHeaderLeftAligned ? <div className={"event-form flex-column with-scroll"}>{renderForm}</div> : <>{renderForm}</>}
            </div>
        );
    }
);

export default EventDetails;
