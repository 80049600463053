import React from "react";
import { connect } from "react-redux";
import { createJavascriptError } from "../utils/error";
import { reportError } from "../../store/error/actions";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }
    componentDidCatch(error, info) {
        const { dispatch } = this.props;
        const { state, onError } = this.props;

        const errorData = createJavascriptError({ error, info, state });

        this.setState({ hasError: true, error: errorData });

        dispatch(reportError(errorData));

        onError && onError(errorData);
    }
    render() {
        return this.state.hasError ? (
            <div className="flex-column" style={{ padding: 20 }}>
                <h2 className="error">Something went wrong.</h2>
                <pre style={{ whiteSpace: "pre-line" }}>
                    {Object.keys(this.state.error).map((key) => (
                        <div key={key}>
                            <br />
                            <div style={{ color: "red", fontWeight: "bold" }}>{key.substr(5)}</div>
                            <div>{this.state.error[key]}</div>
                        </div>
                    ))}
                </pre>
            </div>
        ) : (
            this.props.children
        );
    }
}

function mapStateToProps(state) {
    return { state };
}

export default connect(mapStateToProps)(ErrorBoundary);
