import React, { memo, useContext } from "react";
import cn from "classnames";
import { ScrollSyncPane } from "react-scroll-sync";
import Highlighter from "react-highlight-words";
import IconWrap from "components/ui/Icons";
import { DEFAULT_EXPANDED_STATE, HighlightingContext } from "./RolesManagementBody";
import { ClientProgramsUtility, ExpandedState } from "./types";

export const EntitiesColumnBody = ({ clientPrograms, expandedState, searchTerm, onToggleExpand }: EntitiesColumnBodyProps) => {
    return (
        <ScrollSyncPane group="entities-list">
            <div className="roles-management-entities-column-body with-scroll">
                {clientPrograms.map((utility, index) => (
                    <UtilityRows
                        key={index}
                        utility={utility}
                        isExpanded={expandedState[utility.utilityNumber] ?? DEFAULT_EXPANDED_STATE}
                        searchTerm={searchTerm}
                        onToggleExpand={onToggleExpand}
                    />
                ))}
            </div>
        </ScrollSyncPane>
    );
};

interface EntitiesColumnBodyProps {
    clientPrograms: ClientProgramsUtility[];
    expandedState: ExpandedState;
    searchTerm: string;
    onToggleExpand: (utilityNumber: string) => void;
}

const UtilityRows = memo(({ utility, isExpanded, searchTerm, onToggleExpand }: UtilityRowsProps) => {
    const highlightingContext = useContext(HighlightingContext);

    return (
        <>
            <EntityRow
                title={utility.utilityName}
                entityNumber={utility.utilityNumber}
                isUtilityRow
                isExpanded={isExpanded}
                isHighlighted={highlightingContext.entityNumber === utility.utilityNumber}
                searchTerm={searchTerm}
                onToggleExpand={onToggleExpand}
                onHighlight={highlightingContext.onHighlight}
            />
            {utility.filteredProgramList.map((program) => (
                <EntityRow
                    key={program.programNumber}
                    title={program.programName}
                    entityNumber={program.programNumber}
                    isExpanded={isExpanded}
                    isHighlighted={highlightingContext.entityNumber === program.programNumber}
                    searchTerm={searchTerm}
                    onHighlight={highlightingContext.onHighlight}
                />
            ))}
        </>
    );
});

interface UtilityRowsProps {
    utility: ClientProgramsUtility;
    isExpanded: boolean;
    searchTerm: string;
    onToggleExpand: (utilityNumber: string) => void;
}

const EntityRow = memo(
    ({ title, entityNumber, isUtilityRow, isExpanded, isHighlighted, searchTerm, onToggleExpand, onHighlight }: EntityRowProps) => {
        const icon = isExpanded ? "shevron-small-up-expand-less" : "shevron-small-down-expand-more";
        const hidden = !isUtilityRow && !isExpanded;

        if (hidden) {
            return null;
        }

        return (
            <div
                className={cn("roles-management-entities-row flex-row", {
                    "roles-management-entities-row--utility": isUtilityRow,
                    "roles-management-entities-row--highlighted": isHighlighted,
                })}
                hidden={hidden}
                onMouseOverCapture={() => onHighlight?.(entityNumber)}
                onClick={isUtilityRow ? () => onToggleExpand?.(entityNumber) : undefined}
            >
                <div className="roles-management-entities-row-title">
                    <Highlighter searchWords={searchTerm.split(" ")} textToHighlight={title} autoEscape />
                </div>
                {isUtilityRow && <IconWrap icon={icon} iconWrapClickable={isExpanded} />}
            </div>
        );
    }
);

interface EntityRowProps {
    title: string;
    entityNumber: string;
    isUtilityRow?: boolean;
    isExpanded?: boolean;
    isHighlighted?: boolean;
    searchTerm: string;
    onToggleExpand?: (entityNumber: string) => void;
    onHighlight?: (entityNumber: string) => void;
}
