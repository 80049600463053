import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import SideNavFooter from "../SideNav/SideNavFooter";
import SideNavContent from "../SideNav/SideNavContent";
import SideNavHeader from "../SideNav/SideNavHeader";
import SideNavBody from "../SideNav/SideNavBody";
import Button from "../Button";

/**
 * Wrapper Container for Components that renders a simple JsonSchemaForm component.
 * Handles rendering of SideNavContent structure and delegates form submission to child component.
 * Child component should implement a submitForm method that submits the form by ref.
 * @param {ref} forwardedFormComponentRef - The forwarded ref for the child component
 * @see FileUpload Component for example child component implementation
 */
const SideNavFormContainer = ({
    title,
    titleIcon,
    className,
    onClose,
    forwardedFormComponentRef,
    submitText,
    cancelText,
    children,
    sidePanel,
    fileUpload = false,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onSubmit = useCallback(() => {
        setIsSubmitting(true);
        forwardedFormComponentRef.current.submitForm().catch(() => {
            setIsSubmitting(false);
        });
    }, [forwardedFormComponentRef]);

    useEffect(() => {
        sidePanel.setForm(forwardedFormComponentRef.current.getFormRef());
    }, [sidePanel, forwardedFormComponentRef]);
    const isUploading = useSelector((state) => state.files.isUploading);
    return (
        <SideNavContent>
            <SideNavHeader className={className} leadBlockIcon={titleIcon} title={title} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">{children}</SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={fileUpload ? isUploading : isSubmitting} onClick={onSubmit}>
                    {submitText || "Submit"}
                </Button>
                <Button onClick={onClose}>{cancelText || "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default SideNavFormContainer;
