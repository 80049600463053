import React, { useCallback } from "react";
import TextLabel from "components/ui/Label/TextLabel";
import cn from "classnames";
import { PropertyListItem } from "../Property/PropertyListItem";
import "./TileSelectorProperty.scss";

export const TileSelectorProperty = (props) => {
    const { id, title, value = [], onChange, items } = props;
    const onSelect = useCallback(
        (value) => {
            onChange(id, value);
        },
        [id, onChange]
    );

    return (
        <PropertyListItem className="property-list-item--prop-group property-list-item--background">
            <div className="property-list-item-inner flex-column justify-space-between">
                <TextLabel>{title}:</TextLabel>
                <div className="tiles-list fill-height fill-width">
                    {items.map((item, index) => (
                        <div
                            className={cn("tiles-list-item flex-column align-center", {
                                selected: item.value === value,
                            })}
                            key={index}
                            onClick={() => onSelect(item.value)}
                        >
                            {item.icon}
                            <TextLabel>{item.name}</TextLabel>
                        </div>
                    ))}
                </div>
            </div>
        </PropertyListItem>
    );
};
