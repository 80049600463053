import React, { memo } from "react";

import ViewWithSubmenu from "../../ui/ViewWithSubmenu";
import { systemUserRights } from "components/utils/user";

const submenuItems = [
    {
        id: "users",
        text: "Users",
        icon: "group-people-empty",
        component: "SystemManagementUsers",
        userRights: [systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS],
    },
    {
        id: "user-groups",
        text: "User Groups",
        icon: "group-people-empty",
        component: "SystemManagementUserGroups",
        userRights: [systemUserRights.VISIONDSM_MANAGE_USERGROUPS],
    },
    {
        id: "configuration",
        text: "Configuration",
        icon: "settings-empty",
        component: "SystemManagementConfiguration",
        userRights: [systemUserRights.VISIONDSM_MANAGE_SYSTEM],
    },
    {
        id: "system-logs",
        text: "System Logs",
        icon: "settings-empty",
        component: "SystemManagementLogsSystem",
        userRights: [systemUserRights.VISIONDSM_MANAGE_SYSTEM],
    },
    {
        id: "security-logs",
        text: "Security Logs",
        icon: "security-empty",
        component: "SystemManagementLogsSecurity",
        userRights: [systemUserRights.VISIONDSM_MANAGE_SYSTEM],
    },
];

const SystemManagement = memo(() => {
    return (
        <ViewWithSubmenu className="system-management-view" viewName="SystemManagement" menuItems={submenuItems} viewPlaceholder="System" />
    );
});

export default SystemManagement;
