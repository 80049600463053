import React, { useMemo } from "react";
import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import { SECTION_TEMPLATE_INFO } from "./Section";
import { usePortalBuilderState } from "../PortalBuilderContextProvider";
import { DeviceTypes } from "../types";

export const ConfigurationOverlay = ({ section }: ConfigurationOverlayProps) => {
    const [deviceType, setPortalBuilderState] = usePortalBuilderState((state) => state.deviceType);
    const isOverlayDisabled = useMemo(() => localStorage.getItem("portal-builder-config-overlay-disabled") === "true", []);

    if (deviceType !== DeviceTypes.DESKTOP && section !== SECTION_TEMPLATE_INFO.title && !isOverlayDisabled) {
        return (
            <div className="disabled-configuration-overlay fill-height flex-column justify-center">
                <div className="large-text">
                    Configuration is not available when the
                    {deviceType === DeviceTypes.MOBILE && " mobile "}
                    {deviceType === DeviceTypes.TABLET && " tablet "}
                    preview is happening.
                </div>
                <div className="small-text">Switch to the Desktop view to enable the configuration properties.</div>
                <IconWithLabel icon="desktop-empty" onClick={() => setPortalBuilderState({ deviceType: DeviceTypes.DESKTOP })}>
                    Switch to desktop view
                </IconWithLabel>
            </div>
        );
    }

    return null;
};

interface ConfigurationOverlayProps {
    section: string;
}
