import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";

import * as loginActions from "store/login/actions";

import WaitIcon from "../../ui/WaitIcon";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import IconWrap from "../../ui/Icons";
import FieldGroupInput from "../../ui/FieldGroupInput";
import LoginFormWrap from "../../ui/LoginFormWrap";
import { ErrorMessage } from "../../ui/Message";
import { USER_DELETE } from "store/actionTypes";

import "./style.scss";

const LoginForm = memo(({ isLoading, isSuspended, twoFactorRequired, twoFactorQrCodeExists, onLogin, onForgotPassword, errorMessage }) => {
    const dispatch = useDispatch();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [oneTimeCode, setOneTimeCode] = useState("");
    const [stepUserName, setStepUserName] = useState(true);

    const isValid = () => {
        const userNameValid = userName.trim().length > 0;

        if (stepUserName) {
            return userNameValid;
        } else {
            const oneTimeCodeValid = isSuspended ? oneTimeCode.trim().length > 0 : true;
            const authCodeValid = twoFactorRequired && twoFactorQrCodeExists ? authCode.trim().length > 0 : true;
            const passwordValid = password.trim().length > 0;

            return userNameValid && passwordValid && oneTimeCodeValid && authCodeValid;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        setOneTimeCode("");
        setAuthCode("");

        if (stepUserName) {
            dispatch(loginActions.loginUser2fa({ userName }));
            setStepUserName(false);
            setPassword("");
        } else {
            onLogin({ userName, password, authCode, oneTimeCode });
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        onForgotPassword(userName);
    };

    const onChangeUser = () => {
        setStepUserName(true);
        setPassword("");
        setAuthCode("");
        setOneTimeCode("");
        dispatch({
            type: USER_DELETE,
        });
    };

    if (isLoading) {
        return (
            <LoginFormWrap>
                <WaitIcon />
            </LoginFormWrap>
        );
    }

    return (
        <LoginFormWrap>
            <Form onSubmit={onSubmit}>
                {stepUserName && (
                    <>
                        <h3>Sign in to your account</h3>
                        <FieldGroupInput
                            autoFocus
                            label={"Username"}
                            name={"userName"}
                            type={"text"}
                            placeholder={"Enter your username"}
                            onChange={(e) => setUserName(e.target.value)}
                            value={userName}
                            className="user-name"
                        />
                    </>
                )}

                {!stepUserName && (
                    <>
                        {errorMessage && <ErrorMessage msgSquare>{errorMessage}</ErrorMessage>}
                        <h3 className="text-center">Welcome</h3>
                        <div className="login-form-header username">
                            <div>
                                <IconWrap icon="account-in-circle-filled"></IconWrap>
                                <span>{userName}</span>
                                <IconWrap icon="edit-empty" title="change user" onClick={onChangeUser}></IconWrap>
                            </div>
                        </div>
                    </>
                )}
                {!stepUserName && !isSuspended && (
                    <FieldGroupInput
                        autoFocus
                        label={"Password"}
                        name={"password"}
                        type={"password"}
                        placeholder={"Enter your password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                )}
                {!stepUserName && isSuspended && (
                    <FieldGroupInput
                        label="One Time Code"
                        name="oneTimeCode"
                        type="text"
                        autoComplete="off"
                        autoFocus
                        placeholder="Enter one time code"
                        onChange={(e) => setOneTimeCode(e.target.value)}
                        value={oneTimeCode}
                    />
                )}
                {!stepUserName && twoFactorRequired && twoFactorQrCodeExists && (
                    <FieldGroupInput
                        label={"Authentication Code"}
                        name={"authCode"}
                        type={"text"}
                        autoComplete={"off"}
                        placeholder={"Enter authentication code"}
                        onChange={(e) => setAuthCode(e.target.value)}
                        value={authCode}
                    />
                )}
                <div className={stepUserName ? "form-btn-wrap login sign-in-btn-wrap" : "form-btn-wrap login"}>
                    {!stepUserName && (
                        <span className="login-form-link password" onClick={handleForgotPassword}>
                            Forgot password?
                        </span>
                    )}
                    <span className="flex-one"></span>
                    <Button className={stepUserName ? "next" : "sign-in"} type="submit" primary onClick={onSubmit} disabled={!isValid()}>
                        {stepUserName ? "Next" : "Sign in"}
                    </Button>
                </div>
            </Form>
        </LoginFormWrap>
    );
});

export default LoginForm;
