import { store } from "store/configureStore";
import { useResource } from "store/resources/useResource";
import { AVAILABLE_DASHBOARDS_KEY } from "../ui/Dashboard/DashboardSettings/utils";

export const useUtilityStandardDashboard = () => {
    const [, isLoading] = useResource({
        resourceName: "dashboardsAvailable",
        key: AVAILABLE_DASHBOARDS_KEY,
    });

    const dashboards = store.getState().resources.dashboardsAvailable.itemsById[AVAILABLE_DASHBOARDS_KEY]?.availableDashboardList ?? [];

    const reportID = dashboards
        .find((i) => i.menuItem === "PowerBI Report")
        ?.childList.find((i) => i.menuItem === "Utility Standard Dashboard")?.itemKey;

    return [reportID, isLoading];
};
