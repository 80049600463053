import React, { memo, useCallback, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { exportDatagridToCSV } from "../../utils/CSV";

import ApplicationsFilterControls from "./ApplicationsFilterControls";
import TasksFilterControls from "./TasksFilterControls";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import { WorkCenterContext } from ".";
import { getResourcePromise } from "store/resources/useResource";
import { systemUserRights } from "components/utils/user";
import { getColumnKeys } from "components/views/configureGrids";
import { WarningMessage } from "components/ui/Message";

const WorkqueueGridControls = memo(({ showAssignments }) => {
    const { instanceId } = useContext(WorkCenterContext);
    const [showApplicationFilterControls, setShowApplicationFilterControls] = useState(false);

    const dataGridId = useSelector((state) => state.workcenter[instanceId].workqueueGrid);
    const dataGridInstanceId = useSelector((state) => state.workcenter[instanceId].workqueueGridId);
    const activeFilterTab = useSelector((state) => state.workcenter[instanceId].activeFilterTab);
    const selectedRows = useSelector((state) => state.workcenter[instanceId].workqueueGridSelectedRows);
    const showAssignmentWarning = selectedRows?.length > 0 && !showApplicationFilterControls;

    // For each selected row, make sure the user has VAAP access to each program in the row
    useEffect(() => {
        const getSelectedRowProgramRights = async () => {
            try {
                const columnKeys = getColumnKeys(dataGridId);
                const programNumbers = selectedRows.map((selectedRow) => selectedRow[columnKeys.progId]);
                const uniqueProgramNumbers = [...new Set(programNumbers)];
                const promises = uniqueProgramNumbers.map((programNumber) => {
                    if (!programNumber) {
                        return null;
                    }

                    return getResourcePromise({
                        resourceName: "userRights",
                        key: `program-${programNumber}`,
                        query: {
                            entityType: "3",
                            entityNumber: programNumber,
                        },
                    });
                });
                const response = await Promise.all(promises);
                const hasAccess = response.every((programRights) =>
                    programRights?.rights?.includes(systemUserRights.VISIONDSM_ASSIGN_APPLICATION)
                );
                setShowApplicationFilterControls(hasAccess);
            } catch (err) {
                setShowApplicationFilterControls(false);
            }
        };
        getSelectedRowProgramRights();
    }, [dataGridId, selectedRows]);

    const onExport = useCallback(() => {
        exportDatagridToCSV({
            dataGridId: dataGridInstanceId,
            fileName: "workcenter",
            fileNamePostfix: activeFilterTab.id,
        });
    }, [dataGridInstanceId, activeFilterTab]);

    if (!activeFilterTab) {
        return null;
    }

    return (
        <div className="grid-controls flex-row align-end">
            {showAssignments && (
                <div className="workqueue-controls flex-row flex-wrap align-center">
                    {activeFilterTab.id === "applications" &&
                        (showApplicationFilterControls ? (
                            <ApplicationsFilterControls selectedRows={selectedRows} dataGridId={dataGridId} />
                        ) : (
                            showAssignmentWarning && (
                                <WarningMessage>
                                    The application(s) selected requires you to have rights to perform application assignment.
                                </WarningMessage>
                            )
                        ))}
                    {activeFilterTab.id === "tasks" && <TasksFilterControls selectedRows={selectedRows} dataGridId={dataGridId} />}
                </div>
            )}
            <span className="flex-one" />
            <IconWithLabel withHandMadeIcon onClick={onExport}>
                Export CSV
            </IconWithLabel>
        </div>
    );
});

export default WorkqueueGridControls;
