import {
    AccessibilityValidationMessageForExport,
    LinkType,
    PortalWidgetConfiguration,
    PropertyType,
    ValidateChildrenProps,
    ValidatePagesParams,
} from "./../types";
import { isNullOrWhitespace } from "components/utils/string";
import { debounce, get, isEmpty, isEqual, isNil, isNumber, isString, omit, sortBy, uniqWith } from "lodash";
import { contactUsThankYouPageProperties, getPageProperties, getProgramLevelPageConfig } from "./page";
import { PortalMessenger } from "./PortalMessenger";
import {
    AccessibilityValidationMessage,
    ColumnConfiguration,
    PageConfiguration,
    PortalBuilderProperty,
    PortalTemplateConfiguration,
    RowConfiguration,
    TemplateCookiesConfiguration,
    PortalTemplateInfo,
    ValidateColumnParams,
    ValidateColumnsParams,
    ValidatePageParams,
    ValidatePropertyParams,
    ValidateRowParams,
    ValidateRowsParams,
    ValidationError,
    ValidationMessageImpact,
    ValidationOptions,
    ValidationRule,
    WidgetType,
} from "../types";
import { TEMPLATE_INFO_PROPERTIES } from "../Properties/TemplateInfo/UtilityTemplateInfo";
import { getLeadImageContentProperties, LEAD_IMAGE_FILE_SELECT_PROPERTY, PORTAL_WIDGET_PROPERTIES } from ".";
import { SECTION_CONTENT, SECTION_FOOTER, SECTION_HEADER, SECTION_SETTINGS, SECTION_TEMPLATE_INFO } from "../Properties/Section";
import { COOKIES_STYLE_PROPERTIES, COOKIES_TEXT_PROPERTIES } from "../Properties/Cookies";
import { isDevEnvironment } from "components/utils/constants";

const accessibilityMessagesIdPrefix = "accessibility.";

export const validateUtilityTemplate = (
    config: PortalTemplateConfiguration,
    templateInfo: PortalTemplateInfo,
    options?: ValidationOptions
): ValidationError[] => {
    let errors: ValidationError[] = [];

    // Validate settings
    errors = validateUtilityTemplateSettings(config, errors);

    // Validate header
    if (config.header.isEnabled) {
        errors = errors.concat(
            validateRows({
                rows: config.header.components.map((r) => r.props),
                pageId: "header.components",
                section: SECTION_HEADER,
            })
        );
    }

    // Validate content
    errors = errors.concat(validatePages({ pages: config?.content?.pages ?? [], config, isProgramPortalBuilder: false }));

    // Validate footer
    if (config.footer.isEnabled) {
        errors = errors.concat(
            validateRows({
                rows: config.footer.components.map((r) => r.props),
                pageId: "footer.components",
                section: SECTION_FOOTER,
            })
        );
    }

    // Validate template info
    TEMPLATE_INFO_PROPERTIES.forEach((property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach(
            (validationRule) =>
                (errors = errors.concat(
                    validateProperty({
                        id: `templateInfo.${property.id}`,
                        validationRule,
                        value: templateInfo[property.id as keyof PortalTemplateInfo],
                        config,
                        section: SECTION_TEMPLATE_INFO,
                    })
                ))
        );
    });

    return errors;
};

export const validateProgramTemplate = (
    programConfig: PortalTemplateConfiguration,
    utilityConfig: PortalTemplateConfiguration,
    options?: ValidationOptions
): ValidationError[] => {
    let errors: ValidationError[] = [];
    const pages = (programConfig?.content?.pages ?? [])
        .map((p) => getProgramLevelPageConfig(p, utilityConfig))
        .filter((p) => !isNil(p)) as PageConfiguration[];

    // Validate settings
    errors = validateProgramLevelSettings(programConfig, errors);

    // Validate content
    errors = errors.concat(validatePages({ pages, config: programConfig, utilityConfig, isProgramPortalBuilder: true, options }));

    return errors;
};

const validateUtilityTemplateSettings = (config: PortalTemplateConfiguration, errors: ValidationError[]) => {
    const validate = (property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach((validationRule) => {
            errors = errors.concat(
                validateProperty({
                    id: `settings.${property.id}`,
                    validationRule,
                    value: config?.settings?.[property.id as keyof TemplateCookiesConfiguration] ?? property.defaultValue,
                    section: SECTION_SETTINGS,
                    config,
                })
            );
        });
    };

    // Validate cookies panel
    if (config?.settings.enableCookiesBanner) {
        [...COOKIES_STYLE_PROPERTIES, ...COOKIES_TEXT_PROPERTIES].forEach((property: PortalBuilderProperty) => {
            if (property.propertiesGroup) {
                property.propertiesGroup.forEach((property) => validate(property));
            } else {
                validate(property);
            }
        });
    }

    // Validate notification panel
    if (config?.settings.enableNotification) {
        errors = errors.concat(
            validateRequiredProperty({ id: "notificationText", value: config?.settings.notificationText, section: SECTION_SETTINGS })
        );
    }

    return errors;
};

const validateProgramLevelSettings = (config: PortalTemplateConfiguration, errors: ValidationError[]) => {
    // Validate notification panel
    if (config?.settings.enableProgramNotification) {
        errors = errors.concat(
            validateRequiredProperty({
                id: "programNotificationText",
                value: config?.settings.programNotificationText,
                section: SECTION_SETTINGS,
            })
        );
    }

    return errors;
};

const validateLeadImage = (
    page: PageConfiguration,
    pageIndex: number,
    config: PortalTemplateConfiguration,
    errors: ValidationError[],
    isSaving?: boolean
) => {
    const leadImage = page.leadImage;
    if (!leadImage) return errors;

    const { leadImageContentOff, contentType } = leadImage;
    if (isSaving) {
        LEAD_IMAGE_FILE_SELECT_PROPERTY.forEach((property: PortalBuilderProperty) => {
            errors = errors.concat(validateLeadImageProp(property, pageIndex, config));
        });
    }
    if (!leadImageContentOff) {
        getLeadImageContentProperties(contentType).forEach((property: PortalBuilderProperty) => {
            property.propertiesGroup?.forEach((property: PortalBuilderProperty) => {
                errors = errors.concat(validateLeadImageProp(property, pageIndex, config));
            });
        });
    }

    return errors;
};

const validateLeadImageProp = (property: PortalBuilderProperty, pageIndex: number, config: PortalTemplateConfiguration) => {
    let newErrors: ValidationError[] = [];
    (property.validationRules ?? []).forEach(
        (validationRule) =>
            (newErrors = newErrors.concat(
                validateProperty({
                    id: `content.pages[${pageIndex}].leadImage.${property.id}`,
                    validationRule,
                    value: config?.content?.pages[pageIndex]?.leadImage?.[property.id],
                    config,
                    section: SECTION_CONTENT,
                    pageIndex: pageIndex,
                    isLeadImageError: true,
                })
            ))
    );
    return newErrors;
};

export const debouncedValidateProgramTemplate = debounce(
    (
        programConfig: PortalTemplateConfiguration,
        utilityConfig: PortalTemplateConfiguration,
        setErrors: (errors: ValidationError[]) => void,
        options?: ValidationOptions
    ) => {
        try {
            const errors = validateProgramTemplate(programConfig, utilityConfig, options);
            setErrors(errors);

            return errors;
        } catch (e) {
            setErrors([getTryCatchError(e)]);
        }
    },
    500
);

export const debouncedValidateUtilityTemplate = debounce(
    (
        config: PortalTemplateConfiguration,
        templateInfo: PortalTemplateInfo,
        setErrors: (errors: ValidationError[]) => void,
        options?: ValidationOptions
    ) => {
        try {
            const errors = validateUtilityTemplate(config, templateInfo, options);
            setErrors(errors);
        } catch (e) {
            setErrors([getTryCatchError(e)]);
        }
    },
    500
);

export const validatePages = ({ pages, config, utilityConfig, isProgramPortalBuilder, options }: ValidatePagesParams) => {
    return pages.flatMap((page: PageConfiguration, pageIndex) =>
        validatePage({ page, pageId: `content.pages[${pageIndex}]`, config, pageIndex, utilityConfig, isProgramPortalBuilder, options })
    );
};

export const validatePage = ({ page, pageId, config, pageIndex, utilityConfig, isProgramPortalBuilder, options }: ValidatePageParams) => {
    let errors: ValidationError[] = [];

    let properties = getPageProperties(page, config.content?.pages ?? []) as PortalBuilderProperty[];

    if (page.link === "/contact-us" && page.props?.contactUsFormOn && !isProgramPortalBuilder) {
        properties = properties.concat(contactUsThankYouPageProperties);
    }
    // Validate page properties
    properties.forEach((property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach(
            (validationRule) =>
                (errors = errors.concat(
                    validateProperty({
                        id: `${pageId}.${property.id}`,
                        validationRule,
                        value: page[property.id as keyof Omit<PageConfiguration, "components">],
                        config,
                        pageIndex,
                        section: SECTION_CONTENT,
                        utilityConfig,
                        activeTab: property.tab,
                    })
                ))
        );

        if (property.type === PropertyType.NestedProperties) {
            // validate nested properties and their propertyGroups
            errors = errors.concat(
                validateChildren({
                    parentId: property.id,
                    propertiesGroup: property.propertiesGroup,
                    pageId,
                    pageIndex,
                    config,
                    utilityConfig,
                    activeTab: property.tab,
                })
            );
            (property.propertiesGroup ?? []).forEach((childProperty) => {
                errors = errors.concat(
                    validateChildren({
                        parentId: property.id,
                        propertiesGroup: childProperty.propertiesGroup,
                        pageId,
                        pageIndex,
                        config,
                        utilityConfig,
                    })
                );
            });
        }
    });

    errors = validateLeadImage(page, pageIndex, config, errors, options?.isSaving);

    // Validate page rows
    const rows = (page.components ?? []).map((r) => r.props);
    if (rows.length) {
        errors = errors.concat(
            validateRows({
                rows,
                pageId: `${pageId}.components`,
                pageIndex,
                section: SECTION_CONTENT,
            })
        );
    }

    return errors;
};

export const validateChildren = ({
    parentId,
    propertiesGroup,
    pageId,
    pageIndex,
    config,
    utilityConfig,
    activeTab,
}: ValidateChildrenProps) => {
    let errors: ValidationError[] = [];
    (propertiesGroup ?? []).forEach((childProperty: any) => {
        childProperty.validationRules?.forEach((childValidationRule: ValidationRule) => {
            errors = errors.concat(
                validateProperty({
                    id: `${pageId}.${parentId}.${childProperty.id}`,
                    validationRule: childValidationRule,
                    value: get(config, `${pageId}.${parentId}.${childProperty.id}`),
                    config,
                    pageIndex,
                    section: SECTION_CONTENT,
                    utilityConfig,
                    activeTab: childProperty.tab,
                })
            );
        });
    });
    return errors;
};

export const validateRows = ({ rows, pageId, pageIndex, section }: ValidateRowsParams) => {
    const errors = rows.flatMap((row: RowConfiguration, rowIndex: number) =>
        validateRow({
            row,
            rowPath: `${pageId}[${rowIndex}]`,
            pageIndex,
            rowIndex,
            section,
        })
    );
    return errors;
};

export const validateRow = ({ row, rowPath, pageIndex, rowIndex, section }: ValidateRowParams) => {
    let errors: ValidationError[] = [];

    // Do not validate disabled row.
    if (isNil(row) || row.hidden) {
        return errors;
    }

    const rowProps = PORTAL_WIDGET_PROPERTIES[WidgetType.ROW];
    rowProps.forEach((property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach(
            (validationRule) =>
                (errors = errors.concat(
                    validateProperty({
                        id: `${rowPath}.${property.id}`,
                        validationRule,
                        value: row?.[property.id as keyof RowConfiguration],
                        pageIndex,
                        rowIndex,
                        section,
                    })
                ))
        );
    });

    // Do not try to validate rows without columns.
    if (Array.isArray(row.components)) {
        const columns = row.components.map((c) => c.props);

        errors = errors.concat(
            validateColumns({
                columns,
                rowPath,
                pageIndex,
                rowIndex,
                section,
            })
        );
    }

    return errors;
};

export const validateColumns = ({ columns, rowPath, pageIndex, rowIndex, section }: ValidateColumnsParams) => {
    const errors = columns.flatMap((column: ColumnConfiguration, columnIndex: number) =>
        validateColumn({
            column,
            colPath: `${rowPath}.components[${columnIndex}]`,
            pageIndex,
            rowIndex,
            columnIndex,
            section,
        })
    );
    return errors;
};

export const validateColumn = ({ column, colPath, pageIndex, rowIndex, columnIndex, section }: ValidateColumnParams) => {
    let errors: ValidationError[] = [];
    const colProps = PORTAL_WIDGET_PROPERTIES[WidgetType.COL];

    colProps.forEach((property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach((validationRule) => {
            errors = errors.concat(
                validateProperty({
                    id: `${colPath}.${property.id}`,
                    validationRule,
                    value: column?.[property.id as keyof ColumnConfiguration],
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                })
            );
        });

        // Validate widget
        if (property.id === "components" && column.components[0]) {
            errors = validateWidget(column.components[0], errors, section, colPath, pageIndex, columnIndex, rowIndex);
        }
    });

    return errors;
};

export const validateWidget = (
    widget: PortalWidgetConfiguration,
    errors: ValidationError[],
    section: any,
    colPath: string,
    pageIndex?: number,
    columnIndex?: number,
    rowIndex?: number
) => {
    const widgetType = widget.type;
    const widgetValues = widget.props;
    const widgetProps = PORTAL_WIDGET_PROPERTIES[widgetType];

    widgetProps?.forEach((property: PortalBuilderProperty) => {
        (property.validationRules ?? []).forEach((validationRule) => {
            errors = errors.concat(
                validateProperty({
                    id: `${colPath}.${property.id}`,
                    rowIndex: rowIndex,
                    columnIndex: columnIndex,
                    pageIndex: pageIndex,
                    validationRule,
                    section,
                    value: widgetValues?.[property.id],
                    widget,
                })
            );
        });

        // Validate widget property group properties
        (property.propertiesGroup ?? []).forEach((property) => {
            (property.validationRules ?? []).forEach((validationRule) => {
                // Validate Image widget properties only if file is selected
                if (widgetType === WidgetType.IMAGE && isEmpty(widgetValues["src"])) {
                    return;
                }

                // Validate Card Content widget image alt text only if image is selected
                if (widgetType === WidgetType.CARD_CONTENT) {
                    if (property.id === "altText" && isEmpty(widgetValues["src"])) {
                        return;
                    }
                }

                // Validate Header widget sign out link only if link is selected shown
                if (widgetType === WidgetType.HEADER_NAVIGATION_WIDGET) {
                    if (property.id === "headerSignOutLinkText" && !widgetValues["showHeaderSignOutLink"]) {
                        return;
                    }
                }

                errors = errors.concat(
                    validateProperty({
                        id: `${colPath}.${property.id}`,
                        rowIndex: rowIndex,
                        columnIndex: columnIndex,
                        pageIndex: pageIndex,
                        validationRule,
                        section,
                        value: widgetValues?.[property.id],
                        widget,
                    })
                );
            });
        });
    });

    if (widgetType === WidgetType.ACCORDION) {
        const widgetProps = PORTAL_WIDGET_PROPERTIES[WidgetType.ACCORDION_ITEM];
        widgetProps?.forEach((property: PortalBuilderProperty) => {
            (property.validationRules ?? []).forEach((validationRule) => {
                widgetValues.accordion?.forEach((item: any, index: number) => {
                    errors = errors.concat(
                        validateProperty({
                            id: `${colPath}.props.accordion[${index}].props.${property.id}`,
                            rowIndex: rowIndex,
                            columnIndex: columnIndex,
                            pageIndex: pageIndex,
                            validationRule,
                            section,
                            value: item.props?.[property.id],
                            widget,
                        })
                    );
                });
            });
        });
    }

    if (widgetType === WidgetType.CAROUSEL) {
        const parentWidgetProps = PORTAL_WIDGET_PROPERTIES[WidgetType.CAROUSEL];
        const widgetProps = PORTAL_WIDGET_PROPERTIES[WidgetType.CAROUSEL_ITEM];
        parentWidgetProps?.forEach((property: PortalBuilderProperty) => {
            if (!!property.propertiesGroup) {
                property.propertiesGroup?.forEach((property: PortalBuilderProperty) => {
                    (property.validationRules ?? []).forEach((validationRule) => {
                        if (!widgetValues.autoplayOn && property.id === "interval") {
                            return;
                        }
                        errors = errors.concat(
                            validateProperty({
                                id: `${colPath}.props.${property.id}`,
                                rowIndex: rowIndex,
                                columnIndex: columnIndex,
                                pageIndex: pageIndex,
                                validationRule,
                                section,
                                value: widgetValues?.[property.id],
                                widget,
                                property,
                            })
                        );
                    });
                });
            }

            (property.validationRules ?? []).forEach((validationRule) => {
                errors = errors.concat(
                    validateProperty({
                        id: `${colPath}.${property.id}`,
                        rowIndex: rowIndex,
                        columnIndex: columnIndex,
                        pageIndex: pageIndex,
                        validationRule,
                        property,
                        section,
                        value: widgetValues?.[property.id],
                        widget,
                    })
                );
            });
        });
        widgetProps?.forEach((property: PortalBuilderProperty) => {
            if (!!property.propertiesGroup) {
                property.propertiesGroup?.forEach((property: PortalBuilderProperty) => {
                    (property.validationRules ?? []).forEach((validationRule) => {
                        widgetValues.carousel?.forEach((item: any, index: number) => {
                            if (
                                (isEmpty(item.props.src) && property.id === "altText") ||
                                (isEmpty(item.props.text) && property.id === "href")
                            ) {
                                return;
                            }
                            errors = errors.concat(
                                validateProperty({
                                    id: `${colPath}.props.carousel[${index}].props.${property.id}`,
                                    rowIndex: rowIndex,
                                    columnIndex: columnIndex,
                                    pageIndex: pageIndex,
                                    validationRule,
                                    section,
                                    value: item.props?.[property.id],
                                    widget: item,
                                })
                            );
                        });
                    });
                });
            } else {
                (property.validationRules ?? []).forEach((validationRule) => {
                    widgetValues.carousel?.forEach((item: any, index: number) => {
                        errors = errors.concat(
                            validateProperty({
                                id: `${colPath}.props.carousel[${index}].props.${property.id}`,
                                rowIndex: rowIndex,
                                columnIndex: columnIndex,
                                pageIndex: pageIndex,
                                validationRule,
                                section,
                                value: item.props?.[property.id],
                                widget: item,
                            })
                        );
                    });
                });
            }
        });
    }

    return errors;
};

/**
 * Validate a portal builder config property
 *
 * @param {string} id Property id
 * @param {ValidationRule} validationRule Property validation rule
 * @param {PropertyValue} [value] Property value
 * @returns {ValidationError[]} List of errors
 */
export const validateProperty = ({
    id,
    validationRule,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    property,
    section,
    utilityConfig,
    isLeadImageError,
    activeTab,
    widget,
}: ValidatePropertyParams): ValidationError[] => {
    let errors: ValidationError[] = [];

    switch (validationRule) {
        case ValidationRule.MaxValue:
            errors = errors.concat(validateMaxProperty({ id, value, config, pageIndex, rowIndex, columnIndex, section, property }));
            break;
        case ValidationRule.MinValue:
            errors = errors.concat(validateMinProperty({ id, value, config, pageIndex, rowIndex, columnIndex, section, property }));
            break;
        case ValidationRule.Required:
            errors = errors.concat(
                validateRequiredProperty({
                    id,
                    value,
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                    isLeadImageError,
                    activeTab,
                })
            );
            break;
        case ValidationRule.PageUrlUnique:
            errors = errors.concat(
                validateUniquePageUrl({ id, value, config, pageIndex, rowIndex, columnIndex, section, isLeadImageError })
            );
            break;
        case ValidationRule.PageNameUnique:
            errors = errors.concat(
                validateUniquePageName({ id, value, config, pageIndex, rowIndex, columnIndex, section, utilityConfig, isLeadImageError })
            );
            break;
        case ValidationRule.ExternalInternalLeadImageButton:
            errors = errors.concat(
                validateExternalInternalLeadImageButton({ id, value, config, pageIndex, rowIndex, columnIndex, section, isLeadImageError })
            );
            break;
        case ValidationRule.ExternalInternalPageLink:
            errors = errors.concat(
                validateExternalInternalPageLinks({ id, value, config, pageIndex, rowIndex, columnIndex, section, isLeadImageError })
            );
            break;
        case ValidationRule.ExternalInternalWidgetsLink:
            errors = errors.concat(validateExternalInternalWidgetsLink({ id, value, pageIndex, rowIndex, columnIndex, section, widget }));
            break;
        case ValidationRule.ExternalInternalCookiesLink:
            errors = errors.concat(validateExternalInternalCookiesLink({ id, value, config, pageIndex, rowIndex, columnIndex, section }));
            break;
        default:
            break;
    }

    return errors;
};

export const validateAccessibility = async (
    iframe: MessageEventSource,
    config: PortalTemplateConfiguration
): Promise<AccessibilityValidationMessage[]> => {
    const pages = config?.content?.pages ?? [];

    let pageResults: AccessibilityValidationMessage[] = [];
    for (const page of pages) {
        const result = await validatePageAccessibility(iframe, page.link);
        pageResults = pageResults.concat(result);
    }

    return pageResults;
};

export const validatePageAccessibility = async (
    iframe: MessageEventSource,
    pageLink: string | undefined
): Promise<AccessibilityValidationMessage[]> => {
    if (!pageLink) {
        return [];
    }

    await new PortalMessenger(iframe, 2).post<{}>("portal-navigate", {
        link: pageLink,
    });

    const payload = await new PortalMessenger(iframe, 60).post<{ results: AccessibilityValidationMessage[] }>("portal-validate", {
        loggingEnabled: isDevEnvironment,
    });

    return (payload?.results ?? []).map((item) => ({
        ...item,
        id: `${accessibilityMessagesIdPrefix}${item.id}`,
    }));
};

export const validateMaxProperty = ({
    id,
    value,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    isLeadImageError,
    activeTab,
    property,
}: ValidatePropertyParams): ValidationError[] => {
    const error = {
        id,
        message: `Maximum allowed value is ${property?.maxValue}`,
        pageIndex,
        rowIndex,
        columnIndex,
        section,
        isLeadImageError,
        activeTab,
    };

    if (!isNil(value) && !isNil(property) && !isNil(property.maxValue) && Number(value) > property.maxValue) {
        return [error];
    }

    return [];
};

export const validateMinProperty = ({
    id,
    value,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    isLeadImageError,
    activeTab,
    property,
}: ValidatePropertyParams): ValidationError[] => {
    const error = {
        id,
        message: `Minimum allowed value is ${property?.minValue}`,
        pageIndex,
        rowIndex,
        columnIndex,
        section,
        isLeadImageError,
        activeTab,
    };

    if (!isNil(value) && !isNil(property) && !isNil(property.minValue) && Number(value) < property.minValue) {
        return [error];
    }

    return [];
};

export const validateRequiredProperty = ({
    id,
    value,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    isLeadImageError,
    activeTab,
}: ValidatePropertyParams): ValidationError[] => {
    const error = {
        id,
        message: "This is a required field. Please don't leave it empty.",
        pageIndex,
        rowIndex,
        columnIndex,
        section,
        isLeadImageError,
        activeTab,
    };

    // validate empty value
    if (isNil(value)) {
        return [error];
    }

    // validate string
    if (isString(value) && isNullOrWhitespace(value)) {
        return [error];
    }

    return [];
};

export const validateUniquePageName = ({
    id,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    utilityConfig,
}: ValidatePropertyParams): ValidationError[] => {
    const pageTitles = (config?.content?.pages ?? []).map((page) => {
        if (isEmpty(page.title)) {
            return (utilityConfig?.content?.pages ?? []).find((p) => p.link === page.link)?.title;
        }

        return page.title;
    });

    if (pageTitles.filter((title) => title === value).length > 1) {
        return [
            {
                id,
                message: "This Page Name is already in use by another page.",
                pageIndex,
                rowIndex,
                columnIndex,
                section,
            },
        ];
    }

    return [];
};

export const validateUniquePageUrl = ({
    id,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
}: ValidatePropertyParams): ValidationError[] => {
    if (value.startsWith("/duplicate_page_link")) {
        return [
            {
                id,
                message: "This URL is already in use by another page.",
                pageIndex,
                rowIndex,
                columnIndex,
                section,
            },
        ];
    }

    return [];
};

export const validateExternalInternalLeadImageButton = ({
    id,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    isLeadImageError,
}: ValidatePropertyParams): ValidationError[] => {
    const homePage = config?.content?.pages.find((p) => p.link === "/");
    const isInternalLink = homePage?.leadImage.linkType === LinkType.INTERNAL || isNil(homePage?.leadImage.linkType);

    if (isString(value) && value.length > 0) {
        if (isInternalLink && !value.startsWith("/") && value !== "*") {
            return [
                {
                    id,
                    message: "The URL has to start with a forward slash, i.e., /contact-us, /sign-in.",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                    isLeadImageError,
                },
            ];
        }

        if (!isInternalLink && !value.startsWith("https://") && !value.startsWith("http://")) {
            return [
                {
                    id,
                    message: "The URL has to start with a https:// or http://",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                    isLeadImageError,
                },
            ];
        }
    }
    return [];
};

export const validateExternalInternalPageLinks = ({
    id,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
}: ValidatePropertyParams): ValidationError[] => {
    const pages = config?.content?.pages ?? [];
    if (!isNil(pageIndex) && pages[pageIndex]) {
        if (pages[pageIndex].linkType === LinkType.INTERNAL) {
            if (isString(value) && value.length > 0 && !value.startsWith("/") && value !== "*") {
                return [
                    {
                        id,
                        message: "The URL of a page has to start with a forward slash, i.e., /contact-us, /sign-in.",
                        pageIndex,
                        rowIndex,
                        columnIndex,
                        section,
                    },
                ];
            }
        }
        if (pages[pageIndex].linkType === LinkType.EXTERNAL) {
            if (isString(value) && value.length > 0 && !value.startsWith("https://") && !value.startsWith("http://")) {
                return [
                    {
                        id,
                        message: "The URL of a page has to start with a https:// or http://",
                        pageIndex,
                        rowIndex,
                        columnIndex,
                        section,
                    },
                ];
            }
        }
    }

    return [];
};

export const validateExternalInternalWidgetsLink = ({
    id,
    value,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
    widget,
}: ValidatePropertyParams): ValidationError[] => {
    const widgetProps = widget?.props;
    const isInternalLink = [WidgetType.IMAGE, WidgetType.CARD_CONTENT].includes(widget?.type as WidgetType)
        ? widgetProps.imageLinkType === LinkType.INTERNAL || isNil(widgetProps.imageLinkType)
        : widgetProps.linkType === LinkType.INTERNAL || isNil(widgetProps.linkType);

    if (isString(value) && value.length > 0) {
        if (isInternalLink && !value.startsWith("/") && value !== "*") {
            return [
                {
                    id,
                    message: "The URL has to start with a forward slash, i.e., /contact-us, /sign-in.",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                },
            ];
        }

        if (!isInternalLink && !value.startsWith("https://") && !value.startsWith("http://")) {
            return [
                {
                    id,
                    message: "The URL has to start with a https:// or http://",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                },
            ];
        }
    }

    return [];
};

export const validateExternalInternalCookiesLink = ({
    id,
    value,
    config,
    pageIndex,
    rowIndex,
    columnIndex,
    section,
}: ValidatePropertyParams): ValidationError[] => {
    const settings = config?.settings;
    const isInternalLink = settings?.cookiesPageLink === LinkType.INTERNAL || isNil(settings?.cookiesPageLink);

    if (settings && settings.enableCookiesBannerLink && isString(value) && value.length > 0 && !isNil(value)) {
        if (isInternalLink && !value.startsWith("/") && value !== "*") {
            return [
                {
                    id,
                    message: "The URL has to start with a forward slash, i.e., /contact-us, /sign-in.",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                },
            ];
        }

        if (settings?.cookiesPageLink === LinkType.EXTERNAL && !value.startsWith("https://") && !value.startsWith("http://")) {
            return [
                {
                    id,
                    message: "The URL has to start with a https:// or http://",
                    pageIndex,
                    rowIndex,
                    columnIndex,
                    section,
                },
            ];
        }
    }

    return [];
};

export const isPropertyRequired = (validationRules: ValidationRule[] = []) => {
    return validationRules.some((rule) => rule === ValidationRule.Required);
};

export const getTemplateInfoErrors = (errors: ValidationError[]) => {
    const idPrefix = "templateInfo.";
    return getErrorsByIdPrefix(idPrefix, errors).map((error) => removeErrorIdPrefix(error, idPrefix));
};

export const getSettingsErrors = (errors: ValidationError[]) => {
    const idPrefix = "settings.";
    return getErrorsByIdPrefix(idPrefix, errors).map((error) => removeErrorIdPrefix(error, idPrefix));
};

export const getContentPageErrors = (errors: ValidationError[], pageId: number) => {
    const idPrefix = `content.pages[${pageId}].`;
    return getErrorsByIdPrefix(idPrefix, errors).map((error) => removeErrorIdPrefix(error, idPrefix));
};

export const getRowErrors = (errors: ValidationError[], rowPath: string) => {
    return getErrorsByIdPrefix(rowPath, errors).map((error) => removeErrorIdPrefix(error, rowPath));
};

export const getColumnErrors = (errors: ValidationError[], columnPath: string) => {
    return getErrorsByIdPrefix(columnPath, errors).map((error) => removeErrorIdPrefix(error, columnPath));
};

export const getLeadImageErrors = (errors: ValidationError[], config: PortalTemplateConfiguration) => {
    const homePageIndex = config?.content?.pages.findIndex((p: PageConfiguration) => p.link === "/");
    if (isNumber(homePageIndex)) {
        const path = `content.pages[${homePageIndex}].leadImage.`;
        return getErrorsByIdPrefix(path, errors).map((error) => removeErrorIdPrefix(error, path));
    } else {
        return [];
    }
};

export const getAccessibilityMessages = (messages: ValidationError[]): AccessibilityValidationMessage[] => {
    return [...getAccessibilityErrors(messages), ...getAccessibilityWarnings(messages), ...getAccessibilityNotices(messages)];
};

export const getAccessibilityErrors = (messages: ValidationError[]): AccessibilityValidationMessage[] => {
    return getAccessibilityMessagesByImpact(messages as AccessibilityValidationMessage[], ValidationMessageImpact.Error);
};

export const getAccessibilityWarnings = (messages: ValidationError[]) => {
    return getAccessibilityMessagesByImpact(messages as AccessibilityValidationMessage[], ValidationMessageImpact.Warning);
};

export const getAccessibilityNotices = (messages: ValidationError[]) => {
    return getAccessibilityMessagesByImpact(messages as AccessibilityValidationMessage[], ValidationMessageImpact.Notice);
};

export const getAccessibilityMessagesByImpact = (messages: AccessibilityValidationMessage[], impact: ValidationMessageImpact) => {
    const idPrefix = accessibilityMessagesIdPrefix;
    return sortBy(
        getErrorsByIdPrefix(idPrefix, messages)
            .map((message) => removeErrorIdPrefix(message, idPrefix) as AccessibilityValidationMessage)
            .filter((message: any) => message?.impact === impact),
        (m) => (m.id ?? "").toLocaleLowerCase()
    );
};

export const getErrorsByIdPrefix = (
    idPrefix: string,
    errors: ValidationError[] | AccessibilityValidationMessage[]
): ValidationError[] | AccessibilityValidationMessage[] => {
    return errors.filter(({ id }) => id.startsWith(idPrefix));
};

export const removeErrorIdPrefix = (error: ValidationError, idPrefix: string) => ({ ...error, id: error.id.substr(idPrefix.length) });

/**
 * Convert try catch exception error to validation error.
 *
 * @param exception - try catch error
 * @returns Validation error object
 */
export const getTryCatchError = (exception: unknown): ValidationError => {
    let message = "";

    if (isString(exception)) {
        message = exception;
    } else if (exception instanceof Error) {
        message = exception.message;
    }

    return {
        id: "",
        message,
    };
};

export const getAccessibilityErrorsForExport = (errors: AccessibilityValidationMessage[]): AccessibilityValidationMessageForExport[] => {
    /**
     * Remove duplicates. For example the same header issue in every page.
     * Message is the same if all props are identical ignoring the url.
     */
    const filteredResults = uniqWith(errors, (arrVal, othVal) => isEqual(omit(arrVal, "url"), omit(othVal, "url")));

    const result = filteredResults.map((error) => {
        return {
            IMPACT: error.impact,
            ID: error.id,
            URL: error.url,
            VALIDATOR: error.validator,
            MESSAGE: error.message + (error.description ? `. ${error.description}.` : ""),
            ELEMENTS: error.nodes.map((node) => node.selector).join("\n"),
        };
    });

    return result;
};
