import React, { useState, useEffect } from "react";
import { isNonEmptyString } from "../../../../../utils/form";
import TextInput from "../../../../Input/TextInput";
import IconWrap from "../../../../Icons";
import { escapeEqualSign, unescapeEqualSign } from "components/utils/string";

export const DictionaryItem = ({ value, disabled, onChange, onRemove }) => {
    const [parts, setParts] = useState(["", ""]);

    const displayPart = unescapeEqualSign(parts[0] ?? "");
    const valuePart = unescapeEqualSign(parts[1] ?? "");

    useEffect(() => {
        setParts(value.split("="));
    }, [value]);

    const _onChange1 = (event) => {
        const value = event.target.value;
        setParts([value, parts[1]]);

        if (isNonEmptyString(value) && isNonEmptyString(parts[1])) {
            // Escape equals sign
            const part0 = escapeEqualSign(value).trim();
            const part1 = escapeEqualSign(parts[1]).trim();

            onChange(`${part0}=${part1}`);
        }

        if (!isNonEmptyString(value) && !isNonEmptyString(parts[1])) {
            onChange(`=`);
        }
    };

    const _onChange2 = (event) => {
        const value = event.target.value;
        setParts([parts[0], value]);

        if (isNonEmptyString(value) && isNonEmptyString(parts[0])) {
            // Escape equals sign
            const part0 = escapeEqualSign(parts[0]).trim();
            const part1 = escapeEqualSign(value).trim();

            onChange(`${part0}=${part1}`);
        }

        if (!isNonEmptyString(value) && !isNonEmptyString(parts[0])) {
            onChange(`=`);
        }
    };

    return (
        <>
            <IconWrap className="array-item-remove" icon="clear-close" title="Remove" disabled={disabled} onClick={onRemove} />
            <div className="array-item flex-one align-center">
                <TextInput placeholder="display" labelInside="Display" disabled={disabled} value={displayPart} onChange={_onChange1} />
                <TextInput placeholder="value" labelInside="Value" disabled={disabled} value={valuePart} onChange={_onChange2} />
            </div>
        </>
    );
};
