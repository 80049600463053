import React, { useCallback, useState, useMemo, memo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { modalOpen } from "../../../../../../store/modal/actions";
import { updateResource, createResource } from "../../../../../../store/resources/actions";
import { isNullOrWhitespace, stripHtml } from "components/utils/string";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { submitByRef } from "../../../../../utils/form";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import Button from "../../../../Button";
import NotePreview from "./NotePreview";

import "./NotesPanel.scss";

const NotesEditForm = memo((props) => {
    const { note, applicationNumber, refType = 30, resourceName = "applicationNotes", onSaved, onClose, sidePanel, qcNumber } = props;
    const dispatch = useDispatch();

    const [isSaving, setIsSaving] = useState(false);

    const isEdit = !isNil(note?.noteId) && !props.readOnly;
    const dashboard = useSelector((state) => state.dashboards);
    const qcNote = dashboard.activeDashboardTool[applicationNumber] === "project-control";
    const formRef = useRef();
    const submitText = isSaving ? "Saving..." : "Save";
    const leadBlockIcon = isEdit ? "edit-empty" : props.readOnly ? "eye-visibility-empty" : "plus";

    const schema = {
        type: "object",
        required: ["note", "noteType"],
        properties: {
            note: {
                type: "string",
                title: "Note",
            },
            noteType: {
                type: "string",
                title: "Note Type",
                enum: qcNote ? ["private"] : ["private", "public"],
                default: qcNote ? "private" : "",
            },
        },
    };

    const uiSchema = {
        classNames: "inline-form fill-width",
        "ui:rootFieldId": "note",
        note: {
            classNames: "fill-width",
            "ui:widget": props.readOnly ? (props) => <NotePreview>{props.value}</NotePreview> : "html",
            "ui:toolbar": "lite",
            "ui:forcePasteAsPlainText": true,
        },
        noteType: {
            "ui:widget": "radio",
            "ui:options": {
                inline: true,
            },
        },
    };

    const initialValues = useMemo(() => {
        let value = {};

        if (note) {
            value = {
                ...note,
                noteType: note.noteType?.toLowerCase(),
            };
        }

        return value;
    }, [note]);

    const handleSubmit = useCallback(
        (formData) => {
            const saveNote = (note) => {
                setIsSaving(true);

                if (note.noteId) {
                    dispatch(
                        updateResource({
                            resourceName,
                            resourceId: note.noteId,
                            path: {
                                appId: applicationNumber,
                            },
                            body: note,
                            onSuccess: () => {
                                onSaved();
                            },
                            onError: () => {
                                setIsSaving(false);
                            },
                        })
                    );
                } else {
                    dispatch(
                        createResource({
                            resourceName,
                            path: {
                                appId: applicationNumber,
                                qcNumber,
                            },
                            body: note,
                            onSuccess: () => {
                                onSaved();
                            },
                            onError: () => {
                                setIsSaving(false);
                            },
                        })
                    );
                }
            };

            const note = {
                ...formData,
                refType,
            };

            if (note.noteType === "public") {
                const content = (
                    <>
                        <p>
                            The note is designated as <strong>Public</strong> and client will be notified about it via email.
                        </p>
                        <p>Are you sure you want to do it?</p>
                    </>
                );

                dispatch(
                    modalOpen({
                        modalType: "CONFIRM",
                        modalProps: {
                            title: note.noteId ? "Edit Note" : "Create Note",
                            modalIcon: note.noteId ? "edit-empty" : "plus",
                            overlayClassName: "modal-styled",
                            className: "modal-sm",
                            footerContentCenter: true,
                            content,
                            onConfirm: () => {
                                saveNote(note);
                            },
                        },
                    })
                );
            } else {
                saveNote(note);
            }
        },
        [refType, applicationNumber, resourceName, onSaved, dispatch, qcNumber]
    );

    const handleValidate = useCallback((formData, errors) => {
        if (!isEmpty(formData.note) && isNullOrWhitespace(stripHtml(formData.note))) {
            errors.note.addError("Note cannot be empty or filled with spaces.");
        }

        return errors;
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent>
            <SideNavHeader
                title={isEdit ? "Edit Note" : props.readOnly ? "Note" : "Add Note"}
                leadBlockIcon={leadBlockIcon}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column notes-sidenav-body">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={props.readOnly ? props.readOnly : isSaving}
                    onSubmit={handleSubmit}
                    validate={handleValidate}
                    noReset
                    noCancel
                    noSubmit
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton className={"justify-end"}>
                <Button primary disabled={isSaving} onClick={() => submitByRef(formRef)} hidden={props.saveBtnHidden}>
                    {submitText}
                </Button>

                <Button onClick={onClose}>{props.readOnly ? "Close" : "Cancel"}</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default NotesEditForm;
