import React, { memo } from "react";

import Panel from "../../ui/Form/shared/Program/Panel";

import "./style.scss";

const UtilitiesAndProgramManagementAllPrograms = memo(() => {
    return (
        <div className="all-programs-main-panel fill-height">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <Panel />
            </div>
        </div>
    );
});

export default UtilitiesAndProgramManagementAllPrograms;
