import { useEffect } from "react";
import { createPopper } from "@popperjs/core";
import useUnmounted from "./useUnmounted";

const usePopper = ({ triggerRef, popperRef, popupRef, placement, modifiers, enabled }) => {
    const unmounted = useUnmounted();

    return useEffect(() => {
        const isUnmounted = unmounted.current;

        if (!enabled || isUnmounted) {
            if (popperRef.current) {
                popperRef.current.destroy();
                popperRef.current = null;
            }

            return;
        }

        if (popperRef.current) {
            popperRef.current.destroy();
        }

        popperRef.current = createPopper(triggerRef.current, popupRef.current, {
            placement,
            strategy: "fixed",
            modifiers,
        });

        setTimeout(() => {
            if (!isUnmounted) {
                popperRef.current && popperRef.current.update();
            }
        }, 10);

        return () => {
            if (isUnmounted && popperRef.current) {
                popperRef.current.destroy();
                popperRef.current = null;
            }
        };
    }, [triggerRef, popperRef, popupRef, placement, modifiers, enabled, unmounted]);
};

export default usePopper;
