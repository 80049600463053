import { isNil } from "lodash";
import { hasAnyOfPermissions, systemUserRights } from "./user";

export const getAttributeValue = (name, task) => {
    let result = null;

    if (task.attributes) {
        const attribute = task.attributes.filter((a) => a.attributename === name)[0];

        if (attribute) {
            result = attribute.attributevalue;
        }
    }

    return result;
};

/**
 * Get workflow completed tasks.
 *
 * @param {*} workflowTasks
 * @param {*} completedWorkflowStatuses
 * @returns
 */
export const getCompletedTaskCount = (workflowTasks, completedWorkflowStatuses) => {
    return (
        workflowTasks?.filter((task) => {
            return isTaskCompleted(task) || taskIsInCompletedWorkflowStatusesList(task, completedWorkflowStatuses);
        })?.length ?? 0
    );
};

/**
 * Check if task is present in workflow history.
 * If it is there can consider the task is completed even if completed dates are not set.
 *
 * @param {*} task
 * @param {*} completedWorkflowStatuses
 * @returns
 */
export const taskIsInCompletedWorkflowStatusesList = (task, completedWorkflowStatuses) => {
    return completedWorkflowStatuses
        .filter((status) => status.state === task.state)
        .flatMap((status) => status.tasks)
        .some((t) => t.wfTaskNumber === task.wfTaskNumber);
};

export const isTaskCompleted = (task) => !isNil(task.dateCompleted) || !isNil(task.dateRemoved) || !isNil(task.dateClosed);

export const isTaskRemoved = (task) => !isNil(task.dateRemoved);

export const isTaskReadOnly = (task) => !task.allowAccess && !hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_APPLICATIONS]);

export const isAdhocTask = (task) => task.customStep?.toUpperCase() === "Y";
