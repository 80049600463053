import React, { useState, useContext, useEffect } from "react";
import { SectionHeader } from "../SectionHeader";
import TabList from "components/ui/List/TabList";
import { PropertiesForSection } from "../Section";
import { PortalBuilderContext, PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";
import {
    AlignYPosition,
    LinkType,
    PortalTemplateConfigurationSectionKey,
    PropertyType,
    ValidationRule,
} from "components/ui/PortalBuilder/types";
import { CookiesLinkProperty } from "./CookiesLinkProperty";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";

import "./Cookies.scss";

export const Cookies = (props) => {
    const { onClose } = props;
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const { onConfigChange } = useContext(PortalBuilderContext);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);
    const [activeError] = usePortalBuilderState((state) => state.activeError);
    const [activeSectionErrors] = usePortalBuilderState((state) => state.activeSectionErrors);
    const { sectionTitle, sectionIcon } = useContext(PortalBuilderPropertiesContext);

    // Take cookies related settings and update the id to match property ids.
    const cookiesErrors = activeSectionErrors
        .filter((e) => e.id.toLowerCase().indexOf("cookies") > -1)
        .map((e) => ({ ...e, id: e.id.replace("settings.", "") }));

    // Open "texts" tab if there are errors.
    useEffect(() => {
        setActiveTab((prev) => {
            if (activeError && ["cookiesBannerText", "cookiesBannerLinkUrl"].some((key) => activeError.id.endsWith(key))) {
                return tabs[1];
            }

            return prev;
        });
    }, [activeError]);

    return (
        <div className="portal-builder-cookies-panel flex-column fill-width with-scroll fill-height">
            <SectionHeader Icon={sectionIcon} title={sectionTitle} subtitle="Configure Cookies Panel" onGoBack={onClose} />
            <TabList items={tabs} tabListGhost activeItem={activeTab} onClick={(tab) => setActiveTab(tab)} />
            {activeTab.id === "style" && (
                <PropertiesForSection
                    className="cookies-panel-style-tab"
                    sectionKey={PortalTemplateConfigurationSectionKey.SETTINGS}
                    sectionProperties={COOKIES_STYLE_PROPERTIES}
                    config={updatedConfig}
                    onChange={onConfigChange}
                    errors={cookiesErrors}
                />
            )}
            {activeTab.id === "texts" && (
                <PropertiesForSection
                    className="cookies-panel-texts-tab"
                    sectionKey={PortalTemplateConfigurationSectionKey.SETTINGS}
                    sectionProperties={COOKIES_TEXT_PROPERTIES}
                    config={updatedConfig}
                    onChange={onConfigChange}
                    errors={cookiesErrors}
                />
            )}
        </div>
    );
};

const tabs = [
    {
        id: "style",
        title: "Style",
    },
    {
        id: "texts",
        title: "Texts",
    },
];

export const COOKIES_STYLE_PROPERTIES = [
    {
        id: "cookiesBannerPosition",
        title: "Position",
        type: "icon-selector",
        items: [
            {
                icon: "align-top",
                value: AlignYPosition.Top,
            },
            {
                icon: "align-bottom",
                value: AlignYPosition.Bottom,
            },
        ],
        defaultValue: AlignYPosition.Bottom,
    },
    {
        id: "cookiesBannerOverlay",
        title: "Type: Overlay",
        type: "switch",
        defaultValue: true,
    },
];

export const COOKIES_TEXT_PROPERTIES = [
    {
        id: "cookiesBannerText",
        title: "Text",
        type: "textarea",
        defaultValue: "This site uses cookies to learn about you and enhance your experience",
        validationRules: [ValidationRule.Required],
    },
    {
        id: "cookies",
        type: PropertyType.CustomComponent,
        component: CookiesLinkProperty,
        propertiesGroup: [
            {
                id: "enableCookiesBannerLink",
                title: 'Enable "Read More" link',
                type: "switch",
                defaultValue: true,
            },
            {
                id: "cookiesPageLink",
                defaultValue: LinkType.INTERNAL,
            },
            {
                id: "cookiesBannerLinkUrl",
                defaultValue: "/cookies",
                validationRules: [ValidationRule.Required, ValidationRule.ExternalInternalCookiesLink],
            },
            {
                id: "cookiesBannerLinkOpenInNewTab",
            },
        ],
    },
];
