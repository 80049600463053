import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { downloadFile, deleteFile } from "../../../../../../store/files/actions";
import { getData } from "../../../../../../store/dataGrid/actions";
import { programsFilesGridColumnKeys } from "../../../../../views/configureGrids";
import withDataGrid from "../../../../DataGrid/withDataGrid.js";

import DataGrid from "../../../../DataGrid";
import { isChildProgram } from "components/views/ProgramView/utils";

const FilesGrid = memo(({ gridId, dataGridConfig, programNumber }) => {
    const dispatch = useDispatch();
    const isLocked = isChildProgram({ programNumber });

    const customRowActions = [
        {
            name: "download",
            title: "Download File",
            icon: "download",
        },
        {
            name: "delete",
            title: "Delete File",
            icon: "delete-trash-empty",
            disabled: isLocked,
        },
    ];

    const onRowAction = (action, onExpand) => {
        const { name, dataItem } = action;

        const file = {
            fileId: dataItem[programsFilesGridColumnKeys.fileId],
            fileName: dataItem[programsFilesGridColumnKeys.fileName],
        };

        switch (name) {
            case "download":
                dispatch(downloadFile(file));
                break;

            case "delete":
                dispatch(
                    deleteFile({
                        ...file,
                        onDeleteSuccess: () => {
                            dispatch(getData({ dataGridId: gridId }));
                        },
                    })
                );
                break;

            default:
                break;
        }
    };

    return <DataGrid name={gridId} config={dataGridConfig} onRowAction={onRowAction} customRowActions={customRowActions} />;
});

export default withDataGrid("programsFiles", FilesGrid);
