import React, { memo } from "react";

import { getCatalogItemsHistoryGridId } from "../utils";
import { programsCatalogHistoryGridColumnKeys } from "../../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import Grid from "./Grid";
import Controls from "./Controls";

const CatalogHistoryTab = memo(({ programNumber }) => {
    const dataGridId = getCatalogItemsHistoryGridId({ programNumber });

    return (
        <div className="program-catalog-tab flex-one-in-column no-scroll">
            <Controls
                onExport={() =>
                    exportDatagridToCSV({
                        dataGridId,
                        fileName: "program_catalog_history",
                        fileNamePostfix: programNumber,
                    })
                }
            />
            <Grid gridId={dataGridId} filter={`${programsCatalogHistoryGridColumnKeys.id}=${programNumber}`} />
        </div>
    );
});

export default CatalogHistoryTab;
