import React, { useState, useRef, useEffect } from "react";
import { isNil, omit } from "lodash";
import { pickInitialValues, submitResource, listToAnyOf, isNonEmptyString, submitByRef, referenceToAnyOf } from "../../utils/form";
import WaitIcon from "../../ui/WaitIcon";
import JsonSchemaForm from "../../ui/Form/JsonSchema/JsonSchemaFormV2";
import Associations from "./Associations";
import {
    getResourceName,
    useUserGroup,
    onUpdateAssociation,
    useUserGroupUsers,
    useUserGroupPrograms,
    useUserGroupUtilities,
    getGroupList,
} from "./utils";
import SideNavContent from "components/ui/SideNav/SideNavContent";
import SideNavHeader from "components/ui/SideNav/SideNavHeader";
import SideNavBody from "components/ui/SideNav/SideNavBody";
import SideNavFooter from "components/ui/SideNav/SideNavFooter";
import Button from "components/ui/Button";
import { referenceTypes } from "components/ui/Reference/referenceTypes";
import { useReference } from "components/ui/Reference/useReference";
import { getReferenceValueByName } from "components/ui/Reference/utils";
import { STATUS_ACTIVE } from "components/utils/constants";
import { ErrorMessage } from "components/ui/Message";

const FormPreload = (props) => {
    const { utilityNumber, dataItem } = props;
    const groupNumber = dataItem?.groupNumber;

    const [userList = [], isLoadingUsers] = useUserGroupUsers({
        utilityNumber,
        groupNumber,
    });
    const [programList = [], isLoadingPrograms] = useUserGroupPrograms({
        utilityNumber,
        groupNumber,
    });
    const [utilityList = [], isLoadingUtilities] = useUserGroupUtilities({
        groupNumber,
    });

    const [resource, isLoadingResource] = useUserGroup({
        utilityNumber,
        groupNumber,
    });

    const [userGroupTypes = [], isLoadingGroupTypes] = useReference(referenceTypes.groupType);
    const [userGroupStatus = [], isLoadingGroupStatus] = useReference(referenceTypes.genericStatus);

    if (isLoadingResource || isLoadingGroupTypes || isLoadingGroupStatus) {
        return <WaitIcon />;
    }

    if (
        (userList.length === 0 && isLoadingUsers) ||
        (programList.length === 0 && isLoadingPrograms) ||
        (utilityList.length === 0 && isLoadingUtilities)
    ) {
        return <WaitIcon />;
    }

    return (
        <Form
            {...props}
            resource={resource}
            userGroupTypes={userGroupTypes}
            userGroupStatus={userGroupStatus}
            userList={userList}
            programList={programList}
            utilityList={utilityList}
        />
    );
};

const Form = (props) => {
    const formRef = useRef();

    const {
        resource,
        userGroupTypes,
        userGroupStatus,
        utilityNumber,
        dataItem,
        addChild,
        onClose,
        gridRefresh,
        sidePanel,
        userList,
        programList,
        utilityList,
    } = props;

    const isNew = dataItem === undefined;
    const isCreateForm = isNew || addChild;

    const resourceId = isCreateForm ? null : dataItem.groupNumber;

    const parentId = isNew ? undefined : addChild ? dataItem.groupNumber : resource.parentGroupNumber;

    const title = isNew ? "Add User Group" : addChild ? "Add Child User Group" : "Edit User Group";

    const [isSubmitting, setSubmitting] = useState(false);

    const userGroupList = getGroupList({ utilityNumber });

    const [updatedUsers, setUpdatedUsers] = useState(userList.filter((i) => i.assigned));
    const [updatedPrograms, setUpdatedPrograms] = useState(programList.filter((i) => i.assigned));
    const [updatedUtilities, setUpdatedUtilities] = useState(utilityList.filter((i) => i.assigned));

    const titleIcon = isNew || addChild ? "plus" : "edit-empty";
    const submitText = isSubmitting ? "Saving..." : "Save";

    const notFound = !isCreateForm && isNil(resource);
    const errorText = "User group not found";

    const schema = {
        type: "object",
        required: ["groupName", "groupType", "status"],
        properties: {
            groupName: {
                type: "string",
                title: "Group Name",
            },
            groupType: {
                type: "integer",
                title: "Group Type",
                anyOf: referenceToAnyOf({
                    list: userGroupTypes,
                }),
            },
            status: {
                type: "integer",
                title: "Status",
                anyOf: referenceToAnyOf({
                    list: userGroupStatus,
                }),
                default: Number(
                    getReferenceValueByName({
                        reference: userGroupStatus,
                        displayName: STATUS_ACTIVE,
                    })
                ),
            },
            parentGroupNumber: {
                type: "string",
                title: "Parent Group",
                anyOf: listToAnyOf({
                    list: addChild ? userGroupList : userGroupList.filter((item) => dataItem?.groupNumber !== item.groupNumber),
                    map: (item) => ({
                        title: item.groupName,
                        enum: [item.groupNumber],
                    }),
                }),
            },
            groupDescription: {
                type: "string",
                title: "Group Description",
                maxLength: 300,
            },
            ...(isCreateForm
                ? {}
                : {
                      associations: {
                          type: "string",
                      },
                  }),
        },
    };

    const uiSchema = {
        parentGroupNumber: {
            "ui:disabled": userGroupList.length === 0,
            "ui:options": {
                emptyItem: true,
            },
        },
        groupDescription: {
            classNames: "fill-width",
            "ui:widget": "textarea",
        },
        ...(isCreateForm
            ? {}
            : {
                  associations: {
                      classNames: "assignments",
                      "ui:widget": () => (
                          <Associations
                              utilityNumber={utilityNumber}
                              dataItem={dataItem}
                              users={updatedUsers}
                              programs={updatedPrograms}
                              utilities={updatedUtilities}
                              onChangeUsers={setUpdatedUsers}
                              onChangePrograms={setUpdatedPrograms}
                              onChangeUtilities={setUpdatedUtilities}
                          />
                      ),
                      "ui:options": {
                          displayLabel: false,
                      },
                  },
              }),
    };

    const initialValues = isCreateForm
        ? { parentGroupNumber: parentId }
        : pickInitialValues({
              ...resource,
              parentGroupNumber: parentId,
          });

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: getResourceName({ utilityNumber }),
            path: isNil(utilityNumber)
                ? undefined
                : {
                      utilityNumber,
                  },
        };

        const body = {
            ...omit(formData, ["associations"]),
        };

        const handleSuccess = () => {
            if (resourceId) {
                if (updatedUsers) {
                    onUpdateAssociation({
                        resourceName: isNil(utilityNumber) ? "groupUsers" : "utilityGroupUsers",
                        utilityNumber,
                        groupNumber: resourceId,
                        list: updatedUsers.map((i) => i.userNumber),
                    });
                }

                if (updatedPrograms) {
                    onUpdateAssociation({
                        resourceName: isNil(utilityNumber) ? "groupPrograms" : "utilityGroupPrograms",
                        utilityNumber,
                        groupNumber: resourceId,
                        list: updatedPrograms.map((i) => i.progId),
                    });
                }

                if (isNil(utilityNumber) && updatedUtilities) {
                    onUpdateAssociation({
                        resourceName: "groupUtilities",
                        groupNumber: resourceId,
                        list: updatedUtilities.map((u) => u.utilityNumber),
                    });
                }
            }

            sidePanel ? sidePanel.close() : onClose();
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: gridRefresh,
            onSuccess: handleSuccess,
            setSubmitting,
        });
    };

    const onValidate = (formData, errors) => {
        if (!isNonEmptyString(formData.groupName)) {
            errors.groupName.addError("Invalid field value");
        }

        return errors;
    };

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className="system-user-groups-panel__form edit-user-group">
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody>
                {notFound ? (
                    <ErrorMessage>{errorText}</ErrorMessage>
                ) : (
                    <JsonSchemaForm
                        key={updatedUsers.length + updatedPrograms.length + updatedUtilities.length}
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        disabled={isSubmitting}
                        onSubmit={onSubmit}
                        validate={onValidate}
                        noSubmit
                        noCancel
                        noReset
                    />
                )}
            </SideNavBody>
            {!notFound && (
                <SideNavFooter setPrimaryButton>
                    <Button primary onClick={() => submitByRef(formRef)} disabled={isSubmitting}>
                        {submitText}
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
};

export default FormPreload;
