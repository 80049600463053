import React, { useCallback, useRef } from "react";
import DropDownInput from "components/ui/Input/DropDownInput";
import { PropertyListItem } from "../Property/PropertyListItem";
import { isEmpty } from "lodash";
import cn from "classnames";
import PropertyErrors from "./PropertyErrors";
import { useShowError } from "../../utils";
import { isPropertyRequired } from "../../utils/validation";
import { PropertyTitle } from "./PropertyTitle";

import "./SelectProperty.scss";

export const SelectProperty = (props) => {
    const {
        title,
        id,
        items,
        defaultValue = undefined,
        value,
        onChange,
        inline = true,
        withPopper = true,
        placeholder,
        borderBottom,
        className,
        errors,
        listContainsActiveError,
        fullWidth,
        emptyItem = true,
        validationRules,
    } = props;
    const elementRef = useRef(null);

    const required = isPropertyRequired(validationRules);
    const lightUpError = useShowError(elementRef, listContainsActiveError, id);

    const onSelect = useCallback(
        (event) => {
            onChange(id, event.target.value);
        },
        [id, onChange]
    );

    const innerClasses = cn("property-list-item-inner align-center", {
        "flex-column full-width-property": fullWidth,
        "flex-row justify-space-between": !fullWidth,
    });

    return (
        <PropertyListItem className={cn(className, "property-list-item--select")} borderBottom={borderBottom}>
            <div ref={elementRef} className={innerClasses}>
                {title && <PropertyTitle title={title} required={required} />}
                <DropDownInput
                    lightUpError={lightUpError}
                    inline={inline}
                    className={cn("property-list-item-value", {
                        "no-margin-left": inline === false,
                    })}
                    error={errors.length > 0}
                    emptyItem={emptyItem}
                    emptyItemLabel="Not selected"
                    placeholder={placeholder ?? "-- SELECT --"}
                    data={items}
                    value={isEmpty(value) ? defaultValue : value}
                    onChange={onSelect}
                    withPopper={withPopper}
                />
            </div>
            <PropertyErrors errors={errors} />
        </PropertyListItem>
    );
};
