import React, { useCallback, useContext, useEffect } from "react";
import { isNil, isFunction, isEmpty } from "lodash";
import { RowContext } from "components/ui/PortalBuilder/contexts";
import cn from "classnames";
import { PropertyTitle } from "./PropertyTitle";
import { isPropertyRequired } from "../../utils/validation";
import { PropertyListItem } from "./PropertyListItem";
import IconWrap from "components/ui/Icons";
import { openConfirmModal } from "components/ui/Modal/utils";
import { usePortalBuilderStateSetter } from "../../PortalBuilderContextProvider";

export const SelectPanelProperty = (props) => {
    const {
        className,
        id,
        title,
        value,
        defaultValue,
        selectIcon,
        selectTooltip,
        panelComponent,
        valueComponent,
        onChange,
        selectComponent,
        alwaysShow,
        borderBottom,
        borderTop,
        validationRules,
        noPostfix,
        disabled,
        showConfirmModal,
        confirmModalMessage,
        confirmModalTitle,
    } = props;
    const setPortalBuilderState = usePortalBuilderStateSetter();
    const { row } = useContext(RowContext);
    const Panel = panelComponent;
    const SelectComponent = selectComponent;
    const SelectedValue = valueComponent;
    const showSelectedValue = alwaysShow || (!isNil(value) && !isNil(SelectedValue));

    const selectIconTooltip = isFunction(selectTooltip) ? selectTooltip(!isNil(value)) : selectTooltip;
    const required = isPropertyRequired(validationRules);

    const onOpenPanel = useCallback(() => {
        const onClosePanel = () => setPortalBuilderState({ activeConfigPanel: null });
        if (showConfirmModal && !isNil(confirmModalTitle)) {
            openConfirmModal({
                title: confirmModalTitle,
                modalIcon: selectIcon,
                message: confirmModalMessage,
                onConfirm: () => {
                    setPortalBuilderState({ activeConfigPanel: <Panel {...props} onClose={onClosePanel} row={row} /> });
                },
            });
        } else {
            setPortalBuilderState({ activeConfigPanel: <Panel {...props} onClose={onClosePanel} row={row} /> });
        }
    }, [showConfirmModal, confirmModalTitle, selectIcon, confirmModalMessage, setPortalBuilderState, props, row]);

    const onRemove = useCallback(() => {
        onChange(id, undefined);
    }, [id, onChange]);

    useEffect(() => {
        if (isEmpty(value) && !isEmpty(defaultValue)) {
            onChange(id, defaultValue);
        }
    }, [id, onChange, value, defaultValue]);

    return (
        <PropertyListItem className={cn("property-list-item--select-panel", className)} borderTop={borderTop} borderBottom={borderBottom}>
            <div className="property-list-item-inner align-center">
                <div className="flex-row justify-space-between align-center">
                    {!isEmpty(title) && <PropertyTitle title={title} required={required} noPostfix={noPostfix} />}
                    <div className={cn({ "flex-row align-center": disabled })}>
                        {disabled && <IconWrap icon="block-not-interested-empty" title="The color change is not possible"></IconWrap>}
                        <SelectComponent
                            {...props}
                            id={id}
                            onChange={onChange}
                            value={value}
                            className="property-list-item-select-icon"
                            icon={selectIcon}
                            title={selectIconTooltip}
                            onClick={!disabled ? onOpenPanel : undefined}
                            disabled={disabled}
                        />
                    </div>
                </div>
                {showSelectedValue && (
                    <div className="property-list-item-selected-value">
                        <SelectedValue onChange={onChange} value={value} row={row} onRemove={onRemove} />
                    </div>
                )}
            </div>
        </PropertyListItem>
    );
};
