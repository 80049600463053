import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ContentPanel from "components/ui/Dashboard/Panel/ContentPanel";
import Switcher from "components/ui/Switcher";
import SelectBox from "components/ui/SelectBox";
import Checkbox from "components/ui/Input/Checkbox";
import TextLabel from "components/ui/Label/TextLabel";
import Label from "components/ui/Label";
import {
    isCompletedAdHocTask,
    isCompletedAutomatedTask,
    isCompletedTask,
    isSkippedTask,
    isTodoAdHocTask,
    isTodoAutomatedTask,
    isTodoDependentTask,
    isToDoTask,
    DEFAULT_WORKFLOW_FILTER,
} from "../utils";
import { TaskWorkflowContext } from "../context/TaskWorkflowContext";

import "./WorkflowFilter.scss";

export const WorkflowFilter = memo(({ onChange }) => {
    const { workflowTasks, workflowHistory } = useContext(TaskWorkflowContext);

    const [state, setState] = useState(DEFAULT_WORKFLOW_FILTER);

    const onChangeFilter = useCallback(
        (filterName) => () => {
            setState((prev) => {
                if (filterName === "showHistory" && !prev.showHistory && !prev.showCompletedTasks) {
                    return {
                        ...prev,
                        showCompletedTasks: true,
                        showHistory: true,
                    };
                }

                return {
                    ...prev,
                    [filterName]: !prev[filterName],
                };
            });
        },
        []
    );

    useEffect(() => {
        setTimeout(() => onChange(state));
    }, [state, onChange]);

    const hasHistory = workflowHistory.length !== 0;

    const filterBlocks = useMemo(() => {
        const isToDo = (t) => isToDoTask(t) && !isTodoAdHocTask(t) && !isTodoDependentTask(t) && !isTodoAutomatedTask(t);
        const isCompleted = (t) => isCompletedTask(t) && !isCompletedAdHocTask(t) && !isCompletedAutomatedTask(t) && !isSkippedTask(t);

        return (
            [
                {
                    title: "To Do tasks",
                    items: [
                        {
                            title: "Task",
                            active: state.showTodoTasks,
                            hidden: !workflowTasks.some((t) => isToDo(t)),
                            onClick: onChangeFilter("showTodoTasks"),
                        },
                        {
                            title: "Ad-hoc",
                            active: state.showTodoAdHocTasks,
                            hidden: !workflowTasks.some((t) => isTodoAdHocTask(t)),
                            onClick: onChangeFilter("showTodoAdHocTasks"),
                        },
                        {
                            title: "Dependent",
                            active: state.showTodoDependentTasks,
                            hidden: !workflowTasks.some((t) => isTodoDependentTask(t)),
                            onClick: onChangeFilter("showTodoDependentTasks"),
                        },
                        {
                            title: "Automated",
                            active: state.showTodoAutomatedTasks,
                            hidden: !workflowTasks.some((t) => isTodoAutomatedTask(t)),
                            onClick: onChangeFilter("showTodoAutomatedTasks"),
                        },
                    ],
                },
                {
                    title: "Completed tasks",
                    items: [
                        {
                            title: "Task",
                            active: state.showCompletedTasks,
                            hidden:
                                !workflowHistory.some((status) => status.tasks.some((t) => isCompleted(t))) &&
                                !workflowTasks.some((t) => isCompleted(t)),
                            onClick: onChangeFilter("showCompletedTasks"),
                        },
                        {
                            title: "Ad-hoc",
                            active: state.showCompletedAdHocTasks,
                            hidden:
                                !workflowHistory.some((status) => status.tasks.some((t) => isCompletedAdHocTask(t))) &&
                                !workflowTasks.some((t) => isCompletedAdHocTask(t)),
                            onClick: onChangeFilter("showCompletedAdHocTasks"),
                        },
                        {
                            title: "Automated",
                            active: state.showCompletedAutomatedTasks,
                            hidden:
                                !workflowHistory.some((status) => status.tasks.some((t) => isCompletedAutomatedTask(t))) &&
                                !workflowTasks.some((t) => isCompletedAutomatedTask(t)),
                            onClick: onChangeFilter("showCompletedAutomatedTasks"),
                        },
                    ],
                },
                {
                    items: [
                        {
                            title: "Skipped",
                            active: state.showSkippedTasks,
                            hidden:
                                !workflowHistory.some((status) => status.tasks.some((t) => isSkippedTask(t))) &&
                                !workflowTasks.some((t) => isSkippedTask(t)),
                            onClick: onChangeFilter("showSkippedTasks"),
                        },
                    ],
                },
            ]
                // Filter out empty filter groups
                .filter((b) => b.items.some((i) => !i.hidden))
                // Filter out hidden filters
                .map((b) => ({
                    ...b,
                    items: b.items.filter((i) => !i.hidden),
                }))
        );
    }, [state, workflowTasks, workflowHistory, onChangeFilter]);

    return (
        <ContentPanel className="workflow-tasks-filter flex-column">
            <h2 className="workflow-title">Workflow</h2>
            {hasHistory && (
                <div className="workflow-tasks-show-history">
                    <Label>Show History</Label>
                    <Switcher active={state.showHistory} icon="check-done" onClick={onChangeFilter("showHistory")} />
                </div>
            )}
            {filterBlocks.map((filter, index) => (
                <FilterBlock key={index} filter={filter} />
            ))}
        </ContentPanel>
    );
});

const FilterBlock = ({ filter }) => {
    return (
        <div className="workflow-tasks-filter-block">
            {filter.title && <TextLabel className="workflow-tasks-filter-block-title">{filter.title}</TextLabel>}
            <div className="workflow-tasks-filter-block-items">
                {filter.items.map((item, index) => (
                    <SelectBox key={index} selectBoxSelected={item.active} onClick={item.onClick}>
                        <Checkbox label={item.title} checked={item.active} onChange={item.onClick} />
                    </SelectBox>
                ))}
            </div>
        </div>
    );
};
