import React from "react";
import { useClientRoleRights } from "../../../../store/resources/useResource";
import WaitIcon from "../../../ui/WaitIcon";
import CustomList from "../../../ui/List/CustomList";
import { UserRole } from "./types";

const headers = {
    clearanceCode: "Clearance Code",
    clearance: "Clearance",
};

const RoleRights = ({ role }: RoleRightsProps) => {
    const [rights, isLoading] = useClientRoleRights({
        clientRoleId: role.roleID,
    });

    if (isLoading) {
        return <WaitIcon />;
    }

    if (rights?.length === 0) {
        return <div>No rights</div>;
    }

    return (
        // @ts-ignore
        <CustomList className="no-scroll fill-height" items={rights} headers={headers} renderHeaders={Header} renderItem={ListItem} />
    );
};

interface RoleRightsProps {
    role: UserRole;
}

const Header = () => {
    return (
        <>
            <div>{headers.clearanceCode}</div>
            <div>{headers.clearance}</div>
        </>
    );
};

const ListItem = ({ clearanceCode, clearance }: ListItemProps) => {
    return (
        <div className="list-item-row">
            <div>{clearanceCode}</div>
            <div>{clearance}</div>
        </div>
    );
};

interface ListItemProps {
    clearanceCode: string;
    clearance: string;
    rightId: number;
}

export default RoleRights;
