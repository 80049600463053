import React, { useState } from "react";

import DropDownInput from "../../../ui/Input/DropDownInput";
import DropDownMultiSelect from "../../../ui/Input/DropDownMultiSelect";

const Dropdowns = () => {
    const dropdownListItems1 = Array.from(Array(10).keys()).map((item, index) => ({
        label: index === 5 ? "Item Label Data Long Name Long Name Long Name Long Name" : "Item Label " + index,
        value: index,
    }));

    const dropdownListItems2 = Array.from(Array(5).keys()).map((item, index) => ({
        label: "Item Label " + index,
        value: index,
    }));

    const dropdownListItems3 = Array.from(Array(5).keys()).map((item, index) => ({
        label: "Item Label " + index,
        value: index,
    }));

    const dropdownListItems4 = Array.from(Array(5).keys()).map((item, index) => ({
        label: "Item Label " + index,
        value: index,
    }));

    const [dropdownListItems1Value, setDropdownListItems1Value] = useState();
    const [dropdownListItems2Value, setDropdownListItems2Value] = useState();
    const [dropdownListItems3Value, setDropdownListItems3Value] = useState();
    const [dropdownListItems4Value, setDropdownListItems4Value] = useState();
    const [dropdownListItems5Value, setDropdownListItems5Value] = useState();
    const [dropdownListItems6Value, setDropdownListItems6Value] = useState();

    return (
        <div className="flexbox-row">
            <div className="flexbox-col">
                <div className="ui-library-sub-title">Standard / In Forms</div>
                <div className="row">
                    <DropDownInput
                        label="Dropdown"
                        placeholder="Select"
                        mobileHeader="Select"
                        data={dropdownListItems1}
                        value={dropdownListItems1Value}
                        onChange={(e) => setDropdownListItems1Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Dropdown Selected"
                        placeholder="Item Label Selected"
                        mobileHeader="Select"
                        data={dropdownListItems2}
                        value={dropdownListItems2Value}
                        onChange={(e) => setDropdownListItems2Value(e.target.value)}
                        msgInfo
                        msgText="Tips or Information"
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Disabled Dropdown"
                        mobileHeader="Select"
                        disabled
                        placeholder="Item"
                        data={dropdownListItems3}
                        value={dropdownListItems3Value}
                        onChange={(e) => setDropdownListItems3Value(e.target.value)}
                        msgInfo
                        msgText="Tips or Information"
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Read Only Dropdown"
                        mobileHeader="Select"
                        readOnly
                        placeholder="Item"
                        data={dropdownListItems3}
                        value={dropdownListItems3Value}
                        onChange={(e) => setDropdownListItems3Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Error Dropdown"
                        mobileHeader="Select"
                        required
                        placeholder="Select"
                        data={dropdownListItems4}
                        value={dropdownListItems4Value}
                        onChange={(e) => setDropdownListItems4Value(e.target.value)}
                        msgError
                        msgText="Field is required, please don't leave it empty"
                    />
                </div>
            </div>

            <div className="flexbox-col">
                <div className="ui-library-sub-title">On Widgets</div>
                <div className="row">
                    <DropDownInput
                        label="Raw Dropdown"
                        mobileHeader="Select"
                        placeholder="Select"
                        data={dropdownListItems1}
                        value={dropdownListItems1Value}
                        onChange={(e) => setDropdownListItems1Value(e.target.value)}
                        noBorders
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Raw Dropdown Selected"
                        placeholder="Item Label Selected"
                        mobileHeader="Select"
                        data={dropdownListItems2}
                        value={dropdownListItems2Value}
                        onChange={(e) => setDropdownListItems2Value(e.target.value)}
                        noBorders
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Raw Dropdown Disabled"
                        disabled
                        placeholder="Disaled"
                        mobileHeader="Select"
                        data={dropdownListItems3}
                        value={dropdownListItems3Value}
                        onChange={setDropdownListItems3Value}
                        noBorders
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Raw Dropdown Read Only"
                        readOnly
                        placeholder="Read Only"
                        mobileHeader="Select"
                        data={dropdownListItems3}
                        value={dropdownListItems3Value}
                        onChange={setDropdownListItems3Value}
                        noBorders
                    />
                </div>
                <div className="row">
                    <DropDownInput
                        label="Raw Dropdown Error"
                        required
                        placeholder="Select"
                        mobileHeader="Select"
                        data={dropdownListItems3}
                        value={dropdownListItems3Value}
                        onChange={setDropdownListItems3Value}
                        noBorders
                        msgError
                        msgText="Field is required, please don't leave it empty"
                    />
                </div>
            </div>
            <div className="flexbox-col">
                <div className="ui-library-sub-title">Mutiple Choices List</div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Mutiple Choices (Placeholder) with popper"
                        mobileHeader="Multiple Select"
                        placeholder="Multiple Select"
                        withPopper
                        data={dropdownListItems1}
                        value={dropdownListItems5Value}
                        onChange={(e) => setDropdownListItems5Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Mutiple Choices (Placeholder)"
                        mobileHeader="Multiple Select"
                        placeholder="Multiple Select"
                        data={dropdownListItems1}
                        value={dropdownListItems5Value}
                        onChange={(e) => setDropdownListItems5Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Multiple Choices (Selected)"
                        mobileHeader="Multiple Select"
                        placeholder="Multiple Select"
                        data={dropdownListItems2}
                        value={dropdownListItems6Value}
                        onChange={(e) => setDropdownListItems6Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Multiple Choices (Disabled)"
                        mobileHeader="Multiple Select"
                        disabled
                        placeholder="Multiple Select"
                        data={dropdownListItems2}
                        value={dropdownListItems6Value}
                        onChange={(e) => setDropdownListItems6Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Multiple Choices (Read Only)"
                        mobileHeader="Multiple Select"
                        readOnly
                        placeholder="Multiple Select"
                        data={dropdownListItems2}
                        value={dropdownListItems6Value}
                        onChange={(e) => setDropdownListItems6Value(e.target.value)}
                    />
                </div>
                <div className="row">
                    <DropDownMultiSelect
                        label="Multiple Choices (Error)"
                        mobileHeader="Multiple Select"
                        required
                        placeholder="Select"
                        data={dropdownListItems4}
                        value={dropdownListItems4Value}
                        onChange={(e) => setDropdownListItems4Value(e.target.value)}
                        msgError
                        msgText="Field is required, please don't leave it empty"
                    />
                </div>
            </div>
            <div className="flexbox-col"></div>
        </div>
    );
};

export default Dropdowns;
