import React from "react";
import ExpandablePanel from "../ExpandablePanel";

import "./style.scss";

export const SelectedItemsExpandablePanel = ({ items, itemsTitle, itemTitle }) => {
    const itemsCount = items?.length ?? 0;

    if (itemsCount === 0) {
        return null;
    }

    let content = null;

    if (itemsCount > 1) {
        content = (
            <ExpandablePanel className="flex-row align-center" title={`${itemsTitle} (${items?.length})`}>
                <ul className="selected-items-list">
                    {items.map((item) => (
                        <li>{item}</li>
                    ))}
                </ul>
            </ExpandablePanel>
        );
    } else {
        content = (
            <div>
                <b>{itemTitle}:</b> {items[0]}
            </div>
        );
    }

    return <div className="selected-items-expandable-panel flex-column">{content}</div>;
};
