import React, { memo } from "react";

import { getInvoiceNotesGridId } from "../utils";
import { SortType, mapGridRowToObject } from "../../../../../utils/datagrid";
import { availableGrids } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import WaitIcon from "../../../../WaitIcon/index";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import { useDataGrid } from "components/utils/useDataGrid";

const invoiceNotesGridColumnKeys = {
    noteNumber: "c_78631f1b-eaee-4083-ab31-6c5ef50e31f9",
    noteType: "c_512cb40e-8f63-4b2a-b178-eb8b08992679",
    note: "c_b0cb49a0-2b2b-4ea2-b4f0-ae0afc985922",
    userName: "c_97c7e2b8-ef1f-41e0-a3f1-f8f7b381583d",
    dateEntered: "c_1bd67e29-6644-4646-b522-86c2fae2f037",
};

const rowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit Note",
    },
];

const Grid = memo(({ utilityNumber, contractNumber, documentNumber, onRefresh, onAdd }) => {
    const dataGridId = getInvoiceNotesGridId({ documentNumber });

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.invoiceNotes,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "invoiceNotesGridGetDataAction",
            props: {
                columnKeysMap: invoiceNotesGridColumnKeys,
                utilityNumber,
                contractNumber,
                documentNumber,
            },
        },
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "invoice-notes-sidenav-panel",
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(invoiceNotesGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        documentNumber={documentNumber}
                        gridRefresh={onRefresh}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;
            default:
                break;
        }
    };

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel icon="plus" onClick={onAdd}>
                    Add Note
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: dataGridId,
                            fileName: "invoice_notes",
                            fileNamePostfix: documentNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />
        </>
    );
});

export default Grid;
