import React, { useRef, useState, useCallback, memo, useEffect } from "react";
import { omit } from "lodash";

import { submitResource, referenceToAnyOf, isEmailAddress, pickString, pickNumber } from "../../../../../utils/form";
import { refreshCustomerContacts } from "../../../../../../store/resources/refreshResource";
import { getCustomerContactResourceParams } from "../../../../../../store/configureResources";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { submitByRef } from "../../../../../utils/form";
import { useCustomerContact } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const firstLastOrCompanyErrorText = "Contact First Name and Contact Last Name or Company Name is Required";

const ContactEditForm = memo(({ utilityNumber, customerNumber, contactNumber, onClose, sidePanel }) => {
    const formRef = useRef();

    const [contact = {}, isLoading] = useCustomerContact({
        utilityNumber,
        customerNumber,
        contactNumber,
        forced: true,
    });

    const [isSubmitting, setSubmitting] = useState(false);
    const resourceId = contact && contact.contactNumber;

    const [contactTypes = [], isLoadingContactTypes] = useReference(referenceTypes.userContact);

    const isNew = contactNumber === undefined;
    const titleIcon = isNew ? "plus" : "edit-empty";
    const titleText = isNew ? "Add New Contact" : "Edit Contact";
    const submitText = isSubmitting ? (isNew ? "Creating..." : "Saving...") : isNew ? "Create" : "Save";

    const schema = {
        type: "object",
        required: ["contacttype"],
        properties: {
            contacttype: {
                type: "integer",
                title: "Contact Type",
                anyOf: referenceToAnyOf({ list: contactTypes }),
            },
            contacttitle: {
                type: "string",
                title: "Name This Contact",
            },
            nameSection: {
                type: "object",
                description: `** ${firstLastOrCompanyErrorText}`,
                properties: {
                    firstname: {
                        type: "string",
                        title: "Contact First Name **",
                    },
                    lastname: {
                        type: "string",
                        title: "Contact Last Name **",
                    },
                    company: {
                        type: "string",
                        title: "Company Name **",
                    },
                },
            },
            addressSection: {
                type: "object",
                required: ["address", "city", "state", "zip"],
                properties: {
                    address: {
                        type: "string",
                        title: "Address",
                    },
                    address_cont: {
                        type: "string",
                        title: "Address (continued)",
                    },
                    city: {
                        type: "string",
                        title: "City",
                    },
                    state: {
                        type: "string",
                        title: "State/Province",
                    },
                    zip: {
                        type: "string",
                        title: "Postal Code",
                    },
                },
            },
            communicationSection: {
                type: "object",
                properties: {
                    email: {
                        type: "string",
                        title: "Email",
                    },
                    phone: {
                        type: "string",
                        title: "Phone",
                    },
                    cell: {
                        type: "string",
                        title: "Cell",
                    },
                    fax: {
                        type: "string",
                        title: "Fax",
                    },
                },
            },
            otherInformationSection: {
                type: "object",
                properties: {
                    acct_number: {
                        type: "string",
                        title: "Account Number",
                    },
                    taxid: {
                        type: "string",
                        title: "Tax ID",
                    },
                    premiseid: {
                        type: "string",
                        title: "Premise ID",
                    },
                    meterid: {
                        type: "string",
                        title: "Meter ID",
                    },
                },
            },
        },
    };

    const uiSchema = {
        contacttype: {
            "ui:placeholder": isLoadingContactTypes ? "Loading..." : "-- SELECT --",
            "ui:disabled": isLoadingContactTypes,
        },
        addressSection: {
            state: {
                "ui:widget": "state",
            },
        },
    };

    const initialValues = isNew
        ? {}
        : {
              contacttype: pickNumber(contact.contacttype),
              contacttitle: pickString(contact.contacttitle),
              nameSection: {
                  firstname: pickString(contact.firstname),
                  lastname: pickString(contact.lastname),
                  company: pickString(contact.company),
              },
              addressSection: {
                  address: pickString(contact.address),
                  address_cont: pickString(contact.address_cont),
                  city: pickString(contact.city),
                  state: pickString(contact.state),
                  zip: pickString(contact.zip),
              },
              communicationSection: {
                  email: pickString(contact.email),
                  phone: pickString(contact.phone),
                  cell: pickString(contact.cell),
                  fax: pickString(contact.fax),
              },
              otherInformationSection: {
                  acct_number: pickString(contact.acct_number),
                  taxid: pickString(contact.taxid),
                  premiseid: pickString(contact.premiseid),
                  meterid: pickString(contact.meterid),
              },
          };

    const onValidate = (formData, errors) => {
        if (formData.communicationSection.email && !isEmailAddress(formData.communicationSection.email)) {
            errors.communicationSection.email.addError("Email address must be valid email address");
        }

        if (!formData.nameSection.firstname && !formData.nameSection.lastname && !formData.nameSection.company) {
            errors.nameSection.firstname.addError(firstLastOrCompanyErrorText);
        }

        if (
            (formData.nameSection.firstname && !formData.nameSection.lastname) ||
            (!formData.nameSection.firstname && formData.nameSection.lastname)
        ) {
            errors.nameSection.firstname.addError(firstLastOrCompanyErrorText);
        }

        return errors;
    };

    const handleSubmit = () => {
        submitByRef(formRef);
    };

    const handleSave = useCallback(
        (formData) => {
            const resourceParams = getCustomerContactResourceParams({
                utilityNumber,
                customerNumber,
            });

            const body = {
                ...omit(formData, ["nameSection", "addressSection", "communicationSection", "otherInformationSection"]),
                ...formData.nameSection,
                ...formData.addressSection,
                ...formData.communicationSection,
                ...formData.otherInformationSection,
            };

            submitResource({
                resourceParams,
                resourceId,
                body,
                onSuccess: () => {
                    refreshCustomerContacts({
                        utilityNumber,
                        customerNumber,
                    });
                    sidePanel.close();
                },
                setSubmitting,
            });
        },
        [utilityNumber, customerNumber, resourceId, sidePanel]
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className={isNew ? "customer-add-new-contact-form" : "customer-contact-edit-form"}>
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                {isLoading ? (
                    <WaitIcon />
                ) : (
                    <JsonSchemaForm
                        formRef={formRef}
                        schema={schema}
                        uiSchema={uiSchema}
                        initialValues={initialValues}
                        validate={onValidate}
                        onSubmit={handleSave}
                        disabled={isSubmitting}
                        noReset
                        noCancel
                        noSubmit
                    />
                )}
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default ContactEditForm;
