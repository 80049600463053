import React, { useContext } from "react";
import { BorderRadiusPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderCornerName, BorderCornerSize, BorderRadiusCustomPropertyName, BorderSides } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { PropertyListItem } from "../PropertyListItem";
import { CornerBox } from "./CornerBox";

export const CornerAllSidesProperty = (props: PortalBuilderPropertyProps) => {
    const { value, onChange } = props;
    const { customPropertiesValue } = useContext(BorderRadiusPropertyContext);

    const onSelect = (id: PropertyName, size: BorderCornerSize) => {
        // Deselect the value if same size is selected
        if (size === value) {
            onChange(id, "0");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[BorderRadiusCustomPropertyName.BorderRadiusType] !== BorderSides.AllSides) {
        return null;
    }

    return (
        <PropertyListItem
            className="property-list-item--border-corner property-list-item--border-corner-all-sides flex-row"
            borderTop={true}
            borderBottom={false}
        >
            <CornerBox
                side={BorderCornerName.AllSides}
                size="size-1"
                isSelected={value === BorderCornerSize.Size1}
                onClick={() => onSelect(PropertyName.BorderRadius, BorderCornerSize.Size1)}
            />
            <CornerBox
                side={BorderCornerName.AllSides}
                size="size-2"
                isSelected={value === BorderCornerSize.Size2}
                onClick={() => onSelect(PropertyName.BorderRadius, BorderCornerSize.Size2)}
            />

            <CornerBox
                side={BorderCornerName.AllSides}
                size="size-3"
                isSelected={value === BorderCornerSize.Size3}
                onClick={() => onSelect(PropertyName.BorderRadius, BorderCornerSize.Size3)}
            />
            <CornerBox
                side={BorderCornerName.AllSides}
                size="size-4"
                isSelected={value === BorderCornerSize.Size4}
                onClick={() => onSelect(PropertyName.BorderRadius, BorderCornerSize.Size4)}
            />
            <CornerBox
                side={BorderCornerName.AllSides}
                size="size-5"
                isSelected={value === BorderCornerSize.Size5}
                onClick={() => onSelect(PropertyName.BorderRadius, BorderCornerSize.Size5)}
            />
        </PropertyListItem>
    );
};
