import React from "react";
import ClassNames from "classnames";

import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const Button = (props) => {
    const isTabable = !props.disabled && (Boolean(props.onClick) || props.type === "submit");
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const className = ClassNames("btn", props.icon, props.className, {
        "btn-standard": props.standard || true,
        "btn-blue": props.primary || false,
        "btn-light-blue": (props.primary === false || props.primary === undefined) && !props.success,
        "btn-success": props.success,
        focus: props.focus,
        "btn-disabled": props.disabled,
        "btn-hidden": props.hidden,
        "btn-square": props.square,
        "btn-upload-files": props.upload,
        active: props.active,
        "with-icon": props.icon,
        right: props.iconRight,
        "btn-small": props.small,
    });

    const type = props.type || "button";

    return (
        <div
            className={className}
            onClick={props.disabled ? undefined : props.onClick}
            onMouseDown={props.disabled ? undefined : props.onMouseDown}
            tabIndex={isTabable ? "0" : "-1"}
            title={props.square && props.title}
            onKeyDown={onEnter}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
        >
            <button type={type} title={props.title} disabled={props.disabled} tabIndex="-1">
                {props.btnText}
                {props.children}
            </button>
        </div>
    );
};

export default Button;
