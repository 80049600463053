import cn from "classnames";
import IconWrap from "components/ui/Icons";
import CloseCircle from "components/ui/Icons/CloseCircle";
import TextLabel from "components/ui/Label/TextLabel";
import { isNil } from "lodash";
import React from "react";
import { WidgetListItem } from "./types";

export const WidgetsListItem = ({ item, onEdit, onClick, onRemove }: WidgetsListItemProps) => {
    if (!item) {
        return null;
    }
    const tooltip = item.isEditable
        ? undefined
        : !isNil(onClick)
        ? "Widget usage is not allowed"
        : "Default styling is not available for this widget";

    return (
        <div
            className={cn("widgets-list-item flex-column align-center", {
                "widgets-list-item--disabled": !item.isEditable,
                "widgets-list-item--clickable": item.isEditable && (!isNil(onClick) || !isNil(onEdit)),
            })}
            title={tooltip}
            onClick={item.isEditable ? onClick ?? onEdit : undefined}
        >
            {onRemove && <CloseCircle title="Remove" onClick={onRemove} />}
            {onEdit && item.isEditable && <IconWrap icon="edit-empty" iconWrapRoundedSquare iconWrapWhite onClick={onEdit} />}
            {item.icon}
            <TextLabel>{item.title}</TextLabel>
        </div>
    );
};

interface WidgetsListItemProps {
    item: WidgetListItem;
    onEdit?: () => void;
    onClick?: any;
    onRemove?: any;
}
