export enum UserRolesEntityType {
    Client = 1,
    Utility = 2,
    Program = 3,
}

export enum RoleState {
    On = "on",
    Off = "off",
    Indeterminate = "indeterminate",
}

export enum RoleActionType {
    SelectAll = "select-all",
    UnselectAll = "unselect-all",
    ShowRights = "show-rights",
    ShowUsers = "show-users",
}

export interface User {
    userNumber: string;
    firstName: string;
    lastName: string;
    name: string;
    company: string;
    email: string;
    status: string;
    client: string;
    clientNumber: string;
}

export interface Role {
    roleID: number;
    roleName: string;
}

export interface ClientRole {
    clientRoleId: number;
    roleName: string;
}

export interface UserRole extends Role {
    programs: UserRoleEntityInfo[];
    utilities: UserRoleEntityInfo[];
}

export interface UserRoleEntityInfo {
    assigned: boolean;
    entityName: string;
    entityNumber: string;
    parentEntityName: string;
    parentEntityNumber: string;
}

export interface ClientProgramsUtility {
    utilityNumber: string;
    utilityName: string;
    programList: ProgramListItem[];
    filteredProgramList: ProgramListItem[];
}

export interface ProgramListItem {
    programNumber: string;
    programName: string;
}

export interface ExpandedState {
    [utilityNumber: string]: boolean;
}

export interface EntityRolesState {
    [entityNumber: string]: EntityRolesStateItem;
}

export interface EntityRolesStateItem {
    entityType: UserRolesEntityType;
    rolesState: RolesState;
    parentEntityNumber: string | null;
}

export interface RolesState {
    [roleID: number]: RoleState;
}

export interface UserRolesUpdateItem {
    clientRoleId: number;
    entityTypeId: UserRolesEntityType;
    entityNumber: string;
}

export interface HighlightingCtx {
    entityNumber?: string;
    onHighlight?: (entityNumber: string) => void;
}
