import React from "react";
import { contractVendorsGridColumnKeys } from "../../../../../views/configureGrids";
import { useResource } from "../../../../../../store/resources/useResource";
import WaitIcon from "../../../../WaitIcon";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import DataGrid from "../../../../DataGrid";
import ActionLabel from "../../../../Label/ActionLabel";

const rowActions = [
    {
        name: "select",
        icon: "plus",
        title: "Select Vendor",
    },
];

const VendorSearchGrid = withDataGrid("contractVendors", ({ gridId, dataGridConfig, onSelect }) => {
    const onRowAction = (action) => {
        if (action.name === "select") {
            onSelect(action.dataItem[contractVendorsGridColumnKeys.vendorContactId]);
        }
    };

    return (
        <DataGrid
            name={gridId}
            config={dataGridConfig}
            customRowActions={rowActions}
            onRowAction={onRowAction}
            columnCellContent={{
                [contractVendorsGridColumnKeys.vendorContactId]: (column, row, onExpandRow, onRowAction) => (
                    <ActionLabel onClick={() => onRowAction({ name: "select", dataItem: row })}>{row[column.key]}</ActionLabel>
                ),
            }}
        />
    );
});

const VendorSearchForm = ({ utilityNumber, contractNumber, onSelect }) => {
    const [filterInfo, isLoading] = useResource({
        resourceName: "utilitiesContractsInvoiceDocumentsVendors",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    });

    if (isLoading) {
        return <WaitIcon />;
    }

    const gridId = `${contractNumber}-vendor-grid`;
    const filter =
        filterInfo.resultColumnName !== null && filterInfo.resultValue !== null
            ? `${filterInfo.resultColumnName}=${filterInfo.resultValue}`
            : null;

    return <VendorSearchGrid gridId={gridId} filter={filter} onSelect={onSelect} />;
};

export default VendorSearchForm;
