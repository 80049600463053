import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { usePortalBuilderState } from "../PortalBuilderContextProvider";
import { PortalTemplateConfigurationSectionKey, ValidationError, WidgetType } from "../types";
import { addWidget, setConfigSectionValue } from "../utils";
import { PropertiesForSection } from "./Section";

export const Header = (props: HeaderProps) => {
    const { onChange, errors } = props;
    const [config] = usePortalBuilderState((state) => state.updatedConfig);
    const components = useMemo(() => config?.header?.components ?? [], [config?.header?.components]);
    const isEnabled = useMemo(() => config?.header?.isEnabled ?? false, [config?.header?.isEnabled]);

    const HEADER_PROPERTIES = [
        {
            id: "isEnabled",
            title: "Show Header",
            type: "switch",
            switchOnText: "Show Header",
            switchOffText: "Hide Header",
        },
        {
            id: "components",
            title: "Rows",
            type: "rows",
            hidden: !isEnabled,
        },
    ];

    useEffect(() => {
        if (isEnabled && isEmpty(components)) {
            const widgets = addWidget(components, WidgetType.ROW, 0);
            const updatedConfig = setConfigSectionValue(config, PortalTemplateConfigurationSectionKey.HEADER, "components", widgets);
            onChange && onChange(updatedConfig);
        }
    }, [onChange, components, config, isEnabled]);

    return (
        <PropertiesForSection
            sectionKey={PortalTemplateConfigurationSectionKey.HEADER}
            sectionProperties={HEADER_PROPERTIES}
            config={config}
            onChange={onChange}
            errors={errors}
        />
    );
};

export interface HeaderProps {
    onChange?: any;
    errors: ValidationError;
}
