import React, { useContext, memo } from "react";

import { DocumentQueueGridContext } from "../../../context/DocumentQueueGridContext";
import { Program } from "./Program";

export const ProgramList = memo((props) => {
    const { programList } = useContext(DocumentQueueGridContext);

    return (
        <div className="application-document-queue__dq-grid-programs flex-one-in-column no-scroll">
            <div className="fill-height with-scroll">
                {programList.map((item) => (
                    <Program key={item.progid} {...props} {...item} />
                ))}
            </div>
        </div>
    );
});
