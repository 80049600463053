import React, { memo } from "react";
import { PropertyListItem } from "../PropertyListItem";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { SelectBoxProperty } from "../SelectBoxProperty";
import { InputRangeProperty } from "../InputRangeProperty";

import "./ImageProperty.scss";

export const ImageSizeProperty = memo((props) => {
    const { title, value, onChange } = props;

    const items = [
        {
            text: "Auto",
            value: "auto",
            tooltip: "Image is displayed in its original size",
        },
        {
            text: "Custom",
            value: "custom",
            tooltip: "Image size can be zoomed in and out",
        },
        {
            text: "Fill width",
            value: "fill-width",
            tooltip: "Image is stretched or increased to fill Section width",
        },
        {
            text: "Fill height",
            value: "fill-height",
            tooltip: "Image is stretched or increased to fill Section height",
        },
    ];

    if (!value.src) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--image-size" borderBottom={false}>
            <PropertyListItemTitle title={title} />
            <SelectBoxProperty
                defaultValue={"fill-height"}
                id={"imageSize"}
                onChange={onChange}
                items={items}
                fullWidth={true}
                value={value?.imageSize}
            ></SelectBoxProperty>
            {value?.imageSize === "custom" && (
                <InputRangeProperty id={"customSize"} onChange={onChange} value={value?.customSize} minValue={10}></InputRangeProperty>
            )}
        </PropertyListItem>
    );
});
