import React, { memo } from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";
import RawHtml from "../RawHtml";

import "./style.scss";

const NothingFoundBlock = memo((props) => {
    const className = ClassNames("nothing-found-block flex-column align-center justify-center flex-one", props.className, {
        active: props.nothingFoundBlockActive,
        small: props.nothingFoundBlockSmall,
        "with-margin-top": props.nothingFoundBlockMargin,
        "no-border": props.nothingFoundBlockNoBorder,
        error: props.nothingFoundBlockError,
        "no-wrapper": props.nothingFoundBlockNoWrapper,
    });

    return (
        <div className={className} hidden={props.hidden}>
            <div className="nothing-found-block__content">
                {!props.nothingFoundBlockImgSrc && <IconWrap iconWrapError={props.nothingFoundBlockError} iconWrap={props.icon}></IconWrap>}
                <div className="nothing-found-block__title">
                    <RawHtml>{props.title}</RawHtml>
                </div>
                {props.children}
                {props.nothingFoundBlockImgSrc && (
                    <img
                        src={props.nothingFoundBlockImgSrc}
                        alt=""
                        width={props.nothingFoundBlockImgWidth}
                        height={props.nothingFoundBlockImgHeight}
                    />
                )}
            </div>
        </div>
    );
});

export default NothingFoundBlock;
