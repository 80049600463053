import React, { memo, useEffect } from "react";
import { connect } from "react-redux";

import { construct } from "../../../store/dataGrid/actions";
import { availableGrids, applicationsAssignedGridColumnKeys, widgetApplicationsAssignedGridColumnKeys } from "../../views/configureGrids";
import { searchAttrToFilter } from "../../utils/datagrid";
import { openProjectTab } from "../../utils/window";
import { exportDatagridToCSV } from "../../utils/CSV";

import IconWithLabel from "../../ui/Icons/IconWithLabel";
import WaitIcon from "../../ui/WaitIcon";
import DataGrid from "../../ui/DataGrid";
import ActionLabel from "../../ui/Label/ActionLabel";

import "./style.scss";

const rowActions = [
    {
        name: "update",
        icon: "open-new-window",
        title: "Manage Application",
    },
];

const ApplicationProcessingAssignedApps = memo(() => {
    return (
        <div className="app-processing-assigned-apps panel sticky-grid-list-panel">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <AssignedAppsDataGrid isWidget={false} />
            </div>
        </div>
    );
});

const Grid = memo(({ user, dataGrid, isWidget, dispatch }) => {
    const dataGridID = isWidget ? availableGrids.widgetApplicationsAssigned : availableGrids.applicationsAssigned;

    const columnApplicationNumber = isWidget
        ? widgetApplicationsAssignedGridColumnKeys.applicationNumber
        : applicationsAssignedGridColumnKeys.applicationNumber;
    const columnProjectNumber = isWidget
        ? widgetApplicationsAssignedGridColumnKeys.projectNumber
        : applicationsAssignedGridColumnKeys.projectNumber;
    const columnUserNumber = isWidget ? widgetApplicationsAssignedGridColumnKeys.userNumber : applicationsAssignedGridColumnKeys.userNumber;

    const gridId = `${user.userNumber}${isWidget ? "-widget" : ""}-applications-assigned-grid`;
    const gridFilter = `${columnUserNumber}=${user.userNumber}`;
    const gridConfig = dataGrid[gridId];

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: dataGridID,
                dataGridInstanceId: gridId,
                filter: searchAttrToFilter(gridFilter),
                data: true,
            })
        );
    }, [dataGridID, gridId, gridFilter, dispatch]);

    const onRowAction = (action) => {
        const { dataItem } = action;

        const applicationNumber = dataItem[columnApplicationNumber];

        onApplicationOpen(applicationNumber);
    };

    const onApplicationOpen = (applicationNumber) => {
        openProjectTab({ applicationNumber });
    };

    if (!gridConfig) {
        return <WaitIcon />;
    }

    return (
        <>
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "applications_assigned",
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <DataGrid
                name={gridId}
                config={gridConfig}
                customRowActions={rowActions}
                onRowAction={onRowAction}
                columnCellContent={{
                    [columnApplicationNumber]: (column, row) => (
                        <ActionLabel onClick={() => onApplicationOpen(row[column.key])}>{row[column.key]}</ActionLabel>
                    ),
                    [columnProjectNumber]: (column, row) => {
                        if (row[columnApplicationNumber]) {
                            return (
                                <ActionLabel onClick={() => onApplicationOpen(row[columnApplicationNumber])}>{row[column.key]}</ActionLabel>
                            );
                        }

                        return row[column.key];
                    },
                }}
            />
        </>
    );
});

export default ApplicationProcessingAssignedApps;

export const AssignedAppsDataGrid = connect((state) => ({
    user: state.user,
    dataGrid: state.dataGrid,
}))(Grid);
