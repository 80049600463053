import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { isEqual, debounce } from "lodash";

import cn from "classnames";
import Form from "react-jsonschema-form";
import Button from "../../Button";

import ObjectFieldTemplate from "./fields/ObjectFieldTemplate";

import { flattenFormData, disableAutocomplete, DEFAULT_AUTOCOMPLETE, addExtraError, standardFormValidate } from "../../../utils/form";
import { logError } from "../../../utils/logger";
import { isInViewport } from "components/utils/dom";
import { createId } from "components/utils/string";
import { fields } from "./fields";
import { widgets } from "./widgets";

import "./style.scss";

const JsonSchemaForm = memo((props) => {
    const {
        initialValues,
        disabled,
        onChange,
        onSubmit,
        onError,
        onReset,
        debounce,
        centeredFooter,
        className,
        fieldKey,
        readOnly,
        noValidate,
        validate,
        hidden,
        emptyItemInSelectLists,
        selectListsWithPopper,
        resetDisabled,
    } = props;
    const {
        withCancel,
        submitText,
        resetText,
        cancelText,
        submitIcon,
        noSubmit,
        noReset,
        noActions,
        submitIsPrimary = true,
        submitDisabled,
        cancelIcon,
        cancelDisabled,
        resetIcon,
        liveValidate,
        autocomplete = DEFAULT_AUTOCOMPLETE,
        onCancel,
        otherActions,
        otherActionsClass,
        transformErrors,
    } = props;

    let formRef = useRef();
    const uniqueIdPrefix = useRef(createId()).current;

    if (props.formRef) {
        formRef = props.formRef;
    }

    const [formData, setFormData] = useState(initialValues);
    const [schema, setSchema] = useState(props.schema);
    const [uiSchema, setUiSchema] = useState(props.uiSchema);
    const [extraErrors, setExtraErrors] = useState([]);

    const submitButtonText = submitText || "Submit";
    const resetButtonText = resetText || "Reset";
    const cancelButtonText = cancelText || (readOnly ? "Close" : "Cancel");

    const submitButtonIcon = submitIcon;
    const resetButtonIcon = resetIcon;
    const cancelButtonIcon = cancelIcon;

    const showSubmitButton = !noSubmit && !readOnly && schema && Object.keys(schema).length > 0;
    const showResetButton = !noReset && schema && Object.keys(schema).length > 0;

    // Disable autocomplete
    useEffect(() => {
        disableAutocomplete({ formRef });
    }, []);

    // Update initial values
    useEffect(() => {
        setFormData((prevValue) => {
            if (!isEqual(initialValues, prevValue)) {
                return initialValues;
            }

            return prevValue;
        });
    }, [initialValues]);

    // Update form
    useEffect(() => {
        setSchema((prevValue) => {
            if (!isEqual(props.schema, prevValue)) {
                return props.schema;
            }

            return prevValue;
        });

        setUiSchema((prevValue) => {
            if (!isEqual(props.uiSchema, prevValue)) {
                return props.uiSchema;
            }

            return prevValue;
        });
    }, [props.uiSchema, props.schema]);

    useEffect(() => {
        formRef.current.getInitialValues = () => ({ ...initialValues });
    }, [initialValues]);

    useEffect(() => {
        formRef.current.setFormData = (formData) => {
            setFormData({ formData });
        };
    }, [setFormData]);

    const onFormChange = useCallback(
        (form) => {
            setFormData(form.formData);

            if (onChange) {
                if (debounce) {
                    debouncedOnChange({ onChange, form, fieldKey });
                } else {
                    onChange({
                        formData: flattenFormData(form, fieldKey),
                        errorSchema: form.errorSchema,
                    });
                }
            }
        },
        [debounce, fieldKey, onChange]
    );

    const onFormSubmit = useCallback(
        (form) => {
            const data = flattenFormData(form, fieldKey);

            onSubmit && onSubmit(data);

            if (formRef?.current?.submitCallback) {
                formRef.current.submitCallback(null, data);
            }
        },
        [fieldKey, onSubmit]
    );

    const onFormError = useCallback(
        (form) => {
            if (formRef?.current) {
                const errorEl = formRef.current.formElement.querySelector(".field-error");
                if (errorEl && !isInViewport(errorEl)) {
                    errorEl.scrollIntoView();
                }
            }

            logError(form);
            onError && onError(form);

            if (formRef?.current?.submitCallback) {
                formRef.current.submitCallback(form, null);
            }
        },
        [onError]
    );

    const onFormReset = useCallback(() => {
        setFormData(initialValues);
        onReset && onReset();
    }, [initialValues, onReset]);

    const onFormCancel = useCallback(
        (form) => {
            onCancel && onCancel({ formData: form.formData });
        },
        [onCancel]
    );

    const onFormValidate = useCallback(
        (formData, errors) => {
            return standardFormValidate({
                formData,
                errors,
                validate,
                extraErrors,
                idPrefix: uiSchema?.["ui:rootFieldId"] ?? uniqueIdPrefix,
            });
        },
        [extraErrors, uiSchema, uniqueIdPrefix, validate]
    );

    const setExtraError = useCallback((id, message) => {
        setExtraErrors((extraErrors) => addExtraError({ id, message, extraErrors }));
    }, []);

    if (!schema) {
        return null;
    }

    return (
        <div className={cn("json-form", className)} hidden={hidden}>
            <Form
                formContext={{
                    // Empty option in not required dropdown fields
                    emptyItemInSelectLists,

                    // Render dropdown list in portal with popper
                    selectListsWithPopper,

                    // Function to set extra errors to be shown on submit
                    setExtraError,

                    // List of extra errors
                    extraErrors,
                }}
                ref={formRef}
                idPrefix={uniqueIdPrefix}
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                widgets={widgets}
                fields={fields}
                ObjectFieldTemplate={ObjectFieldTemplate}
                showErrorList={false}
                noHtml5Validate
                autocomplete={autocomplete}
                disabled={disabled}
                onChange={onFormChange}
                onSubmit={onFormSubmit}
                onError={onFormError}
                liveValidate={liveValidate}
                noValidate={noValidate}
                validate={onFormValidate}
                transformErrors={transformErrors}
            >
                <div
                    className={
                        "action-buttons flex-row" +
                        (centeredFooter ? " justify-center" : "") +
                        (otherActionsClass ? " qc-uploads-buttons" : "")
                    }
                    hidden={noActions}
                >
                    {showSubmitButton && (
                        <Button icon={submitButtonIcon} type="submit" primary={submitIsPrimary} disabled={disabled || submitDisabled}>
                            {submitButtonText}
                        </Button>
                    )}
                    {showResetButton && (
                        <Button icon={resetButtonIcon} type="button" disabled={disabled || resetDisabled} onClick={onFormReset}>
                            {resetButtonText}
                        </Button>
                    )}
                    {(withCancel || onCancel) && !disabled && (
                        <Button icon={cancelButtonIcon} type="button" disabled={cancelDisabled} onClick={onFormCancel}>
                            {cancelButtonText}
                        </Button>
                    )}
                    {otherActions}
                </div>
            </Form>
        </div>
    );
});

// Debounce form onChange callback
const debouncedOnChange = debounce(({ onChange, form, fieldKey }) => {
    if (onChange) {
        onChange({
            formData: flattenFormData(form, fieldKey),
            errorSchema: form.errorSchema,
        });
    }
}, 500);

export default JsonSchemaForm;
