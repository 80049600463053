import React, { useContext, memo } from "react";

import { BatchGridContext } from "../../../context/BatchGridContext";
import { Batch } from "./Batch";

export const BatchList = memo((props) => {
    const { batchList } = useContext(BatchGridContext);

    return (
        <div className="application-document-queue__dq-grid-programs flex-one-in-column no-scroll">
            <div className="fill-height with-scroll">
                {batchList.map((batch) => (
                    <Batch key={batch.documentBatchNumber} {...props} {...batch} />
                ))}
            </div>
        </div>
    );
});
