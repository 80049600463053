import React, { useContext } from "react";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderCustomPropertyName, BorderSides } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { SelectPanelProperty } from "../SelectPanelProperty";
import { ColorSelectIcon } from "../ColorSelect/ColorSelectIcon";
import ColorSelectPanel from "../ColorSelect/ColorSelectPanel";

export const ColorAllSidesProperty = (props: PortalBuilderPropertyProps) => {
    const { customPropertiesValue } = useContext(BorderPropertyContext);

    if (customPropertiesValue[BorderCustomPropertyName.BorderColorType] !== BorderSides.AllSides) {
        return null;
    }

    return (
        <SelectPanelProperty
            {...props}
            className="property-list-item--border-color property-list-item--border-color-all-sides"
            id={PropertyName.BorderColor}
            title={"Color"}
            selectComponent={ColorSelectIcon}
            panelComponent={ColorSelectPanel}
            selectionTitle={"Border"}
        />
    );
};
