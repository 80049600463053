import React, { memo, useEffect, useCallback, useMemo } from "react";

import { referenceTypes } from "../../../../Reference/referenceTypes";
import { useReference } from "../../../../Reference/useReference";
import { getSchema, getUiSchema, getInitialValues } from "./utils";

import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { validateAttributesForm } from "components/utils/attributes";

const EquipmentAttributesForm = memo(
    ({ formRef, equipment, values, isNewEquipment, hasRightsToEdit, hasRightsToEditAll, viewOnly, onChange, onSubmit }) => {
        const [validationTypes = [], isLoadingValidationTypes] = useReference(referenceTypes.equipmentValidation);
        const [trueFalseTypes = [], isLoadingTrueFalseTypes] = useReference(referenceTypes.trueFalse);

        const isLoading = isLoadingValidationTypes || isLoadingTrueFalseTypes;

        const schema = useMemo(
            () => (isLoading ? {} : getSchema(equipment, validationTypes, trueFalseTypes, hasRightsToEdit, hasRightsToEditAll)),
            [equipment, validationTypes, trueFalseTypes, hasRightsToEdit, hasRightsToEditAll, isLoading]
        );

        const uiSchema = useMemo(
            () => (isLoading ? {} : getUiSchema(equipment, validationTypes, hasRightsToEdit, hasRightsToEditAll)),
            [equipment, validationTypes, hasRightsToEdit, hasRightsToEditAll, isLoading]
        );

        const initialValues = useMemo(
            () =>
                isLoading
                    ? {}
                    : getInitialValues(equipment, validationTypes, hasRightsToEdit, hasRightsToEditAll, values, schema, isNewEquipment),
            [equipment, validationTypes, hasRightsToEdit, hasRightsToEditAll, values, schema, isLoading, isNewEquipment]
        );

        const onFormChange = useCallback(
            ({ formData }) => {
                onChange && onChange(formData);
            },
            [onChange]
        );

        const onValidate = useCallback(
            (formData, errors) => {
                return validateAttributesForm(formData, errors, schema);
            },
            [schema]
        );

        useEffect(() => {
            if (!isLoading && !values) {
                onChange && onChange(initialValues);
            }
        }, [isLoading, initialValues, values, onChange]);

        const formContext = useMemo(
            () => ({
                localizeDateValues: true,
            }),
            []
        );

        if (isLoading) {
            return <WaitIcon />;
        }

        return (
            <JsonSchemaForm
                formRef={formRef}
                formContext={formContext}
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                onChange={onFormChange}
                noActions
                readOnly={viewOnly}
                onSubmit={onSubmit}
                validate={onValidate}
            />
        );
    }
);

export default EquipmentAttributesForm;
