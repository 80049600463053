import React from "react";
import ClassNames from "classnames";

import IconWrap from "../Icons";

import "./style.scss";

const DragHandle = (props) => {
    const { dragVertical, dragHorizontal, disabled, ...rest } = props;

    const className = ClassNames("drag-handle-icon", props.className, {
        "drag-handle-vertical": dragVertical,
        "drag-handle-disabled": disabled,
    });

    return (
        <div className={className} {...rest}>
            {dragVertical || dragHorizontal ? (
                <IconWrap title={dragHorizontal ? "Move Left/Right" : "Move Up/Down"} icon="view-headline-list-text" />
            ) : (
                <span>⋮</span>
            )}
        </div>
    );
};

export default DragHandle;
