import React from "react";
import ClassNames from "classnames";
import { Draggable } from "react-beautiful-dnd";
import IconWrap from "../Icons";
import DragHandle from "../DragHandle";
import ReactDOM from "react-dom";

import "./DropdownListItem.scss";

// Wrapper component that handles draggable items, if you drag an item, it will be placed into portal to combat problem with incorrect position
const PortalAwareItem = (props) => {
    const portal = document.getElementById("_dnd_portal_draggables_");

    if (!document.getElementById("_dnd_portal_draggables_")) {
        const newPortal = document.createElement("div");
        newPortal.id = "_dnd_portal_draggables_";
        document.getElementById("root").appendChild(newPortal);
    }

    const provided = props.provided;
    const snapshot = props.snapshot;
    const usePortal = snapshot.isDragging;

    const child = (
        <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={props.className + (snapshot.isDragging ? " is-dragging-item" : "")}
            onClick={props.onClick}
            tabIndex="0"
        >
            {props.renderItem ? (
                props.renderItem(provided)
            ) : (
                <div className="flex-row fill-width align-center" title={props.disabled ? props.title : undefined}>
                    {!props.dragDisabled && <DragHandle dragVertical {...provided.dragHandleProps} />}
                    {props.icon && (
                        <IconWrap
                            iconWrapClickable
                            className="dropdown-list-item-icon-left"
                            disabled={props.disabled}
                            icon={props.icon}
                            title={props.title}
                        />
                    )}
                    {props.children}
                </div>
            )}
        </li>
    );

    if (!usePortal) {
        return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
};

const DropdownListItem = (props) => {
    const className = ClassNames("dropdown-list-item", props.icon, props.className, {
        selected: props.selected,
        disabled: props.disabled,
        focused: props.focused,
        "dropdown-list-item-checked": props.checked,
        "multi-select": props.multiSelect,
    });

    if (props.draggable) {
        return (
            <Draggable draggableId={props.draggableId} index={props.draggableIndex} isDragDisabled={props.dragDisabled}>
                {(provided, snapshot) => PortalAwareItem({ provided, snapshot, className, ...props })}
            </Draggable>
        );
    }

    return (
        <li className={className} title={props.disabled ? props.title : undefined} onClick={props.onClick}>
            {props.renderItem ? (
                props.renderItem()
            ) : (
                <div>
                    {props.icon && <IconWrap className="dropdown-list-item-icon-left" icon={props.icon} />}
                    {props.children}
                </div>
            )}
        </li>
    );
};

export default DropdownListItem;
