import React from "react";

import WaitIcon from "../../../../WaitIcon";
import TabList from "../../../../List/TabList";

export const ApplicationFormsSelector = (props) => {
    const { loading, forms, activeFormId, onSelect } = props;

    const onSelectTab = (tab) => {
        onSelect(forms.filter((f) => f.pageNumber === tab.id)[0]);
    };

    if (loading) {
        return <WaitIcon />;
    }

    const tabs = forms.map((form) => ({
        id: form.pageNumber,
        title: form.pageName,
        hasData: form.hasItems,
        disabled: form.allowEdit === "N",
    }));
    const activeTab = tabs.filter((tab) => tab.id === activeFormId)[0];

    return (
        <div className="application-forms-selector">
            <TabList tabListWrapped items={tabs} activeItem={activeTab} onClick={onSelectTab} disabledItemTooltip="Disabled Form" />
        </div>
    );
};
