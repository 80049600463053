import React, { CSSProperties, memo, RefObject } from "react";
import ClassNames from "classnames";

import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const IconWrap = memo((props: IconWrapProps) => {
    const isClickable = (props.iconWrapClickable || Boolean(props.onClick)) && !props.disabled;
    const isTabable = !props.disabled && isClickable;

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    // To prevent the disabled icon click event to bubble up to parent element.
    const onDisabledClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    const className = ClassNames("icon-wrap with-icon", props.iconWrap, props.icon, props.className, {
        "icon-wrap-medium": props.iconWrapMedium || props.iconWrapBig === undefined,
        "icon-wrap-big": props.iconWrapBig,
        "icon-wrap-small": props.iconWrapSmall,
        "icon-wrap-clickable": isClickable,
        "icon-wrap-disabled": props.disabled,
        "icon-wrap-right": props.iconWrapRight,
        "icon-wrap-active": props.iconWrapActive,
        "icon-wrap-dropdown": props.iconWrapDropdown,
        "icon-wrap-white": props.iconWrapWhite,
        "icon-wrap-white-inverse": props.iconWrapWhiteInverse,
        "icon-wrap-transparent": props.iconWrapTransparent,
        "icon-wrap-success": props.iconWrapSuccess,
        "icon-wrap-error": props.iconWrapError,
        "icon-wrap-neutral": props.iconWrapNeutral,
        "icon-wrap-theme": props.iconWrapTheme,
        "icon-wrap-warning": props.iconWrapWarning,
        "icon-wrap-hidden": props.iconWrapHidden,
        "icon-wrap-combined": props.iconWrapCombined,
        "icon-wrap-rounded-square": props.iconWrapRoundedSquare,
        "icon-wrap-no-mouseover": props.iconWrapNoMouseOver,
    });

    const dragHandleProps = props.dragHandleProps || {};

    return (
        <div
            style={props.iconWrapStyle}
            ref={props.elementRef}
            className={className}
            data-test-id={props["data-test-id"]}
            title={props.title}
            onClick={props.disabled ? onDisabledClick : props.onClick}
            {...dragHandleProps}
            tabIndex={isTabable ? "0" : "-1"}
            onKeyDown={onEnter}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onMouseLeave={props.onMouseLeave}
            onMouseOver={props.onMouseOver}
            hidden={props.hidden}
        />
    );
});

export default IconWrap;

interface IconWrapProps {
    iconWrapClickable?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    iconWrap?: string;
    icon: string;
    className?: string;
    iconWrapMedium?: boolean;
    iconWrapBig?: boolean;
    iconWrapSmall?: boolean;
    iconWrapRight?: boolean;
    iconWrapActive?: boolean;
    iconWrapDropdown?: boolean;
    iconWrapWhite?: boolean;
    iconWrapWhiteInverse?: boolean;
    iconWrapSuccess?: boolean;
    iconWrapError?: boolean;
    iconWrapNeutral?: boolean;
    iconWrapTheme?: boolean;
    iconWrapWarning?: boolean;
    iconWrapHidden?: boolean;
    iconWrapCombined?: boolean;
    iconWrapRoundedSquare?: boolean;
    iconWrapNoMouseOver?: boolean;
    iconWrapTransparent?: boolean;
    isClickable?: boolean;
    dragHandleProps?: any;
    iconWrapStyle?: CSSProperties;
    "data-test-id"?: string;
    title?: string;
    onMouseLeave?: () => void;
    onMouseOver?: () => void;
    hidden?: boolean;
    elementRef?: RefObject<HTMLDivElement>;
}
