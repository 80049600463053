import React from "react";
import { useDispatch } from "react-redux";

import { mapGridRowToObject, SortType } from "../../../../../utils/datagrid";
import { onDeleteBudgetLineAmount } from "../utils";
import { availableGrids } from "../../../../../views/configureGrids";
import { getBudgetLinesAmountsResourceParams } from "store/configureResources";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../ui/SideNav/SideNavRoot";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";

import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import WaitIcon from "../../../../WaitIcon";
import { useDataGrid } from "components/utils/useDataGrid";

const budgetLineAmountsGridColumnKeys = {
    amountNumber: "c_587291df-aeb2-49a9-a63f-bb9a3d782009",
    fromDate: "c_daabcafc-7ead-47da-a309-f2c2fc23882e",
    thruDate: "c_9a721052-6452-4744-9987-2ced508a5058",
    amount: "c_f31a9c53-aab3-4888-8697-eb63f67cabe7",
    sortOrder: "c_ca255402-512c-45ae-9e97-0099766f5fc0",
    note: "c_f03ee713-845a-49e0-bfed-323ec2dda77d",
};

const Grid = ({ dataGridId, utilityNumber, contractNumber, budgetLineNumber, onRefresh }) => {
    const dispatch = useDispatch();

    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canEdit =
        utilityRights.includes(systemUserRights.VISIONDSM_ADD_BUDGET) || utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_BUDGET);
    const canDelete =
        utilityRights.includes(systemUserRights.VISIONDSM_ADD_BUDGET) || utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_BUDGET);

    const rowActions = [
        {
            name: "edit",
            icon: "edit-empty",
            title: "Edit Budget Amount",
            footerExclude: true,
            disabled: !canEdit,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            title: "Delete Budget Amount",
            footerName: "Delete",
            disabled: !canDelete,
        },
    ];

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "budget-amounts-sidenav-panel",
    });

    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.budgetLineAmounts,
        dataGridInstanceId: dataGridId,
        sorting: SortType.CLIENT,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: budgetLineAmountsGridColumnKeys,
                resourceParams: getBudgetLinesAmountsResourceParams({
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                }),
            },
        },
    });

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        const data = mapGridRowToObject(budgetLineAmountsGridColumnKeys, dataItem);

        switch (name) {
            case "edit":
                handleOpenSidePanel(
                    <Form
                        dataItem={data}
                        utilityNumber={utilityNumber}
                        contractNumber={contractNumber}
                        budgetLineNumber={budgetLineNumber}
                        gridRefresh={onRefresh}
                        onClose={handleCloseSidePanel}
                    />
                );
                break;
            case "delete":
                onDeleteBudgetLineAmount({
                    utilityNumber,
                    contractNumber,
                    budgetLineNumber,
                    dataItem: data,
                    onRefresh,
                    dispatch,
                });
                break;
            default:
                break;
        }
    };

    if (isLoading || isLoadingRights) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} />;
};

export default Grid;
