import React, { memo, useCallback } from "react";

import { getCatalogCategoriesGridId } from "../../CatalogPanel/utils";
import { isChildProgram, isLockedText } from "../../../../../../views/ProgramView/utils";
import { refreshCatalogCategoriesGrid } from "../../../../../../../store/dataGrid/refreshGrid";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../../SideNav/SideNavRoot";

import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import Controls from "../../../Controls";
import Grid from "./Grid";
import Form from "./Form";

const CatalogCategoriesPanel = memo(({ panel, onToggleTempPanel }) => {
    const programNumber = panel?.data?.programNumber;
    const dataGridId = getCatalogCategoriesGridId({ programNumber });
    const isLocked = isChildProgram({ programNumber });

    const onRefresh = useCallback(() => {
        refreshCatalogCategoriesGrid({ programNumber });
    }, [programNumber]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "catalog-category-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<Form programNumber={programNumber} gridRefresh={onRefresh} onClose={handleCloseSidePanel} />);
    }, [programNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel]);

    const controlItems = [
        {
            position: "right",
            title: "Add Category",
            tooltip: isLocked ? isLockedText : undefined,
            disabled: isLocked,
            icon: "plus",
            onClick: handleCreate,
        },
        {
            position: "right",
            title: "Export CSV",
            withHandMadeIcon: true,
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: dataGridId,
                    fileName: "program_catalog_categories",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="panel panel-catalog-categories sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="Catalog Categories" onClick={() => onToggleTempPanel()} />
            <Controls items={controlItems} />
            <Grid dataGridId={dataGridId} programNumber={programNumber} onRefresh={onRefresh} />
        </div>
    );
});

export default CatalogCategoriesPanel;
