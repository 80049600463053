import React, { useRef } from "react";

import withDataGrid from "../../../../DataGrid/withDataGrid";
import RawHtml from "../../../../RawHtml";
import DataGrid from "../../../../DataGrid";
import { qualityControlGridColumnKeys } from "../../../../../views/configureGrids";
import QualityControlRequestDetails from "./QualityControlRequestDetails";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import { systemUserRights } from "components/utils/user";

import "./QualityControlGrid.scss";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import { useProgramRights } from "store/resources/useResource";

const QualityControlGrid = ({
    gridId,
    dataGridConfig,
    programNumber,
    applicationNumber,
    onUpdateQCRequest,
    onAcceptQCRequest,
    onDeleteQCRequest,
    onRowSelect,
    onRowSelectChange,
    onPageChanged,
}) => {
    const gridListRef = useRef();
    const isAppLocked = isApplicationLocked({ applicationNumber });
    const [programRights = []] = useProgramRights({ programNumber });
    const isEditRequestDisabled = !programRights.includes(systemUserRights.VISIONDSM_ADD_QC);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.staticLarge,
    });

    const customRowActions = [
        {
            name: "update",
            icon: isAppLocked ? "eye-visibility-empty" : "edit-empty",
            title: isAppLocked ? "View Request" : "Edit Request",
            disabled: isEditRequestDisabled,
        },
        {
            name: "delete",
            icon: "delete-trash-empty",
            hide: isAppLocked,
            title: "Delete Request",
            disabled: isEditRequestDisabled,
        },
    ];

    const onRowAction = (action) => {
        const { name } = action;
        const qcNumber = action.dataItem[qualityControlGridColumnKeys.qcNumber];

        switch (name) {
            case "update":
                handleOpenSidePanel(
                    <QualityControlRequestDetails
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        qcNumber={action.dataItem[qualityControlGridColumnKeys.qcNumber]}
                        onClose={() => handleCloseSidePanel()}
                        onDescriptionSave={onDescriptionSave}
                        onAcceptQCRequest={onAcceptQCRequest}
                    />
                );
                break;
            case "delete":
                onDeleteQCRequest({
                    qcNumber,
                    onSuccess: () => onRowSelect([]),
                });
                break;
            default:
                break;
        }
    };

    const onDescriptionSave = (qc) => (description, onComplete) => onUpdateQCRequest(qc, description, onComplete);

    return (
        <DataGrid
            ref={gridListRef}
            name={gridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            onRowSelect={onRowSelect}
            onRowSelectChange={onRowSelectChange}
            onPageChanged={onPageChanged}
            customRowActions={customRowActions}
            canSelectRow
            className="qc-data-grid"
            columnCellContent={{
                [qualityControlGridColumnKeys.description]: (column, row) => (
                    <RawHtml className="qc-description">{row[column.key]}</RawHtml>
                ),
            }}
        />
    );
};

export default withDataGrid("qualityControl", QualityControlGrid);
