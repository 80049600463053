import React from "react";
import IconWrap from "../Icons";
import { AUTO_SIZE_PADDING } from ".";
import { useAutoSize } from "components/utils/autosize";
import { breakLongWords } from "components/utils/string";

const AvailableListItem = ({ items, selectedItems, nameKey, onAdd, index, style, ItemComponent }) => {
    const item = items[index];

    // Content container for minimum size calculation
    const contentRef = React.useRef();

    // Content size updater
    useAutoSize(contentRef, index, AUTO_SIZE_PADDING);

    const Component = ItemComponent ?? Item;

    return <Component contentRef={contentRef} item={item} selectedItems={selectedItems} nameKey={nameKey} style={style} onAdd={onAdd} />;
};

const Item = ({ contentRef, item, nameKey, style, onAdd }) => {
    return (
        <div className={"assignment-selector__list-item flex-row"} style={style} onClick={() => onAdd(item)}>
            <span ref={contentRef}>{breakLongWords(item[nameKey], 30)}</span>
            <span className="flex-one" />
            <IconWrap iconWrap="arrow-right" title="Add >" onClick={() => onAdd(item)} />
        </div>
    );
};

export default AvailableListItem;
