import React from "react";

import IconWrap from "../Icons";
import CounterBox from "../CounterBox";

import "./GridSelectedItemsLabel.scss";

const GridSelectedItemsLabel = ({ text, count, onClear }) => (
    <div className="grid-selected-items-label flex-row align-center">
        {count > 0 && <CounterBox>{count}</CounterBox>}
        <span>{text}</span>
        {count > 0 && <IconWrap icon="clear-close" title="Unselect all selected items" onClick={() => onClear()} />}
    </div>
);

export default GridSelectedItemsLabel;
