import React, { memo } from "react";

import { EventCalculationAssociations } from "./EventCalculationAssociations";
import { CatalogCalculationAssociations } from "./CatalogCalculationAssociations";
import { toArray } from "components/utils/array";

const CalculationAssociations = memo((props) => {
    const isEventCalculation = toArray(props?.rowData)?.[0]?.calculationType?.toLowerCase() === "event_attr";

    return isEventCalculation ? <EventCalculationAssociations {...props} /> : <CatalogCalculationAssociations {...props} />;
});

export default CalculationAssociations;
