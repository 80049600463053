import React, { memo } from "react";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import Panel from "../../../../Form/shared/Program/Panel";

import "../UtilityCommonStyles.scss";

const ProgramsPanel = memo(({ panel }) => {
    const utilityNumber = panel.data.utilityNumber;

    return (
        <div className="panel panel-program-criteria flex-column fill-height">
            <PanelHeaderLarge title={panel.name} />
            <Panel utilityNumber={utilityNumber} />
        </div>
    );
});

export default ProgramsPanel;
