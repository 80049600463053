import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import JsonSchemaFormWithConditionals from "../../ui/Form/JsonSchema/JsonSchemaFormWithConditionals";
import { transformFilterFormErrors } from "./utils";
import { regexPatterns } from "../../utils/form";
import { useResource } from "../../../store/resources/useResource";

const FilterCreateForm = ({ onSubmit, onCancel }) => {
    const schema = {
        type: "object",
        required: ["filterName"],
        properties: {
            filterName: {
                type: "string",
                title: "Filter Name",
                pattern: regexPatterns.nonEmptyString,
            },
        },
    };

    const uiSchema = {
        filterName: {
            classNames: "fill-width",
        },
    };

    // load existing filters, so we can use their names for validation
    const userNumber = useSelector((state) => get(state, "user.userNumber"));
    const [dataSources = []] = useResource({
        resourceName: "workcenter",
        key: `${userNumber}-workcenter`,
    });
    const validateFilterName = useCallback(
        (formData, errors) => {
            if (dataSources.filter((ds) => !ds.isShared).some((ds) => ds.datasourceName === formData.filterName)) {
                errors.filterName.addError("filter name must be unique");
            }

            return errors;
        },
        [dataSources]
    );

    return (
        <JsonSchemaFormWithConditionals
            schema={schema}
            uiSchema={uiSchema}
            transformErrors={transformFilterFormErrors}
            initialValues={{}}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitText="Save"
            validate={validateFilterName}
            noReset
        />
    );
};

export default FilterCreateForm;
