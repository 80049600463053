import React, { memo, useMemo, useCallback, useState, useContext, useEffect, useId } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { ScrollSyncPane } from "react-scroll-sync";

import { getPanel } from "../../utils/dashboard";
import { windowContainerTypes } from "../../utils/window";

import DashboardTools from "./DashboardTools";
import DashboardPanels from "./DashboardPanels";
import SideNavContainer from "../SideNav/SideNavContainer";
import SideNavRoot, { sideNavPosition, getDashboardSideNavKey } from "../SideNav/SideNavRoot";
import { sideNavClose } from "../../../store/sideNav/actions";
import {
    saveDashboard,
    setActiveDashboardTool,
    toggleWidgetConfiguration,
    toggleSidebar,
    setActiveTempPanel,
} from "../../../store/dashboards/actions";
import { WindowContext } from "../Windows/Window";
import { useDashboards } from "../../../store/resources/useResource";
import WaitIcon from "../WaitIcon";
import { openConfirmModal } from "../Modal/utils";

import "./style.scss";
import "./DashboardLayoutLeft.scss";
import { availablePanels } from "./Panel/PanelRoot";

const Dashboard = memo(({ type, header, activeDashboardId, excludedToolIds, dashboardData, useSidebar, onShowDashboard }) => {
    const isHeaderDetailsExpanded = useSelector(
        (state) => state.window[windowContainerTypes.Root].views.find((view) => view.active)?.isHeaderDetailsExpanded
    );

    const { isSplitView, isActiveView } = useContext(WindowContext);
    const dashboardEntityNumber = dashboardData?.dashboardEntityNumber;
    const dashboardKey = dashboardData?.dashboardKey ?? dashboardEntityNumber;

    const panelsContainerId = useId();
    const [tempPanel, setTempPanel] = useState();

    const dispatch = useDispatch();
    const [dashboards = [], isLoading] = useDashboards({
        dashboardType: type,
    });

    const isDesktop = useSelector((state) => state.window?.isDesktop);

    const activeDashboard = dashboards.find((d) => d.id === activeDashboardId);
    const activeToolType = useSelector((state) => state.dashboards.activeDashboardTool[dashboardKey]);
    const activeTempPanel = useSelector((state) => state.dashboards.activeTempPanel?.[dashboardKey]);
    const sidebarExpanded = useSelector((state) => state.dashboards.sidebarExpanded[dashboardKey]) ?? (isDesktop && !isSplitView);

    const dashboardSideNavLeftId = getDashboardSideNavKey({
        dashboardKey,
        position: sideNavPosition.left,
    });
    const dashboardSideNavRightId = getDashboardSideNavKey({
        dashboardKey,
        position: sideNavPosition.right,
    });

    const tools = useMemo(() => {
        return (activeDashboard?.tools || []).filter((tool) => !(excludedToolIds || []).includes(tool.id)).filter((i) => i.show);
    }, [activeDashboard, excludedToolIds]);

    const activeTool = useMemo(() => tools.find((t) => t.panelType === activeToolType), [activeToolType, tools]);

    const mainPanel = useMemo(() => {
        const panel = getPanel(activeTool?.panelType, 2);

        if (panel) {
            return {
                ...panel,
                data: dashboardData,
            };
        }

        return null;
    }, [activeTool, dashboardData]);

    const sidePanels = useMemo(() => {
        return tools
            .filter((t) => t.pinned)
            .map((t, index) => {
                return {
                    ...getPanel(t.panelType, 1),
                    order: index,
                    data: dashboardData,
                };
            });
    }, [tools, dashboardData]);

    const dashboardClass = cn("dashboard flex-one-in-column flex-column no-scroll", {
        "layout-top": activeDashboard?.layout?.type === "top-right",
        "layout-left": activeDashboard?.layout?.type === "left-right" && isDesktop,
        "all-widgets-off": tools.length === 0,
    });

    const sidenavClass = useMemo(() => {
        return cn("", {
            "flex-one-in-row": activeDashboard?.layout?.type === "left-right",
            "flex-one-in-column": activeDashboard?.layout?.type !== "left-right",
        });
    }, [activeDashboard]);

    useEffect(() => {
        if (!isActiveView) {
            dispatch(sideNavClose({ id: dashboardSideNavLeftId }));
            dispatch(sideNavClose({ id: dashboardSideNavRightId }));
        }

        return () => {
            dispatch(sideNavClose({ id: dashboardSideNavLeftId }));
            dispatch(sideNavClose({ id: dashboardSideNavRightId }));
        };
    }, [dashboardSideNavLeftId, dashboardSideNavRightId, isActiveView, dispatch]);

    useEffect(() => {
        if (dashboards.length > 0 && !dashboards.find((d) => d.id === activeDashboardId)) {
            // look for entity-specific dashboard
            let dashboard = dashboards.filter((d) => !d.isGlobal && d.entityNumber === dashboardEntityNumber)[0];

            //look for user default dashboard
            if (!dashboard) {
                dashboard = dashboards.filter((d) => !d.isGlobal && d.isDefault && !d.entityNumber)[0];
            }

            //look for global dashboard
            if (!dashboard) {
                dashboard = dashboards.filter((d) => d.isGlobal)[0];
            }

            // look for any dashboard
            if (!dashboard) {
                dashboard = dashboards[0];
            }

            onShowDashboard && onShowDashboard(dashboard.id);
        }
    }, [activeDashboardId, dashboards, dashboardEntityNumber, onShowDashboard]);

    useEffect(() => {
        if (activeDashboardId && !activeTool) {
            const tool = tools.filter((t) => t.show)[0];
            if (tool) {
                dispatch(
                    setActiveDashboardTool({
                        entityNumber: dashboardKey,
                        tool: tool.panelType,
                    })
                );
            }
        }
    }, [activeDashboardId, dashboardKey, activeTool, tools, dispatch]);

    const onToolPin = useCallback(
        (tool) => {
            const panelType = (tool.panelType || tool.type).replace("-small", "");
            const dashboard = {
                ...activeDashboard,
                tools: activeDashboard.tools.map((t) => ({
                    ...t,
                    pinned: t.panelType === panelType ? !t.pinned : t.pinned,
                })),
                entityNumber: activeDashboard.entityNumber,
            };

            dispatch(saveDashboard({ dashboard }));
        },
        [activeDashboard, dispatch]
    );

    const onToolActivate = useCallback(
        (tool) => {
            if (tool) {
                dispatch(sideNavClose({ id: dashboardSideNavLeftId }));
                dispatch(sideNavClose({ id: dashboardSideNavRightId }));
                dispatch(setActiveTempPanel({ panel: null, activeToolType, entityNumber: dashboardKey }));

                dispatch(
                    setActiveDashboardTool({
                        entityNumber: dashboardKey,
                        tool: tool.panelType,
                    })
                );

                // Scroll to top if new panel opened
                const container = document.getElementById(panelsContainerId);
                if (container) {
                    container.scrollTop = 0;
                }
            }
        },
        [dispatch, dashboardSideNavLeftId, dashboardSideNavRightId, activeToolType, dashboardKey, panelsContainerId]
    );

    const onToggleSidebar = useCallback(() => {
        dispatch(toggleSidebar({ dashboardKey, state: !sidebarExpanded }));
    }, [sidebarExpanded, dashboardKey, dispatch]);

    const onToggleSettings = useCallback(() => {
        dispatch(toggleWidgetConfiguration({ entityNumber: dashboardKey }));
    }, [dashboardKey, dispatch]);

    const onExpandPanel = useCallback(
        (panel) => {
            if ((!isDesktop || isSplitView) && sidebarExpanded) {
                onToggleSidebar();
            }

            const largePanel = getPanel(panel.type, 2);
            if (largePanel) {
                onToolActivate({
                    panelType: largePanel.type,
                });
            }
        },
        [isDesktop, isSplitView, sidebarExpanded, onToggleSidebar, onToolActivate]
    );

    const onToggleTempPanel = useCallback(
        (panel, props = {}, dontStore = false) => {
            if (dontStore) {
                const Panel = availablePanels[panel];
                setTempPanel(
                    <Panel
                        panel={mainPanel}
                        onToggleTempPanel={onToggleTempPanel}
                        onUnpin={onToolPin}
                        onExpand={onExpandPanel}
                        {...props}
                    />
                );
            } else {
                dispatch(setActiveTempPanel({ panel, activeToolType, entityNumber: dashboardKey }));
                if (!panel) {
                    setTempPanel();
                }
            }
        },
        [activeToolType, dashboardKey, dispatch, mainPanel, onExpandPanel, onToolPin]
    );

    useEffect(() => {
        const Panel = availablePanels[activeTempPanel?.tempPanel];
        if (Panel && activeTempPanel?.activeToolType === activeToolType) {
            setTempPanel(<Panel panel={mainPanel} onToggleTempPanel={onToggleTempPanel} onUnpin={onToolPin} onExpand={onExpandPanel} />);
        } else {
            setTempPanel();
        }
    }, [activeTempPanel, activeToolType, dashboardData, mainPanel, onExpandPanel, onToggleTempPanel, onToolPin]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className={dashboardClass}>
            <div
                className={cn("header-view-wrapper", {
                    "header-more-details-opened": isHeaderDetailsExpanded,
                })}
            >
                {header}
                {activeDashboard && (
                    <DashboardTools tools={tools} activeTool={activeTool} onToolPin={onToolPin} onToolActivate={onToolActivate} />
                )}
            </div>
            <SideNavContainer
                className={sidenavClass + (isHeaderDetailsExpanded ? " header-more-details-opened" : "")}
                sideNavIds={[dashboardSideNavLeftId, dashboardSideNavRightId]}
            >
                <SideNavRoot id={dashboardSideNavLeftId} onConfirmClose={openConfirmModal} />
                <ScrollSyncPane>
                    <div id={panelsContainerId} className="dashboard-panels-container popup-boundary fill-height fill-width">
                        <div className="main-grid-wrap responsive fill-height">
                            <DashboardPanels
                                mainPanel={mainPanel}
                                sidePanels={sidePanels}
                                sidebarExpanded={sidebarExpanded}
                                onUnpin={onToolPin}
                                onExpand={onExpandPanel}
                                onToggleSidebar={onToggleSidebar}
                                onToggleTempPanel={onToggleTempPanel}
                                onToggleSettings={onToggleSettings}
                                useSidebar={useSidebar}
                                tempPanel={tempPanel}
                            />
                        </div>
                    </div>
                </ScrollSyncPane>
                <SideNavRoot id={dashboardSideNavRightId} onConfirmClose={openConfirmModal} />
            </SideNavContainer>
        </div>
    );
});

export default Dashboard;
