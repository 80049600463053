import React, { useState, useCallback, useMemo, memo } from "react";

import ExpandableList from "../../../../List/ExpandableList";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import RebateDetails from "./RebateDetails";
import RebatesListItem from "./RebatesListItem";

import "./RebatesList.scss";

const RebatesList = memo(({ rebates }) => {
    const [expandedRebateNumber, setExpandedRebateNumber] = useState();

    const expandedRebate = useMemo(() => {
        return rebates.find((i) => i.number === expandedRebateNumber);
    }, [rebates, expandedRebateNumber]);

    const handleClose = useCallback(() => {
        setExpandedRebateNumber();
    }, []);

    const handleToggleExpand = useCallback((item) => {
        setExpandedRebateNumber(item?.number);
    }, []);

    if (!rebates || rebates.length === 0) {
        return <NothingFoundBlock nothingFoundBlockSmall icon="rebates" title="No Payments Exists" />;
    }

    return (
        <div className="rebates-list">
            <ExpandableList
                itemKey="number"
                items={rebates}
                showOnlyDetails={false}
                onToggle={handleToggleExpand}
                expandedItem={expandedRebate}
                ItemListDetails={(props) => <RebatesListItem {...props} />}
                ItemExpandedDetails={(props) => <RebateDetails {...props} onClose={handleClose} />}
            />
        </div>
    );
});

export default RebatesList;
