import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import { getDropdownContainer, getDropdownZIndex } from "components/utils/dom";
import useUnmounted from "components/utils/useUnmounted";
import { useWindowResize } from "components/utils/useWindowResize";
import Portal from "../Portal";

const DropdownListWrapper = ({
    className,
    triggerRef,
    popupRef,
    popperRef,
    enabled,
    visible,
    filled,
    multipleChoice,
    children,
    zIndexOverride,
    zIndexOffset,
}) => {
    const getListWidth = useCallback(() => {
        let width = undefined;

        if (triggerRef.current) {
            let styles = window.getComputedStyle(triggerRef.current);
            const borderLeftWidth = parseInt(styles.getPropertyValue("border-left-width"), 10);
            const borderRightWidth = parseInt(styles.getPropertyValue("border-right-width"), 10);

            width = triggerRef.current.clientWidth + borderLeftWidth + borderRightWidth;
        }

        return width;
    }, [triggerRef]);

    const [windowWidth] = useWindowResize();
    const [listWidth, setListWidth] = useState();
    const unmounted = useUnmounted();

    useEffect(() => {
        setTimeout(() => {
            if (!unmounted.current && visible) {
                setListWidth((prevValue) => {
                    const newWidth = getListWidth();
                    if (newWidth !== prevValue) {
                        popperRef.current && popperRef.current.forceUpdate();
                    }

                    return newWidth;
                });
            }
        }, 30);
    }, [popperRef, windowWidth, visible, getListWidth, unmounted]);

    if (!enabled) {
        return children;
    }

    return (
        <Portal container={getDropdownContainer({ element: triggerRef.current })}>
            <div
                className={cn("field-group-dropdown-popup__container", className, {
                    filled: filled,
                    "multiple-choice": multipleChoice,
                })}
                ref={popupRef}
                style={{
                    width: listWidth ?? getListWidth(),
                    zIndex: getDropdownZIndex({
                        element: triggerRef.current,
                        zIndexOverride,
                        zIndexOffset: zIndexOffset,
                    }),
                }}
            >
                {children}
            </div>
        </Portal>
    );
};

export default DropdownListWrapper;
