import React from "react";
import { isEqual, pick, debounce } from "lodash";

import ClassNames from "classnames";
import Form from "react-jsonschema-form";
import Button from "../../Button";

import ObjectFieldTemplate from "./fields/ObjectFieldTemplate";

import { flattenFormData, disableAutocomplete, DEFAULT_AUTOCOMPLETE, standardFormValidate, addExtraError } from "../../../utils/form";
import { logError } from "../../../utils/logger";
import { isInViewport } from "components/utils/dom";
import { createId } from "components/utils/string";
import { fields } from "./fields";
import { widgets } from "./widgets";

import "./style.scss";

class JsonSchemaForm extends React.Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.uniqueIdPrefix = createId();

        const { initialValues } = this.props;

        this.state = {
            form: Form,
            formData: initialValues || {},
            extraErrors: [],
        };

        this.ObjectFieldTemplate = ObjectFieldTemplate;

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onFormBlur = this.onFormBlur.bind(this);
        this.onError = this.onError.bind(this);
        this.onFormReset = this.onFormReset.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormValidate = this.onFormValidate.bind(this);

        this.showSubmitButton = this.showSubmitButton.bind(this);
        this.showResetButton = this.showResetButton.bind(this);
        this.setExtraError = this.setExtraError.bind(this);
    }

    componentDidMount() {
        // Try to disable autocomplete
        disableAutocomplete({ formRef: this.formRef });

        this.formRef.current.getInitialValues = () => ({
            ...this.props.initialValues,
        });
        this.formRef.current.setFormData = (formData) => {
            this.setState({ formData });
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValues && !isEqual(prevProps.initialValues, this.props.initialValues)) {
            if (isEqual(this.props.initialValues, {})) {
                return this.setState({
                    formData: {},
                });
            }

            const formData = {
                ...pick(this.state.formData, Object.keys(this.props.initialValues)),
                ...this.props.initialValues,
            };

            this.setState({ formData });
        }
    }

    componentWillReceiveProps(newProps) {
        const { schema, uiSchema } = newProps;

        if (Object.keys(schema).length === 0 && Object.keys(uiSchema).length === 0) {
            this.setState({
                formData: {},
            });
        }

        this.setState({
            form: Form,
        });
    }

    onFormSubmit(form) {
        const data = flattenFormData(form, this.props.fieldKey);

        if (this.props.onSubmit) {
            this.props.onSubmit(data);
        }

        if (this.props.formRef && this.props.formRef.current && this.props.formRef.current.submitCallback) {
            this.props.formRef.current.submitCallback(null, data);
        }
    }

    onFormBlur() {
        if (this.props?.onBlur) {
            this.props.onBlur();
        }
    }

    onFormChange(form) {
        // TODO: Quick fix. check if submited values are for current schema.
        if (Object.keys(form.schema).length === Object.keys(this.props.schema).length) {
            this.setState(
                {
                    formData: form.formData,
                },
                () => {
                    if (this.props.onChange) {
                        // Debounce on change callbacks to not trigger on every char
                        if (this.props.debounce) {
                            debouncedOnChange({
                                onChange: this.props.onChange,
                                form,
                                fieldKey: this.props.fieldKey,
                            });
                        } else {
                            this.props.onChange({
                                formData: flattenFormData(form, this.props.fieldKey),
                                errorSchema: form.errorSchema,
                            });
                        }
                    }
                }
            );
        }
    }

    onError(form) {
        const { onError } = this.props;

        if (this.formRef && this.formRef.current) {
            const errorEl = this.formRef.current.formElement.querySelector(".field-error");
            if (errorEl && !isInViewport(errorEl)) {
                errorEl.scrollIntoView();
            }
        }

        logError(form);
        onError && onError(form);

        if (this.props.formRef && this.props.formRef.current && this.props.formRef.current.submitCallback) {
            this.props.formRef.current.submitCallback(form, null);
        }
    }

    onFormReset() {
        const { onReset } = this.props;

        this.setState({
            formData: this.props.initialValues,
        });

        onReset && onReset();
    }

    onFormCancel(form) {
        if (this.props.onCancel) {
            this.props.onCancel({ formData: form.formData });
        }
    }

    onFormValidate(formData, errors) {
        const { validate, uiSchema } = this.props;

        return standardFormValidate({
            formData,
            errors,
            validate,
            extraErrors: this.state.extraErrors,
            idPrefix: uiSchema?.["ui:rootFieldId"] ?? this.uniqueIdPrefix,
        });
    }

    setExtraError(id, message) {
        const extraErrors = addExtraError({ id, message, extraErrors: this.state.extraErrors });
        this.setState({
            extraErrors,
        });
    }

    showSubmitButton() {
        const { schema, noSubmit, readOnly } = this.props;
        return !noSubmit && !readOnly && schema && Object.keys(schema).length > 0;
    }
    showResetButton() {
        const { schema, noReset } = this.props;
        return !noReset && schema && Object.keys(schema).length > 0;
    }
    render() {
        const {
            schema,
            uiSchema,
            disabled,
            centeredFooter,
            className,
            rules = [],
            formRef,
            viewOnly,
            readOnly,
            noValidate,
            hidden,
            emptyItemInSelectLists,
            selectListsWithPopper,
            formContext,
        } = this.props;
        const {
            withCancel,
            submitText,
            infoMessage,
            cancelTitle,
            resetDisabled,
            resetText,
            cancelText,
            submitIcon,
            submitIsPrimary = true,
            submitDisabled,
            cancelIcon,
            cancelDisabled,
            resetIcon,
            liveValidate,
            autocomplete = DEFAULT_AUTOCOMPLETE,
            onCancel,
            otherActions,
        } = this.props;

        if (!schema) {
            return null;
        }

        let submitButtonText = submitText || "Submit";
        let resetButtonText = resetText || "Reset";
        let cancelButtonText = cancelText || (readOnly ? "Close" : "Cancel");

        let submitButtonIcon = submitIcon;
        let resetButtonIcon = resetIcon;
        let cancelButtonIcon = cancelIcon;
        let cancelTitleText = cancelTitle;

        const formClass = ClassNames("json-form", className);
        const JsonForm = this.state.form;

        let filteredForm = { schema, uiSchema, rules };

        if (viewOnly) {
            filteredForm.uiSchema = JSON.parse(JSON.stringify(uiSchema).replace(/"ui:widget":".*?"/gim, '"ui:widget":"ViewOnlyWidget"'));
        }

        if (readOnly) {
            filteredForm.uiSchema = {
                ...filteredForm.uiSchema,
                "ui:readonly": true,
            };
        }

        if (formRef) {
            this.formRef = formRef;
        }

        return (
            <div className={formClass} hidden={hidden}>
                <JsonForm
                    formContext={{
                        // Empty option in not required dropdown fields
                        emptyItemInSelectLists,

                        // Render dropdown list in portal with popper
                        selectListsWithPopper,

                        // Form context from props
                        ...(formContext ?? {}),

                        // Function to set extra errors to be shown on submit
                        setExtraError: this.setExtraError,

                        // List of extra errors
                        extraErrors: this.state.extraErrors,
                    }}
                    ref={this.formRef}
                    idPrefix={this.uniqueIdPrefix}
                    schema={filteredForm.schema}
                    uiSchema={filteredForm.uiSchema}
                    formData={this.state.formData}
                    widgets={widgets}
                    fields={fields}
                    ObjectFieldTemplate={this.ObjectFieldTemplate}
                    showErrorList={false}
                    noHtml5Validate
                    autocomplete={autocomplete}
                    disabled={disabled}
                    onChange={this.onFormChange}
                    onSubmit={this.onFormSubmit}
                    onBlur={this.onFormBlur}
                    onError={this.onError}
                    liveValidate={liveValidate}
                    noValidate={noValidate}
                    validate={this.onFormValidate}
                >
                    {infoMessage ? infoMessage : <></>}
                    <div className={"action-buttons flex-row" + (centeredFooter ? " justify-center" : "")} hidden={this.props.noActions}>
                        {this.showSubmitButton() && (
                            <Button icon={submitButtonIcon} type="submit" primary={submitIsPrimary} disabled={disabled || submitDisabled}>
                                {submitButtonText}
                            </Button>
                        )}
                        {this.showResetButton() && (
                            <Button icon={resetButtonIcon} type="button" disabled={disabled || resetDisabled} onClick={this.onFormReset}>
                                {resetButtonText}
                            </Button>
                        )}
                        {(withCancel || onCancel) && !disabled && (
                            <Button
                                title={cancelTitleText}
                                icon={cancelButtonIcon}
                                type="button"
                                disabled={cancelDisabled}
                                onClick={this.onFormCancel}
                            >
                                {cancelButtonText}
                            </Button>
                        )}
                        {otherActions}
                    </div>
                </JsonForm>
            </div>
        );
    }
}

// Debounce form onChange callback
const debouncedOnChange = debounce(({ onChange, form, fieldKey }) => {
    if (onChange) {
        onChange({
            formData: flattenFormData(form, fieldKey),
            errorSchema: form.errorSchema,
        });
    }
}, 500);

export default JsonSchemaForm;
