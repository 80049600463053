import React from "react";

import { useResource } from "../../../../../store/resources/useResource";
import WaitIcon from "../../../WaitIcon";
import NothingFoundBlock from "../../../NothingFoundBlock";
import IconHandMade from "../../../Icons/IconHandMade";
import Button from "../../../Button";

import "./TemplateFileSelect.scss";

const TemplateFileSelect = (props) => {
    const { utilityNumber, onSelect } = props;

    const [files, isLoadingFiles] = useResource({
        resourceName: "fileList",
        key: utilityNumber,
        query: {
            entityTypeId: 904,
            entityId: utilityNumber,
        },
        transform: (data) => {
            return (data && data.fileList) || [];
        },
    });

    return (
        <div className="file-upload-form">
            <div className="program-template-select-form">
                {isLoadingFiles && <WaitIcon />}
                {!isLoadingFiles && files.length === 0 && (
                    <NothingFoundBlock
                        nothingFoundBlockSmall
                        nothingFoundBlockNoBorder
                        icon="files"
                        title="There are no available files."
                    />
                )}
                {files.map((file) => (
                    <div key={file} className="file-template-name" onClick={() => onSelect(file)}>
                        <IconHandMade iconDocumentHandMade title={file.split(".").pop()}></IconHandMade>
                        <span className="flex-row justify-space-between align-center">
                            {file}
                            <Button icon="touch-empty" small primary>
                                select
                            </Button>
                        </span>
                        <div className="file-watermark">{file.split(".").pop()}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TemplateFileSelect;
