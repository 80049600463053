import React from "react";
import ListItemCategory from "../List/ListItemCategory";
import IconWrap from "../Icons";
import { WarningMessage } from "../Message";
import IconWithLabel from "../Icons/IconWithLabel";
import { renderCategory } from "../Dashboard/Panel/Project/utils";
import StatusMark from "../StatusMark";

export const SearchResults = ({ title, itemType, categoryItemNumberKey, searchTerm, searchResults, onSelectItem, onResetSearch }) => {
    if (searchTerm && searchResults) {
        if (searchResults.length > 0) {
            return (
                <div className="catalog-columns search">
                    {searchResults.map((item) => (
                        <ListItemCategory key={item[categoryItemNumberKey]} onClick={() => onSelectItem(item)}>
                            <div className="flex-row align-center">
                                <div className="category">{renderCategory(item.category, item.categoryParent)}</div>
                                <div className={"equipment-name-wrap flex-row fill-width align-center flex-one " + itemType}>
                                    <StatusMark statusMarkNeutral>{itemType}</StatusMark>
                                    <div className="equipment-name flex-one">{item.name}</div>
                                </div>
                            </div>
                            <IconWrap icon="plus" title={title} onClick={() => onSelectItem(item)} />
                        </ListItemCategory>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="no-equipment-found-block flex-column align-center">
                    <WarningMessage msgCentered>
                        No {itemType} found for the "{searchTerm}". Please adjust your search.
                    </WarningMessage>
                    <IconWithLabel icon="backup-restore-empty" onClick={onResetSearch}>
                        Reset Search
                    </IconWithLabel>
                </div>
            );
        }
    }

    return null;
};
