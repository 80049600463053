import React, { memo } from "react";
import { formatJsonDateTime } from "../../../../../utils/date";
import { useResource } from "store/resources/useResource";
import ActionLabel from "../../../../Label/ActionLabel";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import WaitIcon from "../../../../WaitIcon";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./EventsPanelSmall.scss";

const headers = {
    category: "Event",
    startdate: "Start",
    enddate: "End",
    duration: "Duration",
};

const EventsList = memo(({ onSelectEvent, resourceName, applicationNumber }) => {
    const [events, isLoading] = useResource({
        resourceName,
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        forced: true,
    });

    const renderItem = (item, index) => {
        return (
            <div key={index} className="list-item-row">
                <div className="item-value">
                    <ActionLabel onClick={() => onSelectEvent(item)}>{item.category}</ActionLabel>
                </div>
                <div className="item-value">{formatJsonDateTime(item.startdate, "")}</div>
                <div className="item-value">{formatJsonDateTime(item.enddate, "")}</div>
                <div className="item-value">{item.duration ? `${item.duration} days` : ""}</div>
            </div>
        );
    };

    return (
        <div className={"events-list flex-column" + (events && events.length !== 0 ? "" : " no-data")}>
            <div className="sidebar-list-body flex-column">
                {isLoading ? (
                    <WaitIcon />
                ) : events && events.length !== 0 ? (
                    <CustomList
                        limit={6}
                        items={events}
                        headers={headers}
                        filterByHeaders
                        renderItem={renderItem}
                        renderFooter={renderCustomFooter}
                    />
                ) : (
                    <div className="events-view-sidebar no-info">
                        <NothingFoundBlock nothingFoundBlockSmall icon="events" title="No Data Available" />
                    </div>
                )}
            </div>
        </div>
    );
});

export default EventsList;
