import DropDownInput from "components/ui/Input/DropDownInput";
import React from "react";
import { dateToJson } from "components/utils/date";
import { DateRange } from "../types";

import "./styles.scss";

export const DateSelector = ({ dateRange, disabled, onDateRangeChange, includeCustomRange }: DateSelectorProps) => {
    const dateRangeItems = Object.values(DateRanges)
        .map((range) => ({
            value: range,
            label: range,
        }))
        .filter((range) => includeCustomRange || range.value !== DateRanges.Custom);

    return (
        <DropDownInput
            // @ts-ignore
            className="analytics-date-range-dropdown"
            label="Date range"
            disabled={disabled}
            data={dateRangeItems}
            onChange={(e: { target: { value: DateRangeOption } }) => onDateRangeChange(e.target.value)}
            value={dateRange}
        />
    );
};

export const getDateRange = (dateRange: DateRangeOption): DateRange => {
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    let dateFrom = new Date(currentDate);
    let dateTo = new Date(new Date().setHours(23, 59, 59, 999));

    switch (dateRange) {
        case DateRanges.Today:
            break;
        case DateRanges.Last7Days:
            dateFrom.setDate(currentDay - 7);
            break;
        case DateRanges.Last30Days:
            dateFrom.setDate(currentDay - 30);
            break;
        case DateRanges.MonthToDate:
            dateFrom.setDate(1);
            break;
        case DateRanges.LastMonth:
            if (currentMonth === 0) {
                dateFrom.setFullYear(currentYear - 1);
            }
            dateFrom.setMonth(currentMonth === 0 ? 11 : currentMonth - 1, 1);
            dateTo.setMonth(currentMonth, 0);
            break;
        case DateRanges.YearToDate:
            dateFrom.setMonth(0, 1);
            break;
        case DateRanges.Last12Months:
            dateFrom.setFullYear(currentYear - 1);
            dateFrom.setMonth(currentMonth + 1, 1);
            break;
        case DateRanges.AllTime:
            dateFrom.setFullYear(1970, 0, 1);
            break;
        default:
            break;
    }

    return {
        dateFrom: dateToJson(dateFrom)!,
        dateTo: dateToJson(dateTo)!,
    };
};

interface DateSelectorProps {
    dateRange: DateRangeOption;
    disabled?: boolean;
    includeCustomRange?: boolean;
    onDateRangeChange: (value: DateRangeOption) => void;
}

export const DateRanges = {
    Today: "Today",
    Last7Days: "Last 7 days",
    Last30Days: "Last 30 days",
    MonthToDate: "Month to Date",
    LastMonth: "Last Month",
    YearToDate: "Year to Date",
    Last12Months: "Last 12 months",
    AllTime: "All Time",
    Custom: "Custom",
} as const;

export type DateRangeOption = typeof DateRanges[keyof typeof DateRanges];
