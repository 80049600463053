import React, { useState, useCallback, memo, useMemo, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { useResource } from "../../../../store/resources/useResource";
import { createResource, getResource } from "../../../../store/resources/actions";
import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import IconWrap from "../../../ui/Icons";
import DropDownInput from "../../../ui/Input/DropDownInput";
import ContentTitle from "../../../ui/Title/ContentTitle";
import ContentSubtitle from "../../../ui/Title/ContentSubtitle";
import Contact from "./Contact";
import Contractor from "./Contractor";
import CustomerSearch from "./CustomerSearch";
import CustomerSearchCIS from "./CustomerSearchCIS";
import classNames from "classnames";
import {
    useBackEndRequirePrimaryContact,
    useBackEndRequirePremiseContact,
    usePrimaryContactDescription,
    usePremiseContactDescription,
    useContractorDescription,
    useBackEndRequireContractor,
} from "../../ProgramView/utils";
import Button from "../../../ui/Button";
import ButtonGroup from "../../../ui/Button/ButtonGroup";
import "./Contact.scss";
import { onCustomerLookup } from "components/ui/Customer/actions";
import { store } from "store/configureStore";
import { isEmpty, isEqual } from "lodash";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";
import { isEmailAddress } from "components/utils/form";
import cn from "classnames";
import { WindowContext } from "components/ui/Windows/Window";

const ContactsPanel = memo(
    ({
        fromScan,
        utilityNumber,
        programNumber,
        applicationNumber,
        customer,
        customerFormRef,
        premise,
        premiseFormRef,
        primary,
        primaryFormRef,
        contractor,
        contractorFormRef,
        onCustomerCopy,
        onChange,
    }) => {
        const customerFormActionInProgress = useRef(false);
        const mouseDownCopy = useRef(false);
        const dispatch = useDispatch();
        const { isSplitView } = useContext(WindowContext);

        const [searchParamsChanged, setSearchParamsChanged] = useState(false);
        const [backgroundSearch, setBackgroundSearch] = useState();
        const [premiseContactNumber, setPremiseContactNumber] = useState();
        const [foundCustomers, setFoundCustomers] = useState(null);
        const [isFoundCustomer, setIsFoundCustomer] = useState(false);
        const [primaryContactNumber, setPrimaryContactNumber] = useState();
        const [premiseContactIsLoading, setPremiseContactIsLoading] = useState(false);
        const [primaryContactIsLoading, setPrimaryContactIsLoading] = useState(false);
        const [isEmailInvalid, setIsEmailInvalid] = useState(false);

        const primaryContactSettings = useBackEndRequirePrimaryContact({
            programNumber,
        });
        const premiseContactSettings = useBackEndRequirePremiseContact({
            programNumber,
        });
        const contractorSettings = useBackEndRequireContractor({
            programNumber,
        });

        const primaryContactDescription = usePrimaryContactDescription({
            programNumber,
        });
        const premiseContactDescription = usePremiseContactDescription({
            programNumber,
        });
        const contractorDescription = useContractorDescription({
            programNumber,
        });

        const requirePrimaryContact = primaryContactSettings === "y";
        const requirePremiseContact = premiseContactSettings === "y";

        const hidePrimaryContact = primaryContactSettings === "h";
        const hidePremiseContact = premiseContactSettings === "h";
        const hideContractor =
            contractorSettings === "h" ||
            (contractorSettings === "r" && !(contractor.company || contractor.firstName || contractor.lastName));

        const [showPrimaryContactForm, setShowPrimaryContactForm] = useState(!isEmpty(primary));
        const [showPremiseContactForm, setShowPremiseContactForm] = useState(!isEmpty(premise));

        const allContactsHidden = hidePrimaryContact && hidePremiseContact && hideContractor;

        const [customerContacts, isLoadingCustomerContacts] = useResource({
            resourceName: "customerContacts",
            key: customer.customerNumber,
            path: {
                utilityNumber: utilityNumber,
                customerNumber: customer.customerNumber,
            },
        });

        const customerContactsList =
            (customerContacts &&
                customerContacts.map((i) => ({
                    label: i.contactDescription,
                    value: i.contactNumber,
                }))) ||
            [];

        const setContactNumber = useCallback((contactType, contactNumber) => {
            switch (contactType) {
                case "premise":
                    setPremiseContactNumber(contactNumber);
                    break;

                case "primary":
                    setPrimaryContactNumber(contactNumber);
                    break;

                default:
                    break;
            }
        }, []);

        const setContactIsLoading = useCallback((contactType, value) => {
            switch (contactType) {
                case "premise":
                    setPremiseContactIsLoading(value);
                    break;

                case "primary":
                    setPrimaryContactIsLoading(value);
                    break;

                default:
                    break;
            }
        }, []);

        const emptyFields = useMemo(() => {
            return isEmpty(customer.accountNumber?.trim()) && isEmpty(customer.email?.trim()) && isEmpty(customer.lastName?.trim());
        }, [customer]);

        const handleCopyCustomerToPremise = useCallback(
            (data) => {
                setContactIsLoading("premise", true);

                onCustomerCopy(data);

                setShowPremiseContactForm(true);
                setContactIsLoading("premise", false);
                mouseDownCopy.current = false;
            },
            [setContactIsLoading, onCustomerCopy]
        );

        const checkCustomer = useCallback(
            (cisCustomer) => {
                const requestFormData = cisCustomer || customerFormRef.current.state.formData;
                const { accountNumber, firstName, lastName, email } = requestFormData;
                if (!isEmpty(email) && !isEmailAddress(email)) {
                    setIsEmailInvalid(true);
                    return;
                } else {
                    setIsEmailInvalid(false);
                }

                if ((!emptyFields || cisCustomer) && !customerFormActionInProgress.current) {
                    setBackgroundSearch(true);
                    store.dispatch(
                        createResource({
                            resourceName: "utilityCustomerSearchV2",
                            body: {
                                firstName: firstName?.trim() || null,
                                lastName: lastName?.trim() || null,
                                email: email?.trim() || null,
                                accountNumber: accountNumber?.trim() || null,
                                entityType: "Application",
                                entityId: applicationNumber,
                            },
                            onSuccess: (action) => {
                                const currentFormData = customerFormRef.current.state.formData;
                                if (!isEqual(currentFormData, requestFormData)) {
                                    return;
                                }
                                setBackgroundSearch(false);
                                setSearchParamsChanged(false);

                                const customers = action.data.customerList;
                                setFoundCustomers(customers);
                                if (
                                    customers.length === 1 &&
                                    customers[0].accountNumber &&
                                    !emptyFields &&
                                    (!currentFormData.firstName || currentFormData.firstName === customers[0].firstName)
                                ) {
                                    setIsFoundCustomer(true);
                                    onChange("customer", customers[0]);
                                    if (mouseDownCopy.current) {
                                        handleCopyCustomerToPremise(customers[0]);
                                        mouseDownCopy.current = false;
                                    }
                                } else {
                                    setIsFoundCustomer(false);
                                    if (mouseDownCopy.current) {
                                        handleCopyCustomerToPremise(currentFormData);
                                        mouseDownCopy.current = false;
                                    }
                                }
                            },
                            onError: () => {
                                setIsFoundCustomer(false);
                                setBackgroundSearch(false);
                            },
                        })
                    );
                }
                customerFormActionInProgress.current = false;
            },
            [customerFormRef, emptyFields, handleCopyCustomerToPremise, onChange, applicationNumber]
        );

        const handleExistingContactChange = useCallback(
            (contactType, contactNumber) => {
                setContactNumber(contactType, contactNumber);
                setContactIsLoading(contactType, true);

                dispatch(
                    getResource({
                        resourceName: "customerContacts",
                        resourceId: contactNumber,
                        path: {
                            utilityNumber: utilityNumber,
                            customerNumber: customer.customerNumber,
                        },
                        onSuccess: (action) => {
                            onChange(contactType, {
                                ...action.data,
                                contacttype: 0,
                            });
                        },
                        onComplete: () => setContactIsLoading(contactType, false),
                    })
                );
            },
            [utilityNumber, customer, setContactNumber, setContactIsLoading, onChange, dispatch]
        );

        const handleCopyPremiseToPrimary = useCallback(() => {
            setContactIsLoading("primary", true);

            const premise = premiseFormRef.current?.state?.formData || {};

            onChange("primary", {
                ...premise,
            });

            setShowPrimaryContactForm(true);
            setContactIsLoading("primary", false);
        }, [premiseFormRef, setContactIsLoading, onChange]);

        const handleChangeCustomer = useCallback(
            (data) => {
                onChange("customer", data);
                setBackgroundSearch(false);
                setSearchParamsChanged(true);
                customerFormActionInProgress.current = false;
            },
            [onChange]
        );

        const handleCustomerListSelect = useCallback(
            (data) => {
                setIsFoundCustomer(true);
                onChange("customer", data);
            },
            [onChange]
        );

        const handleSelectCustomerCis = useCallback(
            (data) => {
                setContactIsLoading("premise", true);

                const premise = premiseFormRef.current?.state?.formData || {};

                onChange("premise", {
                    ...premise,
                    ...data,
                    email: premise.email || data.email,
                });

                setShowPrimaryContactForm(true);
                setShowPremiseContactForm(true);
                setContactIsLoading("premise", false);
                const customer = {
                    accountNumber: data.accountNumber,
                    firstName: data.firstname,
                    lastName: data.lastname,
                    email: data.email,
                };
                onChange("customer", customer);

                const primary = primaryFormRef.current?.state?.formData || {};
                onChange("primary", {
                    ...primary,
                    ...data,
                    email: primary.email || data.email,
                    address: data.mailingAddress || "",
                    city: data.mailingAddress ? data.mailingCity : "",
                    phone: data.mailingAddress ? data.mailingPhone : "",
                    state: data.mailingAddress ? data.mailingState : "",
                    zip: data.mailingAddress ? data.mailingZip : "",
                });
            },
            [setContactIsLoading, premiseFormRef, onChange, primaryFormRef]
        );

        const handleCustomerClear = useCallback(() => {
            setIsFoundCustomer(false);
            onChange("customer", {});
        }, [onChange]);

        const handleUnlink = () => {
            onChange("customer", { ...customer, customerNumber: null });
            setIsFoundCustomer(false);
        };

        const handleCustomerSearch = useCallback(() => {
            onCustomerLookup({
                utilityNumber,
                onSelect: handleCustomerListSelect,
                customers: foundCustomers,
            });
        }, [foundCustomers, handleCustomerListSelect, utilityNumber]);

        const customerFormActionMouseDown = (event) => {
            if (event.button === 0) {
                customerFormActionInProgress.current = true;
            }
        };

        const onMouseDownCopy = (event) => {
            if (event.button === 0) {
                mouseDownCopy.current = true;
            }
        };

        const canSearchCIS = hasAnyOfPermissions([systemUserRights.VISIONDSM_CIS_SEARCH]);

        const hintText = canSearchCIS ? "Search for a customer in CIS or manually" : "Search for a customer";

        const rightBlockClassNames = classNames("section-right-block", {
            "no-cis": !canSearchCIS,
        });

        return (
            <div className="contacts">
                <div className={"new-app-section customer-search" + (fromScan ? " from-scan" : "")}>
                    <div className="section-name">
                        <IconWrap iconWrapBig iconWrapTheme iconWrap="search-person-empty"></IconWrap>
                        <ContentTitle>Customer Search</ContentTitle>
                        <p>{hintText}</p>
                    </div>
                    <div className="new-app-section__form">
                        {canSearchCIS && (
                            <div className={cn("section-left-block", { "split-view": isSplitView })}>
                                <ContentSubtitle>Customer Search CIS</ContentSubtitle>
                                <CustomerSearchCIS utilityNumber={utilityNumber} onSelect={handleSelectCustomerCis} />
                            </div>
                        )}
                        <div className={rightBlockClassNames}>
                            <ContentSubtitle>Customer Search</ContentSubtitle>
                            <CustomerSearch
                                emptyFields={emptyFields}
                                formKey={"customer"}
                                customerFormRef={customerFormRef}
                                customer={customer}
                                isFoundCustomer={isFoundCustomer}
                                isEmailInvalid={isEmailInvalid}
                                backgroundSearch={backgroundSearch}
                                hideCopyToPremise={allContactsHidden}
                                onSearch={handleCustomerSearch}
                                onChange={handleChangeCustomer}
                                onCopy={handleCopyCustomerToPremise}
                                onClear={handleCustomerClear}
                                onCheckCustomer={checkCustomer}
                                foundCustomers={foundCustomers}
                                handleCustomerSearch={handleCustomerSearch}
                                searchParamsChanged={searchParamsChanged}
                                onUnlink={handleUnlink}
                                customerFormActionMouseDown={customerFormActionMouseDown}
                                onMouseDownCopy={onMouseDownCopy}
                            />
                        </div>
                    </div>
                </div>

                {!hidePremiseContact && (
                    <div className="new-app-section home-business-info">
                        <div className="section-name">
                            <IconWrap iconWrapBig iconWrapTheme iconWrap="info-empty"></IconWrap>
                            <ContentTitle>
                                {(premiseContactDescription ?? "").trim() !== ""
                                    ? premiseContactDescription
                                    : "Your Home/Business Information"}
                            </ContentTitle>
                        </div>
                        {(showPremiseContactForm || requirePremiseContact) && (
                            <div className="new-app-section__form">
                                {customer.customerNumber &&
                                    !isLoadingCustomerContacts &&
                                    customerContactsList &&
                                    customerContactsList.length !== 0 && (
                                        <div className="existing-contacts">
                                            <DropDownInput
                                                label="Choose an existing contact to prefill the fields"
                                                mobileHeader="Choose an existing contact to prefill the fields"
                                                placeholder={isLoadingCustomerContacts ? "Loading..." : "Not Selected"}
                                                disabled={isLoadingCustomerContacts}
                                                data={customerContactsList}
                                                value={premiseContactNumber}
                                                onChange={(event) => handleExistingContactChange("premise", event.target.value)}
                                            />
                                            <p>Fill the fields manually</p>
                                        </div>
                                    )}
                                <Contact
                                    canSearchCIS={canSearchCIS}
                                    formKey={"premise"}
                                    formRef={premiseFormRef}
                                    utilityNumber={utilityNumber}
                                    contact={premise}
                                    isLoading={premiseContactIsLoading}
                                    onCancel={requirePremiseContact ? undefined : () => setShowPremiseContactForm(false)}
                                />
                            </div>
                        )}
                        {!showPremiseContactForm && !requirePremiseContact && (
                            <div className="new-app-section__form">
                                <ButtonGroup transparent>
                                    <Button onClick={() => setShowPremiseContactForm(true)}>Add Contact</Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                )}

                {!hidePrimaryContact && (
                    <div className="new-app-section your-contact-info">
                        <div className="section-name">
                            <IconWrap iconWrapBig iconWrapTheme iconWrap="info-empty"></IconWrap>
                            <ContentTitle>
                                {(primaryContactDescription ?? "").trim() !== "" ? primaryContactDescription : "Your Contact Information"}
                            </ContentTitle>
                        </div>
                        {(showPrimaryContactForm || requirePrimaryContact) && (
                            <div className="new-app-section__form">
                                <IconWithLabel iconWithLabel="shevron-in-circle-down-drop-down-empty" onClick={handleCopyPremiseToPrimary}>
                                    Same as Home/Business Information
                                </IconWithLabel>
                                {customer.customerNumber &&
                                    !isLoadingCustomerContacts &&
                                    customerContactsList &&
                                    customerContactsList.length !== 0 && (
                                        <div className="existing-contacts">
                                            <DropDownInput
                                                label="Choose an existing contact to prefill the fields"
                                                mobileHeader="Choose an existing contact to prefill the fields"
                                                placeholder={isLoadingCustomerContacts ? "Loading..." : "Not Selected"}
                                                disabled={isLoadingCustomerContacts}
                                                data={customerContactsList}
                                                value={primaryContactNumber}
                                                onChange={(event) => handleExistingContactChange("primary", event.target.value)}
                                            />
                                            <p>Fill the fields manually</p>
                                        </div>
                                    )}
                                <Contact
                                    canSearchCIS={canSearchCIS}
                                    formKey={"primary"}
                                    formRef={primaryFormRef}
                                    utilityNumber={utilityNumber}
                                    contact={primary}
                                    isLoading={primaryContactIsLoading}
                                    onCancel={requirePrimaryContact ? undefined : () => setShowPrimaryContactForm(false)}
                                />
                            </div>
                        )}
                        {!showPrimaryContactForm && !requirePrimaryContact && (
                            <div className="new-app-section__form">
                                <ButtonGroup transparent>
                                    <Button onClick={() => setShowPrimaryContactForm(true)}>Add Contact</Button>
                                </ButtonGroup>
                            </div>
                        )}
                    </div>
                )}

                {!hideContractor && (
                    <div className="new-app-section search-contractor">
                        <div className="section-name">
                            <IconWrap iconWrapBig iconWrapTheme iconWrap="membership-card-empty"></IconWrap>
                            <ContentTitle>
                                {(contractorDescription ?? "").trim() !== "" ? contractorDescription : "Contractor Information"}
                            </ContentTitle>
                        </div>
                        <div className="new-app-section__form">
                            <Contractor
                                formKey={"contractor"}
                                formRef={contractorFormRef}
                                programNumber={programNumber}
                                contractor={contractor}
                                onChange={(data) => onChange("contractor", data)}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

export default ContactsPanel;
