import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../../ui/WaitIcon";
import IconWithLabel from "../../../../Icons/IconWithLabel";

const Controls = memo(({ utilityNumber, budgetLineNumber, dataGridId, onAdd }) => {
    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const canCreate =
        utilityRights.includes(systemUserRights.VISIONDSM_ADD_BUDGET) || utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_BUDGET);

    return (
        <div className="grid-controls flex-row justify-end">
            {isLoadingRights ? (
                <WaitIcon />
            ) : (
                <>
                    <IconWithLabel icon="plus" disabled={!canCreate} onClick={onAdd}>
                        Add Amount
                    </IconWithLabel>
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: dataGridId,
                                fileName: "budget_line_amounts",
                                fileNamePostfix: budgetLineNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </>
            )}
        </div>
    );
});

export default Controls;
