import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { isEmpty } from "lodash";
import WaitIcon from "../../../ui/WaitIcon";
import Button from "../../../ui/Button";
import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";
import StatusMsg from "components/ui/StatusMsg";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { renderToString } from "react-dom/server";

const CustomerSearch = memo(
    ({
        foundCustomers,
        handleCustomerSearch,
        searchParamsChanged,
        isFoundCustomer,
        isEmailInvalid,
        backgroundSearch,
        onCheckCustomer,
        customer,
        customerFormRef,
        hideCopyToPremise,
        onChange,
        onCopy,
        onClear,
        onUnlink,
        customerFormActionMouseDown,
        onMouseDownCopy,
        emptyFields,
    }) => {
        const [formKey, setFormKey] = useState(false);

        const schema = {
            type: "object",
            required: ["firstName", "lastName"],
            description: renderToString(<StatusMsg msgInfo msgText="Please enter Account Number, Email or Last Name to search." />),
            properties: {
                accountNumber: {
                    type: ["string", "null"],
                    title: "Account Number",
                },
                email: {
                    type: ["string", "null"],
                    title: "Email",
                },
                firstName: {
                    type: ["string", "null"],
                    title: "First Name",
                },
                lastName: {
                    type: ["string", "null"],
                    title: "Last Name",
                },
            },
        };

        const uiSchema = {
            accountNumber: {
                classNames: "string-input",
            },
            email: {
                classNames: "string-input",
            },
            firstName: {
                classNames: "string-input",
            },
            lastName: {
                classNames: "string-input",
            },
        };

        const handleChange = ({ formData }) => {
            onChange({
                ...formData,
                customerNumber: null,
            });
        };

        const handleCopy = () => {
            const { formData } = customerFormRef.current.props;

            onCopy({
                ...formData,
            });
        };

        const handleClear = useCallback(() => {
            onClear();
            setFormKey(!formKey);
        }, [formKey, onClear]);

        const isCopyToPremiseDisabled = hideCopyToPremise || backgroundSearch || isEmpty(customer.firstName) || isEmpty(customer.lastName);

        const isCustomerFilled = useMemo(() => {
            if (Object.values(customer).some((value) => value)) {
                return true;
            }
        }, [customer]);

        // Check the customer if it is filled before touching the fields.
        useEffect(() => {
            // Check if any field is focused.
            const isFocused = customerFormRef.current.formElement.querySelectorAll(".focused").length > 0;

            if (isCustomerFilled && !isFocused) {
                // Allow form to be updated and check customer on next tick.
                setTimeout(onCheckCustomer, 10);
            }
        }, [customerFormRef, isCustomerFilled, onCheckCustomer]);

        const otherActions = (
            <div className="customer-search-form-actions flex-column">
                {!searchParamsChanged && !backgroundSearch && (
                    <>
                        {!isFoundCustomer && foundCustomers?.length > 0 && (
                            <>
                                <div className="customers-found-message flex-row">
                                    <StatusMsg msgFieldStatus msgInfo msgText={`${foundCustomers.length} customers found:`}></StatusMsg>
                                    <IconWithLabel
                                        icon="touch-empty"
                                        onClick={handleCustomerSearch}
                                        onMouseDown={customerFormActionMouseDown}
                                    >
                                        Select
                                    </IconWithLabel>
                                </div>
                                <StatusMsg
                                    msgFieldStatus
                                    msgWarning
                                    msgText="If a customer isn't selected, a new customer will be created."
                                ></StatusMsg>
                            </>
                        )}
                        {!isFoundCustomer && foundCustomers?.length === 0 && (
                            <StatusMsg
                                msgFieldStatus
                                msgWarning
                                msgText="Account does not exist as a current customer - one will be created when application is created"
                            ></StatusMsg>
                        )}
                        {isFoundCustomer && (
                            <StatusMsg
                                msgFieldStatus
                                msgSuccess
                                msgText={
                                    <span>
                                        This customer will be linked to this application.{" "}
                                        <span className="action-label" onClick={onUnlink} onMouseDown={customerFormActionMouseDown}>
                                            Unlink
                                        </span>{" "}
                                        if you want to create a new customer instead.
                                    </span>
                                }
                            ></StatusMsg>
                        )}
                    </>
                )}
                {isEmailInvalid && <StatusMsg msgFieldStatus msgError msgText="Email address must be valid email address"></StatusMsg>}
                {backgroundSearch && <WaitIcon />}
                <div className="customer-search-form-buttons flex-row">
                    <Button
                        disabled={!isCustomerFilled || backgroundSearch}
                        onClick={handleClear}
                        onMouseDown={customerFormActionMouseDown}
                    >
                        Clear
                    </Button>
                    <Button disabled={emptyFields || backgroundSearch || !searchParamsChanged} icon="search">
                        Search Customer
                    </Button>
                    <Button disabled={isCopyToPremiseDisabled} onClick={handleCopy} onMouseDown={onMouseDownCopy} icon="layers-empty">
                        Copy To Premise
                    </Button>
                </div>
            </div>
        );

        return (
            <div className="new-app-customer-search">
                <JsonSchemaForm
                    key={formKey}
                    formRef={customerFormRef}
                    initialValues={customer}
                    schema={schema}
                    uiSchema={uiSchema}
                    onChange={handleChange}
                    onBlur={onCheckCustomer}
                    noSubmit
                    noReset
                    otherActions={otherActions}
                />
            </div>
        );
    }
);

export default CustomerSearch;
