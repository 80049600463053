import React, { useCallback, memo } from "react";

import { refreshContracts } from "../../../../../../store/resources/refreshResource";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ContractList from "./ContractList";
import Form from "./Form";

import "./style.scss";

const FinancialsPanel = memo(({ panel }) => {
    const utilityNumber = panel.data.utilityNumber;

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-sidenav-panel",
    });

    const handleRefresh = useCallback(() => {
        refreshContracts({ utilityNumber });
    }, [utilityNumber]);

    const handleEditContract = useCallback(
        (contractNumber) => {
            handleOpenSidePanel(
                <Form
                    utilityNumber={utilityNumber}
                    contractNumber={contractNumber}
                    onSaved={handleRefresh}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [utilityNumber, handleRefresh, handleOpenSidePanel, handleCloseSidePanel]
    );

    return (
        <div className="panel utility-financials flex-column fill-height">
            <PanelHeaderLarge centered title={panel.name} />
            <ContractList utilityNumber={utilityNumber} onEditContract={handleEditContract} />
        </div>
    );
});

export default FinancialsPanel;
