import { isNil } from "lodash";

import { WORKFLOW } from "../constants/workflow";

export const isValidStrings = (...args) => args.every((arg) => !isNil(arg) && arg !== "");

export const hasErrorsWfStatus = ({ editStatus, isConfirmed, state }) =>
    (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && !isConfirmed) || !isValidStrings(state);

export const hasErrorsWfStep = ({ name, editStatus, term, isConfirmed, typeName, attributes }) => {
    if ((typeName === "Clear Flag" || typeName === "Flag Record") && attributes.length === 0) {
        return (
            (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && !isConfirmed) ||
            (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && isConfirmed && attributes.length === 0) ||
            !isValidStrings(name, term) ||
            (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_EDITED && attributes.length === 0)
        );
    } else {
        return (editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && !isConfirmed) || !isValidStrings(name, term);
    }
};
