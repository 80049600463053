import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";

import { useResource } from "../../../store/resources/useResource";

import Radio from "../../ui/Input/Radio";
import FieldGroup from "../../ui/FieldGroup";
import DropDownInput from "../../ui/Input/DropDownInput";

export const ownershipType = {
    any: 0,
    onAssignedApps: 1,
    withNoOwner: 2,
    ownedBy: 3,
    onAssignedGroups: 4,
};

const TasksFilterTab = memo(({ values, onChange }) => {
    const myUserNumber = useSelector((state) => state.user.userNumber);

    const [users = [], isLoadingUsers] = useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-user-list",
    });

    const ownership = values?.ownership;

    const ownershipUser = useMemo(() => {
        if (users && users.length > 0) {
            return users.find((i) => i.userNumber === ((values && values.ownershipUser) || myUserNumber))
                ? (values && values.ownershipUser) || myUserNumber
                : users[0].userNumber;
        } else {
            return null;
        }
    }, [myUserNumber, values, users]);

    const userList = users.map((u) => {
        return {
            label: u.userName,
            value: u.userNumber,
            selected: u.userNumber === ownershipUser,
        };
    });

    const onOwnershipChange = (event) => {
        onChange &&
            onChange({
                ownership: +event.target.value,
                ownershipUser: ownershipUser,
            });
    };

    const onOwnershipUserChange = (event) => {
        onChange &&
            onChange({
                ownership: ownership,
                ownershipUser: event.target.value,
            });
    };

    const ownershipRadioGroupName = "task-ownership-filter";

    return (
        <div className="list-of-radio-buttons">
            <FieldGroup>
                <Radio
                    label="Any"
                    name={ownershipRadioGroupName}
                    checked={ownership === ownershipType.any}
                    value={ownershipType.any}
                    onChange={onOwnershipChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="On My Groups Tasks"
                    name={ownershipRadioGroupName}
                    checked={ownership === ownershipType.onAssignedGroups}
                    value={ownershipType.onAssignedGroups}
                    onChange={onOwnershipChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="On My Assigned Apps"
                    name={ownershipRadioGroupName}
                    checked={ownership === ownershipType.onAssignedApps}
                    value={ownershipType.onAssignedApps}
                    onChange={onOwnershipChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="With No Owner"
                    name={ownershipRadioGroupName}
                    checked={ownership === ownershipType.withNoOwner}
                    value={ownershipType.withNoOwner}
                    onChange={onOwnershipChange}
                />
            </FieldGroup>
            <FieldGroup className="radio-plus-dropdown task-tap">
                <Radio
                    label="Owned by:"
                    name={ownershipRadioGroupName}
                    checked={ownership === ownershipType.ownedBy}
                    value={ownershipType.ownedBy}
                    onChange={onOwnershipChange}
                />
                <DropDownInput
                    inline
                    disabled={ownership !== ownershipType.ownedBy}
                    placeholder={isLoadingUsers ? "Loading..." : "Select user"}
                    mobileHeader="Select user"
                    data={userList}
                    value={isLoadingUsers ? undefined : ownershipUser}
                    onChange={onOwnershipUserChange}
                    withPopper
                />
            </FieldGroup>
        </div>
    );
});

export default TasksFilterTab;
