import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readProfile, updateProfile } from "../../../store/userProfile/actions";

import UserProfileForm from "./UserProfileForm";

const UserProfile = memo(() => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.userProfile);

    const { isLoading, isUpdating, data = {} } = formData ?? {};
    const initialValues = data;

    const [state, setState] = useState({
        showAllFields: false,
        showPasswordFields: false,
    });

    useEffect(() => {
        dispatch(readProfile());
    }, [dispatch]);

    const onShowAllFields = (state) => {
        setState((prev) => ({ ...prev, showAllFields: state }));
    };

    const onShowPasswordFields = (state) => {
        setState((prev) => ({ ...prev, showPasswordFields: state }));
    };

    const handleSubmit = (values) => {
        dispatch(
            updateProfile({
                data: values,
            })
        );
    };

    const onReset = useCallback(() => {
        dispatch(readProfile());
    }, [dispatch]);

    return (
        <UserProfileForm
            isLoading={isLoading}
            isUpdating={isUpdating}
            initialValues={initialValues}
            showAllFields={state.showAllFields}
            showPasswordFields={state.showPasswordFields}
            onShowAllFields={onShowAllFields}
            onShowPasswordFields={onShowPasswordFields}
            onSubmit={handleSubmit}
            onReset={onReset}
        />
    );
});

export default UserProfile;
