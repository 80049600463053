import React from "react";
import cn from "classnames";

const PanelWithSidePanel = ({ className, children, sidePanel, showSidePanel }) => {
    return (
        <div
            className={cn("panel-with-sidepanel flex-row", className, {
                "panel-with-sidepanel--extended": !showSidePanel,
            })}
        >
            <div className="panel-with-sidepanel__main-content flex-one-in-row">{children}</div>
            {showSidePanel && sidePanel}
        </div>
    );
};

export default PanelWithSidePanel;
