import React, { memo, useCallback, useRef, useState } from "react";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import SideNavContent from "../../../../../ui/SideNav/SideNavContent";
import SideNavHeader from "../../../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../../../ui/SideNav/SideNavFooter";
import Button from "../../../../../ui/Button";
import { submitByRef } from "components/utils/form";
import { useDispatch } from "react-redux";
import { getData } from "store/dataGrid/actions";
import { uploadFile } from "store/files/actions";
import StatusMsg from "../../../../StatusMsg";
import { renderToString } from "react-dom/server";
const FileUpload = memo(({ onClose, gridId, entityId, files }) => {
    const formRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const submitText = isSubmitting ? "Uploading..." : "Upload";
    const fileTypes = files ? files.supportedFileTypes : "";
    const fileLimitText = files ? (
        <p className="info-text">
            <b>Supported formats:</b> {fileTypes} <br />
            <b>Maximum upload file size:</b> {files.fileSizeLimit}
        </p>
    ) : (
        ""
    );

    const onSuccess = () => {
        dispatch(
            getData({
                dataGridId: gridId,
                onSuccess: () => {
                    onClose && onClose();
                },
            })
        );
    };

    const onSubmit = (formData) => {
        setIsSubmitting(true);
        dispatch(
            uploadFile({
                fileData: {
                    ...formData,
                    entityTypeId: 70,
                    entityId: entityId,
                    fileTypeId: 27,
                    itemFilterId: 149,
                },
                onUploadSuccess: onSuccess,
                onUploadError: () => {
                    setIsSubmitting(false);
                },
            })
        );
    };

    const schema = {
        type: "object",
        required: ["file"],
        description: renderToString(<StatusMsg msgInfo msgText={fileTypes ? fileLimitText : ""} />),
        properties: {
            file: {
                type: "array",
                title: "Upload Files",
                items: {
                    type: "string",
                    format: "data-url",
                },
            },
        },
    };

    const uiSchema = {
        file: {
            "ui:elementType": "field",
            "ui:widget": "DropZoneWidget",
            "ui:key": "file",
        },
    };

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    return (
        <SideNavContent>
            <SideNavHeader title="File Upload" onClose={onClose} smallHeader leadBlockIcon={"upload"} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={{}}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    submitText={"Upload"}
                    withCancel
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default FileUpload;
