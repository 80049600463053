import React from "react";
import ClassNames from "classnames";

import "./style.scss";

const FieldGroup = (props) => {
    const className = ClassNames("field-group", props.className, {
        error: props.error,
        inline: props.inline,
    });

    return (
        <div ref={props.fieldGroupRef} className={className} title={props.title}>
            {props.children}
        </div>
    );
};

export default FieldGroup;
