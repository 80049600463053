import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SearchCriteriaContainer } from "./SearchCriteriaContainer";
import { SearchCriteriaItem } from "./SearchCriteriaItem";
import { clearAllSearchCriteria, clickAddSearchInput } from "../../../../store/globalSearch/actions";
import { useCanAddSearchCriteria } from "store/globalSearch/utils";
import { getSearchCriteriaList } from "../utils";

import IconWithLabel from "../../Icons/IconWithLabel";
import IconWrap from "../../Icons";

import "./style.scss";

const SearchCriteria = memo(({ instanceId }) => {
    const dispatch = useDispatch();

    const showAddIcon = useCanAddSearchCriteria({ instanceId });
    const criteriaList = useSelector((state) => state.globalSearch.criteriaList[instanceId]) ?? [];

    const onClearAll = useCallback(() => {
        dispatch(clearAllSearchCriteria({ instanceId }));
    }, [instanceId, dispatch]);

    const onAdd = useCallback(() => {
        dispatch(clickAddSearchInput({ instanceId }));
    }, [instanceId, dispatch]);

    if (criteriaList.length === 0) {
        return null;
    }

    const searchCriteriaList = getSearchCriteriaList({ criteriaList });

    const showRemoveIcon = searchCriteriaList.length > 1;

    return (
        <SearchCriteriaContainer>
            <CriteriaItemsContainer className={showRemoveIcon ? " with-remove-all-icon" : ""}>
                {searchCriteriaList.map((item, index) => (
                    <React.Fragment key={item.searchInputId}>
                        {index > 0 && <CriteriaSeparator />}
                        <SearchCriteriaItem instanceId={instanceId} item={item} />
                    </React.Fragment>
                ))}
                {showAddIcon && <CriteriaAdd onAdd={onAdd} />}
                {showRemoveIcon && (
                    <IconWithLabel icon="remove" onClick={onClearAll}>
                        Clear All
                    </IconWithLabel>
                )}
            </CriteriaItemsContainer>
        </SearchCriteriaContainer>
    );
});

const CriteriaItemsContainer = ({ children, className }) => (
    <div className={"global-search__criteria flex-row flex-wrap flex-one-in-row align-center" + className}>{children}</div>
);

const CriteriaSeparator = () => <div className="global-search__criteria-separator">{"&"}</div>;

const CriteriaAdd = ({ onAdd }) => <IconWrap className="global-search__criteria-add" icon="plus" title="Add Criteria" onClick={onAdd} />;

export default SearchCriteria;
