import * as actionTypes from "../actionTypes";
import { get, isNil } from "lodash";
import { batch } from "react-redux";

import { optimisticUpdateItem, getResource, createResource } from "../resources/actions";
import { openProjectTab, openInvoiceTab, openCustomerTab } from "../../components/utils/window";
import { createId } from "../../components/utils/string";
import { isNonEmptyString } from "../../components/utils/form";
import { getCriteriaListFromHistory } from "../../components/ui/GlobalSearch/utils";

import {
    getSearchFilter,
    GlobalSearchKey,
    entityType,
    defaultPageNumber,
    defaultPageSize,
    isCisSearch,
    getCisSearchValues,
    transformCisSearchResult,
    getCisUtilityNumber,
    DEFAULT_MAIN_CRITERIA,
    DEFAULT_SUB_CRITERIA,
    CIS_UTILITY_SUB_CRITERIA,
    getMainSearchCriteria,
    getSubSearchCriteria,
    isCisSearchCriteria,
    getCriteriaFromCisSearchConfig,
    defaultQuickSearchPageSize,
    getMainCriteriaTitle,
    getUtilityNumber,
    cisCriteriaListToSearchFilter,
    getSecondarySearchInstanceId,
    getPrimarySearchInstanceId,
    isSecondarySearchInstanceId,
} from "./utils";

const TIMEOUT_ERROR_TEXT =
    "<div>Search did not complete and has timed out. <span>Please refine by narrowing your search criteria and try again.</span></div>";
const TIMEOUT_RESPONSE_STARTS_WITH = "Execution Timeout Expired.";

export const clickMainCriteria =
    ({ instanceId, criteria }) =>
    (dispatch, getState) => {
        const state = getState();

        batch(() => {
            const selectedMainCriteria = state.globalSearch.selectedMainCriteria[instanceId] ?? [];
            const isPreviousCriteriaCis = isCisSearchCriteria({
                criteria: selectedMainCriteria,
            });
            const isSelectedCriteriaCis = isCisSearchCriteria({ criteria });

            if (isSelectedCriteriaCis) {
                dispatch({
                    type: actionTypes.SET_GLOBAL_SEARCH_UTILITY,
                    instanceId,
                });

                dispatch({
                    type: actionTypes.SET_GLOBAL_SEARCH_PROGRAM,
                    instanceId,
                });
            } else {
                // Reset utility if switching from CIS to vision search
                if (isPreviousCriteriaCis) {
                    dispatch({
                        type: actionTypes.SET_GLOBAL_SEARCH_UTILITY,
                        instanceId,
                    });
                }
            }

            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_MAIN_CRITERA,
                instanceId,
                criteria,
            });
        });
    };

export const clickSubCriteria =
    ({ instanceId, criteria }) =>
    (dispatch, getState) => {
        const state = getState();

        batch(() => {
            let subCriteriaList = (state.globalSearch.selectedSubCriteria[instanceId] ?? []).slice();

            if (subCriteriaList.find((i) => i.key === criteria.key)) {
                if (criteria.key === CIS_UTILITY_SUB_CRITERIA.key) {
                    // Remove all criteria values
                    subCriteriaList.forEach((i) => {
                        removeCriteriaValues({
                            instanceId,
                            criteria: i,
                            dispatch,
                        });
                    });

                    // Remove all but utility criteria
                    subCriteriaList = [criteria];
                } else {
                    subCriteriaList = subCriteriaList.filter((i) => i.key !== criteria.key);
                    removeCriteriaValues({ instanceId, criteria, dispatch });

                    if (!subCriteriaList.length > 0) {
                        subCriteriaList = [DEFAULT_SUB_CRITERIA];
                    }
                }
            } else {
                if (criteria.key === DEFAULT_SUB_CRITERIA.key) {
                    subCriteriaList = [DEFAULT_SUB_CRITERIA];
                } else if (criteria.key === CIS_UTILITY_SUB_CRITERIA.key) {
                    subCriteriaList = [criteria];
                } else {
                    if (criteria.isGroup) {
                        // Remove all criteria values except utility
                        subCriteriaList
                            .filter((i) => i.key !== CIS_UTILITY_SUB_CRITERIA.key)
                            .forEach((i) => {
                                removeCriteriaValues({
                                    instanceId,
                                    criteria: i,
                                    dispatch,
                                });
                            });

                        subCriteriaList = [CIS_UTILITY_SUB_CRITERIA, criteria];
                    } else {
                        // Add related criteria
                        if (criteria.relatedFields?.length > 0) {
                            const utilityNumber = state.globalSearch.criteriaValues[instanceId]?.["cis-utility"];
                            const cisSearchConfig = state.resources.cisSearchCriteria.itemsById[utilityNumber];

                            const relatedCriteria = getCriteriaFromCisSearchConfig(cisSearchConfig)
                                .filter((i) => criteria.relatedFields.map((r) => r.key).includes(i.key))
                                .filter((i) => !subCriteriaList.some((item) => item.key === i.key));

                            subCriteriaList = subCriteriaList.concat(relatedCriteria);
                        }

                        subCriteriaList = subCriteriaList.concat([criteria]).filter((i) => i.key !== DEFAULT_SUB_CRITERIA.key);
                    }
                }
            }

            const mainCriteria = state.globalSearch.selectedMainCriteria[instanceId] ?? {};
            if (isCisSearchCriteria({ criteria: mainCriteria })) {
                // If no Mandatory criteria selected remove optional criteria
                if (!subCriteriaList.some((i) => i.isMandatory)) {
                    const optionalCriteria = subCriteriaList.filter((i) => !i.isMandatory && i.key !== CIS_UTILITY_SUB_CRITERIA.key);

                    // Remove optional criteria values
                    optionalCriteria.forEach((criteria) => {
                        dispatch(
                            setCriteriaValue({
                                instanceId,
                                criteriaKey: criteria.key,
                                value: undefined,
                            })
                        );
                    });

                    // Remove optional criteria
                    subCriteriaList = subCriteriaList.filter((i) => i.isMandatory || i.key === CIS_UTILITY_SUB_CRITERIA.key);
                }
            }

            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_SUB_CRITERA,
                instanceId,
                criteria: subCriteriaList,
            });
        });
    };

export const setCriteriaValue =
    ({ instanceId, criteriaKey, value, valueTitle }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_CRITERIA_VALUE,
            instanceId,
            criteriaKey,
            value,
            valueTitle,
        });
    };

export const setUtility =
    ({ instanceId, utilityNumber, utilityName, utilityKey }) =>
    (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_UTILITY,
                instanceId,
                utilityNumber,
                utilityName,
                utilityKey,
            });

            if (utilityKey) {
                dispatch({
                    type: actionTypes.SET_GLOBAL_SEARCH_CRITERIA_VALUE,
                    instanceId,
                    criteriaKey: utilityKey,
                    value: utilityNumber,
                    valueTitle: utilityName,
                });
            }

            // Clear selected program
            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_PROGRAM,
                instanceId,
            });
        });
    };

export const setProgram =
    ({ instanceId, programNumber, programName, programKey }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_PROGRAM,
            instanceId,
            programNumber,
            programName,
            programKey,
        });
    };

export const clickSearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const state = getState();
        const searchInputId = createId();

        const mainCriteria = state.globalSearch.selectedMainCriteria[instanceId] ?? {};
        const subCriteriaList = state.globalSearch.selectedSubCriteria[instanceId] ?? [];
        const criteriaValues = state.globalSearch.criteriaValues[instanceId] ?? {};

        const selectedUtility = state.globalSearch.selectedUtility[instanceId];
        const selectedProgram = state.globalSearch.selectedProgram[instanceId];

        let criteriaList = (state.globalSearch.criteriaList[instanceId] ?? []).slice();

        const table = mainCriteria.key === DEFAULT_MAIN_CRITERIA.key ? null : mainCriteria.key;

        const programName = selectedProgram?.programName;
        const utilityName = selectedUtility?.utilityName;

        const mainCriteriaTitle = getMainCriteriaTitle({
            utilityName,
            programName,
            mainCriteriaTitle: mainCriteria.title,
        });

        const mainCriteriaValue = programName
            ? {
                  ...selectedProgram,
                  ...selectedUtility,
              }
            : selectedUtility;

        if (subCriteriaList.every((item) => item.key === DEFAULT_SUB_CRITERIA.key)) {
            if (subCriteriaList.length > 0) {
                criteriaList.push({
                    table,
                    field: null,
                    value: criteriaValues[subCriteriaList[0].key],
                    valueTitle: criteriaValues[`${subCriteriaList[0].key}-title`],
                    mainCriteriaTitle,
                    mainCriteriaValue,
                    subCriteriaTitle: null,
                    searchInputId,
                });
            }
        } else {
            const createField = (field) =>
                criteriaValues[field.key]
                    ? {
                          table,
                          field: field.key === DEFAULT_SUB_CRITERIA.key ? null : field.key,
                          value: criteriaValues[field.key],
                          valueTitle: criteriaValues[`${field.key}-title`],
                          mainCriteriaTitle,
                          mainCriteriaValue,
                          subCriteriaTitle: field.title,
                          searchInputId,
                          groupKey: field.groupKey,
                      }
                    : null;

            if (subCriteriaList.some((field) => field.isGroup)) {
                criteriaList = criteriaList
                    .concat(
                        subCriteriaList
                            .filter((field) => !field.isGroup)
                            .map(createField)
                            .filter((field) => field)
                    )
                    .concat(
                        subCriteriaList
                            .filter((field) => field.isGroup)
                            .flatMap((field) =>
                                field.relatedFields.map((f) => ({
                                    ...f,
                                    groupKey: field.key,
                                }))
                            )
                            .map(createField)
                            .filter((field) => field)
                    );
            } else {
                criteriaList = criteriaList.concat(
                    subCriteriaList
                        .filter(
                            (field) =>
                                !isNil(criteriaValues[field.key]) &&
                                isNonEmptyString(criteriaValues[field.key]) &&
                                field.key !== "cis-utility"
                        )
                        .map(createField)
                        .filter((field) => field)
                );
            }
        }

        batch(() => {
            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                instanceId,
                isExpanded: false,
            });

            dispatch(setGlobalSearchCriteriaList({ instanceId, criteriaList }));
            dispatch(doSearch({ instanceId }));
        });
    };

export const clickSearchHistory =
    ({ instanceId, criteria }) =>
    (dispatch, getState) => {
        if (isNil(criteria?.SEARCH)) {
            return;
        }

        const state = getState();

        const searchOptions = state.resources.searchOptions.itemsById["searchOptions"];
        const utilitiesList = state.resources.utilities.itemsById.utilities ?? [];

        const criteriaList = getCriteriaListFromHistory({
            criteria,
            searchOptions,
            utilitiesList,
        });

        batch(() => {
            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                instanceId,
                isExpanded: false,
            });

            dispatch(setGlobalSearchCriteriaList({ instanceId, criteriaList }));
            dispatch(doSearch({ instanceId }));
        });
    };

export const editSearchInput =
    ({ instanceId, searchInputId }) =>
    (dispatch, getState) => {
        batch(() => {
            const state = getState();
            const criteriaList = (state.globalSearch.criteriaList[instanceId] ?? []).filter((i) => i.searchInputId === searchInputId);

            dispatch(
                removeSearchInput({
                    instanceId,
                    searchInputId,
                    withSearch: false,
                })
            );

            const searchOptions = state.resources.searchOptions.itemsById["searchOptions"];
            const mainCriteriaList = getMainSearchCriteria({ searchOptions });

            const mainCriteria = mainCriteriaList.find((i) => i.key === criteriaList[0]?.table) ?? DEFAULT_MAIN_CRITERIA;
            const mainCriteriaValue = criteriaList[0]?.mainCriteriaValue || {};

            dispatch(clickMainCriteria({ instanceId, criteria: mainCriteria }));
            dispatch(clickSubCriteria({ instanceId, criteria: DEFAULT_SUB_CRITERIA }));

            const { utilityNumber, programNumber } = mainCriteriaValue ?? {};

            if (utilityNumber) {
                dispatch(setUtility({ instanceId, ...mainCriteriaValue }));
            }

            if (programNumber) {
                dispatch(setProgram({ instanceId, ...mainCriteriaValue }));
            }

            if (isCisSearchCriteria({ criteria: mainCriteria })) {
                const cisSearchConfig = state.resources.cisSearchCriteria.itemsById[utilityNumber];
                const cisCriteriaList = getCriteriaFromCisSearchConfig(cisSearchConfig);
                const selectedGroups = [];

                criteriaList.forEach((item) => {
                    // If item from group
                    if (item.groupKey) {
                        const group = cisCriteriaList.find((i) => i.key === item.groupKey);

                        // Select group if not selected
                        if (group && !selectedGroups.includes(item.groupKey)) {
                            dispatch(
                                clickSubCriteria({
                                    instanceId,
                                    criteria: group,
                                })
                            );
                            selectedGroups.push(item.groupKey);
                        }

                        dispatch(
                            setCriteriaValue({
                                instanceId,
                                criteriaKey: item.field,
                                value: item.value,
                            })
                        );
                    } else {
                        const criteria = cisCriteriaList.find((i) => i.key === item.field);

                        if (criteria) {
                            dispatch(clickSubCriteria({ instanceId, criteria }));
                            dispatch(
                                setCriteriaValue({
                                    instanceId,
                                    criteriaKey: item.field,
                                    value: item.value,
                                })
                            );
                        }
                    }
                });
            } else {
                const subCriteriaList = getSubSearchCriteria({
                    mainCriteria,
                    searchOptions,
                });
                criteriaList.forEach((item) => {
                    const criteria = item.field === null ? DEFAULT_SUB_CRITERIA : subCriteriaList.find((i) => i.key === item.field);

                    if (criteria) {
                        dispatch(clickSubCriteria({ instanceId, criteria }));
                        dispatch(
                            setCriteriaValue({
                                instanceId,
                                criteriaKey: criteria.key,
                                value: item.value,
                            })
                        );
                    }
                });
            }

            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                instanceId,
                isExpanded: true,
            });
        });
    };

export const clickAddSearchInput =
    ({ instanceId }) =>
    (dispatch, getState) => {
        batch(() => {
            const state = getState();
            const criteria = state.globalSearch.criteriaList[instanceId] ?? [];
            const lastSearchInputId = criteria[criteria.length - 1]?.searchInputId;

            if (lastSearchInputId) {
                const criteriaList = criteria.filter((i) => i.searchInputId === lastSearchInputId);

                const searchOptions = state.resources.searchOptions.itemsById["searchOptions"];
                const mainCriteriaList = getMainSearchCriteria({
                    searchOptions,
                });

                const mainCriteria = mainCriteriaList.find((i) => i.key === criteriaList[0]?.table) ?? DEFAULT_MAIN_CRITERIA;
                const mainCriteriaValue = criteriaList[0]?.mainCriteriaValue;

                // Select main criteria
                if (mainCriteria) {
                    dispatch(
                        clickMainCriteria({
                            instanceId,
                            criteria: mainCriteria,
                        })
                    );
                    dispatch(
                        clickSubCriteria({
                            instanceId,
                            criteria: DEFAULT_SUB_CRITERIA,
                        })
                    );

                    if (mainCriteriaValue?.utilityNumber) {
                        dispatch(setUtility({ instanceId, ...mainCriteriaValue }));
                    }

                    if (mainCriteriaValue?.programNumber) {
                        dispatch(setProgram({ instanceId, ...mainCriteriaValue }));
                    }
                }

                // Select Utility if main criteria is CIS
                if (isCisSearchCriteria({ criteria: mainCriteria })) {
                    criteriaList.forEach((item) => {
                        if (item.field === CIS_UTILITY_SUB_CRITERIA.key) {
                            dispatch(
                                clickSubCriteria({
                                    instanceId,
                                    criteria: CIS_UTILITY_SUB_CRITERIA,
                                })
                            );

                            dispatch(
                                setUtility({
                                    instanceId,
                                    utilityNumber: item.value,
                                    utilityName: item.valueTitle,
                                    utilityKey: item.field,
                                })
                            );
                        }
                    });
                }
            }

            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                instanceId,
                isExpanded: true,
            });
        });
    };

export const removeSearchInput =
    ({ instanceId, searchInputId, withSearch = true }) =>
    (dispatch, getState) => {
        const state = getState();
        const criteriaList = (state.globalSearch.criteriaList[instanceId] ?? []).filter((i) => i.searchInputId !== searchInputId);

        batch(() => {
            dispatch(setGlobalSearchCriteriaList({ instanceId, criteriaList }));
            withSearch && dispatch(doSearch({ instanceId }));

            if (criteriaList.length === 0) {
                dispatch({
                    type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                    instanceId,
                    isExpanded: true,
                });
            }
        });
    };

export const clearAllSearchCriteria =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const criteriaList = [];

        batch(() => {
            dispatch(setGlobalSearchCriteriaList({ instanceId, criteriaList }));
            dispatch(doSearch({ instanceId }));
            dispatch({
                type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
                instanceId,
                isExpanded: true,
            });
        });
    };

export const setGlobalSearchCriteriaList =
    ({ instanceId, criteriaList }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_CRITERIA_LIST,
            instanceId,
            criteriaList,
        });
    };

export const setGlobalSearchPageNumber =
    ({ instanceId, pageNumber }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_PAGE_NUMBER,
            instanceId,
            pageNumber,
        });
    };

export const setGlobalSearchPageSize =
    ({ instanceId, pageSize }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_PAGE_SIZE,
            instanceId,
            pageSize,
        });
    };

export const paginate =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const primaryInstanceId = getPrimarySearchInstanceId(instanceId);
        const state = getState();
        const criteriaList = get(state, `globalSearch.criteriaList[${primaryInstanceId}]`, []);

        if (isCisSearch({ criteriaList })) {
            if (isSecondarySearchInstanceId(instanceId)) {
                dispatch(
                    doFullCisSecondarySearch({
                        instanceId: primaryInstanceId,
                        clearPreviousResults: false,
                    })
                );
            } else {
                dispatch(doFullCisSearch({ instanceId, clearPreviousResults: false }));
            }
        } else {
            const pageNumber = get(state, `globalSearch.pageNumber[${instanceId}]`, defaultPageNumber);
            const pageSize = get(state, `globalSearch.pageSize[${instanceId}]`, defaultPageSize);
            const searchFilter = getSearchFilter({
                criteriaList,
                pageNumber,
                pageSize,
            });

            performSearch({
                instanceId,
                searchFilter,
                dispatch,
                clearPreviousResults: false,
            });
        }
    };

export const setGlobalSearchIsSearching =
    ({ instanceId, isSearching }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_IS_SEARCHING,
            instanceId,
            isSearching,
        });
    };

export const setGlobalSearchError =
    ({ instanceId, errorMessage }) =>
    (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_ERROR,
            instanceId,
            errorMessage,
        });
    };

export const doSearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const isQuickSearch = instanceId === GlobalSearchKey;

        dispatch(resetSearchPage({ instanceId }));

        if (isQuickSearch) {
            dispatch(doQuickSearch({ instanceId }));
        } else {
            dispatch(doFullSearch({ instanceId }));
        }
    };

export const doQuickSearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const state = getState();
        const criteriaList = state.globalSearch.criteriaList[instanceId] ?? [];
        const pageNumber = 0;
        const pageSize = 0;

        if (isCisSearch({ criteriaList })) {
            dispatch(doQuickCisSearch({ instanceId }));
            dispatch(doQuickCisSecondarySearch({ instanceId }));
        } else {
            const searchFilter = getSearchFilter({
                criteriaList,
                pageNumber,
                pageSize,
            });

            performSearch({
                instanceId,
                searchFilter,
                dispatch,
            });
        }
    };

export const doQuickCisSearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        batch(() => {
            const state = getState();
            const criteriaList = state.globalSearch.criteriaList[instanceId] ?? [];
            const utilityNumber = getCisUtilityNumber({ criteriaList });

            if (utilityNumber) {
                const pageNumber = defaultPageNumber;
                const pageSize = defaultQuickSearchPageSize;

                performCisSearch({
                    instanceId,
                    utilityNumber,
                    pageNumber,
                    pageSize,
                    criteriaList,
                    dispatch,
                });
            }
        });
    };

export const doQuickCisSecondarySearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        batch(() => {
            const state = getState();
            const criteriaList = state.globalSearch.criteriaList[instanceId] ?? [];
            const searchOptions = state.resources.searchOptions.itemsById["searchOptions"];
            const secondarySearchInstanceId = getSecondarySearchInstanceId(instanceId);
            const pageNumber = 0;
            const pageSize = 0;

            const searchFilter = cisCriteriaListToSearchFilter({
                criteriaList,
                searchOptions,
                pageNumber,
                pageSize,
            });

            performSearch({
                instanceId: secondarySearchInstanceId,
                searchFilter,
                dispatch,
            });
        });
    };

export const doFullSearch =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const state = getState();
        const criteriaList = get(state, `globalSearch.criteriaList[${instanceId}]`, []);

        if (isCisSearch({ criteriaList })) {
            dispatch(doFullCisSearch({ instanceId }));
            dispatch(doFullCisSecondarySearch({ instanceId }));
        } else {
            const pageNumber = get(state, `globalSearch.pageNumber[${instanceId}]`, defaultPageNumber);
            const pageSize = get(state, `globalSearch.pageSize[${instanceId}]`, defaultPageSize);
            const searchFilter = getSearchFilter({
                criteriaList,
                pageNumber,
                pageSize,
            });

            performSearch({
                instanceId,
                searchFilter,
                dispatch,
            });
        }
    };

export const doFullCisSearch =
    ({ instanceId, clearPreviousResults }) =>
    (dispatch, getState) => {
        batch(() => {
            const state = getState();
            const criteriaList = state.globalSearch.criteriaList[instanceId] ?? [];
            const utilityNumber = getCisUtilityNumber({ criteriaList });

            if (utilityNumber) {
                const pageNumber = state.globalSearch.pageNumber[instanceId] ?? defaultPageNumber;
                const pageSize = state.globalSearch.pageSize[instanceId] ?? defaultPageSize;

                performCisSearch({
                    instanceId,
                    utilityNumber,
                    pageNumber,
                    pageSize,
                    criteriaList,
                    clearPreviousResults,
                    dispatch,
                });
            }
        });
    };

export const doFullCisSecondarySearch =
    ({ instanceId, clearPreviousResults }) =>
    (dispatch, getState) => {
        batch(() => {
            const secondarySearchInstanceId = getSecondarySearchInstanceId(instanceId);
            const state = getState();
            const criteriaList = state.globalSearch.criteriaList[instanceId] ?? [];
            const searchOptions = state.resources.searchOptions.itemsById["searchOptions"];
            const pageNumber = get(state, `globalSearch.pageNumber[${secondarySearchInstanceId}]`, defaultPageNumber);
            const pageSize = get(state, `globalSearch.pageSize[${secondarySearchInstanceId}]`, defaultPageSize);

            const searchFilter = cisCriteriaListToSearchFilter({
                criteriaList,
                searchOptions,
                pageNumber,
                pageSize,
            });

            performSearch({
                instanceId: secondarySearchInstanceId,
                searchFilter,
                clearPreviousResults,
                dispatch,
            });
        });
    };

export const clearSearchResults =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const state = getState();
        const searchResult = state.resources?.search.itemsById[instanceId];

        if (!isNil(searchResult)) {
            dispatch(
                optimisticUpdateItem({
                    resourceName: "search",
                    resourceId: instanceId,
                    value: null,
                })
            );
        }
    };

export const resetSearchPage =
    ({ instanceId }) =>
    (dispatch, getState) => {
        const state = getState();
        const pageNumber = state.globalSearch.pageNumber[instanceId];

        if (!isNil(pageNumber) && pageNumber !== defaultPageNumber) {
            dispatch(
                setGlobalSearchPageNumber({
                    instanceId,
                    pageNumber: defaultPageNumber,
                })
            );
        }
    };

export const openSearchResult =
    ({ result }) =>
    (dispatch, getState) => {
        switch (result.ENTITY_TYPE) {
            case entityType.application:
                openProjectTab({
                    applicationNumber: result.ENTITYID,
                });
                break;

            case entityType.invoice:
                openInvoiceTab({ documentNumber: result.ENTITYID });
                break;

            case entityType.customer:
                openCustomerTab({
                    utilityNumber: getUtilityNumber({ searchResult: result }),
                    customerNumber: result.ENTITYID,
                });
                break;

            default:
                break;
        }
    };

export const initGrobalSearchTab =
    ({ instanceId }) =>
    (dispatch, getState) => {
        dispatch(doSearch({ instanceId }));
    };

const removeCriteriaValues = ({ instanceId, criteria, dispatch }) => {
    if (criteria.isGroup) {
        // Remove all criteria group values
        (criteria.relatedFields || []).forEach((i) => {
            dispatch(
                setCriteriaValue({
                    instanceId,
                    criteriaKey: i.key,
                    value: undefined,
                })
            );
        });
    } else {
        // Remove criteria value
        dispatch(
            setCriteriaValue({
                instanceId,
                criteriaKey: criteria.key,
                value: undefined,
            })
        );
    }
};

const performSearch = ({ instanceId, searchFilter, clearPreviousResults = true, dispatch }) => {
    if (clearPreviousResults) {
        dispatch(clearSearchResults({ instanceId }));
    }

    if (searchFilter) {
        dispatch(
            setGlobalSearchIsSearching({
                instanceId,
                isSearching: true,
            })
        );

        dispatch(
            setGlobalSearchError({
                instanceId,
                errorMessage: null,
            })
        );

        dispatch({
            type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
            instanceId,
            isExpanded: false,
        });

        dispatch(
            getResource({
                resourceName: "search",
                key: instanceId,
                query: {
                    searchFilter,
                },
                showErrorNotification: false,
                onError: (action) => {
                    dispatch(
                        setGlobalSearchError({
                            instanceId,
                            errorMessage: action.message.startsWith(TIMEOUT_RESPONSE_STARTS_WITH) ? TIMEOUT_ERROR_TEXT : action.message,
                        })
                    );
                },
                onComplete: () => {
                    dispatch(
                        setGlobalSearchIsSearching({
                            instanceId,
                            isSearching: false,
                        })
                    );

                    dispatch(
                        getResource({
                            resourceName: "searchHistory",
                            key: "searchHistory",
                            forced: true,
                        })
                    );
                },
            })
        );
    }
};

const performCisSearch = ({ instanceId, utilityNumber, pageNumber, pageSize, criteriaList, clearPreviousResults = true, dispatch }) => {
    const searchList = getCisSearchValues({ criteriaList });

    if (clearPreviousResults) {
        dispatch(clearSearchResults({ instanceId }));
    }

    dispatch(
        setGlobalSearchIsSearching({
            instanceId,
            isSearching: true,
        })
    );

    dispatch(
        setGlobalSearchError({
            instanceId,
            errorMessage: null,
        })
    );

    dispatch({
        type: actionTypes.SET_GLOBAL_SEARCH_AREA_EXPANDED,
        instanceId,
        isExpanded: false,
    });

    dispatch(
        createResource({
            resourceName: "cisCustomers",
            key: utilityNumber,
            body: {
                utilityNumber,
                page: pageNumber,
                rows: pageSize,
                mode: "",
                searchList,
            },
            showErrorNotification: false,
            onError: (action) => {
                dispatch(
                    setGlobalSearchError({
                        instanceId,
                        errorMessage: action.message.startsWith(TIMEOUT_RESPONSE_STARTS_WITH) ? TIMEOUT_ERROR_TEXT : action.message,
                    })
                );
            },
            onSuccess: (action) => {
                const searchResult = transformCisSearchResult(action);

                dispatch(
                    optimisticUpdateItem({
                        resourceName: "search",
                        resourceId: instanceId,
                        value: searchResult,
                    })
                );
            },
            onComplete: () => {
                dispatch(
                    setGlobalSearchIsSearching({
                        instanceId,
                        isSearching: false,
                    })
                );

                dispatch(
                    getResource({
                        resourceName: "searchHistory",
                        key: "searchHistory",
                        forced: true,
                    })
                );
            },
        })
    );
};
