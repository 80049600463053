import React, { useCallback, useState, useEffect, useMemo, memo } from "react";
import { renderToString } from "react-dom/server";
import { isNil, isEmpty } from "lodash";

import { onCisLookup } from "../../../ui/CIS/actions";
import { parseCisDetails } from "../../../ui/CIS/utils";
import { isEmailAddress, isNameOrCompanyErrorText, isNameOrCompany, isEmailAddressErrorText } from "../../../utils/form";
import { getContactFromCisContact } from "../utils";

import WaitIcon from "../../../ui/WaitIcon";
import Button from "../../../ui/Button";
import TextInput from "../../../ui/Input/TextInput";
import StatusMsg from "../../../ui/StatusMsg";
import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";

const AccountNumberWidget = memo(({ value, required, canSearchCIS, onChange, onCisLookup }) => {
    return (
        <div className="account-number-widget">
            <TextInput value={isEmpty(value) ? "" : value} required={required} onChange={(event) => onChange(event.target.value)} />
            <Button onClick={onCisLookup} disabled={isEmpty(value) || !canSearchCIS}>
                CIS
            </Button>
        </div>
    );
});

const Contact = memo(({ canSearchCIS, formKey, formRef, isLoading, utilityNumber, contact, onCancel }) => {
    const [formData, setFormData] = useState(contact || {});

    const showCancel = !isNil(onCancel);

    useEffect(() => {
        if (contact) {
            setFormData(contact);
        }
    }, [contact]);

    const handleChange = useCallback(({ formData }) => {
        setFormData(formData);
    }, []);

    const handleCisSelect = useCallback(
        (data) => {
            const formData = formRef?.current?.state?.formData;

            if (formData) {
                const cis = parseCisDetails(data);
                const contact = getContactFromCisContact({ cisContact: cis, isMailingAddress: formKey === "primary" });

                setFormData({
                    ...formData,
                    acct_number: contact.acct_number || formData.acct_number,
                    address: contact.address || formData.address,
                    city: contact.city || formData.city,
                    state: contact.state || formData.state,
                    zip: contact.zip || formData.zip,
                    phone: contact.phone || formData.phone,
                    company: contact.company || formData.company,
                    firstname: contact.firstname || formData.firstname,
                    lastname: contact.lastname || formData.lastname,
                    meterid: contact.meterid || formData.meterid,
                    premiseid: contact.premiseid || formData.premiseid,
                    email: formData.email || contact.email,
                });
            }
        },
        [formRef, formKey]
    );

    const handleCancel = useCallback(() => {
        setFormData({});

        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const handleCisLookup = useCallback(() => {
        onCisLookup({
            utilityNumber,
            contact: formRef?.current?.state?.formData || {},
            onSelect: handleCisSelect,
        });
    }, [utilityNumber, formRef, handleCisSelect]);

    const schema = useMemo(
        () => ({
            type: "object",
            required: ["address", "city", "state", "zip"],
            description: renderToString(<StatusMsg msgInfo msgText={isNameOrCompanyErrorText} />),
            properties: {
                contacttitle: {
                    type: ["string", "null"],
                    title: "Name This Contact",
                },
                firstname: {
                    type: ["string", "null"],
                    title: "Contact First Name **",
                },
                lastname: {
                    type: ["string", "null"],
                    title: "Contact Last Name **",
                },
                company: {
                    type: ["string", "null"],
                    title: "Company Name **",
                },
                acct_number: {
                    type: ["string", "null"],
                    title: "Account Number",
                },
                premiseid: {
                    type: ["string", "null"],
                    title: "Premise ID",
                },
                meterid: {
                    type: ["string", "null"],
                    title: "Meter ID",
                },
                address: {
                    type: ["string", "null"],
                    title: "Address",
                },
                address_cont: {
                    type: ["string", "null"],
                    title: "Address (continued)",
                },
                city: {
                    type: ["string", "null"],
                    title: "City",
                },
                state: {
                    type: ["string", "null"],
                    title: "State/Province",
                },
                zip: {
                    type: ["string", "null"],
                    title: "Postal Code",
                },
                phone: {
                    type: ["string", "null"],
                    title: "Phone",
                },
                cell: {
                    type: ["string", "null"],
                    title: "Cell",
                },
                fax: {
                    type: ["string", "null"],
                    title: "Fax",
                },
                email: {
                    type: ["string", "null"],
                    title: "Email",
                },
                taxid: {
                    type: ["string", "null"],
                    title: "Tax ID",
                },
                addToProfileContacts: {
                    type: "boolean",
                    title: "Save this contact for later use in your profile contact list?",
                },
            },
        }),
        []
    );

    const uiSchema = useMemo(
        () => ({
            "ui:rootFieldId": formKey,
            classNames: "inline-form columns-4",
            acct_number: {
                "ui:widget": (props) => <AccountNumberWidget {...props} canSearchCIS={canSearchCIS} onCisLookup={handleCisLookup} />,
            },
            state: {
                "ui:widget": "state",
            },
        }),
        [formKey, canSearchCIS, handleCisLookup]
    );

    const handleValidate = useCallback((formData, errors) => {
        if (formData.email && !isEmailAddress(formData.email)) {
            errors.email.addError(isEmailAddressErrorText);
        }

        if (
            !isNameOrCompany({
                firstName: formData.firstname,
                lastName: formData.lastname,
                company: formData.company,
            })
        ) {
            errors.firstname.addError(isNameOrCompanyErrorText);
        }

        return errors;
    }, []);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className="new-app-contact-panel">
            <JsonSchemaForm
                key={formKey}
                formRef={formRef}
                initialValues={formData}
                schema={schema}
                uiSchema={uiSchema}
                onChange={handleChange}
                noReset
                withCancel
                noSubmit
                cancelText={showCancel ? "Cancel" : "Clear"}
                onCancel={handleCancel}
                validate={handleValidate}
            />
        </div>
    );
});

export default Contact;
