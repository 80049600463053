import React from "react";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../PropertyList";
import { PropertyListItem } from "../Property/PropertyListItem";
import { cloneDeep } from "lodash";
import "./LeadImageParentProperty.scss";
import { AlignXPosition, AlignYPosition, HeaderTextColor } from "../../types";

export const LeadImageParentProperty = (props) => {
    const {
        id,
        title,
        propertiesGroup = [],
        value = {},
        nestingLevel,
        onChange,
        helperPropName,
        selectImageTitle,
        viewTitle,
        hideDescription,
        hideContent,
    } = props;
    const previousLeadImage = props[helperPropName];
    const leadImageOff = !value.leadImage;

    const leadImage = propertiesGroup[0];
    leadImage.selectImageTitle = selectImageTitle;
    leadImage.viewTitle = viewTitle;
    leadImage.hideDescription = hideDescription;
    leadImage.hideContent = hideContent;

    const defaultLeadImage = {
        file: null,
        alignX: AlignXPosition.Left,
        alignY: AlignYPosition.Center,
        imageSize: "auto",
        contentTextColor: HeaderTextColor.Light,
        contentType: "titleText",
        backgroundOff: true,
        backgroundColor: "black",
        backgroundColorOpacity: "40",
        leadImageContentOff: true,
        contentTitle: "Lorem ipsum dolor sit amet",
        contentText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    };

    const turnOff = () => {
        if (!leadImageOff) {
            onChange(id, null, [{ id: helperPropName, value: cloneDeep(value.leadImage) ?? {} }]);
        } else {
            onChange(id, previousLeadImage ?? defaultLeadImage, [{ id: helperPropName, value: null }]);
        }
    };

    return (
        <PropertyListItem className="property-list-item--prop-group lead-image-parent-property">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={leadImageOff ? "Switch ON" : "Switch OFF"}
                onToggle={turnOff}
                toggleValue={leadImageOff}
            />
            <PropertyList
                className="lead-image-config-button"
                items={propertiesGroup}
                nestingLevel={nestingLevel + 1}
                config={value}
                onChange={onChange}
                isExpanded={!leadImageOff}
            />
        </PropertyListItem>
    );
};
