import React from "react";
import cn from "classnames";
import WaitIcon from "components/ui/WaitIcon";
import { formatMoney } from "components/utils/money";
import useAppEquipmentListForWidget, { Equipment } from "store/resources/useAppEquipmentListForWidget";

import "./style.scss";

export const RebateTotalGridWidget = (props: RebateTotalGridWidgetProps) => {
    const { applicationNumber } = props.formContext ?? {};

    const [equipmentList = [], isLoading] = useAppEquipmentListForWidget(applicationNumber);
    const equipmentListToUse = props.equipmentList ?? equipmentList;

    if (isLoading) {
        <WaitIcon />;
    }

    const sum = equipmentListToUse.reduce((acc, equipment) => acc + equipment.incentive * equipment.quantity, 0);

    return (
        <table className={cn(props.className, "rebate-total-grid-widget")}>
            <thead>
                <tr>
                    <th>Equipment Name</th>
                    <th className="text-end">Estimated Total Incentive</th>
                </tr>
            </thead>
            <tbody>
                {equipmentListToUse.map((equipment) => (
                    <tr key={equipment.equipid}>
                        <td>{equipment.name}</td>
                        <td className="text-end">{formatMoney(equipment.incentive * equipment.quantity)}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th scope="row">Estimated Total Project Incentive</th>
                    <th className="text-end">{formatMoney(sum)}</th>
                </tr>
            </tfoot>
        </table>
    );
};

interface RebateTotalGridWidgetProps {
    className?: string;
    formContext?: {
        applicationNumber: string;
    };
    equipmentList?: Equipment[];
}
