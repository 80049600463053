import React, { useCallback, useState, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";

import { setExpandedWorkflowTask } from "../../../store/projects/actions";
import { changeTaskOwnership, refreshWorkflow, useWorkflowModel } from "./utils";
import { updateResource } from "../../../store/resources/actions";
import useUnmounted from "components/utils/useUnmounted";

import { TaskWorkflowContext } from "./context/TaskWorkflowContext";
import { WorkflowFilter } from "./components/WorkflowFilter";
import { WorkflowStatuses } from "./components/WorkflowStatuses";
import ContentPanel from "../Dashboard/Panel/ContentPanel";
import WaitIcon from "../WaitIcon";

import "./style.scss";

const TaskWorkflow = memo(
    ({
        entityId,
        entityTypeId,
        utilityNumber,
        programNumber,
        workflowGetResourceOptions,
        workflowHistoryGetResourceOptions,
        workflowTaskOwnershipUpdateResourceOptions,
        workflowTaskUpdateResourceOptions,
        workflowTaskDetailsResourceOptions,
        isLocked,
        allowUnlock,
        activeStatusName,
        AdHocTaskForm,
        onExpandPanel,
        onChangeTask,
        noDocumentSecurity,
        isExpandTaskDisabled,
        hasAddTaskRights,
    }) => {
        const dispatch = useDispatch();
        const unmounted = useUnmounted();
        const [workflowFilter, setWorkflowFilter] = useState({});

        const { workflowTasks, workflowHistory, isLoading, activeStatus } = useWorkflowModel({
            workflowGetResourceOptions,
        });

        const handleChangeTaskOwnership = useCallback(
            (step, onComplete) => {
                changeTaskOwnership({
                    step,
                    resourceParams: workflowTaskOwnershipUpdateResourceOptions,
                    onSuccess: () => {
                        if (!unmounted.current) {
                            refreshWorkflow({
                                resourceParams: workflowGetResourceOptions,
                            });
                        }
                    },
                    onComplete,
                });
            },
            [workflowTaskOwnershipUpdateResourceOptions, workflowGetResourceOptions, unmounted]
        );

        const handleChangeTask = useCallback(
            (taskNumber, isCompleted) => {
                dispatch(setExpandedWorkflowTask(entityId, undefined));

                dispatch(
                    updateResource({
                        ...workflowTaskUpdateResourceOptions,
                        resourceId: taskNumber,
                        query: {
                            ...workflowTaskUpdateResourceOptions.query,
                            itemType: isCompleted ? "RESTASK" : "COMPLETETASK",
                        },
                        onSuccess: () => {
                            if (!unmounted.current) {
                                onChangeTask && onChangeTask();
                            }
                        },
                    })
                );
            },
            [entityId, workflowTaskUpdateResourceOptions, onChangeTask, dispatch, unmounted]
        );

        const handleWorkflowFilterChange = useCallback((value) => {
            setWorkflowFilter(value);
        }, []);

        const context = useMemo(
            () => ({
                entityId,
                entityTypeId,
                utilityNumber,
                programNumber,
                isLocked,
                isAddAdHocTaskDisabled: !allowUnlock && (isLocked || !hasAddTaskRights),
                workflowGetResourceOptions,
                workflowTaskDetailsResourceOptions,
                workflowHistoryGetResourceOptions,
                workflowTasks,
                workflowHistory,
                onChangeTask: handleChangeTask,
                onChangeTaskOwnership: handleChangeTaskOwnership,
                onExpandPanel,
                noDocumentSecurity,
                activeStatus: isNil(activeStatus) ? { state: activeStatusName } : activeStatus,
                hasAddTaskRights,
            }),
            [
                entityId,
                entityTypeId,
                utilityNumber,
                programNumber,
                isLocked,
                allowUnlock,
                hasAddTaskRights,
                workflowGetResourceOptions,
                workflowTaskDetailsResourceOptions,
                workflowHistoryGetResourceOptions,
                workflowTasks,
                workflowHistory,
                handleChangeTask,
                handleChangeTaskOwnership,
                onExpandPanel,
                noDocumentSecurity,
                activeStatus,
                activeStatusName,
            ]
        );

        if (isLoading) {
            return (
                <ContentPanel>
                    <WaitIcon />
                </ContentPanel>
            );
        }

        return (
            <TaskWorkflowContext.Provider value={context}>
                <div className="flex-row workflow-tasks workflow-tasks-filter-visible">
                    <WorkflowFilter onChange={handleWorkflowFilterChange} />
                    <WorkflowStatuses
                        className="flex-one-in-row"
                        entityId={entityId}
                        AdHocTaskForm={AdHocTaskForm}
                        workflowFilter={workflowFilter}
                        programNumber={programNumber}
                        isExpandTaskDisabled={isExpandTaskDisabled}
                    />
                </div>
            </TaskWorkflowContext.Provider>
        );
    }
);

export default TaskWorkflow;
