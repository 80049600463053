import React, { memo } from "react";
import { useSelector } from "react-redux";

import { useResource } from "../../../store/resources/useResource";

import FieldGroup from "../../ui/FieldGroup";
import DropDownInput from "../../ui/Input/DropDownInput";
import Radio from "../../ui/Input/Radio";

export const assignmentType = {
    any: 0,
    unassigned: 1,
    withNotes: 2,
    readyForStatusChange: 3,
    assignedTo: 4,
    createdBy: 5,
    withTags: 6,
    countsByStatus: 7,
};

const ApplicationsFilterTab = memo(({ values, onChange }) => {
    const myUserNumber = useSelector((state) => state.user.userNumber);

    const [users = [], isLoadingUsers] = useResource({
        resourceName: "workcenterUserList",
        key: "workcenter-user-list",
    });

    const isMyUserInList = users.some((u) => u.userNumber === myUserNumber);

    const assignment = values?.assignment;
    const assignmentUser = values?.assignmentUser ?? (isMyUserInList ? myUserNumber : undefined);
    const createdByUser = values?.createdByUser ?? (isMyUserInList ? myUserNumber : undefined);

    const userList = users.map((u) => {
        return {
            label: u.userName,
            value: u.userNumber,
        };
    });

    const onAssignmentChange = (event) => {
        onChange &&
            onChange({
                assignment: +event.target.value,
                assignmentUser: assignmentUser,
                createdByUser: createdByUser,
            });
    };

    const onAssignmentUserChange = (event) => {
        onChange &&
            onChange({
                assignment: assignment,
                assignmentUser: event.target.value,
                createdByUser: createdByUser,
            });
    };

    const onCreatedByUserChange = (event) => {
        onChange &&
            onChange({
                assignment: assignment,
                assignmentUser: assignmentUser,
                createdByUser: event.target.value,
            });
    };

    const assignmentRadioGroupName = "app-assignment-filter";

    return (
        <div className="list-of-radio-buttons">
            <FieldGroup>
                <Radio
                    label="Any"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.any}
                    value={assignmentType.any}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="Unassigned"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.unassigned}
                    value={assignmentType.unassigned}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup className="radio-plus-dropdown applicaition-tab">
                <Radio
                    label="Assigned to:"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.assignedTo}
                    value={assignmentType.assignedTo}
                    onChange={onAssignmentChange}
                />
                <DropDownInput
                    inline
                    disabled={assignment !== assignmentType.assignedTo}
                    placeholder={isLoadingUsers ? "Loading..." : "Select user"}
                    mobileHeader="Select user"
                    data={userList}
                    value={isLoadingUsers ? undefined : assignmentUser}
                    onChange={onAssignmentUserChange}
                    withPopper
                />
            </FieldGroup>
            <FieldGroup className="radio-plus-dropdown applicaition-tab">
                <Radio
                    label="Created by:"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.createdBy}
                    value={assignmentType.createdBy}
                    onChange={onAssignmentChange}
                />
                <DropDownInput
                    inline
                    disabled={assignment !== assignmentType.createdBy}
                    placeholder={isLoadingUsers ? "Loading..." : "Select user"}
                    mobileHeader="Select user"
                    data={userList}
                    value={isLoadingUsers ? undefined : createdByUser}
                    onChange={onCreatedByUserChange}
                    withPopper
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="With Notes"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.withNotes}
                    value={assignmentType.withNotes}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="Ready for Status Change"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.readyForStatusChange}
                    value={assignmentType.readyForStatusChange}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="With Tags"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.withTags}
                    value={assignmentType.withTags}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
            <FieldGroup>
                <Radio
                    label="Counts by Status"
                    name={assignmentRadioGroupName}
                    checked={assignment === assignmentType.countsByStatus}
                    value={assignmentType.countsByStatus}
                    onChange={onAssignmentChange}
                />
            </FieldGroup>
        </div>
    );
});

export default ApplicationsFilterTab;
