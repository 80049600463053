import React from "react";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PropertyListItem } from "../../Property/PropertyListItem";
import { CornerBox } from "../../Property/BorderRadiusProperty/CornerBox";
import { ButtonCustomPropertyName, ButtonCornerRadius } from "./types";
import { BorderCornerName } from "../../Property/BorderRadiusProperty/types";
import { isNil } from "lodash";

import "./GlobalButtonProperties.scss";

export const ButtonCornerProperty = (props) => {
    const { title, value = {}, onChange } = props;

    const onSelect = (id, radius) => {
        if (radius === value) {
            onChange(id, "0");
        } else {
            onChange(id, radius);
        }
    };

    const isExpanded = Object.values(value).some((v) => !isNil(v));

    const onToggle = () => {
        if (isExpanded) {
            onChange(ButtonCustomPropertyName.ButtonCornerRadius, undefined);
        } else {
            onChange(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius1);
        }
    };
    return (
        <PropertyListItem className={"property-list-item--button-corner"}>
            <PropertyListItemTitle
                title={title}
                toggleTooltip={!isExpanded ? "Switch ON" : "Switch OFF"}
                toggleValue={!isExpanded}
                onToggle={onToggle}
            />
            {isExpanded && (
                <div className="button-corner-boxes flex-row">
                    <CornerBox
                        side={BorderCornerName.AllSides}
                        size="size-1"
                        isSelected={value === ButtonCornerRadius.Radius1}
                        onClick={() => onSelect(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius1)}
                    />
                    <CornerBox
                        side={BorderCornerName.AllSides}
                        size="size-2"
                        isSelected={value === ButtonCornerRadius.Radius2}
                        onClick={() => onSelect(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius2)}
                    />
                    <CornerBox
                        side={BorderCornerName.AllSides}
                        size="size-3"
                        isSelected={value === ButtonCornerRadius.Radius3}
                        onClick={() => onSelect(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius3)}
                    />
                    <CornerBox
                        side={BorderCornerName.AllSides}
                        size="size-4"
                        isSelected={value === ButtonCornerRadius.Radius4}
                        onClick={() => onSelect(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius4)}
                    />
                    <CornerBox
                        side={BorderCornerName.AllSides}
                        size="size-5"
                        isSelected={value === ButtonCornerRadius.Radius5}
                        onClick={() => onSelect(ButtonCustomPropertyName.ButtonCornerRadius, ButtonCornerRadius.Radius5)}
                    />
                </div>
            )}
        </PropertyListItem>
    );
};
