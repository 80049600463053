import { createId } from "components/utils/string";
import { isNil } from "lodash";
import { PortalTemplateConfiguration, ValidationMessageImpact } from "../types";

/**
 * Communicate with the portal using postMessage.
 *
 * @export
 * @class PortalMessenger
 */
export class PortalMessenger {
    private portal: MessageEventSource | null;
    private requestId?: string;
    private resolve?: Function;
    private reject?: Function;
    private timeoutHandler?: any;
    private readonly timeoutInSeconds: number;

    constructor(portal: MessageEventSource | null, timeoutInSeconds?: number) {
        this.portal = portal;
        this.timeoutInSeconds = timeoutInSeconds ?? 10;
        this.onMessage = this.onMessage.bind(this);
    }

    post<T>(requestType: string, payload?: object) {
        // Reset the messenger.
        this.reset();

        // Create a request identifier. This is used to identify the response.
        const requestId = createId();

        // Start listening to messaging events.
        window.addEventListener("message", this.onMessage, false);

        // Post a message.
        if (this.portal) {
            this.portal.postMessage({ type: requestType, payload: { ...payload, requestId } }, POST_MESSAGE_OPTIONS);

            this.requestId = requestId;

            // Initialize message timeout.
            this.timeoutHandler = setTimeout(() => {
                // Cancel the request if still in progress
                if (this.requestId && this.reject) {
                    this.reject({
                        id: `timeout`,
                        message: `${this.timeoutInSeconds} seconds timeout.`,
                    });
                    this.reset();
                }
            }, this.timeoutInSeconds * 1000);

            return new Promise<T>((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        }

        // Reject if the portal window is not available.
        return Promise.reject();
    }

    reset() {
        this.resolve = undefined;
        this.reject = undefined;
        this.requestId = undefined;
        window.removeEventListener("message", this.onMessage, false);
        clearTimeout(this.timeoutHandler);
    }

    private onMessage(e: MessageEvent<any>) {
        const { data } = e;
        const type = data?.type;

        // Resolve the promise if the message is for our request
        if (type === this.requestId && this.resolve) {
            this.resolve(data.payload);
            this.reset();
        }
    }
}

const POST_MESSAGE_OPTIONS = {
    targetOrigin: "*",
};

// Send config to portal
export const sendConfigToPortal = (
    portalRef: React.MutableRefObject<MessageEventSource | null>,
    utilityTemplateConfiguration: PortalTemplateConfiguration,
    programTemplateConfiguration: PortalTemplateConfiguration | null,
    programNumber: string | null
) => {
    if (portalRef.current) {
        portalRef.current.postMessage(
            {
                type: "portal-set-config",
                payload: {
                    utilityTemplateConfiguration,
                    programTemplateConfiguration,
                    programNumber,
                },
            },
            POST_MESSAGE_OPTIONS
        );
    }
};

export const changePortalPage = (link: string, portalRef: React.MutableRefObject<MessageEventSource | null>, state?: any) => {
    if (portalRef.current) {
        portalRef.current.postMessage(
            {
                type: "portal-navigate",
                payload: {
                    requestId: createId(),
                    link,
                    state,
                },
            },
            POST_MESSAGE_OPTIONS
        );
    }
};

export const onPortalPreviewReady = ({
    event,
    portalRef,
    previewName,
    config,
}: {
    event: MessageEvent<any>;
    portalRef: React.MutableRefObject<MessageEventSource | null>;
    previewName?: string;
    config?: {
        utilityTemplateConfiguration: PortalTemplateConfiguration;
        programTemplateConfiguration?: PortalTemplateConfiguration;
        programNumber?: string;
    };
}) => {
    const { data, source } = event;

    // Preserve portal window object for messaging and send the config
    if (isNil(portalRef.current) && data.payload?.name === previewName) {
        portalRef.current = source;

        if (config && portalRef.current) {
            portalRef.current.postMessage(
                {
                    type: "portal-set-config",
                    payload: config,
                },
                POST_MESSAGE_OPTIONS
            );
        }
    }
};

export const highlightPageElement = (
    portalRef: React.MutableRefObject<MessageEventSource | null>,
    selector: string,
    description: string | undefined,
    impact: ValidationMessageImpact | undefined,
    url: string
) => {
    if (portalRef.current) {
        new PortalMessenger(portalRef.current, 30).post<void>("portal-highlight-element", {
            selector,
            impact,
            description,
            url,
        });
    }
};
