import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import ViewPlaceholder from "../../ui/ViewPlaceholder";
import LoginFormWrap from "../../ui/LoginFormWrap";
import { refresh } from "../../../store/login/actions";
import { USER_SAVE } from "../../../store/actionTypes";
import WaitIcon from "../../ui/WaitIcon";
import { openLoginPage, clearBrowserUrl } from "../../utils/window";

const SamlView = memo(() => {
    const dispatch = useDispatch();

    useEffect(() => {
        const params = queryString.parse(window.location.search);

        let accessToken = null;
        let refreshToken = null;

        if (params) {
            Object.keys(params).forEach((key) => {
                const formattedKey = key.toLowerCase();

                switch (formattedKey) {
                    case "accesstoken":
                        accessToken = params[key];
                        break;
                    case "refreshtoken":
                        refreshToken = params[key];
                        break;
                    default:
                        break;
                }
            });
        }

        if (accessToken && refreshToken) {
            dispatch({
                type: USER_SAVE,
                user: {
                    accessToken,
                    refreshToken,
                },
            });

            dispatch(refresh());
        } else {
            openLoginPage();
        }

        // Clear url
        clearBrowserUrl();
    }, [dispatch]);

    return (
        <LoginFormWrap>
            <ViewPlaceholder>
                <WaitIcon />
            </ViewPlaceholder>
        </LoginFormWrap>
    );
});

export default SamlView;
