import React, { memo } from "react";
import AllCatalogAttributesGrid from "./AllCatalogAttributesGrid";

const AllCatalogAttributesPanel = memo((props) => {
    const gridId = "allCatalog-attribute";

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="panel budget-attributes flex-column fill-height no-scroll">
                <AllCatalogAttributesGrid gridId={gridId} filterRequired={true} />
            </div>
        </div>
    );
});

export default AllCatalogAttributesPanel;
