import React from "react";
import cn from "classnames";

import "./TaskIndicator.scss";

export const TaskIndicator = ({ className, completedStatus, skippedStatus, title }) => (
    <span
        title={title}
        className={cn("workflow-tasks-indicator", className, {
            "completed-status-indicator": completedStatus,
            "skipped-status-indicator": skippedStatus,
        })}
    />
);
