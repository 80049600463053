import React from "react";
import ClassNames from "classnames";

import "./style.scss";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

const Switcher = (props) => {
    const isTabable = !props.disabled;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({
        disabled: !isTabable,
        onClick: props.onClick,
    });

    const className = ClassNames("switcher", props.icon, props.className, {
        disabled: props.disabled,
        active: props.active,
        "with-icon": props.icon,
    });
    return (
        <div
            className={className}
            onClick={props.disabled ? undefined : props.onClick}
            title={props.title}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
        />
    );
};

export default Switcher;
