export const WORKFLOW = {
    STATUSES_CONTAINER: "WORKFLOW_STATUSES_CONTAINER",
    STEPS_CONTAINER: "WORKFLOW_STEPS_CONTAINER",
    STATUS: "STATUS",
    STEP: "STEP",
    STANDARD_PROCESS_MODE_ID: 351,
    DISABLED_STATUS_ID: 213,
    ACTIVE_STATUS_ID: 212,
    TARGETABLE_TRANSFER_ID: 190,
    NOT_TARGETABLE_TRANSFER_ID: 191,
    DISABLED_STEP_ID: 152,
    ACTIVE_STEP_ID: 151,
    ITEM_EDIT_STATUSES: {
        IS_NOT_CHANGED: 0,
        IS_CREATED: 1,
        IS_EDITED: 2,
    },
    NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_TOP: ["FBEF786A-C9D7-458E-BF58-AB803504B15D", "DBB7E112-A78C-4EDF-9287-C1B1908D4433"], //application entry/application recieve
    NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_BOTTOM: ["EEF78AF6-9DC0-405F-95B8-F88213521323", "8D18FA63-2D55-4DA7-BFDE-64E354E1E7EF"], //application complete//application cancel
};

export const NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS = WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_TOP.concat(
    WORKFLOW.NOT_ORDERABLE_WORKFLOW_STATE_NUMBERS_BOTTOM
);

export const HEADER_PREFIX_DND_ID = "header-";
