import React, { useEffect, useState, memo } from "react";
import { toArray } from "components/utils/array";
import { getAttributeTitle } from "components/utils/attributes";
import WaitIcon from "../../../../WaitIcon";
import TextInput from "../../../../Input/TextInput";
import Checkbox from "../../../../Input/Checkbox";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";
import { useEquipment } from "../EquipmentPanel/utils";
import { ProgramRights } from "components/utils/user";
import { useProgramRights } from "store/resources/useResource";

const EquipmentDetails = memo(({ equipmentId, applicationNumber, programNumber }) => {
    const [equipment, loadingEquipment] = useEquipment({ equipmentId, applicationNumber, showErrorNotification: false });
    const [programRights = [], isLoadingProgramRights] = useProgramRights({ programNumber });

    const [searchTerm, setSearchTerm] = useState("");
    const [showEmptyValues, setShowEmptyValues] = useState("");
    const [attributes, setAttributes] = useState([]);

    const hasRightsToEditAll = programRights.includes(ProgramRights.VISIONDSM_EDIT_ALL_EQUIPMENT);
    const headers = {
        attributeName: "ATTRIBUTE NAME",
        value: "VALUE",
    };

    useEffect(() => {
        if (equipment && equipment.attributes) {
            setAttributes(
                toArray(equipment.attributes)
                    // filter attributes by visibility
                    .filter((attr) => hasRightsToEditAll || attr.showAll === "1")
                    // filter attributes by value
                    .filter((attr) => (showEmptyValues ? attr : attr.value))
                    // format attribute name to be similar as in equipment edit form
                    .map((attr) => ({
                        ...attr,
                        attributename: getAttributeTitle(attr.friendlyName, attr.attributename),
                    }))
                    // filter attributes by search term
                    .filter((attr) => (attr.attributename || "").toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
    }, [equipment, hasRightsToEditAll, searchTerm, showEmptyValues]);

    if (loadingEquipment || isLoadingProgramRights) {
        return <WaitIcon />;
    }

    if (!equipment && !loadingEquipment) {
        return <div className="not-found-msg">Equipment not found</div>;
    }

    const renderHeader = (headers) => (
        <>
            <div className="header attribute-name">{headers.attributeName}</div>
            <div className="header attribute-value">{headers.value}</div>
        </>
    );

    const renderSearch = (filters, updateFilter) => (
        <>
            <div className="sidebar-list-filter-item attribute-name">
                <TextInput
                    placeholder="Search attribute"
                    icon="search"
                    iconRight
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                />
            </div>
            <div className="sidebar-list-filter-item attribute-value">
                <Checkbox
                    labelIconBig
                    label="Show empty values"
                    checked={showEmptyValues}
                    onChange={(event) => setShowEmptyValues(event.target.checked)}
                />
            </div>
        </>
    );

    const renderItem = (item, index) => {
        return (
            <div key={index} className="list-item-row">
                <div className="item-value attribute-name">{item.attributename}</div>
                <div className="item-value attribute-value">{item.value || "-"}</div>
            </div>
        );
    };

    return (
        <div className="equipment-attributes-list equipment-details sidebar-list flex-column">
            <div className="sidebar-list-body flex-column">
                <CustomList
                    limit={6}
                    items={attributes}
                    headers={headers}
                    renderHeaders={renderHeader}
                    renderSearch={renderSearch}
                    renderItem={renderItem}
                    renderFooter={attributes.length > 6 && renderCustomFooter}
                />
            </div>
        </div>
    );
});

export default EquipmentDetails;
