import React, { useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";

import TabList from "../../ui/List/TabList";
import ProductAttributesPanel from "./ProductAttributesPanel";
import EventAttributesPanel from "./EventAttributesPanel";
import BudgetAttributesPanel from "./BudgetAttributesPanel";
import AllCatalogAttributesPanel from "./AllCatalogAttributesPanel";
import AllHostnamesPanel from "./AllHostnamesPanel";
import Separator from "../../ui/Separator";

import "./style.scss";
import FTPConfigurationsPanel from "./FTPConfigurationsPanel";
import FTPSchedulesPanel from "./FTPSchedulesPanel";
import { hasPermission, systemUserRights } from "components/utils/user";
import AllProgramAttributesPanel from "./AllProgramAttributes";
import { LAYOUT_FULL_SCREEN } from "components/utils/settings";
import cn from "classnames";

const getTabs = (user) => [
    {
        id: "product-attributes",
        title: "Product Attributes",
    },
    {
        id: "event-attributes",
        title: "Event Attributes",
    },
    {
        id: "budget-attributes",
        title: "Budget Attributes",
    },
    {
        id: "all-program-attributes",
        title: "All Program Attributes",
    },
    {
        id: "all-catalog-attributes",
        title: "All Catalog Attributes",
    },
    {
        id: "all-hostnames",
        title: "All Hostnames",
    },
    ...(hasPermission(user, systemUserRights.VISIONDSM_SYSTEM_ADMIN)
        ? [
              {
                  id: "ftp-configs",
                  title: "FTP Configurations",
              },
              {
                  id: "ftp-schedules",
                  title: "FTP Schedules",
              },
          ]
        : []),
];

const SystemManagementConfiguration = (props) => {
    const user = useSelector((state) => state.user);
    const tabs = getTabs(user);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const settingsFromStore = useSelector((state) => state.user?.settings);
    const settings = useMemo(() => settingsFromStore ?? {}, [settingsFromStore]);
    const isFullScreenLayout = settings.layout === LAYOUT_FULL_SCREEN;

    return (
        <div className="system-config-panel flex-column fill-height">
            <div className="main-grid-wrap responsive flex-column fill-height">
                <Separator />
                <TabList
                    className={cn({ fullscreenLayout: isFullScreenLayout })}
                    items={tabs}
                    activeItem={activeTab}
                    onClick={(tab) => setActiveTab(tab)}
                />
                {activeTab.id === "product-attributes" && <ProductAttributesPanel {...props} />}
                {activeTab.id === "event-attributes" && <EventAttributesPanel {...props} />}
                {activeTab.id === "budget-attributes" && <BudgetAttributesPanel {...props} />}
                {activeTab.id === "all-program-attributes" && <AllProgramAttributesPanel {...props} />}
                {activeTab.id === "all-catalog-attributes" && <AllCatalogAttributesPanel {...props} />}
                {activeTab.id === "all-hostnames" && <AllHostnamesPanel {...props} />}
                {activeTab.id === "ftp-configs" && <FTPConfigurationsPanel {...props} />}
                {activeTab.id === "ftp-schedules" && <FTPSchedulesPanel {...props} />}
                <Separator />
            </div>
        </div>
    );
};

export default connect()(SystemManagementConfiguration);
