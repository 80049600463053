import React from "react";
import StatusMsg from "components/ui/StatusMsg";
import { SectionInfo } from "../SectionInfo";
import { SwitchProperty } from "./SwitchProperty";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyListItemTitle } from "../PropertyList/PropertyListItemTitle";

export const SiteTitleProperty = (props) => {
    const { value, onChange, title } = props;

    const enableUtilityName = value?.includes("utilityName");
    const enableProgramName = value?.includes("programName");

    const onPropertyChange = (id, value) => {
        let template = "";
        if (id === "enableUtilityName") {
            template = getTitleTemplate(value, enableProgramName);
        }
        if (id === "enableProgramName") {
            template = getTitleTemplate(enableUtilityName, value);
        }

        onChange(props.id, template);
    };

    return (
        <PropertyListItem className="property-list-item--prop-group" borderTop={false} borderBottom={true}>
            <PropertyListItemTitle title={title} />
            <div className="property-list-item--site-title property-list--level-2">
                <SiteTitleInfo />
                <SwitchProperty
                    id={"enableUtilityName"}
                    title={"Utility Name"}
                    value={enableUtilityName}
                    onChange={onPropertyChange}
                    borderTop={true}
                />
                <SwitchProperty id={"enableProgramName"} title={"Program Name"} value={enableProgramName} onChange={onPropertyChange} />
                <SwitchProperty
                    id={"enablePageName"}
                    title={"Page Name"}
                    value={true}
                    onChange={onChange}
                    disabled={true}
                    switchOffText={"Not allowed to switch OFF. Page Name should present always."}
                />
                <div className="preview-area-site-title flex-column flex-center">
                    <div className="preview-area-text-label">Preview area</div>
                    <div className="preview-text">{getPreviewText(enableUtilityName, enableProgramName)}</div>
                </div>
            </div>
        </PropertyListItem>
    );
};

export const SiteTitleInfo = () => {
    return (
        <SectionInfo>
            <StatusMsg
                msgIcon="info-empty"
                msgText={
                    <>
                        A site title identifies what the web page is about for both web users and search engines. <br />
                        The site title appears at the top of the tab or window.
                    </>
                }
            />
        </SectionInfo>
    );
};

const getPreviewText = (enableUtilityName, enableProgramName) => {
    const preview = "";
    const utilityName = enableUtilityName ? "Utility Name: " : "";
    const programName = enableProgramName ? "Program Name - " : "";
    return preview.concat(utilityName, programName, "Page Name");
};

const getTitleTemplate = (enableUtilityName, enableProgramName) => {
    let result = "";
    const utilityName = enableUtilityName ? "{utilityName}: " : "";
    const programName = enableProgramName ? "{programName} - " : "";

    return result.concat(utilityName, programName, "{pageName}");
};
