import React, { memo } from "react";

import { utilitiesHostnamesGridColumnKeys } from "../../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";

import IconWithLabel from "../../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";
import HostnamesGrid from "./HostnamesGrid";

const HostnamesPanel = memo(({ panel, onToggleTempPanel }) => {
    const utilityNumber = panel?.data?.utilityNumber;
    const gridId = `${utilityNumber}-hostnames-grid`;

    return (
        <div className="panel panel-hostnames sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="Hostnames" onClick={() => onToggleTempPanel()} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "utility_hostnames",
                            fileNamePostfix: utilityNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <HostnamesGrid
                utilityNumber={utilityNumber}
                gridId={gridId}
                filter={`${utilitiesHostnamesGridColumnKeys.utilityNumber}=${utilityNumber}`}
            />
        </div>
    );
});

export default HostnamesPanel;
