import React, { useContext, useCallback, useMemo, memo } from "react";

import { WORKFLOW } from "../constants/workflow";
import { hasErrorsWfStatus, hasErrorsWfStep } from "../utils/validation";

import Button from "../../Button";
import StickyBottomPanel from "../../StickyBottomPanel";
import IconWrap from "../../Icons";
import IconWithLabel from "../../Icons/IconWithLabel";
import { ErrorMessage } from "../../Message";

import { WorkflowContext } from "../context/WorkflowContext";

export const Buttons = memo(
    ({
        withSwitcher,
        isChanged,
        onClickSaveWorkflow,
        onClickCancel,
        onSetActiveItem,
        onToggleShowSteps,
        onToggleDisabledStatusesVisibility,
        activeItem,
    }) => {
        const { workflowStatuses, areDisabledItemsVisible, showSteps, hasErrors, isWorkflowUpdating, isLocked, isLoading } =
            useContext(WorkflowContext);

        const isStatusSelected = useCallback(
            (status) => {
                return activeItem && activeItem.type === WORKFLOW.STATUS && activeItem.statusNumber === status.number;
            },
            [activeItem]
        );

        const isStepSelected = useCallback(
            (step, status) => {
                return (
                    activeItem &&
                    activeItem.type === WORKFLOW.STEP &&
                    activeItem.statusNumber === status.number &&
                    activeItem.stepNumber === step.number
                );
            },
            [activeItem]
        );

        const statusesWithError = useMemo(() => {
            return workflowStatuses.filter(
                (status) =>
                    !(status.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && isStatusSelected(status)) && hasErrorsWfStatus(status)
            );
        }, [workflowStatuses, isStatusSelected]);

        const stepsWithError = useMemo(() => {
            return workflowStatuses.reduce((steps, status) => {
                const filteredSteps = status.steps.filter(
                    (step) =>
                        (!(step.editStatus === WORKFLOW.ITEM_EDIT_STATUSES.IS_CREATED && isStepSelected(step, status)) &&
                            hasErrorsWfStep(step)) ||
                        status.number === step.newStatus
                );
                return steps.concat(filteredSteps);
            }, []);
        }, [workflowStatuses, isStepSelected]);

        const handleSelectError = useCallback(
            (e) => {
                if (statusesWithError?.length > 0) {
                    console.log("handleSelectError", statusesWithError);
                    onSetActiveItem(e, statusesWithError[0].number, WORKFLOW.STATUS);
                } else if (stepsWithError?.length > 0) {
                    console.log("handleSelectError", stepsWithError);
                    const statusNumber = workflowStatuses.find((status) =>
                        status.steps.find((step) => step.number === stepsWithError[0].number)
                    )?.number;

                    onSetActiveItem(e, stepsWithError[0].number, WORKFLOW.STEP, statusNumber);
                }
            },
            [workflowStatuses, statusesWithError, stepsWithError, onSetActiveItem]
        );

        const errorNotification = useMemo(() => {
            if (hasErrors) {
                const statuses = statusesWithError?.length;
                const steps = stepsWithError?.length;
                const thereAreItemsWithErrorAndNotInEditMode = statuses !== 0 || steps !== 0;
                if (thereAreItemsWithErrorAndNotInEditMode) {
                    return (
                        <>
                            <ErrorMessage>{`There are ${statuses} Status${statuses === 1 ? "" : "es"} and ${steps} Step${
                                steps === 1 ? "" : "s"
                            } with errors.`}</ErrorMessage>
                            <IconWithLabel icon={"edit"} title={"Resolve Error"} onClick={handleSelectError}>
                                Click here to resolve
                            </IconWithLabel>
                        </>
                    );
                }
            }
        }, [hasErrors, statusesWithError, stepsWithError, handleSelectError]);

        const disabledItems = useMemo(() => {
            const statuses = workflowStatuses.filter((i) => i.statusID === WORKFLOW.DISABLED_STATUS_ID).length;

            let steps = 0;

            workflowStatuses.forEach((i) => (steps += i.steps.filter((j) => j.statusID === WORKFLOW.DISABLED_STEP_ID).length));

            return {
                statuses,
                steps,
            };
        }, [workflowStatuses]);

        const hasDisabledItems = useMemo(() => {
            const { statuses, steps } = disabledItems;

            return statuses > 0 || steps > 0;
        }, [disabledItems]);

        const disabledItemsCounter = useMemo(() => {
            const { statuses, steps } = disabledItems;

            if (statuses > 0 && steps > 0) {
                return `Statuses: ${statuses}; Steps: ${steps}`;
            } else if (statuses > 0) {
                return `Statuses: ${statuses}`;
            } else if (steps > 0) {
                return `Steps: ${steps}`;
            }
        }, [disabledItems]);

        return (
            <>
                {!withSwitcher && (
                    <StickyBottomPanel visible={isChanged || hasErrors}>
                        {errorNotification}
                        <Button primary onClick={onClickSaveWorkflow} disabled={hasErrors || isWorkflowUpdating || isLocked}>
                            {isWorkflowUpdating ? "Processing..." : "Save Workflow"}
                        </Button>
                        <Button
                            className="wf__buttons-cancel"
                            onClick={onClickCancel}
                            icon="undo"
                            disabled={isWorkflowUpdating || isLocked || isLoading || !isChanged}
                        >
                            Revert Changes
                        </Button>
                    </StickyBottomPanel>
                )}
                <div className="flex-column">
                    {withSwitcher && hasDisabledItems && (
                        <div className="wf__switcher flex-row align-center" onClick={onToggleDisabledStatusesVisibility}>
                            <IconWrap
                                icon={areDisabledItemsVisible ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                                onClick={onToggleDisabledStatusesVisibility}
                                title={disabledItemsCounter}
                            />
                            <div className="wf__switcher-text">Show disabled items</div>
                        </div>
                    )}
                    {withSwitcher && (
                        <div className="wf__switcher flex-row align-center" onClick={onToggleShowSteps}>
                            <IconWrap
                                icon={showSteps ? "fiber-smart-record-filled" : "fiber-smart-record-empty"}
                                onClick={onToggleShowSteps}
                            />
                            <div className="wf__switcher-text">Expand Statuses</div>
                        </div>
                    )}
                </div>
            </>
        );
    }
);
