import React, { useState, memo, useRef, useCallback, useEffect } from "react";
import { isNil } from "lodash";

import { useResource } from "../../../../../../store/resources/useResource";
import { programsGoalsGridColumnKeys } from "../../../../../views/configureGrids";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useReference } from "../../../../Reference/useReference";
import { referenceTypes } from "../../../../Reference/referenceTypes";
import { getData } from "../../../../../../store/dataGrid/actions";
import { jsonDateToDate } from "../../../../../utils/date";
import { referenceToAnyOf, listToAnyOf, pickInitialValues, submitResource, submitByRef } from "../../../../../utils/form";
import { ErrorMessage } from "../../../../Message";
import GridDetailsFooterActions from "../../../../DataGrid/GridDetailsFooterActions";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import { isChildProgram } from "components/views/ProgramView/utils";
import WaitIcon from "components/ui/WaitIcon";

const GoalsForm = memo((props) => {
    const { onClose, dispatch, dataItem, programNumber, gridId, sidePanel } = props;

    const isNew = dataItem == null;
    const dataGridValues = isNew ? {} : mapGridRowToObject(programsGoalsGridColumnKeys, dataItem);
    const resourceId = isNew ? null : dataGridValues.goalnumber;
    const isLocked = isChildProgram({ programNumber });

    const [goalTypes = []] = useReference(referenceTypes.goalType);

    const [groups = []] = useResource({
        resourceName: "programGroups",
        key: programNumber,
        path: {
            programNumber,
        },
    });

    const [isSubmitting, setSubmitting] = useState(false);

    const formRef = useRef();

    const handleSave = useCallback(() => {
        submitByRef(formRef);
    }, []);

    const [resource, isLoading] = useResource({
        resourceName: "programGoals",
        resourceId,
        path: {
            programNumber,
        },
        forced: true,
    });

    const title = isNew ? "Add Goal" : isLocked ? "View Goal" : "Edit Goal";

    const icon = isNew ? "plus" : isLocked ? "eye-visibility-empty" : "edit-empty";

    const submitText = isSubmitting ? "Saving..." : "Save";

    const notFound = !isNew && !isLoading && isNil(resource);
    const errorText = "Goal not found";

    const schema = {
        type: "object",
        required: ["goaltype", "goal", "effectivedate", "expiredate"],
        properties: {
            goaltype: {
                type: "integer",
                title: "Goal Type",
                anyOf: referenceToAnyOf({
                    list: goalTypes,
                }),
            },
            goal: {
                type: "number",
                title: "Goal",
            },
            effectivedate: {
                type: "string",
                title: "Effective Date",
            },
            expiredate: {
                type: "string",
                title: "Expiration Date",
            },
            groupNumber: {
                type: "string",
                title: "Group Name",
                anyOf: listToAnyOf({
                    list: groups,
                    map: (i) => ({ title: i.groupName, enum: [i.groupNumber] }),
                }),
            },
        },
    };

    const uiSchema = {
        goaltype: {
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
        goal: {
            "ui:placeholder": "Numeric",
            "ui:options": {
                decimals: 4,
            },
        },
        effectivedate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        expiredate: {
            "ui:widget": "date",
            "ui:options": {
                overlap: true,
            },
        },
        groupNumber: {
            "ui:options": {
                placeholder: "-- SELECT --",
            },
        },
    };

    const initialValues = isNew ? {} : pickInitialValues(resource);

    const onValidation = (formData, errors) => {
        if (jsonDateToDate(formData.effectivedate) >= jsonDateToDate(formData.expiredate)) {
            errors.effectivedate.addError("Effective Date must be smaller than Expiration Date");
        }

        return errors;
    };

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "programGoals",
            path: {
                programNumber,
            },
        };

        const body = {
            ...formData,
        };

        submitResource({
            resourceParams,
            resourceId,
            body,
            onRefresh: () => {
                dispatch(
                    getData({
                        dataGridId: gridId,
                    })
                );
            },
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    const otherActions = isNew ? null : (
        <>
            <span className="flex-one" />
            <GridDetailsFooterActions {...props} />
        </>
    );

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={icon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                {notFound ? (
                    <div className="with-padding">
                        <ErrorMessage>{errorText}</ErrorMessage>
                    </div>
                ) : (
                    <div className="category-form">
                        <JsonSchemaForm
                            formRef={formRef}
                            schema={schema}
                            uiSchema={uiSchema}
                            initialValues={initialValues}
                            disabled={isSubmitting || isLocked}
                            onSubmit={onSubmit}
                            otherActions={otherActions}
                            validate={onValidation}
                            noActions
                        />
                    </div>
                )}
            </SideNavBody>
            {!notFound && (
                <SideNavFooter justifyEnd={isLocked} setPrimaryButton={!isLocked}>
                    {!isLocked && (
                        <Button primary onClick={handleSave} disabled={isSubmitting}>
                            {submitText}
                        </Button>
                    )}
                    <Button onClick={onClose}>{isLocked ? "Close" : "Cancel"}</Button>
                </SideNavFooter>
            )}
        </SideNavContent>
    );
});

export default GoalsForm;
