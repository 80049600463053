import React, { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createResource } from "../../../store/resources/actions";
import { useResource } from "store/resources/useResource";

import Button from "../../ui/Button";
import { ErrorMessage } from "../../ui/Message";
import DropDownInput from "../../ui/Input/DropDownInput";

import Separator from "components/ui/Separator";
import { SelectedItemsExpandablePanel } from "components/ui/SelectedItemsExpandablePanel";
import WaitIcon from "components/ui/WaitIcon";

import "./MimicUserModal.scss";
import { hasPermission, systemUserRights } from "components/utils/user";

const MimicUserModal = memo(({ onMimicSuccess, onAssignManually, onCancel, users }) => {
    const dispatch = useDispatch();

    const [userNumber, setUserNumber] = useState("");
    const [mimicErrorText, setMimicErrorText] = useState();
    const user = useSelector((state) => state.user);
    const allowMimic =
        hasPermission(user, systemUserRights.VISIONDSM_ADD_USER) && hasPermission(user, systemUserRights.VISIONDSM_MANAGE_USER_RIGHTS);

    const [userList] = useResource(
        {
            key: "clientUsers",
            resourceName: "clientUsers",
            forced: true,
            path: {
                // With both bulk assignment and single user assignment, the first key will have the same client number as every object in the array
                clientNumber: users[0].clientNumber,
            },
            transform: (data) => {
                return data?.clientUserList;
            },
        },
        [users]
    );

    const selectedUsers = users.map(({ firstName, lastName, name }) => `${name} (${firstName} ${lastName})`);

    const changeUser = (e) => {
        setUserNumber(e.target.value);
        setMimicErrorText();
    };

    const handleMimicUser = () => {
        const body = users.map((u) => u.userNumber);

        dispatch(
            createResource({
                resourceName: "userRolesClone",
                path: {
                    userNumber,
                },
                body,
                showErrorNotification: false,
                onSuccess: () => {
                    onMimicSuccess();
                },
                onError: (data) => {
                    setMimicErrorText(data.message);
                },
            })
        );
    };

    return (
        <div className="flex-column mimic-user-modal">
            {mimicErrorText && <ErrorMessage msgSquare>{mimicErrorText}</ErrorMessage>}
            <SelectedItemsExpandablePanel items={selectedUsers} itemTitle="Selected User" itemsTitle="Selected Users" />
            <Separator />
            {userList ? (
                <DropDownInput
                    data={
                        userList &&
                        userList
                            .filter((u) => !users.some((selectedUser) => selectedUser.userNumber === u.userNumber))
                            .map(({ firstName, lastName, userNumber, userName }) => ({
                                label: `${userName} (${firstName} ${lastName})`,
                                value: userNumber,
                            }))
                    }
                    value={userNumber}
                    onChange={changeUser}
                    mobileHeader="Mimic from a user"
                    label="from a user"
                />
            ) : (
                <WaitIcon />
            )}
            <div className="flex-row fill-width mimic-actions">
                <Button disabled={!userNumber || !allowMimic} onClick={() => handleMimicUser()}>
                    Mimic Users
                </Button>
                <Button primary onClick={onAssignManually}>
                    Assign Manually...
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </div>
        </div>
    );
});

export default MimicUserModal;
