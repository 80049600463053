import React, { memo, useContext } from "react";
import { GridListContext } from ".";
import GridListCell from "./GridListCell";
import { MinColumnWidth } from "./utils";
import Checkbox from "../../Input/Checkbox";

const GridListSelectAllCell = memo(({ empty }) => {
    const { canSelectRow, selectAllRowsState, onRowSelectChange } = useContext(GridListContext);

    if (!canSelectRow) {
        return null;
    }

    return (
        <GridListCell className="row-select" width={MinColumnWidth}>
            {!empty && (
                <Checkbox
                    labelIconBig
                    iconLabelEmpty
                    checked={selectAllRowsState}
                    checkedPartly={!selectAllRowsState}
                    title={selectAllRowsState ? "Unselect all" : "Select all"}
                    onChange={() => onRowSelectChange()}
                />
            )}
        </GridListCell>
    );
});

export default GridListSelectAllCell;
