import * as actionTypes from "../actionTypes";
import { getSubMenuState } from "../../components/utils/subMenu";

const initialState = {
    activeSubMenu: {},
};

export const reducer = (state, action) => {
    state = state || getSubMenuState() || initialState;

    switch (action.type) {
        case actionTypes.SET_ACTIVE_SUBMENU:
            state = {
                ...state,
                activeSubMenu: {
                    ...state.activeSubMenu,
                    [action.key]: action.subMenu,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
