import React, { memo } from "react";
import { useSelector } from "react-redux";
import getPath from "lodash/get";
import { get } from "lodash";

import { useResource } from "../../../../../../store/resources/useResource";

import WaitIcon from "../../../../WaitIcon";
import NothingFoundBlock from "../../../../NothingFoundBlock";
import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";

import QualityControlList from "./QualityControlList";

const QualityControlPanelSmall = memo(({ panel, onSettings, onUnpin, onExpand, onToggleSidebar }) => {
    const applicationNumber = getPath(panel, "data.applicationNumber");
    const isDesktop = useSelector((state) => get(state, "window.isDesktop"));
    const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

    const [items = [], isLoading] = useResource({
        resourceName: "qcWidget",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    });

    const handleMoreFunctionality = () => {
        onExpand(panel);

        if (!isDesktop) {
            onToggleSidebar();
        }
    };

    return (
        <SidebarPanel
            sidebarHeaderTitle={panel.name}
            sidebarHeaderActions={sidebarHeaderActions}
            noPadding
            noFooter
            noData={items.length === 0}
        >
            <div className="container-body">
                {isLoading ? (
                    <WaitIcon />
                ) : (
                    <>
                        {items.length > 0 ? (
                            <QualityControlList items={items} onExpand={() => handleMoreFunctionality()} />
                        ) : (
                            <div className="empty-sidebar-content no-data">
                                <NothingFoundBlock nothingFoundBlockSmall icon="control" title="No QC made for this project" />
                            </div>
                        )}
                    </>
                )}
            </div>
        </SidebarPanel>
    );
});

export default QualityControlPanelSmall;
