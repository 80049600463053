import React from "react";
import IconWrap from "components/ui/Icons";
import "./WidgetInfoBlock.scss";

export const WidgetInfoBlock = (props) => {
    const { children } = props;

    return (
        <div className="widget-info-block">
            {children}
            <IconWrap icon="info-empty" />
        </div>
    );
};
