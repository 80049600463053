import React, { lazy, Suspense, memo } from "react";
import ModuleLoadError from "components/ui/ModuleLoadError";
import WaitIcon from "../../../../WaitIcon";

import "./style.scss";

const CalculationCode = lazy(() =>
    import("./CalculationCode").catch((error) => ({
        default: () => <ModuleLoadError error={error} />,
    }))
);

export const CalculationDetailsContext = React.createContext();

const CalculationDetails = memo(
    ({
        utilityNumber,
        programNumber,
        calculationType,
        targetAttrId,
        calculationStr,
        calculation,
        viewOnly,
        error,
        onChange,
        onGetTestInputs,
        onCalculate,
        testInputs,
        isLoadingTestInputs,
        isCalculationChanged,
        disabled,
        headerFormRef,
    }) => {
        const codeFallback = (
            <div className="fill-width">
                <WaitIcon />
            </div>
        );

        return (
            <CalculationDetailsContext.Provider
                value={{
                    utilityNumber,
                    programNumber,
                    isCalculationChanged,
                    isLoadingTestInputs,
                    testInputs,
                    onGetTestInputs,
                    onCalculate,
                }}
            >
                <div className="calculation-details-body flex-one with-scroll flex-row">
                    <Suspense fallback={codeFallback}>
                        <CalculationCode
                            disabled={disabled}
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            calculationType={calculationType}
                            targetAttrId={targetAttrId}
                            calculation={calculation}
                            value={calculationStr}
                            onChange={onChange}
                            viewOnly={viewOnly}
                            error={error}
                            headerFormRef={headerFormRef}
                        />
                    </Suspense>
                </div>
            </CalculationDetailsContext.Provider>
        );
    }
);

export default CalculationDetails;
