import { useState, useLayoutEffect, useRef, useCallback } from "react";

export const useResizeObserver = (node) => {
    const [observerEntry, setObserverEntry] = useState({});
    const observer = useRef(null);

    const disconnect = useCallback(() => observer.current?.disconnect(), []);

    const observe = useCallback((node) => {
        observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry));
        if (node) observer.current.observe(node);
    }, []);

    useLayoutEffect(() => {
        observe(node);
        return () => disconnect();
    }, [node, disconnect, observe]);

    return [observerEntry];
};
