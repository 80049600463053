import React, { memo } from "react";

const SERVICE_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

const StreetView = memo(({ location, width, height }) => {
    if (!location.address) {
        return <p>No data available</p>;
    }

    return (
        <img
            alt="StreetView"
            src={
                "https://maps.googleapis.com/maps/api/streetview?key=" +
                SERVICE_KEY +
                "&size=" +
                width +
                "x" +
                height +
                "&location=" +
                location.address +
                "+" +
                location.city +
                "+" +
                location.state +
                "+" +
                location.zip
            }
            border="0"
        />
    );
});

export default StreetView;
