import { isFormDataChanged } from "components/utils/form";
import { isNil } from "lodash";

export const ConfirmModalCheck = {
    DASHBOARD: "DASHBOARD",
};

export const customConfirmModalCheck = {
    [ConfirmModalCheck.DASHBOARD]: (sideNavState) => {
        // TODO write custom confirm modal check for Dashboard sideNav panel.
    },
};

export const isSideNavFormDataChanged = (sideNavState) => {
    const { form: formRef } = sideNavState ?? {};
    if (Array.isArray(formRef)) {
        return formRef.some((form) =>
            isSideNavFormDataChanged({
                ...sideNavState,
                form,
            })
        );
    }

    if (!formRef?.current) {
        return false;
    }

    if (Array.isArray(formRef.current)) {
        return formRef.current.some((form) =>
            isSideNavFormDataChanged({
                ...sideNavState,
                form,
            })
        );
    }

    const defaultValuesFromSchema = Object.fromEntries(
        Object.entries(formRef.current?.state.schema.properties)
            .filter(([_key, value]) => (value.anyOf ? value.anyOf.some((el) => el.enum.includes(value.default)) : !isNil(value.default)))
            .map(([key, value]) => [key, value.default])
    );

    return isFormDataChanged({
        initialValues: formRef.current?.getInitialValues(),
        currentValues: formRef.current?.state?.formData,
        defaultValues: defaultValuesFromSchema,
    });
};
