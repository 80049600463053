import { isNil } from "lodash";
import uuid from "uuid/v4";
import { getDateValueForInput } from "../../utils/date";
import {
    isValidationTypeNumeric,
    getAttributeFieldsFromCSV,
    validateAttributeFromCSV,
    getAttributesImportFromCsvErrorText,
    isAttributePlaceholderLine,
    ATTRIBUTE_MAX_LENGTH,
} from "../../utils/attributes";
import { getValidationTypes, getAttributeTypes } from "../Dashboard/Panel/Program/CatalogPanel/utils";
import { uploadCSV, lineToObject, showUploadCsvError } from "components/utils/CSV";
import { store } from "store/configureStore";
import { modalOpen, modalClose } from "store/modal/actions";
import { setAttributes } from "store/attributes/actions";
import { toast } from "react-toastify";
import { isLowerCaseEqual } from "components/utils/string";

export const LIMIT_VALIDATION_TEXT = "Lower Limit must be smaller than Upper Limit";

export const getInputValue = (value) => {
    return isNil(value) ? "" : value;
};

export const getDateInputValue = (value) => {
    return getDateValueForInput({ value, isFormatDate: true });
};

export const getLookupValuesListForDropDown = (attribute) => {
    return (attribute.lookupValues || []).map((item) => ({
        label: item.lookup,
        value: isValidationTypeNumeric(item.validationType) ? Number(item.lookup) : item.lookup,
    }));
};

export const getAvailableAttributesList = ({ attributes, availableAttributes, numberKey, descriptionKey }) => {
    const selectedValues = attributes.map((a) => a[numberKey]);

    return availableAttributes.map((a) => ({
        label: a[descriptionKey],
        value: a[numberKey],
        disabled: selectedValues.includes(a[numberKey]),
    }));
};

export const uploadAttributes = ({
    programNumber,
    attributes = [],
    attributesKey,
    numberKey,
    descriptionKey,
    availableAttributesResourceName,
}) => {
    uploadCSV({ delimiter: ",", header: true }).then((result) => {
        const processCSV = async () => {
            try {
                const validationTypes = await getValidationTypes();
                const attributeTypes = await getAttributeTypes({
                    programNumber,
                    resourceName: availableAttributesResourceName,
                });

                const isCatalog = availableAttributesResourceName === "programCatalogAttributes";

                let attributeNumbersInCsv = {};

                const updatedAttributes = result.lines
                    .map((line) => lineToObject({ header: result.header, line }))
                    .reduce((acc, line, index) => {
                        // Return null for empty attribute placeholder line to preserve correct line numbers
                        if (isAttributePlaceholderLine({ line })) {
                            return acc;
                        }

                        const lineNumber = index + 2;

                        const number = (attributeTypes.find((i) => isLowerCaseEqual(i[descriptionKey], line.ATTRNAME)) ?? {})[numberKey];

                        if (!number) {
                            throw Error(`Line ${lineNumber}. Invalid ATTRNAME ${line.ATTRNAME}`);
                        }

                        // Check if attribute is not duplicate in csv
                        if (attributeNumbersInCsv[number]) {
                            throw Error(`Line ${lineNumber}. Duplicate ATTRNAME ${line.ATTRNAME}`);
                        }

                        // Store attribute number for duplicate check
                        attributeNumbersInCsv[number] = true;

                        const attribute = {
                            [numberKey]: number,
                            ...getAttributeFieldsFromCSV(line, validationTypes),
                        };

                        validateAttributeFromCSV({
                            attribute,
                            line,
                            lineNumber,
                            attributeName: line.ATTRNAME,
                            attributeMaxLength: isCatalog ? ATTRIBUTE_MAX_LENGTH : undefined,
                        });

                        if (acc.find((a) => a[numberKey] === number)) {
                            acc = acc.map((existingAttribute) => {
                                if (existingAttribute[numberKey] === number) {
                                    return {
                                        ...existingAttribute,
                                        ...attribute,
                                    };
                                }

                                return existingAttribute;
                            });
                        } else {
                            acc.push({
                                _id: uuid(),
                                ...attribute,
                            });
                        }

                        return acc;
                    }, attributes.slice());

                store.dispatch(
                    setAttributes({
                        attributes: updatedAttributes,
                        key: attributesKey,
                    })
                );

                store.dispatch(modalClose());
                toast.success("Attributes Imported");
            } catch (error) {
                const text = getAttributesImportFromCsvErrorText({ error });
                showUploadCsvError({ text });
            }
        };

        store.dispatch(
            modalOpen({
                modalType: "WAITING_MODAL",
                modalProps: {
                    title: "Importing Attributes...",
                    modalIcon: "upload",
                },
            })
        );

        // Process CSV on next tick to not block waiting modal
        setTimeout(processCSV, 0);
    });
};
