import React, { memo, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { ScrollSyncPane } from "react-scroll-sync";
import cn from "classnames";
import { saveLayout } from "../../../store/dashboards/actions";

import { useDelayedMount, DelayedMountItem } from "components/utils/DelayedMount/useDelayedMount";

import Panel from "./Panel/PanelRoot";
import ViewPlaceholder from "../../ui/ViewPlaceholder";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./style.scss";
import "./DashboardLayoutLeft.scss";

const ResponsiveGridLayout = WidthProvider(Responsive);

const ResponsiveGridDashboard = memo(({ dashboard, dashboardData, availablePanels, onOpenSettings }) => {
    const [isMobile, setIsMobile] = useState(true);
    const activeBreakpoint = useRef();

    const dispatch = useDispatch();

    const isDesktop = useSelector((state) => state.window?.isDesktop);

    const columns = { lg: 3, md: 2, sm: 2, xs: 1 };
    const breakpoints = { lg: 1200, md: 996, sm: 720 /*768*/, xs: 0 };
    const layouts = { lg: [], md: [], sm: [], xs: [] };

    let panels = (dashboard && dashboard.panels) || [];

    if (dashboard) {
        panels = panels.map((p) => {
            return {
                ...p,
                w: p.w || 2,
                h: p.h || 2,
                x: p.x || 0,
                y: p.y || 0,
                data: dashboardData,
                isMobile,
            };
        });

        columns.lg = dashboard.columns;
        columns.md = Math.ceil(dashboard.columns / 2);
        columns.sm = Math.ceil(dashboard.columns / 3);
        columns.xs = 1;

        layouts.lg = panels.map((p, index) => {
            return {
                i: p.id,
                x: p.x,
                y: p.y,
                w: p.w,
                h: p.h,
                minH: 2,
            };
        });

        ["md", "sm", "xs"].forEach((breakpoint) => {
            layouts[breakpoint] = panels.map((p, index) => {
                return {
                    i: p.id,
                    x: p.x > columns[breakpoint] ? 0 : p.x,
                    y: p.y,
                    w: p.w > columns[breakpoint] ? columns[breakpoint] : p.w,
                    h: breakpoint === "xs" ? (p.h > 3 ? (isMobile ? 3 : 2) : 2) : breakpoint === "sm" && p.h > 3 ? 3 : p.h,
                    minH: 2,
                };
            });
        });
    }

    const dashboardClass = cn("dashboard flex-one-in-column flex-column no-scroll", {
        "layout-top": dashboard && dashboard.layout && dashboard.layout.type === "top-right",
        "layout-left": dashboard && dashboard.layout && dashboard.layout.type === "left-right" && isDesktop,
    });

    const onBreakpointChange = useCallback((newBreakpoint, newCols) => {
        activeBreakpoint.current = newBreakpoint;

        if (activeBreakpoint.current === "xs") {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, []);

    const onDragStop = useCallback(
        (layout, oldItem, newItem) => {
            if (activeBreakpoint.current === "lg" && layout.length > 0) {
                dispatch(
                    saveLayout({
                        dashboard,
                        layout: layout.map((l) => ({
                            i: l.i,
                            x: l.x,
                            y: l.y,
                            w: l.w,
                            h: l.h,
                        })),
                    })
                );
            }
        },
        [dashboard, dispatch]
    );

    const onResizeStop = useCallback(
        (layout, oldItem, newItem) => {
            if (activeBreakpoint.current === "lg" && layout.length > 0) {
                dispatch(
                    saveLayout({
                        dashboard,
                        layout: layout.map((l) => ({
                            i: l.i,
                            x: l.x,
                            y: l.y,
                            w: l.w,
                            h: l.h,
                        })),
                    })
                );
            }
        },
        [dashboard, dispatch]
    );

    // Calculate dashboard panels mount delay by their position on grid
    const panelMountDelays = {};
    let sortedPanels = panels.slice(0).sort((p1, p2) => {
        if (p1.y === p2.y) return p1.x - p2.x;
        return p1.y - p2.y;
    });
    let mountDelay = 0;
    for (const p of sortedPanels) {
        mountDelay += 50;
        panelMountDelays[p.id] = mountDelay;
    }

    // Delayed mount manager
    const delayedMountManager = useDelayedMount();

    return (
        <div className={dashboardClass}>
            <ScrollSyncPane>
                <div className="dashboard-panels-container fill-height">
                    <div className="main-grid-wrap responsive fill-height">
                        <ResponsiveGridLayout
                            className="layout"
                            layouts={layouts}
                            breakpoints={breakpoints}
                            cols={columns}
                            margin={[20, 20]}
                            containerPadding={[0, 20]}
                            onBreakpointChange={onBreakpointChange}
                            onDragStop={onDragStop}
                            onResizeStop={onResizeStop}
                            draggableHandle=".move"
                        >
                            {panels.map((p) => {
                                const delay = panelMountDelays[p.id] ? panelMountDelays[p.id] : 0;
                                const hasPermission = (availablePanels ?? []).some((i) => i.itemKey === p.type);
                                const panel = {
                                    ...p,
                                    type: hasPermission ? p.type : "no-permission-panel",
                                };
                                return (
                                    <div key={panel.id}>
                                        <DelayedMountItem key={panel.id} delay={delay} manager={delayedMountManager}>
                                            <Panel panel={panel} />
                                        </DelayedMountItem>
                                    </div>
                                );
                            })}
                        </ResponsiveGridLayout>
                        {panels.length === 0 && (
                            <ViewPlaceholder
                                clickableText="click here to add widgets"
                                clickableTextIcon="plus"
                                onClick={() => onOpenSettings(dashboard)}
                            >
                                {dashboard ? "Please select some widgets" : "Dashboard"}
                            </ViewPlaceholder>
                        )}
                    </div>
                </div>
            </ScrollSyncPane>
        </div>
    );
});

export default ResponsiveGridDashboard;
