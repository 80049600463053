import React, { useCallback, useContext, useEffect, useRef } from "react";
import { cloneDeep, isNil, isNumber } from "lodash";
import { ColumnProperty } from "./ColumnProperty";
import { SectionHeader } from "../SectionHeader";
import { PortalBuilderPropertiesContext, PageContext, RowContext } from "components/ui/PortalBuilder/contexts";
import { RowLayout } from "./RowLayout";
import { setWidgetProperties } from "../../utils";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

export const ColumnsProperty = (props) => {
    const { sectionIcon, selectedColumn, setSelectedColumn, sectionTitle } = useContext(PortalBuilderPropertiesContext);
    const { errors, activeSection } = useErrorContext();
    const { row } = useContext(RowContext);
    const { page } = useContext(PageContext);

    const { id, onChange, selectedLayout } = props;
    const value = row.value.components;

    const { setSectionPath } = useErrorContext();

    // Update active config path when user navigates in/out of row section.
    useEffect(() => {
        setSectionPath(() => {
            if (page) {
                return `pages[${page.index}].components[${row.index}].components[${selectedColumn}]`;
            }

            return `components[${row.index}].components[${selectedColumn}]`;
        });
        return () => {
            setSectionPath(() => {
                if (page) {
                    return `pages[${page.index}].components[${row.index}]`;
                }

                return `components[${row.index}]`;
            });
        };
    }, [page, row.index, selectedColumn, setSectionPath]);

    // onPropertyChange sometimes has old value if not using this ref.
    // TODO: investigate why onChange somehow preserves old value.
    const valueRef = useRef();
    valueRef.current = value;

    const onPropertyChange = useCallback(
        (propertyId, propertyValue, additionalValues = []) => {
            const updatedValue = cloneDeep(valueRef.current);
            updatedValue[selectedColumn] = setWidgetProperties(updatedValue[selectedColumn], [
                { id: propertyId, value: propertyValue },
                ...additionalValues,
            ]);

            onChange(id, updatedValue);
        },
        [id, onChange, selectedColumn]
    );

    const isColumnSelectable = (index) => index !== selectedColumn;

    const subtitle = (
        <span>
            <span className="black-text">
                {row.title} <span className="non-transformed-text">section-{selectedColumn + 1}</span>
            </span>{" "}
            configuration
        </span>
    );

    if (isNumber(selectedColumn) && !isNil(value[selectedColumn])) {
        return (
            <div className="portal-builder-properties-overlay-view flex-column fill-width">
                <SectionHeader
                    Icon={sectionIcon}
                    extraComponent={() => (
                        <RowLayout
                            layout={selectedLayout}
                            isColumnSelectable={isColumnSelectable}
                            onColumnSelect={setSelectedColumn}
                            selectedColumn={selectedColumn}
                        />
                    )}
                    title={page?.title || sectionTitle}
                    subtitle={subtitle}
                    underline
                    onGoBack={() => setSelectedColumn(null)}
                    containsErrors={errors?.some(
                        (e) =>
                            e.section?.path === activeSection?.path &&
                            e.pageIndex === page?.index &&
                            e.rowIndex === row.index &&
                            e.columnIndex === selectedColumn
                    )}
                />
                <ColumnProperty key={selectedColumn} value={value[selectedColumn]?.props} onChange={onPropertyChange} />
            </div>
        );
    }

    return null;
};
