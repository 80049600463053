import React, { memo } from "react";
import cn from "classnames";

import IconWrap from "../../Icons";
import useFocusClasses from "../../../utils/useFocusClasses";

import "../PagingCommon.scss";
import "./style.scss";

const Pagination = memo(({ selectedPage, pageCount, onSelectPage }) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const onKeyDown = (page) => (event) => {
        if (event.key === "Enter") {
            onSelectPage(page);
        }
    };

    return (
        <div>
            {pageCount > 1 && (
                <div className="paging-wrap">
                    <div className="paging">
                        <IconWrap
                            icon="arrow-in-circle-left-empty"
                            title="previous"
                            disabled={selectedPage < 1}
                            onClick={() => onSelectPage(selectedPage - 1)}
                        />
                        {Array.from({ length: pageCount }, (v, k) => k).map((page, index) => (
                            <div
                                key={index}
                                className={cn("selectable-page", {
                                    active: page === selectedPage,
                                })}
                                tabIndex={isTabable ? "0" : "-1"}
                                onFocus={onFocusClassesFocus}
                                onBlur={onFocusClassesBlur}
                                onKeyDown={onKeyDown(page)}
                                onClick={() => onSelectPage(page)}
                            >
                                <span>{page + 1}</span>
                            </div>
                        ))}
                        <IconWrap
                            icon="arrow-in-circle-right-empty"
                            title="next"
                            disabled={selectedPage >= pageCount - 1}
                            onClick={() => onSelectPage(selectedPage + 1)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default Pagination;
