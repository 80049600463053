export const API_GET = "API_GET";
export const API_GET_AUTHORIZED = "API_GET_AUTHORIZED";
export const API_POST = "API_POST";
export const API_POST_AUTHORIZED = "API_POST_AUTHORIZED";
export const API_PUT = "API_PUT";
export const API_PUT_AUTHORIZED = "API_PUT_AUTHORIZED";
export const API_DELETE = "API_DELETE";
export const API_DELETE_AUTHORIZED = "API_DELETE_AUTHORIZED";

export const APP_RESET = "APP_RESET";
export const APP_VERSION_CHANGED = "APP_VERSION_CHANGED";
export const SET_SERVER_TIMEZONE_OFFSET = "SET_SERVER_TIMEZONE_OFFSET";

export const API_CRUD_READ_LIST_REQUEST = "API_CRUD_READ_LIST_REQUEST";
export const API_CRUD_READ_LIST_SUCCESS = "API_CRUD_READ_LIST_SUCCESS";
export const API_CRUD_READ_LIST_ERROR = "API_CRUD_READ_LIST_ERROR";
export const API_CRUD_OPTIMISTIC_UPDATE_LIST = "API_CRUD_OPTIMISTIC_UPDATE_LIST";
export const API_CRUD_OPTIMISTIC_UPDATE_ITEM = "API_CRUD_OPTIMISTIC_UPDATE_ITEM";
export const API_CRUD_READ_REQUEST = "API_CRUD_READ_REQUEST";
export const API_CRUD_READ_SUCCESS = "API_CRUD_READ_SUCCESS";
export const API_CRUD_READ_ERROR = "API_CRUD_READ_ERROR";
export const API_CRUD_CREATE_REQUEST = "API_CRUD_CREATE_REQUEST";
export const API_CRUD_CREATE_SUCCESS = "API_CRUD_CREATE_SUCCESS";
export const API_CRUD_CREATE_ERROR = "API_CRUD_CREATE_ERROR";
export const API_CRUD_UPDATE_REQUEST = "API_CRUD_UPDATE_REQUEST";
export const API_CRUD_UPDATE_SUCCESS = "API_CRUD_UPDATE_SUCCESS";
export const API_CRUD_UPDATE_ERROR = "API_CRUD_UPDATE_ERROR";
export const API_CRUD_DELETE_REQUEST = "API_CRUD_DELETE_REQUEST";
export const API_CRUD_DELETE_SUCCESS = "API_CRUD_DELETE_SUCCESS";
export const API_CRUD_DELETE_ERROR = "API_CRUD_DELETE_ERROR";
export const API_CRUD_REGISTER_CALLBACKS = "API_CRUD_REGISTER_CALLBACKS";

export const DATA_GRID_INIT = "DATA_GRID_INIT";
export const DATA_GRID_DESTROY = "DATA_GRID_DESTROY";
export const DATA_GRID_MOUNT = "DATA_GRID_MOUNT";
export const DATA_GRID_UNMOUNT = "DATA_GRID_UNMOUNT";
export const DATA_GRID_UPDATE_COLUMNS = "DATA_GRID_UPDATE_COLUMNS";
export const DATA_GRID_CONSTRUCT = "DATA_GRID_CONSTRUCT";
export const DATA_GRID_CONSTRUCT_REQUEST = "DATA_GRID_CONSTRUCT_REQUEST";
export const DATA_GRID_CONSTRUCT_SUCCESS = "DATA_GRID_CONSTRUCT_SUCCESS";
export const DATA_GRID_CONSTRUCT_ERROR = "DATA_GRID_CONSTRUCT_ERROR";
export const DATA_GRID_GET_COLUMNS_REQUEST = "DATA_GRID_GET_COLUMNS_REQUEST";
export const DATA_GRID_GET_COLUMNS_SUCCESS = "DATA_GRID_GET_COLUMNS_SUCCESS";
export const DATA_GRID_GET_COLUMNS_ERROR = "DATA_GRID_GET_COLUMNS_ERROR";
export const DATA_GRID_SET_COLUMNS_REQUEST = "DATA_GRID_SET_COLUMNS_REQUEST";
export const DATA_GRID_SET_COLUMNS_SUCCESS = "DATA_GRID_SET_COLUMNS_SUCCESS";
export const DATA_GRID_SET_COLUMNS_ERROR = "DATA_GRID_SET_COLUMNS_ERROR";
export const DATA_GRID_GET_DATA = "DATA_GRID_GET_DATA";
export const DATA_GRID_GET_DATA_REQUEST = "DATA_GRID_GET_DATA_REQUEST";
export const DATA_GRID_GET_DATA_SUCCESS = "DATA_GRID_GET_DATA_SUCCESS";
export const DATA_GRID_CLIENT_GET_DATA_SUCCESS = "DATA_GRID_CLIENT_GET_DATA_SUCCESS";
export const DATA_GRID_CLIENT_PAGINATE = "DATA_GRID_CLIENT_PAGINATE";
export const DATA_GRID_CLIENT_FILTER = "DATA_GRID_CLIENT_FILTER";
export const DATA_GRID_CLIENT_SORT = "DATA_GRID_CLIENT_SORT";
export const DATA_GRID_GET_DATA_ERROR = "DATA_GRID_GET_DATA_ERROR";
export const DATA_GRID_GET_DATA_EXPORT = "DATA_GRID_GET_DATA_EXPORT";
export const DATA_GRID_CLIENT_GET_DATA_EXPORT = "DATA_GRID_CLIENT_GET_DATA_EXPORT";
export const DATA_GRID_SET_DATA_ACTION_PROPS = "DATA_GRID_SET_DATA_ACTION_PROPS";
export const DATA_GRID_SORT = "DATA_GRID_SORT";
export const DATA_GRID_SET_PAGE = "DATA_GRID_SET_PAGE";
export const DATA_GRID_FILTER = "DATA_GRID_FILTER";
export const DATA_GRID_INIT_PAGING = "DATA_GRID_INIT_PAGING";
export const DATA_GRID_CLEAR_ERROR = "DATA_GRID_CLEAR_ERROR";
export const DATA_GRID_SELECT_ROWS = "DATA_GRID_SELECT_ROWS";
export const DATA_GRID_UPDATE_ROWS = "DATA_GRID_UPDATE_ROWS";
export const DATA_GRID_SET_SOURCE_ROWS = "DATA_GRID_SET_SOURCE_ROWS";
export const DATA_GRID_CLEAR_DATA = "DATA_GRID_CLEAR_DATA";
export const DATA_GRID_CHANGE_SETTINGS_OPEN = "DATA_GRID_CHANGE_SETTINGS_OPEN";
export const DATA_GRID_TREE_EXPAND_CHANGE = "DATA_GRID_TREE_EXPAND_CHANGE";
export const DATA_GRID_ROW_EXPAND_CHANGE = "DATA_GRID_ROW_EXPAND_CHANGE";
export const DATA_GRID_ROW_GROUP_EXPAND_CHANGE = "DATA_GRID_ROW_GROUP_EXPAND_CHANGE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_ERROR_CLEAR = "RESET_PASSWORD_ERROR_CLEAR";

export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_ERROR_CLEAR = "CHANGE_PASSWORD_ERROR_CLEAR";
export const CHANGE_SSRS_PASSWORD_ERROR = "CHANGE_SSRS_PASSWORD_ERROR";

export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST";
export const PERMISSIONS_RECEIVE = "PERMISSIONS_RECEIVE";
export const PERMISSIONS_ERROR = "PERMISSIONS_ERROR";

export const SYSTEM_STATUS_REQUEST = "SYSTEM_STATUS_REQUEST";
export const SYSTEM_STATUS_RECEIVE = "SYSTEM_STATUS_RECEIVE";
export const SYSTEM_STATUS_ERROR = "SYSTEM_STATUS_ERROR";

export const HEARTBEAT_START = "HEARTBEAT_START";
export const HEARTBEAT_STOP = "HEARTBEAT_STOP";

export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGIN_USER_2FA_REQUEST = "LOGIN_USER_2FA_REQUEST";
export const LOGIN_USER_2FA_RESPONSE = "LOGIN_USER_2FA_RESPONSE";
export const LOGIN_USER_2FA_ERROR = "LOGIN_USER_2FA_ERROR";

export const LOGIN_REFRESH_REQUEST = "LOGIN_REFRESH_REQUEST";
export const LOGIN_REFRESH_RESPONSE = "LOGIN_REFRESH_RESPONSE";
export const LOGIN_REFRESH_ERROR = "LOGIN_REFRESH_ERROR";
export const LOGIN_ADD_REPEAT_ACTION = "LOGIN_ADD_REPEAT_ACTION";
export const LOGIN_CLEAR_REPEAT_ACTIONS = "LOGIN_CLEAR_REPEAT_ACTIONS";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_RESPONSE = "UPDATE_PASSWORD_RESPONSE";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const UPDATE_PASSWORD_ERROR_CLEAR = "UPDATE_PASSWORD_ERROR_CLEAR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_RESPONSE = "LOGOUT_RESPONSE";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const WINDOW_CONTAINER_ADD = "WINDOW_CONTAINER_ADD";
export const WINDOW_CONTAINER_REMOVE = "WINDOW_CONTAINER_REMOVE";
export const WINDOW_ADD = "WINDOW_ADD";
export const WINDOW_REMOVE = "WINDOW_REMOVE";
export const WINDOW_ACTIVATE = "WINDOW_ACTIVATE";
export const WINDOW_SET_DEVICE = "WINDOW_SET_DEVICE";
export const WINDOW_SET_NOTIFICATION_VISIBLE = "WINDOW_SET_NOTIFICATION_VISIBLE";

export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

export const SIDENAV_OPEN = "SIDENAV_OPEN";
export const SIDENAV_CLOSE = "SIDENAV_CLOSE";
export const SIDENAV_CHANGE = "SIDENAV_CHANGE";
export const SIDENAV_SET_CONFIRM = "SIDENAV_SET_CONFIRM";
export const SIDENAV_SET_OPENING = "SIDENAV_SET_OPENING";
export const SIDENAV_RESET_OPENING = "SIDENAV_CLEAR_OPENING";
export const SIDENAV_SET_CLOSING = "SIDENAV_SET_CLOSING";
export const SIDENAV_SET_FORM = "SIDENAV_SET_FORM";

export const USER_SAVE = "USER_SAVE";
export const USER_DELETE = "USER_DELETE";

export const USER_PROFILE_READ_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_READ_SUCCESS = "USER_PROFILE_READ_SUCCESS";
export const USER_PROFILE_READ_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_ERROR = "USER_PROFILE_UPDATE_ERROR";
export const USER_EMAIL_UPDATE_REQUEST = "USER_EMAIL_UPDATE_REQUEST";
export const USER_EMAIL_UPDATE_SUCCESS = "USER_EMAIL_UPDATE_SUCCESS";
export const USER_EMAIL_UPDATE_ERROR = "USER_EMAIL_UPDATE_ERROR";
export const USER_SSO_REQUEST = "USER_SSO_REQUEST";
export const USER_SSO_SUCCESS = "USER_SSO_SUCCESS";
export const USER_SSO_ERROR = "USER_SSO_ERROR";

export const FILES_UPLOAD_REQUEST = "FILES_UPLOAD_REQUEST";
export const FILES_UPLOAD_SUCCESS = "FILES_UPLOAD_SUCCESS";
export const FILES_UPLOAD_ERROR = "FILES_UPLOAD_ERROR";
export const FILES_DOWNLOAD_REQUEST = "FILES_DOWNLOAD_REQUEST";
export const FILES_DOWNLOAD_SUCCESS = "FILES_DOWNLOAD_SUCCESS";
export const FILES_DOWNLOAD_ERROR = "FILES_DOWNLOAD_ERROR";
export const FILES_PREVIEW_REQUEST = "FILES_PREVIEW_REQUEST";
export const FILES_PREVIEW_SUCCESS = "FILES_PREVIEW_SUCCESS";
export const FILES_PREVIEW_ERROR = "FILES_PREVIEW_ERROR";
export const FILES_DELETE_REQUEST = "FILES_DELETE_REQUEST";
export const FILES_DELETE_SUCCESS = "FILES_DELETE_SUCCESS";
export const FILES_DELETE_ERROR = "FILES_DELETE_ERROR";
export const FILES_REQS_REQUEST = "FILES_REQS_REQUEST";
export const FILES_REQS_SUCCESS = "FILES_REQS_SUCCESS";
export const FILES_REQS_ERROR = "FILES_REQS_ERROR";

export const APP_FORM_INIT = "APP_FORM_INIT";
export const APP_FORM_DESTROY = "APP_FORM_DESTROY";
export const APP_FORM_ADD_FIELD = "APP_FORM_ADD_FIELD";
export const APP_FORM_ADD_COLUMN = "APP_FORM_ADD_COLUMN";
export const APP_FORM_ADD_SECTION = "APP_FORM_ADD_SECTION";
export const APP_FORM_ADD_PAGE = "APP_FORM_ADD_PAGE";
export const APP_FORM_UPDATE = "APP_FORM_UPDATE";
export const APP_FORM_UPDATE_FIELD = "APP_FORM_UPDATE_FIELD";
export const APP_FORM_UPDATE_COLUMN = "APP_FORM_UPDATE_COLUMN";
export const APP_FORM_UPDATE_SECTION = "APP_FORM_UPDATE_SECTION";
export const APP_FORM_UPDATE_PAGE = "APP_FORM_UPDATE_PAGE";
export const APP_FORM_UPDATE_SCHEMA = "APP_FORM_UPDATE_SCHEMA";
export const APP_FORM_UPDATE_UI_SCHEMA = "APP_FORM_UPDATE_UI_SCHEMA";
export const APP_FORM_UPDATE_RULES = "APP_FORM_UPDATE_RULES";
export const APP_FORM_UPDATE_INITIAL_VALUES = "APP_FORM_UPDATE_INITIAL_VALUES";
export const APP_FORM_SELECT_ELEMENT = "APP_FORM_SELECT_ELEMENT";
export const APP_FORM_SET_FORM_DATA = "APP_FORM_SET_FORM_DATA";
export const APP_FORM_SET_VALIDATION_ERRORS = "APP_FORM_SET_VALIDATION_ERRORS";
export const APP_FORM_UPDATE_INITIAL_SCHEMA = "APP_UPDATE_INITIAL_SCHEMA";

export const FORM_PAGES_CLEAR_ACTIVE_PAGE = "FORM_PAGES_CLEAR_ACTIVE_PAGE";
export const FORM_PAGES_SET_ACTIVE_PAGE = "FORM_PAGES_SET_ACTIVE_PAGE";
export const FORM_PAGES_NEW_PAGE_CREATED = "FORM_PAGES_NEW_PAGE_CREATED";
export const FORM_PAGES_NEW_PAGE_SAVED = "FORM_PAGES_NEW_PAGE_SAVED";
export const FORM_PAGES_NEW_PAGE_DELETED = "FORM_PAGES_NEW_PAGE_DELETED";

export const DOCUMENTS_GET_ONE_REQUEST = "DOCUMENTS_GET_ONE_REQUEST";
export const DOCUMENTS_GET_ONE_SUCCESS = "DOCUMENTS_GET_ONE_SUCCESS";
export const DOCUMENTS_GET_ONE_ERROR = "DOCUMENTS_GET_ONE_ERROR";
export const DOCUMENTS_CLEAR_DOCUMENT = "DOCUMENTS_CLEAR_DOCUMENT";
export const DOCUMENTS_CREATE_ONE_REQUEST = "DOCUMENTS_CREATE_ONE_REQUEST";
export const DOCUMENTS_CREATE_ONE_SUCCESS = "DOCUMENTS_CREATE_ONE_SUCCESS";
export const DOCUMENTS_CREATE_ONE_ERROR = "DOCUMENTS_CREATE_ONE_ERROR";

export const FORM_GET_REQUEST = "FORM_GET_REQUEST";
export const FORM_GET_SUCCESS = "FORM_GET_SUCCESS";
export const FORM_GET_ERROR = "FORM_GET_ERROR";
export const FORM_SUBMIT_REQUEST = "FORM_SUBMIT_REQUEST";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_ERROR = "FORM_SUBMIT_ERROR";
export const FORM_CLEAR = "FORM_CLEAR";
export const FORM_MESSAGE_CLEAR = "FORM_MESSAGE_CLEAR";
export const FORM_SET_DATA = "FORM_SET_DATA";
export const FORM_CLEAR_DATA = "FORM_CLEAR_DATA";

export const PROJECTS_SET_ACTIVE_APPLICATION_FORM = "PROJECTS_SET_ACTIVE_APPLICATION_FORM";
export const PROJECTS_SET_EXPANDED_WORKFLOW_TASK = "PROJECTS_SET_EXPANDED_WORKFLOW_TASK";
export const PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS = "PROJECTS_SET_EQUIPMENT_UPLOAD_IN_PROGRESS";
export const PROJECTS_SET_CALCULATIONS_IN_PROGRESS = "PROJECTS_SET_CALCULATIONS_IN_PROGRESS";

export const PROGRAMS_SET_APPLICATION_STATUS = "PROGRAMS_SET_APPLICATION_STATUS";

export const PROGRAM_SET_ATTRIBUTES = "PROGRAM_SET_ATTRIBUTES";
export const PROGRAM_SET_CATALOG_ASSOCIATIONS = "PROGRAM_SET_CATALOG_ASSOCIATIONS";

export const SET_ACTIVE_DASHBOARD = "SET_ACTIVE_DASHBOARD";
export const SET_ACTIVE_DASHBOARD_TOOL = "SET_ACTIVE_DASHBOARD_TOOL";
export const SET_AUTO_CATALOG_EDIT_OPEN = "SET_AUTO_CATALOG_EDIT_OPEN";
export const SET_AUTO_ATTRIBUTE_EDIT_OPEN = "SET_AUTO_ATTRIBUTE_EDIT_OPEN";
export const SET_ACTIVE_TEMP_PANEL = "SET_ACTIVE_TEMP_PANEL";
export const SET_ACTIVE_SUBMENU = "SET_ACTIVE_SUBMENU";
export const TOGGLE_WIDGET_CONFIGURATION = "TOGGLE_WIDGET_CONFIGURATION";
export const TOGGLE_DASHBOARD_SIDEBAR = "TOGGLE_DASHBOARD_SIDEBAR";

export const INIT_WORKCENTER = "INIT_WORKCENTER";
export const SET_WORKQUEUE_ACTIVE_DATASOURCE = "SET_WORKQUEUE_ACTIVE_DATASOURCE";
export const SET_WORKQUEUE_ACTIVE_FILTER_TAB = "SET_WORKQUEUE_ACTIVE_FILTER_TAB";
export const SET_WORKQUEUE_APPLICATIONS_FILTER = "SET_WORKQUEUE_APPLICATIONS_FILTER";
export const SET_WORKQUEUE_TASKS_FILTER = "SET_WORKQUEUE_TASKS_FILTER";
export const SET_WORKQUEUE_GRID = "SET_WORKQUEUE_GRID";
export const SET_WORKQUEUE_GRID_FILTER = "SET_WORKQUEUE_GRID_FILTER";
export const SET_WORKQUEUE_GRID_SELECTED_ROWS = "SET_WORKQUEUE_GRID_SELECTED_ROWS";
export const SET_WORKQUEUE_DATASOURCE_FILTER_EXPANDED = "SET_WORKQUEUE_DATASOURCE_FILTER_EXPANDED";

export const SET_GLOBAL_SEARCH_CRITERIA_LIST = "SET_GLOBAL_SEARCH_CRITERIA_LIST";
export const SET_GLOBAL_SEARCH_PAGE_NUMBER = "SET_GLOBAL_SEARCH_PAGE_NUMBER";
export const SET_GLOBAL_SEARCH_PAGE_SIZE = "SET_GLOBAL_SEARCH_PAGE_SIZE";
export const SET_GLOBAL_SEARCH_IS_SEARCHING = "SET_GLOBAL_SEARCH_IS_SEARCHING";
export const SET_GLOBAL_SEARCH_ERROR = "SET_GLOBAL_SEARCH_ERROR";
export const SET_GLOBAL_SEARCH_MAIN_CRITERA = "SET_GLOBAL_SEARCH_MAIN_CRITERA";
export const SET_GLOBAL_SEARCH_SUB_CRITERA = "SET_GLOBAL_SEARCH_SUB_CRITERA";
export const SET_GLOBAL_SEARCH_CRITERIA_VALUE = "SET_GLOBAL_SEARCH_CRITERIA_VALUE";
export const SET_GLOBAL_SEARCH_AREA_EXPANDED = "SET_GLOBAL_SEARCH_AREA_EXPANDED";
export const SET_GLOBAL_SEARCH_UTILITY = "SET_GLOBAL_SEARCH_UTILITY";
export const SET_GLOBAL_SEARCH_PROGRAM = "SET_GLOBAL_SEARCH_PROGRAM";
