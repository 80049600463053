import React, { memo } from "react";

import { exportDatagridToCSV } from "../../../../../../utils/CSV";
import { utilitiesAttributesGridColumnKeys } from "../../../../../../views/configureGrids";

import { sideNavSize } from "../../../../../SideNav/SideNavRoot";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";

import IconWithLabel from "../../../../../Icons/IconWithLabel";
import PanelHeaderLarge from "../../../../../Headers/PanelHeaderLarge";

import AttributesGrid from "./AttributesGrid";
import AttributesForm from "./AttributesForm";

import "../../../ConfigAttributesPanel.scss";
import "../../UtilityCommonStyles.scss";

const AttributesPanel = memo(({ panel, onToggleTempPanel }) => {
    const utilityNumber = panel?.data?.utilityNumber;
    const gridId = `${utilityNumber}-attributes-grid`;

    const addAttributeIcon = "plus";

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "utility-config-attributes-sidenav-panel",
    });
    const openAttributeForm = () => {
        handleOpenSidePanel(<AttributesForm utilityNumber={utilityNumber} gridId={gridId} onClose={handleCloseSidePanel} />);
    };

    return (
        <div className="panel panel-attributes sticky-grid-list-panel">
            <PanelHeaderLarge title={panel?.name} childTitle="Attributes" onClick={() => onToggleTempPanel()} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel icon={addAttributeIcon} onClick={openAttributeForm}>
                    Add Attribute
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "utility_attributes",
                            fileNamePostfix: utilityNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <AttributesGrid
                utilityNumber={utilityNumber}
                gridId={gridId}
                filter={`${utilitiesAttributesGridColumnKeys.utilityNumber}=${utilityNumber}`}
            />
        </div>
    );
});

export default AttributesPanel;
