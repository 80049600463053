import React, { useCallback, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCatalogItemsGridId, downloadCatalogAttributes, uploadCatalogAttributes } from "../utils";
import { programsCatalogGridColumnKeys } from "../../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../../utils/CSV";
import { isChildProgram, isLockedText } from "../../../../../../views/ProgramView/utils";
import useSidePanelHandlers from "../../../../../../utils/useSidePanelHandlers";
import { onCatalogItemsGridRefresh } from "../utils";
import { setCatalogAutoOpen } from "../../../../../../../store/dashboards/actions";

import Grid from "./Grid";
import Controls from "../../../Controls";
import Form from "../Form";

const CatalogItemsTab = memo(({ utilityNumber, programNumber }) => {
    const dataGridId = getCatalogItemsGridId({ programNumber });
    const isLocked = isChildProgram({ programNumber });
    const dispatch = useDispatch();
    const autoCatalogOpenState = useSelector((state) => {
        return state.dashboards.catalogAutoEditOpen;
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        className: "catalog-sidenav-panel",
    });

    useEffect(() => {
        if (autoCatalogOpenState && autoCatalogOpenState.autoOpen) {
            handleOpenSidePanel(
                <Form
                    utilityNumber={autoCatalogOpenState.utilityNumber}
                    programNumber={autoCatalogOpenState.programNumber}
                    catalogNumber={autoCatalogOpenState.catalogNumber}
                    catalogID={autoCatalogOpenState.catalogID}
                    extended={true}
                    gridRefresh={() => onCatalogItemsGridRefresh({ programNumber })}
                    onClose={handleCloseSidePanel}
                />
            );

            dispatch(
                setCatalogAutoOpen({
                    utilityNumber: "",
                    programNumber: "",
                    catalogNumber: "",
                    autoOpen: false,
                })
            );
        }
    }, [autoCatalogOpenState, handleCloseSidePanel, handleOpenSidePanel, programNumber, dispatch]);

    const handleOpenItem = useCallback(
        ({ dataItem, extended }) => {
            const catalogNumber = dataItem ? dataItem[programsCatalogGridColumnKeys.catalogNumber] : null;
            const catalogID = dataItem ? dataItem[programsCatalogGridColumnKeys.catalogID] : null;
            handleOpenSidePanel(
                <Form
                    utilityNumber={utilityNumber}
                    programNumber={programNumber}
                    catalogNumber={catalogNumber}
                    catalogID={catalogID}
                    extended={extended}
                    gridRefresh={() => onCatalogItemsGridRefresh({ programNumber })}
                    onClose={handleCloseSidePanel}
                />
            );
        },
        [utilityNumber, programNumber, handleOpenSidePanel, handleCloseSidePanel]
    );

    const attributeExportTooltip =
        "Download All Equipment Attributes in CSV Format\nOnly NAME, CATALOGNUMBER, CATALOGID, STATUS and QUANTITY\nfilters are taken into account";

    const controlItems = [
        // TODO: Include actions when functionality will be implemented BUG: V50-2666, V50-2667
        //     {
        //     position: 'left',
        //     title: 'Category Assignment via CSV',
        //     icon: "apps-empty",
        //     disabled: isLocked,
        //     tooltip: isLocked ? isLockedText : undefined,
        //     onClick: () => uploadCategoryAssignment({ programNumber })
        // }, {
        //     position: 'left',
        //     title: 'Lookup Values via CSV',
        //     icon: "view-list-list-bulleted",
        //     disabled: isLocked,
        //     tooltip: isLocked ? isLockedText : undefined,
        //     onClick: () => uploadLookupValues({ programNumber })
        // },
        {
            position: "right",
            title: "Add Catalog Item",
            icon: "plus",
            disabled: isLocked,
            tooltip: isLocked ? isLockedText : undefined,
            onClick: () => handleOpenItem({ dataItem: null, extended: true }),
        },
        {
            position: "right",
            title: "Import Attributes from CSV",
            tooltip: isLocked ? isLockedText : "Upload All Equipment Attributes in CSV Format",
            icon: "upload",
            disabled: isLocked,
            onClick: () => uploadCatalogAttributes({ programNumber }),
        },
        {
            position: "right",
            title: "Export Attributes as CSV",
            tooltip: attributeExportTooltip,
            icon: "download",
            onClick: () => downloadCatalogAttributes({ programNumber, dataGridId }),
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-export-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId,
                    fileName: "program_catalog_items",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="program-catalog-tab flex-one-in-column no-scroll">
            <Controls items={controlItems} />
            <Grid
                programNumber={programNumber}
                gridId={dataGridId}
                filter={`${programsCatalogGridColumnKeys.programNumber}=${programNumber}`}
                onOpenItem={handleOpenItem}
            />
        </div>
    );
});

export default CatalogItemsTab;
