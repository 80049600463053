import React, { useCallback, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { utilitiesEventCalculationAssociationsColumnKeys } from "../../../../../views/configureGrids";
import { onRemoveEventAssignments, onAddEventAssignments } from "./actions";
import { exportCSV } from "../../../../../../store/dataGrid/actions";
import useMultiPageRowSelect from "../../../../../../store/dataGrid/useMultiPageRowSelect";

import Controls from "./Controls";
import BulkAssignmentHeader from "./BulkAssignmentHeader";
import SingleAssignmentHeader from "./SingleAssignmentHeader";
import { EventCalculationAssociationsGrid } from "./EventCalculationAssociationsGrid";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavContent from "../../../../SideNav/SideNavContent";
import Button from "../../../../Button";
import SideNavHeader from "../../../../SideNav/SideNavHeader";

export const EventCalculationAssociations = memo(({ utilityNumber, programNumber, rowData, onClose, sidePanel, disabled }) => {
    const dispatch = useDispatch();
    const calculationNumberKey = utilitiesEventCalculationAssociationsColumnKeys.calculationNumber;
    const itemNumberKey = utilitiesEventCalculationAssociationsColumnKeys.eventNumber;
    const [bulkRows, setBulkRows] = useState(Array.isArray(rowData) ? rowData : [rowData]);
    const isBulkAssign = bulkRows.length > 1;
    const calculationNumbers = bulkRows.map((item) => item.calculationNumber);
    const dataGridId = `${utilityNumber}-event-calculation-associations-grid`;

    //TODO: Will need to fix when grid will allow to filter column by multiple values
    const dataGridFilter = calculationNumbers.map((calculationNumber) => `${calculationNumberKey}=${calculationNumber}`)[0];
    //.join('|');

    const [selectedRows, setSelectedRows] = useState([]);

    const getSelectedItems = useCallback(() => {
        return Object.entries(selectedRows)
            .map((entry) => entry[1])
            .reduce((acc, next) => (acc = acc.concat(next)), [])
            .map((i) => i[itemNumberKey]);
    }, [selectedRows, itemNumberKey]);

    const selectedRowCount = getSelectedItems().length;
    const assignmentDisabled = selectedRowCount === 0;

    const onRowSelect = useCallback((rows) => {
        setSelectedRows(rows);
    }, []);

    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId,
        onRowSelect,
    });

    const onRemove = (eventNumber) =>
        onRemoveEventAssignments({
            dataGridId,
            calculationNumbers,
            eventNumbers: eventNumber ? [eventNumber] : getSelectedItems(),
            onRowSelectClear,
            dispatch,
        });

    const onAdd = () =>
        onAddEventAssignments({
            dataGridId,
            utilityNumber,
            calculationNumbers,
            dispatch,
        });

    const onExport = useCallback(() => {
        dispatch(exportCSV({ dataGridId }));
    }, [dataGridId, dispatch]);

    const onRemoveBulkCalculation = (row) => {
        setBulkRows(bulkRows.filter((r) => r.calculationNumber !== row.calculationNumber));
    };

    return (
        <SideNavContent className="calculations-form associations">
            <SideNavBody rowLayout noPadding>
                <SideNavHeader title="Event Calculation Associations" leadBlockIcon="hub-empty" sidenavHeaderLeftAligned onClose={onClose}>
                    {!isBulkAssign && (
                        <SingleAssignmentHeader
                            utilityNumber={utilityNumber}
                            programNumber={programNumber}
                            calculationType={bulkRows[0].calculationType}
                            userName={bulkRows[0].userName}
                            revisionDate={bulkRows[0].revisionDate}
                            calculationNumber={bulkRows[0].calculationNumber}
                            target={bulkRows[0].target}
                            sidePanel={sidePanel}
                        />
                    )}
                </SideNavHeader>
                <div className="panel calculations associations flex-one no-scroll">
                    {isBulkAssign && (
                        <BulkAssignmentHeader
                            rowData={bulkRows}
                            onRemoveCalculation={onRemoveBulkCalculation}
                            onChangeSelection={onClose}
                        />
                    )}
                    <Controls
                        disabled={disabled}
                        programNumber={programNumber}
                        assignmentDisabled={assignmentDisabled}
                        selectedRowCount={selectedRowCount}
                        onRowSelectClear={onRowSelectClear}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onExport={onExport}
                    />
                    <EventCalculationAssociationsGrid
                        disabled={disabled}
                        programNumber={programNumber}
                        gridId={dataGridId}
                        filter={dataGridFilter}
                        onRowSelectChange={onRowSelectChange}
                        onPageChanged={onPageChanged}
                        onRemove={onRemove}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});
