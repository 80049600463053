import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { programsCorrespondenceWorkflowGridColumnKeys } from "../../../../../views/configureGrids";

import CorrespondenceWorkflowGrid from "./CorrespondenceWorkflowGrid";
import Controls from "../../Controls";
import Button from "../../../../Button";

import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";

const CorrespondenceWorkflowPanel = memo(({ programNumber, documentName, documentNumber, onClose }) => {
    const dispatch = useDispatch();
    const gridId = `${documentNumber}-workflowSteps`;

    const controlItems = [
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: gridId,
                    fileName: "program_correspondence_workflow",
                    fileNamePostfix: documentName,
                }),
        },
    ];

    return (
        <SideNavContent>
            <SideNavHeader
                title="Documents All Program Workflow Items Using "
                leadBlockIcon="workflow"
                nameInBold={documentName}
                smallHeader
                onClose={onClose}
            />
            <SideNavBody className="flex-one-in-column">
                <Controls items={controlItems} />
                <div className="panel program-correspondence-workflow-panel sticky-grid-list-panel">
                    <CorrespondenceWorkflowGrid
                        documentName={documentName}
                        dispatch={dispatch}
                        programNumber={programNumber}
                        gridId={gridId}
                        filter={`${programsCorrespondenceWorkflowGridColumnKeys.documentNumber}=${documentNumber}`}
                    />
                </div>
            </SideNavBody>
            <SideNavFooter justifyEnd>
                <Button onClick={onClose}>Close</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

export default CorrespondenceWorkflowPanel;
