import React, { useCallback, useContext } from "react";
import IconWithLabel from "components/ui/Icons/IconWithLabel";
import { PortalBuilderPropertiesContext } from "components/ui/PortalBuilder/contexts";
import { SECTION_CONTENT } from "../../Section";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";
import { useErrorContext } from "components/ui/PortalBuilder/PortalBuilderErrorContextProvider";

export const HeaderControls = ({ onBack }: HeaderControlsProps) => {
    const [lastEditingPageIndex, setPortalBuilderState] = usePortalBuilderState((state) => state.lastEditingPageIndex);
    const [lastActiveSection] = usePortalBuilderState((state) => state.lastActiveSection);
    const { setActiveSection } = useErrorContext()!;
    const { setSelectedWidget } = useContext(PortalBuilderPropertiesContext);

    const onGoBack = useCallback(() => {
        if (lastActiveSection) {
            if (lastActiveSection === SECTION_CONTENT) {
                setPortalBuilderState({ editingPageIndex: lastEditingPageIndex });
            }
            setActiveSection(lastActiveSection);
            setSelectedWidget && setSelectedWidget(null);
        } else {
            onBack && onBack();
        }
    }, [lastActiveSection, setActiveSection, setSelectedWidget, setPortalBuilderState, lastEditingPageIndex, onBack]);

    return (
        <div className="portal-builder-section-header-controls flex-row">
            <IconWithLabel icon="shevron-small-left" onClick={onGoBack}>
                Back
            </IconWithLabel>
        </div>
    );
};

interface HeaderControlsProps {
    onBack?: () => void;
}
