import React from "react";
import TextLabel from "components/ui/Label/TextLabel";
import { formatJsonDateTime } from "components/utils/date";

import "./style.scss";

export const SupplementalDataBlock = ({ props }) => {
    const {
        createDate,
        creatorUserName,
        lastLoginDate,
        lastLoginIpAddress,
        lastPasswordResetRequest,
        lastPasswordUpdate,
        lastUpdateDate,
        lastUpdateUserName,
    } = props;

    return (
        <div className="supplemental-data-block flex-row flex-wrap">
            <HeaderItem title="Created Date" value={formatJsonDateTime(createDate, "-")} />
            <HeaderItem title="Creator User Name" value={creatorUserName} />
            <HeaderItem title="Last Login Date" value={formatJsonDateTime(lastLoginDate, "-")} />
            <HeaderItem title="Last Login IP Address" value={lastLoginIpAddress} />
            <HeaderItem title="Last Password Reset Request" value={formatJsonDateTime(lastPasswordResetRequest, "-")} />
            <HeaderItem title="Last Password Update" value={formatJsonDateTime(lastPasswordUpdate, "-")} />
            <HeaderItem title="Last Update Date" value={formatJsonDateTime(lastUpdateDate, "-")} />
            <HeaderItem title="Last Update User Name" value={lastUpdateUserName} />
        </div>
    );
};

const HeaderItem = ({ title, value, children }) => {
    return (
        <div className="supplemental-data-block-item">
            <TextLabel>{title}</TextLabel>
            {children ?? <div className="supplemental-data-block-value">{value ?? "-"}</div>}
        </div>
    );
};
