import * as actionTypes from "../actionTypes";
import { debounce, isEmpty } from "lodash";
import { createResource, updateResource, getResource, optimisticUpdateItem } from "../resources/actions";
import { reorder } from "components/utils/array";
import { batch } from "react-redux";
import { refreshApplicationFormsInOpenTabs } from "store/resources/refreshResource";
import { initPage } from "store/formBuilder/actions";

export const getPages =
    ({ programNumber, formNumber }) =>
    (dispatch, getState) => {
        dispatch(
            getResource({
                resourceName: "programFormPages",
                key: `${programNumber}-${formNumber}`,
                path: {
                    programNumber,
                    formNumber,
                },
            })
        );
    };

export const savePage =
    ({ isNew, programNumber, formNumber, schema, uiSchema, rules, initialValues, onSuccess, onError, onComplete }) =>
    (dispatch, getState) => {
        const name = schema && schema.title;

        // Fix separators in page identifier. Now we create form element ids without separators,
        // but some initial configs where created with them.
        let number = uiSchema && uiSchema["af:pageNumber"];
        if (number.indexOf("-") > -1) {
            number = number.split("-").join("");
            uiSchema["af:pageNumber"] = number;
        }

        const configuration = { schema, uiSchema, rules, initialValues };
        const page = { name, number, configuration };

        const saveMethod = isNew ? createResource : updateResource;

        dispatch(
            optimisticUpdateItem({
                resourceName: "programFormPages",
                resourceId: number,
                value: page,
            })
        );

        // Update app form Details. Keys are {appNumber}-{formNumber}
        const applicationFormDetails = getState().resources.applicationFormDetails.itemsById;
        Object.keys(applicationFormDetails ?? {}).forEach((key) => {
            if (key.endsWith(number)) {
                dispatch(
                    optimisticUpdateItem({
                        resourceName: "applicationFormDetails",
                        resourceId: key,
                        value: {
                            ...applicationFormDetails[key],
                            formConfiguration: {
                                schema,
                                uiSchema,
                                rules,
                                initialValues,
                            },
                        },
                    })
                );
            }
        });

        let currentFormNumber = formNumber;
        if (!currentFormNumber) {
            currentFormNumber = getState().resources.programForms.itemsById[programNumber]?.[0].formNumber;
        }

        dispatch(
            saveMethod({
                resourceName: "programFormPages",
                resourceId: number,
                path: {
                    programNumber,
                    formNumber: currentFormNumber,
                },
                body: {
                    configuration: JSON.stringify(page.configuration),
                },
                onSuccess: () => {
                    if (isNew) {
                        dispatch({
                            type: actionTypes.FORM_PAGES_NEW_PAGE_SAVED,
                            pageNumber: number,
                        });
                    }

                    dispatch(
                        getPages({
                            programNumber,
                            formNumber: currentFormNumber,
                        })
                    );

                    dispatch(
                        initPage({
                            schema,
                            uiSchema,
                            rules,
                            instanceId: number,
                        })
                    );

                    onSuccess && onSuccess(page);
                },
                onError: onError,
                onComplete: onComplete,
            })
        );
    };

export const rebuildPage =
    ({ pageNumber, onSuccess, onError }) =>
    (dispatch) => {
        // Fix separators in page identifier. Now we create form element ids without separators,
        // but some initial configs where created with them.
        if (pageNumber.indexOf("-") > -1) {
            pageNumber = pageNumber.split("-").join("");
        }

        dispatch(
            updateResource({
                resourceName: "programFormConfiguration",
                path: { pageNumber },
                onSuccess,
                onError,
            })
        );
    };

export const getPage =
    ({ pageNumber, programNumber, formNumber, refresh, onSuccess }) =>
    (dispatch, getState) => {
        const { programFormPages } = getState().resources;
        const cachedPage = programFormPages.itemsById[pageNumber];

        if (cachedPage && !isEmpty(cachedPage.configuration) && !refresh) {
            onSuccess && onSuccess(cachedPage);
        } else {
            dispatch(
                getResource({
                    resourceName: "programFormPages",
                    resourceId: pageNumber,
                    path: {
                        programNumber,
                        formNumber,
                    },
                    onSuccess: (action) => {
                        onSuccess(action.data);
                    },
                })
            );
        }
    };

export const deletePage =
    ({ pageNumber }) =>
    (dispatch, getState) => {};

export const setActivePage =
    ({ id, page, isNewPage = false }) =>
    (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.FORM_PAGES_SET_ACTIVE_PAGE,
                id,
                page,
            });

            if (isNewPage) {
                dispatch({
                    type: actionTypes.FORM_PAGES_NEW_PAGE_CREATED,
                    id,
                    pageNumber: page.number,
                });
            }
        });
    };

export const updatePageOrder =
    ({ programNumber, formNumber, pageNumber, pageOrder }) =>
    (dispatch, getState) => {
        const key = `${programNumber}-${formNumber}`;
        const pagesList = getState().resources.programFormPages.itemsById[key];

        if (pagesList) {
            const currentIndex = pagesList.findIndex((i) => i.number === pageNumber);

            // Update page order if page is found in the list and index is changed
            if (currentIndex > -1 && currentIndex !== pageOrder) {
                const updatedList = pagesList.slice();

                reorder(updatedList, currentIndex, pageOrder);

                const pages = updatedList.map((item, index) => ({
                    number: item.number,
                    order: index,
                }));

                batch(() => {
                    dispatch(
                        optimisticUpdateItem({
                            resourceName: "programFormPages",
                            resourceId: key,
                            value: updatedList,
                        })
                    );

                    debouncedUpdatePageOrder({
                        programNumber,
                        formNumber,
                        pages,
                        dispatch,
                    });
                });
            }
        }
    };

// Send update request if no updates for at least 1s.
const debouncedUpdatePageOrder = debounce(({ programNumber, formNumber, pages, dispatch }) => {
    dispatch(
        updateResource({
            resourceName: "programFormPageOrder",
            path: {
                programNumber,
                formNumber,
            },
            body: {
                pages,
            },
            onError: () => {
                // Refresh pages to actual state on update error
                dispatch(getPages({ programNumber, formNumber }));
            },
            onSuccess: () => {
                // Refresh forms list in program applications
                refreshApplicationFormsInOpenTabs();
            },
        })
    );
}, 1000);
