import React, { memo } from "react";

import { contractorEquipmentGridColumnKeys } from "../../../../../views/configureGrids";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import IconWithLabel from "../../../../Icons/IconWithLabel";
import DataGrid from "../../../../DataGrid";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./ActivityPanel.scss";

const Equipment = memo(({ contactNumber }) => {
    const gridId = `${contactNumber}-contractor-equipment-grid`;
    const filter = `${contractorEquipmentGridColumnKeys.contactnumber}=${contactNumber}`;

    return (
        <div className="fill-width">
            <h3>Measure Penetration for this Trade Ally</h3>
            {contactNumber ? (
                <Grid applicationNumber={contactNumber} gridId={gridId} filter={filter} />
            ) : (
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="info-empty" title="No Data Available" />
            )}
        </div>
    );
});

const showedColumnsKeys = [contractorEquipmentGridColumnKeys.category, contractorEquipmentGridColumnKeys.quantity];

const Grid = withDataGrid(
    "contractorEquipment",
    memo(({ contactNumber, gridId, dataGridConfig }) => {
        return (
            <>
                <div className="data-grid-controls flex-row justify-end">
                    <IconWithLabel
                        withHandMadeIcon
                        onClick={() =>
                            exportDatagridToCSV({
                                dataGridId: gridId,
                                fileName: "application_contractor_equipment",
                                fileNamePostfix: contactNumber,
                            })
                        }
                    >
                        Export CSV
                    </IconWithLabel>
                </div>
                <DataGrid limit={5} name={gridId} config={dataGridConfig} showedColumnsKeys={showedColumnsKeys} />
            </>
        );
    })
);

export default Equipment;
