import React from "react";
import Tile from "./Tile";

export const PagesList = ({ onWindowOpen }) => {
    const pages = [
        {
            title: "UI Library",
            body: "List of UI elements which will be used through whole system",
            windowTitle: "UI Library",
            windowComponent: "UILibrary",
        },
        {
            title: "Project Page",
            body: "28456F2E2AD54FA0843A5B520404BAAD",
            windowTitle: "A: #28456F2E2AD54FA0843A5B520404BAAD",
            windowComponent: "ProjectView",
            props: {
                applicationNumber: "28456F2E2AD54FA0843A5B520404BAAD",
            },
        },
        {
            title: "Project Page 2",
            body: "0000897328294E7DBFBD43C42A51FA25",
            windowTitle: "A: #0000897328294E7DBFBD43C42A51FA25",
            windowComponent: "ProjectView",
            props: {
                applicationNumber: "0000897328294E7DBFBD43C42A51FA25",
            },
        },
        {
            title: "Project Page 3",
            body: "3FC9D124483D42A1880185B71F5B75DC",
            windowTitle: "A: #3FC9D124483D42A1880185B71F5B75DC",
            windowComponent: "ProjectView",
            props: {
                applicationNumber: "3FC9D124483D42A1880185B71F5B75DC",
            },
        },
        {
            title: "Project CIS",
            body: "2D95884A21F14292BD911B78EF5E0963",
            windowTitle: "A: #2D95884A21F14292BD911B78EF5E0963",
            windowComponent: "ProjectView",
            props: {
                applicationNumber: "2D95884A21F14292BD911B78EF5E0963",
            },
        },
        {
            title: "Utility Page",
            body: "1CDB138B99884D7EAA619B788CBFCFE0",
            windowTitle: "U: #1CDB138B99884D7EAA619B788CBFCFE0",
            windowComponent: "UtilityView",
            props: {
                utilityNumber: "1CDB138B99884D7EAA619B788CBFCFE0",
            },
        },
        {
            title: "Program Page",
            body: "3988F99694124128A6027550B863A934",
            windowTitle: "P: #3988F99694124128A6027550B863A934",
            windowComponent: "ProgramView",
            props: {
                programNumber: "3988F99694124128A6027550B863A934",
            },
        },
    ];

    return pages.map((page) => <Tile key={page.title} {...page} onClick={onWindowOpen} />);
};
