import React, { useCallback, memo } from "react";
import { get } from "lodash";

import { programsContentGridColumnKeys } from "../../../../../views/configureGrids";
import { useResource } from "../../../../../../store/resources/useResource";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import ContentGrid from "./ContentGrid";
import IconWithLabel from "../../../../../ui/Icons/IconWithLabel";
import ContentForm from "./ContentForm";
import WaitIcon from "../../../../WaitIcon";
import { isChildProgram } from "components/views/ProgramView/utils";

const ContentPanel = memo(({ panel, onToggleTempPanel }) => {
    const programNumber = panel.data?.programNumber;

    const gridId = `${programNumber}-content`;

    const [programForms, isLoadingProgramForms] = useResource({
        resourceName: "programForms",
        key: programNumber,
        path: {
            programNumber,
        },
    });

    const formNumber = get(programForms, "[0].formNumber");

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.medium,
        className: "content-add-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(
            <ContentForm gridId={gridId} programNumber={programNumber} formNumber={formNumber} onClose={handleCloseSidePanel} />
        );
    }, [programNumber, formNumber, gridId, handleOpenSidePanel, handleCloseSidePanel]);

    if (isLoadingProgramForms) {
        return <WaitIcon />;
    }

    return (
        <div className="panel program-content-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <div className="data-grid-controls flex-row justify-end">
                <IconWithLabel icon="plus" disabled={isChildProgram({ programNumber })} onClick={handleCreate}>
                    Add New Content
                </IconWithLabel>
                <IconWithLabel
                    withHandMadeIcon
                    onClick={() =>
                        exportDatagridToCSV({
                            dataGridId: gridId,
                            fileName: "program_content",
                            fileNamePostfix: programNumber,
                        })
                    }
                >
                    Export CSV
                </IconWithLabel>
            </div>
            <ContentGrid
                programNumber={programNumber}
                formNumber={formNumber}
                gridId={gridId}
                filter={`${programsContentGridColumnKeys.programNumber}=${programNumber}`}
                onToggleTempPanel={onToggleTempPanel}
            />
        </div>
    );
});

export default ContentPanel;
