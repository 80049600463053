import React from "react";
import { getUiOptions } from "react-jsonschema-form/lib/utils";
import AddButton from "react-jsonschema-form/lib/components/AddButton";
import ObjectProperties from "./ObjectProperties";

const ObjectFieldTemplate = (props) => {
    const canExpand = function canExpand() {
        const { formData, schema, uiSchema } = props;
        if (!schema.additionalProperties) {
            return false;
        }
        const { expandable } = getUiOptions(uiSchema);
        if (expandable === false) {
            return expandable;
        }
        // if ui:options.expandable was not explicitly set to false, we can add
        // another property if we have not exceeded maxProperties yet
        if (schema.maxProperties !== undefined) {
            return Object.keys(formData).length < schema.maxProperties;
        }
        return true;
    };

    const { TitleField, DescriptionField } = props;

    const hasTitle = props.uiSchema["ui:title"] || props.schema["title"];

    return (
        <fieldset className={!hasTitle ? "no-title" : null}>
            {hasTitle && (
                <TitleField
                    id={`${props.idSchema.$id}__title`}
                    title={props.title || props.uiSchema["ui:title"]}
                    required={props.required}
                    formContext={props.formContext}
                />
            )}
            {props.description && (
                <DescriptionField
                    id={`${props.idSchema.$id}__description`}
                    description={props.description}
                    formContext={props.formContext}
                />
            )}
            <ObjectProperties properties={props.properties} />
            {canExpand() && (
                <AddButton
                    className="object-property-expand"
                    onClick={props.onAddClick(props.schema)}
                    disabled={props.disabled || props.readonly}
                />
            )}
        </fieldset>
    );
};

export default ObjectFieldTemplate;
