import IconWithLabel from "components/ui/Icons/IconWithLabel";
import NothingFoundBlock from "components/ui/NothingFoundBlock";
import TilesWrap from "components/ui/TilesWrap";
import PortalsTileItem from "components/ui/Dashboard/Panel/Utility/ConfigPanel/PortalsManagementPanel/PortalsTileItem";
import React, { memo, useEffect, useRef } from "react";

const TemplateList = memo(({ templates, onOpen, onRemove, onClone, hidden }) => {
    const templatesLengthRef = useRef(templates.length);

    useEffect(() => {
        if (templatesLengthRef.current === templates.length - 1) {
            const element = document.getElementsByClassName("portal-template-tile--new")[0];
            if (element) {
                setTimeout(() => {
                    element &&
                        element.scrollIntoView({
                            behavior: "smooth",
                        });
                }, 100);
            }
        }
        templatesLengthRef.current = templates.length;
    }, [templates.length]);

    if (templates.length === 0) {
        return (
            <NothingFoundBlock
                hidden={hidden}
                nothingFoundBlockMargin
                icon="domain-empty"
                title="No templates. Portals not configured yet."
            >
                <IconWithLabel icon={"plus"} onClick={onOpen()}>
                    Add Template
                </IconWithLabel>
            </NothingFoundBlock>
        );
    }

    return (
        <TilesWrap hidden={hidden} justify="start">
            {templates.map((template, index) => (
                <PortalsTileItem key={index} template={template} onOpen={onOpen} onRemove={onRemove} onClone={onClone}></PortalsTileItem>
            ))}
            <PortalsTileItem isNew onOpen={onOpen} />
        </TilesWrap>
    );
});

export default TemplateList;
