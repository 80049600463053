import React from "react";

const TabContent = ({ view }) => {
    const title = view.title || view.name;

    return (
        <div className="tab-content">
            <div className="tab-title">{title}</div>
        </div>
    );
};

export default TabContent;
