import React, { memo } from "react";
import { toast } from "react-toastify";

import ActionLabel from "../../../../Label/ActionLabel";
import IconWrap from "../../../../Icons";
import { copyToClipboard } from "../../../../../utils/string";
import CustomList, { renderCustomFooter } from "../../../../List/CustomList";

const EquipmentList = memo(({ items, onSelect }) => {
    const headers = {
        equipmentName: "EQUIPMENT NAME / REF.ID",
        count: "#",
        incent: "INCENT",
    };

    const onCopyToClipboard = (text) => {
        copyToClipboard(text, () => {
            toast.success("Copied to clipboard");
        });
    };

    const renderHeader = (headers) => (
        <>
            <div className="header equipment-name">{headers.equipmentName}</div>
            <div className="header equipment-count">{headers.count}</div>
            <div className="header equipment-incent">{headers.incent}</div>
        </>
    );

    const renderItem = (item, index) => {
        return (
            <div key={index} className="list-item-row">
                <div className="item-value equipment-name flex-row align-center">
                    <ActionLabel className="text-overflow" onClick={() => onSelect(item)}>
                        {item.name}
                    </ActionLabel>
                    <IconWrap icon="layers-empty" title="Copy REFID to clipboard" onClick={() => onCopyToClipboard(item.refid)} />
                </div>
                <div className="item-value equipment-count">{item.quantity}</div>
                <div className="item-value equipment-incent flex-row align-center">
                    <span className="flex-one">{item.incentive}</span>
                    <IconWrap icon="shevron-small-right" onClick={() => onSelect(item)} />
                </div>
            </div>
        );
    };

    return (
        <div className="equipment-list sidebar-list flex-column">
            <div className="sidebar-list-body flex-column">
                <CustomList
                    limit={6}
                    items={items}
                    headers={headers}
                    searchFilters={{
                        name: "",
                    }}
                    searchPlaceholder="Search equipment"
                    renderHeaders={renderHeader}
                    renderItem={renderItem}
                    renderFooter={renderCustomFooter}
                />
            </div>
        </div>
    );
});

export default EquipmentList;
