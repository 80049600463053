import React, { useEffect } from "react";
import { getAccessibilityErrors, getAccessibilityNotices, getAccessibilityWarnings } from "components/ui/PortalBuilder/utils/validation";
import { AccessibilityMessage } from "./AccessibilityMessage";
import { SectionHeader } from "../SectionHeader";
import { SECTION_ACCESSIBILITY } from "../Section";
import { AccessibilityMessages, ChildrenProps, PageAccessibilityInfo } from "./index";
import ButtonGroup from "components/ui/Button/ButtonGroup";
import Button from "components/ui/Button";
import WaitIcon from "components/ui/WaitIcon";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { changePortalPage } from "../../utils/PortalMessenger";

export const PageDetails = ({ page, isValidating, onClose }: PageDetailsProps) => {
    const portalRef = useErrorContext()?.portalRef;

    const errors = getAccessibilityErrors(page.messages);
    const warnings = getAccessibilityWarnings(page.messages);
    const notices = getAccessibilityNotices(page.messages);

    // Navigate to page
    useEffect(() => {
        if (portalRef) {
            changePortalPage(page.url, portalRef);
        }
    }, [page.url, portalRef]);

    if (isValidating) {
        return (
            <PageDetailsContainer>
                <PageDetailsHeader page={page} onClose={onClose} />
                <WaitIcon />
            </PageDetailsContainer>
        );
    }

    return (
        <PageDetailsContainer>
            <PageDetailsHeader page={page} onClose={onClose} />
            <ButtonGroup transparent className="accessibility-controls flex-row">
                <Button className="flex-one" onClick={() => page.onValidate?.(true)}>
                    Validate Page
                </Button>
            </ButtonGroup>
            <AccessibilityMessages>
                <>
                    {errors.map((message, index) => (
                        <AccessibilityMessage key={index} message={message} />
                    ))}
                    {warnings.map((message, index) => (
                        <AccessibilityMessage key={index} message={message} />
                    ))}
                    {notices.map((message, index) => (
                        <AccessibilityMessage key={index} message={message} />
                    ))}
                </>
            </AccessibilityMessages>
        </PageDetailsContainer>
    );
};

const PageDetailsContainer = ({ children }: ChildrenProps) => (
    <div className="page-accessibility-details flex-column flex-one-in-column with-scroll">{children}</div>
);

const PageDetailsHeader = ({ page, onClose }: PageDetailsHeaderProps) => {
    return (
        <SectionHeader
            subtitle={
                <span>
                    PAGE <b className="page-title">{page.title}</b> DETAILS
                </span>
            }
            onGoBack={onClose}
            Icon={SECTION_ACCESSIBILITY.Icon}
            title={SECTION_ACCESSIBILITY.title}
            underline
        />
    );
};

interface PageDetailsHeaderProps {
    page: PageAccessibilityInfo;
    onClose: () => void;
}

interface PageDetailsProps extends PageDetailsHeaderProps {
    isValidating: boolean;
}
