import React, { memo, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { isNil } from "lodash";

import { setWorkqueueDataSourceFilterExpanded } from "../../../store/workcenter/actions";
import ProgramFilters from "./ProgramFilters";
import IconWithLabel from "../../ui/Icons/IconWithLabel";
import { WorkCenterContext } from ".";

const DataSourceFilterDetails = memo(() => {
    const { instanceId } = useContext(WorkCenterContext);

    const dispatch = useDispatch();
    const activeDataSource = useSelector((state) => state.workcenter[instanceId].activeDataSource);
    const dataSourceFilterExpanded = useSelector((state) => state.workcenter[instanceId].dataSourceFilterExpanded);
    const isMobile = useSelector((state) => state.window?.isMobile);
    const isTablet = useSelector((state) => state.window?.isTablet);

    const onExpand = useCallback(() => {
        dispatch(
            setWorkqueueDataSourceFilterExpanded({
                instanceId,
                dataSourceFilterExpanded: !dataSourceFilterExpanded,
            })
        );
    }, [instanceId, dataSourceFilterExpanded, dispatch]);

    if (!activeDataSource) {
        return null;
    }

    return (
        <div
            className={cn("filter-expandable", {
                expanded: dataSourceFilterExpanded,
                "is-new": activeDataSource.datasourceNumber === "0",
            })}
        >
            <div className="filter-first-block fill-height">
                <div className="saved-filters-content fill-height" key={activeDataSource.datasourceNumber}>
                    <ProgramFilters activeDataSource={activeDataSource} />
                </div>
            </div>
            {!isNil(activeDataSource.datasourceNumber) && activeDataSource.datasourceNumber !== "0" && (isMobile || isTablet) && (
                <IconWithLabel icon="shevron-in-circle-right-empty" onClick={onExpand}>
                    Show Selected Filter Details
                </IconWithLabel>
            )}
        </div>
    );
});

export default DataSourceFilterDetails;
