import * as actionTypes from "../actionTypes";

/**
 * key [active-{programNumber}] Active page in specific program
 * key [new-{programNumber}] List of new unsaved page numbers in specific program
 */
const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FORM_PAGES_SET_ACTIVE_PAGE:
            state = {
                ...state,
                [`active-${action.id}`]: action.page,
            };
            break;
        case actionTypes.FORM_PAGES_NEW_PAGE_CREATED:
            state = {
                ...state,
                [`new-${action.id}`]: [...(state[`new-${action.id}`] ?? []), action.pageNumber],
            };
            break;
        case actionTypes.FORM_PAGES_NEW_PAGE_SAVED:
            state = removeNewPage(state, action);
            break;
        case actionTypes.FORM_PAGES_NEW_PAGE_DELETED:
            state = removeNewPage(state, action);
            state = removeActiveNewPage(state, action);
            break;
        default:
            break;
    }

    return state;
};

const removeNewPage = (state, action) => {
    const key = Object.keys(state).find((key) => key.startsWith("new-") && (state[key] ?? []).includes(action.pageNumber));

    if (key) {
        state = {
            ...state,
            [key]: (state[key] ?? []).filter((pageNumber) => pageNumber !== action.pageNumber),
        };
    }

    return state;
};

const removeActiveNewPage = (state, action) => {
    const key = Object.keys(state).find((key) => key.startsWith("active-") && state[key]?.number === action.pageNumber);

    if (key) {
        state = {
            ...state,
            [key]: undefined,
        };
    }

    return state;
};
