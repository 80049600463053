import { AppLocationsMap } from "components/views/PortalAnalytics/sections/FormInsights/AppLocationsMap";
import { isEmpty } from "lodash";
import React from "react";

import NothingFoundBlock from "../../../../NothingFoundBlock";

import "./LocationView.scss";

const LocationView = ({ appZipCodes }) => {
    if (isEmpty(appZipCodes)) {
        return (
            <div className="activity-tab location-block no-info">
                <NothingFoundBlock nothingFoundBlockSmall nothingFoundBlockMargin icon="location" title="No Data Available" />
            </div>
        );
    }

    return (
        <div className="activity-tab location-view">
            <div className="flex-column fill-width map">
                <AppLocationsMap zipCodes={appZipCodes} />
            </div>
        </div>
    );
};

export default LocationView;
