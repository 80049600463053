import SelectBox from "components/ui/SelectBox";
import React, { memo, useContext, useEffect } from "react";

import { PortalBuilderPropertiesContext } from "../../contexts";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";
import { useDefaultValue } from "../../utils";
import { isPropertyRequired } from "../../utils/validation";
import { SECTION_CONTENT, SECTION_SETTINGS, SECTION_WIDGETS } from "../Section";
import { WidgetInfoBlock } from "../WidgetInfoBlock";
import { PropertyListItem } from "./PropertyListItem";
import { PropertyTitle } from "./PropertyTitle";

import "./TextsStyleProperty.scss";

export const TextsStylesProperty = memo((props) => {
    const { id, value, onChange, title, borderTop, borderBottom, validationRules } = props;
    const required = isPropertyRequired(validationRules);
    const [isProgramPortalBuilder, setPortalBuilderState] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const [editingPageIndex] = usePortalBuilderState((state) => state.editingPageIndex);
    const { activeSection, setActiveSection } = useErrorContext();
    const { selectedColumn, selectedColumnRow, setLastSelectedColumn, setLastSelectedColumnRow } =
        useContext(PortalBuilderPropertiesContext);

    useDefaultValue(id, value, "default", onChange);

    useEffect(() => {
        if (!isProgramPortalBuilder) {
            setPortalBuilderState({ lastEditingPageIndex: undefined, lastActiveSection: undefined });
            setLastSelectedColumn(null);
            setLastSelectedColumnRow(null);
        }
    }, [setLastSelectedColumn, setLastSelectedColumnRow, isProgramPortalBuilder, setPortalBuilderState]);

    const items = [
        {
            text: "Default",
            value: "default",
            tooltip: "Styles set in Widgets Section and affects all Portal places where the widget is included",
        },
    ];

    const onSectionChange = (section) => () => {
        if (activeSection === SECTION_CONTENT) {
            setPortalBuilderState({ lastEditingPageIndex: editingPageIndex });
        }
        setPortalBuilderState({ lastActiveSection: activeSection });
        setLastSelectedColumn(selectedColumn);
        setLastSelectedColumnRow(selectedColumnRow);
        setActiveSection(section);
    };

    return (
        <PropertyListItem className="property-list-item--select-box" borderTop={borderTop} borderBottom={borderBottom}>
            <div className="property-list-item-inner flex-row align-center justify-space-between">
                <PropertyTitle title={title} required={required} />
                <div className="flex-row">
                    {items.map((i, index) => {
                        return (
                            <SelectBox
                                key={index}
                                selectBoxTitle={i.tooltip}
                                selectBoxText={i.text}
                                icon={i.icon}
                                selectBoxSelected={i.value === value}
                                onClick={!i.blocked ? () => onChange(id, i.value) : undefined}
                                blocked={i.blocked}
                            />
                        );
                    })}
                </div>
            </div>
            <WidgetInfoBlock>
                <p>
                    <strong> Default </strong> Texts styles are applied without a possibility to change them from here.
                </p>
                <p>
                    If you would like to adjust <strong> default </strong> styles, please go to&nbsp;
                    <span className="link-to-default-widget-styles" onClick={onSectionChange(SECTION_WIDGETS)}>
                        {" "}
                        Widgets Section
                    </span>{" "}
                    or to&nbsp;
                    <span className="link-to-default-widget-styles" onClick={onSectionChange(SECTION_SETTINGS)}>
                        {"Setting Section -> Typography"}
                    </span>
                </p>
            </WidgetInfoBlock>
        </PropertyListItem>
    );
});
