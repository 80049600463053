import React, { useCallback, useMemo, useContext, memo } from "react";

import DropDownInput from "../../../../../../Input/DropDownInput";
import { WorkflowContext } from "../../../../../context/WorkflowContext";
import useFormPages from "components/ui/Workflow/utils/hooks/useFormPages";
import { useResource } from "store/resources/useResource";
import { STEP_TYPES_ATTRIBUTES } from "components/ui/Workflow/constants/step-types";

export const ActivateFormPageField = memo(() => {
    const { entityId, onChangeWorkflowStepProperties, activeItem, workflowStatuses, stepAttributeTypesGetResourceOptions, isLocked } =
        useContext(WorkflowContext);
    const [pages, isLoadingFormPages] = useFormPages({ entityId });
    const [workflowStepAttributeTypes = [], isLoadingWorkflowStepAttributeTypes] = useResource(stepAttributeTypesGetResourceOptions);

    const isLoading = isLoadingFormPages || isLoadingWorkflowStepAttributeTypes;

    const label = "Form Page";

    const value = useMemo(() => {
        const { statusNumber, stepNumber } = activeItem;

        return workflowStatuses
            .filter((status) => status.number === statusNumber)
            .map((status) => status.steps)
            .reduce((acc, val) => acc.concat(val), [])
            .filter((step) => step.number === stepNumber)
            .map((step) => step.attributes[0]?.value)[0];
    }, [activeItem, workflowStatuses]);

    const handleChange = useCallback(
        (e) => {
            const attribute = workflowStepAttributeTypes.find((i) => i.code === STEP_TYPES_ATTRIBUTES.FORM_PAGE_NUMBER);

            onChangeWorkflowStepProperties({
                attributes: [
                    {
                        typeNumber: attribute.number,
                        name: attribute.name,
                        value: e.target.value || "",
                    },
                ],
            });
        },
        [workflowStepAttributeTypes, onChangeWorkflowStepProperties]
    );

    return (
        <DropDownInput
            className="workflow-step-types-widget__attribute-field"
            value={isLoading ? undefined : value}
            label={label}
            onChange={handleChange}
            readOnly={isLocked}
            data={pages.map((item) => ({
                label: item.name,
                value: item.number,
            }))}
            placeholder={isLoading ? "Loading..." : label}
            disabled={isLoading}
            mobileHeader={label}
        />
    );
});
