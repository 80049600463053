import React, { memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import cn from "classnames";
import { toArray } from "../../utils/array";

import WaitIcon from "../../ui/WaitIcon";
import ViewPlaceholder from "../../ui/ViewPlaceholder";
import SearchResultsCounter from "../../ui/GlobalSearch/SearchResultsCounter";
import SearchResultsEmpty from "../../ui/GlobalSearch/SearchResultsEmpty";
import { SearchResultList } from "../../ui/GlobalSearch/SearchResultList";
import SearchResultsPaging from "./SearchResultsPaging";
import {
    hasCriteriaForSearch,
    isCisSearch,
    getCisSearchHighlightWords,
    useOpenSearchResult,
    getSecondarySearchInstanceId,
    getPagingItems,
    getHighlightWords,
} from "../../../store/globalSearch/utils";
import { defaultPageNumber, defaultPageSize } from "../../../store/globalSearch/utils";
import { ExpandableSearchResult } from "components/ui/GlobalSearch/ExpandableSearchResult";

const GlobalSearchResults = memo(({ instanceId }) => {
    const criteriaList = useSelector((state) => get(state, `globalSearch.criteriaList[${instanceId}]`)) || [];
    const showPlaceholder = criteriaList.length === 0;
    const isCisSearchResult = isCisSearch({ criteriaList });

    if (showPlaceholder) {
        return (
            <ViewPlaceholder viewPlaceholderSmall>
                Please specify <br /> search criteria or a keyword <br /> to get search results
            </ViewPlaceholder>
        );
    }

    return (
        <div className="search-results-wrap flex-one no-scroll">
            <div className="search-results flex-column fill-height">
                {isCisSearchResult ? (
                    <CisSearchResults instanceId={instanceId} criteriaList={criteriaList} />
                ) : (
                    <SearchResults instanceId={instanceId} criteriaList={criteriaList} />
                )}
            </div>
        </div>
    );
});

const SearchResults = memo(({ instanceId, criteriaList }) => {
    const isSearching = useSelector((state) => get(state, `globalSearch.isSearching[${instanceId}]`));
    const results = useSelector((state) => get(state, `resources.search.itemsById.${instanceId}`));
    const pageNumber = useSelector((state) => get(state, `globalSearch.pageNumber[${instanceId}]`)) || defaultPageNumber;
    const pageSize = useSelector((state) => get(state, `globalSearch.pageSize[${instanceId}]`)) || defaultPageSize;

    const totalResults = Number(get(results, "RETURN.TOTAL_RESULT_COUNT", 0));
    const searchResults = toArray(get(results, "RETURN.TABLE", []));
    const words = getHighlightWords({ criteriaList });

    const hasCriteria = hasCriteriaForSearch({ criteriaList });
    const isLoading = isSearching && hasCriteria;

    useOpenSearchResult({
        isSearching,
        totalResults,
        criteriaList,
        searchResults,
    });

    const { firstRecord, lastRecord } = getPagingItems({
        pageNumber,
        pageSize,
        totalResults,
    });

    return totalResults > 0 || isLoading ? (
        <>
            {totalResults > 0 ? (
                <div className="flex-row justify-space-between">
                    <SearchResultsCounter totalResults={totalResults} alignCenter />
                    <PagingInfo pageNumber={pageNumber} totalResults={totalResults} firstRecord={firstRecord} lastRecord={lastRecord} />
                </div>
            ) : null}
            <div className="search-results-container flex-row flex-one-in-column no-scroll">
                {isLoading ? (
                    <div className="flex-column flex-one-in-row no-scroll">
                        <WaitIcon />
                    </div>
                ) : (
                    <SearchResultList
                        className="flex-column flex-one-in-row with-scroll"
                        criteriaList={criteriaList}
                        searchResults={searchResults}
                        words={words}
                    />
                )}

                <SearchResultsPaging instanceId={instanceId} totalResults={totalResults} />
            </div>
        </>
    ) : (
        <SearchResultsEmpty instanceId={instanceId} criteriaList={criteriaList} />
    );
});

const CisSearchResults = memo(({ instanceId, criteriaList }) => {
    const isCisSearchInProgress = useSelector((state) => get(state, `globalSearch.isSearching[${instanceId}]`));
    const cisSearch = useSelector((state) => state.resources.search.itemsById[instanceId]);
    const cisSearchPageNumber = useSelector((state) => get(state, `globalSearch.pageNumber[${instanceId}]`)) || defaultPageNumber;
    const cisSearchPageSize = useSelector((state) => get(state, `globalSearch.pageSize[${instanceId}]`)) || defaultPageSize;

    const cisSearchHighlightWords = getCisSearchHighlightWords({
        criteriaList,
    });
    const cisSearchResults = toArray(get(cisSearch, "RETURN.TABLE", []));
    const totalCisSearchResults = Number(get(cisSearch, "RETURN.TOTAL_RESULT_COUNT", 0));

    const secondarySearchInstanceId = getSecondarySearchInstanceId(instanceId);
    const isSecondarySearchInProgress = useSelector((state) => state.globalSearch.isSearching[secondarySearchInstanceId]);
    const secondarySearch = useSelector((state) => state.resources.search.itemsById[secondarySearchInstanceId]);
    const secondarySearchPageNumber =
        useSelector((state) => get(state, `globalSearch.pageNumber[${secondarySearchInstanceId}]`)) || defaultPageNumber;
    const secondarySearchPageSize =
        useSelector((state) => get(state, `globalSearch.pageSize[${secondarySearchInstanceId}]`)) || defaultPageSize;

    const secondarySearchHighlightWords = getHighlightWords({ criteriaList });
    const secondarySearchResults = toArray(get(secondarySearch, "RETURN.TABLE", []));
    const totalSecondarySearchResults = Number(get(secondarySearch, "RETURN.TOTAL_RESULT_COUNT", 0));

    if (isCisSearchInProgress && isSecondarySearchInProgress) {
        return <WaitIcon />;
    }

    return (
        <>
            <ExpandableSearchResult
                expanded
                className="flex-row align-center"
                title="CIS search results"
                totalResults={totalCisSearchResults}
                pageNumber={cisSearchPageNumber}
                pageSize={cisSearchPageSize}
            >
                {totalCisSearchResults > 0 ? (
                    <div className={cn("search-results-container flex-one-in-column flex-row no-scroll")}>
                        {isCisSearchInProgress ? (
                            <WaitIcon className="flex-one" />
                        ) : (
                            <SearchResultList
                                className="flex-column flex-one-in-row with-scroll"
                                searchResults={cisSearchResults}
                                words={cisSearchHighlightWords}
                                criteriaList={criteriaList}
                            />
                        )}
                        <SearchResultsPaging instanceId={instanceId} totalResults={totalCisSearchResults} />
                    </div>
                ) : isCisSearchInProgress ? (
                    <WaitIcon className="flex-one" />
                ) : (
                    <SearchResultsEmpty instanceId={instanceId} criteriaList={criteriaList} />
                )}
            </ExpandableSearchResult>
            <ExpandableSearchResult
                expanded
                className="flex-row align-center"
                title="Other search results"
                totalResults={totalSecondarySearchResults}
                pageNumber={secondarySearchPageNumber}
                pageSize={secondarySearchPageSize}
            >
                {totalSecondarySearchResults > 0 ? (
                    <div className={cn("search-results-container flex-one-in-column flex-row no-scroll")}>
                        {isSecondarySearchInProgress ? (
                            <WaitIcon className="flex-one" />
                        ) : (
                            <SearchResultList
                                className="flex-column flex-one-in-row with-scroll"
                                searchResults={secondarySearchResults}
                                words={secondarySearchHighlightWords}
                                criteriaList={criteriaList}
                                isCisSecondarySearch
                            />
                        )}
                        <SearchResultsPaging instanceId={secondarySearchInstanceId} totalResults={totalSecondarySearchResults} />
                    </div>
                ) : isSecondarySearchInProgress ? (
                    <WaitIcon className="flex-one" />
                ) : (
                    <SearchResultsEmpty instanceId={secondarySearchInstanceId} criteriaList={criteriaList} />
                )}
            </ExpandableSearchResult>
        </>
    );
});

const PagingInfo = ({ pageNumber, firstRecord, lastRecord, totalResults }) => {
    return (
        <div className="paging-info flex-row align-center">
            <div className="paging-info-counter">
                <b>{`${firstRecord} - ${lastRecord}`}</b> items of <b>{`${totalResults}`}</b>
            </div>
            <div className="paging-info-page flex-row align-center">
                <div className="paging-info-page-label">
                    current <br />
                    page
                </div>
                <div className="paging-info-page-value">{pageNumber}</div>
            </div>
        </div>
    );
};

export default GlobalSearchResults;
