import React from "react";
import ClassNames from "classnames";
import { isNil } from "lodash";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

/**
 * ActionLabel component represents a clickable label.
 * @param {Object} props - The component props.
 * @param {string} [props.className] - The CSS class name for the component.
 * @param {string} [props.title] - The title attribute for the component.
 * @param {ReactNode} props.children - The content of the component.
 * @param {function} props.onClick - The click event handler for the component.
 * @returns {JSX.Element} The ActionLabel component.
 */
const ActionLabel = ({ className, title, children, onClick }) => {
    const classNames = ClassNames("action-label", className);

    const isTabable = !isNil(onClick);
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({ disabled: !isTabable, onClick });

    return (
        <span
            className={classNames}
            tabIndex={isTabable ? "0" : "-1"}
            title={title}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onEnter}
            onClick={onClick}
        >
            {children}
        </span>
    );
};

export default ActionLabel;
