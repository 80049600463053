import React, { useContext } from "react";
import { MarginPropertyContext } from "components/ui/PortalBuilder/contexts";
import { MarginCustomPropertyName, MarginSides } from "./types";
import { MarginBoxSize, PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { MarginBox } from "./MarginBox";

import { PropertyListItem } from "../PropertyListItem";

export const AllSidesProperty = (props: SizeAllSidesPropertyProps) => {
    const { value, borderBottom, borderTop, onChange } = props;
    const { customPropertiesValue } = useContext(MarginPropertyContext);

    const onSelect = (id: PropertyName, size: string | null) => {
        if (value === size) {
            onChange(id, "0rem");
        } else {
            onChange(id, size);
        }
    };

    if (customPropertiesValue[MarginCustomPropertyName.MarginType] !== MarginSides.AllSides) {
        return null;
    }

    return (
        <PropertyListItem
            className="property-list-item--margin-size property-list-item--margin-size-all-sides flex-row"
            innerContent
            borderBottom={borderBottom}
            borderTop={borderTop}
        >
            {MARGIN_SIZES.map((size, index) => (
                <MarginBox
                    allSides
                    key={index}
                    text={size.text}
                    tooltip={size.tooltip}
                    isSelected={value === size.value}
                    onClick={() => onSelect(PropertyName.Margin, size.value)}
                />
            ))}
        </PropertyListItem>
    );
};

interface SizeAllSidesPropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value?: string;
}

export const MARGIN_SIZES = [
    {
        text: "XS",
        tooltip: "extra small",
        value: MarginBoxSize.XS,
    },
    {
        text: "SM",
        tooltip: "small",
        value: MarginBoxSize.SM,
    },
    {
        text: "MD",
        tooltip: "medium",
        value: MarginBoxSize.MD,
    },
    {
        text: "LG",
        tooltip: "large",
        value: MarginBoxSize.LG,
    },
    {
        text: "XL",
        tooltip: "extra large",
        value: MarginBoxSize.XL,
    },
    {
        text: "XXL",
        tooltip: "twice extra large",
        value: MarginBoxSize.XXL,
    },
];
