import React, { useRef, useState, useCallback, memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import { createResource, updateResource } from "../../../../../../store/resources/actions";
import { getContractResourceParams } from "../../../../../../store/configureResources";
import { useContract } from "../../../../../../store/resources/useResource";
import { regexPatterns, submitByRef } from "../../../../../utils/form";

import Button from "../../../../Button";
import SideNavBody from "../../../../SideNav/SideNavBody";
import SideNavFooter from "../../../../SideNav/SideNavFooter";
import SideNavHeader from "../../../../SideNav/SideNavHeader";
import SideNavContent from "../../../../SideNav/SideNavContent";
import JsonSchemaForm from "components/ui/Form/JsonSchema/JsonSchemaForm";

const Form = memo(({ utilityNumber, contractNumber, onSaved, onClose, sidePanel }) => {
    const dispatch = useDispatch();
    const formRef = useRef();

    const isNew = !contractNumber;
    const titleText = isNew ? "Add Contract" : "Edit Contract";
    const titleIcon = isNew ? "plus" : "edit-empty";

    const [contract, isLoadingContract] = useContract({
        utilityNumber,
        contractNumber,
        forced: true,
    });
    const [isSubmitting, setSubmitting] = useState(false);

    const submitText = isSubmitting ? "Saving..." : "Save Contract";

    const onSubmit = useCallback(
        (formData) => {
            const resourceParams = getContractResourceParams({
                utilityNumber,
                contractNumber,
            });

            const body = {
                ...formData,
            };

            if (isNew) {
                dispatch(
                    createResource({
                        ...resourceParams,
                        body,
                        onSuccess: () => {
                            onSaved();
                            sidePanel.close();
                        },
                        setSubmitting,
                    })
                );
            } else {
                dispatch(
                    updateResource({
                        ...resourceParams,
                        resourceId: contractNumber,
                        body,
                        onSuccess: () => {
                            onSaved();
                            sidePanel.close();
                        },
                        setSubmitting,
                    })
                );
            }
        },
        [utilityNumber, contractNumber, isNew, onSaved, sidePanel, dispatch]
    );

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm(formRef);
    }, [sidePanel]);

    return (
        <SideNavContent className="contract-edit-form">
            <SideNavHeader title={titleText} leadBlockIcon={titleIcon} smallHeader onClose={onClose}></SideNavHeader>
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={contractFormSchema}
                    uiSchema={contractFormUiSchema}
                    initialValues={contract}
                    transformErrors={transformFormErrors}
                    disabled={isSubmitting || isLoadingContract}
                    onSubmit={onSubmit}
                    submitText={submitText}
                    noReset
                    noSubmit
                    noCancel
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button primary disabled={isSubmitting || isLoadingContract} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
});

const transformFormErrors = (errors) => {
    return errors.map((error) => {
        if (error.name === "pattern") {
            error.message = "Invalid Contract Description";
        }

        return error;
    });
};

const contractFormSchema = {
    type: "object",
    required: ["contractDesc"],
    properties: {
        contractDesc: {
            type: "string",
            title: "Contract Description",
            pattern: regexPatterns.nonEmptyString,
        },
        note: {
            type: ["string", "null"],
            title: "Note",
        },
    },
};

const contractFormUiSchema = {
    note: {
        classNames: "fill-width",
        "ui:widget": "textarea",
    },
};

export default Form;
