import React, { useEffect, useCallback, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as loginActions from "store/login/actions";
import { clientRoute, clientHost } from "../../utils/constants";
import { isInIframe } from "../../utils/iframe";
import { isAuthenticated, isStatusExpired, isQrCode, isSuspended } from "../../utils/user";
import { setSettingsTheme, DEFAULT_THEME_SIGHTLINE } from "../../utils/settings";

import LoginForm from "./LoginForm";

const Login = memo(({ onWindowOpen }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const login = useSelector((state) => state.login);

    const [isThemeSet, setIsThemeSet] = useState(false);

    // Redirect top window to login page.
    if (!isAuthenticated(user) && isInIframe()) {
        window.top.location.replace(window.location.href);
    }

    useEffect(() => {
        const pathParts = window.location.pathname.split("/");

        if (!isThemeSet && window.location.hostname.startsWith(clientHost.sightLine)) {
            setIsThemeSet(true);
            setSettingsTheme(DEFAULT_THEME_SIGHTLINE);
        }

        if (pathParts[1] === clientRoute.resetPassword) {
            onWindowOpen({
                name: "Reset Password",
                component: "ResetPassword",
                showTab: false,
                showHeader: false,
            });
        } else if (pathParts[1] === clientRoute.saml) {
            onWindowOpen({
                name: "Saml",
                component: "SamlView",
                showTab: false,
                showHeader: false,
            });
        } else if (isQrCode(user)) {
            onWindowOpen({
                name: "QR Code",
                component: "QrCodeForm",
                showTab: false,
                showHeader: false,
            });
        } else if (isStatusExpired(user)) {
            onWindowOpen({
                name: "Change Password",
                component: "ChangePassword",
                showTab: false,
                showHeader: false,
            });
        } else if (isAuthenticated(user)) {
            onWindowOpen({
                name: "Home",
                component: "HomeView",
                showHeader: false,
                close: false,
            });
        }
    }, [user, isThemeSet, onWindowOpen]);

    const handleForgotPassword = useCallback(
        (userName) => {
            onWindowOpen({
                name: "Forgot Password",
                component: "ForgotPassword",
                showTab: false,
                showHeader: false,
                props: {
                    userName,
                },
            });
        },
        [onWindowOpen]
    );

    const handleLogin = useCallback(
        (values) => {
            dispatch(loginActions.login(values));
        },
        [dispatch]
    );

    return (
        <LoginForm
            isLoading={login.isLoading}
            isSuspended={isSuspended(user)}
            twoFactorRequired={login.twoFactorRequired}
            twoFactorQrCodeExists={login.twoFactorQrCodeExists}
            onLogin={handleLogin}
            onForgotPassword={handleForgotPassword}
            errorMessage={login.errorMessage}
        />
    );
});

export default Login;
