import React, { useEffect, useCallback, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { construct, getData } from "../../../../../../store/dataGrid/actions";
import { availableGrids, documentsGridColumnKeys } from "../../../../../views/configureGrids";
import { searchAttrToFilter } from "../../../../../utils/datagrid";
import { getGridId } from "./utils";
import { exportPdf, getValidFileName } from "components/utils/files";
import { stripHtmlForDataGrid } from "components/utils/string";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import DocumentsEditForm from "./DocumentsEditForm";
import IconWrap from "../../../../Icons";

import "./DocumentsPanel.scss";

const DocumentsGrid = memo(({ applicationNumber, isLocked, programNumber }) => {
    const dataGridId = getGridId({ applicationNumber, type: "correspondence" });

    const dispatch = useDispatch();
    const dataGridConfig = useSelector((state) => state.dataGrid[dataGridId]);

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers();
    const [downloadingRowId, setDownloadingRowId] = useState(-1);

    const rowActions = [
        {
            name: "view",
            icon: "eye-visibility-empty",
            title: "View Correspondence",
        },
        {
            name: "download",
            icon: (row, index) => (index === downloadingRowId ? "waiting" : "download"),
            title: "Download",
        },
    ];

    useEffect(() => {
        dispatch(
            construct({
                dataGridId: availableGrids.documents,
                dataGridInstanceId: dataGridId,
                filter: searchAttrToFilter(`${documentsGridColumnKeys.appId}=${applicationNumber}`),
                sort: [
                    {
                        field: documentsGridColumnKeys.date,
                        dir: "desc",
                    },
                ],
                data: true,
            })
        );
    }, [applicationNumber, dataGridId, dispatch]);

    const handleGridRefresh = useCallback(() => {
        dispatch(getData({ dataGridId }));
    }, [dataGridId, dispatch]);

    const onRowAction = useCallback(
        (action) => {
            const { dataItem, dataIndex } = action;

            const item = Object.keys(documentsGridColumnKeys).reduce((item, key) => {
                return {
                    ...item,
                    [key]: dataItem[documentsGridColumnKeys[key]],
                };
            }, {});

            switch (action.name) {
                case "view":
                    handleOpenSidePanel(
                        <DocumentsEditForm
                            dataItem={dataItem}
                            isLocked={isLocked}
                            gridRefresh={handleGridRefresh}
                            onClose={handleCloseSidePanel}
                            programNumber={programNumber}
                        />
                    );
                    break;

                case "download":
                    setDownloadingRowId(dataIndex);
                    const fileName = getValidFileName({
                        fileName: item.item,
                        fileExtension: "pdf",
                    });
                    const fileContent = item.content;

                    exportPdf({
                        fileName,
                        fileContent,
                        onComplete: () => {
                            setDownloadingRowId(-1);
                        },
                    });

                    break;

                default:
                    break;
            }
        },
        [isLocked, handleGridRefresh, handleOpenSidePanel, handleCloseSidePanel, programNumber]
    );

    const getTypeIcon = useCallback((type) => {
        switch (type) {
            case "email":
                return "email-empty";

            case "letter":
                return "mailbox-empty";

            case "text message":
                return "text-wrap";

            default:
                return null;
        }
    }, []);

    if (!dataGridConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={rowActions}
            columnWidth={{
                [documentsGridColumnKeys.type]: 28,
            }}
            columnCellContent={{
                [documentsGridColumnKeys.type]: (column, row) => <IconWrap icon={getTypeIcon(row[column.key].toLowerCase())} />,
                [documentsGridColumnKeys.status]: (column, row) =>
                    row[column.key].toLowerCase() === "public" ? (
                        <IconWrap title="Public" icon="add-group-filled" />
                    ) : (
                        <IconWrap title="Private" icon="user-identity-person-filled" />
                    ),
                [documentsGridColumnKeys.content]: (column, row) => stripHtmlForDataGrid(row[column.key] ?? ""),
            }}
            noFilter
        />
    );
});

export default DocumentsGrid;
