import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import cn from "classnames";

import useFocusClasses from "../../utils/useFocusClasses";
import { defaultPageNumber, defaultPageSize } from "../../../store/globalSearch/utils";
import { paginate, setGlobalSearchPageNumber } from "../../../store/globalSearch/actions";

const SearchResultsPaging = memo(({ instanceId, totalResults, activePage }) => {
    const dispatch = useDispatch();
    const pageNumber = useSelector((state) => get(state, `globalSearch.pageNumber[${instanceId}]`)) || defaultPageNumber;
    const pageSize = useSelector((state) => get(state, `globalSearch.pageSize[${instanceId}]`)) || defaultPageSize;

    const totalPages = parseInt(totalResults / pageSize, 10) + (totalResults % pageSize > 0 ? 1 : 0);

    const onPageChange = useCallback(
        (pageNumber) => {
            dispatch(
                setGlobalSearchPageNumber({
                    instanceId,
                    pageNumber,
                })
            );

            dispatch(paginate({ instanceId }));
        },
        [instanceId, dispatch]
    );

    if (pageSize > totalResults) {
        return null;
    }

    return (
        <div className="search-results__paging flex-column">
            <PageListMobile activePage={pageNumber} totalPages={totalPages} changePage={onPageChange} />
        </div>
    );
});

const PageListMobile = ({ activePage, totalPages, changePage }) => {
    const pages = Array(totalPages)
        .fill()
        .map((i, index) => index + 1);

    return (
        <div className="paging flex-row fill-width align-end">
            {pages.map((page) => (
                <SelectablePage key={page} className={page === activePage ? " active" : ""} pageNumber={page} onSelect={changePage} />
            ))}
        </div>
    );
};

const SelectablePage = ({ className, pageNumber, onSelect }) => {
    const isTabable = true;
    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });

    const onClick = () => {
        onSelect(pageNumber);
    };

    const onKeyDown = (page) => (event) => {
        if (event.key === "Enter") {
            onSelect(page);
        }
    };

    return (
        <div
            className={cn("selectable-page", className)}
            onClick={onClick}
            tabIndex={isTabable ? "0" : "-1"}
            onFocus={onFocusClassesFocus}
            onBlur={onFocusClassesBlur}
            onKeyDown={onKeyDown(pageNumber)}
        >
            <span>{pageNumber}</span>
        </div>
    );
};

export default SearchResultsPaging;
