import React from "react";
import DashboardPanel from "./DashboardPanel";
import { NoPermissionMessage } from "../../../ui/NoPermissionMessage";

export const NoPermissionPanel = ({ panel }) => {
    return (
        <DashboardPanel title={panel.name} className="no-permission-panel">
            <NoPermissionMessage />
        </DashboardPanel>
    );
};
