import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { GlobalSearchKey, useCanAddSearchCriteria } from "../../../store/globalSearch/utils";
import { clickAddSearchInput } from "../../../store/globalSearch/actions";

import IconWithLabel from "../Icons/IconWithLabel";
import ErrorBoundary from "../../utils/ErrorBoundary";
import MainCriteriaSection from "./MainCriteriaSection";
import SubCriteriaSection from "./SubCriteriaSection";
import SearchInputSection from "./SearchInputSection";
import SearchCriteria from "./SearchCriteria";
import SearchHistory from "./SearchHistory";
import SearchQuickResults from "./SearchQuickResults";

const SearchArea = memo(({ instanceId }) => {
    const showQuickResults = instanceId === GlobalSearchKey;
    const isSearchInputExpanded = useSelector((state) => state.globalSearch.isSearchInputExpanded[instanceId]) ?? true;

    return (
        <ErrorBoundary>
            <div
                className={cn("global-search__search-area", {
                    "global-search__search-area--expanded": isSearchInputExpanded,
                })}
            >
                <CollapsedSearchPanel instanceId={instanceId} />
                {isSearchInputExpanded && (
                    <div className="global-search__search-parameters">
                        <div>
                            <MainCriteriaSection instanceId={instanceId} />
                            <SubCriteriaSection instanceId={instanceId} />
                            <SearchInputSection instanceId={instanceId} />
                        </div>
                    </div>
                )}
                <SearchCriteria instanceId={instanceId} />
                <SearchHistory instanceId={instanceId} />
                {showQuickResults && <SearchQuickResults />}
            </div>
        </ErrorBoundary>
    );
});

const CollapsedSearchPanel = ({ instanceId }) => {
    const dispatch = useDispatch();
    const isSearchInputExpanded = useSelector((state) => state.globalSearch.isSearchInputExpanded[instanceId]) ?? true;
    const canAddCriteria = useCanAddSearchCriteria({ instanceId });

    const onClick = useCallback(() => {
        dispatch(clickAddSearchInput({ instanceId }));
    }, [instanceId, dispatch]);

    if (isSearchInputExpanded) {
        return null;
    }

    return (
        <>
            {canAddCriteria && (
                <div className="global-search__search-area-expander flex-column align-center justify-center">
                    <IconWithLabel icon="plus" onClick={onClick}>
                        Add More Search Criteria
                    </IconWithLabel>
                </div>
            )}
        </>
    );
};

export default SearchArea;
