import React, { useState, useRef, useCallback, useEffect } from "react";
import WaitIcon from "../../../../WaitIcon";
import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";
import { useBudgetLinePrograms } from "../../../../../../store/resources/useResource";
import Button from "../../../../Button";
import { submitResource, submitByRef } from "../../../../../utils/form";
import SideNavContent from "../../../../../ui/SideNav/SideNavContent";
import SideNavHeader from "../../../../../ui/SideNav/SideNavHeader";
import SideNavBody from "../../../../../ui/SideNav/SideNavBody";
import SideNavFooter from "../../../../../ui/SideNav/SideNavFooter";

const Form = (props) => {
    const { utilityNumber, contractNumber, budgetLineNumber, onClose, gridRefresh, sidePanel } = props;

    const formRef = useRef();

    const title = "Add Assigned Programs";
    const titleIcon = "plus";

    const [isSubmitting, setSubmitting] = useState(false);

    const [programs = [], isLoading] = useBudgetLinePrograms({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
    });

    const availablePrograms = programs.filter((r) => r.assigned.toLowerCase() !== "yes");

    const schema = {
        type: "object",
        required: ["programList"],
        properties: {
            programList: {
                type: "array",
                title: "Programs",
                uniqueItems: true,
                items: {
                    type: "string",
                    anyOf: availablePrograms.map((i) => ({
                        title: i.programFriendlyName,
                        enum: [i.programNumber],
                    })),
                },
            },
        },
    };

    const uiSchema = {
        classNames: "inline-form",
        programList: {
            classNames: "fill-width",
            "ui:widget": "select",
        },
    };

    const initialValues = {};

    const submitText = isSubmitting ? "Adding..." : "Add";

    const onSubmit = (formData) => {
        const resourceParams = {
            resourceName: "utilitiesContractsBudgetLinesPrograms",
            path: {
                utilityNumber,
                contractNumber,
                budgetLineNumber,
            },
        };

        const body = {
            programList: programs
                .filter((r) => r.assigned.toLowerCase() === "yes")
                .map((p) => p.programNumber)
                .concat(formData.programList),
        };

        submitResource({
            resourceParams,
            body,
            onRefresh: gridRefresh,
            onSuccess: sidePanel.close,
            setSubmitting,
        });
    };

    const handleSubmit = useCallback(() => {
        submitByRef(formRef);
    }, []);

    useEffect(() => {
        sidePanel.setForm([formRef]);
    }, [sidePanel]);

    if (isLoading) {
        return <WaitIcon />;
    }

    if (availablePrograms.length === 0) {
        return (
            <div className="with-panel-borders with-padding">
                <div className="contract-budget-lines__details--information">No Programs to assign</div>
                <div className="flex-row">
                    <Button onClick={onClose}>Close</Button>
                </div>
            </div>
        );
    }

    return (
        <SideNavContent>
            <SideNavHeader title={title} leadBlockIcon={titleIcon} smallHeader onClose={onClose} />
            <SideNavBody className="flex-one-in-column">
                <JsonSchemaForm
                    formRef={formRef}
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={initialValues}
                    disabled={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                    submitText={submitText}
                    noReset
                    noActions
                />
            </SideNavBody>
            <SideNavFooter setPrimaryButton>
                <Button icon={isSubmitting ? "" : "plus"} primary disabled={isSubmitting} onClick={handleSubmit}>
                    {submitText}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </SideNavFooter>
        </SideNavContent>
    );
};

export default Form;
