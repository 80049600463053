import * as actionType from "../actionTypes";
import { getUser } from "../../components/utils/user";

export function userProfileMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        next(action);

        switch (action.type) {
            case actionType.USER_PROFILE_UPDATE_SUCCESS:
                onProfileUpdateSuccess(action);
                break;

            default:
                break;
        }
    };

    async function onProfileUpdateSuccess(action) {
        dispatch({
            type: actionType.USER_SAVE,
            user: getUser(),
        });
    }
}
