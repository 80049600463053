import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { get } from "lodash";

import { exportDatagridToCSV } from "../../../../../utils/CSV";
import { programsGoalsGridColumnKeys } from "../../../../../views/configureGrids";
import { isChildProgram, isLockedText } from "../../../../../views/ProgramView/utils";

import useSidePanelHandlers from "../../../../../utils/useSidePanelHandlers";
import { sideNavSize } from "../../../../SideNav/SideNavRoot";

import PanelHeaderLarge from "../../../../Headers/PanelHeaderLarge";
import GoalsGrid from "./GoalsGrid";
import GoalsForm from "./GoalsForm";
import Controls from "../../Controls";

import "../ProgramCommonStyles.scss";

const GoalsPanel = memo(({ panel }) => {
    const dispatch = useDispatch();
    const programNumber = get(panel, "data.programNumber");

    const gridId = `${programNumber}-goals`;

    const isChild = isChildProgram({ programNumber });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "goals-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<GoalsForm programNumber={programNumber} dispatch={dispatch} gridId={gridId} onClose={handleCloseSidePanel} />);
    }, [programNumber, dispatch, gridId, handleOpenSidePanel, handleCloseSidePanel]);

    const controlItems = [
        {
            position: "right",
            title: "Add Goal",
            tooltip: isChild ? isLockedText : undefined,
            disabled: isChild,
            icon: "plus",
            onClick: handleCreate,
        },
        {
            position: "right",
            title: "Export CSV",
            icon: "custom-csv",
            onClick: () =>
                exportDatagridToCSV({
                    dataGridId: gridId,
                    fileName: "program_goals",
                    fileNamePostfix: programNumber,
                }),
        },
    ];

    return (
        <div className="panel goals-panel sticky-grid-list-panel">
            <PanelHeaderLarge title={panel.name} />
            <Controls items={controlItems} />
            <GoalsGrid
                programNumber={programNumber}
                gridId={gridId}
                filter={`${programsGoalsGridColumnKeys.programNumber}=${programNumber}`}
            />
        </div>
    );
});

export default GoalsPanel;
