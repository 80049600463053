import StatusMsg from "components/ui/StatusMsg";
import React from "react";
import { SectionInfo } from "../SectionInfo";

export const WidgetsInfo = () => {
    return (
        <SectionInfo>
            <StatusMsg
                msgIcon="info-empty"
                msgText={
                    <>
                        Customize widgets here to apply <strong>default</strong> styles. Later you will be able to supplement pages by
                        widgets.
                    </>
                }
            />
        </SectionInfo>
    );
};
