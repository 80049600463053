import * as actionTypes from "../actionTypes";
import { getWindowState } from "../../components/utils/window";

const initialWindow = {
    views: [],
    previousActive: null,
    isNotificationVisible: {},
};

export const reducer = (state, action) => {
    state = state || getWindowState();

    switch (action.type) {
        case actionTypes.WINDOW_CONTAINER_ADD:
            state = {
                ...state,
                [action.window]: initialWindow,
            };
            break;
        case actionTypes.WINDOW_CONTAINER_REMOVE: {
            const { [action.window]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };
            break;
        }
        case actionTypes.WINDOW_ADD:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    views: action.views,
                },
            };
            break;
        case actionTypes.WINDOW_REMOVE:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    views: action.views,
                },
            };
            break;
        case actionTypes.WINDOW_ACTIVATE:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    views: action.views,
                    previousActive: action.previousActive,
                },
            };
            break;

        case actionTypes.WINDOW_SET_DEVICE:
            state = {
                ...state,
                isMobile: action.device.isMobile,
                isTablet: action.device.isTablet,
                isTabletLarge: action.device.isTabletLarge,
                isDesktop: action.device.isDesktop,
                isDesktopSmall: action.device.isDesktopSmall,
                canSplitScreenView: action.device.canSplitScreenView,
            };
            break;

        case actionTypes.WINDOW_SET_NOTIFICATION_VISIBLE:
            state = {
                ...state,
                [action.container]: {
                    ...state[action.container],
                    isNotificationVisible: {
                        ...state[action.container].isNotificationVisible,
                        [action.viewName]: action.isNotificationVisible,
                    },
                },
            };
            break;
        default:
            break;
    }

    return state;
};
