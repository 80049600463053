import React from "react";
import cn from "classnames";

import Breadcrumbs from "./Breadcrumbs";

import "./PanelHeaderLarge.scss";

const PanelHeaderLarge = ({ className, title, children, childTitle, onClick, centered }) => {
    return (
        <div className={cn("panel-header-large", { centered: centered }, className)}>
            {childTitle ? (
                <>
                    <Breadcrumbs title={title} childTitle={childTitle} onClick={onClick} />
                    <h2>{childTitle}</h2>
                </>
            ) : (
                <h2>{title}</h2>
            )}
            <div className="panel-header-actions">{children}</div>
        </div>
    );
};

export default PanelHeaderLarge;
