import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { sideNavClose } from "../../../store/sideNav/actions";
import useOnKey from "../../utils/useOnKey";

const SideNavBackdrop = ({ id, show, disableClose }) => {
    const dispatch = useDispatch();

    const onBackDropClick = useCallback(
        (event) => {
            event.preventDefault();

            if (!disableClose) {
                dispatch(sideNavClose({ id, backdropClick: true }));
            }
        },
        [id, disableClose, dispatch]
    );

    useOnKey("Escape", onBackDropClick);

    const className = cn("sidenav-backdrop", {
        "sidenav-backdrop--transparent": !show,
        "sidenav-backdrop--no-pointer-events": !show,
    });

    return <div className={className} onClick={onBackDropClick} />;
};

export default SideNavBackdrop;
