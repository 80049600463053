import React, { useCallback, useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, isNil, isNumber } from "lodash";
import { Draggable } from "react-beautiful-dnd";

import { setAttributeProperty, selectAttribute } from "../../../store/attributes/actions";
import { dateToJson } from "../../utils/date";
import { isValidationTypeDate, hasLookupValues, isValidationTypeNumeric, valueToString, getAttributeTitle } from "../../utils/attributes";
import { getDateInputValue, getInputValue, getLookupValuesListForDropDown } from "./utils";
import debouncedTextInput from "../../utils/debouncedTextInput";

import DropDownInput from "../Input/DropDownInput";
import TextInput from "../Input/TextInput";
import Checkbox from "../Input/Checkbox";
import IconWrap from "../Icons";
import DragHandle from "../DragHandle";
import NumericInput from "../Input/NumericInput";
import DatePicker, { DatePickerPortal } from "../Input/DatePicker";
import TextLabel from "components/ui/Label/TextLabel";
import AttributeForm from "./AttributeForm";
import { isChildProgram } from "components/views/ProgramView/utils";
import { TrueFalse } from "components/utils/constants";

const DebouncedTextInput = debouncedTextInput(TextInput);
const DebouncedNumericInput = debouncedTextInput(NumericInput);

const AttributesFormItem = memo(
    ({
        programNumber,
        itemId,
        attributeFormRef,
        attributesKey,
        attributes,
        isLocked,
        isSubmitting,
        isValidationLocked,
        attributeMaxLength,
        index,
        validationTypes,
        availableAttributesResourceName,
        availableAttributesResourceKeys,
        onRemove,
        onSelectCalculation,
    }) => {
        const dispatch = useDispatch();

        const numberKey = availableAttributesResourceKeys.number;

        const item = useSelector((state) => state.attributes[attributesKey]?.find((i) => i._id === itemId));
        const calculation = useSelector((state) =>
            state.associations[attributesKey]?.calculationAssociations?.find((i) => i.catalogAttrNumber === item.attrNumber)
        );

        const [isExpanded, setIsExpanded] = useState(false);

        const validationResult = validate({ attribute: item, numberKey, attributeMaxLength });
        const attributeNameReadOnly = item._existing || isLocked;
        const attributeName = item[availableAttributesResourceKeys.name] ? (
            getAttributeTitle(null, item[availableAttributesResourceKeys.name])
        ) : (
            <>&nbsp;</>
        );

        const hasFriendlyName = availableAttributesResourceName !== "programEventAttributes";

        useEffect(() => {
            // Change Error flag if it differs from validation result
            // Check this only in extended edit mode
            if (Boolean(item._error) === isEmpty(validationResult)) {
                dispatch(
                    setAttributeProperty({
                        key: attributesKey,
                        itemId: item._id,
                        propertyName: "_error",
                        propertyValue: !item._error,
                    })
                );
            }
        }, [item, attributesKey, validationResult, dispatch]);

        const onFieldChange = useCallback(
            (key) => (event) => {
                let value = event.target.value;

                if (["showAll", "editAll"].includes(key)) {
                    value = event.target.checked ? "Y" : "N";
                }

                if (key === "validationReq") {
                    value = event.target.checked ? TrueFalse.True : TrueFalse.False;
                }

                if (["defaultValue"].includes(key)) {
                    value = valueToString(value, item);
                }

                if (["lowerLimit", "upperLimit"].includes(key)) {
                    if (isValidationTypeNumeric(item.validationType)) {
                        value = isNumber(value) ? value : isEmpty(value) ? undefined : Number(value);
                    } else {
                        value = valueToString(value, item);
                    }
                }

                dispatch(
                    setAttributeProperty({
                        key: attributesKey,
                        itemId: item._id,
                        propertyName: key,
                        propertyValue: value,
                    })
                );
            },
            [attributesKey, item, dispatch]
        );

        const onDateFieldChange = useCallback(
            (key) => (event) => {
                let value = dateToJson(event.value);

                dispatch(
                    setAttributeProperty({
                        key: attributesKey,
                        itemId: item._id,
                        propertyName: key,
                        propertyValue: value,
                    })
                );
            },
            [attributesKey, item, dispatch]
        );

        const onSelect = useCallback(() => {
            if (!item._selected && !isValidationLocked) {
                dispatch(
                    selectAttribute({
                        key: attributesKey,
                        index,
                    })
                );
            }
        }, [attributesKey, index, item, isValidationLocked, dispatch]);

        if (isNil(item)) {
            return null;
        }
        const isChild = isChildProgram({ programNumber });

        const formItems = (
            <div className="flex-column fill-width">
                <div className="attribute-fields-part flex-one flex-column">
                    <div className="attribute-fields-part__default-fields flex-row fields align-center">
                        <div className="attribute-name flex-row align-center justify-space-between">
                            {attributeNameReadOnly ? (
                                <TextLabel>{attributeName}</TextLabel>
                            ) : (
                                <DropDownInput
                                    required
                                    value={item[numberKey]}
                                    mobileHeader="Select Attribute"
                                    data={attributes}
                                    onChange={onFieldChange(numberKey)}
                                    msgError={validationResult[numberKey]}
                                    withPopper
                                    zIndexOffset={21}
                                    popperPadding={45}
                                />
                            )}
                            {calculation && (
                                <IconWrap
                                    title={"Open Calculation"}
                                    icon={"calculations"}
                                    onClick={onSelectCalculation ? () => onSelectCalculation(calculation) : undefined}
                                ></IconWrap>
                            )}
                        </div>
                        {hasFriendlyName && (
                            <DebouncedTextInput value={item.friendlyName} onChange={onFieldChange("friendlyName")} readOnly={isLocked} />
                        )}
                        <DropDownInput
                            required
                            value={item.validationType}
                            mobileHeader="Select Validation Type"
                            data={validationTypes}
                            onChange={onFieldChange("validationType")}
                            disabled={isLocked}
                            readOnly={isLocked}
                            msgError={validationResult["validationType"]}
                            withPopper
                            zIndexOffset={21}
                            popperPadding={45}
                        />
                        <DefaultValueInput
                            readOnly={isLocked}
                            disabled={isLocked}
                            attribute={item}
                            isLocked={isLocked}
                            attributeMaxLength={attributeMaxLength}
                            msgError={validationResult["defaultValue"]}
                            onFieldChange={onFieldChange}
                            onDateFieldChange={onDateFieldChange}
                        />
                        <div className="flex-row checkboxes">
                            <Checkbox
                                labelIconBig
                                checked={item.validationReq === TrueFalse.True}
                                onChange={onFieldChange("validationReq")}
                                disabled={isLocked}
                            />
                            <Checkbox labelIconBig checked={item.showAll === "Y"} onChange={onFieldChange("showAll")} disabled={isLocked} />
                            <Checkbox labelIconBig checked={item.editAll === "Y"} onChange={onFieldChange("editAll")} disabled={isLocked} />
                        </div>
                    </div>
                    <div className={"attribute-fields-part__hidden-fields" + (isExpanded ? " visible" : "")}>
                        <IconWrap
                            iconWrapWhite
                            iconWrapRoundedSquare
                            disabled={isChild}
                            title={isExpanded ? "Hide Additional Properties" : isChild ? undefined : "Show Additional Properties"}
                            icon={isExpanded ? "shevron-in-circle-up-filled--before" : "shevron-in-circle-down-drop-down-empty"}
                            onClick={() => setIsExpanded(!isExpanded)}
                        ></IconWrap>
                        {isExpanded && (
                            <>
                                <AttributeForm
                                    formRef={attributeFormRef}
                                    programNumber={programNumber}
                                    item={item}
                                    entityKey={attributesKey}
                                    availableAttributesResourceName={availableAttributesResourceName}
                                    availableAttributesResourceKeys={availableAttributesResourceKeys}
                                    isSubmitting={isSubmitting}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        );

        return (
            <Draggable draggableId={itemId} index={item.itemOrder} isDragDisabled={isLocked}>
                {(provided) => (
                    <div
                        id={itemId}
                        className={
                            "attributes-form-items__item flex-row no-shrink" +
                            (isExpanded ? " selected" : "") +
                            (!item._existing ? " new-item" : "")
                        }
                        tabIndex={!isValidationLocked ? 0 : undefined}
                        onFocus={onSelect}
                        onClick={onSelect}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <DragHandle dragVertical {...provided.dragHandleProps} disabled={isLocked} />
                        {formItems}
                        {item._existing || isLocked ? (
                            <span />
                        ) : (
                            <IconWrap
                                iconWrapRoundedSquare
                                iconWrapWhite
                                icon="clear-close"
                                title="Remove Attribute"
                                onClick={() => onRemove(item)}
                            />
                        )}
                        {!item._existing && <div className="watermark-new-item"></div>}
                    </div>
                )}
            </Draggable>
        );
    }
);

const DefaultValueInput = ({ label, attribute, isLocked, attributeMaxLength, msgError, onFieldChange, onDateFieldChange }) => {
    if (isValidationTypeDate(attribute.validationType)) {
        return (
            <DatePicker
                label={label}
                value={getDateInputValue(attribute.defaultValue)}
                readOnly={isLocked}
                popperContainer={DatePickerPortal}
                onChange={onDateFieldChange("defaultValue")}
            />
        );
    }

    if (hasLookupValues(attribute)) {
        return (
            <DropDownInput
                value={getInputValue(attribute.defaultValue)}
                label={label}
                mobileHeader="Select Default Value"
                data={getLookupValuesListForDropDown(attribute)}
                emptyItem
                onChange={onFieldChange("defaultValue")}
                disabled={isLocked}
                readOnly={isLocked}
                msgError={msgError}
                withPopper
            />
        );
    }

    if (isValidationTypeNumeric(attribute.validationType)) {
        return (
            <DebouncedNumericInput
                value={getInputValue(attribute.defaultValue)}
                label={label}
                onChange={onFieldChange("defaultValue")}
                disabled={isLocked}
                readOnly={isLocked}
                error={msgError}
            />
        );
    }

    return (
        <DebouncedTextInput
            value={getInputValue(attribute.defaultValue)}
            label={label}
            disabled={isLocked}
            onChange={onFieldChange("defaultValue")}
            readOnly={isLocked}
            maxLength={attributeMaxLength}
            error={msgError}
        />
    );
};

const validate = ({ attribute, numberKey, attributeMaxLength }) => {
    let errors = {};
    const isRequiredText = "is required property";

    if (isNil(attribute[numberKey])) {
        errors[numberKey] = isRequiredText;
    }

    if (isNil(attribute.validationType)) {
        errors["validationType"] = isRequiredText;
    }

    if (isNumber(attributeMaxLength) && String(attribute.defaultValue).length > attributeMaxLength) {
        errors["defaultValue"] = true;
    }

    return errors;
};

export default AttributesFormItem;
