import React, { memo } from "react";

import { usersGridColumnKeys } from "../../views/configureGrids";
import withDataGrid from "../../ui/DataGrid/withDataGrid";
import DataGrid from "../../ui/DataGrid";
import ActionLabel from "../../ui/Label/ActionLabel";
import { hasAnyOfPermissions, systemUserRights } from "components/utils/user";

import "./UsersGrid.scss";

const UsersGrid = memo(({ gridId, dataGridConfig, onRowSelect, setManagement, onPageChanged, allowMimic }) => {
    const customRowActions = [
        {
            name: "update",
            icon: "user-identity-person-empty icon-wrap-combined edit",
            title: "Edit User Details",
        },
        {
            name: "manageRoles",
            icon: "theaters-empty",
            title: "Manage Roles",
        },
        {
            name: "mimicUser",
            icon: "double-person",
            title: !allowMimic ? "Not permitted" : "Mimic User",
            disabled: !allowMimic,
        },
        {
            name: "auditUser",
            icon: "audit",
            title: "Audit User",
            hide: () => !hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_SYSTEM]),
        },
        {
            name: "assignExternalApps",
            icon: "assignment",
            title: "Assign external applications",
            hide: () => !hasAnyOfPermissions([systemUserRights.VISIONDSM_MANAGE_SYSTEM]),
        },
    ];

    const onRowAction = (action) => {
        const { name, dataItem } = action;

        switch (name) {
            case "update":
                setManagement({
                    type: "update",
                    user: dataItem,
                    isNew: false,
                });
                break;

            case "manageRoles":
                setManagement({
                    type: "manageRoles",
                    users: [dataItem],
                    clientNumber: dataItem[usersGridColumnKeys.clientNumber],
                });
                break;

            case "mimicUser":
                setManagement({
                    type: "mimicUser",
                    users: [dataItem],
                    clientNumber: dataItem[usersGridColumnKeys.clientNumber],
                });
                break;

            case "auditUser":
                setManagement({
                    type: "auditUser",
                    users: [dataItem],
                    clientNumber: dataItem[usersGridColumnKeys.clientNumber],
                });
                break;

            case "assignExternalApps":
                setManagement({
                    type: "assignExternalApps",
                    user: dataItem,
                    isNew: false,
                });
                break;

            default:
                break;
        }
    };

    return (
        <DataGrid
            className="users-grid"
            name={gridId}
            onPageChanged={onPageChanged}
            config={dataGridConfig}
            onRowAction={onRowAction}
            customRowActions={customRowActions}
            columnCellContent={{
                [usersGridColumnKeys.name]: (column, row, onExpandRow, onRowAction) => {
                    return (
                        <ActionLabel
                            onClick={() =>
                                onRowAction({
                                    name: "update",
                                    dataItem: row,
                                })
                            }
                        >
                            {row[column.key]}
                        </ActionLabel>
                    );
                },
            }}
            canSelectRow
            onRowSelectChange={onRowSelect}
        />
    );
});

export default withDataGrid("users", UsersGrid);
