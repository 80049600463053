import React, { useState, memo } from "react";
import { useDispatch } from "react-redux";

import { allHostnamesGridColumnKeys } from "../../configureGrids";
import { updateResource } from "../../../../store/resources/actions";
import InlinePanelHeader from "../../../ui/Dashboard/Panel/InlinePanelHeader";
import JsonSchemaForm from "../../../ui/Form/JsonSchema/JsonSchemaForm";
import { refreshAllHostnamesGrid } from "../../../../store/dataGrid/refreshGrid";
import { refreshProgramInOpenTab } from "store/resources/refreshResource";
import { pickInitialValues } from "../../../utils/form";

const Form = memo((props) => {
    const { dataItem, onClose } = props;

    const dispatch = useDispatch();
    const programNumber = dataItem[allHostnamesGridColumnKeys.programNumber];
    const initialHostname = dataItem[allHostnamesGridColumnKeys.hostname];

    const [isSubmitting, setSubmitting] = useState(false);

    const title = "Edit Hostname";

    const submitText = isSubmitting ? "Saving..." : "Save";

    const schema = {
        type: "object",
        properties: {
            hostname: {
                type: "string",
                title: "Hostname",
            },
        },
    };

    const uiSchema = {};

    const initialValues = pickInitialValues({
        hostname: initialHostname,
    });

    const onSubmit = (formData) => {
        setSubmitting(true);

        dispatch(
            updateResource({
                resourceName: "programHostname",
                path: {
                    programNumber,
                },
                body: {
                    ...formData,
                },
                onSuccess: () => {
                    refreshAllHostnamesGrid();
                    refreshProgramInOpenTab({ programNumber });
                    onClose();
                },
                onError: () => setSubmitting(false),
            })
        );
    };

    return (
        <div className="with-form fill-width">
            <InlinePanelHeader title={title} onClose={onClose} />
            <JsonSchemaForm
                schema={schema}
                uiSchema={uiSchema}
                initialValues={initialValues}
                disabled={isSubmitting}
                onSubmit={onSubmit}
                onCancel={onClose}
                submitText={submitText}
                noReset
            />
        </div>
    );
});

export default Form;
