import React, { memo } from "react";

import JsonSchemaForm from "../../../../Form/JsonSchema/JsonSchemaForm";

const AddApprovedEquipmentQuantity = memo(({ onSubmit, onCancel }) => {
    const initialValues = {
        quantity: 1,
    };

    const schema = {
        type: "object",
        required: ["quantity"],
        properties: {
            quantity: {
                type: "integer",
                title: "Quantity",
            },
        },
    };

    const uiSchema = {
        quantity: {
            classNames: "fill-width",
        },
    };

    return (
        <JsonSchemaForm
            schema={schema}
            uiSchema={uiSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            withCancel
            noReset
            centeredFooter
        />
    );
});

export default AddApprovedEquipmentQuantity;
