import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { resetLogin } from "../../../store/login/actions";
import { isStatusExpired } from "../../utils/user";

import ChangePasswordForm from "./ChangePasswordForm";

const ChangePassword = memo(({ onWindowOpen }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!isStatusExpired(user)) {
            onWindowOpen({
                name: "Home",
                component: "HomeView",
                showHeader: false,
                close: false,
            });
        }
    }, [user, onWindowOpen]);

    const handleSuccess = () => {
        dispatch(resetLogin());
    };

    return <ChangePasswordForm onSuccess={handleSuccess} />;
});

export default ChangePassword;
