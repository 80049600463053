import React, { useState, useCallback, memo } from "react";
import { useSelector } from "react-redux";

import { utilitiesCalculationsGridColumnKeys } from "../../../../../views/configureGrids";
import { calculationTypes } from "../utils";
import { mapGridRowToObject } from "../../../../../utils/datagrid";
import useMultiPageRowSelect from "../../../../../../store/dataGrid/useMultiPageRowSelect";
import { exportDatagridToCSV } from "../../../../../utils/CSV";

import AddCalculationPanel from "../AddCalculationPanel";
import Grid from "./Grid";
import Controls from "./Controls";
import CalculationAssociations from "../CalculationAssociations";

const CatalogCalculationsTab = memo(({ utilityNumber, onOpenPanel, onClosePanel, programNumber }) => {
    const user = useSelector((state) => state.user?.name);

    const [selectedCalculations, setSelectedCalculations] = useState([]);
    const assignmentDisabled = selectedCalculations.length === 0;
    const dataGridId = `${utilityNumber}-catalog-calculations-grid`;

    const onRowSelect = useCallback((selectedRows) => {
        setSelectedCalculations(selectedRows || []);
    }, []);

    const onExport = useCallback(() => {
        exportDatagridToCSV({
            dataGridId,
            fileName: "catalog_calculations",
            fileNamePostfix: utilityNumber,
        });
    }, [dataGridId, utilityNumber]);

    const [onRowSelectChange, onPageChanged, onRowSelectClear] = useMultiPageRowSelect({
        dataGridId,
        onRowSelect,
    });

    const onAddCalculation = useCallback(() => {
        onOpenPanel(
            <AddCalculationPanel
                utilityNumber={utilityNumber}
                calculationType={calculationTypes.catalog}
                user={user}
                onClose={onClosePanel}
                programNumber={programNumber}
            />
        );
    }, [user, utilityNumber, onOpenPanel, onClosePanel, programNumber]);

    const onBulkAssign = useCallback(() => {
        const rowData = selectedCalculations.map((item) => mapGridRowToObject(utilitiesCalculationsGridColumnKeys, item));

        onOpenPanel(<CalculationAssociations utilityNumber={utilityNumber} rowData={rowData} onClose={onClosePanel} />);
    }, [selectedCalculations, utilityNumber, onOpenPanel, onClosePanel]);

    return (
        <div className="catalog-calculations-tab tab-list-tab-content flex-column fill-height">
            <Controls
                programNumber={programNumber}
                assignmentDisabled={assignmentDisabled}
                selectedRowCount={selectedCalculations.length}
                onRowSelectClear={onRowSelectClear}
                onBulkAssign={onBulkAssign}
                onAddCalculation={onAddCalculation}
                onExport={onExport}
            />
            <Grid
                programNumber={programNumber}
                gridId={dataGridId}
                filter={`${utilitiesCalculationsGridColumnKeys.utilityNumber}=${utilityNumber}|${utilitiesCalculationsGridColumnKeys.calculationType}=Equipment_Attr`}
                utilityNumber={utilityNumber}
                onRowSelectChange={onRowSelectChange}
                onPageChanged={onPageChanged}
                onOpenPanel={onOpenPanel}
                onClosePanel={onClosePanel}
            />
        </div>
    );
});

export default CatalogCalculationsTab;
