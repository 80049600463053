import React, { useCallback } from "react";

import Input from "../Input";
import Label from "../Label";
import FieldWrap from "../FieldWrap";
import useFocusClasses from "../../utils/useFocusClasses";
import useEnterKey from "../../utils/useEnterKey";

import "./style.scss";

const FieldGroupCheckbox = (props) => {
    const isTabable = !props.disabled;
    const readOnly = props.readOnly || props.onChange === undefined;

    const onClick = useCallback(
        (event) => {
            event.preventDefault();
            const element = document.getElementById(props.id);
            if (element) {
                element.click();
            }
        },
        [props.id]
    );

    const [onFocusClassesFocus, onFocusClassesBlur] = useFocusClasses({
        disabled: !isTabable,
    });
    const [onEnter] = useEnterKey({ disabled: !isTabable, onClick });

    return (
        <FieldWrap checkbox className={props.className} title={props.title}>
            <Input
                onChange={props.onChange}
                type="checkbox"
                id={props.id}
                disabled={props.disabled}
                readOnly={readOnly}
                checked={props.checked}
                checkedPartly={props.checkedPartly}
            />
            {props.label && (
                <Label
                    labelStyle={props.labelStyle}
                    labelIcon={props.labelIcon}
                    iconLabelEmpty={props.iconLabelEmpty}
                    labelIconBig={props.labelIconBig}
                    htmlFor={props.id}
                    tabIndex={isTabable ? "0" : "-1"}
                    onFocus={onFocusClassesFocus}
                    onBlur={onFocusClassesBlur}
                    onKeyDown={onEnter}
                    onClick={onClick}
                >
                    {props.label}
                </Label>
            )}
        </FieldWrap>
    );
};

export default FieldGroupCheckbox;
