import React, { useCallback } from "react";
import { isNil } from "lodash";

import WaitIcon from "../../ui/WaitIcon";
import AssociationsList from "../../ui/AssociationsList";

import { openUserAssignmentModal, openProgramAssignmentModal, openUtilityAssignmentModal } from "./utils";

const Associations = ({
    isLoading,
    utilityNumber,
    dataItem,
    users,
    programs,
    utilities,
    onChangeUsers,
    onChangePrograms,
    onChangeUtilities,
}) => {
    const isUtilityUserGroups = !isNil(utilityNumber);

    const onEditUtilities = () => {
        openUtilityAssignmentModal({
            group: dataItem,
            onSelect: (items) => {
                onChangeUtilities(items);
            },
        });
    };

    const onRemoveUtility = (utility) => {
        onChangeUtilities(utilities.filter((u) => u.utilityNumber !== utility.utilityNumber));
    };

    const onEditUsers = useCallback(() => {
        openUserAssignmentModal({
            utilityNumber,
            group: dataItem,
            onSelect: (items) => {
                onChangeUsers(items);
            },
        });
    }, [dataItem, onChangeUsers, utilityNumber]);

    const onRemoveUser = useCallback(
        (user) => {
            onChangeUsers(users.filter((i) => i.userNumber !== user.userNumber));
        },
        [users, onChangeUsers]
    );

    const onEditPrograms = useCallback(() => {
        openProgramAssignmentModal({
            utilityNumber,
            group: dataItem,
            onSelect: (items) => {
                onChangePrograms(items);
            },
        });
    }, [dataItem, onChangePrograms, utilityNumber]);

    const onRemoveProgram = useCallback(
        (program) => {
            onChangePrograms(programs.filter((i) => i.progId !== program.progId));
        },
        [programs, onChangePrograms]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    return (
        <div className="flex-column">
            {!isUtilityUserGroups && (
                <>
                    <AssociationsList
                        className="flex-one"
                        headerText="Assigned Utilities"
                        title="Remove Utility"
                        onEdit={onEditUtilities}
                        onRemove={onRemoveUtility}
                        list={utilities}
                        displayProperty="utilityName"
                    />
                </>
            )}
            <AssociationsList
                className="flex-one"
                headerText="Assigned Programs"
                title="Remove Program"
                onEdit={onEditPrograms}
                onRemove={onRemoveProgram}
                list={programs}
                displayProperty="programName"
            />
            <AssociationsList
                className="flex-one"
                headerText="Assigned Users"
                title="Remove User"
                onEdit={onEditUsers}
                onRemove={onRemoveUser}
                list={users}
                displayProperty="userName"
            />
        </div>
    );
};

export default Associations;
