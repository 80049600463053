import React from "react";

import Switcher from "../../../ui/Switcher";
import TagButton from "../../../ui/Button/TagButton";
import ButtonGroup from "../../../ui/Button/ButtonGroup";
import TriggerButton from "../../../ui/Button/TriggerButton";
import FieldGroupCheckbox from "../../../ui/FieldGroupCheckbox";
import Checkbox from "../../../ui/Input/Checkbox";
import FieldGroupRadio from "../../../ui/FieldGroupRadio";

class Controls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
            isActive2: true,
            isActive3: false,
            isActive4: false,
            isActive5: true,
            isActive6: false,
            isChecked: true,
            isChecked2: false,
            isChecked3: false,
            isChecked4: true,
            isChecked6: false,
            isChecked7: false,
            isChecked8: false,
            checkboxID: "first",
            checkboxID2: "second",
            checkboxID3: "third",
            checkboxID4: "fourth",
            checkboxID5: "fifth",
            checkboxID6: "sixth",
            checkboxID7: "seventh",
            radioName: "test",
            radioID: "mark",
            radioID2: "mark2",
            radioID3: "mark3",
        };
    }
    render() {
        return (
            <div>
                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Checkbox</div>
                        <div className="row">
                            <FieldGroupCheckbox
                                label="Checkbox Selected"
                                id={this.state.checkboxID}
                                checked={this.state.isChecked}
                                labelIcon={!this.state.isChecked ? "checkbox-unchecked-empty" : "checkbox-checked-filled"}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked: e.target.checked,
                                    });
                                }}
                            ></FieldGroupCheckbox>
                        </div>
                        <div className="row">
                            <FieldGroupCheckbox
                                label="Checkbox"
                                id={this.state.checkboxID2}
                                checked={this.state.isChecked2}
                                labelIcon={!this.state.isChecked2 ? "checkbox-unchecked-empty" : "checkbox-checked-filled"}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked2: e.target.checked,
                                    });
                                }}
                            ></FieldGroupCheckbox>
                        </div>
                        <div className="row">
                            <FieldGroupCheckbox
                                checked
                                checkedPartly
                                labelIcon="indeterminate-checkbox-filled"
                                label="Checkbox for Select (not) All"
                                id={this.state.checkboxID3}
                            ></FieldGroupCheckbox>
                        </div>
                        <div className="row">
                            <FieldGroupCheckbox
                                disabled
                                label="Checkbox Disabled"
                                labelIcon="checkbox-unchecked-empty"
                                id={this.state.checkboxID4}
                            ></FieldGroupCheckbox>
                        </div>
                        <div className="row">
                            <FieldGroupCheckbox
                                label="Checkbox with long text that drops to the next line and even more to make it look consitence"
                                id={this.state.checkboxID5}
                                checked={this.state.isChecked3}
                                labelIcon={!this.state.isChecked3 ? "checkbox-unchecked-empty" : "checkbox-checked-filled"}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked3: e.target.checked,
                                    });
                                }}
                            ></FieldGroupCheckbox>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Checkbox big and without label</div>
                        <div className="row">
                            <Checkbox
                                labelIconBig
                                iconLabelEmpty
                                id={this.state.checkboxID6}
                                checked={this.state.isChecked4}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked4: e.target.checked,
                                    });
                                }}
                            ></Checkbox>
                        </div>
                        <div className="row">
                            <Checkbox
                                labelIconBig
                                iconLabelEmpty
                                id={this.state.checkboxID7}
                                checked={this.state.isChecked6}
                                onChange={(e) => {
                                    this.setState({
                                        isChecked6: e.target.checked,
                                    });
                                }}
                            ></Checkbox>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Radio</div>
                        <div className="row">
                            <FieldGroupRadio
                                checked={this.state.isChecked}
                                label="Radio Button Active"
                                labelIcon={!this.state.isChecked ? "circle-radio-button-unchecked-empty" : "radio-button-checked"}
                                name={this.state.radioName}
                                id={this.state.radioID}
                            ></FieldGroupRadio>
                        </div>
                        <div className="row">
                            <FieldGroupRadio
                                checked={this.state.isChecked8}
                                label="Radio Button"
                                name={this.state.radioName}
                                labelIcon={!this.state.isChecked8 ? "circle-radio-button-unchecked-empty" : "radio-button-checked"}
                                id={this.state.radioID2}
                            ></FieldGroupRadio>
                        </div>
                        <div className="row">
                            <FieldGroupRadio
                                disabled
                                label="Radio Button"
                                labelIcon="circle-radio-button-unchecked-empty"
                                name={this.state.radioName}
                                id={this.state.radioID3}
                            ></FieldGroupRadio>
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Switcher</div>
                        <div className="row">
                            <Switcher
                                icon="check-done"
                                active={this.state.isActive}
                                onClick={() =>
                                    this.setState({
                                        isActive: !this.state.isActive,
                                    })
                                }
                            />
                            <Switcher
                                disabled
                                icon="check-done"
                                active={this.state.isActive2}
                                onClick={() =>
                                    this.setState({
                                        isActive2: !this.state.isActive2,
                                    })
                                }
                            />
                        </div>
                        <div className="row">
                            <Switcher
                                icon="clear-close"
                                active={this.state.isActive3}
                                onClick={() =>
                                    this.setState({
                                        isActive3: !this.state.isActive3,
                                    })
                                }
                            />
                            <Switcher
                                disabled
                                icon="clear-close"
                                active={this.state.isActive4}
                                onClick={() =>
                                    this.setState({
                                        isActive4: !this.state.isActive4,
                                    })
                                }
                            />
                        </div>
                        <div className="row">
                            <Switcher
                                icon="restore-update"
                                active={this.state.isActive5}
                                onClick={() =>
                                    this.setState({
                                        isActive5: !this.state.isActive5,
                                    })
                                }
                            />
                            <Switcher
                                icon="restore-update"
                                active={this.state.isActive6}
                                onClick={() =>
                                    this.setState({
                                        isActive6: !this.state.isActive6,
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Trigger button</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TriggerButton>Now</TriggerButton>
                                <TriggerButton active>Now</TriggerButton>
                                <TriggerButton disabled>Now</TriggerButton>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>

                <div className="flexbox-row">
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tags edit</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TagButton title="remove" edit>
                                    Tag
                                </TagButton>
                            </ButtonGroup>
                        </div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TagButton title="remove" edit disabled>
                                    Tag
                                </TagButton>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tags view</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TagButton readonly>Tag</TagButton>
                                <TagButton readonly active>
                                    Tag
                                </TagButton>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tags with icon</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TagButton readonly tagWithIcon="offer-empty">
                                    Tag
                                </TagButton>
                                <TagButton readonly tagWithIcon="flag-empty">
                                    Flag
                                </TagButton>
                            </ButtonGroup>
                        </div>
                    </div>
                    <div className="flexbox-col">
                        <div className="ui-library-sub-title">Tag clickable</div>
                        <div className="row">
                            <ButtonGroup transparent>
                                <TagButton tagWithIcon="plus" addTag tagTitle="Add Tag"></TagButton>
                                <TagButton onFullItemClick edit>
                                    Formatted Incentive
                                </TagButton>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Controls;
