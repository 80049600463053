import React, { useState } from "react";
import SelectBox from "../../../SelectBox";
import { FIELD_FORMAT } from "../../../../utils/form";

import "./FieldFormatWidget.scss";

const FieldFormatWidget = (props) => {
    const [selectedFieldType, setSelectedFieldType] = useState(props.value);
    const { email, url } = FIELD_FORMAT;

    const onSelectBoxClick = (value) => {
        if (value === selectedFieldType) {
            setSelectedFieldType("");
            props.onChange("");
        } else {
            setSelectedFieldType(value);
            props.onChange(value);
        }
    };

    return (
        <div className="field-format-widget flex-row">
            <SelectBox
                disabled={props.disabled}
                selectBoxText="Is Email"
                selectBoxSelected={selectedFieldType === email}
                onClick={() => onSelectBoxClick(email)}
            ></SelectBox>
            <SelectBox
                disabled={props.disabled}
                selectBoxText="Is URL"
                selectBoxSelected={selectedFieldType === url}
                onClick={() => onSelectBoxClick(url)}
            ></SelectBox>
        </div>
    );
};

export default FieldFormatWidget;
