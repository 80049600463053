import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { searchAttrToFilter } from "../../../../../utils/datagrid";
import { construct, getData, setPage } from "../../../../../../store/dataGrid/actions";
import { availableGrids, equipmentGridColumnKeys } from "../../../../../views/configureGrids";
import { deleteResource, getResource } from "../../../../../../store/resources/actions";
import { modalOpen } from "../../../../../../store/modal/actions";
import { copyToClipboard } from "../../../../../utils/string";
import { onCatalogItemsGridRefresh } from "../../Program/CatalogPanel/utils";
import { isApplicationLocked } from "../../../../../views/ProjectView/utils";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "../../Program/CatalogPanel/Form";
import ActionLabel from "../../../../Label/ActionLabel";
import CloneEquipmentPanel from "./CloneEquipmentPanel";
import UpdateEquipmentPanel from "./UpdateEquipmentPanel";
import RawHtml from "../../../../RawHtml";
import { refreshSidebarEquipment } from "./utils";
import { ProgramRights, systemUserRights } from "components/utils/user";
import { useProgramRights, useUtilityRights } from "store/resources/useResource";

const EquipmentGrid = memo(
    ({ applicationNumber, programNumber, utilityNumber, onRowSelectChange, onPageChanged, dataGridId, onRowSelect, onRowSelectClear }) => {
        const dispatch = useDispatch();
        const dataGrid = useSelector((state) => state.dataGrid);
        const isAppLocked = isApplicationLocked({ applicationNumber });
        const [programRights = []] = useProgramRights({ programNumber });
        const { handleOpenSidePanel: handleOpenEquipmentSidePanel, handleCloseSidePanel: handleCloseEquipmentSidePanel } =
            useSidePanelHandlers({ className: "app-equipment-sidenav-panel" });
        const { handleOpenSidePanel: handleOpenCatalogSidePanel, handleCloseSidePanel: handleCloseCatalogSidePanel } = useSidePanelHandlers(
            {
                className: "catalog-sidenav-panel",
            }
        );
        const [utilityRights = []] = useUtilityRights({ utilityNumber });

        const dataGridConfig = dataGrid[dataGridId];

        const isEditEquipmentDisabled = !programRights.includes(ProgramRights.VISIONDSM_ADD_EQUIPMENT);
        const isEditCatalogEnabled = utilityRights.includes(systemUserRights.VISIONDSM_ADD_PROGRAM_CATALOG);

        const customRowActions = [
            {
                name: "copyref",
                icon: "layers-empty",
                title: "Copy REFID to clipboard",
            },
            {
                name: "update",
                icon: isAppLocked || isEditEquipmentDisabled ? "eye-visibility-empty" : "edit-empty",
                title: isAppLocked || isEditEquipmentDisabled ? "View" : "Edit",
            },
            {
                name: "clone",
                icon: "opacity-filled",
                hide: isAppLocked,
                title: "Clone",
                disabled: isEditEquipmentDisabled,
            },
            {
                name: "delete",
                icon: "delete-trash-empty",
                hide: isAppLocked,
                title: "Delete",
                disabled: isEditEquipmentDisabled,
            },
        ];

        useEffect(() => {
            dispatch(
                construct({
                    dataGridId: availableGrids.equipment,
                    dataGridInstanceId: dataGridId,
                    filter: searchAttrToFilter(`${equipmentGridColumnKeys.appid}=${applicationNumber}`),
                    sort: [
                        {
                            field: equipmentGridColumnKeys.dateEntered,
                            dir: "desc",
                        },
                    ],
                    data: true,
                })
            );
        }, [applicationNumber, dataGridId, dispatch]);

        const onCloneEquipment = ({ equipmentId }) => {
            if (equipmentId) {
                handleOpenEquipmentSidePanel(
                    <CloneEquipmentPanel
                        equipmentId={equipmentId}
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        onClose={handleCloseEquipmentSidePanel}
                        onRowSelect={onRowSelect}
                        onRowSelectClear={onRowSelectClear}
                    />
                );
            }
        };

        const onEditEquipment = ({ equipmentId }) => {
            if (equipmentId) {
                handleOpenEquipmentSidePanel(
                    <UpdateEquipmentPanel
                        equipmentId={equipmentId}
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        onClose={handleCloseEquipmentSidePanel}
                        onRowSelect={onRowSelect}
                        onRowSelectClear={onRowSelectClear}
                    />
                );
            }
        };

        const onRowAction = (action) => {
            const { dataItem } = action;

            const equipmentId = dataItem[equipmentGridColumnKeys.equipmentId];
            const equipmentName = dataItem[equipmentGridColumnKeys.equipmentName];
            const refID = dataItem[equipmentGridColumnKeys.refId];

            switch (action.name) {
                case "copyref":
                    onCopyToClipboard(refID);
                    break;

                case "clone":
                    onCloneEquipment({ equipmentId });
                    break;

                case "update":
                    document.activeElement.blur();
                    onEditEquipment({ equipmentId });
                    break;

                case "delete":
                    dispatch(
                        modalOpen({
                            modalType: "CONFIRM",
                            modalProps: {
                                title: "Delete Equipment",
                                overlayClassName: "modal-styled",
                                className: "delete-equipment-confirmation-modal modal-sm",
                                modalIcon: "delete-trash-empty",
                                content: <RawHtml>{`<p>Do you want to delete equipment <b>${equipmentName}</b>?</p>`}</RawHtml>,
                                footerContentCenter: true,
                                onConfirm: () => {
                                    dispatch(
                                        deleteResource({
                                            resourceName: "equipment",
                                            resourceId: equipmentId,
                                            path: {
                                                appId: applicationNumber,
                                            },
                                            optimisticUpdate: {
                                                clearItem: true,
                                            },
                                            onSuccess: () => {
                                                onRowSelectClear();
                                                if (dataGridConfig.paging.skip > 0) {
                                                    dispatch(
                                                        setPage({
                                                            dataGridId: dataGridId,
                                                            page: {
                                                                ...dataGridConfig.paging,
                                                                skip: 0,
                                                            },
                                                        })
                                                    );
                                                }
                                                dispatch(
                                                    getData({
                                                        dataGridId: `${applicationNumber}-equipment-grid`,
                                                    })
                                                );
                                                refreshSidebarEquipment({
                                                    applicationNumber,
                                                    showErrorNotification: false,
                                                });
                                            },
                                        })
                                    );
                                },
                            },
                        })
                    );
                    break;

                default:
                    break;
            }
        };

        const onCopyToClipboard = (text) => {
            copyToClipboard(text, () => {
                toast.success("Copied to clipboard");
            });
        };

        const openMeasureConfigScreen = ({ equipmentId, catalogID }) => {
            dispatch(
                getResource({
                    resourceName: "equipment",
                    resourceId: equipmentId,
                    key: equipmentId,
                    path: {
                        appId: applicationNumber,
                    },
                    onSuccess: (action) => {
                        const { catalogNumber } = action.data.equipment;

                        if (!catalogNumber) return;

                        handleOpenCatalogSidePanel(
                            <Form
                                utilityNumber={utilityNumber}
                                programNumber={programNumber}
                                catalogNumber={catalogNumber}
                                catalogID={catalogID}
                                gridRefresh={() => onCatalogItemsGridRefresh({ programNumber })}
                                onClose={handleCloseCatalogSidePanel}
                            />
                        );
                    },
                })
            );
        };

        if (dataGridConfig) {
            return (
                <DataGrid
                    name={dataGridId}
                    config={dataGridConfig}
                    customRowActions={customRowActions}
                    onRowAction={onRowAction}
                    columnCellContent={
                        isEditCatalogEnabled && {
                            [equipmentGridColumnKeys.catalogId]: (column, row) => {
                                return (
                                    <ActionLabel
                                        onClick={() =>
                                            openMeasureConfigScreen({
                                                equipmentId: row[equipmentGridColumnKeys.equipmentId],
                                                catalogID: row[equipmentGridColumnKeys.catalogId],
                                            })
                                        }
                                    >
                                        {row[column.key]}
                                    </ActionLabel>
                                );
                            },
                        }
                    }
                    canSelectRow
                    onPageChanged={onPageChanged}
                    onRowSelectChange={onRowSelectChange}
                />
            );
        }

        return <WaitIcon />;
    }
);

export default EquipmentGrid;
