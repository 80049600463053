import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { getBudgetLookupsResourceParams } from "store/configureResources";
import { onDeleteLookup } from "./utils";
import { availableGrids } from "../../../../../views/configureGrids";

import WaitIcon from "../../../../WaitIcon";
import DataGrid from "../../../../DataGrid";
import Form from "./Form";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";

const budgetLookupsGridColumnKeys = {
    lookupNumber: "c_5c77a333-ac2e-4b7c-abd4-65424b553719",
    parentLookupNumber: "c_d6ce9dc6-bb2f-4706-abba-7c01fc5d1a17",
    displayValue: "c_ac706dc0-eeb8-44b2-b068-c07233f22cb8",
    displayLabel: "c_5ee6932e-debd-4749-b868-9a0ca5cdb842",
    storedValue: "c_fc320a60-d911-45d4-93e5-b50f73decc70",
    budgetLineNumber: "c_0c9aad51-63cc-4a10-832c-75f0d61e01ef",
    budgetLineId: "c_fd4f260e-4517-41c1-a3fa-2dcec2e6e2aa",
};

const rowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit Budget Lookup",
        footerExclude: true,
    },
    {
        name: "add",
        icon: "plus",
        title: "Add New Child Budget Lookup",
        footerExclude: true,
    },
    {
        name: "delete",
        icon: "delete-trash-empty",
        title: "Delete Budget Lookup",
        footerName: "Delete",
    },
];

const Grid = memo(({ dataGridId, utilityNumber, contractNumber, onRefresh }) => {
    const dispatch = useDispatch();

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-budget-lookups-sidenav-panel",
    });

    const [gridConfig, isLoading] = useDataGrid({
        dataGridId: availableGrids.budgetLookups,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "resourceGetDataAction",
            props: {
                dataGridId,
                columnKeysMap: budgetLookupsGridColumnKeys,
                resourceParams: getBudgetLookupsResourceParams({
                    utilityNumber,
                    contractNumber,
                }),
            },
        },
        tree: {
            treeColumn: budgetLookupsGridColumnKeys.displayValue,
            idColumn: budgetLookupsGridColumnKeys.lookupNumber,
            parentIdColumn: budgetLookupsGridColumnKeys.parentLookupNumber,
            parentNameColumn: budgetLookupsGridColumnKeys.parentLookupDisplayValue,
        },
    });

    const onRowAction = useCallback(
        (action) => {
            const { name, dataItem } = action;

            const data = mapGridRowToObject(budgetLookupsGridColumnKeys, dataItem);

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <Form
                            dataItem={data}
                            utilityNumber={utilityNumber}
                            contractNumber={contractNumber}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;

                case "add":
                    handleOpenSidePanel(
                        <Form
                            addChild
                            dataItem={data}
                            utilityNumber={utilityNumber}
                            contractNumber={contractNumber}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;

                case "delete":
                    onDeleteLookup({
                        utilityNumber,
                        contractNumber,
                        dataItem: data,
                        onRefresh,
                        dispatch,
                    });
                    break;

                default:
                    break;
            }
        },
        [utilityNumber, contractNumber, onRefresh, handleOpenSidePanel, handleCloseSidePanel, dispatch]
    );

    if (isLoading) {
        return <WaitIcon />;
    }

    return <DataGrid name={dataGridId} config={gridConfig} onRowAction={onRowAction} customRowActions={rowActions} virtualized />;
});

export default Grid;
