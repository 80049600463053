import React, { useEffect, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { isNil } from "lodash";

import { SubMenu } from "../Menu/SubMenu";
import { windowAdd } from "../../../store/window/actions";
import { windowContainerTypes } from "../../utils/window";
import { availableViews } from "../../views/configureViews";
import { hasAnyOfPermissions } from "../../utils/user";
import ViewPlaceholder from "../ViewPlaceholder";
import { setActiveSubMenu } from "../../../store/subMenu/actions";

const canShowMenuItem = (menuItem) => {
    return isNil(menuItem.userRights) || hasAnyOfPermissions(menuItem.userRights);
};

const ViewWithSubmenu = memo(
    ({ menuItems, className, menuSettings, isLoadingMenu, viewName, viewPlaceholder, onMenuChange, onOpenSettings }) => {
        const dispatch = useDispatch();
        const activeMenuItem = useSelector((state) => state.subMenu.activeSubMenu[viewName]);
        const availableMenuItems = useMemo(() => menuItems.filter(canShowMenuItem), [menuItems]);

        const setSubMenu = useCallback(
            (menuItem) => {
                dispatch(
                    setActiveSubMenu({
                        key: viewName,
                        subMenu: menuItem,
                    })
                );
            },
            [viewName, dispatch]
        );

        const onOpenInTab = useCallback(
            (menuItem) => {
                dispatch(
                    windowAdd({
                        containerName: windowContainerTypes.Root,
                        name: menuItem.id || menuItem.text,
                        title: menuItem.text,
                        component: menuItem.component,
                        header: menuItem.text,
                        close: true,
                        props: menuItem.props,
                        activate: true,
                    })
                );
            },
            [dispatch]
        );

        const onMenuClick = useCallback(
            (menuItem) => {
                setSubMenu(menuItem);
                onMenuChange && onMenuChange(menuItem);
            },
            [setSubMenu, onMenuChange]
        );

        useEffect(() => {
            if (!activeMenuItem && availableMenuItems.length > 0) {
                setSubMenu(availableMenuItems[0]);
            }
        }, [activeMenuItem, availableMenuItems, setSubMenu]);

        useEffect(() => {
            if (activeMenuItem && availableMenuItems.filter((i) => i.id === activeMenuItem.id).length === 0) {
                setSubMenu(null);
            }

            if (availableMenuItems.length === 0) {
                setSubMenu(null);
            }
        }, [availableMenuItems, activeMenuItem, setSubMenu]);

        const ActiveView =
            activeMenuItem &&
            canShowMenuItem(activeMenuItem) &&
            (typeof activeMenuItem.component === "string" ? availableViews[activeMenuItem.component] : activeMenuItem.component);

        return (
            <div className={cn("view-with-submenu flex-column flex-one no-scroll", className)}>
                <SubMenu
                    className="no-shrink"
                    items={availableMenuItems}
                    onClick={onMenuClick}
                    onOpenInTab={onOpenInTab}
                    active={activeMenuItem}
                    menuSettings={menuSettings}
                    isLoading={isLoadingMenu}
                />
                {ActiveView && (
                    <div className="active-view popup-boundary flex-column flex-one-in-column with-scroll">
                        <ActiveView {...activeMenuItem.props} onOpenSettings={onOpenSettings} />
                    </div>
                )}
                {!ActiveView && <ViewPlaceholder>{viewPlaceholder}</ViewPlaceholder>}
            </div>
        );
    }
);

export default ViewWithSubmenu;
