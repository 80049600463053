import { getResourcePromise } from "../../../store/resources/useResource";
import { getResourceFromStore } from "store/resources/selectors";

export const getReferenceValueByName = ({ reference, displayName }) => {
    return (reference ?? []).filter((i) => (i.display ?? "").toLowerCase() === (displayName ?? "").toLowerCase()).map((i) => i.val)[0];
};

export const getReferenceNameByValue = ({ reference, value }) => {
    return reference.filter((i) => i.val === `${value}`).map((i) => i.display)[0];
};

export const referencePromise = ({ type, outputType = "id" }) => {
    return getResourcePromise({
        resourceName: "reference",
        key: type,
        query: {
            type,
            outputType,
        },
    });
};

export const getReference = ({ type, outputType = "id" }) => {
    const value = getResourceFromStore({
        resourceName: "reference",
        key: type,
    });

    if (value) {
        return Promise.resolve(value);
    }

    return referencePromise({ type, outputType });
};
