import React, { memo, useContext, useEffect } from "react";
import { PortalBuilderPropertiesContext } from "../../contexts";
import { PropertyListItem } from "../Property/PropertyListItem";
import { SECTION_CONTENT, SECTION_WIDGETS } from "../Section";
import { WidgetInfoBlock } from "../WidgetInfoBlock";
import { SelectBoxProperty } from "../Property/SelectBoxProperty";
import { SINGLE_WIDGETS } from "../Widgets";
import { usePortalBuilderState } from "../../PortalBuilderContextProvider";
import { useErrorContext } from "../../PortalBuilderErrorContextProvider";

export const LinkStylesProperty = memo((props) => {
    const { value, onChange } = props;
    const [isProgramPortalBuilder, setPortalBuilderState] = usePortalBuilderState((state) => state.isProgramPortalBuilder);
    const [editingPageIndex] = usePortalBuilderState((state) => state.editingPageIndex);
    const { activeSection, setActiveSection } = useErrorContext();
    const { selectedColumn, selectedColumnRow, setLastSelectedColumn, setLastSelectedColumnRow, setSelectedWidget } =
        useContext(PortalBuilderPropertiesContext);

    useEffect(() => {
        if (!isProgramPortalBuilder) {
            setPortalBuilderState({ lastEditingPageIndex: undefined, lastActiveSection: undefined });
            setLastSelectedColumn(null);
            setLastSelectedColumnRow(null);
        }
    }, [setLastSelectedColumn, setLastSelectedColumnRow, isProgramPortalBuilder, setPortalBuilderState]);

    const items = [
        {
            text: "Default",
            value: "default",
            tooltip: "Styles set in Widgets Section and affects all Portal places where the widget is included",
        },
    ];

    const onSectionChange = (section) => () => {
        if (activeSection === SECTION_CONTENT) {
            setPortalBuilderState({ lastEditingPageIndex: editingPageIndex });
        }
        setPortalBuilderState({ lastActiveSection: activeSection });
        setLastSelectedColumn(selectedColumn);
        setLastSelectedColumnRow(selectedColumnRow);
        setActiveSection(section);
        setSelectedWidget(SINGLE_WIDGETS[6]);
    };

    return (
        <PropertyListItem className="property-list-item--link-styles" borderBottom={false}>
            <SelectBoxProperty
                id={"linkStyle"}
                value={value ?? "default"}
                title={"Link styles"}
                items={items}
                borderBottom={false}
                onChange={onChange}
            />
            {!isProgramPortalBuilder && (
                <WidgetInfoBlock>
                    <p>
                        <strong> Default </strong> Link styles are applied without a possibility to change them from here.
                    </p>
                    <p>
                        If you would like to adjust <strong> default </strong> styles, please go to&nbsp;
                        <span className="link-to-default-widget-styles" onClick={onSectionChange(SECTION_WIDGETS)}>
                            {"Widgets Section -> edit Link Widget styles"}
                        </span>
                    </p>
                </WidgetInfoBlock>
            )}
        </PropertyListItem>
    );
});
