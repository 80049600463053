import { get, isNil } from "lodash";

import { createId } from "components/utils/string";
import { toArray } from "components/utils/array";
import { DEFAULT_MAIN_CRITERIA, getMainCriteriaTitle } from "store/globalSearch/utils";

export const searchTableTitle = {
    UTILITY: "Utility",
    PROGRAM: "Program",
    CIS_SEARCH: "CIS Search",
};

export const getSearchCriteriaList = ({ criteriaList }) => {
    if (!criteriaList) {
        return;
    }

    const searchInputs = criteriaList.reduce((result, next) => {
        result[next.searchInputId] = result[next.searchInputId] || [];
        result[next.searchInputId].push(next);

        return result;
    }, {});

    return Object.values(searchInputs).map((searchInput) => ({
        searchInputId: searchInput[0]?.searchInputId,
        mainCriteria: searchInput[0]?.mainCriteriaTitle,
        subCriteriaList: searchInput.map((item) => ({
            title: item.subCriteriaTitle,
            value: item.valueTitle ?? item.value,
        })),
    }));
};

export const getCriteriaListFromHistory = ({ criteria, searchOptions, utilitiesList }) => {
    const searchInputId = createId();

    const getTableTitle = (tableKey) => {
        const table = get(searchOptions, "SEARCH.TABLE", []).find((t) => t.KEY === tableKey);
        return table?.TITLE ?? DEFAULT_MAIN_CRITERIA.title;
    };

    const getFieldTitle = (tableKey, fieldKey) => {
        return get(searchOptions, "SEARCH.TABLE", [])
            .filter((t) => t.KEY === tableKey)
            .reduce((acc, next) => (acc = toArray(next.FIELD)), [])
            .filter((field) => field.KEY === fieldKey)
            .map((t) => t.TITLE)
            .join("");
    };

    const getUtility = (historyList, utilitiesList) => {
        const historyItem = historyList.find((item) => {
            const title = getTableTitle(item.SEARCH_FILTER?.SEARCH_TABLE);
            return title === searchTableTitle.UTILITY || title === searchTableTitle.CIS_SEARCH;
        });

        if (!historyItem) {
            return null;
        }

        const utilityNumber = historyItem.SEARCH_FILTER?.SEARCH_VALUE;
        const utilityName = utilitiesList.find((u) => u.utilityNumber === utilityNumber)?.utility;
        return {
            utilityNumber,
            utilityName,
            utilityKey: historyItem.SEARCH_FILTER?.SEARCH_TABLE,
        };
    };

    const getProgram = (historyList) => {
        const historyItem = historyList.find((item) => {
            const title = getTableTitle(item.SEARCH_FILTER?.SEARCH_TABLE);
            return title === searchTableTitle.PROGRAM;
        });

        if (!historyItem) {
            return null;
        }

        const programNumber = historyItem.SEARCH_FILTER?.SEARCH_VALUE;
        return {
            programNumber,
            programName: programNumber,
            programKey: historyItem.SEARCH_FILTER?.SEARCH_TABLE,
        };
    };

    const history = toArray(criteria.SEARCH);

    const utility = getUtility(history, utilitiesList);
    const program = getProgram(history);
    const mainCriteriaValue = program ?? utility;

    return history
        .filter((item) => {
            const title = getTableTitle(item.SEARCH_FILTER?.SEARCH_TABLE);
            return title !== searchTableTitle.UTILITY && title !== searchTableTitle.PROGRAM && title !== searchTableTitle.CIS_SEARCH;
        })
        .map((item) => {
            const { SEARCH_TABLE: table, SEARCH_VALUE: value, SEARCH_FIELD: field } = item.SEARCH_FILTER ?? {};

            const title = getTableTitle(table);
            const mainCriteriaTitle = getMainCriteriaTitle({
                programName: program?.programName,
                utilityName: utility?.utilityName,
                mainCriteriaTitle: title,
            });

            return {
                table,
                field,
                value,
                mainCriteriaTitle: mainCriteriaTitle,
                mainCriteriaValue,
                subCriteriaTitle: getFieldTitle(table, field),
                searchInputId,
            };
        });
};

export const getRecentSearchCriteriaList = ({ criteria, searchOptions, utilitiesList }) => {
    if (isNil(criteria)) {
        return [];
    }

    const searchInputId = createId();

    const getTableTitle = (tableKey) => {
        return get(searchOptions, "SEARCH.TABLE", [])
            .filter((t) => t.KEY === tableKey)
            .map((t) => t.TITLE)
            .join("");
    };

    const getFieldTitle = (tableKey, fieldKey) => {
        return get(searchOptions, "SEARCH.TABLE", [])
            .filter((t) => t.KEY === tableKey)
            .reduce((acc, next) => (acc = toArray(next.FIELD)), [])
            .filter((field) => field.KEY === fieldKey)
            .map((t) => t.TITLE)
            .join("");
    };

    const getCriteriaValue = (value, mainCriteriaTitle) => {
        if (mainCriteriaTitle === searchTableTitle.UTILITY || mainCriteriaTitle === searchTableTitle.CIS_SEARCH) {
            const utility = utilitiesList.find((u) => u.utilityNumber === value);
            return utility?.utility ?? value;
        }
        return value;
    };

    return toArray(criteria.SEARCH).map((item) => {
        const { SEARCH_TABLE: table, SEARCH_VALUE: value, SEARCH_FIELD: field } = item.SEARCH_FILTER ?? {};

        const mainCriteriaTitle = getTableTitle(table);

        return {
            table,
            field,
            value: getCriteriaValue(value, mainCriteriaTitle),
            mainCriteriaTitle,
            subCriteriaTitle: getFieldTitle(table, field),
            searchInputId,
        };
    });
};
