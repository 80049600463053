import React from "react";
import ClassNames from "classnames";

import IconWrap from "../../Icons";

import "./InlinePanelHeader.scss";

const InlinePanelHeader = ({ title, onClose, className, children, titleIcon }) => {
    const panelClass = ClassNames("inline-panel-header", className);

    return (
        <div className={panelClass}>
            <div className="inline-panel-header-lead">
                {titleIcon && <IconWrap icon={titleIcon} />}
                <h3>{title}</h3>
                {onClose && <IconWrap icon="clear-close" title="Close" onClick={onClose} />}
            </div>
            {children}
        </div>
    );
};

export default InlinePanelHeader;
