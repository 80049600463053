import React, { memo, useCallback } from "react";
import { exportDatagridToCSV } from "../../../utils/CSV";
import IconWithLabel from "../../../ui/Icons/IconWithLabel";
import FTPConfigurationsGrid from "./FTPConfigurationsGrid";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize } from "components/ui/SideNav/SideNavRoot";
import Form from "./Form";

const FTPConfigurationsPanel = memo(() => {
    const gridId = "ftp-configs";

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "ftp-configs-sidenav-panel",
    });

    const handleCreate = useCallback(() => {
        handleOpenSidePanel(<Form gridRefresh={() => {}} onClose={handleCloseSidePanel} />);
    }, [handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <div className="tab-list-tab-content flex-one-in-column flex-column">
            <div className="data-grid-controls">
                <IconWithLabel icon="plus" onClick={handleCreate}>
                    Add New FTP Configuration
                </IconWithLabel>
                <span className="flex-one"></span>
                <IconWithLabel withHandMadeIcon onClick={() => exportDatagridToCSV({ dataGridId: gridId })}>
                    Export CSV
                </IconWithLabel>
            </div>
            <div className="panel ftp-configurations flex-column fill-height no-scroll">
                <FTPConfigurationsGrid gridId={gridId} />
            </div>
        </div>
    );
});

export default FTPConfigurationsPanel;
