import React from "react";
import cn from "classnames";

import IconWrap from "../Icons";

const SideNavHeader = ({
    className,
    title,
    errorBackground,
    leadBlockIcon,
    smallHeader,
    sidenavHeaderLeftAligned,
    iconWrapCombined,
    onClose,
    children,
    nameInBold,
    subTitle,
}) => {
    return (
        <div
            className={cn("sidenav-header", className, {
                "sidenav-header--with-icon": leadBlockIcon,
                "sidenav-header--error": errorBackground,
                "sidenav-header--small": smallHeader,
                "sidenav-header--left-aligned flex-column": sidenavHeaderLeftAligned,
            })}
        >
            <div
                className={cn("sidenav-header__lead", {
                    "flex-row align-center": smallHeader,
                    "flex-column align-center": !smallHeader,
                    "sidenav-header--left-aligned__lead": sidenavHeaderLeftAligned,
                })}
            >
                {leadBlockIcon && (
                    <IconWrap
                        iconWrapMedium={smallHeader}
                        iconWrapBig={!smallHeader}
                        iconWrapTheme
                        iconWrapCombined={iconWrapCombined}
                        icon={leadBlockIcon}
                    />
                )}
                <div
                    className={cn("sidenav-header__title", {
                        "sidenav-header--with-icon__title": leadBlockIcon,
                        "sidenav-header--small__title": smallHeader,
                        "sidenav-header--left-aligned__title": sidenavHeaderLeftAligned,
                    })}
                >
                    {title}
                    <b>{nameInBold}</b>
                </div>
                <div className="sub-title">{subTitle}</div>
                <IconWrap icon="clear-close" title="Close" onClick={() => onClose()} />
            </div>
            {sidenavHeaderLeftAligned ? (
                <div className="scrollable-sidenav-header-part flex-one-in-column with-scroll fill-height">{children}</div>
            ) : (
                <>{children}</>
            )}
        </div>
    );
};

export default SideNavHeader;
