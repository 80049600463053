import React, { useEffect } from "react";

import { connect } from "react-redux";
import getPath from "lodash/get";

import WaitIcon from "../../../../../WaitIcon";
import { getResource } from "../../../../../../../store/resources/actions";

const withResource = (WrappedComponent, mocks) => {
    const InitializedResource = (props) => {
        const { resourceId, resourceName, applicationNumber, gettingAtAnyTime, loading, updating, path, data, onError, dispatch } = props;
        const resource = props[resourceName];

        const needToGetResource = gettingAtAnyTime || (!gettingAtAnyTime && !resource);

        useEffect(() => {
            if (needToGetResource) {
                const properties = {
                    resourceName,
                    path: {
                        appId: applicationNumber,
                    },
                };

                if (resourceId) {
                    properties.resourceId = resourceId;
                }

                if (onError) {
                    properties.onError = onError;
                }

                if (path) {
                    properties.path = {
                        ...properties.path,
                        ...path,
                    };
                }

                dispatch(getResource(properties));
            }
        }, [needToGetResource, resourceId, resourceName, applicationNumber, path, onError, dispatch]);

        if (data) {
            return <WrappedComponent {...props} {...{ [props.resourceName]: data }} />;
        }

        if (updating) {
            return <WaitIcon />;
        }

        if (!resource) {
            if (loading) {
                return <WaitIcon />;
            }

            if (!loading) {
                return "Resource not found";
            }
        }

        return <WrappedComponent {...{ [resourceName]: resource }} {...props} />;
    };

    const mapStateToProps = (state, ownProps) => {
        const { resourceName, resourceId } = ownProps;

        const resource = state.resources[resourceName];

        return {
            [resourceName]: mocks || getPath(resource, `itemsById[${resourceId}]`),
            loading: resource && resource.isReading,
            updating: resource && resource.isUpdating,
        };
    };

    return connect(mapStateToProps)(InitializedResource);
};

export default withResource;
