import React, { memo } from "react";
import cn from "classnames";

const GridListCell = memo(({ className, width, children }) => {
    return (
        <div className={cn("grid-list-column", className)} style={{ width }}>
            {children}
        </div>
    );
});

export default GridListCell;
