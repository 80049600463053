import React from "react";

import "./style.scss";

const Form = (props) => {
    let { className, ...otherProps } = props;

    let classNames = ["form", className].join(" ");

    return (
        <form className={classNames} {...otherProps}>
            {props.children}
        </form>
    );
};

export default Form;
