import React, { useState } from "react";
import TabList from "../../../../List/TabList";
import { FilesTab } from "./FilesTab";
import { NotesTab } from "./NotesTab";

import "./FilesAndNotes.scss";

const FilesAndNotes = (props) => {
    const { programNumber, applicationNumber, resourceId, qc, onDescriptionSave, onAcceptQCRequest, setNotesActive } = props;

    const [fileCount, setFileCount] = useState(0);
    const [noteCount, setNoteCount] = useState(0);

    const tabs = [
        {
            id: "files",
            title: `FILES (${fileCount})`,
        },
        {
            id: "notes",
            title: `NOTES (${noteCount})`,
        },
    ];

    const [activeTab, selectActiveTab] = useState(tabs[setNotesActive ? 1 : 0]);

    return (
        <div className="files-notes-tabs flex-column">
            <TabList items={tabs} activeItem={activeTab} onClick={selectActiveTab} />
            <div className="tabs-content">
                <FilesTab
                    qcNumber={qc.qcnumber}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    resourceId={resourceId}
                    setFileCount={setFileCount}
                    hidden={activeTab.id !== "files"}
                />
                <NotesTab
                    qcNumber={qc.qcnumber}
                    applicationNumber={applicationNumber}
                    programNumber={programNumber}
                    resourceId={resourceId}
                    setNoteCount={setNoteCount}
                    hidden={activeTab.id !== "notes"}
                    onDescriptionSave={onDescriptionSave}
                    onAcceptQCRequest={onAcceptQCRequest}
                />
            </div>
        </div>
    );
};

export default FilesAndNotes;
