let geocodeResponses: {
    [key: string]: google.maps.GeocoderResponse;
} = {};

export const getCoordinates = async ({ address, geocoder }: { address: string | number; geocoder: google.maps.Geocoder }) => {
    if (geocodeResponses[address]) {
        return geocodeResponses[address];
    }

    let retryCount = 0;

    while (retryCount < 3) {
        try {
            const response = await geocoder.geocode({ address: String(address) });
            geocodeResponses[address] = response;
            return response;
        } catch (err: any) {
            if (err.code === "ZERO_RESULTS") {
                geocodeResponses[address] = { results: [] };
                return geocodeResponses[address];
            } else if (err.code === "OVER_QUERY_LIMIT") {
                retryCount++;
                const retryDelay = 100 * retryCount;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            } else {
                return { results: [] };
            }
        }
    }

    return { results: [] };
};
