import React, { useCallback, memo } from "react";

import { useBudgetLine } from "../../../store/resources/useResource";
import { openContractTab, openBudgetLineTab } from "../../utils/window";
import { sideNavSize } from "../../ui/SideNav/SideNavRoot";
import useSidePanelHandlers from "../../utils/useSidePanelHandlers";
import { useIsMobile } from "components/utils/useIsMobile";
import { systemUserRights } from "components/utils/user";
import { useUtilityRights } from "../../../store/resources/useResource";

import WaitIcon from "../../ui/WaitIcon";
import ActionLabel from "../../ui/Label/ActionLabel";
import HeaderIconGroup from "../../ui/Icons/IconGroup";
import IconWrap from "../../ui/Icons";
import WindowBodyHeader from "../../ui/WindowBody/WindowBodyHeader";
import SubHeader from "../../ui/Headers/SubHeader";
import BudgetLineDetails from "../../ui/Dashboard/Panel/Contract/BudgetLinesPanel/BudgetLineDetails";

const Header = memo(({ utilityNumber, contractNumber, budgetLineNumber }) => {
    const [budgetLine = {}, isLoading] = useBudgetLine({
        utilityNumber,
        contractNumber,
        budgetLineNumber,
    });

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers({
        size: sideNavSize.small,
        className: "contract-sidenav-panel",
    });

    const [utilityRights, isLoadingRights] = useUtilityRights({ utilityNumber });

    const isMobile = useIsMobile();
    const canEdit =
        utilityRights.includes(systemUserRights.VISIONDSM_ADD_BUDGET) || utilityRights.includes(systemUserRights.VISIONDSM_MANAGE_BUDGET);
    const canOpenContract = utilityRights.includes(systemUserRights.VISIONDSM_ADD_CONTRACT);

    const items = [
        isMobile && {
            name: "Budget Name",
            value: budgetLine.budgetName,
        },
        {
            name: "Parent Line",
            value:
                (budgetLine.parentBudgetLineNumber ? (
                    <ActionLabel
                        onClick={() =>
                            openBudgetLineTab({
                                utilityNumber,
                                contractNumber,
                                budgetLine: {
                                    budgetLineNumber: budgetLine.parentBudgetLineNumber,
                                    budgetName: budgetLine.parentBudgetName,
                                },
                            })
                        }
                    >
                        {budgetLine.parentBudgetName}
                    </ActionLabel>
                ) : null) || "-",
        },
        {
            name: "Budget Category",
            value: budgetLine.budgetLineCategoryName,
        },
        {
            name: "Contract",
            value: canOpenContract ? (
                <ActionLabel
                    onClick={() =>
                        openContractTab({
                            utilityNumber,
                            contractNumber,
                            contractDescription: budgetLine.contractDescription,
                        })
                    }
                >
                    {budgetLine.contractDescription}
                </ActionLabel>
            ) : (
                budgetLine.contractDescription
            ),
        },
        {
            name: "Budget Note",
            value: budgetLine.note || "-",
        },
    ];

    const handleEdit = useCallback(() => {
        handleOpenSidePanel(
            <BudgetLineDetails
                utilityNumber={utilityNumber}
                contractNumber={contractNumber}
                resource={budgetLine}
                onClose={handleCloseSidePanel}
            ></BudgetLineDetails>
        );
    }, [contractNumber, utilityNumber, budgetLine, handleOpenSidePanel, handleCloseSidePanel]);

    return (
        <WindowBodyHeader windowBodyHeaderName="Budget">
            {isLoading || isLoadingRights ? (
                <WaitIcon />
            ) : (
                <>
                    <HeaderIconGroup header>
                        <IconWrap iconWrapBig icon="edit-empty" title={"Edit Budget"} disabled={!canEdit} onClick={handleEdit} />
                    </HeaderIconGroup>
                    <SubHeader items={items} />
                </>
            )}
        </WindowBodyHeader>
    );
});

export default Header;
