import React, { memo } from "react";
import { LinkType, PropertyType } from "components/ui/PortalBuilder/types";
import { isEmpty, isNil } from "lodash";
import { PropertyListItem } from "../PropertyListItem";
import { PropertyListItemTitle } from "../../PropertyList/PropertyListItemTitle";
import { PropertyList } from "../../PropertyList";
import { errorObjectToArray } from "components/ui/PortalBuilder/utils";
import { usePortalBuilderState } from "components/ui/PortalBuilder/PortalBuilderContextProvider";

import "./ImageProperty.scss";

export const ImageLinkProperty = memo((props) => {
    const { title, value, onChange, nestingLevel, errors = {} } = props;
    const isExpanded = !isNil(value?.imageLinkType);
    const [updatedConfig] = usePortalBuilderState((state) => state.updatedConfig);

    const containsActiveError = !isEmpty(errors.imageLink);
    const errorArray = errorObjectToArray(errors);

    const onToggleLink = () => {
        if (isExpanded) {
            onChange("imageLinkType", undefined);
        } else {
            onChange("imageLinkType", "internal");
        }
    };

    const links = updatedConfig.content.pages.map((p) => {
        return {
            label: p.link,
            value: p.link,
        };
    });

    const isInternalLink = value.imageLinkType === LinkType.INTERNAL;

    const internalLinkSelect = {
        id: "imageLink",
        type: PropertyType.Select,
        items: links,
        inline: false,
        placeholder: "Select url",
        hidden: !isInternalLink,
    };

    const selectExistingPages = {
        id: "imageInternalPageSelect",
        title: "Select from existing pages",
        type: PropertyType.Switch,
        borderBottom: false,
        className: "default-text",
        fullWidth: false,
        hidden: !isInternalLink,
    };
    const showDropdownLinkSelect = isInternalLink && value.imageInternalPageSelect;

    const properties = [
        {
            id: "imageLinkType",
            type: PropertyType.SelectBox,
            items: [
                {
                    text: "Internal",
                    value: "internal",
                },
                {
                    text: "External",
                    value: "external",
                },
            ],
            fullWidth: true,
        },
        selectExistingPages,
        showDropdownLinkSelect
            ? internalLinkSelect
            : {
                  id: "imageLink",
                  type: PropertyType.Text,
                  fullWidth: true,
                  borderBottom: false,
              },
        {
            id: "openInNewTab",
            title: "Open in New Tab",
            type: PropertyType.Switch,
            hidden: isInternalLink,
        },
    ];

    if (!value.src) {
        return null;
    }

    return (
        <PropertyListItem className="property-list-item--image-link">
            <PropertyListItemTitle
                title={title}
                toggleTooltip={!isExpanded ? "Switch ON" : "Switch OFF"}
                onToggle={onToggleLink}
                toggleValue={!isExpanded}
            />
            <PropertyList
                config={value}
                isExpanded={isExpanded}
                nestingLevel={nestingLevel + 1}
                onChange={onChange}
                items={properties}
                containsActiveError={containsActiveError}
                errors={errorArray}
            ></PropertyList>
        </PropertyListItem>
    );
});
