import React from "react";
import { connect } from "react-redux";

import UserGroups from "../UserGroups";

import "./style.scss";

const SystemManagementUserGroups = (props) => {
    return (
        <div className="system-user-groups main-grid-wrap responsive fill-height">
            <UserGroups queryType="top" />
        </div>
    );
};

export default connect()(SystemManagementUserGroups);
