import React from "react";
import { connect } from "react-redux";

import TabList from "../../../../List/TabList";
import FilesTab from "./FilesTab";
import CorrespondenceTab from "./CorrespondenceTab";
import SidebarPanel from "../../../../SidebarPanel";
import { SidebarIconGroup } from "../../../../SidebarPanel/SidebarParts/SidebarIconGroup";

import "./DocumentsPanelSmall.scss";

class DocumentsPanelSmall extends React.Component {
    constructor(props) {
        super(props);

        this.tabs = [
            {
                id: "files",
                title: "FILES",
            },
            {
                id: "correspondence",
                title: "CORRESPONDENCE",
            },
        ];

        this.state = {
            activeTab: this.tabs[0],
        };
    }

    render() {
        const { panel, applicationNumber, onSettings, onUnpin, onExpand } = this.props;

        const sidebarHeaderActions = <SidebarIconGroup panel={panel} onSettings={onSettings} onUnpin={onUnpin} onExpand={onExpand} />;

        return (
            <SidebarPanel sidebarHeaderTitle={panel.name} sidebarHeaderActions={sidebarHeaderActions} noPadding noFooter>
                <TabList
                    equalItemsWidth
                    items={this.tabs}
                    activeItem={this.state.activeTab}
                    onClick={(tab) => this.setState({ activeTab: tab })}
                />
                {this.state.activeTab.id === "files" && <FilesTab applicationNumber={applicationNumber} />}
                {this.state.activeTab.id === "correspondence" && <CorrespondenceTab applicationNumber={applicationNumber} />}
            </SidebarPanel>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const applicationNumber = ownProps.panel && ownProps.panel.data && ownProps.panel.data.applicationNumber;

    return {
        applicationNumber,
    };
}

export default connect(mapStateToProps)(DocumentsPanelSmall);
