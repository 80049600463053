import React from "react";
import cn from "classnames";
import { formatJsonDate, formatJsonTime, dateIsSoon, dateIsOverdue, dateIsNA, formatJsonDateTime } from "../../../../utils/date";
import StatusMark from "../../../StatusMark";
import TaskDateAndTerm from "./TaskDateAndTerm";
import { useIsMobile } from "components/utils/useIsMobile";
import { getCompletedByLabel, isAutomated } from "../../utils";

export const TaskDates = ({ task, isCompleted }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return <TaskDatesForMobile task={task} isCompleted={isCompleted} />;
    }

    return (
        <div className="task-dates flex-column">
            <div className="task-dates-top-block flex-row align-center fill-width flex-one">
                <TaskDateAndTerm task={task} />
                <div
                    className={cn("step-date flex-column align-end", {
                        "due-date-soon": dateIsSoon(task.dateDue) && !dateIsNA(task.term),
                        "due-date-overdue": dateIsOverdue(task.dateDue) && !dateIsNA(task.term),
                        "due-date-na": dateIsNA(task.term),
                    })}
                >
                    <div className="col-label">Due Date:</div>
                    {dateIsNA(task.term) ? (
                        <div className="col-content">
                            not
                            <div className="col-content-new-line" />
                            applicable
                        </div>
                    ) : (
                        <>
                            <div className="col-content step-date__value">
                                {formatJsonDate(task.dateDue) || "-"}
                                <div className="col-content-new-line" />
                                {formatJsonTime(task.dateDue) || "-"}
                            </div>
                            {dateIsSoon(task.dateDue) && (
                                <StatusMark statusMarkWarning statusMarkSmall>
                                    Soon
                                </StatusMark>
                            )}
                            {dateIsOverdue(task.dateDue) && (
                                <StatusMark statusMarkError statusMarkSmall>
                                    Overdue
                                </StatusMark>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="step-assignment flex-row">
                <div className="col-label">Assigned To:</div>
                <div className="col-content">{task.taskGroup || "-"}</div>
            </div>
        </div>
    );
};

export const TaskDatesForMobile = ({ task, isCompleted }) => {
    const termValue = `${task.term} day` + (task.term === 1 ? "" : "s");

    const showCompletedBy = isCompleted && !isAutomated(task);

    return (
        <div className="task-dates task-dates--mobile flex-column">
            <div className="flex-row flex-wrap">
                <div className="col-label">Assigned To:</div>
                <div className="col-content">{task.taskGroup || "-"}</div>
            </div>
            <div className="flex-row flex-wrap">
                <div className="col-label">Created:</div>
                <div className="col-content">{formatJsonDateTime(task.dateCreated)}</div>
            </div>
            <div className="flex-row flex-wrap">
                <span className="col-label">Term:</span>
                <span className="col-content">{termValue}</span>
            </div>
            <div className="flex-row flex-wrap">
                <div className="col-label">Due Date:</div>
                <div
                    className={cn("col-content flex-row align-center", {
                        "due-date-soon": dateIsSoon(task.dateDue) && !dateIsNA(task.term),
                        "due-date-overdue": dateIsOverdue(task.dateDue) && !dateIsNA(task.term),
                        "due-date-na": dateIsNA(task.term),
                    })}
                >
                    {dateIsNA(task.term) ? "not applicable" : formatJsonDateTime(task.dateDue)}
                    {!dateIsNA(task.term) && dateIsSoon(task.dateDue) && (
                        <StatusMark statusMarkWarning statusMarkSmall>
                            Soon
                        </StatusMark>
                    )}
                    {!dateIsNA(task.term) && dateIsOverdue(task.dateDue) && (
                        <StatusMark statusMarkError statusMarkSmall>
                            Overdue
                        </StatusMark>
                    )}
                </div>
            </div>
            {showCompletedBy && (
                <div className="flex-row flex-wrap">
                    <div className="col-label">{getCompletedByLabel(task)}</div>
                    <div className="col-content">{formatJsonDateTime(task.userCompleted)}</div>
                </div>
            )}
        </div>
    );
};
