import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { isEmpty, orderBy } from "lodash";

import { useProgram, useResource } from "../../../store/resources/useResource";
import { createResource, getResource } from "../../../store/resources/actions";
import { openNewApplicationTab } from "../../utils/window";

import WaitIcon from "../../ui/WaitIcon";
import Button from "../../ui/Button";
import ButtonGroup from "../../ui/Button/ButtonGroup";
import DropDownInput from "../../ui/Input/DropDownInput";
import PageLeadBlock from "../../ui/PageLeadBlock";
import UtilityProgramsDropDown from "../../ui/Dashboard/Panel/CalculationsPanel/UtilityProgramsDropDown";
import { WarningMessage } from "./../../ui/Message";

import "./style.scss";

const NewApplication = memo((props) => {
    const dispatch = useDispatch();

    const { customerNumber, customerDetails, reset } = props;

    const [utilityNumber, setUtilityNumber] = useState(props.utilityNumber || null);
    const [programNumber, setProgramNumber] = useState(props.programNumber || null);
    const [formPageNumber, setFormPageNumber] = useState();

    const [formPages, setFormPages] = useState([]);
    const [isLoadingFormPages, setIsLoadingFormPages] = useState(true);

    const [applicationIsCreating, setApplicationIsCreating] = useState(false);
    const [showFormApplicationWarning, setShowFormApplicationWarning] = useState(false);
    const [program = {}] = useProgram({ programNumber });

    const [utilities, isLoadingUtilities] = useResource({
        resourceName: "utilities",
        key: "utilities",
        forced: true,
        transform: (data) => {
            if (!isEmpty(data)) {
                return orderBy(data || [], [(item) => item.utility], ["asc"]).map((i) => ({
                    label: i.utility,
                    value: i.utilityNumber,
                }));
            } else {
                return [
                    {
                        label: program.utility,
                        value: utilityNumber,
                    },
                ];
            }
        },
    });

    useEffect(() => {
        if (reset) {
            setUtilityNumber(props.utilityNumber || null);
            setProgramNumber(props.programNumber || null);
            setFormPageNumber();
        }
    }, [props, reset]);

    useEffect(() => {
        if (programNumber) {
            setIsLoadingFormPages(true);

            dispatch(
                getResource({
                    resourceName: "programApplicationForms",
                    resourceId: programNumber,
                    onError: () => {
                        setShowFormApplicationWarning(true);
                        setIsLoadingFormPages(false);
                    },
                    onComplete: (action) => {
                        if (action.data?.length > 0) {
                            setShowFormApplicationWarning(false);
                        } else {
                            setShowFormApplicationWarning(true);
                        }

                        setFormPages(action.data);
                        setIsLoadingFormPages(false);
                    },
                })
            );
        }
    }, [programNumber, dispatch]);

    const formPagesList =
        (formPages &&
            formPages.map((i) => ({
                label: i.pageName,
                value: i.pageNumber,
            }))) ||
        [];

    const onUtilityChange = (number) => {
        setUtilityNumber(number);
        setProgramNumber();
        setFormPageNumber();
        setShowFormApplicationWarning(false);
    };

    const onProgramChange = (number) => {
        setProgramNumber(number);
        setFormPages([]);
        setFormPageNumber();
        setIsLoadingFormPages(true);
        setShowFormApplicationWarning(false);
    };

    const onApplicationFormChange = (number) => {
        setFormPageNumber(number);
    };

    const onCreate = () => {
        setApplicationIsCreating(true);

        dispatch(
            createResource({
                resourceName: "applications",
                body: {
                    programNumber,
                    pageNumber: formPageNumber,
                },
                showSuccessNotification: false,
                onSuccess: (action) => {
                    openNewApplicationTab({
                        applicationNumber: action.data.number,
                        utilityNumber,
                        programNumber,
                        formPageNumber,
                        customerNumber,
                        customerDetails,
                    });
                },
                onComplete: () => {
                    setApplicationIsCreating(false);
                },
            })
        );
    };

    const onCancel = () => {
        setUtilityNumber();
        setProgramNumber();
        setFormPageNumber();
        setShowFormApplicationWarning(false);
    };

    const renderEmptyProgram = () => {
        return (
            <div className="application-form-warning-msg">
                <WarningMessage>No active program is available for selected utility. Please choose another utility.</WarningMessage>
            </div>
        );
    };

    return (
        <div className="create-application">
            <PageLeadBlock withIcon icon="new-empty">
                <div>
                    Create <span>New Application</span>
                </div>
            </PageLeadBlock>
            <div className="create-application__head">
                <div className="main-grid-wrap responsive">
                    <DropDownInput
                        label="Select Utility"
                        mobileHeader="Select Utility"
                        placeholder={isLoadingUtilities ? "Loading..." : "Not Selected"}
                        disabled={isLoadingUtilities || applicationIsCreating}
                        data={utilities}
                        value={utilityNumber}
                        onChange={(event) => onUtilityChange(event.target.value)}
                        className={"application-create-dropdown" + (utilityNumber ? " selected" : "")}
                    />

                    {utilityNumber && (
                        <UtilityProgramsDropDown
                            label="Select Program"
                            mobileHeader="Select Program"
                            utilityNumber={utilityNumber}
                            activeOnly
                            disabled={applicationIsCreating}
                            program={programNumber}
                            onChange={onProgramChange}
                            className={programNumber ? " selected" : ""}
                            renderWhenEmpty={renderEmptyProgram()}
                        />
                    )}

                    {programNumber && !showFormApplicationWarning && (
                        <DropDownInput
                            label="Select Application Form"
                            mobileHeader="Select Application Form"
                            placeholder={isLoadingFormPages ? "Loading..." : "Not Selected"}
                            disabled={isLoadingFormPages || applicationIsCreating}
                            data={formPagesList}
                            value={formPageNumber}
                            onChange={(event) => onApplicationFormChange(event.target.value)}
                            className={"application-create-dropdown" + (formPageNumber ? " selected" : "")}
                        />
                    )}
                    {showFormApplicationWarning && (
                        <div className="application-form-warning-msg">
                            <WarningMessage>
                                No application form is available for selected program. Please choose another program or utility.
                            </WarningMessage>
                        </div>
                    )}

                    {applicationIsCreating && <WaitIcon />}
                    {!applicationIsCreating && (
                        <div
                            className={
                                "btn-group-wrapper" + (formPageNumber ? " last-step" : "") + (utilityNumber ? " first-selection" : "")
                            }
                        >
                            <ButtonGroup classNasme="test" transparent>
                                <Button primary onClick={onCreate} hidden={!formPageNumber}>
                                    Continue with creation
                                </Button>
                                <Button hidden={!utilityNumber} onClick={onCancel}>
                                    {formPageNumber ? "Cancel" : "Clear Selection"}
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default NewApplication;
