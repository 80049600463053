import * as actionTypes from "../actionTypes";
import cn from "classnames/dedupe";

const initialState = {};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIDENAV_OPEN:
            state = {
                ...state,
                [action.id]: {
                    ...action.props,
                },
            };
            break;
        case actionTypes.SIDENAV_SET_FORM:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    form: action.form,
                },
            };
            break;
        case actionTypes.SIDENAV_CLOSE:
            const { [action.id]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };

            break;
        case actionTypes.SIDENAV_SET_CONFIRM:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    confirmMessage: action.confirmMessage,
                },
            };
            break;
        case actionTypes.SIDENAV_SET_OPENING:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    className: cn(state[action.id].className, "sidenav--opening"),
                },
            };
            break;
        case actionTypes.SIDENAV_RESET_OPENING:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    className: cn(state[action.id].className, {
                        "sidenav--opening": false,
                    }),
                },
            };
            break;
        case actionTypes.SIDENAV_SET_CLOSING:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    className: cn(state[action.id].className, "sidenav--closing"),
                },
            };
            break;
        case actionTypes.SIDENAV_CHANGE:
            state = {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    size: action.size,
                    className: action.className,
                },
            };
            break;
        default:
            break;
    }

    return state;
};
