import React, { useEffect, useRef, memo } from "react";
import * as pbi from "powerbi-client";
import { processHyperlink } from "./utils";

import IconWithLabel from "../Icons/IconWithLabel";

import "./style.scss";

const PowerBiReport = memo(
    ({ accessToken, embedUrl, embedId, showFilter, isMobile, showPrint, pageName, onDataSelected, onDataHyperlinkClicked }) => {
        const reportRef = useRef();
        let report = null;

        useEffect(() => {
            if (reportRef.current) {
                var mobileSettings = {
                    layoutType: pbi.models.LayoutType.MobilePortrait,
                };

                var config = {
                    type: "report",
                    id: embedId,
                    tokenType: pbi.models.TokenType.Embed,
                    accessToken: accessToken,
                    embedUrl: embedUrl,
                    pageName: pageName,
                    permissions: pbi.models.Permissions.Read,
                    settings: {
                        filterPaneEnabled: showFilter || false,
                        navContentPaneEnabled: pageName ? false : true,
                        hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
                        ...(isMobile ? mobileSettings : {}),
                    },
                };

                /* eslint-disable */ // powerbi object is global
                report = powerbi.embed(reportRef.current, config);
                /*eslint-enable */

                /* eslint-disable */ // powerbi object is global
                report.on("dataSelected", function (event) {
                    onDataSelected && onDataSelected(event);
                });

                report.on("dataHyperlinkClicked", function (event) {
                    const { url } = event.detail;

                    processHyperlink({ url });
                    onDataHyperlinkClicked && onDataHyperlinkClicked(event);
                });

                /*eslint-enable */
            }

            return () => {
                if (report && reportRef.current) {
                    /* eslint-disable */ // powerbi object is global
                    powerbi.reset(reportRef.current);
                    /*eslint-enable */
                }
            };
        }, [accessToken, embedUrl, embedId, showFilter, isMobile, pageName]);

        const PrintReport = () => {
            if (reportRef.current) {
                /* eslint-disable */ // powerbi object is global
                var report = powerbi.get(reportRef.current);
                /*eslint-enable */

                report.print();
            }
        };

        return (
            <div className="report">
                {showPrint && (
                    <div className="data-grid-controls flex-row align-center report-actions">
                        <span className="flex-one" />
                        <IconWithLabel icon="print" onClick={PrintReport}>
                            Print
                        </IconWithLabel>
                    </div>
                )}
                <div key={embedId + Boolean(isMobile).toString()} className="report-body" ref={reportRef} />
            </div>
        );
    }
);

export default PowerBiReport;
