const storage = sessionStorage;
const STATE_KEY = "submenu-state";

export const getSubMenuState = () => {
    return JSON.parse(storage.getItem(STATE_KEY));
};

export const setSubMenuState = (state) => {
    if (state !== null) {
        storage.setItem(STATE_KEY, JSON.stringify(state));
    }
};

export const deleteSubMenuState = () => {
    storage.removeItem(STATE_KEY);
};
