import React, { memo, useMemo, useCallback, useContext } from "react";
import { isEqual, sortBy, uniqBy } from "lodash";

import { availableGrids } from "../configureGrids";
import useMultiPageRowSelect from "../../../store/dataGrid/useMultiPageRowSelect";
import { getUserGroupList } from "./utils";
import { useSelector } from "react-redux";
import { toArray } from "components/utils/array";

import Form from "./Form";
import DataGrid from "../../ui/DataGrid";
import WaitIcon from "../../ui/WaitIcon";
import DropDownInput from "components/ui/Input/DropDownInput";
import useSidePanelHandlers from "components/utils/useSidePanelHandlers";
import { sideNavSize, sideNavKey } from "components/ui/SideNav/SideNavRoot";
import { WindowContext } from "../../ui/Windows/Window";
import { useDataGrid } from "components/utils/useDataGrid";
import { mapGridRowToObject } from "components/utils/datagrid";
import { userGroupsGridColumnKeys } from "./utils";

const rowActions = [
    {
        name: "edit",
        icon: "edit-empty",
        title: "Edit User Group",
        footerExclude: true,
    },
    {
        name: "add",
        icon: "plus",
        title: "Add New Child Group",
        footerExclude: true,
    },
];

const Grid = memo(({ utilityNumber, userName, queryType, dataGridId, onRowSelect, onRefresh }) => {
    const [onRowSelectChange, onPageChanged] = useMultiPageRowSelect({
        dataGridId,
        onRowSelect,
    });

    const { isSplitView } = useContext(WindowContext);
    const key = isSplitView ? undefined : sideNavKey.globalRight;

    // Use global sidepanel if used outside of utility dashboard
    const sidePanelProps = useMemo(
        () => ({
            size: sideNavSize.small,
            sideNavKey: utilityNumber ? undefined : key,
        }),
        [utilityNumber, key]
    );

    const { handleOpenSidePanel, handleCloseSidePanel } = useSidePanelHandlers(sidePanelProps);

    // Create list with available user groups
    const groupTypesList = useSelector((state) => {
        const list = toArray(getUserGroupList({ utilityNumber, userName }));

        return sortBy(
            uniqBy(list, "groupType").map((item) => ({
                label: item.groupType,
                value: item.groupType,
            })),
            (item) => item.label.toLowerCase()
        );
    }, isEqual);

    const [gridConfig, isLoadingConfig] = useDataGrid({
        dataGridId: availableGrids.userGroups,
        dataGridInstanceId: dataGridId,
        getDataAction: {
            type: "userGroupsGridGetDataAction",
            props: {
                columnKeysMap: userGroupsGridColumnKeys,
                utilityNumber,
                userName,
                queryType,
                dataGridId,
            },
        },
        tree: {
            treeColumn: userGroupsGridColumnKeys.groupName,
            idColumn: userGroupsGridColumnKeys.groupNumber,
            parentIdColumn: userGroupsGridColumnKeys.parentGroupNumber,
            parentNameColumn: userGroupsGridColumnKeys.parentGroupName,
        },
    });

    const onRowAction = useCallback(
        (action, onExpand) => {
            const { name, dataItem } = action;

            const data = mapGridRowToObject(userGroupsGridColumnKeys, dataItem);

            switch (name) {
                case "edit":
                    handleOpenSidePanel(
                        <Form
                            utilityNumber={utilityNumber}
                            userName={userName}
                            dataItem={data}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;
                case "add":
                    handleOpenSidePanel(
                        <Form
                            addChild
                            utilityNumber={utilityNumber}
                            userName={userName}
                            dataItem={data}
                            gridRefresh={onRefresh}
                            onClose={handleCloseSidePanel}
                        />
                    );
                    break;
                default:
                    break;
            }
        },
        [utilityNumber, userName, handleOpenSidePanel, handleCloseSidePanel, onRefresh]
    );

    const columnFilterContent = useMemo(
        () => ({
            [userGroupsGridColumnKeys.groupType]: (column, filterValue, onEnterChange) => (
                <DropDownInput
                    className="grid-filter-dropdown"
                    withPopper
                    mobileHeader="Select Group Type"
                    disabled={groupTypesList.length === 0}
                    data={groupTypesList}
                    value={filterValue}
                    emptyItem
                    emptyItemLabel="SHOW ALL"
                    onChange={(event) => onEnterChange(event, column)}
                />
            ),
        }),
        [groupTypesList]
    );

    if (isLoadingConfig) {
        return <WaitIcon />;
    }

    return (
        <DataGrid
            name={dataGridId}
            config={gridConfig}
            onRowAction={onRowAction}
            customRowActions={rowActions}
            canSelectRow
            columnFilterContent={columnFilterContent}
            onRowSelectChange={onRowSelectChange}
            onPageChanged={onPageChanged}
            virtualized
        />
    );
});

export default Grid;
