import { store } from "../configureStore";
import getPath from "lodash/get";
import { getResourceKey } from "store/configureResources";

export const getResourceByPath = (path, defaultValue) => {
    const state = store.getState();

    return getPath(state, `resources.${path}`) || defaultValue;
};

/**
 * Retrieves a specific resource item from the store.
 * Either the resourceId or key must be provided.
 *
 * @param {Object} options - The options object.
 * @param {string} options.resourceName - The name of the resource.
 * @param {string} [options.resourceId] - The ID of the resource item.
 * @param {string} [options.key] - The key of the resource item.
 * @param {function} [options.transform] - A function to transform the data.
 * @returns {*} The resource item from the store, or undefined if not found.
 */
export const getResourceFromStore = ({ resourceName, resourceId, key, transform }) => {
    const resourceKey = getResourceKey({ resourceId, key });

    const data = store.getState().resources[resourceName]?.itemsById[resourceKey];

    if (transform) {
        return transform(data);
    }

    return data;
};
