import React from "react";
import withDataGrid from "../../../../DataGrid/withDataGrid";
import DataGrid from "../../../../DataGrid";
import { utilitiesCatalogCalculationAssociationsColumnKeys } from "../../../../../views/configureGrids";
import { associationsGridCustomRowActions } from "../utils";

export const CatalogCalculationAssociationsGrid = withDataGrid(
    "utilitiesCatalogCalculationAssociations",
    ({ gridId, dataGridConfig, onRowSelectChange, onPageChanged, onRemove, programNumber, disabled }) => {
        const onRowAction = (action) => {
            const { name, dataItem } = action;
            const catalogNumber = dataItem[utilitiesCatalogCalculationAssociationsColumnKeys.catalogNumber];
            const catalogName = dataItem[utilitiesCatalogCalculationAssociationsColumnKeys.catalogName];

            switch (name) {
                case "delete":
                    onRemove({ catalogName, catalogNumber });
                    break;
                default:
                    break;
            }
        };

        if (disabled) {
            associationsGridCustomRowActions[0].disabled = true;
            associationsGridCustomRowActions[0].title = undefined;
        }

        return (
            <DataGrid
                name={gridId}
                config={dataGridConfig}
                customRowActions={associationsGridCustomRowActions}
                canSelectRow={!disabled}
                onRowAction={onRowAction}
                onRowSelectChange={onRowSelectChange}
                onPageChanged={onPageChanged}
            />
        );
    }
);
