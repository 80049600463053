import { auditType } from "../../../../../utils/auditType";

export const auditPanelTypes = [
    {
        label: "Workflow History",
        type: auditType.task,
    },
    {
        label: "Project History",
        type: auditType.project,
    },
    {
        label: "All Records",
        type: auditType.all,
    },
];
