import React, { useContext } from "react";
import { BorderPropertyContext } from "components/ui/PortalBuilder/contexts";
import { BorderCustomPropertyName, BorderSideName, BorderSides } from "./types";
import { PortalBuilderPropertyProps, PropertyName } from "components/ui/PortalBuilder/types";
import { BorderBox } from "./BorderBox";
import { DefaultBorderStyle, emptyAllSidesStyleProperties } from ".";
import { PropertyListItem } from "../PropertyListItem";

export const WidthAllSidesProperty = (props: WidthAllSidesPropertyProps) => {
    const { value, borderBottom, borderTop, onChange } = props;
    const { customPropertiesValue } = useContext(BorderPropertyContext);

    const onSelect = (id: PropertyName, width: number) => {
        const extraProperties = {
            ...emptyAllSidesStyleProperties.map((i) => ({ ...i, value: DefaultBorderStyle })),
        };

        // Deselect the value if same width is selected
        if (width === value) {
            onChange(id, `${0}px`, extraProperties);
        } else {
            onChange(id, `${width}px`, extraProperties);
        }
    };

    if (customPropertiesValue[BorderCustomPropertyName.BorderWidthType] !== BorderSides.AllSides) {
        return null;
    }

    const selectedWidth = Number(value);

    return (
        <PropertyListItem
            className="property-list-item--border-width property-list-item--border-width-all-sides flex-row"
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            {Array.from(Array(5).keys()).map((index) => (
                <BorderBox
                    key={index}
                    width={index + 1}
                    side={BorderSideName.AllSides}
                    isSelected={selectedWidth === index + 1}
                    onClick={selectedWidth === index + 1 ? undefined : () => onSelect(PropertyName.BorderWidth, index + 1)}
                />
            ))}
        </PropertyListItem>
    );
};

interface WidthAllSidesPropertyProps extends Omit<PortalBuilderPropertyProps, "value"> {
    value?: number;
}
