import React, { memo } from "react";
import { useSelector } from "react-redux";
import { hasCriteriaForSearch } from "../../../store/globalSearch/utils";

import NothingFoundBlock from "../NothingFoundBlock";

const SearchResultsEmpty = memo(({ instanceId, criteriaList }) => {
    const hasCriteria = hasCriteriaForSearch({ criteriaList });
    const errorMessage = useSelector((state) => state.globalSearch.errorMessage[instanceId]);

    const text = errorMessage ? errorMessage : "Nothing found!";

    if (!hasCriteria) {
        return null;
    }

    return <NothingFoundBlock icon="search" title={text}></NothingFoundBlock>;
});

export default SearchResultsEmpty;
