import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ReactModal from "react-modal";

import { heartbeat } from "../store/system/actions";

import Maintenance from "./views/Maintenance";
import WaitIcon from "./ui/WaitIcon";
import ModalRoot from "./ui/Modal/ModalRoot";
import Layout from "./ui/Layout";
import WindowManager from "./views/WindowManager";
import ViewPlaceholder from "./ui/ViewPlaceholder";

import "react-toastify/dist/ReactToastify.css";

if (process.env.NODE_ENV !== "test") {
    ReactModal.setAppElement("#root");
}

const App = memo(() => {
    const dispatch = useDispatch();

    const checked = useSelector((state) => state.system?.checked);
    const systemStatus = useSelector((state) => state.system?.systemStatus);
    const isLatestVersion = useSelector((state) => state.system.isLatestVersion);

    const isOnline = systemStatus === "active";

    useEffect(() => {
        if (!isLatestVersion) {
            toast.info("New version of Vision DSM available! Refresh browser tab to get latest version.", {
                toastId: "new-app-version-notification",
                autoClose: false,
            });
        }
    }, [isLatestVersion]);

    useEffect(() => {
        dispatch(heartbeat());
    }, [dispatch]);

    return (
        <Layout>
            {isOnline && checked && <WindowManager />}
            {!isOnline && checked && <Maintenance />}
            {!checked && (
                <ViewPlaceholder>
                    <WaitIcon />
                </ViewPlaceholder>
            )}
            <ModalRoot />
            <ToastContainer autoClose={2500} position="bottom-left" />
        </Layout>
    );
});

export default App;
