import React, { memo } from "react";

import ContentTitle from "../../ui/Title/ContentTitle";
import IconWrap from "../../ui/Icons";
import EquipmentPanel from "../../ui/Dashboard/Panel/Project/EquipmentPanel";

import "./style.scss";

const ApplicationEquipment = memo(({ applicationNumber, programNumber }) => {
    return (
        <div className="new-app-equipment">
            <div className="new-app-section">
                <div className="section-name">
                    <IconWrap iconWrapBig iconWrapTheme iconWrap="equipment"></IconWrap>
                    <ContentTitle>Equipment</ContentTitle>
                </div>
                <div className="new-app-section__form">
                    <EquipmentPanel
                        applicationNumber={applicationNumber}
                        programNumber={programNumber}
                        showHeader={false}
                        showExport={false}
                    />
                </div>
            </div>
        </div>
    );
});

export default ApplicationEquipment;
