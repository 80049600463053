import React, { memo } from "react";
import { Droppable } from "react-beautiful-dnd";

import { useId } from "../../../utils/hooks/useId";
import { Step } from "./Step";
import { WORKFLOW } from "../../../constants/workflow";

const Steps = memo(({ steps, statusNumber, onSetActiveItem }) => {
    const droppableId = useId(statusNumber);

    return (
        <Droppable droppableId={droppableId} type={WORKFLOW.STEPS_CONTAINER}>
            {(dropProvided) => (
                <div className="wf__steps" ref={dropProvided.innerRef}>
                    {steps.map((step, index) => (
                        <Step key={step.number} {...step} index={index} statusNumber={statusNumber} onSetActiveItem={onSetActiveItem} />
                    ))}
                    {dropProvided.placeholder}
                </div>
            )}
        </Droppable>
    );
});

export default Steps;
