import React, { useCallback, useContext, useMemo, memo } from "react";

import Button from "../../../../Button";
import { WorkflowContext } from "../../../context/WorkflowContext";
import { StepPropertiesSettingsContext } from "../../../context/StepPropertiesSettingsContext";
import { MainForm } from "./MainForm";
import { RulesForm } from "./RulesForm";
import { Assignments } from "./Assignments";
import { isValidStrings } from "../../../utils/validation";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import "./Form.scss";

export const StepProperties = memo(
    ({
        number,
        name,
        isConfirmed = true,
        typeNumber,
        typeName,
        term,
        statusID,
        constraintRule,
        autoCompleteRule,
        assignments,
        content,
        attributes,
        settingsContainerRef,
    }) => {
        const { activeItem, onChangeWorkflowStepProperties, onDeleteWorkflowStep } = useContext(WorkflowContext);

        const { statusNumber } = activeItem;

        const isValid = useMemo(() => {
            if (typeName === "Clear Flag" || typeName === "Flag Record") {
                if (attributes.length > 0) {
                    if (attributes[0].name === "Application Flag Code") {
                        return isValidStrings(typeNumber, attributes[0].value, name, term) && term >= -1 && term <= 365;
                    }
                } else {
                    return false;
                }
            } else if (typeName === "QC Task" || typeName === "Test for QC with Status Change") {
                return (
                    isValidStrings(
                        typeNumber,
                        name,
                        term,
                        attributes.find((attr) => attr.name === "Quality Control Type")?.value,
                        attributes.find((attr) => attr.name === "Quality Control Threshold Percentage")?.value
                    ) &&
                    term >= -1 &&
                    term <= 365
                );
            } else {
                return isValidStrings(typeNumber, name, term) && term >= -1 && term <= 365;
            }
        }, [typeNumber, name, term, attributes, typeName]);

        const handleClickCreateStep = useCallback(() => {
            if (typeName === "Application Form Entry Task" && isEmpty(attributes)) {
                toast.error("Form Page Number is required");
                return;
            }
            if (isValid) {
                onChangeWorkflowStepProperties({ isConfirmed: true });
            }
        }, [isValid, onChangeWorkflowStepProperties, attributes, typeName]);

        const handleClickCancel = useCallback(
            (e) => onDeleteWorkflowStep(e, statusNumber, number),
            [onDeleteWorkflowStep, statusNumber, number]
        );

        return (
            <StepPropertiesSettingsContext.Provider value={{ attributes, content }}>
                <div className="wf__settings-step flex-column fill-height">
                    <div className="flex-one-in-column with-scroll">
                        <MainForm
                            name={name}
                            typeNumber={typeNumber}
                            typeName={typeName}
                            term={term}
                            statusID={statusID}
                            content={content}
                            attributes={attributes}
                        />
                        {typeNumber && (
                            <>
                                <RulesForm autoCompleteRule={autoCompleteRule} constraintRule={constraintRule} />
                                <Assignments stepNumber={number} assignments={assignments} settingsContainerRef={settingsContainerRef} />
                            </>
                        )}
                    </div>
                    {!isConfirmed && (
                        <div className="wf__settings-step-buttons flex-row">
                            <Button icon="plus" primary onClick={handleClickCreateStep} disabled={!isValid}>
                                Create Step
                            </Button>
                            <Button className="wf__settings-step-buttons-cancel" onClick={handleClickCancel}>
                                Cancel
                            </Button>
                        </div>
                    )}
                </div>
            </StepPropertiesSettingsContext.Provider>
        );
    }
);
