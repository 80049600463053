import { store } from "../../../../../../../store/configureStore";
import { getData } from "../../../../../../../store/dataGrid/actions";
import {
    refreshApplicationFolderListsInOpenTabs,
    refreshApplicationDetailsInOpenTabs,
} from "../../../../../../../store/resources/refreshResource";
import { referenceTypes } from "../../../../../Reference/referenceTypes";
import { referencePromise, getReferenceValueByName } from "../../../../../Reference/utils";

const ATTRIBUTE_TYPE_FILE_FOLDER_LIST = 471;
const ATTRIBUTE_TYPE_REJECT_REBATES = 489;
const ATTRIBUTE_TYPE_RELATE_REBATES_TO_EQUIPMENT = 532;
const ATTRIBUTE_TYPE_LIMIT_REBATE_TYPE = 763;

export const onAttributeChanged = async ({ programNumber, attributeType, attributeTypeName, dataGridId }) => {
    store.dispatch(getData({ dataGridId }));

    try {
        let type = attributeType;

        if (attributeTypeName) {
            const reference = await referencePromise({
                type: referenceTypes.programAttribute,
            });
            type = getReferenceValueByName({
                reference,
                displayName: attributeTypeName,
            });
        }

        switch (Number(type)) {
            case ATTRIBUTE_TYPE_FILE_FOLDER_LIST:
                refreshApplicationFolderListsInOpenTabs({ programNumber });
                break;
            case ATTRIBUTE_TYPE_REJECT_REBATES:
            case ATTRIBUTE_TYPE_RELATE_REBATES_TO_EQUIPMENT:
            case ATTRIBUTE_TYPE_LIMIT_REBATE_TYPE:
                refreshApplicationDetailsInOpenTabs({ programNumber });
                break;
            default:
                break;
        }
    } catch {}
};
