import React from "react";
import { deleteResource } from "../../../../../../store/resources/actions";
import { modalOpen } from "../../../../../../store/modal/actions";

export const onDeleteLookup = ({ utilityNumber, contractNumber, dataItem, onRefresh, dispatch }) => {
    const message = (
        <>
            <p>This action will permanently delete account code lookup and all child items associated.</p>
            <p>Once completed, this action cannot be undone.</p>
            <p>Are you sure you want to perform this action?</p>
        </>
    );

    dispatch(
        modalOpen({
            modalType: "CONFIRM",
            modalProps: {
                title: "Delete Account Code Lookup",
                overlayClassName: "modal-styled",
                className: "delete-budget-lookup-modal modal-sm",
                modalIcon: "delete-trash-empty",
                content: message,
                footerContentCenter: true,
                onConfirm: () => {
                    dispatch(
                        deleteResource({
                            resourceName: "utilitiesContractsBudgetLookups",
                            resourceId: dataItem.lookupNumber,
                            path: {
                                utilityNumber,
                                contractNumber,
                            },
                            onSuccess: onRefresh,
                        })
                    );
                },
            },
        })
    );
};

export const transformFormErrors = (errors) => {
    return errors.map((error) => {
        if (error.name === "pattern") {
            error.message = "Invalid field value";
        }

        return error;
    });
};

export const getBudgetLineFullName = ({ budgetLines, budgetLineNumber }) => {
    const getItem = (budgetLineNumber) => budgetLines.filter((b) => b.budgetLineNumber === budgetLineNumber)[0];
    let nameParts = [];

    let budgetLine = getItem(budgetLineNumber);

    while (budgetLine) {
        nameParts.push(budgetLine.budgetName);
        budgetLine = getItem(budgetLine.parentBudgetLineNumber);
    }

    return nameParts.reverse().join(" > ");
};
