import React from "react";
import { connect } from "react-redux";

import { windowActivate, windowAdd } from "../../../../store/window/actions";
import { windowContainerTypes } from "../../../utils/window";
import { isDevEnvironment } from "components/utils/constants";

import "./style.scss";

const TabHome = ({ dispatch }) => {
    const onOpenHome = () => {
        dispatch(
            windowActivate({
                containerName: windowContainerTypes.Home,
                name: "Dashboard",
            })
        );

        dispatch(
            windowActivate({
                containerName: windowContainerTypes.Root,
                name: "Home",
            })
        );
    };

    const onOpenComponents = () => {
        if (isDevEnvironment) {
            dispatch(
                windowAdd({
                    containerName: windowContainerTypes.Root,
                    name: "Components",
                    component: "ComponentsPage",
                    header: "Components",
                    close: true,
                    activate: true,
                })
            );
        }
    };

    return <div className="tab-home" title="Home" onDoubleClick={() => onOpenComponents()} onClick={() => onOpenHome()} />;
};

export default connect()(TabHome);
